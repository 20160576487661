<fieldset class="mt-40">
  <legend>Parcela</legend>
  <div class="column-50">
    <gmt-input label="Číslo parcely" [required]="true">
      <input
        type="text"
        [(ngModel)]="parcelNumber"
        (input)="onParcelChange()"
        [ngClass]="{ 'parcel-number-error' : (parcelNumber && !parcel.parcisKmen) }"
      />
    </gmt-input>

    <gmt-input label="LV" [required]="true">
      <input type="text" [(ngModel)]="parcel.lv" gmtInputNumber />
    </gmt-input>

    <gmt-input label="Katastrální území" [required]="true">
      <input *ngIf="!areaSelect" type="text" [(ngModel)]="parcel.katuzeNazev" />
      <gmt-select
        *ngIf="areaSelect"
        required="true"
        [data]="this"
        field="parcelArea"
        [filter]="{ sortOrder: { sortBy: 'name' }}"
        (changed)="onParcelChange()"
        resource="areas"
      ></gmt-select>
    </gmt-input>

    <gmt-input *ngIf="!areaSelect" label="Kód katastrální území" [required]="true">
      <input  type="text" [(ngModel)]="parcel.katuzeKod" />
    </gmt-input>
  </div>

  <div class="column-50">
    <gmt-input label="Výměra" [required]="true">
      <input type="text" [(ngModel)]="parcel.vymera" gmtInputNumber />
    </gmt-input>

    <gmt-input label="Druh číslování parcely" [required]="true">
      <gmt-select
        [data]="this"
        field="parcelNumberType"
        [optionItems]="PARCEL_NUMBER_TYPES"
        (changed)="onParcelChange()"
      ></gmt-select>
    </gmt-input>

    <gmt-input label="Druh pozemku" [required]="false">
      <gmt-select
        [data]="this"
        field="parcelType"
        [optionItems]="PARCEL_TYPES"
        (changed)="onParcelChange()"
      ></gmt-select>
    </gmt-input>

    <gmt-input label="Způsob využití" [required]="false">
      <gmt-select
        [data]="this"
        field="usageType"
        [optionItems]="PARCEL_USAGE_TYPES"
        (changed)="onParcelChange()"
      ></gmt-select>
    </gmt-input>
  </div>
</fieldset>
