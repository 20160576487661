import { Component, Inject, OnInit } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { ExportModel } from '@app/ps/models/export.model';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';

@Component({
  selector: 'export-list',
  templateUrl: './export-list.component.html',
  styleUrls: []
})
export class ExportListComponent implements OnInit {

  displayed: number;
  exports: ExportModel[];
  exportList: ListModel<ExportModel>;
  loading = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private listService: ListService,
  ) {
  }

  ngOnInit() {
    this.exportList = this.listService.createList('exports/types-allowed', undefined);
    this.listService.fetchResult(this.exportList).then(() => {
      this.exports = this.exportList.list;

      for (const exp of this.exports) {
        if (exp.userParams) {
          for (const param of exp.userParams) {
            param.selectedObject = {};
            if (!param.selected) {
              param.selected = [];
            }
            param.checklist = new ChecklistModel(param.selected);
          }
        }
      }

      this.loading = false;
    });
  }
}
