﻿import { Component, OnInit, Inject } from "@angular/core";
import { BulkOperationProcessDefinitionModel } from "../../models/bulk-operation-process-definition.model";
import { AuthService } from '@app/common/services/auth.service';
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { SettingsService } from '@app/ps/services/settings.service';

@Component({
  templateUrl: './bulk-operation-select-action.component.html',
  styleUrls: ['./bulk-operation-select-action.component.scss']
})
export class BulkOperationSelectActionComponent implements OnInit {

  data: any;
  actions: any[];
  callbacks: CallbackModel;

  private selectedAction: any;

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    @Inject('processDefinition') private processDefinition: BulkOperationProcessDefinitionModel,
  ) {
    this.setSelectedAction = this.setSelectedAction.bind(this);
  }

  async ngOnInit() {
    this.actions = await this.processDefinition.getActions(this.authService, this.settingsService);
    this.setSelectedAction(this.data.selectAction);
  }

  setSelectedAction(action: any) {
    this.selectedAction = action;
    this.notifyParent();
  }

  notifyParent() {
    this.callbacks.get(Callbacks.DataValidityChanged)({
      isValid: () => !!this.selectedAction,
      data: this.selectedAction,
    });
  }

  isChecked(action: any): boolean {
    return this.selectedAction && action.id === this.selectedAction.id;
  }
}
