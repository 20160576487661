<h1 *ngIf="title">{{ title }}</h1>
<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset *ngFor="let templateType of templateTypes" class="checklist">
      <legend *ngIf="templateType.name">{{ templateType.name }}</legend>
      <div class="cb clearfix checklist-item" *ngFor="let template of templateType.templates" style="display:flex; border-bottom-width: 0px;flex-wrap: wrap;cursor: default;">
        <div class="column-50 checklist-item with-action" style="width: 100%; display:flex">
          <gmt-checkbox [gmtType]="'multiselect'" [gmtChecked]="getParentVisible(template)" (click)="onTemplateChecked(template, $event);" style="padding:7px; margin-top: 15px;" class="fl clickable"></gmt-checkbox>
          <div>
            <label>
              Použitá šablona
            </label>
            <div class="info">
              <div *ngIf="template.name">{{template.name}}<span *ngIf="template.description"> {{template.description}}</span></div>
              {{template.commonName}}
              <div *ngIf="template.versionDescription">{{template.versionDescription}}</div>
            </div>
            <label>
              Generovaný dokument
            </label>
            <div [ngClass]="{ info:template.id !== editedTemplate, input: template.id === editedTemplate }" *ngIf="!templateType.recipients">
              <span *ngIf="template.id !== editedTemplate">
                {{ (template.attachment.baseName ? template.attachment.baseName : '') + '.' + template.attachment.extension }}
              </span>
              <input *ngIf="template.id === editedTemplate" type="text" [(ngModel)]="template.attachment.baseName" (blur)="resetEdit(template);" size="{{ template.attachment.baseName ? template.attachment.baseName.length + 5 : 5 }}" style="width: auto; padding: 4px 9px;"/>
              <a title="Úprava názvu dokumentu" (click)="displayEdit(template.id, $event)" *ngIf="template.id !== editedTemplate">
                <i class="fa fa-pencil ml-10"></i>
              </a>
            </div>

            <div class="fl checklist" *ngIf="template.recipients && template.recipients.length !== 0">
              <div class="cb clearfix" *ngFor="let recipient of template.recipients" [ngClass]="{'checklist-item with-action' : template.recipients.length !== 1}">
                <gmt-checkbox *ngIf="template.recipients.length !== 1" class="fl" [gmtType]="'multiselect'" [gmtChecked]="checklistTemplates.isChecked(getDocumentObject(template, recipient))" (click)="onTemplateRecipientChecked(templateType, template, $event, recipient);" style="padding:7px"></gmt-checkbox>
                <div class="info" *ngIf="template.id+''+recipient.id !== editedTemplate">
                  {{ recipient.templateName + '.' + template.attachment.extension }}
                </div>
                <input *ngIf="template.id+''+recipient.id === editedTemplate" type="text" [(ngModel)]="recipient.templateName" (blur)="resetRecipientEdit(recipient);" size="{{recipient.templateName.length + 5}}" style="width: auto; padding: 4px 9px;"/>
                <a title="Úprava názvu dokumentu" (click)="displayEdit(template.id + '' + recipient.id, $event)" *ngIf="template.id+''+recipient.id !== editedTemplate" style="padding: 7px;float: right;">
                  <i class="fa fa-pencil ml-10"></i>
                </a>
              </div>
            </div>

            <div *ngIf="coveringLetterProcessors.length && isCoveringLetter(templateType)">
              <label title="Proměnné šablony: podepisujiciPrijmeniJmeno, podepisujiciNazevSpolecnosti, podepisujiciEmail, podepisujiciTelefon, podepisujiciFunkce, podepisujiciAdresa, podepisujiciIco, podepisujiciJinaOsoba, podepisujiciJinaOsobaPrijmeniJmeno, podepisujiciJinaOsobaNazevSpolecnosti, podepisujiciJinaOsobaFunkce">
                Zpracovatel
              </label>
              <div class="info">
                <gmt-select [optionItems]="coveringLetterProcessors" [data]="templateCoveringLetterProcessorMap" [field]="template.id" (changed)="onSelectCoveringLetterProcessor($event.newValue, template)"></gmt-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <p *ngFor="let template of templateType.templates">-->
      <!--        {{ template?.attachment ? template.attachment.name : '' }}-->
      <!--      </p>-->
    </fieldset>
    <div class="actions" *ngIf="!generateSetter">
      <gmt-button [clickAction]="generate" [gmtDisabled]="missingRequired()">Generovat</gmt-button>
    </div>
  </div>
</div>
