import { Injectable } from '@angular/core';
import * as  L from 'leaflet';
import * as _ from 'lodash';
import { MapService } from '@app/map/services/map.service';
import { MapLayersStorageService } from '@app/map/services/map-layers-storage.service';
import { MapConfigService } from '@app/map/services/map-config.service';
import { baseKnTemplate } from '@app/map/services/layers-config.service';
import { AuthService } from '@app/common/services/auth.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MapInfoboxService {
  onMapClickHandler: Function;

  constructor(
    private mapService: MapService,
    private mapLayersStorageService: MapLayersStorageService,
    private mapConfigService: MapConfigService,
    private mapUtilsCrsService: MapUtilsCrsService,
    private authService: AuthService,
  ) {
    this.register = this.register.bind(this);
  }

  getMapClickListener(map, mapId, selectorConfig, clickHandler, onFeatureDataLoaded) {

    return async (event) => {
      const popupOptions = <any>{};
      popupOptions.featureDataLoaded = [];
      popupOptions.featureData = [];
      const loaders = [];
      const leafletLayers = await this.mapLayersStorageService.getLayers(mapId);
      let index = 0;

      if (selectorConfig.enableCuzkInfo === true) {
        loaders.push(Promise.resolve((baseKnTemplate.bind({mapUtilsCrsService: this.mapUtilsCrsService}))(event).map((item, key) => {
          item.layerID = '_cuzkInfo';
          item.featureID = key;
          item.highlightFeature = null;
          return item;
        })));
      }

      this.mapConfigService.forEachLayer(selectorConfig, (layer) => {
        if (layer.visible && (layer.type === MapLayerTypeEnum.wms || layer.type === MapLayerTypeEnum.wfs)) {
          const layerObj = this.mapLayersStorageService.getLayerById(leafletLayers, layer.id);
          if (layer.onLayerClick) {
            layerObj.loadFeatureData(
              {
                latlng: event.latlng,
                version: this.getLayerVersion(layerObj),
                queryParams: {t: this.authService.getToken(), p: this.authService.getActualProject().key}
              }
            ).then((data) => {
              let _data = [];
              if (data.features) {
                _data = data.features;
              } else if (Array.isArray(data) && data.length > 0) { // hfbiz multiscenecore layers are served this way
                _data = _.flatten(
                  data
                    .filter(item => item.features && item.features.length > 0)
                    .map(item => item.features)
                );
              }

              if (_data.length > 0) {
                layer.onLayerClick(event, _data, layer);
              }
            });
          }

          if (layer.featureSummaryTemplate) {
            const layerIndex = index++;
            popupOptions.featureData = [];
            popupOptions.featureDataLoaded[layerIndex] = false;

            let dataLoad;
            if (typeof layer.featureSummaryTemplate === 'function') {
              dataLoad = Promise.resolve(layer.featureSummaryTemplate(event)).then(data => {
                return data.map((item, key) => {
                  item.layerID = layer.id;
                  item.featureID = item.featureID || key;
                  item.highlightFeature = item.highlightFeature || null;
                  item.templateProperties = item;
                  return item;
                });
              });
            } else {
              dataLoad = layerObj.loadFeatureData({
                latlng: event.latlng,
                version: this.getLayerVersion(layerObj),
                queryParams: {
                  t: this.authService.getToken(),
                  p: this.authService.getActualProject().key,
                }}
              ).then((data) => {
                if (data.features && data.features.length > 0) {
                  return data.features.map((f) => {
                    return {
                      properties: f.properties,
                      layerID: layer.id,
                      featureID: f.properties.id,
                      templateProperties: layer.featureSummaryTemplate,
                    };
                  });
                }
              });
            }

            dataLoad = dataLoad.then((featureData) => {
              popupOptions.featureDataLoaded[layerIndex] = true;
              if (featureData) {
                popupOptions.featureData.push(...featureData);
                onFeatureDataLoaded(featureData);
              } else {
                onFeatureDataLoaded([]);
              }
            });

            loaders.push(dataLoad);
          }
        }
      });

      loaders.forEach((featureDataloader) => featureDataloader.then((featureData) => {
        if (featureData) {
          popupOptions.featureData.push(...featureData);
          onFeatureDataLoaded(featureData);
        }
      }));

      if (typeof clickHandler === 'function') {
        clickHandler(popupOptions, event);
      }
      onFeatureDataLoaded([]);
    };
  }

  getLayerVersion(layer) {
    return (layer.options && layer.options.version) || (layer.wmsParams && layer.wmsParams.version);
  }

  // temporary disable popups
  disablePopup (evt) {
    if (this.onMapClickHandler) {
      evt.target.off('singleclick', this.onMapClickHandler);
    }
  }

  // reenable popups
  enablePopup (evt) {
    if (this.onMapClickHandler) {
      evt.target.on('singleclick', this.onMapClickHandler);
    }
  }

  register(mapId, selectorConfig, clickHandler, onFeatureDataLoaded) {
    this.mapService.getMap(mapId).then((map) => {
      this.onMapClickHandler = this.getMapClickListener(map, mapId, selectorConfig, clickHandler, onFeatureDataLoaded);
      map.on('singleclick', this.onMapClickHandler);
      // map.on('draw:drawstart', disablePopup);
      // map.on('draw:drawstop', enablePopup);
    });
  }

  deregister(mapId) {
    if (this.onMapClickHandler) {
      this.mapService.getMap(mapId).then((map) => {
        map.off('singleclick', this.onMapClickHandler);
      });
    }

  }
}
