<div class="gmt-switch">
  <ul class="gmt-switch__options">
    <li
      (click)="onSelect(option)"
      *ngFor="let option of options"
      [ngClass]="{ 'gmt-switch__options__item--active': option === selected || option.id === selectedId }"
      class="gmt-switch__options__item is-uppercase pr-10 pl-10 pb-5 pt-5"
      (mouseenter)="onSwitchMouseEnter.emit({event: $event, option: option})" (mouseleave)="onSwitchMouseLeave.emit({event: $event})"
    ><ng-container *ngIf="!option.icon">{{option.value}}</ng-container><i *ngIf="option.icon" class="fa {{option.icon}}"></i></li>
  </ul>
</div>
