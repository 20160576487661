import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { APPLICATIONS } from '@app/common/services/config.service';

@Injectable({providedIn: 'root'})
export class BackendVersionService {

  constructor(
    private authService: AuthService,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
  ) {
    this.getVersion = this.getVersion.bind(this);
  }

  getVersion() {
    const application = this.authService.getActiveApplication();

    if (
      application === this.APPLICATIONS.sy.name
      || application === this.APPLICATIONS.di.name
    ) {
      return this.authService.getAplicationGlobalRestangularProvider()
        .one('version')
        .withHttpConfig({responseType: 'text'})
        .get()
        .toPromise()
        .then(d => {
          return d.data;
        });
    }
  }
}
