<fieldset>
  <legend>Aktualizace dat záborového elaborátu</legend>

  <div class="column-100">
    <label>Kontrola dat</label>
    <div class="info">
      <gmt-button [clickAction]="onPrecheck">Provést</gmt-button>
    </div>
  </div>

  <div class="column-100">
    <label>Aktualizace dat</label>
    <div class="input">
      <gmt-button [clickAction]="onUpdate" [gmtDisabled]="!precheckCompleted || globalSeverity === 1">Provést</gmt-button>
    </div>
  </div>

  <div *ngIf="updateResult" class="column-100">
    <h3 class="pt-7 mb-5">Výsledek:</h3>
    <pre class="jumbotron">{{ updateResult }}</pre>
    <readiness [project]="project"></readiness>
  </div>
</fieldset>

<div class="pt-20 mb-40" [loading]="precheckList && precheckList.loading">
  <div class="text-right">
    <gmt-button [clickAction]="onDownloadCsv">Stáhnout do CSV</gmt-button>
  </div>
  <gmt-labeled-block *ngIf="precheckList && !precheckList.loading" label="Kontrola dat">
    <div class="bordered" *ngIf="precheckList.itemCount">
      <table class="table">
        <thead>
        <tr>
          <th class="cell">
            <sort-by [selected]="'id' === sortOrder.sortBy" (sort)="onSort('id')" [sortDir]="sortOrder.direction">
              id
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'typ' === sortOrder.sortBy" (sort)="onSort('typ')" [sortDir]="sortOrder.direction">
              typ
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'sloupecId' === sortOrder.sortBy" (sort)="onSort('sloupecId')" [sortDir]="sortOrder.direction">
              Sloupec id
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'url' === sortOrder.sortBy" (sort)="onSort('url')" [sortDir]="sortOrder.direction">
              url
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'duvod' === sortOrder.sortBy" (sort)="onSort('duvod')" [sortDir]="sortOrder.direction">
              Důvod
            </sort-by>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of precheckList.list">
          <td class="cell">
            <span *ngIf="item.severity == 1" class="fa-exclamation-triangle fa"></span>
            {{ item.id }}
          </td>
          <td class="cell">
            <span>{{ item.typ }}</span>
          </td>
          <td class="cell">
            <span>{{ item.sloupecId }}</span>
          </td>
          <td class="cell">
            <a href="{{ item.url }}">{{ item.url }}</a>
          </td>
          <td class="cell">
            <span>{{ item.duvod }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!precheckList.itemCount">
      <span>Nenalezena žádná data</span>
    </div>
    <div class="flexbox-container mt-10" *ngIf="precheckList.itemCount">
      <itemscounter *ngIf="precheckList.itemCount" [list]="precheckList" (callbackRegister)="itemCounterCallbackRegister($event)" class="flex-start flex1"></itemscounter>
      <pagescounter *ngIf="precheckList.itemCount" [list]="precheckList" (callbackRegister)="pagesCounterCallbackRegister($event)" class="flex1"></pagescounter>
      <div class="page-count-select">
        <span>Záznamů na stránce</span>
        <gmt-select
          required="true"
          [data]="dataPaging"
          field="itemsPerPage"
          [optionItems]="itemsPerPage"
          class="min-width-unset"
          (changed)="onPageItemsChanged()"
        ></gmt-select>
      </div>
    </div>
    <div class="mt-10">
      <pagination *ngIf="precheckList.itemCount" [shouldUpdateUrl]="false" [list]="precheckList" (pageChanged)="onPageChanged($event)" (callbackRegister)="paginationCallbackRegister($event)"></pagination>
    </div>
  </gmt-labeled-block>


</div>
