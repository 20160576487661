import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ExpertModel, expertModelDefault } from '@app/ps/models/expert.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expert-edit-form',
  templateUrl: './expert-edit-form.component.html',
  styleUrls: ['expert-edit-form.component.scss']
})
export class ExpertEditFormComponent implements OnInit {

  expert: ExpertModel;

  constructor(
    private restangular: Restangular,
    private dialogConfig: DialogConfig,
    private dialogComponent: DialogRef,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    const incomingExpert = (<DialogConfigData>this.dialogConfig.data).expert;
    this.expert = incomingExpert
      ? <ExpertModel>incomingExpert
      : {...expertModelDefault};
  }

  isValid() {
    return this.expert.prijmeni;
  }

  onSave() {
    if (this.expert.id) {
      this.restangular.one('experts', this.expert.id).customPUT(this.expert).toPromise().then((expert) => {
        this.dialogComponent.close(expert.plain());
      });
    } else {
      this.restangular.all('experts/create').customPOST(this.expert).toPromise().then((expert) => {
        this.dialogComponent.close(expert.plain());
      });
    }
  }

  onCancel() {
    this.dialogComponent.close(null);
  }
}
