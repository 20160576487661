<div class="list">
  <div class="row">
    <div class="col-6">
      <h1>Přehled vzorů</h1>
    </div>
    <div class="col-6">
      <div class="actions">
        <a gmtAuthProject actions="template_new" uiSref="sv.tickets.create" class="button mb-10">požadavek na vložení nového vzoru</a>
      </div>
    </div>
  </div>

  <div [loading]="loading"></div>
  <ng-container *ngIf="!loading">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <gmt-select
        [optionItems]="documentTypeOptions"
        [data]="list.filter.filters.documentTypeId"
        field="values"
        [multiselect]="true"
        [itemPipe]="documentTypeFullname"
        selectTitle="Typ dokumentu"
      ></gmt-select>

      <gmt-select
        [optionItems]="statusOptions"
        [data]="list.filter.filters.status"
        field="values"
        [multiselect]="true"
        selectTitle="Platnost vzoru"
      ></gmt-select>

      <gmt-select
        [optionItems]="organizationalUnitOptions"
        [data]="list.filter.filters.organizationalUnitId"
        field="values"
        [multiselect]="true"
        [itemPipe]="organizationalUnitNamePipe"
        selectTitle="{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}"
      ></gmt-select>

      <gmt-select
        visibleByBrand="sv.unit"
        [optionItems]="sectionOptions"
        [data]="list.filter.filters.section"
        field="values"
        [multiselect]="true"
        selectTitle="{{ 'sv.section' | brandTranslate }}"
      ></gmt-select>

      <div class="tag" [ngClass]="{ active: !list.filter.filters.organizationalUnitCode.values.length }" title="Zobrazit vzory všech organizačních jednotek" (click)="onToggleUserOrganizationalUnit()">
        <div>Zobrazit vzory všech organizačních jednotek</div>
      </div>

      <div *ngIf="APP_BRAND.NAME === 'SZ'" class="tag" [ngClass]="{ active: !list.filter.filters.assignable.values.length }" title="Zobrazit vzory neurčené pro {{ APP_BRAND.PS.TITLE }}" (click)="onToggleAssignable()">
        <div>Zobrazit vzory neurčené pro {{ APP_BRAND.PS.TITLE }}</div>
      </div>

      <!-- Fulltext -->
      <div class="column-100">
        <input
          class="fulltext"
          type="text"
          autocomplete="off"
          placeholder="Fultextové hledání..."
          [(ngModel)]="list.filter.filters.searchText.values[0].id"
        />
      </div>
    </filter>

    <div [loading]="list.loading">
      <div class="table-top-container">
        <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      </div>
      <div class="bordered" *ngIf="list.itemCount">
        <div class="table">
          <div class="head-group">
            <div class="row">
              <span class="cell">
                <sort-by
                  [selected]="'organizationalUnitName' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('organizationalUnitName', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}</sort-by>
              </span>

              <span class="cell" visibleByBrand="sv.unit">
                <sort-by
                  [selected]="'section' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('section', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >{{ 'sv.section' | brandTranslate }}</sort-by>
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'documentType' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('documentType', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Typ dokumentu</sort-by>
              </span>

              <span class="cell">
                Platnost
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'createdDate' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('createdDate', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >V {{ APP_BRAND.PS.TITLE }} od</sort-by>
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'number' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('number', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Označení</sort-by>
              </span>

              <span class="cell">
                Účel
              </span>

              <span class="cell">
                Aktuální požadavek na změnu
              </span>

              <span class="cell">
                Možnosti
              </span>
            </div>
          </div>
          <div class="row-group">
            <a class="row" *ngFor="let sample of list.list" [ngClass]="{ 'inactive': sample.status === 'INACTIVE' }" uiSref="sv.samples.detail" [uiParams]="{ id: sample.id }">
              <span class="cell">
                {{ sample.organizationalUnitName }}
              </span>

              <span class="cell" visibleByBrand="sv.unit">
                {{ sample.section }}
              </span>

              <span class="cell">
                {{ sample.documentType | documentTypeFullname }}
              </span>

              <span class="cell">
                {{ sample.status | sampleStatusName }}
                <span *ngIf="getActualizationTicket(sample)" class="tag tag--danger tag--not-clickable" title="V řešení je požadavek na aktualizaci vzoru">A</span>
              </span>

              <span class="cell">
                {{ sample.createdDate | date:'d.M.yyyy' }}
              </span>

              <span class="cell">
                {{ sample.number }}
                <span *ngIf="sample.assignable === false" class="tag tag--danger tag--small is-uppercase">není učen pro {{ APP_BRAND.PS.TITLE }}</span>
              </span>

              <span class="cell">
                {{ sample.description }}
              </span>

              <span class="cell text-center">
                <ng-container *ngIf="getActualizationTicket(sample) as ticket">
                  <a *ngIf="canViewTicket(ticket)" uiSref="sv.tickets.detail" [uiParams]="{ id: ticket.id }">{{ ticket.number }} - {{ ticket.status | ticketStatusName }}</a>
                  <span *ngIf="!canViewTicket(ticket)">{{ ticket.number }} - {{ ticket.status | ticketStatusName }}</span>
                </ng-container>
              </span>

              <span class="cell text-left" style="min-width: 100px;">
                <a *ngIf="sample.canViewSamplePDF" href="{{ getSamplePdfUrl(sample) }}" (click)="$event.stopPropagation();" target="_blank" title="Náhled vzoru">
                  <i class="fa fa-eye"></i>
                </a>
                &nbsp;&nbsp;
                <a (click)="downloadSampleFile(sample); $event.stopPropagation(); $event.preventDefault();" title="Stažení vzoru">
                  <i class="fa fa-download"></i>
                </a>
                &nbsp;&nbsp;
                <a gmtAuthProject actions="template_new" [project]="sample.organizationalUnitCode" *ngIf="(sample.status === 'ACTIVE' || sample.status === 'WAITING_FOR_ACTUALIZATION' || (APP_BRAND.NAME === 'SZ' && sample.status === 'EXCEPTION')) && !getActualizationTicket(sample)" uiSref="sv.tickets.create" [uiParams]="{ id: sample.id }" class="anchor-circle" title="Požadavek na aktualizaci vzoru">
                  A
                </a>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
        Nenalezena žádná data
      </div>
      <div class="flexbox-container">
        <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"></itemscounter>
        <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)" class="flex1 mt-5 text-center"></pagescounter>
        <div class="page-count-select mt-5" *ngIf="list.itemCount && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>

      <div>
        <pagination
          *ngIf="list.itemCount && list.list !== null"
          [list]="list"
          (callbackRegister)="pageableList.paginationCallbackRegister($event)"
        ></pagination>
      </div>
    </div>
  </ng-container>
  <help [helpId]="helpIds.SAMPLE_LIST" class="cm-help-page"></help>
</div>
