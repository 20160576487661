﻿import { Component, Inject, Input, OnInit } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';

import { ConstructionObjectNamePipe } from '../../../common/pipes/construction-object-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';

import { CaseService } from '@app/ps/services/case.service';
import { OwnershipNamePipe } from '@app/common/pipes/ownership-name.pipe';
import { Restangular } from 'ngx-restangular';

class GroupParams {
  group: boolean;
  predicates: GroupPredicate[];
}

class GroupPredicate {
  predicate: (caseData:any)=>boolean = () => true;
  mapping: (caseData:any)=>void = () => {};
}

@Component({
  templateUrl: './titles-create-cases-confirm.component.html',
})
export class TitlesCreateCasesConfirmComponent implements OnInit {
  @Input() data: any;
  saving: boolean = false;
  casesProcessed: number;
  callbacks: CallbackModel;
  user: any;
  cases: any[];
  private messages: any[];
  private caseIds: number[] = [];

  constructor(
    private authService: AuthService,
    private constructionObjectNamePipe: ConstructionObjectNamePipe,
    private subjectNamePipe: SubjectNamePipe,
    private caseService: CaseService,
    private ownershipNamePipe: OwnershipNamePipe,
    private restangular: Restangular,
  ) {
    this.proceed = this.proceed.bind(this);
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.initCases();
  }

  newCase(): any {
    const data = <any>{};
    this.cases.push(data);

    data.constructionObjects = [];
    data.easements = [];
    data.occupations = [];
    data.titles = [];
    //data.buildings = [];
    data.obligation = this.data.details.obligation;
    data.caseOwnerships = [];
    data.series = this.data.details.series;
    data.acceleratedPriceType = this.data.details.acceleratedPriceType;

    return data;
  }

  /**
  * split data into case objects to send to backend
  */
  initCases() {
    this.cases = [];
    this.data.details.titles.forEach(titleData => {
      titleData.dataOwnerships = titleData.dataOwnerships.filter(caseOwnership => !caseOwnership.isParent);
      const groupParams: GroupParams[] = [];

      groupParams.push(this.groupByTitles(titleData));
      groupParams.push(this.groupByConstructionObjects(titleData));
      groupParams.push(this.groupByOwners(titleData));

      // Create combined predicate for case to be created
      groupParams
        .reduce((result: GroupPredicate[], current: GroupParams) => {
          return this.combinePredicates(result, current);
        }, <GroupPredicate[]>null)
        .forEach((predicate: GroupPredicate) => {
          let caseData = this.cases.find(predicate.predicate);
          if (!caseData) {
            caseData = this.newCase();
          }
          predicate.mapping(caseData);
        });
    });

    this.sortCases();
  }

  groupByTitles(titleData): GroupParams {
    const params: GroupParams = new GroupParams();
    const predicates: GroupPredicate[] = [];
    params.group = this.data.details.createGroups.some(group => group.id === 'title');
    params.predicates = predicates;

    const gp: GroupPredicate = new GroupPredicate();
    gp.predicate = (caseData) => caseData.titles[0].id === titleData.dataTitle.id;
    gp.mapping = (caseData) => {
      if (!caseData.titles.some(title => title.id === titleData.dataTitle.id)) {
        caseData.titles.push(titleData.dataTitle);
      }
    };
    predicates.push(gp);

    return params;
  }

  groupByConstructionObjects(titleData): GroupParams {
    const params: GroupParams = new GroupParams();
    const predicates: GroupPredicate[] = [];
    params.group = this.data.details.createGroups.some(group => group.id === 'constructionObject');
    params.predicates = predicates;

    // projít so a filtrovat podle jejich výběru
    if (this.data.details.objectTypes.some(objectType => objectType.key === 'easement')) {
      titleData.dataEasements.forEach(easement => {
        let constructionObjects = easement.constructionObjects;
        if (this.data.details.constructionObjects !== null) {
         constructionObjects = constructionObjects.filter(co1 => this.data.details.constructionObjects.some(co2 => co2.id === co1.id));
        }
        if (constructionObjects.length) {
          constructionObjects.forEach(co => {
            const gp: GroupPredicate = new GroupPredicate();
            gp.predicate = (caseData) => caseData.constructionObjects[0].id === co.id;
            gp.mapping = (caseData) => {
              if (!caseData.constructionObjects.some(co1 => co1.id === co.id)) {
                caseData.constructionObjects.push(co);
              }
              if (!caseData.easements.some(easement1 => easement1.id === easement.id)) {
                delete easement.cases;
                caseData.easements.push(easement);
              }
            };
            predicates.push(gp);
          });
        }
      });
    }

    this.data.details.objectTypes.forEach(objectType => {
      if (!titleData.dataOccupationGroups[objectType.key]) {
        return;
      }
      titleData.dataOccupationGroups[objectType.key].forEach(group => {
        group.occupations.forEach(occupation => {
          let constructionObjects = occupation.constructionObjects;
          if (this.data.details.constructionObjects !== null) {
           constructionObjects = constructionObjects.filter(co1 => this.data.details.constructionObjects.some(co2 => co2.id === co1.id));
          }
          if (constructionObjects.length) {
            constructionObjects.forEach(co => {
              const gp: GroupPredicate = new GroupPredicate();
              gp.predicate = (caseData) => caseData.constructionObjects[0].id === co.id;
              gp.mapping = (caseData) => {
                if (!caseData.constructionObjects.some(co1 => co1.id === co.id)) {
                  caseData.constructionObjects.push(co);
                }
                if (!caseData.occupations.some(occupation1 => occupation1.id === occupation.id)) {
                  caseData.occupations.push(occupation);
                }
              };
              predicates.push(gp);
            });
          }
        });
      });
    });
    return params;
  }

  groupByOwners(titleData): GroupParams {
    const params: GroupParams = new GroupParams();
    const predicates: GroupPredicate[] = [];
    params.group = this.data.details.createGroups.some(group => group.id === 'owner');
    params.predicates = predicates;

    titleData.dataOwnerships.forEach(dataOwnership => {
      const gp: GroupPredicate = new GroupPredicate();
      gp.predicate = (caseData) => caseData.caseOwnerships[0].ownership.opsubId === dataOwnership.ownership.opsubId;
      gp.mapping = (caseData) => {
        if (!caseData.caseOwnerships.some(caseOwnership => caseOwnership.ownership.id === dataOwnership.ownership.id)) {
          caseData.caseOwnerships.push({
            ownership: dataOwnership.ownership,
            parentCaseOwnership: dataOwnership.parentCaseOwnership,
            isParent: dataOwnership.isParent,
          });
        }
      };
      predicates.push(gp);
    });

    return params;
  }

  combinePredicates(predicates1: GroupPredicate[], params: GroupParams) : GroupPredicate[] {
    if (!predicates1 || predicates1.length === 0) {
      predicates1 = [new GroupPredicate()];
    }
    const result: GroupPredicate[] = [];
    predicates1.forEach(p1 => {
      params.predicates.forEach(p2 => {
        const p = new GroupPredicate();
        if (params.group) {
          p.predicate = (caseData) => p1.predicate(caseData) && p2.predicate(caseData);
        } else {
          p.predicate = p1.predicate;
        }
        p.mapping = (caseData) => { p1.mapping(caseData); p2.mapping(caseData); };
        result.push(p);
      });
    });

    return result;
  }

  private sortCases() {
    if (this.data.details.createGroups.some(group => group.id === 'owner')) {
      this.cases.sort((a, b) => {
        const aName = a.caseOwnerships[0] ? this.ownershipNamePipe.transform(a.caseOwnerships[0].ownership) : '';
        const bName = b.caseOwnerships[0] ? this.ownershipNamePipe.transform(b.caseOwnerships[0].ownership) : '';

        if (aName === bName) {
          return 0;
        } else if (typeof aName.localeCompare === 'function') {
          return aName.localeCompare(bName);
        } else {
          return (aName || '') > (bName || '') ? 1 : -1;
        }
      });
    }
  }

  hasCreateGroup(groupKey: string): boolean {
    return this.data.details.createGroups.some(group => group.id === groupKey);
  }

  async proceed() {
    this.casesProcessed = 0;
    this.saving = true;
    this.messages = [];
    for (let caseData of this.cases) {
      try {
        if (caseData.obligation.computePrice && !this.caseService.easementPriceByShare(caseData.obligation)) {
          const series = caseData.series;
          delete caseData.series;
          caseData = await this.restangular
            .one('titles')
            .all('case')
            .all('compute-price')
            .post(caseData)
            .toPromise();
          caseData.series = series;
        }
        const caseCreated = await this.restangular.all('cases/create').post(caseData).toPromise();
        this.caseIds.push(caseCreated.id);
      } catch (error) {
        const caseText = [];
        if (this.hasCreateGroup('owner')) {
          caseText.push('vlastníka ' + caseData.caseOwnerships[0].ownership.subjects.map(this.subjectNamePipe.transform).join(' a '));
        }
        if (this.hasCreateGroup('title')) {
          caseText.push('list vlastnictví ' + caseData.titles[0].lv + ' v katastrálním území ' + caseData.titles[0].area.name);
        }
        if (this.hasCreateGroup('constructionObject')) {
          caseText.push('stavební objekt ' + this.constructionObjectNamePipe.transform(caseData.constructionObjects[0]));
        }
        const msg = (error.status === 400 && error.data && error.data.code === 0 ? error.data.message : 'Při zakládání případu se vyskytla chyba.');
        if (caseText.length) {
          this.messages.push('Pro ' + (caseText.length > 1 ? caseText.slice(0, -1).join(', ') + ' a ' : '') + caseText.slice(-1) + ': ' +
           msg);
        } else {
          this.messages.push(msg);
        }
      }
      this.casesProcessed++;
    }
    this.saving = false;
    this.notifyParent();
  }

  notifyParent() {
    this.callbacks.get(Callbacks.DataValidityChanged)({
      isValid: () => false,
      data: {
        messages: this.messages,
        caseIds: this.caseIds,
      },
    });
  }
}
