import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionService } from '@app/ps/services/opinion.service';
import { TitleModel } from '@app/common/models/title.model';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { BuildingModel } from '@app/common/models/building.model';
import { StateService } from '@uirouter/angular';
import { TooltipParcelPricesComponent } from '@app/common/components/tooltip-parcel-prices/tooltip-parcel-prices.component';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'pricing-overview',
  templateUrl: './pricing-overview.component.html',
})
export class PricingOverviewComponent implements OnInit {

  @Input() titleId: number;

  loading = true;
  isAllowedToCreateOpinion = this.authService.hasPermission('assignable');
  hasExpertOpinionCoefficient = this.authService.getActualProject().showExpertOpinionCoefficient;
  tooltipComponent = TooltipParcelPricesComponent;

  title: TitleModel;
  permanentOpinions: OpinionModel[] = [];
  temporaryOpinions: OpinionModel[] = [];
  parcelsWithPermanentOccupation: ParcelModel[] = [];
  parcelsWithTemporaryOccupation: ParcelModel[] = [];
  buildings: BuildingModel[] = [];

  hasAvailableParcelsWithTemporaryOccupation = false;
  hasAvailableParcelsWithPermanentOccupation = false;
  hasAvailableParcelsWithPermanentOccupationWithoutOpinion = false;
  hasAvailableBuildings = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private stateService: StateService,
    private authService: AuthService,
    private opinionService: OpinionService,
  ) {
  }

  async ngOnInit() {
    await this.loadTitle();
    await this.loadOpinions();
    await this.loadParcelsWithPermanentOccupation();
    await this.loadParcelsWithTemporaryOccupation();
    await this.loadBuildings();

    this.hasAvailableParcelsWithTemporaryOccupation = this.parcelsWithTemporaryOccupation.length > 0;//some(p => p.compensationOpinionsShare < 100 || !p.compensationOpinionsShare);
    this.hasAvailableParcelsWithPermanentOccupationWithoutOpinion = this.parcelsWithPermanentOccupation.some(p => p.buyoutOpinionsShare < 100 || !p.buyoutOpinionsShare);
    this.hasAvailableParcelsWithPermanentOccupation = this.parcelsWithPermanentOccupation.length > 0;
    this.hasAvailableBuildings = this.buildings
      .filter(b => !b.validBuyoutOpinionBuildings)
      .some(b => this.parcelsWithPermanentOccupation.every(p => !p.building || p.building.budId !== b.budId));
    this.loading = false;
  }

  onUpdateOpinion(opinion: OpinionModel) {
    const params = { opinionId: opinion.id, occupationType: opinion.occupationType, id: this.titleId };

    switch (opinion.priceType) {
      case 'E':
        this.stateService.go('symap.project.titles.detail.pricing.expertOpinion', params);
        break;
      case 'O':
        this.stateService.go('symap.project.titles.detail.pricing.otherSource', params);
        break;
      case 'P':
        this.stateService.go('symap.project.titles.detail.pricing.preliminary-opinion', params);
        break;
    }
  }

  private loadTitle(): Promise<any> {
    return this.restangular
      .one('titles', this.titleId)
      .get({ loadCollections: [ 'ownerships', 'ownerships.subjects' ]})
      .toPromise()
      .then(res => { this.title = res.plain(); });
  }

  private loadOpinions(): Promise<any> {
    return this.opinionService
      .getOpinionsForTitle(this.titleId)
      .then(res => {
        this.permanentOpinions = res.filter(opinion => opinion.occupationType === 'P');
        this.temporaryOpinions = res.filter(opinion => opinion.occupationType !== 'P');
      });
  }

  private loadParcelsWithPermanentOccupation(): Promise<any> {
    return this.opinionService.getParcels({
      filters: {
        titleId: [ this.titleId ],
        validity: [ 'valid' ],
        permanentOccupationExists: [ true ],
      },
    }).then(res => this.parcelsWithPermanentOccupation = res);
  }

  private loadParcelsWithTemporaryOccupation(): Promise<any> {
    return this.opinionService.getParcels({
      filters: {
        titleId: [ this.titleId ],
        validity: [ 'valid' ],
        temporaryOccupationExists: [ true ],
      },
    }).then(res => this.parcelsWithTemporaryOccupation = res);
  }

  private loadBuildings(): Promise<any> {
    return this.opinionService.getBuildings({
      filters: {
        titleId: [ this.titleId ],
        validity: [ 'valid' ],
        occupationOrEasementExists: [ true ],
        differentTitle: [ true ],
      },
    }).then(res => this.buildings = res);
  }
}
