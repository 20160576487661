﻿import { Component, Input, Output, EventEmitter } from "@angular/core";
import * as _ from 'lodash';

@Component({
  selector: 'construction-object-detail-create-case-price-case-ownership',
	templateUrl: './create-case-price-case-ownership.component.html',
})
export class CreateCasePriceCaseOwnershipComponent {
  @Input()
  public flatCaseOwnerships: any[];
  @Input()
  public easementPriceByShare: boolean;
  @Input()
  public businessCase: any;
  @Output()
  private easementPriceByShareUpdated = new EventEmitter();
  public mappedCaseOwnerships: any[];
}
