import { Component, Input, OnInit } from '@angular/core';

import { TabModel } from '@app/common/models/tab.model';

@Component({
  selector: 'landing-di',
  templateUrl: './landing-di.component.html',
  styleUrls: ['./landing-di.component.scss'],
})
export class LandingDiComponent implements OnInit {
  @Input() activeTab: string;

  tabs: TabModel[] = [{
    name: 'Podmínky použití',
    id: 'terms-of-use',
    href: 'settings.modules.dimap',
    urlParams: { tab: 'terms-of-use' },
  }];

  constructor() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  isActiveTab(tabId: string): boolean {
    return tabId === this.activeTab;
  }
}
