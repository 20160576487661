import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {

  public updateSeries(seriesParsed) {
    const attributes = ['restart', 'short', 'from', 'to', 'show'];
    let position = 0;
    let lastTypeText = false;
    seriesParsed.series.format = this.generateFormat(seriesParsed.sections);
    seriesParsed.sections.forEach((section) => {
      if (section.type === 'text') {
        if (section.show && section.text && !lastTypeText) {
          lastTypeText = true;
          position++;
        }
        return;
      }
      lastTypeText = false;
      position++;
      attributes.forEach((attrName) => {
        if (section[attrName] !== undefined) {
          seriesParsed.series[section.type + attrName.charAt(0).toUpperCase() + attrName.slice(1)] = section[attrName];
        }
      });
      seriesParsed.series[section.type + 'Position'] = position;
    });
  }

  public parseSeries(seriesList, seriesParsed) {
    seriesList.forEach((series) => {
      seriesParsed.push(this.parseSingleSeries(series));
    });
  }

  public parseSingleSeries(series) {
    const sections = [{
      type: 'text'
    }];
    const myRegexp = /([^%]+)?(?:%(?:0([0-9]+))?(\w))?/g;
    let match = myRegexp.exec(series.format);
    let position = 1;
    let groups = ['year', 'order', 'title'];
    while (match[0] || groups.length > 0) {
      let section;
      let group = null;
      for (const currentGroup of groups) {
        if (position === series[currentGroup + 'Position']) {
          group = currentGroup;
          break;
        } else if (!series[currentGroup + 'Position'] && !groups.some(currentGroup => series[currentGroup + 'Position'])) {
          group = currentGroup;
          break;
        }
      }

      position++;
      if (group) {
        groups = groups.filter(currentGroup => currentGroup !== group);
        section = this.createSection(group, series);
        sections.push(section);
        if (!series[group + 'Show']) {
          continue;
        }
      }
      if (match[1]) {
        section = this.createSection('text', series);
        section.text = match[1].replace(/%%/g, '%');
        section.show = true;
        sections.push(section);
        match[1] = null;
        continue;
      }
      if (match[3]) {
        section.padNumber = match[2];
        section.show = true;
      }
      match = !match[0] ? match : myRegexp.exec(series.format);
    }
    return {
      series: series,
      sections: sections
    };
  }

  public sectionsToSeries(seriesParsed, dataSeries) {
    let position = 1;
    seriesParsed.forEach((seriesParsed) => {
      this.updateSeries(seriesParsed);
      seriesParsed.series.seriesOrder = position++;
      if (!seriesParsed.series.id) {
        dataSeries.push(seriesParsed.series);
      }
    });
  }

  public advanceSeries(seriesParsed) {
    seriesParsed.series.orderCurrent = (this.getNaturalNumber(seriesParsed.series.orderCurrent) || 0) + 1;
    seriesParsed.series.nextNumber = this.generateNumber(seriesParsed, false);
  }

  public generateNumber(seriesParsed, advance = true) {
    let sectionPosition = 0;
    const titleNumber = '[LV]';
    let year = new Date().getFullYear();
    let order = (this.getNaturalNumber(seriesParsed.series.orderCurrent) || 0) + (advance ? 1 : 0);
    let orderRestart = false;
    seriesParsed.series.available = true;
    seriesParsed.sections.forEach((section) => {
      if (section.type === 'text') {
        return;
      } else if (section.type === 'order') {
        orderRestart = section.restart;
      }
    });

    if (seriesParsed.series.yearCurrent && year > seriesParsed.series.yearCurrent && orderRestart) {
      if (seriesParsed.series.orderRestarted) {
        seriesParsed.series.available = false;
        year = seriesParsed.series.yearCurrent;
      } else {
        order = 0;
      }
    }

    return this.generateFormat(seriesParsed.sections).replace(/%%/g, '%').replace(/%(?:0([0-9]+))?(\w)/g, ($0, $1) => {
      while (seriesParsed.sections[sectionPosition].type === 'text' || !seriesParsed.sections[sectionPosition].show) {
        sectionPosition++;
      }
      let num;
      if (seriesParsed.sections[sectionPosition].type === 'year') {
        num = year;
      } else if (seriesParsed.sections[sectionPosition].type === 'order') {
        num = order;
      } else {
        sectionPosition++;
        return titleNumber;
      }

      let tmp = this.getNaturalNumber(seriesParsed.sections[sectionPosition].from);
      if (tmp && num < tmp) {
        num = tmp;
      }

      tmp = this.getNaturalNumber(seriesParsed.sections[sectionPosition].to);
      if (tmp && num > tmp) {
        seriesParsed.series.available = false;
      }

      if (seriesParsed.sections[sectionPosition].short) {
        num = num % 100;
      }

      num += '';
      if ($1 && num.length < $1) {
        const pad = new Array(Math.min(100, ++$1) - num.length).join('0');
        num = pad + num;
      }
      sectionPosition++;
      return num;
    });
  }

  public getNaturalNumber(text) {
    return text && isFinite(text) && Math.round(Math.abs(text)) === text * 1 ? text * 1 : null;
  }

  public generateFormat(sections) {
    let format = '';
    sections.forEach((item) => {
      if (!item.show) {
        return;
      }
      if (item.type === 'text' && item.text) {
        format += item.text.replace(/%/g, '%%');
      } else if (item.type === 'title') {
        format += '%s';
      } else if (item.type === 'year') {
        format += '%' + (item.short ? '02' : '') + 'd';
      } else if (item.type === 'order') {
        const padNumber = this.getNaturalNumber(item.padNumber);
        format += '%' + (padNumber ? '0' + padNumber : '') + 'd';
      }
    });

    return format;
  }

  public createSection(type, series) {
    return {
      type: type,
      show: false,
      restart: series[type + 'Restart'],
      short: series[type + 'Short'],
      from: series[type + 'From'],
      to: series[type + 'To']
    };
  }
}
