import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiSceneCloudService {
  public yaw;
  public pitch;

  constructor() {
   this.yaw = 4.8; // seems to be the front direction
   this.pitch = 0;
  }
}
