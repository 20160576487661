<div class="detail">
  <div style="display:flex">
    <div class="line-height-l" style="min-width: 120px;">
      <a *ngIf="type === 'case'" uiSref="symap.project.cases.detail" [uiParams]="{ id: data.entity.id }" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět na případ</a>
      <a *ngIf="type === 'common'" uiSref="symap.project.map" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět do mapy</a>
    </div>
  </div>

  <h1>Generování mapové přílohy</h1>

  <div class="print-container">
    <div *ngIf="data && step === 1">
      <fieldset>
        <legend>Nastavení tisku</legend>
        <div class="column-100">
          <label>Uživatelské nastavení tisku</label>
          <div class="info">{{ storeName ? storeName : '-' }}</div>

          <label>Formát</label>
          <div class="info">{{ data.formatType.name }}</div>

          <label>Měřítko</label>
          <div class="info">1 : {{ data.measureType.denominator }}</div>

          <label [ngClass]="{ required: type === 'case' }">Příloha č.</label>
          <div class="info">
            <input type="text" [(ngModel)]="attachmentName" (ngModelChange)="documentName = 'příloha_' + $event">
          </div>

          <label>Popis nad mapou</label>
          <div class="info">
            <a (click)="onEditDescription('descriptionBefore')"><i class="fa fa-pencil"></i></a>
          </div>

          <ng-container *ngIf="data.entity?.type === 'businessCase'">
            <label>Vlastník</label>
            <div class="info">
              <gmt-checkbox [gmtChecked]="showOwnership" (click)="showOwnership = !showOwnership"></gmt-checkbox>
              <a (click)="onEditDescription('ownership')" class="pl-5"><i class="fa fa-pencil"></i></a>
            </div>

            <label>Číslo případu</label>
            <div class="info">
              <gmt-checkbox [gmtChecked]="showCaseNumber" (click)="showCaseNumber = !showCaseNumber"></gmt-checkbox>
            </div>
          </ng-container>

          <label>Legenda</label>
          <div class="info">
            <a (click)="onEditLayers()"><i class="fa fa-pencil"></i></a>
          </div>

          <label>Popis pod mapou</label>
          <div class="info">
            <a (click)="onEditDescription('descriptionAfter')"><i class="fa fa-pencil"></i></a>
          </div>
        </div>
      </fieldset>

      <div class="mt-30" *ngIf="previewLoading !== undefined">
        <h2>Náhled</h2>
        <div id="mapPreview" [loading]="previewLoading">
          <object data="about:blank" type="text/html" id="mapPreviewObj" style="width: 100%;" [ngStyle]="{ height: computePreviewHeight() }" ></object>
        </div>
      </div>

      <div class="mt-30">
        <a *ngIf="type === 'case'" uiSref="symap.project.map" [uiParams]="{ businessCase: data.entity.id, tool: 'print-case' }" class="button">Znovu umístit výřez</a>
        <a *ngIf="type === 'common'" uiSref="symap.project.map" [uiParams]="{ tool: 'print' }" class="button">Znovu umístit výřez</a>

        <gmt-button [gmtDisabled]="!isFirstStepValid()" [clickAction]="onPreview">Zobrazit náhled</gmt-button>
        <gmt-button [gmtDisabled]="!isFirstStepValid()" [clickAction]="onSecondStep">Tisk</gmt-button>
        <a uiSref="^" class="button red">Zrušit</a>
      </div>
    </div>

    <div *ngIf="step === 2" class="print-container">
      <fieldset>
        <legend>Tisk</legend>
        <div class="column-100">
          <gmt-input label="Uložit nastavení tisku">
            <gmt-checkbox (click)="store = !store; checkStoreExists(storeName)" [gmtChecked]="store"></gmt-checkbox>
          </gmt-input>

          <gmt-input *ngIf="store" label="Pojmenování uloženého nastavení" [required]="true">
            <input type="text" [(ngModel)]="storeName" (ngModelChange)="checkStoreExists($event)">
            <span *ngIf="storeExists" class="error-message"> Dojde k aktualizaci existujícího nastavení</span>
          </gmt-input>

          <gmt-input label="Název souboru" [required]="true">
            <input type="text" [(ngModel)]="documentName">
          </gmt-input>
        </div>
      </fieldset>

      <div class="mt-30">
        <a (click)="step = 1" class="button">Zpět</a>

        <gmt-button [gmtDisabled]="!isSecondStepValid()" [clickAction]="onPrintPdf">PDF</gmt-button>
        <gmt-button [gmtDisabled]="!isSecondStepValid() || data.formatType.id !== 'A4'" [clickAction]="onPrintDocx">Docx</gmt-button>
        <gmt-button [gmtDisabled]="!isSecondStepValid()" [clickAction]="onPrintPng">PNG (pouze mapová část)</gmt-button>

        <a *ngIf="type === 'case'" uiSref="symap.project.cases.detail" [uiParams]="{ id: data.entity.id }" class="button red mb-10">Zrušit</a>
        <a *ngIf="type === 'common'" uiSref="symap.project.map" class="button red mb-10">Zrušit</a>
      </div>
    </div>

    <div *ngIf="!data" class="jumbotron error-message">
      Nepodařilo se načíst nastavení tisku.
    </div>
  </div>
</div>
