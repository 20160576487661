import { Component, Input } from '@angular/core';

export interface TooltipVfzeProblemsComponentData {
  problems: VfzeProblemModel[];
}

@Component({
  selector: 'tooltip-vfze-problems',
  templateUrl: './tooltip-vfze-problems.component.html',
})
export class TooltipVfzeProblemsComponent {
  @Input() data: TooltipVfzeProblemsComponentData;
}
