﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '@app/models/user';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  @Input() user: User;
  @Input() withCompany = true;
  @Input() servicedeskUrl: string;
  @Output() logoutClick: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  logout(event) {
    this.logoutClick.emit(null);
  }
}
