import { StateParams } from '@uirouter/angular';
import { CallbackModel } from '@app/common/models/callback.model';

export function resolveId(stateParams: StateParams): number {
  return stateParams.id;
}

export function resolveCallbacks() {
  return new CallbackModel();
}

export function resolveTrue(): boolean {
  return true;
}

export function updateBody(module) {
  $('body').removeClass ((index, className) => {
    return (className.match(/(^|\s)mod-\S+/g) || []).join(' ');
  });

  $('body').addClass('mod-' + module);
}

