import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullstreet'
})
@Injectable({
  providedIn: 'root'
})
export class FullStreetPipe implements PipeTransform {

  transform(subject: any): string {

    if (!subject) {
      return '';
    }

    let fullStreet = '';

    if (subject.street) {
      fullStreet += subject.street;
    } else if (subject.neighbourhood && subject.neighbourhood !== subject.municipality) {
      fullStreet += subject.neighbourhood;
    }

    if (subject.houseNumber) {
      if (!fullStreet) {
        fullStreet += subject.cpCe === 2 ? 'č.e.' : 'č.p.';
      } else if (subject.cpCe === 2) {
        fullStreet += ' č.e.';
      }

      fullStreet += ' ' + subject.houseNumber;
    }

    fullStreet += !subject.streetNumber ? '' : '/' + subject.streetNumber;

    return fullStreet;
  }
}
