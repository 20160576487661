import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rentalType'
})
@Injectable({
  providedIn: 'root'
})
export class RentalTypePipe implements PipeTransform {

  transform(rentalType: string): string {
    switch (rentalType) {
      case 'L':
        return 'Pronájem';
      case 'T':
        return 'Pacht';
      default:
        return '';
    }
  }
}
