import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({
  name: 'projectSubjectTypeName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class ProjectSubjectTypeNamePipe implements PipeTransform {

  transform(type: any) {
    if (type === 'OPO') {
      return 'Právnická osoba';
    }
    if (type === 'OPO_OSS') {
      return 'Organizační složka státu';
    }
    if (type === 'OPO_PO') {
      return 'Příspěvková organizace';
    }
    if (type === 'OFO') {
      return 'Fyzická osoba';
    }
    return '';
  }
}
