import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { PostCodePipe } from '@app/common/pipes/post-code.pipe';

@Directive({
  selector: '[gmtInputPostCode]'
})
export class InputPostCodeDirective implements OnInit {

  constructor(
    private postCodePipe: PostCodePipe,
    private el: ElementRef
  ) {
    this.format = this.format.bind(this);
  }

  @Input() gmtInputNumber: boolean;

  ngOnInit() {
    setTimeout(() => {
      if (this.el.nativeElement.value !== '') {
        this.format();
      }
    });
  }

  @HostListener('blur') onBlur() {
    this.format();
  }

  private format() {
    this.el.nativeElement.value = this.postCodePipe.transform(this.el.nativeElement.value);
  }
}
