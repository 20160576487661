import {Pipe, PipeTransform} from "@angular/core";

export type DaysDiffPipeParamType = string | number;

@Pipe({
  name: 'daysdiff'
})
export class DaysDiffPipe implements PipeTransform {

  transform(date1: DaysDiffPipeParamType, date2: DaysDiffPipeParamType): string {
    let text = '';

    if (!date1 || !date2) {
      return text;
    }

    const one = new Date(date1).getTime();
    const two = new Date(date2).getTime();

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisBetween = one - two;
    const days = Math.ceil(millisBetween / millisecondsPerDay);

    switch (Math.abs(days)) {
      case 1: text = "den";
        break;
      case 2:
      case 3:
      case 4:
        text = "dny";
        break;
      default: text = "dní";
    }
    return (days < 0 ? 0 : days) + " " + text;
  }
}
