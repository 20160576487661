﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { TitleModel } from '../models/title.model';
import { UserNamePipe } from './username.pipe';

@Pipe({ name: 'titleUserUniqueNames' })
@Injectable({
  providedIn: 'root'
})
export class TitleUserUniqueNamesPipe implements PipeTransform {

  constructor(
      private userNamePipe: UserNamePipe,
  ) {}

  transform(titles: TitleModel[]) {
    const uniqueUsers = [];
    titles.forEach((title) => {
      if (!title.user && !uniqueUsers.includes(null)) {
        uniqueUsers.push(null);
      } else if (title.user && !uniqueUsers.find((u) => u.id === title.user.id)) {
        uniqueUsers.push(title.user);
      }
    });
    return uniqueUsers.length > 0
      ? uniqueUsers.map((user) => user ? this.userNamePipe.transform(user) : 'nezvoleno').join(', ')
      : '';
  }
}
