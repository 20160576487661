import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DocumentModel } from '../../models/document.model';
import { DocumentTypeModel } from '../../models/document-type.model';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';
import { DocumentTypeCategoryEnum } from '@app/sv/enums/document-type-category.enum';

@Component({
  selector: 'document-edit-form',
  templateUrl: './document-edit-form.component.html',
})
export class DocumentEditFormComponent implements OnInit {

  // model
  loading: boolean;
  allowType: boolean;
  document: DocumentModel;
  documentTypes: DocumentTypeModel[];
  activeDocumentType: DocumentTypeModel;
  initialDocumentType: DocumentTypeModel;

  constructor(
      @Inject(APP_BRAND) public APP_BRAND: any,
      private restangular: Restangular,
      private dialogConfig: DialogConfig,
      public dialogComponent: DialogRef,
  ) {
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    const data = this.dialogConfig.data as DialogConfigData;
    const documentCategory = data.documentCategory;
    this.allowType = data.allowType;
    this.document = data.document;
    this.initialDocumentType = DocumentTypeModel.createById(this.document.type);
    this.activeDocumentType = _.cloneDeep(this.initialDocumentType);
    this.documentTypes = _.cloneDeep(DocumentTypeModel.getByCategory(documentCategory));
    if (this.APP_BRAND.NAME == 'RSD') {
      _.remove(this.documentTypes, (i) => i.id == DocumentTypeCategoryEnum.INFORMATION_LETTER);
    }

    // check disabled types
    for (const disabledDocumentType of (data.disabledDocumentTypes || [])) {
      const found = this.documentTypes.find(dt => dt.id === disabledDocumentType.documentType);
      if (found) {
        found.disabled = disabledDocumentType.reason;
      }
    }
  }

  onUpdateType(documentType: DocumentTypeModel) {
    this.document.type = documentType.id;
    this.activeDocumentType = documentType;
  }

  onUpdate() {
    this.loading = true;
    const restDocument = this.restangular.restangularizeElement(null, this.document, `attachments`);

    return restDocument.put().toPromise().then(data => {
      this.loading = false;
      this.dialogComponent.close(data.plain());
    });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }

  isValid() {
    return this.document.baseName && this.document.type && (!this.activeDocumentType.extensions || this.activeDocumentType.extensions.includes(this.document.extension));
  }
}
