<div class="detail">
  <h1>Generování VFZE</h1>

  <div>
    <fieldset >
      <legend>Generování VFZE pro projekt</legend>
      <gmt-input label="Verze" [required]="true" *ngIf="config.BACKEND_OPTIONS.restUrlVFZEOld">
        <div class="checklist">
        <div class="checklist-item"  (click)="setOldVersion(false)">
        <gmt-radio [gmtChecked]="!oldVersion"></gmt-radio>
          Aktuální verze
        </div>
        <div class="checklist-item"  (click)="setOldVersion(true)">
        <gmt-radio [gmtChecked]="oldVersion"></gmt-radio>
          Předchozí verze
        </div></div>
        <div class="bordered p-10 flexbox-container flex-column mt-10" [loading]="!version">
          <div *ngIf="version" class="column-100"><label>Zvolená verze VFZE</label> <div class="info">{{version.vfzeVersion}}</div></div>
          <div *ngIf="version" class="column-100"><label>Název databázového schema</label> <div class="info">{{version.schemaName}}</div></div>
        </div>
      </gmt-input>
      <gmt-input label="Projekt" [required]="true">
        <gmt-select
          [data]="this"
          field="project"
          resource="projects"
          selectTitle="Vyberte projekt"
          [itemPipe]="projectNamePipe"
          [restangularService]="restangularService"
          [reloadOptionsRegister]="setReloadOptionRegister"
        ></gmt-select>
      </gmt-input>
    </fieldset>

    <gmt-button [gmtDisabled]="!project" [clickAction]="onGenerate">Generovat</gmt-button>
  </div>
</div>
