<fieldset [loading]="!options">
  <legend>Umisťované součásti distribuční soustavy</legend>
  <div class="column-100">
    <div class="comma-list info">
      <span *ngIf="!parts?.length">-</span>
      <span *ngFor="let part of parts">{{part.name}} <a class="fa fa-close" (click)="removePart(part)"></a></span>
    </div>
  </div>

  <div *ngIf="options" class="cb">
    <gmt-select
      (changed)="onSelectedPartChange($event)"
      [filter]="filter"
      [optionItems]="options"
      selectTitle="Vložte text SDS"
      [displayInput]="true"
      [reloadOptionsRegister]="addOnOptionsReloadCallback"
      (searchTextChange)="onNewPartNameChange($event)"
    >
    </gmt-select>

    <gmt-button
      title="Přidat součást distribuční soustavy"
      [clickAction]="createPart"
    >
      <span class="fa fa-plus"></span>
    </gmt-button>
  </div>
</fieldset>
