import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { ListService } from '@app/common/services/list.service';
import { RESTANGULAR_PROMAP } from '@app/common/services/restangular-promap.service';

import { CadastreImportModel } from '@app/settings/modules/models/cadastre-import.model';

@Component({
  selector: 'cadastre-data-import-list',
  templateUrl: './cadastre-data-import-list.component.html',
  styleUrls: ['./cadastre-data-import-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CadastreDataImportListComponent implements OnInit {
  @Input()
  set newImport(newImport: CadastreImportModel) {
    if (newImport) {
      this.onCadastreDataImportAdded(newImport);
    }
  }

  list: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private listService: ListService,
    @Inject(RESTANGULAR_PROMAP) private restangularPromap,
  ) { }

  ngOnInit() {
    this.onCadastreDataImportAdded = this.onCadastreDataImportAdded.bind(this);
    this.getLastFiveImports();
  }

  private onCadastreDataImportAdded(dataImport: CadastreImportModel) {
    this.list.list.unshift(dataImport);
    this.list.list.pop();
    this.cdr.markForCheck();
  }

  private getLastFiveImports() {
    const filters = {
      sortOrder: [{
        sortBy: 'id',
        direction: 'desc'
      }],
      limit: 5,
      offset: 0,
    };
    this.list = this.listService.createList('cuzk/data-import', filters, this.restangularPromap, undefined);
    this.listService.fetchResult(this.list)
      .finally(() => {
        this.list.loading = false;
        this.cdr.markForCheck();
      });
  }
}
