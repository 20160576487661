import { Component, Inject, OnInit } from '@angular/core';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'modules-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
  }

  ngOnInit(): void {
  }
}
