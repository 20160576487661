export enum FailedReasonEnum {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  AREA_TOO_LARGE = 'AREA_TOO_LARGE',
  INCOMPLETE_DATA = 'INCOMPLETE_DATA',
  NOT_DIGITALIZED_MAP = 'NOT_DIGITALIZED_MAP',
  INVALID_GEOMETRY = 'INVALID_GEOMETRY',
  INVALID_GEOMETRY_MORE_THAN_ROW = 'INVALID_GEOMETRY_MORE_THAN_ROW',
  INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY = 'INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY',
  INVALID_GEOMETRY_HOLE_IN_GEOMETRY = 'INVALID_GEOMETRY_HOLE_IN_GEOMETRY',
  INVALID_GEOMETRY_OR_NOT_POLYGON = 'INVALID_GEOMETRY_OR_NOT_POLYGON',
  CTIOS_WRONG_CREDENTIALS = 'CTIOS_WRONG_CREDENTIALS',
  CTIOS_EXPIRED_CREDENTIALS = 'CTIOS_EXPIRED_CREDENTIALS',
  MISSING_SUBJECT = 'MISSING_SUBJECT',
  CTIOS_UNAVAILABLE = 'CTIOS_UNAVAILABLE',
  PRIDATNE_SPOLUVLASTNICTVI = 'PRIDATNE_SPOLUVLASTNICTVI',
  INVALID_GEOMETRY_NARROW = 'INVALID_GEOMETRY_NARROW'
}
