import { Component, Inject, OnInit } from '@angular/core';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { User } from '@app/models/user';
import { AuthService } from '@app/common/services/auth.service';
import { SelectItem } from '@app/common/components/select/select.component';
import {
  DEFAULT_CELL_OPTIONS,
  DEFAULT_CELL_OPTIONS_H_CENTER,
  XlsxService
} from '@app/common/services/xlsx.service';
import { DatePipe } from '@angular/common';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'user-list',
  styleUrls: ['./user-list.component.scss'],
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {

  list: ListModel<User>;
  pageableList: PageableList;
  module = this.authService.getActiveApplication();
  projectKey: string;
  roles: SelectItem[];
  helpIds = HelpService.HELP_IDS;

  private static readonly COLUMN_NAMES = [
    'Příjmení uživatele',
    'Jméno uživatele',
    'Organizace',
    'Přihlašovací jméno',
    'E-mail',
    'Poslední přihlášení',
  ];

  private static readonly COLUMN_FIELDS = [
    'surname',
    'name',
    'companyName',
    'username',
    'email',
    'lastLogin'
  ];

  private static readonly COLUMN_OPTIONS = [
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
  ];

  private static readonly COLUMN_EXTENDED_NAMES = [
    'Příjmení uživatele',
    'Jméno uživatele',
    'Organizace',
    'Přihlašovací jméno',
    'E-mail',
    'Poslední přihlášení',
    'Přiřazené role',
    'Přiřazená akce k roli - číslo',
    'Přiřazená akce k roli - název',
  ];

  private static readonly COLUMN_EXTENDED_FIELDS = [
    'surname',
    'name',
    'companyName',
    'username',
    'email',
    'lastLogin',
    'roleName',
    'roleProjectIsprofond',
    'roleProjectName',
  ];

  private static readonly COLUMN_EXTENDED_OPTIONS = [
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
  ];

  private static readonly RSD_COLUMN_NAMES = [
    'Příjmení uživatele',
    'Jméno uživatele',
    'Číslo uživatele',
    'Organizace',
    'Název organizační jednotky',
    'Číslo organizační jednotky',
    'Přihlašovací jméno',
    'E-mail',
    'Poslední přihlášení',
  ];

  private static readonly RSD_COLUMN_FIELDS = [
    'surname',
    'name',
    'employeePersonalNumber',
    'ssoCompanyName',
    'departmentName',
    'departmentId',
    'username',
    'email',
    'lastLogin'
  ];

  private static readonly RSD_COLUMN_OPTIONS = [
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
  ];

  private static readonly RSD_COLUMN_EXTENDED_NAMES = [
    'Příjmení uživatele',
    'Jméno uživatele',
    'Číslo uživatele',
    'Organizace',
    'Název organizační jednotky',
    'Číslo organizační jednotky',
    'Přihlašovací jméno',
    'E-mail',
    'Poslední přihlášení',
    'Přiřazené role',
    'Přiřazená akce k roli - číslo',
    'Přiřazená akce k roli - název',
  ];

  private static readonly RSD_COLUMN_EXTENDED_FIELDS = [
    'surname',
    'name',
    'employeePersonalNumber',
    'ssoCompanyName',
    'departmentName',
    'departmentId',
    'username',
    'email',
    'lastLogin',
    'roleName',
    'roleProjectIsprofond',
    'roleProjectName',
  ];

  private static readonly RSD_COLUMN_EXTENDED_OPTIONS = [
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
    DEFAULT_CELL_OPTIONS_H_CENTER,
    DEFAULT_CELL_OPTIONS,
  ];

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public globalRestangular: any,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private rolePipe: RolePipe,
    private authService: AuthService,
    private pageableListService: PageableListService,
    private xlsxService: XlsxService,
    private datePipe: DatePipe,
  ) {
    this.export = this.export.bind(this);
    this.exportExtended = this.exportExtended.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  export(): Promise<any> {
    return this.globalRestangular.one('export/active-users').get().toPromise().then(rest => {
      const usersData = rest.plain();

      const header = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_NAMES : UserListComponent.COLUMN_NAMES;
      const columnIds = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_FIELDS : UserListComponent.COLUMN_FIELDS;
      const cellOpinions = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_OPTIONS : UserListComponent.COLUMN_OPTIONS;

      const body = [];

      for (const user of usersData) {
        const row = [];
        for (const id of columnIds) {
          if (id === 'lastLogin') {
            row.push(this.datePipe.transform(user[id], 'mediumDate'));
          } else {
            row.push(user[id]);
          }
        }
        body.push(row);
      }

      const data = [header].concat(body);

      this.xlsxService.createFile(
        data,
        'export.xlsx',
        undefined,
        cellOpinions
      );
    });
  }

  exportExtended() {
    return this.globalRestangular.one('export/active-users-roles').get().toPromise().then(rest => {
      const usersData = rest.plain();

      // flat data
      const flattenData = [];

      for (const user of usersData) {
        const roles = this.APP_BRAND.NAME === 'RSD' ? user.ssoRoles : user.roles;
        delete user.roles;
        delete user.ssoRoles;

        if (roles && roles.length > 0) {
          for (const role of roles) {
            const userRow = { ...user };
            userRow.roleName = this.rolePipe.transform(role.role);
            userRow.roleProjectIsprofond = role.isprofond;
            userRow.roleProjectName = role.projectName;
            flattenData.push(userRow);
          }
        } else {
          flattenData.push(user);
        }
      }

      const header = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_NAMES : UserListComponent.COLUMN_EXTENDED_NAMES;
      const columnIds = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_FIELDS : UserListComponent.COLUMN_EXTENDED_FIELDS;
      const cellOpinions = this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_OPTIONS : UserListComponent.COLUMN_EXTENDED_OPTIONS;

      const body = [];

      for (const user of flattenData) {
        const row = [];
        for (const id of columnIds) {
          if (id === 'lastLogin') {
            row.push(this.datePipe.transform(user[id], 'mediumDate'));
          } else {
            row.push(user[id]);
          }
        }
        body.push(row);
      }

      const data = [ header ].concat(body);

      this.xlsxService.createFile(
        data,
        'export-extended.xlsx',
        undefined,
        cellOpinions
      );
    });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'users/list',
      this.getDefaultFilter(),
      'globalUsersFilter',
      this.globalRestangular
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: {
          values: [{ id: '' }]
        },
        lastLoginNotEmpty: {
          values: [true]
        },
        applications: {
          values: [
            this.APPLICATIONS.sy.name,
            this.APPLICATIONS.sv.name,
            this.APPLICATIONS.dashboard.name,
          ]
        },
      },
    };
  }
}
