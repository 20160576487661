<div class="mb-10" *ngIf="data.cases.length > 1">
  <gmt-button [gmtDisabled]="businessCaseIndex === 0" [clickAction]="previous">Předchozí případ</gmt-button>
  <span class="pl-20 pr-20"><strong>{{businessCaseIndex + 1}} / {{data.cases.length}}</strong></span>
  <gmt-button [gmtDisabled]="businessCaseIndex + 1 === data.cases.length" [clickAction]="next">Následující případ</gmt-button>
</div>

<h2>LV <span class="comma-list"><span *ngFor="let title of data.cases[businessCaseIndex].titles">{{title.lv}}</span></span></h2>
<div [loading]="data.cases[businessCaseIndex].totalEasementPrice === null">
  <div *ngIf="data.cases[businessCaseIndex].totalEasementPrice != null">
    <div class="bordered">
      <div class="table">
        <div class="head-group">
          <div class="row">
            <div class="cell">Parcela</div>
            <div class="cell">Geometrický plán</div>
            <div class="cell">Plocha VB [<span class="unit">m<sup>2</sup></span>]</div>
            <div class="cell">Délka VB [<span class="unit">m</span>]</div>
            <div class="cell" *ngIf="!easementPriceByShare && data.obligation.computePrice">Cena [<span class="unit">Kč</span>]</div>
          </div>
        </div>
        <div class="row-group">
          <div class="row" *ngFor="let easement of data.cases[businessCaseIndex].easements">
            <div class="cell no-padding">
              <div class="fill-content">
                <cell-parcel [parcel]="easement.parcel" [tooltipData]="{parcel: easement.parcel}"></cell-parcel>
              </div>
            </div>
            <div class="cell">
              {{easement.geometricPlan.cis}}
            </div>
            <div class="cell number">
              {{easement.vymera}}
            </div>
            <div class="cell number">
              {{easement.delka}}
            </div>
            <div class="cell number" *ngIf="!easementPriceByShare && data.obligation.computePrice">
              {{easement.easementPrice|number}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bordered mt-20">
      <construction-object-detail-create-case-price-case-ownership [businessCase]="data.cases[businessCaseIndex]" (easementPriceByShareUpdated)="easementPriceByShareUpdated(data.cases[businessCaseIndex])" [easementPriceByShare]="easementPriceByShare" [flatCaseOwnerships]="flatCaseOwnerships[businessCaseIndex]">

      </construction-object-detail-create-case-price-case-ownership>
    </div>

    <distribution-system-parts
      [options]="distributionSystemPartsOptions"
      [parts]="data.cases[businessCaseIndex].distributionSystemParts"
      *ngIf="showEasementDistributionSystemParts"
      class="mt-20 block"
    ></distribution-system-parts>

    <div class="clearfix mt-20" *ngIf="easementPriceByShare || data.obligation.computePrice">
      <div class="fieldset fr no-legend">
        <strong>Cena za LV {{data.cases[businessCaseIndex].totalEasementPrice|number|price}}</strong>
      </div>
    </div>

    <div class="mt-20">
      <gmt-labeled-block [collapsed]="true" [label]="'Omezení vlastnického práva' + (restrictions[businessCaseIndex] ? ' (' + restrictions[businessCaseIndex].length + ')' : '')">
        <div class="bordered" [loading]="!dataOtherLegalRelations || dataOtherLegalRelations.loading">
          <div *ngIf="restrictions[businessCaseIndex]">
            <div *ngIf="restrictions[businessCaseIndex].length === 0" class="empty-info">Žádná omezení</div>
            <table *ngIf="restrictions[businessCaseIndex].length > 0">
              <tbody>
              <tr *ngFor="let itemRestriction of restrictions[businessCaseIndex]">
                <td><b>{{itemRestriction.nazev}}</b>{{itemRestriction.popisPravnihoVztahu ? ' (' + itemRestriction.popisPravnihoVztahu + ')' : ''}}
                  <div *ngIf="itemRestriction.subjectsTo.length || itemRestriction.parcelTo || itemRestriction.buildingTo">
                    K: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsTo">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemRestriction.parcelTo">{{itemRestriction.parcelTo|parcelnumber}}</span>
                    <span *ngIf="itemRestriction.buildingTo">stavba {{itemRestriction.buildingTo | buildingNumber}} na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemRestriction.buildingTo.parcels">{{itemParcel|parcelnumber}}{{itemParcel.soucasti ? ' (součástí)' : ''}}</span></span></span>
                  </span>
                  </div>
                  <div *ngIf="itemRestriction.subjectsFor.length || itemRestriction.parcelFor">
                    Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsFor">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemRestriction.parcelFor">p.č. {{itemRestriction.parcelFor|parcelnumber}}</span></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </gmt-labeled-block>
    </div>

    <div class="mt-20">
      <gmt-labeled-block [collapsed]="true" [label]="'Jiné zápisy' + (otherEntries[businessCaseIndex] ? ' (' + otherEntries[businessCaseIndex].length + ')' : '')">
        <div class="bordered" [loading]="!dataOtherLegalRelations || dataOtherLegalRelations.loading">
          <div *ngIf="otherEntries[businessCaseIndex]">
            <div *ngIf="otherEntries[businessCaseIndex].length === 0" class="empty-info">Žádné zápisy</div>
            <table *ngIf="otherEntries[businessCaseIndex].length > 0">
              <tbody>
              <tr *ngFor="let itemOtherEntry of otherEntries[businessCaseIndex]">
                <td>
                  <b>{{itemOtherEntry.nazev}}</b>{{itemOtherEntry.popisPravnihoVztahu ? ' (' + itemOtherEntry.popisPravnihoVztahu + ')' : ''}}
                  <div *ngIf="itemOtherEntry.subjectsTo.length || itemOtherEntry.parcelTo || itemOtherEntry.buildingTo">
                    K: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsTo">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemOtherEntry.parcelTo">p.č. {{itemOtherEntry.parcelTo|parcelnumber}}</span></span>
                    <span *ngIf="itemOtherEntry.buildingTo">stavba {{itemOtherEntry.buildingTo | buildingNumber }} na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemOtherEntry.buildingTo.parcels">{{itemParcel|parcelnumber}}{{itemParcel.soucasti ? ' (součástí)' : ''}}</span></span></span>
                  </div>
                  <div *ngIf="itemOtherEntry.subjectsFor.length || itemOtherEntry.parcelFor">
                    Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsFor">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemOtherEntry.parcelFor">p.č. {{itemOtherEntry.parcelFor|parcelnumber}}</span></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </gmt-labeled-block>
    </div>

    <fieldset class="mt-20">
      <div class="column-100" *ngIf="seriesData.length">
        <label>Číselná řada</label>
        <div class="input"><gmt-select [data]="data.cases[businessCaseIndex]" field="series" [optionItems]="seriesData" [itemPipe]="seriesNamePipe" (changed)="refreshSeriesNumbers(businessCaseIndex)"></gmt-select></div>
      </div>

      <div class="column-100" *ngIf="data.cases[businessCaseIndex].series">
        <label>Předpokládané číslo</label>
        <div class="info" *ngIf="data.cases[businessCaseIndex].series">{{generatedSeriesNumbers[businessCaseIndex] !== null ? generatedSeriesNumbers[businessCaseIndex] : 'mimo rozsah číselné řady'}}</div>
      </div>

      <div class="column-100" *ngIf="!data.cases[businessCaseIndex].series">
        <label>Číslo případu</label>
        <div class="input"><input type="text" [(ngModel)]="data.cases[businessCaseIndex].mpp.number"/></div>
      </div>
    </fieldset>
    <div class="cb"></div>
  </div>
</div>

<div class="mt-10" *ngIf="data.cases.length > 1">
  <gmt-button [gmtDisabled]="businessCaseIndex === 0" [clickAction]="previous">Předchozí případ</gmt-button>
  <span class="pl-20 pr-20"><strong>{{businessCaseIndex + 1}} / {{data.cases.length}}</strong></span>
  <gmt-button [gmtDisabled]="businessCaseIndex + 1 === data.cases.length" [clickAction]="next">Následující případ</gmt-button>
</div>

<div class="mt-10">
  <gmt-button [gmtDisabled]="loading" [clickAction]="submit">
    Další
  </gmt-button>
</div>
