﻿import {
  Component,
  Inject,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnDestroy,
  Injector
} from "@angular/core";
import { BulkOperationProcessDefinitionModel } from '../../models/bulk-operation-process-definition.model';
import { ListService } from '@app/common/services/list.service';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { CaseService } from '@app/ps/services/case.service';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';
import { actionDialogues } from '@app/ps/models/action-dialogues.model';

@Component({
  templateUrl: './cases-change-status.component.html',
  styleUrls: ['./cases-change-status.component.scss']
})
export class CasesChangeStatusComponent  implements OnDestroy, OnInit, AfterViewInit {
  @Input()
  data: any;
  @Input()
  notifyParent: Function;
  @ViewChild('statusDetails', {
    read: ViewContainerRef
  }) statusViewContainerRef: ViewContainerRef;

  inputData: any = {};
  list: any;
  countResolved: number = 0;
  statusComponent;
  allowAssignment: boolean = false;
  interrupt: boolean = false;

  constructor(
    private listService: ListService,
    private authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject('processDefinition') private processDefinition: BulkOperationProcessDefinitionModel,
    private caseStatusService: CaseStatusService,
    private restangular: Restangular,
    private caseService: CaseService,
    private injector: Injector
  ) {
    this.arrangeCases = this.arrangeCases.bind(this);
    this.setActiveNextStatus = this.setActiveNextStatus.bind(this);
  }

  ngOnInit() {
    this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
    this.inputData = this.data.details || {};
    if (!this.inputData.groups) {
      const entityIdFilter = this.data.selectEntities.filters.entityId;
      const filter = {
        limit: null,
        filters: Object.assign(
          transformListToFilter({filters: this.data.selectEntities.filters}).filters,
          {
            entityId: {
              values: entityIdFilter.length && this.data.selectEntities.inverseSelection ?
                entityIdFilter.filter(entityId => !this.data.selectEntities.checkedItems.some(entity => entity.id === entityId)) :
                (this.data.selectEntities.checkedItems.map(entity => entity.id)),
              negation: !entityIdFilter.length && this.data.selectEntities.inverseSelection,
            },
            loadCollections: ['titles'],
          }
        )
      };

      this.list = this.listService.createList('cases', filter);
      this.listService.fetchResult(this.list);
    } else {
      this.countResolved = this.data.selectEntities.count;
      const activeGroup = this.inputData.groups.find((group) => this.inputData.group && this.inputData.group.caseStatus.id === group.caseStatus.id);
      if (activeGroup) {
        this.setActiveNextStatus(activeGroup);
      }
    }
  }

  ngOnDestroy() {
    this.interrupt = true;
  }

  ngAfterViewInit() {
    if (!this.inputData.groups) {
      this.list.promise.then(async (cases) => {
        const data = await this.arrangeCases(cases);
        if (data) {
          this.inputData.groups = data.groups;
          this.inputData.noStatusGroup = data.noStatusGroup;
          this.inputData.allCases = data.allCases;
        }
      });
    }
  }

  async arrangeCases(cases: any): Promise<any> {
    const groups = [];
    const noStatusGroup = [];
    const allCases = [];
    for (const item of cases.list) {
      if (this.interrupt) {
        return false;
      }
      const nextCaseStauses = await this.loadSetting(item.id);
      this.countResolved++;

      allCases.push(item);

      if (nextCaseStauses.length === 0) {
         noStatusGroup.push(item);
         continue;
      }

      for (const caseStatus of nextCaseStauses) {
        let found = groups.find((group) => group.caseStatus.id === caseStatus.id);

        if (!found) {
          const settings = actionDialogues.find((button) => button.caseStatus === caseStatus.key);
          found = {
            caseStatus: caseStatus,
            cases: [],
            formComponent: settings ? settings.formComponent || null : undefined,
            saveEndpoint: settings ? settings.saveEndpoint : undefined,
          };
          groups.push(found);
        }

        found.cases.push(item);
      }
    }

    return {
      groups: groups,
      noStatusGroup: noStatusGroup,
      allCases: allCases,
    };
  }

  async loadSetting(caseId: number) {
    return await this.restangular.one('cases', caseId).one("setting").get()
      .toPromise()
      .then(async (data) => {
        const nextStates = data.nextStates.items;
        return await Promise.all(_.map(nextStates, async (stateKey) => {
          return await this.caseStatusService.loadStatusName(stateKey);
        }));
      });
  }

  setActiveNextStatus(group) {
    if (group.formComponent === undefined || (this.inputData.group === group && this.statusComponent)) {
      return;
    }
    if (this.inputData.group && this.inputData.group !== group) {
      this.inputData.case = {};
      this.inputData.cases = [];
    }
    this.inputData.case = this.inputData.case || {};
    this.inputData.cases = this.inputData.cases || [];
    this.inputData.group = group;
    this.statusViewContainerRef.clear();

    if (group.formComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(group.formComponent);
      this.statusComponent = factory.create(this.statusViewContainerRef.parentInjector);
      this.statusViewContainerRef.insert(this.statusComponent.hostView);
      this.statusComponent.instance.data = this.inputData.case;
      this.statusComponent.instance.cases = this.inputData.group.cases;
      this.statusComponent.instance.dataCases = this.inputData.cases;

      this.notifyParent(() => this.statusComponent.instance.isValid() && this.inputData.group.cases.length, this.inputData);
    } else {
      this.notifyParent(() => this.inputData.group.cases.length, this.inputData);
    }
  }
}
