<svg attr.width="{{svgRootStyle['width.px']}}" attr.height="{{svgRootStyle['height.px']}}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" attr.viewBox="{{ svgViewBox }}" [ngStyle]="svgRootStyle">
  <!-- stroke -->
  <rect
    (click)="interactive && onColorpickerToggle('stroke');"
    [attr.width]="getStrokeStyle()['width.px']"
    [attr.height]="getStrokeStyle()['height.px']"
    [ngStyle]="getStrokeStyle()" />
  <!-- fill -->
  <rect
    (click)="interactive && onColorpickerToggle('fill');"
    [attr.width]="getFillStyle()['width.px']"
    [attr.height]="getFillStyle()['height.px']"
    [ngStyle]="getFillStyle()"
    attr.x="{{ interactive ? 2 : 1 }}"
    attr.y="{{ interactive ? 2 : 1 }}" />
</svg>

<ng-container *ngIf="isColorpickerVisible">
  <color-chrome *ngIf="fillOrStroke"
    [color]="style[fillOrStroke]"
    (onChange)="onColorChange($event);"
    class="ml-10"
  ></color-chrome>
</ng-container>

