import { Component, Input, OnInit } from '@angular/core';

import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';

@Component({
  selector: 'gmt-construction-object-list',
  templateUrl: './construction-object-list.component.html',
  styleUrls: ['./construction-object-list.component.scss']
})
export class ConstructionObjectListComponent implements OnInit {

  // bindings
  @Input() constructionObjects: ConstructionObjectModel[];
  @Input() showAdministrator: boolean = false;
  @Input() showMore = true;
  administrators: string[];

  // model
  show = false;

  constructor(private projectSubjectNamePipe: ProjectSubjectNamePipe) {}

  ngOnInit() {
    if (this.showAdministrator) {
      this.administrators = [...new Set(this.constructionObjects
        .map(co => co.administrator ? this.projectSubjectNamePipe.transform(co.administrator) : 'nezvoleno'))
      ];
    }

    if (!this.showMore) {
      this.show = true;
    }
  }

  onShowMore(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.show = true;
  }
}
