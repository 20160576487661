import { Component, Inject, Input, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { ModulesService } from '@app/common/services/modules.service';
import { ListModel } from '@app/common/models/list.model';
import { SortModel } from '@app/common/models/sort.model';
import { RolePipe } from '@app/common/pipes/role.pipe';
import * as _ from 'lodash';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND, APPLICATIONS, UICONSTANTS } from '@app/common/services/config.service';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {

  @Input() userId: number;

  loading = true;
  user: User;
  list: ListModel<any>;
  sortOrder: SortModel = { sortBy: 'roleName', direction: 'asc' };
  itemsPerPage = UICONSTANTS.itemsPerPage10;
  dataPaging = { itemsPerPage: { id: 10, name: 10 }};
  onListChangedPagination: Function;
  onListChangedItemCounter: Function;
  onListChangedPagesCounter: Function;
  roles: any[] = [];
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    private modulesService: ModulesService,
    private rolePipe: RolePipe,
  ) {
  }

  async ngOnInit() {
    this.user = await this.modulesService.getUser(this.userId).then(d => d.plain());

    if (this.APP_BRAND.NAME === 'RSD') {
      this.roles = this.user.ssoRoles
        ? this.user.ssoRoles.map(r => {
            return {
              roleName: this.rolePipe.transform(r.role),
              isprofond: r.isprofond,
              projectKey: r.projectKey,
              projectName: r.projectName
            };
          })
        : [];
    } else {
      const allProjects = await this.modulesService.getProjects(this.APPLICATIONS.sy.name).then(d => d.plain());
      const userProjectKeys = this.user.applicationProjects[this.APPLICATIONS.sy.name];
      const projects = allProjects.filter(p => userProjectKeys.includes(p.key));

      for (const p of projects) {
        const roles = this.user.roles.filter(r => p.key === r.projectKey);

        if (roles.length) {
          for (const r of roles) {
            this.roles.push({
              roleName: this.rolePipe.transform(r.role),
              isprofond: p.isprofond,
              projectKey: p.key,
              projectName: p.name
            });
          }
        } else {
          this.roles.push({
            isprofond: p.isprofond,
            projectKey: p.key,
            projectName: p.name
          });
        }
      }
    }

    this.initList();

    this.loading = false;
  }

  pagesCounterCallbackRegister(event) {
    this.onListChangedPagesCounter = event.onListChanged;
  }

  itemCounterCallbackRegister(event) {
    this.onListChangedItemCounter = event.onListChanged;
  }

  paginationCallbackRegister(event) {
    this.onListChangedPagination = event.onListChanged;
  }

  onSort(sortBy: string) {
    this.sortOrder.direction = sortBy === this.sortOrder.sortBy
      ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
      : 'asc';
    this.sortOrder.sortBy = sortBy;
    this.reloadList();
  }

  onPageChanged(event) {
    this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
    this.reloadList();
  }

  onPageItemsChanged() {
    this.list.filter.offset = 0;
    this.reloadList();
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
    if (this.onListChangedItemCounter) {
      this.onListChangedItemCounter();
    }
    if (this.onListChangedPagesCounter) {
      this.onListChangedPagesCounter();
    }
  }

  reloadList() {
    this.roles = _.orderBy(this.roles, this.sortOrder.sortBy, this.sortOrder.direction as any);
    this.list.list = this.roles.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
    this.list.itemCount = this.roles.length;
    this.list.filter.limit = this.dataPaging.itemsPerPage.id;
    this.onListChanged();
  }

  private initList() {
    this.list = {
      loading: false,
      list: this.roles.slice(0, this.dataPaging.itemsPerPage.id),
      itemCount: this.roles.length,
      filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
    };
  }
}
