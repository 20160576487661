import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { DocumentModel } from '@app/common/models/document.model';
import { DocumentTypeModel } from '@app/common/models/document-type.model';

export type DocumentTypePipeField = 'icon' | 'title';

@Pipe({ name: 'documentType' })
@Injectable({
  providedIn: 'root'
})
export class DocumentTypePipe implements PipeTransform {
  transform(document: DocumentModel, field: DocumentTypePipeField): string {
    const documentType = DocumentTypeModel.createById(document.type);

    switch (field) {
      case 'title':
        return documentType.title;
      case 'icon':
        return documentType.icon;
    }
  }
}
