import * as _ from 'lodash';

export class ArrayUtils {

  public static generateIntegerArray(from= 0, to = 10): number[] {
    const result = [];

    for (let i = from; i < to; i++) {
      result.push(i);
    }

    return result;
  }

  public static getLongestCommonSubstring(arr, caseSensitive = false) {
    if (!_.isArray(arr)) {
      return;
    }

    let sorted = arr.concat().sort();

    if (caseSensitive) {
      sorted = _.map(sorted, (item) => item.toLowerCase());
    }

    const shortest = sorted[0];
    const longest = sorted[sorted.length - 1];
    const shortestLength = shortest.length;
    let i = 0;

    while (i < shortestLength && shortest.charAt(i) === longest.charAt(i)) {
      i += 1;
    }

    return shortest.substring(0, i);
  }
}
