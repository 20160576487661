<h1>{{ editMode === 'edit' ? 'Úprava údajů ZP' : requestService.getStatusEnumLabel(newStatus) }}</h1>
<fieldset>
  <div class="column-100">
    <!-- cislo ZP -->
    <div *ngIf="isFieldVisible('name')" class="column-50">
      <gmt-input label="Číslo ZP" class="mt-10" [required]="editMode === 'change-status'">
        <input type="text" [(ngModel)]="opinionRequest.name"/>
      </gmt-input>
    </div>

    <!-- datum zadani zpracovani -->
    <div *ngIf="isFieldVisible('realizationStartedDate')" class="column-50">
      <gmt-input label="Datum zadání zpracování" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="opinionRequest.realizationStartedDate"/>
      </gmt-input>
    </div>

    <!-- datum pro vyhotoveni -->
    <div *ngIf="isFieldVisible('realizationFinishExpectedDate')" class="column-50">
      <gmt-input label="Termín pro vyhotovení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="opinionRequest.realizationFinishExpectedDate"/>
      </gmt-input>
    </div>

    <!-- datum vyhotoveni -->
    <div *ngIf="isFieldVisible('realizedDate')" class="column-50">
      <gmt-input label="Datum vyhotovení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="opinionRequest.realizedDate"/>
      </gmt-input>
    </div>

    <!-- cena za vyhotoveni -->
    <div *ngIf="isFieldVisible('realizationPrice')" class="column-50">
      <gmt-input label="Cena za vyhotovení" class="mt-10">
        <input type="text" [(ngModel)]="opinionRequest.realizationPrice" gmtInputNumber/>
      </gmt-input>
    </div>

    <!-- datum zruseni -->
    <div *ngIf="isFieldVisible('deletedDate')" class="column-50">
      <gmt-input label="Datum zrušení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="opinionRequest.deletedDate"/>
      </gmt-input>
    </div>
  </div>
</fieldset>

<!-- znalec -->
<expert-select-form *ngIf="editMode === 'change-status' && isFieldVisible('expert')" [expert]="opinionRequest.expert" (changed)="onExpertSelected($event)"></expert-select-form>

<!-- screen ZE -->
<fieldset *ngIf="editMode === 'change-status' && isFieldVisible('attachments')" class="mb-70">
  <legend>Příloha ZP</legend>
  <fileupload class="blue" (change)="onFileChange($event)" [message]="filesMsg" inputId="opinionAttachmentsHidden"></fileupload>
</fieldset>

<div class="actions">
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
