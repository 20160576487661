<div class="list">
  <div class="actions">
    <!--<a (click)="generateAll()" gmtAuth="admin"><i *ngIf="!generating" class="fa fa-plus-square"></i><i *ngIf="generating" class="fa fa-circle-o-notch fa-spin"></i> Vygenerovat všechny dokumenty</a>
      -->
    <a class="button" href="{{restBaseUrl}}/cases/all-files?t={{authToken}}" gmtAuth="admin"><i class="fa fa-plus-square"></i>Stáhnout všechny dokumenty</a>
    <a uiSref="symap.project.cases.bulkOperation" class="button">Hromadná operace</a>
  </div>
  <h1>Případy</h1>
  <ng-container *ngIf="list">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.obligationId"
        [globalFilterConfig]="globalFilter"
        [filter]="{filters: {caseExists: {values: [true] }}, sortOrder: {sortBy: 'name'}}"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.obligationId.negation"
        resource="obligations"
        selectTitle="Typ případu"
        logGa
      ></gmt-select>

      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.caseStatusId"
        [globalFilterConfig]="globalFilter"
        [filter]="{filters: {caseExists: {values: [true] }}}"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.caseStatusId.negation"
        resource="case-statuses"
        selectTitle="Stav případu"
        logGa
      ></gmt-select>

      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.areaId"
        [globalFilterConfig]="globalFilter"
        [filter]="{sortOrder: {sortBy: 'name'}}"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.areaId.negation"
        resource="areas"
        selectTitle="Katastrální území"
        logGa
      ></gmt-select>
      <gmt-select [multiselect]="true" [data]="list.filter.filters.cadastreValid" field="values"
                  [optionItems]="cadastreValidities" selectTitle="Platnost KN dat" logGa></gmt-select>
      <gmt-select [multiselect]="true" [data]="list.filter.filters.inputValid" field="values" [optionItems]="inputValidities"
                  selectTitle="Platnost záborů/VB" logGa></gmt-select>
      <gmt-select *ngIf="!hideColumn('mandatary')" [restangularService]="restangularSettings" [multiselect]="true" [data]="list.filter.filters.mandataryId"
                  field="values" resource="users/list" [filter]="mandataryFilter" selectTitle="Řešitel případu"
                  [itemPipe]="userNamePipe" logGa></gmt-select>
      <gmt-select *ngIf="!hideColumn('officer')" [restangularService]="restangularSettings" [multiselect]="true" [data]="list.filter.filters.officerId"
                  field="values" resource="users/list" [filter]="officerFilter" selectTitle="Referent případu"
                  [itemPipe]="userNamePipe" logGa></gmt-select>
      <gmt-select *ngIf="!hideColumn('titleUser')" [restangularService]="restangularSettings" [multiselect]="true" [data]="list.filter.filters.titleUserId"
                  field="values" resource="users/list" [filter]="mandataryFilter" selectTitle="Přidělení LV"
                  [itemPipe]="userNamePipe" logGa></gmt-select>
      <gmt-select [multiselect]="true" [data]="list.filter.filters.constructionObjectStageId"
                  [filter]="{filters: {validity: 'valid'}, sortOrder: {sortBy: 'name'}}" field="values"
                  resource="construction-object-stages" selectTitle="{{'ETAPA_USEK'|wordtranslate}}" logGa></gmt-select>
      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.constructionObjectId"
        [filter]="{filters: {validity: 'valid'}, sortOrder: {sortBy: 'socis'}}"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.constructionObjectId.negation"
        resource="construction-objects"
        selectTitle="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}"
        [itemPipe]="constructionObjectNamePipe"
        logGa
      ></gmt-select>

      <div class="tag" [ngClass]="{active: checkCases()}" title="Zobrazení vč. těch případů, které jsou ve stavu zrušeno"
           (click)="onHideDisabledCasesCheckboxClicked()">
        <div>Zobrazit zrušené případy</div>
      </div>
      <statistics-entity-filter
        (changed)="statisticsEntityFilterChanged($event)"
        [filter]="list.filter"
        (registerCallback)="registerUpdateStatisticsEntityFilterKey($event)"
      ></statistics-entity-filter>
      <div class="column-100">
        <input class="fulltext" autocomplete="off" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
      </div>
    </filter>

    <div [loading]="list.loading || !settingsLoaded">
      <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
        <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
        <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
        <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>
      <table-cases [list]="list" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)" *ngIf="settingsLoaded" [caseStatusType]="'progress'"></table-cases>
      <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
      <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
    </div>

    <help [helpId]="helpIds.PANEL_CASES" class="cm-help-page"></help>
  </ng-container>
</div>
