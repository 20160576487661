import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { Restangular } from 'ngx-restangular';
import { ExpertModel } from '@app/ps/models/expert.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';

@Component({
  templateUrl: './update-occupation.component.html',
  styleUrls: ['./update-occupation.component.scss'],
})
export class UpdateOccupationComponent implements OnInit {
  @Input() titleId: number;
  @Input() parcelId: number;
  @Input() occupationId: number;
  public occupationTypeName: string;

  occupationObject: OccupationModel;
  public priceType: SwitchOption[];
  public priceUnit: SwitchOption[];

  public occupationSolutionOptions = [
    {id: null, value: 'Jiný způsob řešení'},
    {id: 'permanentWithSolutionTypeUnderOneYear', value: 'Nájem do 1 roku'},
    {id: 'permanentWithSolutionTypeOverOneYear', value: 'Nájem nad 1 rok'},
  ];

  constructor(
    private stateService: StateService,
    private restangular: Restangular,
  ) {
    this.goToDetail = this.goToDetail.bind(this);
    this.update = this.update.bind(this);
    this.priceType = [
      {
        value: 'Cenový výměr ministerstva financí',
        id: 'F',
      },
      {
        value: 'Znalecký posudek',
        id: 'E',
      },
      {
        value: 'Jiný způsob',
        id: 'O',
      },
    ];

    this.priceUnit = [
      {
        value: 'Roční cena za metr čtvereční',
        id: 'A',
      },
      {
        value: 'Jednorázová cena',
        id: 'T',
      },
    ];
  }

  async ngOnInit() {
    await this.loadOccupation();
  }

  async loadOccupation() {
    this.occupationObject = await this.restangular.one('occupations', this.occupationId).get({loadCollections: ['rentalExpert']}).toPromise();
    this.occupationObject.rentalPriceType = this.occupationObject.rentalPriceType || 'F';
    this.occupationObject.rentalPriceUnit = this.occupationObject.rentalPriceUnit || 'A';
    if (this.occupationObject.solutionType === 'permanentWithSolutionUnderOneYear') {
      this.occupationObject.rentalLength = this.occupationObject.rentalLength || 12;
    }
    this.occupationObject.solutionType = this.occupationObject.solutionType || null;
    this.occupationTypeName = this.occupationLongerThanOneYear() ? 'Dočasný zábor nad 1 rok' : (this.occupationShorterThanOneYear() ? 'Dočasný zábor do 1 roku' : this.occupationObject.zabtyptx);
  }

  goToDetail() {
     this.stateService.go('^', {tab: this.titleId ? 'parcels' : 'occupations'});
  }

  async update() {
    await (<any>this.occupationObject)
          .put().toPromise();
    this.goToDetail();
  }

  onChangeExpert(expert: ExpertModel) {
    this.occupationObject.rentalExpert = expert;
  }

  isValid() {
    return (!this.occupationObject.solutionType && !this.occupationLongerThanOneYear() && !this.occupationShorterThanOneYear()) ||
     (this.occupationObject.rentalPriceType && this.occupationObject.rentalLength > 0 &&
       (
        ((this.occupationLongerThanOneYear() || this.occupationObject.solutionType === 'permanentWithSolutionTypeOverOneYear') && this.occupationObject.rentalLength > 12) ||
        ((this.occupationShorterThanOneYear() || this.occupationObject.solutionType === 'permanentWithSolutionTypeUnderOneYear') && this.occupationObject.rentalLength <= 12)
       ) &&
       (
        (this.occupationObject.rentalPriceType === 'F' && this.occupationObject.priceAssessmentYear && this.occupationObject.priceAssessmentLandPrice) ||
        (this.occupationObject.rentalPriceType === 'E' && this.occupationObject.rentalExpertOpinionNumber && this.occupationObject.rentalExpertOpinionDate && this.occupationObject.rentalExpert && this.occupationObject.priceAssessmentLandPrice) ||
        (this.occupationObject.rentalPriceType === 'O' && this.occupationObject.rentalOtherPriceSource && ((this.occupationObject.rentalPriceUnit === 'T' && this.occupationObject.totalRentPrice) || (this.occupationObject.rentalPriceUnit === 'A' && this.occupationObject.priceAssessmentLandPrice)))
      )
    );
  }

  occupationLongerThanOneYear() {
    return this.occupationObject.occupationType.category === 'overOneYear';
  }

  occupationShorterThanOneYear() {
    return this.occupationObject.occupationType.category === 'underOneYear';
  }
}

