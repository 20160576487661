import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';

@Component({
  selector: 'representative-form',
  templateUrl: './representative-form.component.html',
  styleUrls: ['./representative-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepresentativeFormComponent implements OnInit {

  @Input() compact = false; // caused by different contexts (update-case-subject, owner-detail)
  @Input() allowRoleAssignment: boolean; // used for both representative and contact person
  @Input() heading = 'Zastupující';
  @Input() representative: ActingPersonModel;
  @Input() editable = true;
  @Input() editableToggle = false;
  @Output() toggleRoleCadastre: EventEmitter<ActingPersonModel> = new EventEmitter<ActingPersonModel>();
  requiredFields: boolean;

  ngOnInit() {
    this.requiredFields = this.allowRoleAssignment;
  }

  onToggleRoleCadastre(representative: ActingPersonModel) {
    representative.roleCadastre = !representative.roleCadastre;
    this.toggleRoleCadastre.emit(representative);
  }
}
