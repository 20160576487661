<ng-template #warnings>
  <div>
    <ul>
      <li *ngFor="let warning of easement.warnings | keyvalue">
        {{ warning.value }}
      </li>
    </ul>
  </div>
</ng-template>

<ng-container>
  <td class="no-padding">
    <div class="fill-content">
      <div *ngIf="!easement.editable" [tooltip]="existingEasementMsg" class="fill-content text-center">
        <div class="fill-content flexbox-container flex-centered padding-10">
          <i class="fa fa-info" aria-hidden="true"></i>
        </div>
      </div>
      <div *ngIf="getEasementWarnings()" [tooltip]="warnings" class="tooltip-hover-area fill-content text-center">
        <div class="fill-content flexbox-container flex-centered padding-10">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </td>
  <td class="text-center">
    <gmt-checkbox (click)="onToggleIncluded();" [gmtChecked]="easement['include']"></gmt-checkbox>
  </td>
  <td class="no-padding cell-max-height">
    <div *ngIf="!easement.editable">
      <cell-cases
        class="blue"
        [casesCount]="easement.countCases"
        [tooltipType]="'cases'"
        [tooltipData]="{ filter: { filters: { easementId: easement.importParcel.id, cancelStatus: 'notCancelled' }}, customData: { openNewTab: true }}"
      ></cell-cases>
    </div>
  </td>
  <td class="text-center">
    <b *ngIf="parcel.future">budoucí {{ parcel.parcelName }}</b>
    <b *ngIf="!parcel.future">{{ parcel.parcelName }}</b>
  </td>
  <td class="text-center">{{ parcel.lv }}</td>
  <td class="text-center">{{ parcel.drupozNazev }}</td>
  <td class="text-center">{{ easement.symapParcelIntersection.area || easement.symapParcelIntersection.area === 0 ? easement.symapParcelIntersection.area : 'stávající VB' }}</td>
  <td class="text-center">{{ easement.csvParcelIntersection && easement.csvParcelIntersection.area ? easement.csvParcelIntersection.area : '-' }}</td>
  <td>
    <span *ngIf="!easement.editable" style="padding-left: 10px;">
      {{ easement.importParcel.area === null ? '-' : easement.importParcel.area }}
    </span>
    <input *ngIf="easement.editable" [(ngModel)]="easement.importParcel.area" type="text" size="8" style="width:inherit" gmtInputNumber />
  </td>
  <td class="text-center">{{ easement.csvParcelIntersection && easement.csvParcelIntersection.length ? easement.csvParcelIntersection.length : '-' }}</td>
  <td>
    <span *ngIf="!easement.editable" style="padding-left: 10px;">
      {{ easement.importParcel.length === null ? '-' : easement.importParcel.length }}
    </span>
    <input *ngIf="easement.editable" [(ngModel)]="easement.importParcel.length" type="text" size="8" style="width:inherit" gmtInputNumber />
  </td>
</ng-container>
