import { Component, Inject, OnInit } from '@angular/core';
import { RESTANGULAR_DIMAP } from '@app/common/services/restangular-dimap.service';
import { AuthService } from '@app/common/services/auth.service';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'project-update',
  templateUrl: './project-update.component.html',
})
export class ProjectUpdateComponent implements OnInit {

  private projectId = this.authService.getActualProject().key;
  data: any = {};
  checked = false;
  loading = true;

  tab = 'G';
  tabs = [
    {id: 'G', name: 'Základní'},
    {id: 'C', name: 'Podmínky užití'}
  ];

  constructor(
    @Inject(RESTANGULAR_DIMAP) private restangularDimap: any,
    private authService: AuthService,
    private stateService: StateService
  ) {
    this.update = this.update.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit(): void {

    this.authService.loadProject(this.projectId).then((data) => {
      this.data = data;
      this.loading = false;
    });
  }

  toggleCheckbox = () => {
    this.checked = !this.checked;
    return this.checked;
  };

  isActiveTab(id: string) {
    return id === this.tab;
  }

  update() {
    const data = {
      name: this.data.name,
      termsAndConditions: this.data.termsAndConditions,
      forceApproval: (this.checked === true)
    };

    return this.restangularDimap.one('projects', this.projectId).customPUT(data).toPromise().then(() => {
      this.stateService.go('dimap.project.settings');
    });
  }
}
