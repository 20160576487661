<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ approvedInvestorName }}</h1>
    <fieldset>
      <div class="column-25">
        <label>Případ</label>
        <div class="cb pt-10">
          <b>{{ data.mpp.number }}</b>
          <div *ngFor="let title of data.titles" class="comma-list">
            {{ title.lv }} ({{ title.area.name }})
          </div>
          <div class="comma-list">
            <span *ngFor="let itemCaseSubject of data.caseSubjects">
              <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: itemCaseSubject.subject.id, tab: 'about' }">
                {{ itemCaseSubject.subject | subjectName }}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="column-25">
        <label>Typ</label>
        <div class="cb pt-10">{{ data.obligation.name }}</div>
      </div>
      <div class="column-25">
        <label>Stav</label>
        <div class="cb pt-10">{{ data.caseStatus.name }}<br/>{{ data.statusRespectiveDate | date }}</div>
      </div>
      <div class="column-25">
        <label>Přidělení LV</label>
        <div class="cb pt-10">
          {{ data.titles | titleUserUniqueNames }}
        </div>
      </div>
      <div class="column-100 mt-10">
        <label class="required">K datu</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="date" [maxToday]="true" /></div>
      </div>
    </fieldset>

    <div>
      <gmt-button class="red" [clickAction]="onDecline" [gmtDisabled]="!isValid()">{{ declinedInvestorName }}</gmt-button>
      <gmt-button [clickAction]="onApprove" [gmtDisabled]="!isValid()">{{ approvedInvestorName }}</gmt-button>
    </div>
  </div>
</div>
