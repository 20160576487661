import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';
import {Ng2StateDeclaration, StateParams, Transition, UIRouterModule} from '@uirouter/angular';
import { ProjectCreateComponent } from '@app/dimap/settings/components/project-create/project-create.component';
import { ProjectUpdateComponent } from '@app/dimap/settings/components/project-update/project-update.component';
import { SettingsComponent } from '@app/dimap/settings/components/settings/settings.component';
import { UserListComponent } from '@app/common/components/user-list/user-list.component';
import { UserDetailComponent } from '@app/common/components/user-detail/user-detail.component';
import { resolveCallbacks, resolveId } from '@app/common/utils/state.utils';
import { UserCredentialsComponent } from '@app/common/components/user-credentials/user-credentials.component';
import { CallbackModel } from '@app/common/models/callback.model';
import { handleUserPermissionsModal, resolveProjectKey } from '@app/settings/users/users.states';
import { resolveApplication, resolveUser } from '@app/ps/project-settings/project-settings.states';

export const projectCrateState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.createProject',
  data: {
    title: 'Založení projektu',
  },
  url: '/create-project',
  views: {
    'content@dimap.project': {
      component: ProjectCreateComponent,
    }
  }
};

export const projectUpdateState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.editProject',
  data: {
    title: 'Editace projektu',
  },
  url: '/edit-project',
  views: {
    'content@dimap.project': {
      component: ProjectUpdateComponent,
    }
  }
};

export const settingsState: Ng2StateDeclaration = {
  name: 'dimap.project.settings',
  data: {
    title: 'Nastavení',
  },
  url: '/settings',
  views: {
    'content@dimap.project': {
      component: SettingsComponent,
    }
  }
};

export const userListState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.users',
  url: '/users?page',
  data: {
    title: 'Uživatelé',
  },
  views: {
    'content@dimap.project': {
      component: UserListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const userCredentialsState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.changePassword',
  url: '/change-password',
  data: {
    title: 'Změna přihlašovacích údajů',
  },
  views: {
    'content@dimap.project': {
      component: UserCredentialsComponent,
    }
  },
  params: {
    backSref: {
      type: 'string',
      value: 'dimap.project.settings'
    },
  }
};

export const userDetailState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.users.detail',
  url: '/:id',
  data: {
    title: 'Detail uživatele',
  },
  views: {
    'content@dimap.project': {
      component: UserDetailComponent,
    }
  },
  resolve: {
    userId: [ '$stateParams', resolveId ],
    callbacks: [ resolveCallbacks ],
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export function resolvePermissionsCallback(callbacks: CallbackModel) {
  return callbacks.get('userPermissionsChangedCallback');
}

export const updateUserPermissionsState: Ng2StateDeclaration = {
  name: 'dimap.project.settings.users.detail.updateUserPermissions',
  url: '/user-permissions',
  data: {
    title: 'Editace oprávnění uživatele',
  },
  resolve: {
    application: ['$stateParams', '$transition$', resolveApplication],
    projectKey: ['$stateParams', resolveProjectKey],
    user: ['$stateParams', '$transition$', resolveUser],
    userPermissionsChangedCallback: [ 'callbacks', resolvePermissionsCallback ],
  },
  onEnter: handleUserPermissionsModal
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        projectCrateState,
        projectUpdateState,
        settingsState,
        userListState,
        userDetailState,
        userCredentialsState,
        updateUserPermissionsState,
      ],
    }),
    CommonModule,
    DimapCommonModule,
  ],
  providers: [],
  declarations: [
    ProjectCreateComponent,
    ProjectUpdateComponent,
    SettingsComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class SettingsModule {}
