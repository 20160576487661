import { Component, Input } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';

@Component({
  selector: 'parcel-tenant',
  templateUrl: './parcel-tenant.component.html',
  styleUrls: []
})
export class ParcelTenantComponent {
  @Input() parcel: ParcelModel;
}
