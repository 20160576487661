export enum SampleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXCEPTION = 'EXCEPTION',
  WAITING_FOR_ACTUALIZATION = 'WAITING_FOR_ACTUALIZATION'
}

export const sampleStatusOptions = [
  {
    id: SampleStatusEnum.ACTIVE,
    name: 'Platný',
  },
  {
    id: SampleStatusEnum.INACTIVE,
    name: 'Neplatný',
  },
  {
    id: SampleStatusEnum.EXCEPTION,
    name: 'Výjimka',
  },
  {
    id: SampleStatusEnum.WAITING_FOR_ACTUALIZATION,
    name: 'Čeká na aktualizaci',
  },
];
