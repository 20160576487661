import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import {
  titlesState,
  createCaseState,
  createPricingOverviewState,
  createPricingExpertOpinionCreateState,
  createPricingOtherSourceCreateState,
  titleDetailState,
  createPricingState, createPreliminaryOpinionState,
  updateOccupationState,
} from './titles.states';

import { CreateCaseComponent } from './components/create-case/create-case.component';
import { CreateCaseSelectObligationComponent } from './components/create-case-select-obligation/create-case-select-obligation.component';
import { CreateCaseSelectObjectsComponent } from './components/create-case-select-objects/create-case-select-objects.component';
import { CreateCaseSelectObjectsItemsComponent } from './components/create-case-select-objects-items/create-case-select-objects-items.component';
import { CreateCaseSelectTitlesComponent } from './components/create-case-select-titles/create-case-select-titles.component';
import { CreateCaseSelectParentOwnershipComponent } from './components/create-case-select-parent-ownership/create-case-select-parent-ownership.component';
import { CreateCaseDetailComponent } from './components/create-case-detail/create-case-detail.component';
import { CreateCaseConfirmComponent } from './components/create-case-confirm/create-case-confirm.component';
import { CreateCaseSummaryComponent } from './components/create-case-summary/create-case-summary.component';
import { CreateCaseSelectExternalParcelsComponent } from '@app/ps/titles/components/create-case-select-external-parcels/create-case-select-external-parcels.component';
import { TitleListComponent } from './components/title-list/title-list.component';
import { PricingOverviewComponent } from '@app/ps/titles/components/pricing-overview/pricing-overview.component';
import { PriceTypeNamePipe } from '@app/ps/pipes/price-type-name.pipe';
import { PricingExpertOpinionComponent } from '@app/ps/titles/components/pricing-expert-opinion/pricing-expert-opinion.component';
import { PricingExpertOpinionBaseComponent } from '@app/ps/titles/components/pricing-expert-opinion-base/pricing-expert-opinion-base.component';
import { PricingOtherSourceComponent } from '@app/ps/titles/components/pricing-other-source/pricing-other-source.component';
import { PricingExpertOpinionParcelsComponent } from '@app/ps/titles/components/pricing-expert-opinion-parcels/pricing-expert-opinion-parcels.component';
import { TitleDetailComponent } from '@app/ps/titles/components/title-detail/title-detail.component';
import { PricingPreliminaryOpinionComponent } from '@app/ps/titles/components/pricing-preliminary-opinion/pricing-preliminary-opinion.component';
import { CreateCaseSelectTitlesMultipleDialogComponent } from '@app/ps/titles/components/create-case-select-titles-multiple-dialog/create-case-select-titles-multiple-dialog.component';
import { CreateCaseSelectOwnerDialogComponent } from '@app/ps/titles/components/create-case-select-owner-dialog/create-case-select-owner-dialog.component';
import { UIRouterModule } from '@uirouter/angular';
import { PricingExpertOpinionSummaryComponent } from '@app/ps/titles/components/pricing-expert-opinion-summary/pricing-expert-opinion-summary.component';


const TITLES_STATES = [
  titlesState,
  createCaseState,
  createPricingState,
  createPricingOverviewState,
  createPricingExpertOpinionCreateState,
  createPricingOtherSourceCreateState,
  createPreliminaryOpinionState,
  titleDetailState,
  updateOccupationState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...TITLES_STATES],
    }),
    CommonModule,
    PsCommonModule,
  ],
  providers: [],
  declarations: [
    CreateCaseComponent,
    CreateCaseSelectObligationComponent,
    CreateCaseSelectObjectsComponent,
    CreateCaseSelectObjectsItemsComponent,
    CreateCaseSelectTitlesComponent,
    CreateCaseSelectParentOwnershipComponent,
    CreateCaseDetailComponent,
    CreateCaseConfirmComponent,
    CreateCaseSummaryComponent,
    CreateCaseSelectExternalParcelsComponent,
    TitleListComponent,
    PricingOverviewComponent,
    PricingExpertOpinionComponent,
    PricingExpertOpinionBaseComponent,
    PricingExpertOpinionParcelsComponent,
    PricingExpertOpinionSummaryComponent,
    PricingOtherSourceComponent,
    PricingPreliminaryOpinionComponent,
    TitleDetailComponent,
    CreateCaseSelectTitlesMultipleDialogComponent,
    CreateCaseSelectOwnerDialogComponent,

    PriceTypeNamePipe,
  ],
  entryComponents: [
    CreateCaseSelectTitlesMultipleDialogComponent,
    CreateCaseSelectOwnerDialogComponent,
  ],
  exports: [
  ]
})
export class TitlesModule {}
