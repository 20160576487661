import { Component, OnInit } from '@angular/core';

import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { GeometricPlanRequestTypeEnum } from '@app/ps/enums/geometric-plan-request-type.enum';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { approveOptions } from '@app/ps/enums/approve.enum';
import { GeometricPlanRequestStatusEnum } from '@app/ps/enums/geometric-plan-request-status.enum';
import { NoteTypeKeyEnum } from '@app/ps/enums/note-type-key.enum';
import { NoteTypeModel } from '@app/common/models/note-type.model';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { AuthService } from '@app/common/services/auth.service';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { KnGpNamePipe } from '@app/ps/pipes/kn-gp-name.pipe';
import {DialogRef} from "@app/common/services/dialog-ref";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";

@Component({
  selector: 'geometric-plan-request-edit',
  templateUrl: './geometric-plan-request-edit.component.html',
  styleUrls: ['./geometric-plan-request-edit.component.scss']
})
export class GeometricPlanRequestEditComponent implements OnInit {

  editMode: 'change-status' | 'edit';
  newStatus: string;
  geometricPlanRequest: GeometricPlanRequestModel;
  noteTypes: NoteTypeModel[];
  typeEnum = GeometricPlanRequestTypeEnum;
  statusEnum = GeometricPlanRequestStatusEnum;
  approveOptions: SwitchOption[] = approveOptions;
  parcelSplitPreApproveByBuildingAuthority;
  parcelSplitPreApproveByBuildingAuthorityNote: string;
  conceptDisapprovedReasonNote: string;
  gpFiles: UploadFileExtended[] = [];
  gpVfkFiles: UploadFileExtended[] = [];
  gpDocFiles: UploadFileExtended[] = [];
  gpOtherFiles: UploadFileExtended[] = [];
  gpFilesValid = false;
  gpVfkFilesValid = false;
  gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
  gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
  gpDocFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_DOC_FILES_DEFAULT;
  gpOtherFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_OTHER_FILES_DEFAULT;

  static readonly MSG_GP_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
  static readonly MSG_GP_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';

  static readonly MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
  static readonly MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';

  static readonly MSG_GP_DOC_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';

  static readonly MSG_GP_OTHER_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';

  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  static readonly STATUSES_CONFIG = [
    {
      status: GeometricPlanRequestStatusEnum.CONCEPT_REALIZATION,
      fields: [
        'conceptRealizationInputDate',
        'supplier',
        'conceptRealizationOutputDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION,
      fields: [
        'conceptToApproveDate',
        'attachmentsOther',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED,
      fields: [
        'conceptDisapprovedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
      fields: [
        'conceptApprovedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION,
      fields: [
        'sentToConfirmationDate',
        'verificationDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.DISAPPROVED,
      fields: [
        'disapprovedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.APPROVED,
      fields: [
        'approvedDate',
        'realizationPrice',
        'pgpNumber',
        'attachments',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.DELETED,
      fields: [
        'deletedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.REGISTER_REQUESTED,
      fields: [
        'registerRequestedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.REGISTERED,
      fields: [
        'registeredDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.IMPORT_REQUESTED,
      fields: [
        'importRequestedDate',
      ]
    },
    {
      status: GeometricPlanRequestStatusEnum.IMPORTED,
      fields: [
        'importedDate',
        'knGp'
      ]
    },
  ];

  constructor(
    public requestService: GeometricPlanRequestService,
    public authService: AuthService,
    public dialogComponent: DialogRef,
    public knGpNamePipe: KnGpNamePipe,
    private dialogConfig: DialogConfig,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    const data = (this.dialogConfig.data as DialogConfigData);
    this.newStatus = data.newStatus;
    this.editMode = data.editMode;
    this.geometricPlanRequest = data.geometricPlanRequest;
    this.noteTypes = data.noteTypes;
    this.parcelSplitPreApproveByBuildingAuthority = this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority
      ? this.approveOptions.find(o => o.id === this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority)
      : this.approveOptions[0];
  }

  isValid(): boolean {
    if (this.editMode === 'edit') {
      return true;
    }

    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    const fieldsValidity = statusConfig.fields
      .filter(n => n !== 'realizationPrice' && n !== 'attachments' && n !== 'attachmentsOther' && n !== 'knGp')
      .map(n => !!this.geometricPlanRequest[n])
      .every(b => b);

    if (this.newStatus === GeometricPlanRequestStatusEnum.APPROVED) {
      return (fieldsValidity
        && this.gpFilesValid
        && this.gpVfkFilesValid
        && (this.geometricPlanRequest.type !== GeometricPlanRequestTypeEnum.EASEMENT_EXTENT || this.gpDocFiles.length > 0)
      );
    }

    if (this.newStatus === GeometricPlanRequestStatusEnum.IMPORTED) {
      return (fieldsValidity && !!this.geometricPlanRequest.knGp);
    }

    return fieldsValidity;
  }

  isFieldVisible(field: string): boolean {
    if (this.editMode === 'edit') {
      if (field.indexOf('Date') > 0 && this.geometricPlanRequest[field] === undefined) {
        return false;
      } else {
        return true;
      }
    }

    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    return statusConfig.fields.includes(field);
  }

  onGpFilesChange(files: UploadFileExtended[]) {
    this.gpFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'pdf');

    if (this.gpFiles.length === 0) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_EXTENSION;
    } else if (this.gpFiles.length > 1) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpFilesMsg = '';
    }

    this.gpFilesValid = (extensionValid && this.gpFiles.length === 1);
  }

  onGpVfkFilesChange(files: UploadFileExtended[]) {
    this.gpVfkFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'vfk');

    if (this.gpVfkFiles.length === 0) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_EXTENSION;
    } else if (this.gpVfkFiles.length > 1) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpVfkFilesMsg = '';
    }

    this.gpVfkFilesValid = (extensionValid && this.gpVfkFiles.length === 1);
  }

  onGpDocFilesChange(files: UploadFileExtended[]) {
    this.gpDocFiles = files;
  }

  onGpOtherFilesChange(files: UploadFileExtended[]) {
    this.gpOtherFiles = files;
  }

  onSave() {
    if (!this.isValid()) {
      return;
    }

    if (this.newStatus) {
      this.geometricPlanRequest.status = this.newStatus;
    }

    this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority = this.parcelSplitPreApproveByBuildingAuthority.id as string;

    const notes = [];

    if (this.parcelSplitPreApproveByBuildingAuthorityNote) {
      notes.push({
        text: this.parcelSplitPreApproveByBuildingAuthorityNote,
        noteType: this.noteTypes.find(nt => nt.key === NoteTypeKeyEnum.ParcelSplitPreApproveByBuildingAuthority)
      });
    }

    if (this.conceptDisapprovedReasonNote) {
      notes.push({
        text: this.conceptDisapprovedReasonNote,
        noteType: this.noteTypes.find(nt => nt.key === NoteTypeKeyEnum.ConceptDisapprovedReason)
      });
    }

    this.dialogComponent.close({
      geometricPlanRequest: this.geometricPlanRequest,
      notes: notes,
      gpFiles: this.gpFiles,
      gpVfkFiles: this.gpVfkFiles,
      gpDocFiles: this.gpDocFiles,
      gpOtherFiles: this.gpOtherFiles,
    });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }

  private getStatusConfig() {
    return GeometricPlanRequestEditComponent.STATUSES_CONFIG.find(sc => sc.status === this.newStatus);
  }
}
