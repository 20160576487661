<div *ngIf="showAdministrator">správce {{"CONSTRUCTION_OBJECT_GENITIV_SHORTCUT" | wordtranslate}}:
  <span class="comma-list"><span *ngFor="let adm of administrators">{{adm}}</span></span>
</div>
<div *ngFor="let constructionObject of constructionObjects; index as i" class="mb-10">
  <ng-container *ngIf="show || constructionObjects.length < 5 || (i < 3 && constructionObjects.length > 4)">
    <a uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: constructionObject.id, tab: 'parcels' }">
      {{ constructionObject | constructionObjectName }}
    </a>
  </ng-container>
</div>
<a *ngIf="constructionObjects.length > 4 && !show" (click)="onShowMore($event)" class="pt-7">
  Zobrazit dalších {{constructionObjects.length - 3}} {{"CONSTRUCTION_OBJECT_PLURAL_GENITIV" | wordtranslate}}.
</a>
