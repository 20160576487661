import { Component } from '@angular/core';

import { TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { BuildingModel } from '@app/common/models/building.model';

@Component({
  selector: 'table-buildings',
  templateUrl: './table-buildings.component.html',
})
export class TableBuildingsComponent extends TableParcelsAbstractComponent<BuildingModel> {

  protected readonly COMPONENT_COLUMNS = [
    'parcel',
    'parcel_title',
    'building_title',
    'cast_obce',
    'house_number',
    'usage',
    'protection',
    'cases',
    'price'
  ];
}
