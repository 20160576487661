import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ParcelModel } from '../models/parcel.model';
import { StringUtils } from '@app/common/utils/string.utils';

@Pipe({
  name: 'parcelnumber'
})
@Injectable({
  providedIn: 'root'
})
export class ParcelNumberPipe implements PipeTransform {

  private readonly simpleParcelNumberRegExp = new RegExp('^(\\d+)(\\/(\\d+))?(\\((\\d+)\\))?$', 'i');

  transform(parcel: ParcelModel, type: 'complex' | 'simple' = 'complex') {
    return (
      type === 'complex'
        ? this.transformComplex(parcel)
        : this.transformSimple(parcel)
    );
  }

  parse(parcelNumber: string): ParcelModel {
    if (!this.isParcelNumberValid(parcelNumber)) {
      return null;
    }

    const parcel = new ParcelModel();
    const matches = this.simpleParcelNumberRegExp.exec(StringUtils.removeSpaces(parcelNumber));

    if (matches[1]) {
      parcel.parcisKmen = parseInt(matches[1]);
    }

    if (matches[3]) {
      parcel.parcisPod = parseInt(matches[3]);
    }

    if (matches[5]) {
      parcel.parcisDil = parseInt(matches[5]);
    }

    return parcel;
  }

  private transformComplex(parcel: ParcelModel): string {
    let result = '';

    if (!parcel) {
      return result;
    }

    if (parcel.future) {
      result += 'budoucí';
    } else if (parcel.zeKod && parcel.zeKod === 3) {
      result += 'EN';
    } else if (parcel.zeKod && parcel.zeKod === 4) {
      result += 'PK';
    } else if (parcel.zeKod) {
      result += 'GP';
    } else {
      result += 'KN';
    }

    result += ' ';

    if (
      (parcel.knsk12 && parcel.knsk12 === 1) ||
      (parcel.knsk12 && parcel.knsk12 === 2 && parcel.kndruhp && parcel.kndruhp === 'zastavěná plocha a nádvoří') ||
      (parcel.cislKod && parcel.cislKod === 1) ||
      (parcel.cislKod && parcel.cislKod === 2 && parcel.drupozKod && parcel.drupozKod === 13)
    ) {
      result += ' st. ';
    }

    result += parcel.parcisKmen;
    result += (parcel.parcisPod ? '/' + parcel.parcisPod : '');
    result += (parcel.parcisDil ? ' (' + parcel.parcisDil + ')' : '');

    return result;
  }

  private transformSimple(parcel: ParcelModel): string {
    let ret = '';

    if (!parcel.parcisKmen) {
      return ret;
    }

    ret += parcel.parcisKmen;

    if (!!parcel.parcisPod) {
      ret += '/' + parcel.parcisPod;
    }

    if (!!parcel.parcisDil) {
      ret += '(' + parcel.parcisDil + ')';
    }

    return ret;
  }

  private isParcelNumberValid(parcelNumber: string): boolean {
    return this.simpleParcelNumberRegExp.test(StringUtils.removeSpaces(parcelNumber));
  }
}
