import { Component, OnInit } from '@angular/core';
import { CadastreImportModel } from '@app/settings/modules/models/cadastre-import.model';

@Component({
  selector: 'cadastre-data-import',
  templateUrl: './cadastre-data-import.component.html',
  styleUrls: ['./cadastre-data-import.component.scss'],
})
export class CadastreDataImportComponent implements OnInit {
  newImport: CadastreImportModel;

  ngOnInit() { }

  onImportAdded(data: CadastreImportModel) {
    this.newImport = {...data};
  }
}
