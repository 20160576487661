﻿import { Directive, ComponentRef, HostListener, Injector, Type, TemplateRef, ViewContainerRef, ComponentFactoryResolver, OnInit, EventEmitter, Input, Output, ElementRef, Inject, Renderer2 } from "@angular/core";
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { WindowDimensionService } from '@app/common/services/window-dimension.service';

@Directive({
  selector: '[tooltip]',
})
// loading data from resource is not implemented and should be handled in custom component. Also handling changes of data is not case for tooltip directive
export class TooltipDirective implements OnInit {
  // We can pass string, template or component
  @Input('tooltip') content: string | TemplateRef<any> | Type<any>;
  @Output() callback = new EventEmitter();
  @Input() data: any;
  @Output() visibilityChange = new EventEmitter();
  @Input() position: string; // where render tooltip - center, border (top or bottom). Default is border
  @Input() activeContent: boolean = false; // true/false if true, then is possible to move mouse inside tooltip. Default is false.
  visible: boolean;
  tooltipElement: any;
  tooltipContent: any;
  tooltipMarginTop: number = 0;
  private componentRef: ComponentRef<TooltipComponent>;

  constructor(
    private elementRef: ElementRef,
    private windowDimensionService: WindowDimensionService,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
  ) {
    this.closeTooltip = this.closeTooltip.bind(this);
    this.mouseTooltipEnter = this.mouseTooltipEnter.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.checkPosition = this.checkPosition.bind(this);
  }

  ngOnInit() {
    this.position = this.position === 'center' ? 'center' : 'border';
    this.activeContent = this.activeContent !== false;
    this.visible = false;
  }

  @HostListener('mouseenter')
  mouseEnter() {
    if (!this.content) {
      return;
    }
    if ( !this.componentRef ) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
      const factory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      const injector = Injector.create([
        {
          provide: 'tooltipConfig',
          useValue: {
            host: this.elementRef.nativeElement,
            content: this.content,
            checkPosition: this.checkPosition,
            data: this.data,
          }
        }
      ]);
      this.componentRef = this.viewContainerRef.createComponent(factory, 0, injector);
      // Move element inside directive
      const children = this.elementRef.nativeElement.children;
      if (children.length > 0) {
        this.elementRef.nativeElement.insertBefore(this.componentRef.location.nativeElement, children[0]);
      } else {
        this.elementRef.nativeElement.appendChild(this.componentRef.location.nativeElement);
      }
      this.tooltipElement = this.componentRef.location.nativeElement.querySelector('.tooltip');
      this.renderer.listen(this.tooltipElement, "click", (e) => {
        if (e.target.tagName !== 'A') {
            e.preventDefault();
        }
        e.stopPropagation();
      });
      this.renderer.listen(this.tooltipElement, 'mouseenter', this.mouseTooltipEnter);
      this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');
      this.tooltipContent = this.tooltipElement.querySelector('.tooltip-content');
    }

    this.visible = true;
    this.checkPosition();
    this.visibilityChange.emit({
        visible: this.visible,
        checkPosition: this.checkPosition
    });
  }

  @HostListener('mouseleave')
  closeTooltip() {
      if (!this.content || !this.visible) {
        return;
      }
      this.visible = false;
      this.checkPosition();
      this.visibilityChange.emit({
          visible: this.visible,
          checkPosition: this.checkPosition
      });
  }

  checkPosition() {
    this.position === 'center' ? this.setCenterPosition() : this.setBorderPosition();
  }

  // verticaly and horizontaly fix position and height of tooltip to be inside window
  setCenterPosition() {
      setTimeout(() => {
          if (!this.visible || (this.data && this.data.hasOwnProperty('visible') && !this.tooltipHidden())) {
              this.renderer.removeClass(this.tooltipElement, 'tooltip-show');
              this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');
              return;
          }
          this.renderer.removeStyle(this.tooltipElement, 'visibility');
          this.renderer.addClass(this.tooltipElement, 'tooltip-show');
          const tooltipScrollable = this.elementRef.nativeElement.querySelector('div.scrollable');
          const dimensions = this.windowDimensionService.get(this.elementRef.nativeElement, 5);

          // position horizontaly
          this.renderer.removeClass(this.tooltipElement, 'left');
          if ($(this.tooltipElement).offset().left + $(this.tooltipElement).outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft) {
              this.renderer.addClass(this.tooltipElement, 'left');
          }

          // position verticaly
          if (tooltipScrollable) {
              $(tooltipScrollable).css({
                  maxHeight: Math.min(300, dimensions.windowHeight - ($(this.tooltipContent).innerHeight() - $(this.tooltipContent).height())) + 'px' // minus padding
              });
          }
          // top overflow
          var newTooltipMarginTop = Math.min($(this.tooltipContent).height(),
                  2 * (dimensions.windowOffsetTop - $(this.tooltipElement).offset().top)
                  - this.tooltipMarginTop);

          if (newTooltipMarginTop < 0) {
              newTooltipMarginTop = 0;

              // bottom overflow
              newTooltipMarginTop = Math.max(-$(this.tooltipContent).height(), 2 * ((dimensions.windowOffsetTop + dimensions.windowHeight)
                      - ($(this.tooltipElement).offset().top + $(this.tooltipContent).outerHeight()))
                      - this.tooltipMarginTop);

              if (newTooltipMarginTop > 0) {
                  newTooltipMarginTop = 0;
              }
          }

          this.renderer.setStyle(this.tooltipContent, 'marginTop', (this.tooltipMarginTop = newTooltipMarginTop) + 'px');
      });
  }

  setBorderPosition() {
    setTimeout(() => {
        if (!this.visible || (this.data && this.data.hasOwnProperty('visible') && !this.tooltipHidden())) {
            this.renderer.removeClass(this.tooltipElement, 'tooltip-show');
            this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');
            return;
        }
        this.renderer.removeStyle(this.tooltipElement, 'visibility');
        this.renderer.addClass(this.tooltipElement, 'tooltip-show');
        const tooltipScrollable = this.elementRef.nativeElement.querySelector('div.scrollable');
        const dimensions = this.windowDimensionService.get(this.elementRef.nativeElement, 5);

        // position horizontaly
        this.renderer.removeClass(this.tooltipElement, 'left');
        if ($(this.tooltipElement).offset().left + $(this.tooltipElement).outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft) {
            this.renderer.addClass(this.tooltipElement, 'left');
        }

        // position verticaly
        if (tooltipScrollable) {
            $(tooltipScrollable).css({
                maxHeight: Math.min(300, dimensions.windowHeight - ($(this.tooltipContent).innerHeight() - $(this.tooltipContent).height())) + 'px' // minus padding
            });
        }

        // place tooltip to bottom
        let newTooltipMarginTop = ($(this.tooltipContent).outerHeight() - $(this.elementRef.nativeElement).height()) / 2;

        //actualy bottom or top?
        const actualyTop = parseInt($(this.tooltipElement).css("marginTop"), 10)< 0;

        if(actualyTop) {
          //check if tooltip in top position fit to window
          if (dimensions.windowHeight < ($(this.tooltipElement).offset().top + $(this.tooltipElement).height() - dimensions.windowOffsetTop)) {
            //don't fit -> let it in top
            newTooltipMarginTop = -newTooltipMarginTop;
          } else {
            //fit
            //try bottom position
            if (dimensions.windowHeight >= ($(this.tooltipElement).offset().top + $(this.tooltipElement).height() - dimensions.windowOffsetTop + $(this.tooltipElement).height() - $(this.elementRef.nativeElement).height())) {
              //tooltip coul be in bottom position
              newTooltipMarginTop = newTooltipMarginTop;
            } else {
              //don't fit -> let it in top
              newTooltipMarginTop = -newTooltipMarginTop;
            }
          }
        } else {
          //try if bottom tooltip fit to window
          if(dimensions.windowHeight < ($(this.elementRef.nativeElement).offset().top + $(this.tooltipElement).height() - dimensions.windowOffsetTop)) {
            // bottom overflow -> place tooltip top
            newTooltipMarginTop = -newTooltipMarginTop;
          }
        }
        this.renderer.setStyle(this.tooltipElement, 'marginTop', (this.tooltipMarginTop = newTooltipMarginTop) + 'px');
    });
  }

  tooltipHidden() {
    if (this.data && this.data.hasOwnProperty('visible')) {
      return this.data.visible !== false
    } else {
      return null;
    }
  }

  mouseTooltipEnter(evt) {
    if (!this.activeContent) {
      this.closeTooltip();
    }
  }
}
