import { TitleNamePipe } from './../../../../common/pipes/title-name.pipe';
import { Component, Inject, OnInit } from '@angular/core';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import {
  opinionRequestStatusOptions
} from '@app/ps/enums/opinion-request-status.enum';
import { SelectItem } from '@app/common/components/select/select.component';
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ExportListService } from '@app/ps/services/export-list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'opinion-request-list',
  templateUrl: './opinion-request-list.component.html',
  styleUrls: ['./opinion-request-list.component.scss']
})
export class OpinionRequestListComponent implements OnInit {
  list: ListModel<OpinionRequestModel>;
  pageableList: PageableList;
  isArray = Array.isArray;
  statusOptions: SelectItem[] = opinionRequestStatusOptions;
  exportEnable = true;
  settingsLoaded = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
    public parcelNumberPipe: ParcelNumberPipe,
    public subjectNamePipe: SubjectNamePipe,
    public requestService: OpinionRequestService,
    public titleNamePipe: TitleNamePipe,
    private pageableListService: PageableListService,
    private exportListService: ExportListService,
    private settingsService: SettingsService,
  ) {
    this.onToggleDeleted = this.onToggleDeleted.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }

    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onToggleDeleted() {
    const deleted = this.list.filter.filters.deleted;
    deleted.values = (deleted.values.length ? [] : [ false ]);
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('parcels');
    return this.exportListService
      .exportList('opinion-requests', 'opinionRequests', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('opinionRequests', columnId);
  }

  private getDefaultFilter() {
    return {
      filters: {
        loadCollections: [],
        searchText: { values: [{ id: '' }] },
        areaId: { values: [] },
        titleId: { values: [] },
        parcelId: { values: [] },
        status: { values: [] },
        expertId: { values: [] },
        deleted: { values: [ false ] }
      }
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get(
      'opinion-requests',
      this.getDefaultFilter(),
      'opinionRequestsFilter'
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
