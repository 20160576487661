import { OwnerListComponent } from './components/owner-list/owner-list.component';
import { OwnerDetailComponent } from './components/owner-detail/owner-detail.component';
import { ProblematicNoteComponent } from './components/problematic-note/problematic-note.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ActingPersonModel } from "@app/ps/models/acting-person.model";
import { resolveCallbacks } from '@app/common/utils/state.utils';
import { Restangular } from 'ngx-restangular';
import {Ng2StateDeclaration, StateParams, StateService, Transition} from '@uirouter/angular';
import {UIRouter} from "@uirouter/core";

export function resolveOwnerFunction(stateParams: StateParams, restangular: Restangular) {
  const ownerId = stateParams.ownerId;
  const collections = {
    actingPersons: {
      load: true
    },
    globalSubjectDetail: {},
  };
  return restangular.all(`subjects/${ownerId}`).post(collections).toPromise().then(data => {
    data.actingPersons = data.actingPersons && data.actingPersons.map(ActingPersonModel.fromFlatObject);
    return data;
  });
}

export function resolveNoteFunction(restangular, owner) {
  const filters = {
    attributes: {},
    filter: {
      offset: 0,
      limit: 1,
      sortOrder: [{
        sortBy: 'timestamp',
        direction: 'desc'
      }],
      filters: {
        subjectId: {
          values: [owner.id]
        },
        systemType: false,
        noteTypeKey: 'SubjectProblematic',
      }
    }
  };
  return restangular.all('notes').post(filters).toPromise();
}

export function resolvePreviousTabFunction(stateParams: StateParams) { return stateParams.tab; }

export function problematicOwnerDialogFunction(transition: Transition) {
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const owner = transition.injector().get('owner');
  const note = transition.injector().get('note');
  const callbacks = transition.injector().get('callbacks');
  const previousTab = transition.injector().get('previousTab');

  const dialog = dialogService.open(ProblematicNoteComponent, {
    data: {
      owner,
      note,
    },
    callbacks: callbacks,
  });

  const sub = dialog.afterClosed.subscribe((res: boolean) => {
    stateService.go('symap.project.owners.detail', { tab: previousTab});
    sub.unsubscribe();
  });
}

export const ownerListState: Ng2StateDeclaration = {
  name: 'symap.project.owners',
  url: '/owners?page&sef',
  data: {
    title: 'Vlastníci',
  },
  views: {
    'content@symap.project': {
      component: OwnerListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    sef: {
      type: 'string',
      inherit: false,
      array: true
    },
  },
};

export const ownerDetailState: Ng2StateDeclaration = {
  name: 'symap.project.owners.detail',
  url: '/:ownerId/:tab',
  data: {
    title: 'Vlastník',
  },
  views: {
    'content@symap.project': {
      component: OwnerDetailComponent,
    }
  },
  resolve: {
    owner: ['$stateParams', Restangular, resolveOwnerFunction],
    callbacks: [resolveCallbacks],
  },
  params: {
    ownerId: {
      type: 'int',
      value: undefined
    },
    tab: {
      type: 'string',
      value: undefined,
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const problematicOwnerNoteState: Ng2StateDeclaration = {
  name: 'symap.project.owners.detail.problematic',
  url: '/problematic',
  data: {
    title: 'Problematický vlastník',
  },
  resolve: {
    note: [ Restangular, 'owner', resolveNoteFunction],
    previousTab: ['$stateParams', resolvePreviousTabFunction],
  },
  onEnter: problematicOwnerDialogFunction
};
