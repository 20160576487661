import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gmt-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {

  @Input() total: number;
  @Input() done: number;
  @Input() background = 'STARTED';

  isNA: boolean;
  percentage: number;

  public ngOnInit() {
    this.isNA = this.total === 0;
    this.percentage = (this.total === 0 ? 0 : (this.done / this.total * 100));
  }
}
