﻿<div class="drop-container">
  <div class="drop-border-area">
    <div class="drop-area">
      <div class="drop-infobox">
        <i class="fa fa-3x fa-cloud-upload"></i>
        <p>{{message}}</p>
      </div>
      <ul class="file-upload-container">
        <li *ngFor="let file of files; index as i" class="file-upload" [ngClass]="{invalid: !file.valid}">
          <div>
            <i class="fa fa-file-o fa-lg"></i>
          </div>
          <div class="ml-10">
            <div>
              <span>{{file.fileEntry.name}}</span>
              <span *ngIf="file.validationMessage"> ({{file.validationMessage}})</span>
              <a class="ml-10" (click)="$event.stopPropagation();removeFile(file)">
                <i class="fa fa-times mr-10"></i>
              </a>
            </div>
            <div>
              <div class="progressbar" *ngIf="file.uploading">
                <div [ngStyle]="{'width': file.progress + '%', 'margin': '2px 0px'}"></div>
              </div>
              <div class="error" *ngIf="file.error">
                {{ file.errorMessage ? file.errorMessage : 'Chyba při nahrávání souboru' }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <input id="{{inputId}}" type="file" style="display: none;" (change)="inputFilesAdded($event);$event.stopPropagation();" (click)="$event.stopPropagation();" multiple />
</div>
