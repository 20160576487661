import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { TitleModel } from '@app/common/models/title.model';

import { getOrder } from '../../pure/listLoadOrder';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'title-list',
  templateUrl: './title-list.component.html',
  styleUrls: []
})
export class TitleListComponent implements OnInit {

  list: ListModel<TitleModel>;
  pageableList: PageableList;
  helpIds = HelpService.HELP_IDS;

  filterArea = {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('areas/list')};
  filterSubject = {sortOrder: getOrder('subjects/list')};
  filterParcel = {sortOrder: getOrder('parcels/list')};

  constructor(
    public titleNamePipe: TitleNamePipe,
    public subjectNamePipe: SubjectNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    private pageableListService: PageableListService,
  ) {
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private getDefaultFilter() {
    return {
      filters: {
        areaId : {},
        subjectId : {},
        parcelId : {},
        requestArea : {values: [{id: true}]},
        searchText: {values: [{id: ''}]}
      },
      sortOrder: getOrder('titles/list'),
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get(
      'titles/list',
      this.getDefaultFilter(),
      'titlesFilter',
      undefined,
      { area: {}}
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
