<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset>
      <div class="column-100" *ngIf="data.obligation.type === 'FulfillmentOfDecisionAuctionSaleExecutionLiability'">
        <gmt-input label="Datum finanční kontroly">
          <input type="text" pick-a-date [(ngModel)]="data.financialControlDate" [maxToday]="true"/>
        </gmt-input>
      </div>

      <div class="column-100" *ngIf="data.obligation.type === 'FulfillmentOfDecisionAuctionSaleExecutionLiability'">
        <gmt-input label="Maximální částka pro dražbu" >
          <input type="text" [(ngModel)]="data.maxAuctionAmount"/>
        </gmt-input>
      </div>

      <div class="column-100">
        <gmt-input label="Datum podpisu" required="true">
          <input type="text" pick-a-date [(ngModel)]="data.signedInvestorDate" [maxToday]="true"/>
        </gmt-input>
      </div>
    </fieldset>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
