import { NgModule } from '@angular/core';

import {
  projectSettingsMainState,
  projectCreateState,
  projectUpdateState,
  administratorListState,
  administratorDetailState,
  expertListState,
  expertDetailState,
  templateListState,
  templateHistoryState,
  sendUserEmailState,
  userListState,
  userDetailState,
  userCredentialsState,
  updateUserPermissionsState,
  templateInternalListState,
  templateExternalListState,
  bulkPricesState,
} from '@app/ps/project-settings/project-settings.states';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { SvAppModule } from '@app/sv/sv.modules';
import { MapModule as MapLibModule } from '@app/map/map.module';

import { SendUserEmailComponent } from '@app/ps/project-settings/components/send-user-email/send-user-email.component';
import { AdministratorDetailComponent } from '@app/ps/project-settings/components/administrator-detail/administrator-detail.component';
import { AdministratorListComponent } from '@app/ps/project-settings/components/administrator-list/administrator-list.component';
import { ExpertDetailComponent } from '@app/ps/project-settings/components/expert-detail/expert-detail.component';
import { ExpertListComponent } from '@app/ps/project-settings/components/expert-list/expert-list.component';
import { TemplateHistoryComponent } from '@app/ps/project-settings/components/template-history/template-history.component';
import { TemplateListComponent } from '@app/ps/project-settings/components/template-list/template-list.component';
import { ProjectSettingsMainComponent } from '@app/ps/project-settings/components/project-settings-main/project-settings-main.component';
import { ProjectUpdateComponent } from '@app/ps/project-settings/components/project-update/project-update.component';
import { ProjectCreateComponent } from '@app/ps/project-settings/components/project-create/project-create.component';
import { StatisticsSettingsComponent } from '@app/ps/project-settings/components/statistics-settings/statistics-settings.component';
import { MapSettingsComponent } from '@app/ps/project-settings/components/map-settings/map-settings.component';
import { DashboardSettingsComponent } from '@app/ps/project-settings/components/dashboard-settings/dashboard-settings.component';
import { ObligationSettingsComponent } from '@app/ps/project-settings/components/obligation-settings/obligation-settings.component';
import { ObligationFlowSettingsComponent } from '@app/ps/project-settings/components/obligation-flow-settings/obligation-flow-settings.component';
import { DocumentSettingsComponent } from '@app/ps/project-settings/components/document-settings/document-settings.component';
import { ProjectRemoveComponent } from '@app/ps/project-settings/components/project-remove/project-remove.component';
import { NotificationConfigComponent } from '@app/ps/project-settings/components/notification-configuration/notification-config.component';
import { ProjectInfoSettingsComponent } from '@app/ps/project-settings/components/project-info-settings/project-info-settings.component';
import { ProjectSubjectSimpleOfoComponent } from '@app/ps/project-settings/components/project-subject-simple-ofo/project-subject-simple-ofo.component';
import { KnWsdpUpdateComponent } from '@app/ps/project-settings/components/kn-wsdp-update/kn-wsdp-update.component';
import { SampleListComponent } from '@app/ps/project-settings/components/sample-list/sample-list.component';
import { KnWsdpStatusModalComponent } from '@app/ps/project-settings/components/kn-wsdp-status-modal/kn-wsdp-status-modal.component';
import { StatisticsSettingsStatusEditComponent } from '@app/ps/project-settings/components/statistics-settings-status-edit/statistics-settings-status-edit.component';
import { ActivityLogComponent } from '@app/ps/project-settings/components/activity-log/activity-log.component';
import { BulkPriceComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price/bulk-price.component';
import { BulkPriceInitComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-init/bulk-price-init.component';
import { BulkPriceResultComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-result/bulk-price-result.component';
import { BulkPriceConfirmComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-confirm/bulk-price-confirm.component';
import { DataZeComponent } from '@app/ps/project-settings/components/data-ze/data-ze.component';
import { UIRouterModule } from '@uirouter/angular';
import {ReadinessComponent} from "@app/ps/project-settings/components/readiness/readiness.component";

const PROJECT_SETTINGS_STATES = [
  projectSettingsMainState,
  projectCreateState,
  projectUpdateState,
  administratorListState,
  administratorDetailState,
  expertListState,
  expertDetailState,
  templateListState,
  templateHistoryState,
  sendUserEmailState,
  userListState,
  userDetailState,
  userCredentialsState,
  updateUserPermissionsState,
  templateInternalListState,
  templateExternalListState,
  bulkPricesState
];

@NgModule({
  declarations: [
    ProjectSettingsMainComponent,
    AdministratorDetailComponent,
    AdministratorListComponent,
    ExpertDetailComponent,
    ExpertListComponent,
    TemplateHistoryComponent,
    TemplateListComponent,
    SendUserEmailComponent,
    ProjectUpdateComponent,
    ProjectCreateComponent,
    StatisticsSettingsComponent,
    MapSettingsComponent,
    DashboardSettingsComponent,
    ObligationSettingsComponent,
    ObligationFlowSettingsComponent,
    DocumentSettingsComponent,
    ProjectRemoveComponent,
    NotificationConfigComponent,
    ProjectInfoSettingsComponent,
    ProjectSubjectSimpleOfoComponent,
    KnWsdpUpdateComponent,
    SampleListComponent,
    KnWsdpStatusModalComponent,
    StatisticsSettingsStatusEditComponent,
    ActivityLogComponent,
    BulkPriceComponent,
    BulkPriceInitComponent,
    BulkPriceConfirmComponent,
    BulkPriceResultComponent,
    DataZeComponent,
    ReadinessComponent,
  ],
  entryComponents: [
    TemplateHistoryComponent,
    MapSettingsComponent,
    DashboardSettingsComponent,
    ObligationSettingsComponent,
    ObligationFlowSettingsComponent,
    DocumentSettingsComponent,
    ProjectRemoveComponent,
    ProjectSubjectSimpleOfoComponent,
    KnWsdpStatusModalComponent,
    StatisticsSettingsStatusEditComponent,
    DataZeComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    SvAppModule,
    MapLibModule,
    UIRouterModule.forChild({
      states: [...PROJECT_SETTINGS_STATES],
    }),
  ]
})
export class ProjectSettingsModule { }
