import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CellAbstractComponent, CellTooltipMappingType } from '../cell-abstract/cell-abstract.component';
import { TooltipParcelsBaseComponent } from '@app/common/components/tooltip-parcels-base/tooltip-parcels-base.component';
import { TooltipDimapParcelsComponent } from '@app/common/components/tooltip-dimap-parcels/tooltip-dimap-parcels.component';

@Component({
  selector: 'cell-parcels',
  templateUrl: './cell-parcels.component.html',
  styleUrls: ['./cell-parcels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellParcelsComponent extends CellAbstractComponent {

  // bindings
  @Input() countParcels: number;

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'parcels': TooltipParcelsBaseComponent,
    'parcels-dimap': TooltipDimapParcelsComponent,
  };
}
