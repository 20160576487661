﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-payment-instruction',
	templateUrl: './form-case-payment-instruction.component.html',
	styleUrls: ['./form-case-payment-instruction.component.scss']
})
export class FormCasePaymentInstructionComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    this.data.paymentInstructionDate = this.data.paymentInstructionDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.paymentInstructionDate;
  }
}
