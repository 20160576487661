import { Injectable } from '@angular/core';
import { ModulesService } from '@app/common/services/modules.service';
import { AuthService } from '@app/common/services/auth.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { EmailModel } from '@app/common/models/email.model';
import { User } from '@app/models/user';
import { EmailUtils } from '@app/common/utils/email.utils';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private modulesService: ModulesService,
  ) { }

  loadProjectEmails(): Promise<any> {
    const projectId = this.authService.getActualProject().id;
    return this.settingsService.loadSettings(projectId).then((settings) => {
      return (settings.email) || null;
    });
  }

  sendUserEmail(user: User, email: EmailModel, convertToHtml = true): Promise<void> {
    if (convertToHtml) {
      email = {...email, body: EmailUtils.textToHtml(email.body, user)};
    }
    return this.modulesService.sendUserEmail(user.id, email);
  }
}
