import { Component, Inject, Input, OnInit } from '@angular/core';
import { EmailModel } from '@app/common/models/email.model';
import { APP_BRAND } from '@app/common/services/config.service';


@Component({
  selector: 'gmt-email-form',
  templateUrl: './email-form.component.html'
})
export class EmailFormComponent implements OnInit {

  @Input() email: EmailModel;
  @Input() label: string;
  @Input() type: 'common' | 'welcome' = 'common';

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
  }

  ngOnInit() {
    if (this.type === 'welcome' && !this.email.subject && !this.email.body) {
      this.email.subject = this.APP_BRAND.PS.WELCOME_EMAIL.SUBJECT;
      this.email.body = this.APP_BRAND.PS.WELCOME_EMAIL.BODY;
    }
  }
}
