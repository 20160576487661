﻿import { Component, Input, Output, EventEmitter } from "@angular/core";
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'construction-object-detail-create-case-summary',
	templateUrl: './create-case-summary.component.html',
})
export class CreateCaseSummaryComponent {
  @Input()
  public data: any;
  @Output()
  public submitCallback = new EventEmitter();

  constructor(
    private stateService: StateService,
  ) {
    this.submit = this.submit.bind(this);
    this.goToCasesBulkOperation = this.goToCasesBulkOperation.bind(this);
  }

  public submit() {
    this.submitCallback.emit();
  }

  public goToCasesBulkOperation() {
    let ids: number[] = this.data.createdCases.map((businessCase : any) : number => businessCase.id);
    this.stateService.go('symap.project.cases.bulkOperation',
      {
        entityIds: ids,
      }
    );
  }
}
