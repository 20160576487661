import { Directive, ElementRef, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[loading]'
})
export class LoadingDirective {
	@Input('loading') loading: boolean;

	defaultClass: string;

	@HostBinding('class')
	get elementClass(): string {
			return this.loading ? this.defaultClass + ' content-loading' : this.defaultClass;
	}

	constructor(private elRef: ElementRef) {
		this.elRef.nativeElement.innerHTML += '<div class="loader"><span class="loading-spinner"></span></div>';
	    this.defaultClass = this.elRef.nativeElement.className;
	}
}
