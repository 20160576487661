﻿<div class="detail">
  <div>
    <a *ngIf="!caseId" uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>List vlastnictví</a>
    <a *ngIf="caseId" uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Případ</a>
  </div>
  <h1 *ngIf="!caseId">Založení případu z LV{{title.lv}}</h1>
  <h1 *ngIf="caseId">Založení případu vyvlastnění</h1>
  <div class="mt-20" [loading]="loading">
    <div class="flexbox-container">
      <div class="content-menu" *ngIf="!caseId">
        <div *ngFor="let menuStep of menuSteps" (click)="onClickStep(menuStep)" [ngClass]="{active : menuStep.ids.includes(step?.key), cancelled: !menuStep.enabled || (sent && !menuStep.ids.includes(step?.key))}">
          {{menuStep.name}}
        </div>
      </div>
      <div class="flex-rest">
        <div class="mb-10" *ngIf="caseId && step?.key === 'detail'">Vyvlastnění bude založeno v souladu s případem č. {{data.fromCase.mpp.number||'bez čísla'}}, se kterým bude propojeno. Do ukončení procesu vyvlastnění nebude možné měnit stav původního případu.</div>
        <title-detail-create-case-select-obligation [data]="data" [titleId]="titleId" [generalDataPromise]="generalDataPromise" (submitCallback)="onSelectObligation()" *ngIf="step?.key === 'select-obligation'"></title-detail-create-case-select-obligation>
        <title-detail-create-case-select-objects [data]="data" [titleId]="titleId" [generalDataPromise]="generalDataPromise"  [titleDataPromise]="titleDataPromise" (submitCallback)="onSelectObjects()" *ngIf="step?.key === 'select-objects'"></title-detail-create-case-select-objects>
        <title-detail-create-case-select-titles [data]="data" [forwardStep]="displayConfirm" (submitCallback)="onSelectTitles($event?.skipped)" *ngIf="step?.key === 'select-titles'"></title-detail-create-case-select-titles>
        <title-detail-create-case-select-parent-ownership [data]="data" [forwardStep]="displayConfirm" (submitCallback)="onSelectParentOwnership($event?.skipped)" *ngIf="step?.key === 'select-parent-ownership' || step?.key === 'select-parent-ownership2'"></title-detail-create-case-select-parent-ownership>
        <title-detail-create-case-detail [data]="data" [forwardStep]="displayConfirm" (submitCallback)="onDetail($event?.error)" *ngIf="step?.key === 'detail'"></title-detail-create-case-detail>
        <title-detail-create-case-confirm [data]="data" (submitCallback)="onConfirm()" *ngIf="step?.key === 'confirm'"></title-detail-create-case-confirm>
        <title-detail-create-case-summary [data]="data" (submitCallback)="onSummary()" *ngIf="step?.key === 'summary'"></title-detail-create-case-summary>
      </div>
    </div>
  </div>
  <help [helpId]="helpId" class="cm-help-page" (callbackRegister)="onCallbackRegister($event)"></help>
</div>
