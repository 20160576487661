<table>
  <thead>
    <tr>
      <th>Osoba</th>
      <th>Typ</th>
      <th>Podíl</th>
      <th>Adresa</th>
      <th>RČ / IČ</th>
      <th *ngIf="businessCase.obligation.computePrice && !easementPriceByShare">Cena [<span class="unit">Kč</span>]</th>
      <th *ngIf="easementPriceByShare">Cena [<span class="unit">Kč</span>]</th>
      <th *ngIf="businessCase.obligation.computePrice && easementPriceByShare">Číslo SOSB</th>
      <th *ngIf="businessCase.obligation.computePrice && easementPriceByShare">Stav SOSB</th>
    </tr>
  </thead>
  <tbody *ngFor="let mappedCaseOwnership of flatCaseOwnerships" class="hover">
    <tr *ngFor="let itemSubject of mappedCaseOwnership.caseOwnership.ownership.subjects; let $index = index; let $count = count">
      <td><a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></td>
      <td *ngIf="$index === 0" [attr.rowspan]="$count">{{mappedCaseOwnership.caseOwnership.ownership.typravNazev}}</td>
      <td *ngIf="$index === 0" [attr.rowspan]="$count">
        <span>{{mappedCaseOwnership.caseOwnership.ownership.podilCitatel}}/{{mappedCaseOwnership.caseOwnership.ownership.podilJmenovatel}}</span>
        <span *ngIf="businessCase.titles.length > 1"> (LV {{mappedCaseOwnership.caseOwnership.ownership.title.lv}} - {{mappedCaseOwnership.caseOwnership.ownership.title.area.name}})</span>
      </td>
      <td>
        {{itemSubject|address}}
      </td>
      <td>
        {{itemSubject.rodneCislo|personalNumber}}{{itemSubject.rodneCislo && itemSubject.ico !== 1 && itemSubject.ico ? ' / ' : ''}}{{itemSubject.ico !== 1 ? itemSubject.ico : ''}}
      </td>
      <td *ngIf="$index === 0 && businessCase.obligation.computePrice && !easementPriceByShare" [attr.rowspan]="$count" class="number">{{mappedCaseOwnership.caseOwnership.easementPrice|number}}</td>
      <td *ngIf="$index === 0 && easementPriceByShare" [attr.rowspan]="$count"><input type="text" class="w-inherit" size="6" [(ngModel)]="mappedCaseOwnership.caseOwnership.easementPriceByShare" (change)="easementPriceByShareUpdated.emit()" [gmtInputNumber]="true"/></td>
      <td *ngIf="$index === 0 && businessCase.obligation.computePrice && easementPriceByShare" [attr.rowspan]="$count"><input type="text" class="w-inherit" size="23" [(ngModel)]="mappedCaseOwnership.caseOwnership.easementFutureContractNumber" /></td>
      <td *ngIf="$index === 0 && businessCase.obligation.computePrice && easementPriceByShare" [attr.rowspan]="$count"><input type="text" class="w-inherit" size="23" [(ngModel)]="mappedCaseOwnership.caseOwnership.easementFutureContractStatus" /></td>
    </tr>
    <tr *ngFor="let itemCaseOwnership of mappedCaseOwnership.parentCaseOwnerships" class="cancelled">
      <td><a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemCaseOwnership.ownership.subjects[0].id, tab: 'about'}">{{itemCaseOwnership.ownership.subjects[0] | subjectName}}</a></td>
      <td>{{itemCaseOwnership.ownership.typravNazev}}</td>
      <td></td>
      <td>
        {{itemCaseOwnership.ownership.subjects[0] | address}}
      </td>
      <td>
        {{itemCaseOwnership.ownership.subjects[0].rodneCislo|personalNumber}}{{itemCaseOwnership.ownership.subjects[0].rodneCislo && itemCaseOwnership.ownership.subjects[0].ico !== 1 && itemCaseOwnership.ownership.subjects[0].ico ? ' / ' : ''}}{{itemCaseOwnership.ownership.subjects[0].ico !== 1 ? itemCaseOwnership.ownership.subjects[0].ico : ''}}
      </td>
      <td *ngIf="businessCase.obligation.computePrice || easementPriceByShare"></td>
      <td *ngIf="businessCase.obligation.computePrice && easementPriceByShare"></td>
      <td *ngIf="businessCase.obligation.computePrice && easementPriceByShare"></td>
    </tr>
  </tbody>
</table>
