import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CaseService } from '@app/ps/services/case.service';
import { Restangular } from 'ngx-restangular';

@Component({
	selector: 'form-case-undelivered-owner',
	templateUrl: './form-case-undelivered-owner.component.html',
})
export class FormCaseUndeliveredOwnerComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  // data shared for each case
  data: any;
  @Input()
  // data set per case
  dataCases: any[];
  @Input()
  // list of cases to fill in
  cases: any[];
  caseIndex = -1;
  firstCaseId;
  private reloadRecipients: Function;

  constructor(
    private restangular: Restangular,
    private caseService: CaseService,
  ) {
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.isValid = this.isValid.bind(this);
    this.reloadOptionsRegister = this.reloadOptionsRegister.bind(this);
  }

  ngOnInit() {
    this.data.undeliveredOwnerDate = this.data.sentOwnerDate || new Date();
    this.data.note = {};

    this.callbackRegister.emit({
      isValid: this.isValid,
    });

    this.next();
  }

  async loadRecipients(caseIndex: number) {
    const bcase = this.cases[caseIndex];
    if (bcase.recipients) {
      return;
    }
    await this.restangular.one('cases', bcase.id).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise()
      .then((dataCase) => {
        const dataCaseOwnerships = this.caseService.getDataCaseOwnerships(dataCase.caseOwnerships, dataCase.caseSubjects);
        bcase.recipients = this.caseService.getRecipients(dataCaseOwnerships);

        let tmpRecipients = bcase.recipients.filter((value) => !value.receivedDate && (value.sentDate || value.personalVisitDate));
        if (tmpRecipients.length) {
          bcase.recipients = tmpRecipients;
        }
      });
  }

  reloadOptionsRegister(reload) {
    this.reloadRecipients = reload;
  }

  async previous() {
      if (this.caseIndex > this.firstCaseId) {
       await this.loadRecipients(this.caseIndex - 1);
       this.caseIndex--;
       if (this.cases[this.caseIndex].recipients.length === 1) {
         this.previous();
       } else if (this.reloadRecipients) {
         setTimeout(this.reloadRecipients);
       }
     }
   }

   async next() {
     if (this.caseIndex >= 0) {
       this.dataCases[this.caseIndex] = {
         recipientOpsubId: this.cases[this.caseIndex].recipient.opsubIds || [this.cases[this.caseIndex].recipient.id],
       };
     }
     if (this.caseIndex + 1 < this.cases.length) {
       await this.loadRecipients(this.caseIndex + 1);
       this.caseIndex++;
       if (this.cases[this.caseIndex].recipients.length === 1) {
         this.cases[this.caseIndex].recipient = this.cases[this.caseIndex].recipients[0];
         this.next();
       } else {
         if (this.reloadRecipients) {
           setTimeout(this.reloadRecipients);
         }
         if (this.firstCaseId === undefined) {
           this.firstCaseId = this.caseIndex;
         }
       }
     } else {
       this.reloadRecipients = undefined;
       this.caseIndex++;
     }
   }

  isValid() {
    return !!this.data.undeliveredOwnerDate && !!this.data.note.text && !!this.data.note.noteType && this.caseIndex === this.cases.length;
  }
}
