import { ChangeDetectorRef, Component, Inject } from '@angular/core';

import { ParcelModel } from '@app/common/models/parcel.model';
import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';
import { ListService } from '@app/common/services/list.service';

@Component({
  selector: 'tooltip-dimap-parcels',
  templateUrl: './tooltip-dimap-parcels.component.html',
})
export class TooltipDimapParcelsComponent extends TooltipAbstractListComponent<ParcelModel> {
  protected resource = 'parcels/list';

  constructor(
    cdr: ChangeDetectorRef,
    listLoadService: ListService,
    @Inject('tooltipConfig') tooltipConfig: any
  ) {
    super(cdr, listLoadService, tooltipConfig);
    this.loadNext = this.loadNext.bind(this);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  loadNext() {
    this.listLoadService.fetchResult(this.data.list, true).then(() => {
      this.cdr.detectChanges();
      this.tooltipConfig.checkPosition();
    });
  }
}

