<ng-template #ownershipCell>
  <div class="fill-content p-10 ownership-count-column">
    <div class="icon-count">
      <i class="fa" [ngClass]="{'fa-user' : count === 1, 'fa-users' : count > 1}"></i>
    </div>
    <div class="number ml-4">{{count}}</div>
  </div>
  <div class="fill-content flex-centered p-10 ownership-list-column">
    <ul *ngIf="ownerships" class="flex-rest">
      <li *ngFor="let ownership of filteredOwnerships | slice:0:(ownerships.length > limit ? limit - 1 : limit); first as isFirst"
        [ngClass]="{'mt-5' : !isFirst}" class="cell--flex__container">
        <div class="cell--flex__container__main">
          <span *ngFor="let subject of ownership.subjects; last as isLast">{{subject | subjectName}}{{isLast ? '' : ' a '}}</span>
          ({{ownership.podilCitatel}}/{{ownership.podilJmenovatel}})
        </div>
        <div class="cell--flex__container_side">
          <span title="Problematický vlastník" class="tag tag--danger tag--inline is-uppercase" *ngIf="ownership.subjects[0].subjectDetail?.problematic || ownership.subjects[1]?.subjectDetail?.problematic">PV</span>
        </div>
      </li>
      <li class="mt-5" *ngIf="ownerships.length > limit">a další ({{ownerships.length - limit + 1}})</li>
    </ul>
  </div>
</ng-template>

<div *ngIf="!tooltipComponent || !tooltipData" class="fill-content">
  <ng-container *ngTemplateOutlet="ownershipCell"></ng-container>
</div>

<div *ngIf="tooltipComponent && tooltipData"
    [tooltip]="tooltipComponent"
    [activeContent]="true"
    [data]="tooltipData"
    class="tooltip-hover-area fill-content"
>
  <ng-container *ngTemplateOutlet="ownershipCell"></ng-container>
</div>
