import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FullMunicipalityPipe } from '@app/common/pipes/full-municipality.pipe';
import { FullStreetPipe } from '@app/common/pipes/full-street.pipe';

@Pipe({
  name: 'fulladdress'
})
@Injectable({
  providedIn: 'root'
})
export class FullAddressPipe implements PipeTransform {
  constructor(
    private fullStreetPipe: FullStreetPipe,
    private fullMunicipalityPipe: FullMunicipalityPipe
  ) {
  }

  transform(subject: any): string {
    if (!subject) {
      return '';
    }

    let fullAddress = this.fullStreetPipe.transform(subject);
    if (subject.quarter && subject.quarter !== subject.municipality && subject.street) {
      fullAddress += fullAddress !== '' ? ', ' : '';
      fullAddress += subject.quarter;
    }

    const municipality = this.fullMunicipalityPipe.transform(subject);
    fullAddress += fullAddress !== '' && municipality !== '' ? ', ' : '';
    return fullAddress + municipality;
  }
}
