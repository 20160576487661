import { Component } from '@angular/core';

import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';
import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';

@Component({
  selector: 'tooltip-parcel-prices',
  templateUrl: './tooltip-parcel-prices.component.html',
})
export class TooltipParcelPricesComponent extends TooltipAbstractListComponent<ParcelPriceModel> {
  protected resource = 'parcel-prices';
}
