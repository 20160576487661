import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import * as _ from "lodash";
import {RoleEnum} from "@app/common/enums/role.enum";


@Component({
  selector: 'case-list',
  templateUrl: './case-list.component.html',
  styleUrls: []
})
export class CaseListComponent implements OnInit {
  list: any;
  pageableList: PageableList;
  deferredUpdateStatistics: any;
  cadastreValidities = [
      {id: 'valid', name: 'Odpovídá stavu KN'},
      {id: 'invalid', name: 'Neodpovídá stavu KN'},
      {id: 'non-checked', name: 'Nesledováno'}
  ];
  inputValidities = [
      {id: 'valid', name: 'Odpovídá vstupním datům'},
      {id: 'invalid', name: 'Neodpovídá vstupním datům'}
  ];
  settingsLoaded = false;
  mandataryFilter: any;
  officerFilter: any;
  restBaseUrl: string;
  authToken: string;
  exportEnable = true;
  helpIds = HelpService.HELP_IDS;

  globalFilter = {
    obligationId: {values: []},
    caseStatusId: {values: []},
    areaId : {values: []},
    cadastreValid: {values: []},
    inputValid: {values: []},
    mandataryId: {values: []},
    officerId: {values: []},
    constructionObjectStageId: {values: []},
    constructionObjectId: {values: []},
  };

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private authService: AuthService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public userNamePipe: UserNamePipe,
    private exportListService: ExportListService
  ) {
    this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
    this.authToken = this.authService.getToken();
    const activeApplication = this.authService.getActiveApplication();
    const activeProjectKey = this.authService.getActualProject().key;
    this.mandataryFilter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [activeApplication],
        },
        permission: {
          values: ['assignable'],
        },
        projects: {
          values: [activeProjectKey],
        },
      },
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };

    this.officerFilter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [activeApplication],
        },
        roles: {
          values: [RoleEnum.REFERENT, RoleEnum.REFERENT_VEDOUCI],
        },
        projects: {
          values: [activeProjectKey],
        },
      },
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }
    const promise = new Promise((resolve, reject) => {
      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.deferredUpdateStatistics.promise = promise;
    this.initializeList();
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .exportList('cases', 'cases', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  private getDefaultFilter() {
    return {
      filters: {
        loadCollections: [
          'titles',
          'caseOwnerships',
          'constructionObjects',
          'caseSubjects',
        ],
        cancelStatus: {values: []},
        statisticsEntityFilterKey: {values: []},
        searchText: {values: [{id: ''}]},
        obligationId: { values: [], negation: false },
        caseStatusId: { values: [], negation: false },
        areaId: { values: [], negation: false },
        cadastreValid: {},
        inputValid: {},
        mandataryId: {},
        officerId: {},
        titleUserId: {},
        constructionObjectStageId: {},
        constructionObjectId: { values: [], negation: false },
      }
    };
  }

  private async initializeList() {
    await this.settingsService.getSettingsLoader();
    const filter = this.getDefaultFilter();
    this.pageableList = this.pageableListService.get('cases', filter, 'casesFilter');

    this.list = this.pageableList.list;

    _.assign(this.globalFilter, _.mapValues(this.globalFilter, (value, filterKey) => this.list.filter.filters[filterKey] || value));

    if (this.hideColumn('mandatary')) {
      this.pageableList.list.filter.filters.mandataryId = {};
    }
    if (this.hideColumn('officer')) {
      this.pageableList.list.filter.filters.officerId = {};
    }
    if (this.hideColumn('titleUser')) {
      this.pageableList.list.filter.filters.titleUserId = {};
    }
    await this.initStatisticsEntityFilter();
    this.resolveParams();
    this.pageableList.load();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  registerUpdateStatisticsEntityFilterKey(registerCallback) {
    this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    }
  }

  statisticsEntityFilterChanged(changed) {
    if (!changed.state) {
      this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
    }
  }

  checkCases() {
    const exists = this.list.filter.filters.cancelStatus;
    return exists && (!exists.values || exists.values.length === 0);
  }

  onHideDisabledCasesCheckboxClicked() {
    this.list.filter.filters.cancelStatus = {values: this.checkCases() ? [{id: 'notCancelled'}] : []};
  }

  private resolveParams() {
    if (this.stateService.params.obligationId) {
      this.list.filter.filters.obligationId.values = this.stateService.params.obligationId.map(id => {
        return { id: id };
      });
    } /*else if (this.stateService.params.sef) {
      this.list.filter.filters.obligationId.values.splice(0, this.list.filter.filters.obligationId.values.length);
    }*/

    if (this.stateService.params.cancelStatus) {
      this.list.filter.filters.cancelStatus.values = [{id: this.stateService.params.cancelStatus}];
    } /*else if (this.stateService.params.sef) {
      this.list.filter.filters.cancelStatus.values.splice(0, this.list.filter.filters.cancelStatus.values.length);
    }*/

    if (this.stateService.params.sefReset) {
      this.list.filter.filters.statisticsEntityFilterKey.values = [];
    }
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('cases', columnId);
  }

  /*generateAll() {
    if (!this.generating) {
      this.generating = true;
      this.restangular.all('cases').customGET('generate-all').toPromise().then(() => {
        this.generating = false;
      });
    }
  }*/
}
