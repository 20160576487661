import { Component, Input, OnInit } from '@angular/core';

import { User } from '@app/models/user';
import { EmailModel } from '@app/common/models/email.model';
import { EmailService } from '@app/ps/services/email.service';
import { ModulesService } from '@app/common/services/modules.service';

@Component({
  selector: 'send-user-email',
  templateUrl: './send-user-email.component.html'
})
export class SendUserEmailComponent implements OnInit {
  @Input() userId: number;

  welcomeEmail: EmailModel;
  user: User;
  loading = true;

  constructor(
    private modulesService: ModulesService,
    private emailService: EmailService
  ) {
    this.onSendWelcomeEmail = this.onSendWelcomeEmail.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
  }

  async ngOnInit() {
    const projectEmails = await this.emailService.loadProjectEmails();
    this.welcomeEmail = (projectEmails && projectEmails.welcomeEmail) || { subject: '', body: '' };
    this.user = await this.modulesService.getUser(this.userId);
    this.loading = false;
  }

  isEmailValid(email: EmailModel): boolean {
    return (!!email.body && !!email.subject);
  }

  onSendWelcomeEmail(): Promise<any> {
    return this.emailService.sendUserEmail(this.user, this.welcomeEmail);
  }
}
