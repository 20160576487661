﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';

@Pipe({ name: 'parentCaseOwnershipName' })
@Injectable({
  providedIn: 'root'
})
export class ParentCaseOwnershipNamePipe implements PipeTransform {

    constructor(private subjectNamePipe: SubjectNamePipe) {}

	transform(caseOwnership: any) : string {
        return this.subjectNamePipe.transform(caseOwnership && caseOwnership.ownership && caseOwnership.ownership.subjects[0]);
	}
}