import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DialogService } from '@app/common/services/dialog.service';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { SeriesService } from '@app/ps/services/series.service';
import { AdministratorEditComponent } from '@app/ps/components/administrator-edit/administrator-edit.component';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { SeriesModel } from '@app/ps/models/series.model';
import { SettingsService } from '@app/ps/services/settings.service';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'construction-object-update',
  templateUrl: './construction-object-update.component.html',
  styleUrls: []
})
export class ConstructionObjectUpdateComponent implements OnInit {

  @Input() constructionObjectId: number;

  loading = true;
  constructionObject: ConstructionObjectModel;
  loadingActingPersons = false;
  administratorTypes: SwitchOption[] = [
    {
      id: undefined,
      value: 'Neurčen',
    },
    {
      id: 'I',
      value: 'Investor je správcem',
    },
    {
      id: 'A',
      value: 'Jiný subjekt',
    },
  ];
  actingPersons: any;
  series: any;
  reloadAdministrators: Function;
  seriesParsed: SeriesModel[] = [];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    public projectSubjectNamePipe: ProjectSubjectNamePipe,
    private dialogService: DialogService,
    private seriesService: SeriesService,
    private settingsService: SettingsService,
  ) {
    this.reloadAdministratorRegister = this.reloadAdministratorRegister.bind(this);
    this.onChangeAdministratorType = this.onChangeAdministratorType.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onChangeAdministrator = this.onChangeAdministrator.bind(this);
  }

  async ngOnInit() {
    await this.settingsService.getSettingsLoader();
    this.restangular
      .one('construction-objects', this.constructionObjectId)
      .get({
        loadCollections: [
          'series',
          'actingPersons',
          'administrator',
          'administrator.actingPersons',
        ],
      })
      .toPromise()
      .then(data => {
        this.constructionObject = data.plain();
        this.actingPersons = new ChecklistModel(this.constructionObject.actingPersons);

        if (this.constructionObject.series) {
          this.series = this.seriesService.parseSingleSeries(this.constructionObject.series);
        }

        if (this.constructionObject.administrator && this.constructionObject.administratorType && this.constructionObject.administratorType === 'A') {
          this.constructionObject.administrator.actingPersons = this.uniqueMergeByKey(
            this.constructionObject.actingPersons,
            this.constructionObject.administrator.actingPersons,
            'id'
          );
        }

        if (!this.constructionObject.series) {
          this.constructionObject.series = [];
        }
        this.seriesService.parseSeries(this.constructionObject.series, this.seriesParsed);
        this.loading = false;
      });
  }

  reloadAdministratorRegister(reloadFn: Function) {
    this.reloadAdministrators = reloadFn;
  }

  onChangeAdministratorType() {
    this.constructionObject.administrator = undefined;
    this.actingPersons.empty();
  }

  onChangeAdministrator() {
    this.actingPersons.empty();

    if (this.constructionObject.administratorType && this.constructionObject.administratorType === 'A' && this.constructionObject.administrator) {
      this.constructionObject.administrator.actingPersons = undefined;
      this.loadingActingPersons = true;
      this.restangular
        .one('administrators', this.constructionObject.administrator.id)
        .all('acting-persons')
        .getList()
        .toPromise()
        .then(data => {
          this.constructionObject.administrator.actingPersons = data.plain().filter(ap => !ap.cancelled && ap.roleContract);
          this.loadingActingPersons = false;
        });
    }
  }

  onUpdateAdministrator() {
    const dialog = this.dialogService.open(AdministratorEditComponent, {
      data: { administratorId: this.constructionObject.administrator.id }
    });

    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        res.actingPersons = this.uniqueMergeByKey(this.constructionObject.actingPersons, res.actingPersons, 'id');
        _.extend(this.constructionObject.administrator, res);
      }
      sub.unsubscribe();
    });
  }

  onCreateAdministrator() {
    const dialog = this.dialogService.open(AdministratorEditComponent, { data: {} });
    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        this.constructionObject.administrator = res;
        this.onChangeAdministrator();
        this.reloadAdministrators();
      }
      sub.unsubscribe();
    });
  }

  onUpdate(): Promise<any> {
    const params = {
      loadCollections: [
        'actingPersons',
        'administrator',
      ],
      update: [
        'series',
        'administrator',
      ]
    };

    this.constructionObject.series = this.constructionObject.series.filter(item => item.id);
    this.seriesService.sectionsToSeries(this.seriesParsed, this.constructionObject.series);

    return this.restangular.one('construction-objects', this.constructionObject.id)
      .customPUT(this.constructionObject, '', params)
      .toPromise();
  }

  /**
   * Merges unique items from two object arrays by key.
   *
   * @param {array} arr1 - array of objects
   * @param {array} arr2 - array of objects
   * @param {string} key - key to compare by
   * @returns {array}
   */
  private uniqueMergeByKey(arr1, arr2, key) {
    const include = item => (!item.cancelled && item.roleContract) || this.actingPersons.isChecked(item);
    return _.filter(_.uniqBy(_.union(arr1, arr2), key), include);
  }

  public hideColumnList(columnID: string): boolean {
    const tableID = 'constructionObjects';
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }
}
