import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig } from '@app/common/models/dialog-config';
import { DialogService } from '@app/common/services/dialog.service';
import { ModulesService } from '@app/common/services/modules.service';

@Component({
  selector: 'approve-terms',
  templateUrl: './approve-terms.component.html',
})
export class ApproveTermsComponent implements OnInit {

  module;
  conditionLoading = true;
  conditions;

  constructor(
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private dialogService: DialogService,
    private modulesService: ModulesService,
  ) {
    this.onSave = this.onSave.bind(this);
    this.module = (this.dialogConfig.data as any).module;
  }

  ngOnInit() {
    this.modulesService.getApproveTermsConditions(this.module).then((conditions) => {
      this.conditions = conditions.termsAndConditions;
      this.conditionLoading = false;
    });
  }

  onSave() {
    this.dialogRef.close(true);
  }
}
