import {Component, Inject, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {AuthService} from '@app/common/services/auth.service';
import {TicketService} from '@app/sv/services/ticket.service';
import {TicketModel} from '@app/sv/models/ticket.model';
import {TicketStatusEnum} from '@app/sv/enums/ticket-status.enum';
import {UploadFileExtended} from '@app/common/components/fileupload/fileupload.component';
import {SampleStatusEnum} from '@app/sv/enums/sample-status.enum';
import {Restangular} from 'ngx-restangular';
import {DialogRef} from '@app/common/services/dialog-ref';
import {DialogConfig, DialogConfigData} from '@app/common/models/dialog-config';
import { APP_BRAND } from '@app/common/services/config.service';
import {NoteTypeEnum} from '@app/sv/enums/note-type.enum';
import {BrandTranslatePipe} from '@app/common/pipes/brand-translate.pipe';

@Component({
  selector: 'ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss']
})
export class TicketEditComponent implements OnInit {

  static STATUSES_CONFIG = [
    {
      status: TicketStatusEnum.SENT_TO_CONFIRMATION,
      fields: [
        'sentToConfirmationDate',
      ]
    },
    {
      status: TicketStatusEnum.REQUEST_FOR_CLARIFICATION,
      fields: [
        'requestForClarificationDate',
        'changeStatusDescription',
      ],
      noteType: NoteTypeEnum.REQUEST_FOR_CLARIFICATION,
    },
    {
      status: TicketStatusEnum.CLARIFICATION_REQUEST_DONE,
      fields: [
        'clarificationRequestDoneDate',
        'changeStatusDescription',
      ],
      noteType: NoteTypeEnum.CLARIFICATION_REQUEST_DONE,
    },
    {
      status: TicketStatusEnum.APPROVED,
      fields: [
        'approvedDate',
        'approvedChangeStatusDescription',
      ],
      noteType: NoteTypeEnum.SAMPLE_CHANGED_BY_APPROVER,
    },
    {
      status: TicketStatusEnum.DISAPPROVED,
      fields: [
        'disapprovedDate',
        'changeStatusDescription',
      ],
    },
    {
      status: TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING,
      fields: [
        'templateSentToProcessingDate',
      ]
    },
    {
      status: TicketStatusEnum.REQUEST_FOR_INFORMATION,
      fields: [
        'requestForInformationDate',
        'changeStatusDescription',
      ],
      noteType: NoteTypeEnum.REQUEST_FOR_INFORMATION,
    },
    {
      status: TicketStatusEnum.INFORMATION_REQUEST_DONE,
      fields: [
        'informationRequestDoneDate',
        'changeStatusDescription',
      ],
      noteType: NoteTypeEnum.INFORMATION_REQUEST_DONE,
    },
    {
      status: TicketStatusEnum.IMPORTED,
      fields: [
        'documentTypeOrder',
        'importedDate',
        'sampleNumber',
        'sampleDescription',
        'templateAttachment',
        'fullDocumentName',
        'documentNameSpecification',
      ]
    },
    {
      status: TicketStatusEnum.DELETED,
      fields: [
        'deletedDate',
      ]
    },
  ];

  static readonly MSG_TEMPLATE_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor docx.';
  static readonly MSG_TEMPLATE_FILES_EXTENSION = 'Soubor musí být ve formátu DOCX.';
  static readonly MSG_SAMPLE_FILES_DEFAULT = 'Vložte vzor přetažením, nebo jej vyberte z počítače. Povolen je jeden dokument typu doc, docx.';
  static readonly MSG_SAMPLE_FILES_EXTENSION = 'Soubor musí být ve formátu doc, docx.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  newStatus: string;
  ticket: TicketModel;

  documentTypeOrder: string;
  loading = true;
  note: string;

  templateFiles: UploadFileExtended[] = [];
  templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_DEFAULT;
  templateFilesValid = false;

  sampleFiles: UploadFileExtended[] = [];
  sampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_DEFAULT;
  sampleFilesValid = true;

  revisionSampleFiles: UploadFileExtended[] = [];
  revisionSampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_DEFAULT;
  revisionSampleFilesValid = true;

  constructor(
    private restangular: Restangular,
    @Inject(APP_BRAND) private APP_BRAND: any,
    public ticketService: TicketService,
    public authService: AuthService,
    public dialogComponent: DialogRef,
    private dialogConfig: DialogConfig,
    private brandTranslatePipe: BrandTranslatePipe,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
    if (this.newStatus === TicketStatusEnum.APPROVED && this.APP_BRAND.NAME === 'SZ') {
      const approvedConfig = TicketEditComponent.STATUSES_CONFIG.find(c => c.status === TicketStatusEnum.APPROVED);
      approvedConfig.fields.push('sampleAttachment');
      approvedConfig.fields.push('revisionSampleAttachment');
    }

    const data = (this.dialogConfig.data as DialogConfigData);
    this.ticket = data.ticket;
    this.newStatus = data.newStatus;

    if (this.newStatus === TicketStatusEnum.IMPORTED) {
      this.documentTypeOrder = await this.loadDocumentTypeOrder();
      this.ticket.sampleNumber = this.getSampleNumber();
    }

    this.loading = false;
  }

  isValid(): boolean {
    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    const fieldsValidity = statusConfig.fields
      .filter(n => n !== 'templateAttachment'
        && n !== 'sampleAttachment'
        && n !== 'revisionSampleAttachment'
        && n !== 'documentTypeOrder'
        && n !== 'fullDocumentName'
        && n !== 'documentNameSpecification'
        && n !== 'approvedChangeStatusDescription'
      ).map(n => !!this.ticket[n])
      .every(b => b);


    if (this.newStatus === TicketStatusEnum.IMPORTED) {
      return fieldsValidity && (this.ticket.assignable === false || this.templateFilesValid);
    }

    if (this.newStatus === TicketStatusEnum.APPROVED) {
      const descriptionValidity = (this.sampleFiles.length === 0 && this.revisionSampleFiles.length === 0) || this.ticket.changeStatusDescription;
      return fieldsValidity && descriptionValidity && this.sampleFilesValid && this.revisionSampleFilesValid;
    }

    return fieldsValidity;
  }

  isFieldVisible(field: string): boolean {
    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    return statusConfig.fields.includes(field);
  }

  onSave() {
    if (!this.isValid()) {
      return;
    }

    if (this.newStatus) {
      this.ticket.status = this.newStatus as TicketStatusEnum;
    }

    const statusConfig = this.getStatusConfig();
    const noteType = statusConfig.noteType ? statusConfig.noteType : null;
    const prefix = statusConfig.noteType && [NoteTypeEnum.CLARIFICATION_REQUEST_DONE, NoteTypeEnum.INFORMATION_REQUEST_DONE].includes(statusConfig.noteType) ? 'Odpověd: ' : 'Důvod: ';

    this.dialogComponent.close({
      ticket: this.ticket,
      templateFiles: this.templateFiles,
      sampleFiles: this.sampleFiles,
      revisionSampleFiles: this.revisionSampleFiles,
      note: this.ticket.changeStatusDescription ? { ticketId: { id: this.ticket.id }, systemType: false, text: (prefix + this.ticket.changeStatusDescription), noteType: noteType } : undefined,
    });
  }

  onTemplateFilesChange(files: UploadFileExtended[]) {
    this.templateFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'docx');

    if (this.templateFiles.length === 0) {
      this.templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_EXTENSION;
    } else if (this.templateFiles.length > 1) {
      this.templateFilesMsg = TicketEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.templateFilesMsg = '';
    }

    this.templateFilesValid = (extensionValid && this.templateFiles.length === 1);
  }

  onSampleFilesChange(files: UploadFileExtended[]) {
    this.sampleFiles = files;

    const extensionValid = files.every(f => ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()));

    if (this.sampleFiles.length === 0) {
      this.sampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.sampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_EXTENSION;
    } else if (this.sampleFiles.length > 1) {
      this.sampleFilesMsg = TicketEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.sampleFilesMsg = '';
    }

    this.sampleFilesValid = (extensionValid && this.sampleFiles.length < 2);
  }

  onRevisionSampleFilesChange(files: UploadFileExtended[]) {
    this.revisionSampleFiles = files;

    const extensionValid = files.every(f => ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()));

    if (this.revisionSampleFiles.length === 0) {
      this.revisionSampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.revisionSampleFilesMsg = TicketEditComponent.MSG_SAMPLE_FILES_EXTENSION;
    } else if (this.revisionSampleFiles.length > 1) {
      this.revisionSampleFilesMsg = TicketEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.revisionSampleFilesMsg = '';
    }

    this.revisionSampleFilesValid = (extensionValid && this.revisionSampleFiles.length < 2);
  }

  onCancel() {
    this.dialogComponent.close(false);
  }

  private getStatusConfig() {
    const config = TicketEditComponent.STATUSES_CONFIG.find(sc => sc.status === this.newStatus);

    if (this.newStatus === TicketStatusEnum.IMPORTED && this.ticket.assignable === false) {
      _.remove(config.fields, f => f === 'templateAttachment');
    }

    return config;
  }

  private getSampleNumber() {
    if (this.ticket.actualizedSample) {
      let prevVersion = parseInt(this.ticket.actualizedSample.number.substr(-2));
      prevVersion++;
      return this.ticket.actualizedSample.number.slice(0, -2) + String(prevVersion).padStart(2, '0');
    }
    const documentTypeOrder = String(this.documentTypeOrder).padStart(2, '0');

    if (this.APP_BRAND.NAME === 'SZ') {
      return this.ticket.organizationalUnitCode
        + '-'
        + this.ticket.documentType.name
        + '.'
        + documentTypeOrder
        + '-'
        + 'v01';
    }

    const sectionCode = this.brandTranslatePipe.transform('sv.section.additional') === this.ticket.section ? 'P' : 'V';
    return this.ticket.organizationalUnitCode
      + '-'
      + sectionCode
      + '-'
      + this.ticket.documentType.name
      + '.'
      + documentTypeOrder
      + '-'
      + 'v01';
  }

  private loadDocumentTypeOrder() {
    return this.restangular
      .all('samples')
      .customPOST({
        filter: {
          limit: null,
          filters: {
            organizationalUnitId: {
              values: [this.ticket.organizationalUnitId],
            },
            documentTypeId: {
              values: [this.ticket.documentType.id],
            },
            section: {
              values: this.ticket.section ? [this.ticket.section] : [],
            },
            status: {
              values: [SampleStatusEnum.ACTIVE, SampleStatusEnum.EXCEPTION]
            }
          }
        }
      })
      .toPromise()
      .then(samples => {
        let max = 0;

        for (const sample of samples) {
          const matches = sample.number.match(/\.[0-9]{2}/g);

          if (!matches) {
            continue;
          }
          const version = parseInt(matches[0].replace(/\./g, ''));

          if (!isNaN(version) && version > max) {
            max = version;
          }
        }

        return max + 1;
      });
  }

  protected readonly TicketStatusEnum = TicketStatusEnum;
}
