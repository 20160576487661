﻿import {Pipe, PipeTransform, Injectable, Inject} from "@angular/core";
import {OwnershipModel} from '../models/ownership.model';
import {SubjectNamePipe} from './subject-name.pipe';

export type OwnershipNamePipeType = 'name-with-fraction' | 'only-fraction' | 'name';

@Pipe({name: 'ownershipName'})
@Injectable({
  providedIn: 'root'
})
export class OwnershipNamePipe implements PipeTransform {

  constructor(private subjectNamePipe: SubjectNamePipe) {
  }

  transform(
      ownership: OwnershipModel,
      type: OwnershipNamePipeType = 'name-with-fraction'
  ) {
    const fraction = (ownership.podilCitatel + '/' + ownership.podilJmenovatel) + (ownership.isBsm ? ' (SJM)' : '');

    switch (type) {
      case 'name':
        return ownership.subjects.map(this.subjectNamePipe.transform).join(', ');
      case 'name-with-fraction':
        const names = ownership.subjects.map(this.subjectNamePipe.transform).join(', ');
        return names + ' (' + fraction + ')';
      case 'only-fraction':
        return fraction;
    }
  }
}
