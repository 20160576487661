<div class="detail" [loading]="loading">
  <div class="row">
    <div class="col-6">
      <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam znaleckých posudků</a>
    </div>
    <div [gmtAuth]="'manage_opinions'" class="col-6 text-right">
      <ng-container *ngFor="let nextStatus of opinionRequest.nextStatuses">
        <a *ngIf="isStatusButtonVisible(nextStatus)" (click)="onEdit(nextStatus)" class="button ml-10 mb-10">
          <i class="fa mr-5" [ngClass]="{'fa-plus-square': nextStatus !== statusEnum.DELETED, 'fa-remove' : nextStatus === statusEnum.DELETED }"></i>
          {{ requestService.getStatusEnumAction(nextStatus) }}
        </a>
      </ng-container>
    </div>
  </div>

  <h1>Znalecký posudek č. {{ opinionRequest.name }}</h1>
  <div class="case-status">
    <span>Stav: <b>{{ requestService.getStatusEnumLabel(opinionRequest.status) }}</b></span>
  </div>

  <div class="edit">
    <a  [gmtAuth]="'manage_opinions'" (click)="onEdit(null)" class="button mb-10">
      <i class="fa fa-pencil mr-5"></i>Upravit údaje
    </a>
  </div>

  <div class="mt-30 mb-70">
    <div class="flex-rest">
      <div class="column-50">
        <label>Katastrální území</label>
        <div class="info">{{ opinionRequest.title.area.name }}</div>

        <label>LV</label>
        <div class="info">
          <a uiSref="symap.project.titles.detail"  [uiParams]="{ id: opinionRequest.title.id, tab: null }">{{ opinionRequest.title.lv }}</a>
        </div>

        <label>Znalec</label>
        <div class="info">{{ opinionRequest.expert | subjectName }}</div>

        <div *ngIf="opinionRequest.geometricPlanRequest">
          <label>GP</label>
          <div class="info">
            <a uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: opinionRequest.geometricPlanRequest.id, tab: 'parcels-budouc' }">
              {{ opinionRequest.geometricPlanRequest.name }}
            </a>
          </div>
        </div>
      </div>

      <div class="column-50">
        <label>Datum založení</label>
        <div class="info">{{ opinionRequest.createdDate | date }}</div>

        <label>Datum zahájení zpracování</label>
        <div class="info">{{ opinionRequest.realizationStartedDate | date }}</div>

        <label>Datum vyhotovení</label>
        <div class="info">{{ opinionRequest.realizedDate | date }}</div>

        <label>Cena za vyhotovení</label>
        <div class="info">{{ opinionRequest.realizationPrice | price }}</div>

        <label>Poslední změna stavu</label>
        <div class="info">{{ opinionRequest.lastStatusChangeDate ? (opinionRequest.lastStatusChangeDate | date) : '' }}</div>
      </div>
    </div>
  </div>

  <div>
    <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
      <div *ngIf="activeTab === 'parcels'">
        <table-parcels-small
          *ngIf="opinionRequest.parcels?.length > 0"
          [data]="opinionRequest.parcels"
          [columns]="PARCELS_COLUMNS"
        ></table-parcels-small>
        <div *ngIf="opinionRequest.parcels?.length === 0" class="bordered">
          <div class="empty-info">Žádné parcely</div>
        </div>
      </div>
      <div *ngIf="activeTab === 'buildings'">
        <table-buildings
          *ngIf="opinionRequest.buildings?.length > 0"
          [data]="opinionRequest.buildings"
          [columns]="BUILDINGS_COLUMNS"
        ></table-buildings>
        <div *ngIf="opinionRequest.buildings?.length === 0" class="bordered">
          <div class="empty-info">Žádné budovy</div>
        </div>
      </div>
      <div *ngIf="activeTab === 'history'">
        <gmt-notes
          [type]="'history'"
          [noteList]="historyList"
          [editable]="false"
          [allowType]="true"
        ></gmt-notes>
      </div>
      <div *ngIf="activeTab === 'documents'">
        <gmt-documents
          [documentCategory]="'o'"
          [documentList]="documentList"
          [defaultData]="{ opinionRequestId: opinionRequest.id }"
          [authorizedUser]="canEdit()"
          [allowType]="true"
          (updated)="onUpdateDocumentList();loadHistory();"
        ></gmt-documents>
      </div>
    </gmt-navbar>
  </div>

  <help [helpId]="helpIds.OPINION_REQUEST_DETAIL" class="cm-help-page"></help>
</div>
