import { InjectionToken } from '@angular/core';
import { Restangular } from 'ngx-restangular';

export const RESTANGULAR_CONFIGURATION = new InjectionToken<any>('RestangularConfiguration');

export function RestangularConfigurationFactory(restangular: Restangular, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlConfig);
  });
  return r;
}
