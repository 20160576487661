import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { VfzeValidationResponseModel } from '@app/common/models/vfze-validation-response.model';
import { VfzeUtils } from '@app/common/utils/vfze.utils';
import { saveAs } from 'file-saver';
import { RESTANGULAR_VFZE } from '@app/common/services/restangular-vfze.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import {AuthService} from "@app/common/services/auth.service";

@Component({
  selector: 'vfze-import-validation',
  templateUrl: './vfze-import-validation.component.html',
})
export class VfzeImportValidationComponent extends VfzeImportStepComponent implements OnInit {

  files: UploadFileExtended[] = [];
  isFileValidToUpload = false;
  authToken = '';
  validationResponse: VfzeValidationResponseModel;
  xmlErrorsDisplayed = false;
  vfzeFileContent = '';

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService,
    private datePipe: DatePipe,
  ) {
    super();
    this.onSaveValidationResponse = this.onSaveValidationResponse.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.onUpload();
  }

  onUpload(): Promise<any> {
    this.validationResponse = undefined;
    this.xmlErrorsDisplayed = false;

    return this.uploadService.upload({
      url: this.restangular.configuration.baseUrl + '/vfze/validate',
      data: { vfze: this.data.files[0].fileEntry },
      headers: { Authorization: this.authService.getToken() }
    }).then((response: { importVfzeId: number, validationResult: VfzeValidationResponseModel }) => {
      this.validationResponse = response.validationResult;
      this.isFileValidToUpload = false;
      this.data.importVfzeId = response.importVfzeId;

      if (this.data.files[0] && this.containResponseErrors()) {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          this.vfzeFileContent = reader.result as string;
        });
        reader.readAsText(this.data.files[0].fileEntry);
      }
    }, (r) => {
      this.errorHandlerService.get(r);
      this.backCallback.emit();
    });
  }

  onSaveValidationResponse() {
    const blob = new Blob(
    [ VfzeUtils.validationResponseToText(this.validationResponse, this.datePipe) ],
    { type: 'text/plain;charset=utf-8' }
    );
    saveAs(blob, 'validace-vfze.txt');
  }

  isResponseSuccessfully(): boolean {
    if (!this.validationResponse) {
      return false;
    }

    const { xmlStructureValid, modelDeserializationPassed, errors, fatal } = this.validationResponse;

    return !!(
      errors.length === 0
      && fatal.length === 0
    );
  }

  containResponseErrors(): boolean {
    if (!this.validationResponse) {
      return false;
    }
    const { errors, fatal } = this.validationResponse;
    return (!!errors.filter(p => p.xmlStructureError).length || !!fatal.filter(p => p.xmlStructureError).length);
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
