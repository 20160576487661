import { ActingPersonModel } from '@app/ps/models/acting-person.model';

export interface ProjectSubjectModel {
  id?: number;
  discriminator: string;
  subjectType?: string;
  companyName?: string;
  branchName?: string;
  surname?: string;
  firstName?: string;
  titleBefore?: string;
  degreeAfter?: string;
  identificationNumber?: number;
  vatId?: string;
  personalIdentificationNumber?: string;
  street?: string;
  houseNumber?: number;
  streetNumber?: string;
  neighbourhood?: string;
  quarter?: string;
  municipality?: string;
  county?: string;
  postCode?: number;
  country?: string;
  deliveryUse?: boolean;
  deliveryStreet?: string;
  deliveryHouseNumber?: number;
  deliveryStreetNumber?: string;
  deliveryNeighbourhood?: string;
  deliveryQuarter?: string;
  deliveryMunicipality?: string;
  deliveryCounty?: string;
  deliveryPostCode?: number;
  deliveryCountry?: string;
  businessRegisterName?: string;
  businessRegisterSection?: string;
  businessRegisterEntry?: string;
  opsubId?: number;
  parentOpsubId?: number;
  actingPersons?: ActingPersonModel[];
  phoneNumber?: string;
  email?: string;
  accountNumber?: string;
  bankCode?: string;
  cadastreActingPerson?: ActingPersonModel;
  databoxId?: string;
  cadastreAgent?: ProjectSubjectModel;
  district?: string;
  deliveryDistrict?: string;
  cadastreNotes?: any[];
  useCaseNumberAsReferenceNumber?: boolean;
  useAccountNumberForProposal?: boolean;
  vatPayer?: boolean;
  showPublicationNote?: boolean;
  publicationNoteTemplate?: string;
  showNoPublicationNote?: boolean;
  noPublicationNoteTemplate?: string;
  customerIdText?: string;
  customer?: any;
  easementDescription?: string;
  useEasementDescription?: boolean;
}

export const createAdministrator = (): ProjectSubjectModel => {
  return {
    useCaseNumberAsReferenceNumber: false,
    useAccountNumberForProposal: false,
    deliveryUse: false,
    discriminator: 'A',
    actingPersons: [],
    showPublicationNote: false,
    showNoPublicationNote: false,
    useEasementDescription: false,
  };
};

export const createAgent = (): ProjectSubjectModel => {
  return {
    useCaseNumberAsReferenceNumber: false,
    useAccountNumberForProposal: false,
    useEasementDescription: false,
    deliveryUse: false,
    discriminator: 'G',
    subjectType: 'OPO',
    actingPersons: [],
  };
};

export const createSubject = (discriminator: string): ProjectSubjectModel => {
  return {
    useCaseNumberAsReferenceNumber: false,
    useAccountNumberForProposal: false,
    useEasementDescription: false,
    deliveryUse: false,
    discriminator: discriminator,
    subjectType: 'OFO',
    actingPersons: [],
    showPublicationNote: false,
    showNoPublicationNote: false,
  };
};
