<fieldset class="flex1">
  <legend>{{ label }}</legend>

  <div class="column-100">
    <label>Od</label>
    <div class="info">
      podpora@gmtech.cz
    </div>

    <gmt-input label="Předmět">
      <input type="text" [(ngModel)]="email.subject"/>
    </gmt-input>
  </div>

  <div class="column-100 flexbox-container">
    <label>Text</label>
    <div class="input flex-rest">
      <textarea type="text" [(ngModel)]="email.body" style="height: 300px"></textarea>
    </div>
  </div>
</fieldset>
