import { Component, Inject, Input, OnInit } from '@angular/core';
import { TitleModel } from '@app/common/models/title.model';
import { AuthService } from '@app/common/services/auth.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'gmt-generate-documents-form',
  templateUrl: './generate-documents-form.component.html',
  styleUrls: ['./generate-documents-form.component.scss']
})
export class GenerateDocumentsFormComponent implements OnInit {

  @Input() caseId: number;
  @Input() title: TitleModel;
  @Input() templatesGetter: Function;
  @Input() generateSetter: Function;
  @Input() getCheckedItemsGetter: Function;
  loading = true;
  otherDocumentTemplate = null;
  coveringLetterTemplate = null;
  templateTypes = [];
  data = null;
  editedTemplate = null;
  checklistTemplates = new ChecklistModel();
  coveringLetterDefaultProcessor;
  coveringLetterProcessors = [];
  templateCoveringLetterProcessorMap: any = {};

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
  ) {
    this.displayEdit = this.displayEdit.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.resetRecipientEdit = this.resetRecipientEdit.bind(this);
    this.getDocumentObject = this.getDocumentObject.bind(this);
    this.getParentVisible = this.getParentVisible.bind(this);
    this.onTemplateRecipientChecked = this.onTemplateRecipientChecked.bind(this);
    this.onTemplateChecked = this.onTemplateChecked.bind(this);
    this.getCheckedItems = this.getCheckedItems.bind(this);
    this.missingRequired = this.missingRequired.bind(this);
    this.generate = this.generate.bind(this);
  }

  async ngOnInit() {
    await this.loadCoveringLetterProcessors();

    this.checklistTemplates.identity = (item) => {
      const filter: any = {
        id: item.id,
      };

      if (item.recipientOpsubId) {
        filter.recipientOpsubId = item.recipientOpsubId;
      }
      return filter;
    };

    this.templatesGetter().then((data) => {
      this.loading = false;
      this.templateTypes = data;
      this.templateTypes.forEach(templateType => {
        if (templateType.key === 'SalesContractLiabilityCoveringLetter' && this.coveringLetterDefaultProcessor) {
          for (const template of templateType.templates) {
            this.templateCoveringLetterProcessorMap[template.id] = this.coveringLetterDefaultProcessor;
          }
        }

        const oneRecipient = templateType.recipients ? templateType.recipients.length === 1 : true;
        if (templateType.templates.length === 1 && oneRecipient) {
          this.switchTemplateWanted(templateType.templates[0]);
        }
      });
    });

    if (this.generateSetter) {
      this.generateSetter({generate: this.generate});
    }

    if (this.getCheckedItemsGetter) {
      this.getCheckedItemsGetter({checkedItemsGetter: this.getCheckedItems});
    }
  }

  onSelectCoveringLetterProcessor(processor: any, template) {
    delete this.templateCoveringLetterProcessorMap[template.id];
    if (processor) {
      this.templateCoveringLetterProcessorMap[template.id] = processor;
    }
  }

  displayEdit(id, event) {
    this.editedTemplate = id;
    const el = event.target.closest('div');

    setTimeout(() => {
      const input = el.querySelector('input');
      input.focus();
    });
  }

  resetEdit(template) {
    this.editedTemplate = null;

    setTimeout(() => {
      if (template.attachment.baseName === undefined || template.attachment.baseName.trim() === '') {
        template.attachment.baseName = template.commonName;
      }
    });
  }

  resetRecipientEdit(recipient) {
    this.editedTemplate = null;

    setTimeout(() => {
      if (recipient.templateName.trim() === '') {
        recipient.templateName = recipient.commonTemplateName;
      }
    });
  }

  getDocumentObject(template, recipient?) {
    const document: any = {
      id: template.attachment.id,
      templateId: template.id
    };

    if (recipient && recipient.id) {
      document.recipientOpsubId = recipient.id;
      document.templateName = recipient.templateName;
    }
    return document;
  }

  getParentVisible(template) {
    if (template.recipients && template.recipients.length > 0) {
      return template.recipients.some(r => this.checklistTemplates.isChecked(this.getDocumentObject(template, r)));
    } else {
      return this.checklistTemplates.isChecked(this.getDocumentObject(template));
    }
  }

  onTemplateRecipientChecked(templateType, template, event, recipient) {
    event.stopPropagation();
    this.switchTemplateRecipientWanted(template, recipient);
  }

  onTemplateChecked(template, $event) {
    $event.stopPropagation();
    this.switchTemplateWanted(template);
  }

  getCheckedItems() {
    return this.checklistTemplates ? this.checklistTemplates.checkedItems : [];
  }

  missingRequired() {
    return !this.checklistTemplates || this.checklistTemplates.checkedItems.length === 0;
  }

  isCoveringLetter(template: any) {
    return template.key.indexOf('CoveringLetter') !== -1 || template.key.indexOf('InformationLetter') !== -1;
  }

  generate() {
    if (this.missingRequired()) {
      return;
    }

    const documents = this.checklistTemplates.checkedItems;

    const generateDocuments = documents.map((d) => {
      const doc: any = {
        name: this.getDocumentName(d),
        id: d.templateId,
      };
      if (d.recipientOpsubId) {
        doc.recipientOpsubId = d.recipientOpsubId;
      }

      if (this.templateCoveringLetterProcessorMap[d.templateId]) {
        doc.customProperties = this.transformCoveringLetterProcessor(this.templateCoveringLetterProcessorMap[d.templateId]);
      }
      return doc;
    });

    return this.restangular.one('cases', this.caseId)
      .all('generate')
      .customPOST(generateDocuments)
      .toPromise()
      .then((attachments) => {
          return attachments;
      });
  }

  private getRecipientById(recipientId, templateId) {
    let recipient = null;
    this.templateTypes.find((templateType) => {
      return templateType.templates.find((template) => {
        return template.attachment.id === templateId && template.recipients ? (template.recipients.find((rep) => {
          if (rep.id === recipientId) {
            recipient = rep;
            return true;
          }
          return false;
        })) : false;
      });
    });
    return recipient;
  }

  private getAttachmentByID(attachmentId) {
    let attachment = null;
    this.templateTypes.find((templateType) => {
      return templateType.templates.find((template) => {
        if (template.attachment.id === attachmentId) {
          attachment = template.attachment;
          return true;
        }
        return false;
      });
    });
    return attachment;
  }

  private switchTemplateRecipientWanted(template, recipient) {
    const document = this.getDocumentObject(template, recipient);
    this.checklistTemplates.toggleSelection(document);
  }

  private switchTemplateWanted(template) {
    const document = this.getDocumentObject(template);
    const checked = this.checklistTemplates.isChecked(document);
    if (template.recipients && template.recipients.length !== 0) {
      for (const recipient of template.recipients) {
        const document = this.getDocumentObject(template, recipient);
        const checkedRecipientDocument = this.checklistTemplates.isChecked(document);
        if (checkedRecipientDocument === checked) {
          this.switchTemplateRecipientWanted(template, recipient);
        }
      }
    } else {
      this.checklistTemplates.toggleSelection(document);
    }
  }

  private getDocumentName(document) {
    const attachment = this.getAttachmentByID(document.id);
    return document.recipientOpsubId ? this.getRecipientById(document.recipientOpsubId, document.id).templateName : attachment.baseName;
  }

  private async loadCoveringLetterProcessors() {
    const projectProperties = await this.restangular.all('properties').customGET('').toPromise();

    // parse zpracovatel majetkopravnich cinnosti pro pruvodni dopis
    const groupName = 'Údaje do průvodního dopisu';
    const prefix = 'podepisujici';
    const group = projectProperties.find(g => g.groupName === groupName);

    if (group) {
      this.coveringLetterProcessors = [];

      for (const item of group.items) {
        if (!item.templateVariable || item.templateVariable.indexOf(prefix) !== 0) {
          continue;
        }
        const id = item.templateVariable.slice(0, prefix.length + 1);
        const processor = this.coveringLetterProcessors.find(p => p.id === id);

        if (processor) {
          processor.items.push(item);
        } else {
          this.coveringLetterProcessors.push({
            id: id,
            items: [item],
          });
        }
      }

      this.coveringLetterProcessors = this.coveringLetterProcessors
        .filter(p => {
          const itemName = p.items.find(i => i.templateVariable === p.id + 'PrijmeniJmeno');
          return itemName && !!itemName.valueText;
        })
        .map(p => {
          const itemName = p.items.find(i => i.templateVariable === p.id + 'PrijmeniJmeno');
          const itemOtherProcessor = p.items.find(i => i.templateVariable === p.id + 'JinaOsoba');
          const itemOtherProcessorName = p.items.find(i => i.templateVariable === p.id + 'JinaOsobaPrijmeniJmeno');
          p.name = itemName.valueText + (itemOtherProcessor.valueBoolean && itemOtherProcessorName.valueText ? ' (' + itemOtherProcessorName.valueText + ')' : '');
          return p;
        });

      const defaultProcessor = this.coveringLetterProcessors.find(p => p.items.find(i => i.type === 'USER_SELECT' && i.valueLong && this.authService.getUser().id === i.valueLong));

      if (defaultProcessor) {
        this.coveringLetterDefaultProcessor = defaultProcessor;
      } else if (this.coveringLetterProcessors.length === 1) {
        this.coveringLetterDefaultProcessor = this.coveringLetterProcessors[0];
      }
    }
  }

  private transformCoveringLetterProcessor(processor) {
    const prefix = 'podepisujici';

    return processor.items.map(i => {
      return {...i, templateVariable: prefix + i.templateVariable.slice(prefix.length + 1)};
    });
  }
}
