import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, StateParams, UIRouterModule } from '@uirouter/angular';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { OccupationListComponent } from '@app/ps/occupations/components/occupation-list/occupation-list.component';
import { UpdateOccupationParcelComponent } from '@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component';

export const occupationListState: Ng2StateDeclaration = {
  name: 'symap.project.occupations',
  url: '/occupations?page',
  data: {
    title: 'Zábory',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
      squash: true,
    },
  },
  views: {
    'content@symap.project': {
      component: OccupationListComponent,
    },
  },
};

export const updateOccupationParcelState: Ng2StateDeclaration = {
  name: 'symap.project.occupations.updateOccupationParcel',
  url: '/{occupationId}/update-parcel',
  data: {
    title: 'Aktualizace parcely záboru',
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  resolve: {
    occupationId: ['$stateParams', resolveOccupationIdFunction],
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationParcelComponent,
    },
  },
};

export function resolveOccupationIdFunction(stateParams: StateParams) {
  return stateParams.occupationId;
}

@NgModule({
  declarations: [
    OccupationListComponent,
    UpdateOccupationParcelComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [
        occupationListState,
        updateOccupationParcelState,
      ],
    }),
  ]
})
export class OccupationsModule { }
