import { Component, OnInit, Inject } from '@angular/core';
import { PermissionsBulkStepComponent } from '@app/settings/users/component/permission-bulk-step/permissions-bulk-step.component';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { APPLICATIONS } from '@app/common/services/config.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';
import { ModulesService } from "@app/common/services/modules.service";

@Component({
  selector: 'permissions-bulk-confirm',
  templateUrl: './permissions-bulk-confirm.component.html',
})
export class PermissionsBulkConfirmComponent extends PermissionsBulkStepComponent implements OnInit {

  loading = true;
  uploading = false;
  progress = 0;
  clearRestProjects = false;

  constructor(
    @Inject(APPLICATIONS) public applications: any,
    @Inject(RESTANGULAR_SETTINGS) public restangular: Restangular,
    private modulesService: ModulesService,
    public rolePipe: RolePipe,
  ) {
    super();
    this.onSave = this.onSave.bind(this);
  }

  async ngOnInit() {

  }

  async onSave() {
    this.uploading = true;

    if (this.data.operation === 'p') {
      if (this.data.removePermissions) {
        for (const user of this.data.users) {
          const payload = {
            application: this.applications.sy.name,
            projectKey: this.data.project.key,
            permissions: [],
          };

          await this.modulesService.saveUserPermissions(this.applications.sy.name, this.data.project.key, user.id, payload);
          this.progress += 1;
        }

      } else {
        for (const user of this.data.users) {
          const permissions = this.data.permissions && this.data.permissions.map(item => item.id);
          const roleId: string = this.data.role && this.data.role.id;
          const payload: any = {
            application: this.applications.sy.name,
            projectKey: this.data.project.key,
            permissions: permissions || [],
          };

          if (roleId) {
            payload.projectRole = roleId;
          }

          await this.modulesService.saveUserPermissions(this.applications.sy.name, this.data.project.key, user.id, payload);
          this.progress += 1;
        }
      }
    }

    if (this.data.operation === 'u') {
      if (this.data.removePermissions) {
        for (const project of this.data.projects) {
          const payload = {
            application: this.applications.sy.name,
            projectKey: project.key,
            permissions: [],
          };

          await this.modulesService.saveUserPermissions(this.applications.sy.name, project.key, this.data.user.id, payload);
          this.progress += 1;
        }

      } else {
        for (const project of this.data.projects) {
          const permissions = this.data.permissions && this.data.permissions.map(item => item.id);
          const roleId: string = this.data.role && this.data.role.id;
          const payload: any = {
            application: this.applications.sy.name,
            projectKey: project.key,
            permissions: permissions || [],
          };

          if (roleId) {
            payload.projectRole = roleId;
          }

          await this.modulesService.saveUserPermissions(this.applications.sy.name, project.key, this.data.user.id, payload);
          this.progress += 1;
        }

        if (this.clearRestProjects) {
          const restProjects = this.data.common.projects.filter(p => !this.data.projects.includes(pp => pp.key === p.key));
          for (const project of restProjects) {
            const payload = {
              application: this.applications.sy.name,
              projectKey: project.key,
              permissions: [],
            };

            await this.modulesService.saveUserPermissions(this.applications.sy.name, project.key, this.data.user.id, payload);
            this.progress += 1;
          }
        }
      }
    }

    this.submitCallback.emit();
  }
}
