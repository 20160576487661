import { Injectable } from '@angular/core';
import * as  L from 'leaflet';
import proj4 from 'proj4';

@Injectable({
  providedIn: 'root'
})
export class MapUtilsCrsService {

  leafletSrids = {
    3857: L.CRS.EPSG3857,
    4326: L.CRS.EPSG4326,
    3395: L.CRS.EPSG3395
  };

  constructor() {
    this.setLeafletSrid = this.setLeafletSrid.bind(this);
    this.getLeafletSrid = this.getLeafletSrid.bind(this);
  }

  getCrs(sridId) {
    return this.getLeafletSrid(sridId);
  }

  setProj4Srid(projId, crsDef) {
    try {
      proj4('urn:ogc:def:crs:EPSG::' + projId);
    } catch (e) {
      proj4.defs('urn:ogc:def:crs:EPSG::' + projId, crsDef);
    }
  }

  setLeafletSrid(sridId, sridDefs) {
    this.leafletSrids[sridId] = sridDefs;
  }

  getLeafletSrid(sridId) {
    return this.leafletSrids[sridId];
  }
}

