import { InjectionToken } from '@angular/core';

export const RESTANGULAR_DIMAP = new InjectionToken<any>('RestangularDimap');

export function RestangularDimapFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlDI + '/app');
  });
  return r;
}
