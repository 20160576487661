<div>
  <div *ngIf="!imported" class="centered-text jumbotron">
    Probíhá import VFZE dat do databáze.
    <div [loading]="true"></div>
  </div>

  <div *ngIf="imported" class="centered-text jumbotron">
    Data byla úspěšně importována
  </div>

  <div class="mt-10">
    <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!imported">Dokončit</gmt-button>
  </div>
</div>