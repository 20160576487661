<div>
  <fieldset class="bordered" *ngIf="loadingHeader || constructionObject">
    <legend>{{ "CONSTRUCTION_OBJECT" | wordtranslate:true }}</legend>
    <div [loading]="loadingHeader">
      <div *ngIf="constructionObject">
        <div class="column-50">
          <label>{{ "CONSTRUCTION_OBJECT" | wordtranslate:true }}</label>
          <div class="info">{{ header.so }}</div>
        </div>
        <div class="column-50">
          <label>{{ 'ETAPA_USEK' | wordtranslate }}</label>
          <div class="info">{{ header.sousek }}</div>
        </div>
      </div>
    </div>
  </fieldset>

  <fieldset class="bordered" *ngIf="loadingHeader || constructionObject">
    <legend>Geometrický plán</legend>
    <div [loading]="loadingHeader">
      <div *ngIf="constructionObject">
        <div class="column-50">
          <label>Zhotovitel</label>
          <div class="info">{{ header.zhotovitel }}</div>
          <label>Číslo geometrického plánu</label>
          <div class="info">{{ header.cisloGp }}</div>
          <label>Katastrální území</label>
          <div class="info">{{ header.ku }}</div>
        </div>
        <div class="column-50">
          <label>Datum ověření zpracovatelem</label>
          <div class="info">{{ header.datumOvereni | date }}</div>
          <label>Datum potvrzení pracovníkem KP</label>
          <div class="info">{{ header.datumZhotoveni | date }}</div>
        </div>
      </div>
    </div>
  </fieldset>

  <div [loading]="list.loading">
    <div class="bordered" *ngIf="list.itemCount">
      <table>
        <thead>
        <tr>
          <th [attr.rowspan]="2" class="">Upozornění</th>
          <th [attr.rowspan]="2" class="">
            <gmt-checkbox [gmtChecked]="includeAllEasements" (click)="toggleAllEasements()"></gmt-checkbox>
            Zahrnout
          </th>
          <th [attr.rowspan]="2" class="">PLATNÝ PŘÍPAD VB</th>
          <th [attr.rowspan]="2" class="">
            <sort-by [selected]="'parcelNumber' === sortOrder.sortBy" (sort)="onSortParcel('parcelNumber')" [sortDir]="sortOrder.direction">
              Parcela
            </sort-by>
          </th>
          <th [attr.rowspan]="2" class="">
            <sort-by [selected]="'lv' === sortOrder.sortBy" (sort)="onSort('lv', 'parcel.lv')" [sortDir]="sortOrder.direction">
              LV
            </sort-by>
          </th>
          <th [attr.rowspan]="2" class="">Druh pozemku</th>
          <th [attr.rowspan]="2" class="">Výměra VB z dat VFK [<span class="unit">m<sup>2</sup></span>]</th>
          <th [attr.rowspan]="2" class="">Výměra VB z CSV [<span class="unit">m<sup>2</sup></span>]</th>
          <th [attr.rowspan]="2" class="">Výměra VB do případu [<span class="unit">m<sup>2</sup></span>]</th>
          <th [attr.rowspan]="2" class="">Délka VB z CSV [<span class="unit">m</span>]</th>
          <th [attr.rowspan]="2" class="">Délka VB do případu [<span class="unit">m</span>]</th>
        </tr>
        </thead>
        <tbody *ngFor="let parcel of list.list">
          <tr easement-create-parcel-row *ngFor="let easement of parcel.easements" [parcel]="parcel.parcel" [easement]="easement"></tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info" *ngIf="!list.itemCount && list.list && !list.loading">Nenalezena žádná data</div>
  </div>

  <div *ngIf="list.list" class="mt-20 text-center">
    <gmt-checkbox [gmtChecked]="roundTo0DecimalPlaces" (click)="roundTo0DecimalPlaces = !roundTo0DecimalPlaces"></gmt-checkbox>
    Zaokrouhlit výsledné hodnoty výměr věcných břemen na celé metry (pro výměry větší jak 0,5m)
  </div>

  <div class="mt-20 text-center">
    <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">
      <span *ngIf="!uploadingData">Zavést VB do {{ APP_BRAND.PS.TITLE }} ({{ getUploadData().length }})</span>
      <span *ngIf="uploadingData">Nahrávám data...</span>
    </gmt-button>

    <span *ngIf="getDeleteEasements().length > 0">
      Bude smazáno ({{ getDeleteEasements().length }}) VB.
    </span>
  </div>
  <div>
    <span *ngIf="dataContainsWarnings()">
      Některé importované VB obsahují chyby, zkontrolujte řádky se znakem <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>.
    </span>
  </div>
</div>
