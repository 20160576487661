<ng-template #parcelsCell>
  <div class="fill-content flexbox-container flex-centered p-10">
    <div class="icon-count flexbox-container">
      <i class="fa fa-file-powerpoint-o"></i>
    </div>
    <div class="number ml-4">{{ countParcels }}</div>
  </div>
</ng-template>

<ng-container *ngIf="!tooltipComponent || !tooltipData">
  <ng-container *ngTemplateOutlet="countParcels"></ng-container>
</ng-container>

<div *ngIf="tooltipComponent && tooltipData"
     [tooltip]="tooltipComponent"
     [activeContent]="true"
     [data]="tooltipData"
     class="tooltip-hover-area fill-content"
>
  <ng-container *ngTemplateOutlet="parcelsCell"></ng-container>
</div>
