<div [loading]="!formData">
  <div *ngFor="let statistic of formData" class="column-100">
    <div class="fl">
      <a class="fl mr-10" (click)="moveStatistics(statistic, -1)"><i class="fa fa-arrow-up"></i></a>&nbsp;
      <a class="fl mr-10" (click)="moveStatistics(statistic, 1)"><i class="fa fa-arrow-down"></i></a>
      <div class="fl mr-10" (click)="toggleStatistics(statistic)">
        <gmt-checkbox gmtType="multiselect" [gmtChecked]="statistic.statistics.available"></gmt-checkbox>
      </div>
    </div>
    <div class="ml-70">
      <gmt-labeled-block label="{{ statistic.statistics.name || '-' }}" [collapsed]="true">
        <fieldset class="bordered no-legend">
          <div class="column-100">
            <gmt-input label="Název">
              <input type="text" [(ngModel)]="statistic.statistics.name" />
            </gmt-input>
            <div class="info">
              <a class="fa fa-close" (click)="removeStatistics(statistic)"></a>
            </div>
          </div>

          <div class="column-100">
            <div class="label"></div>
            <div class="info-description">
              <span>Přehled dle případů</span>
              <span class="ml-4">Přehled dle ostatních paramterů</span>
            </div>
            <div class="label">Výchozí kategorie</div>
            <div class="input">
              <input type="text" [(ngModel)]="statistic.statistics.defaultCategory" />
              <input type="text" class="ml-4" [(ngModel)]="statistic.statistics.defaultCategoryAlternative" />
              &nbsp;
              <a class="fa fa-pencil" (click)="edit(statistic.statistics)"></a>

              <div class="column-100" *ngIf="isEdited(statistic.statistics)">
                <gmt-input label="Typ grafu">
                  <gmt-switch [options]="graphTypes" [(selectedId)]="statistic.statistics.defaultCategoryGraphType"></gmt-switch>
                </gmt-input>
                <gmt-input label="Popis osy y">
                  <input type="text" [(ngModel)]="statistic.statistics.defaultCategoryYAxisLabel" />
                </gmt-input>
                <gmt-input label="Popis hodnoty v grafu">
                  <input type="text" [(ngModel)]="statistic.statistics.defaultCategoryValueLabel" />
                </gmt-input>
                <gmt-input label="Popis zůstatku v grafu">
                  <input type="text" [(ngModel)]="statistic.statistics.defaultCategoryRemainLabel" />
                </gmt-input>
              </div>
            </div>
          </div>

          <div class="column-100">
            <div class="label">Kategorie případů</div>
            <div class="input">
              <div *ngFor="let category of statistic.categories" class="cb" [ngClass]="{ 'pt-10': !category.id }">
                <div class="info ml--40 mr-10" *ngIf="category.name !== null || category.id">
                  <a class="fa fa-arrow-up" (click)="upCategory(category, statistic)"></a>&nbsp;
                  <a class="fa fa-arrow-down" (click)="downCategory(category, statistic)"></a>
                </div>
                <div class="input">
                  <ng-container *ngIf="!category.split">
                    <input type="text" [(ngModel)]="category.name" (change)="newCategoryChanged(category, statistic)" />
                    <input type="text" class="ml-4" [(ngModel)]="category.nameAlternative" />
                  </ng-container>

                  <ng-container *ngIf="category.split">
                    <input type="text" [(ngModel)]="category.splitName" />
                    <input type="text" class="ml-4" [(ngModel)]="category.splitNameAlternative" />
                  </ng-container>
                </div>
                <div class="info" *ngIf="category.name !== null || category.id">
                  <a class="fa fa-pencil" (click)="edit(category)"></a>&nbsp;
                  <a class="fa fa-close" (click)="removeCategory(category, statistic)"></a>
                </div>
                <div class="column-100" *ngIf="isEdited(category)">
                  <div class="label" (click)="onSplit(category)">
                    <gmt-checkbox [gmtChecked]="category.split"></gmt-checkbox> Dělená kategorie
                  </div>

                  <div *ngIf="category.split" class="cb bordered p-10 mb-10">
                    <h3>Nastavení kompletního stavu</h3>
                    <gmt-input label="Typ grafu">
                      <gmt-switch [options]="graphTypes" [(selectedId)]="category.splitGraphType"></gmt-switch>
                    </gmt-input>
                    <gmt-input label="Popis osy y">
                      <input type="text" [(ngModel)]="category.splitYAxisLabel" />
                    </gmt-input>
                    <gmt-input label="Popis hodnoty v grafu">
                      <input type="text" [(ngModel)]="category.splitValueLabel" />
                    </gmt-input>
                    <gmt-input label="Popis zůstatku v grafu">
                      <input type="text" [(ngModel)]="category.splitRemainLabel" />
                    </gmt-input>
                  </div>

                  <div class="cb" [ngClass]="{ bordered: category.split, 'p-10': category.split }">
                    <ng-container *ngIf="category.split">
                      <h3>Nastavení částečného stavu</h3>
                      <gmt-input label="Název pro přehled dle případů">
                        <input type="text" [(ngModel)]="category.name" />
                      </gmt-input>
                      <gmt-input label="Název pro přehled dle ostatních parametrů">
                        <input type="text" [(ngModel)]="category.nameAlternative" />
                      </gmt-input>
                    </ng-container>

                    <gmt-input label="Typ grafu">
                      <gmt-switch [options]="graphTypes" [(selectedId)]="category.graphType"></gmt-switch>
                    </gmt-input>
                    <gmt-input label="Popis osy y">
                      <input type="text" [(ngModel)]="category.yAxisLabel" />
                    </gmt-input>
                    <gmt-input label="Popis hodnoty v grafu">
                      <input type="text" [(ngModel)]="category.valueLabel" />
                    </gmt-input>
                    <gmt-input label="Popis zůstatku v grafu">
                      <input type="text" [(ngModel)]="category.remainLabel" />
                    </gmt-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column-100">
            <div class="label">Typy případů</div>
            <div class="input">
              <div *ngFor="let obligation of statistic.obligations">
                <div class="cb info">
                  <b>{{obligation.obligationData.name}}</b>&nbsp;
                  <a class="fa fa-pencil" (click)="edit(obligation)"></a>&nbsp;
                  <a class="fa fa-close" (click)="removeObligation(obligation, statistic)"></a>&nbsp;
                  <gmt-checkbox [gmtChecked]="obligation.repeat" (click)="toggleRepeat(obligation)" title="Opakování stavů případu"></gmt-checkbox>
                </div>
                <div *ngIf="isEdited(obligation)">
                  <div *ngFor="let category of obligation.categories">
                    <div class="info cb"><i>{{category.category.name||'-'}}</i></div>
                    <div class="input cb">
                      <span *ngFor="let status of category.statusesData" class="mr-10">{{status.name}}
                        <a class="fa fa-pencil mr-5" (click)="editStatus(category, status)"></a>
                        <a class="fa fa-close" (click)="removeStatus(obligation, category, status)"></a>
                      </span>
                      <gmt-select
                        selectTitle="přidat stav"
                        required="true"
                        [data]="data"
                        field="selected"
                        [optionItems]="statuses"
                        [filter]="category.statusesFilter"
                        [reloadOptionsRegister]="category.reloadOptionsRegisterStatus"
                        (changed)="selectedStatus(obligation, category, $event.newValue, data)"
                      ></gmt-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cb input" *ngIf="obligations">
                <gmt-select
                  selectTitle="přidat typ případu"
                  required="true"
                  [data]="data"
                  field="selected"
                  [optionItems]="obligations"
                  [filter]="statistic.obligationsFilter"
                  [reloadOptionsRegister]="statistic.reloadOptionsRegisterObligation"
                  (changed)="selectedObligation(statistic, $event.newValue, data)"
                ></gmt-select>
              </div>
            </div>
          </div>

          <div class="column-100">
            <div class="label" (click)="statistic.statistics.objectTypesCombination = !statistic.statistics.objectTypesCombination">
              Služebnosti podle dat GP, služebnosti podle dat ZE, dočasné zábory do jednoho roku, nad jeden rok a trvalé zábory na jednom případu
            </div>
            <div class="info" (click)="statistic.statistics.objectTypesCombination = !statistic.statistics.objectTypesCombination">
              <gmt-checkbox [gmtChecked]="statistic.statistics.objectTypesCombination"></gmt-checkbox>
            </div>
          </div>

          <div class="column-100">
            <div class="label">Metodika uzavírání případů</div>
            <div class="input">
              <div *ngFor="let objectType of statistic.objectTypes">
                <div class="cb info">
                  <b (click)="toggleObjectType(objectType, statistic)" [ngClass]="{ cancelled: !isObjectTypeAvailable(objectType, statistic) }">
                    <gmt-checkbox [gmtChecked]="!!objectType.item"></gmt-checkbox> {{objectType.name}}
                  </b>
                </div>
                <div class="cb info ml-10" *ngIf="!!objectType.occupationTypes">
                  <div (click)="objectType.item.occupationTypes = []">
                    <gmt-checkbox [gmtChecked]="!objectType.item.occupationTypes.length"></gmt-checkbox> Všechny typy záborů
                  </div>
                  <div *ngFor="let occupationType of objectType.occupationTypes" (click)="toggleOccupationTypeChecked(objectType.item.occupationTypes, occupationType)">
                    <gmt-checkbox [gmtChecked]="isOccupationTypeChecked(objectType.item.occupationTypes, occupationType)"></gmt-checkbox> {{occupationType.name}}
                  </div>
                </div>
                <div class="cb info ml-10" *ngIf="objectType.item">
                  <div (click)="objectType.countTypeChecklist.toggleSelection('OWNERSHIP')">
                    <gmt-checkbox [gmtChecked]="objectType.countTypeChecklist.isChecked('OWNERSHIP')"></gmt-checkbox> Po vlastnících
                  </div>
                  <div (click)="objectType.countTypeChecklist.toggleSelection('TITLE')">
                    <gmt-checkbox [gmtChecked]="objectType.countTypeChecklist.isChecked('TITLE')"></gmt-checkbox> Po listech vlastnictví
                  </div>
                  <div (click)="objectType.countTypeChecklist.toggleSelection('CONSTRUCTION_OBJECT')">
                    <gmt-checkbox [gmtChecked]="objectType.countTypeChecklist.isChecked('CONSTRUCTION_OBJECT')"></gmt-checkbox> Po {{ 'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate }}
                  </div>
                  <div (click)="objectType.countTypeChecklist.toggleSelection('ADMINISTRATOR')">
                    <gmt-checkbox [gmtChecked]="objectType.countTypeChecklist.isChecked('ADMINISTRATOR')"></gmt-checkbox> Po správcích {{ 'CONSTRUCTION_OBJECT_GENITIV'|wordtranslate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column-100">
            <div class="label">Standardní období</div>
            <div class="input">
              <div *ngFor="let interval of statistic.intervals" class="cb">
                <div class="info mr-10" *ngIf="interval.name !== null">
                  <a class="fa fa-arrow-up" (click)="upInterval(interval, statistic)"></a>&nbsp;
                  <a class="fa fa-arrow-down" (click)="downInterval(interval, statistic)"></a>
                </div>
                <div class="input">
                  <input type="text" [(ngModel)]="interval.name" (change)="newIntervalChanged(interval, statistic)" />
                </div>
                <div class="info" *ngIf="interval.name !== null">
                  <a class="fa fa-pencil" (click)="edit(interval)"></a>&nbsp;
                  <a class="fa fa-close" (click)="removeInterval(interval, statistic)"></a>
                </div>
                <div class="column-100" *ngIf="isEdited(interval)">
                  <gmt-input label="Počet bodů v grafu">
                    <input type="text" [(ngModel)]="interval.pointCount" />
                  </gmt-input>
                  <gmt-input label="Počet dnů mezi body">
                    <input type="text" [(ngModel)]="interval.pointInterval" />
                  </gmt-input>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </gmt-labeled-block>
    </div>
  </div>
</div>
<div>
  <gmt-button [clickAction]="addStatistics">Nová statistika</gmt-button>
</div>
