<ng-container>
  <td *ngIf="selectable" (click)="selected = !selected" class="centered-text">
    <gmt-checkbox gmtType="multiselect" [gmtDisabled]="disabled" [gmtChecked]="selected"></gmt-checkbox>
  </td>
  <td *ngIf="showParcel" class="no-padding cell-max-height">
    <cell-parcel
      [parcel]="parcel"
      [tooltipType]="'parcel'"
      [tooltipData]="{ parcel: parcel, withoutTitle: true }"
    ></cell-parcel>
  </td>
  <td *ngIf="opinion.occupationType === 'P'">
    <input (change)="onChange()" type="text" [(ngModel)]="parcelPrice.otherLandPrice" gmtInputNumber/>
  </td>
  <td *ngIf="opinion.occupationType === 'T'">
    <input (change)="onChange()" type="text" [(ngModel)]="parcelPrice.otherCompensationPrice" gmtInputNumber/>
  </td>
  <td *ngIf="opinion.occupationType === 'P'">
    <input (change)="onChange()" type="text" [(ngModel)]="parcelPrice.otherVegetationPrice" gmtInputNumber/>
  </td>
  <td *ngIf="opinion.occupationType === 'P'">
    <input (change)="onChange()" type="text" [(ngModel)]="parcelPrice.otherBuildingsPrice" gmtInputNumber/>
  </td>
  <td class="number">
    {{ computeParcelPrice() | number | price }}
  </td>
</ng-container>
