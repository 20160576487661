import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({ name: 'projectName' })
@Injectable({
  providedIn: 'root'
})
export class ProjectNamePipe implements PipeTransform {

  transform(project: any) {
    const words = [];

    if (project.isprofond) {
      words.push('(' + project.isprofond + ')');
    }

    words.push(project.name);
    return words.join(' ');
  }
}
