<fieldset>
  <legend>Základní údaje</legend>
  <div class="column-50">
    <gmt-input label="Číslo znaleckého posudku" [required]="true">
      <input type="text" [(ngModel)]="opinion.expertOpinionNumber"/>
    </gmt-input>
  </div>

  <div class="column-50">
    <gmt-input label="Datum vyhotovení" [required]="true">
      <input pick-a-date [(ngModel)]="opinion.expertOpinionDate"/>
    </gmt-input>
  </div>
</fieldset>
