<ng-template #parcelCell>
  <div class="fill-content flexbox-container flex-centered p-10 {{ propertyStatusClass }}">{{ item.area | number }}</div>
</ng-template>

<ng-container *ngIf="!tooltipComponent || !tooltipData">
  <ng-container *ngTemplateOutlet="parcelCell"></ng-container>
</ng-container>

<div *ngIf="tooltipComponent && tooltipData"
     [tooltip]="tooltipComponent"
     [data]="tooltipData"
     [activeContent]="true"
     class="tooltip-hover-area fill-content"
>
  <ng-container *ngTemplateOutlet="parcelCell"></ng-container>
</div>
