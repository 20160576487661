<h1>Editace ceny věcného břemene</h1>

<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Způsob určení ceny</legend>
      <div class="column-100">
        <div class="input">
          <gmt-switch [options]="priceTypes" [(selectedId)]="easement.priceType" (select)="refreshDefaultPrice($event.id);"></gmt-switch>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Cena</legend>
      <div class="column-100" *ngIf="easement.priceType === 'E'">
        <label class="w-300">Číslo znaleckého posudku</label>
        <div class="input"><input type="text" [(ngModel)]="easement.expertOpinionNumber"/></div>

        <label class="w-300">Datum znaleckého posudku</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="easement.expertOpinionDate"/></div>

        <label class="w-300">Způsob výpočtu ceny</label>
        <div class="input">
          <gmt-switch [options]="unitTypes" [(selectedId)]="easement.unitType" (select)="refreshDefaultPrice($event.id);"></gmt-switch>
        </div>

        <div *ngIf="easement.unitType === 'L'">
          <label class="w-300">Cena VB podle znaleckého posudku [<span class="unit">Kč/bm</span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.lengthPrice=$event;refreshDefaultPrice()" [ngModel]="easement.lengthPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="easement.unitType === 'A'">
          <label class="w-300">Cena VB podle znaleckého posudku [<span class="unit">Kč/m<sup>2</sup></span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.areaPrice=$event;refreshDefaultPrice()" [ngModel]="easement.areaPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="easement.unitType === 'T'">
          <label class="w-300">Cena VB podle znaleckého posudku [<span class="unit">Kč</span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.totalPrice=$event;refreshDefaultPrice()" [ngModel]="easement.totalPrice" gmtInputNumber/></div>
        </div>
      </div>

      <div class="column-100" *ngIf="easement.priceType === 'O'">
        <label class="w-300">Původ ceny</label>
        <div class="input"><input type="text" [(ngModel)]="easement.otherSource"/></div>

        <label class="w-300">Způsob výpočtu ceny</label>
        <div class="input">
          <gmt-switch [options]="unitTypes" [(selectedId)]="easement.unitType" (select)="refreshDefaultPrice($event.id);"></gmt-switch>
        </div>

        <div *ngIf="easement.unitType === 'L'">
          <label class="w-300">Cena VB [<span class="unit">Kč/bm</span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.lengthPrice=$event;refreshDefaultPrice()" [ngModel]="easement.lengthPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="easement.unitType === 'A'">
          <label class="w-300">Cena VB [<span class="unit">Kč/m<sup>2</sup></span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.areaPrice=$event;refreshDefaultPrice()" [ngModel]="easement.areaPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="easement.unitType === 'T'">
          <label class="w-300">Cena VB [<span class="unit">Kč</span>]</label>
          <div class="input"><input type="text" (ngModelChange)="easement.totalPrice=$event;refreshDefaultPrice()" [ngModel]="easement.totalPrice" gmtInputNumber/></div>
        </div>
      </div>

      <div class="column-100" *ngIf="easement.priceType === 'S'">
        <label class="w-300">Původ ceny</label>
        <div class="info">{{ easement.otherSource}}</div>

        <div>
          <label class="w-300">Cena VB</label>
          <div class="input">
            <input type="text" (ngModelChange)="easement.totalPrice=$event;refreshDefaultPrice()" [ngModel]="easement.totalPrice" gmtInputNumber/>
          </div>
        </div>
      </div>

      <div class="column-100" *ngIf="showCoefficient && easement.priceType !== 'S' && easement.priceType !== null">
        <label class="w-300">{{ 'ps.easementCoefficient' | brandTranslate }}</label>
        <div class="input">
          <input type="text" (ngModelChange)="easement.priceCoefficient=$event;refreshPrice()" [ngModel]="easement.priceCoefficient" gmtInputNumber/>
        </div>
        <div class="info">
          <span (click)="easement.priceMultiply = !easement.priceMultiply;refreshPrice();">
            <gmt-checkbox [gmtChecked]="easement.priceMultiply"></gmt-checkbox> Násobit koeficientem
          </span>
        </div>
      </div>

      <div class="column-100" *ngIf="easement.priceType !== null">
        <label class="w-300">Celková cena {{ easement.constructionObjects.length > 1 ? 'společného' : '' }} VB</label>
        <div class="info">{{ calculatedPrice | number | price }}</div>
      </div>

      <div class="column-100" *ngIf="easement.priceType !== 'S' && easement.priceType !== null">
        <label class="w-300">
          <gmt-checkbox (changed)="easement.useCustomRounding=$event;refreshPrice()" [gmtChecked]="easement.useCustomRounding"></gmt-checkbox> Úprava celkové ceny VB (zaokrouhlení)
        </label>
        <div class="input" *ngIf="easement.useCustomRounding">
          <input type="text" [(ngModel)]="easement.easementPrice" gmtInputNumber/>
        </div>
        <div class="info" *ngIf="!easement.useCustomRounding">{{ easement.easementPrice | number | price }}</div>
      </div>

      <div class="column-100 text-center" *ngIf="easement.priceType === null">
        Nastavení ceny bude vráceno do výchozího stavu.
      </div>
    </fieldset>

    <expert-select-form [expert]="easement.expertOpinionExpert" (changed)="onExpertSelected($event)" *ngIf="easement.priceType === 'E'"></expert-select-form>

    <div class="actions">
      <gmt-button [gmtDisabled]="!isFormValid()" [clickAction]="onUpdate">Uložit údaje</gmt-button>
    </div>
  </div>

  <help [helpId]="helpIds.CONSTRUCTIONOBJECT_UPDATE_EASEMENT_PRICE" class="cm-help-page"></help>
</div>
