import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import * as _ from 'lodash';

import { ObligationModel } from '@app/ps/models/obligation.model';
import { ListService } from '@app/common/services/list.service';
import { CaseStatusModel } from '@app/ps/models/case-status.model';
import { AuthService } from '@app/common/services/auth.service';
import { StatisticsSettingModel } from '@app/ps/models/statistics-setting.model';
import { StatisticsCategoryModel } from '@app/ps/models/statistics-category.model';
import { StatisticsIntervalModel } from '@app/ps/models/statistics-interval.model';
import { StatisticsObjectTypeModel } from '@app/ps/models/statistics-object-type.model';
import { StatisticsObligationModel } from '@app/ps/models/statistics-obligation.model';
import { StatisticsStatusModel } from '@app/ps/models/statistics-status.model';
import { TypeEnum } from '@app/ps/models/statistics-object-type.model';
import { DialogService } from '@app/common/services/dialog.service';
import { StatisticsSettingsStatusEditComponent } from '@app/ps/project-settings/components/statistics-settings-status-edit/statistics-settings-status-edit.component';
import { ChecklistModel } from '@app/common/models/checklist.model';

class FormStatisticsObligationModel {
  obligationData : ObligationModel;
  categories: FormStatisticsCategoryModel[];
  repeat : boolean;
  reloadOptionsStatus : Function;
}

class FormStatisticsCategoryModel {
  category: StatisticsCategoryModel;
  obligation: FormStatisticsObligationModel;
  statusesData: StatisticsStatusModel[];
  reloadOptionsRegisterStatus : Function;
  reloadOptionsStatus? : Function;
  statusesFilter : Function;
}

class FormStatisticsSettingsModel {
  statistics? : StatisticsSettingModel;
  obligations? : FormStatisticsObligationModel[];
  categories? : StatisticsCategoryModel[];
  intervals? : StatisticsIntervalModel[];
  categoryId? : number;
  objectTypes? : FormStatisticsObjectTypeModel[];
  obligationsFilter? : any;
  reloadOptionsRegisterObligation? : Function;
  reloadOptionsObligation? : Function;
}

class FormStatisticsObjectTypeModel {
  item? : StatisticsObjectTypeModel;
  countTypeChecklist? : any;
  occupationTypes? : any[];
  type: TypeEnum;
  name: string;
}

@Component({
  selector: 'statistics-settings',
  templateUrl: './statistics-settings.component.html',
  styleUrls: ['./statistics-settings.component.scss']
})
export class StatisticsSettingsComponent implements OnInit {

  @Input() data : StatisticsSettingModel[];
  @Output() callbackRegister = new EventEmitter();

  formData: FormStatisticsSettingsModel[];
  editedObject: any;
  obligations: ObligationModel[];
  statuses: CaseStatusModel[];

  graphTypes: SwitchOption[] = [
    { id: 'INCREASING', value: 'Rostoucí' },
    { id: 'DECREASING', value: 'Klesající' }
  ];

  constructor(
    private listService: ListService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this.newCategoryChanged = this.newCategoryChanged.bind(this);
    this.addStatistics = this.addStatistics.bind(this);
    this.reload = this.reload.bind(this);
  }

  ngOnInit() {
    const obligationsData = this.listService.createList('obligations', { limit: null, filters: { loadCollections: 'statuses' }});
    this.listService.fetchResult(obligationsData).then(() => {
      this.obligations = obligationsData.list;
    });

    const statusData = this.listService.createList('case-statuses', { limit: null });
    this.listService.fetchResult(statusData).then(() => {
      this.statuses = statusData.list;
    });

    Promise.all([obligationsData.promise, statusData.promise]).then(() => {
      this.reload(this.data);
    });

    this.callbackRegister.emit({
      reload: this.reload
    });
  }

  reload(data) {
    this.data = data;
    this.formData = [];
    if (this.obligations && this.statuses) {
      this.data.forEach((statistic) => {
        this.initStatistics(statistic);
      });
    }
  }

  // General update button action

  edit(object) {
    if (this.isEdited(object)) {
      this.editedObject = undefined;
    } else {
      this.editedObject = object;
    }
  }

  isEdited(object) {
    return object === this.editedObject;
  }

  // Statistics

  addStatistics() {
    const statistic : StatisticsSettingModel = {
      name: '',
      categories: [],
      objectTypes: [],
      objectTypesCombination: false,
      intervals: [],
      available: false,
      statisticsOrder: this.data.length + 1,
    };
    this.data.push(statistic);
    this.initStatistics(statistic);
  }

  removeStatistics = (statistics) => {
    _.pull(this.data, statistics.statistics);
    _.pull(this.formData, statistics);
  }

  moveStatistics(statistics, direction) {
    StatisticsSettingsComponent.move(this.data, statistics.statistics, direction);
    StatisticsSettingsComponent.move(this.formData, statistics, direction);
    this.data.forEach((field, index) => { field.statisticsOrder = index + 1; });
  }

  toggleStatistics(statistics) {
    statistics.statistics.available = !statistics.statistics.available;
  }

  // Category

  newCategoryChanged(category, statistic) {
    if (category.name !== null && category === statistic.categories[statistic.categories.length - 1]) {
      const cat = {
        name: null,
        id: null,
        obligations: [],
      };
      statistic.categories.push(cat);
      category.id = ++statistic.categoryId;
      statistic.statistics.categories.push(category);
      statistic.obligations.forEach((obligationForm) => {
        const obligation = {
          type: obligationForm.obligationData.type,
          statuses: [],
        };
        category.obligations.push(obligation);
        this.addCategory(category, obligationForm, obligation);
      });
    }
  }

  removeCategory(category, statistic) {
    _.pull(statistic.categories, category);
    _.pull(statistic.statistics.categories, category);
    statistic.obligations.forEach((obligation) => {
      _.remove(obligation.categories, { category: category });
    });
  }

  upCategory(category, statistic) {
    StatisticsSettingsComponent.move(statistic.categories, category, -1);
    StatisticsSettingsComponent.move(statistic.statistics.categories, category, -1);
    statistic.obligations.forEach((obligation) => {
      StatisticsSettingsComponent.move(obligation.categories, _.find(obligation.categories, {category: category}), -1);
    });
  }

  downCategory(category, statistic) {
    StatisticsSettingsComponent.move(statistic.categories, category, +1, false);
    StatisticsSettingsComponent.move(statistic.statistics.categories, category, +1);
    statistic.obligations.forEach((obligation) => {
      StatisticsSettingsComponent.move(obligation.categories, _.find(obligation.categories, {category: category}), +1);
    });
  }

  onSplit(category) {
    category.split = !category.split;

    if (category.split) {
      category.splitName = category.name;
      category.splitNameAlternative = category.nameAlternative;
      category.splitGraphType = category.graphType;
      category.splitYAxisLabel = category.yAxisLabel;
      category.splitValueLabel = category.valueLabel;
      category.splitRemainLabel = category.remainLabel;

      category.name = null;
      category.nameAlternative = null;
      category.graphType = null;
      category.yAxisLabel = null;
      category.valueLabel = null;
      category.remainLabel = null;
    } else {
      category.name = category.splitName;
      category.nameAlternative = category.splitNameAlternative;
      category.graphType = category.splitGraphType;
      category.yAxisLabel = category.splitYAxisLabel;
      category.valueLabel = category.splitValueLabel;
      category.remainLabel = category.splitRemainLabel;

      category.splitName = null;
      category.splitNameAlternative = null;
      category.splitGraphType = null;
      category.splitYAxisLabel = null;
      category.splitValueLabel = null;
      category.splitRemainLabel = null;
    }
  }

  private addCategory(category : StatisticsCategoryModel, obligationForm, obligation) {
    if (!_.some(obligationForm.categories, {category: category})) {
      const cat : FormStatisticsCategoryModel = {
        category: category,
        obligation: obligation,
        statusesData: [],
        reloadOptionsRegisterStatus: (reloadOptions) => {
          cat.reloadOptionsStatus = reloadOptions;
        },
        statusesFilter: (caseStatus) => {
          return caseStatus.key !== 'Cancelled' &&
            _.includes(obligationForm.obligationData.statuses, caseStatus.key) &&
            _.every(obligationForm.categories, (formCategory) => {
              return (category !== formCategory.category && obligationForm.repeat) ||
              _.every(formCategory.obligation.statuses, (status) => status.status !== caseStatus.key);
            });
        },
      };
      obligationForm.categories.push(cat);
      return cat;
    }
  }

  // Obligation

  selectedObligation(formStatistics : FormStatisticsSettingsModel, obligationData : ObligationModel, data : any) {
    data.selected = null;
    formStatistics.categories.forEach((category) => {
      if (category.name === null) {
        return;
      }
      const obligation = {
        type: obligationData.type,
        statuses: [],
      } as StatisticsObligationModel;
      category.obligations.push(obligation);
      if (category !== formStatistics.categories[formStatistics.categories.length - 1]) {
        this.addObligation(formStatistics, obligation, category);
      }
    });
    this.addObligation(formStatistics, {
      type: obligationData.type,
      statuses: [],
    } as StatisticsObligationModel);

    formStatistics.reloadOptionsObligation();
  }

  removeObligation(obligation, formStatistics : FormStatisticsSettingsModel) {
    _.pull(formStatistics.obligations, obligation);
    formStatistics.categories.forEach((category) => {
      _.remove(category.obligations, {type: obligation.obligationData.type});
    });
    formStatistics.reloadOptionsObligation();
  }

  selectedStatus(obligation, category, status, data) {
    data.selected = null;
    this.addStatus(category, {
      status: status.key,
    });
    obligation.reloadOptionsStatus();
  }

  removeStatus(obligation, category, statusData) {
    _.remove(category.obligation.statuses, (caseStatus: any) => caseStatus.status === statusData.key);
    _.pull(category.statusesData, statusData);
    obligation.reloadOptionsStatus();
  }

  editStatus(category, statusData) {
    const caseStatus = category.obligation.statuses.find(caseStatus => caseStatus.status === statusData.key);
    const dialog = this.dialogService.open(StatisticsSettingsStatusEditComponent, {
      data: {
        statuses: [...this.statuses],
        caseStatus: Object.assign({}, caseStatus),
      },
    });

    const sub = dialog.afterClosed.subscribe((result: { caseStatus: StatisticsStatusModel }) => {
      if (result) {
        Object.assign(caseStatus, result.caseStatus);
      }
      sub.unsubscribe();
    });
  }

  public toggleRepeat(obligation : FormStatisticsObligationModel) {
    obligation.repeat = !obligation.repeat;
    obligation.reloadOptionsStatus();
  }

  private initStatistics(statistics : StatisticsSettingModel) {
    const formStatistics : FormStatisticsSettingsModel = {};
    formStatistics.statistics = statistics;
    formStatistics.obligations = [];
    formStatistics.categories = _.clone(statistics.categories);
    formStatistics.intervals = _.clone(statistics.intervals);
    formStatistics.categoryId = 0;
    formStatistics.objectTypes = [
      {type: TypeEnum.EASEMENT_GEOMETRIC_PLAN, name: 'Služebnosti podle dat GP'},
      {type: TypeEnum.EASEMENT_LAND_TAKE, name: 'Služebnosti podle dat ZE'},
      {type: TypeEnum.UNDER_ONE_YEAR, name: 'Dočasné zábory do jednoho roku'},
      {type: TypeEnum.OVER_ONE_YEAR, name: 'Dočasné zábory nad jeden rok'},
      {type: TypeEnum.PERMANENT, name: 'Trvalé zábory'},
    ];

    formStatistics.objectTypes.forEach((obectType) => {
      obectType.item = _.find(statistics.objectTypes, {type: obectType.type}) as any;
      if (obectType.item) {
        obectType.countTypeChecklist = new ChecklistModel(obectType.item.countType);
        obectType.item.occupationTypes = obectType.item.occupationTypes || [];
        obectType.occupationTypes = this.filterOccupationTypes(obectType.type);
      }
    });

    statistics.categories.forEach((category) => {
      formStatistics.categoryId = formStatistics.categoryId < category.id ? category.id : formStatistics.categoryId;
      category.obligations.forEach((obligation) => {
        this.addObligation(formStatistics, obligation, category);
      });
    });

    formStatistics.intervals.push({
      name: null,
    } as StatisticsIntervalModel);

    formStatistics.categories.push({
      name: null,
      id: null,
      obligations: [],
    } as StatisticsCategoryModel);

    formStatistics.obligationsFilter = this.getObligationsFilter(formStatistics);
    formStatistics.reloadOptionsRegisterObligation = (reloadOptions) => {
      formStatistics.reloadOptionsObligation = reloadOptions;
    };
    this.formData.push(formStatistics);
  }

  private addObligation(formStatistics : FormStatisticsSettingsModel, obligation : StatisticsObligationModel, category : StatisticsCategoryModel= null) {
    const obligationData = _.find(this.obligations, {type: obligation.type});
    if (!obligationData) {
      return;
    }
    let obligationForm = _.find(formStatistics.obligations, {obligationData: obligationData});

    if (!obligationForm) {
      obligationForm = {
        obligationData: obligationData,
        categories: [],
        repeat: false,
        reloadOptionsStatus: () => {
          obligationForm.categories.forEach((category) => category.reloadOptionsStatus ? category.reloadOptionsStatus() : null);
        },
      } as FormStatisticsObligationModel;
      formStatistics.obligations.push(obligationForm);
    }

    if (category) {
      const cat = this.addCategory(category, obligationForm, obligation);
      obligation.statuses.forEach((caseStatus) => this.addStatus(cat, caseStatus));
    }
  }

  private addStatus(category, caseStatus) {
    const statusData = _.find(this.statuses, {key: caseStatus.status});
    category.statusesData.push(statusData);

    if (!_.includes(category.obligation.statuses, caseStatus)) {
      category.obligation.statuses.push(caseStatus);
    }
  }

  private getObligationsFilter(formStatistics : FormStatisticsSettingsModel) {
    return (obligationData) => !_.some(formStatistics.obligations, { obligationData: obligationData });
  }

  // Object type

  isObjectTypeAvailable(objectTypeForm, statistic) {
    return _.some(statistic.obligations, (obligation) =>
      (obligation.obligationData.objectTypes.easementsGeometricPlan && objectTypeForm.type === 'EASEMENT_GEOMETRIC_PLAN') ||
      (obligation.obligationData.objectTypes.easementsLandTake && objectTypeForm.type === 'EASEMENT_LAND_TAKE') ||
      (obligation.obligationData.objectTypes.occupations && (objectTypeForm.type === 'OVER_ONE_YEAR' || objectTypeForm.type === 'UNDER_ONE_YEAR' || objectTypeForm.type === 'PERMANENT')) ||
      (objectTypeForm.type === 'PERMANENT' && (_.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'permanent') || _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'permanentWithSolutionTypeUnderOneYear') || _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'permanentWithSolutionTypeOverOneYear'))) ||
      (objectTypeForm.type === 'UNDER_ONE_YEAR' && _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'underOneYear')) ||
      (objectTypeForm.type === 'OVER_ONE_YEAR' && _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'overOneYear'))
    );
  }

  toggleObjectType(objectTypeForm, statistic) {
    if (objectTypeForm.item) {
      delete objectTypeForm.countTypeChecklist;
      _.pull(statistic.statistics.objectTypes, objectTypeForm.item);
      delete objectTypeForm.item;
      delete objectTypeForm.occupationTypes;
    } else if (this.isObjectTypeAvailable(objectTypeForm, statistic)) {
      objectTypeForm.item = {type: objectTypeForm.type, countType: [], occupationTypes: []};
      statistic.statistics.objectTypes.push(objectTypeForm.item);
      objectTypeForm.countTypeChecklist = new ChecklistModel(objectTypeForm.item.countType);
      objectTypeForm.occupationTypes = this.filterOccupationTypes(objectTypeForm.type);
    }
  }

  isOccupationTypeChecked(list, occupationType) {
    return list.some(item => item === occupationType.number);
  }

  toggleOccupationTypeChecked(list, occupationType) {
    this.isOccupationTypeChecked(list, occupationType) ? _.pull(list, occupationType.number) : list.push(occupationType.number);
  }

  private filterOccupationTypes(type) {
    if (type !== 'EASEMENT_GEOMETRIC_PLAN' && type !== 'EASEMENT_LAND_TAKE') {
      return this.authService.getActualProject().occupationTypes.filter(occ => {
        const cat = _.snakeCase(occ.category).toUpperCase();
        return cat === type;
      }).map(occ => {
        return {
          number: occ.number,
          name: occ.name,
        };
      });
    }
  }

  // Intervals list update functions

  upInterval(interval, statistic) {
    StatisticsSettingsComponent.move(statistic.intervals, interval, -1);
    StatisticsSettingsComponent.move(statistic.statistics.intervals, interval, -1);
  }

  downInterval(interval, statistic) {
    StatisticsSettingsComponent.move(statistic.intervals, interval, 1);
    StatisticsSettingsComponent.move(statistic.statistics.intervals, interval, 1);
  }

  newIntervalChanged(interval, statistic) {
    if (interval.name !== null && interval === statistic.intervals[statistic.intervals.length - 1]) {
      const int = {
        name: null,
      };
      statistic.intervals.push(int);
      statistic.statistics.intervals.push(interval);
    }
  }

  removeInterval(interval, statistic) {
    _.pull(statistic.intervals, interval);
    _.pull(statistic.statistics.intervals, interval);
  }

  // Common

  private static move(list, item, diff, lastIncluded = true) {
    const index = _.indexOf(list, item);
    if (index + diff < list.length - (lastIncluded ? 0 : 1) && index + diff >= 0) {
      _.pull(list, item);
      list.splice(index + diff, 0, item);
    }
  }
}
