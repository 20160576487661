<div class="bordered" *ngIf="list.itemCount">
  <div class="table">
    <div class="head-group sticky">
      <div class="row">
        <!-- vlastnik -->
        <span *ngIf="!hideColumn('owner')" class="cell">
          <sort-by
            [selected]="'sortName' === list.filter.sortOrder.sortBy"
            (sort)="onSort('sortName', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{getColumnName('owner')}}</sort-by>
        </span>

        <!-- adresa -->
        <span *ngIf="!hideColumn('address')" class="cell">{{getColumnName('address')}}</span>

        <!-- pripady -->
        <span *ngIf="!hideColumn('cases')" class="cell">
          <sort-by
            [selected]="'countCases' === list.filter.sortOrder.sortBy"
            (sort)="onSort('countCases', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{getColumnName('cases')}}</sort-by>
        </span>

        <!-- lv -->
        <span *ngIf="!hideColumn('titles')" class="cell">
          <sort-by
            [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1]?.sortBy === 'minTitle'"
            (sort)="onSort(['titleCount', 'minTitle'], $event)"
            [sortDir]="list.filter.sortOrder[0]?.direction"
          >{{getColumnName('titles')}}</sort-by>
        </span>

        <!-- k.u. -->
        <span *ngIf="!hideColumn('areaName')" class="cell">
          <sort-by
            [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1]?.sortBy === 'minAreaName'"
            (sort)="onSort(['areaCount', 'minAreaName'], $event)"
            [sortDir]="list.filter.sortOrder[0]?.direction"
          >{{getColumnName('areaName')}}</sort-by>
        </span>
        <span *ngIf="!hideColumn('pernamentOccupationState')" class="cell">{{getColumnName('pernamentOccupationState')}}</span>
        <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell">{{getColumnName('overOneYearOccupationState')}}</span>
        <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell">{{getColumnName('underOneYearOccupationState')}}</span>
        <span *ngIf="!hideColumn('easementCaseState')" class="cell">{{getColumnName('easementCaseState')}}</span>
      </div>
    </div>
    <div class="row-group">
      <a class="row" *ngFor="let owner of list.list"  uiSref="symap.project.owners.detail" [uiParams]="{ownerId: owner.id, tab: 'about'}">

        <!-- vlastnik -->
        <span class="cell cell--flex">
            <div class="cell--flex__container">
              <div class="cell--flex__container__main">
                <span>{{owner | subjectName}}</span>
              </div>
              <div class="cell--flex__container_side">
                <span title="Bývalý vlastník" class="tag tag--inactive tag--small is-uppercase ml-5" *ngIf="owner.endDate">BV</span>
                <span title="Problematický vlastník" class="tag tag--danger tag--small is-uppercase ml-5" *ngIf="owner.subjectDetail?.problematic">PV</span>
                <span title="Sankce" class="tag tag--danger tag--small is-uppercase ml-5" *ngIf="owner.subjectDetail?.ssEU || owner.subjectDetail?.ssNonEU || owner.subjectDetail?.ssRuskyVliv">Sankce</span>
                <ng-container *ngIf="showNavIcons">
                  <a class="pl-5 pt-5" uiSref="symap.project.owners.detail" [uiParams]="{projectKey: customProjectKey, ownerId: owner.id, tab: 'about'}" target="_blank" title="Zobrazit detail akce" (click)="$event.stopPropagation()"><i class="fa fa-eye"></i></a>
                  <a class="pl-5 pt-5" uiSref="symap.project.map" [uiParams]="{projectKey: customProjectKey, subject: owner.id, layer: ['geom_ku', 'kn_latest']}" target="_blank" title="Zobrazit v mapovém okně" (click)="$event.stopPropagation()"><i class="fa fa-map"></i></a>
                </ng-container>
              </div>
            </div>
        </span>

        <!-- adresa -->
        <span class="cell cell--flex" *ngIf="owner.subjectDetail && owner.subjectDetail.customAddressUse; else defaultAddress">
          <div class="cell--flex__container">
            <div class="cell--flex__container__main">
              {{ owner.subjectDetail | address }}
            </div>
            <div class="cell--flex__container_side">
              <span class="tag tag--inactive tag--small is-uppercase tag--not-clickable">
                Adresa nepochází z KN
              </span>
            </div>
          </div>
        </span>

        <ng-template #defaultAddress>
          <span class="cell">{{ owner | address }}</span>
        </ng-template>

        <!-- pripady -->
        <span class="cell no-padding cell-max-height">
          <cell-cases
            [casesCount]="owner.countCases"
            [tooltipType]="'cases'"
            [tooltipData]="{filter: {filters: {subjectId: owner.id, cancelStatus: 'notCancelled'}, limit: null}}"
          ></cell-cases>
        </span>

        <!-- lv -->
        <span class="cell number" [ngPlural]="getUniqueTitleCount(owner)">
          <ng-template ngPluralCase="=0">-</ng-template>
          <ng-template ngPluralCase="=1">
            <a uiSref="symap.project.titles.detail" [uiParams]="{ id: owner.ownerships[0].title.id, tab: null }" (click)="$event.stopPropagation()">
              {{ owner.ownerships[0].lv }}
            </a>
          </ng-template>
          <ng-template ngPluralCase="other">více LV</ng-template>
        </span>

        <!-- k.u. -->
        <span class="cell" [ngPlural]="getUniqueCadastreCount(owner)">
          <ng-template ngPluralCase="=0">-</ng-template>
          <ng-template ngPluralCase="=1">{{ owner.ownerships[0].title.area.name }}</ng-template>
          <ng-template ngPluralCase="other">více k.ú.</ng-template>
        </span>

        <!-- trvale zabory -->
        <span *ngIf="!hideColumn('pernamentOccupationState')" class="cell no-padding cell--chart cell-max-height">
          <settlement-progress-chart
            [tooltipType]="'parcels'"
            [tooltipData]="{occupationResolution: owner.occupationResolution, occupationCategory: 'permanent'}"
            [data]="owner.occupationResolution | settlementCategory: 'permanent'"
          ></settlement-progress-chart>
        </span>

        <!-- docasne zabory nad jeden rok -->
        <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell no-padding cell-max-height cell--chart">
          <settlement-progress-chart
            [tooltipType]="'parcels'"
            [tooltipData]="{occupationResolution: owner.occupationResolution, occupationCategory: 'overOneYear'}"
            [data]="owner.occupationResolution | settlementCategory: 'overOneYear'"
          ></settlement-progress-chart>
        </span>

        <!-- docasne zabory do jednoho roku -->
        <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell no-padding cell-max-height cell--chart">
          <settlement-progress-chart
            [tooltipType]="'parcels'"
            [tooltipData]="{occupationResolution: owner.occupationResolution, occupationCategory: 'underOneYear'}"
            [data]="owner.occupationResolution | settlementCategory: 'underOneYear'"
          ></settlement-progress-chart>
        </span>

        <!-- vecna bremena -->
        <span *ngIf="!hideColumn('easementCaseState')" class="cell no-padding cell-max-height cell--chart">
          <settlement-progress-chart
            tooltipType="easements"
            [tooltipData]="{occupationResolution: owner.occupationResolution, occupationCategory: 'easement'}"
            [data]="owner.occupationResolution | settlementCategory: 'easement'"
          ></settlement-progress-chart>
        </span>
      </a>
    </div>
  </div>
</div>
<div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
  <span>Nebyla nalezena žádná data.</span>
</div>
