import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import {StateService} from "@uirouter/angular";
import * as _ from 'lodash';

import { CustomerContract } from '@app/models/customerContract';
import { ContractService } from '@app/settings/customers/services/contract.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';

@Component({
  selector: 'contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
})
export class ContractFormComponent implements OnInit {

  private static readonly FILE_EXTENSION_WHITELIST: string[] = ['pdf'];
  readonly fileUploadMessage: string = 'Vložte smlouvu ve formátu PDF.';
  contract: CustomerContract;

  constructor(
    private contractService: ContractService,
    private config: DialogConfig,
    public dialog: DialogRef,
    private stateService: StateService,
  ) {
    if (config.data && (<DialogConfigData>config.data).contract) {
      this.contract = _.clone((<DialogConfigData>config.data).contract);
    } else {
      this.contract = new CustomerContract();
    }

    this.save = this.save.bind(this);
    this.isComplete = this.isComplete.bind(this);
  }

  ngOnInit() { }

  /**
   * Save new contract.
   *
   * @returns {Promise}
   */
  async save(): Promise<any> {
    let data: FormData = new FormData();
    data.append('subject', this.contract.subject);
    data.append('signDate', formatDate(this.contract.signDate, 'yyyy-MM-dd', 'cs'));
    data.append('contractNumber', this.contract.contractNumber);

    if (this.contract.description) {
      data.append('description', this.contract.description);
    }

    if (this.contract.file) {
      data.append('file', this.contract.file['fileEntry']);
    }

    if (this.contract.idText) {
      return await this.contractService
        .update(this.contract.idText, data)
        .then((res) => {
          this.dialog.close();
          this.stateService.go('^', {id: res.idText});

        });
    } else {
      return await this.contractService
        .create((<DialogConfigData>this.config.data).customerId, data)
        .then(() => {
          this.dialog.close(true);
        });
    }
  }

  /**
   * Check the contract is complete.
   *
   * @returns {boolean}
   */
  isComplete(): boolean {
    const values: any[] = [
      this.contract.contractNumber,
      this.contract.signDate,
      this.contract.subject,
    ];

    // check the file is present on new contract
    if (!this.contract.idText) {
      values.push(this.contract.file);
    }

    return values.some(x => (x === null || x === '' || typeof x === 'undefined'));
  }

  /**
   * Check the uploaded file matches validity criteria.
   *
   * @returns {boolean}
   */
  isFileUploadValid(file): boolean {
    return ContractFormComponent.FILE_EXTENSION_WHITELIST.includes(file.extension.toLowerCase());
  }

  /**
   * Reset contract file when new file is uploaded.
   *
   * @returns {void}
   */
  onFileAdded(files: any[]) {
    this.contract.file = files.filter(f => f.valid)[0];
  }
}
