import { Component, Input, OnInit, Type } from '@angular/core';
import { TooltipVfzeProblemsComponent } from '@app/common/components/tooltip-vfze-problems/tooltip-vfze-problems.component';
import { VfzeValidationResponseModel } from '@app/common/models/vfze-validation-response.model';

export interface VfzeRowModel {
  line: number;
  content: string;
  problems?: VfzeProblemModel[];
}

export interface VfzeRowsRange {
  low: number;
  high: number;
}

@Component({
  selector: 'vfze-problems',
  templateUrl: './vfze-problems.component.html',
  styleUrls: ['./vfze-problems.component.scss']
})
export class VfzeProblemsComponent implements OnInit {

  @Input() validationResponse: VfzeValidationResponseModel;
  @Input() vfzeSource: string;

  loading = true;
  rowsToShow: VfzeRowModel[];
  tooltipProblemsComponent: Type<TooltipVfzeProblemsComponent> = TooltipVfzeProblemsComponent;
  private rows: VfzeRowModel[];
  private readonly boundary = 10;

  // positioning
  // totalHeight has to be dividible by rowHeight without reminder. Any change of rowHeight needs change in scss...
  viewHeight = 600;
  totalHeight = this.viewHeight;
  rowHeight = 30;
  marginTop = 0;
  marginBottom = 0;
  showFromRow = 0;

  ngOnInit() {
    this.prepareRows();

    if (this.rows.length * this.rowHeight > this.totalHeight) {
      this.totalHeight = this.rows.length * this.rowHeight;
      this.marginBottom = this.totalHeight - this.viewHeight;
      this.rowsToShow = this.rows.slice(this.showFromRow, this.viewHeight / this.rowHeight);
    } else {
      this.rowsToShow = this.rows;
    }

    this.loading = false;
  }

  onScroll(event: any) {
    const top = event.target.scrollTop;

    if (top > this.totalHeight - this.viewHeight) {
      return;
    }

    this.marginTop = top;
    this.marginBottom = this.totalHeight - this.viewHeight - top;
    this.showFromRow = Math.floor(top / this.rowHeight);
    this.rowsToShow = this.rows.slice(this.showFromRow, this.showFromRow + this.viewHeight / this.rowHeight);
  }

  /**
   * Select rows in boundary around problematic rows
   */
  private prepareRows() {
    const allRows = this.vfzeSource.split('\n');
    this.rows = [];

    // Get problems
    const problems = this.validationResponse.fatal.map(p => { return {...p, type: 'fatal'}; })
        .concat(this.validationResponse.errors.map(p => { return {...p, type: 'errors'}; }))
        .concat(this.validationResponse.warnings.map(p => { return {...p, type: 'warnings'}; }))
        .filter(p => p.xmlStructureError && p.line !== null)
        .map(p => { return {...p, line: p.line - 1}; }) // wrong number of lines from BE
        .sort((a, b) => a.line - b.line);

    const problemLines = [...new Set(problems.map(p => p.line))];

    const problemIndex = {};
    for (const line of problemLines) {
      problemIndex[line] = problems.filter(p => p.line === line);
    }

    // Get rows ranges
    const ranges: VfzeRowsRange[] = [];

    for (const line of problemLines) {
      const lowBoundary = (line - this.boundary < 0 ? 0 : line - this.boundary);
      const highBoundary = (line + this.boundary > allRows.length - 1 ? allRows.length - 1 : line + this.boundary);
      const prev = ranges[ranges.length - 1];

      if (!prev || prev.high < lowBoundary) {
        ranges.push({ low: lowBoundary, high: highBoundary });
      } else if (prev.high < highBoundary) {
        prev.high = highBoundary;
      }
    }

    // Prepare rows by ranges
    for (const range of ranges) {
      const rows = allRows
        .slice(range.low, range.high)
        .map((r, i) => {
          return {
            line: range.low + i,
            content: r,
            problems: problemIndex[range.low + i] ? problemIndex[range.low + i] : []
          };
        });

      this.rows = this.rows.concat(rows, [{ line: undefined, content: undefined, problems: undefined }]);
    }
  }
}

