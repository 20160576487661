<div class="tabbable-line tabs-below">
  <ul *ngIf="tabs" class="nav nav-tabs">
    <li *ngFor="let tab of tabs"
        title="{{ tab.title || tab.name }}"
        class="nav-item"
        [ngClass]="{active: isActive(tab.id), disabled: isDisabled(tab.id)}"
    >
      <a class="nav-link" [ngClass]="{active: isActive(tab.id)}"
         uiSref="{{ isDisabled(tab.id) ? '' : tab.href }}"
         [uiParams]="(tab.urlParams ? tab.urlParams : {})"
         [uiOptions]="{ replace:true, location:'replace' }"
         [attr.disabled]="isDisabled(tab.id)"
      >
        {{ tab.name }}

        <span *ngIf="tab.required">*</span>
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <ng-content class="active"></ng-content>
  </div>
</div>
