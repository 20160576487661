import { Component, OnInit, Inject } from '@angular/core';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import {AuthService} from "@app/common/services/auth.service";

@Component({
  selector: 'vfze-import-raw-data',
  templateUrl: './vfze-import-raw-data.component.html',
})
export class VfzeImportRawDataComponent extends VfzeImportStepComponent implements OnInit {
  public imported = false;

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.uploadService.upload({
      url: this.restangular.configuration.baseUrl + '/vfze/save',
      data: { vfze: this.data.files[0].fileEntry },
      headers: { Authorization: this.authService.getToken() }
    }).then((data) => {
      this.imported = true;
    }, (r) => {
       this.errorHandlerService.get(r);
       this.backCallback.emit();
    });
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
