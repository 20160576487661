<div [loading]="loading">
  <div *ngIf="!loading">
    <div *ngIf="projects.length > 0">
      <div class="column-100 checklist">
        <div *ngFor="let project of projects" class="checklist-item">
          <div (click)="onPermissionsChange(project)">
            <gmt-checkbox gmtType="multiselect" (click)="onPermissionsChange(project, true); $event.preventDefault(); $event.stopPropagation();" [gmtChecked]="checklistSelection.isChecked(project.key)" class="clickable"></gmt-checkbox> {{ project.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="empty-info bordered" *ngIf="projects.length === 0">
      <span>
        Nenalezena žádná data
      </span>
    </div>
  </div>
</div>
