import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { AuthService } from '@app/common/services/auth.service';
import { saveAs } from 'file-saver';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'vfze-list',
  templateUrl: './vfze-list.component.html',
  styleUrls: []
})
export class VfzeListComponent implements OnInit {

  list: ListModel<any>;
  pageableList: PageableList;

  private defaultFilter = {
    filters: {
      searchText: { values: [{ id: '' }]},
    }
  };

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    private restangular: Restangular,
    public userNamePipe: UserNamePipe,
    private pageableListService: PageableListService,
    private authService: AuthService,
  ) {
    this.onGenerateFromProject = this.onGenerateFromProject.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onGenerateFromProject() {
    return this.restangular.one(`vfze/generate`).withHttpConfig({ responseType: 'blob' }).get().toPromise().then((response) => {
      saveAs(response, this.authService.getActualProject().key + '.vfze', true);
    });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'vfze',
      this.defaultFilter
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
