﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'construction-object-detail-create-case-select-area',
	templateUrl: './create-case-select-area.component.html',
})
export class CreateCaseSelectAreaComponent implements OnInit {
  @Input()
  private constructionObjectId: number;
  @Input()
  data: any;
  @Output()
  private submitCallback = new EventEmitter();
  @Output()
  private backCallback = new EventEmitter();
  @Input()
  private hasStep: Function;
  public loading: boolean = true;
  public dataConstructionObject: any;

  constructor(
    private authService: AuthService,
    private listService: ListService,
    private dialogService: DialogService,
    private restangular: Restangular,
  ) {
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {
    this.data.geometricPlansSelected = this.data.geometricPlansSelected || [];
    delete this.data.easementsSelected;
    this.data.geometricPlansSelected.forEach(geometricPlan => {
      delete geometricPlan.easementChecklist;
    });
    this.loadConstructionObject();
  }

  loadConstructionObject() {
    this.restangular.one('construction-objects', this.constructionObjectId).get({loadCollections: ['geometricPlans']}).toPromise()
      .then((data) => {
        this.dataConstructionObject = data;
        this._sortGeometricPlans();
        this.loading = false;
      });
  }

  _sortGeometricPlans() {
    this.dataConstructionObject.geometricPlans.sort((a, b) => {
      if (a.kutx === b.kutx) {
        return 0;
      } else if (a.kutx && b.kutx && typeof a.kutx.localeCompare === 'function') {
        return a.kutx.localeCompare(b.kutx);
      } else {
        return (a.kutx || '') > (b.kutx || '') ? 1 : -1;
      }
    });
  }

  toggleSelectionGeometricPlan(array, item) {
    if (this.isDisabled(item)) {
      return;
    }
    let check = !this.isChecked(array, item);
    let globalPromise = Promise.resolve(true);
    this.dataConstructionObject.geometricPlans.forEach((geometricPlan) => {
      if (!this.isDisabled(geometricPlan) && geometricPlan.ku === item.ku) {
        let promise = null;
        if (geometricPlan === item) {
          promise = Promise.resolve(true);
        } else if (check === this.isChecked(array, geometricPlan)) {
          promise = Promise.resolve(false);
        } else {
          promise = globalPromise = globalPromise
            .then(() => this.dialogService.confirmDialogPromise('Ve stejném katastrálním území se nachází geometrický plán č. ' + geometricPlan.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?'));
        }
        promise.then((result) => {
          if (result) {
            if (check) {
              array.push(geometricPlan);
            } else {
              _.remove(array, (item: any) => item.id === geometricPlan.id);
            }
          }
        });
      }
    });
  }

  isChecked(array, item) {
    return _.some(array, {id: item.id});
  }

  isDisabled(item) {
    return item.fiktivni && (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated');
  }

  submit() {
    if (!this.data.geometricPlansSelected.length) {
      return;
    }
    return this.submitCallback.emit();
  }
}
