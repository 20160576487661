<h1>Editace nápovědy</h1>

<fieldset class="no-legend">
  <div class="column-100">
    <label>ID</label>
    <div class="info">
      {{help.id}}
    </div>
    <label>ID text</label>
    <div class="info">
      {{help.strId}}
    </div>
    <label>Url adresa</label>
    <div class="input">
      <input type="text" [(ngModel)]="help.url">
    </div>
    <label>Titulek</label>
    <div class="input">
      <input type="text" [(ngModel)]="help.title">
    </div>
    <label>Popis</label>
    <div class="input">
      <input type="text" [(ngModel)]="help.description">
    </div>
  </div>
</fieldset>

<div class="actions">
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="disabled()">Uložit</gmt-button>
</div>
