<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV' | wordtranslate }}</a>
  </div>
  <h1>Zadání nového věcného břemena</h1>
  <div class="mt-20">
    <div class="flexbox-container">
      <div class="content-menu">
        <div
          *ngFor="let step of steps"
          (click)="onStep(step.id)"
          [ngClass]="{ active : step.id === activeStep.id, cancelled: !step.enabled }
        ">{{ step.name }}</div>
      </div>
      <div class="flex-rest">
        <easement-create-add-files
          *ngIf="activeStep.id === 'add-files'"
          [data]="data"
          (submitCallback)="onStep('info', true)"
        ></easement-create-add-files>

        <easement-create-info
          *ngIf="activeStep.id === 'info'"
          [data]="data"
          (submitCallback)="onStep('summary', true)"
          (backCallback)="onStep('add-files')"
        ></easement-create-info>

        <easement-create-summary
          *ngIf="activeStep.id === 'summary'"
          [data]="data"
          (submitCallback)="onFinish()"
          (backCallback)="onStep('add-files')"
        ></easement-create-summary>
      </div>
    </div>
  </div>

  <help [helpId]="helpIds[activeStep.help]" class="cm-help-page"></help>
</div>
