<div class="content-loading small-spinner right" *ngIf="areaLoading === true">
  <div class="loader"><span class="loading-spinner"></span></div>
</div>
<div class="switch" gmtAuth="manage_property_status" [ngClass]="{'hidden-switch': areaLoading}">
  <ul class="options">
    <li [ngClass]="{active: null === selected}">
      <a (click)="updateSelection(null);">Výchozí</a>
    </li>
    <li [ngClass]="{active: true === selected}">
      <a (click)="updateSelection(true);">{{options.wanted}}</a>
    </li>
    <li [ngClass]="{active: false === selected}">
      <a (click)="updateSelection(false);">{{options.unwanted}}</a>
    </li>
  </ul>
</div>
<div gmtAuthNot="manage_property_status">
  <span *ngIf="null === selected">Výchozí</span>
  <span *ngIf="true === selected">{{options.wanted}}</span>
  <span *ngIf="false === selected">{{options.unwanted}}</span>
</div>
