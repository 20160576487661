<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset class="bordered" *ngFor="let item of data.ownershipsWithParent">
      <legend *ngIf="item.caseOwnership.ownership.isBsm">Vlastník - Osoba SJM</legend>
      <legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OPO'">Vlastník - Společnost</legend>
      <legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OFO'">Vlastník - Fyzická osoba</legend>
      <div class="column-100">
        <label>LV</label>
        <div class="info">
          <b>{{item.caseOwnership.ownership.title|titleName}}</b>
        </div>
      </div>
      <div class="column-100">
        <label>Typ práv</label>
        <div class="info">{{item.caseOwnership.ownership.typravNazev}}</div>
      </div>
      <div class="column-100">
        <label>{{item.caseOwnership.ownership.subjects[0].opsubType === 'OPO' ? 'Název' : 'Jméno'}}</label>
        <div class="info">
          <b>{{item.caseOwnership.ownership|ownershipName}}</b>
        </div>
      </div>
      <div class="column-100">
        <label>Nadřazený subjekt</label>
        <div class="input"><gmt-select [optionItems]="item.optionItems" [itemPipe]="parentCaseOwnershipNamePipe" [data]="item.caseOwnership" field="parentCaseOwnership" (changed)="changedParent($event.newValue, $event.oldValue, item)"></gmt-select></div>
      </div>
    </fieldset>

    <div class="mt-10">
      <gmt-button [clickAction]="submit" [gmtDisabled]="needsParentSelection()">Další</gmt-button>
    </div>
  </div>
</div>
