import { Component, Input, Inject, OnInit, Output, EventEmitter } from "@angular/core";
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
	selector: 'form-case-sign-owner',
	templateUrl: './form-case-sign-owner.component.html',
})
export class FormCaseSignOwnerComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  // data shared for each case
  public data: any;
  @Input()
  // data set per case
  private dataCases: any[];
  @Input()
  // list of cases to fill in
  cases: any[];
  caseIndex = -1;
  firstCaseId;
  private subjectChecklist: any;
  public displaySignatureVerificationPrice: boolean = false;
  public signedDate: string;
  public signatureVerificationPrice: number;

  constructor(
    private restangular: Restangular,
  ) {
    this.isValid = this.isValid.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.checkSubject = this.checkSubject.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  ngOnInit() {
    this.data.responseReceivedDate = new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });

    this.next();
    this.displaySignatureVerificationPrice = this.cases.some(bCase => this.signatureVerificationPriceEnabled(bCase.obligation.type));
  }

  signatureVerificationPriceEnabled(obligationType) {
    const whitelist = [
      'AccessToLandLiability',
      'AgreementOnAFutureNetworkConstructionContractLiability',
      'AgreementOnAFutureTransferContractLiability',
      'AgreementOnAOtherFutureContractLiability',
      'BuildingPlacementLiability',
      'BorrowingLiability',
      'OtherLiability',
      'OwnersStatementLiability',
      'RightToRealizeABuildingLiability',
      'FutureRealBurdenLiabilityAccelerated',
    ];
    return !whitelist.includes(obligationType);
  }

  async load(caseIndex: number) {
    const bcase = this.cases[caseIndex];
    if (bcase.caseSubjects.length) {
      return;
    }
    await this.restangular.one('cases', bcase.id).get({loadCollections: ['caseSubjects', 'caseOwnerships']})
      .toPromise()
      .then((dataCase) => {
        bcase.caseSubjects = dataCase.caseSubjects.filter(
          (value) => !value.signedDate && value.subject.opsubType !== "BSM" &&
            dataCase.caseOwnerships.some((value2) => {
              let bsmSubject;
              if (value2.ownership.isBsm) {
                bsmSubject = dataCase.caseSubjects.find((cs) => cs.subject.opsubId === value2.ownership.opsubId).subject;
              }
              return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)))
            })
        );
      });
  }

  async previous() {
    if (this.caseIndex > this.firstCaseId) {
      await this.load(this.caseIndex - 1);
      this.caseIndex--;
      if (this.cases[this.caseIndex].caseSubjects.length === 1) {
        this.previous();
      } else {
        this.subjectChecklist = new ChecklistModel(this.dataCases[this.caseIndex].caseSubjects);
      }
    }
  }

  async next() {
    if (this.caseIndex + 1 < this.cases.length) {
      await this.load(this.caseIndex + 1);
      this.caseIndex++;
      this.dataCases[this.caseIndex] = this.dataCases[this.caseIndex] || {caseSubjects: []};
      this.subjectChecklist = new ChecklistModel(this.dataCases[this.caseIndex].caseSubjects);
      const sentSubjects = this.cases[this.caseIndex].caseSubjects
                .filter((caseSubject) => caseSubject.sentDate || caseSubject.personalVisitDate);
      if (this.dataCases[this.caseIndex].caseSubjects.length === 0) {
        sentSubjects.forEach(this.checkSubject);
      }
      if (this.cases[this.caseIndex].caseSubjects.length === sentSubjects.length) {
        this.next();
      } else {
        if (this.firstCaseId === undefined) {
          this.firstCaseId = this.caseIndex;
        }
      }
    } else {
      this.caseIndex++;
    }
  }

  checkSubject(caseSubject) {
    this.setData(this.cases[this.caseIndex].obligation.type, caseSubject);
    this.subjectChecklist.toggleSelection(caseSubject);
  }

  setData(obligationType, caseSubject) {
    if (this.signatureVerificationPriceEnabled(obligationType)) {
      caseSubject.signatureVerificationPrice = this.signatureVerificationPrice;
    }
    caseSubject.signedDate = this.signedDate;
  }

  onUpdate() {
    this.dataCases.forEach((bCase, i) => {
      bCase.caseSubjects.forEach(caseSubject => {
        this.setData(this.cases[i].obligation.type, caseSubject);
      });
    });
  }

  checkedSubject(caseSubject) {
    return this.subjectChecklist.isChecked(caseSubject);
  }

  isValidCurrentCase() {
    return this.dataCases[this.caseIndex].caseSubjects.length;
  }

  isValid() {
    return !!this.signedDate && !!this.data.responseReceivedDate && this.caseIndex === this.cases.length;
  }
}
