<fieldset *ngIf="!nestedForm">
  <legend>Platební údaje</legend>
  <ng-container *ngTemplateOutlet="inner"></ng-container>
</fieldset>

<div *ngIf="nestedForm">
  <ng-container *ngTemplateOutlet="inner"></ng-container>
</div>

<ng-template #inner>
  <div class="column-100">
    <gmt-input label="Způsob platby">
      <gmt-switch [options]="options" [(selectedId)]="paymentOptions.paymentType"></gmt-switch>
    </gmt-input>
  </div>

  <ng-container *ngIf="isBankTransferPayment()">
    <div class="column-50">
      <gmt-input label="Číslo účtu">
        <input type="text" [(ngModel)]="paymentOptions.accountNumber" />
      </gmt-input>

      <gmt-input label="Kód banky">
        <input type="text" [(ngModel)]="paymentOptions.bankCode" />
      </gmt-input>
    </div>

    <div *ngIf="withPaymentDetails" class="column-50">
      <gmt-input label="Variabilní symbol">
        <input type="text" [(ngModel)]="paymentOptions.variableSymbol" />
      </gmt-input>

      <gmt-input label="Specifický symbol">
        <input type="text" [(ngModel)]="paymentOptions.specificSymbol" />
      </gmt-input>

      <gmt-input label="Konstantní symbol">
        <input type="text" [(ngModel)]="paymentOptions.constantSymbol" />
      </gmt-input>
    </div>
  </ng-container>
</ng-template>
