<div>
  <h1>Vlastní statistika</h1>

  <fieldset>
    <div class="column-100">
      <label>Název <div class="error-message inline" title="Povinný údaj">*</div></label>
      <div class="input"><input type="text" [(ngModel)]="data.name"/></div>
    </div>
  </fieldset>

  <div class="actions">
    <gmt-button class="red" [clickAction]="onCancel">Zrušit</gmt-button>
    <gmt-button [clickAction]="onSave">Uložit</gmt-button>
  </div>
</div>
