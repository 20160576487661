import { Component, OnInit, Input } from '@angular/core';

import { EasementModel } from '@app/common/models/easement.model';
import { TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { AuthService } from '@app/common/services/auth.service';
import { UpdateEasementPriceComponent } from '@app/ps/components/update-easement-price/update-easement-price.component';
import { DialogService } from '@app/common/services/dialog.service';

@Component({
  selector: 'table-easements',
  templateUrl: './table-easements.component.html',
  styleUrls: ['./table-easements.component.scss']
})
export class TableEasementsComponent extends TableParcelsAbstractComponent<EasementModel> implements OnInit {
  @Input() showUpdate: boolean;

  // props
  protected readonly COMPONENT_COLUMNS = [
    'area_name', 'title_name', 'parcel', 'parcel_title', 'geometric_plan', 'construction_object',
    'title', 'area', 'length', 'subject_price', 'title_price', 'case_price', 'parcel_price', 'case', 'vb_tz'
  ];

  constructor(
    private authService: AuthService,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    // If find some easement with property oznacVb, then set $scope.easementType to true, else false
    this.easementType = this.data && this.data.some((easement: EasementModel) => easement.oznacVb);
  }

  canEdit(permissions) {
    return this.authService.isAuthorized()
        || this.authService.hasPermission("assignable")
        || this.authService.hasPermission(permissions);
  }

  isEditablePrice(easement: EasementModel) {
    const easementGlobalPriceType = new Set(easement.constructionObjects.map(co => co.easementGlobalPriceType)).size == 1 ?
      easement.constructionObjects[0].easementGlobalPriceType : null;
    const easementGlobalOtherUnitType = new Set(easement.constructionObjects.map(co => co.easementGlobalOtherUnitType)).size == 1 ?
      easement.constructionObjects[0].easementGlobalOtherUnitType : null;

    // @TODO - refactor
    return easement.parcel.title
      && (this.authService.isAuthorized() || this.authService.hasPermission("assignable"))
      && (easementGlobalPriceType === 'I'
        || (
          easementGlobalPriceType === 'B'
          && easementGlobalOtherUnitType === 'S'
          && easement.acceleratedCaseStatus === 'DeclinedOwner'
        )
      )
      && !easement.existsCaseBlockingPrice;
  }

  onPriceEdit(easement: EasementModel) {
    if (!this.isEditablePrice(easement)) {
      return;
    }

    const dialog = this.dialogService.open(UpdateEasementPriceComponent, {
      data: { easementId: easement.id },
    });

    const sub = dialog.afterClosed.subscribe(updatedEasement => {
      if (updatedEasement) {
        easement.easementPrice = updatedEasement.easementPrice;
      }
      sub.unsubscribe();
    });
  }
}
