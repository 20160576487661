export class StringUtils {

  static removeSpaces(from: string): string {
    return from.replace(/\s/g, '');
  }

  static removeMultipleSpaces(from: string) {
    return from.replace(/\s\s+/g, ' ').trim();
  }

  static removeSpecialChars(from: string): string {
    return from.replace(/[^a-zA-Z0-9\-_]/g, '-');
  }

  static valueToString(value: any): string {
    switch (typeof value) {
      case 'string': return value;
      case 'object':
      case 'undefined': return '';
      case 'boolean':
      case 'number': return String(value);
      default: return '';
    }
  }

  static capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  /**
   * Search string in other string.
   * Is possible to use ? and * wildcard chars for searching.
   *
   * @param {string} search
   * @param {string} where
   */
  static searchStringWithWildcard(search: string, where: string): boolean {
    if (search.length === 0) {
      return false;
    }

    const whereSanitized = StringUtils.removeMultipleSpaces(where);

    // create search RegExp - escape RegExp reserved chars except ? and *
    const searchRegExp = StringUtils.removeMultipleSpaces(search)
        .replace(/([.+^=!:${}()|\[\]\/\\])/g, '\\$1')
        .replace(/\?/g, '.')
        .replace(/\*/g, '.*?');

    const searchChunks = searchRegExp.split(' ');

    return searchChunks.every((chunk) => {
      return new RegExp('([\\s]|^)' + chunk, 'i').test(whereSanitized);
    });
  }

  static imageToBase64(path: string): Promise<any> {
    return fetch(path)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
        });
      });
  }
}
