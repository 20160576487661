import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './wsdp-credentials.component.html'
})
export class WsdpCredentialsComponent implements OnInit {

  // model
  cuzkAccountValidationMessage: string;
  cuzkWebserviceName: string;
  cuzkWebservicePassword: string;
  helpIds = HelpService.HELP_IDS;

  // params
  backSref: string;

  constructor(
    private restangular: Restangular,
      private stateService: StateService
  ) {
    this.update = this.update.bind(this);
    this.isUpdateDisabled = this.isUpdateDisabled.bind(this);
  }

  ngOnInit() {
    this.backSref = this.stateService.params.backSref;
  }

  async update() {
    const valid = await this.validateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword);
    if (valid) {
      await this.updateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword);
      if (this.backSref) {
        this.stateService.go(this.stateService.params.backSref);
      }
    }
  }

  isUpdateDisabled() {
    return !!this.cuzkWebserviceName && !!this.cuzkWebservicePassword;
  }

  private updateWsdpAccount(name: string, password: string) {
    return this.restangular.one('cuzk/save-credentials').customPUT({
      userName: name,
      password: password
    }).toPromise();
  }

  private validateWsdpAccount(name: string, password: string): Promise<boolean> {
    this.cuzkAccountValidationMessage = '';

    return this.restangular.one('cuzk/verify-credentials').customPOST({
      userName: name,
      password: password
    }).toPromise().then(data => {
      const { valid } = data;
      this.cuzkAccountValidationMessage = (valid ? '' : 'Zadali jste neplatné přihlašovací údaje k WSDP účtu.');
      return valid;
    });
  }
}
