﻿import { Component, Input, OnInit, Inject, Output, EventEmitter } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { ActingPersonNamePipe } from '@app/ps/pipes/acting-person-name.pipe';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'form-cadastre-entry-proposal',
  templateUrl: './form-cadastre-entry-proposal.component.html',
  styleUrls: [],
})
export class FormCadastreEntryProposalComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Output()
  load = new EventEmitter();
  @Input()
  data: any;

  public sendDataProposal: any = {};
  public cadastreNotes: any[] = null;
  public accountNumber = null;
  public cadastreNotesReload: Function = null;
  public actingPersonReload: Function = null;
  public investor: any;
  public cadastreAgent: any;
  public actingPersons: any[];
  public entryProposalName: string;
  public loading: boolean = true;

  constructor(
    private authService: AuthService,
    public actingPersonNamePipe: ActingPersonNamePipe,
    private restangular: Restangular,
  ) {
    this.hasValidProposalCredentials = this.hasValidProposalCredentials.bind(this);
    this.sendProposal = this.sendProposal.bind(this);
    this.deleteProposal = this.deleteProposal.bind(this);
  }

  ngOnInit() {
    let constructionObjectsPromise: Promise<any> = Promise.resolve();
    if (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated') {
      constructionObjectsPromise = this.restangular.all('construction-objects').customPOST({filter: {filters: {caseId: [this.data.id], loadCollections: ['administrator', 'administrator.actingPersons']}}}).toPromise();
    }
    Promise.all([
      constructionObjectsPromise,
      this.authService.getActualProject().investor ? this.restangular.one('project-subjects', this.authService.getActualProject().investor.id).get({loadCollections: ['cadastreAgent', 'actingPersons', 'cadastreAgent.actingPersons']}).toPromise() : Promise.resolve()
    ]).then((data) => {
      if (data[0] && data[0][0] && data[0][0]['administrator']) {
        this.investor = data[0][0]['administrator'];
      } else {
        this.investor = data[1];
      }
      if (this.investor) {
        this.investor.actingPersons = this.investor.actingPersons ? this.investor.actingPersons.filter(item => item.cancelled === false && item.roleCadastre === true) : null;
      }
      this.cadastreAgent = data[1] ? data[1].cadastreAgent : null;
      if (this.cadastreAgent) {
        this.cadastreAgent.actingPersons = this.cadastreAgent.actingPersons ? this.cadastreAgent.actingPersons.filter(item => item.cancelled === false && item.roleCadastre === true) : null;
      }
      this.sendDataProposal.useCadastreAgent = !!this.cadastreAgent;
      this.updateCadastreActingPerson();
      this.load.emit();
      this.loading = false;
    });

    this.callbackRegister.emit({
      isValid: this.hasValidProposalCredentials,
      submit: this.sendProposal,
      delete: this.deleteProposal,
    });
  }

  updateCadastreActingPerson() {
    this.sendDataProposal.easementDescription = (this.sendDataProposal.useCadastreAgent ? (this.cadastreAgent.useEasementDescription ? this.cadastreAgent.easementDescription : null) : (this.investor.useEasementDescription ? this.investor.easementDescription : null))
      || 'Věcné břemeno - služebnost inženýrské sítě spočívající v právu zřídit, provozovat a udržovat na pozemku inženýrskou síť';
    this.actingPersons = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.actingPersons : this.investor.actingPersons;
    this.sendDataProposal.cadastreActingPerson = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.cadastreActingPerson : this.investor.cadastreActingPerson;
    if (!this.sendDataProposal.cadastreActingPerson && this.actingPersons.length === 1) {
      this.sendDataProposal.cadastreActingPerson = this.actingPersons[0];
    }
    this.changedActingPerson();
    if (this.actingPersons.length && this.actingPersonReload) {
      setTimeout(this.actingPersonReload);
    } else {
      this.actingPersonReload = null;
    }

    this.cadastreNotes = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.cadastreNotes : this.investor.cadastreNotes;
    if (this.cadastreNotes && this.cadastreNotes.length && this.cadastreNotesReload) {
      setTimeout(this.cadastreNotesReload);
    } else {
      this.cadastreNotesReload = null;
    }

    if (this.sendDataProposal.useCadastreAgent && this.cadastreAgent && this.cadastreAgent.useAccountNumberForProposal && this.cadastreAgent.bankCode && this.cadastreAgent.accountNumber) {
      this.accountNumber = this.cadastreAgent.accountNumber + '/' + this.cadastreAgent.bankCode;
    } else if (!this.sendDataProposal.useCadastreAgent && this.investor && this.investor.useAccountNumberForProposal && this.investor.bankCode && this.investor.accountNumber) {
      this.accountNumber = this.investor.accountNumber + '/' + this.investor.bankCode;
    } else {
      this.accountNumber = null;
    }
    this.sendDataProposal.note = this.getContractRegisterNote() || '';
  }

  changedActingPerson() {
    this.sendDataProposal.email = this.sendDataProposal.phoneNumber = null;
    if (this.sendDataProposal.cadastreActingPerson) {
      this.sendDataProposal.email = this.sendDataProposal.cadastreActingPerson.email;
      this.sendDataProposal.phoneNumber = this.sendDataProposal.cadastreActingPerson.phoneNumber;
    }
    this.sendDataProposal.email = this.sendDataProposal.email || (this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.email : this.investor.email);
    this.sendDataProposal.phoneNumber = this.sendDataProposal.phoneNumber || (this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.phoneNumber : this.investor.phoneNumber);
  }

  toggleUseCadastreAgent() {
    this.sendDataProposal.useCadastreAgent = !this.sendDataProposal.useCadastreAgent;
    this.updateCadastreActingPerson();
  }

  hasValidProposalCredentials() {
    return this.sendDataProposal.entryProposalUser && this.sendDataProposal.entryProposalPassword;
  }

  sendProposal() {
    return this.data.customPUT(this.sendDataProposal, 'send-proposal').toPromise().then((data) => {
      this.data.entryProposalLink = data.entryProposalLink;
      this.data.entryProposalId = data.entryProposalId;
      this.data.entryProposalUser = this.sendDataProposal.entryProposalUser;
    });
  }

  deleteProposal() {
    const data = this.restangular.copy(this.data);
    data.entryProposalLink = null;
    data.entryProposalId = null;
    data.entryProposalUser = null;
    return data.put().toPromise().then((data) => {
      this.data.entryProposalLink = data.entryProposalLink;
      this.data.entryProposalId = data.entryProposalId;
      this.data.entryProposalUser = data.entryProposalUser;
    });
  }

  getContractRegisterNote() {
    const person = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent : this.investor;
    if (this.data.contractRegisterPublicationContractId && person.showPublicationNote && person.publicationNoteTemplate) {
      return person.publicationNoteTemplate.replace('[ID]', this.data.contractRegisterPublicationContractId);
    } else if (!this.data.contractRegisterPublicationContractId && person.showNoPublicationNote && person.noPublicationNoteTemplate) {
      return person.noPublicationNoteTemplate.replace('[VYJIMKA]', this.data.contractRegisterPublicationException);
    }
    return null;
  }

  cadastreNoteSelected(newValue, data) {
    this.sendDataProposal.note = newValue.content;
    this.sendDataProposal.note += (this.sendDataProposal.note ? '\n' : '') + (this.getContractRegisterNote() || '');
    data.selected = null;
  }

  registerCadastreNotesReload(reload) {
    this.cadastreNotesReload = reload;
  }

  registerActingPersonReload(reload) {
    this.actingPersonReload = reload;
  }

  isValid() {
    return !!this.data.handedOverInvestorDate;
  }
}
