export enum ApproveEnum {
  NOT_ENSURE = 'NOT_ENSURE',
  APPROVE = 'APPROVE',
  NOT_APPROVE = 'NOT_APPROVE'
}

export const approveOptions = [
  {
    id: ApproveEnum.NOT_ENSURE,
    name: 'Nezajištěno',
    value: 'Nezajištěno',
  },
  {
    id: ApproveEnum.APPROVE,
    name: 'Souhlas',
    value: 'Souhlas'
  },
  {
    id: ApproveEnum.NOT_APPROVE,
    name: 'Nesouhlas',
    value: 'Nesouhlas'
  },
];
