import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ArticleModel } from '@app/common/models/article.model';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogService } from '@app/common/services/dialog.service';
import { ArticleAttachmentComponent } from '@app/common/components/article-attachment/article-attachment.component';
import { ConfAttachmentModel } from '@app/common/models/conf-attachment.model';
import { AuthService } from '@app/common/services/auth.service';

import * as GMTCK from 'gmt-ckeditor/dist';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Component({
  selector: 'article-edit',
  styleUrls: ['./article-edit.component.scss'],
  templateUrl: './article-edit.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ArticleEditComponent implements OnInit {
  article: ArticleModel;
  editor;
  token: string = this.authService.getToken();
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    private elementRef: ElementRef,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private dialogService: DialogService,
    private authService: AuthService,
  ) {
    this.article = (<DialogConfigData>dialogConfig.data).article;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
    await this.prepareEditor();
    this.registerLinkListener();
  }

  isValid() {
    return this.article.title;
  }

  onSave(): Promise<any> {
    this.article.content = this.editor.getData();
    return this.configurationRestangular.all('articles').customPUT(this.article).toPromise().then(article => {
      this.dialogRef.close(article.plain());
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  private async prepareEditor() {
    this.editor = await GMTCK.ClassicEditor.create(document.querySelector('#editor'));
    this.editor.setData(this.article.content);
  }

  onFileUpload() {
    const dialog = this.dialogService.open(ArticleAttachmentComponent, {
      data: { article: this.article }
    });

    const sub = dialog.afterClosed.subscribe((res: { text: string, attachment: ConfAttachmentModel }) => {
      if (res) {
        this.editor.model.change(writer => {
          const link = writer.createText(res.text ? res.text : res.attachment.name, {
            linkHref: './inner-' + res.attachment.id,
          });

          this.editor.model.insertContent(link, this.editor.model.document.selection);
        });
      }
      sub.unsubscribe();
    });
  }

  private registerLinkListener() {
    $('body').on('click', '.ck-link-actions__preview', (event) => {
      event.preventDefault();
      event.stopPropagation();

      const href = $(event.currentTarget).attr('href');

      if (href.indexOf('./inner-') === 0) {
        window.open(`${this.configurationRestangular.configuration.baseUrl}/attachments/${href.replace('./inner-', '')}/data?t=${this.token}`, '_blank');
      } else {
        window.open(href, '_blank');
      }
    });
  }
}
