﻿import { Component, Input, OnInit, Inject } from "@angular/core";
import { StateService, TransitionService } from "@uirouter/angular";
import { TitleDetailCreateCaseSelectParentService } from '@app/ps/services/title-detail-create-case-select-parent.service';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';

@Component({
	templateUrl: './create-case.component.html',
})
export class CreateCaseComponent implements OnInit {
  @Input() constructionObjectId: number;
  public activeStepId: string;
  private steps: any[];
  public finished: boolean = false;
  public data: any;
  public helpId: number;
  private deregisterLeaveListener: Function;
  public loading: boolean= true;
  private onHelpIdChanged: Function;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private transitionService: TransitionService,
    private stateService: StateService,
    private titleDetailCreateCaseSelectParentService: TitleDetailCreateCaseSelectParentService,
    private dialogService: DialogService,
  ) {
    this.hasStep = this.hasStep.bind(this);
    this.onSelectParentOwnership = this.onSelectParentOwnership.bind(this);
  }

  menuSteps: {name: string, ids: string[], enabled: boolean}[] = [
    {name: 'Typ případu', ids: ['select-obligation'], enabled: false},
    {name: 'Geometrický plán', ids: ['select-area'], enabled: false},
    {name: 'Věcné břemeno', ids: ['select-easement'], enabled: false},
    {name: 'Detaily případu', ids: ['select-parent-ownership', 'price'], enabled: false},
    {name: 'Potvrzení', ids: ['confirm'], enabled: false},
    {name: 'Výsledek', ids: ['summary'], enabled: false},
  ];

  // Prevent exit without users will
  beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
    });
  }

  ngOnInit() {
    this.steps = [];
    this.stepChanged('select-obligation');
    this.data = {
      obligation: null,
      createGroups: [],
      cases: null,
    };

    // If any link is clicked
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
  }

  onSelectObligation($event) {
    if ($event === undefined) {
      this.stepChanged('select-area');
    } else if ($event.skipped === true) {
      this.steps.pop();
      this.menuSteps.shift();
      this.loading = false;
      this.stepChanged('select-area');
    } else if ($event.skipped === false) {
      this.loading = false;
    }
  }

  onSelectArea() {
    this.stepChanged('select-easement');
  }

  onSelectEasement() {
    this.stepChanged('select-parent-ownership');
  }

  onSelectParentOwnership(skipped = false) {
    if (skipped) {
      this.steps.pop();
    }
    this.stepChanged('price');
  }

  onPrice() {
    this.stepChanged('confirm');
  }

  onConfirm() {
    this.finished = true;
    this.deregisterLeaveListener();
    this.stepChanged('summary');
  }

  onBack() {
    this.steps.pop();
    this.stepChanged(this.steps.pop());
  }

  onGoToConstructionObject() {
    this.stateService.go("^");
  }

  hasStep(stepId) : boolean {
    return this.steps.includes(stepId);
  }

  onClickStep(menuStep) {
    if (!menuStep.enabled) {
      return;
    }
    while (!menuStep.ids.includes(this.activeStepId) && this.steps.length) {
      this.onBack();
    }
    let index = menuStep.ids.indexOf(this.activeStepId);
    if (index > 0 && this.hasStep(menuStep.ids[index-1])) {
      this.onBack();
    }
  }

  private stepChanged(stepId) {
    this.activeStepId = stepId;
    const menuStep = this.menuSteps.find(menuStep => menuStep.ids.includes(stepId));
    this.steps.push(stepId);
    let helpId = null;
    switch(stepId) {
      case 'select-obligation':
        helpId = null;
        break;
      case 'select-area':
        helpId = this.helpIds.CONSTRUCTIONOBJECT_CREATECASE_SELECT_AREA;
        break;
      case 'select-easement':
        helpId = this.helpIds.CONSTRUCTIONOBJECT_CREATECASE_SELECT_EASEMENT;
        break;
      case 'select-parent-ownership':
        helpId = this.helpIds.CONSTRUCTIONOBJECT_CREATECASE_SELECT_PARENT_OWNERSHIP;
        break;
      case 'price':
        helpId = this.helpIds.CONSTRUCTIONOBJECT_CREATECASE_PRICE;
        break;
      case 'confirm':
        helpId = null;
        break;
      case 'summary':
        helpId = this.helpIds.CONSTRUCTIONOBJECT_CREATECASE_SUMMARY;
        break;
    }
    this.helpId = helpId;
    if (this.onHelpIdChanged) {
      this.onHelpIdChanged(this.helpId);
    }

    this.menuSteps.forEach((step) => {
      step.enabled = (step.ids.includes('summary') && this.activeStepId === 'summary') || (this.activeStepId !== 'summary' && step.ids.some(this.hasStep));
    });
  }

  public onCallbackRegister($event) {
    this.onHelpIdChanged = $event.onHelpIdChanged;
  }
}
