import { Component, Inject, OnInit } from '@angular/core';
import { EasementCreateStepComponent } from '@app/ps/construction-objects/components/easement-create-step/easement-create-step.component';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Component({
  selector: 'easement-create-add-files',
  templateUrl: './easement-create-add-files.component.html',
})
export class EasementCreateAddFilesComponent extends EasementCreateStepComponent implements OnInit {

  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';
  static readonly MSG_FILES_VALIDATION  = 'Vyčkejte, probíhá validace.';
  static readonly MSG_FILES_VALID  = 'Soubor je validní.';
  static readonly MSG_FILES_NOT_VALID  = 'Soubor není validní.';

  static readonly MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
  static readonly MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';

  static readonly MSG_GP_CSV_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor CSV.';
  static readonly MSG_GP_CSV_FILES_EXTENSION = 'Soubor musí být ve formátu CSV.';

  // model
  vfkFiles: UploadFileExtended[] = [];
  csvFiles: UploadFileExtended[] = [];
  vfkMessage = EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_DEFAULT;
  csvMessage = EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_DEFAULT;
  csvErrors: string[] = [];
  vfkErrors: string[] = [];
  vfkValid = false;
  csvValid = false;

  // aux
  restBaseUrl = this.authService.getActiveApplicationRestUrl();

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
  ) {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.uploadVfk = this.uploadVfk.bind(this);
    this.uploadCsv = this.uploadCsv.bind(this);
  }

  ngOnInit() {
  }

  onChangeFile(files: UploadFileExtended[], type: 'csv' | 'vfk') {
    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === type);

    this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_VALIDATION;
    this[type + 'Files'] = files;
    this[type + 'Valid'] = false;
    this[type + 'Errors'] = [];


    if (files.length === 0) {
      this[type + 'Message'] = type === 'vfk'
        ? EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_DEFAULT
        : EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_DEFAULT;
      return;
    }

    if (!extensionValid) {
      this[type + 'Message'] = type === 'vfk'
        ? EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_EXTENSION
        : EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_EXTENSION;
      return;
    }

    if (files.length > 1) {
      this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_QUANTITY;
      return;
    }

    this.validate(this.vfkFiles[0], 'vfk')
      .then(data => {
        this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_VALID;
        this[type + 'Valid'] = true;
      })
      .catch(data => {
        this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_NOT_VALID;
        this[type + 'Errors'] = data.data.detailMsgs ? data.data.detailMsgs : [];
      });
  }

  isFormValid() {
    return !!(
      this.vfkFiles.length === 1
      && this.vfkValid
      && (this.csvFiles.length === 0 || (this.csvFiles.length === 1 && this.csvValid))
    );
  }

  onSubmit(): Promise<any> {
    return this.uploadVfk()
      .then(data => {
        this.data.vfkId = data.vfkId;

        return (this.csvFiles.length !== 0
          ? this.uploadCsv(data.vfkId)
          : true
        );
      })
      .then(() => {
        this.submitCallback.emit();
      });
  }

  private validate(file: UploadFileExtended, type: string): Promise<any> {
    const typeUrl = type === 'csv' ? '/csv/' : '/';

    return this.uploadService.upload({
      url: this.restBaseUrl + '/vfks' + typeUrl + 'validate',
      data: {
        filename: file.fileEntry.name,
        file: file.fileEntry
      },
      headers: { Authorization: this.authService.getToken() }
    }).progress(event => {
      file.progress = (100.0 * event.loaded / event.total);
    });
  }

  private uploadVfk(): Promise<any> {
    const file = this.vfkFiles[0].fileEntry;

    return this.uploadService.upload({
      url: this.restBaseUrl + '/vfks/create',
      data: {
        filename: file.name,
        file: file
      },
      headers: { Authorization: this.authService.getToken() }
    });
  }

  private uploadCsv(vfkId: number): Promise<any> {
    const file = this.csvFiles[0].fileEntry;

    return this.uploadService.upload({
      url: this.restBaseUrl + '/vfks/csv/upload?vfkId=' + vfkId,
      data: {
        filename: file.name,
        file: file,
        vfkId: vfkId
      },
      headers: { Authorization: this.authService.getToken() }
    });
  }
}
