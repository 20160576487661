<div class="jumbotron" *ngIf="countResolved!==data.selectEntities.count">
	<div *ngIf="list.loading" class="centered-text">Načítá se seznam případů.</div>
	<div *ngIf="!list.loading" class="centered-text">Probíhá vyhodnocení dostupných dokumentů a oprávnění.</div>
	<div *ngIf="!list.loading" class="centered-text"><b>{{countResolved}}/{{data.selectEntities.count}}</b></div>
	<div [loading]="true"></div>
</div>
<div *ngIf="countResolved === data.selectEntities.count">
	<fieldset *ngIf="inputData.noStatusGroup.length">
		<legend>Případy ve stavu bez dostupných dokumentů</legend>
		<div class="column-100">
			<label>Případy</label>
			<div class="info comma-list"><span *ngFor="let case of inputData.noStatusGroup | slice:0:10"><a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{case.mpp.number||'bez čísla'}}</a></span><span *ngIf="inputData.noStatusGroup.length > 10">dalších {{inputData.noStatusGroup.length - 10}} případů</span></div>
		</div>
	</fieldset>

	<fieldset *ngIf="inputData.groups.length">
		<legend>Dostupné dokumenty ke generování</legend>
		<div class="column-100">
			<label class="required">Dokument</label>
			<div class="input">
				<div class="checklist">
					<div class="checklist-item" *ngFor="let group of inputData.groups" (click)="setActiveDocument(group)">
						<div>
							<gmt-radio [gmtChecked]="inputData.group && inputData.group.document.key === group.document.key"></gmt-radio> {{group.document.name}} ({{group.cases.length}})
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>

	<fieldset *ngIf="inputData.group">
		<legend>Detaily dokumentu</legend>
		<div class="column-100">
			<label>Případy</label>
			<div class="info comma-list" *ngIf="inputData.group.cases.length"><span *ngFor="let case of inputData.group.cases | slice:0:10"><a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{case.mpp.number||'bez čísla'}}</a></span><span *ngIf="inputData.group.cases.length > 10">dalších {{inputData.group.cases.length - 10}} případů</span></div>
			<div class="info" *ngIf="!inputData.group.cases.length">žádné případy</div>
		</div>
	</fieldset>
</div>

<div *ngIf="inputData.group && inputData.group.cases.length">
	<fieldset class="no-legend">
		<div [loading]="true" *ngIf="loadingGroup"></div>
		<div class="flexbox-container" style="flex-direction:column;" *ngIf="!loadingGroup">
			<div class="column-100">
				<label [ngClass]="{required : templates.length !== 1}">{{ useTemplateService ? 'Vzor' : 'Šablona'}}</label>
				<div class="input" *ngIf="templates.length !== 1">
					<gmt-select [optionItems]="templates" [required]="true" [data]="inputData.group" field="template"></gmt-select>
				</div>
				<div class="info" *ngIf="templates.length === 1">
					 {{ inputData.group.template.name }}
				</div>
			</div>
			<div class="column-100 flexbox-container">
				<label style="flex: 0 0 auto;" class="required">Formát názvu generovaných dokumentů</label>
				<div class="input flex-rest">
					<div class="column-100" *ngFor="let section of inputData.filename">
						<div *ngIf="section.type === 'text'">
							<div class="label">
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, -1)"><i class="fa fa-arrow-up"></i></a>
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, 1)"><i class="fa fa-arrow-down"></i></a>
								<div (click)="toggleSection(section, inputData.filename)" class="fl mr-10">
									<gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Text
								</div>
							</div>
							<div class="input">
								<input type="text" [(ngModel)]="section.text"/>
							</div>
						</div>
						<div *ngIf="section.type === 'caseNumber'">
							<div class="label">
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, -1)"><i class="fa fa-arrow-up"></i></a>
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, 1)"><i class="fa fa-arrow-down"></i></a>
								<div (click)="toggleSection(section)" class="fl mr-10">
									<gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Číslo případu
								</div>
							</div>
						</div>
						<div *ngIf="section.type === 'titleNumber'">
							<div class="label">
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, -1)"><i class="fa fa-arrow-up"></i></a>
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, 1)"><i class="fa fa-arrow-down"></i></a>
								<div (click)="toggleSection(section)" class="fl mr-10">
									<gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Číslo LV
								</div>
							</div>
						</div>
						<div *ngIf="section.type === 'owner'">
							<div class="label">
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, -1)"><i class="fa fa-arrow-up"></i></a>
								<a class="fl mr-10" (click)="moveSection(section, inputData.filename, 1)"><i class="fa fa-arrow-down"></i></a>
								<div (click)="toggleSection(section)" class="fl mr-10">
									<gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Jméno vlastníka
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="column-100">
				<label>Náhled výsledného názvu souboru</label><div class="info">{{generateFilename()}}</div>
			</div>
		</div>
	</fieldset>
</div>
