import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { TooltipIntersectionAreaComponent } from '@app/dimap/components/tooltip-intersection-area/tooltip-intersection-area.component';
import {
  CellAbstractComponent,
  CellTooltipMappingType
} from '@app/common/components/cell-abstract/cell-abstract.component';
import { MapPropertyStatusService } from '@app/dimap/map/services/map-property-status.service';

@Component({
  selector: 'cell-area',
  templateUrl: './cell-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellAreaComponent extends CellAbstractComponent implements OnInit {

  // bindings
  @Input() item: any;
  propertyStatusClass;

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'intersection-area': TooltipIntersectionAreaComponent
  };

  constructor(
    private propertyStatusService: MapPropertyStatusService
  ) {
    super();
  }

  ngOnInit(): void {
    this.propertyStatusClass = this.propertyStatusService.getPropertyStatusWanted(this.item.ownerType, this.item.divisionType, this.item.wanted);
  }
}
