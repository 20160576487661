import { Inject, Injectable } from '@angular/core';
import { APP_BRAND } from '@app/common/services/config.service';

@Injectable({providedIn: 'root'})
export class BrandService {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any
  ) {
  }

  isVisible(id: string) {
    return this.APP_BRAND.VISIBILITY[id] && this.APP_BRAND.VISIBILITY[id] === true;
  }
}
