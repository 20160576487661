<h1>Založení nového projektu</h1>

<div>
  <fieldset>
    <div class="column-100">
      <label class="required" style="width: 250px">Název projektu</label>
      <div class="input">
        <input type="text" [(ngModel)]="data.project.name"/>
      </div>
    </div>
    <div class="column-100">
      <label class="required" style="width: 250px">Identifikátor projektu (bez prefixu di_)</label>
      <div class="input">
        <input type="text" [(ngModel)]="data.project.key" (ngModelChange)="removePrefix()"/>
      </div>
    </div>
  </fieldset>

  <div class="actions">
    <gmt-button [clickAction]="update" [ngClass]="{ disabled: disabled() }">Uložit</gmt-button>
  </div>
</div>
