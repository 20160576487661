import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SvAppModule } from '@app/sv/sv.modules';
import { DocumentTypeCategoryEnum, documentTypeCategoryOptions } from '@app/sv/enums/document-type-category.enum';


@Pipe({ name: 'documentTypeCategoryName' })
@Injectable({
  providedIn: 'root'
})
export class DocumentTypeCategoryNamePipe implements PipeTransform {
  transform(value: DocumentTypeCategoryEnum): string {
    const option = documentTypeCategoryOptions.find(option => option.id === value);
    return option.name;
  }
}
