import { OpinionModel } from '@app/ps/models/opinion.model';
import { OpinionRequestModel } from '../../models/opinion-request.model';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cell-opinions',
  templateUrl: './cell-opinions.component.html',
  styleUrls: ['./cell-opinions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellOpinions {
  @Input() opinionRequests: OpinionRequestModel[];
  @Input() opinions: OpinionModel[];

  shouldBeOpinionDisplayed(opinion: OpinionModel) {
    // keep only opinions without opinions request relation
    if (this.opinionRequests && this.opinionRequests.length && opinion.opinionRequest) {
      return !this.opinionRequests.find(or => opinion.opinionRequest.id === or.id);
    }
    return true;
  }
}
