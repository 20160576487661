<h1 class="centered-text">Zveřejnění smlouvy v registru smluv</h1>

<form-contracts-register
  *ngIf="publishFormVisible || doNotPublishFormVisible"
  [data]="caseObject"
  [isPublished]="publishFormVisible"
  [required]="true"
  (exceptionSelected)="onExceptionSelected($event)"
></form-contracts-register>

<div *ngIf="!publishFormVisible && !doNotPublishFormVisible" class="actions">
  <gmt-button class="red" [clickAction]="onRenderOrSubmitDoNotPublishForm">Bez zveřejnění</gmt-button>
  <gmt-button [clickAction]="onRenderPublishForm">Zveřejněno</gmt-button>
</div>

<div *ngIf="publishFormVisible" class="actions">
  <gmt-button class="red" [clickAction]="onCancel">Zpět</gmt-button>
  <gmt-button [gmtDisabled]="!isValidForPublishing()" [clickAction]="onSend">Zveřejněno</gmt-button>
</div>

<div *ngIf="doNotPublishFormVisible" class="actions">
  <gmt-button class="red" [clickAction]="onCancel">Zpět</gmt-button>
  <gmt-button [gmtDisabled]="!isValidForNotPublishing()" [clickAction]="onSend">Bez zveřejnění</gmt-button>
</div>
