<h1 class="centered-text">Editace osoby</h1>
<div [loading]="loading">
  <div *ngIf="!loading">
    <basic-info-form
      [basicInfo]="basicInfo"
      [editable]="false"
      [editableToggle]="true"
    ></basic-info-form>

    <address-form
      [collapsed]="subjectDetail.customAddressUse"
      [address]="address"
      toggleText="Jiná adresa než v datech KN"
      (toggle)="onToggleAddress($event)"
      [editable]="false"
      [editableToggle]="true"
    ></address-form>

    <address-form
      [collapsed]="caseSubject.dorucovaciUse"
      [address]="mailingAddress"
      heading="Doručovací adresa"
      toggleText="Jiná doručovací adresa"
      (toggle)="onToggleMailingAddress($event)"
      [editable]="false"
      [editableToggle]="true"
    ></address-form>

    <contact-form [contactInfo]="caseSubject" [editable]="false" [editableToggle]="true"></contact-form>

    <!-- contact person -->
    <representative-form
      [compact]="true"
      [representative]="contactPerson"
      [allowRoleAssignment]="false"
      heading="Kontaktní osoba"
      [editable]="false"
      [editableToggle]="true"
    ></representative-form>

    <representative-form
      *ngFor="let r of caseSubject.actingPersons; last as isLast"
      heading="Zastupující / Jednající"
      [compact]="true"
      [representative]="r"
      [allowRoleAssignment]="true"
      (toggleRoleCadastre)="removeRoleCadastreForAllExcept($event)"
      [ngClass]="{'mb-60' : !isLast}"
    >
      <!-- delete button -->
      <div style="margin-top: -15px;" class="column-100" slot="deleteButton">
        <gmt-button class="fl red mr-5 mt-10" *ngIf="shouldShowDeleteButton()" [clickAction]="onRemoveRepresentative(r)">
          <i class="fa fa-minus"></i>
        </gmt-button>
      </div>

      <! -- add button -->
      <gmt-button class="ml-20" *ngIf="isLast" [clickAction]="onCreateAndAddRepresentative" slot="addButton">
        <i class="fa fa-plus"></i>
      </gmt-button>
    </representative-form>

    <payment-form *ngIf="shouldShowPaymentForm" [paymentOptions]="caseSubject" [withPaymentDetails]="true"></payment-form>

    <form-case-subject-dates *ngIf="hasSubjectFieldsToDisplay" [visibleFields]="subjectFields" [dates]="caseSubject"></form-case-subject-dates>

    <fieldset *ngIf="hasOwnershipFieldsToDisplay">
      <!-- cena VB za podil -->
      <div class="column-50" *ngIf="ownershipFields.easementPriceByShare">
        <gmt-input label="Cena VB za podíl">
          <input type="text" [(ngModel)]="caseOwnership.easementPriceByShare" />
        </gmt-input>
      </div>

      <!-- cislo SOSB -->
      <div class="column-50" *ngIf="ownershipFields.easementFutureContractNumber">
        <gmt-input label="Číslo SOSB">
          <input type="text" [(ngModel)]="caseOwnership.easementFutureContractNumber" />
        </gmt-input>
      </div>

      <!-- stav sosb -->
      <div class="column-50" *ngIf="ownershipFields.easementFutureContractStatus">
        <gmt-input label="Stav SOSB">
          <input type="text" [(ngModel)]="caseOwnership.easementFutureContractStatus" />
        </gmt-input>
      </div>
    </fieldset>

    <div class="actions">
      <gmt-button [gmtDisabled]="!hasValidForm()" [clickAction]="onSubmit">Uložit</gmt-button>
    </div>
  </div>
</div>
