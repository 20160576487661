<div class="statistic-label" (click)="onDisplayChange()">{{ exportCfg.name }}</div>
<div class="statistic-detail" *ngIf="displayed">
  <p class="mb-20">{{ exportCfg.info }}</p>
  <p class="mb-20" *ngIf="!exportCfg.userParams">Export nemá žádné dodatečné volby</p>
  <div *ngIf="exportCfg.userParams">
    <ng-container *ngFor="let param of exportCfg.userParams">
      <div class="mb-20" *ngIf="isVisible(param)">
        <h2 *ngIf="param.title && param.type !== 'combo' && param.display !== 'small'" [ngClass]="{ required: param.required }">{{ param.title }}</h2>
        <h3 *ngIf="param.title && param.type !== 'combo' && param.display === 'small'" class="mb-10" [ngClass]="{ required: param.required }">{{ param.title }}</h3>

        <ul *ngIf="param.type !== 'combo'" class="export-params">
          <ng-container *ngIf="param.type !== 'radio'">
            <li *ngFor="let option of param.options">
              <label (click)="$event.stopPropagation();$event.preventDefault();param.checklist.toggleSelection(option)">
                <gmt-checkbox gmtType="multiselect" [gmtChecked]="param.checklist.isChecked(option)"></gmt-checkbox>
                {{ option.name }}
              </label>
            </li>
          </ng-container>
          <ng-container *ngIf="param.type === 'radio'">
            <li *ngFor="let option of param.options">
              <label (click)="$event.stopPropagation(); $event.preventDefault(); param.checklist.empty(); param.checklist.toggleSelection(option)">
                <gmt-radio *ngIf="param.type === 'radio'" [gmtChecked]="param.checklist.isChecked(option)"></gmt-radio>
                {{ option.name }}
              </label>
            </li>
          </ng-container>
        </ul>
        <div *ngIf="param.type === 'combo'">
          <gmt-select
            [ngClass]="{ required: param.required }"
            [data]="param.selectedObject"
            field="selected"
            [multiselect]="param.comboOptions.allowMultipleSelection"
            [itemPipe]="getPipe(param.comboOptions.fieldName)"
            [resource]="param.comboOptions.dataUrlFragment"
            [selectTitle]="param.comboOptions.title"
            [filter]="param.comboOptions.filterOptions"
            [optionItems]="param.comboOptions.optionItems"
          ></gmt-select>
        </div>
      </div>
    </ng-container>
  </div>
  <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onCreateExport">Generovat</gmt-button>
  <span *ngIf="showNotification">
    {{notification}}
  </span>
</div>
