<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^"  class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Seznam uživatelů</a>
      </div>
      <div class="actions">
        <a (click)="onEdit()" style="text-decoration: underline"><i class="fa fa-pencil"></i>Editace uživatele</a>
        <a uiSref=".updateUserPermissions" *ngIf="editor.superuser || APP_BRAND.NAME !== 'RSD'"><i class="fa fa-pencil"></i>Editace práv uživatele</a>
        <a *ngIf="module === 'SY'" uiSref="symap.project.projectSettings.users.detail.email"><i class="fa fa-envelope"></i>Email uživateli</a>
      </div>
    </div>

    <h1>Uživatel</h1>

    <fieldset class="bordered">
      <div class="column-50">
        <label>Titul před jménem</label>
        <div class="info">{{ user.titleBefore }}</div>
        <label>Příjmení</label>
        <div class="info">{{ user.surname }}</div>
        <label>E-mail</label>
        <div class="info">{{ user.email }}</div>
        <label>Telefon</label>
        <div class="info">{{ user.phone }}</div>
        <label>Název firmy</label>
        <div class="info">{{ user.company | customerName }}</div>
      </div>

      <div class="column-50">
        <label>Křestní jméno</label>
        <div class="info">{{ user.name }}</div>
        <label>Titul za jménem</label>
        <div class="info">{{ user.degreeAfter }}</div>
        <label>Uživatelské jméno</label>
        <div class="info">{{ user.username }}</div>
        <ng-container *ngIf="user.superuser">
          <label>Developer</label>
          <div class="info">{{ user.superuser ? 'Ano' : 'Ne' }}</div>
        </ng-container>
      </div>
    </fieldset>

    <div *ngIf="module === 'SY'" class="flexbox-container">
      <div class="column-100">
        <fieldset class="bordered">
          <legend>Role v projektu {{ project.name }}</legend>
          <div [ngClass]="{'empty-info': !userRole }">{{ userRole ? userRole.roleDescription : 'Žádná role' }}</div>
        </fieldset>
      </div>
    </div>

    <div class="flexbox-container">
      <div visibleByBrand="maja" class="column-50">
        <fieldset class="bordered">
          <legend>Oprávnění pro projekt {{ project.name }}</legend>
          <div class="empty-info" *ngIf="!userPermissions[project.key] || userPermissions[project.key]?.length === 0">Žádná oprávnění</div>
          <ng-container class="authorization-item" *ngIf="userPermissions[project.key]?.length !== 0">
            <div *ngFor="let authorization of userPermissions[project.key]" class="authorization-item">
              {{ getPermissionName(authorization.permission) }}
            </div>
          </ng-container>
        </fieldset>
      </div>
      <div [ngClass]="{'column-50' : APP_BRAND.NAME !== 'RSD', 'column-100' : APP_BRAND.NAME === 'RSD'}">
        <fieldset class="ml-10 bordered">
          <legend>Projekty s oprávněním</legend>
          <div class="empty-info" *ngIf="user.applicationProjects[module].length === 0">Žádné projekty</div>
          <div *ngIf="user.applicationProjects[module].length !== 0">
            <div class="authorization-item" *ngFor="let project of user.applicationProjects[module]">
              {{ getProjectName(project) }}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>
