import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'percentage'
})
@Injectable({
  providedIn: 'root'
})
export class PercentagePipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe,
  ) {
  }

  transform(value: any, total: any): string {
    const val = parseFloat(value);
    const tot = parseFloat(total);

    if (isNaN(val) || isNaN(tot) || tot === 0 || val === 0) {
      return '0%';
    }

    const percentage = val / tot * 100;

    if (percentage > 0 && percentage < 1) {
      return this.decimalPipe.transform(percentage, '1.2-2') + '%';
    }

    if (percentage >= 1 || percentage === 0) {
      return this.decimalPipe.transform(percentage, '1.0-0') + '%';
    }
  }
}
