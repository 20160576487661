import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { CallbackModel } from '@app/common/models/callback.model';
import { CommonAddressModel } from '@app/ps/models/common-address.model';
import { SubjectBasicInfoModel } from '@app/ps/models/subject-basic-info.model';
import { SubjectDetailModel } from '@app/ps/models/subject-detail.model';
import { SubjectModel } from '@app/common/models/subject.model';
import { SubjectService } from '@app/ps/services/subject.service';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'tab-owner-about',
  templateUrl: './about.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent implements OnInit {
  @Input() owner: SubjectModel;

  address: CommonAddressModel;
  contactPerson: Partial<ActingPersonModel>;
  basicInfo: SubjectBasicInfoModel;
  mailingAddress: CommonAddressModel;
  actualizeAll = false;
  caseSubject: CaseSubjectModel;

  constructor(
    private restangular: Restangular,
    @Inject('callbacks') private callbacks: CallbackModel,
    private subjectService: SubjectService,
  ) { }

  ngOnInit() {
    this.address = this.normalizeAddressFromSubject();
    this.mailingAddress = this.normalizeMailingAddressFromSubject();
    this.contactPerson = this.normalizeContactPerson();
    this.basicInfo = this.normalizeBasicInfo();

    if (!this.owner.subjectDetail) {
      this.owner.subjectDetail = <SubjectDetailModel>{};
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onToggleMailingAddress(addressUsed: boolean) {
    this.owner.subjectDetail.dorucovaciUse = addressUsed;
  }

  onToggleAddress(addressUsed: boolean) {
    this.owner.subjectDetail.customAddressUse = addressUsed;

    if (!CommonAddressModel.isAddressFiled(this.address)) {
      this.address.street = this.owner.ulice;
      this.address.houseNumber = this.owner.cisloDomovni;
      this.address.commonNumber = this.owner.cisloOrientacni;
      this.address.neighborhood = this.owner.castObce;
      this.address.cityDistrict = this.owner.mestskyObvod;
      this.address.city = this.owner.obec;
      this.address.county = this.owner.okres;
      this.address.postalCode = this.owner.psc;
      this.address.quarter = this.owner.mestskaCast;
      this.address.country = this.owner.stat;
    }
  }

  async onSubmit() {
    this.assembleOwner();
    const updatedSubject = await this.restangular.one(`subjects/${this.owner.id}/detail`).customPUT(this.owner.subjectDetail).toPromise();

    if (this.actualizeAll) {
      await this.restangular.one(`subjects/${this.owner.id}/update-cases-subjects-by-subject-detail`).post().toPromise();
    }
    const plainUpdatedSubject = updatedSubject.plain();
    this.callbacks.get(Callbacks.SubjectUpdated)(plainUpdatedSubject);
  }

  private normalizeBasicInfo(): SubjectBasicInfoModel {
    return this.subjectService.getAdaptedBasicInfo(this.owner);
  }

  private denormalizeBasicInfo(): Partial<SubjectDetailModel> {
    return this.subjectService.getSubjectCompatibleBasicInfo(this.basicInfo);
  }

  private normalizeContactPerson(): Partial<ActingPersonModel> {
    return this.subjectService.getAdaptedContactPerson(this.owner.subjectDetail);
  }

  private denormalizeContactPerson(): any {
    return this.subjectService.getSubjectCompatibleContactPerson(this.contactPerson);
  }

  private normalizeAddressFromSubject(): CommonAddressModel {
    return this.subjectService.getAdaptedAddress(this.owner.subjectDetail);
  }

  private normalizeMailingAddressFromSubject(): CommonAddressModel {
    return this.subjectService.getAdaptedMailingAddress(this.owner.subjectDetail);
  }

  private denormalizeMailingAddress() {
    return this.subjectService.getSubjectCompatibleMailingAddress(this.mailingAddress);
  }

  private denormalizeSubjectAddress() {
    return this.subjectService.getSubjectCompatibleAddress(this.address);
  }

  private assembleOwner() {
    Object.assign(this.owner.subjectDetail, this.denormalizeMailingAddress());
    Object.assign(this.owner.subjectDetail, this.denormalizeSubjectAddress());
    Object.assign(this.owner.subjectDetail, this.denormalizeContactPerson());
    Object.assign(this.owner.subjectDetail, this.denormalizeBasicInfo());

    if (!this.owner.subjectDetail.customAddressUse) {
      this.owner.subjectDetail.customAddressUse = false;
    }

    if (this.owner.subjectDetail.identificationNumber === '') {
      this.owner.subjectDetail.identificationNumber = undefined;
    }
  }
}
