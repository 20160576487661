import { Inject, Injectable } from '@angular/core';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class OpinionService {

  constructor(
    private restangular: Restangular,
  ) { }

  getOne(opinionId: number): Promise<any> {
    return this.restangular
      .one('opinions', opinionId)
      .get()
      .toPromise()
      .then(response => response.plain());
  }

  createOne(opinion: OpinionModel): Promise<any> {
    return this.restangular
      .all('opinions')
      .all('create')
      .post(opinion)
      .toPromise();
  }

  updateOne(opinionId: number, opinion: OpinionModel): Promise<any> {
    return this.restangular
      .one('opinions', opinionId)
      .customPUT(opinion)
      .toPromise();
  }

  deleteOne(opinionId: number): Promise<any> {
    return this.restangular
      .one('opinions', opinionId)
      .remove()
      .toPromise();
  }

  getOpinionsForTitle(titleId: number): Promise<any> {
    return this.restangular
      .all('opinions')
      .customPOST({
        filter: {
          filters: {
            titleId,
            validity: ['valid'],
          },
          sortOrder: [
            {
              sortBy: 'id',
            },
          ],
        },
      })
      .toPromise()
      .then(response => response.plain());
  }

  getParcels(filter: any): Promise<any> {
    return this.restangular
      .all('parcels')
      .customPOST({ filter })
      .toPromise()
      .then(response => response.plain());
  }

  getBuildings(filter: any): Promise<any> {
    return this.restangular
      .all('buildings')
      .customPOST({ filter })
      .toPromise()
      .then(response => response.plain());
  }

  getParcelPrices(opinionId: number): Promise<any> {
    return this.restangular
      .all('parcel-prices')
      .customPOST({
        filter: {
          filters: {
            opinionId: [opinionId],
            loadCollections: ['building.parcels'],
          },
        },
      })
      .toPromise()
      .then(response => response.plain());
  }

  getTitleParcelPrices(titleId: number): Promise<any> {
    return this.restangular
      .all('parcel-prices')
      .customPOST({
        filter: {
          filters: {
            titleId: [titleId],
            validity: ['valid'],
          },
        },
      })
      .toPromise()
      .then(response => response.plain());
  }
}
