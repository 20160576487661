<div>
    <h1 class="text-center">{{contract.id ?  'Editace smlouvy' : 'Nová smlouva'}}</h1>
    <fieldset>
        <div class="column-50">
            <!-- cislo smlouvy -->
            <label class="required">Číslo smlouvy</label>
            <div class="input"><input type="text" [(ngModel)]="contract.contractNumber"/></div>

            <!-- datum uzavreni smlouvy -->
            <label class="required">Datum uzavření smlouvy</label>
            <div class="input"><input pick-a-date type="text" [(ngModel)]="contract.signDate"/></div>

            <!-- predmet smlouvy -->
            <label class="required">Předmět smlouvy</label>
            <div class="input"><input type="text" [(ngModel)]="contract.subject"/></div>
        </div>
        <div class="column-50">
            <!-- poznamka -->
            <label>Poznámka</label>
            <div class="input">
                <textarea style="width:232px" rows="120" [(ngModel)]="contract.description"></textarea>
            </div>
        </div>

        <div class="column-100 mt-20">
            <fileupload
                [message]="fileUploadMessage"
                [validation]="isFileUploadValid"
                (change)="onFileAdded($event)">
            </fileupload>
        </div>
    </fieldset>
    <div class="text-center">
        <gmt-button [clickAction]="save" [gmtDisabled]="isComplete()">Uložit</gmt-button>
    </div>
</div>

