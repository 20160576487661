import * as _ from 'lodash';

import { ColumnDefinition } from '../models/column-definition.model';

export class TableUtils {

  static repairColumnsStructure(columns: (ColumnDefinition | string)[]): ColumnDefinition[] {
    return columns.map(column => {
      return (typeof column === 'string' ? ColumnDefinition.createFromString(column) : column);
    });
  }

  static getAvailableColumns(columns: ColumnDefinition[], avaliableColumns: string[]) {
    return columns.filter(column => avaliableColumns.includes(column.id));
  }

  static sortDataByColumnStatic<T>(data: T[], dataPath, sortDir): T[] {
    return _.orderBy(data, dataPath, sortDir);
  }

  static getColumnsCount(columns: ColumnDefinition[], searchString: string) {
    return columns.filter(c => c.id.indexOf(searchString) === 0).length;
  }
}
