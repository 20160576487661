<h1>Legenda</h1>

<div>
  <div class="column-100 checklist">
    <div class="checklist-item with-action cb clearfix" *ngFor="let layer of layers">
      <div class="info mr-10">
        <gmt-checkbox style="height: 15px;display: block;"
          gmtType="multiselect"
          [gmtChecked]="layer.visible"
          (click)="layer.visible = !layer.visible"
        ></gmt-checkbox>
      </div>

      <div class="info mr-10" *ngIf="layer.icon || layer.style">
        <span *ngIf="layer.icon" class="icon">
          <img [src]="layer.icon"/>
        </span>

        <span *ngIf="layer.style" class="icon">
          <layer-symbol [interactive]="false" [style]="layer.style"></layer-symbol>
        </span>
      </div>

      <div class="info fn" [ngClass]="{info: layer.id !== currentlyEditedLayer?.id, input: layer.id === currentlyEditedLayer?.id}">
        <div *ngIf="layer.id !== currentlyEditedLayer?.id; else interactiveInputBlock" (click)="onLayerNameEdit(layer)">{{ layer.title ? layer.title : layer.defaultTitle }}
          <span *ngIf="layer.defaultTitle && layer.title !== '' && layer.title !== layer.defaultTitle"> ({{layer.defaultTitle}})</span>
        </div>
      </div>

      <ng-template #interactiveInputBlock>
        <input gmtAutofocus type="text" class="w-350" [(ngModel)]="layer.title" (blur)="onLayerNameBlur(layer)"/>
      </ng-template>

    </div>
  </div>

  <div class="actions">
    <gmt-button [clickAction]="onSave">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</div>
