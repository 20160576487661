import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver } from "@angular/core";

@Component({
  templateUrl: './bulk-operation-finish.component.html',
})
export class BulkOperationFinishComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  newOperation: Function;
  @Input()
  goToList: Function;
  @ViewChild('operationFinish', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef;
  finishComponent: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    if (this.data.selectAction && this.data.selectAction.finishComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.data.selectAction.finishComponent);
      this.finishComponent = factory.create(this.viewContainerRef.parentInjector);
      this.viewContainerRef.insert(this.finishComponent.hostView);

      this.finishComponent.instance.data = this.data;
      this.finishComponent.instance.newOperation = this.newOperation;
      this.finishComponent.instance.goToList = this.goToList;
    }
  }
}
