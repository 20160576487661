import { Component, Input } from '@angular/core';

import { ConstructionObjectModel } from '@app/common/models/construction-object.model';

export interface TooltipConstructionObjectsData {
  constructionObjects: ConstructionObjectModel[];
}

@Component({
  selector: 'tooltip-construction-objects',
  templateUrl: './tooltip-construction-objects.component.html',
  styleUrls: ['./tooltip-construction-objects.component.scss']
})
export class TooltipConstructionObjectsComponent {
  @Input() data: TooltipConstructionObjectsData;
}
