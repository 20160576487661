<h1 class="text-center">Označení problematického vlastníka</h1>
<fieldset>
  <div class="column-100" [ngSwitch]="owner.opsubType">
    <!-- osoba/spolecnost -->
    <label *ngSwitchCase="'OFO'">Osoba</label>
    <label *ngSwitchCase="'OPO'">Společnost</label>
    <div class="input">{{ owner | subjectName }}</div>

    <!-- datum zaznamu -->
    <label class="required">Datum záznamu</label>
    <div class="input"><input pick-a-date type="text" [(ngModel)]="payload.timestamp"/></div>
  </div>
</fieldset>

<fieldset>
  <div class="column-100">
    <!-- poznamka -->
    <label class="required">Poznámka</label>
    <div>
      <textarea rows="120" [(ngModel)]="payload.text"></textarea>
    </div>
  </div>
</fieldset>
<div class="actions">
  <gmt-button [gmtDisabled]="!isValid()" [clickAction]="submit">Uložit</gmt-button>
  <gmt-button *ngIf="owner.subjectDetail?.problematic" [clickAction]="remove" class="red">Smazat</gmt-button>
</div>
