import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Injectable({ providedIn: 'root' })
export class ProjectService {

  constructor(
    private stateService: StateService
  ) {}

  changeProject(project) {
    this.stateService.go('symap.project.home', { projectKey: project.key }, { inherit : false });
  }
}
