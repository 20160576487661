import { ClassName } from '../enums/class-name.enum';
import { CallbackModel } from '@app/common/models/callback.model';

type Data = DialogConfigData | ConfirmationConfigData;

export interface DialogConfigData {
  [key: string]: any;
}

export interface ConfirmationConfigData {
  msg: string;
  isHtml?: boolean;
}

export class DialogConfig {
  data?: Data;
  className?: ClassName;
  callbacks?: CallbackModel;
}
