<div>
  <div class="jumbotron text-center">
    Nahráno {{ fail + success }} z <strong>{{ data.occupations.length }}</strong>.
    <div *ngIf="fail + success === data.occupations.length" class="text-center">
      Z toho {{ success }} úspěšně.
    </div>
  </div>

  <div class="mt-10">
    <gmt-button [clickAction]="onSubmit" [gmtDisabled]="(fail + success !== data.occupations.length)">Hotovo</gmt-button>
  </div>
</div>
