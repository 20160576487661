import { Injectable } from '@angular/core';
import * as XLSX from 'sheetjs-style';

export interface ColumnOptions {
  width?: {
    type: 'wpx' | 'wch' | 'auto',
    value?: number,
  };
}

export interface CellOptions {
  style?: {
    fill?: any,
    font?: any,
    numFmt?: any,
    alignment?: any,
    border?: any,
  };
}

export const DEFAULT_CELL_OPTIONS = { style: { alignment: { vertical: 'top' }}};
export const DEFAULT_CELL_OPTIONS_WRAP = { style: { alignment: { vertical: 'top', wrapText: true }}};
export const DEFAULT_CELL_OPTIONS_H_CENTER = { style: { alignment: { vertical: 'top', horizontal: 'center' }}};

@Injectable({ providedIn: 'root' })
export class XlsxService {

  static readonly HEADER_CELL_OPTIONS: CellOptions = {
    style: {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'ffeeeeee' },
      },
      font: {
        bold: true,
      }
    }
  };

  createFile(
    data: any[][],
    name: string,
    columnOptions: { [key: number]: ColumnOptions } = {},
    bodyCellOptions: { [key: number]: CellOptions } = {},
  ) {
    // init worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const range = XLSX.utils.decode_range(ws['!ref']);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // columns styles
    const columnsMaxLength: { [key: number]: number } = {};
    const rowsMaxHeight: { [key: number]: number } = {};

    for (let r = range.s.r; r <= range.e.r; ++r) {
      for (let c = range.s.c; c <= range.e.c; ++c) {
        const cellAddress = { c: c, r: r };
        const cellRef = XLSX.utils.encode_cell(cellAddress);

        if (ws[cellRef] === undefined) {
          continue;
        }

        if (r === 0) {
          ws[cellRef].s = XlsxService.HEADER_CELL_OPTIONS.style;
        } else {
          if (bodyCellOptions[c] && bodyCellOptions[c].style) {
            ws[cellRef].s = bodyCellOptions[c].style;
          }
        }

        const chunks = (typeof data[r][c] === 'string' ? data[r][c].split('\n') : [data[r][c]]);
        if (rowsMaxHeight[r] === undefined || (rowsMaxHeight[r] < chunks.length)) {
          rowsMaxHeight[r] = chunks.length;
        }

        for (const chunk of chunks) {
          if (columnsMaxLength[c] === undefined || (columnsMaxLength[c] < chunk.length)) {
            columnsMaxLength[c] = chunk.length;
          }
        }
      }
    }

    // columns width
    const cols = [];

    for (let c = range.s.c; c <= range.e.c; ++c) {
      if (columnOptions && columnOptions[c] && columnOptions[c].width) {
        const { width } = columnOptions[c];
        switch (width.type) {
          case 'auto':
            cols.push({ wch: (columnsMaxLength[c] ? columnsMaxLength[c] : 10) });
            break;
          case 'wch':
            cols.push({ wch: width.value });
            break;
          case 'wpx':
            cols.push({ wpx: width.value });
            break;
        }
      } else {
        cols.push({ wch: (columnsMaxLength[c] ? columnsMaxLength[c] : 10) });
      }
    }
    ws['!cols'] = cols;

    // rows height
    const rows = [];

    for (let r = range.s.r; r <= range.e.r; ++r) {
      rows.push(rowsMaxHeight[r] ? { hpt: rowsMaxHeight[r] * 15 } : undefined);
    }

    ws[ '!rows' ] = rows;

    // export
    XLSX.writeFile(wb, name, { bookType: 'xlsx', type: 'buffer' });
  }
}
