import { Component, Inject, OnInit } from '@angular/core';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import { StateService } from '@uirouter/angular';
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { AreaModel } from '@app/common/models/area.model';
import { BuildingNamePipe } from '@app/common/pipes/building-name.pipe';
import { BuildingModel } from '@app/common/models/building.model';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { ExternalParcelFormComponent } from '@app/ps/components/external-parcel-form/external-parcel-form.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ListModel } from '@app/common/models/list.model';
import { DialogService } from '@app/common/services/dialog.service';
import { GeometricPlanRequestStatusEnum } from '@app/ps/enums/geometric-plan-request-status.enum';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'opinion-request-create',
  templateUrl: './opinion-request-create.component.html',
  styleUrls: ['./opinion-request-create.component.scss']
})
export class OpinionRequestCreateComponent implements OnInit {

  readonly PARCEL_COLS = [
    { id: 'parcel', sortable: true },
    { id: 'title', sortable: true },
    { id: 'area_name', sortable: true },
    { id: 'area', sortable: false },
  ];

  opinionRequest = new OpinionRequestModel();
  area: AreaModel;
  similarOpinionRequestExists = false;
  buildings: BuildingModel[] = [];

  gpConcept = false;
  externalParcelListAdded: ListModel<ParcelModel> = { loading: false, list: [], itemCount: 0 };
  checkedAll = false;
  checkedAllAdded = false;
  checklistExternalParcelsAdded: any;

  titleFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  parcelFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
    titleId: { values: [] },
  };

  buildingFilter = {
    validity: ['valid'],
    occupationOrEasementExists: [ true ],
    titleRelatedId: { values: [] },
    loadCollections: ['parcelsWithEasementOrOccupation'],
  };

  geometricPlanRequestFilter = {
    status: { values: [
      GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
      GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION
    ]},
  };

  constructor(
    public requestService: OpinionRequestService,
    public titleNamePipe: TitleNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    public buildingNamePipe: BuildingNamePipe,
    private stateService: StateService,
    private dialogService: DialogService,
    private restangular: Restangular,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddParcel = this.onAddParcel.bind(this);
    this.isCheckedAdded = this.isCheckedAdded.bind(this);
  }

  ngOnInit() {
    this.checklistExternalParcelsAdded = new ChecklistModel();
  }

  isValid(): boolean {
    return !!(
      this.opinionRequest.name
      && this.opinionRequest.title
      && (
        this.gpConcept
        ||
        (this.opinionRequest.parcels && this.opinionRequest.parcels.length)
        ||
        (this.opinionRequest.buildings && this.opinionRequest.buildings.length)
      )
      && (
        !this.gpConcept
        ||
        (this.opinionRequest.geometricPlanRequest && this.checklistExternalParcelsAdded.checkedItems.length > 0)
      )
    );
  }

  onCadastreChanged() {
    this.parcelFilter.areaId.values = [this.area.id];
    this.titleFilter.areaId.values = [this.area.id];

    this.opinionRequest.title = undefined;
    this.opinionRequest.parcels = undefined;
    this.opinionRequest.buildings = undefined;
  }

  onTitleChanged() {
    this.parcelFilter.titleId.values = [ this.opinionRequest.title.id ];
    this.buildingFilter.titleRelatedId.values = [ this.opinionRequest.title.id ];

    this.opinionRequest.parcels = undefined;
    this.opinionRequest.buildings = undefined;

    this.reloadBuildings();
  }

  onParcelsChanged() {
    this.checkExistingSimilarOpinionRequest();
  }

  onBuildingsChanged() {
    this.checkExistingSimilarOpinionRequest();
  }

  onAddParcel() {
    const dialog = this.dialogService.open(ExternalParcelFormComponent, {
      data: { enablePricing: false, areaSelect: true },
      className: ClassName.HIGHER_DIALOG
    });
    const subscriber = dialog.afterClosed.subscribe((parcel: ParcelModel) => {
      if (parcel) {
        this.externalParcelListAdded.list = [parcel].concat(this.externalParcelListAdded.list);
        this.externalParcelListAdded.itemCount++;
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
      }
      subscriber.unsubscribe();
    });
  }

  isCheckedAdded(parcel: ParcelModel): boolean {
    return this.checklistExternalParcelsAdded.isChecked(parcel);
  }

  onToggleAllAdded() {
    this.checkedAllAdded = !this.checkedAllAdded;
    this.externalParcelListAdded.list.forEach((parcel: ParcelModel) => {
      if (!(this.checkedAllAdded && this.checklistExternalParcelsAdded.isChecked(parcel))) {
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
      }
    });
  }

  onToggleOneAdded(parcel: ParcelModel) {
    this.checklistExternalParcelsAdded.toggleSelection(parcel);
    this.checkedAllAdded = (this.externalParcelListAdded.list.length === this.checklistExternalParcelsAdded.checkedItems);
  }

  onSubmit(): Promise<any> {
    if (this.checklistExternalParcelsAdded.checkedItems.length) {
      this.opinionRequest.parcels = this.opinionRequest.parcels
        ? this.opinionRequest.parcels.concat(this.checklistExternalParcelsAdded.checkedItems)
        : this.checklistExternalParcelsAdded.checkedItems;
    }

    return this.requestService
      .create(this.opinionRequest)
      .then(opinionRequest => {
        this.stateService.go(
          'symap.project.opinionRequests.detail',
          { id: opinionRequest.id, tab: 'parcels' });
      });
  }

  private checkExistingSimilarOpinionRequest() {
    if ((!this.opinionRequest.parcels || !this.opinionRequest.parcels.length)
      && (!this.opinionRequest.buildings || !this.opinionRequest.buildings.length)
    ) {
      this.similarOpinionRequestExists = false;
      return;
    }

    const parcelIds = this.opinionRequest.parcels ? this.opinionRequest.parcels.map(p => p.id) : [];
    const buildingIds = this.opinionRequest.buildings ? this.opinionRequest.buildings.map(b => b.id) : [];

    const filter = { filter: { filters: {
      parcelId: parcelIds,
      buildingId: buildingIds,
    }}};

    this.restangular
      .all('opinion-requests').customPOST(filter)
      .toPromise()
      .then(data => {
        const requests = data.plain().filter(or => or.status !== OpinionRequestStatusEnum.DELETED);
        this.similarOpinionRequestExists = requests.length > 0;
      });
  }

  private reloadBuildings() {
    this.buildings = [];
    const title = this.opinionRequest.title;

    if (!title) {
      return;
    }

    this.restangular.all('buildings').customPOST({ filter: { filters: this.buildingFilter }})
      .toPromise()
      .then(data => {
        data.plain().forEach(building => {
          building.parcels.forEach(parcel => {
            if (building.title.id === title.id && parcel.title.id !== title.id) {
              this.buildings.push(building);
            }
          });
        });
      });
  }
}
