<h1 class="centered-text">Vyhledávání v systému Ares</h1>
<div class="mb-20">
    <fieldset>
        <legend>Parametry hledání</legend>

        <div class="column-100">
            <label>Způsob hledání</label>
            <gmt-input>
              <gmt-switch (select)="handleSelectOption($event)" [selected]="currentOption" [options]="options"></gmt-switch>
            </gmt-input>
        </div>

        <div class="column-100" *ngIf="currentOption.id === 'search-by-name'">
            <gmt-input label="Název">
              <input type="text" [(ngModel)]="nameToSearch" />
            </gmt-input>
        </div>

        <div class="column-100" *ngIf="currentOption.id === 'search-by-id'">
          <gmt-input label="IČO">
            <input type="text" [(ngModel)]="icoToSearch" />
          </gmt-input>
        </div>

        <gmt-button class="fr" [clickAction]="handleSearch">Hledat</gmt-button>
    </fieldset>
</div>
