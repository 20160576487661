<div class="list">
  <div style="display:flex">
    <div class="actions">
      <a (click)="onEdit()" class="button"><i class="fa fa-plus-square"></i>Vytvořit nový záznam</a>
    </div>
  </div>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group">
          <div class="row">
            <span class="cell">
              <sort-by
                [selected]="'id' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('id', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >ID.</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Titulek</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'publishDate' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('publishDate', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Publikovat k datu</sort-by>
            </span>
            <span class="cell">
              Akce
            </span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let changelog of list.list" (click)="onEdit(changelog)">
            <span class="cell">{{ changelog.id }}</span>
            <span class="cell">{{ changelog.name }}</span>
            <span class="cell">{{ changelog.publishDate | date:'mediumDate' }}</span>
            <span class="cell text-right"><a (click)="$event.stopPropagation(); $event.preventDefault(); onDelete(changelog);"><i class="fa fa-trash-o"></i></a></span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>
