import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SampleStatusEnum, sampleStatusOptions } from '@app/sv/enums/sample-status.enum';

@Pipe({ name: 'sampleStatusName' })
@Injectable({
  providedIn: 'root',
})
export class SampleStatusNamePipe implements PipeTransform {
  transform(value: SampleStatusEnum): string {
    const option = sampleStatusOptions.find(option => option.id === value);
    return option.name;
  }
}
