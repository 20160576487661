export class SubjectDetailModel {
  customAddressUse: boolean;
  databoxId: string;
  gender: Object;
  maritalStatus: Object;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  accountNumber: string;
  bankCode: string;
  businessRegisterEntry: string;
  businessRegisterSection: string;
  businessRegisterRegionalCourt: Object;
  dic: string;
  identificationNumber: string;
  personalIdentificationNumber: string;
  invoicePayment: boolean;
  vatPayer: boolean;
  contactPersonTitleBefore: string;
  contactPersonFirstName: string;
  contactPersonSurname: string;
  contactPersonDegreeAfter: string;
  contactPersonFunction: string;
  contactPersonPhoneNumber: string;
  contactPersonEmail: string;
  ulice: string;
  cisloDomovni: number;
  cisloOrientacni: string;
  castObce: string;
  mestskaCast: string;
  mestskyObvod: string;
  obec: string;
  okres: string;
  psc: string;
  stat: string;
  kodStatu: string;
  kodStatuAl2: string;
  dorucovaciUse: boolean;
  dorucovaciUlice: string;
  dorucovaciCisloDomovni: number;
  dorucovaciCisloOrientacni: string;
  dorucovaciCastObce: string;
  dorucovaciMestskaCast: string;
  dorucovaciMestskyObvod: string;
  dorucovaciObec: string;
  dorucovaciOkres: string;
  dorucovaciPsc: string;
  dorucovaciStat: string;
  dorucovaciKodStatu: string;
  dorucovaciKodStatuAl2: string;
  paymentType: 'X' | 'M' | 'B';
  problematic?: boolean;
  ssEU?: boolean;
  ssNonEU?: boolean;
  ssRuskyVliv?: boolean;
  ssDataTimestamp?: string;
}
