<ng-template #progressBar>
<div class="fill-content flexbox-container flex-centered p-10">
  <span *ngIf="data?.notStartedParcelIds.length > 0" class="chart chart--onhold" [style.width.%]="data?.notStartedParcelIds.length/(data?.notStartedParcelIds.length + data?.inProgressParcelIds.length + data?.doneParcelIds.length) * 95" title="Parcely k řešení"><span class="chart__number">{{data?.notStartedParcelIds.length}}</span></span>
  <span *ngIf="data?.inProgressParcelIds.length > 0" class="chart chart--progress" [style.width.%]="data?.inProgressParcelIds.length/(data?.notStartedParcelIds.length + data?.inProgressParcelIds.length + data?.doneParcelIds.length) * 95" title="Řešené parcely"><span class="chart__number">{{data?.inProgressParcelIds.length}}</span></span>
  <span *ngIf="data?.doneParcelIds.length > 0" class="chart chart--done" [style.width.%]="data?.doneParcelIds.length/(data?.notStartedParcelIds.length + data?.inProgressParcelIds.length + data?.doneParcelIds.length) * 95" title="Vyřešené parcely"><span class="chart__number">{{data?.doneParcelIds.length}}</span></span>
  <span *ngIf="!data || (data?.notStartedParcelIds.length + data?.inProgressParcelIds.length + data?.doneParcelIds.length) === 0" class="chart--no-data">-</span>
</div>
</ng-template>

<ng-container *ngIf="!tooltipComponent || !tooltipDataResult || (data?.notStartedParcelIds.length === 0 && data?.inProgressParcelIds.length === 0 && data?.doneParcelIds.length === 0)">
  <ng-container *ngTemplateOutlet="progressBar"></ng-container>
</ng-container>

<div *ngIf="tooltipComponent && tooltipDataResult && (data?.notStartedParcelIds.length > 0 || data?.inProgressParcelIds.length > 0 || data?.doneParcelIds.length > 0)"
  [activeContent]="true"
  [data]="tooltipDataResult"
  [tooltip]="tooltipComponent"
  class="tooltip-hover-area fill-content"
>
  <ng-container *ngTemplateOutlet="progressBar"></ng-container>
</div>


