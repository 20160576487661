import { Pipe, PipeTransform, Injectable } from "@angular/core";
/**
 * Display name of price type
 */
@Pipe({
  name: 'priceTypeName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class PriceTypeNamePipe implements PipeTransform {

  transform(priceType: string): string {
    if (priceType === 'P') {
      return 'Předběžný posudek';
    }
    if (priceType === 'E') {
      return 'Znalecký posudek';
    }
    if (priceType === 'O') {
      return 'Jiné určení';
    }
    return '';
  }
}
