import { Component, Input, OnInit } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';
import {
  ExpertOpinionCoefficientsFlatten,
  ParcelPriceCoefficient,
  ParcelPriceModel
} from '@app/ps/models/parcel-price.model';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { OpinionModel } from '@app/ps/models/opinion.model';
import {ParcelPriceUtils} from "@app/ps/utils/parcel-price.utils";

@Component({
  selector: '[pricing-expert-opinion-row]',
  templateUrl: './pricing-expert-opinion-row.component.html',
  styleUrls: ['pricing-expert-opinion-row.component.scss']
})
export class PricingExpertOpinionRowComponent implements OnInit {

  @Input() parcelPrice: ParcelPriceModel;
  @Input() parcel: ParcelModel;
  @Input() building: any;
  @Input() hasCoefficient: boolean;

  @Input() opinion: OpinionModel;
  @Input() selectable: boolean;
  @Input() selected: boolean;
  @Input() showParcel: boolean;

  readonly COEFFICIENT_8 = ParcelPriceCoefficient.COEFFICIENT_8;
  readonly COEFFICIENT_1_5 = ParcelPriceCoefficient.COEFFICIENT_1_5;
  readonly COEFFICIENT_1 = ParcelPriceCoefficient.COEFFICIENT_1;

  constructor(
    private numericPipe: NumericPipe,
  ) {
  }

  ngOnInit() {
  }

  isParcelPriceEnabled(): boolean {
    const hasLand = (this.parcel && !this.parcel.validBuyoutOpinionLand) || !this.opinion.expertOpinionLand;
    const hasVegetation = (this.parcel && !this.parcel.validBuyoutOpinionVegetation) || !this.opinion.expertOpinionVegetation;
    const hasBuilding = (this.parcel && !this.parcel.validBuyoutOpinionBuildings) || !this.opinion.expertOpinionBuildings;
    const isBuilding = this.building && (!this.building.validBuyoutOpinionBuildings || !this.opinion.expertOpinionBuildings);
    return (hasLand && hasVegetation && hasBuilding) || isBuilding;
  }

  computeParcelPrice() {
    const parcelPrice = this.parcelPrice;
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    parcelPrice.expertOpinionParcelPriceDefault = 0;
    parcelPrice.expertOpinionLandPriceCoefficient = 0;

    // Land price
    if (this.opinion.expertOpinionLand && parcelPrice.parcel) {
      const squarePrice = this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      parcelPrice.expertOpinionParcelPriceDefault = squarePrice * parcelPrice.parcel.vymera;
      parcelPrice.expertOpinionLandPriceCoefficient = ParcelPriceUtils.computeLandPriceCoefficient(parcelPrice);
    } else {
      parcelPrice.expertOpinionLandSquarePrice = null;
      parcelPrice.expertOpinionLandAreaPrice = null;
    }

    // Compensation price
    if (this.opinion.expertOpinionCompensation) {
      const compensationPrice = this.numericPipe.transform(parcelPrice.expertOpinionCompensationPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += compensationPrice;
      parcelPrice.expertOpinionCompensationPrice = coefficients.compensationCoefficient !== this.COEFFICIENT_1
        ? Math.round(compensationPrice * coefficients.compensationCoefficient)
        : compensationPrice;
    } else {
      parcelPrice.expertOpinionCompensationPrice = null;
      parcelPrice.expertOpinionCompensationPriceDefault = null;
    }

    // Vegetation price
    if (this.opinion.expertOpinionVegetation) {
      const vegetationPrice = this.numericPipe.transform(parcelPrice.expertOpinionVegetationPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += vegetationPrice;
      parcelPrice.expertOpinionVegetationPrice = coefficients.vegetationCoefficient !== this.COEFFICIENT_1
        ? Math.round(vegetationPrice * coefficients.vegetationCoefficient)
        : vegetationPrice;
    } else {
      parcelPrice.expertOpinionVegetationPrice = null;
      parcelPrice.expertOpinionVegetationPriceDefault = null;
    }

    // Buildings price
    if (this.opinion.expertOpinionBuildings) {
      const buildingsPrice = this.numericPipe.transform(parcelPrice.expertOpinionBuildingsPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += buildingsPrice;
      parcelPrice.expertOpinionBuildingsPrice = coefficients.buildingsCoefficient !== this.COEFFICIENT_1
        ? Math.round(buildingsPrice * coefficients.buildingsCoefficient)
        : buildingsPrice;
    } else {
      parcelPrice.expertOpinionBuildingsPrice = null;
      parcelPrice.expertOpinionBuildingsPriceDefault = null;
    }

    // Store configuration
    parcelPrice.expertOpinionCoefficients = ExpertOpinionCoefficientsFlatten.toCoefficients(coefficients);
  }

  toggleMultiply(type: 'compensation' | 'vegetation' | 'buildings') {
    const coefficients = this.parcelPrice.expertOpinionCoefficientsFlatten;
    let firstLandCoefficient = null;

    if (coefficients.land8Multiply) {
      firstLandCoefficient = this.COEFFICIENT_8;
    } else if (coefficients.land15Multiply) {
      firstLandCoefficient = this.COEFFICIENT_1_5;
    }

    coefficients[type + 'Multiply'] = !coefficients[type + 'Multiply'];

    if (firstLandCoefficient && coefficients[type + 'Coefficient'] === this.COEFFICIENT_1) {
      coefficients[type + 'Coefficient'] = firstLandCoefficient;
    }
  }

  multipleLandCoefficient(parcelPrice: ParcelPriceModel) {
    return ParcelPriceUtils.multipleLandCoefficient(parcelPrice);
  }
}
