import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CellAbstractComponent, CellTooltipMappingType } from '../cell-abstract/cell-abstract.component';
import { TooltipConstructionObjectsComponent } from '@app/common/components/tooltip-construction-objects/tooltip-construction-objects.component';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';

@Component({
  selector: 'cell-construction-objects',
  templateUrl: './cell-construction-objects.component.html',
  styleUrls: ['./cell-construction-objects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellConstructionObjectsComponent extends CellAbstractComponent {

  // bindings
  @Input() list = true;
  @Input() constructionObjects: ConstructionObjectModel[];


  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'construction-objects': TooltipConstructionObjectsComponent,
  };
}
