import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { MonthPickerOptionsInput } from '@app/common/components/month-picker/month-picker.component';
import { RESTANGULAR_PROMAP } from '@app/common/services/restangular-promap.service';
import { RequestModel } from '@app/pk/models/request.model';
import { CadastreReportModel } from '@app/settings/modules/models/cadastre-report.model';
import {APP_CONFIG} from "@app/common/services/config.service";
import {HttpService} from "@app/common/services/http.service";
import {HttpResponse} from "@angular/common/http";

const RESULT_CODE_MESSAGES = {
  10: 'V hlášení některé žádosti chybí, hlášení nelze vytvořit. Zkontrolujte data.',
  20: 'V hlášení jsou k. ú., v nichž nebyly odebrány žádné měrné jednotky, hlášení nelze vytvořit. Zkontrolujte data.',
  30: 'Hlášení pro zvolené období už pravděpodobně existuje.',
  40: 'Do hlášení nebyla zahrnuta všechna data, hlášení nelze vytvořit. Zkontrolujte data.',
};

@Component({
  selector: 'cadastre-data-report',
  templateUrl: './cadastre-data-report.component.html',
  styleUrls: ['./cadastre-data-report.component.scss']
})
export class CadastreDataReportComponent implements OnInit {

  reportDate: string;
  unfinishedRequests: RequestModel[];

  public readonly monthPickerOptions: MonthPickerOptionsInput = {
    minDate: moment().subtract(1, 'month').toDate(),
    maxDate: moment().subtract(1, 'month').toDate(),
    defaultDate: moment().subtract(1, 'month').toDate(),
  };

  constructor(
    private dialogService: DialogService,
    @Inject(RESTANGULAR_PROMAP) private restangularPromap,
    @Inject(APP_CONFIG) private config: any,
    private httpService: HttpService
  ) {  }

  ngOnInit() {
    this.onExistingReportDownload = this.onExistingReportDownload.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onExistingReportDownload() {
    let reports = await this.getBaseReport().get().toPromise();
    reports = reports.items;

    this.processFoundReport(reports);
    return reports;
  }

  onReportDateChange(reportDate: Date) {
    this.reportDate = formatDate(reportDate, 'yyyy-MM-dd', 'cs');
    this.checkUnfinishedRequests();
  }

  async onSubmit(): Promise<CadastreReportModel[]> {
    const reports = await this.getBaseReport().customPOST({}, '', { validityDate: this.reportDate }).toPromise();
    this.processFoundReport(reports);
    return reports;
  }

  private async checkUnfinishedRequests() {
    this.unfinishedRequests = await this.restangularPromap
      .one(`cuzk/xml-report/unfinished?validityDate=${this.reportDate}`)
      .get()
      .toPromise();
  }

  private async downloadXmlReport(report: CadastreReportModel) {
    await this.httpService.call({
      path: `cuzk/xml-report/${report.id}`,
      method: 'GET',
      fullResponse: true,
      responseType: 'blob',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlPK
    }).then((response: HttpResponse<any>) => {
      const filename = HttpService.parseFilename2(response);
      saveAs(response.body, filename);
    });
  }

  private getBaseReport() {
    return this.restangularPromap
      .all('cuzk')
      .one('xml-report');
  }

  private showMessageOnXmlReportProcessingDone(msg: string) {
    return this.dialogService.open(AlertComponent, {
      data: {
        msg
      },
      className: ClassName.ADJUSTED_DIALOG,
    });
  }

  private processFoundReport(reports: CadastreReportModel[]) {
    const invalidReports  = reports.filter((r: CadastreReportModel) => !r.validXml);

    if (invalidReports.length > 0) {
      this.showMessageOnXmlReportProcessingDone(RESULT_CODE_MESSAGES[invalidReports[0].resultCode]);
      return;
    } else {
      this.showDownloadMessage(reports);
    }
  }

  private showDownloadMessage(reports: CadastreReportModel[]) {
    const msg = this.getDownloadMessage();
    const dialog = this.showMessageOnXmlReportProcessingDone(msg);
    const sub = dialog.afterClosed.subscribe((downloadWanted: boolean) => {
      if (downloadWanted) {
        reports.forEach(this.downloadXmlReport, this);
      }
      sub.unsubscribe();
    });
  }

  private getDownloadMessage(): string {
    return 'Hlášení je připraveno, bude staženo po zavření dialogu.';
  }
}
