import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbStorageService {
  private store;

  constructor() {
    this.store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'sy',
      version: 1.0,
      storeName: 'sy',
      description: 'SY'
    });
  }

  save(key: string, data: any): any {
    return this.store.setItem(key, data);
  }

  load(key: string): any {
    return this.store.getItem(key);
  }

  remove(key: string): any {
    return this.store.removeItem(key);
  }
}
