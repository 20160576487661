<div class="list">
  <div class="row">
    <div class="col-6">
      <h1>Nastavení</h1>
    </div>
    <div class="col-6">
      <div class="actions">
        <a uiSref="sv.settings.createDocumentType" class="button mb-10"><i class="fa fa-plus"></i> Vyvoření nového typu dokumentu</a>
      </div>
    </div>
  </div>

  <h2>Typy dokumentů</h2>
  <div [loading]="loading">
    <div *ngIf="!loading">
      <ng-container *ngIf="documentTypes.length">
        <table class="bordered">
          <thead>
            <tr>
              <th>Název</th>
              <th>Popis</th>
              <th>Kategorie</th>
              <th>Typy případů</th>
            </tr>
          </thead>
          <tr *ngFor="let documentType of documentTypes">
            <td>{{ documentType.name }}</td>
            <td>{{ documentType.description }}</td>
            <td>{{ documentType.category | documentTypeCategoryName }}</td>
            <td class="comma-list"><span *ngFor="let obligation of documentType.obligations">{{obligation.name}}</span></td>
          </tr>
        </table>
      </ng-container>
      <ng-container *ngIf="!documentTypes.length">
        <div class="empty-info bordered">
          Nenalezena žádná data
        </div>
      </ng-container>
    </div>
  </div>
</div>

