<div class="home">
  <h1 class="mb-20">Přehled</h1>
  <mpv-statistics></mpv-statistics>
  <div class="mt-80">
    <fieldset class="bordered">
      <legend>Upozornění</legend>
      <gmt-notifications></gmt-notifications>
    </fieldset>
  </div>
</div>
<help [helpId]="helpIds.PANEL_HOME" class="cm-help-page"></help>
