import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ListService } from "@app/common/services/list.service";
import { OccupationModel } from '@app/ps/models/occupation.model';
import { ListModel } from '@app/common/models/list.model';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './update-occupation.component.html',
  styleUrls: ['./update-occupation.component.scss'],
})
export class UpdateOccupationComponent implements OnInit {
  @Input() caseId: number;
  @Input() occupationId: number;
  occupationObject: OccupationModel;
  occupationList: ListModel<OccupationModel>;
  selected: OccupationModel;

  constructor(
    private stateService: StateService,
    private listService: ListService,
    private restangular: Restangular,
  ) {
    this.goToCase = this.goToCase.bind(this);
    this.update = this.update.bind(this);
  }

  async ngOnInit() {
    await this.loadOccupation();
    this.occupationList = this.listService.createList(
        'occupations',
        {
          limit: undefined,
          filters: {
            parcelId: this.occupationObject.parcel.id,
            occupationTypeId: this.occupationObject.occupationType.id,
            validity: 'valid',
            loadCollections: ['constructionObjects'],
          },
          sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}]
        }
    );
    this.listService.fetchResult(this.occupationList);
  }

  async loadOccupation() {
    this.selected = this.occupationObject = await this.restangular.one('occupations', this.occupationId).get({
      loadCollections: ['constructionObjects'],
    }).toPromise();
  }

  goToCase() {
     this.stateService.go('^');
  }

  onToggleCheckItem(occupation: OccupationModel) {
    this.selected = occupation;
  }

  async update() {
    await this.restangular.one('cases', this.caseId).one('update-occupation', this.occupationId).all(this.selected.id).customPUT().toPromise();
    this.stateService.go('^');
  }
}

