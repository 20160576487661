<fieldset>
  <div class="column-100">
    <gmt-input label="Datum doručení souhlasu" required="true">
      <input type="text" pick-a-date [(ngModel)]="data.responseReceivedDate" [maxToday]="true"/>
    </gmt-input>
  </div>
</fieldset>

<fieldset>
  <legend>Datum souhlasu</legend>
  <div *ngFor="let caseSubject of data.caseSubjects" class="cb">
    <div class="column-100">
      <label [ngClass]="{'required': enabledSubjects[caseSubject.id]}">{{caseSubject.subject|subjectName}}</label>
      <div class="input" *ngIf="enabledSubjects[caseSubject.id]">
        <input type="text" pick-a-date [(ngModel)]="caseSubject.acceptedGeometricalPlanPossibleDate"/>
      </div>
      <div class="info" *ngIf="!enabledSubjects[caseSubject.id]">
        {{caseSubject.acceptedGeometricalPlanPossibleDate|date}}
      </div>
    </div>
  </div>
</fieldset>
