<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam LV</a>
      </div>
      <div class="actions">
      </div>
    </div>

    <h1 class="mb-30">LV{{data.lv}}
      <span>{{data.area.name}} /</span>
      <span *ngIf="data.endDate" class="data-ended">neaktualizováno od {{data.endDate|date}}</span>
      <span *ngIf="!data.endDate">částečný výpis / Data KN aktuální k {{data.cadastreDataDate| date: 'd.M.yyyy'}}</span>
    </h1>

    <div *ngIf="!data.endDate">
      <gmt-labeled-block class="pt-20" label="Osoby">
        <div class="bordered">
          <div *ngIf="data.ownerships.length === 0" class="empty-info">Žádní vlastníci</div>
          <table *ngIf="data.ownerships.length !== 0">
            <thead>
            <tr>
              <th>Osoba</th>
              <th>Podíl</th>
              <th>Adresa</th>
              <th>IČ</th>
            </tr>
            </thead>
            <tbody *ngFor="let ownership of data.ownerships" class="hover">
            <tr *ngIf="getSubject(ownership) as currentSubject">
              <td><b>{{currentSubject|subjectName}}</b></td>
              <td [attr.rowspan]="ownership.subject.opsubType === 'BSM' ? 2 : 1">{{ownership.podilCitatel}}/{{ownership.podilJmenovatel}}<br/>{{ownership.typravNazev}}</td>
              <td>{{currentSubject.ulice || !currentSubject.cisloDomovni ? currentSubject.ulice : 'č.p.'}} {{currentSubject.cisloDomovni}}<span *ngIf="currentSubject.cisloOrientacni">/{{currentSubject.cisloOrientacni}}</span><span *ngIf="currentSubject.ulice || currentSubject.cisloDomovni">, </span>{{currentSubject.psc}} {{currentSubject.obec}}
              </td>
              <td>{{currentSubject.ico !== 1 ? currentSubject.ico : ''}}</td>
            </tr>
            <tr *ngIf="ownership.subject.opsubType === 'BSM'">
              <td><b>{{ownership.subject.subjectSjm2|subjectName}}</b></td>
              <td *ngIf="ownership.subject.opsubType !== 'BSM'">{{ownership.podilCitatel}}/{{ownership.podilJmenovatel}}<br/>{{ownership.typravNazev}}</td>
              <td>{{ownership.subject.subjectSjm2.ulice || !ownership.subject.subjectSjm2.cisloDomovni ? ownership.subject.subjectSjm2.ulice : 'č.p.'}} {{ownership.subject.subjectSjm2.cisloDomovni}}<span *ngIf="ownership.subject.subjectSjm2.cisloOrientacni">/{{ownership.subject.subjectSjm2.cisloOrientacni}}</span><span *ngIf="ownership.subject.subjectSjm2.ulice || ownership.subject.subjectSjm2.cisloDomovni">, </span>{{ownership.subject.subjectSjm2.psc}} {{ownership.subject.subjectSjm2.obec}}
              </td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>

      <gmt-labeled-block class="mt-20" label="Parcely">
        <div class="bordered">
          <div *ngIf="data.parcels.length === 0" class="empty-info">Žádné parcely</div>
          <table *ngIf="data.parcels.length !== 0">
            <thead>
            <tr>
              <th rowspan="2">Parcela</th>
              <th rowspan="2">Výměra [<span class="unit">m<sup>2</sup></span>]</th>
              <th rowspan="2">Druh pozemku</th>
              <th rowspan="2">Ochrana</th>
            </tr>
            </thead>
            <tbody *ngFor="let parcel of data.parcels" class="hover">
              <tr>
                <td class="no-padding">
                  <cell-parcel
                    [parcel]="parcel"
                    [tooltipType]="'dimap-parcel'"
                    [tooltipData]="{ parcel: parcel }"
                  ></cell-parcel>
                </td>
                <td>{{parcel.vymera}}</td>
                <td>{{parcel.drupozNazev}}<div *ngIf="parcel.zpusobVyuziti">({{parcel.zpusobVyuziti}})</div></td>
                <td>
                  <div>{{ parcel.parcelProtectionsText }}</div>
                  <div class="comma-list" *ngIf="parcel.bpejList?.length > 0">(<span *ngFor="let itemBpej of parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>
  </div>

  <help [helpId]="helpIds.VM_TITLE_DETAIL" class="cm-help-page"></help>
</div>
