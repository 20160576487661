import { InjectionToken } from '@angular/core';
import { Restangular } from 'ngx-restangular';

export const RESTANGULAR_GLOBAL = new InjectionToken<any>('RestangularGlobal');

export function RestangularGlobalFactory(restangular: Restangular, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrl);
  });
  return r;
}

