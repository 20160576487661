<div [loading]="loading" *ngIf="loading">
</div>
<div *ngIf="!loading">
  <gmt-labeled-block class="mb-20" *ngIf="dataOccupationGroupsFiltered.permanent?.length" label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
    <div class="bordered">
      <table>
        <thead>
        <tr>
          <th class="min">
            <gmt-checkbox (click)="checkedAllGroups.permanent = !checkedAllGroups.permanent; selection.toggleAll(checkedAllGroups.permanent, checklistGroup, dataOccupationGroupsFiltered.permanent, selection.toggleSelectionGroup);" [gmtChecked]="checkedAllGroups.permanent"></gmt-checkbox>
          </th>
          <th>
            <sort-by [selected]="'occupationParcel.parcisKmen,occupationParcel.parcisPod' === sortOrder.permanent?.sortBy" (sort)="onSortOccupationParcel('occupationParcel.parcisKmen,occupationParcel.parcisPod', 'permanent')" [sortDir]="sortOrder.permanent?.direction">
              Parcela
            </sort-by>
          </th>
          <th>Ochrana</th>
          <th>{{'ETAPA_USEK'|wordtranslate}}</th>
          <th>Zábor</th>
          <th>{{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}</th>
          <th *ngIf="priceColumnShown()">
            Cena [<span class="unit">Kč</span>]
          </th>
          <th *ngIf="priceColumnShown()">
            Posudek
          </th>
          <th>
            <sort-by [selected]="'occupationCases'=== sortOrder.permanent?.sortBy" (sort)="onSortOccupation('occupationCases','occupations[0].countCases', 'permanent')" [sortDir]="sortOrder.permanent?.direction">
              Existující případy
            </sort-by>
          </th>
        </tr>
        </thead>
        <tbody *ngFor="let itemGroup of dataOccupationGroupsFiltered.permanent; let $indexGroup = index" class="hover">
        <tr *ngFor="let itemOccupation of itemGroup.occupations; let $index = index" (click)="selection.toggleSelectionGroup(itemGroup)">
          <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length">
            <gmt-checkbox [gmtChecked]="checklistGroup.isChecked(itemGroup)" [gmtDisabled]="!selection.isGroupEnabled(itemGroup)"></gmt-checkbox>
          </td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="itemGroup.parcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{ parcel: itemGroup.parcel, withoutTitle: true }"
            ></cell-parcel>
          </td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length">
            <div class="comma-list"><span *ngFor="let itemProtection of itemGroup.parcel.parcelProtections">{{itemProtection.nazevOchrany}}</span></div>
            <div class="comma-list" *ngIf="itemGroup.parcel.bpejList.length > 0">(<span *ngFor="let itemBpej of itemGroup.parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
          </td>
          <td class="number">{{itemOccupation.zabst}}</td>
          <td class="no-padding cell-max-height">
            <cell-occupation [occupation]="itemOccupation"></cell-occupation>
          </td>
          <td>
            <div *ngFor="let constructionObject of itemOccupation.constructionObjects">
              <a (click)="$event.stopPropagation();" uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: constructionObject.id }">
                {{constructionObject|constructionObjectName}}
              </a>
            </div>
          </td>
          <td *ngIf="priceColumnShown() && $index === 0" [attr.rowspan]="itemGroup.occupations.length" class="number">
            {{itemGroup.parcel.totalPrice|number}}
          </td>
          <td *ngIf="priceColumnShown() && $index === 0" [attr.rowspan]="itemGroup.occupations.length">
            {{itemGroup.priceSource}}
          </td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length" class="no-padding cell-max-height">
            <cell-cases
              [casesCount]="itemGroup.cases ? itemGroup.cases.length : 0"
              tooltipType="cases"
              [tooltipData]="{ list: { loading: false, list: itemGroup.cases || [] }}"
            ></cell-cases>
          </td>
        </tr>
        <tr class="bordered-top error-message" *ngIf="!selection.isGroupEnabled(itemGroup)" [ngClass]="{'bordered-bottom': $indexGroup < dataOccupationGroupsFiltered.permanent.length - 1}">
          <td colspan="9"><div *ngFor="let msg of selection.getGroupDisabledMessage(itemGroup)">{{msg}}</div></td>
        </tr>
        </tbody>
      </table>
    </div>
  </gmt-labeled-block>

  <div *ngFor="let key of ['permanentWithSolutionType', 'permanentWithSolutionTypeUnderOneYear', 'permanentWithSolutionTypeOverOneYear', 'underOneYear', 'overOneYear']">
    <gmt-labeled-block class="mb-20" *ngIf="dataOccupationGroupsFiltered[key]?.length" [label]="temporaryOccupationTableNames[key]">
      <div class="bordered">
        <table>
          <thead>
          <tr>
            <th class="min">
              <gmt-checkbox (click)="checkedAllGroups[key] = !checkedAllGroups[key]; selection.toggleAll(checkedAllGroups[key], checklistGroup, dataOccupationGroupsFiltered[key], selection.toggleSelectionGroup);" [gmtChecked]="checkedAllGroups[key]"></gmt-checkbox>
            </th>
            <th>
              <sort-by [selected]="'occupationParcel.parcisKmen,occupationParcel.parcisPod' === sortOrder[key]?.sortBy" (sort)="onSortOccupationParcel('occupationParcel.parcisKmen,occupationParcel.parcisPod', key)" [sortDir]="sortOrder[key]?.direction">
                Parcela
              </sort-by>
            </th>
            <th>Ochrana</th>
            <th>{{'ETAPA_USEK'|wordtranslate}}</th>
            <th>Zábor</th>
            <th>{{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}</th>
            <th *ngIf="priceColumnShown()">
              Cena za <span class="unit">m<sup>2</sup>/rok</span> [<span class="unit">Kč</span>]
            </th>
            <th *ngIf="priceColumnShown()">
              Posudek
            </th>
            <th>
              <sort-by [selected]="'occupationCases'=== sortOrder[key]?.sortBy" (sort)="onSortOccupation('occupationCases','occupations[0].countCases', key)" [sortDir]="sortOrder[key]?.direction">
                Existující případy
              </sort-by>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let itemGroup of dataOccupationGroupsFiltered[key]; let $indexGroup = index" class="hover">
          <tr *ngFor="let itemOccupation of itemGroup.occupations; let $index = index" (click)="selection.toggleSelectionGroup(itemGroup)">
            <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length">
              <gmt-checkbox [gmtChecked]="checklistGroup.isChecked(itemGroup)" [gmtDisabled]="!selection.isGroupEnabled(itemGroup)"></gmt-checkbox>
            </td>
            <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length" class="no-padding cell-max-height">
              <cell-parcel
                [parcel]="itemGroup.parcel"
                [tooltipType]="'parcel'"
                [tooltipData]="{ parcel: itemGroup.parcel, withoutTitle: true }"
              ></cell-parcel>
            </td>
            <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length">
              <div class="comma-list"><span *ngFor="let itemProtection of itemGroup.parcel.parcelProtections">{{itemProtection.nazevOchrany}}</span></div>
              <div class="comma-list" *ngIf="itemGroup.parcel.bpejList.length > 0">(<span *ngFor="let itemBpej of itemGroup.parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
            </td>
            <td class="number">{{itemOccupation.zabst}}</td>
            <td class="no-padding cell-max-height">
              <cell-occupation [occupation]="itemOccupation"></cell-occupation>
            </td>
            <td>
              <div *ngFor="let constructionObject of itemOccupation.constructionObjects">
                <a (click)="$event.stopPropagation();" uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: constructionObject.id }">
                  {{constructionObject|constructionObjectName}}
                </a>
              </div>
              <div *ngIf="isPermanentOccupationOnParcel(itemGroup.parcel) && !isPermanentCategoryOccupation(itemOccupation)" class="mt-5"><i class="fa fa-info-circle"></i> Na parcele se nachází trvalý zábor</div>
            </td>
            <td *ngIf="priceColumnShown() && $index === 0" [attr.rowspan]="itemGroup.occupations.length" class="number">
              {{itemOccupation.priceAssessmentLandPrice|number}}
            </td>
            <td *ngIf="priceColumnShown() && $index === 0" [attr.rowspan]="itemGroup.occupations.length">
              {{itemOccupation.priceAssessmentYear}}
            </td>
            <td *ngIf="$index === 0" [attr.rowspan]="itemGroup.occupations.length" class="no-padding cell-max-height">
              <cell-cases
                [casesCount]="itemGroup.cases ? itemGroup.cases.length : 0"
                tooltipType="cases"
                [tooltipData]="{ list: { loading: false, list: itemGroup.cases || [] }}"
              ></cell-cases>
            </td>
          </tr>
          <tr *ngIf="(data.obligation.type === 'RentalContractShorterThan1YearLiability' || data.obligation.type === 'RentalContractLongerThan1YearLiability' || data.obligation.type === 'RentalContractCommonLiability') && itemGroup.parcel.compensationPrice > 0">
            <td colspan="9" class="bordered-top" [ngClass]="{'bordered-bottom': $indexGroup < dataOccupationGroupsFiltered[key].length - 1}">
              <a class="button mr-10" [ngClass]="{red: itemGroup.occupations[0].compensationPrice, disabled: itemGroup.disabledCompensationPrice || itemGroup.parcel.compensationOpinionsShare !== 100}" (click)="selection.toggleSelectionCompensationPrice(itemGroup)">
                <i class="fa" [ngClass]="{'fa-plus': !itemGroup.occupations[0].compensationPrice, 'fa-minus': itemGroup.occupations[0].compensationPrice}"></i>
              </a>
              <span *ngIf="!itemGroup.occupations[0].compensationPrice">Připojit do smlouvy náhradu</span>
              <span *ngIf="itemGroup.occupations[0].compensationPrice">Ke smlouvě je připojena náhrada</span>
              za trvalý porost podle <span class="comma-list"><span *ngFor="let parcelPrice of itemGroup.compensationParcelPrices">{{parcelPrice.opinion.priceType == 'E' ? 'znaleckého posudku' + (parcelPrice.opinion.expertOpinionNumber ? ' ' + parcelPrice.opinion.expertOpinionNumber : '') : 'jiného určení ceny' + (parcelPrice.opinion.otherSource ? ' ' + parcelPrice.opinion.otherSource : '')}}</span></span>, v celkové ceně {{itemGroup.parcel.compensationPrice|number|price}}
            </td>
          </tr>
          <tr class="bordered-top error-message" *ngIf="!selection.isGroupEnabled(itemGroup)" [ngClass]="{'bordered-bottom': $indexGroup < dataOccupationGroupsFiltered[key].length - 1}">
            <td colspan="9"><div *ngFor="let msg of selection.getGroupDisabledMessage(itemGroup)">{{msg}}</div></td>
          </tr>
          </tbody>
        </table>
      </div>
    </gmt-labeled-block>
  </div>

  <gmt-labeled-block class="mb-20" *ngIf="dataEasements.length && (data.obligation.objectTypes.easementsGeometricPlan || data.obligation.objectTypes.easementsLandTake)" [collapsed]="false" label="{{ 'EASEMENT' | wordtranslate }}">
    <div class="bordered">
      <table>
        <thead>
        <tr>
          <th class="min"><gmt-checkbox (click)="checkedAllEasements = !checkedAllEasements; selection.toggleAll(checkedAllEasements, checklistEasement, dataEasements, selection.toggleSelectionEasement);" [gmtChecked]="checkedAllEasements"></gmt-checkbox></th>
          <th>
            <sort-by [selected]="'easementsParcel.parcisKmen,easementsParcel.parcisPod' === sortOrder.easement?.sortBy" (sort)="onSortEasementsParcel('easementsParcel.parcisKmen,easementsParcel.parcisPod')" [sortDir]="sortOrder.easement?.direction">
              Parcela
            </sort-by>
          </th>
          <th>Ochrana</th>
          <th>
            <sort-by [selected]="'administratorName' === sortOrder.easement?.sortBy" (sort)="onSortEasementsAdministrator('administratorName')" [sortDir]="sortOrder.easement?.direction">
              {{ "CONSTRUCTION_OBJECT" | wordtranslate }}
            </sort-by>
          </th>
          <th>Plocha VB [<span class="unit">m<sup>2</sup></span>]</th>
          <th>Délka VB [<span class="unit">m</span>]</th>
          <th>Poloha VB</th>
          <th *ngIf="priceColumnShown()">Cena [<span class="unit">Kč</span>]</th>
          <th>
            <sort-by [selected]="'easementsCases'=== sortOrder.easement?.sortBy" (sort)="onSortEasements('easementsCases','occupations[0].countCases')" [sortDir]="sortOrder.easement?.direction">
              Existující případy
            </sort-by>
          </th>
        </tr>
        </thead>
        <tbody *ngFor="let easement of dataEasements; let $index = index" class="hover">
        <tr (click)="toggleSelectionEasement(easement)">
          <td>
            <gmt-checkbox [gmtChecked]="checklistEasement.isChecked(easement)" [gmtDisabled]="!selection.isEasementEnabled(easement)"></gmt-checkbox>
          </td>
          <td class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="easement.parcel"
              tooltipType="parcel"
              [tooltipData]="{ parcel: easement.parcel, withoutTitle: true }"
            ></cell-parcel>
          </td>
          <td>
            <div>{{ easement.parcel.parcelProtectionsText }}</div>
            <div class="comma-list" *ngIf="easement.parcel.bpejList.length > 0">(<span *ngFor="let itemBpej of easement.parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
          </td>
          <td>
            <gmt-construction-object-list [showAdministrator]="true" (click)="$event.stopPropagation();" [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
            <div *ngIf="isPermanentOccupationOnParcel(easement.parcel)" class="mt-5"><i class="fa fa-info-circle"></i> Na parcele se nachází trvalý zábor</div>
          </td>
          <td class="number">{{easement.vymera}}</td>
          <td class="number">{{easement.delka}}</td>
          <td class="text-center">{{easement | easementOccupation}}</td>
          <td class="number" *ngIf="priceColumnShown()">{{easement.easementPrice}}</td>
          <td class="no-padding cell-max-height">
            <cell-cases
              [casesCount]="easement.countCases"
              tooltipType="cases"
              [tooltipData]="{ list: { loading: false, list: easement.cases || [] }}"
            ></cell-cases>
          </td>
        </tr>
        <tr class="bordered-top error-message" *ngIf="!selection.isEasementEnabled(easement)" [ngClass]="{'bordered-bottom': $index < dataEasements.length - 1}">
          <td [attr.colspan]="7 + (priceColumnShown() ? 1 : 0)"><div *ngFor="let msg of selection.getEasementDisabledMessage(easement)">{{msg}}</div></td>
        </tr>
        </tbody>
      </table>
    </div>
  </gmt-labeled-block>

  <gmt-labeled-block label="Budovy" *ngIf="dataBuildingsFiltered.length" class="mb-20">
    <div class="empty-info bordered" *ngIf="dataBuildingsFiltered.length === 0">
      Žádné budovy
    </div>
    <div *ngIf="dataBuildingsFiltered.length > 0" class="bordered">
      <table>
        <thead>
        <tr>
          <th class="min"><gmt-checkbox (click)="checkedAllBuildings = !checkedAllBuildings; selection.toggleAll(checkedAllBuildings, checklistBuilding, dataBuildingsFiltered, selection.toggleSelectionBuilding);" [gmtChecked]="checkedAllBuildings"></gmt-checkbox></th>
          <th>Parcela</th>
          <th>LV parcely</th>
          <th>Část obce</th>
          <th>Číslo budovy</th>
          <th>Typ a Využití</th>
          <th>Ochrana</th>
          <th *ngIf="priceColumnShown()">
            Cena [<span class="unit">Kč</span>]
          </th>
          <th *ngIf="priceColumnShown()">
            Posudek
          </th>
          <th>Existující případy</th>
        </tr>
        </thead>
        <tbody *ngFor="let itemBuilding of dataBuildingsFiltered; let $indexBuilding = index" (click)="selection.toggleSelectionBuilding(itemBuilding)" class="hover">
        <tr *ngFor="let itemParcel of itemBuilding.building.parcels; let $index = index">
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">
            <gmt-checkbox [gmtChecked]="checklistBuilding.isChecked(itemBuilding.building)" [gmtDisabled]="!selection.isBuildingEnabled(itemBuilding)"></gmt-checkbox>
          </td>
          <td class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="itemParcel"
              tooltipType="parcel"
              [tooltipData]="{ parcel: itemParcel }"
            ></cell-parcel>
          </td>
          <td class="number">{{itemParcel.lv}}</td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building.castobce}}</td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building | buildingNumber:true}}</td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building.typ}}<br>{{itemBuilding.building.vyuziti}}</td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">
              <span class="comma-list">
                  <span *ngFor="let itemProtection of itemBuilding.building.buildingProtections">{{itemProtection.nazevOchrany}}</span>
              </span>
          </td>
          <td [attr.rowspan]="itemBuilding.building.parcels.length" *ngIf="priceColumnShown() && $index === 0" class="number">
            {{itemBuilding.building.buildingsPrice|number}}
          </td>
          <td [attr.rowspan]="itemBuilding.building.parcels.length" *ngIf="priceColumnShown() && $index === 0">
            {{itemBuilding.priceSource}}
          </td>
          <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length" class="no-padding cell-max-height">
            <cell-cases
              [casesCount]="itemBuilding.building.countCases"
              tooltipType="cases"
              [tooltipData]="{ list: { loading: false, list: itemBuilding.cases || [] }}"
            ></cell-cases>
          </td>
        </tr>
        <tr class="bordered-top error-message" *ngIf="!selection.isBuildingEnabled(itemBuilding)" [ngClass]="{'bordered-bottom': $indexBuilding < dataBuildingsFiltered.length - 1}">
          <td [attr.colspan]="8 + (priceColumnShown() ? 2 : 0)"><div *ngFor="let msg of selection.getBuildingDisabledMessage(itemBuilding)">{{msg}}</div></td>
        </tr>
        </tbody>
      </table>
    </div>
  </gmt-labeled-block>

  <gmt-labeled-block *ngIf="selectConstructionObjectRequired()" class="mb-20" label="{{ 'CONSTRUCTION_OBJECT_PLURAL_NOMINATIV' | wordtranslate: true }}">
    <div class="bordered">
      <table *ngIf="dataConstructionObjectsFiltered.length">
        <thead>
        <tr>
          <th class="min"><gmt-checkbox (click)="checkedAllConstructionObjects = !checkedAllConstructionObjects; selection.toggleAll(checkedAllConstructionObjects, checklistConstructionObject, dataConstructionObjectsFiltered, selection.toggleSelectionConstructionObject);" [gmtChecked]="checkedAllConstructionObjects"></gmt-checkbox></th>
          <th>{{ "CONSTRUCTION_OBJECT" | wordtranslate:true }}</th>
          <th class="comma-list">
            <span *ngIf="!checklistGroup.isEmpty()">Zábory</span>
            <span *ngIf="!checklistEasement.isEmpty()">VB</span>
          </th>
        </tr>
        </thead>
        <tbody *ngFor="let itemConstructionObject of dataConstructionObjectsFiltered; let $index=index" class="hover">
        <tr (click)="selection.toggleSelectionConstructionObject(itemConstructionObject)">
          <td [ngClass]="{cancelled: !itemConstructionObject.selected && checklistConstructionObject.isChecked(itemConstructionObject)}">
            <gmt-checkbox [gmtChecked]="checklistConstructionObject.isChecked(itemConstructionObject)" [gmtDisabled]="!selection.isConstructionObjectEnabled(itemConstructionObject)"></gmt-checkbox>
          </td>
          <td>
            <a (click)="$event.stopPropagation();" uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: itemConstructionObject.constructionObject.id }">
              {{itemConstructionObject.constructionObject|constructionObjectName}}
            </a>
          </td>
          <td>
            <div *ngFor="let occupation of itemConstructionObject.occupations">
              <b>{{occupation.zabcis}}</b>
              <div *ngIf="occupation.nabyvatel">Nabyvatel: {{occupation.nabyvatel}}</div>
              <div *ngIf="occupation.investor">Investor: {{occupation.investor}}</div>
              <div>{{occupation.zabtyptx}} ({{occupation.zabvym}})</div>
            </div>
            <div *ngFor="let easement of itemConstructionObject.easements">
              <b>{{easement.geometricPlan.cis}}</b>
              <div class="comma-list">
                <span *ngIf="easement.delka" class="unit">{{easement.delka}} m</span>
                <span *ngIf="easement.vymera" class="unit">{{easement.vymera}} m<sup>2</sup></span>
              </div>
            </div>
          </td>
        </tr>
        <tr class="bordered-top error-message" *ngIf="!selection.isConstructionObjectEnabled(itemConstructionObject)" [ngClass]="{'bordered-bottom': $index < dataConstructionObjectsFiltered.length - 1}">
          <td colspan="3"><div *ngFor="let msg of selection.getConstructionObjectDisabledMessage(itemConstructionObject)">{{msg}}</div></td>
        </tr>
        </tbody>
      </table>
      <div class="empty-info" *ngIf="!dataConstructionObjectsFiltered.length">
        Žádné {{ "CONSTRUCTION_OBJECT_PLURAL_NOMINATIV" | wordtranslate }}
      </div>
    </div>
  </gmt-labeled-block>
</div>
