import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';

import { SubjectModel } from '@app/common/models/subject.model';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'problematic-note',
  templateUrl: './problematic-note.component.html',
  styleUrls: ['./problematic-note.component.scss']
})
export class ProblematicNoteComponent implements OnInit {
  private API_ENDPOINT: string;
  public owner: SubjectModel;
  public payload: any = {
    text: undefined,
    timestamp: moment(),
  };

  constructor(
    private config: DialogConfig,
    private dialog: DialogRef,
    private restangular: Restangular,
  ) {
    this.remove = this.remove.bind(this);
    this.submit = this.submit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.owner = (<DialogConfigData>config.data).owner;
    this.API_ENDPOINT = `subjects/${this.owner.id}/problematic`;

    if ((<DialogConfigData>config.data).note && (<DialogConfigData>config.data).note.length > 0 && this.owner.subjectDetail && this.owner.subjectDetail.problematic) {
      this.payload = (<DialogConfigData>config.data).note[0];
    }
  }

  ngOnInit() { }

  onSaved(owner: SubjectModel) {
    if (this.config.callbacks) {
      this.config.callbacks.get(Callbacks.ProblematicNoteChanged)({ owner });
    }
  }

  remove() {
    return this.restangular
      .one(this.API_ENDPOINT)
      .customPUT({})
      .toPromise()
      .then((res: SubjectModel) => {
        this.dialog.close();
        this.onSaved(res);
      });
  }

  submit() {
    this.payload['timestamp'] = moment(this.payload['timestamp']).unix();
    return this.restangular
      .one(this.API_ENDPOINT)
      .customPUT(this.payload)
      .toPromise()
      .then((res: SubjectModel) => {
        this.dialog.close();
        this.onSaved(res);
      });
  }

  isValid() {
    return this.payload['timestamp'] && this.payload['text'];
  }
}
