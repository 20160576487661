import { Component, Inject, OnInit } from '@angular/core';
import { DocumentTypeModel } from '@app/sv/models/document-type.model';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loading = true;
  documentTypes: DocumentTypeModel[];

  constructor(
    private restangular: Restangular,
  ) { }

  async ngOnInit() {
    await this.loadDocumentTypes();
    this.loading = false;
  }

  private loadDocumentTypes(): Promise<any> {
    return this.restangular
      .all('document-types')
      .getList()
      .toPromise()
      .then(list => {
        this.documentTypes = _.sortBy(list.plain(), ['name']);
      });
  }
}
