import { Ng2StateDeclaration } from '@uirouter/angular';
import { ProjectInfoComponent } from './components/project-info/project-info.component';

export const projectInfoState: Ng2StateDeclaration = {
  name: 'symap.project.info',
  data: {
   title: '',
  },
  url: '/info/:tab',
  params: {
    tab: {
      type: 'string',
      value: null,
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
  views: {
   'content@symap.project': {
     component: ProjectInfoComponent,
   }
  }
};
