﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { ParentCaseOwnershipNamePipe } from '@app/ps/pipes/parent-case-ownership-name.pipe';
import { TitleDetailCreateCaseSelectParentService } from '@app/ps/services/title-detail-create-case-select-parent.service';

@Component({
  selector: 'title-detail-create-case-select-parent-ownership',
	templateUrl: './create-case-select-parent-ownership.component.html',
})
export class CreateCaseSelectParentOwnershipComponent implements OnInit {
  @Input()
  private data: any;
  @Input()
  private forwardStep: boolean = true;
  @Output()
  private submitCallback = new EventEmitter();
  public loading: boolean = false;

  public ownershipsWithParent: any[];

  constructor(
    public parentCaseOwnershipNamePipe: ParentCaseOwnershipNamePipe,
    private titleDetailCreateCaseSelectParentService: TitleDetailCreateCaseSelectParentService,
  ) {
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {
    this.checkNeedsParent();
  }

  submit() {
    if (this.needsParentSelection()) {
      return;
    }
    return this.submitCallback.emit({
      skipped: !this.ownershipsWithParent,
    });
  }

  checkNeedsParent() {
    if (this.data.obligation.type === 'InnominateSalesContractLiability') {
      return this.submit();
    }
    return this.titleDetailCreateCaseSelectParentService.checkOwnershipsWithParent(this.data.caseOwnerships).then((ownershipsWithParent) => {
      if (!this.forwardStep || this.titleDetailCreateCaseSelectParentService.needsParentSelection(ownershipsWithParent)) {
        this.ownershipsWithParent = ownershipsWithParent;
      } else {
        this.submit();
      }
    });
  }

  changedParent(newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
    const promise = this.titleDetailCreateCaseSelectParentService.changedParent(newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
    if (promise) {
      this.loading = true;
      promise.then(() => {
        this.loading = false;
      });
    }
  }

  needsParentSelection() {
   return this.loading || this.titleDetailCreateCaseSelectParentService.needsParentSelection(this.ownershipsWithParent);
  }
}
