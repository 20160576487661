import { Component } from '@angular/core';

import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';
import { ParcelModel } from '@app/common/models/parcel.model';

@Component({
  selector: 'tooltip-parcels-base',
  templateUrl: './tooltip-parcels-base.component.html',
  styleUrls: ['./tooltip-parcels-base.component.scss']
})
export class TooltipParcelsBaseComponent extends TooltipAbstractListComponent<ParcelModel> {
  protected resource = 'parcels';
}
