<h1 class="centered-text">Editace obecných údajů případu</h1>

<div style="position: relative">
  <div>
    <fieldset>
      <legend>Případ</legend>
      <div class="column-50">
        <gmt-input label="Číslo">
          <input type="text" [(ngModel)]="caseObject.mpp.number"/>
        </gmt-input>
        <ng-container *ngIf="!hideColumn('mandatary')">
          <gmt-input label="Řešitel případu">
            <gmt-select
              [restangularService]="restangularSettings"
              required="true"
              [data]="caseObject"
              field="mandatary"
              resource="users/list"
              [filter]="mandataryFilter"
              [itemPipe]="userNamePipe"
            ></gmt-select>
          </gmt-input>
        </ng-container>
        <ng-container *ngIf="!hideColumn('officer')">
          <gmt-input label="Referent případu">
            <gmt-select
              [restangularService]="restangularSettings"
              required="true"
              [data]="caseObject"
              field="officer"
              resource="users/list"
              [filter]="officerFilter"
              [itemPipe]="userNamePipe"
            ></gmt-select>
          </gmt-input>
        </ng-container>
      </div>
    </fieldset>

    <fieldset>
      <legend>Smluvní nájemce</legend>
      <div class="column-100">
        <div class="column-50">
          <div *ngFor="let tenant of caseObject.tenantNames; index as i; trackBy: trackByFn" class="input">
            <div style="width:40px; float:left">
              <a class="button red" (click)="onRemoveTenant(i)"><i class="fa fa-minus"></i></a>
            </div>
            <input type="text" [(ngModel)]="caseObject.tenantNames[i]" style="padding-right: 30px;">
          </div>
          <div class="input column-100">
            <a class="button" (click)="onAddTenant()">
              <i class="fa fa-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="!caseObject.obligation.computePrice">
      <legend>Finanční plnění</legend>
      <div class="column-50">
        <gmt-input label="Finanční plnění za smlouvu">
          <input type="text" [(ngModel)]="caseObject.fulfillmentPrice" gmtInputNumber/> Kč
        </gmt-input>
      </div>
    </fieldset>

    <distribution-system-parts
      [options]="distributionSystemPartsOptions"
      [parts]="caseObject.distributionSystemParts"
      *ngIf="distributionSystemPartsEnabled"
      class="mt-20 block"
    ></distribution-system-parts>

    <fieldset *ngIf="caseObject.obligation.type === 'RentalContractShorterThan1YearLiability' || caseObject.obligation.type === 'RentalContractLongerThan1YearLiability' || caseObject.obligation.type === 'RentalContractCommonLiability'">
      <legend>Data</legend>
      <div class="column-50">
        <gmt-input label="Datum předpokládaného zahájení nájmu">
          <input pick-a-date type="text" [(ngModel)]="caseObject.rentalStartDate"/>
        </gmt-input>

        <gmt-input label="Datum předání staveniště">
          <input pick-a-date type="text" [(ngModel)]="caseObject.siteDeliveryDate"/>
        </gmt-input>
      </div>
    </fieldset>

    <fieldset *ngIf="displayFieldsEnabled">
      <legend>Data</legend>
      <div class="column-50" *ngIf="displayFields.createdDate">
        <gmt-input label="Datum založení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.createdDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.sentInvestorDate">
        <gmt-input label="Datum odeslání investorovi">
          <input type="text" pick-a-date [(ngModel)]="caseObject.sentInvestorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.approvedInvestorDate">
        <gmt-input label="Datum schválení investorem">
          <input type="text" pick-a-date [(ngModel)]="caseObject.approvedInvestorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.declinedInvestorDate">
        <gmt-input label="Datum zamítnutí investorem">
          <input type="text" pick-a-date [(ngModel)]="caseObject.declinedInvestorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.signedInvestorDate">
        <gmt-input label="Datum podpisu investorem">
          <input type="text" pick-a-date [(ngModel)]="caseObject.signedInvestorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.sentAdministratorDate">
        <gmt-input label="Datum odeslání oprávněnému">
          <input type="text" pick-a-date [(ngModel)]="caseObject.sentAdministratorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.signedAdministratorDate">
        <gmt-input label="Datum podpisu oprávněným">
          <input type="text" pick-a-date [(ngModel)]="caseObject.signedAdministratorDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.sentAllOwnersDate">
        <gmt-input label="Datum odeslání vlastníkovi">
          <input type="text" pick-a-date [(ngModel)]="caseObject.sentAllOwnersDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProposalDate">
        <gmt-input label="Datum žádosti">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProposalDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAssessmentInteruptionDate">
        <gmt-input label="Datum přerušení posouzení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAssessmentInteruptionDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAssessmentInteruptionDeadlineDate">
        <gmt-input label="Datum lhůty přerušení posouzení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAssessmentInteruptionDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAssessmentInteruptionReason">
        <label>Důvod přerušení posouzení</label>
        <textarea [(ngModel)]="caseObject.expropriationAssessmentInteruptionReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingStartedDate">
        <gmt-input label="Datum zahájení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProceedingStartedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationInterimDecisionRequestDate">
        <gmt-input label="Datum žádosti o mezitimní rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationInterimDecisionRequestDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationInterimDecisionDate">
        <gmt-input label="Datum mezitimního rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationInterimDecisionDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationStartDeadlineDate">
        <gmt-input label="Datum lhůty pro zahájení uskutečňování účelu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationStartDeadlineDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationAdministrativeAppealDate">
        <gmt-input label="Datum podání správní žaloby">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAdministrativeAppealDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationAdministrativeAppealRejectedDate">
        <gmt-input label="Datum zamítnutí správní žaloby">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAdministrativeAppealRejectedDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationAdministrativeAppealAcceptedDate">
        <gmt-input label="Datum potvrzení správní žaloby">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAdministrativeAppealAcceptedDate"/>
        </gmt-input>
      </div>
      <div class="column-50" *ngIf="displayFields.expropriationCompensationPaymentDate">
        <gmt-input label="Datum úhrady zálohy na náhradu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationCompensationPaymentDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionDate">
        <gmt-input label="Datum přerušení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProceedingInteruptionDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionDeadlineDate">
        <gmt-input label="Datum lhůty přerušení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProceedingInteruptionDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionReason">
        <label>Důvod přerušení řízení</label>
        <textarea [(ngModel)]="caseObject.expropriationProceedingInteruptionReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionExtendedDate">
        <gmt-input label="Datum prodloužení přerušení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProceedingInteruptionExtendedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionExtendedDeadlineDate">
        <gmt-input label="Datum prodloužené lhůty přerušení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationProceedingInteruptionExtendedDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationProceedingInteruptionExtendedReason">
        <label>Důvod prodloužení přerušení řízení</label>
        <textarea [(ngModel)]="caseObject.expropriationProceedingInteruptionExtendedReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationStoppedBySide">
        <gmt-input label="Řízení zastavil">
          <input type="text" [(ngModel)]="caseObject.expropriationStoppedBySide"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationStoppedDate">
        <gmt-input label="Datum zastavení řízení">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationStoppedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationStoppedReason">
        <label>Důvod zastavení řízení</label>
        <textarea [(ngModel)]="caseObject.expropriationStoppedReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationCalledHearingCalledDate">
        <gmt-input label="Datum doručení rozhodnutí o svolání ústního jednání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationCalledHearingCalledDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationCalledHearingDate">
        <gmt-input label="Datum ústního jednání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationCalledHearingDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationHearingRepetitionCalledDate">
        <gmt-input label="Datum doručení rozhodnutí o svolání opakovaného ústního jednání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationHearingRepetitionCalledDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationHearingRepetitionDate">
        <gmt-input label="Datum opakovaného ústního jednání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationHearingRepetitionDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationHearingRepetitionReason">
        <label>Důvod opakování ústního jednání</label>
        <textarea [(ngModel)]="caseObject.expropriationHearingRepetitionReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationResultDate">
        <gmt-input label="Datum rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationResultDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationResultDeliveryDate">
        <gmt-input label="Datum doručení rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationResultDeliveryDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealDeadlineDate">
        <gmt-input label="Datum lhůty pro odvolání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAppealDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationResultRealizationDeadlineDate">
        <gmt-input label="Lhůta pro zahájení uskutečňování účelu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationResultRealizationDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAmount">
        <gmt-input label="Cena vyvlastnění">
          <input type="text" [(ngModel)]="caseObject.expropriationAmount"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAmountSource">
        <gmt-input label="Původ ceny vyvlastnění">
          <input type="text" [(ngModel)]="caseObject.expropriationAmountSource"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealReason">
        <label>Důvod odvolání</label>
        <textarea [(ngModel)]="caseObject.expropriationAppealReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealDate">
        <gmt-input label="Datum odvolání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAppealDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealSide">
        <gmt-input label=""></gmt-input>
        <label>Odvolávající se strana</label>
        <gmt-switch (select)="onExpropriationAppealSideSelect($event)" [options]="expropriationAppealSides" [selected]="caseObject.expropriationAppealSide"></gmt-switch>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealResultDate">
        <gmt-input label="Datum výsledku odvolání">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationAppealResultDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealResultReason">
        <label>Odůvodnění výsledku odvolání</label>
        <textarea [(ngModel)]="caseObject.expropriationAppealResultReason"></textarea>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationAppealResultArbitrator">
        <gmt-input label="O výsledku odvolání rozhodl">
          <input type="text" [(ngModel)]="caseObject.expropriationAppealResultArbitrator"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.expropriationEnforceableDate">
        <gmt-input label="Datum právní moci rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.expropriationEnforceableDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.bonusDate">
        <gmt-input label="Datum pro nárok na akcelerační příplatek">
          <input type="text" pick-a-date [(ngModel)]="caseObject.bonusDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.bonusPossible">
        <label (click)="caseObject.bonusPossible = !caseObject.bonusPossible">Možný nárok na akcelerační příplatek</label>
        <div class="info">
          <gmt-checkbox [gmtChecked]="caseObject.bonusPossible" (click)="caseObject.bonusPossible = !caseObject.bonusPossible"></gmt-checkbox>
        </div>
      </div>

      <div class="column-50" *ngIf="displayFields.bonusGranted">
        <label (click)="caseObject.bonusGranted = !caseObject.bonusGranted">Přiznán akcelerační příplatek</label>
        <div class="info">
          <gmt-checkbox [gmtChecked]="caseObject.bonusGranted" (click)="caseObject.bonusGranted = !caseObject.bonusGranted"></gmt-checkbox>
        </div>
      </div>

      <div class="column-50" *ngIf="displayFields.deliveryDeadlineDate">
        <gmt-input label="Datum termínu pro doručení vlastníkovi">
          <input type="text" pick-a-date [(ngModel)]="caseObject.deliveryDeadlineDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.validityDate">
        <gmt-input label="Datum platnosti návrhu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.validityDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.signedSentOwnersDate">
        <gmt-input label="Datum odeslání podepsané smlouvy vlastníkům">
          <input type="text" pick-a-date [(ngModel)]="caseObject.signedSentOwnersDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.buildingAuthorityApprovalRequestDate">
        <gmt-input label="Datum žádosti o souhlas SÚ">
          <input type="text" pick-a-date [(ngModel)]="caseObject.buildingAuthorityApprovalRequestDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.buildingAuthorityApprovedDate">
        <gmt-input label="Datum udělení souhlasu SÚ">
          <input type="text" pick-a-date [(ngModel)]="caseObject.buildingAuthorityApprovedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.buildingAuthorityRejectedDate">
        <gmt-input label="Datum nesouhlasu SÚ">
          <input type="text" pick-a-date [(ngModel)]="caseObject.buildingAuthorityRejectedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.entryProposalDate">
        <gmt-input label="Datum podání návrhu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.entryProposalDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.cadastreEntryDate">
        <gmt-input label="Datum rozhodnutí KN">
          <input type="text" pick-a-date [(ngModel)]="caseObject.cadastreEntryDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.legalEffectsDate">
        <gmt-input label="Datum právních účinků">
          <input type="text" pick-a-date [(ngModel)]="caseObject.legalEffectsDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.cadastreEntryDeclinedDate">
        <gmt-input label="Datum zamítnutí vkladu KN">
          <input type="text" pick-a-date [(ngModel)]="caseObject.cadastreEntryDeclinedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.cadastreEntryStoppedDate">
        <gmt-input label="Datum zastavení řízení vkladu KN">
          <input type="text" pick-a-date [(ngModel)]="caseObject.cadastreEntryStoppedDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.cadastreEntryDeliveryDate">
        <gmt-input label="Datum doručení rozhodnutí">
          <input type="text" pick-a-date [(ngModel)]="caseObject.cadastreEntryDeliveryDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.cadastreEntryNumber">
        <gmt-input label="Číslo jednací rozhodnutí KN">
          <input type="text" [(ngModel)]="caseObject.cadastreEntryNumber"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.dueDate">
        <gmt-input label="Datum splatnosti">
          <input type="text" pick-a-date [(ngModel)]="caseObject.dueDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.paymentInstructionDate">
        <gmt-input label="Datum žádosti o úhradu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.paymentInstructionDate"/>
        </gmt-input>
      </div>

      <div class="column-50" *ngIf="displayFields.paymentOrderDate">
        <gmt-input label="Datum platebního příkazu">
          <input type="text" pick-a-date [(ngModel)]="caseObject.paymentOrderDate"/>
        </gmt-input>
      </div>
    </fieldset>

    <form-contracts-register
      *ngIf="displayFields.contractRegisterPublicationDate"
      [isPublished]="!caseObject.contractRegisterPublicationException"
      [data]="caseObject"
      (exceptionSelected)="onPublicationExceptionSelect($event)"
      legend="Zveřejnění v registru smluv"
    ></form-contracts-register>

    <div class="actions">
      <gmt-button [clickAction]="onUpdate">Uložit základní údaje případu</gmt-button>
    </div>
  </div>

  <help [helpId]="helpIds.CASES_DETAIL_UPDATE" class="cm-help-modal"></help>
</div>
