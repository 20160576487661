import { Component, Inject, OnInit } from '@angular/core';
import { BulkPriceStepComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-step/bulk-price-step.component';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'bulk-price-confirm',
  templateUrl: './bulk-price-confirm.component.html',
})
export class BulkPriceConfirmComponent extends BulkPriceStepComponent implements OnInit {

  loading = true;
  rentalPriceUnitTxt = '';
  rentalPriceTypeTxt = '';

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.rentalPriceUnitTxt = this.priceUnit.find(i => i.id === this.data.rentalPrice.rentalPriceUnit).value + '';
    this.rentalPriceTypeTxt = this.priceType.find(i => i.id === this.data.rentalPrice.rentalPriceType).value + '';
  }

  isValid(): boolean {
    return true;
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
