﻿import { Injectable, Inject } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';
import {HttpService} from "@app/common/services/http.service";

@Injectable({ providedIn: 'root' })
export class ProjectStatusService {
  private listeners: Function[] = [];
  private timer;
  private lastChange;
  private directDownloadIds = [];

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private httpService: HttpService,
  ) {
    this.makeCall = this.makeCall.bind(this);
  }

  init() {
    this.destroy();
    this.makeCall();
  }

  destroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.lastChange = undefined;
  }

  registerListener(listener: Function) {
    this.listeners.push(listener);
  }

  download(processIds) {
    for (const processId of processIds) {
      const url = this.authService.getActiveApplicationRestUrl() + '/processes/' + processId + '/data?t=' + this.authService.getToken();
      const link = document.createElement('a');
      link.setAttribute('download', null);
      link.setAttribute('href', url);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      window.setTimeout(() => link.remove(), 5000);
    }
  }

  remove(process) {
    _.pull(this.directDownloadIds, process.id);

    return this.restangular
      .one('processes', process.id)
      .remove()
      .toPromise()
      .then(() => this.refresh());
  }

  refresh(process?) {
    if (process) {
      this.directDownloadIds.push(process.id);
    }
    this.makeCall(false);
  }

  private makeCall(continues = true) {
    return this.httpService.call({
      path: 'processes/status',
      method: 'GET',
      params: { lastChange: this.lastChange },
    })
    .then(data => {
      if (this.lastChange === undefined || data.lastChange !== this.lastChange) {
        this.listeners.forEach(l => l(data));

        if (this.directDownloadIds.length) {
          const downloadIds = data.processes
            .filter(process => process.status === 'F' && process.hasData && _.remove(this.directDownloadIds, (item) => item === process.id).length)
            .map(process => process.id);

          this.download(downloadIds);
        }
      }
      this.lastChange = data.lastChange;

      if (continues) {
        this.timer = window.setTimeout(this.makeCall, 5000);
      }
    })
    .catch((error) => {
      // Výpadek nebo chyba BE
      if (continues) {
        this.timer = window.setTimeout(this.makeCall, 10000);
      }
    });
  }
}
