import { Inject, Injectable } from '@angular/core';
import { TicketModel } from '@app/sv/models/ticket.model';
import { ticketStatusOptions } from '@app/sv/enums/ticket-status.enum';
import { AuthService } from '@app/common/services/auth.service';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { SampleModel } from '@app/sv/models/sample.model';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { NoteModel } from '@app/common/models/note.model';
import { TicketStatusNamePipe } from '@app/sv/pipes/ticket-status-name.pipe';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import {FileUtils} from "@app/common/utils/file.utils";

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
    private ticketStatusNamePipe: TicketStatusNamePipe,
  ) {
  }

  getById(ticketId): Promise<TicketModel> {
    return this.restangular.one('tickets', ticketId)
      .get()
      .toPromise()
      .then(ticket => {
        return ticket.plain();
      });
  }

  create(ticket: TicketModel, samples: UploadFileExtended[], revisionSamples: UploadFileExtended[]): Promise<TicketModel> {
    const restBaseUrl = this.authService.getActiveApplicationRestUrl();
    const sampleAttachmentType = DocumentTypeModel.createById('SAMPLE').id;
    const revisionSampleAttachmentType = DocumentTypeModel.createById('REVISION_SAMPLE').id;

    const sendData: any = {
      files: [],
      filename: [],
      ticket: ticket,
      attachmentType: [],
      baseName: [],
    };

    samples.forEach(file => {
      sendData.files.push(file.fileEntry);
      sendData.filename.push(file.fileEntry.name);
      sendData.attachmentType.push(sampleAttachmentType);
      sendData.baseName.push(file.fileEntry.name);
    });

    revisionSamples.forEach(file => {
      sendData.files.push(file.fileEntry);
      sendData.filename.push(file.fileEntry.name);
      sendData.attachmentType.push(revisionSampleAttachmentType);
      sendData.baseName.push(file.fileEntry.name);
    });

    return this.uploadService.upload({
      url: restBaseUrl + '/tickets/create',
      formData: FileUtils.objectToJsonMultipartFormData(sendData),
      headers: { Authorization: this.authService.getToken() }
    });
  }

  createBySample(ticket: TicketModel, sample: SampleModel, samples: UploadFileExtended[], revisionSamples: UploadFileExtended[]): Promise<TicketModel> {
    ticket.documentType = sample.documentType;
    ticket.section = sample.section;
    ticket.organizationalUnitName = sample.organizationalUnitName;
    ticket.organizationalUnitId = sample.organizationalUnitId;
    ticket.organizationalUnitCode = sample.organizationalUnitCode;
    ticket.assignable = sample.assignable;
    ticket.actualizedSample = sample;

    return this.create(ticket, samples, revisionSamples);
  }

  update(ticket: TicketModel, templates: UploadFileExtended[], samples: UploadFileExtended[], revisionSamples: UploadFileExtended[], note?: NoteModel): Promise<TicketModel> {
    return this.createDocuments(ticket, templates, 'TEMPLATE')
      .then(() => this.createDocuments(ticket, samples, 'SAMPLE'))
      .then(() => this.createDocuments(ticket, revisionSamples, 'REVISION_SAMPLE'))
      .then(() => this.createNotes(ticket, (note ? [note] : [])))
      .then(() => {
        return this.restangular
          .one('tickets', ticket.id)
          .customPUT(ticket, '', { loadCollections: [] })
          .toPromise();
      })
      .then(ticket => {
        return ticket.plain();
      });
  }

  updateSample(ticket: TicketModel, file: UploadFileExtended, note: NoteModel, reason: string): Promise<any> {
    return this.createDocuments(ticket, [file], 'SAMPLE', reason)
      .then(() => this.createNotes(ticket, [note]));
  }

  getStatusEnumAction(value: string): string {
    const option = ticketStatusOptions.find(option => option.id === value);
    const action = (option.action ? option.action : option.name);
    return this.ticketStatusNamePipe.translate(action);
  }

  createNotes(ticket: TicketModel, notes: NoteModel[] = []): Promise<any> {
    const promises = notes.map(note => {
      return this.restangular
        .all('notes/create')
        .customPOST({ ...note, ticket: ticket })
        .toPromise();
    });
    return Promise.all(promises);
  }

  createDocuments(ticket: TicketModel, files: UploadFileExtended[], type: string, note?: string): Promise<any> {
    const restBaseUrl = this.authService.getActiveApplicationRestUrl();
    const attachmentType = DocumentTypeModel.createById(type).id;
    const promises = files.map(file => {
      let filename = file.fileEntry.name;

      if (ticket.sampleNumber) {
        filename = ticket.sampleNumber + (type === 'TEMPLATE' ? '_s' : '') + '.' + file.extension.toLowerCase();
      }

      const sendData: any = {
        file: file.fileEntry,
        filename: file.fileEntry.name,
        ticketId: ticket.id,
        attachmentType: attachmentType,
        baseName: filename
      };

      if (note) {
        sendData.note = note;
      }

      return this.uploadService.upload({
        url: restBaseUrl + '/attachments',
        data: sendData,
        headers: { Authorization: this.authService.getToken() }
      });
    });
    return Promise.all(promises);
  }
}
