import { Component, Inject, OnInit } from '@angular/core';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { GeometricPlanRequestTypeEnum, geometricPlanRequestTypeOptions } from '@app/ps/enums/geometric-plan-request-type.enum';
import { SelectItem } from '@app/common/components/select/select.component';
import { StateService } from '@uirouter/angular';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { KnGpNamePipe } from '@app/ps/pipes/kn-gp-name.pipe';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { KnGpModel } from '@app/common/models/kn-gp.model';
import * as _ from 'lodash';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'geometric-plan-request-create-from-existing',
  templateUrl: './geometric-plan-request-create-from-existing.component.html',
  styleUrls: ['./geometric-plan-request-create-from-existing.component.scss']
})
export class GeometricPlanRequestCreateFromExistingComponent implements OnInit {

  static readonly MSG_GP_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
  static readonly MSG_GP_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';

  static readonly MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
  static readonly MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';

  static readonly MSG_GP_DOC_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';

  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  geometricPlanRequest = GeometricPlanRequestModel.create();
  typeOptions: SelectItem[] = geometricPlanRequestTypeOptions;
  similarGeometricPlanRequestExists = false;
  emptyKnGp = false;
  type: SelectItem = this.typeOptions[0];
  typeEnum = GeometricPlanRequestTypeEnum;

  gpFiles: UploadFileExtended[] = [];
  gpVfkFiles: UploadFileExtended[] = [];
  gpDocFiles: UploadFileExtended[] = [];
  gpFilesValid = false;
  gpVfkFilesValid = false;
  gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_DEFAULT;
  gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_DEFAULT;
  gpDocFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_DOC_FILES_DEFAULT;

  constructionObjectFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  constructor(
    public requestService: GeometricPlanRequestService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    private stateService: StateService,
    public knGpNamePipe: KnGpNamePipe,
    private restangular: Restangular,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
  }

  isValid(): boolean {
    return !!(
      this.type
      && !this.emptyKnGp
      && this.geometricPlanRequest.knGp
      && this.gpFiles.length
      && this.gpVfkFiles.length
    );
  }

  onKnGpChange(knGp: KnGpModel) {
    this.similarGeometricPlanRequestExists = false;
    this.emptyKnGp = false;

    if (!this.geometricPlanRequest.knGp) {
      return;
    }

    let parcels = [];
    this.geometricPlanRequest.knGp.knBudoucs.forEach(knBudouc => {
      parcels = parcels.concat(knBudouc.parcels);
    });

    this.geometricPlanRequest.knGp.easements.forEach(easement => {
      parcels.push(easement.parcel);
    });

    parcels = _.uniqBy(parcels, 'id');

    if (!parcels.length) {
      this.emptyKnGp = true;
      return;
    }

    const parcelIds = parcels.map(p => p.id);
    const filter = { filter: { filters: { parcelId: parcelIds }}};

    this.restangular
      .all('geometric-plan-requests')
      .customPOST(filter)
      .toPromise()
      .then(data => {
        const requests = data.plain().filter(or => or.status !== OpinionRequestStatusEnum.DELETED);
        this.similarGeometricPlanRequestExists = requests.length > 0;
      });
  }

  onGpFilesChange(files: UploadFileExtended[]) {
    this.gpFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'pdf');

    if (this.gpFiles.length === 0) {
      this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_EXTENSION;
    } else if (this.gpFiles.length > 1) {
      this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpFilesMsg = '';
    }

    this.gpFilesValid = (extensionValid && this.gpFiles.length === 1);
  }

  onGpVfkFilesChange(files: UploadFileExtended[]) {
    this.gpVfkFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'vfk');

    if (this.gpVfkFiles.length === 0) {
      this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_EXTENSION;
    } else if (this.gpVfkFiles.length > 1) {
      this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpVfkFilesMsg = '';
    }

    this.gpVfkFilesValid = (extensionValid && this.gpVfkFiles.length === 1);
  }

  onGpDocFilesChange(files: UploadFileExtended[]) {
    this.gpDocFiles = files;
  }

  onSubmit(): Promise<any> {
    this.geometricPlanRequest.type = this.type.id as string;
    return this.requestService
      .createFromExisting(this.geometricPlanRequest, this.gpFiles, this.gpVfkFiles, this.gpDocFiles)
      .then(geometricPlanRequest => {
        this.stateService.go(
          'symap.project.geometricPlanRequests.detail',
          { id: geometricPlanRequest.id, tab: 'parcels-budouc' });
      });
  }
}
