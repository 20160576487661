<h1>{{ title }}</h1>
<fieldset>
  <div>
    <gmt-input [required]="required" [label]="label">
      <textarea [(ngModel)]="text"></textarea>
    </gmt-input>
  </div>

  <div *ngIf="additionalText">{{ additionalText }}</div>

  <div class="text-center pt-20">
    <gmt-button [clickAction]="onUpdate" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</fieldset>
