import {Component, Inject, OnInit} from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { NoteModel } from '@app/common/models/note.model';

@Component({
  selector: 'kn-wsdp-status-modal',
  templateUrl: './kn-wsdp-status-modal.component.html',
})
export class KnWsdpStatusModalComponent implements OnInit {

  // model
  request;

  constructor(
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef
  ) {
    this.onClose = this.onClose.bind(this);
  }

  ngOnInit() {
    this.request = (<DialogConfigData>this.dialogConfig.data).request;
  }

  onClose() {
    this.dialogComponent.close(false);
  }
}
