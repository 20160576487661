import { InjectionToken } from '@angular/core';

export const RESTANGULAR_PROMAP_PROXY = new InjectionToken<any>('RestangularPromapProxy');

export function RestangularPromapProxyFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlPkProxy);
  });
  return r;
}

