import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
  HttpClient,
  HttpErrorResponse, HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { FileUtils } from '@app/common/utils/file.utils';
import {HttpProgressEvent} from '@angular/common/http/src/response';
import { GmtHttpError, HttpService } from '@app/common/services/http.service';

export interface UploadOptions {
  url: string;
  data?: any;
  formData?: FormData;
  headers: any;
  arrayKey?: any;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

@Injectable({providedIn: 'root'})
export class UploadService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Returns promise with extension methods success, error, progress
   */
  upload(options: UploadOptions): any {
    options.data = _.cloneDeep(options.data);
    HttpService.fixData(options.data);

    const request = new HttpRequest(
      'POST',
      options.url,
      options.formData || FileUtils.objectToFormData(options.data),
      {
        headers: options.headers ? new HttpHeaders(options.headers) : undefined,
        reportProgress: true,
        responseType: options.responseType
      });

    const promise: any = new Promise<any>((resolve, reject) => {
      this.http.request(request).subscribe(
        (response: HttpEvent<any>) => {
          if (UploadService.isProgressEvent(response) && promise.progressFn) {
            promise.progressFn(response);
          } else if (response instanceof HttpResponse) {
            resolve(response.body);
          }
        },
        (error: HttpErrorResponse) => {
          const gmtError: GmtHttpError = { status: error.status, data: error.error };
          reject(gmtError);
        }
      );
    });

    promise.success = (successFn: (data?: any) => void) => {
      promise.then(successFn);
      return promise;
    };

    promise.error = (errorFn: (err?: GmtHttpError) => void) => {
      promise.then(null, errorFn);
      return promise;
    };

    promise.progress = (progressFn: (event?: HttpProgressEvent) => void) => {
      promise.progressFn = progressFn;
      return promise;
    };

    return promise;
  }

  private static isProgressEvent(response: any) {
    return response.type === HttpEventType.UploadProgress && response.loaded !== undefined;
  }
}
