import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { Customer } from '@app/models/customer';


@Pipe({ name: 'customerName' })
@Injectable({
  providedIn: 'root'
})
export class CustomerNamePipe implements PipeTransform {
  transform(customer: Customer) {
    if (!customer) {
      return '';
    }

    return customer.companyName;
  }
}
