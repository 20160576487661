import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  OnInit
} from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ObligationModel } from '@app/ps/models/obligation.model';
import * as _ from 'lodash';
import { DocumentSettingsComponent } from '@app/ps/project-settings/components/document-settings/document-settings.component';
import { HelpService } from '@app/common/services/help.service';
import { DocumentModel } from '@app/common/models/document.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'obligation-flow-settings',
  templateUrl: './obligation-flow-settings.component.html',
  styleUrls: ['./obligation-flow-settings.component.scss'],
})
export class ObligationFlowSettingsComponent implements OnInit, AfterContentChecked {

  obligationId: number;
  obligation: ObligationModel;
  data: any;
  editable = false;
  workflowChanged = false;
  loading = true;
  caseStatusOptions: any[];
  flowData: any;
  flowDataSort: any[];
  documentTemplateNames: any;
  documentTemplatesOptions: any[];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private elementRef: ElementRef,
    private dialogService: DialogService,
    private config: DialogConfig,
    private dialog: DialogRef,
    private cdr: ChangeDetectorRef,
  ) {
    this.obligationId = (<DialogConfigData>config.data).obligationId;
    this.selectedEnterStatus = this.selectedEnterStatus.bind(this);
    this.selectedLeaveStatus = this.selectedLeaveStatus.bind(this);
    this.selectedDocument = this.selectedDocument.bind(this);
    this.removeEnterStatus = this.removeEnterStatus.bind(this);
    this.removeLeaveStatus = this.removeLeaveStatus.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
    this.removeFlowStatus = this.removeFlowStatus.bind(this);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
    this.reloadOptionsRegisterEnter = this.reloadOptionsRegisterEnter.bind(this);
    this.reloadOptionsRegisterLeave = this.reloadOptionsRegisterLeave.bind(this);
    this.reloadOptionsRegisterDocument = this.reloadOptionsRegisterDocument.bind(this);
    this.editDocument = this.editDocument.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.goToStatusAnchor = this.goToStatusAnchor.bind(this);
    this.toggleAllowCadastreEntryProposal = this.toggleAllowCadastreEntryProposal.bind(this);
  }

  async ngOnInit() {
    await this.restangular.all('templates').one('obligations', this.obligationId).all('types-allowed').getList()
      .toPromise()
      .then((data) => {
        this.documentTemplatesOptions = _.sortBy(data, ['name']);
        this.documentTemplateNames = {};
        data.forEach((item) => {
          this.documentTemplateNames[item.key] = item.name;
        });
      });

    this.restangular.one('flow', this.obligationId).get().toPromise().then(data => {
      this.processData(data.plain());
      this.loading = false;
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  selectedEnterStatus(caseStatus, enterStatus, data) {
    this.workflowChanged = true;
    const flowStatusExt = this.flowData[caseStatus];
    flowStatusExt.flowStatus.enterStatuses.push(enterStatus.key);
    let foundFlowStatus = this.flowData[enterStatus.key];
    if (!foundFlowStatus) {
      foundFlowStatus = enterStatus;
      this.flowDataSort.push(enterStatus.key);
      this.flowData[enterStatus.key] = foundFlowStatus;
    }
    foundFlowStatus.leaveStatuses.push(caseStatus);
    data.selected = null;
    flowStatusExt.reloadOptionsEnter();
    if (foundFlowStatus.reloadOptionsLeave) {
      foundFlowStatus.reloadOptionsLeave();
    }
  }

  selectedLeaveStatus(caseStatus, leaveStatus, data) {
    this.workflowChanged = true;
    const flowStatusExt = this.flowData[caseStatus];
    flowStatusExt.leaveStatuses.push(leaveStatus.key);
    let foundFlowStatus = this.flowData[leaveStatus.key];
    if (!foundFlowStatus) {
      foundFlowStatus = leaveStatus;
      this.flowDataSort.push(leaveStatus.key);
      this.flowData[leaveStatus.key] = foundFlowStatus;
    }
    foundFlowStatus.flowStatus.enterStatuses.push(caseStatus);
    data.selected = null;
    flowStatusExt.reloadOptionsLeave();
    if (foundFlowStatus.reloadOptionsEnter) {
      foundFlowStatus.reloadOptionsEnter();
    }
  }

  selectedDocument(caseStatus, document, data) {
    const flowStatusExt = this.flowData[caseStatus];
    flowStatusExt.flowStatus.documents.push({
      key: document.key
    });
    data.selected = null;
    flowStatusExt.reloadOptionsDocument();
  }

  removeEnterStatus(enterStatus, caseStatus) {
    this.workflowChanged = true;
    let flowStatusExt = this.flowData[caseStatus];
    _.pull(flowStatusExt.flowStatus.enterStatuses, enterStatus);
    let foundFlowStatus = this.flowData[enterStatus];
    if (foundFlowStatus) {
      _.pull(foundFlowStatus.leaveStatuses, caseStatus);
      if (!foundFlowStatus.leaveStatuses.length && !foundFlowStatus.flowStatus.enterStatuses.length && enterStatus !== 'Created') {
        _.pull(this.flowDataSort, enterStatus);
        delete this.flowData[enterStatus];
        foundFlowStatus = null;
      }
    }
    if (!flowStatusExt.leaveStatuses.length && !flowStatusExt.flowStatus.enterStatuses.length && caseStatus !== 'Created') {
      _.pull(this.flowDataSort, caseStatus);
      delete this.flowData[caseStatus];
      flowStatusExt = null;
    }
    if (flowStatusExt) {
      flowStatusExt.reloadOptionsEnter();
    }
    if (foundFlowStatus) {
      foundFlowStatus.reloadOptionsLeave();
    }
  }

  removeLeaveStatus(leaveStatus, caseStatus) {
    this.workflowChanged = true;
    let flowStatusExt = this.flowData[caseStatus];
    _.pull(flowStatusExt.leaveStatuses, leaveStatus);
    let foundFlowStatus = this.flowData[leaveStatus];
    if (foundFlowStatus) {
      _.pull(foundFlowStatus.flowStatus.enterStatuses, caseStatus);
      if (!foundFlowStatus.leaveStatuses.length && !foundFlowStatus.flowStatus.enterStatuses.length && leaveStatus !== 'Created') {
        _.pull(this.flowDataSort, leaveStatus);
        delete this.flowData[leaveStatus];
        foundFlowStatus = null;
      }
    }
    if (!flowStatusExt.leaveStatuses.length && !flowStatusExt.flowStatus.enterStatuses.length && caseStatus !== 'Created') {
      _.pull(this.flowDataSort, caseStatus);
      delete this.flowData[caseStatus];
      flowStatusExt = null;
    }
    if (flowStatusExt) {
      flowStatusExt.reloadOptionsLeave();
    }
    if (foundFlowStatus) {
      foundFlowStatus.reloadOptionsEnter();
    }
  }

  removeDocument(document, caseStatus) {
    const flowStatusExt = this.flowData[caseStatus];
    _.pull(flowStatusExt.flowStatus.documents, document);
    flowStatusExt.reloadOptionsDocument();
  }

  removeFlowStatus(caseStatus) {
    this.workflowChanged = true;
    _.pull(this.flowDataSort, caseStatus);
    this.flowData[caseStatus].flowStatus.enterStatuses = [];
    this.flowData[caseStatus].leaveStatuses = [];
    delete this.flowData[caseStatus];

    _.forEach(this.flowData, (flowStatus2, caseStatus2) => {
      _.pull(flowStatus2.leaveStatuses, caseStatus);
      _.pull(flowStatus2.flowStatus.enterStatuses, caseStatus);
      if (!flowStatus2.leaveStatuses.length && !flowStatus2.flowStatus.enterStatuses.length && caseStatus2 !== 'Created') {
        _.pull(this.flowDataSort, caseStatus2);
        delete this.flowData[caseStatus2];
      }
    });
  }

  up(caseStatus) {
    const index = _.indexOf(this.flowDataSort, caseStatus);
    if (index > 0) {
      _.pull(this.flowDataSort, caseStatus);
      this.flowDataSort.splice(index - 1, 0, caseStatus);
    }
  }

  down(caseStatus) {
    const index = _.indexOf(this.flowDataSort, caseStatus);
    if (index < this.flowDataSort.length - 1) {
      _.pull(this.flowDataSort, caseStatus);
      this.flowDataSort.splice(index + 1, 0, caseStatus);
    }
  }

  reloadOptionsRegisterEnter(flowStatusExt) {
    if (!flowStatusExt.reloadOptionsRegisterEnter) {
      flowStatusExt.reloadOptionsRegisterEnter = (reloadOptions) => {
        flowStatusExt.reloadOptionsEnter = reloadOptions;
      };
      flowStatusExt.enterFilter = (option) => {
        return !_.includes(flowStatusExt.flowStatus.enterStatuses, option.key) && flowStatusExt.key !== option.key;
      };
    }
    return flowStatusExt.reloadOptionsRegisterEnter;
  }

  reloadOptionsRegisterLeave(flowStatus) {
    if (!flowStatus.reloadOptionsRegisterLeave) {
      flowStatus.reloadOptionsRegisterLeave = (reloadOptions) => {
        flowStatus.reloadOptionsLeave = reloadOptions;
      };
      flowStatus.leaveFilter = (option) => {
        return !_.includes(flowStatus.leaveStatuses, option.key) && flowStatus.key !== option.key;
      };
    }
    return flowStatus.reloadOptionsRegisterLeave;
  }

  reloadOptionsRegisterDocument(flowStatusExt) {
    if (!flowStatusExt.reloadOptionsRegisterDocument) {
      flowStatusExt.reloadOptionsRegisterDocument = (reloadOptions) => {
        flowStatusExt.reloadOptionsDocument = reloadOptions;
      };
      flowStatusExt.documentFilter = (option) => {
        return !_.some(flowStatusExt.flowStatus.documents, {key: option.key});
      };
    }
    return flowStatusExt.reloadOptionsRegisterDocument;
  }

  editDocument(event, document) {
    event.stopPropagation();
    event.preventDefault();

    const dialog = this.dialogService.open(DocumentSettingsComponent, { data: { document: {...document}}});
    const sub = dialog.afterClosed.subscribe((updatedDocument) => {
      if (updatedDocument) {
        Object.assign(document, updatedDocument);
      }
      sub.unsubscribe();
    });
  }

  update() {
    if (this.data.readOnly && this.editable && this.workflowChanged) {
      return this.dialogService.confirmDialogPromise('Určitě uložit změny? Změny mohou způsobit konflikt ve stavech existujících případů.')
        .then((data) => {
          if (data === true) {
            return this.saveData();
          }
        });
    } else {
      return this.saveData();
    }
  }

  reset() {
    return this.dialogService.confirmDialogPromise('Určitě chcete obnovit workflow? Změny mohou způsobit konflikt ve stavech existujících případů.')
      .then((data) => {
        if (data === true) {
          this.loading = true;
          return this.restangular.one('flow', this.obligationId).remove().toPromise().then(this.processData).then(() => {
            this.loading = false;
          }, (response) => {
            this.loading = false;
            return Promise.reject(response);
          });
        }
      });
  }

  goToStatusAnchor(flowStatusKey) {
    const anchor = this.elementRef.nativeElement.querySelector('#statusAnchor' + flowStatusKey);
    $(anchor).get(0).scrollIntoView();

    const parent = $(anchor).parent();
    parent.addClass('colored');
    setTimeout(() => {
      parent.removeClass('colored');
    }, 1000);
  }

  toggleAllowCadastreEntryProposal(flowStatusExt) {
    flowStatusExt.flowStatus.allowCadastreEntryProposal = !flowStatusExt.flowStatus.allowCadastreEntryProposal;
  }

  private processData(data) {
    this.editable = !data.readOnly;
    this.data = data;
    this.flowDataSort = this.data.flow ? Object.keys(this.data.flow) : null;
    // Original data that keeps only attributes for REST message
    // Extended objects based on original data that are refferenced and structure never modified
    this.flowData = {};
    this.caseStatusOptions = [];
    _.forEach(this.data.flow, (flowStatus, caseStatus) => {
      // remove disabled documents
      _.remove(flowStatus.documents, (document: any) => this.documentTemplateNames[document.key] === undefined);
      const flowStatusExtended = {
        flowStatus: flowStatus,
        key: caseStatus,
        name: flowStatus.name,
        leaveStatuses: []
      };
      this.flowData[caseStatus] = flowStatusExtended;
      this.caseStatusOptions.push(flowStatusExtended);
    });
    this.caseStatusOptions = _.sortBy(this.caseStatusOptions, ['name']);

    _.forEach(this.data.flow, (flowStatus, caseStatus) => {
      for (let i = 0; i < flowStatus.enterStatuses.length; i++) {
        const enterStatus = flowStatus.enterStatuses[i];
        const foundFlowStatus = this.flowData[enterStatus];
        if (!foundFlowStatus) {
          // remove from view statuses not in workflow
          _.pull(flowStatus.enterStatuses, enterStatus);
          i--;
          continue;
        }
        foundFlowStatus.leaveStatuses.push(caseStatus);
      }
    });

    // remove from view statuses not selected
    _.forEach(this.flowData, (flowStatusExtended, caseStatus) => {
      if (!flowStatusExtended.flowStatus.enterStatuses.length && !flowStatusExtended.leaveStatuses.length && caseStatus !== 'Created') {
        _.pull(this.flowDataSort, caseStatus);
        delete this.flowData[caseStatus];
      }
    });
  }

  private saveData() {
    this.data.flow = {};
    this.flowDataSort.forEach((caseStatus) => {
      this.data.flow[caseStatus] = this.flowData[caseStatus].flowStatus;
    });
    return this.restangular.one('flow', this.obligationId).customPOST(this.data).toPromise().then(() => {
      this.dialog.close();
    });
  }
}
