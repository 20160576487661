<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Základní údaje</legend>
      <div class="column-50">
        <div class="label" [ngClass]="{ required: !data.readonly }">Číslo znaleckého posudku</div>
        <div class="input" *ngIf="!data.readonly">
          <input type="text" [(ngModel)]="data.opinion.expertOpinionNumber"/>
        </div>
        <div class="info" *ngIf="data.readonly">{{ data.opinion.expertOpinionNumber }}</div>
      </div>
      <div class="column-50">
        <div class="label" [ngClass]="{ required: !data.readonly }">Datum vyhotovení</div>
        <div class="input" *ngIf="!data.readonly">
          <input pick-a-date type="text" [(ngModel)]="data.opinion.expertOpinionDate"/>
        </div>
        <div class="info" *ngIf="data.readonly">{{data.opinion.expertOpinionDate | date}}</div>
      </div>
    </fieldset>

    <expert-select-form *ngIf="!data.readonly" [expert]="data.opinion.expert" (changed)="onChangeExpert($event)"></expert-select-form>

    <fieldset *ngIf="data.readonly">
      <legend>Odhadce</legend>
      <div class="column-50">
        <label>Titul před jménem</label>
        <div class="input">{{ data.opinion.expert.titulPredJmenem }}</div>
        <label>Křestní jméno</label>
        <div class="input">{{ data.opinion.expert.jmeno }}</div>
        <label>Příjmení</label>
        <div class="input">{{ data.opinion.expert.prijmeni }}</div>
        <label>Titul za jménem</label>
        <div class="input">{{data.opinion. expert.titulZaJmenem }}</div>
      </div>

      <div class="column-50">
        <label>Ulice</label>
        <div class="input">{{ data.opinion.expert.ulice }}</div>
        <label>Číslo orientační</label>
        <div class="input">{{ data.opinion.expert.cisloOrientacni }}</div>
        <label>Číslo popisné</label>
        <div class="input">{{ data.opinion.expert.cisloDomovni }}</div>
        <label>Město</label>
        <div class="input">{{ data.opinion.expert.obec }}</div>
        <label>PSČ</label>
        <div class="input">{{ data.opinion.expert.psc | postCode }}</div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.opinion.occupationType === 'P' && multipleOpinionsPerParcelEnabled">
      <legend>Obsah znaleckého posudku</legend>
      <ul style="line-height: 1.5rem">
        <li>
          <div *ngIf="!data.readonly" (click)="data.opinion.expertOpinionLand = !data.opinion.expertOpinionLand">
            <gmt-checkbox [gmtChecked]="data.opinion.expertOpinionLand"></gmt-checkbox> cena zjištěná za pozemek nebo m<sup>2</sup> pozemku
          </div>
          <span *ngIf="data.readonly && data.opinion.expertOpinionLand">cena zjištěná za pozemek nebo m<sup>2</sup> pozemku</span>
        </li>
        <li>
          <div *ngIf="!data.readonly" (click)="data.opinion.expertOpinionVegetation = !data.opinion.expertOpinionVegetation">
            <gmt-checkbox [gmtChecked]="data.opinion.expertOpinionVegetation"></gmt-checkbox> cena zjištěná za porost (vedlejší úpravy) pozemku
          </div>
          <span *ngIf="data.readonly && data.opinion.expertOpinionVegetation">cena zjištěná za porost (vedlejší úpravy) pozemku</span>
        </li>
        <li>
          <div *ngIf="!data.readonly" (click)="data.opinion.expertOpinionBuildings = !data.opinion.expertOpinionBuildings">
            <gmt-checkbox [gmtChecked]="data.opinion.expertOpinionBuildings"></gmt-checkbox> cena zjištěná za stavbu(y) na pozemku
          </div>
          <span *ngIf="data.readonly && data.opinion.expertOpinionBuildings">cena zjištěná za stavbu(y) na pozemku</span>
        </li>
      </ul>
    </fieldset>

    <h2 style="font-size: 15px; padding-top: 0">Dokument znaleckého posudku</h2>

    <ng-container *ngIf="!data.opinionRequest && !data.opinion.id">
      <fileupload (change)="onFileChange($event)" [message]="filesMsg" inputId="opinionAttachmentsHidden"></fileupload>
    </ng-container>

    <gmt-documents
      *ngIf="data.opinionRequest || data.opinion.opinionRequest"
      [defaultData]="{ opinionRequestId: (data.opinionRequest ? data.opinionRequest.id : data.opinion.opinionRequest.id) }"
      [defaultDocumentType]="attachmentType"
      [allowType]="false"
      [authorizedUser]="!data.readonly"
      [documentList]="documentList"
    ></gmt-documents>

    <div class="mt-30">
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isFormValid()">Pokračovat</gmt-button>
    </div>
  </div>
</div>
