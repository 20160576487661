<ng-template #notificationConfig let-statusType="statusType">
  <div *ngFor="let config of notificationConfigsMapped">
    <div *ngIf="getStatusByConfig(config) as status">
      <fieldset *ngIf="config.statusType === statusType" class="bordered">
        <legend>{{ status.name }}</legend>
        <div class="column-50">
          <div class="label">Role</div>
          <div>
            <span *ngFor="let role of roles" (click)="onToggleRole(config, role)" [ngClass]="{'active': isRoleEnabled(config, role)}" class="mr-5 mb-5 tag">
              {{ role.id | roleName }}
            </span>
          </div>
        </div>

        <div class="column-50">
          <div class="column-100">
            <div class="label">Jednotliví uživatelé</div>
            <div class="input">
              <span (click)="onToggleCustomUsers(config)" class="pr-10 pt-10">
                <gmt-checkbox [gmtChecked]="config.customUsers"></gmt-checkbox>
              </span>

              <gmt-select
                *ngIf="config.customUsers"
                [optionItems]="users"
                [data]="this"
                field="userSelected"
                [filter]="config.userFilter ? config.userFilter : {}"
                [reloadOptionsRegister]="reloadOptionsRegisterUser(config)"
                (changed)="onUserSelected(config, $event.newValue)"
                required="true"
                selectTitle="přidat uživatele"
                [itemPipe]="userNamePipe"
              ></gmt-select>
            </div>
          </div>

          <div *ngIf="config.users.length > 0" class="column-100 bordered p-5 mt-10">
            <span *ngFor="let user of config.users" class="tag tag--small active mr-5">
              {{ user | username:false }} <span *ngIf="getUserRole(user) as roleName">({{ roleName }})</span> <a (click)="onUserUnselected(config, user)"><i class="fa fa-remove"></i></a>
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</ng-template>

<div [loading]="loading">
  <div *ngIf="!loading">
    <h2>Notifikace pro změnu stavu případu</h2>
    <ng-container *ngTemplateOutlet="notificationConfig; context: { statusType: 'caseStatuses' }"></ng-container>
    <ng-container *ngIf="geometricPlanModuleEnabled">
      <h2>Notifikace pro změnu stavu GP</h2>
      <ng-container *ngTemplateOutlet="notificationConfig; context: { statusType: 'geometricPlanStatuses' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="emailNotificationTypesEnabled">
      <h2>Obecné notifikace</h2>
      <ng-container *ngTemplateOutlet="notificationConfig; context: { statusType: 'notificationTypes' }"></ng-container>
    </ng-container>
    <div *ngIf="!saveNotificationConfigRegister" class="mt-30 text-center">
      <gmt-button [clickAction]="onSave">Uložit</gmt-button>
    </div>
  </div>
</div>
