import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CaseSubjectModel } from '@app/ps/models/case-subject.model';

@Component({
  selector: 'form-case-subject-dates',
  templateUrl: './form-case-subject-dates.component.html',
  styleUrls: ['./form-case-subject-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCaseSubjectDatesComponent implements OnInit {
  @Input() dates: CaseSubjectModel;
  @Input() visibleFields: {[s: string]: boolean}[];

  ngOnInit() { }
}
