<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset>
      <div class="column-100">
        <label [ngClass]="{ required: recipients.length > 1 }">Adresát</label>
        <div class="input" *ngIf="recipients.length > 1">
          <gmt-select [data]="dataSelected" field="recipient" required="true" [optionItems]="recipients"></gmt-select>
        </div>
        <div class="info" *ngIf="recipients.length === 1">{{ dataSelected.recipient.name }}</div>
      </div>

      <div class="column-100">
        <label class="required">Datum návštěvy</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="dataSend.personalVisitDate" [maxToday]="true"/></div>
      </div>
    </fieldset>
    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>

