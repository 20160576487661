import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfig } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'help-edit',
  templateUrl: './help-edit.component.html',
})
export class HelpEditComponent implements OnInit {
  help: any;

  constructor(
    private helpService: HelpService,
    public config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    const help = (this.config.data as any);
    this.help = help || {};
  }

  onSubmit() {
    this.helpService.saveChanges(this.help).then(() => {
      this.dialog.close(true);
    });
  }

  onCancel() {
    this.dialog.close(false);
  }

  disabled() {
    return !(this.help.title && this.help.description && this.help.url);
  }
}
