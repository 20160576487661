<h1>Nastavení osoby</h1>

<div class="cb">
  <fieldset>
    <legend>Základní údaje</legend>
    <div class="column-50">
      <gmt-input label="Titul před jménem"><input type="text" [(ngModel)]="projectSubject.titleBefore"/></gmt-input>
      <gmt-input label="Křestní jméno"><input type="text" [(ngModel)]="projectSubject.firstName"/></gmt-input>
      <gmt-input label="Příjmení"><input type="text" [(ngModel)]="projectSubject.surname"/></gmt-input>
      <gmt-input label="Titul za jménem"><input type="text" [(ngModel)]="projectSubject.degreeAfter"/></gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="Firma"><input type="text" [(ngModel)]="projectSubject.companyName"/></gmt-input>
    </div>
  </fieldset>

  <fieldset>
    <legend>Kontakt</legend>

    <div class="column-50">
      <gmt-input label="E-mail"><input type="text" [(ngModel)]="projectSubject.email"/></gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Telefon"><input type="text" [(ngModel)]="projectSubject.phoneNumber"/></gmt-input>
    </div>
  </fieldset>
</div>

<div class="text-center pt-20">
  <gmt-button class="button black" [clickAction]="onCancel">
    Zrušit změny
  </gmt-button>
  <gmt-button class="button" [clickAction]="onSave">
    Použít změny
  </gmt-button>
</div>
