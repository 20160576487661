import { Component, Input } from '@angular/core';

import { TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { OccupationModel } from '../../models/occupation.model';
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'table-occupations-temporary',
  templateUrl: './table-occupations-temporary.component.html',
  styleUrls: ['./table-occupations-temporary.component.scss']
})
export class TableOccupationsTemporaryComponent extends TableParcelsAbstractComponent<OccupationModel> {

  @Input() updateOccupationParcelPath: string;
  geometricPlanModuleEnabled: boolean;

  protected readonly COMPONENT_COLUMNS = [
    'label_gp', 'occupation_area', 'occupation_title', 'occupation_parcel', 'occupation_parcel_title',
    'occupation_phase', 'occupation_occupation', 'occupation_cases', 'occupation_assessment_price',
    'occupation_price_land_price', 'occupation_price_rent_price', 'occupation_price_rent_price_year',
    'occupation_price_rent_length', 'occupation_concern_object',
    'occupation_price_summary_cases', 'occupation_price_total_rental_price', 'current_title',
    'current_parcel', 'current_parcel_title', 'current_protection', 'current_concern_object',
    'occupation_switch' /* změna zvoleného záboru */, 'update_occupation' /* změna parametrů záboru */,
  ];

  constructor(protected authService: AuthService) {
    super();

    this.geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
  }

  canEdit(permissions: string): boolean {
    return this.authService.hasPermission(permissions);
  }
}
