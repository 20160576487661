import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { PermissionsBulkStepComponent } from '@app/settings/users/component/permission-bulk-step/permissions-bulk-step.component';
import {Permission} from '@app/common/components/user-permissions/user-permissions.component';
import {RolePipe} from '@app/common/pipes/role.pipe';
import {MirroredListItem} from "@app/common/components/mirrored-list/mirrored-list.component";


@Component({
  selector: 'permissions-bulk-permissions',
  templateUrl: './permissions-bulk-permissions.component.html',
})
export class PermissionsBulkPermissionsComponent extends PermissionsBulkStepComponent implements OnInit {

  permissions; // opravneni, ktera jsou k dispozici pro vyber
  currentRolePermissions;

  constructor(
    public rolePipe: RolePipe,
  ) {
    super();
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    this.setRoleAndPermissions();
  }

  handleRoleSelect() {
    this.data.permissions = [];
    this.setRoleAndPermissions();
  }

  handleItemSelect(items: Permission[]) {
    this.data.permissions  = items;
  }

  isValid(): boolean {
    return this.data.removePermissions || this.data.permissions || this.data.role;
  }

  onSubmit() {
    this.submitCallback.emit();
  }

  private setRoleAndPermissions() {
    this.permissions = _.differenceBy(
      PermissionsBulkPermissionsComponent.map(this.data.common.permissions),
      this.getPermissionsByCurrentRole().concat(this.data.permissions),
      'id'
    );
    this.currentRolePermissions = _.sortBy(this.getPermissionsByCurrentRole(), 'value');
  }

  private getPermissionsByCurrentRole(): Permission[] {
    return PermissionsBulkPermissionsComponent.map(
      this.data.common.permissionsWithRoles.filter(pwr => pwr.roles.includes(this.data.role && this.data.role.id))
    );
  }

  private static map(permissions: any[]): Permission[] {
    return permissions.map(item => ({ id: item.name, value: item.description }));
  }
}
