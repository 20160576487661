<div class="jumbotron" *ngIf="!exportRunning">
  <div class="centered-text">Vygenerované soubory je možné jednorázově stáhnout v archivu ve formátu zip. Přejete si spustit generování exportu s nově vygenerovanými soubory?</div>
  <div class="centered-text mt-10">
    <gmt-button [clickAction]="generateZipFile">Spustit export souborů</gmt-button>
  </div>
</div>

<div class="jumbotron" *ngIf="exportRunning">
  <div class="centered-text">Na pozadí probíhá generování exportu datových příloh. Průběh akce můžete sledovat v horní liště.</div>
</div>

<div class="jumbotron">
  <div class="centered-text p-5">Operace byla úspěšně provedena.</div>
  <div class="centered-text p-5" *ngIf="data.confirm.attachmentsWithoutGeneratedMap.length">
    V následujícíh dokumentech se nepodařilo vygenerovat mapovou část: {{ data.confirm.attachmentsWithoutGeneratedMap.join(', ') }}
  </div>
  <div class="centered-text">Přejete si provést další hromadnou operaci nad zvoleným seznamem?</div>
  <div class="centered-text mt-10">
    <gmt-button [clickAction]="newOperation">Ano</gmt-button>
    <gmt-button [clickAction]="goToList">Ne</gmt-button>
  </div>
</div>
