import { ChangeDetectionStrategy, Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

import { CaseModel } from '@app/ps/models/case.model';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ConfirmationConfigData, DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { defaultLegalException } from '@app/ps/cases/components/form-contracts-register/contracts-register-legal-exceptions';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'form-contracts-register-dialog',
  templateUrl: './form-contracts-register-dialog.component.html',
  styleUrls: ['./form-contracts-register-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContractsRegisterDialogComponent implements OnInit {
  initialCaseObject: CaseModel;
  caseObject: CaseModel;
  publishFormVisible = false;
  doNotPublishFormVisible = false;

  constructor(
    private restangular: Restangular,
    private config: DialogConfig,
    private dialog: DialogRef,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {  }

  ngOnInit() {
    this.caseObject = (<DialogConfigData>this.config.data).caseObject;
    this.initialCaseObject = {...this.caseObject};

    this.isValidForNotPublishing = this.isValidForNotPublishing.bind(this);
    this.isValidForPublishing = this.isValidForPublishing.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onExceptionSelected = this.onExceptionSelected.bind(this);
    this.onRenderOrSubmitDoNotPublishForm = this.onRenderOrSubmitDoNotPublishForm.bind(this);
    this.onRenderPublishForm = this.onRenderPublishForm.bind(this);
    this.onSend = this.onSend.bind(this);
  }

  onExceptionSelected(value: string) {
    this.caseObject.contractRegisterPublicationException = value;
  }

  onCancel() {
    this.publishFormVisible = false;
    this.doNotPublishFormVisible = false;
    this.caseObject = {...this.initialCaseObject};
  }

  onRenderPublishForm() {
    if (!this.caseObject.contractRegisterPublicationNecessary) {
      const dialog = this.createContractPublishmentConfirmationDialogWhenNotRequired();
      const sub = dialog.afterClosed.subscribe((result: boolean) => {
        sub.unsubscribe();
        if (result) {
          this.publishFormVisible = true;
          this.cdr.detectChanges();
        } else {
          this.onCancel();
        }
      });
    } else {
      this.publishFormVisible = true;
    }
  }

  createContractPublishmentConfirmationDialogWhenNotRequired() {
    const data = <ConfirmationConfigData> {
      msg: 'Skutečně chcete tuto smlouvu zveřejnit v registru smluv? Její hodnota nepřesahuje 50000,- Kč.',
    };

    const dialog = this.dialogService.open(ConfirmationComponent, {
      data,
      className: ClassName.ADJUSTED_DIALOG,
    });

    return dialog;
  }

  onRenderOrSubmitDoNotPublishForm() {
    if (this.caseObject.contractRegisterPublicationNecessary) {
      this.doNotPublishFormVisible = !this.doNotPublishFormVisible;
    } else {
      this.caseObject.contractRegisterPublicationException = defaultLegalException;
      this.onSend();
    }
  }

  isValidForPublishing(): boolean {
    return !!(this.caseObject.contractRegisterPublicationDate
      && this.caseObject.contractRegisterPublicationContractId
      && this.caseObject.contractRegisterPublicationVersionId);
  }

  isValidForNotPublishing(): boolean {
    return !!this.caseObject.contractRegisterPublicationException;
  }

  async onSend(): Promise<CaseModel> {
    this.caseObject.contractRegisterPublicationDate = this.caseObject.contractRegisterPublicationDate || new Date();
    const result: CaseModel = await this.restangular
      .one('cases', this.caseObject.id)
      .customPUT(this.caseObject, 'contract-register-publication')
      .toPromise();

    this.dialog.close(result);
    return result;
  }
}

