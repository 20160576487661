import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
 import * as  L from 'leaflet';
import { LeafletWmsCommonService } from '@app/map/services/leaflet-wms-common.service';
import { MapUtilsCqlFilterService } from '@app/map/services/map-utils-cql-filter.service';
import { MapUtilsWfsFeatureService } from '@app/map/services/map-utils-wfs-feature.service';

/**
 * GeoJSON WFS layer
 *
 * Based on code from CAN by Martin Tesar
 * Modified by Petr Sobeslavsky to work with L.Proj.GeoJSON - GeoJSON layer with custom CRS support
 */
@Injectable({
  providedIn: 'root'
})
export class LeafletProjGeoJsonWfs {
    private ProjGeoJsonWfs;

    constructor(
      http: HttpClient,
      leafletWmsCommonService: LeafletWmsCommonService,
      mapUtilsCqlFilterService: MapUtilsCqlFilterService,
      mapUtilsWFSFeatureService: MapUtilsWfsFeatureService
    ) {
       this.ProjGeoJsonWfs = L.Proj.GeoJSON.extend({
          defaultWfsParams: {
              service: 'WFS',
              request: 'GetFeature',
              version: '2.0.0',
              typeNames: '',
              outputFormat: 'application/json',
              count: 100,
              srsName: 'EPSG:5514',
          },
          initialize: function (url, options) {
              if(!options || !options.id) {
                  throw new Error("Missing layer id option in initialization.");
              }

              this.state = {
                  dataBounds: null,
                  newBounds: null,
                  zoom: null,
                  newZoom: null,
                  featuresFetched: 0,
                  limitReached: false,
                  currentDownloadChain: 0
              };

              this._id = options.id;
              this._url = url;
              this._wfsParams = {...L.extend(this.defaultWfsParams, options.wfs)};
              this._filter = options.filter;
              this._wfsFormatParams = this.defaultWfsFormatParams;
              this._geometryColumn = options.geometryColumn || 'geom';
              this._limit = options.limit || 2000;
              this._httpService = http;
              this._chunked = false;
              this._unbounded = false;

              if (options.unbounded) {
                  this._unbounded = true;
              }

              if (options.chunked) {
                  // only set startIndex if we are downloading data in chunks
                  // it requires layers to provide natural ordering, so we cannot set it by default
                  // for all layers
                  this._wfsParams.startIndex = 0;
                  this._chunked = true;
              }

              if (options.dataCallback) {
                  this._dataCallback = options.dataCallback;
              }

              L.GeoJSON.prototype.initialize.call(this, null, options.geojson);
          },
          loadFeatureData: function (options) {
              var filter = null;
              if (this._filter) {
                  filter = mapUtilsCqlFilterService.getFilter(this._filter);
              }

              return leafletWmsCommonService.loadFeatureData({
                latlng: options.latlng,
                queryParams: options.queryParams,
                url: this._url,
                uppercase: this._uppercase,
                httpService: this._httpService,
                map: this._map,
                crs: this._crs,
                layers: this._wfsParams['typeNames'],
                cqlFilter: filter,
                featureCount: this._wfsParams['feature_count']
              });
          },
          loadFeatureDataByID: function (featureID, queryParams) {
            var wfsBaseURL = this._url;
            return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this._wfsParams['typeNames'], featureID, queryParams);
          },
          onAdd: function (map) {
              L.GeoJSON.prototype.onAdd.call(this, map);

              this._crs = this.options.crs || map.options.crs;
              this._uppercase = this.options.uppercase || false;

              this._map = map;
              map.on({
                  moveend: this._updateDisplayedMap
              }, this);
              this._updateDisplayedMap();
          },
          _updateDisplayedMap: function () {
              if (!this._map) {
                  return;
              }
              var bounds = this._map.getBounds();
              var zoom = this._map.getZoom();

              this.update(bounds, zoom);
          },
          getExtendedBounds: function (bounds) {
              // extend bounds by 10 % in all directions
              return bounds.pad(0.05);
          },
          update: function (viewBounds, newZoom) {
              if (!this._map) {
                  return;
              }
              if (this._needsUpdate(viewBounds, newZoom)) {
                  this.state.newBounds = viewBounds;
                  this.state.newZoom = newZoom;
                  this._getFeatures(viewBounds);
              }
          },
          refresh: function() {
              this._getFeatures(this.state.newBounds);
          },
          _getFormatString: function (obj) {
              var params = [];
              for (var i in obj) {
                  if (obj.hasOwnProperty(i)) {
                      params.push(i + ':' + obj[i]);
                  }
              }
              return params.join(';');
          },
          _getRequestUrl: function (_wfsParams, bounds) {
              var extendedBounds = this.getExtendedBounds(bounds);
              var sw = this._crs.project(extendedBounds.getSouthWest());
              var ne = this._crs.project(extendedBounds.getNorthEast());
              //var bbox = sw.x + ',' + sw.y + ' ' + ne.x + ',' + ne.y; // pro OGC filter
              var bbox = sw.x + ',' + sw.y + ',' + ne.x + ',' + ne.y;
              // we need to supply BBOX as part of filter, because we cannot specify both bbox and filter
              const filters = [];
              if (this._filter) {
                filters.push(this._filter);
              }

              if (!this._unbounded) {
                  var bboxFilter = {
                      type: 'BBOX',
                      propertyName: this._geometryColumn,
                      coordinates: bbox
                  };
                  filters.push(bboxFilter);
              }
              if (filters.length) {
                const requestFilter = filters.length > 1 ? {
                    type: 'And',
                    args: filters,
                } : filters[0];
                _wfsParams['cql_filter'] = mapUtilsCqlFilterService.getFilter(requestFilter);
              }
              // prepare format params
              var fo = this._getFormatString(this._wfsFormatParams);

              return this._url +
                      L.Util.getParamString(_wfsParams, this._url, this._uppercase) +
                      (this._uppercase ? '&FORMAT_OPTIONS=' : '&format_options=') + fo;
          },
          _getFeatures: function (bounds) {
              var _wfsParams = _.cloneDeep(this._wfsParams);

              this.state.currentDownloadChain += 1;
              var downloadChain = this.state.currentDownloadChain;

              this.state.featuresFetched = 0;
              this.state.limitReached = false;
              this._fetchFeatures(_wfsParams, bounds, downloadChain);
          },
          _fetchFeatures: function (_wfsParams, bounds, downloadChain) {
              var url = this._getRequestUrl(_wfsParams, bounds);

              var _this = this;
              this._httpService.get(url).toPromise()
                      .then(function (data) {
                          _this._FeaturesFetchedCallback(data, _wfsParams, bounds, downloadChain);
                      }, function () {
                          _this._updateDisplayedMapFailCallback();
                      });
          },
          _FeaturesFetchedCallback: function (data, _wfsParams, bounds, downloadChain) {
              // if we received data from an old call, discard them
              if (downloadChain < this.state.currentDownloadChain) {
                  return;
              }

              if (this.state.featuresFetched === 0) {
                  // delete old data upon reception of the first batch of new data
                  this.clearLayers();
              }

              this.state.featuresFetched += data.features.length;
              this.addData(data);

              if (this._dataCallback) {
                  this._dataCallback(data);
              }

              if (this.state.featuresFetched >= data.totalFeatures) {
                  this._updateDisplayedMapDoneFinish(data);
                  return;
              }

              if (this.state.featuresFetched >= this._limit) {
                  this.state.limitReached = true;
                  this._updateDisplayedMapDoneFinish(data);
                  return;
              }

              if (this._chunked) {
                  _wfsParams.startIndex += _wfsParams.count;
                  this._fetchFeatures(_wfsParams, bounds, downloadChain);
              }
          },
          _updateDisplayedMapFailCallback: function () {

          },
          _updateDisplayedMapDoneFinish: function () {
              this.state.dataBounds = this.state.newBounds;
              this.state.zoom = this.state.newZoom;
          },
          _needsUpdate: function (viewBounds, newZoom) {
              var oldBounds = this.state.dataBounds;
              var oldZoom = this.state.zoom;

              var needs = false;
              if ((newZoom > oldZoom) && this.state.limitReached) {
                  needs = true;
              }
              if (!(oldBounds && oldBounds.contains(viewBounds))) {
                  needs = true;
              }

              return needs;
          }
      });
    }



    getLayer(url, options) {
        return new this.ProjGeoJsonWfs(url, options);
    }
}
