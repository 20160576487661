<div class="detail">
  <gmt-button [clickAction]="goToCase"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  <div [loading]="!easementObject">
    <ng-container *ngIf="easementObject">
      <fieldset class="bordered">
        <div class="column-50"><label>Parcela</label>
          <div class="info"><a uiSref="symap.project.parcels.detail" [uiParams]="{id: easementObject.parcel.id}">{{easementObject.parcel|parcelnumber}}</a></div>
        </div>
        <div class="column-50"><label>Katastrální území</label>
          <div class="info">{{easementObject.parcel.katuzeNazev}}</div>
        </div>
        <div class="column-50"><label>Výměra parcely</label>
          <div class="info">{{easementObject.parcel.vymera}} m<sup>2</sup></div>
        </div>
        <div class="column-50"><label>Geometrický plán</label>
          <div class="info">{{easementObject.geometricPlan.cis}}</div>
        </div>
        <div class="column-50"><label>Plocha VB</label>
          <div class="info"><span *ngIf="easementObject.vymera" class="unit">{{easementObject.vymera}} m<sup>2</sup></span></div>
        </div>
        <div class="column-50"><label>Délka VB</label>
          <div class="info"><span *ngIf="easementObject.delka" class="unit">{{easementObject.delka}} m</span></div>
        </div>
        <div class="column-50" *ngIf="easementObject.oznacVb"><label>Označení VB</label>
          <div class="info">{{easementObject.oznacVb}}</div>
        </div>
      </fieldset>

      <div class="bordered fieldset no-padding">
        <div class="legend">Aktuálně platná věcná břemena na parcele</div>
        <div [loading]="easementList.loading">
          <table *ngIf="easementList.list?.length">
            <thead>
              <tr>
                <th class="min"></th>
                <th>Geometrický plán</th>
                <th>{{"CONSTRUCTION_OBJECT" | wordtranslate:true}}</th>
                <th *ngIf="showVbColumn">Označení VB</th>
                <th>Plocha VB <span class="unit">m<sup>2</sup></span></th>
                <th>Délka VB <span class="unit">m</span></th>
                <th>Případy</th>
              </tr>
            </thead>
            <tbody class="hover">
              <tr *ngIf="easementObject.endDate" (click)="onToggleCheckItem(easementObject)">
                <td><gmt-radio [gmtChecked]="selected.id === easementObject.id"></gmt-radio></td>
                <td><span class="cancelled">{{easementObject.geometricPlan.cis}}</span></td>
                <td><div class="cancelled"><gmt-construction-object-list [constructionObjects]="easementObject.constructionObjects"></gmt-construction-object-list></div></td>
                <td *ngIf="showVbColumn"><span class="cancelled">{{easementObject.oznacVb}}</span></td>
                <td class="number"><span class="cancelled">{{easementObject.vymera}}</span></td>
                <td class="number"><span class="cancelled">{{easementObject.delka}}</span></td>
                <td class="no-padding">
                  <cell-cases
                    [casesCount]="easementObject.countCases"
                    tooltipType="cases"
                    [tooltipData]="{filter: {filters: {easementId: easementObject.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
                  ></cell-cases>
                </td>
              </tr>
            </tbody>
            <tbody class="hover" *ngFor="let easement of easementList.list">
              <tr (click)="onToggleCheckItem(easement)">
                <td><gmt-radio [gmtChecked]="selected.id === easement.id"></gmt-radio></td>
                <td>{{easement.geometricPlan.cis}}</td>
                <td><gmt-construction-object-list [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list></td>
                <td *ngIf="showVbColumn">{{easement.oznacVb}}</td>
                <td class="number">{{easement.vymera}}</td>
                <td class="number">{{easement.delka}}</td>
                <td class="no-padding">
                  <cell-cases
                    [casesCount]="easement.countCases"
                    tooltipType="cases"
                    [tooltipData]="{filter: {filters: {easementId: easement.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
                  ></cell-cases>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="empty-info" *ngIf="easementList.list?.length === 0">Nenalezena žádná věcná břemena</div>
        </div>
      </div>
      <div>
        <gmt-button [clickAction]="update">Uložit</gmt-button>
      </div>
    </ng-container>
  </div>
</div>
