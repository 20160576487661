interface BusinessRegisterRegionalCourt {
  id: number;
  name: string;
}

export class SubjectBasicInfoModel {
  maritalStatus: Object;
  identificationNumber: string;
  invoicePayment: boolean;
  gender: Object;
  personalIdentificationNumber: string;
  dateOfBirth: string;
  businessRegisterRegionalCourt: BusinessRegisterRegionalCourt;
  businessRegisterSection: string;
  businessRegisterEntry: string;
  dic: string;
  vatPayer: boolean;
  opsubType: string;
  ico: number;
}
