<fieldset>
  <legend>Odhadce</legend>
  <div class="column-100">
    <gmt-input label="Odhadce" [required]="true">
      <gmt-select
        [data]="this"
        field="expert"
        resource="experts"
        [itemPipe]="subjectNamePipe"
        [reloadOptionsRegister]="expertsReloadRegister"
        (changed)="onChange()"
      ></gmt-select>
      <gmt-button *ngIf="!!expert" [clickAction]="onUpdateExpert" [title]="'Upravit odhadce'"><span class="fa fa-pencil"></span></gmt-button>
      <gmt-button [clickAction]="onAddExpert" [title]="'Nový odhadce'"><span class="fa fa-plus"></span></gmt-button>
    </gmt-input>
  </div>
</fieldset>
