<div>
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>

<div *ngIf="data.operation === 'u'" [loading]="loading" class="mt-30">
  <div *ngIf="!loading">
    <fieldset class="mt-20">
      <form (submit)="sortAndFilterProjects()">
        <div class="filter-items">
          <ng-container *ngFor="let column of projectsFilters">
            <gmt-select
              [data]="projectsFilterData"
              field="{{ column.id }}"
              required="true"
              multiselect="true"
              [optionItems]="getFilterItemsByColumn(column)"
              [selectTitle]="column.name"
            ></gmt-select>
          </ng-container>
          <div class="tag" [ngClass]="{ active: projectsCustomFilterData.hasIsprofond }" (click)="projectsCustomFilterData.hasIsprofond = !projectsCustomFilterData.hasIsprofond" title="Zobrazit pouze {{ 'ps.projects' | brandTranslate }} s evidenčním číslem">
            <div>Pouze {{ 'ps.projects' | brandTranslate }} s evidenčním číslem</div>
          </div>
        </div>
        <div class="column-100">
          <input class="fulltext" name="search" type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="projectsCustomFilterData.searchText" />
        </div>
        <gmt-button [clickAction]="sortAndFilterProjects" class="filter-submit">Filtrovat</gmt-button>
        <input type="submit" style="visibility: hidden; position: absolute; left: -9999px; width: 1px; height: 1px;" tabindex="-1" />
      </form>
    </fieldset>

    <div class="text-center">
      Počet vybraných {{ 'ps.projectsAccusative' | brandTranslate }}: <strong>{{ projectChecklist.checkedItems.length }}</strong>
    </div>

    <div class="pt-20 mb-40">
      <div *ngIf="projects.length">
        <table class="table bordered">
          <thead>
            <tr>
              <th class="text-center" (click)="allProjectsSelectedToggle()">
                <gmt-checkbox [gmtChecked]="allProjectsSelected"></gmt-checkbox>
              </th>
              <th>Evidenční číslo</th>
              <th>Název</th>
              <th>Organizační jednotka</th>
              <th>Přístup</th>
              <th>Role</th>
              <th>Oprávnění</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let project of projects">
              <td class="text-center" (click)="projectChecklist.toggleSelection(project); allProjectsSelectedRecalculate()">
                <gmt-checkbox [gmtChecked]="projectChecklist.isChecked(project)"></gmt-checkbox>
              </td>

              <td>{{ project.isprofond }}</td>
              <td><b>{{ project.name }}</b></td>
              <td>{{ project.investor }}</td>
              <td class="text-center"><i *ngIf="project.enabled" class="fa fa-check"></i></td>
              <td class="text-center" >{{ project.roles }}</td>
              <td>
                <div *ngIf="project.permissions.length" class="text-center" style="width: 230px">
                  <strong>{{ project.permissions.length }}</strong>&nbsp;&nbsp;<a (click)="project.permissionShow = !project.permissionShow"><i class="fa fa-eye"></i></a>
                  <div *ngIf="project.permissionShow" class="text-left">
                    <div *ngFor="let permission of project.permissions">{{ permission }}</div>
                  </div>
                </div>
                <div *ngIf="!project.permissions.length" class="text-center">-</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="empty-info bordered" *ngIf="projects.length === 0">
        <span>Nenalezena žádná data</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data.operation === 'p'" class="mt-20">
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div class="text-center mb-20">
    Počet vybraných uživatelů: <strong>{{ userChecklist.checkedItems.length }}</strong>
  </div>

  <div [loading]="list.loading">
    <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
      <div class="cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="bordered" *ngIf="list.itemCount">
      <table class="table">
        <thead>
          <tr>
            <th class="text-center" (click)="allUsersSelectedToggle()">
              <gmt-checkbox [gmtChecked]="allProjectsSelected"></gmt-checkbox>
            </th>
            <th>
              <sort-by
                [selected]="'fullName' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('fullName', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Celé jméno</sort-by>
            </th>
            <th>
              <sort-by
                [selected]="'username' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('username', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Přihlašovací jméno</sort-by>
            </th>
            <th>
              <sort-by
                [selected]="'email' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('email', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >E-mail</sort-by>
            </th>
            <th>Firma</th>
            <th>Přístup</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of list.list">
            <td class="text-center" (click)="userChecklist.toggleSelection(user); allUsersSelectedRecalculate()">
              <gmt-checkbox [gmtChecked]="userChecklist.isChecked(user)"></gmt-checkbox>
            </td>
            <td>{{ user.fullName }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.company | projectSubjectName }}</td>
            <td class="text-center"><i *ngIf="isProjectEnabled(user)" class="fa fa-check"></i></td>
            <td class="text-center">{{ getProjectRoles(user) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
      Nenalezena žádná data
    </div>
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" [shouldUpdateUrl]="false" (pageChanged)="pageChanged($event)" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  </div>
</div>

<div class="mt-10">
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>





