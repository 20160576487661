import { Component, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'map-module-move',
  template: '',
})
export class MapModuleMoveComponent implements OnInit {
  @Output()
  visibilityInfoboxCallback = new EventEmitter<any>();

  ngOnInit() {
    this.visibilityInfoboxCallback.emit({infoboxVisibility:false});
  }
}
