import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import * as _ from 'lodash';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'obligation-settings',
  templateUrl: './obligation-settings.component.html',
})
export class ObligationSettingsComponent implements OnInit {

  obligationId: number;
  obligation: any;
  loading = true;

  dueDateFromTypes = [
    { id: 'EntryProposalDate', name: 'Podání návrhu na vklad' },
    { id: 'CadastreEntryDate', name: 'Rozhodnutí KN' },
    { id: 'CadastreEntryDeliveryDate', name: 'Doručení rozhodnutí' },
    { id: 'ResponseReceivedSignedDate', name: 'Doručení podepsané smlouvy' },
    { id: 'SignedAllOwnersDate', name: 'Podpis všech vlastníků' },
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private dialogService: DialogService,
    private config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.obligationId = (<DialogConfigData>config.data).obligationId;
    this.update = this.update.bind(this);
  }

  ngOnInit() {
    this.restangular.one('obligations', this.obligationId).get().toPromise().then(data => {
      this.obligation = data.plain();
      this.loading = false;
      this.obligation.dueDateFromType = _.find(this.dueDateFromTypes, { id: data.dueDateFromType });
    });
  }

  update() {
    return this.restangular.one('obligations', this.obligationId)
      .customPUT({...this.obligation, dueDateFromType: this.obligation.dueDateFromType.id })
      .toPromise()
      .then(data => {
        this.dialog.close(data.plain());
      });
  }
}
