import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MapStorageHelpersService } from '@app/map/services/map-storage-helpers.service';
import * as  L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MapLayersStorageService {

  layers = [];

  constructor(
    private mapStorageHelpersService: MapStorageHelpersService
  ) { }

  /**
   * Adds Leaflet layer to given layers variable. Returns layers variable.
   * @memberof CmLayersStorage
   * @param {type} layers
   * @param {type} leafletLayer
   * @returns {Array} layers
   */
  addLayerToLayers(layers, leafletLayer) {
    if (!_.isArray(layers)) {
      layers = [];
    }

    layers.push(leafletLayer);

    return layers;
  }

  /**
   * Returns Leaflet layer object with given id.
   * @memberof CmLayersStorage
   * @param {Array} layers
   * @param {type} id
   * @returns {mixed} layer
   */
  getLayerById(layers, id) {
    var layer;

    if (_.isArray(layers) && id) {
      _.forEach(layers, function(value) {
        if (value._id === id) {
          layer = value;
        }

        if (value instanceof L.LayerGroup) {
          value.eachLayer(function(l) {
            if (l._id === id) {
              layer = l;
            }
          });
        }
      });
    }

    return layer;
  }

  /**
   * Returns deep copy of stored layers.
   * @memberof CmLayersStorage
   * @param {type} mapId
   * @returns {unresolved} promise
   */
  getLayers(mapId) {
    var defer = this.mapStorageHelpersService.getDefer(this.layers, mapId);
    return defer.promise;
  }

  /**
   * Sets layers to get layers promise.
   * @memberof CmLayersStorage
   * @param {type} c
   * @param {type} id
   */
  setLayers(c, id) {
    var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.layers, id);
    defer.resolve(c);
    this.mapStorageHelpersService.setResolvedDefer(this.layers, id);
  }

  /**
   * Removes Leaflet layer from given layers variable and returns it.
   * @memberof CmLayersStorage
   * @param {type} layers
   * @param {type} layerId
   * @returns {unresolved} layers
   */
  removeLayerFromLayers(layers, layerId) {
    if(_.isArray(layers) && layerId) {
      _.forEach(layers, function(value) {
        if(value instanceof L.LayerGroup) {
          _.remove(value.getLayers(), function(v: any) {
            return (v._id && v._id === layerId);
          });
        }
      });

      _.remove(layers, function(v) {
        return (v._id && v._id === layerId);
      });
    }

    return layers;
  }

  /**
   * Resets layer storage for given map id.
   * @memberof CmLayersStorage
   * @param {type} mapId
   */
  resetLayers(mapId) {
    this.layers[mapId] = [];
  }
}

