<div class="statistics templates">
  <div style="display:flex">
    <div class="line-height-l" style="min-width: 120px;">
      <a uiSref="symap.project.projectSettings"  class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
    </div>
  </div>

  <h1 class="mb-20">Šablony dokumentů</h1>

  <div [loading]="loading" class="flexbox-container">
    <div class="content-menu" *ngIf="!loading">
      <ng-container *ngFor="let templateType of templateTypes; let i = index">
        <div (click)="display(i)" [ngClass]="{ active: displayed === i }">{{ templateType.name }}</div>
      </ng-container>
    </div>

    <div *ngIf="!loading">
      <ng-container *ngFor="let templateType of templateTypes; let i = index">
        <div *ngIf="i === displayed" [loading]="templateType.loading">
          <div class="empty-info bordered p-20" *ngIf="!templateType.loading && !templateType.templates.length">Šablona není zadána.</div>
          <div *ngIf="!templateType.loading && templateType.templates.length" class="bordered p-20">
            <div *ngFor="let template of templateType.templates" class="mt-5 pb-5">
              <a href="{{ restBaseUrl }}/templates/{{ template.id }}/data?t={{ authToken }}">
                <i class="fa fa-file-o fa-lg"></i>{{ template.attachment.name }}
              </a>
              <a class="fa fa-times" *ngIf="templateType.modifiable" (click)="onRemoveTemplate(template)"></a>
            </div>
          </div>
          <div *ngIf="!templateType.loading && !project.useTemplatesService" [gmtAuth]="'manage_templates'">
            <div class="mt-20">
              <fileupload
                inputId="file-upload-{{ i }}"
                [(files)]="uploadingFiles"
                [disabled]="false"
                [message]="'Vložte šablony přetažením, nebo je vyberte z počítače.'"
                (change)="onSelectFiles($event)"
              ></fileupload>
            </div>
            <a class="button not-underline" uiSref="symap.project.projectSettings.templates.history" [uiParams]="{ key: templateType.key }">Zobrazit historii změn</a>
          </div>
        </div>
      </ng-container>
      <p *ngIf="!loading && templateTypes.length === 0">Žádné šablony nejsou ve zvoleném projektu používány.</p>
    </div>
  </div>

  <help [helpId]="helpIds.SETTINGS_TEMPLATES" class="cm-help-page"></help>
</div>
