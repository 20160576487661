import { Component, Input, Inject, OnInit, Output, EventEmitter } from "@angular/core";
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
	selector: 'form-case-sign-investor',
	templateUrl: './form-case-sign-investor.component.html',
})
export class FormCaseSignInvestorComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  // data shared for each case
  public data: any;
  @Input()
  // data set per case
  private dataCases: any[];
  @Input()
  // list of cases to fill in
  cases: any[];
  caseIndex = -1;
  firstCaseId;
  executionCaseName: string;

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.isValid = this.isValid.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  async ngOnInit() {
    this.data.signedInvestorDate = this.data.signedInvestorDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });

    this.next();
  }

  async previous() {
    if (this.caseIndex > this.firstCaseId) {
      this.caseIndex--;
      if (this.cases[this.caseIndex].obligation.type !== 'FulfillmentOfDecisionAuctionSaleExecutionLiability') {
        this.previous();
      }
    }
  }

  async next() {
    if (this.caseIndex >= 0) {
      this.dataCases[this.caseIndex] = {
        financialControlDate: this.cases[this.caseIndex].financialControlDate,
        maxAuctionAmount: this.cases[this.caseIndex].maxAuctionAmount,
      };
    }
    if (this.caseIndex + 1 < this.cases.length) {
      this.caseIndex++;
      if (this.cases[this.caseIndex].obligation.type !== 'FulfillmentOfDecisionAuctionSaleExecutionLiability') {
        this.next();
      } else if (this.firstCaseId === undefined) {
        this.executionCaseName = this.cases[this.caseIndex].obligation.name;
        this.firstCaseId = this.caseIndex;
      }
    } else {
      this.caseIndex++;
    }
  }

  isValid() {
    return !!this.data.signedInvestorDate && this.caseIndex === this.cases.length;
  }
}
