import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { roleOptions } from '@app/common/enums/role.enum';

@Pipe({ name: 'roleName' })
@Injectable({
  providedIn: 'root'
})
export class RolePipe implements PipeTransform {
  transform(value: string | any): string {
    if (!value) {
      return '';
    }

    const option = typeof value === 'string'
      ? roleOptions.find(option => option.id === value)
      : roleOptions.find(option => option.id === value.id);
    return option.name;
  }
}
