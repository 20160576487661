<div class="mb-20">
  <a (click)="onToggleCollapsed()" class="block-label">{{label}}
    <span class="arrow" [ngClass]="{'active': !collapsed}"></span>
  </a>
  <div class="bottom-corner">
  </div>
  <div class="bordered block-ellipsis" *ngIf="collapsed" (click)="onToggleCollapsed()">
    <i class="fa fa-ellipsis-h"></i>
  </div>
  <div *ngIf="!collapsed" style="position:relative;">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
