<div class="jumbotron">
  <div class="centered-text">
    Pro <span class="comma-list">
      <span *ngIf="data.easements.length"><b>{{data.easements.length}}</b> {{data.easements.length === 1 ? 'věcné břemeno' : (data.easements.length < 5 ? 'věcná břemena' : 'věcných břemen')}}</span>
      <span *ngIf="data.occupations.length"><b>{{data.occupations.length}}</b> {{data.occupations.length === 1 ? 'zábor' : (data.occupations.length < 5 ? 'zábory' : 'záborů')}}</span>
    </span>
    na
    <span class="comma-list">
      <span *ngIf="parcelCount"><b>{{parcelCount}}</b> {{parcelCount === 1 ? 'parcele' : 'parcelách'}}</span>
      <span *ngIf="data.buildings.length"><b>{{data.buildings.length}}</b> {{data.buildings.length === 1 ? 'budově' : 'budovách'}}</span>
    </span>
    bude vytvořen případ typu <b>{{data.obligation.name}}</b>
    <span *ngIf="data.obligation.computePrice"> v celkové výši <b>{{priceTotal|number|price}}</b></span>.
  </div>
</div>
<div class="mt-10">
  <gmt-button [clickAction]="create">Založit případ</gmt-button>
</div>
