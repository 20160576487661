﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({ name: 'price' })
@Injectable({
  providedIn: 'root'
})
export class PricePipe implements PipeTransform {
  nbsp = String.fromCharCode(160);

  transform(amount: number) {
    if (!amount) {
      return amount;
    }
    return `${amount}${this.nbsp}Kč`;
  }
}