import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '../common/common.module';
import { MainComponent } from './components/main/main.component';
import { RequestsComponent } from './components/requests/requests.component';
import { ProjectsComponent } from '@app/pk/components/projects/projects.component';
import { CreateProjectFirstStepComponent } from './components/create-project/create-project-first-step/create-project-first-step.component';
import { CreateProjectSecondStepComponent } from './components/create-project/create-project-second-step/create-project-second-step.component';
import { CreateProjectThirdStepComponent } from './components/create-project/create-project-third-step/create-project-third-step.component';
import { UserCredentialsComponent } from '../common/components/user-credentials/user-credentials.component';
import { SettingsComponent } from './components/settings/settings.component';
import { WsdpCredentialsComponent } from './components/wsdp-credentials/wsdp-credentials.component';
import { StatusMessagePipe } from './pipes/status-message.pipe';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';

export const pkState: Ng2StateDeclaration = {
  name: 'pk',
  url: '/promap',
  abstract: true,
  component: MainComponent,
};

export const projectsState: Ng2StateDeclaration = {
  name: 'pk.projects',
  url: '/projects?page',
  data: {
    title: 'Projekty',
  },
  views: {
    'content@pk': {
      component: RequestsComponent,
    }
  },
  params: {
    page: {
      dynamic: true,
      value: 1,
      type: 'int',
      squash: true,
      inherit: false,
    }
  },
};

export const createProjectFirstStep: Ng2StateDeclaration = {
  name: 'pk.projects.create-project-first',
  url: '/create-first',
  data: { title: 'Nová žádost - Vložení zájmového území' },
  views: {
    'content@pk': {
      component: CreateProjectFirstStepComponent
    }
  },
  params: {
    requestId: null
  }
};

export const createProjectSecondStep: Ng2StateDeclaration = {
  name: 'pk.projects.create-project-second',
  url: '/create-second',
  data: { title: 'Nová žádost - Identifikace žádosti' },
  views: {
    'content@pk': {
      component: CreateProjectSecondStepComponent
    }
  },
  params: {
    formData: null,
    type: null,
    requestId: null
  }
};

export const createProjectThirdStep: Ng2StateDeclaration = {
  name: 'pk.projects.create-project-third',
  url: '/create-third/:requestId',
  data: { title: 'Nová žádost - Odeslání žádosti' },
  views: {
    'content@pk': {
      component: CreateProjectThirdStepComponent
    }
  },
  params: {
    requestId: {
      type: 'string',
      value: null
    },
    title: {
      type: 'string',
      value: 'Nová žádost'
    },
    subtitle: {
      type: 'string',
      value: 'Odeslání žádosti'
    },
  }
};

export const settingsState: Ng2StateDeclaration = {
  name: 'pk.settings',
  url: '/settings',
  data: {
    title: 'Nastavení',
  },
  views: {
    'content@pk': {
      component: SettingsComponent,
    }
  }
};

export const userCredentials: Ng2StateDeclaration = {
  name: 'pk.settings.user-credentials',
  url: '/user-credentials',
  data: {
    title: 'Změna přihlašovacích údajů',
  },
  views: {
    'content@pk': {
      component: UserCredentialsComponent,
    }
  },
  params: {
    backSref: {
      type: 'string',
      value: 'pk.settings'
    },
  }
};

export const wsdpCredentials: Ng2StateDeclaration = {
  name: 'pk.settings.wsdp-credentials',
  url: '/wsdp-credentials',
  data: {
    title: 'Změna WSDP',
  },
  views: {
    'content@pk': {
      component: WsdpCredentialsComponent,
    }
  },
  params: {
    backSref: {
      type: 'string',
      value: 'pk.settings'
    },
  }
};

export const projectsGlobalState: Ng2StateDeclaration = {
  name: 'pk.projectsGlobal',
  url: '/projects-global?page',
  data: {
    title: 'Projekty a žádosti',
  },
  views: {
    'content@pk': {
      component: ProjectsComponent,
    }
  },
  params: {
    page: {
      dynamic: true,
      value: 1,
      type: 'int',
      squash: true,
      inherit: false,
    }
  },
};

@NgModule({
  imports: [
    NgbModule,
    HttpClientModule,
    CommonModule,
    UIRouterModule.forChild({
      states: [
        pkState,
        projectsState,
        createProjectFirstStep,
        createProjectSecondStep,
        createProjectThirdStep,
        settingsState,
        userCredentials,
        wsdpCredentials,
        projectsGlobalState
      ]
    }),
  ],
  providers: [],
  declarations: [
    MainComponent,
    RequestsComponent,
    ProjectsComponent,
    CreateProjectFirstStepComponent,
    CreateProjectSecondStepComponent,
    CreateProjectThirdStepComponent,
    SettingsComponent,
    WsdpCredentialsComponent,
    StatusMessagePipe
  ],
  entryComponents: [
  ]
})


export class PkAppModule {}
