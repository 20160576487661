import {Component, OnInit, HostListener, Inject, Input} from '@angular/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { OSM, TileWMS, WMTS, Vector } from 'ol/source';
import { Layer, Vector as VectorLayer } from 'ol/layer';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { register} from 'ol/proj/proj4';
import { get as getProjection } from 'ol/proj';
import { Style, Stroke, Fill, Icon } from 'ol/style';
import proj4 from 'proj4';
import { WMTSCapabilities, GML } from 'ol/format';
import { DialogService } from '@app/common/services/dialog.service';
import { UploadService } from '@app/common/services/upload.service';
import { AuthService } from '@app/common/services/auth.service';
import { APP_CONFIG } from '@app/common/services/config.service';
import { IndexedDbStorageService } from '@app/common/services/indexed-db-storage.service';

@Component({
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  static readonly MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';

  static readonly VFZE_LAYERS_TMP = {
    akce:           { name: 'Zájmové území akce',       zIndex: 110, fillColor: '#2F6E9E50', strokeColor: '#2F6E9E', features: [], dxf: "SELECT nazev AS Text, 'zajmove_uzemi_akce' AS Layer,    'BRUSH(fc:#2F6E9E)' AS OGR_STYLE, geometry FROM akce WHERE geometry IS NOT NULL" },
    parcela:        { name: 'Analogové parcely KN',     zIndex: 130, fillColor: '#ffa50050', strokeColor: '#ffa500', features: [], dxf: "SELECT id AS Text, 'analogove_parcely_kn' AS Layer,     'BRUSH(fc:#ffa500)' AS OGR_STYLE, geometry FROM parcela WHERE geometry IS NOT NULL" },
    gpParcela:      { name: 'Parcely nezapsaných GP',   zIndex: 140, fillColor: '#cc000050', strokeColor: '#cc0000', features: [], dxf: "SELECT id AS Text, 'parcely_nezapsanych_gp' AS Layer,   'BRUSH(fc:#cc0000)' AS OGR_STYLE, geometry FROM gpParcela WHERE geometry IS NOT NULL" },
    zabor11:        { name: 'Trvalý zábor',             zIndex: 150, fillColor: '#e87fff50', strokeColor: '#e87fff', features: [], dxf: "SELECT id AS Text, 'trvaly_zabor' AS Layer,             'BRUSH(fc:#e87fff)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'trvalý zábor' AND geometry IS NOT NULL" },
    zabor12:        { name: 'Trvalý zábor bez výkupu',  zIndex: 150, fillColor: '#eabcf550', strokeColor: '#eabcf5', features: [], dxf: "SELECT id AS Text, 'trvaly_zabor_bez_vykupu' AS Layer,  'BRUSH(fc:#eabcf5)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'trvalý zábor bez výkupu' AND geometry IS NOT NULL" },
    zabor21:        { name: 'Dočasný zábor nad 1 rok',  zIndex: 150, fillColor: '#91e5ff50', strokeColor: '#91e5ff', features: [], dxf: "SELECT id AS Text, 'docasny_zabor_nad_1_rok' AS Layer,  'BRUSH(fc:#91e5ff)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'dočasný zábor nad 1 rok' AND geometry IS NOT NULL" },
    zabor22:        { name: 'Dočasný zábor do 1 roku',  zIndex: 150, fillColor: '#bfff9650', strokeColor: '#bfff96', features: [], dxf: "SELECT id AS Text, 'docasny_zabor_do_1_roku' AS Layer,  'BRUSH(fc:#bfff96)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'dočasný zábor do 1 roku' AND geometry IS NOT NULL" },
    zabor31:        { name: 'Jiné dotčení',             zIndex: 150, fillColor: '#96ff9650', strokeColor: '#96ff96', features: [], dxf: "SELECT id AS Text, 'jine_dotceni' AS Layer,             'BRUSH(fc:#96ff96)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'jiné dotčení' AND geometry IS NOT NULL" },
    zabor41:        { name: 'Vyvedení',                 zIndex: 150, fillColor: '#00000050', strokeColor: '#000000', features: [], dxf: "SELECT id AS Text, 'vyvedeni' AS Layer,                 'BRUSH(fc:#000000)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'vyvedení' AND geometry IS NOT NULL" },
    zabor42:        { name: 'Zbytková část parcely',    zIndex: 150, fillColor: '#f1d2f850', strokeColor: '#f1d2f8', features: [], dxf: "SELECT id AS Text, 'zbytkova_cast_parcely' AS Layer,    'BRUSH(fc:#f1d2f8)' AS OGR_STYLE, geometry FROM zabor WHERE typ = 'zbytková část parcely' AND geometry IS NOT NULL" },
    objekt:         { name: 'Stavební objekty',         zIndex: 160, fillColor: '#fdae6150', strokeColor: '#fdae61', features: [], dxf: "SELECT id AS Text, 'stavebni_objekty' AS Layer,         'BRUSH(fc:#fdae61)' AS OGR_STYLE, geometry FROM objekt WHERE geometry IS NOT NULL" },
    vb:             { name: 'Věcná břemena',            zIndex: 170, fillColor: '#c8c8c850', strokeColor: '#c8c8c8', features: [], dxf: "SELECT id AS Text, 'vecna_bremena' AS Layer,            'BRUSH(fc:#c8c8c8)' AS OGR_STYLE, geometry FROM vb WHERE geometry IS NOT NULL" },
    ochrannePasmo:  { name: 'Ochranne pásmo',           zIndex: 180, fillColor: '#dafc7550', strokeColor: '#dafc75', features: [], dxf: "SELECT id AS Text, 'ochranne_pasmo' AS Layer,           'BRUSH(fc:#dafc75)' AS OGR_STYLE, geometry FROM ochrannePasmo WHERE geometry IS NOT NULL" },
    export:         { name: 'Export',                   zIndex: 100, fillColor: '', fillOpacity: 1, strokeColor: '', features: [] },
    subjekt:        { name: 'Subjekt',                  zIndex: 100, fillColor: '', fillOpacity: 1, strokeColor: '', features: [] },
    gpInfo:         { name: 'GP',                       zIndex: 100, fillColor: '', fillOpacity: 1, strokeColor: '', features: [] },
  };

  map: Map;
  projection;
  clickedFeatures = [];
  clickedFeaturesMaxHeight;
  loading = true;
  vfzeFileContent: string;
  isArray = Array.isArray;
  private gmlTxt: string;
  private features: any[];
  private xsltProcessor = new XSLTProcessor();
  private authToken = this.authService.getToken();

  constructor(
    private dialogService: DialogService,
    private uploadService: UploadService,
    private authService: AuthService,
    private indexedDbStorage: IndexedDbStorageService,
    @Inject(APP_CONFIG) private config: any,
  ) {
    this.onShowVfze = this.onShowVfze.bind(this);
    this.fitMap = this.fitMap.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onGmlDownload = this.onGmlDownload.bind(this);
    this.onCsvDownload = this.onCsvDownload.bind(this);
    this.onDxfDownload = this.onDxfDownload.bind(this);
  }

  async ngOnInit() {
    this.vfzeFileContent = await this.indexedDbStorage.load('vfze');
    this.loading = false;

    if (!this.vfzeFileContent) {
      return;
    }

    this.setupXlstProcessor(() => {
        this.fitMap();
        this.onShowVfze();
    });
  }

  initMap() {
    proj4.defs('EPSG:5514', '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813975277778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +units=m +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56');
    register(proj4);
    this.projection = getProjection('EPSG:5514');

    this.map = new Map({
      target: 'map',
      layers: [],
      view: new View({
        zoom: 16,
        projection: this.projection
      })
    });

    this.map.on('singleclick', this.onClick);

    this.createBaseLayers();
  }

  onShowVfze() {
    if (!this.map) {
      this.initMap();
    }

    for (const l of this.getLayers('vfze')) {
      this.map.removeLayer(l);
    }

    const parser = new DOMParser();
    const serializer = new XMLSerializer();

    const xmlDoc = parser.parseFromString(this.vfzeFileContent, 'application/xml');
    let resultDoc = this.xsltProcessor.transformToDocument(xmlDoc);
    // Hack - nahrada gml verze
    const resultDocTxt = serializer.serializeToString(resultDoc).replace(/\/3.2/g, '');
    this.gmlTxt = resultDocTxt;
    resultDoc = parser.parseFromString(resultDocTxt, 'application/xml');
    this.createVfzeLayers(resultDoc);
  }

  getLayers(group: string): any[] {
    return this.map.getLayers().getArray().filter(l => l.get('group') === group);
  }

  onClick(event) {
    this.clickedFeatures = [];

    this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
      const name = layer.get('name');
      const group = this.clickedFeatures.find(g => g.name === name);
      const propArr = this.propertiesToArr(feature.getProperties());
      if (!group) {
        this.clickedFeatures.push({ name: name, features: [propArr]});
      } else {
        group.features.push(propArr);
      }
    });
  }

  onGmlDownload() {
    saveAs(new Blob([this.gmlTxt], { type: 'application/xml;charset=utf-8' }), `vfze.gml`, true);
  }

  onCsvDownload() {
    const groupedFeatures = {};
    for (const f of this.features) {
      const group = f.get('vfzeTyp');
      if (groupedFeatures[group]) {
        groupedFeatures[group].push(f.getProperties());
      } else {
        groupedFeatures[group] = [f.getProperties()];
      }
    }

    const zip = new JSZip();

    for (const group of Object.keys(groupedFeatures)) {
      const csv = this.prepareCsvFile(groupedFeatures[group]);
      zip.file(`${group}.csv`, csv);

    }

    zip.generateAsync({type: 'blob'}).then(content => {
      saveAs(content, 'vfze.zip');
    });
  }

  onDxfDownload() {
    const formData = new FormData();
    const sql = [];

    this.getLayers('vfze').forEach(l => {
      sql.push(MapComponent.VFZE_LAYERS_TMP[l.get('id')].dxf);
    });
    const sqlText = sql.join(' UNION ALL ');

    formData.append('sql', new Blob([sqlText], { type: 'plain/text;charset=utf-8' }));
    formData.append('gml', new Blob([this.gmlTxt], { type: 'application/xml;charset=utf-8' }));

    return this.uploadService.upload({
      url: `${this.config.BACKEND_OPTIONS.restUrlPkProxy}/geometry/gml2dxf`,
      formData: formData,
      headers: { Authorization: this.authToken },
      responseType: 'blob'
    }).then((data) => {
      saveAs(data, 'vfze.dxf');
    });
  }

  @HostListener('window:resize')
  fitMap() {
    const mapEl = $('#map');
    const top = mapEl.offset().top;
    const left = mapEl.offset().left;
    const windowHeight = $(window).height();
    const windowWidth = $(window).width();
    mapEl.height(windowHeight - top - 30);
    mapEl.width(windowWidth - left - 30);
    this.clickedFeaturesMaxHeight = windowHeight - top - 120 + 'px';
  }

  propertiesToArr(properties: any): any[] {
    return Object.keys(properties)
      .filter(k => k !== 'geometry' && k !== 'vfzeTyp')
      .map(k => {
        if (typeof properties[k] === 'object') {
          return { name: k, value: this.propertiesToArr(properties[k]) };
        }
        return { name: k, value: properties[k] };
      });
  }

  private async createBaseLayers() {
    // kn map layer
    const knKmLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://services.cuzk.cz/wms/local-KM-wms.asp',
        params: { LAYERS: 'KN' },
        projection: this.projection
      }),
      visible: true
    });

    knKmLayer.set('group', 'base');
    knKmLayer.set('id', 'knKm');
    knKmLayer.set('name', 'WMS ČÚZK KN mapa');
    knKmLayer.set('icon', 'img/map/border-1-solid-000000.svg');
    knKmLayer.setZIndex(40);
    this.map.addLayer(knKmLayer);

    // osm layer
    const osmLayer = new TileLayer({
      source: new OSM(),
      visible: false
    });

    osmLayer.set('group', 'base');
    osmLayer.set('id', 'osm');
    osmLayer.set('name', 'Open Street Map');
    osmLayer.set('icon', 'img/map/fill-299631.svg');
    osmLayer.setZIndex(30);
    this.map.addLayer(osmLayer);

    // ztm layer
    const ztmTileLayer = await fetch('https://ags.cuzk.cz/arcgis1/rest/services/ZTM/MapServer/WMTS/1.0.0/WMTSCapabilities.xml')
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        const parser = new WMTSCapabilities();
        const result = parser.read(text);
        const options = optionsFromCapabilities(result, {
          layer: 'ZTM',
          matrixSet: 'default028mm',
        });

        return new TileLayer({
          source: new WMTS(options),
          visible: false
        });
      });

    ztmTileLayer.set('group', 'base');
    ztmTileLayer.set('id', 'ztm-tile');
    ztmTileLayer.set('name', 'Základní mapy');
    ztmTileLayer.set('icon', 'img/map/zm.png');
    ztmTileLayer.setZIndex(20);
    this.map.addLayer(ztmTileLayer);

    // orto layer
    const ortoLayer = new TileLayer({
      source: new TileWMS({
        url: 'https://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx',
        params: { LAYERS: 'GR_ORTFOTORGB' },
        projection: this.projection
      }),
      visible: true
    });

    ortoLayer.set('group', 'base');
    ortoLayer.set('id', 'orto');
    ortoLayer.set('name', 'Ortofoto ČÚZK');
    ortoLayer.set('icon', 'img/map/orthofoto.png');
    ortoLayer.setZIndex(10);
    this.map.addLayer(ortoLayer);
  }

  private createVfzeLayers(gml: Document) {
    const format = new GML({
      srsName: 'EPSG:5514'
    });

    this.features = format.readFeatures(gml, {
      featureProjection: 'EPSG:5514',
      dataProjection: 'EPSG:5514'
    });

    const layers = JSON.parse(JSON.stringify(MapComponent.VFZE_LAYERS_TMP));
    let hasGeometry = false;

    for (const f of this.features) {
      let type = f.get('vfzeTyp');
      const occupationType = f.get('typKod');
      type = type === 'zabor' ? (type + occupationType) : type;

      if (f.getGeometry()) {
        hasGeometry = true;
        layers[type].features.push(f);
      }
    }

    if (!hasGeometry) {
      this.showError();
      return;
    }

    for (const type of Object.keys(layers)) {
      const l = layers[type];
      if (!l.features.length) {
        continue;
      }

      const vector = new VectorLayer({
        source: new Vector()
      });

      const style = new Style({
        fill: new Fill({ color: l.fillColor }),
        stroke: new Stroke({ color: l.strokeColor, width: 1 })
      });

      vector.getSource().addFeatures(l.features);
      vector.set('group', 'vfze');
      vector.set('id', type);
      vector.set('name', l.name);
      vector.set('color', l.strokeColor);
      vector.setZIndex(l.zIndex);
      vector.setStyle(style);
      this.map.addLayer(vector);
    }

    if (layers.akce.features.length) {
      const extent = layers.akce.features[0].getGeometry().getExtent();
      this.map.getView().fit(extent, { duration: 1000 });
    }
  }

  private prefixParcelObj(obj: any, prefix: string) {
    const prefixed = {};
    Object.keys(obj).forEach((key) => {
      if (key !== 'geometry' && key !== 'vfzeTyp') {
        prefixed[prefix + '_' + key] = obj[key];
      }
    });
    return prefixed;
  }

  private prepareCsvFile(data: any[]): string {
    const headers = new Set();

    data.forEach((obj) => {
      if (obj.parcela && typeof obj.parcela === 'object') {
        const parcela = obj.parcela;
        delete obj.parcela;
        obj = Object.assign(obj, this.prefixParcelObj(parcela, 'parcela'));
      }
      if (obj.gpParcela && typeof obj.gpParcela === 'object') {
        const gpParcela = obj.gpParcela;
        delete obj.gpParcela;
        obj = Object.assign(obj, this.prefixParcelObj(gpParcela, 'gpParcela'));
      }
      Object.keys(obj).forEach((key) => {
        if (key !== 'geometry' && key !== 'vfzeTyp') {
          headers.add(key);
        }
      });
    });

    const csvHeaders = Array.from(headers);
    let csv = csvHeaders.join(';') + '\n';

    csv += data.map((row) => {
      return csvHeaders.map((fieldName) => {
        return row[fieldName] !== undefined ? JSON.stringify(row[fieldName]) : '';
      }).join(';');
    }).join('\n');

    return csv;
  }

  private showError() {
    return this.dialogService.alertDialogPromise('Nepodařilo se zpracovat soubor.');
  }

  private setupXlstProcessor(callback: Function) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'assets/transform.xslt', true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const xslDoc = xhr.responseXML;
        this.xsltProcessor.importStylesheet(xslDoc);
        callback();
      }
    };

    xhr.send();
  }
}
