<div class="list">
  <div class="actions">
    <a class="button" *ngIf="importedObjectsExists" uiSref="symap.project.constructionObjects.constructionObjectsImported"><i class="fa fa-plus-square"></i>Připravované {{ "CONSTRUCTION_OBJECT_PLURAL_NOMINATIV" | wordtranslate }}</a>
    <a class="button" gmtAuth="admin,manage_input_data" uiSref="symap.project.constructionObjects.newConstructionObject"><i class="fa fa-plus-square"></i>Založit {{ "NEW_CONSTRUCTION_OBJECT_SHORTCUT" | wordtranslate }}</a>
    <a class="button" gmtAuth="admin,manage_input_data" uiSref="symap.project.constructionObjects.newEasement"><i class="fa fa-plus-square"></i>Vložit nové věcné břemeno</a>
  </div>

  <h1>{{ 'CONSTRUCTION_OBJECT_PLURAL_NOMINATIV' | wordtranslate:true }}</h1>

  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectStageId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'name' } }"
      field="values"
      resource="construction-object-stages"
      selectTitle="{{ 'ETAPA_USEK' | wordtranslate }}" logGa
    ></gmt-select>

    <gmt-select *ngIf="!hideColumn('state')"
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectStatusId"
      field="values"
      resource="construction-object-statuses"
      selectTitle="Stav"
      logGa
    ></gmt-select>

    <gmt-select *ngIf="!hideColumn('mandatary')"
      [multiselect]="true"
      [data]="list.filter.filters.userId"
      [filter]="mandataryFilter"
      field="values"
      resource="users/list"
      selectTitle="Přidělení {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }}"
      [itemPipe]="userNamePipe"
      logGa
      [restangularService]="restangularSettings"
    ></gmt-select>

    <div
      class="tag"
      [ngClass]="{ active: isGeometricPlansChecked() }"
      title="Zobrazení {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV' | wordtranslate }} s GP na věcná břemena"
      (click)="onHideWithoutGP()"
    ><div>Zobrazit pouze věcná břemena</div></div>

    <div
      class="tag"
      [ngClass]="{ active: isAdministratorInvestor() }"
      title="Zobrazení {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV' | wordtranslate }} kde je správcem investor"
      (click)="onAdministratorInvestorToggle()"
    ><div>Zobrazit pouze {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }} - správce investor</div></div>

    <div class="column-100">
      <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount && settingsLoaded">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">

            <span *ngIf="!hideColumn('co')" class="cell">
              <sort-by
                [selected]="'socis' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('socis', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('co') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('phase')" class="cell">
              <sort-by
                [selected]="'sousek' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('sousek', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('phase') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('name')" class="cell">
              <sort-by
                [selected]="'sotx' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('sotx', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('name') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('administrator')" class="cell">
              <sort-by
                [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1]?.sortBy === 'detail.administrator.companyName'"
                (sort)="pageableList.onSort(['detail.administrator.companyName', 'detail.administrator.surname'], $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('administrator') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('state')" class="cell">
              <sort-by
                [selected]="'detail.constructionObjectStatus.name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('detail.constructionObjectStatus.name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('state') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('coSolution')" class="cell">
              <sort-by
                [selected]="'detail.constructionObjectSolution.name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('detail.constructionObjectSolution.name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >{{ getColumnName('coSolution') }}</sort-by>
            </span>

            <span *ngIf="!hideColumn('realizationDate')" class="cell">{{ getColumnName('realizationDate') }}</span>
            <span *ngIf="!hideColumn('mandatary')" class="cell">{{ getColumnName('mandatary') }}</span>
            <span *ngIf="!hideColumn('permanentOccupationState')" class="cell">{{ getColumnName('permanentOccupationState') }}</span>
            <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell">{{ getColumnName('overOneYearOccupationState') }}</span>
            <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell">{{ getColumnName('underOneYearOccupationState') }}</span>
            <span *ngIf="!hideColumn('easementCaseState')" class="cell">{{ getColumnName('easementCaseState') }}</span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let constructionObject of list.list" uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: constructionObject.id, page: null }">
            <span *ngIf="!hideColumn('co')" class="cell" style="white-space: nowrap;">
              {{ constructionObject.socis }} <span *ngIf="constructionObject.geometricalPlanExists" class="badge badge-blue">VB</span>
            </span>
            <span *ngIf="!hideColumn('phase')" class="cell number">{{ constructionObject.sousek }}</span>
            <span *ngIf="!hideColumn('name')" class="cell">{{ constructionObject.sotx }}</span>
            <span *ngIf="!hideColumn('administrator')" class="cell">{{ (constructionObject.administrator?.customer?.rootCustomer || constructionObject.administrator) | projectSubjectName:false:true }}</span>
            <span *ngIf="!hideColumn('state')" class="cell">{{ constructionObject.constructionObjectStatus?.name }}</span>
            <span *ngIf="!hideColumn('coSolution')" class="cell">{{ constructionObject.constructionObjectSolution?.name }}</span>
            <span *ngIf="!hideColumn('realizationDate')" class="cell centered-text">{{ constructionObject.realizationDate | date }}</span>

            <span *ngIf="!hideColumn('mandatary')" class="cell" style="line-height:15px;">
              <span gmtAuthNot="assignment_edit">{{ constructionObject.user | username }}</span>
              <gmt-select
                gmtAuth="assignment_edit"
                [data]="constructionObject"
                field="user"
                [optionItems]="users"
                [itemPipe]="userNamePipe"
                required="true"
                (click)="$event.preventDefault(); $event.stopPropagation();"
                (changed)="onUpdateUser(constructionObject.id, constructionObject.user.id);"
              ></gmt-select>
              <span *ngIf="userSending[constructionObject.id]" class="ib sending"><span class="fa fa-circle-o-notch fa-spin"></span> Ukládání...</span>
            </span>

            <!-- trvale zabory -->
            <span *ngIf="!hideColumn('permanentOccupationState')" class="cell no-padding cell--chart cell-max-height">
              <settlement-progress-chart
                [tooltipType]="'parcels'"
                [tooltipData]="{ occupationResolution: constructionObject.occupationResolution, occupationCategory: 'permanent' }"
                [data]="constructionObject.occupationResolution | settlementCategory: 'permanent'"
              ></settlement-progress-chart>
            </span>

            <!-- docasne zabory nad jeden rok -->
            <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell no-padding cell--chart cell-max-height">
              <settlement-progress-chart
                [tooltipType]="'parcels'"
                [tooltipData]="{ occupationResolution: constructionObject.occupationResolution, occupationCategory: 'overOneYear' }"
                [data]="constructionObject.occupationResolution | settlementCategory: 'overOneYear'"
              ></settlement-progress-chart>
            </span>

            <!-- docasne zabory do jednoho roku -->
            <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell no-padding cell--chart cell-max-height">
              <settlement-progress-chart
                [tooltipType]="'parcels'"
                [tooltipData]="{ occupationResolution: constructionObject.occupationResolution, occupationCategory: 'underOneYear' }"
                [data]="constructionObject.occupationResolution | settlementCategory: 'underOneYear'"
              ></settlement-progress-chart>
            </span>

            <!-- vecna bremena -->
            <span *ngIf="!hideColumn('easementCaseState')" class="cell no-padding cell--chart cell-max-height">
              <settlement-progress-chart
                tooltipType="easements"
                [tooltipData]="{ occupationResolution: constructionObject.occupationResolution, occupationCategory: 'easement' }"
                [data]="constructionObject.occupationResolution | settlementCategory: 'easement'"
              ></settlement-progress-chart>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.PANEL_CONSTRUCTION_OBJECTS" class="cm-help-page"></help>
</div>
