import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { SimpleCaseStatusFormFieldModel } from '@app/ps/models/simple-case-status-form-field.model';

@Component({
  selector: 'simple-case-status-form',
  templateUrl: './simple-case-status-form.component.html',
  styleUrls: ['./simple-case-status-form.component.scss']
})
export class SimpleCaseStatusFormComponent implements OnInit {

  @Output() callbackRegister = new EventEmitter();
  @Input() data: CaseModel;
  @Input() config: SimpleCaseStatusFormFieldModel[];

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    for (const formField of this.config) {
      if (formField.default && formField.default === 'currentDate') {
        this.data[formField.field] = this.data[formField.field] || new Date();
      }
    }

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    for (const formField of this.config) {
      if (formField.required && !this.data[formField.field]) {
        return false;
      }
    }
    return true;
  }

  onChange($event, formField) {
    this.data[formField.field] = $event;
    if ((typeof formField.onChange) === 'function') {
      formField.onChange(this.data);
    }
  }
}
