import { Component, ElementRef, OnInit } from '@angular/core';
import {DialogRef} from "@app/common/services/dialog-ref";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";

@Component({
  selector: 'map-print-layers',
  styleUrls: ['./map-print-layers.component.scss'],
  templateUrl: './map-print-layers.component.html',
})
export class MapPrintLayersComponent implements OnInit {
  layers = [];
  currentlyEditedLayer;

  constructor(
    private elementRef: ElementRef,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
  ) {
    this.layers = (dialogConfig.data as DialogConfigData).layers;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
  }

  onSave() {
    this.dialogRef.close(this.layers);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onLayerNameBlur(layer) {
    this.currentlyEditedLayer = undefined;

    if (layer.title.trim() === '') {
      layer.title = layer.defaultTitle;
    }
  }

  onLayerNameEdit(layer) {
    this.currentlyEditedLayer = layer;
  }
}
