<div class="tasks">
  <h1>
    Nastavení
  </h1>

  <div class="home-actions mt-40">
    <div>
      <a *ngIf="APP_BRAND.NAME !== 'RSD' && APP_BRAND.NAME !== 'SZ'" uiSref="symap.project.projectSettings.userCredentials" class="button">
        <i class="fa fa-key"></i>Změna přihlašovacích údajů
      </a>
    </div>
    <div>
      <a uiSref="symap.project.projectSettings.experts" class="button">
        <i class="fa fa-user-circle"></i>Odhadci
      </a>
    </div>
    <div>
      <a uiSref="symap.project.projectSettings.administrators" class="button">
        <i class="fa fa-user-secret"></i>Správci {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV_SHORTCUT' | wordtranslate }}
      </a>
    </div>
    <div *ngIf="useTemplatesService">
      <a uiSref="symap.project.projectSettings.templates" class="button">
        <i class="fa fa-file-text-o"></i>Vzory dokumentů
      </a>
    </div>
    <div *ngIf="!useTemplatesService">
      <a uiSref="symap.project.projectSettings.templates" class="button">
        <i class="fa fa-file-text-o"></i>Šablony dokumentů
      </a>
    </div>
    <div gmtAuth="admin,manage_properties">
      <a uiSref="symap.project.projectSettings.bulkPrices" class="button">
        <i class="fa fa-users"></i>Hromadné nastavení cen
      </a>
    </div>
    <div gmtAuth="admin,manage_properties">
      <a uiSref="symap.project.projectSettings.users" class="button">
        <i class="fa fa-users"></i>Správa uživatelů
      </a>
    </div>
    <div gmtAuth="admin">
      <a uiSref="symap.project.projectSettings.update" title="Upravit projekt" class="button">
        <i class="fa fa-pencil"></i>{{ 'ps.projectSettings' | brandTranslate }}
      </a>
    </div>
    <div gmtAuth="admin">
      <a uiSref="symap.project.projectSettings.create" title="Nový projekt" class="button">
        <i class="fa fa-plus"></i>Nový projekt
      </a>
    </div>
    <div *ngIf="user.superuser">
      <a (click)="onRemoveProject()" title="Odstranit projekt" class="button">
        <i class="fa fa-trash"></i>Odstranit projekt
      </a>
    </div>
  </div>

  <help [helpId]="helpIds.PANEL_SETTINGS" class="cm-help-page"></help>
</div>
