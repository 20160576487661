import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { Component, OnInit } from '@angular/core';

import { OpinionRequestModel } from '@app/ps/models/opinion-request.model'
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { AuthService } from '@app/common/services/auth.service';
import { ExpertModel } from '@app/ps/models/expert.model';

@Component({
  selector: 'opinion-request-edit',
  templateUrl: './opinion-request-edit.component.html',
  styleUrls: ['./opinion-request-edit.component.scss']
})
export class OpinionRequestEditComponent implements OnInit {

  editMode: 'change-status' | 'edit';
  newStatus: string;
  opinionRequest: OpinionRequestModel;
  statusEnum = OpinionRequestStatusEnum;
  filesMsg = OpinionRequestEditComponent.MSG_FILES_DEFAULT;
  files: UploadFileExtended[];
  filesValid = false;

  static readonly MSG_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
  static readonly MSG_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  static readonly STATUSES_CONFIG = [
    {
      status: OpinionRequestStatusEnum.REALIZATION_STARTED,
      fields: [
        'realizationStartedDate',
        'expert',
        'realizationFinishExpectedDate',
      ]
    },
    {
      status: OpinionRequestStatusEnum.REALIZED,
      fields: [
        'realizedDate',
        'realizationPrice',
        'attachments',
      ]
    },
    {
      status: OpinionRequestStatusEnum.DELETED,
      fields: [
        'deletedDate',
      ]
    },
  ];

  constructor(
    public requestService: OpinionRequestService,
    public authService: AuthService,
    public dialogComponent: DialogRef,
    private dialogConfig: DialogConfig,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onExpertSelected = this.onExpertSelected.bind(this);
  }

  ngOnInit() {
    const data = <DialogConfigData>this.dialogConfig.data;
    this.newStatus = data.newStatus;
    this.editMode = data.editMode;
    this.opinionRequest = data.opinionRequest;
  }

  isValid(): boolean {
    if (this.editMode === 'edit') {
      return true;
    }

    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    const fieldsValidity = statusConfig.fields
      .filter(n => n !== 'realizationPrice' && n !== 'attachments')
      .map(n => !!this.opinionRequest[n])
      .every(b => b);

    return (fieldsValidity && (this.newStatus !== OpinionRequestStatusEnum.REALIZED || this.filesValid));
  }

  isFieldVisible(field: string): boolean {
    if (this.editMode === 'edit') {
      if (field.indexOf('Date') > 0 && this.opinionRequest[field] === undefined) {
        return false;
      } else if (['name', 'realizedDate'].includes(field) && <string>OpinionRequestStatusEnum.PRICES_FILLED === this.opinionRequest.status) {
        return false;
      } else {
        return true;
      }
    }

    const statusConfig = this.getStatusConfig();

    if (!statusConfig) {
      return false;
    }

    return statusConfig.fields.includes(field);
  }

  onExpertSelected(expert: ExpertModel) {
    if (this.opinionRequest) {
      this.opinionRequest.expert = expert;
    }
  }

  onFileChange(files: UploadFileExtended[]) {
    this.files = files;
    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'pdf');

    if (this.files.length === 0) {
      this.filesMsg = OpinionRequestEditComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.filesMsg = OpinionRequestEditComponent.MSG_FILES_EXTENSION;
    } else if (this.files.length > 1) {
      this.filesMsg = OpinionRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.filesMsg = '';
    }

    this.filesValid = (extensionValid && this.files.length === 1);
  }

  onSave() {
    if (!this.isValid()) {
      return;
    }

    if (this.newStatus) {
      this.opinionRequest.status = this.newStatus;
    }

    this.dialogComponent.close({ opinionRequest: this.opinionRequest, files: this.files });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }

  private getStatusConfig() {
    return OpinionRequestEditComponent.STATUSES_CONFIG.find(sc => sc.status === this.newStatus);
  }
}
