import {Component, Input, OnInit, Inject, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { MapCallbackEnum } from '@app/map/enums/map-callback.enum';
import { MapLayerSelectorService } from '@app/map/services/map-layer-selector.service';
import { MapLayerService } from '@app/map/services/map-layer.service';
import { Transition } from '@uirouter/core';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-2-local-storage';
import {CallbackModel} from "@app/common/models/callback.model";

@Component({
  selector: 'map-layer-selector',
  templateUrl: './map-layer-selector.component.html',
})
export class MapLayerSelectorComponent implements OnInit, OnDestroy {
  @Input() options: any;
  @Input() config: any;
  @Input() mapId: string;
  public getCollapsedState: Function;


  constructor(
    private mapLayerSelectorService: MapLayerSelectorService,
    private localStorageService: LocalStorageService,
    private mapLayerService: MapLayerService,
    private cdRef: ChangeDetectorRef,
    @Inject('$transition$') private transition: Transition,
  ) {
    this.switchLayers = this.switchLayers.bind(this);
  }

  async ngOnInit() {
    this.getCollapsedState = this.mapLayerSelectorService.getCollapsedState;
    this.mapLayerService.registerCallback(MapCallbackEnum.layersVisibilityChanged, this.switchLayers);

    // default options array
    const options = {
      saveLayersVisibility: false,
      saveGroupCollapseStatus: false,
      showCollapsedVisibleLayersCount: false,
      storagePrefix: ''
    };

    // used to chain asynchronous layer initialization
    // let layerInit = $q.resolve();

    // merge defaults with user provided values
    Object.assign(options, this.options);

    if (Array.isArray(this.transition.params().layer)) {
      await this.layersShow(this.config, this.transition.params().layer);
      if (this.isOptionsSaveLayersVisibility() === true) {
        this.saveConfig();
      }
      return;
    }

    if (this.isOptionsSaveGroupCollapseStatus() === true) {
      this.updateCollapsed(this.config);
    }

    if (this.isOptionsSaveLayersVisibility() === true) {
      this.loadConfig();
    }
  }

  ngOnDestroy() {
    this.mapLayerService.unregisterCallback(MapCallbackEnum.layersVisibilityChanged, this.switchLayers);
  }

  // updates layer and sub-layer collapsed flag configuration from local storage
  updateCollapsed(layer) {
    const isCollapsed = this.localStorageService.get(this.getLayerGroupVisibilityId(layer.id));
    if (isCollapsed === true) {
      layer.collapsed = true;
    } else {
      layer.collapsed = false;
    }

    if (Array.isArray(layer.layers)) {
      layer.layers.forEach((subLayer) => {
        this.updateCollapsed(subLayer);
      });
      layer.hasVisibleItems = layer.layers.some(l => !l.collapsed);
    }
  }

  hideLayerObjects(layerIds) {
    if (!Array.isArray(layerIds)) {
      return;
    }
    layerIds.forEach((id) => {
      const config = this.mapLayerSelectorService.getObjectConfig(this.config, id);
      if (config.selector === 'group') {
        this.hideGroup(config);
      } else {
        this.hideLayer(config);
      }
    });
  }

  hideLayer(layerConfig) {
    if (_.isString(layerConfig)) {
        layerConfig = this.mapLayerSelectorService.getObjectConfig(this.config, layerConfig);
    }
    if (layerConfig.visible) {
        this.switchLayer(layerConfig);
    }
  }

  hideGroup(groupConfig) {
    if (_.isString(groupConfig)) {
        groupConfig = this.mapLayerSelectorService.getObjectConfig(this.config, groupConfig);
    }
    if (groupConfig.selector !== 'group') {
        return;
    }
    groupConfig.layers.forEach(this.hideLayer);
  }

  getLayersToSave(config) {
    const result = [];

    if (config.id !== undefined && config.visible !== undefined) {
      let item;

      // saving always true from layer with minZoomVisibility
      if(_.isNumber(config.minZoomVisibility)) {
          item = { id: config.id, visible: true };
      } else {
          item = { id: config.id, visible: config.visible };
      }

      result.push(item);
    }

    if (Array.isArray(config.layers)) {
      config.layers.forEach((value) => {
        this.getLayersToSave(value).forEach((value) => {
          result.push(value);
        });
      });
    }

    return result;
  }

  getVisibleForLayerId(loadedConfig, id) {
    const found = loadedConfig.find(value => value.id === id);
    if (found) {
      return found.visible;
    }
    return undefined;
  }

  getMapSelectorConfigId() {
    return (this.options.storagePrefix ? this.options.storagePrefix + '.' : '') + 'layerSelector.' + this.mapId;
  }

  getLayerGroupVisibilityId(id) {
    return this.getMapSelectorConfigId() + '_groupSelector_' + id;
  }

  isOptionsSaveLayersVisibility() {
    return this.options.saveLayersVisibility === true;
  }

  isOptionsSaveGroupCollapseStatus() {
    return this.options.saveGroupCollapseStatus === true;
  }

  saveConfig() {
    this.localStorageService.set(this.getMapSelectorConfigId(), this.getLayersToSave(this.config));
  }

  async loadConfig() {
    const config = this.localStorageService.get(this.getMapSelectorConfigId());

    if ((await this.loadLayers(config, this.config)) === true) {

    }
  }

  async loadLayers(loadedConfig, scopeConfig) {
    let change = false;

    if (scopeConfig.id !== undefined) {
      const newVisible = loadedConfig ? this.getVisibleForLayerId(loadedConfig, scopeConfig.id) : (scopeConfig.visible || (scopeConfig.parent ? scopeConfig.parent.visible : false));

      if ((scopeConfig.visible !== newVisible) && (newVisible !== undefined)) {
        scopeConfig.visible = newVisible;
        change = true;
      }

      if (scopeConfig.visible && scopeConfig.type !== 'node') {
        await this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id);
        /*layerInit = layerInit.then(
                () => {
                    return this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)
                }
            );*/
      }
    }

    if (Array.isArray(scopeConfig.layers)) {
      for (const value of scopeConfig.layers) {
        if ((await this.loadLayers(loadedConfig, value)) === true) {
          change = true;
        }
      }
    }

    return change;
  }

  async layersShow(scopeConfig, layersToShow) {
    let childVisible;
    if (layersToShow.includes(scopeConfig.id) || (scopeConfig.parent && scopeConfig.parent.visible)) {
      scopeConfig.visible = true;
      if (scopeConfig.type !== 'node') {
        await this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)
        /*layerInit = layerInit.then(
          () => {
            return this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)
          }
        );*/
      } else {
        scopeConfig.collapsed = false;
        if (this.isOptionsSaveGroupCollapseStatus() === true) {
          this.localStorageService.set(this.getLayerGroupVisibilityId(scopeConfig.id), scopeConfig.collapsed);
        }
      }
      childVisible = true;
    } else {
      scopeConfig.visible = false;
    }

    if (Array.isArray(scopeConfig.layers)) {
      for (const value of scopeConfig.layers) {
        const vVisible = await this.layersShow(value, layersToShow);
        if (childVisible === undefined) {
          childVisible = vVisible;
        } else if (childVisible !== vVisible) {
          childVisible = null;
        }
      }

      if (scopeConfig.type === 'node') {
        scopeConfig.visible = childVisible;
      }
    }
    return scopeConfig.visible;
  }

  showCollapsedVisibleLayersCount() {
    return this.options.showCollapsedVisibleLayersCount === true;
  }

  getSelectorLayers(layerConfig) {
    return (layerConfig.comparator ? layerConfig.layers.slice().sort(layerConfig.comparator) : layerConfig.layers.slice().reverse());
  }

  // recursive search for map layers that are currently visible in a map
  getDisplayedLayersCount(layer) {
    let current = 0;

    if (layer.visible && layer.type !== 'node') {
      current += 1;
    }

    if (Array.isArray(layer.layers)) {
      layer.layers.forEach((value) => {
        current += this.getDisplayedLayersCount(value);
      });
    }

    return current;
  }

  // recursive search for all map layer (no nodes)
  getTotalLayersCount(layer) {
    let current = 0;

    if (layer.type !== 'node') {
      current += 1;
    }

    if (Array.isArray(layer.layers)) {
      layer.layers.forEach((value) => {
        current += this.getTotalLayersCount(value);
      });
    }

    return current;
  }

  logMapLayerSwitch(action) {
    (<any>window).GoogleAnalytics('send', {
      hitType: 'event',
      eventCategory: 'mapModule',
      eventAction: action,
      eventLabel: 'legend',
    });
  }

  switchLayer(layerConfig, collapse = false) {
    if (collapse) {
      this.switchGroup(layerConfig);
      return;
    }
    layerConfig.visible = !layerConfig.visible;

    this.logMapLayerSwitch(`layer visibility change ${layerConfig.title} ${layerConfig.visible}`);

    if (layerConfig.visible && layerConfig.hideIfVisible) {
      this.hideLayerObjects(layerConfig.hideIfVisible);
    }

    if (layerConfig.parent && layerConfig.parent.selector === 'switch' && !layerConfig.visible) {
      layerConfig.parent.visible = layerConfig.parent.layers.some(l => l.visible) ? null : false;
    } else if (layerConfig.parent && layerConfig.parent.selector === 'switch' && layerConfig.visible) {
      layerConfig.parent.visible = layerConfig.parent.layers.every(l => l.visible) ? true : null;
    }

    if (this.isOptionsSaveLayersVisibility() === true) {
      this.saveConfig();
    }

    if (layerConfig.type === MapLayerTypeEnum.node && layerConfig.layers) {
      layerConfig.layers.forEach(l => {
        if (layerConfig.visible !== !!l.visible) {
          this.switchLayer(l);
        }
      });
      return;
    }

    if (layerConfig.visible) {
      this.mapLayerService
        .addLayerToMap(this.mapId, layerConfig, layerConfig.id);
    } else {
      this.mapLayerService
        .removeLayerFromMap(this.mapId, layerConfig.id);
    }
  }

  switchGroup(layerConfig) {
    layerConfig.collapsed = !layerConfig.collapsed;
    this.logMapLayerSwitch(`group visibility change ${layerConfig.title} ${layerConfig.collapsed}`);

    if (this.isOptionsSaveGroupCollapseStatus() === true) {
      this.localStorageService.set(this.getLayerGroupVisibilityId(layerConfig.id), layerConfig.collapsed);
    }

    layerConfig.parent.hasVisibleItems = layerConfig.parent.layers.some(l => !l.collapsed);
  }

  layerMouseEnter(event, layer) {
    event.layer = layer;
    this.mapLayerSelectorService.fireCallback(MapCallbackEnum.layerMouseEnter, event);
  }

  layerMouseLeave(event) {
    this.mapLayerSelectorService.fireCallback(MapCallbackEnum.layerMouseLeave, event);
  }

  private switchLayers(allowed: string[]) {
    // groups
    for (const group of this.config.layers) {

      // layers
      for (const layer of group.layers) {
        // node
        if (layer.type === MapLayerTypeEnum.node && layer.layers) {
          layer.visible = allowed.indexOf(layer.id) !== -1;

          for (const nestedLayer of layer.layers) {
            nestedLayer.visible = allowed.indexOf(nestedLayer.id) !== -1;
            this.cdRef.detectChanges();
          }
        // layer
        } else {
          layer.visible = allowed.indexOf(layer.id) !== -1;
        }
        this.cdRef.detectChanges();
      }
    }
  }
}
