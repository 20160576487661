<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^" class="button"><i class="fa fa-chevron-left mr-5"></i> Seznam uživatelů</a>
      </div>
      <div class="actions">
        <a class="button" (click)="onEdit()"><i class="fa fa-pencil"></i>Editace uživatele</a>
        <a class="button" *ngIf="data.applications.length > 0" (click)="onDeactivate()"><i class="fa fa-trash"></i>Deaktivace uživatele</a>
        <gmt-button [clickAction]="onUserSync"><i class="fa fa-refresh"></i> Synchronizovat uživatele</gmt-button>
      </div>
    </div>
    <h1>Uživatel</h1>

    <fieldset class="bordered">
      <div class="column-50">
        <label>Titul před jménem</label>
        <div class="info">{{ data.titleBefore }}</div>
        <label>Příjmení</label>
        <div class="info">{{ data.surname }}</div>
        <label>E-mail</label>
        <div class="info">{{ data.email }}</div>
        <label>Telefon</label>
        <div class="info">{{ data.phone }}</div>
        <label>Název firmy</label>
        <div class="info">{{ data.company | projectSubjectName }}</div>
      </div>

      <div class="column-50">
        <label>Křestní jméno</label>
        <div class="info">{{ data.name }}</div>
        <label>Titul za jménem</label>
        <div class="info">{{ data.degreeAfter }}</div>
        <label>Uživatelské jméno</label>
        <div class="info">{{ data.username }}</div>
        <label>Developer</label>
        <div class="info">{{ data.superuser ? 'Ano' : 'Ne' }}</div>
      </div>
    </fieldset>


    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab" [disabledTabs]="disabledTabs">
        <div *ngIf="tab === 'modules'">
          <div class="flexbox-container">
            <div class="column-100 checklist">
              <div *ngFor="let module of modules" (click)="onModuleClick(module)" class="checklist-item">
                <gmt-checkbox gmtType="multiselect" [gmtChecked]="moduleAvailable(module)"></gmt-checkbox> {{ module.name }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab === applications.sy.name">
          <sydi-projects [callbacks]="callbacks" [userId]="userId" [module]="applications.sy.name" [userProjects]="syProjects"></sydi-projects>
        </div>
        <div *ngIf="tab === applications.dashboard.name">
          Uživatel disponuje právy na modul {{ applications.dashboard.title }}.
        </div>
        <div *ngIf="tab === applications.di.name">
          <sydi-projects [callbacks]="callbacks" [userId]="userId" [module]="applications.di.name" [userProjects]="diProjects"></sydi-projects>
        </div>
        <div *ngIf="tab === applications.pk.name">
          <div [loading]="pkLoading">
            <a *ngIf="!pkLoading" uiSref="settings.users.detail.editPermissions" [uiParams]="{ projectKey: pkProject.key }">
              Nastavit oprávnění
            </a>
          </div>
        </div>
        <div *ngIf="tab === applications.vfze.name">
          <div [loading]="vfzeLoading">
            <a *ngIf="!vfzeLoading" uiSref="settings.users.detail.editPermissions" [uiParams]="{ projectKey: vfzeProject.key }">
              Nastavit oprávnění
            </a>
          </div>
        </div>
        <div *ngIf="tab === applications.sv.name">
          <sv-permission-list [callbacks]="callbacks" [userId]="userId" [userOrganizationalUnits]="svProjects"></sv-permission-list>
        </div>
      </gmt-navbar>
    </div>
  </div>
</div>
