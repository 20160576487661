import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { DialogService } from '@app/common/services/dialog.service';
import { AresData, AresFormComponent } from '@app/ps/components/ares-form/ares-form.component';
import { ProjectSubjectAgentComponent } from '@app/ps/components/project-subject-agent/project-subject-agent.component';
import { ActingPersonNamePipe } from '@app/ps/pipes/acting-person-name.pipe';

@Component({
  selector: 'gmt-project-subject',
  templateUrl: './project-subject.component.html',
})
export class ProjectSubjectComponent implements OnInit {

  @Input() subject: ProjectSubjectModel;
  @Output() projectSubjectUpdateRegister = new EventEmitter();
  isInvestor: boolean;
  actingPersonsNotCancelled;
  subjectType: SwitchOption;
  reloadOptionsCadastreActingPerson: Function;

  subjectTypes: SwitchOption[] = [
    { id: 'OPO', value: 'Právnická osoba' },
    { id: 'OFO', value: 'Fyzická osoba' }
  ];

  constructor(
    public actingPersonNamePipe: ActingPersonNamePipe,
    private dialogService: DialogService,
  ) {
    this.updateActingSelect = this.updateActingSelect.bind(this);
    this.reloadOptionsCadastreActingPersonRegister = this.reloadOptionsCadastreActingPersonRegister.bind(this);
    this.onAddActing = this.onAddActing.bind(this);
    this.onCancelActing = this.onCancelActing.bind(this);
    this.onAddAgent = this.onAddAgent.bind(this);
  }

  ngOnInit() {
    this.isInvestor = this.subject.discriminator === 'I';

    if (this.subject.discriminator !== 'G') {
      this.subjectTypes.push({ id: 'OPO_OSS', value: 'Organizační složka státu' });
      this.subjectTypes.push({ id: 'OPO_PO', value: 'Příspěvková organizace' });
    }

    if (this.subject.subjectType) {
      this.subjectType = this.subjectTypes.find(t => t.id === this.subject.subjectType);
    } else {
      this.subjectType = this.subjectTypes[0];
      this.subject.subjectType = this.subjectType.id as string;
    }

    this.updateActingSelect();
    this.projectSubjectUpdateRegister.emit((actingPersons) => { this.updateActingSelect(actingPersons); });
  }

  onSubjectTypeSelected(item: SwitchOption) {
    this.subject.subjectType = item.id as string;
  }

  reloadOptionsCadastreActingPersonRegister(reload: Function) {
    this.reloadOptionsCadastreActingPerson = reload;
  }

  onAddActing() {
    this.subject.actingPersons.push({ cancelled: false } as ActingPersonModel);
    this.updateActingSelect();
  }

  onCancelActing(acting) {
    if (
      acting && this.subject.cadastreActingPerson &&
      (acting === this.subject.cadastreActingPerson || acting.id === this.subject.cadastreActingPerson.id)
    ) {
      this.onUpdateCadastreActingPerson({ oldValue: this.subject.cadastreActingPerson });
      this.subject.cadastreActingPerson = undefined;
    }

    if (acting.id) {
      acting.cancelled = true;
    } else {
      _.remove(this.subject.actingPersons, a => _.isEqual(a, acting));
    }
    this.updateActingSelect();
  }

  onRestoreActing(acting) {
    acting.cancelled = false;
    this.updateActingSelect();
  }

  onAddCadastreNote() {
    this.subject.cadastreNotes = this.subject.cadastreNotes || [];
    this.subject.cadastreNotes.push({});
  }

  onRemoveCadastreNote(cadastreNote) {
    _.pull(this.subject.cadastreNotes, cadastreNote);
  }

  onUpdateCadastreActingPerson(event: any) {
    if (event.oldValue) {
      event.oldValue.cadastreActingPerson = false;
    }
    if (event.newValue) {
      event.newValue.cadastreActingPerson = true;
    }
  }

  onSearchCompany(name, id) {
    const dialog = this.dialogService.open(AresFormComponent, {
      data: {
        icoToSearch: id,
        nameToSearch: name,
      },
    });

    const sub = dialog.afterClosed.subscribe((aresData: AresData | undefined) => {
      if (aresData) {
        this.subject.actingPersons = [];
        _.assignInWith(this.subject, aresData, (value, other) => {
          return other || value;
        });
      }
      sub.unsubscribe();
    });
  }

  onAddAgent() {
    const dialog = this.dialogService.open(ProjectSubjectAgentComponent, {
      data: { agent: this.subject.cadastreAgent }
    });

    const sub = dialog.afterClosed.subscribe((agent: ProjectSubjectModel | undefined) => {
      if (agent) {
        this.subject.cadastreAgent = agent;
      }
      sub.unsubscribe();
    });
  }

  updateActingSelect(actingPersons = null) {
    this.actingPersonsNotCancelled = this.actingPersonsNotCancelled || [];

    let items = [];
    if (this.subject) {
      items = _.filter(actingPersons || this.subject.actingPersons, { cancelled: false, roleCadastre: true });
    }
    this.actingPersonsNotCancelled.splice(0, this.actingPersonsNotCancelled.length, ...items);

    if (this.reloadOptionsCadastreActingPerson) {
      this.reloadOptionsCadastreActingPerson();
    }
  }
}
