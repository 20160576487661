<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ preliminary ? 'Předběžná' : 'Závěrečná' }} kontrola způsobu platby</h1>

    <fieldset *ngFor="let caseOwnership of dataCaseOwnerships" class="subject">
      <div *ngIf="caseOwnership.caseOwnership.ownership.isBsm">
        <legend>Vlastník - Osoba SJM</legend>
        <div class="column-100">
          <label (click)="toggleSinglePayment(caseOwnership)">Jedna platba za podíl SJM</label>
          <div class="info">
            <gmt-checkbox [gmtChecked]="caseOwnership.caseOwnership.singlePayment" (click)="toggleSinglePayment(caseOwnership)"></gmt-checkbox>
          </div>
        </div>
        <div *ngIf="caseOwnership.caseOwnership.singlePayment">
          <div class="column-100">
            <label>Manželé</label>
            <div class="info">
              <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseOwnership.caseSubjects[0].subject.id, tab: 'about' }">
                {{caseOwnership.caseSubjects[0].subject | subjectName}}
              </a>
              a
              <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseOwnership.caseSubjects[1].subject.id, tab: 'about' }">
                {{caseOwnership.caseSubjects[1].subject | subjectName}}
              </a>
            </div>
          </div>
          <payment-form [paymentOptions]="caseOwnership.caseOwnership" [nestedForm]="true"></payment-form>
        </div>
        <ng-container *ngFor="let caseSubject of caseOwnership.caseSubjects">
          <div *ngIf="!caseOwnership.caseOwnership.singlePayment">
            <div class="column-100 bordered-top mt-10 pt-10">
              <label>Jméno</label>
              <div class="info">
                <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseSubject.subject.id, tab: 'about' }">{{ caseSubject.subject | subjectName }}</a>
              </div>
            </div>
            <payment-form [paymentOptions]="caseSubject" [nestedForm]="true"></payment-form>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!caseOwnership.caseOwnership.ownership.isBsm">
        <legend *ngIf="caseOwnership.caseSubjects[0].subject.opsubType === 'OPO'">Vlastník - Společnost</legend>
        <legend *ngIf="caseOwnership.caseSubjects[0].subject.opsubType === 'OFO'">Vlastník - Fyzická osoba</legend>
        <div class="column-100">
          <label>{{ caseOwnership.caseSubjects[0].subject.opsubType == 'OPO' ? 'Název' : 'Jméno' }}</label>
          <div class="info">
            <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseOwnership.caseSubjects[0].subject.id, tab: 'about' }">
              {{ caseOwnership.caseSubjects[0].subject | subjectName }}
            </a>
          </div>
        </div>
        <payment-form [paymentOptions]="caseOwnership.caseSubjects[0]" [nestedForm]="true"></payment-form>
      </div>
    </fieldset>

    <gmt-generate-documents-form [caseId]="caseId" [templatesGetter]="templatesGetter" [generateSetter]="generateSetter" [getCheckedItemsGetter]="getCheckedItemsGetter"></gmt-generate-documents-form>

    <div>
      <gmt-button [gmtDisabled]="disabled()" [clickAction]="updatePayment">Uložit a generovat</gmt-button>
    </div>
  </div>
</div>
