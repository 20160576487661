import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  application = this.authService.getActiveApplication();
  user = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
  }
}
