<div class="list">
  <h1>Parcely</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <gmt-select
      multiselect="true"
      [data]="list.filter.filters.areaId"
      field="values"
      [filter]="filterArea"
      resource="areas/list"
      selectTitle="Katastrální území"
    ></gmt-select>
    <gmt-select
      multiselect="true"
      [data]="list.filter.filters.titleId"
      field="values"
      [filter]="filterTitle"
      resource="titles/list"
      selectTitle="List vlastnictví"
      [attributes]="{area: {mapping: {attributes: {geometry: {load: false}}}}}"
      [itemPipe]="titleNamePipe"
    ></gmt-select>
    <gmt-select
      multiselect="true"
      [data]="list.filter.filters.subjectId"
      field="values"
      [filter]="filterSubject"
      resource="subjects/list"
      selectTitle="Vlastník"
      [attributes]="{subjectSjm1: {}, subjectSjm2: {}}"
      [itemPipe]="subjectNamePipe"
    ></gmt-select>

    <!-- Fulltext -->
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">
            <span class="cell">Katastrální území</span>
            <span class="cell">Parcela</span>
            <sort-by class="cell" [selected]="'lv' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('lv', $event)" [sortDir]="list.filter.sortOrder.direction">LV</sort-by>
            <span class="cell">Druh pozemku</span>
            <sort-by class="cell" [selected]="'vymera' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('vymera', $event)" [sortDir]="list.filter.sortOrder.direction">Výměra</sort-by>
            <span class="cell">Způsob ochrany</span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let parcel of list.list" uiSref="dimap.project.parcels.detail" [uiParams]="{id: parcel.id}">
            <span class="cell">{{ parcel.katuzeNazev }}</span>
            <span class="cell">{{ parcel | parcelnumber }}</span>
            <span class="cell">{{ parcel.title.lv }}</span>
            <span class="cell">{{ parcel.drupozNazev }}</span>
            <span class="cell">{{ parcel.vymera }}</span>
            <span class="cell">{{ parcel.parcelProtectionsText }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.VM_PANEL_PARCELS" class="cm-help-page"></help>
</div>
