import { Component, Input, Inject, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-sent-all-owners',
	templateUrl: './form-case-sent-all-owners.component.html',
})
export class FormCaseSentAllOwnersComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  public data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.callbackRegister.emit({
      isValid: this.isValid,
    });
    this.data.sentAllOwnersDate = new Date();
  }

  isValid() {
    return !!this.data.sentAllOwnersDate;
  }
}
