<h1>{{ ticketService.getStatusEnumAction(newStatus) }}</h1>

<div [loading]=""></div>

<div *ngIf="!loading">


  <fieldset>
    <div class="column-100">
      <div *ngIf="isFieldVisible('sentToConfirmationDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.sentToConfirmationDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('requestForClarificationDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.requestForClarificationDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('clarificationRequestDoneDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.clarificationRequestDoneDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('approvedDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.approvedDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('disapprovedDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.disapprovedDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('templateSentToProcessingDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.templateSentToProcessingDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('requestForInformationDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.requestForInformationDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('informationRequestDoneDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.informationRequestDoneDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('documentTypeNumber')" class="column-50">
        <gmt-input label="Pořadí dokumentu" [required]="false" class="mt-10">
          {{ documentTypeOrder }}
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('importedDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.importedDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('sampleNumber')" class="column-50">
        <gmt-input label="Označení šablony a verze" class="mt-10">
          <div class="info">{{ ticket.sampleNumber }}</div>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('sampleDescription')" class="column-50">
        <gmt-input label="Účel vzoru" [required]="true" class="mt-10">
          <input type="text" [(ngModel)]="ticket.sampleDescription"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('fullDocumentName')" class="column-50">
        <gmt-input label="Generovaný název dokumentu" class="mt-10">
          <div class="info">ČísloPřípadu_{{ ticket.documentType.name }}{{ ticket.documentNameSpecification ? '_' + ticket.documentNameSpecification : '' }}{{ ticket.documentType.category === 'COVERING_LETTER' || ticket.documentType.category === 'INFORMATION_LETTER' ? '_Adresát' : '' }}.docx</div>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('documentNameSpecification')" class="column-50">
        <gmt-input label="Upřesnění názvu generovaného dokumentu" class="mt-10">
          <input type="text" [(ngModel)]="ticket.documentNameSpecification"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('deletedDate')" class="column-50">
        <gmt-input label="K datu" [required]="true" class="mt-10">
          <input type="text" pick-a-date [maxToday]="true" [(ngModel)]="ticket.deletedDate"/>
        </gmt-input>
      </div>

      <div *ngIf="isFieldVisible('changeStatusDescription')" class="column-100">
        <gmt-input label="Důvod" [required]="true" class="mt-10">
          <textarea [(ngModel)]="ticket.changeStatusDescription"></textarea>
        </gmt-input>
      </div>
    </div>
  </fieldset>

  <fieldset *ngIf="isFieldVisible('templateAttachment')">
    <legend class="required">Dokument šablony</legend>
    <fileupload class="blue" (change)="onTemplateFilesChange($event)" [message]="templateFilesMsg" inputId="templateHidden"></fileupload>
  </fieldset>

  <fieldset *ngIf="isFieldVisible('revisionSampleAttachment')">
    <legend>Oprava vzoru s vyznačenými úpravami</legend>
    <fileupload class="blue" (change)="onRevisionSampleFilesChange($event)" [message]="revisionSampleFilesMsg" inputId="revisionSampleHidden"></fileupload>
  </fieldset>

  <fieldset *ngIf="isFieldVisible('sampleAttachment')">
    <legend>Oprava originálu nového vzoru (bude k dispozici jako vzor ke stažení)</legend>
    <fileupload class="blue" (change)="onSampleFilesChange($event)" [message]="sampleFilesMsg" inputId="sampleHidden"></fileupload>
  </fieldset>

  <fieldset *ngIf="isFieldVisible('approvedChangeStatusDescription') && (!!sampleFiles.length || !!revisionSampleFiles.length)">
    <div class="column-100">
      <gmt-input label="Důvod opravy vzoru" [required]="true" class="mt-10">
        <textarea [(ngModel)]="ticket.changeStatusDescription"></textarea>
      </gmt-input>
    </div>
  </fieldset>

  <div class="actions">
    <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>

</div>
