// @TODO - move to ../common/models...
import { StateService } from '@uirouter/angular';
import { ListService } from '../common/services/list.service';

export class PageableList {

  dataPaging: any = {
    itemsPerPage: {}
  };
  itemsPerPageItems: any;
  onListChangedPagination: Function;
  onListChangedItemcounter: Function;
  onListChangedPagescounter: Function;
  list: any;
  listService: ListService;
  stateService: StateService;

  constructor(list: any, listService: ListService, stateService: StateService, uiconstants: any) {
    this.list = list;
    this.listService = listService;
    this.stateService = stateService;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onListChanged = this.onListChanged.bind(this);
    this.onSort = this.onSort.bind(this);

    this.itemsPerPageItems = uiconstants.itemsPerPage;
    this.dataPaging.itemsPerPage = {
      id: this.list.filter.limit,
      name: this.list.filter.limit
    };
  }

  onFilterChanged(changingPage) {
    if (!changingPage) {
      return this.fetchResults();
    }
  }

  onSort(sortValue, sortDir) {
    this.listService.sort(this.list, sortValue, sortDir)
      .then(this.onListChanged);
  }

  uiOnParamsChanged(changedParams, transition) {
    if (transition.$from().name === transition.$to().name) {
      const page = this.getActualPage(changedParams);
      // through sorting reload is triggered and in some cases also change to first page one at once, hence reload is not needed
      if (page && page !== this.listService.getCurrentPage(this.list)) {
        return this.fetchResults(page);
      }
    }
  }

  changePageItems() {
    this.list.filter.limit = this.dataPaging.itemsPerPage.id;
    return this.onFilterChanged(false);
  }

  paginationCallbackRegister(event) {
    this.onListChangedPagination = event.onListChanged;
  }

  itemcounterCallbackRegister(event) {
    this.onListChangedItemcounter = event.onListChanged;
  }

  pagescounterCallbackRegister(event) {
    this.onListChangedPagescounter = event.onListChanged;
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
    if (this.onListChangedItemcounter) {
      this.onListChangedItemcounter();
    }
    if (this.onListChangedPagescounter) {
      this.onListChangedPagescounter();
    }
  }

  getActualPage(params, defaultPage = this.list.filter.offset / this.list.filter.limit + 1) {
    return parseInt(params.page, 10) || defaultPage;
  }

  fetchResults(page = this.getActualPage(this.stateService.params)) {
    const overfloatFallback = () => this.stateService.go('.', {page: 1});
    return this.listService.fetchListPerPage(this.list, page, this.onListChanged, overfloatFallback);
  }

  async load() {
    const actualPage = this.getActualPage(this.stateService.params);
    await this.stateService.go('.', Object.assign({}, this.stateService.params, {page: actualPage}));
    return this.onFilterChanged(false);
  }

  disableLocalStorage() {
    this.list.filterLocalStorageName = null;
  }
}
