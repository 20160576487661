import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as  L from 'leaflet';
import { MapLayersStorageService } from '@app/map/services/map-layers-storage.service';
import { MapLayerFactoryService } from '@app/map/services/map-layer-factory.service';
import { MapStorageService } from '@app/map/services/map-storage.service';
import {CallbackModel} from "@app/common/models/callback.model";

@Injectable({
  providedIn: 'root'
})
export class MapLayerService {

  private callbacks;

  constructor(
    private mapLayersStorageService: MapLayersStorageService,
    private mapLayerFactoryService: MapLayerFactoryService,
    private mapStorageService: MapStorageService,
  ) {
    this.callbacks = new CallbackModel();
    const MapLayerId = {
      hasLayerById: function (layer) {
        if (!layer) { return false; }

        return !_.isEmpty(_.find(this._layers, function(l){ return l._id === layer._id; }));
      },

      removeLayerById: function (layerToRemove) {
        const layer = _.find(this._layers, function(l){ return l._id === layerToRemove._id; });

        this.removeLayer(layer);

      }
    };

    L.Map.include(MapLayerId);
  }

  /**
   * Creates Leaflet layer from given configuration, adds it to map and
   * saves it to layers storage. Returns promise.
   */
  addLayerToMap(mapId, layerConf, layerId) {
    const layerLeaflet = this.mapLayerFactoryService.createLayer(layerConf, layerId);
    let layerAlreadyExists = false;

    return this.mapStorageService.getMap(mapId)
      .then((map) => {
        if (map.hasLayerById(layerLeaflet)) {
          layerAlreadyExists = true;
        } else {
          layerLeaflet.addTo(map);
        }
      })
      .then(() => this.mapLayersStorageService.getLayers(mapId))
      .then((layers) => {
        if (!layerAlreadyExists) {
          // adds layer to immutable layer storage
          layers = this.mapLayersStorageService.addLayerToLayers(layers, layerLeaflet);
          this.mapLayersStorageService.setLayers(layers, mapId);
        }
      });
  }

  /**
   * Applies func for every layer.
   */
  forEachLayer(layersStorage, func) {
    if (_.isArray(layersStorage)) {
      _.forEach(layersStorage, (value) => {
        func(value);
      });
    }
  }

  /**
   * Recursively adds new layers or layers groups to map from given
   * configuration. Recursion keeps the promise chain.
   */
  initLayersForMap(mapId, layerConfiguration) {
    if (_.isArray(layerConfiguration) && layerConfiguration.length > 0) {
      const layerConf = _.head(layerConfiguration);
      layerConfiguration = _.tail(layerConfiguration);

      if (layerConf.type && layerConf.type === 'layer' && layerConf.id && layerConf.layer) {
        this.addLayerToMap(mapId, layerConf.layer, layerConf.id).then(() => {
          this.initLayersForMap(mapId, layerConfiguration);
        });
      }
    }
  }

  /**
   * Removes layer from map and layer storage by given layer id.
   * Returns promise.
   */
  removeLayerFromMap(mapId, layerId) {
    return this.mapLayersStorageService.getLayers(mapId).then((layers) => {
      const layerLeaflet = this.mapLayersStorageService.getLayerById(layers, layerId);

      this.mapStorageService.getMap(mapId).then((map) => {

        if (map.hasLayerById(layerLeaflet)) {
          map.removeLayerById(layerLeaflet);

          layers = this.mapLayersStorageService.removeLayerFromLayers(layers, layerId);
          this.mapLayersStorageService.setLayers(layers, mapId);
        }
      });
    });
  }

  registerCallback(eventName, callback) {
    this.callbacks.add(eventName, callback);
  }

  fireCallback(eventName, event) {
    this.callbacks.get(eventName)(event);
  }

  unregisterCallback(eventName, callback) {
    this.callbacks.remove(eventName, callback);
  }
}

