// @TODO - move to ../common/models...
import { Project } from '@app/models/project';
import { Customer } from '@app/models/customer';

export class CustomerContract {
  contractNumber: string;
  description: string;
  file: File;
  id?: number;
  idText: string;
  location?: string;
  signDate: string;
  subject: string;
  projects: Project[];
  customer: Customer;
}
