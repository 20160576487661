import { Component, Inject, OnInit } from '@angular/core';
import { PricingExpertOpinionStepComponent } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { TitleModel } from '@app/common/models/title.model';
import { ExpertModel } from '@app/ps/models/expert.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { ListModel } from '@app/common/models/list.model';
import { DocumentModel } from '@app/common/models/document.model';
import { ListService } from '@app/common/services/list.service';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'pricing-expert-opinion-base',
  templateUrl: './pricing-expert-opinion-base.component.html',
})
export class PricingExpertOpinionBaseComponent extends PricingExpertOpinionStepComponent implements OnInit {

  static readonly MSG_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
  static readonly MSG_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  multipleOpinionsPerParcelEnabled = this.authService.getActualProject().multipleOpinionsPerParcel;
  filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_DEFAULT;
  filesValid = false;
  documentList: ListModel<DocumentModel>;
  attachmentType = DocumentTypeModel.createById('OPINION_SCAN');

  constructor(
    private restangular: Restangular,
    private listService: ListService,
    protected authService: AuthService,
  ) {
    super(authService);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    if (!this.data.opinion) {
      this.data.opinion = this.data.occupationType === 'P'
        ? OpinionModel.createDefaultExpertOpinionPermanent()
        : OpinionModel.createDefaultExpertOpinionTemporary();

      this.data.opinion.title = <TitleModel>{ id: this.data.title.id };
      this.data.opinion.parcelPrices = [];

      if (this.data.opinionRequest) {
        this.data.opinion.expertOpinionNumber = this.data.opinionRequest.name;
        this.data.opinion.expert = this.data.opinionRequest.expert;
        this.data.opinion.expertOpinionDate = this.data.opinionRequest.realizedDate;
      }
    }

    await this.loadDocuments();
    this.loading = false;
  }

  onChangeExpert(expert: ExpertModel) {
    this.data.opinion.expert = expert;
  }

  isFormValid(): boolean {
    const hasNumber = this.data.opinion.expertOpinionNumber;
    const hasDate = this.data.opinion.expertOpinionDate;
    const hasExpert = this.data.opinion.expert;
    const hasContent = (this.data.opinion.expertOpinionBuildings || this.data.opinion.expertOpinionLand || this.data.opinion.expertOpinionVegetation) || this.data.occupationType === 'T';
    return !!(hasNumber && hasDate && hasExpert && hasContent) || this.data.readonly;
  }

  onSubmit() {
    this.submitCallback.emit();
  }

  onFileChange(files: UploadFileExtended[]) {
    this.data.files = files;
    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'pdf');

    if (this.data.files.length === 0) {
      this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_EXTENSION;
    } else if (this.data.files.length > 1) {
      this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_QUANTITY;
    } else {
      this.filesMsg = '';
    }

    this.filesValid = (extensionValid && this.data.files.length === 1);
  }

  private loadDocuments(): Promise<any> {
    if (!this.data.opinion.opinionRequest && !this.data.opinionRequest) {
      return Promise.resolve();
    }

    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { opinionRequestId: (this.data.opinionRequest ? this.data.opinionRequest.id : this.data.opinion.opinionRequest.id), cancelled: false },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    return this.listService.fetchResult(this.documentList);
  }
}
