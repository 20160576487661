<fieldset>
  <legend>{{ heading }} <a *ngIf="editableToggle" (click)="editable = !editable"><i class="fa fa-pencil"></i></a></legend>

  <gmt-checkbox
    [gmtChecked]="collapsed"
    [gmtDisabled]="!editable"
    (click)="toggleCollapsed()"
    *ngIf="toggleText"
    gmtLabelPosition="after"
  >{{ toggleText }}</gmt-checkbox>

  <!-- first row -->
  <ng-container *ngIf="collapsed">
    <div class="column-100">
      <!-- ulice -->
      <gmt-input *ngIf="editable" label="Ulice" [required]="isRequired('street')">
        <input type="text" [(ngModel)]="address.street"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Ulice</label>
        <div class="info">{{address.street}}</div>
      </ng-container>
    </div>

    <!-- second row -->
    <div class="column-50">
      <!-- cislo popisne -->
      <gmt-input *ngIf="editable" label="Číslo popisné" [required]="isRequired('houseNumber')">
        <input type="text" [(ngModel)]="address.houseNumber"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Číslo popisné</label>
        <div class="info">{{address.houseNumber}}</div>
      </ng-container>
    </div>

    <!-- second row -->
    <div class="column-50">
      <!-- cislo orientacni -->
      <gmt-input *ngIf="editable" label="Číslo orientační" [required]="isRequired('commonNumber')">
        <input type="text" [(ngModel)]="address.commonNumber"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Číslo orientační</label>
        <div class="info">{{address.commonNumber}}</div>
      </ng-container>
    </div>

    <!-- third row -->
    <div class="column-50">
      <!-- cast obce -->
      <gmt-input *ngIf="editable" label="Část obce" [required]="isRequired('neighborhood')">
        <input type="text" [(ngModel)]="address.neighborhood"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Část obce</label>
        <div class="info">{{address.neighborhood}}</div>
      </ng-container>
    </div>

    <!-- third row -->
    <div class="column-50">
      <!-- mestska cast -->
      <gmt-input *ngIf="editable" label="Městská část" [required]="isRequired('quarter')">
        <input type="text" [(ngModel)]="address.quarter"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Městská část</label>
        <div class="info">{{address.quarter}}</div>
      </ng-container>
    </div>

    <!-- fourth row -->
    <div class="column-50">
      <gmt-input *ngIf="editable" label="Městský obvod" [required]="isRequired('cityDistrict')">
        <input type="text" [(ngModel)]="address.cityDistrict"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Městský obvod</label>
        <div class="info">{{address.cityDistrict}}</div>
      </ng-container>
    </div>

    <!-- fourth row -->
    <div class="column-50">
      <!-- obec -->
      <gmt-input *ngIf="editable" label="Obec" [required]="isRequired('city')">
        <input type="text" [(ngModel)]="address.city"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Obec</label>
        <div class="info">{{address.city}}</div>
      </ng-container>
    </div>

    <!-- fifth row -->
    <div class="column-100">
      <!-- okres -->
      <gmt-input *ngIf="editable" label="Okres" [required]="isRequired('county')">
        <input type="text" [(ngModel)]="address.county"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Okres</label>
        <div class="info">{{address.county}}</div>
      </ng-container>

      <!-- psc -->
      <gmt-input *ngIf="editable" label="PSČ" [required]="isRequired('postalCode')">
        <input type="text" [(ngModel)]="address.postalCode" gmtInputPostCode/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>PSČ</label>
        <div class="info">{{ address.postalCode | postCode }}</div>
      </ng-container>

      <!-- stat -->
      <gmt-input *ngIf="editable" label="Stát" [required]="isRequired('country')">
        <input type="text" [(ngModel)]="address.country"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Stát</label>
        <div class="info">{{address.country}}</div>
      </ng-container>
    </div>

    <!-- sixth row -->
    <div class="column-50 country-code-block">
      <!-- trojmistny kod statu -->
      <gmt-input *ngIf="editable" label="Trojmístný numerický kód státu (dle ISO 3166-1)" [required]="isRequired('countryCode')">
        <input type="text" [(ngModel)]="address.countryCode"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Trojmístný numerický kód státu (dle ISO 3166-1)</label>
        <div class="info">{{address.countryCode}}</div>
      </ng-container>
    </div>

    <!-- sixth row -->
    <div class="column-50 country-code-block">
      <!-- dvoumistny kod statu -->
      <gmt-input *ngIf="editable" label="Dvoumístný alfabetický kód státu (dle ISO 3166-1)" [required]="isRequired('countryShortCode')">
        <input type="text" [(ngModel)]="address.countryShortCode"/>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Dvoumístný alfabetický kód státu (dle ISO 3166-1)</label>
        <div class="info">{{address.countryShortCode}}</div>
      </ng-container>
    </div>
  </ng-container>
</fieldset>
