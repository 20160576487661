import { Inject, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Project } from '@app/models/project';
import { CustomerService } from '@app/settings/customers/services/customer.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  @Input() projects: Project[];
  @Input() customerId: string;
  @Output() projectRemoval: EventEmitter<number> = new EventEmitter<number>();

  availableProjects: any[];

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    private customerService: CustomerService,
    private dialogService: DialogService,
  ) {
    this.setApplicationName = this.setApplicationName.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
  }

  async ngOnInit() {
    const args = {
      filter: {},
    };
    this.availableProjects = await this.customerService.getProjects(this.customerId, args);
    this.projects = <Project[]>this.projects
      .map(this.setApplicationName)
      .map(this.setProjectName);
  }

  hasProjects(): boolean {
    return this.projects.length > 0;
  }

  removeProject(projectId: number): void {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Vybraný projekt bude odebrán ze smlouvy.',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.projectRemoval.emit(projectId);
      }

      sub.unsubscribe();
    });
  }

  private setApplicationName(project: Project): Project {
    project['applicationName'] = Object.values(this.applications)
      .find((app:any) => app.name === project['application'])['title'];
    return project;
  }

  private setProjectName(project: Project): Project {
    const matchingProject = this.availableProjects.find((p:any) => project.projectKey === p['projectKey']);

    if (matchingProject) {
      project['projectName'] = matchingProject['projectName'];
    }

    return project;
  }
}
