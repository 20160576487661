import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SwitchOption {
  id: string | number;
  value: string | number;
}

@Component({
  selector: 'gmt-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements OnInit {
  @Input() options: SwitchOption[];
  @Input() selected: SwitchOption;
  @Output() selectedChange: EventEmitter<SwitchOption> = new EventEmitter<SwitchOption>();
  @Output() select: EventEmitter<SwitchOption> = new EventEmitter<SwitchOption>();
  @Input() selectedId: string | number;
  @Output() selectedIdChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() onSwitchMouseEnter = new EventEmitter<string | number>();
  @Output() onSwitchMouseLeave = new EventEmitter<string | number>();

  ngOnInit() {
  }

  onSelect(option: SwitchOption) {
    this.selected = option;
    this.selectedChange.emit(option);
    this.select.emit(option);
    this.selectedId = option.id;
    this.selectedIdChange.emit(this.selectedId);
  }
}
