import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { createAdministrator, ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'administrator-edit',
  templateUrl: './administrator-edit.component.html',
})
export class AdministratorEditComponent implements OnInit {

  administratorId: number;
  administrator: ProjectSubjectModel;
  loading = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private dialogService: DialogService,
    private config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.administratorId = (<DialogConfigData>config.data).administratorId;
    this.onCreate = this.onCreate.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  ngOnInit() {
    if (this.administratorId) {
      this.restangular
        .one('administrators', this.administratorId)
        .get({ loadCollections: [ 'actingPersons' ] })
        .toPromise()
        .then(data => {
          this.administrator = data.plain();
          this.loading = false;
        });
    } else {
      this.administrator = createAdministrator();
      this.loading = false;
    }
  }

  onCreate(): Promise<any> {
    return this.restangular
      .all('administrators')
      .all('create')
      .post(this.administrator)
      .toPromise()
      .then(data => {
        this.dialog.close(data.plain());
      });
  }

  onUpdate(): Promise<any> {
    return this.restangular
      .one('administrators', this.administrator.id)
      .customPUT(this.administrator, '', { loadCollections: [ 'actingPersons' ] })
      .toPromise()
      .then(data => {
        this.dialog.close(data.plain());
      });
  }

  isValid() {
    const companyTypes = ['OPO', 'OPO_OSS', 'OPO_PO'];
    const isCompany = this.administrator.companyName && companyTypes.includes(this.administrator.subjectType);
    const isPerson = this.administrator.surname && this.administrator.subjectType === 'OFO';
    return isCompany || isPerson;
  }
}
