﻿import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: 'gmt-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() gmtChecked: boolean;
  @Input() gmtDisabled: boolean;
  @Output() changed = new EventEmitter();

  ngOnInit() {}

  onChanged() {
    this.changed.emit(this.gmtChecked);
  }
}
