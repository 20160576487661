<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam vlastníků</a>
      </div>
      <div class="actions">
        <a uiSref="symap.project.map" [uiParams]="{ subject: ownerId }" class="button"><i class="fa fa-map-marker"></i>Zobrazit v mapovém okně</a>

        <a uiSref="symap.project.owners.detail.problematic" [uiParams]="{'tab': tab, 'ownerId': ownerId}" class="button">problematický vlastník</a>
      </div>
    </div>

    <h1>{{owner | subjectName}}</h1>
    <span class="tag tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.subjectDetail?.problematic">Problematický vlastník</span>
    <span class="tag tag--inactive tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.endDate">Bývalý vlastník</span>
    <span class="tag tooltip-min tooltip--nowrap tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.subjectDetail?.ssEU===true" tooltip="stav k {{ owner.subjectDetail.ssDataTimestamp | date}}" position="center">Sankční seznam EU</span>
    <span class="tag tooltip-min tooltip--nowrap tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.subjectDetail?.ssNonEU===true" tooltip="stav k {{ owner.subjectDetail.ssDataTimestamp | date}}" position="center">Sankční seznam mimo EU</span>
    <span class="tag tooltip-min tooltip--nowrap tag--danger tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.subjectDetail?.ssRuskyVliv===true" tooltip="stav k {{ owner.subjectDetail.ssDataTimestamp | date}}" position="center">Ruský vliv</span>
    <span class="tag tooltip-min tooltip--nowrap tag--active tag--not-clickable is-uppercase mt-5 mr-10" *ngIf="owner.subjectDetail?.ssEU===false && owner.subjectDetail?.ssNonEU===false && owner.subjectDetail?.ssRuskyVliv===false" tooltip="stav k {{ owner.subjectDetail.ssDataTimestamp | date}}" position="center">Ověřeno v sankčních seznamech</span>
    <div class="row mt-30">
      <div class="col-md-12 col-lg-6 col-xl-4" style="min-height: 110px; position: relative;">
        <div style="height: 100%;position: relative;">
          <perfect-scrollbar [wheelPropagation]="true" style="height: 100%;width: 100%;position: absolute;">
            <titles-short-list
              [ownershipList]="ownershipList"
              [options]="{showOwnerships: true}"
            ></titles-short-list>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-8">
        <div class="column-100">
          <label>Adresa</label>
          <div class="info">
            {{ owner.subjectDetail?.customAddressUse ? (owner.subjectDetail | address) : (owner | address) }}
          </div>

          <ng-container *ngIf="owner.opsubType === 'OFO'; else legalPerson">
            <label>Rodné číslo</label>
            <div class="info">{{owner.subjectDetail?.personalIdentificationNumber || owner.rodneCislo }}</div>

            <label>Datum narození</label>
            <div class="info">{{owner.subjectDetail?.dateOfBirth | date }}</div>
          </ng-container>

          <ng-template #legalPerson>
            <label>IČO</label>
            <div class="info">{{ owner.ico }}</div>
          </ng-template>

          <label>Telefon</label>
          <div class="info">
            {{ ownerContact.phoneNumber }}
          </div>
          <label>E-mail</label>
          <div class="info">
            {{ ownerContact.email }}
          </div>
          <label>Datová schránka</label>
          <div class="info">
            {{ ownerContact.databoxId }}
          </div>
        </div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab" [disabledTabs]="disabledTabs">
        <tab-owner-about [owner]="owner" *ngIf="tab === 'about'"></tab-owner-about>
        <tab-owner-representation [representatives]="owner.actingPersons" *ngIf="tab === 'representation'"></tab-owner-representation>
        <div *ngIf="tab === 'parcels'">
          <div [loading]="easementList.loading || occupationList.loading">
            <gmt-labeled-block *ngIf="occupationGroups?.permanent" [collapsed]="false" label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
              <table-occupations-permanent [data]="occupationGroups.permanent" [columns]="occupationPermanentColumns"></table-occupations-permanent>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.underOneYear" [collapsed]="false" [label]="occupationUnderYearTableName">
              <table-occupations-temporary [data]="occupationGroups.underOneYear" [columns]="occupationUnderOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.overOneYear" [collapsed]="false" [label]="occupationOverYearTableName">
              <table-occupations-temporary [data]="occupationGroups.overOneYear" [columns]="occupationOverOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="easementList.list && easementList.list.length > 0" [collapsed]="false" label="{{ 'EASEMENT' | wordtranslate }}">
              <table-easements [data]="easementList.list" [columns]="easementColumns"></table-easements>
            </gmt-labeled-block>
          </div>
        </div>
        <div *ngIf="tab === 'cases'">
          <table-cases
            [loading]="caseList.loading"
            [list]="caseList"
            (sort)="onCaseTableSort($event)"
          ></table-cases>
        </div>
        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="true"
            [defaultData]="{subject: {id: owner.id}, systemType: false}"
            (updated)="onUpdateNoteList()"
          ></gmt-notes>
        </div>
        <div *ngIf="tab === 'notifications'">
          <gmt-notifications
            [notificationList]="notificationList"
            (updated)="onUpdateNotificationList();loadHistory();"
          ></gmt-notifications>
        </div>
        <div *ngIf="tab === 'documents'">
          <gmt-documents
            [documentList]="documentList"
            [defaultData]="{opsubId: owner.opsubId}"
            [authorizedUser]="canEdit()"
            [allowType]="true"
            documentCategory="subject"
            (updated)="onUpdateDocumentList();loadHistory();"
          ></gmt-documents>
        </div>
        <div *ngIf="tab === 'history'">
          <gmt-notes
            [type]="'history'"
            [noteList]="historyList"
            [editable]="false"
            [allowType]="true"
          ></gmt-notes>
        </div>
      </gmt-navbar>
    </div>
  </div>
  <help [helpId]="helpIds.PS_SUBJECT_DETAIL" class="cm-help-page"></help>
</div>
