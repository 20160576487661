<div class="detail">
  <div class="mb-10">
    <a (click)="onClose()" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět</a>
  </div>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <h1>{{ opinionId ? (readonly ? 'Náhled' : 'Úprava') + ' předběžného posudku' : 'Nový předběžný posudek' }}
        <span>LV {{ title.lv }}</span>
      </h1>
      <div *ngIf="opinion.parcelPrices.length">
        <div class="bordered mt-20">
          <table>
            <thead>
            <tr>
              <th>Parcela</th>
              <th>Cena za pozemek</th>
              <th>Koeficient</th>
              <th>Celková cena parcely</th>
            </tr>
            </thead>
            <tbody *ngIf="!readonly">
              <tr *ngFor="let parcelPrice of opinion.parcelPrices">
                <td class="no-padding cell-max-height">
                  <cell-parcel
                    [parcel]="parcelPrice.parcel"
                    [tooltipType]="'parcel'"
                    [tooltipData]="{ parcel: parcelPrice.parcel, withoutTitle: true }"
                  ></cell-parcel>
                </td>
                <td class="number">
                  <input (ngModelChange)="parcelPrice.preliminaryOpinionLandPrice = $event; computeSum()" type="text" [ngModel]="parcelPrice.preliminaryOpinionLandPrice" gmtInputNumber/>
                </td>
                <td class="number">
                  <input (ngModelChange)="parcelPrice.preliminaryOpinionCoefficient = $event; computeSum()" type="text" [ngModel]="parcelPrice.preliminaryOpinionCoefficient" gmtInputNumber/>
                </td>
                <td class="number">
                  {{ (parcelPrice.preliminaryOpinionLandPrice * parcelPrice.preliminaryOpinionCoefficient * parcelPrice.parcel.vymera) | number }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="readonly">
              <tr *ngFor="let parcelPrice of opinion.parcelPrices">
                <td class="no-padding cell-max-height">
                  <cell-parcel
                    [parcel]="parcelPrice.parcel"
                    [tooltipType]="'parcel'"
                    [tooltipData]="{ parcel: parcelPrice.parcel, withoutTitle: true }"
                  ></cell-parcel>
                </td>
                <td class="number">
                  {{ parcelPrice.preliminaryOpinionLandPrice | number }}
                </td>
                <td class="number">
                  {{ parcelPrice.preliminaryOpinionCoefficient | number }}
                </td>
                <td class="number">
                  {{ (parcelPrice.preliminaryOpinionLandPrice * parcelPrice.preliminaryOpinionCoefficient * parcelPrice.parcel.vymera) | number }}
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <th colspan="3">Celkem</th>
                <td class="number">{{ sum.price | number }}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <fieldset>
          <legend>Předběžný posudek</legend>

          <div class="column-50">
            <label>Číslo znaleckého posudku</label>
            <div class="input" *ngIf="!readonly"><input type="text" [(ngModel)]="opinion.preliminaryOpinionNumber"/></div>
            <div class="info" *ngIf="readonly">{{ opinion.preliminaryOpinionNumber }}</div>
          </div>
        </fieldset>

        <expert-select-form [expert]="opinion.expert" (changed)="opinion.expert = $event"></expert-select-form>

        <div class="actions">
          <gmt-button *ngIf="!readonly" [gmtDisabled]="!isValid()" [clickAction]="onSave">Uložit</gmt-button>
        </div>
      </div>
    </div>
  </div>
</div>
