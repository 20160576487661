import { Component, Inject, Input, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { ModulesService } from '@app/common/services/modules.service';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { CustomerNamePipe } from '@app/common/pipes/customer-name.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit {

  userId: number;
  loading = false;
  user: User = {};
  originalUser: User = {};
  username: string;
  modules: any[];
  create: boolean;
  editor: User = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(RESTANGULAR_CONFIGURATION) public globalRestangular: any,
    public customerNamePipe: CustomerNamePipe,
    private modulesService: ModulesService,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
    private authService: AuthService,
  ) {
    this.userId = (<DialogConfigData>dialogConfig.data).userId;
    this.modules = (<DialogConfigData>dialogConfig.data).modules;
    this.create = this.userId === undefined;
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    if (!this.create) {
      this.loading = true;
      return this.modulesService.getUser(this.userId).then(user => {
        this.user =  user.plain();
        this.username = this.user.username;
        delete this.user.username;
        this.originalUser = {...this.user};
        this.loading = false;
      });
    }
  }

  onSave(): Promise<any> {
    if (this.create) {
      if (this.modules) {
        this.user.applications = this.modules;
      }
      return this.modulesService.createUser(this.user).then(data => {
        this.dialogRef.close(data.plain());
      });
    } else {
      return this.modulesService.saveUser(this.user).then(data => {
        this.dialogRef.close(data.plain());
      });
    }
  }

  isValid() {
    if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
      return this.checkFilledFields();
    } else if (this.create) {
      return (this.checkPassword() && this.checkFilledFields() && this.checkUsername());
    } else {
      return (this.checkPassword() && this.checkChangedFields() && this.checkFilledFields() && this.checkUsername());
    }
  }

  checkChangedFields() {
    const checkFields = ['titleBefore', 'surname', 'email', 'phone', 'name', 'degreeAfter', 'company', 'username', 'superuser', 'password'];
    for (const field of checkFields) {
      if (this.user[field] !== this.originalUser[field] && (!this.user[field] || !this.originalUser[field] || typeof this.user[field] !== 'object' || this.user[field].id !== this.originalUser[field].id)) {
        return true;
      }
    }
    return false;
  }

  checkFilledFields() {
    const checkFields = ['surname', 'email', 'name', 'company'];
    for (const field of checkFields) {
      if (!this.user[field]) {
        return false;
      }
    }
    return true;
  }

  checkPassword() {
    if (this.user.password) {
      return this.user.password === this.user.passwordCheck;
    } else {
      return !this.create;
    }
  }

  checkUsername() {
    if (this.user.username) {
      return !!this.user.password;
    }

    if (this.create) {
      return !!(this.user.password && this.user.username);
    } else {
      return true;
    }
  }
}
