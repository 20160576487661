import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import * as moment from 'moment';
import { HelpService } from '@app/common/services/help.service';
import { APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: []
})
export class ActivityLogComponent implements OnInit {

  list: ListModel<any>;
  pageableList: PageableList;

  private defaultFilter = {
    filters: {
      validity: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      ]
    },
    sortOrder: {
      sortBy: 'time',
      direction: 'desc',
    }
  };
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    private pageableListService: PageableListService,
  ) {}

  async ngOnInit() {
    this.initializeList();
  }

  pageChanged(pageNumber: number) {
    this.pageableList.fetchResults(pageNumber);
    this.pageableList.onListChanged();
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'activity-log',
      this.defaultFilter
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
