import { Component, Inject, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { ListService } from '@app/common/services/list.service';

import { AuthService } from '@app/common/services/auth.service';
import { TabModel } from '@app/common/models/tab.model';
import { DocumentModel } from '@app/common/models/document.model';
import { NoteModel } from '@app/common/models/note.model';
import { ProjectPropertiesGroupModel } from '@app/ps/models/project-properties-group.model';
import { ListModel } from '@app/common/models/list.model';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ModulesService } from '@app/common/services/modules.service';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { DialogService } from '@app/common/services/dialog.service';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { IsprofondPipe } from '@app/ps/pipes/isprofond.pipe';

@Component({
  selector: 'project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {
  loaded: boolean = false;
  project: any;
  noteList: ListModel<NoteModel>;
  landTakeDocumentationNoteList: ListModel<NoteModel>;
  documentList: ListModel<DocumentModel>;
  tab: string;
  tabs: TabModel[];
  usersTemp: any = {};
  customGroupsVisibility = [
    'podepisujici1JinaOsoba',
    'podepisujici2JinaOsoba',
    'podepisujici3JinaOsoba',
    'podepisujici4JinaOsoba',
    'podepisujici5JinaOsoba',
    'podepisujici6JinaOsoba',
    'podepisujici7JinaOsoba',
  ];
  projectProperties: ProjectPropertiesGroupModel[];
  helpIds = HelpService.HELP_IDS;
  private additionalInfoChanged = false;
  private deregisterLeaveListener: Function;
  additionalInfoCollapsed: { [key: string]: boolean } = {
    'Údaje k akci': false,
    'Obecné údaje do smlouvy': true,
    'Údaje do nájemní smlouvy / průvodního dopisu': true,
    'Údaje do průvodního dopisu': true,
    'Dokument finanční kontroly / Referátník': true,
  };
  paymentEasement: any;
  paymentTemporaryOccupation: any;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(RESTANGULAR_SYMAP) public restangularSymap: any,
    private restangular: Restangular,
    public authService: AuthService,
    public userNamePipe: UserNamePipe,
    public isprofondPipe: IsprofondPipe,
    private stateService: StateService,
    private listService: ListService,
    private modulesService: ModulesService,
    private brandTranslatePipe: BrandTranslatePipe,
    private dialogService: DialogService,
    private transitionService: TransitionService,
  ) {
    this.project = authService.getActualProject();
    this.uiOnParamsChanged(this.stateService.params);
    this.isActiveTab = this.isActiveTab.bind(this);
    this.saveProperties = this.saveProperties.bind(this);
    this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.onAdditionalInfoChanged = this.onAdditionalInfoChanged.bind(this);
    this.onChangePayment = this.onChangePayment.bind(this);
  }

  async ngOnInit() {
    document.title = this.brandTranslatePipe.transform('ps.projectInfo');
    this.tabs = this.getTabs();
    this.loadNotes();
    this.loadLandTakeDocumentationNotes();
    this.loadDocuments();
    await this.loadProperties();
    this.setupPayments();
    await this.loadProject();
    this.loaded = true;
  }

  async loadProject() {
    this.project = await this.modulesService.loadSyMAPProjectById(this.project.id);
    this.setupPayments();
  }

  async loadProperties() {
    this.projectProperties = await this.restangular.all('properties').customGET('').toPromise();

    for (const group of this.projectProperties) {
      for (const item of group.items) {
        if (item.type === 'USER_SELECT' && item.valueLong) {
          this.usersTemp[item.templateVariable] = await this.modulesService.getUser(item.valueLong).then(d => d.plain());
        }
      }
    }
  }

  onChangePayment(type: 'Easement' | 'TemporaryOccupation', event: any) {
    const attrName = 'payment' + type;
    if (event.newValue) {
      this.project[attrName] = event.newValue.isprofond;
    } else {
      this.project[attrName] = undefined;
    }
  }

  onAdditionalInfoChanged() {
    if (!this.deregisterLeaveListener) {
      this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    }
    this.additionalInfoChanged = true;
    if (this.APP_BRAND.NAME === 'RSD') {
      this.tabs.find(t => t.id === 'additional-info').required = true;
    }
  }

  private beforeExit(transition: Transition) {
    if (!this.additionalInfoChanged) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Provedené úpravy nejsou uložené. Opravdu chcete opustit záložku bez uložení?')
      .then((data) => {
        if (data === true) {
          this.loadProperties();
          this.loadProject();
          this.deregisterLeaveListener();
          this.deregisterLeaveListener = null;
          this.tabs.find(t => t.id === 'additional-info').required = false;
        } else {
          return Promise.resolve(false);
        }
      });
  }

  async saveProperties() {
    if (!this.loaded) {
      return;
    }
    this.additionalInfoChanged = false;
    this.tabs.find(t => t.id === 'additional-info').required = false;
    this.modulesService.saveProject(this.APPLICATIONS.sy.name, this.project);
    this.projectProperties = await this.restangular.all('properties').customPOST(this.projectProperties).toPromise();
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    if (changedParams.tab !== undefined) {
      this.tab = changedParams.tab || 'notes';
    }
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky k ${this.APP_BRAND.NAME === 'RSD' ? 'akci' : 'projektu'} (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateLandTakeDocumentationNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'land-take-documentation-notes');
    noteTab.name = `Stav dat záborového elaborátu (${this.landTakeDocumentationNoteList.itemCount})`;
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUserChanged(user: any, group: any, prefix: string) {
    if (user) {
      group.items.find(i => i.templateVariable === prefix).valueLong = user.id;

      let item = group.items.find(i => i.templateVariable === prefix + 'NazevSpolecnosti');
      if (item) {
        item.valueText = user.ssoCompanyName || (user.company ? user.company.name : '');
      }

      item = group.items.find(i => i.templateVariable === prefix + 'PrijmeniJmeno');
      if (item) {
        item.valueText = [user.surname, user.name].join(' ');
      }

      item = group.items.find(i => i.templateVariable === prefix + 'Email');
      if (item) {
        item.valueText = user.email;
      }
    }
  }

  isVisible(group, item): boolean {
    const sw = this.customGroupsVisibility.find(tv => item.templateVariable.indexOf(tv) !== -1);

    if (!sw || sw === item.templateVariable) {
      return true;
    }

    const switchItem = group.items.find(i => i.templateVariable === sw);

    return switchItem && switchItem.valueBoolean;
  }

  translate(text: string): string {
    if (text.indexOf('ŘSD')) {
      text = text.replace('ŘSD ČR', this.brandTranslatePipe.transform('ps.investorCommonName'));
    }

    return text;
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: {projectInfo: true, systemType: false, cancelled: false, },
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadLandTakeDocumentationNotes() {
    this.landTakeDocumentationNoteList = this.listService.createList(
      'notes',
      {
        filters: {landTakeDocumentation: true, systemType: false, cancelled: false},
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.landTakeDocumentationNoteList).then(() => {
      this.onUpdateLandTakeDocumentationNoteList();
    });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      'attachments',
      {
        limit: null,
        filters: { projectInfo: true, cancelled: false },
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}}
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private getTabs(): TabModel[] {
    return [{
      name: 'Poznámky k ' + (this.APP_BRAND.NAME === 'RSD' ? 'akci' : 'projektu'),
      id: 'notes',
      href: 'symap.project.info',
      urlParams: {tab : 'notes'},
    },
    {
      name: 'Stav dat záborového elaborátu',
      id: 'land-take-documentation-notes',
      href: 'symap.project.info',
      urlParams: {tab : 'land-take-documentation-notes'},
    },
    {
      name: 'Doplňkové údaje akce',
      id: 'additional-info',
      href: 'symap.project.info',
      urlParams: { tab : 'additional-info' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: 'symap.project.info',
      urlParams: {tab : 'documents'},
    },
    ];
  }

  private setupPayments() {
    if (this.project.paymentEasement) {
      this.paymentEasement = { isprofond: this.project.paymentEasement, actionName: '' };
    }
    if (this.project.paymentTemporaryOccupation) {
      this.paymentTemporaryOccupation = { isprofond: this.project.paymentTemporaryOccupation, actionName: '' };
    }
  }

  canEdit() {
    return this.authService.hasPermission('admin');
  }
}
