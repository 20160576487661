<div class="detail">
  <gmt-button [clickAction]="goToCase"><i class="fa fa-chevron-left mr-5"></i> Zpět</gmt-button>
  <div [loading]="!occupationObject">
    <ng-container *ngIf="occupationObject">
      <fieldset class="bordered">
        <div class="column-50"><label>Parcela</label>
          <div class="info"><a uiSref="symap.project.parcels.detail" [uiParams]="{id: occupationObject.parcel.id}">{{occupationObject.parcel|parcelnumber}}</a></div>
        </div>
        <div class="column-50"><label>Katastrální území</label>
          <div class="info">{{occupationObject.parcel.katuzeNazev}}</div>
        </div>
        <div class="column-50"><label>Výměra parcely</label>
          <div class="info">{{occupationObject.parcel.vymera}} m<sup>2</sup></div>
        </div>
        <div class="column-50"><label>Druh záboru</label>
          <div class="info">{{occupationObject.zabtyptx}}</div>
        </div>
        <div class="column-50"><label>Výměra záboru</label>
          <div class="info">{{occupationObject.zabvym}} m<sup>2</sup></div>
        </div>
        <div class="column-50"><label>Číslo záboru</label>
          <div class="info">{{occupationObject.zabcis}}</div>
        </div>
        <div class="column-50"><label>{{'ETAPA_USEK'|wordtranslate}}</label>
          <div class="info">{{occupationObject.zabst}}</div>
        </div>
      </fieldset>

      <div class="bordered fieldset no-padding">
        <div class="legend">Aktuálně platné parcely nalezené podle geometrie záboru</div>
        <div [loading]="parcelList.loading">
          <div class="table" *ngIf="parcelList.list?.length">
            <div class="head-group">
              <div class="row">
                <div class="cell min"></div>
                <div class="cell">{{ getColumnName('parcels', 'parcel') }}</div>
                <div class="cell">{{ getColumnName('parcels', 'areaName') }}</div>
                <div class="cell">{{ getColumnName('parcels', 'lv') }}</div>
                <div class="cell">{{ getColumnName('parcels', 'owner') }}</div>
                <div class="cell">{{ getColumnName('parcels', 'case') }}</div>
                <div class="cell">{{ getColumnName('parcels', 'protection') }}</div>
              </div>
            </div>
            <div class="row-group">
              <div class="row" *ngFor="let parcel of parcelList.list" (click)="onToggleCheckItem(parcel)">
                <div class="cell"><gmt-radio [gmtChecked]="selected && selected.id === parcel.id"></gmt-radio></div>
                <div class="cell no-padding cell-max-height">
                  <cell-parcel
                    [parcel]="parcel"
                    [tooltipType]="'parcel'"
                    [tooltipData]="{ parcel: parcel, withoutTitle: true }"
                  ></cell-parcel>
                </div>
                <div class="cell">{{ parcel.area.name }}</div>
                <div class="cell cell--flex">
                  <span *ngIf="!parcel.title?.jpvExists">
                    <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" (click)="$event.stopPropagation()">
                      {{ parcel.title.lv }}
                    </a>
                  </span>
                  <span *ngIf="parcel.title?.jpvExists" class="cell--flex__container">
                    <span class="cell--flex__container__main">
                      <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" (click)="$event.stopPropagation()">
                        {{ parcel.title.lv }}
                      </a>
                    </span>
                    <span class="cell--flex__container_side">
                      <span title="Jiné právní vztahy" class="tag tag--inactive tag--small is-uppercase ml-5">JPV</span>
                    </span>
                  </span>
                </div>
                <div class="cell no-padding cell-max-height">
                  <cell-ownership
                    *ngIf="parcel.title && parcel.title.id"
                    [count]="parcel.title.countOwnerships"
                    [ownerships]="parcel.title.ownerships"
                    [tooltipData]="{ filter: {sortOrder: {sortBy: 'subjectName'}, filters: { titleId: parcel.title.id, validity: 'valid' }}, attributes: {subject: {load: false}}}"
                    tooltipType="ownership-list"
                  ></cell-ownership>
                </div>
                <div class="cell no-padding cell-max-height">
                  <cell-cases
                    [casesCount]="parcel.countCases"
                    tooltipType="cases"
                    [tooltipData]="{ filter: { filters: { parcelId: parcel.id, cancelStatus: 'notCancelled' }}}"
                  ></cell-cases>
                </div>
                <div class="cell">
                  {{ parcel | parcelprotection }}
                </div>
              </div>
            </div>
          </div>
          <div class="empty-info" *ngIf="parcelList.list?.length === 0">Nenalezeny žádné zábory</div>
        </div>
      </div>

      <div class="bordered fieldset no-padding">
        <div class="legend">Případy, na kterých bude provedena aktualizace záboru a příslušné parcely</div>
        <div [loading]="caseList.loading">
          <div class="table" *ngIf="caseList.list?.length">
            <div class="head-group">
              <div class="row">
                <div class="cell min"></div>
                <div class="cell">{{getColumnName('cases', 'caseNumber')}}</div>
                <div class="cell">{{getColumnName('cases', 'areaName')}}</div>
                <div class="cell">{{getColumnName('cases', 'lv')}}</div>
                <div class="cell">{{getColumnName('cases', 'owner')}}</div>
                <div class="cell">{{getColumnName('cases', 'caseType')}}</div>
                <div class="cell">{{getColumnName('cases', 'caseStatus')}}</div>
                <div class="cell">{{getColumnName('cases', 'mandatary')}}</div>
              </div>
            </div>
            <div class="row-group">
              <div class="row" *ngFor="let case of caseList.list" (click)="onToggleCheckCaseItem(case)">
                <div class="cell"><gmt-checkbox [gmtChecked]="isCaseChecked(case)" [gmtDisabled]="isCaseDisabled(case)"></gmt-checkbox></div>
                <div class="cell"><a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{ case.mpp.number }}</a></div>
                <div class="cell">{{ case.titles | titleAreaNames }}</div>
                <div class="cell number">
                  <a *ngIf="case.titles.length === 1; else manyTitlesBlock" uiSref="symap.project.titles.detail" [uiParams]="{ id: case.titles[0].id, tab: null }" (click)="$event.stopPropagation()">
                    {{case.titles[0].lv}}
                  </a>
                  <ng-template #manyTitlesBlock>
                    {{ case.titles | titleNames }}
                  </ng-template>
                </div>
                <div class="cell no-padding cell-max-height">
                  <cell-ownership
                    [count]="case.countOwnerships"
                    [ownerships]="case.caseOwnerships"
                    [tooltipData]="{filter: {sortOrder: {sortBy: 'subjectName'}, filters: {caseId: case.id}}, attributes: {subject: {load: false}}}"
                    [tooltipType]="'ownership-list'"
                  ></cell-ownership>
                </div>
                <div class="cell">{{ case.obligation.name }}</div>
                <div class="cell"><div>{{ case.caseStatus.name }}</div>
                  <div *ngIf="case.caseStatusSubjects" class="comma-list"><span
                    *ngFor="let subject of case.caseStatusSubjects">{{subject | subjectName}}</span></div>
                </div>
                <div class="cell">
                  {{case.titles | titleUserUniqueNames }}
                </div>
              </div>
            </div>
          </div>
          <div class="empty-info" *ngIf="caseList.list?.length === 0">Nenalezeny žádné případy</div>
        </div>
      </div>

      <div>
        <gmt-button [clickAction]="update" [gmtDisabled]="!isValid()">Uložit</gmt-button>
      </div>
    </ng-container>
  </div>
</div>
