import { Component, Input, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import * as _ from 'lodash';

import { ContractService } from '@app/settings/customers/services/contract.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ConfirmationConfigData } from '@app/common/models/dialog-config';
import { CustomerContract } from '@app/models/customerContract';

@Component({
  selector: 'contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss'],
})
export class ContractDetailComponent implements OnInit {
  @Input() contract: CustomerContract;

  filename: string;

  constructor(
    private contractService: ContractService,
    private dialogService: DialogService,
    private stateService: StateService,
  ) {
    this.deleteContract = this.deleteContract.bind(this);
    this.editContract = this.editContract.bind(this);
    this.addProject = this.addProject.bind(this);
  }

  ngOnInit() {
    if (this.contract && this.contract.location) {
      const location = this.contract.location.split('/');
      this.filename = location[location.length - 1];
    }
  }

  async downloadFile(): Promise<any> {
    return await this.contractService.getOneFile(this.contract.idText, this.filename);
  }

  public deleteContract() {
    const data = <ConfirmationConfigData> {
      msg: 'Smlouva bude zneplatněna a vazby na projekty zrušeny.',
    };
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data,
      className: ClassName.ADJUSTED_DIALOG,
    });
    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      sub.unsubscribe();
      if (result) {
        return this.contractService
          .deleteOne(this.contract.idText)
          .then(() => {
            this.goToCustomerDetail();
          });
      }
    });
  }

  public editContract(): void {
    this.stateService.go('.edit');
  }

  public addProject(): void {
    this.stateService.go('.project');
  }

  public async removeProject(projectId: number): Promise<any> {
    return await this.contractService
      .deleteProject(this.contract.idText, projectId)
      .then(() => {
        _.remove(this.contract['projects'], p => p.id === projectId);
        this.contract['projects'] = [].concat(this.contract['projects']); // force change detection
      });
  }

  private goToCustomerDetail(): void {
    this.stateService.go('^.^');
  }
}
