import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChangelogModel } from '@app/common/models/changelog.model';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { ListModel } from '@app/common/models/list.model';
import { EmailUtils } from '@app/common/utils/email.utils';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';

@Component({
  selector: 'changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangelogComponent implements OnInit {
  @Input() showCount = 1;
  changelogList: ListModel<ChangelogModel>;
  collapsed = true;

  constructor(
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: any,
    private listService: ListService,
    private authService: AuthService,
  ) {
    this.onAccept = this.onAccept.bind(this);
  }

  ngOnInit() {
    const filter = {
      filters: {
        unacceptedByUserId: this.authService.getUser().id,
        application: this.authService.getActiveApplication().name
      },
      limit: this.showCount,
      sortOrder: { sortBy: 'publishDate', direction: 'desc' },
    };
    this.changelogList = this.listService.createList('changelogs', filter, this.restangularSettings);
    this.listService.fetchResult(this.changelogList);
  }

  onAccept(changelog: ChangelogModel) {
    this.restangularSettings
      .one(`changelogs/${changelog.id}/accept/${this.authService.getUser().id}`)
      .post()
      .toPromise()
      .then(() => {
        this.listService.fetchResult(this.changelogList);
      });
  }

  getPreview(description: string): string {
    return EmailUtils.textToHtml(description);
  }
}

