<div class="tools leaflet-bar leaflet-control" style="display: flex;position: absolute;top: 10px;right: 20px;">
  <button *ngFor="let tool of tools" (click)="onModuleClick(tool.id)" [title]="tool.title">
    <i [ngClass]="[tool.icon, getActiveTool().id === tool.id ? 'active' : '' ]"></i>
  </button>
</div>
<div [hidden]="!infoboxVisible">
  <div class="cm-map-infobox">
    <div class="content">
      <ng-container [ngSwitch]="getActiveTool().id">
        <perfect-scrollbar>
          <div>
            <map-module-info *ngSwitchCase="'info'" [mapId]="mapId" [config]="config" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)" [infoboxWidthGetter]="getInfoboxWidth"></map-module-info>
            <map-module-measure *ngSwitchCase="'measure'" [mapId]="mapId"></map-module-measure>
            <map-module-move *ngSwitchCase="'move'" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)"></map-module-move>
            <map-module-cuzk *ngSwitchCase="'cuzk'" [mapId]="mapId" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)"></map-module-cuzk>
            <!-- moduly dimap -->
            <map-module-intersection *ngSwitchCase="'intersection'" [mapId]="mapId"></map-module-intersection>
            <map-module-cut-parcels *ngSwitchCase="'cutParcels'" [mapId]="mapId" [infoboxWidthGetter]="getInfoboxWidth"></map-module-cut-parcels>
          </div>
        </perfect-scrollbar>

        <div>
          <map-module-print *ngSwitchCase="'print-case'" [config]="config" [printData]="config.printCaseData" [mapId]="mapId" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)"></map-module-print>
          <map-module-print *ngSwitchCase="'print'" [config]="config" [printData]="config.printData" [mapId]="mapId" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)"></map-module-print>
          <map-module-print-project *ngSwitchCase="'print-project'" [config]="config" [printData]="config.projectPrintData" [mapId]="mapId" (visibilityInfoboxCallback)="visibilitySetter($event.infoboxVisibility)"></map-module-print-project>
        </div>
      </ng-container>
    </div>
  </div>
</div>
