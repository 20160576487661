import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

import { AlertComponent } from '@app/common/components/alert/alert.component';
import { CadastreRequestModel } from '@app/settings/modules/models/cadastre-request.model';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { MonthPickerOptionsInput } from '@app/common/components/month-picker/month-picker.component';
import { RequestModel } from '@app/pk/models/request.model';
import { RESTANGULAR_PROMAP } from '@app/common/services/restangular-promap.service';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@app/common/services/http.service';
import { APP_CONFIG } from '@app/common/services/config.service';

@Component({
  selector: 'cadastre-data-request',
  templateUrl: './cadastre-data-request.component.html',
  styleUrls: ['./cadastre-data-request.component.scss'],
})
export class CadastreDataRequestComponent implements OnInit {

  requestDate: string;
  unprocessedRequests: RequestModel[] = [];
  lastRequest = false;

  public readonly monthPickerOptions: MonthPickerOptionsInput = {
    minDate: moment().subtract(1, 'month').toDate(),
    maxDate: moment().add(1, 'month').toDate(),
    defaultDate: moment().add(1, 'month').toDate(),
  };

  constructor(
    private dialogService: DialogService,
    @Inject(RESTANGULAR_PROMAP) private restangularPromap,
    @Inject(APP_CONFIG) private config: any,
    private httpService: HttpService
  ) {  }

  ngOnInit() {
    this.onExistingRequestsDownload = this.onExistingRequestsDownload.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onExistingRequestsDownload() {
    let requests = await this.getBaseRequest().get().toPromise();
    requests = requests.items;

    this.processFoundRequests(requests);
    return requests;
  }

  onRequestDateChange(requestDate: Date) {
    this.requestDate = formatDate(requestDate, 'yyyy-MM-dd', 'cs');
    this.checkUnprocessedRequests();
  }

  async onSubmit(): Promise<CadastreRequestModel[]> {
    const requests = await this.getBaseRequest().customPOST({}, '', { validityDate: this.requestDate, isLast: this.lastRequest }).toPromise();
    this.processFoundRequests(requests);
    return requests;
  }

  private async checkUnprocessedRequests() {
    this.unprocessedRequests = await this.restangularPromap
      .one(`cuzk/requests/unprocessed?validityDate=${this.requestDate}`)
      .get()
      .toPromise();
  }

  private async downloadXmlRequest(request: CadastreRequestModel) {
    await this.httpService.call({
      path: `cuzk/xml-request/${request.id}`,
      method: 'GET',
      fullResponse: true,
      responseType: 'blob',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlPK
    }).then((response: HttpResponse<any>) => {
      const filename = HttpService.parseFilename2(response);
      saveAs(response.body, filename);
    });
  }

  private getBaseRequest() {
    return this.restangularPromap
      .all('cuzk')
      .one('xml-request');
  }

  private showMessageOnXmlRequestProcessingDone(msg: string) {
    return this.dialogService.open(AlertComponent, {
      data: {
        msg
      },
      className: ClassName.ADJUSTED_DIALOG,
    });
  }

  private processFoundRequests(requests: CadastreRequestModel[]) {
    if (requests.length === 0) {
      this.showMessageOnXmlRequestProcessingDone('Nebyly nalezeny žádné dosud neodeslané požadavky.');
      return;
    } else {
      this.showDownloadMessage(requests);
    }
  }

  private showDownloadMessage(requests: CadastreRequestModel[]) {
    const msg = this.getDownloadMessage(requests);
    const dialog = this.showMessageOnXmlRequestProcessingDone(msg);
    const sub = dialog.afterClosed.subscribe((downloadWanted: boolean) => {
      if (downloadWanted) {
        requests.forEach(this.downloadXmlRequest, this);
      }
      sub.unsubscribe();
    });
  }

  private getDownloadMessage(requests: CadastreRequestModel[]): string {
    const msg: string[] = [];
    const invalidMsg = this.getInvalidFilesMessage(requests);

    if (requests.length === 1) {
      msg.push('Žádost je připravena, bude stažena po zavření dialogu.');
    } else {
      msg.push('Žádosti jsou připraveny, budou staženy po zavření dialogu.');
    }

    msg.push(invalidMsg);

    return msg.join(' ');
  }

  private getRequestReadableName(request: CadastreRequestModel): string {
    let name = `${request.cuzkZadostId}-${request.poradoveCislo}`;
    name += request.uplatnyRezim
      ? ' (úplatná)'
      : ' (bezúplatná)';

    return name;
  }

  private getInvalidFilesMessage(requests: CadastreRequestModel[]): string | undefined {
    const filesWithInvalidXml: string[] = requests.filter(r => !r.validXml).map(this.getRequestReadableName, this);
    let msg: string;

    if (!filesWithInvalidXml || filesWithInvalidXml.length === 0) {
      return undefined;
    }

    if (filesWithInvalidXml.length > 1) {
      msg = `Žádosti ${filesWithInvalidXml.join(', ')} nejsou validní a mohou být odmítnuty ČÚZK.`;
    } else {
      msg = `Žádost ${filesWithInvalidXml[0]} není validní a může být odmítnuta ČÚZK.`;
    }

    return msg;
  }
}

