import { Component, OnInit, Input } from "@angular/core";
import { StateService } from "@uirouter/angular";

@Component({
  templateUrl: './titles-create-cases-finish.component.html',
})
export class TitlesCreateCasesFinishComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  newOperation: Function;
  @Input()
  goToList: Function;

  constructor(
    private stateService: StateService,
  ) {
    this.goToBulkOperation = this.goToBulkOperation.bind(this);
  }

  ngOnInit() {
  }

  goToBulkOperation() {
    this.stateService.go('symap.project.cases.bulkOperation', {entityIds: this.data.confirm.caseIds});
  }
}
