<div class="detail">
  <div class="flex-centered fill-content flexbox-container mt-200">
    <a uiSref="settings.modules.ps" class="bordered" title="{{ APPLICATIONS.sy.title }}">
      <img src="img/logo/{{ APP_BRAND.PS.ICON_PATH }}_xxl.svg" alt="logo"/>
    </a>
    <a uiSref="settings.modules.dimap" visibleByBrand="settings.modules" class="bordered" title="{{ APPLICATIONS.di.title }}">
      <img src="img/logo/dimap_xxl.svg" alt="logo"/>
    </a>
    <a uiSref="settings.modules.pk" visibleByBrand="settings.modules" class="bordered" title="{{ APPLICATIONS.pk.title }}">
      <img src="img/logo/promap_xxl.svg" alt="logo"/>
    </a>
    <a uiSref="settings.modules.vfze" visibleByBrand="settings.modules" class="bordered" title="{{ APPLICATIONS.vfze.title }}">
      <img src="img/logo/vfze_xxl.svg" alt="logo"/>
    </a>
  </div>
</div>
