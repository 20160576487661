<ul *ngIf="data?.parcel">
  <li class="title" *ngIf="!data.withoutTitle && data.parcel.title?.id">
    <a uiSref="symap.project.titles.detail" [uiParams]="{ id: data.parcel.title.id }">
      List vlastnictví {{data.parcel.title.lv}}
    </a>
  </li>
  <li class="map">
    <a uiSref="symap.project.map" [uiParams]="{parcel: data.parcel.id}">Mapové okno</a>
  </li>
  <li *ngIf="data.parcel.cuzk" class="cuzk">
    <a href="{{ data.parcel.cuzk }}" class="underlined" target="_blank">Nahlížení do katastru</a>
  </li>
  <li class="parcel">
    <a uiSref="symap.project.parcels.detail" [uiParams]="{ id: data.parcel.id }">Detail parcely</a>
  </li>
</ul>
<div *ngIf="!data || !data.parcel">Parcela nenalezena v KN</div>
