import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { MapReloadLayersService } from '@app/map/services/map-reload-layers.service';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { ListService } from '@app/common/services/list.service';
import * as L from 'leaflet';

import getAffectedLayers from '@app/map/services/pure/MapAffectedPropertyLayers';
import { getPropertyAreaColorClass } from '@app/common/utils/map.utils';

@Component({
  selector: 'map-module-cut-parcels',
  templateUrl: './map-module-cut-parcels.component.html',
})
export class MapModuleCutParcelsComponent implements OnInit, OnDestroy {
  @Input() mapId: string;

  @Input() infoboxWidthGetter : Function;

  private map = null;
  public intersectLayerExist = false;
  private intersectionLayer = null
  public cutParcels = null;
  private cutParcelsLayers = null;
  private drawKN = null;

  constructor(
    private mapService: MapService,
    private mapReloadLayersService : MapReloadLayersService,
    private mapHighlightService: MapHighlightService,
    private listService: ListService
  ) {
    this.onDrawCreated = this.onDrawCreated.bind(this);
    this.onNewCut = this.onNewCut.bind(this);
    this.onCutClick = this.onCutClick.bind(this);
    this.onFeatureMouseOut = this.onFeatureMouseOut.bind(this);
    this.onFeatureMouseOver = this.onFeatureMouseOver.bind(this);
  }

  ngOnInit() {
    this.mapService.getMap(this.mapId).then((map) => {
      this.map = map;
    }).then(() => {
      this.startDrawIntersection()
    });
  }

  ngOnDestroy() {
    this.stopIntersection();
    this.clearParcels();
    this.removeHighlightParcel();
    this.map.off(L.Draw.Event.CREATED, this.onDrawCreated);
    this.map = null;
  }

  getVertexIcon() {
    return new L.DivIcon({
      iconSize: new L.Point(8,8),
      className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
    });
  }

  onNewCut() {
    this.clearParcels();
    this.startDrawIntersection();
  }

  onWantedChanged(newValue, oldValue, ownerType) {
    const layersIDs = getAffectedLayers(newValue, oldValue, ownerType);
    layersIDs.push('property_info_geometric_plan');
    this.mapReloadLayersService.reload(this.mapId, layersIDs)
  }

  getAreaClass(owner_type, wanted, key) {
    return getPropertyAreaColorClass(owner_type, wanted, key);
  }

  stopIntersection() {
    if(this.drawKN) {
      this.drawKN.disable();
      this.drawKN = null;
      if(this.intersectionLayer) {
        this.map.removeLayer(this.intersectionLayer);
      }
    }
    L.DomEvent.off(this.map._container, 'keyup', this._cancelDrawing, this);
  }

  _cancelDrawing = (e) => {
    if (e.keyCode === 27 && !this.intersectLayerExist) {
      this.startDrawIntersection();
    }
  }

  startDrawIntersection = () => {
    if (this.drawKN) {
      this.stopIntersection();
    }

    this.setDrawSelectPolylineLocalization();
    this.drawKN = new L.Draw.Polyline(this.map, {showArea: false, showLength: false});

    this.drawKN.options.icon = this.getVertexIcon();

    this.drawKN.enable();

    this.map.once(L.Draw.Event.CREATED, this.onDrawCreated);

    L.DomEvent.on(this.map._container, 'keyup', this._cancelDrawing, this);
  }

  onDrawCreated(evt) {
    setTimeout(() => {
      this.intersectLayerExist = true;
    })
    this.intersectionLayer = evt.layer;
    this.map.addLayer(this.intersectionLayer);
  }

  clearParcels() {
    if (this.cutParcelsLayers) {
      this.cutParcelsLayers.forEach(l => {
        this.map.removeLayer(l);
        l.off('mouseover', this.onFeatureMouseOver);
        l.off('mouseout', this.onFeatureMouseOut);
      })
    }

    this.cutParcels = null;
    this.cutParcelsLayers = null;
    this.intersectLayerExist = false;
  }

  highlightParcel(parcel) {
    this.mapHighlightService.highlightFeature(this.mapId, JSON.stringify(parcel.geometry), false);
  }

  removeHighlightParcel() {
    this.mapHighlightService.removeHighlight(this.mapId);
  }

  getParcelByID(id) {
    return this.cutParcels.find(p => p.properties.id === id);
  }

  setHighlighted(layer, highlighted) {
    const id = layer.feature.properties.id;
    const parcel = this.getParcelByID(id);
    if (parcel) {
      setTimeout(() => {parcel.properties.highlighted = highlighted});
    }
  }

  onFeatureMouseOver(evt) {
    this.setHighlighted(evt.layer, true);
  }

  onFeatureMouseOut(evt) {
    this.setHighlighted(evt.layer, false);
  }

  onCutClick() {
    const filter = {
      filters: {'lineString': JSON.stringify(this.intersectionLayer.toGeoJSON().geometry)}
    }

    const dataList = this.listService.createList('intersections/cut-by-line/4326', filter, undefined,
    {
      division: {
        mapping: {
          attributes: {
            divisionType: {},
          }
        }
      },
      divisionParcel: {
        mapping: {
          attributes: {
            parcel: {
              mapping: {
                attributes: {
                  title:{}
                }
              }
            }
          }
        }
      }
    });

    return this.listService.fetchResult(dataList).then((data) => {
      this.cutParcels = [];
      this.cutParcelsLayers = [];
      this.intersectLayerExist = false;
      this.stopIntersection();
      const bounds = L.latLngBounds([]);
      data.list.forEach((f) => {
        const properties: any = Object.keys(f)
                            .filter(key => key !== 'geometry')
                            .reduce((obj, key) => {
                              obj[key] = f[key];
                              return obj;
                            }, {});
        const feature = {
          type: "Feature",
          properties: {
            id: properties.id,
            wanted: properties.wanted,
            owner_type: properties.divisionParcel.parcel.title.ownerType,
            area: properties.area,
            parcelNumber: properties.divisionParcel.parcel.parcelNumber,
            key: properties.division && properties.division.divisionType ? properties.division.divisionType.key : null,
          },
          geometry: JSON.parse(f.geometry),
          crs: {
                type: 'name',
                properties: {
                    'name': 'urn:ogc:def:crs:EPSG::5514'
                }
              }
        }

        let layerLeaflet = L.Proj.geoJson(feature, {
          style: {
            clickable: true,
            fillColor: 'blue',
            fillOpacity: 0.1,
            color: 'blue',
            weight: 2,
            opacity: 1
          }
        });

        layerLeaflet.on('mouseover', this.onFeatureMouseOver);
        layerLeaflet.on('mouseout', this.onFeatureMouseOut);

        this.map.addLayer(layerLeaflet);
        this.cutParcels.push(layerLeaflet.getLayers()[0].feature);
        this.cutParcelsLayers.push(layerLeaflet);

        const layerBounds = layerLeaflet.getBounds();
        bounds.extend(layerBounds);
      })

      setTimeout(() => {
        this.map.fitBounds(bounds, {
          paddingTopLeft: [10, 10],
          paddingBottomRight: [this.infoboxWidthGetter(), 10]
        });
      });
    });
  }

  setDrawSelectPolylineLocalization() {
    L.drawLocal.draw.handlers.polyline.tooltip.start = "Nakreslete novou hranici.";
    L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
    L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete kreslení.";
  }
}
