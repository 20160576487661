import { Inject, Injectable } from '@angular/core';

import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { MapStorageService } from '@app/map/services/map-storage.service';
import { MapLayersStorageService } from '@app/map/services/map-layers-storage.service';
import { MapLayerService } from '@app/map/services/map-layer.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private mapStorageService: MapStorageService,
    private mapLayersStorage: MapLayersStorageService,
    private mapLayerService: MapLayerService,
  ) { }

  /**
   * Registers listener called with center positions and zoom every move end.
   * @param {String} mapId
   * @param {function} callback
   */
   addPositionListener(mapId, callback) {
      return this.mapStorageService.getMap(mapId).then(function(map) {
          if (typeof callback !== 'function') {
              throw new Error('No callback function provided.');
          }

          map.on('moveend', function (event) {
              if (event && event.target) {
                  var mapTarget = event.target,
                          center = mapTarget.getCenter(),
                          zoom = mapTarget.getZoom();

                  callback.call(this, center.lat, center.lng, zoom);
              }
          });
      });
  }

   getMap(mapId) {
      return this.mapStorageService.getMap(mapId);
  }

   fitBounds(mapId, bounds){
      return this.mapStorageService.getMap(mapId).then(function(map) {
          map.fitBounds(bounds);
      });
  }

  /**
   * If positionListener option is setted, registers position listener.
   * @param {String} mapId
   * @param {object} opts
   */
   setPositionListener(mapId, opts) {
      if (opts.positionListener) {
          this.addPositionListener(mapId, opts.positionListener);
      }
  }

   toggleFullscreen(mapId) {
      return this.mapStorageService.getMap(mapId).then(function(map) {
          map.fullscreenControl.toggleFullScreen(map);
      });
  }

   enableLoadingWatch(mapId){
    /* return CmMapStorage.getMap(mapId).then(function(map) {

        map.on('layeradd', function (e) {

            if (e.layer.loading) console.log('start');

            if (typeof e.layer.on != 'function') return;

            e.layer.on('data:loading', function () { console.log('start'); });
            e.layer.on('data:loaded',  function () { console.log('stop'); });

        });


    });*/

    return this.mapLayersStorage.getLayers(mapId).then((config) => {

        var fnc = (conf) => {

            if (conf.type === MapLayerTypeEnum.wms) {


                //var layer = config.layerObj;
                // console.log(layer)


            }


        };

        this.mapLayerService.forEachLayer(config, fnc);

    });



    // this.on('layerremove', function (e) {
        // if (e.layer.loading) this.spin(false);
        // if (typeof e.layer.on != 'function') return;
        // e.layer.off('data:loaded');
        // e.layer.off('data:loading');
    // }, this);

  }
}

