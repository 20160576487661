import { AreaModel } from './area.model';
import { User } from '@app/models/user';
import { OwnershipModel } from './ownership.model';

export class TitleModel {
  id: number;
  lv: number;
  area: AreaModel;
  user: User;
  ownerships: OwnershipModel[];
  countOwnerships?: number;
  jpvExists?: boolean;
  duplicityOwnership?: boolean;
  ssEU?: boolean;
  ssNonEU?: boolean;
  ssRuskyVliv?: boolean;
}
