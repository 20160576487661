<div class="detail" [loading]="loading">
  <div *ngIf="!loading" class="flex1">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 190px;">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV' | wordtranslate }}</a>
      </div>
      <div class="actions">
        <a class="button" *ngIf="isConstructionObjectGeometry" uiSref="symap.project.map" [uiParams]="{ constructionObject: constructionObject.id }">
          <i class="fa fa-map-marker"></i>Zobrazit v mapovém okně
        </a>
        <a class="button" *ngIf="geometricPlans?.length > 0" (click)="$event.stopPropagation(); onCreateCase();">
          <i class="fa fa-plus-square"></i>Založit případy
        </a>
      </div>
    </div>

    <h1>{{ constructionObject.socis }} {{ constructionObject.sotx }}
      <span>{{ 'ETAPA_USEK' | wordtranslate }}: {{ constructionObject.sousek }}</span>
    </h1>

    <div class="case-top mt-30 mb-70">
      <div class="flex-rest">
        <div class="edit" gmtAuth="assignable">
          <a uiSref="symap.project.constructionObjects.update" [uiParams]="{ id: constructionObject.id }" class="button">
            Upravit údaje
          </a>
        </div>
        <div class="column-50">
          <label>
            Správce{{ constructionObject.administratorType === 'I' ? ' (investor)' : '' }}
          </label>
          <div class="info">
            <b *ngIf="constructionObject.administrator?.customer?.rootCustomer; else administratorSubject">
              {{ (constructionObject.administrator.customer.rootCustomer | projectSubjectName) || "nezvoleno" }}
            </b>
            <ng-template #administratorSubject>
              <b>{{ (constructionObject.administrator | projectSubjectName) || "nezvoleno" }}</b>
            </ng-template>
          </div>
          <ng-container *ngIf="constructionObject.administrator">
            <div *ngIf="constructionObject.administrator?.customer?.rootCustomer; else administrator">
              <div>
                <label>Adresa</label>
                <div class="info">
                  {{ constructionObject.administrator.customer.rootCustomer | fullstreet }}<br/>
                  <span *ngIf="constructionObject.administrator.customer.rootCustomer.quarter !== constructionObject.administrator.customer.rootCustomer.municipality && constructionObject.administrator.customer.rootCustomer.street">
                  {{ constructionObject.quarter }}<br/>
                </span>
                  {{ constructionObject.administrator.customer.rootCustomer | fullmunicipality }}
                </div>
              </div>
              <div *ngIf="constructionObject.administrator.customer.rootCustomer.type.indexOf('OPO') === 0">
                <label>IČ</label><div class="info">{{ constructionObject.administrator.customer.rootCustomer.identificationNumber }}</div>
                <label>DIČ</label><div class="info">{{ constructionObject.administrator.customer.rootCustomer.vatId }}</div>
                <label>Zápis</label><div class="info">
                {{ constructionObject.administrator.customer.rootCustomer.businessRegisterName || 'registr' }},
                oddíl{{ constructionObject.administrator.customer.rootCustomer.businessRegisterSection ? ' ' + constructionObject.administrator.customer.rootCustomer.businessRegisterSection : '' }},
                vložka{{ constructionObject.administrator.customer.rootCustomer.businessRegisterEntry ? ' ' + constructionObject.administrator.customer.rootCustomer.businessRegisterEntry : '' }}</div>
              </div>
              <div *ngIf="constructionObject.administrator.customer.rootCustomer.type === 'OFO'">
                <label>R.Č.</label><div class="info">{{ constructionObject.administrator.customer.rootCustomer.personalIdentificationNumber | personalNumber }}</div>
              </div>
            </div>
            <ng-template #administrator>
              <div>
                <label>Adresa</label>
                <div class="info">
                  {{ constructionObject.administrator | fullstreet }}<br/>
                  <span *ngIf="constructionObject.administrator.quarter !== constructionObject.administrator.municipality && constructionObject.administrator.street">
                  {{ constructionObject.quarter }}<br/>
                </span>
                  {{ constructionObject.administrator | fullmunicipality }}
                </div>
              </div>
              <div *ngIf="constructionObject.administrator.subjectType.indexOf('OPO') === 0">
                <label>IČ</label><div class="info">{{ constructionObject.administrator.identificationNumber }}</div>
                <label>DIČ</label><div class="info">{{ constructionObject.administrator.vatId }}</div>
                <label>Zápis</label><div class="info">
                {{ constructionObject.administrator.businessRegisterName|| 'registr' }},
                oddíl{{ constructionObject.administrator.businessRegisterSection ? ' ' + constructionObject.administrator.businessRegisterSection : '' }},
                vložka{{ constructionObject.administrator.businessRegisterEntry ? ' ' + constructionObject.administrator.businessRegisterEntry : '' }}</div>
              </div>
              <div *ngIf="constructionObject.administrator.subjectType === 'OFO'">
                <label>R.Č.</label><div class="info">{{ constructionObject.administrator.personalIdentificationNumber | personalNumber }}</div>
              </div>
            </ng-template>
          </ng-container>
        </div>
        <div class="column-50">
          <label>Povolující úřad</label>
          <div class="info">{{ constructionObject.sourad || 'nezvoleno' }}</div>
          <ng-container *ngIf="!hideColumnList('realizationDate')">
            <label>Termín realizace</label>
            <div class="info">{{ (constructionObject.realizationDate | date) || 'nezvoleno' }}</div>
          </ng-container>
          <ng-container *ngIf="!hideColumnList('state')">
            <label>Stav</label>
            <div class="info" gmtAuthNot="assignable">
              {{ constructionObject.constructionObjectStatus?.name || 'nezvoleno' }}
            </div>
            <div class="input" gmtAuth="assignable" *ngIf="statuses">
              <gmt-select
                [optionItems]="statuses"
                (changed)="onUpdateConstructionObjectStatus()"
                [data]="constructionObject"
                field="constructionObjectStatus"
                required="true"
              ></gmt-select>
              <div *ngIf="updatingStatus" class="ib sending"><span class="fa fa-circle-o-notch fa-spin"></span> Ukládání...</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'parcels'">
          <div [loading]="occupationList.loading">
            <gmt-labeled-block *ngIf="occupationGroups?.permanent" [collapsed]="true" label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
              <table-occupations-permanent [data]="occupationGroups.permanent" [columns]="occupationPermanentColumns"></table-occupations-permanent>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.permanentWithSolutionType" [collapsed]="true" label="Trvalý zábor bez výkupu (nájem)">
              <table-occupations-temporary [data]="occupationGroups.permanentWithSolutionType" [columns]="permanentWithSolutionTypeColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.underOneYear" [collapsed]="true" [label]="occupationUnderYearTableName">
              <table-occupations-temporary [data]="occupationGroups.underOneYear" [columns]="occupationUnderOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
            <gmt-labeled-block *ngIf="occupationGroups?.overOneYear" [collapsed]="true" [label]="occupationOverYearTableName">
              <table-occupations-temporary [data]="occupationGroups.overOneYear" [columns]="occupationOverOneYearColumns"></table-occupations-temporary>
            </gmt-labeled-block>
          </div>
        </div>
        <div *ngIf="tab === 'easements'">
          <ng-container *ngTemplateOutlet="easements"></ng-container>
        </div>
        <div *ngIf="tab === 'documents'">
          <gmt-documents
            [documentList]="documentList"
            [defaultData]="{ constructionObjectId: constructionObject.id }"
            [authorizedUser]="canEdit()"
            [allowType]="false"
            (updated)="onUpdateDocumentList();loadHistory();"
          ></gmt-documents>
        </div>
        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="false"
            [defaultData]="{ constructionObject: { id: constructionObject.id }, systemType: false }"
            (updated)="onUpdateNoteList();loadHistory();"
          ></gmt-notes>
        </div>
        <div *ngIf="tab === 'notifications'">
          <gmt-notifications
            [notificationList]="notificationList"
            (updated)="onUpdateNotificationList()"
          ></gmt-notifications>
        </div>
        <div *ngIf="tab === 'history'">
          <gmt-notes
            [type]="'history'"
            [noteList]="historyList"
            [editable]="false"
            [allowType]="true"
          ></gmt-notes>
        </div>
      </gmt-navbar>
    </div>
  </div>
  <help [helpId]="helpIds.CONSTRUCTIONOBJECT_DETAIL" class="cm-help-page"></help>
</div>

<ng-template #easements>
  <fieldset *ngIf="showPrice" class="bordered">
    <legend>Cena věcného břemene</legend>
    <div class="edit" *ngIf="constructionObject.countEasementCases === 0" gmtAuth="assignable">
      <a uiSref="symap.project.constructionObjects.detail.updateGlobalEasementPrice" [uiParams]="{ id: constructionObject.id, tab: tab }" class="button">
        Upravit údaje
      </a>
    </div>

    <div class="column-50">
      <label>Způsob zadání ceny</label>
      <div class="info">{{ constructionObject.easementGlobalPriceType === "B" ? 'Hromadně' : (constructionObject.easementGlobalPriceType === "I" ? 'Individuálně' : '') }}</div>
    </div>

    <div class="column-50" *ngIf="constructionObject.easementGlobalPriceType === 'B'">
      <label>Původ ceny</label>
      <div class="info">{{ constructionObject.easementGlobalOtherSource }}</div>
    </div>
    <div class="column-50" *ngIf="constructionObject.easementGlobalPriceType === 'B'">
      <label>Způsob výpočtu ceny</label>
      <div class="info">{{ constructionObject.easementGlobalOtherUnitType === "A" ? 'Plocha' : (constructionObject.easementGlobalOtherUnitType === "S" ? 'Jednorázová cena' : 'Délka') }}</div>
    </div>
    <div class="column-100" *ngIf="constructionObject.easementGlobalPriceType === 'B'">
      <label>Cena VB [<span class="unit">Kč<span *ngIf="constructionObject.easementGlobalOtherUnitType !== 'S'">/m<sup *ngIf="constructionObject.easementGlobalOtherUnitType === 'A'">2</sup></span></span>]</label>
      <div class="info">{{ (constructionObject.easementGlobalOtherUnitType === "A" ? constructionObject.easementGlobalOtherAreaPrice : (constructionObject.easementGlobalOtherUnitType === "S" ? constructionObject.easementGlobalOtherSinglePrice : constructionObject.easementGlobalOtherLengthPrice)) | number }}</div>
    </div>
  </fieldset>

  <div [loading]="geometricPlansLoading">
    <ng-container *ngFor="let geometricPlan of geometricPlans">
      <gmt-labeled-block
        label="{{ (geometricPlan.kutx ? geometricPlan.kutx + ' - ' : '') + geometricPlan.cis }}"
        collapsed="true"
        (changed)="geometricPlan.listener && geometricPlan.listener($event)"
      >
        <fieldset class="bordered no-legend">
          <div class="column-25" *ngIf="geometricPlan.zhot">
            <label>Zhotovitel</label>
            <div class="info">{{ geometricPlan.zhot }}</div>
          </div>
          <div class="column-25">
            <label>Celková plocha VB [<span class="unit">m<sup>2</sup></span>]</label>
            <div [loading]="!geometricPlan.easementList || geometricPlan.easementList.loading" class="info small-spinner">
              <span *ngIf="geometricPlan.totalVymera">{{ geometricPlan.totalVymera | number: '.2' }}</span>
            </div>
          </div>
          <div class="column-25">
            <label>Celková délka VB [<span class="unit">m</span>]</label>
            <div [loading]="!geometricPlan.easementList || geometricPlan.easementList.loading" class="info small-spinner">
              <span *ngIf="geometricPlan.totalDelka">{{ geometricPlan.totalDelka | number:'.2' }}</span>
            </div>
          </div>
          <div class="column-25" *ngIf="showPrice">
            <label>Celková cena VB</label>
            <div [loading]="!geometricPlan.easementList || geometricPlan.easementList.loading" class="info small-spinner">
              <span *ngIf="geometricPlan.totalPrice">{{ geometricPlan.totalPrice | number | price }}</span>
            </div>
          </div>
        </fieldset>

        <div class="bordered" *ngIf="!geometricPlan.easementList || geometricPlan.easementList.loading" loading="true"></div>

        <div *ngIf="geometricPlan.easementList?.list">
          <div *ngIf="geometricPlan.easementList.list.length === 0">Geometrický plán neobsahuje žádná věcná břemena</div>
          <div *ngIf="geometricPlan.easementList.list.length > 0">
            <table-geometric-plans [data]="geometricPlan.easementList.list" [columns]="geometricPlanColumns"></table-geometric-plans>
          </div>
        </div>
      </gmt-labeled-block>
    </ng-container>
  </div>
</ng-template>
