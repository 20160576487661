import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { AdministratorEditComponent } from '@app/ps/components/administrator-edit/administrator-edit.component';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'administrator-detail',
  templateUrl: './administrator-detail.component.html',
})
export class AdministratorDetailComponent implements OnInit {

  @Input() administratorId: number;

  loading = true;
  administrator: ProjectSubjectModel;

  constructor(
    private restangular: Restangular,
    private dialogService: DialogService,
  ) {
    this.onEdit = this.onEdit.bind(this);
  }

  ngOnInit() {
    this.restangular
      .one('administrators', this.administratorId)
      .get({ loadCollections: [ 'actingPersons' ] })
      .toPromise()
      .then(data => {
        this.administrator = data.plain();
        this.loading = false;
      });
  }

  onEdit() {
    const dialog = this.dialogService.open(AdministratorEditComponent, {
      data: { administratorId: this.administrator.id }
    });

    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        Object.assign(this.administrator, res);
      }
      sub.unsubscribe();
    });
  }
}
