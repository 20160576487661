<h1>Potvrzení výběru vlastníka</h1>

<ng-template #parentText>
  <span class="comma-list"><span *ngFor="let titleOwnership of ownershipsPossibleParents">{{ titleOwnership.ownership | ownershipName:'name' }}</span></span>
</ng-template>

<p class="font-size-16 mb-10">Na tomto LV se nachází organizace, která má jeden z typů práva či příslušnosti hospodaření s majetkem vlastníka. Jde o organizaci:</p>

<p *ngFor="let titleOwnership of ownershipsWithoutParent" class="font-size-16 text-center">
  <b>{{ titleOwnership.ownership | ownershipName:'name' }} - {{ titleOwnership.ownership.typravNazev }}</b>
</p>

<p class="font-size-16 mt-10 mb-10">Případy je nutné zakládat vždy s organizací, se kterou budete dále jednat, což nutně nemusí být Vámi vybraný faktický vlastník <ng-container *ngTemplateOutlet="parentText"></ng-container></p>

<p class="font-size-16">Opravdu chcete založit případ s vybraným vlasníkem <ng-container *ngTemplateOutlet="parentText"></ng-container> a nikoliv s pověřenou organizací <span class="comma-list"><span *ngFor="let titleOwnership of ownershipsWithoutParent">{{ titleOwnership.ownership | ownershipName:'name' }}</span></span>?


<div class="text-center">
  <gmt-button [clickAction]="onSubmit" class="pr-15">Ano</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Ne</gmt-button>
</div>
