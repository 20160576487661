import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gmt-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() text: string;
  @Input() sref: string;

  constructor() { }

  ngOnInit() {
  }

}
