import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { StringUtils } from '@app/common/utils/string.utils';
import { MonthPickerOptionsInput } from '@app/common/components/month-picker/month-picker.component';
import { CustomerNamePipe } from '@app/common/pipes/customer-name.pipe';
import { Customer } from '@app/models/customer';
import { CustomerContract } from '@app/models/customerContract';
import { CustomerContractNamePipe } from '@app/common/pipes/customer-contract-name.pipe';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './create-project-second-step.component.html'
})
export class CreateProjectSecondStepComponent implements OnInit {

  // model
  loading: boolean;
  projectName = '';
  prijemceIdentifikatorSmlouvy = '';
  prijemcePredmetSmlouvy = '';
  prijemceDatumUzavreniSmlouvy = '';
  cuzkWebserviceName = '';
  cuzkWebservicePassword = '';
  saveWsdpCredentials = true;
  oneTimeRequest = true;
  periodicalRequest = false;
  periodRequestEndDate = '';
  validityDate: Date;
  otherLegalRelationships = true;
  conditions = false;
  user: User;
  cuzkAccountValidationMessage: string;
  cuzkAccountExpirationMessage: string;
  hasValidWsdpAccount = true;
  monthPickerOptions: MonthPickerOptionsInput;
  customer: Customer;
  contract: CustomerContract;
  reloadContractOptions: Function;
  canSelectCustomer: boolean;

  // params
  private formData: FormData;
  private type: string;
  requestId: number;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) public configRestangular: any,
    private restangular: Restangular,
    public customerNamePipe: CustomerNamePipe,
    public customerContractNamePipe: CustomerContractNamePipe,
    private stateService: StateService,
    private authService: AuthService,
    private datePipe: DatePipe,
  ) {
    this.onValidDateChanged = this.onValidDateChanged.bind(this);
    this.onCustomerSelected = this.onCustomerSelected.bind(this);
    this.setReloadContractOptions = this.setReloadContractOptions.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
  }

  ngOnInit() {
    if (!this.stateService.params.formData && !this.stateService.params.requestId) {
      this.stateService.go('pk.projects.create-project-first');
    }

    this.loading = true;
    this.formData = this.stateService.params.formData;
    this.type = this.stateService.params.type;
    this.requestId = this.stateService.params.requestId;
    this.user = this.authService.getUser();
    this.canSelectCustomer = (!this.requestId && this.user.company.companyName === 'GMtech, s.r.o.');

    if (this.requestId) {
      this.initRepeatRequest();
    } else {
      this.initNewRequest();
    }
  }

  onValidDateChanged(date: Date) {
    this.validityDate = date;
  }

  isFormValid() {
    // Validation of repeating request
    if (this.requestId) {
      return this.validityDate;
    }

    // WSDP account validation - if user has no credential and didn't fill it...
    if (!this.hasValidWsdpAccount && !(this.cuzkWebserviceName && this.cuzkWebservicePassword)) {
      return false;
    }

    // Form validation
    return (
      this.projectName
      && (!this.canSelectCustomer || this.customer)
      && this.prijemceIdentifikatorSmlouvy
      && this.prijemcePredmetSmlouvy
      && this.prijemceDatumUzavreniSmlouvy
      && this.validityDate
      && (!this.periodicalRequest || (this.periodicalRequest && this.periodRequestEndDate))
      && this.conditions
    );
  }

  continue() {
    // only when repeated request has unchanged geometry (from first step)
    if (this.requestId && !this.type) {
      return this.uploadRepeatRequest();
    }

    if (this.cuzkWebserviceName && this.cuzkWebservicePassword) {
      return this.validateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword)
        .then(ok => {
          if (ok) {
            this.uploadData();
          }
        });
    }

    return this.uploadData();
  }

  cancel() {
    this.stateService.go('pk.projects');
  }

  onCustomerSelected() {
    this.checkValidityOfWsdpAccount();
    this.contract = null;
    this.onContractSelected();

    if (this.reloadContractOptions) {
      this.reloadContractOptions();
    }
  }

  onContractSelected() {
    this.prijemceIdentifikatorSmlouvy = this.contract && this.contract.contractNumber;
    this.prijemcePredmetSmlouvy = this.contract && this.contract.subject;
    this.prijemceDatumUzavreniSmlouvy = this.contract && this.contract.signDate;
  }

  setReloadContractOptions(reloadFn: Function) {
    this.reloadContractOptions = reloadFn;
  }

  private async initNewRequest() {
    const nextMonthDate = moment().add(1, 'month').toDate();
    this.monthPickerOptions = {
      minDate: await this.isCurrentMonthClosed() ? nextMonthDate : new Date(),
      maxDate: nextMonthDate,
      defaultDate: nextMonthDate
    };
    this.checkValidityOfWsdpAccount();
  }

  private async initRepeatRequest() {
    this.restangular.one(`projects/${this.requestId}`).get()
      .toPromise()
      .then(async (data) => {
        this.projectName = data.name;
        this.prijemceIdentifikatorSmlouvy = data.identifikatorSmlouvy;
        this.prijemcePredmetSmlouvy = data.predmetSmlouvy;
        this.prijemceDatumUzavreniSmlouvy = data.datumUzavreniSmlouvy;
        this.otherLegalRelationships = data.jpv;

        const currentDate = new Date();
        const nextMonthDate = moment().add(1, 'month').toDate();
        const dataDate = moment(data.version).toDate();

        this.monthPickerOptions = {
          minDate: await this.isCurrentMonthClosed() || (dataDate.getFullYear() === currentDate.getFullYear() && dataDate.getMonth() >= currentDate.getMonth())
            ? nextMonthDate
            : currentDate,
          maxDate: nextMonthDate,
          defaultDate: nextMonthDate
        };

        this.customer = data.prijemce.company || data.company;
        this.loading = false;
      });
  }

  private fillFormData() {
    const company = this.customer ? this.customer : this.user.company;

    // Form values
    this.formData.append('NAME', this.projectName);
    this.formData.append('PRIJEMCE_IDENTIFIKATOR_SMLOUVY', this.prijemceIdentifikatorSmlouvy);
    this.formData.append('PRIJEMCE_PREDMET_SMLOUVY', this.prijemcePredmetSmlouvy);
    this.formData.append('PRIJEMCE_DATUM_UZAVRENI_SMLOUVY', this.changeDateFormat(this.prijemceDatumUzavreniSmlouvy));
    this.formData.append('OPAKOVANA_MESICNI_ZADOST', StringUtils.valueToString(this.periodicalRequest));
    this.formData.append('OPAKOVANA_MESICNI_ZADOST_DO', this.changeDateFormat(this.periodRequestEndDate));
    this.formData.append('DATUM_PLATNOSTI', this.changeDateFormat(this.validityDate));
    this.formData.append('JPV', StringUtils.valueToString(this.otherLegalRelationships));

    // Company values
    this.formData.append('PRIJEMCE_COMPANY_ID_TEXT', company.idText);
    this.formData.append('PRIJEMCE_JMENO', this.user.name);
    this.formData.append('PRIJEMCE_PRIJMENI', this.user.surname);
    this.formData.append('PRIJEMCE_COMPANY_ID', StringUtils.valueToString(company.id));
    this.formData.append('PRIJEMCE_NAZEV', StringUtils.valueToString(company.companyName));
    this.formData.append('PRIJEMCE_ICO', StringUtils.valueToString(company.identificationNumber));
    this.formData.append('PRIJEMCE_ULICE', StringUtils.valueToString(company.street));
    this.formData.append('PRIJEMCE_CISLO_DOMOVNI', StringUtils.valueToString(company.houseNumber));
    this.formData.append('PRIJEMCE_OBEC', StringUtils.valueToString(company.municipality));
    this.formData.append('PRIJEMCE_PSC', StringUtils.valueToString(company.postCode));

    // Default values
    this.formData.append('CENY', 'false');
    this.formData.append('UPLATNY_REZIM', 'true');
    this.formData.append('DESCRIPTION', '');
    this.formData.append('ANALOG_PARCELS', '');
    this.formData.append('PRIJEMCE_DATUM_NAROZENI', '');

    // If new WSDP account
    if (this.cuzkWebserviceName && this.cuzkWebservicePassword) {
      this.formData.append('CUZK_WEBSERVICE_NAME', this.cuzkWebserviceName);
      this.formData.append('CUZK_WEBSERVICE_PASSWORD', this.cuzkWebservicePassword);
      this.formData.append('CUZK_SAVE_CREDENTIALS', StringUtils.valueToString(this.saveWsdpCredentials));
    }
  }

  private validateWsdpAccount(name: string, password: string): Promise<boolean> {
    this.cuzkAccountValidationMessage = '';

    return this.restangular.one('cuzk/verify-credentials').customPOST({
      userName: name,
      password: password
    }).toPromise().then(data => {
      const { valid } = data;
      this.cuzkAccountValidationMessage = (valid ? '' : 'Zadali jste neplatné přihlašovací údaje k WSDP účtu.');
      return valid;
    });
  }

  private checkValidityOfWsdpAccount() {
    this.cuzkAccountExpirationMessage = '';
    const params = (this.customer && this.customer.idText)
      ? { companyIdText: this.customer.idText }
      : undefined;

    this.restangular.one('cuzk/verify-saved-credentials').customGET('', params).toPromise().then(data => {
      const { valid, closeToExpire, msg } = data;
      this.hasValidWsdpAccount = valid;
      this.cuzkAccountExpirationMessage = (valid && closeToExpire && msg) ? msg : '';
      this.loading = false;
    });
  }

  private uploadData() {
    this.fillFormData();

    return this.restangular.all('request').customPOST(
      this.formData,
      '',
      { type: this.type },
      { 'Content-Type': undefined }
    ).toPromise().then((data) => {
      this.stateService.go('pk.projects.create-project-third', { requestId: data.request.requestId });
    });
  }

  private uploadRepeatRequest() {
    return this.restangular.all(`request/${this.requestId}/repeat`).customPOST(
      {},
      null,
      { validityDate: this.changeDateFormat(this.validityDate) }
    ).toPromise().then((data) => {
      this.stateService.go('pk.projects.create-project-third', { requestId: data.request.requestId });
    });
  }

  private changeDateFormat(date: any): string {
    const dateString = StringUtils.valueToString(this.datePipe.transform(date));
    return StringUtils.removeSpaces(dateString);
  }

  private async getFirstPossibleDateByCuzkReport(): Promise<Date> {
      const cuzkReportDates = await this.restangular.one(`cuzk/xml-report/report?lastMonths=10`).get().toPromise();

      for (const report of cuzkReportDates.plain()) {
        if (report.knHlaseniGenerated) {
          // month, when last cuzk report was sent (months from BE starts from 1!)
          return moment().month(report.month).year(report.year).toDate();
        }
      }
  }

  private async isCurrentMonthClosed(): Promise<boolean> {
    const res = await this.restangular.one(`cuzk/status`).get().toPromise();
    return res.currentMonthClosed;
  }
}
