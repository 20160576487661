<fieldset>
  <legend>Náklady na pořízení (výkup) parcely</legend>

  <div class="column-100">
    <label>Smluvní cena parcely</label>
    <div class="pt-7">
      <ng-container *ngIf="parcel.casePrice; else emptyPrice">{{ parcel.casePrice | price }}</ng-container>
      <ng-template #emptyPrice>-</ng-template>
    </div>
  </div>
</fieldset>

<fieldset class="no-legend">
  <div class="column-50">
    <gmt-input label="Zpracování geometrického plánu">
      <input type="text" [(ngModel)]="parcel.parcelDetail.geometricalPlanCosts" gmtInputNumber /> Kč
    </gmt-input>
    <gmt-input label="Vyjmutí ze ZPF / PUPFL">
      <input type="text" [(ngModel)]="parcel.parcelDetail.exclusionCosts" gmtInputNumber /> Kč
    </gmt-input>
    <gmt-input label="Správní poplatek za návrh na vklad do KN">
      <input type="text" [(ngModel)]="parcel.parcelDetail.entryProposalCosts" gmtInputNumber /> Kč
    </gmt-input>
  </div>
  <div class="column-50">
    <gmt-input label="Zpracování znaleckého posudku">
      <input type="text" [(ngModel)]="parcel.parcelDetail.expertOpinionCosts" gmtInputNumber /> Kč
    </gmt-input>
    <gmt-input label="Cena mandatářských činností">
      <input type="text" [(ngModel)]="parcel.parcelDetail.mandataryCosts" gmtInputNumber /> Kč
    </gmt-input>
    <gmt-input label="Náhrada dalších nákladů vlastníka">
      <input type="text" [(ngModel)]="parcel.parcelDetail.otherOwnersCosts" gmtInputNumber /> Kč
    </gmt-input>
  </div>
</fieldset>

