import { Component, OnInit } from '@angular/core';

import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ContractService } from '@app/settings/customers/services/contract.service';
import { CustomerService } from '@app/settings/customers/services/customer.service';

@Component({
  selector: 'project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  loading: boolean = true;
  projects: any[];
  selected: Object = {
    project: null,
  };
  postArgs: Object = {
    filter: {},
  };
  private readonly promapObj: Object = {
    application: 'PRO',
    projectKey: 'promap',
    projectName: 'PROMAP',
    contractCount: 0,
  };

  constructor(
    private config: DialogConfig,
    public dialog: DialogRef,
    private contractService: ContractService,
    private customerService: CustomerService,
  ) {
    this.save = this.save.bind(this);
  }

  async ngOnInit() {
    this.projects = await this.loadProjects();
    this.loading = false;
  }

  async save() {
    return await this.contractService
      .addProject((<DialogConfigData>this.config.data).contractId, this.selected['project']['application'] || 'SY', this.selected['project']['projectKey'])
      .then(() => {
        return this.dialog.close(true);
      });
  }

  isProjectSet() {
    return this.selected['project'] && this.selected['project']['projectKey'];
  }

  private async loadProjects(): Promise<any[]> {
    let projects = await this.customerService.getProjects((<DialogConfigData>this.config.data).customerId, this.postArgs);
    projects.push(this.promapObj);
    projects.forEach(p => p.name = p.projectName);
    projects = projects.filter(p => p.contractCount === 0);

    return projects;
  }
}
