import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

export interface HttpOptions {
  path: string;
  data?: any;
  method: string;
  headers?: any;
  baseUrl?: string;
  fullResponse?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  cancelPromise?: Promise<any>;
  params?: any;
}

export interface GmtHttpError {
  status: number;
  data: {
    code?: number;
    message: string;
    status: number;
  };
}

@Injectable({ providedIn: 'root' })
export class HttpService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  call(options: HttpOptions): Promise<any> {
    options = this.fixOptions(options);
    const request = new HttpRequest(
      options.method,
      options.baseUrl + '/' + options.path,
      options.data,
      {
        headers: new HttpHeaders(options.headers),
        responseType: options.responseType,
        params: options.params
      });

    let sub;
    const promise = new Promise<HttpResponse<any> | any>((resolve, reject) => {
       sub = this.http.request(request).subscribe(
         (response: HttpResponse<any>) => {
           if (response.type === 0) {
             return;
           }
           const data = options.fullResponse ? response : response.body;
           resolve(data);
         },
         (error: HttpErrorResponse) => {
           const gmtError: GmtHttpError = { status: error.status, data: error.error };
           const authCheck = this.authService.restangularErrorInterceptor(gmtError);

           if (authCheck !== false) {
             reject(gmtError);
           }
         }
       );
    });

    if (options.cancelPromise) {
      options.cancelPromise.then(() => {
        sub.unsubscribe();
      });
    }

    return promise;
  }

  private fixOptions(options: HttpOptions): HttpOptions {
    if (!options.baseUrl) {
      options.baseUrl = this.authService.getActiveApplicationRestUrl();
    }

    if (!options.headers) {
      options.headers = {};
    }
    options.headers.Authorization = this.authService.getToken();

    if (!options.fullResponse === undefined) {
      options.fullResponse = false;
    }

    if (!options.responseType) {
      options.responseType = 'json';
    }

    if (options.data) {
      options.data = _.cloneDeep(options.data);
      HttpService.fixData(options.data);
    }

    if (options.params) {
      options.params = HttpService.objectToHttpParams(options.params);
    }

    return options;
  }

  public static fixData(data: any) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key] === undefined) {
          delete data[key];
        }
      });
    }
  }

  public static parseFilename1(response: HttpResponse<any>): string {
    // attachment;filename*=UTF-8''22-MPS10055-19%20%281%29.docx
    const header = response.headers.get('Content-Disposition');
    let filename = '';
    if (header) {
      const matches = new RegExp("filename\\*?=(?:[^']*'')?([^;]*)").exec(header);
      if (matches.length) {
        filename = matches[1];
      }
    }
    return filename;
  }

  public static parseFilename2(response: HttpResponse<any>): string {
    // attachment; filename="gmtech_09_2019_hlaseni.zip"
    const header = response.headers.get('Content-Disposition');
    const filename = header
      .split('=') // ["attachment; filename", ""gmtech_09_2019_hlaseni.zip""]
      .reverse()[0] // "gmtech_09_2019_hlaseni.zip"
      .replace(/"/g, ''); // gmtech_09_2019_hlaseni.zip

    return filename;
  }

  public static objectToHttpParams(obj: any): HttpParams {
    let params = new HttpParams({encoder: new HttpUrlEncodingCodec()});

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        params = params.set(key, obj[key]);
      }
    }

    return params;
  }
}

export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return k; }
  encodeValue(v: string): string { return typeof v === 'string' ? v.replace(/\+/g, '%2B') : v; }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}

