<div class="mb-20 bordered">
  <table>
    <thead class="sticky">
      <tr>
        <th *ngIf="visibleColumns.parcel">
          <sort-by
            *ngIf="sortableColumns.parcel"
            [selected]="'parcel' === sortOrder.sortBy"
            (sort)="onSortParcel('parcel')"
            [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.parcel">
            Parcela
          </p>
        </th>
        <th *ngIf="visibleColumns.parcel_title">
          <sort-by
            *ngIf="sortableColumns.parcel_title"
            [selected]="'parcel_title' === sortOrder.sortBy"
            (sort)="onSortParcel('parcel_title')"
            [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.parcel_title">
            Parcela
          </p>
        </th>
        <th *ngIf="visibleColumns.title_name">
          <sort-by
            *ngIf="sortableColumns.title_name"
            [selected]="'title_name' === sortOrder.sortBy"
            (sort)="onSort('title_name', 'parcel.title.lv')"
            [sortDir]="sortOrder.direction">
            LV
          </sort-by>
          <p *ngIf="!sortableColumns.title_name">
            LV
          </p>
        </th>
        <th *ngIf="visibleColumns.area_name">
          <sort-by
            *ngIf="sortableColumns.area_name"
            [selected]="'area_name' === sortOrder.sortBy"
            (sort)="onSort('area_name', 'parcel.katuzeNazev')"
            [sortDir]="sortOrder.direction">
            K.Ú.
          </sort-by>
          <p *ngIf="!sortableColumns.area_name">
            K.Ú.
          </p>
        </th>
        <th *ngIf="visibleColumns.geometric_plan">Geometrický plán</th>
        <th *ngIf="visibleColumns.construction_object">{{"CONSTRUCTION_OBJECT" | wordtranslate:true}}</th>
        <th *ngIf="easementType && visibleColumns.title">Označení VB</th>
        <th *ngIf="visibleColumns.area">Plocha VB [<span class="unit">m<sup>2</sup></span>]</th>
        <th *ngIf="visibleColumns.length">Délka VB [<span class="unit">m</span>]</th>
        <th *ngIf="visibleColumns.vb_tz">Poloha VB</th>
        <th *ngIf="visibleColumns.subject_price">Cena VB</th>
        <th *ngIf="visibleColumns.title_price">Cena VB</th>
        <th *ngIf="visibleColumns.case_price">Cena VB</th>
        <th *ngIf="visibleColumns.parcel_price">Cena VB</th>
        <th *ngIf="visibleColumns.case">
          <sort-by
            *ngIf="sortableColumns.case"
            [selected]="'case' === sortOrder.sortBy"
            (sort)="onSort('case', 'countCases')"
            [sortDir]="sortOrder.direction">
            Případy
          </sort-by>
          <p *ngIf="!sortableColumns.case">
            Případy
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let easement of data">
        <td *ngIf="visibleColumns.parcel" class="no-padding cell-max-height">
          <cell-parcel
            [parcel]="easement.parcel"
            [tooltipType]="'parcel'"
            [tooltipData]="{parcel: easement.parcel}"
            [showProtection]="true"
          ></cell-parcel>
        </td>
        <td *ngIf="visibleColumns.parcel_title" class="no-padding cell-max-height">
          <cell-parcel
            [parcel]="easement.parcel"
            [tooltipType]="'parcel'"
            [tooltipData]="{parcel: easement.parcel, withoutTitle: true}"
            [showProtection]="true"
          ></cell-parcel>
        </td>
        <td *ngIf="visibleColumns.title_name" class="number">
          <a *ngIf="easement.parcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: easement.parcel.title.id, tab: null}">
            <b>{{easement.parcel.title.lv}}</b>
          </a>
        </td>
        <td *ngIf="visibleColumns.area_name">
          {{easement.parcel.katuzeNazev}}
        </td>
        <td class="no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content flexbox-container" [ngClass]="{'tooltip-hover-area' : easement.endDate}">
            <div class="content-wrap">
              <div *ngIf="easement.endDate" class="mr-10"><span class="error-message"><i class="fa fa-exclamation-triangle"></i></span></div>
              <div [ngClass]="{cancelled: easement.endDate}">{{easement.geometricPlan.cis}}</div>
              <div class="flex1" *ngIf="showUpdate && easement.endDate">
                <a class="fr button" gmtAuth="admin" uiSref="symap.project.cases.detail.updateEasement" [uiParams]="{easementId: easement.id}">
                  <i class="fa fa-pencil"></i>
                </a>
              </div>
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.construction_object" class="no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content flexbox-container" [ngClass]="{'tooltip-hover-area': easement.endDate}">
            <div class="content-wrap" [ngClass]="{cancelled: easement.endDate}">
              <gmt-construction-object-list [showAdministrator]="true" [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
            </div>
          </div>
        </td>
        <td *ngIf="easementType && visibleColumns.title" class="no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content flexbox-container" [ngClass]="{'tooltip-hover-area': easement.endDate}">
            <div class="content-wrap" [ngClass]="{cancelled: easement.endDate}">
              {{easement.oznacVb}}
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.area" class="no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content flexbox-container" [ngClass]="{'tooltip-hover-area': easement.endDate}">
            <div class="content-wrap" [ngClass]="{cancelled: easement.endDate}">
              <div class="number">{{easement.vymera}}</div>
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.length" class="number no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content flexbox-container" [ngClass]="{'tooltip-hover-area': easement.endDate}">
            <div class="content-wrap" [ngClass]="{cancelled: easement.endDate}">
              <div class="number">{{easement.delka}}</div>
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.vb_tz" class="text-center">
          {{ easement | easementOccupation }}
        </td>
        <!-- This column is used only in subject detail -->
        <td *ngIf="visibleColumns.subject_price" [ngClass]="{disabled: easement.endDate}" class="no-padding cell-max-height">
          <cell-price
            [price]="easement.easementPrice"
            [editable]="false"
            [tooltipType]="'easement-price'"
            [tooltipData]="{easement: easement}"
          ></cell-price>
        </td>
        <!-- This column is used only in titles -->
        <td *ngIf="visibleColumns.title_price" [ngClass]="{disabled: easement.endDate}" class="no-padding cell-max-height">
          <cell-price
            [editFunction]="onPriceEdit"
            [editFunctionParams]="easement"
            [price]="easement.easementPrice"
            [editable]="isEditablePrice(easement)"
            [tooltipType]="'easement-price'"
            [tooltipData]="{easement: easement}"
          ></cell-price>
        </td>
        <!-- This column is used only in cases -->
        <td *ngIf="visibleColumns.case_price" class="no-padding cell-max-height">
          <cell-price
            *ngIf="easement.caseEasement"
            [price]="easement.caseEasement.easementPrice"
            [editable]="canEdit('update_case_price')"
            [editFunction]="priceEditFunction"
            [editFunctionParams]="{parcelId: easement.caseParcel.parcel.id, easementId: easement.id}"
            [casePrice]="easement.caseEasement"
            [tooltipType]="'easement-price'"
            [tooltipData]="{easement: easement}"
          ></cell-price>
        </td>
        <!-- This column is used only in parcel and title -->
        <td *ngIf="visibleColumns.parcel_price" class="no-padding cell-max-height">
          <cell-price
            [editFunction]="onPriceEdit"
            [editFunctionParams]="easement"
            [price]="easement.easementPrice"
            [editable]="isEditablePrice(easement)"
            [tooltipType]="'easement-price'"
            [tooltipData]="{ easement: easement }"
          ></cell-price>
        </td>
        <td *ngIf="visibleColumns.case" class="no-padding cell-max-height">
          <cell-cases
            [casesCount]="easement.countCases"
            [tooltipType]="'cases'"
            [tooltipData]="{filter: {filters: {easementId: easement.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
          ></cell-cases>
        </td>
      </tr>
    </tbody>
  </table>
</div>
