<div class="tasks">
  <fieldset class="mt-40 bordered">
    <legend>
      Nastavení
    </legend>
    <div class="home-actions">
      <div class="column-25">
        <a uiSref="dimap.project.settings.changePassword">
          <i class="fa fa-key"></i>
          Změna přihlašovacích údajů
        </a>
      </div>
      <div class="column-25" gmtAuth="admin">
        <a uiSref="dimap.project.settings.users">
          <i class="fa fa-users"></i>
          Správa uživatelů
        </a>
      </div>
      <div class="column-25" gmtAuth="admin">
        <a uiSref="dimap.project.settings.editProject">
          <i class="fa fa-pencil"></i>
          Editace projektu
        </a>
      </div>
      <div class="column-25" gmtAuth="admin">
        <a uiSref="dimap.project.settings.createProject">
          <i class="fa fa-plus"></i>
          Nový projekt
        </a>
      </div>
    </div>
  </fieldset>
  <help [helpId]="helpIds.VM_PANEL_SETTINGS" class="cm-help-page"></help>
</div>
