import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'cadastre-entry-proposal',
  templateUrl: './cadastre-entry-proposal.component.html',
  styleUrls: [],
})
export class CadastreEntryProposalComponent implements OnInit {
  @Input() caseId: number;
  public loading = true;

  public data: any;
  public sendProposal: Function;
  public deleteProposal: Function;
  public hasValidProposalCredentials: Function;
  public loadingFinished: boolean = false;

  constructor(
    private restangular: Restangular,
  ) {}

  ngOnInit() {
    this.restangular.one('cases', this.caseId).get().toPromise().then((data) => {
      this.data = data;
      this.loading = false;
    });
  }

  onLoad() {
      this.loadingFinished = true;
  }

  formCallbackRegister($event) {
    this.sendProposal = $event.submit;
    this.deleteProposal = $event.delete;
    this.hasValidProposalCredentials = $event.isValid;
  }
}
