import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';
import { CaseStatusModel } from '@app/ps/models/case-status.model';
import { SubjectModel } from '@app/common/models/subject.model';

@Component({
  selector: 'cell-case-subjects',
  templateUrl: './cell-case-subjects.component.html',
  styleUrls: ['./cell-case-subjects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellCaseSubjectsComponent implements OnInit {
  @Input() caseSubjects: CaseSubjectModel[];
  @Input() caseStatus: CaseStatusModel;
  @Input() lastCaseChangeDate: string;

  subjectsToShow: SubjectModel[] = [];
  progress = 0;

  private static readonly STATUS_FIELD_MAP = {
    SentOwner: 'sentDate',
    ReceivedOwner: 'receivedDate',
    DeclinedOwner: 'declinedDate',
    PersonalVisit: 'personalVisitDate',
    SignedOwner: 'signedDate',
    DunningLetter: 'dunningLetterDate',
    CallBeforeExpropriation: 'callBeforeExpropriationDate',
    CallBeforeExpropriationReceived: 'callBeforeExpropriationReceivedDate',
    ExpropriationCaseInProgress: 'expropriationCaseInProgressDate',
    ExpropriationCaseStopped: 'expropriationCaseStoppedDate',
    ExpropriationCaseFinished: 'expropriationCaseFinishedDate',
    DisapprovedOwner: 'disapprovedOwnerDate',
    ApprovedOwner: 'approvedOwnerDate',
    ExchangeRequest: 'exchangeRequestDate',
    OtherSolutionRequest: 'otherSolutionRequestDate',
    WithoutResolution: 'withoutResolutionDate',
  };

  ngOnInit(): void {
    if (!(
      this.caseSubjects
      && this.caseStatus
      && this.lastCaseChangeDate
      && Object.keys(CellCaseSubjectsComponent.STATUS_FIELD_MAP).includes(this.caseStatus.key)
    )) {
      return;
    }

    for (const caseSubject of this.caseSubjects) {
      const subjectDate = caseSubject[CellCaseSubjectsComponent.STATUS_FIELD_MAP[this.caseStatus.key]];
      const signed = !!caseSubject[CellCaseSubjectsComponent.STATUS_FIELD_MAP.SignedOwner];

      if (this.lastCaseChangeDate === subjectDate) {
        this.subjectsToShow.push(caseSubject.subject);
      }

      if (this.lastCaseChangeDate >= subjectDate || signed) {
        this.progress++;
      }
    }
  }
}
