import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';

export class FileUtils {

  public static hasFilesSameTitles(files: any[]) {
    if (files.length === 0) {
      return true;
    }
    return files.every(f => f.title === files[0].title);
  }

  public static fileToUpload(file: File, validator: Function): UploadFileExtended {
    const returnFile: UploadFileExtended = new UploadFileExtended(file);
    returnFile.extension = FileUtils.getFileExtension(file);
    returnFile.title = FileUtils.getFileTitle(file);
    returnFile.status = '';
    returnFile.valid = validator(returnFile);
    return returnFile;
  }

  public static getFileExtension(file) {
    const lastDotIndex = FileUtils.getLastDotIndex(file);
    let extension = null;

    if (lastDotIndex !== -1) {
      extension = file.name.substr(lastDotIndex + 1);
    }

    return extension;
  }

  public static getFileTitle(file: File): string {
    const lastDotIndex = FileUtils.getLastDotIndex(file);
    let title = file.name;

    if (lastDotIndex !== -1) {
      title = file.name.substr(0, lastDotIndex);
    }

    return title;
  }

  public static jsonBlob(value: string | object): Blob {
    if (value != null && typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    return new Blob([value as string], { type: 'application/json' });
  }

  public static objectToJsonMultipartFormData(obj, formData = new FormData()) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && !(value instanceof File) && !Array.isArray(value)) {
          formData.append(key, FileUtils.jsonBlob(value));
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === 'object' && !(item instanceof File)) {
              formData.append(key, FileUtils.jsonBlob(item));
            } else {
              formData.append(key, item);
            }
          });
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

  public static objectToFormData(obj, formData = new FormData(), parentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = parentKey ? `${parentKey}[${key}]` : key;
        const value = obj[key];

        if (typeof value === 'object' && !(value instanceof File) && !Array.isArray(value)) {
          FileUtils.objectToFormData(value, formData, propName);
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            const arrayKey = `${propName}[${index}]`;

            if (typeof item === 'object' && !(item instanceof File)) {
              FileUtils.objectToFormData(item, formData, arrayKey);
            } else {
              formData.append(arrayKey, item);
            }
          });
        } else {
          formData.append(propName, value);
        }
      }
    }

    return formData;
  }

  private static getLastDotIndex(file: File): number {
    return file.name.lastIndexOf('.');
  }
}
