import { InjectionToken } from '@angular/core';
import { Restangular } from 'ngx-restangular';

export const RESTANGULAR_PROMAP = new InjectionToken<any>('RestangularPromap');

export function RestangularPromapFactory(restangular: Restangular, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlPK);
  });
  return r;
}

