<div [ngClass]="{collapsed: collapsed}" style="width:100%">
  <div class="sections">
    <ul>
      <li *ngFor="let item of menuConfig" [gmtAuth]="item.permissions" [ngClass]="{collapsed: collapsed, active: isActive(item.uiSref)}" class="menu-item tooltip-min" [tooltip]="item.tooltip ? item.tooltip : item.title" position="center" [data]="{visible: collapsed}">
        <a *ngIf="!item.children && item.uiSref" [uiSref]="item.uiSref" [uiParams]="item.uiParams" [attr.target]="item.target ? item.target : '_self'">
          <i class="fa" [ngClass]="[item.icon]"></i>
          <span *ngIf="!collapsed" class="section-label" >
						{{item.title}}
					</span>
        </a>
        <a *ngIf="!item.children && item.href" [href]="item.href" [attr.target]="item.target ? item.target : '_self'">
          <i class="fa" [ngClass]="[item.icon]" ></i>
          <span *ngIf="!collapsed" class="section-label" >
						{{item.title}}
					</span>
        </a>
      </li>
    </ul>
  </div>
  <div id="bottom-bar">
    <div class="sections" *ngIf="applications.length > 1 && (APP_BRAND.NAME !== 'RSD' || user?.superuser)">
      <ul>
        <li class="menu-item" id="app-switcher" [ngClass]="{collapsed: collapsed}" (mouseleave)="onApplicationSwitcherMouseLeave($event)" (mouseenter)="onApplicationSwitcherMouseEnter($event)" (click)="showApplications($event)">
          <a>
            <i class="fa fa-th-large"></i>
            <div *ngIf="!collapsed && APP_BRAND.NAME !== 'RSD'" class="section-label">
              Aplikace
            </div>
          </a>
          <ng-template applications-host></ng-template>
        </li>
      </ul>
    </div>
    <div *ngIf="!collapsed" class="copy" [tooltip]="!metadata || (APP_BRAND.NAME === 'RSD' && !metadata.cadastre) ? undefined : tooltipSystemMetadataComponent" [data]="metadata">
      <div class="text-center mb-10">verze: {{metadata?.frontendVersion}}</div>
      <div *ngIf="APP_BRAND.NAME !== 'RSD'" class="text-center">{{copyrightDate| date:'yyyy'}} &copy; GMtech, s.r.o.</div>
    </div>
  </div>
</div>
