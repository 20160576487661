import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { EasementModel } from '../../models/easement.model';

export interface TooltipEasementPriceComponentData {
  easement: EasementModel;
}

@Component({
  selector: 'tooltip-easement-price',
  templateUrl: './tooltip-easement-price.component.html',
  styleUrls: ['./tooltip-easement-price.component.scss'],
})
export class TooltipEasementPriceComponent implements OnInit {

  // bindings
  @Input() data: TooltipEasementPriceComponentData;

  // model
  easementGlobalPriceType: string;

  ngOnInit() {
    const easementGlobalPriceType = new Set(this.data.easement.constructionObjects.map(co => co.easementGlobalPriceType)).size === 1 ?
      this.data.easement.constructionObjects[0].easementGlobalPriceType : null;

    this.easementGlobalPriceType = easementGlobalPriceType === 'I'
    || (
      easementGlobalPriceType === 'B'
      && this.data.easement.priceType
    ) ? 'I' : easementGlobalPriceType;
  }
}
