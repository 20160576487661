<div class="list">
  <div class="actions">
    <a class="button" [ngClass]="{'disabled': showRunningCadastreImportNotification}" uiSref="{{showRunningCadastreImportNotification ? false : 'pk.projects.create-project-first'}}">Založit novou žádost</a>
  </div>
  <h1>Žádosti</h1>

  <filter (onChanged)="pageableList.onFilterChanged($event)" [list]="list">
    <div class="column-100">
      <input class="fulltext" type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id" />
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="bordered" *ngIf="list.itemCount">
      <table class="table">
        <thead>
          <tr>
            <th class="cell">
              <sort-by [selected]="'name' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('name', $event)" [sortDir]="list.filter.sortOrder.direction">
                Název žádosti
              </sort-by>
            </th>
            <th class="cell">
              <sort-by [selected]="'recivedTime' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('recivedTime', $event)" [sortDir]="list.filter.sortOrder.direction">
                Datum založení
              </sort-by>
            </th>
            <th class="cell">ID žádosti</th>
            <th class="cell">
              Pravidelná měsíční<br>aktualizace dat
            </th>
            <th class="cell">
              Počet stažení
            </th>
            <th class="cell">
              Uživatel
            </th>
            <th class="cell">
              Dostupnost dat
            </th>
            <th class="cell">
              Předpokládaná cena [<span class="unit">Kč</span>]
            </th>
            <th class="cell" style="min-width: 80px;">
              Akce
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="clickable"
              gmtDraggable
              (mousedone)="goToProjectDetail($event, project)"
              *ngFor="let project of list.list"
              >
              <td class="cell">
                {{ project.name }}
              </td>
            <td class="cell">
              {{ project.recivedTime | date }}
            </td>
            <td class="cell">
              {{ project.requestId }}
            </td>
            <td class="cell">
              {{ project.monthlyUpdate === true ? "Ano" : "Ne" }}
            </td>
            <td class="cell">
              {{ project.downloadCount }}
            </td>
            <td class="cell">
              {{ project.user | username:false }} ({{ (project.prijemce.company || project.company) | customerName }})
            </td>

            <td class="cell">
              <span *ngIf="project.status === STATUSES.FINISHED; else notFinished">
                <i class="fa fa-check-circle"></i> k {{ project.version | date }}
              </span>
              <ng-template #notFinished>
                <span class="inline-block"><i class="fa fa-times-circle-o"></i> </span>
                <span class="inline-block">
                  {{ project.status | statusMessage }} <br> k {{ project.version | date }}
                </span>
              </ng-template>
              <div *ngIf="user.superuser" class="pt-5">
                ({{ project.status }})
              </div>
            </td>
            <td class="cell">
              {{(project.priceCalculation.price !== -1 ? project.priceCalculation.price : "") | currency:",-":"symbol":"1.0"}}
              <span *ngIf="project.status === STATUSES.WAITING_FOR_PRICE_CONFIRMATION">
                <i class="fa fa-money c-blue"></i>
              </span>
            </td>
            <td class="cell text-center" style="min-width: 80px;">

              <span *ngIf="project.status === STATUSES.FINISHED">
                <a uiSref="pk.projects.create-project-first"
                   [uiParams]="{ requestId: project.requestId }"
                   (mousedown)="$event.stopPropagation()"
                   (mouseup)="$event.stopPropagation()"
                   (mousemove)="$event.stopPropagation()"
                   title="Opakování žádosti"
                   class="p-5"
                >
                  <i class="fa fa-repeat c-blue"></i>
                </a>
                &nbsp;&nbsp;
                <i class="fa fa-download c-blue p-5" title="Stažení dat"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nenalezena žádná data</span>
    </div>
  </div>

  <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
  <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  <help [helpId]="helpIds.PK_PROJECTS" class="cm-help-page"></help>
</div>
