import {Component, Inject, OnInit} from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { NoteModel } from '@app/common/models/note.model';

@Component({
  selector: 'simple-text-modal',
  templateUrl: './simple-text-modal.component.html',
})
export class SimpleTextModalComponent implements OnInit {

  // model
  title: string;
  label: string;
  text: string;
  additionalText: string;
  required: boolean;

  constructor(
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef
  ) {
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.title = (<DialogConfigData>this.dialogConfig.data).title;
    this.label = (<DialogConfigData>this.dialogConfig.data).label;
    this.required = (<DialogConfigData>this.dialogConfig.data).required;
    this.text = (<DialogConfigData>this.dialogConfig.data).text || '';
    this.additionalText = (<DialogConfigData>this.dialogConfig.data).additionalText || '';
  }

  isValid() {
    return !!this.text || !this.required;
  }

  onUpdate() {
    this.dialogComponent.close(this.text);
  }

  onCancel() {
    this.dialogComponent.close(false);
  }
}
