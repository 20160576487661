import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { SampleModel, SampleProjectModel } from '@app/sv/models/sample.model';
import { SampleStatusEnum } from '@app/sv/enums/sample-status.enum';
import { TabModel } from '@app/common/models/tab.model';
import { SampleService } from '@app/sv/services/sample.service';
import { AuthService } from '@app/common/services/auth.service';
import { StateService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { DocumentModel } from '@app/common/models/document.model';
import { NoteModel } from '@app/common/models/note.model';
import { ProjectModel } from '@app/ps/models/project.model';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { SimpleTextModalComponent } from '@app/common/components/simple-text-modal/simple-text-modal.component';
import { TicketStatusEnum } from '@app/sv/enums/ticket-status.enum';
import { TicketModel } from '@app/sv/models/ticket.model';
import { HelpService } from '@app/common/services/help.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';
import { NoteTypeEnum, noteTypeOptions} from '@app/sv/enums/note-type.enum';

@Component({
  selector: 'sample-detail',
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.scss']
})
export class SampleDetailComponent implements OnInit {

  static readonly MSG_TEMPLATE_FILES_DEFAULT = 'Vložte opravu šablony. Je možné vložit jeden soubor docx.';
  static readonly MSG_TEMPLATE_FILES_EXTENSION = 'Soubor musí být ve formátu DOCX.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  @Input() sampleId: number;
  loading = true;
  sample: SampleModel;
  tab: string;
  sampleStatusEnum = SampleStatusEnum;
  documentList: ListModel<DocumentModel>;
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  templateFiles: UploadFileExtended[] = [];
  templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_DEFAULT;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();
  currentProjects: SampleProjectModel[];
  projects: ProjectModel[] = [];

  tabs: TabModel[] = [
    {
      name: 'Poznámky',
      id: 'notes',
      href: `sv.samples.detail`,
      urlParams: { tab: 'notes' },
    },
    {
      name: 'Akce',
      id: 'projects',
      href: `sv.samples.detail`,
      urlParams: { tab: 'projects' },
    },
    {
      name: 'Historie',
      id: 'history',
      href: `sv.samples.detail`,
      urlParams: { tab: 'history' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: `sv.samples.detail`,
      urlParams: { tab: 'documents' },
    },
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    private restangular: Restangular,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    private errorHandlerService: ErrorHandlerService,
    public sampleService: SampleService,
    private listService: ListService,
    private authService: AuthService,
    private stateService: StateService,
    private dialogService: DialogService
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'notes');
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onUpdateProjects = this.onUpdateProjects.bind(this);
    this.onDocumentsSort = this.onDocumentsSort.bind(this);
    this.toggleProjectActive = this.toggleProjectActive.bind(this);
    this.toggleProjectDisabled = this.toggleProjectDisabled.bind(this);
    this.noteTypeResolver = this.noteTypeResolver.bind(this);
  }

  async ngOnInit() {
    await this.loadSample();
    await this.loadProjects();
    this.loadDocuments();
    this.loadNotes();
    this.loadHistory();
    this.loading = false;
  }

  isProjectActive(project: ProjectModel, acceptWaitingStatus = true): boolean {
    return this.sample.status === 'ACTIVE' || (acceptWaitingStatus && this.sample.status === 'WAITING_FOR_ACTUALIZATION') || this.sample.projects.some(sampleProject => sampleProject.projectKey === project.key && sampleProject.exception);
  }

  isProjectEnabled(project: ProjectModel): boolean {
    return (this.sample.status === 'ACTIVE' && !this.sample.projects.some(sampleProject => sampleProject.projectKey === project.key && sampleProject.disabled)) ||
      (this.sample.projects.some(sampleProject => sampleProject.projectKey === project.key && sampleProject.exception && !sampleProject.disabled));
  }

  toggleProjectActive(project: ProjectModel) {
    if (this.sample.status === 'ACTIVE' || this.sample.status === 'WAITING_FOR_ACTUALIZATION') {
      return;
    }
    let sampleProject = this.sample.projects.find(sampleProject => sampleProject.projectKey === project.key);
    if (!sampleProject) {
      sampleProject = {projectKey: project.key};
      this.sample.projects.push(sampleProject);
    }
    sampleProject.exception = !sampleProject.exception;
    sampleProject.disabled = false;
  }

  toggleProjectDisabled(project: ProjectModel) {
    if (!this.isProjectActive(project, false)) {
      return;
    }
    let sampleProject = this.sample.projects.find(sampleProject => sampleProject.projectKey === project.key);
    if (!sampleProject) {
      sampleProject = {projectKey: project.key, exception: false};
      this.sample.projects.push(sampleProject);
    }
    sampleProject.disabled = !sampleProject.disabled;
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  canEdit(): boolean {
    return true;
  }

  canBeActualized(): boolean {
    const allowedSampleStatuses = [SampleStatusEnum.ACTIVE, SampleStatusEnum.WAITING_FOR_ACTUALIZATION];

    if (this.APP_BRAND.NAME === 'SZ') {
      allowedSampleStatuses.push(SampleStatusEnum.EXCEPTION);
    }

    return (allowedSampleStatuses.includes(this.sample.status))
      && this.sample.actualizationTickets.every(t => t.status === TicketStatusEnum.DELETED || t.status === TicketStatusEnum.DISAPPROVED);
  }

  canUserManage() {
    return this.authService.hasPermissionOnProject('template_manage', this.sample.organizationalUnitCode);
  }

  canEditNote() {
    return this.authService.hasPermissionOnProject('template_view', this.sample.organizationalUnitCode);
  }

  canViewTicket() {
    return this.authService.hasPermissionOnProject('template_aproval,template_manage') ||
      this.authService.hasPermissionOnProject('template_new', this.sample.organizationalUnitCode);
  }

  getActualizationTicket(): TicketModel {
    if (!this.sample.actualizationTickets) {
      return undefined;
    }
    return this.sample.actualizationTickets.find(t => t.status !== TicketStatusEnum.DELETED && t.status !== TicketStatusEnum.DISAPPROVED);
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onDocumentsSort(sortValue: any, sortDir: any) {
    this.listService.sort(this.documentList, sortValue, sortDir);
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onSampleInactivate() {
    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: 'Zneplatnění vzoru',
        label: 'Důvod',
        required: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        text = 'Vzor zneplatněn. Důvod: ' + text;
        const note = { sampleId: { id: this.sampleId }, systemType: true, text: text };
        this.sample.status = SampleStatusEnum.INACTIVE;
        this.loading = true;
        this.sample = await this.sampleService.update(this.sample, note);
        await this.loadHistory();
        this.loading = false;
      }
      sub.unsubscribe();
    });
  }

  onSampleToWaitingForActualization() {
    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: 'Vynutit aktualizaci vzoru',
        label: 'Důvod',
        required: true,
        additionalText: 'Vzor bude přesunut do stavu Čeká na aktualizaci, nebude možné generování podle vzoru, bude možné zadat požadavek na aktualizaci.'
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        text = 'Vynucena aktualizace vzoru. Důvod: ' + text;
        const note: any = { sampleId: { id: this.sampleId }, systemType: true, text: text, noteType: NoteTypeEnum.SAMPLE_WAITING_FOR_ACTUALIZATION };
        this.sample.status = SampleStatusEnum.WAITING_FOR_ACTUALIZATION;
        this.loading = true;
        await this.sampleService.update(this.sample, note);
        await this.loadHistory();
        this.loading = false;
      }
      sub.unsubscribe();
    });
  }

  onUpdate(): Promise<any> {
    return this.sampleService.update(this.sample)
      .then(sample => {
        this.sample = sample;
      });
  }

  async onUpdateProjects() {
    const changes = this.getProjectChanges();

    // if no changes in projects (only in enabling sample in project)
    if (!changes.in.length && !changes.out.length) {
      this.loading = true;
      await this.sampleService.updateProjects(this.sample);
      await this.loadSample();
      this.loading = false;
      return;
    }

    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: 'Změna nastavení akcí vzoru',
        label: 'Důvod',
        required: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        // create msg
        let actionText = '';
        if (changes.in.length) {
          const projects = changes.in.map(sp => this.projects.find(p => p.key === sp.projectKey).name).join(', ');
          actionText += 'Vzor aktivován pro ' + (changes.in.length === 1 ? 'akci: ' : 'akce:') + projects + '. ';
        }
        if (changes.out.length) {
          const projects = changes.out.map(sp => this.projects.find(p => p.key === sp.projectKey).name).join(', ');
          actionText += 'Vzor deaktivován pro akce ' + (changes.out.length === 1 ? 'akci: ' : 'akce:') + projects + '. ';
        }
        text = actionText + 'Důvod: ' + text;
        const note = { sampleId: { id: this.sampleId }, systemType: true, text: text };

        // store changes
        this.loading = true;
        await this.sampleService.updateProjects(this.sample, note);
        await this.loadSample();
        await this.loadHistory();
        this.loading = false;
      }
      sub.unsubscribe();
    });
  }

  onTemplateFilesChange(files: UploadFileExtended[]) {
    this.templateFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'docx');

    if (this.templateFiles.length === 0) {
      this.templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_EXTENSION;
    } else if (this.templateFiles.length > 1) {
      this.templateFilesMsg = SampleDetailComponent.MSG_FILES_QUANTITY;
    } else {
      this.templateFilesMsg = '';
    }

    const valid = (extensionValid && this.templateFiles.length === 1);

    if (!valid) {
      return;
    }

    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: 'Technická oprava šablony',
        label: 'Důvod opravy šablony',
        required: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        const noteText = 'Oprava šablony vzoru. Důvod: ' + text;
        const note = { sampleId: { id: this.sampleId }, systemType: true, text: noteText };
        this.loading = true;
        try {
          await this.sampleService.updateTemplate(this.sample, this.templateFiles[0], note, text);
        } catch (e) {
          this.errorHandlerService.get(e);
        }
        await this.loadDocuments();
        await this.loadHistory();
        this.loading = false;
        this.templateFiles = [];
      }
      sub.unsubscribe();
    });
  }

  noteTypeResolver(noteType: any) {
    const found = noteTypeOptions.find(nto => nto.id === noteType);
    return found ? found.name : '';
  }

  private loadDocuments() {
    const types = ['SAMPLE'];

    if (this.authService.hasPermissionOnProject('template_aproval,template_manage')) {
      types.push('TEMPLATE');
      types.push('INACTIVE_TEMPLATE');
    }

    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { sampleId: this.sample.id, attachmentType: { values: types }, cancelled: false},
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { sampleId: this.sampleId, systemType: true, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private async loadProjects() {
    const projectsByInvestorOrganizationUnit = this.listService.createList(
      'projects/list',
      {
        filters: { investorOrganizationalUnitId: this.sample.organizationalUnitId },
        sortOrder: { sortBy: 'name', direction: 'asc' },
        limit: null
      },
      this.restangularSymap
    );
    await this.listService.fetchResult(projectsByInvestorOrganizationUnit);

    const projectsByTemplatesOrganizationUnit = this.listService.createList(
      'projects/list',
      {
        filters: { templatesOrganizationalUnitId: this.sample.organizationalUnitId },
        sortOrder: { sortBy: 'name', direction: 'asc' },
        limit: null
      },
      this.restangularSymap
    );
    await this.listService.fetchResult(projectsByTemplatesOrganizationUnit);

    this.projects = _.uniqBy(
      _.concat(
        projectsByInvestorOrganizationUnit.list.filter(p => !p.templatesOrganizationalUnitId || p.templatesOrganizationalUnitId === this.sample.organizationalUnitId),
        projectsByTemplatesOrganizationUnit.list
      ),
      p => p.id
    );
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { sampleId: this.sampleId, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadSample() {
    return this.sampleService.getByIdWithAttributes(this.sampleId, {
      madeByTicket: {},
      actualizationTickets: {},
      parentSample: {},
      childSample: {},
      projects: {},
    }).then(sample => {
      this.sample = sample;
      this.currentProjects = [];
      this.sample.projects.forEach(p => this.currentProjects.push({...p}));
      if (this.sample.assignable === false) {
        this.tabs = this.tabs.filter(t => t.id !== 'projects');
      }
    });
  }

  private getProjectChanges(): { in: SampleProjectModel[], out: SampleProjectModel[] } {
    const res = { in: [], out: [] };

    this.sample.projects.forEach(sp => {
      const csp = this.currentProjects.find(csp => csp.projectKey === sp.projectKey);
      if (sp.exception === true && (!csp || csp.exception === false)) {
        res.in.push(sp);
      }

      if (sp.exception === false && csp && csp.exception === true) {
        res.out.push(sp);
      }
    });

    return res;
  }
}
