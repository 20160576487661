const getLayerIDByTypeAndWanted = (ownerType, wanted) => {
  switch(ownerType) {
    case 'O':
      return wanted ? 'property_info_owned_inside' : wanted === false ? 'property_info_owned_outside' : 'property_info_others_outside';
    case 'F':
      return wanted ? 'property_info_others_inside' : wanted === false ? 'property_info_owned_inside' : 'property_info_others_outside';
    case 'S':
      return wanted ? 'property_info_others_inside' : wanted === false ? 'property_info_owned_outside' : 'property_info_others_outside';
  }
;}

export default (newValue, oldValue, ownerType) =>
  [getLayerIDByTypeAndWanted(ownerType, newValue), getLayerIDByTypeAndWanted(ownerType, oldValue)];
