<div *ngIf="checkFilterStatistics()" class="tag active" (click)="updateEntityFilterKey([])">
  <div>
    <span [loading]="!filterNames" class="small-spinner">
      <ng-container *ngIf="filterNames?.length === 1">
        <span *ngFor="let filterName of filterNames">
          Statistika: {{ filterName.name }};
          Kategorie: {{ filterName.categoryName }}
          <ng-container *ngIf="filterName.areaName">; KÚ: {{ filterName.areaName }}</ng-container>
          <ng-container *ngIf="filterName.constructionObjectName">; {{ 'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate:true}}: {{ filterName.constructionObjectName }}</ng-container>
          <ng-container *ngIf="filterName.constructionObjectStageName">; {{ 'ETAPA_USEK'|wordtranslate }}: {{ filterName.constructionObjectStageName }}</ng-container>
        </span>
      </ng-container>

      <ng-container *ngIf="filterNames?.length > 1">
        <ng-template #statsTooltip>
          <div *ngFor="let filterName of filterNames; index as i">
            <br *ngIf="i !== 0">
            Statistika: {{ filterName.name }};
            Kategorie: {{ filterName.categoryName }}
            <ng-container *ngIf="filterName.areaName">; KÚ: {{ filterName.areaName }}</ng-container>
            <ng-container *ngIf="filterName.constructionObjectName">; {{ 'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate:true}}: {{ filterName.constructionObjectName }}</ng-container>
            <ng-container *ngIf="filterName.constructionObjectStageName">; {{ 'ETAPA_USEK'|wordtranslate }}: {{ filterName.constructionObjectStageName }}</ng-container>
          </div>
        </ng-template>
        <span [tooltip]="statsTooltip" [activeContent]="true">Složená statistika</span>
      </ng-container>
    </span>
  </div>
</div>
