import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { StateService } from '@uirouter/angular';
import { LocalStorageService } from 'angular-2-local-storage';
import { FormatType, MapPrintData, MapPrintResult, MeasureType, PrintType } from '@app/ps/map/models/map-print.model';

@Injectable({
  providedIn: 'root'
})
export class MapPrintService {

  static readonly formatTypesProject: FormatType[] = [
    { id: 'A0', name: 'A0 na šířku', width: 1070, height: 800, paperWidth: 1189, paperHeight: 841,
      layout: {
        header:           { top: 10, left: 9, width: 1070, height: 28 },
        descriptionBefore: { top: 10, left: 0, width: 1174, height: 28 },
        map:              { top: 28, left: 9, width: 1070, height: 800 },
        measure:          { top: 43, left: 1089, width: 85, height: 15 },
        page:             { top: 60, left: 1089, width: 85, height: 15 },
        overview:         { top: 90, left: 1089, width: 85, height: 95 },
        layers:           { top: 200, left: 1089, width: 75, height: 27 },
      }
    },
    { id: 'A1', name: 'A1 na šířku', width: 732, height: 553, paperWidth: 841, paperHeight: 594,
      layout: {
        header:           { top: 10, left: 9, width: 732, height: 28 },
        descriptionBefore: { top: 10, left: 0, width: 826, height: 28 },
        map:              { top: 28, left: 9, width: 732, height: 553 },
        measure:          { top: 43, left: 751, width: 75, height: 15 },
        page:             { top: 60, left: 751, width: 75, height: 15 },
        overview:         { top: 90, left: 751, width: 75, height: 95 },
        layers:           { top: 200, left: 751, width: 75, height: 27 },
      }
    },
  ];

  static readonly measureTypesProject: MeasureType[] = [
    { id: '1:1000', name: '1 : 1 000', denominator: 1000 },
    { id: '1:2000', name: '1 : 2 000', denominator: 2000 },
    { id: '1:5000', name: '1 : 5 000', denominator: 5000 },
  ];

  readonly printTypes: PrintType[] = [];

  readonly formatTypes: FormatType[] = [
    { id: 'A4', name: 'A4 na výšku', width: 190, height: 180, paperWidth: 210, paperHeight: 297,
      layout: {
        header:           { top: 14, left: 10, width: 190, height: 3.5 },
        attachmentName:   { top: 21.5, left: 10, width: 190, height: 3.5 },
        descriptionBefore: { top: 30, left: 10, width: 190, height: 25 },
        ownership:        { top: 50, left: 10, width: 130, height: 3.5 },
        caseNumber:       { top: 50, left: 140, width: 60, height: 3.5 },
        map:              { top: 55, left: 10, width: 190, height: 180 },
        measure:          { top: 236, left: 10, width: 190, height: 3.5 },
        layers:           { top: 245, left: 10, width: 190, height: 27 },
        descriptionAfter: { top: 275, left: 10, width: 190, height: 14 },
        overview:         { top: 110, left: 10, width: 100, height: 100 },
      }
    },
    { id: 'A4L', name: 'A4 na šířku', width: 210, height: 140, paperWidth: 297, paperHeight: 210,
      layout: {
        header:           { top: 15, left: 10, width: 67, height: 15 },
        attachmentName:   { top: 35, left: 10, width: 57, height: 3.5 },
        descriptionBefore: { top: 15, left: 90, width: 197, height: 20 },
        ownership:        { top: 30, left: 77, width: 150, height: 3.5 },
        caseNumber:       { top: 30, left: 227, width: 60, height: 3.5 },
        map:              { top: 35, left: 77, width: 210, height: 140 },
        measure:          { top: 176, left: 77, width: 210, height: 3.5 },
        layers:           { top: 48, left: 10, width: 57, height: 152 },
        descriptionAfter: { top: 182, left: 77, width: 210 , height: 18 },
        overview:         { top: 110, left: 10, width: 100, height: 100 },
      }
    },
    { id: 'A3', name: 'A3 na výšku', width: 270, height: 290, paperWidth: 297, paperHeight: 420,
      layout: {
        header:           { top: 15, left: 13.5, width: 270, height: 3.5 },
        attachmentName:   { top: 22, left: 13.5, width: 270, height: 3.5 },
        descriptionBefore: { top: 30, left: 13.5, width: 270, height: 18 },
        ownership:        { top: 45, left: 13.5, width: 210, height: 2.5 },
        caseNumber:       { top: 45, left: 223.5, width: 60, height: 2.5 },
        map:              { top: 50, left: 13.5, width: 270, height: 320 },
        measure:          { top: 342, left: 13.5, width: 270, height: 3.5 },
        layers:           { top: 367, left: 13.5, width: 270, height: 25 },
        descriptionAfter: { top: 396, left: 13.5, width: 270, height: 14 },
        overview:         { top: 110, left: 10, width: 100, height: 100 },
      }
    },
    { id: 'A3L', name: 'A3 na šířku', width: 330, height: 230, paperWidth: 420, paperHeight: 297,
      layout: {
        header:           { top: 15, left: 10, width: 60, height: 15 },
        attachmentName:   { top: 30, left: 10, width: 60, height: 3.5 },
        descriptionBefore: { top: 15, left: 90, width: 320, height: 15 },
        ownership:        { top: 25, left: 80, width: 270, height: 3.5 },
        caseNumber:       { top: 25, left: 350, width: 60, height: 3.5 },
        map:              { top: 30, left: 80, width: 330, height: 230 },
        measure:          { top: 263, left: 80, width: 330, height: 3.5 },
        layers:           { top: 45, left: 10, width: 60, height: 242 },
        descriptionAfter: { top: 270, left: 80, width: 330, height: 15 },
        overview:         { top: 110, left: 10, width: 100, height: 100 },
      }
    },
  ];

  readonly measureTypes: MeasureType[] = [
    { id: '1:500', name: '1 : 500', denominator: 500 },
    { id: '1:1000', name: '1 : 1 000', denominator: 1000 },
    { id: '1:2000', name: '1 : 2 000', denominator: 2000 },
    { id: '1:5000', name: '1 : 5 000', denominator: 5000 },
    { id: 'custom', name: 'Vlastní', denominator: 100 },
  ];

  private data: any = {};

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private localStorageService: LocalStorageService,
  ) {
    this.getCasePrintData = this.getCasePrintData.bind(this);
    this.removeStoredPrintType = this.removeStoredPrintType.bind(this);
  }

  addData(id: number, data: MapPrintResult) {
    this.data[id] = data;
  }

  getData(id): MapPrintResult {
    return this.data[id];
  }

  removeData(id) {
    delete this.data[id];
  }

  getPrintData(): MapPrintData {
    return {
      title: 'Tisk z mapového okna',
      printTypes: this.getPrintTypes(),
      formatTypes: this.formatTypes,
      measureTypes: this.measureTypes,
      onCustomPrintRemoveFunction: this.removeStoredPrintType,

      onPrintFunction: (data) => {
        this.addData(data.id, data);
        this.stateService.go('symap.project.mapPrint', { projectKey: this.authService.getActualProject().key, id: data.id, type: 'common' });
      },
      onSelectItemFilter: (item) => {
        // CASE DETECTION
        if (item.caseParcels && item.caseBuildings) {
          return { id: item.id, type: 'businessCase' };
        }

        // OCCUPATION DETECTION
        if (item.zabcis) {
          return { id: item.id, type: 'occupation' };
        }

        // PARCEL DETECTION
        if (item.idpar) {
          return { id: item.id, type: 'parcel' };
        }

        // CONSTRUCTION OBJECT DETECTION
        if (item.countEasements !== undefined) {
          return { id: item.id, type: 'constructionObject' };
        }

        // SUBJECT
        if (item.address) {
          return { id: item.id, type: 'subject' };
        }

        if (item.countCases !== undefined) {
          return { id: item.id, type: 'title' };
        }

        return undefined;
      },
      validationText: (formatType, measureType, entity) => {
        return '';
      },
      validationButton: (formatType, measureType, entity) => {
        return !!(formatType && measureType);
      }
    };
  }

  getCasePrintData(): MapPrintData {
    return {
      title: 'Generovaní mapové přílohy smlouvy',
      printTypes: this.getPrintTypes(),
      formatTypes: this.formatTypes,
      measureTypes: this.measureTypes,
      onCustomPrintRemoveFunction: this.removeStoredPrintType,

      onPrintFunction: (data) => {
        this.addData(data.id, data);
        this.stateService.go('symap.project.mapPrint', { projectKey: this.authService.getActualProject().key, id: data.id, type: 'case' });
      },
      onSelectItemFilter: (item) => {
        // CASE DETECTION
        const isCaseItem = item.caseParcels && item.caseBuildings;
        if (isCaseItem) {
          return { id: item.id, type: 'businessCase' };
        }

        return undefined;
      },
      validationText: (formatType, measureType, entity) => {
        return (formatType && measureType && !entity ? 'Musí být zvolen případ' : '');
      },
      validationButton: (formatType, measureType, entity) => {
        return !!(formatType && measureType && entity);
      }
    };
  }

  getPrintTypes(): PrintType[] {
    return this.printTypes.concat(this.getStoredPrintTypes());
  }

  addStoredPrintType(printType: PrintType) {
    this.removeStoredPrintType(printType);
    const printTypes = this.getStoredPrintTypes();
    printTypes.push(printType);
    this.localStorageService.set(this.getStorageKey(), printTypes);
  }

  removeStoredPrintType(printType: PrintType) {
    const storedPrintTypes = this.getStoredPrintTypes();
    _.remove(storedPrintTypes, (p: any) => p.id === printType.id);
    this.localStorageService.set(this.getStorageKey(), storedPrintTypes);
  }

  getStoredPrintTypes(): PrintType[] {
    const printTypes = this.localStorageService.get<PrintType[]>(this.getStorageKey());
    return printTypes || [];
  }

  getStoredPrintType(id: string): PrintType {
    const storedPrintTypes = this.getStoredPrintTypes();
    return storedPrintTypes.find(spt => spt.id === id);
  }

  private getStorageKey(): string {
    const projectKey = this.authService.getActualProject().key;
    return projectKey + '.mapPrintSettings';
  }
}
