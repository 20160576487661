<div *ngFor="let geometricPlanRequest of geometricPlanRequests">
  <div (click)="$event.stopPropagation()">
    <a uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: geometricPlanRequest.id, tab: 'parcels-budouc' }">
      {{ geometricPlanRequest.name }}
    </a>
  </div>
</div>

<div *ngFor="let knGp of knGps">
  <ng-container *ngIf="shouldBeKnGpDisplayed(knGp)">
    <div *ngIf="knGp.geometricPlanRequest && withLink; else withoutLink" (click)="$event.stopPropagation()">
      <a uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: knGp.geometricPlanRequest.id, tab: 'parcels-budouc' }">
        {{ knGp.cis }}
      </a>
    </div>
    <ng-template #withoutLink>
      {{ knGp.cis }}
    </ng-template>
  </ng-container>
</div>
