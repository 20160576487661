<div [loading]="loading">
  <ul *ngIf="!loading && data?.list?.list">
    <li *ngFor="let parcelPrice of data.list.list">
      <a *ngIf="parcelPrice.parcel" uiSref="symap.project.parcels.detail" [uiParams]="{ id: parcelPrice.parcel.id }">{{ parcelPrice.parcel | parcelnumber }}</a>
      <span *ngIf="parcelPrice.building">{{ parcelPrice.building | buildingNumber }}</span>

      <div *ngIf="data.customData.priceType === 'P'">
        <div>Pozemek: {{ parcelPrice.preliminaryOpinionCoefficient * parcelPrice.preliminaryOpinionLandPrice * parcelPrice.parcel.vymera | number }}</div>
      </div>

      <div *ngIf="data.customData.priceType === 'E'">
        <div *ngIf="parcelPrice.expertOpinionLandPrice">Pozemek: {{ parcelPrice.expertOpinionLandPrice | number}}</div>
        <div *ngIf="parcelPrice.expertOpinionVegetationPrice">Porost (vedlejší úpravy): {{ parcelPrice.expertOpinionVegetationPrice | number }}</div>
        <div *ngIf="parcelPrice.expertOpinionBuildingsPrice">Stavby: {{ parcelPrice.expertOpinionBuildingsPrice | number }}</div>
        <div *ngIf="parcelPrice.expertOpinionCompensationPrice">Náhrada: {{ parcelPrice.expertOpinionCompensationPrice | number }}</div>
      </div>

      <div *ngIf="data.customData.priceType === 'O'">
        <div *ngIf="parcelPrice.otherLandPrice !== null">Pozemek: {{ parcelPrice.otherLandPrice | number }}</div>
        <div *ngIf="parcelPrice.otherCompensationPrice !== null">Náhrada: {{ parcelPrice.otherCompensationPrice | number }}</div>
        <div *ngIf="parcelPrice.otherVegetationPrice !== null">Porost (vedlejší úpravy): {{ parcelPrice.otherVegetationPrice | number }}</div>
        <div *ngIf="parcelPrice.otherBuildingsPrice !== null">Stavby: {{ parcelPrice.otherBuildingsPrice | number }}</div>
      </div>
    </li>
  </ul>
  <div *ngIf="data?.list?.list?.length === 0">Žádné parcely</div>
</div>
