import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { TableService } from '@app/ps/services/table.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ConstructionObjectImportedModel } from '@app/ps/models/construction-object-imported.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'construction-object-imported-list',
  templateUrl: './construction-object-imported-list.component.html',
  styleUrls: []
})
export class ConstructionObjectImportedListComponent implements OnInit {

  list: ListModel<ConstructionObjectImportedModel>;
  pageableList: PageableList;
  helpIds = HelpService.HELP_IDS;

  private defaultFilter = {
    filters: { },
    sortOrder: { sortBy: 'createdDate', direction: 'desc' },
  };

  constructor(
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private tableService: TableService,
    private dialogService: DialogService,
  ) {
    this.onDeleteImportedConstructionObject = this.onDeleteImportedConstructionObject.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'construction-objects/imports',
      this.defaultFilter,
      'constructionObjectsImportedFilter'
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('constructionObjectsImported', columnId);
  }

  getColumnName(columnId: string) {
    return this.tableService.getColumnById('constructionObjectsImported', columnId).name;
  }

  onDeleteImportedConstructionObject(constructionObjectId) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: { msg: 'Opravdu chcete stavební objekt smazat?' },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.restangular.all('construction-objects').one('create', constructionObjectId).customPOST('{}', 'rollback')
          .toPromise()
          .then(() => {
            this.pageableList.fetchResults();
          });
      }

      sub.unsubscribe();
    });
  }
}
