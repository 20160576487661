import { CreateCaseComponent } from '@app/ps/construction-objects/components/create-case/create-case.component';
import { ConstructionObjectListComponent } from '@app/ps/construction-objects/components/construction-object-list/construction-object-list.component';
import { Ng2StateDeclaration, StateParams, StateService, Transition } from '@uirouter/angular';
import { ConstructionObjectDetailComponent } from '@app/ps/construction-objects/components/construction-object-detail/construction-object-detail.component';
import { ConstructionObjectUpdateComponent } from '@app/ps/construction-objects/components/construction-object-update/construction-object-update.component';
import { DialogService } from '@app/common/services/dialog.service';
import { EasementUpdateGlobalPriceComponent } from '@app/ps/construction-objects/components/easement-update-global-price/easement-update-global-price.component';
import { ConstructionObjectImportedListComponent } from '@app/ps/construction-objects/components/construction-object-imported-list/construction-object-imported-list.component';
import { ConstructionObjectCreateComponent } from '@app/ps/construction-objects/components/construction-object-create/construction-object-create.component';
import { EasementCreateComponent } from '@app/ps/construction-objects/components/easement-create/easement-create.component';
import { resolveId } from '@app/common/utils/state.utils';
import {UIRouter} from "@uirouter/core";

export function updateGlobalEasementPriceDialogFunction(transition: Transition) {
  const dialogService = transition.injector().get(DialogService);
  const stateParams = transition.injector().get('$stateParams');
  const stateService = transition.injector().get(UIRouter).stateService;

  const dialog = dialogService.open(EasementUpdateGlobalPriceComponent, {
    data: { constructionObjectId: stateParams.id },
  });

  const sub = dialog.afterClosed.subscribe(() => {
    stateService.go('^', stateService.params, { reload: true });
    sub.unsubscribe();
  });
}

export const constructionObjectListState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects',
  url: '/construction-objects?page',
  data: {
    title: '$CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
  },
  views: {
    'content@symap.project': {
      component: ConstructionObjectListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const constructionObjectImportedListState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.constructionObjectsImported',
  url: '/imported?page',
  data: {
    title: 'Připravované $CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
  },
  views: {
    'content@symap.project': {
      component: ConstructionObjectImportedListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const constructionObjectDetailState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail $CONSTRUCTION_OBJECT_GENITIV',
  },
  views: {
    'content@symap.project': {
      component: ConstructionObjectDetailComponent,
    }
  },
  resolve: {
    constructionObjectId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'easements',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const constructionObjectDetailUpdateState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.update',
  url: '/update/:id',
  data: {
    title: 'Editace $CONSTRUCTION_OBJECT_GENITIV',
  },
  views: {
    'content@symap.project': {
      component: ConstructionObjectUpdateComponent,
    }
  },
  resolve: {
    constructionObjectId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export const constructionObjectCreateState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.newConstructionObject',
  url: '/create',
  data: {
    title: '$NEW_CONSTRUCTION_OBJECT',
  },
  views: {
    'content@symap.project': {
      component: ConstructionObjectCreateComponent,
    }
  }
};

export const easementCreateState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.newEasement',
  url: '/create-easement',
  data: {
    title: 'Nové VB',
  },
  views: {
    'content@symap.project': {
      component: EasementCreateComponent,
    }
  }
};

export const createCaseState: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.detail.createCase',
  url: '/create-case',
  data: {
    title: 'Založení případů VB',
  },
  resolve: {
    constructionObjectId: ['$stateParams', resolveId],
  },
  views: {
    'content@symap.project': {
      component: CreateCaseComponent,
    }
  },
};

export const updateGlobalEasementPrice: Ng2StateDeclaration = {
  name: 'symap.project.constructionObjects.detail.updateGlobalEasementPrice',
  url: '/update-global-easement-price',
  data: {
    title: 'Editace hromadného zadání ceny VB',
  },
  onEnter: updateGlobalEasementPriceDialogFunction
};
