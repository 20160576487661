<h2 class="mb-10 mt-20">Poslední importy</h2>
<div [loading]="list.loading">
  <div class="bordered table" *ngIf="list.itemCount">
    <div class="table">
      <div class="head-group">
        <div class="row">
          <!-- stav -->
          <span class="cell">
            Stav
          </span>

          <!-- id importu -->
          <span class="cell">
            ID importu
          </span>

          <!-- datum vložení -->
          <span class="cell">
            Datum vložení
          </span>

          <!-- webdav adresář -->
          <span class="cell">
            WebDAV adresář
          </span>

          <!-- verze vfk -->
          <span class="cell">
            Verze VFK
          </span>

          <!-- seznam k.u. -->
          <span class="cell">
            Seznam k.ú.
          </span>
        </div>
      </div>
      <div class="row-group">
        <span class="row" *ngFor="let row of list.list">
          <!-- stav -->
          <span class="cell">
            {{ row.downloaded ? 'Vyřízeno' : 'Vyřizuje se' }}
          </span>

          <!-- id importu -->
          <span class="cell">
            {{ row.id }}
          </span>

          <!-- datum vložení -->
          <span class="cell">
            {{ row.insertedAt|date }}
          </span>

          <!-- webdav adresář -->
          <span class="cell">
            {{ row.folder }}
          </span>

          <!-- verze vfk -->
          <span class="cell">
            {{ row.vfkVersion }}
          </span>

          <!-- seznam k.u. -->
          <span class="cell">
            {{ row.kuIdsToDownload }}
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
  <span>Nebyla nalezena žádná data.</span>
</div>
