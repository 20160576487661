<div class="detail">
  <div class="row">
    <div class="col-6">
      <a uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: geometricPlanRequest.id, tab: 'parcels' }" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Detail geometrického plánu</a>
    </div>
  </div>

  <h1>Import geometrického plánu do aplikace</h1>

  <div [loading]="step === undefined">
    <div *ngIf="step === 1">
      <div class="row mt-30">
        <div class="col-12 offset-lg-3 col-lg-6">
          <fieldset>
            <!-- katastralni uzemi -->
            <gmt-input label="Katastrální území" [required]="true" class="mt-20">
              <gmt-select
                resource="areas"
                [filter]="{ sortOrder: { sortBy: 'name' }}"
                [data]="this"
                field="area"
                required="false"
                (changed)="onCadastreChanged()"
              ></gmt-select>
            </gmt-input>

            <!-- stavebni objekty -->
            <gmt-input label="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}" class="mt-20" [required]="geometricPlanRequest.type === typeEnum.EASEMENT_EXTENT">
              <gmt-select
                resource="construction-objects"
                [globalFilterConfig]="constructionObjectFilter"
                [filter]="{ sortOrder: { sortBy: 'socis' }}"
                [data]="geometricPlanRequest"
                field="constructionObjects"
                [multiselect]="true"
                required="false"
                [itemPipe]="constructionObjectNamePipe"
              ></gmt-select>
            </gmt-input>

            <!-- cislo GP -->
            <gmt-input label="Číslo GP" class="mt-10" [required]="true">
              <input type="text" [(ngModel)]="geometricPlanRequest.name"/>
            </gmt-input>

            <!-- zhotovitel -->
            <gmt-input label="Zhotovitel" class="mt-10" [required]="true">
              <input type="text" [(ngModel)]="geometricPlanRequest.supplier"/>
            </gmt-input>

            <!-- datum schvaleni konceptu -->
            <gmt-input label="Datum ověření GP" class="mt-10" [required]="true">
              <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.verificationDate"/>
            </gmt-input>

            <!-- datum schvaleni -->
            <gmt-input label="Datum potvrzení KP" class="mt-10" [required]="true">
              <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.approvedDate"/>
            </gmt-input>

            <!-- cislo PGP -->
            <gmt-input label="Číslo potvrzení GP (PGP)" class="mt-10" [required]="true">
              <input type="text" [(ngModel)]="geometricPlanRequest.pgpNumber" />
            </gmt-input>
          </fieldset>

          <!-- PDF GP -->
          <fieldset>
            <legend [ngClass]="{required: !hasFile}">Dokument PDF potvrzeného geometrického plánu katastrálním pracovištěm</legend>
            <fileupload class="blue" (change)="onGpFilesChange($event)" [message]="gpFilesMsg" inputId="gpHidden"></fileupload>
            <p class="text-center"><i>Vkládaný dokument PDF musí obsahovat elektronické razítko katastrálního pracoviště.</i></p>
          </fieldset>

          <!-- VFK GP -->
          <fieldset>
            <legend [ngClass]="{required: !hasVfkFile}">Soubor VFK předaný na katastrální pracoviště</legend>
            <fileupload class="blue" (change)="onGpVfkFilesChange($event)" [message]="gpVfkFilesMsg" inputId="gpVfkHidden"></fileupload>
          </fieldset>

          <!-- GP CSV -->
          <fieldset *ngIf="geometricPlanRequest.type === typeEnum.EASEMENT_EXTENT">
            <legend>Nepovinné údaje - hromadné vložení dat GP, CSV s výměrami a délkami VB</legend>
            <fileupload class="yellow" (change)="onGpCsvFilesChange($event)" [message]="gpCsvFilesMsg" inputId="gpCsvHidden"></fileupload>
          </fieldset>
        </div>
      </div>

      <div class="mt-30 flexbox-container flex-centered">
        <gmt-button [gmtDisabled]="!isValidForSecondStep()" [clickAction]="onSecondStep">Další</gmt-button>
      </div>
    </div>

    <div *ngIf="step === 2">
      <div class="mt-30 mb-70">
        <fieldset class="bordered">
          <legend>Geometrický plán</legend>
          <div class="column-50">
            <label>Zhotovitel</label>
            <div class="info">{{ geometricPlanRequest.supplier }}</div>

            <label>Číslo geometrického plánu</label>
            <div class="info">{{ geometricPlanRequest.name }}</div>

            <label>Katastrální území</label>
            <div class="info">
              <span *ngFor="let area of geometricPlanRequest.areas">
                {{ area.name }}
              </span>
            </div>
          </div>

          <div class="column-50">
            <label>Datum schválení konceptu</label>
            <div class="info">{{ geometricPlanRequest.conceptApprovedDate | date }}</div>

            <label>Datum potvrzení KP</label>
            <div class="info">{{ geometricPlanRequest.approvedDate | date }}</div>
          </div>
        </fieldset>
      </div>

      <table class="table bordered">
        <thead>
        <tr class="bordered-bottom">
          <th [attr.colspan]="2">Dosavadní stav</th>
          <th [attr.colspan]="5">Nový stav</th>
          <th [attr.colspan]="5">Porovnání se stavem evidence právních vztahů</th>
        </tr>
        <tr class="bordered-bottom">
          <th>Parcela</th>
          <th>LV</th>
          <th>Import</th>
          <th>Parcela</th>
          <th>Výměra</th>
          <th>Druh pozemku</th>
          <th>Způsob využití</th>
          <th>Import</th>
          <th>Parcela PEPV</th>
          <th>Výměra geometrie</th>
          <th>Označení dílu</th>
          <th>Výměra dílu</th>
        </tr>
        </thead>
        <tbody *ngFor="let d of data; index as j">
          <tr class="not-hover" *ngIf="!d.budouc.length">
            <td class="no-padding">
              <cell-parcel
                *ngIf="d.parcel"
                [parcel]="d.parcel"
                [tooltipType]="'parcel'"
                [tooltipData]="{ parcel: d.parcel }"
                cellClass="padding-geometric-plan"
              ></cell-parcel>
              <span *ngIf="!d.parcel">{{ d.current.parcis }}</span>
            </td>
            <td>
              {{ d.parcel ? d.parcel.lv : '' }}
            </td>
            <td class="text-center" colspan="10">

            </td>
          </tr>
          <ng-container *ngFor="let b of d.budouc; index as i">
            <tr class="not-hover" *ngIf="i === 0">
              <td [attr.rowspan]="d.budouc.length+1" class="no-padding">
                <cell-parcel
                  *ngIf="d.parcel"
                  [parcel]="d.parcel"
                  [tooltipType]="'parcel'"
                  [tooltipData]="{ parcel: d.parcel }"
                  cellClass="padding-geometric-plan"
                ></cell-parcel>
                <span *ngIf="!d.parcel">{{ d.current.parcis }}</span>
              </td>
              <td [attr.rowspan]="d.budouc.length + 1">
                <div class="column-100">
                  <div class="info">{{ d.parcel ? d.parcel.lv : '' }}</div>
                </div>
              </td>
              <td colspan="10" class="hover-fixer"></td>
            </tr>
            <tr>
              <td class="text-center">
                <div class="column-100">
                  <div class="info"><gmt-checkbox (click)="b.toImport = (b.vstupExists && b.imported ? b.toImport : !b.toImport)" [gmtChecked]="b.toImport" [gmtDisabled]="b.vstupExists"></gmt-checkbox></div>
                </div>
              </td>
              <td>
                <div class="column-100">
                  <div class="info">{{ b.parcis }}</div>
                  <div class="input">
                    <span class="cell--flex__container_side" *ngIf="b.dily">
                      <span title="Díl" class="tag tag--danger tag--small is-uppercase ml-5">Díl</span>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-100">
                  <div class="info">{{ b.vymera|number }}</div>
                </div>
              </td>
              <td><div class="column-100">
                <div class="info">
                 {{ b.druhpozNazev }}
                </div>
              </div>
              </td>
              <td><div class="column-100">
                <div class="info">
                  {{ b.zpusobVyuziti }}
                </div>
              </div>
              </td>
              <td [ngClass]="{'bordered-top' : i != 0 || j != 0}">
                <div class="column-100 flexbox-container flex-column flex-centered" [ngClass]="{'invalid-pepv-selection': b.toImport && !b.validPepv}">
                  <div *ngFor="let p of b.pepv" class="info">
                    <gmt-checkbox (click)="onPepvToImportClick(p, b)" [gmtChecked]="p.toImport && b.toImport" [gmtDisabled]="!b.toImport || b.vstupExists"></gmt-checkbox>
                  </div>
                </div>
              </td>
              <td [ngClass]="{'bordered-top' : i != 0 || j != 0}">
                <div class="column flexbox-container flex-column">
                  <div *ngFor="let p of b.pepv" class="info">
                    {{ p.parcis }}
                  </div>
                </div>
              </td>
              <td [ngClass]="{'bordered-top' : i != 0 || j != 0}">
                <div class="column flexbox-container flex-column">
                  <div *ngFor="let p of b.pepv" class="info">
                    {{ p.vymeraGeom|number }}
                  </div>
                </div>
              </td>
              <td [ngClass]="{'bordered-top' : i != 0 || j != 0}">
                <div class="column flexbox-container flex-column">
                  <div *ngFor="let p of b.pepv" class="input">
                    <input type="text" [(ngModel)]="p.oznaceniDilu"/>
                  </div>
                </div>
              </td>
              <td [ngClass]="{'bordered-top' : i != 0 || j != 0}">
                <div class="column flexbox-container flex-column">
                  <div *ngFor="let p of b.pepv" class="input">
                    <input type="text" gmtInputNumber [(ngModel)]="p.vymeraDilu"/>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tbody *ngIf="data.length" class="bordered-bottom bordered-top">
        <tr class="not-hover">
          <td class="import-footer-cell">{{ currentSum|number }}</td>
          <td colspan="3" class="import-footer-cell text-center">Kontrolní součet výměr</td>
          <td class="import-footer-cell">{{ budoucSum|number }}</td>
          <td colspan="7"></td>
        </tr>
        </tbody>
      </table>

      <div class="mt-30 flexbox-container flex-centered">
        <gmt-button [clickAction]="onFirstStep">Zpět</gmt-button>
        <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
      </div>
    </div>
  </div>
</div>
