<fieldset>
  <legend>Kontakt <a *ngIf="editableToggle" (click)="editable = !editable"><i class="fa fa-pencil"></i></a></legend>
  <!-- telefon -->
  <div class="column-33">
    <gmt-input *ngIf="editable" label="Telefon">
      <input type="text" [(ngModel)]="contactInfo.phoneNumber"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Telefon</label>
      <div class="info">{{ contactInfo.phoneNumber }}</div>
    </ng-container>
  </div>

  <!-- e-mail -->
  <div class="column-33">
    <gmt-input *ngIf="editable" label="E-mail">
      <input type="text" [(ngModel)]="contactInfo.email"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>E-mail</label>
      <div class="info">{{ contactInfo.email }}</div>
    </ng-container>
  </div>

  <!-- datova schranka -->
  <div class="column-33">
    <gmt-input *ngIf="editable" label="Datová schránka">
      <input type="text" [(ngModel)]="contactInfo.databoxId"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Datová schránka</label>
      <div class="info">{{ contactInfo.databoxId }}</div>
    </ng-container>
  </div>
</fieldset>
