import { Component, Inject, OnInit } from '@angular/core';
import { BulkPriceStepComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-step/bulk-price-step.component';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { ExpertModel } from '@app/ps/models/expert.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'bulk-price-init',
  templateUrl: './bulk-price-init.component.html',
})
export class BulkPriceInitComponent extends BulkPriceStepComponent implements OnInit {

  loading = false;
  rentalPrice: any;
  occupations: OccupationModel[];
  occupationType;

  constructor(
    private restangular: Restangular,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.loadOccupations = this.loadOccupations.bind(this);
  }

  ngOnInit() {
    this.rentalPrice = this.data.rentalPrice || {
      rentalPriceType: 'F',
      rentalPriceUnit: 'A',
      rentalLength: 12,
    };
    this.occupations = this.data.occupations;
    this.occupationType = this.data.occupationType;
  }

  isValid(): boolean {
    const rentValid = this.rentalPrice.rentalPriceType
      && this.rentalPrice.rentalLength
      && ((this.occupationType === 'u' && this.rentalPrice.rentalLength <= 12) || (this.occupationType === 'o' && this.rentalPrice.rentalLength > 12))
      && (
        (this.rentalPrice.rentalPriceType === 'F' && this.rentalPrice.priceAssessmentYear && this.rentalPrice.priceAssessmentLandPrice)
        || (this.rentalPrice.rentalPriceType === 'E' && this.rentalPrice.rentalExpertOpinionNumber && this.rentalPrice.rentalExpertOpinionDate && this.rentalPrice.rentalExpert && this.rentalPrice.priceAssessmentLandPrice)
        || (this.rentalPrice.rentalPriceType === 'O' && this.rentalPrice.rentalOtherPriceSource && ((this.rentalPrice.rentalPriceUnit === 'T' && this.rentalPrice.totalRentPrice) || (this.rentalPrice.rentalPriceUnit === 'A' && this.rentalPrice.priceAssessmentLandPrice)))
      );

    return !!(this.occupations && this.occupations.length && rentValid);
  }

  onSubmit() {
    this.data.rentalPrice = this.rentalPrice;
    this.data.occupations = this.occupations;
    this.data.occupationType = this.occupationType;
    this.submitCallback.emit();
  }

  onChangeExpert(expert: ExpertModel) {
    this.rentalPrice.rentalExpert = expert;
  }

  onTypeChange(type: 'o' | 'u') {
    if (this.loading) {
      return;
    }
      this.occupationType = type;
    this.loadOccupations();
  }

  private loadOccupations(): Promise<any> {
    this.loading = true;
    const occupationFilter = {filter: {filters: {
      validity: ['valid'],
      loadCollections: ['rentalExpert'],
      withCaseOrFutureCase: [false],
      occupationTypeId: [],
    }}};

    const occupationTypeFilter = {filter: {filters: {
      validity: ['valid'],
      // underOneYear, overOneYear
      number: this.occupationType === 'u' ? [5,9,22,221,31] : [6,10,21,211],
    }}};

    return this.restangular.all('occupation-types')
      .customPOST(occupationTypeFilter)
      .toPromise()
      .then(ot => {
        ot = ot.plain();

        if (ot.length === 0) {
          this.loading = false;
          this.occupations = [];
          return Promise.resolve();
        }

        occupationFilter.filter.filters.occupationTypeId = ot.map(i => i.id);

        return this.restangular
          .all('occupations')
          .customPOST(occupationFilter)
          .toPromise()
          .then(data => {
            this.loading = false;
            this.occupations = data.plain();
          });
      });
    }
}
