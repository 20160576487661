import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectService } from '@app/ps/services/project.service';
import { LoginService } from '@app/common/services/login.service';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { HelpService } from '@app/common/services/help.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'project-remove',
  templateUrl: './project-remove.component.html',
})
export class ProjectRemoveComponent implements OnInit {

  project: any;
  phase: string;
  password: string;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(RESTANGULAR_SYMAP) private restangular: any,
    private projectService: ProjectService,
    private loginService: LoginService,
    private authService: AuthService,
    private dialogService: DialogService,
    private config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.project = (<DialogConfigData>config.data).project;
    this.confirm = this.confirm.bind(this);
    this.decline = this.decline.bind(this);
    this.confirmPhase = this.confirmPhase.bind(this);
  }

  ngOnInit() {
  }

  confirm() {
    return this.restangular.one('projects', this.project.id).remove({ password: this.password })
      .toPromise()
      .then(() => {
        _.remove(this.authService.getActualProjects(), {id: this.project.id});
        this.localStorageService.set('auth-projects', this.authService.getActualProjects());
        this.dialog.close();
        if (this.authService.getActualProjects().length > 0) {
          this.projectService.changeProject(this.authService.getActualProjects()[0]);
        } else {
          return this.loginService.redirectToLogin(false);
        }
      });
  }

  decline() {
    this.dialog.close();
  }

  confirmPhase() {
    this.phase = 'confirm';
  }
}
