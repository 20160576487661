import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SampleListComponent } from '@app/sv/components/sample-list/sample-list.component';
import {Ng2StateDeclaration, StateParams, UIRouterModule} from '@uirouter/angular';
import { MainComponent } from '@app/sv/components/main/main.component';
import { TicketListComponent } from '@app/sv/components/ticket-list/ticket-list.component';
import { SampleDetailComponent } from '@app/sv/components/sample-detail/sample-detail.component';
import { TicketDetailComponent } from '@app/sv/components/ticket-detail/ticket-detail.component';
import { SettingsComponent } from '@app/sv/components/settings/settings.component';
import { resolveId } from '@app/common/utils/state.utils';
import { TicketCreateComponent } from '@app/sv/components/ticket-create/ticket-create.component';
import { TicketEditComponent } from '@app/sv/components/ticket-edit/ticket-edit.component';
import { DocumentTypeCreateComponent } from '@app/sv/components/document-type-create/document-type-create.component';
import { DocumentTypeCategoryNamePipe } from '@app/sv/pipes/document-type-category-name.pipe';
import { SampleStatusNamePipe } from '@app/sv/pipes/sample-status-name.pipe';
import { TicketStatusNamePipe } from '@app/sv/pipes/ticket-status-name.pipe';

export const sampleState: Ng2StateDeclaration = {
  name: 'sv',
  url: '/sv',
  abstract: true,
  component: MainComponent,
};

export const sampleListState: Ng2StateDeclaration = {
  name: 'sv.samples',
  url: '/sample-list?page',
  data: {
    title: 'Správa vzorů',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  views: {
    'content@sv': {
      component: SampleListComponent,
    }
  },
};

export const sampleDetailState: Ng2StateDeclaration = {
  name: 'sv.samples.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail vzoru',
  },
  views: {
    'content@sv': {
      component: SampleDetailComponent,
    }
  },
  resolve: {
    sampleId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'notes',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const ticketListState: Ng2StateDeclaration = {
  name: 'sv.tickets',
  url: '/ticket-list?page',
  data: {
    title: 'Požadavky',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  views: {
    'content@sv': {
      component: TicketListComponent,
    }
  },
};

export const ticketDetailState: Ng2StateDeclaration = {
  name: 'sv.tickets.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail požadavku',
  },
  views: {
    'content@sv': {
      component: TicketDetailComponent,
    }
  },
  resolve: {
    ticketId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'notes',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const ticketCreateState: Ng2StateDeclaration = {
  name: 'sv.tickets.create',
  url: '/create?id',
  data: {
    title: 'Vytvoření požadavku',
  },
  resolve: {
    sampleId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
  views: {
    'content@sv': {
      component: TicketCreateComponent,
    }
  },
};

export const settingsState: Ng2StateDeclaration = {
  name: 'sv.settings',
  url: '/settings',
  data: {
    title: 'Požadavky',
  },
  views: {
    'content@sv': {
      component: SettingsComponent,
    }
  },
};

export const documentTypeCreateState: Ng2StateDeclaration = {
  name: 'sv.settings.createDocumentType',
  url: '/create-document-type',
  data: {
    title: 'Vytvoření nového typu dokumentu',
  },
  views: {
    'content@sv': {
      component: DocumentTypeCreateComponent,
    }
  },
};

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        sampleState,
        sampleListState,
        sampleDetailState,
        ticketListState,
        ticketDetailState,
        settingsState,
        ticketCreateState,
        documentTypeCreateState,
      ]
    }),
  ],
  providers: [
    DatePipe,
    DecimalPipe,
  ],
  declarations: [
    MainComponent,
    SampleListComponent,
    TicketListComponent,
    SampleDetailComponent,
    TicketDetailComponent,
    SettingsComponent,
    TicketCreateComponent,
    TicketEditComponent,
    DocumentTypeCreateComponent,

    DocumentTypeCategoryNamePipe,
    SampleStatusNamePipe,
    TicketStatusNamePipe,
  ],
  entryComponents: [
    SampleListComponent,
    TicketEditComponent,
  ],
  exports: [
    SampleStatusNamePipe,
  ]
})
export class SvAppModule { }
