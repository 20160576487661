<h1>Výběr zobrazených sloupců</h1>
<fieldset>
  <div class="bordered column-100 checklist">
    <ng-container *ngFor="let column of columns">
      <div *ngIf="column.selectable === undefined || column.selectable === true" class="checklist-item cb">
        <a class="fl mr-10" (click)="onMoveColumn(column, -1)"><i class="fa fa-arrow-up"></i></a>
        <a class="fl mr-10" (click)="onMoveColumn(column, +1)"><i class="fa fa-arrow-down"></i></a>
        <div class="mr-10" (click)="onToggleColumn(column)">
          <gmt-checkbox
            class="item-checkbox"
            [gmtChecked]="isColumnEnabled(column)"
          ></gmt-checkbox> {{ column.name }}
        </div>
      </div>
    </ng-container>
  </div>
</fieldset>
<div class="text-center pt-20">
  <gmt-button [clickAction]="onUpdate" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
