export enum OpinionRequestStatusEnum {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  REALIZATION_STARTED = 'REALIZATION_STARTED',
  REALIZED = 'REALIZED',
  PRICES_FILLED = 'PRICES_FILLED',
}

export const opinionRequestStatusOptions = [
  {
    id: OpinionRequestStatusEnum.CREATED,
    name: 'Založení záznamu o ZP',
    dialog: false,
  },
  {
    id: OpinionRequestStatusEnum.DELETED,
    name: 'Zrušený ZP',
    action: 'Zrušit ZP',
    dialog: true,
  },
  {
    id: OpinionRequestStatusEnum.REALIZATION_STARTED,
    name: 'Zahájeno zpracování ZP',
    dialog: true,
  },
  {
    id: OpinionRequestStatusEnum.REALIZED,
    name: 'Vyhotovený ZP',
    dialog: true,
  },
  {
    id: OpinionRequestStatusEnum.PRICES_FILLED,
    name: 'Vložení cen dle ZP do aplikace',
    action: 'Vložit ceny dle ZP do aplikace',
    dialog: false,
  },
];
