<legend *ngIf="caseSubject.subject.opsubType == 'OPO'">Společnost</legend>
<legend *ngIf="!caseOwnership.ownership.isBsm && caseSubject.subject.opsubType == 'OFO'">Fyzická osoba</legend>
<legend *ngIf="caseOwnership.ownership.isBsm" class="pl-20">Osoba č. {{ index }}</legend>
<div>
  <div class="edit" *ngIf="canEdit">
    <a (click)="editFunction({ caseSubjectId: caseSubject.id, caseOwnershipId: caseOwnership.id })" class="button">Upravit údaje</a>
  </div>
  <div class="flexbox-container">
    <div class="column-50">
      <label>{{caseSubject.subject.opsubType == 'OPO' ? 'Název' : 'Jméno'}}</label>
      <div class="info">
        <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseSubject.subject.lastSubjectId, tab: 'about' }">{{ caseSubject.subject | subjectName }}</a>
      </div>
      <div class="input">
        <span title="Problematický vlastník" class="ml-10 tag tag--danger tag--small is-uppercase" *ngIf="caseSubject.subject.subjectDetail.problematic">PV</span>
        <span title="Sankce" class="ml-10 tag tag--danger tag--small is-uppercase" *ngIf="caseSubject.subject.subjectDetail.ssEU || caseSubject.subject.subjectDetail.ssNonEU || caseSubject.subject.subjectDetail.ssRuskyVliv">Sankce</span>
      </div>
      <label>Adresa</label>
      <div class="info">
        {{ { street:caseSubject.ulice, houseNumber: caseSubject.cisloDomovni, streetNumber: caseSubject.cisloOrientacni, neighbourhood: caseSubject.castObce, municipality: caseSubject.obec, cpCe: caseSubject.cpCe } | fullstreet }}<br/>
        <span *ngIf="caseSubject.castObce && caseSubject.castObce !== caseSubject.obec && caseSubject.ulice">{{ caseSubject.castObce }}<br/></span>
        {{ { postCode: caseSubject.psc, municipality: caseSubject.obec, district: caseSubject.mestskyObvod } | fullmunicipality }}
      </div>
      <div *ngIf="caseSubject.dorucovaciUlice || caseSubject.dorucovaciCisloDomovni || caseSubject.dorucovaciCisloOrientacni || caseSubject.dorucovaciObec">
        <label>Doručovací adresa</label>
        <div class="info">
          {{ {street:caseSubject.dorucovaciUlice, houseNumber: caseSubject.dorucovaciCisloDomovni, streetNumber: caseSubject.dorucovaciCisloOrientacni, neighbourhood: caseSubject.dorucovaciCastObce, cpCe: caseSubject.dorucovaciCpCe } | fullstreet }}<br/>
          {{ caseSubject.dorucovaciPsc | postCode}} {{ caseSubject.dorucovaciObec }}
        </div>
      </div>
      <div *ngIf="caseSubject.subject.opsubType === 'OPO'">
        <label>IČ</label><div class="info">{{caseSubject.subject.ico }}</div>
        <label>DIČ</label><div class="info">{{caseSubject.dic }}</div>
        <label>Zápis</label><div class="info">
        {{ caseSubject.businessRegisterRegionalCourt?.name || 'registr' }},
        oddíl{{ caseSubject.businessRegisterSection ? ' ' + caseSubject.businessRegisterSection : '' }},
        vložka{{ caseSubject.businessRegisterEntry ? ' ' + caseSubject.businessRegisterEntry : '' }}</div>
      </div>
      <div *ngIf="caseSubject.subject.opsubType !== 'OPO'">
        <label>R.Č.</label><div class="info">{{ caseSubject.personalIdentificationNumber }}</div>
        <label>Datum narození</label><div class="info">{{ caseSubject.dateOfBirth| date: 'd.M.yyyy' }}</div>
      </div>
      <label>Telefon</label><div class="info">{{ caseSubject.phoneNumber }}</div>
      <label>E-mail</label><div class="info">{{ caseSubject.email }}</div>
    </div>
    <div class="column-50">
      <label>Zastupující</label>
      <div class="info">
        <span style="display: block" *ngFor="let person of caseSubject.actingPersons">
          {{{
            titulZaJmenem: person.degreeAfter,
            jmeno: person.firstName,
            prijmeni: person.surname,
            titulPredJmenem: person.titleBefore
          } | subjectName}}</span>
      </div>
      <label>Plná moc</label><div class="info">{{ caseSubject.powerOfAttorneyDate| date: 'd.M.yyyy' }}</div>

      <div *ngIf="!caseOwnership.ownership.isBsm">
        <label>Podíl</label>
        <div class="info" *ngIf="titles.length === 1">
          {{caseOwnership.podil}}
        </div>
        <div class="info" *ngIf="titles.length > 1">
          {{caseOwnership.podil}}

          <ng-container *ngIf="caseOwnership.ownership.title">
            (LV <a uiSref='symap.project.titles.detail' [uiParams]="{ caseSubjectId: caseSubject.id, id: caseOwnership.ownership.title.id, tab: null }" title='{{caseOwnership.ownership.title.area.name }}'>{{ caseOwnership.ownership.title.lv }}</a> - {{ caseOwnership.ownership.title.area.name }})
          </ng-container>
          <ng-container *ngIf="!caseOwnership.ownership.title">
            (LV {{ caseOwnership.ownership.lv }})
          </ng-container>
        </div>
      </div>
      <div *ngIf="!caseOwnership.ownership.isBsm || !caseOwnership.singlePayment">
        <div *ngIf="caseSubject.paymentType !== 'B' && caseSubject.paymentType !== 'M'">
          <label>Způsob platby</label><div class="info">Neurčeno</div>
        </div>
        <div *ngIf="caseSubject.paymentType === 'M'">
          <label>Způsob platby</label><div class="info">Poštovní poukázkou</div>
        </div>
        <div *ngIf="caseSubject.paymentType === 'B'">
          <label>Způsob platby</label><div class="info">Bankovním převodem</div>
          <label>Číslo B.Ú.</label><div class="info">{{ caseSubject.accountNumber && caseSubject.bankCode ? caseSubject.accountNumber + "/" + caseSubject.bankCode : '' }}</div>
          <label>Var. symbol</label><div class="info">{{ caseSubject.variableSymbol }}</div>
        </div>
      </div>
      <div *ngIf="!caseOwnership.ownership.isBsm">
        <div *ngIf="obligationType === 'SalesContractLiability' || obligationType === 'NonGratuitousTransferContractLiability' || obligationType === 'ExchangeContractLiability'">
          <label>Kupní cena</label><div class="info" *ngIf="caseOwnership.price !== null">{{ caseOwnership.price | number | price }}<br/>{{ caseOwnership.priceInWords }}</div>
        </div>
        <div *ngIf="obligationType === 'GiftContractLiability' || obligationType === 'GratuitousTransferContractLiability'">
          <label>Účetní cena</label><div class="info">{{ caseOwnership.price | number | price }}<br/>{{ caseOwnership.priceInWords }}</div>
        </div>
        <div *ngIf="caseOwnership.easementPrice !== undefined">
          <label>Cena služebnosti</label><div class="info">{{ caseOwnership.easementPrice | number | price}}<br/>{{ caseOwnership.easementPriceInWords }}</div>
        </div>
        <div *ngIf="obligationType === 'RentalContractShorterThan1YearLiability' || obligationType === 'RentalContractLongerThan1YearLiability' || obligationType === 'RentalContractCommonLiability'">
          <label *ngIf="caseOwnership.landPrice">Nájem</label>
          <div *ngIf="caseOwnership.landPrice" class="info">{{ caseOwnership.landPrice | number | price }}</div>
          <label *ngIf="caseOwnership.compensationPrice">Náhrady</label>
          <div *ngIf="caseOwnership.compensationPrice" class="info">
            {{ caseOwnership.compensationPrice | number | price }}
          </div>
          <label>Celkem</label><div class="info">{{ caseOwnership.price | number | price }}<br/>{{ caseOwnership.priceInWords }}</div>
        </div>
        <div *ngIf="caseOwnership.easementFutureContractNumber">
          <label>Číslo SOSB</label><div class="info">{{ caseOwnership.easementFutureContractNumber }}</div>
        </div>
        <div *ngIf="caseOwnership.easementFutureContractStatus">
          <label>Stav SOSB</label><div class="info">{{ caseOwnership.easementFutureContractStatus }}</div>
        </div>
      </div>

      <div *ngIf="obligationBonusPeriod || caseSubject.bonusPrice">
        <label>Akcelerační příplatek</label><div class="info" *ngIf="caseSubject.bonusPrice !== undefined">{{ caseSubject.bonusPrice | number | price}}</div>
      </div>
      <div *ngIf="caseSubject.signatureVerificationPrice !== undefined">
        <label>Příplatek za ověření</label><div class="info">{{ caseSubject.signatureVerificationPrice | number | price }}</div>
      </div>
      <div *ngIf="caseOwnership.parentCaseOwnership !== undefined">
        <label>Nadřazený subjekt</label><div class="info">{{ caseOwnership.parentCaseOwnership | parentCaseOwnershipName }}</div>
      </div>
    </div>
  </div>
</div>
