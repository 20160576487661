import { Component, OnInit, Inject } from '@angular/core';
import { DialogConfig } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './title-create-subject.component.html',
})
export class TitleCreateSubjectComponent implements OnInit {

  data: any = {};
  sending = false;

  constructor(
    private restangular: Restangular,
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
  ) { }

  ngOnInit() {

  }

  create() {
    if (!this.sending) {
      this.sending = true;
      this.data.opsubType = (this.data.charosKod === 2) ? 'OFO' : 'OPO';
      return this.restangular.all('subjects/create')
        .post(this.data)
        .toPromise()
        .then((data) => {
          this.dialogRef.close(data.plain());
          this.sending = false;
        });
    }
  }
}
