import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TrendDataModel } from '@app/common/models/trend-data.model';

@Component({
  selector: 'gmt-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendComponent implements OnInit {

  @Input() trendData: TrendDataModel;
  @Input() withoutPercentage = false;

  direction: 'up' | 'down' | 'stay';
  trend: number;

  public ngOnInit() {
    const percentage = TrendComponent.getPercentage(this.trendData.done, this.trendData.total);
    const percentageBefore = TrendComponent.getPercentage(this.trendData.doneBefore, this.trendData.totalBefore);
    this.trend = percentage - percentageBefore;

    if (percentage > percentageBefore) {
      this.direction = 'up';
    } else if (percentage < percentageBefore) {
      this.direction = 'down';
    } else {
      this.direction = 'stay';
    }
  }

  private static getPercentage(done: number, total: number): number {
    if (total === 0 || done === 0) {
      return 0;
    }
    return (done / total * 100);
  }
}
