﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { ParentCaseOwnershipNamePipe } from '@app/ps/pipes/parent-case-ownership-name.pipe';
import { ListService } from '@app/common/services/list.service';
import * as _ from 'lodash';
import { CaseService } from '@app/ps/services/case.service';
import { DialogService } from '@app/common/services/dialog.service';

@Component({
  selector: 'construction-object-detail-create-case-select-parent-ownership',
	templateUrl: './create-case-select-parent-ownership.component.html',
})
export class CreateCaseSelectParentOwnershipComponent implements OnInit {
  @Input() data: any;
  @Output() private submitCallback = new EventEmitter();
  @Output() private backCallback = new EventEmitter();
  public loading: boolean = true;
  public showEasementDistributionSystemParts: boolean;

  constructor(
    private authService: AuthService,
    public parentCaseOwnershipNamePipe: ParentCaseOwnershipNamePipe,
    private listService: ListService,
    private dialogService: DialogService,
    private caseService: CaseService,
  ) {
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {
    const project = this.authService.getActualProject();
    this.showEasementDistributionSystemParts = project.easementDistributionSystemParts;
    this.createCaseOwnerships();
  }

  createOwnershipWithParent(caseOwnership, businessCase) {
    caseOwnership.parentCaseOwnership = caseOwnership.parentCaseOwnership || null;
    const ownershipWithParent = {
      caseOwnership: caseOwnership,
      businessCase: businessCase,
      optionItems:
        _.filter(businessCase.caseOwnerships, (caseOwnership2) => {
          return caseOwnership !== caseOwnership2 && caseOwnership2.ownership.subjects[0].opsubType === 'OPO';
        })
    };
    if (!caseOwnership.parentCaseOwnership && ownershipWithParent.optionItems.length === 1) {
      caseOwnership.parentCaseOwnership = ownershipWithParent.optionItems[0];
    }
    if (caseOwnership.parentCaseOwnership) {
      this.changedParent(caseOwnership.parentCaseOwnership, null, ownershipWithParent);
    }
    return ownershipWithParent;
  }

  async createCaseOwnerships() {
    const loadTitles = [];
    this.loading = true;
    this.data.cases = [];
    this.data.easementsSelected.forEach((easement) => {
      let businessCase = _.find(this.data.cases, (value) => value.titles[0].id === easement.parcel.title.id);
      if (businessCase === undefined) {
        if (easement.parcel.title.ownerships.length === 0) {
          loadTitles.push(easement.parcel.title.id);
        }
        businessCase = {
          obligation: this.data.obligation,
          titles: [easement.parcel.title],
          easements: [],
          mpp: {},
          distributionSystemParts: this.showEasementDistributionSystemParts ? [] : null,
        };
        this.data.cases.push(businessCase);
      }
      businessCase.easements.push(easement);
    });
    if (loadTitles.length > 0) {
      const list = this.listService.createList('ownerships', {
        limit: null,
        filters: {
          validity: ['valid'],
          titleId: loadTitles
        },
      });
      await this.listService.fetchResult(list).then((data) => {
        data.list.forEach(ownership => {
          const businessCase = this.data.cases.find((value) => value.titles[0].id === ownership.title.id);
          businessCase.titles[0].ownerships.push(ownership);
        });
      });
    }
    this.filterOwnershipsWithParent();
    this.loading = false;
  }

  filterOwnershipsWithParent() {
    let oldOwnershipsWithParent = null
    if (this.data.ownershipsWithParent) {
      oldOwnershipsWithParent = this.data.ownershipsWithParent;
    }
    this.data.ownershipsWithParent = [];

    this.data.cases.forEach((businessCase) => {
      businessCase.caseOwnerships = [];
      businessCase.titles[0].ownerships.forEach((ownership) => {
        const caseOwnership = {
          easementPrice: null,
          ownership: ownership,
        };
        businessCase.caseOwnerships.push(caseOwnership);
      });
      businessCase.caseOwnerships.forEach((caseOwnership) => {
        if (caseOwnership.ownership.typeNeedsParent) {
          if (oldOwnershipsWithParent) {
            const foundOld = _.find(oldOwnershipsWithParent, {caseOwnership: {ownership: {id: caseOwnership.ownership.id}}});
            if (foundOld) {
              caseOwnership.parentCaseOwnership = _.find(businessCase.caseOwnerships, {ownership: {id: foundOld.caseOwnership.parentCaseOwnership.ownership.id}});
            }
          }
          this.data.ownershipsWithParent.push(this.createOwnershipWithParent(caseOwnership, businessCase));
        }
      });
    });
    if (!oldOwnershipsWithParent && !this.needsParentSelection()) {
      this.submit(true);
    }
  }

  needsParentSelection() {
    return _.some(this.data.ownershipsWithParent, (item) => !item.caseOwnership.parentCaseOwnership);
  }

  changedParent(newValue, oldValue, ownershipWithParent) {
    if (oldValue &&
      !_.includes(ownershipWithParent.businessCase.caseOwnerships, oldValue) &&
      !_.some(this.data.ownershipsWithParent, (item) => item.caseOwnership.parentCaseOwnership === oldValue)
    ) {
      ownershipWithParent.businessCase.caseOwnerships.push(oldValue);
      oldValue.isParent = false;
    }

    if (newValue) {
      newValue.isParent = true;
      if (this.caseService.checkParentCaseOwnershipCycle(newValue)) {
        oldValue = newValue.parentCaseOwnership;
        newValue.parentCaseOwnership = null;
        this.changedParent(null, oldValue, _.find(this.data.ownershipsWithParent, {caseOwnership: newValue}));
        this.dialogService.alertDialogPromise('Zjištěn cyklický vztah v zadání nadřazených subjektů!');
      }
      delete newValue.easementPrice;
      _.pull(ownershipWithParent.businessCase.caseOwnerships, newValue);
    }
  }

  submit(skip = false) {
    if (this.data.obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' || this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated') {
      const findGroup = (casesGrouped, businessCase) => {
        return casesGrouped.find(businessCaseGrouped =>
          (!this.data.createGroups.some(group => group.id === 'owner') ||
            businessCaseGrouped.caseOwnerships[0].ownership.opsubId === businessCase.caseOwnerships[0].ownership.opsubId) &&
          (!this.data.createGroups.some(group => group.id === 'title') ||
            businessCaseGrouped.titles[0].id === businessCase.titles[0].id));
      };
      this.data.cases = this.data.cases
        // Split for each caseOwnership
        .flatMap((businessCase) => {
          if (this.data.createGroups.some(group => group.id === 'owner')) {
            return businessCase.caseOwnerships.map(co => Object.assign({}, businessCase, {
              caseOwnerships: [co],
            }));
          } else {
            return [businessCase];
          }
        })
        // do grouping
        .reduce((casesGrouped, businessCase) => {
          const foundGroup = findGroup(casesGrouped, businessCase);
          if (!foundGroup) {
            casesGrouped.push(businessCase);
          } else {
            foundGroup.titles.push(...businessCase.titles);
            foundGroup.caseOwnerships.push(...businessCase.caseOwnerships);
            foundGroup.easements.push(...businessCase.easements);
          }
          return casesGrouped;
        }, []);
    }

    return this.submitCallback.emit({skipped: skip});
  }
}
