import { Component, Input, OnInit } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';
import { AreaModel } from '@app/common/models/area.model';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { CommonTypeModel, parcelTypes, parcelUsageTypes, parcelNumberTypes } from '@app/common/models/common-type.model';

@Component({
  selector: 'parcel-edit-form',
  templateUrl: './parcel-edit-form.component.html',
  styleUrls: ['parcel-edit-form.component.scss']
})
export class ParcelEditFormComponent implements OnInit {

  @Input() parcel: ParcelModel;
  @Input() areaSelect: boolean;

  parcelNumber: string;
  parcelArea: AreaModel;
  parcelType: CommonTypeModel;
  usageType: CommonTypeModel;
  parcelNumberType: CommonTypeModel;

  readonly PARCEL_TYPES = parcelTypes;
  readonly PARCEL_USAGE_TYPES = parcelUsageTypes;
  readonly PARCEL_NUMBER_TYPES = parcelNumberTypes;

  constructor(
    private parcelNumberPipe: ParcelNumberPipe
  ) {
    this.onParcelChange = this.onParcelChange.bind(this);
  }

  ngOnInit() {
    this.parcelNumber = this.parcelNumberPipe.transform(this.parcel, 'simple');
  }

  onParcelChange() {
    const parcelByNumber = this.parcelNumberPipe.parse(this.parcelNumber);

    if (parcelByNumber) {
      this.parcel.parcisKmen = parcelByNumber.parcisKmen;
      this.parcel.parcisPod = parcelByNumber.parcisPod;
      this.parcel.parcisDil = parcelByNumber.parcisDil;
    } else {
      this.parcel.parcisKmen = undefined;
      this.parcel.parcisPod = undefined;
      this.parcel.parcisDil = undefined;
    }

    if (this.parcelArea) {
      this.parcel.katuzeNazev = this.parcelArea.name;
      this.parcel.katuzeKod = this.parcelArea.id;
    }

    if (this.parcelType) {
      this.parcel.drupozKod = this.parcelType.id;
      this.parcel.drupozNazev = this.parcelType.name;
    } else {
      this.parcel.drupozKod = undefined;
      this.parcel.drupozNazev = undefined;
    }

    if (this.usageType) {
      this.parcel.zpusobVyuziti = this.usageType.name;
    } else {
      this.parcel.zpusobVyuziti = undefined;
    }

    if (this.parcelNumberType) {
      this.parcel.cislKod = this.parcelNumberType.id;
      this.parcel.cislNazev = this.parcelNumberType.name;
    } else {
      this.parcel.cislKod = undefined;
      this.parcel.cislNazev = undefined;
    }
  }
}
