<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam geometrických plánů</a>
  </div>

  <h1>Nový geometrický plán</h1>

  <div class="row mt-30">
    <div class="col-12 offset-lg-3 col-lg-6">
      <fieldset>
        <legend>Základní údaje</legend>
        <!-- cislo GP -->
        <gmt-input label="Číslo GP" [required]="true" class="mt-20">
          <input type="text" [(ngModel)]="geometricPlanRequest.name"/>
        </gmt-input>

        <!-- ucel GP -->
        <gmt-input label="Účel" [required]="true" class="mt-20">
          <gmt-select
            [optionItems]="typeOptions"
            [data]="this"
            field="type"
            required="true"
          ></gmt-select>
        </gmt-input>

        <!-- katastralni uzemi -->
        <gmt-input label="Katastrální území" [required]="true" class="mt-20">
          <gmt-select
            resource="areas"
            [filter]="{ sortOrder: { sortBy: 'name' }}"
            [data]="this"
            field="area"
            required="false"
            (changed)="onCadastreChanged()"
          ></gmt-select>
        </gmt-input>

        <!-- LV -->
        <gmt-input label="LV" class="mt-20">
          <gmt-select
            resource="titles"
            [globalFilterConfig]="titleFilter"
            [data]="this"
            field="titles"
            required="false"
            [multiselect]="true"
            [itemPipe]="titleNamePipe"
            (changed)="onTitleChanged()"
          ></gmt-select>
        </gmt-input>

        <!-- stavebni objekty -->
        <gmt-input label="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}" class="mt-20">
          <gmt-select
            resource="construction-objects"
            [globalFilterConfig]="constructionObjectFilter"
            [filter]="{ sortOrder: { sortBy: 'socis' }}"
            [data]="geometricPlanRequest"
            field="constructionObjects"
            [multiselect]="true"
            required="false"
            [itemPipe]="constructionObjectNamePipe"
          ></gmt-select>
        </gmt-input>

        <!-- parcely -->
        <gmt-input label="Parcela" [required]="true" class="mt-20">
          <gmt-select
            resource="parcels"
            [globalFilterConfig]="parcelFilter"
            [data]="geometricPlanRequest"
            field="parcels"
            [multiselect]="true"
            required="false"
            [itemPipe]="parcelNumberPipe"
            (changed)="onParcelsChanged()"
          ></gmt-select>
        </gmt-input>
      </fieldset>

      <!-- predbezny souhlas stavebniho uradu -->
      <fieldset *ngIf="type.id === typeEnum.PARCEL_SPLIT">
        <legend>Předběžné schválení dělení pozemků od SÚ</legend>
        <gmt-switch
          [options]="approveOptions"
          [selected]="parcelSplitPreApproveByOwner"
          (select)="parcelSplitPreApproveByOwner = $event"
        ></gmt-switch>
        <div class="column-100 mt-10">
          <label class="">Poznámka</label>
          <textarea [(ngModel)]="parcelSplitPreApproveByOwnerNote"></textarea>
        </div>
      </fieldset>

      <!-- predbezny souhlas vlastnika -->
      <fieldset *ngIf="type.id === typeEnum.PARCEL_SPLIT">
        <legend>Předběžný souhlas s dělením pozemků od vlastníka</legend>
        <gmt-switch
          [options]="approveOptions"
          [selected]="parcelSplitPreApproveByBuildingAuthority"
          (select)="parcelSplitPreApproveByBuildingAuthority = $event"
        ></gmt-switch>
        <div class="column-100 mt-10">
          <label class="">Poznámka</label>
          <textarea [(ngModel)]="parcelSplitPreApproveByBuildingAuthorityNote"></textarea>
        </div>
      </fieldset>

      <div *ngIf="similarGeometricPlanRequestExists" class="mt-20 text-center bordered error-message p-20">
        Existuje GP, který obsahuje jednu nebo více vybraných parcel.
      </div>

      <div class="mt-20 text-center">
        <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">Uložit</gmt-button>
      </div>
    </div>
  </div>
</div>
