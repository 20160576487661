import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { AdministratorEditComponent } from '@app/ps/components/administrator-edit/administrator-edit.component';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'administrator-list',
  templateUrl: './administrator-list.component.html',
})
export class AdministratorListComponent implements OnInit {

  list: ListModel<ProjectSubjectModel>;
  pageableList: PageableList;

  private defaultFilter = {
    filters: {
      searchText: { values: [{ id: '' }]},
    }
  };
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
  ) {
    this.onCreate = this.onCreate.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'administrators',
      this.defaultFilter,
      'administratorsFilter'
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onCreate() {
    const dialog = this.dialogService.open(AdministratorEditComponent, { data: {} });
    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        this.pageableList.fetchResults();
      }
      sub.unsubscribe();
    });
  }
}
