import { Customer } from './customer';

// @TODO - move to ../common/models...
export class User {
  id?: number;
  idText?: string;
  titleBefore?: string;
  name?: string;
  surname?: string;
  degreeAfter?: string;
  company?: Customer;
  username?: string;
  roles?: any[];
  superuser?: boolean;
  applicationProjects?: any;
  fullName?: string;
  email?: string;
  phone?: string;
  password?: string;
  passwordCheck?: string;
  employeePersonalNumber?: string;
  applications?: any[];
  ssoRoles?: any[];
  ssoCompanyName?: string;
  departmentName?: string;
  departmentId?: string;
  lastLogin?: string;
}
