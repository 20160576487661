import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';
import { StateService } from '@uirouter/angular';
import { OccupationGroupsType, ParcelService } from '@app/ps/services/parcel.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { AuthService } from '@app/common/services/auth.service';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe';
import { User } from '@app/models/user';
import { TabModel } from '@app/common/models/tab.model';
import { SelectItem } from '@app/common/components/select/select.component';
import { TableUtils } from '@app/common/utils/table.utils';
import { ListModel } from '@app/common/models/list.model';
import { NotificationModel } from '@app/ps/models/notification.model';
import { NoteModel } from '@app/common/models/note.model';
import { DocumentModel } from '@app/common/models/document.model';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { EasementModel } from '@app/common/models/easement.model';
import { KnGpModel } from '@app/common/models/kn-gp.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import { DialogService } from '@app/common/services/dialog.service';
import { DialogConfigData } from '@app/common/models/dialog-config';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';

@Component({
  selector: 'construction-object-detail',
  templateUrl: './construction-object-detail.component.html',
  styleUrls: []
})
export class ConstructionObjectDetailComponent implements OnInit {

  private static readonly OCCUPATION_PERMANENT_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_title', sortable: true },
    { id: 'occupation_parcel', sortable: true },
    { id: 'occupation_area', sortable: true },
    { id: 'occupation_phase', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_cases_parcel', sortable: true },
    { id: 'current_title', sortable: false },
    { id: 'current_parcel', sortable: false },
    { id: 'current_protection', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_title', sortable: true },
    { id: 'occupation_parcel', sortable: true },
    { id: 'area', sortable: true },
    { id: 'occupation_phase', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_cases', sortable: true },
    { id: 'current_title', sortable: false },
    { id: 'current_parcel', sortable: false },
    { id: 'current_protection', sortable: false },
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: ColumnDefinition[] =
    ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly GEOMETRIC_PLAN_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel', sortable: true },
    { id: 'title', sortable: true },
    { id: 'area', sortable: true },
    { id: 'length', sortable: true },
    { id: 'cases', sortable: true },
    { id: 'vb_tz', sortable: false },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_price_parcel', sortable: false },
    { id: 'occupation_price_vegetation', sortable: false },
    { id: 'occupation_price_construction', sortable: false },
    { id: 'occupation_price_summary', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_assessment_price', sortable: false },
    { id: 'occupation_price_land_price', sortable: false },
    { id: 'occupation_price_rent_price_year', sortable: false },
    { id: 'occupation_price_rent_length', sortable: false },
    { id: 'occupation_price_total_rental_price', sortable: false },
  ];

  private static readonly PERMANENT_WITH_SOLUTION_TYPE_COLUMNS: ColumnDefinition[] =
    [...ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS, ...ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS];

  private static readonly OCCUPATION_OVER_YEAR_PRICE_COLUMNS: ColumnDefinition[] =
    ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  private static readonly GEOMETRIC_PLAN_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'construction_objects_by_shared_easement', sortable: true }
  ];

  private static readonly PARCELS_TAB = {
    name: 'Parcely',
    id: 'parcels',
    href: `symap.project.constructionObjects.detail`,
    urlParams: { tab: 'parcels' }
  };

  private static readonly GEOMETRIC_PLANS_TAB = {
    name: 'Služebnosti',
    title: 'Počet GP/počet parcel',
    id: 'easements',
    href: `symap.project.constructionObjects.detail`,
    urlParams: { tab: 'easements' }
  };

  private static readonly TABS_ORDER = [ 'easements', 'parcels', 'notes', 'history', 'notifications', 'documents' ];

  tabs: TabModel[] = [
    {
      name: 'Poznámky',
      id: 'notes',
      href: `symap.project.constructionObjects.detail`,
      urlParams: { tab: 'notes' }
    },
    {
      name: 'Historie',
      id: 'history',
      href: `symap.project.constructionObjects.detail`,
      urlParams: { tab: 'history' }
    },
    {
      name: 'Upozornění',
      id: 'notifications',
      href: `symap.project.constructionObjects.detail`,
      urlParams: { tab: 'notifications' }
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: `symap.project.constructionObjects.detail`,
      urlParams: { tab: 'documents' }
    },
  ];

  @Input() constructionObjectId: number;
  tab: string;
  constructionObject: ConstructionObjectModel;
  loading = true;
  isConstructionObjectGeometry = this.authService.getActualProject().isConstructionObjectGeometry;
  showPrice = !this.authService.getActualProject().easementPriceByShare;
  user: User = this.authService.getUser();
  statuses: SelectItem[];
  updatingStatus = false;
  geometricPlans: KnGpModel[] = [];
  geometricPlansLoading = true;
  geometricPlansCount: number;
  allEasements = [];
  noteList: ListModel<NoteModel>;
  documentList: ListModel<DocumentModel>;
  historyList: ListModel<NoteModel>;
  notificationList: ListModel<NotificationModel>;
  occupationList: ListModel<OccupationModel>;
  notificationCounts = { archived: undefined, unarchived: undefined };
  occupationGroups: OccupationGroupsType;
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  permanentWithSolutionTypeColumns: ColumnDefinition[];
  geometricPlanColumns: ColumnDefinition[];
  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;
  rootAdministrator: any;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private wordTranslatePipe: WordTranslatePipe,
    private dialogService: DialogService,
    private listService: ListService,
    private stateService: StateService,
    private parcelService: ParcelService,
    private settingsService: SettingsService,
    private authService: AuthService,
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'occupations');
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onCreateCase = this.onCreateCase.bind(this);
    this.onUpdateConstructionObjectStatus = this.onUpdateConstructionObjectStatus.bind(this);
  }

  async ngOnInit() {
    await this.loadConstructionObject();
    await this.loadOccupations();
    await this.loadGeometricPlans();

    this.onUpdateGeometricPlansList();

    await this.settingsService.getSettingsLoader();

    if (!this.hideColumnList('state')) {
      this.loadStatuses();
    }
    this.loadNotes();
    this.loadDocuments();
    this.loadHistory();
    this.loadNotifications();

    this.resolveTabs();
    this.prepareParcelColumns();
    this.loading = false;
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  onCreateCase() {
    if (!this.constructionObject.administratorType) {
      const data = <DialogConfigData> {
        msg: `
          Před založením případů prosím zadejte správce
          ${this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_GENITIV')},
          v základních údajích o ${this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_LOCAL')}.
        `,
      };
      this.dialogService.open(AlertComponent, {
        data,
        className: ClassName.ADJUSTED_DIALOG,
      });
    } else {
      this.stateService.go('symap.project.constructionObjects.detail.createCase');
    }
  }

  onUpdateConstructionObjectStatus() {
    return this.restangular.one('construction-objects', this.constructionObjectId)
      .customPUT(this.constructionObject)
      .toPromise()
      .then(data => {
        delete data.actingPersons;
        delete data.administrator;
        _.assign(this.constructionObject, data.plain());
        this.updatingStatus = false;
      });
  }

  onUpdateGeometricPlansList() {
    const tab = ConstructionObjectDetailComponent.GEOMETRIC_PLANS_TAB;
    const count = this.constructionObject.countEasements;
    tab.name = `Služebnosti (${this.geometricPlansCount}/${this.constructionObject.countEasements})`;
    const tabIndex = this.tabs.indexOf(tab);
    if (count === 0 && tabIndex > -1) {
      this.tabs.splice(this.tabs.indexOf(tab), 1);
    } else if (count > 0 && tabIndex === -1) {
      this.tabs.push(tab);
    }
  }

  onUpdateParcelsList() {
    const tab = ConstructionObjectDetailComponent.PARCELS_TAB;
    const occupationsParIDs = this.occupationList && this.occupationList.list
      ? this.occupationList.list.map((occupation: OccupationModel) => occupation.parcel.idpar)
      : [];
    const parcelsIDs = [...new Set([...occupationsParIDs])];
    const parcelsCount = parcelsIDs.length;
    tab.name = `Parcely (${parcelsCount})`;
    const tabIndex = this.tabs.indexOf(tab);
    if (parcelsCount === 0 && tabIndex > -1) {
      this.tabs.splice(this.tabs.indexOf(tab), 1);
    } else if (parcelsCount > 0 && tabIndex === -1) {
      this.tabs.push(tab);
    }
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onUpdateDocumentList() {
    const attachmentsTab = this.tabs.find((tab) => tab.id === 'documents');
    attachmentsTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateNotificationList() {
    const filter = {
      limit: 1,
      filters: {
        constructionObjectId: this.constructionObject.id,
        archive: false
      }
    };

    const archivedFilter = {...filter, filters: {...filter.filters, archive: true}};
    const notificationList = this.listService.createList('notifications', filter);
    const notificationListArchived = this.listService.createList('notifications', archivedFilter);

    this.listService.fetchResult(notificationList).then(data => {
      this.notificationCounts.unarchived = data.itemCount;
      this.updateNotificationTab();
    });

    this.listService.fetchResult(notificationListArchived).then(data => {
      this.notificationCounts.archived = data.itemCount;
      this.updateNotificationTab();
    });
  }

  canEdit() {
    return this.authService.isAuthorized() || this.authService.hasPermission("assignable");
  }

  private loadConstructionObject(): Promise<any> {
    return this.restangular.one('construction-objects', this.constructionObjectId)
      .get({ loadCollections: [ 'actingPersons', 'administrator' ]})
      .toPromise()
      .then(data => {
        this.constructionObject = data.plain();
      });
  }

  private loadGeometricPlans(): Promise<any> {
    return this.restangular.all('geometric-plans')
      .customPOST({
        filter: {
          filters: { constructionObjectId: [ this.constructionObjectId ]},
          sortOrder: [ { sortBy: 'kutx' }]
        }
      })
      .toPromise()
      .then((data) => {
        this.geometricPlans = data.plain();
        this.geometricPlansLoading = false;
        this.geometricPlansCount = 0;

        this.geometricPlans.forEach((geometricPlan) => {
            // init
            geometricPlan.totalVymera = 0;
            geometricPlan.totalDelka = 0;
            geometricPlan.totalPrice = 0;

            this.geometricPlansCount += 1;

            geometricPlan.listener = (labelState) => {
              if (labelState === false && !geometricPlan.easementList) {
                delete geometricPlan.listener; // run only once
                // load GP data
                geometricPlan.easementList = this.listService.createList(
                  'easements',
                  {
                    filters: { validOrWithCase: true, gpId: geometricPlan.id, constructionObjectId: this.constructionObjectId, loadCollections: [
                      'constructionObjects',
                      'parcel.buildings',
                    ]},
                    limit: undefined
                  }
                );
                this.listService.fetchResult(geometricPlan.easementList).then(() => {
                  geometricPlan.easementList.list.forEach(easement => {
                    ConstructionObjectDetailComponent.ammendGPInfo(easement, geometricPlan, ConstructionObjectDetailComponent.determinetGlobalPriceType(easement));
                  });
                  this.allEasements = this.allEasements.concat(geometricPlan.easementList.list);
                  geometricPlan.totalVymera = ConstructionObjectDetailComponent.getGeometricPlanTotalArea(geometricPlan.easementList.list);
                  geometricPlan.totalDelka = ConstructionObjectDetailComponent.getGeometricPlanTotalLength(geometricPlan.easementList.list);
                  geometricPlan.totalPrice = ConstructionObjectDetailComponent.getGeometricPlanTotalPrice(geometricPlan.easementList.list);
                });
              }
            };
          }
        );
      });
  }

  public hideColumnList(columnID: string): boolean {
    const tableID = 'constructionObjects';
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }

  private hideColumn(columnID: string): boolean {
    const tableID = 'constructionObjectDetail';
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [...ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_COLUMNS];
    let occupationUnderOneYearColumns = [...ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS];
    let occupationOverOneYearColumns = [...ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS];
    let permanentWithSolutionTypeColumns = [ ...ConstructionObjectDetailComponent.PERMANENT_WITH_SOLUTION_TYPE_COLUMNS ];
    let geometricPlanColumns = [...ConstructionObjectDetailComponent.GEOMETRIC_PLAN_COLUMNS];

    if (this.showPrice) {
      if (!this.hideColumn('permanentOccupationPriceColumns')) {
        occupationPermanentColumns = occupationPermanentColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
      }
      if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
        occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
      }
      if (!this.hideColumn('temporaryOverYearPriceColumns')) {
        occupationOverOneYearColumns = occupationOverOneYearColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
      }
      if (!this.hideColumn('constructionObjectsBySharedEasement')) {
        geometricPlanColumns = geometricPlanColumns.concat(ConstructionObjectDetailComponent.GEOMETRIC_PLAN_PRICE_COLUMNS);
      }

      if (this.constructionObject.easementGlobalPriceType === 'B' && this.constructionObject.easementGlobalOtherUnitType === 'S') {
        geometricPlanColumns.push({ id: 'price_edit_construction_object', sortable: true });
      } else if (this.constructionObject.easementGlobalPriceType !== 'I') {
        geometricPlanColumns.push({ id: 'price', sortable: true });
      } else if (this.constructionObject.easementGlobalPriceType === 'I') {
        geometricPlanColumns.push({ id: 'price_edit_construction_object', sortable: true });
      }
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.permanentWithSolutionTypeColumns = TableUtils.repairColumnsStructure(permanentWithSolutionTypeColumns);
    this.geometricPlanColumns = TableUtils.repairColumnsStructure(geometricPlanColumns);
  }

  private loadStatuses() {
    const statusesList = this.listService.createList('construction-object-statuses', { limit: null });
    this.listService.fetchResult(statusesList).then(() => { this.statuses = statusesList.list; });
  }

  public loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { constructionObjectId: this.constructionObject.id, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  public loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { constructionObjectId: this.constructionObject.id, systemType: true },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadOccupations(): Promise<any> {
    this.occupationList = this.listService.createList(
      'occupations',
      {
        limit: undefined,
        filters: {
          constructionObjectId: this.constructionObjectId,
          validity: [ 'valid' ],
          loadCollections: [
            'parcel.parcelProtections',
            'parcel.knBudouc.currentParcels',
            'parcel.knBudouc.currentParcels.bpejList',
            'parcel.knBudouc.currentParcels.parcelProtections',
            'parcel.buildings',
            'rentalExpert',
          ]
        },
        sortOrder: [ { sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' } ]
      }
    );

    return this.listService.fetchResult(this.occupationList).then((data: ListModel<OccupationModel>) => {
      this.occupationGroups = this.parcelService.groupOccupationsByParcel(data.list, []);
      this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.underOneYear);
      this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.overOneYear);
      this.onUpdateParcelsList();
    });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { constructionObjectId: this.constructionObject.id, cancelled: false },
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}}
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private loadNotifications() {
    this.notificationList = this.listService.createList(
      'notifications',
      {
        filters: {
          constructionObjectId: this.constructionObject.id,
          loadCollections: ['knBudouc.futureParcel'],
          archive: false,
        },
        sortOrder: { sortBy: 'createdTimestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.notificationList).then(() => {
      this.onUpdateNotificationList();
    });
  }

  private updateNotificationTab() {
    const notificationTab = this.tabs.find((tab) => tab.id === 'notifications');
    if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
      notificationTab.name = `Upozornění (${this.notificationCounts.unarchived}/${this.notificationCounts.archived})`;
    } else {
      notificationTab.name = 'Upozornění';
    }
  }

  private resolveTabs() {
    // if no occupations go to easements or notes
    if (this.occupationList.itemCount === 0 && this.tab === 'parcels') {
      if (this.constructionObject.countEasements > 0) {
        this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
      } else {
        this.stateService.go('.', { tab: 'notes' }, { location: 'replace' });
      }
    }

    // redirect if path is active
    if (this.constructionObject.countEasements === 0 && this.tab === 'easements') {
      this.stateService.go('.', { tab: 'notes' }, { location: 'replace' });
    }

    this.tabs.sort((a, b) => {
      return ConstructionObjectDetailComponent.TABS_ORDER.indexOf(a.id) - ConstructionObjectDetailComponent.TABS_ORDER.indexOf(b.id);
    });
  }

  private static getGeometricPlanTotalPrice(easements: EasementModel[]) {
    return easements.reduce((sum, e) => {
      return e.easementPrice ? sum + e.easementPrice : sum;
    }, 0);
  }

  private static getGeometricPlanTotalLength(easements: EasementModel[]) {
    return easements.reduce((sum, e) => {
      return e.delka ? sum + e.delka : sum;
    }, 0);
  }

  private static getGeometricPlanTotalArea(easements: EasementModel[]) {
    return easements.reduce((sum, e) => {
      return e.vymera ? sum + e.vymera : sum;
    }, 0);
  }

  private static ammendGPInfo(easement: EasementModel, geometricPlan: KnGpModel, easementGlobalPriceType: string) {
    easement.geometricPlan = geometricPlan;
    easement.easementGlobalPriceType = easementGlobalPriceType;
  }

  private static determinetGlobalPriceType(easement: EasementModel) {
    const easementGlobalPriceType = new Set(easement.constructionObjects.map(co => co.easementGlobalPriceType)).size == 1 ?
      easement.constructionObjects[0].easementGlobalPriceType : null;
    const easementGlobalOtherUnitType = new Set(easement.constructionObjects.map(co => co.easementGlobalOtherUnitType)).size == 1 ?
      easement.constructionObjects[0].easementGlobalOtherUnitType : null;

    return easementGlobalPriceType === 'I' ||
    (
      easementGlobalPriceType === 'B' &&
      easementGlobalOtherUnitType === 'S' &&
      easement.priceType &&
      (easement.existsCaseBlockingPrice || easement.acceleratedCaseStatus === 'DeclinedOwner')
    ) ? 'I' : easementGlobalPriceType;
  }
}
