<div>
  <div class="column-100">
    <label>Způsob zadání ceny</label>
    <div class="cb">{{ easementGlobalPriceType === "B" && data.easement.easementPrice !== undefined ? 'Hromadně' : (easementGlobalPriceType === "I" && data.easement.easementPrice !== undefined ? 'Individuálně' : '') }}</div>
  </div>
  <div *ngIf="easementGlobalPriceType === 'B' && data.easement.easementPrice !== undefined">
    <div class="column-100">
      <label>Původ ceny</label>
      <div class="cb">{{ data.easement.constructionObjects[0].easementGlobalOtherSource }}</div>
    </div>
    <div class="column-100">
      <label>Způsob výpočtu ceny</label>
      <div class="cb">{{data.easement.constructionObjects[0].easementGlobalOtherUnitType === "A" ? 'Plocha' : (data.easement.constructionObjects[0].easementGlobalOtherUnitType === "S" ? 'Jednorázová cena' : 'Délka')}}</div>
    </div>
    <div class="column-100">
      <label>Cena VB [<span class="unit">Kč<span *ngIf="data.easement.constructionObjects[0].easementGlobalOtherUnitType !== 'S'">/m<sup *ngIf="data.easement.constructionObjects[0].easementGlobalOtherUnitType === 'A'">2</sup></span></span>]</label>
      <div class="cb">{{(data.easement.constructionObjects[0].easementGlobalOtherUnitType === "A" ? data.easement.constructionObjects[0].easementGlobalOtherAreaPrice : (data.easement.constructionObjects[0].easementGlobalOtherUnitType === "S" ? data.easement.constructionObjects[0].easementGlobalOtherSinglePrice : data.easement.constructionObjects[0].easementGlobalOtherLengthPrice)) | number}}</div>
    </div>
  </div>
  <div *ngIf="easementGlobalPriceType === 'I' && data.easement.easementPrice  !== undefined">
    <div class="column-100">
      <label>Způsob určení ceny</label>
      <div class="cb">{{data.easement.priceType === "E" ? 'Znalecký posudek' : (data.easement.priceType === "O" ? 'Jiný způsob' : (data.easement.priceType === "S" ? 'zákon 416/2009 Sb.' : ''))}}</div>
    </div>
    <div class="column-100" *ngIf="data.easement.priceType === 'E'">
      <label>Číslo znaleckého posudku</label>
      <div class="cb">{{data.easement.expertOpinionNumber}}</div>

      <label>Způsob výpočtu ceny</label>
      <div class="cb">{{data.easement.expertOpinionUnitType === "A" ? 'Plocha' : (data.easement.expertOpinionUnitType === "T" ? 'Jednorázová cena' : 'Délka')}}</div>

      <div *ngIf="data.easement.expertOpinionUnitType === 'L'">
        <label>Cena VB [<span class="unit">Kč/bm</span>]</label>
        <div class="cb">{{data.easement.expertOpinionLengthPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.expertOpinionUnitType === 'A'">
        <label>Cena VB [<span class="unit">Kč/m<sup>2</sup></span>]</label>
        <div class="cb">{{data.easement.expertOpinionAreaPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.expertOpinionUnitType === 'T'">
        <label>Cena VB [<span class="unit">Kč</span>]</label>
        <div class="cb">{{data.easement.expertOpinionTotalPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.priceMultiply">
        <label>{{ 'ps.easementCoefficient' | brandTranslate }}</label>
        <div class="cb">{{data.easement.priceCoefficient | number}}</div>
      </div>
      <label>Odhadce</label>
      <div class="cb">
        {{data.easement.expertOpinionExpert | subjectName}}
      </div>
    </div>

    <div class="column-100" *ngIf="data.easement.priceType === 'O'">
      <label>Původ ceny</label>
      <div class="cb">{{data.easement.otherSource}}</div>

      <div *ngIf="data.easement.otherUnitType === 'A' || data.easement.otherUnitType === 'L'">
        <label>Způsob výpočtu ceny</label>
        <div class="cb">{{data.easement.otherUnitType === "A" ? 'Plocha' : 'Délka'}}</div>
      </div>
      <div *ngIf="data.easement.otherUnitType === 'L'">
        <label>Cena VB [<span class="unit">Kč/bm</span>]</label>
        <div class="cb">{{data.easement.otherLengthPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.otherUnitType === 'A'">
        <label>Cena VB [<span class="unit">Kč/m<sup>2</sup></span>]</label>
        <div class="cb">{{data.easement.otherAreaPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.otherUnitType === 'T' || data.easement.otherUnitType === 'S'">
        <label>Cena VB [<span class="unit">Kč</span>]</label>
        <div class="cb">{{data.easement.otherTotalPrice | number}}</div>
      </div>
      <div *ngIf="data.easement.priceMultiply">
        <label>{{ 'ps.easementCoefficient' | brandTranslate }}</label>
        <div class="cb">{{data.easement.priceCoefficient | number}}</div>
      </div>
    </div>

    <div class="column-100" *ngIf="data.easement.priceType === 'S'">
      <label>Původ ceny</label>
      <div class="cb">{{data.easement.otherSource}}</div>

      <div>
        <label>Cena VB [<span class="unit">Kč</span>]</label>
        <div class="cb">{{data.easement.otherTotalPrice | number}}</div>
      </div>
    </div>
  </div>
</div>
