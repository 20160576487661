import { Component, Inject, OnInit } from '@angular/core';
import { UIRouterGlobals, Transition, TargetState, StateService} from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { MenuConfig } from '@app/models/menuConfig';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

@Component({
  templateUrl: './main.component.html',
})

export class MainComponent implements OnInit {
  loading = true;
  collapsed = false;
  user: User;
  servicedeskUrl: string;
  menuConfig: MenuConfig[] = [];
  ENV = this.config.ENV;

  constructor(
    private authService: AuthService,
    private uiRouterGlobals: UIRouterGlobals,
    @Inject('$transition$') private transition: Transition,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(APP_CONFIG) private config,
    @Inject(APP_BRAND) public APP_BRAND,
    @Inject(APPLICATIONS) private applications,
    private localStorageService: LocalStorageService,
    private stateService: StateService,
  ) {
  }

  async ngOnInit() {
    await this.authService.checkProjectStatus(this.transition, this.applications.sv.name, null, this.authService.loadSvProjects, 'error')
      .then(
        () => {
          this.loading = false;
        }, (newState: TargetState) => {
          this.stateService.go(newState.name(), newState.params(), newState.options());
        }
      );

    this.authService.setActiveApplication(this.applications.sv.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('sv');

    this.collapsed = this.localStorageService.get('menuOpened');

    this.user = this.authService.getUser();
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  onLogout() {
    this.authService.logout();
  }

  isActive(uiSref: string): boolean {
    return !!this.uiRouterGlobals.$current.includes[uiSref];
  }

  getMenuConfig(): MenuConfig[] {
    const menu = [
      new MenuConfig('Vzory', 'sv.samples', 'fa-home')
    ];

    if (this.authService.hasPermissionOnProject('template_new,template_aproval,template_manage')) {
      menu.push(new MenuConfig('Požadavky', 'sv.tickets', 'fa-folder-open'));
    }

    if (this.authService.hasPermissionOnProject('template_manage')) {
      menu.push(new MenuConfig('Nastavení', 'sv.settings', 'fa-wrench'));
    }

    return menu;
  }
}
