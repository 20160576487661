<div class="detail">
  <div [loading]="loading">
    <div *ngIf="!loading" class="row">
      <div class="col-8">
        <h2 class="text-uppercase mb-20">Poslední navštívené akce</h2>
        <ul>
          <li *ngFor="let project of projects" class="mb-10">
            <a uiSref="symap.project.home" [uiParams]="{ projectKey: project.key }" class="not-underline">{{ project | projectName }}</a>
          </li>
        </ul>
      </div>
      <div class="col-4">
        <h2 class="text-uppercase mb-20">Přístup k dalším aplikacím</h2>
        <div *ngIf="APP_BRAND.NAME === 'RSD'">
          <ul>
            <li class="mb-10"><a href="https://podpora-it.rsd.cz" target="_blank">HelpDesk</a></li>
            <li class="mb-10"><a href="https://rsdcz.sharepoint.com/sites/usek/SitePages/MPV.aspx" target="_blank">Intranet MPV</a></li>
            <li class="mb-10"><a href="https://epd.rsd.cz/" target="_blank">EPD</a></li>
            <li class="mb-10"><a href="https://pps.rsd.cz" target="_blank">PPS</a></li>
            <li class="mb-10"><a href="https://grv-termwebc.rsd.cz/RDWeb" target="_blank">JSIVV</a></li>
            <li class="mb-10"><a href="https://grv-termwebc.rsd.cz/RDWeb" target="_blank">HELIOS</a></li>
            <li class="mb-10"><a href="https://gisa.rsd.cz" target="_blank">GISA</a></li>
          </ul>
        </div>
        <div *ngIf="APP_BRAND.NAME === 'SZ'">
          <ul>
            <li class="mb-10"><a href="https://gmtech-cz.atlassian.net/servicedesk/customer/portal/61" target="_blank">Helpdesk MAJA SŽ</a></li>
            <li class="mb-10"><a href="https://cesta.spravazeleznic.cz" target="_blank">C.E.S.T.A</a></li>
            <li class="mb-10"><a href="https://famaplus.spravazeleznic.cz/client/main" target="_blank">FAMA+</a></li>
            <li class="mb-10"><a href="https://portal.spravazeleznic.cz/group/erms" target="_blank">ERMS</a></li>
            <li class="mb-10"><a href="https://ispdmapy.szdc.cz" target="_blank">ISPD mapy</a></li>
            <li class="mb-10"><a href="https://katastr.cuzk.cz/LoginDPWEB/login/Login.do" target="_blank">Dálkový přístup do KN</a></li>
            <li class="mb-10"><a href="https://nahlizenidokn.cuzk.cz/" target="_blank">Nahlížení do KN</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="!loading" class="mt-60">
      <articles application="SY" [canEdit]="canEditArticles" [published]="canEditArticles ? undefined : true"></articles>
    </div>

    <help [helpId]="helpIds.HOME" class="cm-help-page"></help>
  </div>
</div>
