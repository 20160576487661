import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

interface Metadata {
  frontendVersion: string;
  backendVersion: string;
  wikiUrl: string;
  cadastre: any;
}

@Component({
  selector: 'tooltip-system-metadata',
  templateUrl: './tooltip-system-metadata.component.html',
  styleUrls: ['./tooltip-system-metadata.component.scss']
})
export class TooltipSystemMetadataComponent implements OnInit {

  @Input() data: Metadata;
  application: string;
  applicationName: string;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.application = this.authService.getActiveApplication();
    this.applicationName = (this.application === this.APPLICATIONS.sy.name
      ? this.APP_BRAND.PS.TITLE
      : this.application
    );
  }
}
