<div id="top-bar" [ngClass]="{ test: ENV !== 'production' }">
  <div class="logo">
    <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault();" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
      <i [ngClass]="['fa', 'fa-bars']"></i>
    </a>
    <div class="image-container">
      <img src="./img/logo/settings_xl.svg" alt="logo"/>
    </div>
  </div>
  <login [user]="user" [servicedeskUrl]="servicedeskUrl" (logoutClick)="logout()"></login>
</div>

<div class="main">
  <app-menu [ngClass]="{ collapsed: collapsed }" [collapsed]="collapsed" class="flexbox-container" id="menu" [menuConfig]="menuConfig"></app-menu>
  <div id="content-wrap">
    <div id="content" ui-view="content"></div>
  </div>
</div>
