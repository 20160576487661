<div class="notification notification--{{ level }}">
  <div class="notification__icon">
    <i *ngIf="level === 'danger'" class="fa fa-lg fa-exclamation-triangle"></i>
    <i *ngIf="level === 'warning'" class="fa fa-lg fa-info-circle"></i>
  </div>
  <div class="notification__content">
    <ng-content></ng-content>
  </div>
</div>

