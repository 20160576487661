import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { SimpleCaseStatusConfigModel } from '@app/ps/models/simple-case-status-config.model';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { SimpleCaseStatusFormFieldModel } from '@app/ps/models/simple-case-status-form-field.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'simple-case-status',
  templateUrl: './simple-case-status.component.html',
  styleUrls: ['./simple-case-status.component.scss']
})
export class SimpleCaseStatusComponent implements OnInit {

  @Input() caseId: number;
  @Input() config: SimpleCaseStatusConfigModel;
  @Input() doneRedirect = true;
  @Output() done = new EventEmitter();
  @Output() cancel = new EventEmitter();

  loading = true;
  data: CaseModel;
  name: string;
  isValid: Function;

  constructor(
    private stateService: StateService,
    private caseService: CaseService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService
  ) {
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    this.name = (await this.caseStatusService.loadStatusName(this.config.name)).name;
    this.data = await this.restangular.one('cases', this.caseId).get(this.config.recipient ? {loadCollections: ['caseSubjects', 'caseOwnerships']} : undefined).toPromise();
    if (this.config.recipient) {
      const dataCaseOwnerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
      let recipients = this.caseService.getRecipients(dataCaseOwnerships).filter(r => !this.config.statusDateFieldName || !r[this.config.statusDateFieldName]);
      let recipient;
      if (this.config.recipientFilter && recipients.some(this.config.recipientFilter)) {
        recipients = recipients.filter(this.config.recipientFilter);
      } else {
        // pro stavy obesílání vlastníka, kdy by nemělo být třeba vybírat adresáta
        recipient = recipients.find(
            (value) => {
                return !value.signedDate && !value.acceptedGeometricalPlanPossibleDate && (value.declinedDate || value.undeliveredDate || value.sentDate || value.personalVisitDate || value.receivedDate);
            }
        );
      }
      this.config = Object.assign({}, this.config);
      if (!recipient && recipients.length==1) {
        recipient = recipients[0];
      }
      if (!recipient) {
        this.config.formFields = [{
          name: 'Adresát',
          field: 'recipientOpsubId',
          options: recipients,
          required: true,
          type: 'select',
        } as SimpleCaseStatusFormFieldModel].concat(this.config.formFields);
      } else {
        this.data['recipientOpsubId'] = recipient;
        this.config.formFields = [{
          value: recipient.name,
          field: 'recipientOpsubId',
          name: 'Adresát',
        } as SimpleCaseStatusFormFieldModel].concat(this.config.formFields);
      }

    }
    if (this.config.hasNote) {
      this.data.note = {};
    }
    this.loading = false;
  }

  formCallbackRegister(event: any) {
    this.isValid = () => event.isValid() && (!this.config.hasNote || this.data.note.text) && (!this.config.hasNoteType || this.data.note.noteType);
  }

  onCancel() {
    this.cancel.emit();
  }

  async onSubmit(): Promise<any> {
    const sendData = {};

    for (const formField of this.config.formFields) {
      if (!formField.field) {
        continue;
      }
      if (formField.field == 'recipientOpsubId' && this.data[formField.field]) {
        sendData[formField.field] = this.data[formField.field].opsubIds || [this.data[formField.field].id];
      } else {
        sendData[formField.field] = this.data[formField.field];
      }
    }

    if (this.config.hasNote) {
      sendData['note'] = this.data.note;
    }

    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, this.config.saveEndpoint)
      .toPromise();

    if (this.doneRedirect) {
      this.stateService.go('^');
    } else {
      this.done.emit();
    }
  }
}
