﻿import { Component, Inject, Input } from "@angular/core";
import { ListService } from '@app/common/services/list.service';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './titles-mandatary-change-confirm.component.html',
})
export class TitlesMandataryChangeConfirmComponent {
  @Input()
  data: any;

  constructor(
    private listService: ListService,
    private restangular: Restangular,
  ) {
    this.proceed = this.proceed.bind(this);
  }

  async proceed() {
    const data = {
      filters: Object.assign(
        this.listService.toFilterRequestMessage(transformListToFilter({filters: this.data.selectEntities.filters})).filters,
        {
          entityId: {
            values: this.data.selectEntities.checkedItems.map(entity => entity.id),
            negation: this.data.selectEntities.inverseSelection,
          }
        }
      ),
      user: this.data.details.user,
    };
    await this.restangular.all('rights')
       .customPUT(data, 'bulk-update')
      .toPromise();
  }
}
