<fieldset class="bordered">
  <legend>Aktualizace a doplnění listů vlastnictví</legend>

  <div class="column-100">
    <div class="input">
      <gmt-switch [options]="requestTypes" [(selectedId)]="requestType" (select)="onSwitchType()"></gmt-switch>
    </div>
  </div>

  <div class="column-100">
    <gmt-input label="Katastrální území" [required]="true">
      <gmt-select
        resource="areas"
        [filter]="{ sortOrder: { sortBy: 'name' }}"
        [data]="this"
        field="area"
        required="false"
        (changed)="onCadastreChanged()"
      ></gmt-select>
    </gmt-input>
  </div>

  <ng-container *ngIf="requestType === 'GP'">
    <div class="column-100">
      <gmt-input label="GP" [required]="true">
        <gmt-select
          resource="geometric-plan-requests"
          [globalFilterConfig]="geometricPlanRequestFilter"
          [data]="this"
          field="geometricPlanRequest"
          required="true"
          (changed)="onGpChange($event.newValue)"
        ></gmt-select>
      </gmt-input>
    </div>

    <div *ngIf="geometricPlanRequest" class="column-100">
      <gmt-input label="Výběr z LV geometrickho plánu" [required]="true">
        <gmt-select
          [optionItems]="gpTitleOptions"
          [data]="this"
          field="gpTitles"
          [multiselect]="true"
          required="true"
        ></gmt-select>
      </gmt-input>
    </div>
  </ng-container>

  <ng-container *ngIf="requestType === 'LV'">
    <div class="column-100">
      <gmt-input label="Výběr ze stávajících LV" [required]="newTitles === ''">
        <gmt-select
          resource="titles"
          [globalFilterConfig]="titleFilter"
          [data]="this"
          field="titles"
          required="false"
          [multiselect]="true"
          [itemPipe]="titleNamePipe"
        ></gmt-select>
      </gmt-input>
    </div>

    <div class="column-100">
      <gmt-input label="Zavedení nových LV" [required]="!titles.length">
        <input type="text" [(ngModel)]="newTitles"/>
      </gmt-input>
    </div>
  </ng-container>

  <div class="column-100">
    <gmt-input label="Číslo tiketu v HD" [required]="true">
      <input type="text" [(ngModel)]="ticketNumber"/>
    </gmt-input>
  </div>

  <div class="column-100">
    <gmt-input label="Žadatel" [required]="true">
      <gmt-select
        [restangularService]="restangularSettings"
        [data]="this"
        field="user"
        resource="users/list"
        [itemPipe]="userNamePipe"
      ></gmt-select>
    </gmt-input>
  </div>
</fieldset>

<gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isLvValid()">Provést</gmt-button>

<h2 class="mt-40">Seznam výsledků aktualizací</h2>

<div [loading]="list.loading">
  <div *ngIf="!list.loading">
    <div class="bordered" *ngIf="list.itemCount">
      <table class="table">
        <thead>
        <tr>
          <th class="cell">
            <sort-by [selected]="'createdDate' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('createdDate', $event)" [sortDir]="list.filter.sortOrder.direction">
              Datum
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'type' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('type', $event)" [sortDir]="list.filter.sortOrder.direction">
              Typ aktualizace
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'ticketNumber' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('ticketNumber', $event)" [sortDir]="list.filter.sortOrder.direction">
              HD ticket
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'userFullName' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('userFullName', $event)" [sortDir]="list.filter.sortOrder.direction">
              Žadatel
            </sort-by>
          </th>
          <th class="cell">
            <sort-by [selected]="'areaName' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('areaName', $event)" [sortDir]="list.filter.sortOrder.direction">
              Katastrální území
            </sort-by>
          </th>
          <th class="cell">
              LV
          </th>
          <th class="cell">
            Výsledek aktualizace
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let request of list.list">
          <td class="cell">
            {{ request.createdDate | date:'d.M.yyyy' }}
          </td>
          <td class="cell">
            {{ request.type }}
          </td>
          <td class="cell">
            {{ request.ticketNumber }}
          </td>
          <td class="cell">
            {{ request.userFullName }}
          </td>
          <td class="cell">
            {{ request.areaName }}
          </td>
          <td class="cell">
            <span *ngIf="request.lvs.length > 1" title="{{ formatTitles(request.lvs) }}">Více LV</span>
            <span *ngIf="request.lvs.length === 1">{{ request.lvs[0].lv }}</span>
          </td>
          <td class="cell">
            {{ translateStatus(request.status) }} <a *ngIf="request.statusDescription" (click)="showStatusDescription(request)"><i class="fa fa-info"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount">
      <span>Nenalezena žádná data</span>
    </div>
    <div class="flexbox-container mt-10" *ngIf="list.itemCount">
      <itemscounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" class="flex-start"></itemscounter>
      <pagescounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)" class="flex1 mt-5 text-center"></pagescounter>
      <div class="page-count-select">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="mt-10">
      <pagination *ngIf="list.itemCount" [shouldUpdateUrl]="false" [list]="list" (pageChanged)="pageChanged($event)" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
    </div>
  </div>
</div>
