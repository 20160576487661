import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectService } from '@app/ps/services/project.service';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { ChecklistModel } from '@app/common/models/checklist.model';

@Component({
  selector: 'project-create',
  templateUrl: './project-create.component.html',
})
export class ProjectCreateComponent implements OnInit {

  private static readonly PREFIX = 'sy_';

  data: any = { templateProjectSettings: undefined };
  projects = [];
  checklistSettings;
  selectedTemplateSettingsBasic = 'a';
  templateSettingsBasic: SwitchOption[] = [
    {
      id: 'a',
      value: 'Vše',
    },
    {
      id: 'c',
      value: 'Zvolit',
    }
  ];

  templateSettings = [
    { name: 'Základní', id: 'BASIC' },
    { name: 'Data katastru nemovitostí', id: 'CADASTRE_DATA' },
    { name: 'Investor', id: 'INVESTOR' },
    { name: 'Správci stavebních objektů', id: 'ADMINISTRATORS' },
    { name: 'Číselné řady', id: 'SERIES' },
    { name: 'Nastavení typů případů a jejich workflow', id: 'CASES' },
    { name: 'Stavy případů', id: 'CASE_STATUSES' },
    { name: 'Šablony a dokumenty šablon', id: 'TEMPLATES' },
    { name: 'Exporty', id: 'EXPORTS' },
    { name: 'Statistiky', id: 'STATISTICS' },
    { name: 'Sloupce', id:  'COLUMNS' },
    { name: 'Názvosloví', id: 'NOMENCLATURE' },
    { name: 'Mapové okno', id: 'MAP' },
  ];

  constructor(
    @Inject(RESTANGULAR_SYMAP) private restangular: any,
    private authService: AuthService,
    private projectService: ProjectService,
  ) {
    this.onChangeTemplateSettings = this.onChangeTemplateSettings.bind(this);
    this.onToggleSettings = this.onToggleSettings.bind(this);
    this.onTemplateProjectChanged = this.onTemplateProjectChanged.bind(this);
    this.removePrefix = this.removePrefix.bind(this);
    this.update = this.update.bind(this);
  }
  ngOnInit() {
    const projects = this.authService.getActualProjects();
    if (projects) {
      this.projects = projects.filter(p => p.key.match(/sy_/));
    }

    this.checklistSettings = new ChecklistModel();
  }

  onChangeTemplateSettings(type: string | number) {
    this.data.templateProjectSettings = type === 'c'
      ? this.checklistSettings.checkedItems
      : undefined;
  }

  onToggleSettings(settings) {
    this.checklistSettings.toggleSelection(settings);
  }

  onTemplateProjectChanged(project) {
    this.data.templateProjectKey = (project ? project.key : undefined);
  }

  isCheckedSettings(settings) {
    return this.checklistSettings.isChecked(settings);
  }

  isDisabled() {
    return !(this.data.name && this.data.key);
  }

  removePrefix() {
    if (this.data.key) {
      const idx = this.data.key.indexOf(ProjectCreateComponent.PREFIX);
      if (idx === -1 || idx !== 0) {
        return;
      }
      this.data.key = this.data.key.substring(idx + ProjectCreateComponent.PREFIX.length);
    }
  }

  update() {
    return this.restangular.all('projects')
      .post(this.data)
      .toPromise()
      .then((data) => {
        if (data) {
          return this.authService.loadSymapProjects(this.authService.getUser().id).then(() => {
            return this.projectService.changeProject(data);
          });
        }
      });
  }
}
