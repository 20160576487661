import { Component, Input, OnInit, Inject} from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { MapPropertyStatusService } from '@app/dimap/map/services/map-property-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'parcel-area',
  templateUrl: './parcel-area.component.html',
})
export class ParcelAreaComponent implements OnInit {

  @Input()
  private parcelId: number;
  public toKeep = 0;
  public toBuy = 0;
  public toSell = 0;
  public loading = true;

  public classToKeep;
  public classToBuy;
  public classToSell;
  public isDisplayed = true;

  constructor(
    private authService: AuthService,
    private mapPropertyStatusService: MapPropertyStatusService,
    private restangular: Restangular,
  ) {
  }

  ngOnInit() {
    this.classToKeep = this.mapPropertyStatusService.propertyStatus.ownedInside;
    this.classToBuy = this.mapPropertyStatusService.propertyStatus.othersInside;
    this.classToSell = this.mapPropertyStatusService.propertyStatus.ownedOutside;

    if (this.authService.hasPermission('property_status,manage_property_status')) {
      this.restangular.one('parcels', this.parcelId).all('areas')
              .customPOST()
              .toPromise()
              .then((data) => {
                if (data) {
                  this.toBuy = data.propertyareabuy;
                  this.toKeep = data.propertyareakeep;
                  this.toSell = data.propertyareasell;
                } else {
                  this.isDisplayed = false;
                }
                this.loading = false;
              });
    } else {
      this.isDisplayed = false;
      this.loading = false;
    }
  }
}
