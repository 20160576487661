export interface ConstructionObjectImportedModel {
  id?: number;
  createdDate?: string;
  name?: string;
  cislo?: string;
  usekEtapa?: number;
  description?: string;
  urad?: string;
  realizationDate?: string;
  kuList?: string[];
  administratorId?: number;
  administratorType?: string;
  series?: any[];
  validityDate?: string;
}

export const createConstructionObject = (): ConstructionObjectImportedModel => {
  return {
    name: '',
    cislo: '',
    description: '',
    urad: '',
    administratorType: 'I',
    series: []
  };
};
