import { Ng2StateDeclaration, StateParams, Transition } from '@uirouter/angular';

import { TermsOfUseComponent } from '@app/common/components/terms-of-use/terms-of-use.component';
import { LandingPkComponent } from '@app/settings/modules/components/landing-pk/landing-pk.component';
import { CadastreDataImportComponent } from '@app/settings/modules/components/cadastre-data-import/cadastre-data-import.component';
import { CadastreDataRequestComponent } from '@app/settings/modules/components/cadastre-data-request/cadastre-data-request.component';
import { CadastreDataReportComponent } from '@app/settings/modules/components/cadastre-data-report/cadastre-data-report.component';
import { SettingsService } from '@app/ps/services/settings.service';
import { WordService } from '@app/common/services/word.service';
import { LandingSyComponent } from '@app/settings/modules/components/landing-sy/landing-sy.component';
import { CadastreUpdateComponent } from '@app/settings/modules/components/cadastre-update/cadastre-update.component';
import { ChangelogListComponent } from '@app/settings/modules/components/changelog-list/changelog-list.component';
import { LandingVfzeComponent } from '@app/settings/modules/components/landing-vfze/landing-vfze.component';
import { MainComponent } from '@app/settings/modules/components/main/main.component';
import { LandingDiComponent } from '@app/settings/modules/components/landing-di/landing-di.component';
import { APPLICATIONS } from '@app/common/services/config.service';

export function resolveActiveTabFunction(stateParams: StateParams) {
  return stateParams.tab;
}

export const modulesState: Ng2StateDeclaration = {
  name: 'settings.modules',
  url: '/modules',
  data: {
    title: 'Správa modulů',
  },
  views: {
    'content@settings': {
      component: MainComponent,
    }
  },
};

export function resolveApplicationDiNameFunction(APPLICATIONS: any) {
  return APPLICATIONS.di.name;
}

export const landingDiState: Ng2StateDeclaration = {
  name: 'settings.modules.dimap',
  data: {
    title: 'Modul DiMAP',
  },
  url: '/dimap/:tab',
  params: {
    tab: 'terms-of-use'
  },
  views: {
    'content@settings': {
      component: LandingDiComponent,
    },
    'terms-of-use@settings.modules.dimap': {
      component: TermsOfUseComponent,
    },
  },
  resolve: {
    activeTab: ['$stateParams', resolveActiveTabFunction],
    appName: [APPLICATIONS, resolveApplicationDiNameFunction]
  },
};

export function resolveApplicationPkNameFunction(APPLICATIONS: any) {
  return APPLICATIONS.pk.name;
}

export const promapLandingState: Ng2StateDeclaration = {
  name: 'settings.modules.pk',
  data: {
    title: 'PROMAP',
  },
  url: '/pk/:tab',
  params: {
    tab: 'cadastre-data-import'
  },
  views: {
    'content@settings': {
      component: LandingPkComponent,
    },
    'terms-of-use@settings.modules.pk': {
      component: TermsOfUseComponent,
    },
    'cadastre-data-import@settings.modules.pk': {
      component: CadastreDataImportComponent,
    },
    'cadastre-data-request@settings.modules.pk': {
      component: CadastreDataRequestComponent,
    },
    'cadastre-data-report@settings.modules.pk': {
      component: CadastreDataReportComponent,
    },
  },
  resolve: {
    activeTab: ['$stateParams', resolveActiveTabFunction],
    appName: [APPLICATIONS, resolveApplicationPkNameFunction]
  },
};

export function resolveApplicationSyNameFunction(APPLICATIONS: any) {
  return APPLICATIONS.sy.name;
}

export const landingSyState: Ng2StateDeclaration = {
  name: 'settings.modules.ps',
  data: {
    title: 'MAJA/MPV',
  },
  url: '/PS_URL_BASE_PATH/:tab',
  params: {
    tab: 'cadastre-update'
  },
  views: {
    'content@settings': {
      component: LandingSyComponent,
    },
    'terms-of-use@settings.modules.ps': {
      component: TermsOfUseComponent,
    },
    'cadastre-update@settings.modules.ps': {
      component: CadastreUpdateComponent,
    },
    'changelog@settings.modules.ps': {
      component: ChangelogListComponent,
    },
  },
  resolve: {
    activeTab: ['$stateParams', resolveActiveTabFunction],
    applicationName: [APPLICATIONS, resolveApplicationSyNameFunction],
    appName: [APPLICATIONS, resolveApplicationSyNameFunction]
  },
};

export function resolveApplicationVfzeNameFunction(APPLICATIONS: any) {
  return APPLICATIONS.vfze.name;
}


export const vfzeLandingState: Ng2StateDeclaration = {
  name: 'settings.modules.vfze',
  data: {
    title: 'VFZE',
  },
  url: '/vfze/:tab',
  params: {
    tab: 'terms-of-use'
  },
  views: {
    'content@settings': {
      component: LandingVfzeComponent,
    },
    'terms-of-use@settings.modules.vfze': {
      component: TermsOfUseComponent,
    },
  },
  resolve: {
    activeTab: ['$stateParams', resolveActiveTabFunction],
    appName: [APPLICATIONS, resolveApplicationVfzeNameFunction]
  },
};

