<div [loading]="loading"></div>

<div *ngIf="!loading" class="bordered">
  <div class="vfze-row head">
    <div class="vfze-column">Řádek</div>
    <div class="vfze-column">XML</div>
    <div class="vfze-column">Problém</div>
  </div>
  <div [ngStyle]="{ height: viewHeight + 'px' }" (scroll)="onScroll($event)" class="view-area">
    <div [ngStyle]="{ height: viewHeight + 'px', marginTop: marginTop + 'px', marginBottom: marginBottom + 'px' }">
      <div *ngFor="let row of rowsToShow" [ngClass]="{ error: row.problems?.length }" class="vfze-row">
        <ng-container *ngIf="row.line && row.content; else divider">
          <div class="vfze-column">
            {{ row.line }}
          </div>
          <div class="vfze-column">
            <pre [ngClass]="{ 'error': row.problems.length }">{{ row.content }}</pre>
          </div>
          <div class="vfze-column" *ngIf="row.problems?.length" [tooltip]="tooltipProblemsComponent" [data]="{ problems: row.problems }" [activeContent]="true">
            <div *ngIf="row.problems?.length">
              <span class="fa fa-eye"> ({{ row.problems.length }})</span> {{ row.problems[0].message }}
            </div>
          </div>
          <div class="vfze-column" *ngIf="!row.problems || row.problems.length === 0"></div>
        </ng-container>
        <ng-template #divider>
          <div class="divider"><span>...</span></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!--<ul>-->
<!--  <li *ngFor="let problem of problems">{{ problem.message }}</li>-->
<!--</ul>-->
