import { Component, Inject, Input, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { ChangelogModel } from '@app/common/models/changelog.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ChangelogEditComponent } from '@app/settings/modules/components/changelog-edit/changelog-edit.component';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'changelog-list',
  templateUrl: './changelog-list.component.html'
})
export class ChangelogListComponent implements OnInit {

  list: ListModel<ChangelogModel>;
  pageableList: PageableList;
  @Input() applicationName: string;

  private defaultFilter = {
    filters: {
      application: { values: [ ]},
    }
  };

  constructor(
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
  ) {
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  ngOnInit() {
    this.initializeList();
    this.defaultFilter.filters.application.values = [ this.applicationName ];
  }

  onEdit(changelog?: ChangelogModel) {
    const dialog = this.dialogService.open(ChangelogEditComponent, {
      data: {
        changelogId: changelog ? changelog.id : undefined,
        applicationName: this.applicationName
      }
    });

    const sub = dialog.afterClosed.subscribe((updated: ChangelogModel) => {
      if (updated) {
        this.pageableList.load();
      }
      sub.unsubscribe();
    });
  }

  onDelete(changelog?: ChangelogModel) {
    this.restangular
      .one(`changelogs/${changelog.id}`)
      .remove()
      .toPromise()
      .then(() => this.pageableList.load());
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'changelogs',
      this.defaultFilter
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
