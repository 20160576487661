import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TableService } from '@app/ps/services/table.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { TitleModel } from '@app/common/models/title.model';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'table-titles',
  templateUrl: './table-titles.component.html',
  styleUrls: ['./table-titles.component.scss']
})
export class TableTitlesComponent implements OnInit {
  @Input() list: any;
  @Input() isChecked: Function;
  @Input() inverseSelection: boolean;
  @Input() customColumns: string[]; // avoid setting service
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() checkAll = new EventEmitter();
  @Output() sort = new EventEmitter();
  @Output() toggleCheckItem = new EventEmitter();
  private readonly TABLE_ID = 'titles';
  public mandataryFilter: any;
  public users = [];
  public userSending = {};

  constructor(
    private tableService: TableService,
    private settingsService: SettingsService,
    private authService: AuthService,
    public userNamePipe: UserNamePipe,
    private restangular: Restangular,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
  ) {}

  ngOnInit() {
    const activeApplication = this.authService.getActiveApplication();
    const activeProjectKey = this.authService.getActualProject().key;
    this.mandataryFilter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [activeApplication],
        },
        permission: {
          values: ['assignable'],
        },
        projects: {
          values: [activeProjectKey],
        },
      },
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };
    if (this.isChecked === undefined) {
      this.loadPossibleMandataryUsers();
    }
  }

  trackByFn(index, item) {
    return item['id'];
  }

  onCheckAll() {
    this.checkAll.emit();
  }

  onToggleCheckItem(title: TitleModel) {
    this.toggleCheckItem.emit(title);
  }

  onSort(column: string, ascOrDesc: string) {
     this.sort.emit({
       column,
       ascOrDesc,
     });
  }

  hideColumn(columnId: string): boolean {
    if (this.customColumns) {
      return !this.customColumns.includes(columnId);
    }
    return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
  }

  getColumnName(columnId: string): string {
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  async loadPossibleMandataryUsers() {
    if (!this.authService.hasPermission('assignment_edit')) {
      return;
    }
    this.users = await this.restangularSettings.all('users/list').customPOST({ filter: this.mandataryFilter }).toPromise();
  }

  updateUser(titleId, userId) {
    this.userSending[titleId] = true;

    this.restangular.one('titles', titleId)
      .customPUT({id: userId}, 'user')
      .toPromise()
      .then(() => {
        this.userSending[titleId] = false;
    });
  }
}
