import * as _ from 'lodash';

export class ChecklistModel {
  identity = null;
  beforeCallback = this.toggleCallback;
  transformation = null;
  afterCallback = null;

  constructor(
    public checkedItems = [],
    private toggleCallback?
  ) {
    this.indexOf = this.indexOf.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
  }

  toggleSelection(item, event?) {
    if (typeof this.beforeCallback === 'function') {
      const beforeCallbackResult = this.beforeCallback(event, item);
      if (beforeCallbackResult === false) {
        return;
      } else if (typeof beforeCallbackResult !== 'boolean') {
        item = beforeCallbackResult;
      }
    }
    if (typeof this.transformation === 'function') {
      item = this.transformation(item);
    }
    const idx = this.indexOf(item);
    let result = null;
    if (idx > -1) {
      this.checkedItems.splice(idx, 1);
      result = false;
    } else {
      this.checkedItems.push(item);
      result = true;
    }

    if (typeof this.afterCallback === 'function') {
      this.afterCallback(event, item, result);
    }

    return result;
  }

  isChecked(item) {
    if (typeof this.transformation === 'function') {
      item = this.transformation(item);
    }
    return this.indexOf(item) > -1;
  }

  indexOf(item) {
    if (this.identity) {
      return _.findIndex(this.checkedItems, this.identity(item));
    } else if (item.id !== undefined) {
      return _.findIndex(this.checkedItems, { id: item.id });
    } else {
      return _.indexOf(this.checkedItems, item);
    }
  }

  empty() {
    this.checkedItems.splice(0, this.checkedItems.length);
  }

  isEmpty() {
    return !this.checkedItems.length;
  }
}
