<div class="group" id="ls-search">
  <div class="group-title">Vyhledat</div>
  <a class="fa fa-times" *ngIf="highlightExists() || search.text.length > 0" (click)="search.text=''; searchChange(); removeHighlight();"></a>
  <input type="text" placeholder="Fultextové hledání..." [ngModel]="search.text" (ngModelChange)="search.text=$event; searchChange()"/>
  <div class="switch-wrapper" *ngIf="search.text !== '' && searchResources">
    <gmt-switch [options]="searchResources" [(selectedId)]="search.resource" (select)="searchChange($event.id)" (onSwitchMouseEnter)="onMouseEnterOption($event.event, $event.option)" (onSwitchMouseLeave)="onMouseLeaveOption($event.event)"></gmt-switch>
  </div>
  <div [loading]="search.list?.loading">
    <div class="count" *ngIf="search.list !== null && search.list.list !== null && search.list.itemCount">Nalezeno záznamů: {{ search.list.itemCount }}</div>
    <div class="count" *ngIf="search !== undefined && search.list !== null && search.list.list !== null && !search.list.itemCount">Nenalezeny žádné záznamy</div>
    <ul class="results" *ngIf="search.list?.itemCount">
      <li *ngFor="let item of search.list.list">
        <div (click)="highlightFeature(item)">{{search.formatItem(item)}}</div>
      </li>
    </ul>
    <div class="load-next" *ngIf="search.list?.itemCount > search.list?.list?.length">
      <a class="button" (click)="loadNext()">Načíst další</a>
    </div>
  </div>
</div>
