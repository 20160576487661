﻿import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';

@Component({
  templateUrl: './bulk-operation-confirm.component.html',
})
export class BulkOperationConfirmComponent implements OnInit {
  data: any;
  callbacks: CallbackModel;
  @ViewChild('operationConfirm', {
    read: ViewContainerRef
  }) confirmViewContainerRef: ViewContainerRef;
  confirmComponent: any;
  proceed: Function;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.notifyParent = this.notifyParent.bind(this);
  }

  ngOnInit() {
    if (this.data.selectAction && this.data.selectAction.confirmComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.data.selectAction.confirmComponent);
      this.confirmComponent = factory.create(this.confirmViewContainerRef.parentInjector);
      this.confirmViewContainerRef.insert(this.confirmComponent.hostView);

      this.confirmComponent.instance.data = this.data;
      this.confirmComponent.instance.callbacks = this.callbacks;
      this.proceed = this.confirmComponent.instance.proceed;
    }
  }

  notifyParent(isValid: Function, inputData: any) {
    this.callbacks.get(Callbacks.DataValidityChanged)({
      isValid: isValid,
      data: inputData,
    });
  }
}
