import { Component } from '@angular/core';

import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';

@Component({
  selector: 'tooltip-occupation-price',
  templateUrl: './tooltip-occupation-price.component.html',
  styleUrls: ['./tooltip-occupation-price.component.scss'],
})
export class TooltipOccupationPriceComponent extends TooltipAbstractListComponent<any> {
  protected resource = 'parcel-prices';
}
