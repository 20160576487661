import { Component, OnInit, Inject } from '@angular/core';
import {StateService, TransitionService} from '@uirouter/angular';
import {ModulesService} from '@app/common/services/modules.service';
import {RolePipe} from '@app/common/pipes/role.pipe';
import {OrganizationalUnitNamePipe} from '@app/common/pipes/organizational-unit-name.pipe';
import {BulkPriceStep} from '@app/ps/project-settings/components/bulk-price/bulk-price/bulk-price.component';
import {APPLICATIONS} from '@app/common/services/config.service';
import {RESTANGULAR_SETTINGS} from '@app/common/services/restangular-settings.service';
import {DialogService} from '@app/common/services/dialog.service';
import {Restangular} from 'ngx-restangular';
import {RESTANGULAR_SYMAP} from "@app/common/services/restangular-symap.service";

@Component({
  selector: 'permissions-bulk',
  templateUrl: './permissions-bulk.component.html',
  styleUrls: ['./permissions-bulk.component.scss']
})
export class PermissionsBulkComponent implements OnInit {

  loading = true;
  activeStep: BulkPriceStep;

  /*
    {
      -- init
      common: {
        projects: all projects
        permissions: all permissions
        roles: all roles
        rolesWithPermissions: all roles with permissions (mapper)
        permissionsWithRoles: all permissions with roles (mapper)
      }

      -- select type
      operation: u | p - roles for user | roles for project
      user: selected user
      project: selected project

      -- select entities
      projects: selected projects
      users: selected users

      -- select permissions
      removePermissions
      role
      permissions
    }
  */
  data: any = {};
  steps: any[] = [
    { id: 'type', name: 'Výběr operace', enabled: false },
    { id: 'entities', name: 'Výběr entit', enabled: false },
    { id: 'permissions', name: 'Oprávnění', enabled: false },
    { id: 'confirm', name: 'Potvrzení', enabled: false },
  ];

  private deregisterLeaveListener: Function;
  private stepsHistory: string[] = [];

  constructor(
    @Inject(APPLICATIONS) public applications: any,
    @Inject(RESTANGULAR_SETTINGS) public restangular: Restangular,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: Restangular,
    private modulesService: ModulesService,
    private dialogService: DialogService,
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private stateService: StateService,
    private transitionService: TransitionService,
    public rolePipe: RolePipe,
  ) {
  }

  async ngOnInit() {
    this.onStep('type', true);
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));

    // data for all steps
    const projects = await this.restangularSymap.one('projects/full').get().toPromise().then((d) => d.plain());
    const rolesWithPermissions = await this.modulesService.getModuleRoles(this.applications.sy.name);
    const permissions = await this.modulesService.getPermissions(this.applications.sy.name);
    const roles = await this.restangular.all(`${this.applications.sy.name}/roles?withDeprecated=true&withGlobal=false`).getList().toPromise().then(d => d.plain());

    this.data.common = {
      // all projects
      projects: projects,
      // all permissions
      permissions: permissions,
      // all roles
      roles: roles,
      // all roles with permissions (mapper)
      rolesWithPermissions: rolesWithPermissions,
      // all permissions with roles (mapper)
      permissionsWithRoles: permissions
        .map((dp) => {
          dp.roles = [];
          rolesWithPermissions.forEach(role => {
            if (role.permissions.includes(dp.name)) {
              dp.roles.push(role.role);
            }
          });

          return dp;
        })
    };

    this.loading = false;
  }

  onStep(id: string, enable = false) {
    const step = this.steps.find(s => s.id === id);

    if (step.enabled === false && enable === false) {
      return;
    }

    // step back
    if (this.stepsHistory.includes(id)) {
      this.activeStep = step;
      this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
      this.steps.forEach(v => v.enabled = this.stepsHistory.includes(v.id));

    // step forward
    } else {
      this.activeStep = step;
      this.stepsHistory.push(id);
      step.enabled = true;
    }
  }

  onFinish() {
    this.deregisterLeaveListener();
    this.stateService.go('^');
  }

  private beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
      });
  }
}
