import { Component, Inject, Input, OnInit } from '@angular/core';
import { TicketModel } from '@app/sv/models/ticket.model';
import { AuthService } from '@app/common/services/auth.service';
import { TabModel } from '@app/common/models/tab.model';
import { StateService } from '@uirouter/angular';
import { TicketService } from '@app/sv/services/ticket.service';
import { TicketStatusEnum } from '@app/sv/enums/ticket-status.enum';
import { TicketEditComponent } from '@app/sv/components/ticket-edit/ticket-edit.component';
import { SampleService } from '@app/sv/services/sample.service';
import { ListModel } from '@app/common/models/list.model';
import { DocumentModel } from '@app/common/models/document.model';
import { ListService } from '@app/common/services/list.service';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { NoteModel } from '@app/common/models/note.model';
import { SimpleTextModalComponent } from '@app/common/components/simple-text-modal/simple-text-modal.component';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';
import { HelpService } from '@app/common/services/help.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { noteTypeOptions } from '@app/sv/enums/note-type.enum';

@Component({
  selector: 'ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {

  static readonly MSG_FILES_DEFAULT = 'Vložte opravu vzoru. Povolen je jeden dokument typu doc, docx.';
  static readonly MSG_FILES_EXTENSION = 'Soubor musí být ve formátu doc, docx.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  @Input() ticketId: number;
  loading = true;
  ticket: TicketModel;
  tab: string;
  ticketStatusEnum = TicketStatusEnum;
  documentList: ListModel<DocumentModel>;
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();
  helpIds = HelpService.HELP_IDS;
  nextStatuses: TicketStatusEnum[];

  files: UploadFileExtended[];
  filesMsg = TicketDetailComponent.MSG_FILES_DEFAULT;

  tabs: TabModel[] = [
    {
      name: 'Poznámky',
      id: 'notes',
      href: `sv.tickets.detail`,
      urlParams: { tab: 'notes' },
    },
    {
      name: 'Historie',
      id: 'history',
      href: `sv.tickets.detail`,
      urlParams: { tab: 'history' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: `sv.tickets.detail`,
      urlParams: { tab: 'documents' },
    },
  ];

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    private errorHandlerService: ErrorHandlerService,
    public ticketService: TicketService,
    private listService: ListService,
    private sampleService: SampleService,
    private authService: AuthService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'notes');
    this.isActiveTab = this.isActiveTab.bind(this);
    this.onDocumentsSort = this.onDocumentsSort.bind(this);
    this.isActionButtonVisible = this.isActionButtonVisible.bind(this);
    this.noteTypeResolver = this.noteTypeResolver.bind(this);
  }

  async ngOnInit() {
    await this.loadTicket();
    this.loadDocuments();
    this.loadNotes();
    this.loadHistory();
    this.loading = false;
  }

  onEdit(status: string) {
    const dialog = this.dialogService.open(TicketEditComponent, {
      data: {
        ticket: { ...this.ticket },
        newStatus: status
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: any | boolean) => {
      sub.unsubscribe();

      if (typeof result === 'object') {
        this.uploadAndRefresh(
          result.ticket,
          result.templateFiles,
          result.sampleFiles,
          result.revisionSampleFiles,
          result.note
        );
      }

      return;
    });
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  isActionButtonVisible(nextStatus: TicketStatusEnum): boolean {
    // template_new - zadavatel
    // template_aproval - schvalovatel
    // template_manage - zpracovatel
    switch (nextStatus) {
      case TicketStatusEnum.REQUEST_FOR_CLARIFICATION:
      case TicketStatusEnum.APPROVED:
      case TicketStatusEnum.DISAPPROVED:
        return this.authService.hasPermissionOnProject('template_aproval', this.ticket.organizationalUnitCode);
      case TicketStatusEnum.CLARIFICATION_REQUEST_DONE:
        return this.authService.hasPermissionOnProject('template_new', this.ticket.organizationalUnitCode);
      case TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING:
      case TicketStatusEnum.REQUEST_FOR_INFORMATION:
      case TicketStatusEnum.IMPORTED:
      case TicketStatusEnum.DELETED:
        return this.authService.hasPermissionOnProject('template_manage', this.ticket.organizationalUnitCode);
      case TicketStatusEnum.INFORMATION_REQUEST_DONE:
        return this.authService.hasPermissionOnProject('template_new', this.ticket.organizationalUnitCode);
      default:
        return false;
    }
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onDocumentsSort(sortValue: any, sortDir: any) {
    this.listService.sort(this.documentList, sortValue, sortDir);
  }

  canEditNote() {
    return this.authService.hasPermissionOnProject('template_view', this.ticket.organizationalUnitCode);
  }

  onFilesChange(files: UploadFileExtended[]) {
    this.files = files;

    const extensionValid = files.every(f => ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()));

    if (this.files.length === 0) {
      this.filesMsg = TicketDetailComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.filesMsg = TicketDetailComponent.MSG_FILES_EXTENSION;
    } else if (this.files.length > 1) {
      this.filesMsg = TicketDetailComponent.MSG_FILES_QUANTITY;
    } else {
      this.filesMsg = TicketDetailComponent.MSG_FILES_DEFAULT;
    }

    const valid = (extensionValid && this.files.length === 1);

    if (!valid) {
      return;
    }

    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: 'Technická oprava šablony',
        label: 'Důvod opravy šablony',
        required: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        const noteText = 'Oprava vloženého vzoru Schvalovatelem. Důvod: ' + text;
        const note = { ticketId: { id: this.ticketId }, systemType: true, text: noteText };
        this.loading = true;
        try {
          await this.ticketService.updateSample(this.ticket, this.files[0], note, text);
        } catch (e) {
          this.errorHandlerService.get(e);
        }
        await this.loadDocuments();
        await this.loadHistory();
        this.loading = false;
        this.files = [];
      }
      sub.unsubscribe();
    });
  }

  noteTypeResolver(noteType: any) {
    const found = noteTypeOptions.find(nto => nto.id === noteType);
    return found ? found.name : '';
  }

  private loadTicket() {
    return this.ticketService.getById(this.ticketId).then(ticket => {
      this.ticket = ticket;
      this.nextStatuses = ticket.nextStatuses.filter(this.isActionButtonVisible);
    });
  }

  private loadDocuments() {
    const attachmentFilter = ['SAMPLE', 'INACTIVE_SAMPLE'];

    if (this.APP_BRAND.NAME !== 'RSD') {
      attachmentFilter.push('REVISION_SAMPLE');
      attachmentFilter.push('INACTIVE_REVISION_SAMPLE');
    }

    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { ticketId: this.ticket.id, attachmentType: { values: attachmentFilter }, cancelled: false },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { ticketId: this.ticketId, systemType: true},
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { ticketId: this.ticketId, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private async uploadAndRefresh(ticket: TicketModel, templateFiles: UploadFileExtended[], sampleFiles: UploadFileExtended[], revisionSampleFiles: UploadFileExtended[], note?: NoteModel) {
    try {
      this.loading = true;
      this.ticket = await this.ticketService.update(ticket, templateFiles, sampleFiles, revisionSampleFiles, note);
      this.nextStatuses = this.ticket.nextStatuses.filter(this.isActionButtonVisible);

      if (ticket.status === TicketStatusEnum.IMPORTED) {
        await this.sampleService.createByTicket(this.ticket);
      }

      this.ticket = await this.ticketService.getById(this.ticketId);
      await this.loadDocuments();
      await this.loadNotes();
      await this.loadHistory();
    } catch (e) {
      this.errorHandlerService.get(e);
    } finally {
      this.loading = false;
    }
  }
}
