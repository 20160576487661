import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OccupationModel } from '@app/ps/models/occupation.model';
import { TooltipAbstractListComponent } from '@app/common/components/tooltip-abstract-list/tooltip-abstract-list.component';

export interface TooltipDisabledOccupationComponentData {
  occupation: OccupationModel;
}

@Component({
  selector: 'tooltip-disabled-occupation',
  templateUrl: './tooltip-disabled-occupation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipDisabledOccupationComponent extends TooltipAbstractListComponent<any> {
  protected resource = 'changes';

  // bindings
  @Input() customData: TooltipDisabledOccupationComponentData;

  ngOnInit() {
    super.ngOnInit();
  }
}
