import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as  L from 'leaflet';
import { MapOgcCommonService } from '@app/map/services/map-ogc-common.service';

/**
 * WMS layer extended with WFS-style filtering capabilities
 */
@Injectable({
  providedIn: 'root'
})
export class LeafletTileLayerWmts {
  private WmtsTileLayer;

  constructor(
     http: HttpClient,
     mapOgcCommonService: MapOgcCommonService,
  ) {
    this.WmtsTileLayer = L.TileLayer.extend({
        defaultWmtsParams: {
          service: 'WMTS',
          request: 'GetTile',
          version: '1.0.0',
          layer: '',
          style: '',
          tilematrixSet: '',
          format: 'image/jpeg'
        },
        // query parametry dlaždice
        wmtsParams: {},

        initialize: function (url, options) {

          if(!options || !options.id) {
              throw new Error("Missing layer id option in initialization.");
          }

          this._url = url;
          var tileSize = options.tileSize || this.options.tileSize;
          this.tilematrixSet = options.tilematrixSet;
          if (options.crs) {
            if(!options.crs.srid || !options.crs.name) {
                throw new Error("Missing layer crs option in initialization.");
            }
            options.customCRS =  'EPSG:' + options.crs.srid + '_' + options.crs.name;
          }
          this.wmtsParams = {};
          // Podle tohoto parametru se rozlišuje na rest volání KVP vs RESTful
          if (options.wmts) {
            L.extend(this.wmtsParams, this.defaultWmtsParams);
            if (options.detectRetina && L.Browser.retina) {
              this.wmtsParams.width = this.wmtsParams.height = tileSize * 2;
            } else {
              this.wmtsParams.width = this.wmtsParams.height = tileSize;
            }

            for (var optionsKey in options.wmts) {
              if (options.wmts.hasOwnProperty(optionsKey)) {
                this.wmtsParams[optionsKey] = options.wmts[optionsKey];
              }
            }
          }
          this.matrixIds = options.matrixIds || this.getDefaultMatrix();
          L.setOptions(this, options);
          this._id = options.id;
          this._httpService = http;
        },

        onAdd: function (map) {
          L.TileLayer.prototype.onAdd.call(this, map);
        },

        getTileUrl: function (tilePoint) {
          var ident = this.matrixIds[tilePoint.z].identifier;
          var tilecol = tilePoint.x ;
          var tilerow = tilePoint.y ;
          var url = L.Util.template(this._url, {
            s: this._getSubdomain(tilePoint),
            TileMatrixSet: this.tilematrixSet,
            TileMatrix: ident,
            TileCol: tilecol,
            TileRow: tilerow,
          });

          let params = this.wmtsParams
          if (this.options.wmts) {
            params = L.extend({
              tilematrix: ident,
              tilerow: tilerow,
              tilecol: tilecol
            }, params);
          }
          url += L.Util.getParamString(params, url);

          return url;
        },

        setParams: function (params, noRedraw) {
            L.extend(this.wmtsParams, params);
            if (!noRedraw) {
              this.redraw();
            }
            return this;
          },

        getDefaultMatrix: function () {
              /**
               * the matrix3857 represents the projection
               * for in the IGN WMTS for the google coordinates.
               */
          var matrixIds3857 = new Array(22);
          for (var i = 0; i < 22; i++) {
            matrixIds3857[i] = {
              identifier: '' + i,
              topLeftCorner: new L.LatLng(20037508.3428, -20037508.3428)
            };
          }
          return matrixIds3857;
        }
    });
  }

   getLayer(url, options) {
      return new this.WmtsTileLayer(url, options);
  }
}
