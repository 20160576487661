import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  loading = true;
  projects: any[];
  user = this.authService.getUser();
  canEditArticles = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private authService: AuthService,
  ) {
  }

  async ngOnInit() {
    await this.loadUserProjects();
    this.canEditArticles = this.user.roles.filter(r => r.application === 'SY' && r.role === 'SUPER_ADMIN').length > 0;
    this.loading = false;
  }

  private async loadUserProjects() {
    const apps = this.authService.getApplications();
    const lastProjects = this.authService.getLastVisitedProjects(this.APPLICATIONS.sy.name);

    if (!apps || !apps.includes(this.APPLICATIONS.sy.name) || !lastProjects) {
      return;
    }

    const userProjects = this.authService.getSymapProjects()
      ? this.authService.getSymapProjects()
      : await this.authService.loadSymapProjects(this.authService.getUser().id).then(d => d.projects);

    const activeLastProjects = lastProjects
      .filter(l => userProjects.find(p => p.key === l))
      .filter(l => l !== 'sy_statistics' && l !== 'sy_statistics_emh') // @TODO - temp hide construction
      .map(l => userProjects.find(p => p.key === l));

    if (activeLastProjects.length === 0) {
      return;
    }

    this.projects = activeLastProjects.slice(0, 10);
  }
}
