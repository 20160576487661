import { Component, Inject, Input, OnInit } from '@angular/core';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { User } from '@app/models/user';
import { AuthService } from '@app/common/services/auth.service';
import { UserEditComponent } from '@app/common/components/user-edit/user-edit.component';
import { SelectItem } from '@app/common/components/select/select.component';
import { BrandService } from '@app/common/services/brand.service';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {

  list: ListModel<User>;
  pageableList: PageableList;
  module = this.authService.getActiveApplication();
  projectKey: string;
  roles: SelectItem[];
  editor: User = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;

  private defaultFilter = {
    filters: {
      searchText: {
        values: [{ id: '' }]
      },
      applications: {
        values: [this.module]
      },
      projects: {
        values: []
      },
      roles: {
        values: []
      },
      withoutRole: {
        values: []
      },
    },
  };

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public globalRestangular: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private authService: AuthService,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
    private brandService: BrandService,
    public rolePipe: RolePipe,
  ) {
    this.onCreate = this.onCreate.bind(this);
  }

  async ngOnInit() {
    if (this.authService.getActualProject()) {
      this.projectKey = this.authService.getActualProject().key;
      this.defaultFilter.filters.projects.values = [this.projectKey];
    }
    this.roles = await this.globalRestangular.all(`${this.module}/roles`).getList().toPromise();
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'users/list',
      this.defaultFilter,
      'usersFilter2',
      this.globalRestangular
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  onCreate() {
    const dialog = this.dialogService.open(UserEditComponent, {
      data: { modules: [this.authService.getActiveApplication()] }
    });
    const sub = dialog.afterClosed.subscribe((res: User) => {
      if (res) {
        this.pageableList.fetchResults();
      }
      sub.unsubscribe();
    });
  }
}
