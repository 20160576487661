<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam znaleckých posudků</a>
  </div>

  <h1>Nový znalecký posudek</h1>

  <div class="row mt-30">
    <div class="col-12 offset-lg-3 col-lg-6">
      <fieldset>
        <legend>Řešené LV / parcely znaleckým posudkem</legend>

        <div class="column-100">
          <!-- cislo ZP -->
          <gmt-input label="Číslo ZP" [required]="true" class="mt-20">
            <input type="text" [(ngModel)]="opinionRequest.name"/>
          </gmt-input>

          <!-- katastralni uzemi -->
          <gmt-input label="Katastrální území" [required]="!gpConcept" class="mt-20">
            <gmt-select
              resource="areas"
              [filter]="{ sortOrder: { sortBy: 'name' }}"
              [data]="this"
              field="area"
              required="false"
              (changed)="onCadastreChanged()"
            ></gmt-select>
          </gmt-input>

          <!-- LV -->
          <gmt-input label="LV" [required]="true" class="mt-20">
            <gmt-select
              resource="titles"
              [globalFilterConfig]="titleFilter"
              [data]="opinionRequest"
              field="title"
              required="false"
              [itemPipe]="titleNamePipe"
              (changed)="onTitleChanged()"
            ></gmt-select>
          </gmt-input>

          <!-- parcely -->
          <gmt-input label="Dotčené parcely" [required]="!gpConcept && (buildings?.length === 0 || !this.opinionRequest.buildings || this.opinionRequest.buildings.length === 0)" class="mt-20">
            <gmt-select
              resource="parcels"
              [globalFilterConfig]="parcelFilter"
              [data]="opinionRequest"
              field="parcels"
              [multiselect]="true"
              required="false"
              [itemPipe]="parcelNumberPipe"
              (changed)="onParcelsChanged()"
            ></gmt-select>
          </gmt-input>

          <!-- budovy -->
          <gmt-input *ngIf="buildings?.length > 0" label="Budova na parcele" [required]="!gpConcept && (!this.opinionRequest.parcels || this.opinionRequest.buildings.length > 0)" class="mt-20">
            <gmt-select
              [optionItems]="buildings"
              [data]="opinionRequest"
              field="buildings"
              [multiselect]="true"
              required="false"
              [itemPipe]="buildingNamePipe"
              (changed)="onBuildingsChanged()"
            ></gmt-select>
          </gmt-input>

          <!-- GP -->
          <label (click)="gpConcept = !gpConcept">Znalecký posudek zadaný na koncept geometrického plánu</label>
          <div class="info" (click)="gpConcept = !gpConcept">
            <gmt-checkbox [gmtChecked]="gpConcept"></gmt-checkbox>
          </div>
        </div>

        <div *ngIf="gpConcept" class="column-100 mt-20">
          <gmt-input  label="Geometrický plán" [required]="true" class="mt-20">
            <gmt-select
              resource="geometric-plan-requests"
              [globalFilterConfig]="geometricPlanRequestFilter"
              [data]="opinionRequest"
              field="geometricPlanRequest"
              required="true"
            ></gmt-select>
          </gmt-input>

          <gmt-input>
            <gmt-button [clickAction]="onAddParcel"><i class="fa fa-plus"></i> Přidat parcelu</gmt-button>
          </gmt-input>
        </div>

        <div *ngIf="gpConcept && externalParcelListAdded.list?.length > 0" class="column-100">
          <table-parcels-small
            [data]="externalParcelListAdded.list"
            [columns]="PARCEL_COLS"
            [checkedAll]="checkedAllAdded"
            [isChecked]="isCheckedAdded"
            (toggleOne)="onToggleOneAdded($event)"
            (toggleAll)="onToggleAllAdded()"
          ></table-parcels-small>
        </div>

      </fieldset>

      <div *ngIf="similarOpinionRequestExists" class="mt-20 text-center bordered error-message p-20">
        Existuje ZP, který obsahuje jednu nebo více vybraných parcel nebo budov.
      </div>

      <div class="mt-20 text-center">
        <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">Založit</gmt-button>
      </div>
    </div>
  </div>
</div>

