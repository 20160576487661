import { Component, Input } from '@angular/core';

export interface TooltipTrendComponentData {
  total: number;
  totalBefore: number;
  done: number;
  doneBefore: number;
  unit: string;
}

@Component({
  selector: 'tooltip-trend',
  templateUrl: './tooltip-trend.component.html',
  styleUrls: ['./tooltip-trend.component.scss']
})
export class TooltipTrendComponent {
  @Input() data: TooltipTrendComponentData;
}
