import {
  FormCaseSendAdministratorComponent
} from '@app/ps/cases/components/form-case-send-administrator/form-case-send-administrator.component';
import {
  FormCaseSignAdministratorComponent
} from '@app/ps/cases/components/form-case-sign-administrator/form-case-sign-administrator.component';
import {
  FormCaseSendInvestorComponent
} from '@app/ps/cases/components/form-case-send-investor/form-case-send-investor.component';
import {
  FormCaseSignInvestorComponent
} from '@app/ps/cases/components/form-case-sign-investor/form-case-sign-investor.component';
import {FormCaseSendOwnerComponent} from '@app/ps/cases/components/form-case-send-owner/form-case-send-owner.component';
import {
  FormCaseUndeliveredOwnerComponent
} from '@app/ps/cases/components/form-case-undelivered-owner/form-case-undelivered-owner.component';
import {
  FormCaseReceiveOwnerComponent
} from '@app/ps/cases/components/form-case-receive-owner/form-case-receive-owner.component';
import {
  FormCaseDeclineOwnerComponent
} from '@app/ps/cases/components/form-case-decline-owner/form-case-decline-owner.component';
import {FormCaseSignOwnerComponent} from '@app/ps/cases/components/form-case-sign-owner/form-case-sign-owner.component';
import {
  FormCaseEntryProposalComponent
} from '@app/ps/cases/components/form-case-entry-proposal/form-case-entry-proposal.component';
import {
  FormCaseEntryDeclinedComponent
} from '@app/ps/cases/components/form-case-entry-declined/form-case-entry-declined.component';
import {
  FormCaseEntryStoppedComponent
} from '@app/ps/cases/components/form-case-entry-stopped/form-case-entry-stopped.component';
import {
  FormCaseHandOverInvestorComponent
} from '@app/ps/cases/components/form-case-hand-over-investor/form-case-hand-over-investor.component';
import {
  FormCasePaymentInstructionComponent
} from '@app/ps/cases/components/form-case-payment-instruction/form-case-payment-instruction.component';
import {
  FormCasePaymentOrderComponent
} from '@app/ps/cases/components/form-case-payment-order/form-case-payment-order.component';
import {
  FormCaseSignedSendOwnersComponent
} from '@app/ps/cases/components/form-case-signed-send-owners/form-case-signed-send-owners.component';
import {
  FormCaseSentAllOwnersComponent
} from '@app/ps/cases/components/form-case-sent-all-owners/form-case-sent-all-owners.component';

export const actionDialogues = [
  {caseStatus: 'SentAdministrator', formComponent: FormCaseSendAdministratorComponent, saveEndpoint: 'send-administrator'},
  {caseStatus: 'SignedAdministrator', formComponent: FormCaseSignAdministratorComponent, saveEndpoint: 'sign-administrator'},

  {caseStatus: 'SentInvestor', formComponent: FormCaseSendInvestorComponent, saveEndpoint: 'send-investor'},

  {caseStatus: 'ApprovedInvestor', saveEndpoint: 'approve-investor'},
  {caseStatus: 'DeclinedInvestor', saveEndpoint: 'decline-investor'},
  {caseStatus: 'SignedInvestor', formComponent: FormCaseSignInvestorComponent, saveEndpoint: 'sign-investor'},

  // {caseStatus: 'AuctionWin'},
  {caseStatus: 'SentOwner', formComponent: FormCaseSendOwnerComponent, saveEndpoint: 'send-owner'},
  {caseStatus: 'UndeliveredOwner', formComponent: FormCaseUndeliveredOwnerComponent, saveEndpoint: 'undelivered-owner'},
  {caseStatus: 'ReceivedOwner', formComponent: FormCaseReceiveOwnerComponent, saveEndpoint: 'receive-owner'},
  {caseStatus: 'DeclinedOwner', formComponent: FormCaseDeclineOwnerComponent, saveEndpoint: 'decline-owner'},
  {caseStatus: 'SignedOwner', formComponent: FormCaseSignOwnerComponent, saveEndpoint: 'sign-owner'},

  /*{caseStatus: 'ExpropriationProposal'},
  {caseStatus: 'ExpropriationAssessmentInterupted'},
  {caseStatus: 'ExpropriationProceedingStarted'},
  {caseStatus: 'ExpropriationProceedingInterupted'},
  {caseStatus: 'ExpropriationProceedingInteruptionExtended'},
  {caseStatus: 'ExpropriationStopped'},
  {caseStatus: 'ExpropriationCalledHearing'},
  {caseStatus: 'ExpropriationHearingRepetition'},
  {caseStatus: 'ExpropriationAccepted'},
  {caseStatus: 'ExpropriationAppeal'},
  {caseStatus: 'ExpropriationAppealAccepted'},
  {caseStatus: 'ExpropriationEnforceable'},*/

  {caseStatus: 'EntryProposal', formComponent: FormCaseEntryProposalComponent, saveEndpoint: 'entry-proposal'},
  // {caseStatus: 'CadastreEntry'},
  {caseStatus: 'EntryDeclined', formComponent: FormCaseEntryDeclinedComponent, saveEndpoint: 'decline-cadastre-entry'},
  {caseStatus: 'EntryStopped', formComponent: FormCaseEntryStoppedComponent, saveEndpoint: 'stop-cadastre-entry'},

  {caseStatus: 'HandedOverInvestor', formComponent: FormCaseHandOverInvestorComponent, saveEndpoint: 'hand-over-investor'},

  {caseStatus: 'PaymentInstruction', formComponent: FormCasePaymentInstructionComponent, saveEndpoint: 'payment-instruction'},
  {caseStatus: 'PaymentOrder', formComponent: FormCasePaymentOrderComponent, saveEndpoint: 'payment-order'},
  {caseStatus: 'SignedSentOwners', formComponent: FormCaseSignedSendOwnersComponent, saveEndpoint: 'signed-sent-owners'},

  {caseStatus: 'ContractRegisterPublication', saveEndpoint: 'contract-register-publication'},

  {caseStatus: 'Cancelled', saveEndpoint: 'cancel'},

  {caseStatus: 'SentAllOwners', formComponent: FormCaseSentAllOwnersComponent, saveEndpoint: 'sent-all-owners'},
];
