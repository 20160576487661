import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CellAbstractComponent, CellTooltipMappingType } from '../cell-abstract/cell-abstract.component';
import { TooltipEasementPriceComponent } from '../tooltip-easement-price/tooltip-easement-price.component';
import { TooltipOccupationPriceComponent } from '../tooltip-occupation-price/tooltip-occupation-price.component';

@Component({
  selector: 'cell-price',
  templateUrl: './cell-price.component.html',
  styleUrls: ['./cell-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellPriceComponent extends CellAbstractComponent {

  // bindings
  @Input() price: number;
  @Input() editable: boolean;
  @Input() editFunction: Function;
  @Input() editFunctionParams: any;
  @Input() casePrice: any;

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'easement-price': TooltipEasementPriceComponent,
    'occupation-price': TooltipOccupationPriceComponent
  };

  isCaseChanged() {
    return this.casePrice && (this.casePrice.priceChanged || this.casePrice.expertOpinionChanged);
  }
}
