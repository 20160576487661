import { InjectionToken } from '@angular/core';

export const RESTANGULAR_SV = new InjectionToken<any>('RestangularSV');

export function RestangularSVFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlSV);
  });

  return r;
}

