<div class="list">
  <div class="actions">
    <a uiSref="symap.project.titles.bulkOperation" class="button">Hromadná operace</a>
  </div>
  <h1>Listy vlastnictví <cadastre-data-date></cadastre-data-date></h1>

  <ng-container *ngIf="list">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.areaId"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.areaId.negation"
        resource="areas"
        [filter]="{filters: {}, sortOrder: {sortBy: 'name'}}"
        selectTitle="Katastrální území"
        logGa
      ></gmt-select>

      <gmt-select [multiselect]="true" [data]="list.filter.filters.subjectId" field="values" resource="subjects"
                  [filter]="{filters: {validity: 'valid'}, sortOrder: [{sortBy: 'prijmeni'}, {sortBy: 'nazev'}]}"
                  selectTitle="Vlastníci" [itemPipe]="subjectNamePipe" logGa></gmt-select>

      <gmt-select *ngIf="!hideColumn('user')" [multiselect]="true" [data]="list.filter.filters.userId" field="values" resource="users/list"
                  [filter]="mandataryFilter" selectTitle="Přidělení LV" [itemPipe]="userNamePipe"
                  [restangularService]="restangularSettings" logGa></gmt-select>

      <gmt-select *ngIf="!hideColumn('mandatary')" [multiselect]="true" [data]="list.filter.filters.caseMandataryId" field="values" resource="users/list"
                  [filter]="mandataryFilter" selectTitle="Řešitel případu" [itemPipe]="userNamePipe"
                  [restangularService]="restangularSettings" logGa></gmt-select>

      <gmt-select [multiselect]="true" [data]="list.filter.filters.parcelId" field="values" resource="parcels"
                  [filter]="{filters: {validity: 'valid'}}" selectTitle="Parcela" [itemPipe]="parcelNamePipe"
                  logGa></gmt-select>

      <gmt-select [multiselect]="true" [data]="list.filter.filters.blockedByUser" field="values"
                  [optionItems]="blockStatuses" selectTitle="Stav blokování" logGa></gmt-select>

      <gmt-select
        [multiselect]="true"
        [data]="list.filter.filters.constructionObjectId"
        [filter]="{filters: {validity: 'valid'}, sortOrder: {sortBy: 'socis'}}"
        field="values"
        enableSelectAll="true"
        [(selectAll)]="list.filter.filters.constructionObjectId.negation"
        resource="construction-objects"
        selectTitle="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}"
        [itemPipe]="constructionObjectNamePipe"
        logGa
      ></gmt-select>

      <span tooltip="Zvolte rozsah ve tvaru &quot;1,2,3&quot; nebo &quot;1-500&quot; nebo &quot;!10002&quot; (vše kromě 10002)" position="center">
          <input type="text" placeholder="Rozsah LV..." [(ngModel)]="list.filter.filters.titleDefinition.values[0].id"/>
      </span>

      <div class="tag" [ngClass]="{active: checkNeighbors()}"
           title="Zobrazení vč. těch LV, na kterých není žádná stavba přímo umístěna, ale jsou dostupné v {{APP_BRAND.PS.TITLE}}"
           (click)="onTitlesCheckboxClicked()">
        <div>Zobrazit sousední LV</div>
      </div>

      <statistics-entity-filter (changed)="statisticsEntityFilterChanged($event)"
                                [filter]="list.filter"
                                (registerCallback)="registerUpdateStatisticsEntityFilterKey($event)"
                                title="Zobrazení listů vlastnictví, které spadají do kategorie příslušné statistiky"
      ></statistics-entity-filter>

      <div class="column-100">
        <input type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id" class="fulltext"/>
      </div>
    </filter>

    <div [loading]="list.loading || !settingsLoaded">
      <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
        <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
        <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
        <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>
      <table-titles [list]="list" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)" *ngIf="settingsLoaded"></table-titles>
      <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
      <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
    </div>

    <help [helpId]="helpIds.PANEL_TITLES" class="cm-help-page"></help>
  </ng-container>
</div>
