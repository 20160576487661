import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { TemplateModel } from '@app/ps/models/template.model';
import { TemplateTypeModel } from '@app/ps/models/template-type.model';
import { ProjectModel } from '@app/ps/models/project.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import {GmtHttpError} from "@app/common/services/http.service";

@Component({
  selector: 'template-list',
  templateUrl: './template-list.component.html',
})
export class TemplateListComponent implements OnInit {

  loading = true;
  templateTypes: TemplateTypeModel[] = [];
  displayed = undefined;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();
  uploadingFiles: UploadFileExtended[] = [];
  public project: ProjectModel;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private uploadService: UploadService,
    private restangular: Restangular,
    private authService: AuthService,
  ) {
    this.onSelectFiles = this.onSelectFiles.bind(this);
    this.onRemoveTemplate = this.onRemoveTemplate.bind(this);
  }

  ngOnInit() {
    this.project = this.authService.getActualProject();
    this.loadAllowedTemplates();
  }

  display(index) {
    this.displayed = index;

    if (this.templateTypes[index].loading === undefined) {
      this.templateTypes[index].loading = true;

      this.loadTemplatesByType(this.templateTypes[index]).then(data => {
        this.templateTypes[index].loading = false;
        this.templateTypes[index].templates = data;
        this.templateTypes[index].modifiable = this.isModifiable();
      });
    }
  }

  onRemoveTemplate(template: TemplateModel) {
    const templates = this.templateTypes[this.displayed].templates;

    if (!template.id) {
      _.pull(templates, template);
    } else {
      this.restangular
        .one('templates', template.id)
        .remove()
        .toPromise()
        .then(() => {
          _.remove(templates, { id: template.id });
        });
    }
  }

  onSelectFiles(files: UploadFileExtended[]) {
    for (const file of files) {
      this.uploadFile(file);
    }
  }

  private uploadFile(file: UploadFileExtended) {
    if (this.uploadingFiles.indexOf(file) === -1) {
      this.uploadingFiles.push(file);
    }

    file.uploading = this.uploadService.upload({
      url: `${this.restBaseUrl}/templates`,
      data: {
        key: this.templateTypes[this.displayed].key,
        filename: file.fileEntry.name,
        file: file.fileEntry,
      },
      headers: { Authorization: this.authService.getToken() },
    }).progress(evt => {
      file.progress = (100.0 * evt.loaded / evt.total);
    }).success(data => {
      this.templateTypes[this.displayed].templates.push(data);
      this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1);
    }).error((error: GmtHttpError) => {
      if (error.status === 400 && error.data.message) {
        file.errorMessage = error.data.message;
      }
      file.error = true;
    });
  }

  private loadAllowedTemplates(): Promise<any> {
    return this.restangular
      .all('templates')
      .all('types-allowed')
      .getList()
      .toPromise()
      .then((data) => {
        this.templateTypes = data.plain();
        this.loading = false;
      });
  }

  private isModifiable() {
    return !this.project.useTemplatesService && (this.authService.hasPermission('manage_templates') || this.authService.hasPermission('admin'));
  }

  private loadTemplatesByType(templateType): Promise<any> {
    return this.restangular
      .all('templates')
      .customPOST({
        filter: {
          filters: {
            key: [templateType.key],
            validity: ['valid'],
          },
        },
      })
      .toPromise();
  }
}
