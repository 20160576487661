import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { IntersectionService } from '@app/dimap/map/services/intersection.service';
import { StateService } from '@uirouter/angular';
import * as L from 'leaflet';

@Component({
  selector: 'map-module-intersection',
  templateUrl: './map-module-intersection.component.html',
})
export class MapModuleIntersectionComponent implements OnInit, OnDestroy {
  @Input() mapId: string;

  public intersectionOptions = [
    {id: 'polyline', value: 'Linie'},
    {id: 'polygon', value: 'Plochy'}
  ];

  public intersectionOptionsSelections = 'polyline';
  private map;
  private intersectPopup;
  private drawKN;
  private layer;

  constructor(
    private mapService: MapService,
    private stateService: StateService,
    private intersectionService: IntersectionService,
  ) {
    this.onDrawCreated = this.onDrawCreated.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
  }

  ngOnInit() {
    this.mapService.getMap(this.mapId).then((map) => {
      this.map = map;
      this.onChangedIntersectionType(this.intersectionOptionsSelections);
    });
  }

  ngOnDestroy() {
    this.stopIntersection();
    this.map = null;
  }

  onChangedIntersectionType(newVal) {
    this.startDrawIntersection(newVal);
  }

  onPopupClose() {
    this.onChangedIntersectionType(this.intersectionOptionsSelections);
  }

  onDrawCreated(evt) {
    this.layer = evt.layer;
    const latLngs = this.layer.getLatLngs();
    let lastLatLngs;
    if (this.layer instanceof L.Polygon) {
      lastLatLngs = latLngs[0][latLngs.length - 1];
    } else {
      lastLatLngs = latLngs[latLngs.length - 1];
    }

    //show popup
    const popupContent = $('<p>');

    $('<a>').attr('class','button').on('click', (evt) => {
      //backend expecting featurecollection
      const geoJson = {
        "type": "FeatureCollection",
        "features": [
          this.layer.toGeoJSON()
        ]
      };
      //start loading
      const el = evt.target;
      el.classList.add('content-loading');

      this.intersectionService.intersectGeometry(geoJson).then((requestID) => {
        //stop loading
        el.classList.remove('content-loading');
        this.stateService.go("dimap.project.propertyStatus", {requestid: requestID});
      },() => {
        //stop loading
        el.classList.remove('content-loading');
        //TODO show error
      });
    }).html('ZOBRAZIT<div class="loading-spinner"></div>').appendTo(popupContent);

    $(popupContent).append(' V MAJETKOPRÁVNÍM PŘEHLEDU');

    this.intersectPopup = L.popup()
                          .setLatLng(lastLatLngs)
                          .setContent(popupContent[0])
                          .openOn(this.map);

    this.map.once('popupclose', this.onPopupClose);
    this.map.addLayer(this.layer);
  }

  stopIntersection() {
    if(this.drawKN) {
      this.drawKN.disable();
      this.map.off(L.Draw.Event.CREATED);
      this.drawKN = null;
      if(this.layer) {
        this.map.removeLayer(this.layer);
      }
      //check if popup exist and is visible in map
      if (this.intersectPopup && this.intersectPopup._map) {
        this.map.off('popupclose', this.onPopupClose);
        this.map.closePopup(this.intersectPopup);
        this.intersectPopup = null;
      }
    }
    L.DomEvent.off(this.map._container, 'keyup', this._cancelDrawing, this);
  };

  startDrawIntersection(type) {
    if (this.drawKN) {
      this.stopIntersection();
    }

    switch (type) {
      case 'polygon':
        this.setDrawSelectPolygonLocalization();
        this.drawKN = new L.Draw.Polygon(this.map, {showLength: false});
        break;
      case 'polyline':
        this.setDrawSelectPolylineLocalization();
        this.drawKN = new L.Draw.Polyline(this.map, {showArea: false,showLength: false});
        break;
    }
    this.drawKN.options.icon = this.getVertexIcon();

    this.drawKN.enable();
    //open popup on end draw
    this.map.once(L.Draw.Event.CREATED, this.onDrawCreated);

    L.DomEvent.on(this.map._container, 'keyup', this._cancelDrawing, this);
  }

  _cancelDrawing(e) {
    if (e.keyCode === 27) {
      this.onChangedIntersectionType(this.intersectionOptionsSelections);
    }
  }

  setDrawSelectPolylineLocalization() {
    L.drawLocal.draw.handlers.polyline.tooltip.start = "Vyberte zájmové území linií.";
    L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
    L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete kreslení.";
  }

  setDrawSelectPolygonLocalization() {
    L.drawLocal.draw.handlers.polygon.tooltip.start = "Vyberte zájmové území plochou.";
    L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klikněte pro pokračování.";
    L.drawLocal.draw.handlers.polygon.tooltip.end = "Kliknutím na první bod ukončete kreslení.";
  }

  getVertexIcon() {
    return new L.DivIcon({
      iconSize: new L.Point(8,8),
      className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
    });
  }
}
