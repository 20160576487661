<fieldset class="no-legend">
  <div class="column-100">
    <label class="required">Datum odmítnutí</label>
    <div class="input"><input type="text" pick-a-date [(ngModel)]="data.declinedOwnerDate" [maxToday]="true"/></div>
  </div>
</fieldset>

<fieldset>
  <legend>Důvod odmítnutí</legend>
  <div class="column-100">
    <label class="required">Typ poznámky</label>
    <div class="input">
      <gmt-select [data]="data.note" field="noteType" resource="note-types" [filter]="{filters: {keys: ['ReasonNotSigned', 'ReasonNotSigned-disagreement', 'ReasonNotSigned-priceDisagreement', 'ReasonNotSigned-priceDisagreementConditional', 'ReasonNotSigned-restrictionNotRemovable', 'ReasonNotSigned-restrictionRemovable', 'ReasonNotSigned-promise', 'ReasonNotSigned-healthCondition', 'ReasonNotSigned-otherCaseTypeRequested', 'ReasonNotSigned-otherTechnicalRequirements'], dialogTypes:['BusinessCase']}}"></gmt-select>
    </div>
  </div>
  <div class="column-100">
    <label class="required">Text poznámky</label>
    <textarea [(ngModel)]="data.note.text"></textarea>
  </div>
</fieldset>
