export const options = [
  { id: 1, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. a)' },
  { id: 2, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. b)' },
  { id: 3, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. c)' },
  { id: 4, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. d)' },
  { id: 5, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. e)' },
  { id: 6, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. f)' },
  { id: 7, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. g)' },
  { id: 8, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. h)' },
  { id: 9, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. i)' },
  { id: 10, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. j)' },
  { id: 11, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. k)' },
  { id: 12, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. l)' },
  { id: 13, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. m)' },
  { id: 14, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. n)' },
  { id: 15, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. o)' },
  { id: 16, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. p)' },
  { id: 17, name: 'zákon 340/2015 Sb. §3, odstavec 2, písm. q)' },
];

export const defaultLegalException = options.find((o) => o.id === 8).name;

