<div class="bordered" *ngIf="list.itemCount">
  <div class="table">
    <div class="head-group sticky">
      <div class="row">
        <span class="cell" *ngIf="isChecked"><gmt-checkbox [gmtChecked]="inverseSelection" (click)="onCheckAll()"></gmt-checkbox></span>
        <span *ngIf="!hideColumn('lv')" class="cell">
          <sort-by [selected]="'lv' === list.filter.sortOrder.sortBy" (sort)="onSort('lv', $event)"
                   [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('lv')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('areaName')" class="cell">
          <sort-by [selected]="'area.name' === list.filter.sortOrder.sortBy"
                   (sort)="onSort('area.name', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('areaName')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('owner')" class="cell">{{getColumnName('owner')}}</span>
        <span *ngIf="!hideColumn('case')" class="cell">
          <sort-by [selected]="'countCases' === list.filter.sortOrder.sortBy"
                   (sort)="onSort('countCases', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('case')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('pernamentOccupationState')" class="cell">{{getColumnName('pernamentOccupationState')}}</span>
        <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell">{{getColumnName('overOneYearOccupationState')}}</span>
        <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell">{{getColumnName('underOneYearOccupationState')}}</span>
        <span *ngIf="!hideColumn('easementCaseState')" class="cell">{{getColumnName('easementCaseState')}}</span>
        <span *ngIf="!hideColumn('blocked')" class="cell">
          <sort-by [selected]="'blockedByUser' === list.filter.sortOrder.sortBy"
                   (sort)="onSort('blockedByUser', $event)" [sortDir]="list.filter.sortOrder.direction">
            {{getColumnName('blocked')}}
          </sort-by>
        </span>
        <span *ngIf="!hideColumn('user')" class="cell">{{getColumnName('user')}}</span>
        <span *ngIf="!hideColumn('mandatary')" class="cell">{{getColumnName('mandatary')}}</span>
      </div>
    </div>
    <div class="row-group">
      <a class="row" *ngFor="let title of list.list; trackBy: trackByFn" (click)="onToggleCheckItem(title)"
         [uiSref]="isChecked ? undefined : 'symap.project.titles.detail'" [uiParams]="{id: title.id}">
        <span *ngIf="isChecked" class="cell text-center"><gmt-checkbox [gmtChecked]="isChecked(title) !== inverseSelection"></gmt-checkbox></span>
        <span *ngIf="!hideColumn('lv')" class="cell cell--flex">
          <span class="cell--flex__container">
            <span class="cell--flex__container__main">
              <span>{{ title.lv }}</span>
            </span>
            <span class="cell--flex__container_side">
              <span *ngIf="title.buildingOtherTitleExists || title.buildingParcelOtherTitleExists" title="Budova na jiném LV" class="tag tag--inactive tag--small ml-5">cBUD</span>
              <span *ngIf="title.jpvExists" title="Jiné právní vztahy" class="tag tag--inactive tag--small is-uppercase ml-5">JPV</span>
              <span *ngIf="title.duplicityOwnership === true" title="Duplicitní zápis vlastnictví" class="tag tag--danger tag--small is-uppercase ml-5">DUP</span>
              <span title="Sankce" class="tag tag--danger tag--small is-uppercase ml-5" *ngIf="title.ssEU || title.ssNonEU || title.ssRuskyVliv">Sankce</span>
              <ng-container *ngIf="showNavIcons">
                <a class="pl-5 pt-5" uiSref="symap.project.titles.detail" [uiParams]="{projectKey: customProjectKey, id: title.id}" target="_blank" title="Zobrazit detail akce" (click)="$event.stopPropagation()"><i class="fa fa-eye"></i></a>
                <a class="pl-5 pt-5" uiSref="symap.project.map" [uiParams]="{projectKey: customProjectKey, title: title.id, layer: ['geom_ku', 'kn_latest']}" target="_blank" title="Zobrazit v mapovém okně" (click)="$event.stopPropagation()"><i class="fa fa-map"></i></a>
              </ng-container>
            </span>
          </span>
        </span>
        <span *ngIf="!hideColumn('areaName')" class="cell">{{ title.area.name}}</span>
        <span *ngIf="!hideColumn('owner')" class="cell cell-max-height no-padding">
          <div class="fill-content">
            <cell-ownership
              [count]="title.countOwnerships"
              [ownerships]="title.ownerships"
              [tooltipData]="{filter: {sortOrder: {sortBy: 'subjectName'},filters: {titleId: title.id, validity: 'valid'}}, attributes: {subject: {load: false}}}"
              [tooltipType]="'ownership-list'"
            ></cell-ownership>
          </div>
        </span>
        <span *ngIf="!hideColumn('case')" class="cell no-padding cell-max-height">
          <cell-cases
            [casesCount]="title.countCases"
            [tooltipType]="'cases'"
            [tooltipData]="{filter: {filters: {parcelTitleId: title.id, cancelStatus: 'notCancelled'}}}"
          ></cell-cases>
        </span>

        <!-- trvale zabory -->
        <span *ngIf="!hideColumn('pernamentOccupationState')" class="cell fill cell--chart">
          <settlement-progress-chart
                  [tooltipType]="'parcels'"
                  [tooltipData]="{occupationResolution: title.occupationResolution, occupationCategory: 'permanent'}"
                  [data]="title.occupationResolution | settlementCategory: 'permanent'"
          ></settlement-progress-chart>
        </span>

        <!-- docasne zabory nad jeden rok -->
        <span *ngIf="!hideColumn('overOneYearOccupationState')" class="cell fill cell--chart">
          <settlement-progress-chart
                  [tooltipType]="'parcels'"
                  [tooltipData]="{occupationResolution: title.occupationResolution, occupationCategory: 'overOneYear'}"
                  [data]="title.occupationResolution | settlementCategory: 'overOneYear'"
          ></settlement-progress-chart>
        </span>

        <!-- docasne zabory do jednoho roku -->
        <span *ngIf="!hideColumn('underOneYearOccupationState')" class="cell fill cell--chart">
          <settlement-progress-chart
                  [tooltipType]="'parcels'"
                  [tooltipData]="{occupationResolution: title.occupationResolution, occupationCategory: 'underOneYear'}"
                  [data]="title.occupationResolution | settlementCategory: 'underOneYear'"
          ></settlement-progress-chart>
        </span>

        <!-- vecna bremena -->
        <span *ngIf="!hideColumn('easementCaseState')" class="cell fill cell--chart">
          <settlement-progress-chart
                  tooltipType="easements"
                  [tooltipData]="{occupationResolution: title.occupationResolution, occupationCategory: 'easement'}"
                  [data]="title.occupationResolution | settlementCategory: 'easement'"
          ></settlement-progress-chart>
        </span>

        <span *ngIf="!hideColumn('blocked')" class="cell">
          {{ title.blockedByUser ? "Blokováno" : "-"}}
        </span>
        <span *ngIf="!hideColumn('user')" class="cell" style="line-height:15px;">
          <span *ngIf="isChecked">
            {{ title.user|username}}
          </span>
          <span *ngIf="!isChecked">
            <span gmtAuthNot="assignment_edit">
                {{ title.user|username}}
            </span>
            <span gmtAuth="assignment_edit" class="ib">
              <gmt-select gmtAuth="assignment_edit"
                          (click)="$event.preventDefault(); $event.stopPropagation();" [required]="true"
                          (changed)="updateUser(title.id, title.user.id);" [data]="title"
                          field="user" [optionItems]="users"
                          [itemPipe]="userNamePipe"></gmt-select>
              <span [ngStyle]="{visibility: userSending[title.id] ? 'visible' : 'hidden'}" class="content-loading small-spinner">
                <span class="loader"><span class="loading-spinner"></span></span>
              </span>
            </span>
          </span>
        </span>
        <span *ngIf="!hideColumn('mandatary')" class="cell">
          <div *ngFor="let user of title.caseMandatary">{{ user | username }}</div>
        </span>
      </a>
    </div>
  </div>
</div>
<div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
  Nenalezena žádná data
</div>
