<fieldset class="bordered">
  <legend>Nájemce parcely</legend>

  <div class="column-50">
    <label>Typ nájmu</label><div class="info">{{ parcel.parcelDetail.rentalType | rentalType}}</div>
  </div>
  <div class="column-50" *ngIf="parcel.parcelDetail.rentalType">
    <label>Nájem uzavřen do data</label><div class="info">{{ parcel.parcelDetail.rentalDateTo | date }}</div>
  </div>
  <div class="column-100" *ngIf="parcel.parcelDetail.rentalType">
    <label>Nájemce</label>
    <div class="info">
      <b>
        {{ { titulZaJmenem: parcel.parcelDetail.tenantTitulZaJmenem, jmeno: parcel.parcelDetail.tenantJmeno, prijmeni: parcel.parcelDetail.tenantPrijmeni, titulPredJmenem: parcel.parcelDetail.tenantTitulPredJmenem, nazev: parcel.parcelDetail.tenantNazev } | subjectName }}
      </b>
    </div>
  </div>
  <div class="column-100" *ngIf="parcel.parcelDetail.rentalType">
    <label>Adresa nájemce</label>
    <div class="info">
      {{ { street: parcel.parcelDetail.tenantUlice, houseNumber: parcel.parcelDetail.tenantCisloDomovni, streetNumber: parcel.parcelDetail.tenantCisloOrientacni, neighbourhood: parcel.parcelDetail.tenantCastObce } | fullstreet }}<br/>
      {{ parcel.parcelDetail.tenantPsc | postCode }} {{ parcel.parcelDetail.tenantObec }}
    </div>
  </div>
  <div class="column-50" *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType === 'OPO'">
    <label>IČ</label><div class="info">{{parcel.parcelDetail.tenantIco }}</div>
    <label>DIČ</label><div class="info">{{parcel.parcelDetail.tenantDic }}</div>
    <label>Zápis</label><div class="info">
    {{ parcel.parcelDetail.tenantBusinessRegisterName || 'registr'}},
    oddíl{{ parcel.parcelDetail.tenantBusinessRegisterSection ? ' ' + parcel.parcelDetail.tenantBusinessRegisterSection : '' }},
    vložka{{ parcel.parcelDetail.tenantBusinessRegisterEntry ? ' ' + parcel.parcelDetail.tenantBusinessRegisterEntry : '' }}</div>
  </div>
  <div class="column-50" *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType === 'OFO'">
    <label>R.Č.</label><div class="info">{{ parcel.parcelDetail.tenantRodneCislo | personalNumber }}</div>
  </div>
  <div class="column-50" *ngIf="parcel.parcelDetail.rentalType">
    <label>Telefon</label><div class="info">{{ parcel.parcelDetail.tenantPhoneNumber }}</div>
  </div>
  <div class="column-50" *ngIf="parcel.parcelDetail.rentalType">
    <label>E-mail</label><div class="info">{{ parcel.parcelDetail.tenantEmail }}</div>
  </div>
</fieldset>
