import { Injectable, Inject } from '@angular/core';
import { OwnershipWithParentModel } from "@app/ps/models/ownership-with-parent.model";
import { CaseOwnershipModel } from "@app/ps/models/case-ownership.model";
import { TitleDetailCreateCaseSelectParentService } from '@app/ps/services/title-detail-create-case-select-parent.service';

@Injectable({
  providedIn: 'root'
})
export class CreateCaseSelectParentOwnershipService {

  constructor(
    private titleDetailCreateCaseSelectParentService: TitleDetailCreateCaseSelectParentService,
  ) { }

  async checkOwnershipsWithParent(caseOwnerships: CaseOwnershipModel[]): Promise<OwnershipWithParentModel[]> {
    return await this.titleDetailCreateCaseSelectParentService.checkOwnershipsWithParent(caseOwnerships);
  }

  needsParentSelection(ownershipsWithParent: OwnershipWithParentModel[]): boolean {
    return this.titleDetailCreateCaseSelectParentService.needsParentSelection(ownershipsWithParent);
  }

  async changedParent(newValue: CaseOwnershipModel, oldValue: CaseOwnershipModel, ownershipWithParentExt: OwnershipWithParentModel, caseOwnerships: CaseOwnershipModel[], ownershipsWithParent: OwnershipWithParentModel[]) : Promise<any> {
    await this.titleDetailCreateCaseSelectParentService.changedParent(newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
  }
}
