<div [loading]="loading">
  <div *ngIf="!loading">
    <title-detail-create-case-select-objects-items
      [data]="data"
      [checklistGroup]="checklistGroup"
      [checklistEasement]="checklistEasement"
      [checklistConstructionObject]="checklistConstructionObject"
      [checklistBuilding]="checklistBuilding"
      [checklistOwnership]="checklistOwnership"
      [titleId]="titleId"
      [titleDataPromise]="titleDataPromise"
      [generalDataPromise]="generalDataPromise"
      (callbackRegister)="callbackRegister($event)"
    ></title-detail-create-case-select-objects-items>

    <div *ngIf="data.obligation.type === 'ExchangeContractLiability'">
      <gmt-labeled-block label="Parcely investora">
        <create-case-select-external-parcels (selected)="onParcelsExternalSelected($event)"></create-case-select-external-parcels>
      </gmt-labeled-block>
    </div>

    <div *ngIf="data.obligation.type === 'InnominateSalesContractLiability'" class="mt-20">
      <gmt-labeled-block label="Předmět případu">
        <fieldset class="no-legend">
          <div class="column-100">
            <label class="required">Popis</label>

            <textarea [(ngModel)]="data.innominationSubject"></textarea>
          </div>
          <div class="column-100">
            <label class="required">Cena [<span class="unit">Kč</span>]</label>
            <div class="input"><input type="text" [(ngModel)]="data.innominationPrice" gmtInputNumber/></div>
          </div>
        </fieldset>
      </gmt-labeled-block>
    </div>

    <div class="mt-20">
      <gmt-labeled-block label="Osoby">
        <div class="bordered">
          <table *ngIf="selection && (data.obligation.type !== 'InnominateSalesContractLiability' || checklistOwnership.checkedItems.length)">
            <thead>
              <tr>
                <th class="min"><gmt-checkbox *ngIf="data.obligation.type !== 'InnominateSalesContractLiability'" (click)="onSelectAllOwnerships()" [gmtChecked]="selection?.checkedAllOwnerships.value"></gmt-checkbox></th>
                <th>Osoba</th>
                <th *ngIf="data.obligation.type !== 'InnominateSalesContractLiability'">Typ</th>
                <th *ngIf="data.obligation.type !== 'InnominateSalesContractLiability'">Podíl</th>
                <th>Adresa</th>
                <th>Rodné číslo / IČ</th>
              </tr>
            </thead>
            <tbody *ngFor="let itemOwnership of (data.obligation.type !== 'InnominateSalesContractLiability' ? dataOwnerships : checklistOwnership.checkedItems); let $indexOwnership = index" class="hover">
              <tr *ngFor="let itemSubject of itemOwnership.ownership.subjects; let $index = index" (click)="selection?.toggleSelectionOwnership(itemOwnership)">
                <td *ngIf="$index === 0" [attr.rowspan]="itemOwnership.ownership.subjects.length">
                  <gmt-checkbox [gmtChecked]="checklistOwnership.isChecked(itemOwnership)" [gmtDisabled]="!selection?.isOwnershipEnabled(itemOwnership)"></gmt-checkbox>
                </td>
                <td><a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></td>
                <td *ngIf="$index === 0 && data.obligation.type !== 'InnominateSalesContractLiability'" [attr.rowspan]="itemOwnership.ownership.subjects.length">{{itemOwnership.ownership.typravNazev}}</td>
                <td *ngIf="$index === 0 && data.obligation.type !== 'InnominateSalesContractLiability'" [attr.rowspan]="itemOwnership.ownership.subjects.length" class="number">
                  {{itemOwnership.ownership.podilCitatel}}/{{itemOwnership.ownership.podilJmenovatel}}
                </td>
                <td>
                  {{itemSubject|address}}
                </td>
                <td class="number">
                  {{itemSubject.rodneCislo|personalNumber}}{{itemSubject.rodneCislo && itemSubject.ico !== 1 && itemSubject.ico ? ' / ' : ''}}{{itemSubject.ico !== 1 ? itemSubject.ico : ''}}
                </td>
              </tr>
              <tr class="bordered-top error-message" *ngIf="!selection?.isOwnershipEnabled(itemOwnership)" [ngClass]="{'bordered-bottom': $indexOwnership < dataOwnerships.length - 1}">
                <td colspan="6"><div *ngFor="let msg of selection.getOwnershipDisabledMessage(itemOwnership)">{{msg}}</div></td>
              </tr>
            </tbody>
          </table>
          <div class="empty-info" *ngIf="data.obligation.type === 'InnominateSalesContractLiability' && !checklistOwnership.checkedItems.length">
            Žádné vybrané osoby
          </div>
        </div>

        <div *ngIf="data.obligation.type === 'InnominateSalesContractLiability' && selection" class="clearfix">
          <div class="column-100 mt-5">
            <div class="input">
              <gmt-select [reloadOptionsRegister]="selection?.subjectReloadRegister" [itemPipe]="subjectNamePipe" [data]="dataSubject" field="subjects" resource="subjects" [filter]="{filters: {validity: 'valid'}, sortOrder: {sortBy: 'prijmeni'}}" (changed)="selection.subjectChanged($event.newValue);dataSubject.subjects = null;"></gmt-select>
              <a class="button ml-5" (click)="selection?.createSubject()" title="Nová osoba"><span class="fa fa-plus"></span></a>
            </div>
          </div>
        </div>
      </gmt-labeled-block>
    </div>

    <div class="mt-10">
      <gmt-button [clickAction]="submit" [gmtDisabled]="!isSelectionValid()">Další</gmt-button>
    </div>
  </div>
</div>
