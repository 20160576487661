﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ParcelService } from '@app/ps/services/parcel.service';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { TitleDetailCreateCaseSelectTitlesService } from '@app/ps/services/title-detail-create-case-select-titles.service';
import { TitleDetailCreateCaseSelectionService } from '@app/ps/services/title-detail-create-case-selection.service';
import { DialogService } from '@app/common/services/dialog.service';
import { CreateCaseSelectTitlesMultipleDialogComponent } from '@app/ps/titles/components/create-case-select-titles-multiple-dialog/create-case-select-titles-multiple-dialog.component';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { CaseOwnershipModel } from '@app/ps/models/case-ownership.model';

@Component({
  selector: 'title-detail-create-case-select-titles',
  templateUrl: './create-case-select-titles.component.html',
})
export class CreateCaseSelectTitlesComponent implements OnInit {
  @Input() data: any;
  @Input() private forwardStep: boolean = true;
  @Output() private submitCallback = new EventEmitter();
  public loading: boolean = false;
  public checklistOwnership: any;
  public checklistGroup: any;
  public checklistEasement: any;
  public checklistBuilding: any;
  public checklistConstructionObject: any;
  public dataTitles: any;
  checklistOwnershipTitle: any;
  displayTitleOwnership: any;

  constructor(
    private authService: AuthService,
    public subjectNamePipe: SubjectNamePipe,
    private parcelService: ParcelService,
    private dialogService: DialogService,
    private titleDetailCreateCaseSelectTitlesService: TitleDetailCreateCaseSelectTitlesService,
    public selection: TitleDetailCreateCaseSelectionService,
  ) {
    this.submit = this.submit.bind(this);
    this.closeSelectOwnershipTitleCancel = this.closeSelectOwnershipTitleCancel.bind(this);
    this.closeSelectOwnershipTitle = this.closeSelectOwnershipTitle.bind(this);
  }

  ngOnInit() {
    this.selection.scope = this;
    this.checklistOwnership = new ChecklistModel();
    this.checklistGroup = new ChecklistModel();
    this.checklistEasement = new ChecklistModel();
    this.checklistBuilding = new ChecklistModel();
    this.checklistConstructionObject = new ChecklistModel(this.data.constructionObjects.map((co) => this.selection.constructionObjectToCase(co, true)));
    for (let i = 0; i < this.data.easements.length; i++) {
      const easement = this.data.easements[i];
      if (easement.parcel.title.id !== this.data.titles[0].id) {
        _.pull(this.data.easements, easement);
        i--;
        this.checklistEasement.checkedItems.push(easement);
      } else {
        easement.constructionObjects.forEach(co => {
          const found = this.checklistConstructionObject.checkedItems.find(item => item.id === co.id);
          if (found) {
            found.selected = found.selectedOriginal = false;
          }
        });
      }
    }
    for (let i = 0; i < this.data.buildings.length; i++) {
      const building = this.data.buildings[i];
      if (building.title.id !== this.data.titles[0].id) {
        _.pull(this.data.buildings, building);
        i--;
        this.checklistBuilding.checkedItems.push(building);
      }
    }
    Object.entries(this.parcelService.groupOccupationsByParcelSingle(
      this.data.occupations
    )).forEach(([key, group]) => {
      if (group.parcel.title.id !== this.data.titles[0].id) {
        group.occupations.forEach(occupation => {
          _.pull(this.data.occupations, occupation);
        });
        this.checklistGroup.checkedItems.push(group);
      } else {
        // if construction object contains any easements or occupations of principal title do not allow deselection
        group.occupations.forEach(occupation => {
          occupation.constructionObjects.forEach(co => {
            const found = this.checklistConstructionObject.checkedItems.find(item => item.id === co.id);
            if (found) {
              found.selected = found.selectedOriginal = false;
            }
          });
        });
      }
    });
    _.pullAll(this.data.constructionObjects, this.checklistConstructionObject.checkedItems.filter(item => item.selected === true).map(item => item.constructionObject));
    this.checklistOwnershipTitle = new ChecklistModel();

    this.loadTitles();
  }

  submit() {
    this.checklistGroup.checkedItems.forEach((group) => {
      group.occupations.forEach((occupation) => {
        this.data.occupations.push(occupation);
      });
    });

    this.checklistBuilding.checkedItems.forEach((building) => {
      this.data.buildings.push(building);
    });
    this.checklistEasement.checkedItems.forEach((easement) => {
      delete easement.cases;
      this.data.easements.push(easement);
    });

    this.checklistConstructionObject.checkedItems.forEach((caseConstructionObject) => {
      if (!this.data.constructionObjects.some((constructionObject) => constructionObject.id === caseConstructionObject.id)) {
        this.data.constructionObjects.push(caseConstructionObject.constructionObject);
      }
    });

    this.checklistOwnershipTitle.checkedItems.forEach((ownershipTitle) => {
      this.data.caseOwnerships.push({ownership: ownershipTitle.ownership});
      this.data.titles.push(ownershipTitle.ownership.title);
    });
    return this.submitCallback.emit();
  }

  getSubjectName(): string {
    return this.dataTitles[0].ownership.subjects.map(this.subjectNamePipe.transform).join(', ');
  }

  async loadTitles() {
    const notParentOwnerships = this.data.caseOwnerships.filter((caseOwnership) => caseOwnership.isParent !== true);
    if (this.data.titles.length > 1 || notParentOwnerships.length === 1 &&
            this.data.obligation.type !== 'InnominateSalesContractLiability' &&
            (this.data.obligation.type !== 'RealBurdenLiability' || !this.data.caseOwnerships.some((dataOwnership) => dataOwnership.ownership.podilCitatel !== dataOwnership.ownership.podilJmenovatel))
            ) {
      return this.titleDetailCreateCaseSelectTitlesService.loadTitlesBySubject(
              notParentOwnerships[0].ownership.subjects[0].id,
              this.data.titles[0].id,
              this.data.obligation.type,
              !!this.data.fromCase).promise.then(async (dataTitles) => {
        if (this.data.titles.length > 1) {
          this.dataTitles = dataTitles.list;
          // remove caseOwnerships
          this.data.titles = [this.data.titles[0]];
          _.remove(this.data.caseOwnerships, (caseOwnership: CaseOwnershipModel) => caseOwnership.ownership.title.id !== this.data.titles[0].id)
            .forEach(caseOwnership => {
              const titleOwnership = this.dataTitles.find((dataTitles) => dataTitles.ownership.id === caseOwnership.ownership.id);
              this.checklistOwnershipTitle.toggleSelection(titleOwnership);
              this.checklistOwnership.toggleSelection(titleOwnership);
          });
        } else if (dataTitles.list.length && !this.data.fromCase) {
          this.dataTitles = dataTitles.list;
          if (this.forwardStep) {
            const dialog = this.dialogService.open(CreateCaseSelectTitlesMultipleDialogComponent, {
              data: { subjectName: this.getSubjectName(), obligationName: this.data.obligation.name }
            });

            const sub = dialog.afterClosed.subscribe((res: boolean) => {
              if (!res) {
                this.submitCallback.emit();
              }
              sub.unsubscribe();
            });
          }
        } else if (this.data.fromCase && this.data.fromCase.titles.length > 1) {
          this.dataTitles = dataTitles.list;
          for (const title of this.data.fromCase.titles.slice(1)) {
            const dataTitle = dataTitles.list.find(dataTitle => dataTitle.ownership.lv === title.lv && dataTitle.ownership.katuzeKod === title.area.id);
            if (dataTitle) {
              this.toggleSelectionOwnershipTitle(dataTitle);
              await dataTitle.itemsSelected;
            }
          }
          this.submit();
        } else {
          this.submitCallback.emit({skipped: true});
        }
      });
    } else {
      this.submitCallback.emit({skipped: true});
    }
  }

  toggleSelectionOwnershipTitle(titleOwnership, checkboxClick = true) {
    if (!this.selection.isOwnershipEnabled(titleOwnership)) {
      return;
    }
    let checked = true;
    if (checkboxClick || !this.checklistOwnershipTitle.isChecked(titleOwnership)) {
      checked = this.checklistOwnershipTitle.toggleSelection(titleOwnership);
      this.checklistOwnership.toggleSelection(titleOwnership);
    }

    if (!checked && titleOwnership.unselect) {
      titleOwnership.unselect();
    } else if (checked) {
      this.checklistConstructionObject.checkedItems.forEach((dataConstructionObject) => {
        dataConstructionObject.easements = [];
        dataConstructionObject.occupations = [];
      });
      this.displayTitleOwnership = titleOwnership;
    }
  }

  closeSelectOwnershipTitleCancel() {
    this.toggleSelectionOwnershipTitle(this.displayTitleOwnership);
    delete this.displayTitleOwnership;
  }

  closeSelectOwnershipTitle() {
    if (this.displayTitleOwnership.isSelectionValidLocal()) {
      delete this.displayTitleOwnership;
    }
  }
}
