<ng-template #layers let-group="group">
  <ul *ngFor="let l of getLayers(group)">
    <li (click)="l.setVisible(!l.getVisible())">
      <span>
        <div class="switch">
          <span class="selector">
            <span *ngIf="!l.getVisible()" class="fa fa-plus"></span>
            <span *ngIf="l.getVisible()" class="icon">
              <img *ngIf="l.get('icon')" src="{{ l.get('icon') }}"/>
              <span *ngIf="!l.get('icon')" class="col-rect" [ngStyle]="{ border: '1px solid ' + l.get('color'), background:  l.get('color') + '50' }"></span>
            </span>
          </span>
          <span class="title">{{ l.get('name') }}</span>
        </div>
      </span>
    </li>
  </ul>
</ng-template>

<div class="detail">
  <div class="row mb-40">
    <div class="col-6"><h1>Zobrazení VFZE</h1></div>
    <div *ngIf="map" class="col-6 text-right">
      <action-buttons name="Možnosti">
        <a uiSref="vfze.validation" class="bordered-bottom p-10"><i class="fa fa-plus"></i> Nahrát jiné VFZE</a>
        <a (click)="onGmlDownload()" class="bordered-bottom p-10"><i class="fa fa-download"></i> Stáhnout jako GML</a>
        <a (click)="onCsvDownload()" class="p-10"><i class="fa fa-download"></i> Stáhnout jako CSV</a>
        <a (click)="onDxfDownload()" class="p-10"><i class="fa fa-download"></i> Stáhnout jako DXF</a>
      </action-buttons>
    </div>
  </div>

  <div [loading]="loading">
    <div *ngIf="!loading" class="flexbox-container">
      <div *ngIf="!vfzeFileContent" class="jumbotron text-center full-width">
        Není vložen soubor formátu VFZE. Nejdříve vložte soubor.
        <br><br>
        <a uiSref="vfze.validation" class="button">Vložit soubor</a>
      </div>

      <div *ngIf="vfzeFileContent">
        <div *ngIf="map" class="map-layer-selector pt-7">
          <div id="ls-selector">
            <div class="group">
              <div class="group-title">Základní mapy</div>
              <ng-container *ngTemplateOutlet="layers, context:{group: 'base'}"></ng-container>
            </div>
            <div class="group">
              <div class="group-title">Vrstvy VFZE</div>
              <ng-container *ngTemplateOutlet="layers, context:{group: 'vfze'}"></ng-container>
            </div>
          </div>
        </div>

        <div id="map">
          <div *ngIf="clickedFeatures.length" class="cm-map-infobox">
            <div class="content">
              <perfect-scrollbar [ngStyle]="{'max-height': clickedFeaturesMaxHeight || '700px'}">
                <div class="content-layers">
                  <ng-container *ngFor="let group of clickedFeatures">
                    <div *ngFor="let properties of group.features" class="layer-item">
                      <h2>{{ group.name }}</h2>
                      <div *ngFor="let p of properties" class="column-100">
                        <label>{{ p.name }}</label>
                        <ng-container *ngIf="!isArray(p.value)">
                          <div class="info first-level">{{ p.value }}</div>
                        </ng-container>
                        <ng-container *ngIf="isArray(p.value)">
                          <div class="info" style="padding-left: 50px;">
                            <div class="column-100">
                              <div *ngFor="let pp of p.value" class="column-100">
                                <label>{{ pp.name }}</label>
                                <div class="info second-level">{{ pp.value }}</div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <span (click)="clickedFeatures = []" class="popup-close-button">×</span>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
