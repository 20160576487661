<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Nastavení</a>
  </div>

  <h1>Hromadné nastavení cen</h1>

  <div class="mt-20">
    <div class="flexbox-container">
      <div class="content-menu">
        <div
          *ngFor="let step of steps"
          (click)="onStep(step.id)"
          [ngClass]="{ active : step.id === activeStep.id, cancelled: !step.enabled }
        ">{{ step.name }}</div>
      </div>
      <div class="flex-rest">
        <bulk-price-init
          *ngIf="activeStep.id === 'init'"
          [data]="data"
          (submitCallback)="onStep('confirm', true)"
        ></bulk-price-init>
        <bulk-price-confirm
          *ngIf="activeStep.id === 'confirm'"
          [data]="data"
          (submitCallback)="onStep('result', true)"
          (backCallback)="onStep('init', true)"
        ></bulk-price-confirm>
        <bulk-price-result
          *ngIf="activeStep.id === 'result'"
          [data]="data"
          (submitCallback)="onFinish()"
          (backCallback)="onStep('init', true)"
        ></bulk-price-result>
      </div>
    </div>
  </div>

  <help [helpId]="helpIds[activeStep.help]" class="cm-help-page"></help>
</div>
