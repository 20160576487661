import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { OccupationModel } from '@app/ps/models/occupation.model';
import { TooltipDisabledOccupationComponent } from '@app/common/components/tooltip-disabled-occupation/tooltip-disabled-occupation.component';
import {BrandService} from "@app/common/services/brand.service";

@Component({
  selector: 'cell-occupation',
  templateUrl: './cell-occupation.component.html',
  styleUrls: ['./cell-occupation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellOccupationComponent implements OnInit {

  @Input() occupation: OccupationModel;
  @Input() showSwitch = false;
  @Input() showUpdate = false;
  @Input() type: 'occupation' | 'phase' = 'occupation';

  tooltipComponent = TooltipDisabledOccupationComponent;

  constructor(
    private brandService: BrandService,
  ) {}

  ngOnInit() {
    this.showUpdate = this.showUpdate &&
      this.type !== 'phase' &&
      (
        this.occupation.occupationType.category === 'underOneYear' || this.occupation.occupationType.category === 'overOneYear' ||
        ((this.occupation.zabtyp == 3 || this.occupation.zabtyp == 7 || this.occupation.zabtyp == 12 || this.occupation.zabtyp == 121) && !this.brandService.isVisible('mpv'))
      ) &&
      (this.occupation.countCases - this.occupation.countCasesFuture) === 0
  }
}
