import {Component, Inject, OnInit} from "@angular/core";
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  helpIds = HelpService.HELP_IDS;

  ngOnInit() {}
}
