import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@app/common/utils/string.utils';

@Pipe({
  name: 'postCode'
})
@Injectable({
  providedIn: 'root'
})
export class PostCodePipe implements PipeTransform {

  transform(postCode: any): string {
    if (!postCode) {
      return '';
    }

    const spacePosition = 3;
    const stringVal = StringUtils.valueToString(postCode);
    const withoutSpaces = StringUtils.removeSpaces(stringVal);

    if (withoutSpaces.length === 5 && /^\d+$/.test(withoutSpaces)) {
      return `${withoutSpaces.substr(0, spacePosition)} ${withoutSpaces.substr(spacePosition)}`;
    }

    return stringVal;
  }
}
