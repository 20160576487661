import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';

@Directive({
  selector: '[gmtAuthProject]'
})
export class AuthProjectDirective implements OnInit {
  @Input('actions') actions: string;
  @Input('project') project: string;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.actions && !this.authService.hasPermissionOnProject(this.actions, this.project)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
