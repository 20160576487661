import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import * as L from 'leaflet';
import { Restangular } from 'ngx-restangular';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { saveAs } from 'file-saver';
import { StringUtils } from '@app/common/utils/string.utils';
import { ProjectStatusService } from '@app/ps/services/project-status.service';
import { AuthService } from '@app/common/services/auth.service';
import { MapLayerService } from '@app/map/services/map-layer.service';
import { MapCallbackEnum} from '@app/map/enums/map-callback.enum';
import { FormatType, MapPrintData, MeasureType } from '@app/ps/map/models/map-print.model';

@Component({
  selector: 'map-module-print-project',
  templateUrl: './map-module-print-project.component.html',
  styleUrls: ['./map-module-print-project.component.scss']
})
export class MapModulePrintProjectComponent implements OnInit, OnDestroy {

  @Input() mapId: string;
  @Input() printData: MapPrintData;
  @Input() config: any;
  @Output() visibilityInfoboxCallback =  new EventEmitter();

  private readonly allowedLayers = [
    'base_kn',

    'easement_status2',
    'easement_status2_resolved',
    'easement_status2_in_progress',
    'easement_status2_not_in_progress',

    'temporary_occupation_over_one_year_status',
    'temporary_occupation_over_one_year_status_resolved',
    'temporary_occupation_over_one_year_status_in_progress',
    'temporary_occupation_over_one_year_status_not_in_progress',

    'temporary_occupation_under_one_year_status',
    'temporary_occupation_under_one_year_status_resolved',
    'temporary_occupation_under_one_year_status_in_progress',
    'temporary_occupation_under_one_year_status_not_in_progress',

    'permanent_occupation_status',
    'permanent_occupation_status_resolved',
    'permanent_occupation_status_in_progress',
    'permanent_occupation_status_not_in_progress',
  ];

  open = true;
  map;
  geom: any;
  envelope: any;
  rects = [];
  rectangles = [];
  editRect;
  printStatus = '';
  canPrint = false;

  formatType: FormatType;
  measureType: MeasureType;

  northEast;
  southWest;
  tileWidth = 0;
  tileHeight = 0;
  width = 0;
  height = 0;
  rows = 0;
  columns = 0;
  dx = 0;
  dy = 0;

  editing = false;
  editingFrom;
  editingX = 0;
  editingY = 0;

  formatTypes: FormatType[] = [];
  measureTypes: MeasureType[] = [];

  constructor(
    private mapService: MapService,
    private mapUtilsCrsService: MapUtilsCrsService,
    private restangular: Restangular,
    private projectStatusService: ProjectStatusService,
    private authService: AuthService,
    private mapLayerService: MapLayerService
  ) {
    this.onEditingModeStart = this.onEditingModeStart.bind(this);
    this.onEditingModeStop = this.onEditingModeStop.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onEditingProgress = this.onEditingProgress.bind(this);
    this.onEditingStop = this.onEditingStop.bind(this);
    this.recalculateRect = this.recalculateRect.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }

  ngOnInit() {
    this.visibilityInfoboxCallback.emit({ infoboxVisibility: true });
    this.mapService.getMap(this.mapId).then(map => {
      this.map = map;
    });

    setTimeout(() => {
      this.mapLayerService.fireCallback(MapCallbackEnum.layersVisibilityChanged, this.allowedLayers);
    }, 30);

    this.restangular.one('map/project-geom').get().toPromise().then(r => {
      const data = r.plain();
      this.geom = JSON.parse(data.geomWgs);
      const envelope = JSON.parse(data.envelope);

      this.northEast = { x: envelope.coordinates[0][2][0], y: envelope.coordinates[0][2][1] };
      this.southWest = { x: envelope.coordinates[0][0][0], y: envelope.coordinates[0][0][1] };
    });

    this.formatTypes = this.printData.formatTypes;
    this.measureTypes = this.printData.measureTypes;
  }

  ngOnDestroy() {
    if (this.rects.length) {
      this.rects.forEach(r => r.remove());
      this.rects = [];
    }

    this.map.dragging.enable();
    this.map.off('mousedown', this.onEditingStart);
    this.map.off('mousemove', this.onEditingProgress);
    this.map.off('mouseup', this.onEditingStop);
  }

  private recalculateCommon() {
    if (!(this.measureType && this.formatType)) {
      return;
    }

    this.tileWidth = (this.formatType.width / 1000) * this.measureType.denominator;
    this.tileHeight = (this.formatType.height / 1000) * this.measureType.denominator;

    this.width = this.northEast.x - this.southWest.x + (2 * this.tileWidth);
    this.height = this.northEast.y - this.southWest.y + (2 * this.tileHeight);

    this.rows = Math.ceil(this.height / this.tileHeight);
    this.columns = Math.ceil(this.width / this.tileWidth);
  }

  onFormatTypeChange(formatType) {
    this.formatType = formatType;
    this.recalculateCommon();
    this.recalculateRect();
  }

  onMeasureTypeChange(measureType) {
    this.measureType = measureType;
    this.recalculateCommon();
    this.recalculateRect();
  }

  onEditingModeStart() {
    this.editing = true;
    this.map.dragging.disable();
    this.map.on('mousedown', this.onEditingStart);
    this.map.on('mousemove', this.onEditingProgress);
    this.map.on('mouseup', this.onEditingStop);
  }

  onEditingModeStop() {
    this.editing = false;
    this.map.dragging.enable();
    this.map.off('mousedown', this.onEditingStart);
    this.map.off('mousemove', this.onEditingProgress);
    this.map.off('mouseup', this.onEditingStop);
  }

  private onEditingStart(e) {
    this.editingFrom = e.latlng;
  }

  private onEditingProgress(e) {
    if (this.editingFrom) {
      if (this.editRect) {
        this.editRect.remove();
      }

      const crs = this.mapUtilsCrsService.getCrs('5514');
      const fromJtsk = crs.project(this.editingFrom);
      const toJtsk = crs.project(e.latlng);

      this.editingX = -1 * (fromJtsk.x - toJtsk.x);
      this.editingY = -1 * (fromJtsk.y - toJtsk.y);

      const startX = this.southWest.x - this.tileWidth + this.dx + this.editingX;
      const startY = this.northEast.y + this.tileHeight + this.dy + this.editingY;

      const bbJtsk = {
        northEast: { x: startX + (this.tileWidth * this.columns - 1), y: startY },
        southWest: { x: startX, y: startY - (this.tileHeight * this.rows - 1) },
      };

      const coordinates = this.getCoordinatesFromBoundingBox(bbJtsk, crs);
      this.editRect = L.polygon(coordinates, { color: '#00FF00', weight: 2, fillOpacity: 0.5 }).addTo(this.map);
    }
  }

  private onEditingStop(e) {
    if (this.editRect) {
      this.editRect.remove();
    }
    this.dx = this.dx + this.editingX;
    this.dy = this.dy + this.editingY;
    this.editingX = 0;
    this.editingY = 0;
    this.editingFrom = undefined;
    this.recalculateRect();
  }

  recalculateRect() {
    if (this.rects.length) {
      this.rects.forEach(r => r.remove());
      this.rects = [];
      this.canPrint = false;
    }

    if (!(this.measureType && this.formatType)) {
      return;
    }

    this.rects.push(L.polygon(this.geom.coordinates, {color: '#ce06ba', weight: 1, fillOpacity: 0, opacity: 0.5}).addTo(this.map));

    const crs = this.mapUtilsCrsService.getCrs('5514');

    const startX = this.southWest.x - this.tileWidth + this.dx;
    const startY = this.northEast.y + this.tileHeight + this.dy;

    this.rectangles = [];

    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.columns; col++) {
        const bbJtsk = {
          northEast: { x: startX + (this.tileWidth * col) + this.tileWidth, y: startY - (this.tileHeight * row) },
          southWest: { x: startX + (this.tileWidth * col), y: startY - (this.tileHeight * row) - this.tileHeight },
        };
        const coordinates = this.getCoordinatesFromBoundingBox(bbJtsk, crs);
        const rect = L.polygon(coordinates, {color: '#ce06ba', fillColor: '#ce06ba', weight: 1, fillOpacity: 0.3}).addTo(this.map);

        this.rectangles.push({ boundingBox: bbJtsk, row: row, col: col });
        rect.row = row;
        rect.col = col;
        this.rects.push(rect);
      }
    }

    this.restangular.one('map/intersection').customPOST({rectangles: this.rectangles}).toPromise().then(res => {
      this.rectangles = res.plain().rectangles;
      for (const rec of this.rectangles) {
        if (rec.intersection) {
          const p = this.rects.find(r => r.row === rec.row && r.col === rec.col);
          p.setStyle({fillColor: '#000000', fillOpacity: 0 });
          this.canPrint = true;
        }
      }
    });
  }

  onPrint() {
    const request = {
      rectangles: this.rectangles,
      format: { width: this.formatType.paperWidth, height: this.formatType.paperHeight },
      layers: this.getActiveLayers(),
      denominator: this.measureType.denominator,

      headerLayout: this.formatType.layout.header,
      descriptionBeforeLayout: this.formatType.layout.descriptionBefore,
      mapLayout: this.formatType.layout.map,
      measureLayout: this.formatType.layout.measure,
      pageLayout: this.formatType.layout.page,
      overviewLayout: this.formatType.layout.overview,
      layersLayout: this.formatType.layout.layers,
    };

    return this.convertLayers(request)
      .then(() => {
        return this.restangular.all('map/project-pdf-process')
          .customPOST(request)
          .toPromise()
          .then((res) => {
            this.projectStatusService.refresh(res);
          });
      }).then(() => {
        this.printStatus = 'Generuji. Průběh sledujte v horní liště.';

        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.printStatus = '';
        });
      });

    // return this.convertLayers(request)
    //   .then(() => {
    //     return this.restangular.all('map/project-pdf')
    //       .withHttpConfig({responseType: 'blob'})
    //       .customPOST(request)
    //       .toPromise()
    //       .then((res) => {
    //         saveAs(new Blob([res], { type: 'application/pdf' }), 'necicko.pdf');
    //       });
    //   });
  }

  private getCoordinatesFromBoundingBox(bbJtsk, crs) {
    const corner1Jtsk = { x: bbJtsk.southWest.x, y: bbJtsk.southWest.y }; // levy spodni
    const corner2Jtsk = { x: bbJtsk.northEast.x, y: bbJtsk.southWest.y }; // pravy spodni
    const corner3Jtsk = { x: bbJtsk.northEast.x, y: bbJtsk.northEast.y }; // pravy horni
    const corner4Jtsk = { x: bbJtsk.southWest.x, y: bbJtsk.northEast.y }; // levy horni

    const corner1 = crs.unproject(corner1Jtsk);
    const corner2 = crs.unproject(corner2Jtsk);
    const corner3 = crs.unproject(corner3Jtsk);
    const corner4 = crs.unproject(corner4Jtsk);

    return [
      [corner1.lat, corner1.lng],
      [corner2.lat, corner2.lng],
      [corner3.lat, corner3.lng],
      [corner4.lat, corner4.lng],
      [corner1.lat, corner1.lng],
    ];
  }

  private getActiveLayers(): any[] {
    const layers = [];

    // groups
    for (const group of this.config.layers) {
      // layers
      for (const layer of group.layers) {
        // node
        if (layer.type === MapLayerTypeEnum.node && layer.layers) {
          for (const nestedLayer of layer.layers) {
            if (nestedLayer.visible) {
              layers.push(this.mapLayer(nestedLayer, layer));
            }
          }
        // layer
        } else if (layer.visible) {
          layers.push(this.mapLayer(layer));
        }
      }
    }

    return layers;
  }

  private mapLayer(layer: any, parentLayer?: any) {
    return {
      id: layer.id,
      visible: true,
      title: (parentLayer ? (parentLayer.title + ' - ') : '') + layer.title,
      icon: layer.icon,
      style: layer.style
    };
  }

  private convertLayers(req): Promise<any[]> {
    const promises = [];

    for (const l of req.layers) {
      if (l.icon) {
        const promise = StringUtils.imageToBase64(l.icon).then(data => l.iconBase64 = data);
        promises.push(promise);
      }
    }

    return Promise.all(promises);
  }
}
