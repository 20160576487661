import { BulkOperationTypeConfig } from './bulk-operation-type-config';
import { TableTitlesComponent } from '../components/table-titles/table-titles.component';
import { TableCasesComponent } from '../components/table-cases/table-cases.component';
import { TitleMandataryChangeComponent } from '../components/title-mandatary-change/title-mandatary-change.component';
import { TitlesMandataryChangeConfirmComponent } from '../components/titles-mandatary-change-confirm/titles-mandatary-change-confirm.component';
import { CasesChangeStatusComponent } from '../components/cases-change-status/cases-change-status.component';
import { CasesChangeStatusConfirmComponent } from '../components/cases-change-status-confirm/cases-change-status-confirm.component';
import { CasesGenerateDocumentsComponent } from '../components/cases-generate-documents/cases-generate-documents.component';
import { CasesGenerateDocumentsConfirmComponent } from '../components/cases-generate-documents-confirm/cases-generate-documents-confirm.component';
import { TitlesCreateCasesComponent } from '../components/titles-create-cases/titles-create-cases.component';
import { TitlesCreateCasesConfirmComponent } from '../components/titles-create-cases-confirm/titles-create-cases-confirm.component';
import { TitlesCreateCasesFinishComponent } from '../components/titles-create-cases-finish/titles-create-cases-finish.component';
import { CasesGenerateDocumentsFinishComponent } from '../components/cases-generate-documents-finish/cases-generate-documents-finish.component';
import { SettingsService } from '@app/ps/services/settings.service';

const isVisible = (settingsService: any, table: string, column: string): boolean => !settingsService.shouldHideColumn(table, column);

export class BulkOperationType {
  static readonly CASES = new BulkOperationType({
    key: 'cases',
    filterKey: 'casesFilter',
    title: 'Případy',
    backButtonText: 'Seznam případů',
    firstStepName: 'Výběr případů',
    tableComponent: TableCasesComponent,
    getDefaultFilters(settingsService: SettingsService) {
      const loadCollections = ['caseOwnerships'];
      const columns = ['areaName', 'lv'];
      const shouldLoadTitleAndCadastre = columns.some((col) => isVisible(settingsService, BulkOperationType.CASES.toString(), col));
      const shouldLoadStatus = isVisible(settingsService, BulkOperationType.CASES.toString(), 'caseStatus');

      if (shouldLoadTitleAndCadastre) {
        loadCollections.push('titles');
      }

      if (shouldLoadStatus) {
        loadCollections.push('caseStatusSubjects');
      }

      return {
        loadCollections,
      };
    },
    getActions() {
      return [
        {
          name: 'Změna stavu podle workflow',
          id: 'case-status-change',
          component: CasesChangeStatusComponent,
          confirmComponent: CasesChangeStatusConfirmComponent,
        },
        {
          name: 'Generování dokumentů',
          id: 'generate-documents',
          component: CasesGenerateDocumentsComponent,
          confirmComponent: CasesGenerateDocumentsConfirmComponent,
          finishComponent: CasesGenerateDocumentsFinishComponent,
        },
      ];
    },
  });

  static readonly TITLES = new BulkOperationType({
    key: 'titles',
    filterKey: 'titlesFilter',
    title: 'Listy vlastnictví',
    backButtonText: 'Seznam LV',
    firstStepName: 'Výběr LV',
    tableComponent: TableTitlesComponent,
    getDefaultFilters(settingsService: SettingsService) {
      const loadCollections = ['ownerships'];
      const settlementProgressColumns = [
        'pernamentOccupationState',
        'overOneYearOccupationState',
        'underOneYearOccupationState',
        'easementCaseState',
      ];
      const shouldLoadSettlementProgress = settlementProgressColumns.some((col) => isVisible(settingsService, BulkOperationType.TITLES.toString(), col));

      if (shouldLoadSettlementProgress) {
        loadCollections.push('occupationResolution');
      }

      return {
        loadCollections,
      };
    },
    async getActions(authService: any, settingsService: SettingsService) {
      const actions = [];
      await settingsService.loadSettings(authService.getActualProject().id);
      if (authService.hasPermission('assignment_edit') && !settingsService.shouldHideColumn('titles', 'user')) {
        actions.push({
          name: 'Změna přidělení LV',
          id: 'title-mandatary-change',
          component: TitleMandataryChangeComponent,
          confirmComponent: TitlesMandataryChangeConfirmComponent,
        });
      }
      actions.push({
        name: 'Založení případů',
        id: 'title-create-case',
        component: TitlesCreateCasesComponent,
        confirmComponent: TitlesCreateCasesConfirmComponent,
        finishComponent: TitlesCreateCasesFinishComponent,
      });
      return actions;
    },
  });

  private constructor(public readonly config: BulkOperationTypeConfig) { }

  public toString() {
    return this.config.key;
  }
}
