import { DocumentTypeModel } from '../../common/models/document-type.model';
import { AuthService } from './../../common/services/auth.service';
import { UploadFileExtended } from './../../common/components/fileupload/fileupload.component';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import { Inject, Injectable } from '@angular/core';
import { opinionRequestStatusOptions } from '@app/ps/enums/opinion-request-status.enum';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Injectable({
  providedIn: 'root'
})
export class OpinionRequestService {
  constructor(
    private uploadService: UploadService,
    private restangular: Restangular,
    private authService: AuthService,
  ) {
  }

  create(opinionRequest: OpinionRequestModel): Promise<OpinionRequestModel> {
    return this.restangular
      .all('opinion-requests/create')
      .customPOST(
        opinionRequest,
        '',
        { loadCollections: [ 'parcels', 'buildings' ] }
      )
      .toPromise();
  }

  createByOpinion(opinion: OpinionModel): Promise<OpinionRequestModel> {
    return this.restangular
      .all('opinion-requests/create-by-opinion')
      .post(opinion)
      .toPromise();
  }

  update(opinionRequest: OpinionRequestModel, files: UploadFileExtended[] = []): Promise<OpinionRequestModel> {
    let updatedOpinionRequest: OpinionRequestModel;
    return this.restangular
      .one('opinion-requests', opinionRequest.id)
      .customPUT(
        opinionRequest,
        '',
        { loadCollections: [ 'parcels', 'buildings' ] }
      )
      .toPromise()
      .then(response => {
        updatedOpinionRequest = response.plain();
        return this.createDocuments(updatedOpinionRequest, files);
      })
      .then(() => updatedOpinionRequest);
  }

  createDocuments(opinionRequest: OpinionRequestModel, files: UploadFileExtended[]): Promise<any> {
    const restBaseUrl = this.authService.getActiveApplicationRestUrl();
    const attachmentType = DocumentTypeModel.createById('OPINION_SCAN').id;
    const promises = files.map(file => {
      const sendData = {
        file: file.fileEntry,
        filename: file.fileEntry.name,
        opinionRequestId: opinionRequest.id,
        attachmentType: attachmentType
      };

      return this.uploadService.upload({
        url: restBaseUrl + '/attachments',
        data: sendData,
        headers: { Authorization: this.authService.getToken() }
      });
    });
    return Promise.all(promises);
  }

  getStatusEnumLabel(value: string): string {
    return opinionRequestStatusOptions.find(option => option.id === value).name;
  }

  getStatusEnumAction(value: string): string {
    const option = opinionRequestStatusOptions.find(option => option.id === value);
    return (option.action ? option.action : option.name);
  }

  isStatusDialog(status: string): boolean {
    return opinionRequestStatusOptions.find(option => option.id === status).dialog;
  }
}
