<div class="list">
  <h1>Parcely <cadastre-data-date></cadastre-data-date></h1>

  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">

    <!-- KU -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.areaId"
      field="values"
      [filter]="{ sortOrder: { sortBy: 'name' }}"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.areaId.negation"
      resource="areas"
      selectTitle="Katastrální území"
      logGa
    ></gmt-select>

    <!-- Etapa -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectStageId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'name' } }"
      field="values"
      resource="construction-object-stages"
      selectTitle="{{ 'ETAPA_USEK' | wordtranslate }}"
      logGa
    ></gmt-select>

    <!-- SO -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.constructionObjectId"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'socis' }}"
      field="values"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.constructionObjectId.negation"
      resource="construction-objects"
      selectTitle="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}"
      [itemPipe]="constructionObjectNamePipe"
      logGa
    ></gmt-select>

    <!-- Sousedni parcely -->
    <div
      class="tag"
      [ngClass]="{ active: checkNeighbors() }"
      title="Zobrazení vč. těch parcel, na kterých není žádná stavba přímo umístěna, ale jsou dostupné v {{APP_BRAND.PS.TITLE}}"
      (click)="onParcelsCheckboxClicked()"
    >
      <div>Zobrazit sousední parcely</div>
    </div>

    <!-- Filtr statistik -->
    <statistics-entity-filter
      (changed)="statisticsEntityFilterChanged($event)"
      [filter]="list.filter"
      (registerCallback)="registerUpdateStatisticsEntityFilterKey($event)"
      title="Zobrazení parcel, které spadají do kategorie příslušné statistiky"
    ></statistics-entity-filter>

    <!-- Fulltext -->
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <table-parcels [list]="list" [geometricPlanModuleEnabled]="geometricPlanModuleEnabled" [opinionModuleEnabled]="opinionModuleEnabled" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)" *ngIf="settingsLoaded"></table-parcels>
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  </div>

  <help [helpId]="helpIds.PANEL_PARCELS" class="cm-help-page"></help>
</div>
