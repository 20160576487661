<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Nastavení Webové služby dálkového přístupu (WSDP)</h1>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-12 offset-lg-3 col-lg-6">
                <fieldset>
                    <legend>Přístupové údaje do Webové služby dálkového přístupu (WSDP)</legend>

                    <div class="error-message mb-10" *ngIf="cuzkAccountValidationMessage">{{cuzkAccountValidationMessage}}</div>

                    <div class="column-100">
                        <label class="required">Přihlašovací jméno</label>
                        <div class="input">
                            <input type="text" [(ngModel)]="cuzkWebserviceName"/>
                        </div>
                        <label class="required">Heslo</label>
                        <div class="input">
                            <input type="password" [(ngModel)]="cuzkWebservicePassword"/>
                        </div>
                    </div>
                </fieldset>

                <div class="text-center">
                    <a class="button" *ngIf="backSref" uiSref="{{backSref}}">Ukončit</a>
                    <gmt-button [clickAction]="update" [gmtDisabled]="!isUpdateDisabled()">Uložit</gmt-button>
                </div>
            </div>
        </div>
    </div>
    <help [helpId]="helpIds.PK_SETTINGS_WSDP" class="cm-help-page"></help>
</div>
