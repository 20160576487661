import { Component, Inject, Input, OnInit } from '@angular/core';
import {StateService, TargetState, Transition} from '@uirouter/angular';
import { TransitionService, UIRouterGlobals } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { MenuConfig } from '@app/models/menuConfig';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { ModulesService } from '@app/common/services/modules.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";
import {Restangular} from "ngx-restangular";

@Component({
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  loading = true;
  symapPermissions;
  collapsed = false;
  projectsCollapsed = true;
  usersCollapsed = true;
  managerReportCollapsed = true;
  user: User;
  servicedeskUrl: string;
  menuConfig: MenuConfig[] = [];
  ENV = this.config.ENV;

  constructor(
    private authService: AuthService,
    private brandTranslatePipe: BrandTranslatePipe,
    private uiRouterGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private modulesService: ModulesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject('$transition$') private transition: Transition,
    @Inject(APP_CONFIG) public config,
    @Inject(APP_BRAND) public APP_BRAND,
    @Inject(APPLICATIONS) private applications,
    private localStorageService: LocalStorageService,
    private stateService: StateService,
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: Restangular
  ) {
  }

  async ngOnInit() {
    await this.authService.checkProjectStatus(this.transition, this.applications.landing.name, null, null, 'error')
      .then(() => { }, (newState: TargetState) => this.stateService.go(newState.name(), newState.params(), newState.options()));

    // Hack - fill symap permissions for use in this module
    if (!this.authService.symapPermissions) {
      await this.restangularSettings
        .one(`permissions/application/${this.applications.sy.name}/user/`, this.authService.getUser().id)
        .customGET('project-permissions')
        .toPromise()
        .then(data => {
          this.authService.symapPermissions = data.plain();
        });
    }

    this.symapPermissions = this.authService.symapPermissions;

    this.loading = false;

    this.authService.setActiveApplication(this.applications.landing.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('landing');

    this.collapsed = this.localStorageService.get('menuCollapsed');
    const projectsCollapsed: any = this.localStorageService.get('projectsMenuCollapsed');
    const usersCollapsed: any = this.localStorageService.get('usersMenuCollapsed');
    const managerReportCollapsed: any = this.localStorageService.get('managerReportMenuCollapsed');

    if (projectsCollapsed !== null) {
      this.projectsCollapsed = projectsCollapsed;
    }

    if (usersCollapsed !== null) {
      this.usersCollapsed = usersCollapsed;
    }

    if (managerReportCollapsed !== null) {
      this.managerReportCollapsed = managerReportCollapsed;
    }

    this.user = this.authService.getUser();
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();

    this.transitionService.onStart({}, transition => {
      if (transition && transition.to()) {
        this.menuConfig = this.getMenuConfig(transition.to().name);
      }
    });
  }

  getCollapseType(): 'menuCollapsed' | 'projectsMenuCollapsed' | 'usersMenuCollapsed' | 'managerReportMenuCollapsed' {
    if (this.isActive('landing.users')) {
      return 'usersMenuCollapsed';
    }
    if (this.isActive('landing.projects')) {
      return 'projectsMenuCollapsed';
    }
    if (this.isActive('landing.managerReportProjects')) {
      return 'managerReportMenuCollapsed';
    }
    return 'menuCollapsed';
  }

  isCollapsed(): boolean {
    const type = this.getCollapseType();
    if (type === 'menuCollapsed') {
      return this.collapsed;
    } else if (type === 'projectsMenuCollapsed') {
      return this.projectsCollapsed;
    } else if (type === 'usersMenuCollapsed') {
      return this.usersCollapsed;
    } else if (type === 'managerReportMenuCollapsed') {
      return this.managerReportCollapsed;
    }
  }

  onCollapse() {
    const type = this.getCollapseType();

    if (type === 'menuCollapsed') {
      this.collapsed = !this.collapsed;
      this.localStorageService.set('menuCollapsed', this.collapsed);
    } else if (type === 'projectsMenuCollapsed') {
      this.projectsCollapsed = !this.projectsCollapsed;
      this.localStorageService.set('projectsMenuCollapsed', this.projectsCollapsed);
    } else if (type === 'usersMenuCollapsed') {
      this.usersCollapsed = !this.usersCollapsed;
      this.localStorageService.set('usersMenuCollapsed', this.usersCollapsed);
    } else if (type === 'managerReportMenuCollapsed') {
      this.managerReportCollapsed = !this.managerReportCollapsed;
      this.localStorageService.set('managerReportMenuCollapsed', this.managerReportCollapsed);
    }
  }

  onLogout() {
    this.authService.logout();
  }

  isActive(uiSref: string, strict = false): boolean {
    if (strict) {
      return this.uiRouterGlobals.$current.name === uiSref;
    }
    return !!this.uiRouterGlobals.$current.includes[uiSref];
  }

  getMenuConfig(route?: string): MenuConfig[] {
    if (route === 'landing.projects' || (route === undefined && this.isActive('landing.projects'))) {
      return [
        new MenuConfig(this.brandTranslatePipe.transform('ps.projectList'), 'landing.projects', 'fa-list'),
      ];
    }

    if (
      route === 'landing.users' || route === 'landing.users.detail' || (route === undefined && this.isActive('landing.users'))
    ) {
      return [
        new MenuConfig(this.brandTranslatePipe.transform('Uživatelé'), 'landing.users', 'fa-users'),
      ];
    }

    if (
      route === 'landing.managerReportProjects' || route === 'landing.managerReportProjects.detail' || (route === undefined && this.isActive('landing.managerReportProjects'))
    ) {
      return [
        new MenuConfig(this.brandTranslatePipe.transform('Manažerský report'), 'landing.managerReportProjects', 'fa-users'),
      ];
    }

    const menu = [
      new MenuConfig('Úvodní stránka', 'landing.home', 'fa-home'),
    ];

    if (this.authService.hasPermissionOnProject('web', undefined, this.symapPermissions)) {
      menu.push(new MenuConfig(this.brandTranslatePipe.transform('ps.projectList'), 'landing.projects', 'fa-list'));
    }

    if (this.authService.isAuthenticatedApplication(this.applications.sv.name)) {
      menu.push(new MenuConfig('Správa vzorů', 'sv.samples', 'fa-folder-open'));
    }

    if (this.authService.isAuthenticatedApplication(this.applications.dashboard.name) && this.APP_BRAND.NAME !== 'SZ') {
      menu.push(new MenuConfig(this.brandTranslatePipe.transform('dashboard.name'), 'dashboard.projects', 'fa-list-ul'));
    }

    if ((this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') && this.authService.hasPermissionOnProject('manager_report', undefined, this.symapPermissions)) {
      menu.push(new MenuConfig('Manažerský report', 'landing.managerReportProjects', 'fa-bar-chart'));
    }

    const isAdmin = this.user.roles.filter(r => r.application === 'SY' && r.role === 'SUPER_ADMIN').length > 0;
    if (isAdmin || this.authService.isAuthenticatedApplication(this.applications.vfze.name)) {
      menu.push(new MenuConfig('Validátor VFZE', 'vfze.validation', 'fa-check'));
    }

    if (isAdmin) {
      menu.push(new MenuConfig(this.brandTranslatePipe.transform('Uživatelé'), 'landing.users', 'fa-users'));
    }

    if (this.APP_BRAND.HELP) {
      menu.push(new MenuConfig('Nápověda', undefined, 'fa-question', undefined, undefined, this.APP_BRAND.HELP, '_blank'));
    }

    return menu;
  }
}
