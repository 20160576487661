<div [loading]="loading">
  <div *ngIf="!loading">
    <div *ngIf="dataOccupationGroups.length || data.easements?.length">
      <gmt-labeled-block label="Parcely">
        <div class="bordered" *ngIf="dataOccupationGroups.length">
          <table>
            <thead>
            <tr>
              <th>Parcela</th>
              <th>Ochrana</th>
              <th>{{'ETAPA_USEK'|wordtranslate}}</th>
              <th>Zábor</th>
              <th>{{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}</th>
              <th *ngIf="data.obligation.computePrice">Cena [<span class="unit">Kč</span>]</th>
              <th *ngIf="data.obligation.computePrice">Posudek</th>
            </tr>
            </thead>
            <tbody class="hover" *ngFor="let occupationGroup of dataOccupationGroups; let $indexGroup = index">
            <tr *ngFor="let occupation of occupationGroup.occupations; let $index = index">
              <td class="no-padding" *ngIf="$index === 0" [attr.rowspan]="occupationGroup.occupations.length">
                <cell-parcel
                  [parcel]="occupationGroup.parcel"
                  tooltipType="parcel"
                  [tooltipData]="{ parcel: occupationGroup.parcel }"
                  [showTitle]="data.titles.length > 1"
                ></cell-parcel>
              </td>
              <td *ngIf="$index === 0" [attr.rowspan]="occupationGroup.occupations.length">
                <div class="comma-list"><span *ngFor="let itemProtection of occupationGroup.parcel.parcelProtections">{{itemProtection.nazevOchrany}}</span></div>
                <div class="comma-list" *ngIf="occupationGroup.parcel.bpejList.length > 0">(<span *ngFor="let itemBpej of occupationGroup.parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
              </td>
              <td class="number">{{occupation.zabst}}</td>
              <td class="no-padding cell-max-height">
                <cell-occupation [occupation]="occupation"></cell-occupation>
              </td>
              <td>
                <div *ngFor="let constructionObject of occupation.constructionObjects">
                  <a uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: constructionObject.id }" *ngIf="!constructionObject.endDate">
                    {{constructionObject|constructionObjectName}}
                  </a>
                </div>
              </td>
              <td *ngIf="data.obligation.computePrice && $index === 0" [attr.rowspan]="occupationGroup.occupations.length" class="number">
                {{occupationGroup.price|number}}
              </td>
              <td *ngIf="data.obligation.computePrice && $index === 0" [attr.rowspan]="occupationGroup.occupations.length">
                {{occupation.priceAssessmentYear ? occupation.priceAssessmentYear : occupationGroup.priceSource}}
              </td>
            </tr>
            <tr *ngIf="occupationGroup.compensationParcelPrices?.length">
              <td colspan="7" class="bordered-top" [ngClass]="{'bordered-bottom': $indexGroup < dataOccupationGroups.length - 1}">
                Ke smlouvě je připojena náhrada za trvalý porost podle <span class="comma-list"><span *ngFor="let parcelPrice of occupationGroup.compensationParcelPrices">{{parcelPrice.opinion.priceType === 'E' ? 'znaleckého posudku' + (parcelPrice.opinion.expertOpinionNumber ? ' ' + parcelPrice.opinion.expertOpinionNumber : '') : 'jiného určení ceny' + (parcelPrice.opinion.otherSource ? ' ' + parcelPrice.opinion.otherSource : '')}}</span></span>, v celkové ceně {{occupationGroup.parcel.compensationPrice|number|price}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="bordered" *ngIf="dataEasements.length" [ngClass]="{'mt-20': data.occupations.length}">
          <table>
            <thead>
            <tr>
              <th>Parcela</th>
              <th>Ochrana</th>
              <th>{{ 'CONSTRUCTION_OBJECT' | wordtranslate }}</th>
              <th>Plocha VB [<span class="unit">m<sup>2</sup></span>]</th>
              <th>Délka VB [<span class="unit">m</span>]</th>
              <th>Poloha VB</th>
              <th *ngIf="data.obligation.computePrice && !easementPriceByShare(data.obligation)">Cena [<span class="unit">Kč</span>]</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let caseEasement of dataEasements">
              <td class="no-padding">
                <cell-parcel
                  [parcel]="caseEasement.easement.parcel"
                  tooltipType="parcel"
                  [tooltipData]="{ parcel: caseEasement.easement.parcel }"
                  [showTitle]="data.titles.length > 1"
                >
                </cell-parcel>
              </td>
              <td>
                <div>{{ caseEasement.easement.parcel.parcelProtectionsText}}</div>
                <div class="comma-list" *ngIf="caseEasement.easement.parcel.bpejList.length > 0">(<span *ngFor="let itemBpej of caseEasement.easement.parcel.bpejList">{{itemBpej.bpejKod}}</span>)</div>
              </td>
              <td class="comma-list">
                <gmt-construction-object-list [showAdministrator]="true" [constructionObjects]="caseEasement.easement.constructionObjects"></gmt-construction-object-list>
              </td>
              <td class="number">{{caseEasement.easement.vymera}}</td>
              <td class="number">{{caseEasement.easement.delka}}</td>
              <td class="text-center">{{caseEasement.easement | easementOccupation}}</td>
              <td *ngIf="data.obligation.computePrice && !easementPriceByShare(data.obligation)" class="number">{{caseEasement.price}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <div *ngIf="data.parcelsExternal?.length > 0">
      <gmt-labeled-block label="Parcely investora">
        <table-parcels-small
          [data]="data.parcelsExternal"
          [columns]="PARCEL_COLS"
        ></table-parcels-small>
      </gmt-labeled-block>
    </div>

    <div class="mt-20" *ngIf="dataBuildings.length">
      <gmt-labeled-block label="Budovy">
        <div class="bordered">
          <table>
            <thead>
            <tr>
              <th>Parcela</th>
              <th>LV parcely</th>
              <th>Část obce</th>
              <th>Číslo budovy</th>
              <th>Typ a Využití</th>
              <th>Ochrana</th>
              <th *ngIf="data.obligation.computePrice">
                Cena [<span class="unit">Kč</span>]
              </th>
              <th *ngIf="data.obligation.computePrice">
                Posudek
              </th>
            </tr>
            </thead>
            <tbody *ngFor="let itemBuilding of dataBuildings" class="hover">
            <tr *ngFor="let itemParcel of itemBuilding.building.parcels; let $index = index">
              <td class="no-padding cell-max-height">
                <cell-parcel
                  [parcel]="itemParcel"
                  tooltipType="parcel"
                  [tooltipData]="{ parcel: itemParcel }"
                ></cell-parcel>
              </td>
              <td class="number">{{itemParcel.lv}}</td>
              <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building.castobce}}</td>
              <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building | buildingNumber:true}}</td>
              <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length">{{itemBuilding.building.typ}}<br>{{itemBuilding.building.vyuziti}}</td>
              <td *ngIf="$index === 0" [attr.rowspan]="itemBuilding.building.parcels.length"><span class="comma-list"><span *ngFor="let itemProtection of itemBuilding.building.buildingProtections">{{itemProtection.nazevOchrany}}</span></span></td>
              <td [attr.rowspan]="itemBuilding.building.parcels.length" *ngIf="data.obligation.computePrice && $index === 0" class="number">
                {{itemBuilding.price|number}}
              </td>
              <td [attr.rowspan]="itemBuilding.building.parcels.length" *ngIf="data.obligation.computePrice && $index === 0" class="number">
                {{itemBuilding.priceSource}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <div *ngIf="dataConstructionObjects.length" class="mt-20">
      <gmt-labeled-block label="{{ 'CONSTRUCTION_OBJECT_PLURAL_NOMINATIV' | wordtranslate:true }}">
        <div class="bordered">
          <table>
            <thead>
            <tr>
              <th>{{ "CONSTRUCTION_OBJECT" | wordtranslate:true }}</th>
              <th class="comma-list"><span *ngIf="data.occupations.length">Zábory</span><span *ngIf="data.easements.length">VB</span></th>
            </tr>
            </thead>
            <tr *ngFor="let itemConstructionObject of dataConstructionObjects">
              <td>
                <a (click)="$event.stopPropagation();" uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: itemConstructionObject.constructionObject.id }">
                  {{itemConstructionObject.constructionObject|constructionObjectName}}
                </a>
              </td>
              <td>
                <div *ngFor="let occupation of itemConstructionObject.occupations">
                  <b>{{occupation.zabcis}}</b>
                  <div *ngIf="occupation.nabyvatel">Nabyvatel: {{occupation.nabyvatel}}</div>
                  <div *ngIf="occupation.investor">Investor: {{occupation.investor}}</div>
                  <div>{{occupation.zabtyptx}} ({{occupation.zabvym}})</div>
                </div>
                <div *ngFor="let easement of itemConstructionObject.easements">
                  <b>{{easement.geometricPlan.cis}}</b>
                  <div class="comma-list">
                    <span *ngIf="easement.delka" class="unit">{{easement.delka}} m</span>
                    <span *ngIf="easement.vymera" class="unit">{{easement.vymera}} m<sup>2</sup></span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <div *ngIf="data.obligation && data.obligation.type === 'InnominateSalesContractLiability'" class="mt-20">
      <gmt-labeled-block label="Předmět případu">
        <fieldset class="no-legend">
          <div class="column-100">
            <label>Popis</label><div class="info">{{data.innominationSubject}}</div>
            <label>Cena</label><div class="info">{{data.innominationPrice|price}}</div>
          </div>
        </fieldset>
      </gmt-labeled-block>
    </div>

    <div class="mt-20">
      <gmt-labeled-block label="Osoby">
        <div class="bordered">
          <table>
            <thead>
            <tr>
              <th>Osoba</th>
              <th *ngIf="data.obligation.type !== 'InnominateSalesContractLiability'">Typ</th>
              <th *ngIf="data.obligation.type !== 'InnominateSalesContractLiability'">Podíl</th>
              <th>Adresa</th>
              <th>Rodné číslo / IČ</th>
              <th *ngIf="data.obligation.computePrice || easementPriceByShare(data.obligation)">Cena [<span class="unit">Kč</span>]</th>
            </tr>
            </thead>
            <tbody *ngFor="let mappedCaseOwnership of flatCaseOwnerships" class="hover">
            <tr *ngFor="let itemSubject of mappedCaseOwnership.caseOwnership.ownership.subjects; let $index = index">
              <td><a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></td>
              <td *ngIf="$index===0 && data.obligation.type !== 'InnominateSalesContractLiability'" [attr.rowspan]="mappedCaseOwnership.caseOwnership.ownership.subjects.length">
                {{mappedCaseOwnership.caseOwnership.ownership.typravNazev}}
              </td>
              <td *ngIf="$index===0 && data.obligation.type !== 'InnominateSalesContractLiability'" [attr.rowspan]="mappedCaseOwnership.caseOwnership.ownership.subjects.length">
                <span>{{mappedCaseOwnership.caseOwnership.ownership.podilCitatel}}/{{mappedCaseOwnership.caseOwnership.ownership.podilJmenovatel}}</span>
                <div *ngIf="data.titles.length > 1">LV{{mappedCaseOwnership.caseOwnership.ownership.title|titleName}}</div>
              </td>
              <td>
                {{itemSubject|address}}
              </td>
              <td>
                {{itemSubject.rodneCislo|personalNumber}}{{itemSubject.rodneCislo && itemSubject.ico !== 1 && itemSubject.ico ? ' / ' : ''}}{{itemSubject.ico !== 1 ? itemSubject.ico : ''}}
              </td>
              <td class="number" *ngIf="!easementPriceByShare(data.obligation) && data.obligation.computePrice && $index===0" [attr.rowspan]="mappedCaseOwnership.caseOwnership.ownership.subjects.length">
                {{mappedCaseOwnership.caseOwnership.price|number}}
              </td>
              <td *ngIf="easementPriceByShare(data.obligation) && $index===0" [attr.rowspan]="mappedCaseOwnership.caseOwnership.ownership.subjects.length">
                <input *ngIf="!mappedCaseOwnership.caseOwnership.isParent" type="text" class="w-inherit" size="6" [(ngModel)]="mappedCaseOwnership.caseOwnership.easementPriceByShare" (change)="easementPriceByShareUpdated()" [gmtInputNumber]="true"/>
              </td>
            </tr>
            <tr *ngFor="let itemCaseOwnership of mappedCaseOwnership.parentCaseOwnerships" class="cancelled">
              <td><a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemCaseOwnership.ownership.subjects[0].id, tab: 'about'}">{{itemCaseOwnership.ownership.subjects[0] | subjectName}}</a></td>
              <td>{{itemCaseOwnership.ownership.typravNazev}}</td>
              <td></td>
              <td>
                {{itemCaseOwnership.ownership.subjects[0] | address}}
              </td>
              <td>
                {{itemCaseOwnership.ownership.subjects[0].rodneCislo|personalNumber}}{{itemCaseOwnership.ownership.subjects[0].rodneCislo && itemCaseOwnership.ownership.subjects[0].ico !== 1 && itemCaseOwnership.ownership.subjects[0].ico ? ' / ' : ''}}{{itemCaseOwnership.ownership.subjects[0].ico !== 1 ? itemCaseOwnership.ownership.subjects[0].ico : ''}}
              </td>
              <td *ngIf="easementPriceByShare(data.obligation) || data.obligation.computePrice"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <div class="mt-20 clearfix" *ngIf="showPrice()">
      <div class="price-summary">Cena celkem {{priceSummary|number|price}}</div>
    </div>

    <div class="mt-20">
      <gmt-labeled-block label="Omezení vlastnického práva">
        <div class="bordered" [loading]="dataOtherLegalRelations?.loading">
          <div *ngIf="restrictions?.length === 0" class="empty-info">Žádná omezení</div>
          <table *ngIf="restrictions?.length > 0">
            <tbody>
            <tr *ngFor="let itemRestriction of restrictions">
              <td><b>{{itemRestriction.nazev}}</b>{{itemRestriction.popisPravnihoVztahu ? ' (' + itemRestriction.popisPravnihoVztahu + ')' : ''}}
                <div *ngIf="itemRestriction.subjectsTo.length || itemRestriction.parcelTo || itemRestriction.buildingTo">
                  K: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsTo">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemRestriction.parcelTo">{{itemRestriction.parcelTo|parcelnumber}}</span>
                    <span *ngIf="itemRestriction.buildingTo">stavba {{itemRestriction.buildingTo | buildingNumber}} na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemRestriction.buildingTo.parcels">{{itemParcel|parcelnumber}}{{itemParcel.soucasti ? ' (součástí)' : ''}}</span></span></span></span>
                </div>
                <div *ngIf="itemRestriction.subjectsFor.length || itemRestriction.parcelFor">
                  Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemRestriction.subjectsFor">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemRestriction.parcelFor">p.č. {{itemRestriction.parcelFor|parcelnumber}}</span></span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <div class="mt-20">
      <gmt-labeled-block label="Jiné zápisy">
        <div class="bordered" [loading]="dataOtherLegalRelations?.loading">
          <div *ngIf="otherEntries?.length === 0" class="empty-info">Žádné zápisy</div>
          <table *ngIf="otherEntries?.length > 0">
            <tbody>
            <tr *ngFor="let itemOtherEntry of otherEntries">
              <td>
                <b>{{itemOtherEntry.nazev}}</b>{{itemOtherEntry.popisPravnihoVztahu ? ' (' + itemOtherEntry.popisPravnihoVztahu + ')' : ''}}
                <div *ngIf="itemOtherEntry.subjectsTo.length || itemOtherEntry.parcelTo || itemOtherEntry.buildingTo">
                  K: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsTo">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemOtherEntry.parcelTo">p.č. {{itemOtherEntry.parcelTo|parcelnumber}}</span></span>
                  <span *ngIf="itemOtherEntry.buildingTo">stavba {{itemOtherEntry.buildingTo | buildingNumber }} na parcele <span class="comma-list"><span *ngFor="let itemParcel of itemOtherEntry.buildingTo.parcels">{{itemParcel|parcelnumber}}{{itemParcel.soucasti ? ' (součástí)' : ''}}</span></span></span>
                </div>
                <div *ngIf="itemOtherEntry.subjectsFor.length || itemOtherEntry.parcelFor">
                  Pro: <span class="comma-list"><span *ngFor="let itemSubject of itemOtherEntry.subjectsFor">
                    <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: itemSubject.id, tab: 'about'}">{{itemSubject | subjectName}}</a></span>
                    <span *ngIf="itemOtherEntry.parcelFor">p.č. {{itemOtherEntry.parcelFor|parcelnumber}}</span></span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </gmt-labeled-block>
    </div>

    <fieldset class="mt-20" *ngIf="(restrictions?.length > 0 || otherEntries?.length > 0) && requiresAcceptance()">
      <div (click)="acceptRestrictions()" class="column-100">
        <div class="info">
          <gmt-checkbox [gmtChecked]="acceptedRestrictions"></gmt-checkbox>
        </div>
        <div class="info required">&nbsp;Beru na vědomí uvedená omezení (a jiné zápisy) k parcele/vlastníkovi</div>
      </div>
    </fieldset>

    <fieldset class="column-100 mt-20" [loading]="loadingSeries">
      <div class="column-100" *ngIf="seriesData.length">
        <label>Číselná řada</label>
        <div class="input"><gmt-select [data]="data" field="series" [optionItems]="seriesData" [itemPipe]="seriesNamePipe"></gmt-select></div>
      </div>

      <div class="column-100" *ngIf="!data.series">
        <label>Číslo případu</label>
        <div class="input"><input type="text" [(ngModel)]="data.mpp.number"/></div>
      </div>

      <div class="column-100">
        <label class="required">Datum založení</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.createdDate" [maxToday]="true"/></div>
      </div>
    </fieldset>

    <div class="mt-20">
      <gmt-button [gmtDisabled]="!createEnabled()" [clickAction]="confirm">Další</gmt-button>
    </div>
  </div>
</div>
