<div>
  <div>
    <fieldset>
      <div class="column-100">
        <gmt-input label="Katastrální území" required="true">
          <gmt-select
            resource="areas"
            [filter]="{ sortOrder: { sortBy: 'name' }}"
            [data]="this"
            field="area"
            selectTitle="Nezvoleno"
          ></gmt-select>
        </gmt-input>

        <gmt-input label="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}" required="true">
          <gmt-select
            resource="construction-objects"
            [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'socis' }}"
            [data]="this"
            field="constructionObject"
            [itemPipe]="constructionObjectNamePipe"
            selectTitle="Nezvoleno"
          ></gmt-select>
        </gmt-input>

        <gmt-input label="Číslo GP" required="true">
          <input type="text" [(ngModel)]="gpNumber"/>
        </gmt-input>

        <gmt-input label="Zhotovitel GP" required="true">
          <input type="text" [(ngModel)]="gpCreator"/>
        </gmt-input>

        <gmt-input label="Datum ověření GP" required="true">
          <input pick-a-date type="text" [(ngModel)]="dateOfCheck"/>
        </gmt-input>

        <gmt-input label="Datum potvrzení GP" required="true">
          <input pick-a-date type="text" [(ngModel)]="dateOfConfirm"/>
        </gmt-input>

        <gmt-input label="Číslo jednací KP" required="true">
          <input type="text" [(ngModel)]="processNumber"/>
        </gmt-input>
      </div>
    </fieldset>
  </div>

  <div class="mt-10">
    <gmt-button [gmtDisabled]="!isFormValid()" [clickAction]="onSubmit">Další</gmt-button>
  </div>
</div>
