import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { StatisticsService } from '@app/ps/services/statistics.service';
import { DatePipe } from '@angular/common';
import { ListService } from '@app/common/services/list.service';
import { StateService } from '@uirouter/angular';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ListModel } from '@app/common/models/list.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { AuthService } from '@app/common/services/auth.service';
import { DialogService } from '@app/common/services/dialog.service';
import { AreaModel } from '@app/common/models/area.model';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { APP_BRAND, APP_CONFIG } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'mpv-statistics',
  templateUrl: './mpv-statistics.component.html',
  styleUrls: ['./mpv-statistics.component.scss']
})
export class MpvStatisticsComponent implements OnInit, OnDestroy {

  project = this.authService.getActualProject();
  stateParams;
  statisticsList: ListModel<any>;
  statisticsOptions: SwitchOption[] = [];
  selectedStatisticsOption: SwitchOption;
  statisticsId: string;
  activeStatistics;
  statusDataList: ListModel<any>;
  currentData;
  expropriation;
  categoryTabs;
  totalPrecheck;
  totalLoading = true;
  totalAvailable;
  totalSectionCollapsed;
  casesSectionCollapsed;
  otherSectionCollapsed;
  entityTab;
  area: AreaModel;
  constructionObject: ConstructionObjectModel;
  statisticsEntityFilterKeyFinished: any[];
  statisticsEntityFilterKeyUnfinished: any[];
  expropriationObligationIds: number[];

  constructionObjectFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  totalCategoryTabs: any[] = [
    {
      name: 'Celkový počet všech případů'
    },
    {
      name: 'Nezaložené případy'
    },
    {
      name: 'Případy v řešení'
    },
    {
      name: 'Vypořádané případy'
    },
    {
      name: 'Vyvlastnění'
    },
  ];

  entityTabs = [
    {
      name: 'Případy',
      id: 'cases',
      sref: 'cases',
      totalCategoryName: 'Celkový počet případů',
      totalCategoryNameRsd: 'Počet případů',
    },
    {
      name: 'Listy vlastnictví',
      id: 'titles',
      sref: 'titles',
      params: { statisticsEntityTab: 'titles' },
      totalCategoryName: 'Celkový počet listů vlastnictví',
      totalCategoryNameRsd: 'Počet listů vlastnictví',
    },
    {
      name: 'Parcely',
      id: 'parcels',
      sref: 'parcels',
      totalCategoryName: 'Celkový počet parcel',
      totalCategoryNameRsd: 'Počet parcel',
    },
    {
      name: 'Vlastníci',
      id: 'subjects',
      sref: 'owners',
      totalCategoryName: 'Celkový počet vlastníků',
      totalCategoryNameRsd: 'Počet vlastníků',
    },
  ];

  private xhrCancelResolve: Function;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APP_CONFIG) private config: any,
    @Inject(RESTANGULAR_CONFIGURATION) private restangularConfig: any,
    private localStorageService: LocalStorageService,
    private restangular: Restangular,
    private dialogService: DialogService,
    private authService: AuthService,
    private statisticsService: StatisticsService,
    private datePipe: DatePipe,
    private listService: ListService,
    private stateService: StateService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    private httpService: HttpService,
  ) {
    this.stateParams = this.stateService.params;
    this.fetchStatusData = this.fetchStatusData.bind(this);
    this.prepareEntityTab = this.prepareEntityTab.bind(this);
    this.changeEntity = this.changeEntity.bind(this);
  }

  async ngOnInit() {
    // init
    const totalSectionCollapsed = this.localStorageService.get('totalSectionCollapsed');
    const casesSectionCollapsed = this.localStorageService.get('casesSectionCollapsed');
    const otherSectionCollapsed = this.localStorageService.get('otherSectionCollapsed');
    const area = this.statisticsService.getStorageKeyValue('area');
    const constructionObject = this.statisticsService.getStorageKeyValue('constructionObject');

    this.totalSectionCollapsed = totalSectionCollapsed === undefined ? false : totalSectionCollapsed;
    this.casesSectionCollapsed = casesSectionCollapsed === undefined ? false : casesSectionCollapsed;
    this.otherSectionCollapsed = otherSectionCollapsed === undefined ? false : otherSectionCollapsed;
    this.area = area ? JSON.parse(area) : undefined;
    this.constructionObject = constructionObject ? JSON.parse(constructionObject) : undefined;

    // load data
    await this.loadStatistics();
    if (this.statisticsList.list.length === 0) {
      return;
    }
    this.loadExpropriationObligationIds();
    this.fetchTotalData();
    this.setupParams();
    this.fetchStatusData(this.statisticsId);
  }

  ngOnDestroy(): void {
    if (this.xhrCancelResolve) {
      this.xhrCancelResolve();
    }
    if (this.statusDataList) {
      this.listService.cancelFetch(this.statusDataList);
    }
  }

  fetchStatusData(statisticsId) {
    if (this.statusDataList) {
      this.listService.cancelFetch(this.statusDataList);
    }
    this.activeStatistics = _.find(this.statisticsList.list, { id: statisticsId });
    this.selectedStatisticsOption = _.find(this.statisticsOptions, { id: statisticsId }) as any;
    this.statisticsId = statisticsId;
    this.refreshState();

    this.currentData = undefined;
    this.expropriation = undefined;
    this.categoryTabs = [];

    this.activeStatistics.categories.forEach((tab, index) => {
      this.categoryTabs.push({
        name: tab.name,
        nameAlternative: tab.nameAlternative,
        splitTab: tab.split,
      });
      if (tab.split) {
        this.categoryTabs.push({
          name: tab.splitName,
          nameAlternative: tab.splitNameAlternative,
        });
      }
    });
    this.categoryTabs.unshift({
      name: this.activeStatistics.defaultCategory,
      nameAlternative: this.activeStatistics.defaultCategoryAlternative,
    });
    this.categoryTabs.unshift({
      name: null,
    });

    const curDate = this.datePipe.transform(new Date(), 'y-MM-dd');

    this.statusDataList = this.listService.createList('statistics/generic', {
      filters: {
        dateTo: [curDate],
        statisticsId : [this.statisticsId],
        areaId: this.area ? [this.area.id] : [],
        constructionObjectId: this.constructionObject ? [this.constructionObject.id] : [],
      },
    });

    return this.listService.fetchResult(this.statusDataList).then((data) => {
      this.currentData = _.keyBy(
        _.map(this.entityTabs, (tab) => {
          return this.prepareEntityTab(data, data.list[0].plot[curDate], tab);
        }),
        tabData => tabData.id
      );
      return this.restangular.one(`statistics/${statisticsId}/expropriation`).customGET('', {
        areaId: this.area ? this.area.id : undefined,
        constructionObjectId: this.constructionObject ? this.constructionObject.id : undefined
      }).toPromise();
    }).then(expropriation => {
      this.expropriation = expropriation.data;
    }).catch((e) => {
      // console.log(e);
    });
  }

  changeEntity(id: string) {
    this.entityTab = id;
    this.refreshState();
  }

  getTotalCategoryName(entityId: string) {
    const entity = _.find(this.entityTabs, { id: entityId });
    return this.APP_BRAND.NAME === 'RSD' ? entity.totalCategoryNameRsd : entity.totalCategoryName;
  }

  onSectionCollapse(name: string) {
    const collapsed = this[name];
    this[name] = !collapsed;
    this.localStorageService.set(name, this[name]);
  }

  onCadastreChanged() {
    this.constructionObjectFilter.areaId.values = this.area ? [this.area.id] : [];
    this.constructionObject = undefined;
    this.fetchStatusData(this.statisticsId);
  }

  private loadStatistics(): Promise<any> {
    this.statisticsList = this.statisticsService.getStatisticsList();
    return this.listService.fetchResult(this.statisticsList).then(() => {
      this.statisticsList.list = this.statisticsList.list.filter(s => s.available);
      const options = [];
      this.statisticsList.list.forEach((s, i) => {
        options.push({id: s.id, value: s.name });

        // resolve categories with same id
        let id = 1;
        s.categories.forEach(category => {
          category.index = id++;
        });
      });

      this.statisticsOptions = options;
    });
  }

  private loadExpropriationObligationIds() {
    const obligations = this.listService.createList('obligations', {filters: {obligationType: ['FulfillmentOfDecisionExpropriationLiability', 'TemporaryExpropriationLiability']}});
    return this.listService.fetchResult(obligations).then(() => {
      this.expropriationObligationIds = obligations.list.map(o => o.id);
    });
  }

  private async fetchTotalData() {
    // load dashboard settings
    await this.restangularConfig.one(`dashboard/${this.project.key}`)
      .get()
      .toPromise()
      .then(data => data, (result) => {
        if (result.status === 404) {
          return null;
        }
        return Promise.reject(result);
      })
      .then(data => {
        if (!data || !data.show) {
          this.totalPrecheck = false;
          return;
        }

        data = data.plain();

        this.totalPrecheck = true;

        this.statisticsEntityFilterKeyFinished = [];
        this.statisticsEntityFilterKeyUnfinished = [];
        const dashboardStats = [ 'statisticsPermanent', 'statisticsUnderOneYear', 'statisticsOverOneYear', 'statisticsEasement' ];

        dashboardStats.forEach(s => {
          // resolve finished category
          const statId = s + 'Id';
          const catId = s + 'CategoryId';
          const statistic = _.find(this.statisticsList.list, { id: data[statId] });

          if (statistic) {
            statistic.categories.forEach(c => {

              // final categories
              if (c.index === data[catId]) {
                this.statisticsEntityFilterKeyFinished.push(data[statId] + '_' + (c.id + 1) + '____' + (c.split ? '1' : '0'));
              // other categories
              } else {
                this.statisticsEntityFilterKeyUnfinished.push(data[statId] + '_' + (c.id + 1) + '____0');
              }
            });
          }
        });
      });

    // load dashboard data
    if (!this.totalPrecheck) {
      return;
    }

    const cancelPromise = new Promise<any>((resolve, reject) => {
      this.xhrCancelResolve = resolve;
    });

    this.httpService.call({
      path: `dashboard/${this.project.key}`,
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
      method: 'GET',
      cancelPromise: cancelPromise
    }).then(projectData => {
        this.totalLoading = false;

        if (!projectData) {
          this.totalAvailable = false;
          return;
        }
        this.totalAvailable = true;
        this.totalCategoryTabs[0].value = projectData.total;
        this.totalCategoryTabs[1].value = projectData.toCreate;
        this.totalCategoryTabs[1].total = projectData.total;
        this.totalCategoryTabs[2].value = projectData.inProgress;
        this.totalCategoryTabs[2].total = projectData.total;
        this.totalCategoryTabs[3].value = projectData.done;
        this.totalCategoryTabs[3].total = projectData.total;
        this.totalCategoryTabs[4].value = projectData.expropriationTotal;
      });
  }

  private setupParams() {
    const statisticsId = this.stateParams.statisticsStatistics || this.statisticsService.getStorageKeyValue('statisticsId');
    const statistics = _.find(this.statisticsList.list, { id: statisticsId });
    const entityTab = this.stateParams.statisticsEntityTab || this.statisticsService.getStorageKeyValue('entityTab') || 'titles';
    this.statisticsId = statistics ? statisticsId : this.statisticsList.list[0].id;
    this.entityTab = entityTab === 'cases' ? 'titles' : entityTab;
    this.refreshState();
  }

  private prepareEntityTab(data, currentStatuses, tab) {
    const dataR = {
      id: tab.id,
      data: [],
    };
    let index = 0;
    const totalStatus = currentStatuses ? _.find(currentStatuses, {status: 0}) : null;

    data.list[0].categories.forEach((categorySetting, categoryIndex) => {
      const category = _.find(currentStatuses, {status: categoryIndex + 1});

      dataR.data.push({
        value: currentStatuses && category ? category[tab.id + 'InStatus'] : 0,
        url: tab.sref && (tab.id !== 'cases' || index !== 0)
          ? this.stateService.href('symap.project.' + tab.sref, { sef: category ? [category.entityFilterKey + (categorySetting.split ? '_0' : '')] : null })
          : null,
        total: totalStatus ? totalStatus[tab.id] : 0,
      });
      index++;

      if (categorySetting.split) {
        dataR.data.push({
          value: currentStatuses && category ? (tab.id === 'cases' ? category['casesInStatus'] : category[tab.id + 'OnlyInStatus']) : 0,
          url: tab.sref && (tab.id !== 'cases' || index !== 0)
            ? this.stateService.href('symap.project.' + tab.sref, { sef: category ? [category.entityFilterKey + '_1'] : null, })
            : null,
          total: totalStatus ? totalStatus[tab.id] : 0,
        });

        dataR.data[dataR.data.length - 2].value = dataR.data[dataR.data.length - 2].value - dataR.data[dataR.data.length - 1].value;
        index++;
      }
    });

    dataR.data.unshift({
      value: totalStatus ? totalStatus[tab.id] : 0,
      url: totalStatus && tab.sref && tab.id !== 'cases'
        ? this.stateService.href('symap.project.' + tab.sref, { sef: [totalStatus.entityFilterKey]})
        : null,
    });
    return dataR;
  }

  private refreshState() {
    this.statisticsService.updateStorageValue({
      statisticsId: this.statisticsId,
      entityTab: this.entityTab,
      area: this.area ? JSON.stringify(this.area) : undefined,
      constructionObject: this.constructionObject ? JSON.stringify(this.constructionObject) : undefined,
    });
    this.stateService.go('.', { statisticsStatistics: this.statisticsId, statisticsEntityTab: this.entityTab });
  }
}
