import * as _ from 'lodash';

export const transformListToFilter = (filter) => {
  filter = _.cloneDeep(filter);
  for (const key in filter.filters) {
    if (
      typeof filter.filters[key] === 'string' ||
      typeof filter.filters[key] === 'number' ||
      typeof filter.filters[key] === typeof true
    ) {
      filter.filters[key] = [filter.filters[key]];
    }

    if (filter.filters[key] instanceof Array) {
      filter.filters[key] = {values: filter.filters[key]};
    }

    if (filter.filters[key] && filter.filters[key].values instanceof Array) {
      filter.filters[key].values.forEach((value, i) => {
        if (value !== null && value !== undefined && typeof value === 'object') {
          filter.filters[key].values[i] = value.id;
        }
      });
    }

    if (key === 'searchText') {
      if (typeof filter.filters.searchText === 'string' && filter.filters.searchText.length > 0) {
        filter.filters.searchText = {values: filter.filters.searchText.split(' ')};
      } else {
        filter.filters.searchText = {values: filter.filters.searchText.values};
      }
    }
  }
  return filter;
};
