<div [loading]="loading">
  <div *ngIf="!loading">
    <div class="checklist">
      <div class="checklist-item" *ngFor="let area of dataConstructionObject.geometricPlans" (click)="toggleSelectionGeometricPlan(data.geometricPlansSelected, area)">
          <gmt-checkbox gmtType="multiselect" [gmtChecked]="isChecked(data.geometricPlansSelected, area)" [gmtDisabled]="isDisabled(area)"></gmt-checkbox>
          {{area.kutx ? area.kutx + ' - GP číslo: ' : ''}}{{area.cis}}, obsahuje celkem {{area.countEasements}} VB
      </div>
    </div>

    <div class="mt-10">
      <gmt-button [clickAction]="submit" [gmtDisabled]="!data.geometricPlansSelected.length">Další</gmt-button>
    </div>
  </div>
</div>
