﻿export class SeriesModel {
  series: any;
  sections: any[];
  nextNumber?: string;

  public static createDefault(): SeriesModel {
    return {
      series: {
        cancelled: false,
        available: true,
      },
      sections: [
        {
          type: 'text',
        },
        {
          type: 'order',
          restart: true,
          show: false,
        },
        {
          type: 'year',
          short: true,
          show: false,
        },
        {
          type: 'title',
          show: false
        },
      ],
    };
  }
}
