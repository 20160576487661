import { Component, Inject, Input, OnInit } from '@angular/core';
import { SelectItem } from '@app/common/components/select/select.component';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { ProjectSubjectSimpleOfoComponent } from '@app/ps/project-settings/components/project-subject-simple-ofo/project-subject-simple-ofo.component';
import { DialogService } from '@app/common/services/dialog.service';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { RESTANGULAR_CONFIGURATION } from "@app/common/services/restangular-configuration.service";
import { APP_BRAND } from '@app/common/services/config.service';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { ListService } from '@app/common/services/list.service';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { TemplateOrganizationalUnitNamePipe } from '@app/common/pipes/template-organizational-unit-name.pipe';
import { ProjectUpdateComponent } from '@app/ps/project-settings/components/project-update/project-update.component';
import {BrandService} from "@app/common/services/brand.service";
import { ListModel } from '@app/common/models/list.model';
import { ProjectTypeNamePipe } from '@app/common/pipes/project-type-name.pipe';

import * as _ from 'lodash';

@Component({
  selector: 'project-info-settings',
  templateUrl: './project-info-settings.component.html',
})
export class ProjectInfoSettingsComponent implements OnInit {

  @Input() project: any;
  region: SelectItem;
  infrastructure: SelectItem;
  projectType: SelectItem;
  state: SelectItem;
  managerFilter: any;
  geodetFilter: any;
  propertyActivityProcessorFilter: any;
  organizationalUnitsNotCancelled: ListModel<any>;
  reloadOptionsOrganizationalUnit;
  reloadManager;
  templatesOrganizationalUnitNotCancelled: ListModel<any>;
  reloadOptionsTemplatesOrganizationalUnit;

  private static readonly STATE_LIST = [ 'CREATED', 'STARTED', 'PAUSED', 'FINISHED' ];
  private static readonly UNIT_LIST = [ 'Úsek výstavby', 'Úsek provozní' ];
  private static readonly INFRASTRUCTURE_LIST = [ 'neurčeno', 'D0', 'D1', 'D10', 'D11', 'D2', 'D3', 'D35', 'D4', 'D46', 'D48', 'D5', 'D52', 'D55', 'D56', 'D6', 'D7', 'D8', 'I/10', 'I/11', 'I/12', 'I/13', 'I/14', 'I/15', 'I/16', 'I/17', 'I/18', 'I/19', 'I/2', 'I/20', 'I/21', 'I/22', 'I/23', 'I/24', 'I/25', 'I/26', 'I/27', 'I/28', 'I/29', 'I/3', 'I/30', 'I/31', 'I/32', 'I/33', 'I/34', 'I/35', 'I/36', 'I/37', 'I/38', 'I/39', 'I/4', 'I/40', 'I/41', 'I/42', 'I/43', 'I/44', 'I/45', 'I/46', 'I/47', 'I/48', 'I/49', 'I/5', 'I/50', 'I/51', 'I/52', 'I/53', 'I/54', 'I/55', 'I/56', 'I/57', 'I/58', 'I/59', 'I/6', 'I/60', 'I/61', 'I/62', 'I/63', 'I/64', 'I/65', 'I/66', 'I/67', 'I/68', 'I/69', 'I/7', 'I/70', 'I/71', 'I/8', 'I/9' ];
  private static readonly REGION_LIST = [ 'Hlavní město Praha', 'Středočeský kraj', 'Jihočeský kraj', 'Plzeňský kraj', 'Karlovarský kraj', 'Ústecký kraj', 'Liberecký kraj', 'Královéhradecký kraj', 'Pardubický kraj', 'Vysočina', 'Jihomoravský kraj', 'Olomoucký kraj', 'Zlínský kraj', 'Moravskoslezský kraj' ];
  projectTypes: any[] = [{
    id: 'INVEST',
    name: this.projectTypeNamePipe.transform('INVEST'),
    unit: ProjectInfoSettingsComponent.UNIT_LIST[0],
  }, {
    id: 'ADDITIONAL',
    name: this.projectTypeNamePipe.transform('ADDITIONAL'),
    unit: ProjectInfoSettingsComponent.UNIT_LIST[1],
  }];

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangularService: any,
    public userNamePipe: UserNamePipe,
    private dialogService: DialogService,
    private brandTranslatePipe: BrandTranslatePipe,
    public projectSubjectNamePipe: ProjectSubjectNamePipe,
    private listService: ListService,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    public templateOrganizationalUnitNamePipe: TemplateOrganizationalUnitNamePipe,
    private brandService: BrandService,
    public projectTypeNamePipe: ProjectTypeNamePipe,
  ) {
    this.handleInvestorChange = this.handleInvestorChange.bind(this);
    this.onUpdateOrganizationalUnit = this.onUpdateOrganizationalUnit.bind(this);
    this.reloadOptionsOrganizationalUnitRegister = this.reloadOptionsOrganizationalUnitRegister.bind(this);
    this.reloadManagerRegister = this.reloadManagerRegister.bind(this);
    this.onUpdateProjectType = this.onUpdateProjectType.bind(this);
    this.reloadOptionsTemplatesOrganizationalUnitRegister = this.reloadOptionsTemplatesOrganizationalUnitRegister.bind(this);
  }

  ngOnInit() {
    this.state = (this.getOptions('state') as SelectItem[]).find(item => item.id === this.project.state);
    this.region = (this.getOptions('region') as SelectItem[]).find(item => item.id === this.project.region);
    this.infrastructure = (this.getOptions('infrastructure') as SelectItem[]).find(item => item.id === this.project.infrastructure);
    this.projectType = (this.projectTypes as SelectItem[]).find(item => item.id === this.project.type);

    this.managerFilter = {
      filters: {},
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };

    this.geodetFilter = {
      filters: {},
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };

    this.propertyActivityProcessorFilter = {
      filters: {},
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };

    if (this.APP_BRAND.NAME === 'RSD') {
      this.managerFilter.filters.ssoCompanies = { values: [ 'ŘSD' ] };
      this.propertyActivityProcessorFilter.filters.ssoCompanies = { values: [ 'ŘSD' ], negation: true };
    }
    this.organizationalUnitsNotCancelled = this.listService.createList('organizational-units', {filters: {cancelled: [false]}}, this.configurationRestangularService);
    this.templatesOrganizationalUnitNotCancelled = this.listService.createList('organizational-units', {filters: {cancelled: [false]}}, this.configurationRestangularService);

    this.reloadUnits();
  }

  getOptions(type: 'infrastructure' | 'region' | 'state'): SelectItem[] {
    if (type === 'state') {
      return ProjectInfoSettingsComponent.STATE_LIST.map(i => {
        return {
          id: i,
          name: this.brandTranslatePipe.transform('ps.projectState.' + i)
        };
      });
    }

    let list = [];
    switch (type) {
      case 'infrastructure':
        list = ProjectInfoSettingsComponent.INFRASTRUCTURE_LIST;
        break;
      case 'region':
        list = ProjectInfoSettingsComponent.REGION_LIST;
        break;
    }

    return list.map(i => { return { id: i, name: i }; });
  }

  onStateChanged(value: any) {
    this.project.state = value ? value.id : undefined;
  }

  onInfrastructureChanged(value: any) {
    this.project.infrastructure = value ? value.id : '';
  }

  onRegionChanged(value: any) {
    this.project.region = value ? value.id : '';
  }

  onEditProjectSubject(projectSubject: ProjectSubjectModel, type: string, discriminator: string) {
    const dialog = this.dialogService.open(ProjectSubjectSimpleOfoComponent, { data: {
      projectSubject: projectSubject,
      discriminator: discriminator
    }});

    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        this.project[type] = res;
      }

      sub.unsubscribe();
    });
  }

  compareCustomers(item1, item2): boolean {
    return (item1.customerIdText || item1.idText) === (item2.customerIdText || item2.idText);
  }

  handleInvestorChange(investor: any) {
    if (!investor) {
      this.project.investor = null;
      return;
    }
    this.project.investor = _.cloneDeep(investor);
    this.project.investor.customerIdText = investor.idText;
    this.project.investor.customerOrganizationalUnit = null;
    this.project.investor.actingPersons.forEach(ap => {
      ap.remoteId = ap.id;
      ap.id = null;
    });
    ProjectUpdateComponent.getInvestorDefaults(this.project.investor);
    this.reloadUnits();
  }

  private reloadUnits() {
    if (this.project.investor.customerIdText) {
      this.organizationalUnitsNotCancelled.filter.filters.idText = [this.project.investor.customerIdText];
      if (this.reloadOptionsOrganizationalUnit) {
        this.reloadOptionsOrganizationalUnit();
      }
    }
    this.onUpdateOrganizationalUnit();
  }

  onUpdateOrganizationalUnit(event = undefined) {
    if (event) {
      this.project.manager = null;
      if (this.reloadManager) {
        this.reloadManager();
      }
      this.project.investor.customerOrganizationalUnit = event.newValue;
      if (event.newValue && event.newValue.code && this.brandService.isVisible('mpv')) {
        this.managerFilter.filters.departmentId = [event.newValue.code.substring(0, 3) + '*'];
      }
    } else if (this.project.investor && this.project.investor.customerOrganizationalUnit && this.brandService.isVisible('mpv')) {
      this.managerFilter.filters.departmentId = [ this.project.investor.customerOrganizationalUnit.code.substring(0, 3) + '*'];
    } else {
      this.managerFilter.filters.departmentId = [];
    }
  }

  reloadOptionsOrganizationalUnitRegister(reloadOptionsOrganizationalUnit) {
    this.reloadOptionsOrganizationalUnit = reloadOptionsOrganizationalUnit;
  }

  reloadManagerRegister(reloadFn: Function) {
    this.reloadManager = reloadFn;
  }

  reloadOptionsTemplatesOrganizationalUnitRegister(reloadOptionsTemplatesOrganizationalUnit) {
    this.reloadOptionsTemplatesOrganizationalUnit = reloadOptionsTemplatesOrganizationalUnit;
  }

  onUpdateProjectType(event) {
    this.project.templatesOrganizationalUnit = null;
    if (event) {
      this.project.type = event.newValue ? event.newValue.id : null;
      if (event.newValue && this.brandService.isVisible('mpv')) {
        this.templatesOrganizationalUnitNotCancelled.filter.filters.section = [event.newValue.unit];
      }
    } else {
      this.templatesOrganizationalUnitNotCancelled.filter.filters.section = [];
    }

    if (this.reloadOptionsTemplatesOrganizationalUnit) {
      this.reloadOptionsTemplatesOrganizationalUnit();
    }
  }
}
