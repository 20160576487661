<h1>Editace dokumentu</h1>
<fieldset>
  <div class="column-100">
    <label class="required">Název</label>
    <input class="info" type="text" [(ngModel)]="document.baseName"/>
    <label class="required" *ngIf="allowType">Typ</label>
    <div *ngIf="allowType" class="info">
      <div class="checklist">
        <div class="checklist-item" *ngFor="let documentType of documentTypes" (click)="(!documentType.disabled || documentType.id === initialDocumentType.id) && onUpdateType(documentType)">
          <gmt-radio [gmtChecked]="documentType.id === activeDocumentType.id" [gmtDisabled]="!!documentType.disabled && documentType.id !== initialDocumentType.id"></gmt-radio>
          <span [ngClass]="{ disabled: !!documentType.disabled  && documentType.id !== initialDocumentType.id}">
            <i [ngClass]="['ml-10 mr-5 fa ' + documentType.icon]"></i>
            {{ documentType.title }}
            <span *ngIf="documentType.extensions">(<span class="comma-list"><span *ngFor="let extension of documentType.extensions">{{extension}}</span></span>)</span>
          </span>
          <span *ngIf="!!documentType.disabled && documentType.id !== initialDocumentType.id" class="error-message"> ({{ documentType.disabled }})</span>
        </div>
      </div>
    </div>
    <label>Poznámka k dokumentu</label>
    <textarea [(ngModel)]="document.note"></textarea>
  </div>
</fieldset>
<div class="text-center pt-20">
  <gmt-button [clickAction]="onUpdate" [gmtDisabled]="!isValid()">Uložit dokument</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
