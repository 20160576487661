<div>
  <fileupload
    [(files)]="uploadingFiles"
    [disabled]="false"
    [message]="'Vložte nové dokumenty přetažením, nebo je vyberte z počítače.'"
    [draggable]="draggable"
    (change)="onFileSelect($event)"
    *ngIf="canModify('manipulate_files')"
  ></fileupload>

  <ng-container *ngIf="documentList">
    <div class="mb-10" [gmtAuth]="'admin'">
      <div class="tag"
           [ngClass]="{active: checkCancelled()}"
           title="Zobrazení uživateli zrušených dokumentů"
           (click)="onCancelledCheckboxClicked()"
      >Zobrazit zrušené</div>
    </div>

    <div [loading]="documentList.loading">
      <div class="bordered" *ngIf="documentList.itemCount">
        <table >
          <thead>
          <tr>
            <th>
              <sort-by
                [selected]="'name' === documentList.filter.sortOrder.sortBy"
                [sortDir]="documentList.filter.sortOrder.direction"
                (sort)="onSort('name', documentList.filter.sortOrder.direction)">
                Název
              </sort-by>
            </th>
            <th>
              Poznámka
            </th>
            <th>
              <sort-by
                [selected]="'createdUser.name' === documentList.filter.sortOrder.sortBy"
                [sortDir]="documentList.filter.sortOrder.direction"
                (sort)="onSort('createdUser.name', documentList.filter.sortOrder.direction)">
                Nahrál
              </sort-by>
            </th>
            <th>
              <sort-by
                [selected]="'timestamp' === documentList.filter.sortOrder.sortBy"
                [sortDir]="documentList.filter.sortOrder.direction"
                (sort)="onSort('timestamp', documentList.filter.sortOrder.direction)">
                Datum nahrání
              </sort-by>
            </th>
            <th>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let document of documentList.list" [ngClass]="{cancelled: document.cancelled}">
            <td class="attachment-item">
              <div>
                <div>
                  <a href="{{restBaseUrl}}/attachments/{{document.id}}/data?t={{authToken}}">
                    <i class="mr-10 fa fa-lg flex-icon {{document | documentType : 'icon'}}"></i>
                  </a>
                </div>
                <div>
                  <a href="{{restBaseUrl}}/attachments/{{document.id}}/data?t={{authToken}}">
                    {{document.baseName + (document.extension ? '.' + document.extension : '')}}
                  </a>
                  <p>{{document | documentType : 'title'}}</p>
                  <p>id: {{document.id}}</p>
                </div>
              </div>
            </td>
            <td>
              {{document.note}}
            </td>
            <td>
              {{document.createdUser | username}}
            </td>
            <td>
              {{document.timestamp | date : 'short'}}
            </td>
            <td>
              <div class="fr">
                <a *ngIf="document.canViewPDF" href="{{getDocumentUrl(document)}}" title="Náhled dokumentu" target="_blank" class="mr-10"><span class="fa fa-eye fa-lg"></span></a>
                <span *ngIf="canModify('manipulate_files', document.createdUser)">
                  <a *ngIf="!document.cancelled" (click)="onEditDocument(document)" title="Upravit"><span class="fa fa-pencil fa-lg"></span></a>
                  <a *ngIf="!document.cancelled" (click)="onDeleteDocument(document)" title="Odstranit" class="remove-document"><span class="fa fa-trash-o fa-lg"></span></a>
                </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="bordered" *ngIf="!documentList.itemCount">
        <div class="empty-info">
          <span>Žádné dokumenty</span>
        </div>
      </div>
    </div>
  </ng-container>


</div>
