import { Component, OnInit, Input } from '@angular/core';

import { Customer } from '@app/models/customer';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  @Input() customer: Partial<Customer>;

  pageTitle: string;

  constructor(
    private stateService: StateService,
  ) {
    this.pageTitle = this.stateService.current.data.title;
  }

  ngOnInit() {
    if (!this.customer) {
      this.customer = {
        actingPersons: new Array(),
        organizationalUnits: new Array(),
      } as Partial<Customer>;
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.stateService.go('^', undefined, { reload: true, });
  }
}
