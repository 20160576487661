import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { ArticleModel } from '@app/common/models/article.model';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Component({
  selector: 'article-attachment',
  templateUrl: './article-attachment.component.html'
})
export class ArticleAttachmentComponent implements OnInit {
  text: string;
  files: UploadFileExtended[] = [];
  filesMsg = 'Vložte soubor přetažením nebo výběrem.';
  article: ArticleModel;

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
  ) {
    this.article = (<DialogConfigData>dialogConfig.data).article;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
  }

  onFilesChange(files: UploadFileExtended[]) {
    this.files = files;

    if (this.files.length === 0) {
      this.filesMsg = 'Vložte soubor přetažením nebo výběrem.';
    } else if (this.files.length > 1) {
      this.filesMsg = 'Je možné vložit pouze jeden soubor.';
    } else {
      this.filesMsg = '';
    }
  }

  onSave(): Promise<any> {
    const data: FormData = new FormData();
    data.append('file', this.files[0].fileEntry);
    data.append('filename', this.files[0].fileEntry.name);

    if (this.article.projectKey) {
      data.append('projectKey', this.article.projectKey);
    }

    if (this.article.application) {
      data.append('application', this.article.application);
    }

    return this.configurationRestangular
      .all('attachments')
      .customPOST(
        data,
        '',
        null,
        { 'Content-Type': undefined },
      ).toPromise().then(attachment => {
        this.dialogRef.close({
          text: (this.text ? this.text : ''),
          attachment: attachment.plain()
        });
      });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  isValid() {
    return this.files && this.files.length === 1;
  }
}
