import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expropriation-appeal-result',
  templateUrl: './expropriation-appeal-result.component.html',
  styleUrls: ['./expropriation-appeal-result.component.scss']
})
export class ExpropriationAppealResultComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data = {
    expropriationAppealResultDate: new Date(),
    expropriationAppealResultArbitrator: null,
    expropriationAppealResultReason: null
  };
  expropriationAppealAcceptedName: string;
  expropriationAppealRejectedName: string;
  step;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onRejected = this.onRejected.bind(this);
    this.onAccepted = this.onAccepted.bind(this);
  }

  async ngOnInit() {
    this.expropriationAppealAcceptedName = (await this.caseStatusService.loadStatusName('ExpropriationAppealAccepted')).name;
    this.expropriationAppealRejectedName = (await this.caseStatusService.loadStatusName('ExpropriationAppealRejected')).name;
    this.loading = false;
  }

  setStep(step) {
    this.step = step;
  }

  onRejected() {
    if (this.data.expropriationAppealResultDate &&
      this.data.expropriationAppealResultArbitrator &&
      this.data.expropriationAppealResultReason
    ) {
      return this.restangular
        .one('cases', this.caseId)
        .customPUT(this.data, 'expropriation-appeal-rejected')
        .toPromise()
        .then(() => {
          this.stateService.go('^');
        }
      );
    }
  }

  onAccepted() {
    if (this.data.expropriationAppealResultDate &&
      this.data.expropriationAppealResultArbitrator &&
      this.data.expropriationAppealResultReason
    ) {
      return this.restangular
        .one('cases', this.caseId)
        .customPUT(this.data, 'expropriation-appeal-accepted')
        .toPromise()
        .then(() => {
          this.stateService.go('^');
        }
      );
    }
  }
}
