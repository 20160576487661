import { InjectionToken } from '@angular/core';

export const RESTANGULAR_SYMAP = new InjectionToken<any>('RestangularSymap');

export function RestangularSymapFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlSY + '/app');
  });
  return r;
}

