import { VfzeValidationResponseModel } from '@app/common/models/vfze-validation-response.model';
import { DatePipe } from '@angular/common';

export class VfzeUtils {

  static readonly OCCUPATION_SUMMARY_TRANSLATE = {
    TRVALY: 'trvalý zábor',
    TRVALY_BEZ_VYKUPU: 'trvalý zábor bez výkupu',
    DOCASNY: 'dočasný zábor nad 1 rok',
    DOCASNY_DO_ROKU: 'dočasný zábor do 1 roku',
    JINE_DOTCENI: 'jiné dotčení',
    VYVEDENI: 'vyvedení',
    ZBYTKOVA_CAST_PARCELY: 'zbytková část parcely'
  };

  public static getValidationResult(validationResponse: VfzeValidationResponseModel): 'success' | 'warning' | 'error' | 'fatal' {
    if (!validationResponse) {
      return;
    }

    const { xmlStructureValid, modelDeserializationPassed, errors, warnings, fatal } = validationResponse;

    if (!xmlStructureValid) {
      return 'fatal';
    }

    if (!xmlStructureValid || !modelDeserializationPassed || fatal.length !== 0 || errors.length !== 0) {
      return 'error';
    }

    if (warnings.length !== 0) {
      return 'warning';
    }

    return 'success';
  }

  public static validationResponseToText(validationResponse: VfzeValidationResponseModel, datePipe: DatePipe): string {
    if (!validationResponse) {
      return '';
    }

    // header
    const result = VfzeUtils.getValidationResult(validationResponse);

    let mainMsg = '';

    switch (result) {
      case 'success':
        mainMsg = 'Validace proběhla úspěšně.' + '\n\n';
        break;
      case 'warning':
        mainMsg = 'Validace proběhla úspěšně. Protokol obsahuje varování na možné nesoulady. Doporučena kontrola.' + '\n\n';
        break;
      case 'error':
        mainMsg = 'Validace skončila neúspěšně, s chybami.' + '\n\n';
        break;
      case 'fatal':
        mainMsg = 'Validace skončila neúspěšně. Nesoulad s XSD šablonou.' + '\n\n';
        break;
    }

    mainMsg += "Informace o  VFZE \n";
    if (validationResponse.verzeFormat) {
        mainMsg += "verze VFZE\t\t" + validationResponse.verzeFormat + "\n";
    }
    if (validationResponse.platnostDatKN) {
        mainMsg += "datum platnosti KN dat\t" + datePipe.transform(validationResponse.platnostDatKN) + "\n";
    }
    if (validationResponse.platnostExportu) {
        mainMsg += "datum vytvoření VFZE\t" + datePipe.transform(validationResponse.platnostExportu) + "\n";
    }
    if (validationResponse.software) {
        mainMsg += "sofware\t\t\t" + validationResponse.software + "\n";
    }
    if (validationResponse.autor) {
        mainMsg += "autor\t\t\t" + validationResponse.autor + "\n";
    }
    if (validationResponse.nazev) {
        mainMsg += "název akce\t\t" + validationResponse.nazev + "\n";
    }
    mainMsg += "\n";

    const validationMsg = validationResponse.validationMessage ? validationResponse.validationMessage + '\n\n' : '';

    // warnings, errors
    const content = validationResponse.fatal.map(problem => problem.message)
      .concat([''])
      .concat(validationResponse.errors.map(problem => problem.message))
      .concat([''])
      .concat(validationResponse.warnings.map(problem => problem.message)).join('\n');

    // summary
    let objectCounts = '';

    if (validationResponse.occupationSummary) {
      for (const key of Object.keys(VfzeUtils.OCCUPATION_SUMMARY_TRANSLATE)) {
        if (validationResponse.occupationSummary[key] !== undefined) {
          objectCounts += VfzeUtils.OCCUPATION_SUMMARY_TRANSLATE[key] + ': ' + validationResponse.occupationSummary[key] + ' m2\n';
        }
      }
    }

    if (validationResponse.easementArea !== undefined && validationResponse.easementArea !== null) {
      objectCounts += 'věcné břemeno: ' + validationResponse.easementArea  + ' m2\n';
    }

    objectCounts = objectCounts ? ('\n\nKontrolní součty:\n' + objectCounts) : '';

    return mainMsg + validationMsg + content + objectCounts;
  }
}
