<div [loading]="!dataTitles">
  <div *ngIf="dataTitles">
    <h2 *ngIf="!displayTitleOwnership">Výběr dalších LV subjektu {{getSubjectName()}}</h2>
    <h2 *ngIf="displayTitleOwnership && !data.fromCase">Výběr dalších parcel LV {{displayTitleOwnership.ownership.title.lv}} (k.ú. {{displayTitleOwnership.ownership.title.area.name}})</h2>

    <div *ngIf="!displayTitleOwnership" class="mt-10 clearfix">
      <div class="column-100" *ngFor="let item of dataTitles; let $index = index">
        <div class="info">
          <gmt-checkbox [gmtChecked]="checklistOwnershipTitle.isChecked(item)" [gmtDisabled]="!selection.isOwnershipEnabled(item)" (click)="toggleSelectionOwnershipTitle(item)"></gmt-checkbox>
        </div>
        <div class="info ml-5">
          <a (click)="toggleSelectionOwnershipTitle(item, false)">LV {{item.ownership.title.lv}} (k.ú. {{item.ownership.title.area.name}}) - podíl {{item.ownership.podilCitatel}}/{{item.ownership.podilJmenovatel}}</a>
        </div>
      </div>
    </div>

    <title-detail-create-case-select-objects-items [data]="data"
                                             [checklistGroup]="checklistGroup"
                                             [checklistEasement]="checklistEasement"
                                             [checklistConstructionObject]="checklistConstructionObject"
                                             [checklistBuilding]="checklistBuilding"
                                             [checklistOwnership]="checklistOwnership"
                                             [titleId]="displayTitleOwnership.ownership.title.id"
                                             (callbackRegister)="displayTitleOwnership.callbackRegister($event)"
                                             *ngIf="displayTitleOwnership">
    </title-detail-create-case-select-objects-items>

    <div class="mt-20" *ngIf="!displayTitleOwnership">
      <gmt-button [clickAction]="submit">Další</gmt-button>
    </div>
    <div class="mt-20" *ngIf="displayTitleOwnership && !data.fromCase">
      <gmt-button class="black mr-10" [clickAction]="closeSelectOwnershipTitleCancel">Zrušit výběr LV</gmt-button>
      <gmt-button [gmtDisabled]="!displayTitleOwnership.isSelectionValidLocal || !displayTitleOwnership.isSelectionValidLocal()" [clickAction]="closeSelectOwnershipTitle">Potvrdit výběr</gmt-button>
    </div>
  </div>
</div>
