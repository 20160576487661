import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SampleModel } from '@app/sv/models/sample.model';
import { sampleStatusOptions } from '@app/sv/enums/sample-status.enum';
import { TicketStatusEnum } from '@app/sv/enums/ticket-status.enum';
import { TicketModel } from '@app/sv/models/ticket.model';
import { SelectItem } from '@app/common/components/select/select.component';
import { saveAs } from 'file-saver';
import { AuthService } from '@app/common/services/auth.service';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { DocumentTypeFullnamePipe } from '@app/common/pipes/document-type-fullname.pipe';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@app/common/services/http.service';
import { ExportListService } from '@app/ps/services/export-list.service';
import {BrandTranslatePipe} from "@app/common/pipes/brand-translate.pipe";

@Component({
  selector: 'sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: ['./sample-list.component.scss']
})
export class SampleListComponent implements OnInit {

  public static readonly VIEWER_URL = window.location.pathname + 'pdf-viewer/web/viewer.html?file=';

  list: ListModel<SampleModel>;
  pageableList: PageableList;
  statusOptions: SelectItem[] = sampleStatusOptions;
  documentTypeOptions: SelectItem[] = [];
  sectionOptions: SelectItem[] = [];
  organizationalUnitOptions: SelectItem[] = [];
  loading = true;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();
  helpIds = HelpService.HELP_IDS;
  exportEnable = true;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangularService: any,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    public documentTypeFullname: DocumentTypeFullnamePipe,
    private pageableListService: PageableListService,
    private authService: AuthService,
    private httpService: HttpService,
    private exportListService: ExportListService,
    private brandTranslatePipe: BrandTranslatePipe,
  ) {
    this.onExport = this.onExport.bind(this);
  }

  async ngOnInit() {
    this.sectionOptions.push({
      id: this.brandTranslatePipe.transform('sv.section.invest'),
      name: this.brandTranslatePipe.transform('sv.section.invest')
    });
    this.sectionOptions.push({
      id: this.brandTranslatePipe.transform('sv.section.additional'),
      name: this.brandTranslatePipe.transform('sv.section.additional')
    });
    await this.loadOptions();
    this.loading = false;
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  getActualizationTicket(sample: SampleModel): TicketModel {
    if (!sample.actualizationTickets) {
      return undefined;
    }
    return sample.actualizationTickets.find(t => t.status !== TicketStatusEnum.DELETED && t.status !== TicketStatusEnum.DISAPPROVED && t.status !== TicketStatusEnum.IMPORTED);
  }

  canViewTicket(ticket: TicketModel) {
    return this.authService.hasPermissionOnProject('template_aproval,template_manage') ||
      this.authService.hasPermissionOnProject('template_new', ticket.organizationalUnitCode);
  }

  onToggleUserOrganizationalUnit() {
    const organizationalUnitCode = this.list.filter.filters.organizationalUnitCode;
    organizationalUnitCode.values = (organizationalUnitCode.values.length ? [] : this.authService.getProjectsByPermission());
  }

  onToggleAssignable() {
    const assignable = this.list.filter.filters.assignable;
    assignable.values = (assignable.values.length ? [] : [true]);
  }

  downloadSampleFile(sample: SampleModel): Promise<any> {
    return this.httpService.call({
      path: `samples/${sample.id}/sample`,
      method: 'GET',
      fullResponse: true,
      responseType: 'blob'
    }).then((response: HttpResponse<any>) => {
      const filename = HttpService.parseFilename1(response)
        ? HttpService.parseFilename1(response)
        : sample.number + '.docx';
      saveAs(response.body, filename, true);
    });
  }

  getSamplePdfUrl(sample: SampleModel) {
    return `${SampleListComponent.VIEWER_URL}${this.restBaseUrl}/samples/${sample.id}/sample-pdf?t=${this.authToken}`;
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .downloadList('samples', 'samples', filter, {actualizationTickets: { load: true }}, true)
      .then(() => { this.exportEnable = true; });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'samples',
      this.getDefaultFilter(),
      'samplesFilter',
      this.restangular,
      {
        actualizationTickets: { load: true },
        canViewSamplePDF: { load: true },
      }
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private loadOptions(): Promise<any> {
    const p1 = this.restangular
      .all('document-types')
      .getList()
      .toPromise()
      .then(list => {
        this.documentTypeOptions = list.plain();
      });

    const p2 = this.configurationRestangularService
      .all('organizational-units').customPOST({
        filter: {
          limit: null,
          sortOrder: [{ sortBy: 'code', direction: 'asc' }],
          filters: {
            cancelled: {
              values: [false],
            },
          }
        }
      })
      .toPromise()
      .then(data => {
        this.organizationalUnitOptions = data;
      });

    return Promise.all([p1, p2]);
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: { values: [{ id: '' }]},
        documentTypeId: { values: [] },
        status: { values: [] },
        organizationalUnitId: { values: [] },
        section: { values: [] },
        organizationalUnitCode: { values: this.authService.getProjectsByPermission() },
        assignable: { values: [] },
      }
    };
  }
}
