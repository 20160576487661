import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { OwnersModule } from '@app/ps/owners/owners.module';
import { MapModule as MapLibModule } from '@app/map/map.module';
import { PsCommonModule } from '@app/ps/ps-common.module';

import { mapPrintState, mapState } from './map.states';

import { MapComponent } from './components/map/map.component';
import { UIRouterModule } from '@uirouter/angular';
import { MapPrintComponent } from '@app/ps/map/components/map-print/map-print.component';
import { MapPrintDescriptionComponent } from '@app/ps/map/components/map-print-description/map-print-description.component';
import { MapPrintLayersComponent } from '@app/ps/map/components/map-print-layers/map-print-layers.component';

const STATES = [
  mapState,
  mapPrintState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...STATES],
    }),
    CommonModule,
    OwnersModule,
    PsCommonModule,
    MapLibModule,
  ],
  providers: [],
  declarations: [
    MapComponent,
    MapPrintComponent,
    MapPrintDescriptionComponent,
    MapPrintLayersComponent,
  ],
  entryComponents: [
    MapPrintComponent,
    MapPrintDescriptionComponent,
    MapPrintLayersComponent,
  ],
  exports: [
  ]
})
export class MapModule {}
