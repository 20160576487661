import { Type } from '@angular/core';

import { BulkOperationSelectEntitiesComponent } from '../components/bulk-operation-select-entities/bulk-operation-select-entities.component';
import { BulkOperationSelectActionComponent } from '../components/bulk-operation-select-action/bulk-operation-select-action.component';
import { BulkOperationDetailsComponent } from '../components/bulk-operation-details/bulk-operation-details.component';
import { BulkOperationConfirmComponent } from '../components/bulk-operation-confirm/bulk-operation-confirm.component';
import { BulkOperationFinishComponent } from '../components/bulk-operation-finish/bulk-operation-finish.component';
import { AuthService } from '@app/common/services/auth.service';
import { BulkOperationType } from '@app/ps/models/bulk-operation-type.model';
import { SettingsService } from '@app/ps/services/settings.service';

export class BulkOperationProcessDefinitionModel {
  constructor(private operation: BulkOperationType, private entityIds?: number[]) {}

  getTypeName(): string {
    return this.operation.config.title;
  }

  getBackButtonText(): string {
    return this.operation.config.backButtonText;
  }

  getSteps() {
    return [
     {name: this.operation.config.firstStepName, id: 'selectEntities', component: BulkOperationSelectEntitiesComponent},
     {name: 'Výběr operace', id: 'selectAction', component: BulkOperationSelectActionComponent},
     {name: 'Detaily', id: 'details', component: BulkOperationDetailsComponent},
     {name: 'Potvrzení', id: 'confirm', component: BulkOperationConfirmComponent},
     {name: 'Výsledek', id: 'finish', component: BulkOperationFinishComponent},
   ];
  }

  getBulkOperationId(): string {
    return this.operation.config.key;
  }

  getDefaultFilters(settingsService: SettingsService): any {
    if (typeof this.operation.config.getDefaultFilters === 'function') {
      const filters = this.operation.config.getDefaultFilters(settingsService);
      filters['entityId'] = this.getEntityIds() || [];

      return filters;
    }
  }

  getFilterLocalStorageName(): string {
    return this.operation.config.filterKey;
  }

  getTableComponent(): Type<any> {
    return this.operation.config.tableComponent;
  }

  async getActions(authService: AuthService, settingsService: SettingsService): Promise<any[]> {
    return await this.operation.config.getActions(authService, settingsService);
  }

  getEntityIds(): number[] {
    return this.entityIds;
  }

  setEntityIds(ids: number[]) {
    this.entityIds = ids;
  }
}
