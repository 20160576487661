﻿import { Component, Input, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ListService } from '@app/common/services/list.service';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { CaseService } from '@app/ps/services/case.service';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './cases-generate-documents.component.html',
})
export class CasesGenerateDocumentsComponent  implements OnDestroy, OnInit, AfterViewInit {
  @Input()
  data: any;
  @Input()
  notifyParent: Function;
  inputData: any = {};
  list: any;
  countResolved = 0;
  allowAssignment = false;
  interrupt = false;
  exampleCase: any;
  templates: any[];
  loadingGroup = false;
  useTemplateService = this.authService.getActualProject().useTemplatesService;

  constructor(
    private listService: ListService,
    private authService: AuthService,
    private restangular: Restangular,
    private caseService: CaseService,
  ) {
    this.arrangeCases = this.arrangeCases.bind(this);
    this.setActiveDocument = this.setActiveDocument.bind(this);
  }

  ngOnInit() {
    this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
    this.inputData = this.data.details || {};
    const entityIdFilter = this.data.selectEntities.filters.entityId;
    const filter = {
      limit: null,
      filters: Object.assign(
        transformListToFilter({filters: this.data.selectEntities.filters}).filters,
        {
          entityId: {
            values: entityIdFilter.length && this.data.selectEntities.inverseSelection ?
              entityIdFilter.filter(entityId => !this.data.selectEntities.checkedItems.some(entity => entity.id === entityId)) :
              (this.data.selectEntities.checkedItems.map(entity => entity.id)),
            negation: !entityIdFilter.length && this.data.selectEntities.inverseSelection,
          },
          loadCollections: ['titles'],
        }
      )
    };

    if (!this.inputData.groups) {
      this.list = this.listService.createList('cases', filter);
      this.listService.fetchResult(this.list);
    } else {
      this.countResolved = this.data.selectEntities.count;
      const activeGroup = this.inputData.groups.find((group) => this.inputData.group && this.inputData.group.document.key === group.document.key);
      if (activeGroup) {
        this.setActiveDocument(activeGroup);
      }
    }
  }

  ngOnDestroy() {
    this.interrupt = true;
  }

  ngAfterViewInit() {
    if (!this.inputData.groups) {
      this.list.promise.then(async (cases) => {
        const data = await this.arrangeCases(cases);
        if (data) {
          this.inputData.groups = data.groups;
          this.inputData.noStatusGroup = data.noStatusGroup;
        }
      });
    }
  }

  async arrangeCases(cases: any): Promise<any> {
    const groups = [];
    const noStatusGroup = [];
    const allowedTemplates = await this.getAllowedTemplates();
    for (const item of cases.list) {
      if (this.interrupt) {
        return false;
      }
      const settings = await this.loadObligationSetting(item.obligation.id, item.caseStatus.key);
      const documents = [];

      for (const document of settings.documents) {
        if (document.permission && !this.authService.hasPermission(document.permission.name)) {
          continue;
        }
        const template = allowedTemplates.find((allowedTemplate) => allowedTemplate.key === document.key);
        if (!template) {
          continue;
        }
        const templateFiles = await this.getTemplates(document.key);
        if (!templateFiles.length) {
          continue;
        }
        documents.push({
          key: document.key,
          name: template.name,
          permission: document.permission,
          mandatary: document.mandatary,
          templates: templateFiles,
        });
      }
      this.countResolved++;

      if (documents.length === 0) {
         noStatusGroup.push(item);
         continue;
      }

      for (const document of documents) {
        let found = groups.find((group) => group.document.key === document.key);

        if (!found) {
          found = {
            document: document,
            cases: [],
            allowOwner: document.key.indexOf('CoveringLetter') !== -1 || document.key.indexOf('InformationLetter') !== -1,
          };
          groups.push(found);
        }

        found.cases.push(item);
      }
    }

    return {
      groups: groups,
      noStatusGroup: noStatusGroup,
    };
  }

  async loadObligationSetting(obligationId: number, caseStatus: string) {
    return await this.restangular.one('flow', obligationId).one('status', caseStatus).get().toPromise();
  }

  updateExampleCase(group: any) {
    const cases = group.cases;
    this.exampleCase = null;
    if (cases.length) {
      return this.getCase(cases[0].id).then(bCase => {
        this.exampleCase = bCase;
      });
    }
    return Promise.resolve();
  }

  notifyValidity() {
    const cases = this.inputData.group.cases;
    this.notifyParent(() => (cases.length) && this.inputData.group.template && this.inputData.filename && this.inputData.filename.some(item => item.show), this.inputData);
  }

  setActiveDocument(group) {
    const changed = this.inputData.group !== group;
    this.inputData.group = group;
    if (changed || (!this.loadingGroup && !this.exampleCase)) {
      this.loadingGroup = true;
      this.exampleCase = null;
      const casePromise = this.updateExampleCase(group);

      this.templates = group.document.templates;

      if (this.templates.length === 1) {
        group.template = this.templates[0];
      }
      Promise.all([casePromise]).then(() => {
        this.loadingGroup = false;
      });
      this.notifyValidity();
    }
    if (!changed) {
      return;
    }
    this.inputData.filename = [];
    this.inputData.filename.push({
      type: 'text'
    });
    this.inputData.filename.push({
      type: 'caseNumber',
      show: true,
    });
    this.inputData.filename.push({
      type: 'text',
      text: '_LV',
      show: true,
    });
     this.inputData.filename.push({
      type: 'titleNumber',
      show: true,
    });
    if (group.allowOwner) {
      this.inputData.filename.push({
        type: 'text',
        text: '_',
        show: true,
      });
      this.inputData.filename.push({
        type: 'owner',
        show: true,
      });
    }
  }

  async getAllowedTemplates() {
    return await this.restangular
      .all('templates')
      .all('types-allowed')
      .getList()
      .toPromise();
  }

  async getTemplates(key: string) {
    let mapper = (template) => {
      template.name = template.attachment.name;
      return template;
    };

    if (this.useTemplateService) {
      mapper = (template) => {
        template.name = template.name + (template.description ? (' ' + template.description) : '')
          + ' (' + template.attachment.baseName + (template.versionDescription ? (', ' + template.versionDescription) : '') + ')';
        return template;
      };
    }

    return await this.restangular.all('templates').customPOST({filter: {filters: {validity: ['valid'], key: [key]}}}).toPromise().then((data) => {
      return data.plain().map(mapper);
    });
  }

  async getCase(caseId: number) {
    return await this.restangular
      .one('cases', caseId).get({
        loadCollections: [
          'caseSubjects',
          'caseOwnerships',
          'titles',
        ],
      }).toPromise().then(bCase => {
        const dataCaseOwnerships = this.caseService.getDataCaseOwnerships(bCase.caseOwnerships, bCase.caseSubjects);
        bCase.dataRecipients = this.caseService.getRecipients(dataCaseOwnerships, true);
        return bCase;
      });
  }


  moveSection(section, sections, step) {
      const x = _.indexOf(sections, section);
      if (x + step < 0 || x + step >= sections.length) {
          return;
      }
      sections[x] = sections.splice(x + step, 1, sections[x])[0];
  }

  generateFilename() {
    if (!this.exampleCase) {
      return '';
    }
    let name = '';

    for (const section of this.inputData.filename) {
      if (section.show) {
        switch (section.type) {
          case 'text':
            name += section.text;
            break;
          case 'caseNumber':
            name += this.exampleCase.mpp.number;
            break;
          case 'titleNumber':
            name += this.exampleCase.titles.map(title => title.lv).join(',');
            break;
          case 'owner':
            name += this.exampleCase.dataRecipients[0].name;
            break;
        }
      }
    }

    return name;
  }

  toggleSection(section, sections) {
    if (section.type === 'text') {
      if (section.show) { // remove
        sections.splice(_.indexOf(sections, section), 1);
      } else { // add new empty line
        sections.splice(0, 0, {
          type: 'text',
          text: '',
        });
      }
    }
    section.show = !section.show;
  }
}
