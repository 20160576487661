<div [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.small">
    <div class="logo">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault();" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i [ngClass]="['fa', 'fa-bars']"></i>
      </a>
      <div class="image-container">
        <img src="./img/logo/{{ APP_BRAND.PS.ICON_PATH }}_xxl.svg" alt="logo"/>
      </div>
    </div>
    <login [user]="user" (logoutClick)="onLogout()"></login>
  </div>

  <div id="top-bar" class="big-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.big">
    <div class="top-bar-container left">
      <a href="#" class="menu-indicator" (click)="onCollapse(); $event.preventDefault()" [attr.title]="isCollapsed() ? 'Otevřít menu' : 'Zavřít menu'">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <div class="top-bar-container left logo">
      <a uiSref="landing.home"><img src="./img/logo/{{ APP_BRAND.PS.ICON_PATH }}_top.svg" alt="logo" /></a>
    </div>

    <div class="left top-bar-container top-bar-container-padding" *ngIf="ENV !== 'production'" style="color: red">
      TEST
    </div>

    <div class="top-bar-container top-bar-container-padding left">
      <div *ngIf="isActive('landing.home')" class="header without-breadcrumbs">
        <div class="header-title">Úvodní stránka</div>
      </div>
      <div *ngIf="isActive('landing.projects')" class="header">
        <div class="header-title">{{ 'ps.projectList' | brandTranslate }}</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a> > {{ 'ps.projectList' | brandTranslate }}
        </div>
      </div>
      <div *ngIf="isActive('landing.managerReportProjects', true)" class="header">
        <div class="header-title">Manažerský report</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a> > Manažerský report
        </div>
      </div>
      <div *ngIf="isActive('landing.managerReportProjects.detail', true)" class="header">
        <div class="header-title">Manažerský report</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a> > <a uiSref="landing.managerReportProjects">Manažerský report</a> > Manažerský report akce
        </div>
      </div>
      <div *ngIf="isActive('landing.users') || isActive('landing.users.detail')" class="header">
        <div class="header-title">Uživatelé</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a> > Uživatelé
        </div>
      </div>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-10" *ngIf="APP_BRAND.NAME === 'RSD'">
      <img src="./img/logo/rsd_main.svg" alt="logo" height="60"/>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-10" *ngIf="APP_BRAND.NAME === 'SZ'">
      <img src="./img/logo/sz.png" alt="logo" height="60"/>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-20">
      <login [user]="user" [withCompany]="false" [servicedeskUrl]="servicedeskUrl" (logoutClick)="onLogout()"></login>
    </div>
  </div>

  <div class="main">
    <app-menu [ngClass]="{collapsed: isCollapsed()}" [collapsed]="isCollapsed()" class="flexbox-container" id="menu" [menuConfig]="menuConfig"></app-menu>
    <div id="content-wrap">
      <div id="content" ui-view="content"></div>
    </div>
  </div>
</ng-container>
