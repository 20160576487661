<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <h1>Rozhodnutí vyvlastňovacího úřadu</h1>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <fieldset *ngIf="step">
      <div class="column-100">
        <label class="required">Datum rozhodnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.expropriationResultDate" [maxToday]="true"/></div>
      </div>
      <div class="column-100">
        <label class="required">Datum doručení rozhodnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.expropriationResultDeliveryDate" [maxToday]="true"/></div>
      </div>
      <div class="column-100">
        <label class="required">Lhůta pro odvolání</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.expropriationAppealDeadlineDate"/></div>
      </div>
      <div class="column-100" *ngIf="step === 'accepted'">
        <label class="required">Lhůta pro zahájení uskutečňování účelu</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.expropriationResultRealizationDeadlineDate"/></div>
      </div>
      <div class="column-100" *ngIf="step === 'accepted'">
        <label class="required">Cena za vyvlastnění</label>
        <div class="input"><input type="text" [(ngModel)]="data.expropriationAmount" gmtInputNumber/></div>
      </div>
      <div class="column-100" *ngIf="step === 'accepted'">
        <label class="required">Původ ceny</label>
        <div class="input"><input type="text" [(ngModel)]="data.expropriationAmountSource"/></div>
      </div>
      <div class="column-100" *ngIf="step === 'accepted'">
        <label class="required">Datum splatnosti</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.dueDate"/></div>
      </div>
    </fieldset>

    <div>
      <gmt-button class="red" (click)="setStep('rejected')" *ngIf="!step">{{ expropriationRejectedName }}</gmt-button>
      <gmt-button (click)="setStep('accepted')" *ngIf="!step">{{ expropriationAcceptedName }}</gmt-button>
      <gmt-button class="black" (click)="setStep(null)" *ngIf="step">Zpět</gmt-button>
      <gmt-button [clickAction]="step === 'accepted' ? onAccepted : onRejected" *ngIf="step">Uložit</gmt-button>
    </div>
  </div>
</div>
