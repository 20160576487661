<fieldset *ngIf="data.confirm.messages && data.confirm.messages.length">
  <legend>Nezaložené případy a chybová hlášení</legend>
  <div *ngFor="let message of data.confirm.messages">{{message}}</div>
</fieldset>

<div class="jumbotron centered-text">
  <ng-container *ngIf="data.confirm.caseIds.length === 1">Byl založen {{data.confirm.caseIds.length}} případ.</ng-container>
  <ng-container *ngIf="data.confirm.caseIds.length >= 2 && data.confirm.caseIds.length <= 4">Byly založeny {{data.confirm.caseIds.length}} případy.</ng-container>
  <ng-container *ngIf="data.confirm.caseIds.length === 0 || data.confirm.caseIds.length >= 5">Bylo založeno {{data.confirm.caseIds.length}} případů.</ng-container>
  <div *ngIf="data.confirm.caseIds.length">
    <div class="centered-text mt-10">Provést hromadnou opraci s nově založenými případy?</div>
    <div  class="centered-text mt-10">
      <gmt-button [clickAction]="goToBulkOperation">Ano</gmt-button>
      <gmt-button [clickAction]="goToList">Ne</gmt-button>
    </div>
  </div>

  <div *ngIf="!data.confirm.caseIds.length">
    <div class="centered-text mt-10">Opakovat hromadnou opraci se zvoleným seznamem listů vlastictví?</div>
    <div class="centered-text mt-10">
      <gmt-button [clickAction]="newOperation">Ano</gmt-button>
      <gmt-button [clickAction]="goToList">Ne</gmt-button>
    </div>
  </div>
</div>
