import { Component, Inject, Input, OnInit } from '@angular/core';

import { AuthService } from '@app/common/services/auth.service';
import { ModulesService } from '@app/common/services/modules.service';
import { APPLICATIONS } from '@app/common/services/config.service';

interface TermsOfUse {
  id: number;
  description: string;
  termsAndConditions: string;
}

@Component({
  selector: 'terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  @Input() appName: string;
  editable = false;
  loading = true;
  defaultTermsOfUse: string;
  termsOfUse: string;

  constructor(
    @Inject(APPLICATIONS) applications: any,
    private authService: AuthService,
    private modulesService: ModulesService,
  ) {
  }

  async ngOnInit() {
    this.termsOfUse = await this.getTermsOfUse();
    this.defaultTermsOfUse = this.termsOfUse;

    this.conditionsChanged = this.conditionsChanged.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.saveTermsOfUse = this.saveTermsOfUse.bind(this);
  }

  enableEditing() {
    this.editable = true;
  }

  async saveTermsOfUse() {
    const newTermsOfUse: TermsOfUse = await this.modulesService.saveConditions(this.appName, this.termsOfUse);
    this.termsOfUse = newTermsOfUse.termsAndConditions;
    this.defaultTermsOfUse = this.termsOfUse;
    this.editable = false;
    return this.modulesService.setUserApprovedTerms(this.authService.getUser().id, undefined);
  }

  conditionsChanged(): boolean {
    return this.termsOfUse !== this.defaultTermsOfUse;
  }

  private async getTermsOfUse(): Promise<string> {
    const terms = await this.modulesService.getApproveTermsConditions(this.appName);
    this.loading = false;
    return terms.termsAndConditions || '';
  }
}
