﻿import { Component, OnInit, Input, Inject, Output, EventEmitter } from "@angular/core";
import { AuthService } from '../../services/auth.service';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpEditComponent } from '@app/common/components/help-edit/help-edit.component';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: [ './help.component.scss' ]
})
export class HelpComponent implements OnInit {
  @Input() public helpId: number;
  @Output() callbackRegister = new EventEmitter();
  isSet = false;
  help: any = null;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private helpService: HelpService,
  ) {
    this.onEditClick = this.onEditClick.bind(this);
    this.onHelpIdChanged = this.onHelpIdChanged.bind(this);
  }

  ngOnInit() {
    this.callbackRegister.emit({
      onHelpIdChanged: this.onHelpIdChanged,
    });
    this.onHelpIdChanged(this.helpId);
  }

  onHelpIdChanged(helpId: number) {
    if (!helpId) {
      this.help = null;
      this.isSet = false;
      return;
    }
    this.helpService.getHelpById(helpId).then((help) => {
      this.isSet = !!help;
      this.help = help || {
        id: helpId,
        url: null,
        title: null,
        description: null,
        strId: Object.keys(this.helpIds).find(key => this.helpIds[key] === helpId)
      };
    });
  }

  isAdmin = () => this.authService.getUser() && this.authService.getUser().superuser;

  onEditClick() {
    const dialog = this.dialogService.open(HelpEditComponent, { data: this.help });

    const sub = dialog.afterClosed.subscribe(res => {
      this.helpService.getHelpById(this.helpId).then((help) => {
        this.isSet = !!help;
      });
      sub.unsubscribe();
    });
  }
}
