import { Pipe, PipeTransform, Injectable, Inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { WordService } from "../services/word.service";
import { EVENTS } from '@app/common/services/config.service';

@Pipe({
  name: 'wordtranslate',
  pure: false
})
@Injectable({
  providedIn: 'root'
})
export class WordTranslatePipe implements PipeTransform {

  private translations = {};

  constructor(
      private wordService: WordService,
      private authService: AuthService
  ) {
    this.authService.registerCallback(EVENTS.authProjectUpdated, () => {
      this.translations = {};
    });
  }

  transform(id: string, firstLetterUpper: boolean = false, toUpper: boolean = false) {
    if (!id /*|| !this.authService.getActualProject()*/) {
      return '';
    }

    if (!this.translations[id]) {
      if (this.wordService.hasVocabulary()) {
        this.translations[id] = this.wordService.getTranslation(id, firstLetterUpper, toUpper);
      } else {
        this.translations[id] = '';
        this.wordService.setVocabulary();
      }
    }
    return this.translations[id];
  }
}
