import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { OwnersModule } from '@app/ps/owners/owners.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { parcelDetailState, parcelsState, updateOccupationParcelState, updateOccupationState } from './parcels.states';

import { ParcelListComponent } from './components/parcel-list/parcel-list.component';
import { ParcelDetailComponent } from '@app/ps/parcels/components/parcel-detail/parcel-detail.component';
import { ParcelTenantComponent } from '@app/ps/parcels/components/parcel-tenant/parcel-tenant.component';
import { ParcelTenantEditFormComponent } from '@app/ps/parcels/components/parcel-tenant-edit-form/parcel-tenant-edit-form.component';
import { UIRouterModule } from '@uirouter/angular';

const PARCELS_STATES = [
  parcelsState,
  parcelDetailState,
  updateOccupationParcelState,
  updateOccupationState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...PARCELS_STATES],
    }),
    CommonModule,
    OwnersModule,
    PsCommonModule,
  ],
  providers: [],
  declarations: [
    ParcelListComponent,
    ParcelDetailComponent,
    ParcelTenantComponent,
    ParcelTenantEditFormComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class ParcelsModule {}
