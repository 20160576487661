import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { getLayersConfig as _getLayersConfig } from '@app/ps/map/config/layers-config';
import { MapConfigService } from '@app/map/services/map-config.service';
import { NewMapConfigService } from '@app/map/services/new-map-config.service';
import { Project } from '@app/models/project';
import { VectorLayerStyleModel } from '@app/ps/map/models/vector-layer-style.model';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

interface LayerGroup {
  id?: string;
  layers?: Layer[] | LayerGroup[] | string[];
  selector: string;
  title?: string;
  type: string;
  visible?: boolean;
}

interface Layer {
  defaultStyle?: VectorLayerStyleModel;
  defaultTitle?: string;
  description?: string;
  id: string;
  geojson?: any;
  origTitle?: string;
  stylable?: boolean;
  style?: VectorLayerStyleModel;
  title: string;
  type?: string;
  visible: boolean;
  layers: Layer[];
}

@Component({
  selector: 'map-settings',
  templateUrl: './map-settings.component.html',
  styleUrls: ['./map-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSettingsComponent implements OnInit {
  @Input() project: Project;
  // these two are used to plug into the angularjs project settings
  @Input() mapDefaults: any;
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();

  defaults: { activeLayerGroups: LayerGroup[]; activeLayers: Layer[]; availableLayerGroups: LayerGroup[]; } = {
    activeLayerGroups: undefined,
    activeLayers: undefined,
    availableLayerGroups: undefined,
  };
  layersChecklist: any;
  currentlyEditedLayer: Layer;
  currentColorLayer: Layer;
  private readonly immutableLayerNamePattern = '$';

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private cdr: ChangeDetectorRef,
    private newMapConfigService: NewMapConfigService,
    private mapConfigService: MapConfigService,
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: any,
  ) { }

  async ngOnInit() {
    this.onLayerNameEdit = this.onLayerNameEdit.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.mapLayerToForm = this.mapLayerToForm.bind(this);

    await this.initializeData();
    this.cdr.markForCheck();
  }

  onBackgroundClick() {
    this.currentColorLayer = undefined;
  }

  onLayerNameBlur(layer: Layer) {
    this.setCurrentlyEditedLayer(undefined);

    if (layer.title.trim() === '') {
      layer.title = layer.defaultTitle;
    }
  }

  onLayerNameEdit(layer: Layer) {
    if (this.isEditable(layer)) {
      this.setCurrentlyEditedLayer(layer);
    }
  }

  onLayerStyleResetToDefault(layer: Layer) {
    layer.style = layer.defaultStyle;
  }

  onLayerSymbolClick(layer: Layer, $event: MouseEvent) {
    this.currentColorLayer = layer;
    $event.stopPropagation();
  }

  onLayerStyleChange(layer: Layer, $event: VectorLayerStyleModel) {
    layer.style = $event;
  }

  onVisibilityChange(layer: Layer) {
    layer.visible = !layer.visible;
  }

  async onSubmit(): Promise<LayerGroup[]> {
    const payload = this.prepareLayersPayload();
    return this.restangularSettings.one(`application/${this.applications.sy.name}/project/${this.project.key}/map`).customPUT(payload).toPromise();
  }

  private async getActiveLayerGroups(): Promise<LayerGroup> {
    return this.newMapConfigService.getLayersConfig(this.applications.sy.name, this.project.key, true);
  }

  /**
   * Find layer groups with at least one active layer.
   */
  private getActiveLayerGroupsToSubmit(): LayerGroup[] {
    const mapConfig = _getLayersConfig(this.project);
    return mapConfig.layers
      .filter((layerGroup: LayerGroup) => (<string[]>layerGroup.layers).some((layer) => this.layersChecklist.checkedItems.find((checkedLayer: Layer) => checkedLayer.id === layer)));
  }

  private getActiveLayers(): Layer[] {
    const layerGroups: LayerGroup[] = this.defaults.activeLayerGroups;
    const reduce = (accumulator: Layer[], curVal: Layer[]): Layer[] => {
      accumulator.push(...curVal);
      accumulator.push(...curVal.filter(l => l.layers).map(l => l.layers).reduce(reduce, <Layer[]>[]));
      return accumulator;
    };
    const result: Layer[] = <Layer[]>layerGroups
      .map((lg: LayerGroup) => <Layer[]>lg.layers)
      .reduce(reduce, <Layer[]>[]);

    return result;
  }

  private async getAvailableLayerGroups(): Promise<LayerGroup[]> {
    return await this.getLayersConfig();
  }

  private mapLayerToForm(layer: Layer): Layer {
    const customized: any = this.defaults.activeLayers.find((l) => l.id === layer.id) || {};
    const customLayer: Layer = {
      id: customized.id || layer.id,
      title: customized.title || layer.title,
      defaultTitle: layer.origTitle,
      visible: customized.visible,
      stylable: layer.type === 'wfs',
      description: layer.description,
      layers: layer.layers ? layer.layers.map(this.mapLayerToForm).reverse() : undefined,
    };

    if (customLayer.stylable) {
      const style = customized && customized.style;
      const defaultStyle = this.getDefaultLayerGeoJSONStyle(layer);
      customLayer.style = style ? new VectorLayerStyleModel(style.fill, style.stroke) : this.getDefaultLayerGeoJSONStyle(layer);
      customLayer.defaultStyle = defaultStyle;
    }

    return customLayer;
  }

  private getLayersConfig(): Promise<LayerGroup[]> {
    const mapConfig = _getLayersConfig(this.project);

    for (const l of mapConfig.layers) {
      if (this.APP_BRAND.NAME === 'RSD' && l.id === 'zabory') {
        _.pull(l.layers, 'occupation_acquirer');
      } else if (this.APP_BRAND.NAME === 'DTM' && l.id === 'base') {
        _.pull(l.layers, 'dtm_osm_overview');
      }
      if (this.APP_BRAND.NAME !== 'SZ' && l.id === 'zabory') {
        _.pull(l.layers, 'occupation_type_112');
        _.pull(l.layers, 'occupation_type_113');
      }
    }

    const mapId = 'main-map';

    return this.mapConfigService.setLayers({
      type: 'node',
      selector: 'hidden',
      layers: mapConfig.layers,
    }, mapId).then((mc: LayerGroup) => {

      const layerGroups = (<LayerGroup[]>mc.layers).map((layerGroup: LayerGroup) => {
        layerGroup.layers = (<Layer[]>layerGroup.layers).map(this.mapLayerToForm);

        layerGroup.layers.reverse(); // put more important layers on top
        return layerGroup;
      });

      layerGroups.reverse(); // put more important layergroups on top
      return layerGroups;
    });
  }

  /**
   * Expects vector layers to have the default style set in app/js/common/map/services/layersConfig.js.
   *
   * @throws
   */
  private getDefaultLayerGeoJSONStyle(layer: Layer): VectorLayerStyleModel | never {
    const defaultStyle = layer.geojson && layer.geojson.style;

    if (!defaultStyle || !defaultStyle.fillColor || !defaultStyle.color || !defaultStyle.opacity || !defaultStyle.fillOpacity) {
      throw new Error('No default style for given layer.');
    }

    const style = VectorLayerStyleModel.fromRgbStrings(defaultStyle.fillColor, defaultStyle.color);
    style.fill.a = defaultStyle.fillOpacity;
    style.stroke.a = defaultStyle.opacity;

    return style;
  }

  private async initializeData() {
    if (!this.mapDefaults) {
      const activeLayerGroups = await this.getActiveLayerGroups();
      this.defaults.activeLayerGroups = <LayerGroup[]>activeLayerGroups.layers;
      this.defaults.activeLayers = this.getActiveLayers();
      this.defaults.availableLayerGroups = await this.getAvailableLayerGroups();
      this.loaded.emit({
        defaults: this.defaults,
        onSave: this.onSubmit,
      });
    } else {
      this.defaults = this.mapDefaults;
    }
    this.layersChecklist = new ChecklistModel(this.defaults.activeLayers);
  }

  private isEditable(layer: Layer): boolean {
    return !layer.defaultTitle.includes(this.immutableLayerNamePattern);
  }

  private prepareLayersPayload() {
    const activeLayerGroups = this.getActiveLayerGroupsToSubmit();

    return activeLayerGroups.map((layerGroup: LayerGroup) => {
      return {
        id: layerGroup.id,
        type: layerGroup.type,
        title: layerGroup.title,
        selector: layerGroup.selector,
        layers: (<string[]>layerGroup.layers).map((layer: string) => {
          const checkedLayer: Layer = this.layersChecklist.checkedItems.find((checkedLayer: Layer) => checkedLayer.id === layer);

          if (!checkedLayer) {
            return;
          }

          const availableLayers = this.defaults.availableLayerGroups.map(l => l.layers).reduce((accum: Layer[], layers: Layer[]) => accum.concat(layers), []);
          const currentLayer = (<Layer[]>availableLayers).find((availableLayer: Layer) => availableLayer.id === checkedLayer.id);

          const result: Layer = {
            id: checkedLayer.id,
            title: currentLayer.title || currentLayer.defaultTitle,
            visible: currentLayer.visible,
            layers: currentLayer.layers,
          };

          if (currentLayer.stylable && Object.keys(currentLayer.style).length > 0) {
            result.style = currentLayer.style;
          }

          return result;
        }).filter((layer: Layer) => layer !== undefined),
      };
    });
  }

  private setCurrentlyEditedLayer(layer: Layer) {
    this.currentlyEditedLayer = layer;
  }
}

