<h1>Nastavení projektu</h1>
<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
      <fieldset *ngIf="tab === 'G'">
        <legend>Základní</legend>
        <div class="column-100">
          <label for="project-name">Název projektu</label>

          <div class="input">
            <input type="text" id="project-name" [(ngModel)]="data.name"/>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="tab === 'C'">
        <legend>Podmínky užití</legend>
        <div class="column-100">
          <label>Podmínky užití</label>
          <textarea [(ngModel)]="data.termsAndConditions" class="tall"></textarea>
        </div>

        <div class="column-100">
          <label>Vynutit souhlas uživatelů</label>

          <div class="info">
            <a class="list-check" (click)="toggleCheckbox()">
              <gmt-checkbox [gmtChecked]="checked"></gmt-checkbox>
            </a>
          </div>
        </div>
      </fieldset>

      <div class="actions">
        <gmt-button [clickAction]="update">Uložit nastavení projektu</gmt-button>
      </div>
    </gmt-navbar>
  </div>
</div>
