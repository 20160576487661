import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';
import { options } from './contracts-register-legal-exceptions';

@Component({
  selector: 'form-contracts-register',
  templateUrl: './form-contracts-register.component.html',
  styleUrls: ['./form-contracts-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContractsRegisterComponent implements OnInit {
  @Input() data: CaseModel;
  @Input() isPublished: boolean;
  @Input() legend: string;
  @Input() required: boolean;
  @Output() exceptionSelected = new EventEmitter<string>();

  contractsRegister = {
    publicationException: undefined,
  };
  options: {[key: string]: any}[];

  ngOnInit() {
    this.options = options;
    this.contractsRegister.publicationException = this.options.find((opt) => opt.name === this.data.contractRegisterPublicationException);
  }

  onPublicationExceptionSelect(evt: {[key: string]: any}) {
    this.exceptionSelected.emit(evt.newValue.name);
  }
}

