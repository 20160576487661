<div class="column-100 padding-20">
  <gmt-input label="Evidenční číslo">
    <input type="text" [(ngModel)]="project.isprofond"/>
  </gmt-input>

  <gmt-input label="Číslo akce S" *ngIf="APP_BRAND.NAME == 'SZ'">
    <input type="text" [(ngModel)]="project.numberS"/>
  </gmt-input>

  <gmt-input label="{{ 'ps.projectName' | brandTranslate }}">
    <input type="text" [(ngModel)]="project.name"/>
  </gmt-input>

  <div visibleByBrand="mpv">
    <gmt-input label="Kraj">
      <gmt-select
        [optionItems]="getOptions('region')"
        [data]="this"
        (changed)="onRegionChanged($event.newValue)"
        field="region"
        required="true"
        selectTitle="vybrat kraj"
      ></gmt-select>
    </gmt-input>

    <gmt-input label="Komunikace">
      <gmt-select
        [optionItems]="getOptions('infrastructure')"
        [data]="this"
        (changed)="onInfrastructureChanged($event.newValue)"
        field="infrastructure"
        required="true"
        selectTitle="vybrat komunikaci"
      ></gmt-select>
    </gmt-input>
  </div>

  <div visibleByBrand="maja">
    <gmt-input label="Kraj">
      <input type="text" [(ngModel)]="project.region"/>
    </gmt-input>

    <gmt-input label="Komunikace">
      <input type="text" [(ngModel)]="project.infrastructure"/>
    </gmt-input>

    <gmt-input label="Útvar">
      <input type="text" [(ngModel)]="project.unit"/>
    </gmt-input>

    <gmt-input label="URL webového dotazníku (QR kód)">
      <input type="text" [(ngModel)]="project.qrCodeFormat"/>
    </gmt-input>
  </div>

  <gmt-input label="Stupeň">
    <input type="text" [(ngModel)]="project.phase"/>
  </gmt-input>
</div>
<div class="column-100 bordered-top padding-20">
  <gmt-input label="{{ 'ps.projectStartDate' | brandTranslate }}">
    <input type="text" pick-a-date [(ngModel)]="project.projectStartDate" [maxToday]="true"/>
  </gmt-input>

  <gmt-input label="{{ 'ps.projectState' | brandTranslate }}">
    <gmt-select
      [data]="this"
      field="state"
      [optionItems]="getOptions('state')"
      (changed)="onStateChanged($event.newValue)"
    ></gmt-select>
  </gmt-input>

  <gmt-input *ngIf="project.state" label="Datum změny stavu" [required]="true">
    <input *ngIf="project.state === 'CREATED'" type="text" pick-a-date [(ngModel)]="project.stateCreatedDate" [maxToday]="true"/>
    <input *ngIf="project.state === 'STARTED'" type="text" pick-a-date [(ngModel)]="project.stateStartedDate" [maxToday]="true"/>
    <input *ngIf="project.state === 'PAUSED'" type="text" pick-a-date [(ngModel)]="project.statePausedDate" [maxToday]="true"/>
    <input *ngIf="project.state === 'FINISHED'" type="text" pick-a-date [(ngModel)]="project.stateFinishedDate" [maxToday]="true"/>
  </gmt-input>
</div>
<div class="column-100 bordered-top padding-20" *ngIf="project.state === 'STARTED'">
  <readiness [project]="project"></readiness>
</div>
<div class="column-100 bordered-top padding-20">
   <gmt-input label="Organizace">
      <gmt-select
        [restangularService]="configurationRestangularService"
        resource="v2/customers"
        [filter]="{ filters: { includeDepartments: { values: ['true'] }}, sortOrder: { sortBy: 'companyName' }}"
        [data]="project"
        field="investor"
        (changed)="handleInvestorChange($event.newValue)"
        [itemPipe]="projectSubjectNamePipe"
        required="true"
        [compareFunction]="compareCustomers"
      ></gmt-select>
    </gmt-input>
  <div *ngIf="project.investor && project.investor.customerIdText">
    <gmt-input label="Organizační jednotka">
      <gmt-select
        [data]="project.investor"
        field="customerOrganizationalUnit"
        [optionItems]="organizationalUnitsNotCancelled"
        [itemPipe]="organizationalUnitNamePipe"
        (changed)="onUpdateOrganizationalUnit($event)"
        [reloadOptionsRegister]="reloadOptionsOrganizationalUnitRegister"
      ></gmt-select>
    </gmt-input>
  </div>
  <div *ngIf="project.investor.customerOrganizationalUnit">
    <label>{{ 'ps.projectManager' | brandTranslate }}</label>
    <div class="input">
      <gmt-select
        [restangularService]="restangularSettings"
        [data]="project"
        field="manager"
        resource="users/list"
        [filter]="managerFilter"
        selectTitle="{{ 'ps.projectManager' | brandTranslate }}"
        [itemPipe]="userNamePipe"
        [reloadOptionsRegister]="reloadManagerRegister"
      ></gmt-select>
    </div>
  </div>
</div>
<div class="column-100 bordered-top padding-20" visibleByBrand="mpv">
  <div>
    <gmt-input label="Typ akce">
      <gmt-select
        [data]="this"
        field="projectType"
        [optionItems]="projectTypes"
        (changed)="onUpdateProjectType($event)"
      ></gmt-select>
    </gmt-input>
  </div>
  <div *ngIf="project.type">
    <gmt-input label="Zařazení vzorů">
      <gmt-select
        [data]="project"
        field="templatesOrganizationalUnit"
        [optionItems]="templatesOrganizationalUnitNotCancelled"
        [itemPipe]="templateOrganizationalUnitNamePipe"
        [reloadOptionsRegister]="reloadOptionsTemplatesOrganizationalUnitRegister"
      ></gmt-select>
    </gmt-input>
  </div>
</div>
<div class="column-100 bordered-top padding-20">
  <div>
    <label>{{ 'ps.propertyActivityProcessor' | brandTranslate }}</label>
    <div class="input">
      <gmt-select
        [restangularService]="restangularSettings"
        [data]="project"
        field="propertyActivityProcessor"
        resource="users/list"
        [filter]="propertyActivityProcessorFilter"
        selectTitle="{{ 'ps.propertyActivityProcessor' | brandTranslate }}"
        [itemPipe]="userNamePipe"
      ></gmt-select>
    </div>
  </div>

  <div>
    <label>Zpracovatel záb. elaborátu</label>
    <div class="info">
      <span *ngIf="project.landTakeDocumentationAuthor">{{ project.landTakeDocumentationAuthor | projectSubjectName: true }} </span>
      <a (click)="onEditProjectSubject(project.landTakeDocumentationAuthor, 'landTakeDocumentationAuthor', 'L')"><i class="fa fa-pencil"></i></a>
    </div>
  </div>

  <div>
    <label>Projektant</label>
    <div class="info">
      <span *ngIf="project.designer">{{ project.designer | projectSubjectName: true }} </span>
      <a (click)="onEditProjectSubject(project.designer, 'designer', 'D')"><i class="fa fa-pencil"></i></a>
    </div>
  </div>

  <div visibleByBrand="maja">
    <label>Geodet investora</label>
    <div class="input">
      <gmt-select
        [restangularService]="restangularSettings"
        [data]="project"
        field="geodet"
        resource="users/list"
        [filter]="geodetFilter"
        selectTitle="Geodet investora"
        [itemPipe]="userNamePipe"
      ></gmt-select>
    </div>
    <label>Vedoucí MP činností</label>
    <div class="input">
      <gmt-select
        [restangularService]="restangularSettings"
        [data]="project"
        field="propertyActivityHead"
        resource="users/list"
        [filter]="geodetFilter"
        selectTitle="Vedoucí MP činností"
        [itemPipe]="userNamePipe"
      ></gmt-select>
    </div>
  </div>
</div>
