import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PostCodePipe } from '@app/common/pipes/post-code.pipe';

@Pipe({
  name: 'fullmunicipality'
})
@Injectable({
  providedIn: 'root'
})
export class FullMunicipalityPipe implements PipeTransform {

  constructor(
    private postCodePipe: PostCodePipe
  ) {
  }

  transform(subject: any): string {
    if (!subject) {
      return '';
    }

    let postCode = this.postCodePipe.transform(subject.postCode);

    if (!subject.district && 'Praha' === subject.municipality && subject.postCode && (subject.postCode + '').length === 5) {
      const digit = (subject.postCode + '').substr(1, 1);
      subject.district = 'Praha ' + (digit === '0' ? 10 : digit);
    }
    if (subject.municipality || subject.district) {
      postCode += postCode ? ' ' : '';
      postCode += subject.district || subject.municipality;
    }
    return postCode;
  }
}
