import { Injectable, Inject } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({providedIn: 'root'})
export class IntersectionService {

  constructor(
    private restangular: Restangular,
  ) {}

  async intersectGeometry(GeoJSON) {
    return await this.restangular.one('parcel/intersection/intersect/4326').customPOST(GeoJSON).toPromise().then((d) => {
      const requestID = d.data;
      const maxRequests = 300;
      return this.isFinished(requestID, maxRequests);
    });
  }

  async getFinishedResponse(requestID) {
    return await this.restangular.one(`parcel/intersection/${requestID}/finished`).get().toPromise().then(d => d.data);
  }

  async isFinished(requestID, cutdown) {
    return await this.getFinishedResponse(requestID).then((response) => {
      if (cutdown === 0) {
        throw 'Unresolved in time';
      } else {
        if (response === 'true') {
          return requestID;
        } else {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              this.isFinished(requestID, cutdown - 1).then(() => {
                resolve(requestID);
              }, ()=>{console.log('errr');});
            }, 200);
          });
        }
      }
    });
  }
}
