﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { ListService } from '@app/common/services/list.service';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'construction-object-detail-create-case-select-obligation',
	templateUrl: './create-case-select-obligation.component.html',
})
export class CreateCaseSelectObligationComponent implements OnInit {
  @Input()
  private constructionObjectId: number;
  @Input()
  private data: any;
  @Output()
  private submitCallback = new EventEmitter();
  public loading: boolean = true;
  public obligations: any[];
  public dataConstructionObject: any;
  private createGroups: {id: string, name: string}[];
  private checklistCreateGroups: any;
  acceleratedPriceTypes: {id: string, name: string}[];

  constructor(
    private listService: ListService,
    private wordTranslatePipe: WordTranslatePipe,
    private restangular: Restangular,
  ) {
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {
    delete this.data.geometricPlansSelected;
    this.loadConstructionObject();

    if (!this.data.createGroups) {
      this.data.createGroups = [];
    }
    this.checklistCreateGroups = new ChecklistModel(this.data.createGroups);
    this.onSelectObligation(this.data.obligation);
    this.setupAcceleratedPriceOptions();
  }

  toggleCreateGroup(createGroup) {
    if (this.isCreateGroupDisabled()) {
      return;
    }
    this.checklistCreateGroups.toggleSelection(createGroup);
  }

  isCheckeCreateGroup(createGroup): boolean {
    return this.checklistCreateGroups.isChecked(createGroup);
  }

  isCreateGroupDisabled(): boolean {
    return !this.data.obligation || (this.data.obligation.type !== 'AgreementOnAFutureNetworkConstructionContractLiability' && this.data.obligation.type !== 'FutureRealBurdenLiabilityAccelerated');
  }

  loadConstructionObject() {
    const obligations = this.listService.createList('obligations', {filters: {obligationType: ['RealBurdenLiability', 'RealBurdenLiabilityAccelerated', 'AgreementOnAFutureNetworkConstructionContractLiability',  'FutureRealBurdenLiabilityAccelerated']}});
    Promise.all([
      this.listService.fetchResult(obligations),
      this.restangular.one('construction-objects', this.constructionObjectId).get().toPromise()
    ]).then((data) => {
      this.obligations = data[0].list;
      this.dataConstructionObject = data[1];
      this.loading = false;
      // Other than global batch price with sigle price
      /*if (this.dataConstructionObject.easementGlobalPriceType !== 'B' || this.dataConstructionObject.easementGlobalOtherUnitType !== 'S') {
        this.obligations = this.obligations.filter(obligation => obligation.type !== 'RealBurdenLiabilityAccelerated');
      }*/
      if (this.obligations.length === 1) {
        this.data.obligation = this.obligations[0];
        this.submitCallback.emit({skipped : this.isCreateGroupDisabled()});
      } else {
        this.submitCallback.emit({skipped : false});
      }
    });
  }

  submit() {
    return this.submitCallback.emit();
  }

  onSelectObligation(obligation) {
    if (obligation) {
      this.createGroups = [
        {id: 'title', name: 'Po listech vlastnictví'},
      ];

      if (obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' || obligation.type === 'FutureRealBurdenLiabilityAccelerated') {
        this.createGroups.push({id: 'owner', name: 'Po vlastnících'});
      }
    }
    if (this.data.obligation === obligation) {
      return;
    }
    this.checklistCreateGroups.empty();
    this.data.createGroups.push({id: 'title'});
    this.data.obligation = obligation;
    this.data.acceleratedPriceType = undefined;

    this.setupAcceleratedPriceOptions();
  }

  toggleAcceleratedPriceType(acceleratedPriceType) {
    this.data.acceleratedPriceType = acceleratedPriceType.id;
  }

  isCheckedAcceleratedPriceType(acceleratedPriceType): boolean {
    return this.data.acceleratedPriceType === acceleratedPriceType.id;
  }

  private setupAcceleratedPriceOptions() {
    this.acceleratedPriceTypes = this.data.obligation && this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated' ? [
      {id: 'SINGLE', name: 'Jednotná cena za parcelu (parcely)'},
      {id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu'},
      {id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu věcných břemen na parcele (parcelách)'},
      {id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV')},
    ] : [
      {id: 'SINGLE', name: 'Jednotná cena za parcelu (parcely)'},
      {id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu věcných břemen na parcele (parcelách)'},
    ];
  }
}
