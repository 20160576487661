﻿import { Component, Inject, Input } from '@angular/core';
import { ProjectStatusService } from '../../services/project-status.service';
import { StateService } from '@uirouter/angular';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './cases-generate-documents-finish.component.html',
})
export class CasesGenerateDocumentsFinishComponent {
  @Input()
  data: any;
  @Input()
  newOperation: Function;
  @Input()
  goToList: Function;
  exportRunning = false;

  constructor(
    private stateService: StateService,
    private projectStatusService: ProjectStatusService,
    private restangular: Restangular,
  ) {
    this.generateZipFile = this.generateZipFile.bind(this);
  }

  async generateZipFile() {
    const data = await this.restangular.all('exports/ATTACHMENT').post(
      {
        'exportSettings': {
          'attachmentIds': this.data.confirm.attachmentIds.map(id => {
              return {
                'objectId': id,
              };
          })
        }
      }
    ).toPromise();
    this.exportRunning = true;
    this.projectStatusService.refresh(data);
  }
}
