import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input, OnChanges,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';

export interface PieDataModel {
  value: number;
  color: string;
  name?: string;
  tooltip?: string;
}

@Component({
  selector: 'gmt-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements AfterViewInit, OnChanges {

  @ViewChild('containerPieChart') element: ElementRef;
  @Input() data: PieDataModel[];
  @Input() title: string;
  @Input() subTitle: string;
  @Input() subSubTitle: string;
  @Input() tooltip = true;

  private svg;
  private g;

  constructor() {
    this.draw = this.draw.bind(this);
  }

  ngAfterViewInit() {
    this.draw();
  }

  ngOnChanges(changes) {
    this.draw();
  }

  @HostListener('window:resize')
  onResize() {
    this.draw();
  }

  private draw() {
    // Setup elements
    const el = this.element.nativeElement;
    const sizeCoefficient = 0.7;
    const size = el.clientWidth * sizeCoefficient;

    el.innerHTML = '';
    this.svg = d3.select(el).append('svg');
    this.g = this.svg.append('g');

    this.svg.attr('width', size)
      .attr('height', size)
      .attr('viewBox', `0 0 ${size} ${size}`);
    this.g.attr('transform', 'translate(' + size / 2 + ',' + size / 2 + ')');


    // Generate the pie
    const pie = d3.pie();
    pie.sort(null);

    // Generate the arcs
    const arc = d3.arc()
      .innerRadius((size / 2.5 > 55) ? (size / 2.5) : 55)
      .outerRadius(size / 2);

    // Generate groups
    const arcs = this.g.selectAll('arc')
      .data(pie(this.extractData('value')))
      .enter()
      .append('g')
      .attr('class', 'arc');

    // Draw arc paths
    const colors = d3.scaleOrdinal(this.extractData('color'));
    arcs.append('path')
      .attr('fill', (d, i) => {
        return colors(i);
      })
      .attr('d', arc);

    // Tooltip
    if (this.tooltip) {
      const tooltip = d3.select(el)
        .append('div')
        .style('position', 'fixed')
        .style('z-index', '10')
        .style('visibility', 'hidden')
        .style('padding', '7px')
        .style('background', '#fff')
        .style('border', '1px solid #dddddd')
        .style('box-shadow', '0 6px 5px 0 rgba(0, 0, 0, 0.2)')
        .style('-webkit-box-shadow', '0 6px 5px 0 rgba(0, 0, 0, 0.2)');

      arcs
        .on('mouseover', (d) => {
          tooltip.text(this.data[d.index].tooltip);
          return tooltip.style('visibility', 'visible');
        })
        .on('mousemove', () => {
          return tooltip
            .style('top', (d3.event.pageY - 30) + 'px')
            .style('left', (d3.event.pageX + 10) + 'px');
        })
        .on('mouseout', () => {
          return tooltip.style('visibility', 'hidden');
        });
    }

    // Text
    if (this.title !== undefined) {
      this.svg.append('svg:text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '-10')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('style', 'font-size:30px')
        .text(this.title);
    }

    if (this.subTitle !== undefined) {
      this.svg.append('svg:text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '15')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('style', 'font-size:16px')
        .text(this.subTitle);
    }

    if (this.subSubTitle !== undefined) {
      this.svg.append('svg:text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '35')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('style', 'font-size:16px')
        .text(this.subSubTitle);
    }
  }

  private extractData(key: string): any[] {
    return this.data.map(d => d[key]);
  }
}
