<div class="list">
  <div class="actions">
    <a *ngIf="!!user.superuser || authService.hasPermission('admin')"  uiSref="symap.project.geometricPlanRequests.createFromExisting" class="button">
      <i class="fa fa-plus-square"></i>Vložit zavedený geometrický plán
    </a>
    <a [gmtAuth]="'manage_geometric_plans'" uiSref="symap.project.geometricPlanRequests.create" class="button">
      <i class="fa fa-plus-square"></i>Založit nový geometrický plán
    </a>
  </div>

  <h1>Geometrické plány</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">

    <!-- katastralni uzemi -->
    <gmt-select
      resource="areas"
      [filter]="{ sortOrder: { sortBy: 'name' }}"
      [data]="list.filter.filters.areaId"
      field="values"
      [multiselect]="true"
      selectTitle="Katastrální území"
    ></gmt-select>

    <!-- stavebni objekt -->
    <gmt-select
      resource="construction-objects"
      [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'socis' }}"
      [data]="list.filter.filters.constructionObjectId"
      field="values"
      [multiselect]="true"
      [itemPipe]="constructionObjectNamePipe"
      selectTitle="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}"
    ></gmt-select>

    <!-- parcely -->
    <gmt-select
      resource="parcels"
      [filter]="{ filters: { validity: 'valid' }}"
      [data]="list.filter.filters.parcelId"
      field="values"
      [multiselect]="true"
      [itemPipe]="parcelNumberPipe"
      selectTitle="Parcela"
    ></gmt-select>

    <!-- typ gp -->
    <gmt-select
      [optionItems]="typeOptions"
      [data]="list.filter.filters.type"
      field="values"
      [multiselect]="true"
      selectTitle="Typ GP"
    ></gmt-select>

    <!-- stav gp -->
    <gmt-select
      [optionItems]="statusOptions"
      [data]="list.filter.filters.status"
      field="values"
      [multiselect]="true"
      selectTitle="Stav GP"
    ></gmt-select>

    <div class="tag" [ngClass]="{ active: !list.filter.filters.deleted.values.length }" title="Zobrazení vč. GP, které jsou smazané" (click)="onToggleDeleted()">
      <div>Zobrazit zrušené GP</div>
    </div>

    <div>
      <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount && settingsLoaded">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">

            <!-- k.u. -->
            <span class="cell" *ngIf="!hideColumn('area')">
              <sort-by
                [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1]?.sortBy === 'minAreaName'"
                (sort)="pageableList.onSort(['areaCount', 'minAreaName'], $event)"
                [sortDir]="list.filter.sortOrder[0]?.direction"
              >Katastrální území</sort-by>
            </span>

            <span class="cell" *ngIf="!hideColumn('lv')">
              <sort-by
                [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[1].sortBy === 'minTitle'"
                (sort)="pageableList.onSort(['titleCount', 'minTitle'], $event)"
                [sortDir]="list.filter.sortOrder[0]?.direction"
              >LV</sort-by>
            </span>

            <!-- cislo gp -->
            <span class="cell" *ngIf="!hideColumn('name')">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Číslo GP</sort-by>
            </span>

            <!-- zhotovitel -->
            <span class="cell" *ngIf="!hideColumn('supplier')">
              <sort-by
                [selected]="'supplier' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('supplier', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Zhotovitel GP</sort-by>
            </span>

            <!-- typ -->
            <span class="cell" *ngIf="!hideColumn('type')">
              <sort-by
                [selected]="'type' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('type', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Typ</sort-by>
            </span>

            <!-- stav -->
            <span class="cell" *ngIf="!hideColumn('status')">
              <sort-by
                [selected]="'status' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('status', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Stav</sort-by>
            </span>

            <!-- k datu -->
            <span class="cell" *ngIf="!hideColumn('date')">
              <sort-by
                [selected]="'updatedDate' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('updatedDate', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >K datu</sort-by>
            </span>

            <!-- resene parcely -->
            <span class="cell" *ngIf="!hideColumn('parcels')">Řešené parcely</span>

            <!-- stavebni objekt -->
            <span class="cell" *ngIf="!hideColumn('constructionObject')">Stavební objekt</span>
          </div>
        </div>

        <div class="row-group">
          <a class="row" *ngFor="let gpr of list.list"  uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: gpr.id, tab: 'parcels-budouc' }">

            <!-- katastralni uzemi -->
            <span class="cell" *ngIf="!hideColumn('area')">
              <span *ngFor="let area of gpr.areas">
                {{ area.name }}
              </span>
            </span>

            <!-- LV -->
            <span class="cell" *ngIf="!hideColumn('lv')">
              <span *ngIf="gpr.parcels?.length">
                {{ getTitles(gpr) | titleNames }}
              </span>
            </span>

            <!-- cislo gp -->
            <span class="cell" *ngIf="!hideColumn('name')">{{ gpr.name }}</span>

            <!-- zhotovitel -->
            <span class="cell" *ngIf="!hideColumn('supplier')">{{ gpr.supplier }}</span>

            <!-- typ -->
            <span class="cell" *ngIf="!hideColumn('type')">{{ requestService.getEnumLabel('type', gpr.type) }}</span>

            <!-- stav -->
            <span class="cell" *ngIf="!hideColumn('status')">{{ requestService.getEnumLabel('status', gpr.status) }}</span>

            <!-- k datu -->
            <span class="cell text-center" *ngIf="!hideColumn('date')">{{ (gpr.updatedDate ? gpr.updatedDate : gpr.createdDate) | date }}</span>

            <!-- resene parcely -->
            <span class="cell no-padding" *ngIf="!hideColumn('parcels')">
              <cell-parcels
                [countParcels]="gpr.countParcels"
                [tooltipType]="'parcels'"
                [tooltipData]="{ filter: { filters: { geometricPlanRequestId: gpr.id }}}"
              ></cell-parcels>
            </span>

            <!-- stavebni objekt -->
            <span class="cell" *ngIf="!hideColumn('constructionObject')">
              <gmt-construction-object-list [constructionObjects]="gpr.constructionObjects"></gmt-construction-object-list>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.GEOMETRIC_PLAN_REQUEST_LIST" class="cm-help-page"></help>
</div>
