import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { AuthService } from '@app/common/services/auth.service';
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { CaseModel } from '@app/ps/models/case.model';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { DocumentModel } from '@app/common/models/document.model';
import { EasementModel } from '@app/common/models/easement.model';
import { ListModel } from '@app/common/models/list.model';
import { ListService } from '@app/common/services/list.service';
import { NoteModel } from '@app/common/models/note.model';
import { NotificationModel } from '@app/ps/models/notification.model';
import { OccupationGroupsType, ParcelService } from '@app/ps/services/parcel.service';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { SettingsService } from '@app/ps/services/settings.service';
import { SubjectDetailModel } from '@app/ps/models/subject-detail.model';
import { SubjectModel } from '@app/common/models/subject.model';
import { TabModel } from '@app/common/models/tab.model';
import { TableUtils } from '@app/common/utils/table.utils';
import { OwnershipModel } from '@app/common/models/ownership.model';
import { WordService } from '@app/common/services/word.service';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'owner-detail',
  templateUrl: './owner-detail.component.html',
  styleUrls: ['./owner-detail.component.scss']
})
export class OwnerDetailComponent implements OnInit, OnDestroy {
  @Input() owner: SubjectModel;
  @Input() callbacks: CallbackModel;

  private static readonly TABS: TabModel[] = [
    {
      name: 'Údaje o vlastníkovi',
      id: 'about',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Zastoupení vlastníka',
      id: 'representation',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Parcely',
      id: 'parcels',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Případy',
      id: 'cases',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Poznámky',
      id: 'notes',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Upozornění',
      id: 'notifications',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Historie',
      id: 'history',
      href: 'symap.project.owners.detail'
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: 'symap.project.owners.detail'
    },
  ];

  private static readonly OCCUPATION_PERMANENT_COLUMNS: (ColumnDefinition | string)[] = [
    'label_gp',
    {id: 'occupation_area', sortable: true},
    {id: 'occupation_title', sortable: true},
    {id: 'occupation_parcel_title', sortable: true},
    'occupation_concern_object',
    {id: 'occupation_phase', sortable: true},
    {id: 'occupation_occupation', sortable: true},
    {id: 'occupation_cases', sortable: true},
    'current_parcel_title',
    'current_protection',
    'current_concern_object'
  ];

  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: (ColumnDefinition | string)[] = [
    'label_gp',
    {id: 'occupation_area', sortable: true},
    {id: 'occupation_title', sortable: true},
    {id: 'occupation_parcel_title', sortable: true},
    'occupation_concern_object',
    {id: 'occupation_phase', sortable: true},
    {id: 'occupation_occupation', sortable: true},
    {id: 'occupation_cases', sortable: true},
    'current_parcel_title',
    'current_protection',
    'current_concern_object'
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: (ColumnDefinition | string)[] =
      OwnerDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly EASEMENT_COLUMNS: (ColumnDefinition | string)[] = [
    {id: 'area_name', sortable: true},
    {id: 'title_name', sortable: true},
    {id: 'parcel_title', sortable: true},
    'geometric_plan',
    'construction_object',
    'title',
    'area',
    'length',
    {id: 'case', sortable: true},
    { id: 'vb_tz', sortable: false },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: (ColumnDefinition | string)[] = [
    'occupation_price_parcel',
    'occupation_price_vegetation',
    'occupation_price_construction',
    'occupation_price_summary',
  ];

  private static readonly  OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: (ColumnDefinition | string)[] = [
    'occupation_assessment_price',
    'occupation_price_land_price',
    'occupation_price_rent_price_year',
    'occupation_price_rent_length',
    'occupation_price_total_rental_price',
  ];

  private static readonly  OCCUPATION_OVER_YEAR_PRICE_COLUMNS: (ColumnDefinition | string)[] =
      OwnerDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  private static readonly  EASEMENT_PRICE_COLUMNS: (ColumnDefinition | string)[] = [
    'subject_price'
  ];

  // params
  ownerId: number;
  tab: string;
  ownerContact: any = {};

  // model
  loading: boolean;
  tabs: TabModel[];
  noteList: ListModel<NoteModel>;
  documentList: ListModel<DocumentModel>;
  historyList: ListModel<NoteModel>;
  notificationList: ListModel<NotificationModel>;
  ownershipList: ListModel<OwnershipModel>;
  caseList: ListModel<CaseModel>;
  occupationList: ListModel<OccupationModel>;
  easementList: ListModel<EasementModel>;
  occupationGroups: OccupationGroupsType;
  notificationCounts = {archived: undefined, unarchived: undefined};
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  easementColumns: ColumnDefinition[];
  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private listService: ListService,
    private stateService: StateService,
    private parcelService: ParcelService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private wordService: WordService,
  ) {
    this.ownerId = this.stateService.params.ownerId;
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'about');

    this.isActiveTab = this.isActiveTab.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
    this.onUpdateSubject = this.onUpdateSubject.bind(this);
    this.onUpdateRepresentatives = this.onUpdateRepresentatives.bind(this);
    this.onUpdateProblematicNote = this.onUpdateProblematicNote.bind(this);
  }

  ngOnInit() {
    this.prepareParcelColumns();
    this.tabs = this.getTabs();
    this.loadNotes();
    this.loadDocuments();
    this.loadHistory();
    this.loadNotifications();
    this.loadOwnerships();
    this.loadCases();
    this.loadOccupations();
    this.loadEasements();
    this.registerCallbacks();
    this.ownerContact = this.getOwnerContact();
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onUpdateCaseList() {
    const caseTab = this.tabs.find((tab) => tab.id === 'cases');
    caseTab.name = `Případy (${this.caseList.itemCount})`;
  }

  onUpdateNotificationList() {
    const filter = {
      limit: 1,
      filters: {
        subjectId: this.ownerId,
        archive: false
      }
    };

    const archivedFilter = {...filter, filters: {...filter.filters, archive: true}};
    const notificationList = this.listService.createList('notifications', filter);
    const notificationListArchived = this.listService.createList('notifications', archivedFilter);

    this.listService.fetchResult(notificationList).then(data => {
      this.notificationCounts.unarchived = data.itemCount;
      this.updateNotificationTab();
    });

    this.listService.fetchResult(notificationListArchived).then(data => {
      this.notificationCounts.archived = data.itemCount;
      this.updateNotificationTab();
    });
  }

  onUpdateParcelList() {
    const parcelTab = this.tabs.find((tab) => tab.id === 'parcels');
    const occupationsParIDs = this.occupationList && this.occupationList.list
      ? this.occupationList.list.map((occupation: OccupationModel) => occupation.parcel.idpar)
      : [];
    const easementsParIDs = this.easementList && this.easementList.list
      ? this.easementList.list.map((easement: EasementModel) => easement.parcel.idpar)
      : [];
    const parcelsIDs = [...new Set([...occupationsParIDs, ...easementsParIDs])];
    parcelTab.name = `Parcely (${parcelsIDs.length})`;
  }

  onCaseTableSort(event: any) {
    this.listService.sort(this.caseList, event.column, event.ascOrDesc);
  }

  ngOnDestroy() {
    this.removeCallbacks();
  }

  /**
   * Keep frozen initial version of contact info
   * that is refreshed on form submit.
   */
  private getOwnerContact(): Partial<SubjectDetailModel> {
    return {
        phoneNumber: this.owner.subjectDetail && this.owner.subjectDetail.phoneNumber,
        email: this.owner.subjectDetail && this.owner.subjectDetail.email,
        databoxId: this.owner.subjectDetail && this.owner.subjectDetail.databoxId,
    };
  }

  private updateNotificationTab() {
    const notificationTab = this.tabs.find((tab) => tab.id === 'notifications');
    if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
      notificationTab.name = `Upozornění (${this.notificationCounts.unarchived}/${this.notificationCounts.archived})`;
    } else {
      notificationTab.name = 'Upozornění';
    }
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: {opsubId: this.owner.opsubId, systemType: false, cancelled: false, },
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      },
      undefined,
      {
        title: {
          load: true
        },
        businessCase: {
          load: true
        },
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
        'attachments',
        {
          filters: {subjectId: this.ownerId, cancelled: false},
          sortOrder: {sortBy: 'timestamp', direction: 'desc'}},
        undefined,
        {businessCase: {}}
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  public loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: {opsubId: this.owner.opsubId, systemType: true, },
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      },
      undefined,
      {
        subject: {
          load: true
        }
      },
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  public canEdit(): boolean {
    return this.authService.isAuthorized() || this.authService.hasPermission("assignable");
  }

  private loadNotifications() {
    this.notificationList = this.listService.createList(
      'notifications',
      {
        filters: {
          subjectId: this.ownerId,
          loadCollections: ['knBudouc.futureParcel'],
          archive: false
        },
        sortOrder: {sortBy: 'createdTimestamp', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.notificationList).then(() => {
      this.onUpdateNotificationList();
    });
  }

  private loadOwnerships() {
    this.ownershipList = this.listService.createList(
        'ownerships',
        {
          filters: {
            subjectId: this.ownerId,
            validity: 'valid'
          },
        },
        undefined,
        {
          subject: { load: true },
          duplicityOwnership: { load: true }
        }
    );
    this.listService.fetchResult(this.ownershipList);
  }

  private loadCases() {
    this.caseList = this.listService.createList(
        'cases',
        {
          limit: undefined,
          filters: {
            subjectId: this.ownerId,
            loadCollections: ['titles', 'caseOwnerships', 'constructionObjects']
          }
        }
    );
    this.listService.fetchResult(this.caseList).then(() => {
      this.onUpdateCaseList();
    });
  }

  private loadOccupations() {
    this.occupationList = this.listService.createList(
        'occupations',
        {
          limit: undefined,
          filters: {
            subjectId: this.ownerId,
            validOrWithCase: true,
            loadCollections: [
              'parcel.knBudouc.currentParcels',
              'parcel.knBudouc.currentParcels.bpejList',
              'constructionObjects'
            ]},
          sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}]
        }
    );
    this.listService.fetchResult(this.occupationList).then((data: ListModel<OccupationModel>) => {
      this.occupationGroups = this.parcelService.groupOccupationsByParcel(data.list, []);
      this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.underOneYear);
      this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.overOneYear);
      this.onUpdateParcelList();
    });
  }

  private loadEasements() {
    this.easementList = this.listService.createList(
        'easements',
        {
          limit: undefined,
          filters: {
            subjectId: this.ownerId,
            validOrWithCase: true,
            loadCollections: []
          },
          sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}]
        },
        undefined,
        {
          constructionObjects: {
            load: true,
            attributes: {
              administrator: {load: true},
            }
          }
        }
    );
    this.listService.fetchResult(this.easementList).then(() => {
      this.onUpdateParcelList();
    });
  }

  private getTabs(): TabModel[] {
    return OwnerDetailComponent.TABS.map(tab => {
      return {...tab, urlParams: {ownerId: this.ownerId, tab: tab.id}};
    });
  }

  private hideColumn(columnID: string): boolean {
    const tableID = 'titleDetail'; // @TODO - replace
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [...OwnerDetailComponent.OCCUPATION_PERMANENT_COLUMNS];
    let occupationUnderOneYearColumns = [...OwnerDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS];
    let occupationOverOneYearColumns = [...OwnerDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS];
    let easementColumns = [...OwnerDetailComponent.EASEMENT_COLUMNS];

    if (!this.hideColumn('permanentOccupationPriceColumns')) {
      occupationPermanentColumns = occupationPermanentColumns.concat(OwnerDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
      occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(OwnerDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryOverYearPriceColumns')) {
      occupationOverOneYearColumns = occupationOverOneYearColumns.concat(OwnerDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
      easementColumns = easementColumns.concat(OwnerDetailComponent.EASEMENT_PRICE_COLUMNS);
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.easementColumns = TableUtils.repairColumnsStructure(easementColumns);
  }

  private registerCallbacks() {
    this.callbacks.add(Callbacks.ProblematicNoteChanged, this.onUpdateProblematicNote);
    this.callbacks.add(Callbacks.SubjectUpdated, this.onUpdateSubject);
    this.callbacks.add(Callbacks.RepresentativesUpdated, this.onUpdateRepresentatives);
  }

  private removeCallbacks() {
    this.callbacks.remove(Callbacks.ProblematicNoteChanged, this.onUpdateProblematicNote);
    this.callbacks.remove(Callbacks.SubjectUpdated, this.onUpdateSubject);
    this.callbacks.remove(Callbacks.RepresentativesUpdated, this.onUpdateRepresentatives);
  }

  private onUpdateProblematicNote(event) {
    this.owner.subjectDetail = this.owner.subjectDetail || new SubjectDetailModel();
    this.owner.subjectDetail.problematic = event.owner.subjectDetail.problematic;
    this.loadNotes();
    this.loadHistory();
  }

  private onUpdateSubject(subjectDetail: SubjectDetailModel) {
    this.owner.subjectDetail = {...subjectDetail};
    this.ownerContact = this.getOwnerContact();
    this.loadHistory();
  }

  private onUpdateRepresentatives(representatives: ActingPersonModel[]) {
    this.owner.actingPersons = representatives;
  }
}
