import { Component, Inject, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'parcel-detail',
  templateUrl: './parcel-detail.component.html',
})
export class ParcelDetailComponent implements OnInit {

  @Input() parcelId: number;
  loading = true;
  data;
  list: ListModel<any>;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private listService: ListService,
    private stateService: StateService,
  ) {
  }

  ngOnInit(): void {
    this.restangular.one('parcels', this.parcelId).customPOST({
      attributes: {
        title: {
          mapping: {
            attributes: {
              area: {},
              ownerships: {
                mapping: {
                  attributes: {
                    subject: {
                      mapping: {
                        attributes: {
                          subjectSjm1: {},
                          subjectSjm2: {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    })
      .toPromise()
      .then((data) => {
      this.data = data;
      this.loading = false;
    });

    this.initList();
  }

  getSubject(ownership) {
    return ownership.subject.opsubType === 'BSM' ? ownership.subject.subjectSjm1 : ownership.subject;
  }

  private initList() {
    this.list = this.listService.createList(`parcels/${this.parcelId}/intersections`, {});
    this.listService.attachFilterStorage(this.list, 'intersectionsFilter');
    this.listService.fetchResult(this.list);
  }
  onSort(sortValue, sortDir) {
    this.listService.sort(this.list, sortValue, sortDir);
  }
}
