import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'cadastre-entry',
  templateUrl: './cadastre-entry.component.html',
  styleUrls: ['./cadastre-entry.component.scss']
})
export class CadastreEntryComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  step: any = 1;
  nextStates: string[];
  entryStoppedName: string;
  entryDeclinedName: string;
  cadastreEntryName: string;

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private caseService: CaseService,
    private stateService: StateService,
  ) {
    this.onDecline = this.onDecline.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.setStep = this.setStep.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get().toPromise();
    this.nextStates = (await this.restangular.one('cases', this.caseId).one('setting').get().toPromise()).nextStates.items;
    this.entryStoppedName = this.nextStates.includes('EntryStopped') ? (await this.caseStatusService.loadStatusName('EntryStopped')).name : null;
    this.entryDeclinedName = this.nextStates.includes('EntryDeclined') ? (await this.caseStatusService.loadStatusName('EntryDeclined')).name : null;
    this.cadastreEntryName = this.nextStates.includes('CadastreEntry') ? (await this.caseStatusService.loadStatusName('CadastreEntry')).name : null;

    this.data.legalEffectsDate = this.data.entryProposalDate;
    this.data.cadastreEntryDeliveryDate = new Date();
    this.data.cadastreEntryDate = new Date();
    this.data.cadastreEntryDeclinedDate = new Date();
    this.data.cadastreEntryStoppedDate = new Date();

    if (this.nextStates.length === 1) {
      this.setStep(this.nextStates[0]);
    }

    this.loading = false;
  }

  setStep(step) {
    this.step = step;
  }

  onDateChanged(value, from: 'CadastreEntryDeliveryDate' | 'CadastreEntryDate') {
    const duePeriod = this.data.obligation.duePeriod;
    const dueDataFromType = this.data.obligation.dueDateFromType;

    if (!this.data.paymentOrderDate && duePeriod && dueDataFromType === from) {
      if (value) {
        const date = new Date(value);
        this.data.dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
      } else {
        this.data.dueDate = null;
      }
    }
  }

  isValidStop() {
    return this.data.cadastreEntryStoppedDate;
  }

  async onStop(): Promise<any> {
    const sendData = {
      cadastreEntryStoppedDate: this.data.cadastreEntryStoppedDate
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'stop-cadastre-entry')
      .toPromise();
    this.stateService.go('^');
  }

  isValidDecline() {
    return this.data.cadastreEntryDeclinedDate;
  }

  async onDecline(): Promise<any> {
    const sendData = {
      cadastreEntryDeclinedDate: this.data.cadastreEntryDeclinedDate
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'decline-cadastre-entry')
      .toPromise();
    this.stateService.go('^');
  }

  isValidAccepted() {
    return this.data.cadastreEntryDeliveryDate && this.data.cadastreEntryDate && this.data.cadastreEntryNumber && this.data.legalEffectsDate;
  }

  async onConfirm(): Promise<any> {
    const sendData = {
      dueDate: this.data.dueDate,
      cadastreEntryDeliveryDate: this.data.cadastreEntryDeliveryDate,
      legalEffectsDate: this.data.legalEffectsDate,
      cadastreEntryDate: this.data.cadastreEntryDate,
      cadastreEntryNumber: this.data.cadastreEntryNumber,
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'confirm-cadastre-entry')
      .toPromise();
    this.stateService.go('^');
  }
}
