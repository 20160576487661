// @TODO - move to ../common/models...
export class Price {
  price: number;
  landCount: number;
  lvCount: number;

  public static createFromApi(data: any): Price {
    const price = new Price();
    price.price = (data.price && data.price !== -1 ? data.price : 0);
    price.landCount = (data.parcelCount ? data.parcelCount : 0);
    price.lvCount = (data.lvcount ? data.lvcount : 0);
    return price;
  }
}
