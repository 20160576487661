﻿import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent {
  @Input() applications: any;

  constructor(
    public authService: AuthService,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {}

  hasApplication(applicationId: any) {
    return this.applications.includes(applicationId);
  }
}
