<div class="detail">
  <div class="row mb-10">
    <div class="col-6">
      <gmt-button [clickAction]="onClose"><i class="fa fa-chevron-left mr-5"></i> Zpět</gmt-button>
    </div>
    <div class="col-6 text-right">
      <gmt-button [clickAction]="onDelete" *ngIf="canDelete" class="red">Smazat</gmt-button>
    </div>
  </div>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <h1>{{ opinionId ? (readonly ? 'Náhled' : 'Úprava' ) + ' znaleckého posudku' : 'Nový znalecký posudek' }}
        <span>LV {{ title.lv }}</span>
      </h1>

      <div *ngIf="caseExistsAndNotCancelled" class="mt-5">
        <i class="fa fa-info-circle"></i> Znalecký posudek je použitý v aktivním případu. Nelze editovat či smazat.
      </div>

      <div class="mt-20">
        <div class="flexbox-container">
          <div class="content-menu">
            <div
              *ngFor="let step of steps"
              (click)="onStep(step.id)"
              [ngClass]="{ active : step.id === activeStep.id, cancelled: !step.enabled }
            ">{{ step.name }}</div>
          </div>
          <div class="flex-rest">
            <pricing-expert-opinion-base
              *ngIf="activeStep.id === 'base'"
              [data]="data"
              (submitCallback)="onStep('parcels', true)"
            ></pricing-expert-opinion-base>

            <pricing-expert-opinion-parcels
              *ngIf="activeStep.id === 'parcels'"
              [data]="data"
              (submitCallback)="onStep('summary', true)"
              (backCallback)="onStep('base')"
            ></pricing-expert-opinion-parcels>

            <pricing-expert-opinion-summary
              *ngIf="activeStep.id === 'summary'"
              [data]="data"
              (submitCallback)="onFinish()"
              (backCallback)="onStep('parcels')"
            ></pricing-expert-opinion-summary>
          </div>
        </div>
      </div>

      <help [helpId]="helpIds[activeStep.help]" class="cm-help-page"></help>
    </div>
  </div>
</div>
