﻿import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ListService } from '../../services/list.service';

@Component({
	selector: 'itemscounter',
	templateUrl: './itemscounter.component.html',
	styleUrls: ['./itemscounter.component.scss']
})
export class ItemsCounterComponent implements OnInit {
	@Input() list: any;
	@Output() callbackRegister = new EventEmitter();
	itemsCount = 0;
	items = 'záznamů';

	constructor(private listService: ListService) {
	  this.onListChanged = this.onListChanged.bind(this);
	}

	ngOnInit() {
      this.callbackRegister.emit({
        onListChanged: this.onListChanged,
      });
      this.onListChanged();
	}

	onListChanged() {
      this.itemsCount = this.listService.getItemsCount(this.list);

      switch (true) {
		case (this.itemsCount === 1):
			this.items = 'záznam';
			break;
		case (this.itemsCount > 1 && this.itemsCount < 5):
			this.items = 'záznamy';
			break;
		default:
			this.items = 'záznamů';
			break;
      }
	}
}