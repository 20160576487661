<div class="jumbotron" *ngIf="countResolved!==data.selectEntities.count">
	<div *ngIf="list.loading" class="centered-text">Načítá se seznam případů.</div>
	<div *ngIf="!list.loading" class="centered-text">Probíhá vyhodnocení dostupných stavů případů a oprávnění.</div>
	<div *ngIf="!list.loading" class="centered-text"><b>{{countResolved}}/{{data.selectEntities.count}}</b></div>
	<div [loading]="true"></div>
</div>
<div *ngIf="countResolved === data.selectEntities.count">
	<fieldset *ngIf="inputData.noStatusGroup.length">
		<legend>Případy bez možnosti změny stavu</legend>
		<div class="column-100">
			<label>Případy</label>
			<div class="info comma-list"><span *ngFor="let case of inputData.noStatusGroup | slice:0:10"><a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{case.mpp.number||'bez čísla'}}</a></span><span *ngIf="inputData.noStatusGroup.length > 10">dalších {{inputData.noStatusGroup.length - 10}} případů</span></div>
		</div>
	</fieldset>

	<fieldset *ngIf="inputData.groups.length">
		<legend>Změna stavu případů</legend>
		<div class="column-100">
			<label class="required">Nový stav případu</label>
			<div class="input">
				<div class="checklist">
					<div class="checklist-item" *ngFor="let group of inputData.groups" (click)="setActiveNextStatus(group)">
						<div [ngClass]="{cancelled: !group.saveEndpoint}">
							<gmt-radio [gmtChecked]="inputData.group && inputData.group.caseStatus.id === group.caseStatus.id"></gmt-radio> {{group.caseStatus.name}} ({{group.cases.length}})
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>

	<fieldset *ngIf="inputData.group">
		<legend>Detaily stavu</legend>
		<div class="column-100">
			<label>Případy</label>
			<div class="info comma-list" *ngIf="inputData.group.cases.length"><span *ngFor="let case of inputData.group.cases | slice:0:10"><a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}">{{case.mpp.number||'bez čísla'}}</a></span><span *ngIf="inputData.group.cases.length > 10">dalších {{inputData.group.cases.length - 10}} případů</span></div>
			<div class="info" *ngIf="!inputData.group.cases.length">žádné případy</div>
		</div>
	</fieldset>
</div>

<div [style.display]="inputData.group && inputData.group.cases.length ? 'block' : 'none'">
	<ng-template #statusDetails></ng-template>
</div>
