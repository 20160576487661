import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { CaseModel } from '@app/ps/models/case.model';

/**
 * Display name of case
 */
@Pipe({
  name: 'caseName',
})
@Injectable({
  providedIn: 'root'
})
export class CaseNamePipe implements PipeTransform {

  transform(businessCase: CaseModel) {
    return businessCase.mpp.number ? businessCase.mpp.number : 'bez čísla';
  }
}
