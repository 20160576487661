<div class="project-name" *ngIf="projects?.length === 1">
  {{ projects[0] | projectName }}
</div>
<div class="project-select" *ngIf="projects?.length > 1">
  <gmt-select
    [data]="this"
    required="true"
    field="selectedProject"
    [optionItems]="projects"
    [itemPipe]="projectNamePipe"
    (changed)="onProjectSelect($event)"
  ></gmt-select>
</div>
