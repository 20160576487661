import { Inject, Injectable } from '@angular/core';
import { DocumentTypeModel } from '@app/sv/models/document-type.model';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  constructor(
    private restangular: Restangular,
  ) {
  }

  upload(documentType: DocumentTypeModel): Promise<DocumentTypeModel> {
    return this.restangular.one('document-types').customPUT(documentType)
      .toPromise()
      .then(data => {
        return data.plain();
      });
  }
}
