<h1>Workflow typu případu</h1>

<div [loading]="loading">
  <div *ngIf="!loading && data.flow !== null">
    <div *ngIf="data.readOnly && !editable" class="jumbotron mt-20 mb-20">
      Tento typ má již založené případy. Změna pořadí stavů ve workflow nebo odebrání stavů může způsobit zablokování změnu stavu některých případů.
      <div class="mt-10"><gmt-button (click)="editable = true">Povolit editaci</gmt-button></div>
    </div>
    <div class="workflow">
      <div class="bordered mt-20 hover" *ngFor="let caseStatus of flowDataSort;">
        <ng-container *ngIf="flowData[caseStatus] as flowStatusExt">
          <div id="statusAnchor{{ flowStatusExt.key }}" class="flow-status">
            <a><i class="fa fa-arrow-up" (click)="up(caseStatus)"></i></a>&nbsp;
            <a><i class="fa fa-arrow-down" (click)="down(caseStatus)"></i></a>
            {{ flowStatusExt.name }}
            <a (click)="removeFlowStatus(caseStatus)" *ngIf="caseStatus !== 'Created' && editable"><i class="fa fa-remove"></i></a>
          </div>
          <div class="enter-statuses" *ngIf="editable || flowStatusExt.flowStatus.enterStatuses.length">
            <i class="fa fa-sign-in"></i>
            <span *ngFor="let enterStatus of flowStatusExt.flowStatus.enterStatuses">
              <a (click)="goToStatusAnchor(enterStatus)">{{ data.flow[enterStatus].name }}</a>&nbsp;
              <a (click)="removeEnterStatus(enterStatus, caseStatus)" *ngIf="editable"><i class="fa fa-remove"></i></a>
            </span>
            <gmt-select
              *ngIf="editable"
              [optionItems]="caseStatusOptions"
              selectTitle="přidat stav"
              required="true"
              [data]="data"
              field="selected"
              [filter]="flowStatusExt.enterFilter"
              [reloadOptionsRegister]="reloadOptionsRegisterEnter(flowStatusExt)"
              (changed)="selectedEnterStatus(caseStatus, $event.newValue, data)"
            ></gmt-select>
          </div>
          <div class="leave-statuses" id="prcat" *ngIf="editable || flowStatusExt.leaveStatuses.length">
            <i class="fa fa-sign-out"></i>
            <span *ngFor="let leaveStatus of flowStatusExt.leaveStatuses">
              <a (click)="goToStatusAnchor(leaveStatus)">{{ data.flow[leaveStatus].name }}</a>&nbsp;
              <a (click)="removeLeaveStatus(leaveStatus, caseStatus)" *ngIf="editable"><i class="fa fa-remove"></i></a>
            </span>
            <gmt-select
              *ngIf="editable"
              [optionItems]="caseStatusOptions"
              selectTitle="přidat stav"
              required="true"
              [data]="data"
              field="selected"
              [filter]="flowStatusExt.leaveFilter"
              [reloadOptionsRegister]="reloadOptionsRegisterLeave(flowStatusExt)"
              (changed)="selectedLeaveStatus(caseStatus, $event.newValue, data)"
            ></gmt-select>
          </div>

          <div class="documents">
            <i class="fa fa-file"></i>
            <span *ngFor="let document of flowStatusExt.flowStatus.documents">{{ documentTemplateNames[document.key] }}
              <ng-container *ngIf="documentTemplateNames[document.key]">
                <a (click)="removeDocument(document, caseStatus)"><i class="fa fa-remove"></i></a>&nbsp;
                <a (click)="editDocument($event, document)"><i class="fa fa-pencil"></i></a>
              </ng-container>
            </span>
            <gmt-select
              [optionItems]="documentTemplatesOptions"
              selectTitle="přidat dokument"
              required="true"
              [data]="data"
              field="selected"
              [filter]="flowStatusExt.documentFilter"
              [reloadOptionsRegister]="reloadOptionsRegisterDocument(flowStatusExt)"
              (changed)="selectedDocument(caseStatus, $event.newValue, data)"></gmt-select>
          </div>
          <div class="documents" (click)="toggleAllowCadastreEntryProposal(flowStatusExt)">
            <gmt-checkbox [gmtChecked]="!!flowStatusExt.flowStatus.allowCadastreEntryProposal"></gmt-checkbox> Zobrazit úlohu "Generovat návrh na vklad do KN"
          </div>
        </ng-container>
      </div>
    </div>
    <div class="text-center actions" *ngIf="data.flow !== null">
      <gmt-button [clickAction]="update">Uložit</gmt-button>
      <gmt-button class="red" [clickAction]="reset" *ngIf="!data.readOnly || editable">Obnovit výchozí</gmt-button>
    </div>
  </div>
  <div *ngIf="!loading && data?.flow === null" class="empty-info">
    Typ případu nemá definované variabilní workflow
  </div>
</div>
