import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import * as _ from 'lodash';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'declined-owner',
  templateUrl: './declined-owner.component.html',
  styleUrls: ['./declined-owner.component.scss']
})
export class DeclinedOwnerComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  dataSend: any = {
    declinedOwnerDate: new Date(),
    note: {},
  };
  name: string;
  dataCaseOwnerships;
  recipient;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({ loadCollections: [ 'caseSubjects', 'caseOwnerships' ]}).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('DeclinedOwner')).name;

    this.dataCaseOwnerships = [...this.data.caseOwnerships];
    this.dataCaseOwnerships.forEach((item) => {
      let bsmSubject;
      if (item.ownership.isBsm) {
        bsmSubject = this.data.caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId).subject;
      }
      item.caseSubjects = this.data.caseSubjects.filter(
        (value) => (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId))
      );
    });

    this.recipient = _.find(this.caseService.getRecipients(this.dataCaseOwnerships),
      (value) => {
        return !value.signedDate && (value.sentDate || value.personalVisitDate);
      }
    );

    this.loading = false;
  }

  isValid() {
    return !!this.dataSend.declinedOwnerDate && this.dataSend.note.text && this.dataSend.note.noteType;
  }

  async onSubmit(): Promise<any> {
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(this.dataSend, 'decline-owner')
      .toPromise();
    this.stateService.go('^');
  }

}
