import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { BrandService } from '@app/common/services/brand.service';

@Directive({
  selector: '[visibleByBrand]'
})
export class BrandVisibilityDirective implements OnInit {
  @Input('visibleByBrand') visibleByBrand: string;

  constructor(
    private elementRef: ElementRef,
    private brandService: BrandService,
  ) {}

  ngOnInit() {
    if (this.visibleByBrand && !this.brandService.isVisible(this.visibleByBrand)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
