﻿<div (click)="$event.stopPropagation()" class="cm-app-switcher-menu">
    <ng-container *ngIf="hasApplication('SY')"><ng-container *ngTemplateOutlet="sy"></ng-container></ng-container>
    <div visibleByBrand="maja">
      <ng-container *ngIf="hasApplication('DI')"><ng-container *ngTemplateOutlet="di"></ng-container></ng-container>
      <ng-container *ngIf="hasApplication('PRO')"><ng-container *ngTemplateOutlet="pk"></ng-container></ng-container>
      <ng-container *ngIf="hasApplication('DASH')"><ng-container *ngTemplateOutlet="dashboard"></ng-container></ng-container>
      <ng-container *ngIf="hasApplication('VFZE')"><ng-container *ngTemplateOutlet="vfze"></ng-container></ng-container>
    </div>
    <div visibleByBrand="mpv">
      <ng-container *ngIf="hasApplication('PRO')"><ng-container *ngTemplateOutlet="pk"></ng-container></ng-container>
    </div>
    <ng-container *ngIf="hasApplication('SETTINGS')"><ng-container *ngTemplateOutlet="settings"></ng-container></ng-container>
</div>

<ng-template #sy>
    <a uiSref="symap.project.home" [uiParams]="{projectKey: authService.getSYdefaultProjectKey() || 'sy_'}" class="application">
        <img src="./img/logo/{{APP_BRAND.PS.ICON_PATH}}_m.svg" class="logo-image"/>
        &nbsp;
        <img src="./img/logo/{{APP_BRAND.PS.ICON_PATH}}_xl.svg" class="logo-text"/>
    </a>
</ng-template>

<ng-template #di>
    <a uiSref="dimap.project.propertyStatus" [uiParams]="{projectKey: authService.getDIdefaultProjectKey() || 'di_'}" class="application">
        <img src="./img/logo/dimap_m.svg" class="logo-image"/>
        &nbsp;
        <img src="./img/logo/dimap_xl.svg" class="logo-text"/>
    </a>
</ng-template>

<ng-template #pk>
    <a uiSref="pk.projects" class="application">
        <img src="./img/logo/promap_m.svg" class="logo-image"/>
        &nbsp;
        <img src="./img/logo/promap_xl.svg" class="logo-text"/>
    </a>
</ng-template>

<ng-template #dashboard>
    <a uiSref="dashboard.projects" class="application">
        <img src="./img/logo/dashboard_m.svg" class="logo-image"/>
        &nbsp;
        <img src="./img/logo/dashboard_xl.svg" class="logo-text"/>
    </a>
</ng-template>

<ng-template #vfze>
  <a uiSref="vfze.validation" class="application">
      <img src="./img/logo/vfze_m.svg" class="logo-image"/>
      &nbsp;
      <img src="./img/logo/vfze_xl.svg" class="logo-text"/>
  </a>
</ng-template>

<ng-template #settings>
    <a uiSref="settings.users" class="application">
        <img src="./img/logo/settings_m.svg" class="logo-settings-image"/>
        &nbsp;
        <img src="./img/logo/settings_xl.svg" class="logo-settings-text"/>
    </a>
</ng-template>
