import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ParcelModel } from '../models/parcel.model';
import { ParcelNumberPipe } from './parcel-number.pipe';

@Pipe({
  name: 'parcelName'
})
@Injectable({
  providedIn: 'root'
})
export class ParcelNamePipe implements PipeTransform {

  constructor(
    private parcelNumberPipe: ParcelNumberPipe,
  ) {
    this.transform = this.transform.bind(this);
  }

  transform(parcel: ParcelModel) {
    let name = '-';

    const parcelNumber = this.parcelNumberPipe.transform(parcel);
    if (parcelNumber) {
      name = parcelNumber;
    }

    if ((parcel.title && parcel.title.lv) || (parcel.area && parcel.area.name) || parcel.katuzeNazev) {
      const detail = [];
      if (parcel.title && parcel.title.lv) {
        detail.push('LV' + parcel.title.lv)
      }
      if ((parcel.area && parcel.area.name) || parcel.katuzeNazev) {
        detail.push('KÚ ' + (parcel.katuzeNazev || parcel.area.name))
      }
      name += ' (' + detail.join(' - ') + ')';
    }
    return name;
  }
}
