import { Component, Input, Inject, OnInit } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expropriation-administrative-appeal',
  templateUrl: './expropriation-administrative-appeal.component.html',
})
export class ExpropriationAdministrativeAppealComponent implements OnInit {
  @Input() caseId: number;
  public loading = true;
  public data: CaseModel;
  public isValid: Function;
  public recipient: any;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.restangular.one('cases', this.caseId).get().toPromise().then((data) => {
      this.data = data;
      this.loading = false;
    });
  }

  formCallbackRegister($event) {
    this.isValid = $event.isValid;
  }

  async onSubmit(): Promise<any> {
    const sendData = {
      expropriationAdministrativeAppealDate: this.data.expropriationAdministrativeAppealDate,
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'expropriation-administrative-appeal')
      .toPromise();

    this.stateService.go('^');
  }
}

