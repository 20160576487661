export enum Callbacks {
  ProblematicNoteChanged = 'problematic-note-changed',
  DataValidityChanged = 'data-validity-changed',
  CaseUpdated = 'caseUpdated',
  CaseSubjectUpdated = 'caseSubjectUpdated',
  CaseOwnershipUpdated = 'caseOwnershipUpdated',
  SubjectUpdated = 'subjectUpdated',
  RepresentativesUpdated = 'representativesUpdated',
  ConstructionObjectUpdated = 'constructionObjectUpdated',
}
