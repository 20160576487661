<div [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }">
    <div class="logo">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault()" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i [ngClass]="['fa', 'fa-bars']"></i>
      </a>
      <div class="image-container">
        <img src="./img/logo/dimap_xxl.svg" alt="logo"/>
      </div>
    </div>
    <div>
      <login [user]="user" [servicedeskUrl]="servicedeskUrl" (logoutClick)="logout()"></login>
    </div>
    <gmt-project-select [projects]="projects" [selectedProject]="selProject" (selected)="changeProject($event)"></gmt-project-select>
  </div>
  <div class="main">
    <app-menu id="menu" [menuConfig]="menuConfig" [collapsed]="collapsed" [ngClass]="{collapsed: collapsed}" class="flexbox-container"></app-menu>
    <div id="content-wrap">
      <div ui-view="content" id="content"> </div>
    </div>
  </div>
</ng-container>
