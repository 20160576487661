import { Component, OnInit } from "@angular/core";
import * as _ from 'lodash';

import { EasementModel } from '@app/common/models/easement.model';
import { TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { AuthService } from '@app/common/services/auth.service';
import { DialogService } from '@app/common/services/dialog.service';
import { UpdateEasementPriceComponent } from '@app/ps/components/update-easement-price/update-easement-price.component';

@Component({
  selector: 'table-geometric-plans',
  templateUrl: './table-geometric-plans.component.html',
})
export class TableGeometricPlansComponent extends TableParcelsAbstractComponent<EasementModel> implements OnInit {

  // props
  protected readonly COMPONENT_COLUMNS = [
    'parcel', 'title', 'area', 'length', 'price', 'price_edit_construction_object', 'cases',
    'construction_objects_by_shared_easement', 'vb_tz'
  ];

  // model
  showVbColumn: boolean;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService
  ) {
    super();
    this.onPriceEdit = this.onPriceEdit.bind(this);
  }

  ngOnInit() {
    this.showVbColumn = this.data.some(easement => easement.hasOwnProperty('oznacVb') && easement.oznacVb);
  }

  isEditablePrice(easement: EasementModel) {
    // Hodnoty na všech SO shodné
    const easementGlobalPriceType = new Set(easement.constructionObjects.map(co => co.easementGlobalPriceType)).size == 1 ?
      easement.constructionObjects[0].easementGlobalPriceType : null;

    return this.visibleColumns.price_edit_construction_object
      && easement.parcel.title
      && (this.authService.isAuthorized() || this.authService.hasPermission("assignable"))
      && easementGlobalPriceType
      && !easement.existsCaseBlockingPrice;
  }

  onPriceEdit(easement: EasementModel) {
    if (!this.isEditablePrice(easement)) {
      return;
    }

    const dialog = this.dialogService.open(UpdateEasementPriceComponent, {
      data: { easementId: easement.id },
    });

    const sub = dialog.afterClosed.subscribe(updatedEasement => {
      if (updatedEasement) {
        const i = _.findIndex(this.data, { id: updatedEasement.id });
        this.data.splice(i, 1, updatedEasement);
      }
      sub.unsubscribe();
    });
  }
}
