<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col-6">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Přehled vzorů</a>
      </div>
      <div class="col-6 text-right">
        <action-buttons name="Možnosti" *ngIf="canBeActualized() || sample.status === sampleStatusEnum.ACTIVE">
          <a gmtAuthProject
             actions="template_new"
             [project]="sample.organizationalUnitCode"
             *ngIf="canBeActualized()"
             uiSref="sv.tickets.create"
             [uiParams]="{ id: sample.id }"
             class="bordered-bottom p-10"
          >Aktualizovat vzor</a>
          <a gmtAuthProject
             actions="template_manage"
             [project]="sample.organizationalUnitCode"
             *ngIf="sample.status === sampleStatusEnum.ACTIVE || sample.status === sampleStatusEnum.WAITING_FOR_ACTUALIZATION"
             (click)="onSampleInactivate()"
             class="bordered-bottom p-10"
          >Zneplatnit vzor</a>
          <a gmtAuthProject
             actions="template_manage"
             [project]="sample.organizationalUnitCode"
             *ngIf="sample.status === sampleStatusEnum.ACTIVE"
             (click)="onSampleToWaitingForActualization()"
             class="bordered-bottom p-10"
          >Vynutit aktualizaci vzoru</a>
        </action-buttons>
      </div>
    </div>

    <h1>Složka vzoru {{ sample.number }}</h1>

    <div class="case-status">
      <span>Stav: <b>{{ sample.status | sampleStatusName }}</b></span>
      <span>Typ dokumentu: <b>{{ sample.documentType | documentTypeFullname }}</b></span>
    </div>

    <span *ngIf="sample.assignable === false" class="tag tag--danger tag--small is-uppercase">není učen pro {{ APP_BRAND.PS.TITLE }}</span>

    <div class="mt-30 mb-70 flexbox-container">
      <div class="column-50">
        <div visibleByBrand="sv.unit">
          <label>{{ 'sv.section' | brandTranslate }}:</label>
          <div class="info">
            {{ sample.section }}
          </div>
        </div>
        <label>{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}:</label>
        <div class="info">
          {{ sample.organizationalUnitName }}
        </div>
        <ng-container *ngIf="sample.assignable !== false">
          <label>V {{ APP_BRAND.PS.TITLE }} od:</label>
          <div class="info">
            {{ sample.createdDate | date: 'd.M.yyyy' }}
          </div>
          <label>V {{ APP_BRAND.PS.TITLE }} do:</label>
          <div *ngIf="sample.status === sampleStatusEnum.INACTIVE" class="info">
            {{ sample.deletedDate | date: 'd.M.yyyy' }}
          </div>
        </ng-container>
      </div>
      <div class="column-50">
        <label>Účel (popis):</label>
        <div class="info">
          {{ sample.description }}
        </div>
        <ng-container *ngIf="sample.assignable !== false">
          <label>Upřesnění názvu generovaného dokumentu:</label>
          <div class="info">
            {{ sample.documentNameSpecification }}
          </div>
        </ng-container>
        <label>Předchozí verze vzoru:</label>
        <div class="info">
          <a *ngIf="sample.parentSample" uiSref="sv.samples.detail" [uiParams]="{ id: sample.parentSample.id }">{{ sample.parentSample.number }}</a>
        </div>
        <label>Navazující verze vzoru:</label>
        <div class="info">
          <a *ngIf="sample.childSample" uiSref="sv.samples.detail" [uiParams]="{ id: sample.childSample.id }">{{ sample.childSample.number }}</a>
        </div>
        <label>Vytvořen na základě požadavku:</label>
        <div class="info">
          <a *ngIf="canViewTicket()" uiSref="sv.tickets.detail" [uiParams]="{ id: sample.madeByTicket.id }">{{ sample.madeByTicket.number }}</a>
          <span *ngIf="!canViewTicket()">{{ sample.madeByTicket.number }}</span>
        </div>
        <label>Požadavek na aktualizaci:</label>
        <div class="info">
          <ng-container *ngIf="getActualizationTicket() as actualizationTicket">
            <a *ngIf="canViewTicket()" uiSref="sv.tickets.detail" [uiParams]="{ id: actualizationTicket.id }">{{ actualizationTicket.number }}</a>
            <span *ngIf="!canViewTicket()">{{ actualizationTicket.number }}</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="canEditNote()"
            [allowType]="true"
            [defaultData]="{ sample: { id: sample.id }, systemType: false }"
            (updated)="onUpdateNoteList();loadHistory();"
            [noteTypeResolver]="noteTypeResolver"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'projects'">
          <ng-container *ngIf="projects.length; else noProjects">
            <table class="bordered mb-10">
              <thead>
              <tr>
                <th>Aktivní</th>
                <th>Povolená</th>
                <th>Evidenční číslo</th>
                <th>Název akce</th>
              </tr>
              </thead>
              <tr *ngFor="let project of projects">
                <td><gmt-checkbox [gmtChecked]="isProjectActive(project)" (click)="toggleProjectActive(project)" [gmtDisabled]="!canUserManage() || sample.status==='ACTIVE' || sample.status==='WAITING_FOR_ACTUALIZATION'"></gmt-checkbox></td>
                <td><gmt-checkbox [gmtChecked]="isProjectEnabled(project)" (click)="toggleProjectDisabled(project)" [gmtDisabled]="!canUserManage() || !isProjectActive(project, false)"></gmt-checkbox></td>
                <td>{{ project.isprofond }}</td>
                <td><a uiSref="symap.project.home" [uiParams]="{ projectKey: project.key }">{{ project.name }}</a></td>
              </tr>
            </table>

            <gmt-button
              gmtAuthProject
              actions="template_manage"
              [project]="sample.organizationalUnitCode"
              [clickAction]="onUpdateProjects"
              [gmtDisabled]="sample.status === sampleStatusEnum.WAITING_FOR_ACTUALIZATION"
            >Uložit</gmt-button>
        </ng-container>
          <ng-template #noProjects>
            <div class="empty-info bordered">
              Nenalezena žádná data
            </div>
          </ng-template>
        </div>

        <div *ngIf="tab === 'history'">
          <gmt-notes
            [type]="'history'"
            [noteList]="historyList"
            [editable]="false"
            [allowType]="true"
            [noteTypeResolver]="noteTypeResolver"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'documents'">
          <div class="bordered" *ngIf="documentList.itemCount">
            <table>
              <thead>
              <tr>
                <th>
                  <sort-by
                    [selected]="'name' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('name', documentList.filter.sortOrder.direction)"
                  >Název</sort-by>
                </th>
                <th>
                  <sort-by
                    [selected]="'createdUser.name' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('createdUser.name', documentList.filter.sortOrder.direction)"
                  >Nahrál</sort-by>
                </th>
                <th>
                  <sort-by
                    [selected]="'timestamp' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('timestamp', documentList.filter.sortOrder.direction)"
                  >Datum nahrání</sort-by>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let document of documentList.list">
                <td class="attachment-item">
                  <div>
                    <div>
                      <a href="{{ restBaseUrl }}/attachments/{{ document.id }}/data?t={{ authToken }}">
                        <i class="mr-10 fa fa-lg flex-icon {{ document | documentType : 'icon' }}"></i>
                      </a>
                    </div>
                    <div>
                      <a href="{{ restBaseUrl }}/attachments/{{document.id}}/data?t={{ authToken }}">
                        {{ document.baseName + (document.extension ? '.' + document.extension : '') }}
                      </a>
                      <p>{{document | documentType : 'title'}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  {{ document.userFullName }}
                </td>
                <td>
                  {{ document.timestamp | date : 'short' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="bordered" *ngIf="!documentList.itemCount">
            <div class="empty-info">
              <span>Žádné dokumenty</span>
            </div>
          </div>

          <div gmtAuthProject actions="template_manage" [project]="sample.organizationalUnitCode" class="mt-20">
            <fileupload
              [message]="templateFilesMsg"
              (change)="onTemplateFilesChange($event)"
            ></fileupload>
          </div>
        </div>
      </gmt-navbar>
    </div>
  </div>
</div>
