export class EnumUtils {
  /**
   * Transforms each enum member into an object with:
   * enum member name represented with `withKeyName`
   * enum member value represented with `withValueName`.
   */
  public static toObject(_enum: any, withKeyName = 'id', withValueName = 'value'): {[key: string]: any}[] {
    return Object.keys(_enum).map((key) => {
      const tmp = {};
      tmp[withKeyName] = key;
      tmp[withValueName] = _enum[key];
      return tmp;
    });
  }
}
