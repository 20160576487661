import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapStorageHelpersService {

  constructor() { }

  getDefer(obj, id) {
      var defer;
      if (_.isEmpty(obj[id]) || obj[id].resolvedDefer === false) {
          defer = this.getUnresolvedDefer(obj, id);
      } else {
          defer = obj[id].defer;
      }
      return defer;
  }

  getUnresolvedDefer(obj, id) {
      let defer;
      if (_.isEmpty(obj[id]) || obj[id].resolvedDefer === true) {
          let reject;
          let resolve;
          defer = {};
          defer.promise = new Promise((_resolve, _reject) => {
            reject = _reject;
            resolve = _resolve;
          });
          defer.reject = reject;
          defer.resolve = resolve;
          obj[id] = {
              defer: defer,
              resolvedDefer: false
          };
      } else {
          defer = obj[id].defer;
      }
      return defer;
  }

  setResolvedDefer(obj, id) {
      obj[id].resolvedDefer = true;
  }
}

