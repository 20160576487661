<h1>{{ changelogId ? 'Editace' : 'Založení' }}</h1>

<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <div class="column-100">
        <gmt-input label="Titulek" class="mt-10" [required]="true">
          <input type="text" [(ngModel)]="changelog.name"/>
        </gmt-input>

        <gmt-input label="Datum zveřejnění" class="mt-10">
          <input type="text" pick-a-date [(ngModel)]="changelog.publishDate"/>
        </gmt-input>
      </div>

      <div class="column-100 flexbox-container">
        <label>Obsah</label>
        <div class="input flex-rest">
          <textarea type="text" rows="20" name="cadastreCodes" [(ngModel)]="changelog.description"></textarea>
        </div>
      </div>

      <div *ngIf="changelog.description" class="column-100 flexbox-container">
        <div class="label">Náhled</div>
        <div class="input flex-rest" [innerHtml]="getPreview(changelog.description)"></div>
      </div>
    </fieldset>

    <div class="actions">
      <gmt-button [clickAction]="onCancel">Zrušit</gmt-button>
      <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onUpdate">Uložit</gmt-button>
    </div>
  </div>
</div>
