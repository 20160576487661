<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset>
      <div class="column-100">
        <label>Adresát</label>
        <div class="info">
          {{ recipient.name }}
        </div>
      </div>
      <div class="column-100">
        <label class="required">Datum odmítnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="dataSend.declinedOwnerDate" [maxToday]="true"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Důvod odmítnutí</legend>
      <div class="column-100">
        <label class="required">Typ poznámky</label>
        <div class="input">
          <gmt-select
            [data]="dataSend.note"
            field="noteType"
            resource="note-types"
            [filter]="{ filters: { keys: ['ReasonNotSigned', 'ReasonNotSigned-disagreement', 'ReasonNotSigned-priceDisagreement', 'ReasonNotSigned-priceDisagreementConditional', 'ReasonNotSigned-restrictionNotRemovable', 'ReasonNotSigned-restrictionRemovable', 'ReasonNotSigned-promise', 'ReasonNotSigned-healthCondition', 'ReasonNotSigned-otherCaseTypeRequested', 'ReasonNotSigned-otherTechnicalRequirements'], dialogTypes:['BusinessCase'] }}"
          ></gmt-select>
        </div>
      </div>
      <div class="column-100">
        <label class="required">Text poznámky</label>
        <textarea [(ngModel)]="dataSend.note.text"></textarea>
      </div>
    </fieldset>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
