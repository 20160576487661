import { Ng2StateDeclaration, StateParams } from '@uirouter/angular';

import { OpinionRequestDetailComponent } from '@app/ps/opinions/components/opinion-request-detail/opinion-request-detail.component';
import { OpinionRequestCreateComponent } from '@app/ps/opinions/components/opinion-request-create/opinion-request-create.component';
import { OpinionRequestListComponent } from '@app/ps/opinions/components/opinion-request-list/opinion-request-list.component';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { PricingExpertOpinionComponent } from '@app/ps/titles/components/pricing-expert-opinion/pricing-expert-opinion.component';
import { resolveId } from '@app/common/utils/state.utils';
import { Restangular } from 'ngx-restangular';

export function resolveOpinionRequest(restangular: Restangular, stateParams: StateParams): Promise<OpinionRequestModel> {
  return restangular
    .one('opinion-requests', stateParams.id)
    .get({ loadCollections: [ 'parcels', 'buildings' ] })
    .toPromise();
}

export const opinionRequestListState: Ng2StateDeclaration = {
  name: 'symap.project.opinionRequests',
  url: '/opinion-requests?page',
  data: {
    title: 'ZP',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  views: {
    'content@symap.project': {
      component: OpinionRequestListComponent,
    },
  },
};

export const opinionRequestCreateState: Ng2StateDeclaration = {
  name: 'symap.project.opinionRequests.create',
  url: '/new',
  data: {
    title: 'Nový ZP',
  },
  views: {
    'content@symap.project': {
      component: OpinionRequestCreateComponent,
    },
  },
};

export const opinionRequestDetailState: Ng2StateDeclaration = {
  name: 'symap.project.opinionRequests.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail ZP'
  },
  params: {
    id: {
      type: 'int',
    },
    tab: {
      dynamic: true,
      squash: true,
    },
  },
  views: {
    'content@symap.project': {
      component: OpinionRequestDetailComponent,
    },
  },
  resolve: {
    opinionRequest: [ Restangular, '$stateParams', resolveOpinionRequest ],
  },
};

export function resolveTitleId(stateParams: StateParams): number {
  return stateParams.titleId;
}

export function resolveReturn(): string {
  return 'opinionRequest';
}

export const opinionRequestFillPricesFormState: Ng2StateDeclaration = {
  name: 'symap.project.opinionRequests.detail.fillPricesForm',
  url: '/fill-prices-form?titleId',
  data: {
    title: 'Zadání cen ZP'
  },
  resolve: {
    titleId: [ '$stateParams', resolveTitleId ],
    opinionRequestId: [ '$stateParams', resolveId ],
    returnDestination: [ resolveReturn ],
  },
  params: {
    titleId: {
      type: 'int',
    },
  },
  views: {
    'content@symap.project': {
      component: PricingExpertOpinionComponent,
    },
  },
};
