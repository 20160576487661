import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { saveAs } from 'file-saver';

import { RequestStatusEnum } from '@app/pk/enums/request-status.enum';
import { Price } from '@app/models/price';
import { CadastralArea } from '@app/models/cadastralArea';
import { FailedReasonEnum } from '@app/pk/enums/failed-reason.enum';
import { RequestModel } from '@app/pk/models/request.model';
import { HelpService } from '@app/common/services/help.service';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './create-project-third-step.component.html',
  styleUrls: ['./create-project-third-step.component.scss']
})
export class CreateProjectThirdStepComponent implements OnInit, OnDestroy {

  // model
  loading: boolean;
  project: RequestModel;
  status: RequestStatusEnum;
  isGeometryFail: boolean;
  isAdditionalOwnershipFail: boolean;
  message: string;
  priceConfirmation = false;
  cadastralAreas: CadastralArea[];
  price: Price;
  additionalOwnershipTitles: any[];
  helpIds = HelpService.HELP_IDS;

  readonly STATUSES: typeof RequestStatusEnum = RequestStatusEnum;

  // params
  title: string;
  subtitle: string;
  private requestId: string;

  // props
  private keepWaitForRequiredStatus = false;

  private static readonly REQUIRED_STATUS_LIST = [
    RequestStatusEnum.FAILED,
    RequestStatusEnum.FINISHED,
    RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION,
    RequestStatusEnum.PRICE_CONFIRMED,
    RequestStatusEnum.CUZK_DATA_WAITING
  ];

  private static readonly FAIL_REASON_LIST = {
    INTERNAL_ERROR: "Při zpracování došlo k interní chybě aplikace. Kontaktujte náš helpdesk.",
    AREA_TOO_LARGE: "Zadané území je větší než 15 km 2. Pokud jste si jisti správností území, kontaktujte náš helpdesk a Váš požadavek vyřešíme individuálně.",
    INCOMPLETE_DATA: "Chybí soubor se vstupní geometrii.",
    NOT_DIGITALIZED_MAP: "Katastrální mapa ve vašem území není plně digitální, požadavek nelze zpracovat.",
    INVALID_GEOMETRY: "Vstupní soubor obsahuje neplatnou geometrii.",
    INVALID_GEOMETRY_MORE_THAN_ROW: "Chybná geometrie (území tvoří více ploch).",
    INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY: "Chybná geometrie (území tvoří více ploch).",
    INVALID_GEOMETRY_HOLE_IN_GEOMETRY: "Chybná geometrie (území tvoří plocha s dírou).",
    INVALID_GEOMETRY_OR_NOT_POLYGON: "Chybná geometrie (území není plocha).",
    CTIOS_WRONG_CREDENTIALS: "Chybné přihlašovací údaje k WSDP účtu.",
    CTIOS_EXPIRED_CREDENTIALS: "Expirovalo heslo WSDP přístupu.",
    MISSING_SUBJECT: "Chybějící vlastník - přídatné spoluvlastnictví.",
    CTIOS_UNAVAILABLE: "Nedostupná služba ČUZK pro přístup k osobním údajům vlastníků.",
    PRIDATNE_SPOLUVLASTNICTVI: "Přídatné spoluvlastnictví.",
    INVALID_GEOMETRY_NARROW: "Geometrie je v některém místě užší než 2 cm.",
  };

  private static readonly MESSAGE_LIST = {
    WAITING_FOR_INTERSECT: "Pracujeme na protnutí zadané geometrie s daty SGI katastru nemovitostí.",
    WAITING_FOR_PRICE_CONFIRM: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem.",
    WAITING_FOR_CUZK_DATA: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem. Pracujeme na získání dat z katastru nemovitostí.",
    FINISHED: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem."
  };

  constructor(
    private restangular: Restangular,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
    this.delete = this.delete.bind(this);
    this.cancel = this.cancel.bind(this);
    this.confirmPrice = this.confirmPrice.bind(this);
    this.downloadTest = this.downloadTest.bind(this);
    this.downloadVfk = this.downloadVfk.bind(this);
    this.downloadExcelSheets = this.downloadExcelSheets.bind(this);
    this.downloadZip = this.downloadZip.bind(this);
    this.downloadShp = this.downloadShp.bind(this);
    this.downloadOriginal = this.downloadOriginal.bind(this);
    this.downloadExcelReport = this.downloadExcelReport.bind(this);
    this.onAdditionalOwnershipTitilesLoad = this.onAdditionalOwnershipTitilesLoad.bind(this);
  }

  ngOnInit() {
    this.requestId = this.stateService.params.requestId;
    this.title = this.stateService.params.title;
    this.subtitle = this.stateService.params.subtitle;
    this.loading = true;
    this.keepWaitForRequiredStatus = true;

    if (!this.requestId) {
      this.showErrorModal();
    } else {
      this.waitForRequiredStatus(this.requestId)
        .then(() => this.loadProject())
        .catch(this.showErrorModal);
    }
  }

  ngOnDestroy() {
    this.keepWaitForRequiredStatus = false;
  }

  confirmPrice() {
    this.loading = true;
    return this.restangular.one(`request/${this.requestId}/price-confirm`).post()
      .toPromise()
      .then(() => {
        this.loading = false;
        this.stateService.go('pk.projects');
      });
  }

  delete() {
    return this.restangular.one(`request/${this.requestId}`).remove()
      .toPromise()
      .then(() => this.stateService.go('pk.projects'));
  }

  cancel() {
    this.stateService.go('pk.projects');
  }

  downloadTest() {
    return this.download('test');
  }

  downloadVfk() {
    return this.download('vfk');
  }

  downloadExcelSheets() {
    return this.download('excel');
  }

  downloadZip() {
    return this.download('zip');
  }

  downloadShp() {
    return this.download('shp');
  }

  downloadExcelReport() {
    return this.download('excel-report');
  }

  downloadOriginal() {
    return this.restangular.one(`request/${this.requestId}/dump/geometries`)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .toPromise()
      .then((response) => {
        saveAs(response, 'geometrie.zip', true);
      });
  }

  onAdditionalOwnershipTitilesLoad() {
    return this.restangular.one(`request/${this.requestId}/spoluvlastnictvi`).get().toPromise().then(data => {
      this.additionalOwnershipTitles = data.plain();
    });
  }

  private waitForRequiredStatus(requestId) {
    const getStatusIteration = (resolve, reject) => {
      this.restangular.one(`projects/${requestId}/status`).customGET('')
        .toPromise()
        .then((data) => {
          const status = data && data['status'] ? data['status'] : null;

          if (!this.keepWaitForRequiredStatus) {
            reject();
          } else if (CreateProjectThirdStepComponent.REQUIRED_STATUS_LIST.indexOf(status) === -1) {
            this.message = CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_INTERSECT;
            setTimeout(() => getStatusIteration(resolve, reject), 1000);
          } else {
            resolve(data);
            this.keepWaitForRequiredStatus = false;
          }
        }, (data) => {
          reject(data);
        });
    };
    return new Promise(getStatusIteration);
  }

  private loadProject() {
    this.loading = true;
    this.restangular.one(`projects/${this.requestId}`).get()
      .toPromise()
      .then((data) => {
        this.project = data.plain();
        this.status = data.status;
        this.cadastralAreas = CadastralArea.createListFromApi(data.ruianKatuze);
        this.price = Price.createFromApi(data.priceCalculation);
        this.message = CreateProjectThirdStepComponent.getMessageFromStatus(this.status, data.failedReason);
        this.isGeometryFail = CreateProjectThirdStepComponent.isGeometryFail(data.failedReason);
        this.isAdditionalOwnershipFail = (data.failedReason === FailedReasonEnum.PRIDATNE_SPOLUVLASTNICTVI);
        this.loading = false;
      });
  }

  private download(type: string) {
    return this.getDownloadRequest(type).then((response: Blob) => {
      const extension = type === 'excel' ? 'xlsx' : 'zip';
      const filename = `${this.project.requestId}-${type}.${extension}`;

      saveAs(response, filename, true);
    });
  }

  private getDownloadRequest(type) {
    switch (type) {
      case 'test':
        return this.restangular.one(`request/${this.requestId}/export`, 'vfk-static').withHttpConfig({ responseType: 'blob' }).get().toPromise();
      case 'vfk':
        return this.restangular.one(`request/${this.requestId}/export`, 'vfk').withHttpConfig({ responseType: 'blob' }).get().toPromise();
      case 'excel':
        return this.restangular.one(`request/${this.requestId}/result`, 'excel-sheets').withHttpConfig({ responseType: 'blob' }).get().toPromise();
      case 'zip':
        return this.restangular.one(`request/${this.requestId}/zip`, 'create').withHttpConfig({ responseType: 'blob' }).get().toPromise();
      case 'shp':
        return this.restangular.one(`request/${this.requestId}/shp`, 'create').withHttpConfig({ responseType: 'blob' }).get().toPromise();
      case 'excel-report':
        return this.restangular.one(`request/${this.requestId}/report`, 'excel').withHttpConfig({ responseType: 'blob' }).get().toPromise();
    }
  }

  private showErrorModal() {
    const msg = `${CreateProjectThirdStepComponent.FAIL_REASON_LIST.INTERNAL_ERROR}. Budete přesměrováni na seznam projektů.`;
    return this.dialogService.alertDialogPromise(msg)
      .then(() => {
        this.cancel();
      });
  }

  private static getMessageFromStatus(status: RequestStatusEnum, failReason: FailedReasonEnum) {
    switch (status) {
      case RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION:
        return CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_PRICE_CONFIRM;
      case RequestStatusEnum.PRICE_CONFIRMED:
      case RequestStatusEnum.CUZK_DATA_WAITING:
        return CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_CUZK_DATA;
      case RequestStatusEnum.FINISHED:
        return CreateProjectThirdStepComponent.MESSAGE_LIST.FINISHED;
      case RequestStatusEnum.FAILED:
        return CreateProjectThirdStepComponent.FAIL_REASON_LIST[failReason]
            || CreateProjectThirdStepComponent.FAIL_REASON_LIST.INTERNAL_ERROR;
      default:
        return '';
    }
  }

  private static isGeometryFail(failedReason: FailedReasonEnum) {
    return [
      FailedReasonEnum.INVALID_GEOMETRY,
      FailedReasonEnum.INVALID_GEOMETRY_MORE_THAN_ROW,
      FailedReasonEnum.INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY,
      FailedReasonEnum.INVALID_GEOMETRY_HOLE_IN_GEOMETRY,
      FailedReasonEnum.INVALID_GEOMETRY_OR_NOT_POLYGON
    ].indexOf(failedReason) !== -1;
  }
}
