<h1>{{ pricingType ? 'Cena parcely' : 'Přidání parcely' }}</h1>

<!-- Add parcel -->
<ng-container *ngIf="!pricingType">
  <parcel-edit-form [parcel]="parcel" [areaSelect]="areaSelect"></parcel-edit-form>

  <div *ngIf="enablePricing" class="p-20 text-center">
    <gmt-button [gmtDisabled]="!isParcelValid()" [clickAction]="onPricingTypeSelect('E')">Vytvořit znalecký posudek</gmt-button>
    <gmt-button [gmtDisabled]="!isParcelValid()" [clickAction]="onPricingTypeSelect('O')">Vytvořit cenu podle jiného zdroje</gmt-button>
  </div>
</ng-container>

<!-- Add parcel pricing -->
<ng-container *ngIf="pricingType">
  <ng-container *ngIf="pricingType === 'E'">
    <opinion-basic-form [opinion]="opinion"></opinion-basic-form>
    <expert-select-form (changed)="onExpertSelected($event)"></expert-select-form>

    <h2>Zadání ceny ze znaleckého posudku</h2>
    <table class="bordered">
      <thead>
      <tr>
        <th>Cena za m<sup>2</sup> pozemku</th>
        <th>Cena porost<br/>(vedlejší úpravy)</th>
        <th>Cena stavby</th>
        <th>Celková cena</th>
      </tr>
      </thead>
      <tbody>
      <tr pricing-expert-opinion-row
          [parcelPrice]="parcelPrice"
          [parcel]="parcel"
          [opinion]="opinion"
          [selectable]="false"
          [selected]="false"
          [showParcel]="false"
          [hasCoefficient]="false"
      ></tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="pricingType === 'O'">
    <fieldset>
      <legend>Jiné určení ceny</legend>
      <gmt-input label="Původ ceny" [required]="true">
        <input type="text" [(ngModel)]="opinion.otherSource"/>
      </gmt-input>
    </fieldset>

    <h2>Zadání ceny podle jiného zdroje</h2>
    <table class="bordered">
      <thead>
      <tr>
        <th>Cena za pozemek</th>
        <th>Cena porost<br/>(vedlejší úpravy)</th>
        <th>Cena stavby</th>
        <th>Celková cena parcely</th>
      </tr>
      </thead>
      <tbody>
      <tr pricing-other-source-row
          [parcelPrice]="parcelPrice"
          [parcel]="parcel"
          [opinion]="opinion"
          [selectable]="false"
          [selected]="false"
          [disabled]="false"
          [showParcel]="false"
      ></tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>

<div class="text-center pt-40">
  <gmt-button *ngIf="pricingType" [clickAction]="onPricingTypeSelect(undefined)">Zpět</gmt-button>
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
