import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import {
  geometricPlanRequestTypeOptions
} from '@app/ps/enums/geometric-plan-request-type.enum';
import {
  geometricPlanRequestStatusOptions
} from '@app/ps/enums/geometric-plan-request-status.enum';
import { SelectItem } from '@app/common/components/select/select.component';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { TitleModel } from '@app/common/models/title.model';
import { ExportListService } from '@app/ps/services/export-list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { HelpService } from '@app/common/services/help.service';
import { User } from '@app/models/user';
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'geometric-plan-request-list',
  templateUrl: './geometric-plan-request-list.component.html',
  styleUrls: ['./geometric-plan-request-list.component.scss']
})
export class GeometricPlanRequestListComponent implements OnInit {
  list: ListModel<GeometricPlanRequestModel>;
  user: User = this.authService.getUser();
  pageableList: PageableList;
  isArray = Array.isArray;
  typeOptions: SelectItem[] = geometricPlanRequestTypeOptions;
  statusOptions: SelectItem[] = geometricPlanRequestStatusOptions;
  exportEnable = true;
  settingsLoaded = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    public requestService: GeometricPlanRequestService,
    private pageableListService: PageableListService,
    private exportListService: ExportListService,
    private settingsService: SettingsService,
    public authService: AuthService,
  ) {
    this.onToggleDeleted = this.onToggleDeleted.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }

    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onToggleDeleted() {
    const deleted = this.list.filter.filters.deleted;
    deleted.values = (deleted.values.length ? [] : [ false ]);
  }

  getTitles(geometricPlanRequest: GeometricPlanRequestModel): TitleModel[] {
    const titles = geometricPlanRequest.parcels
      .filter(p => p.title)
      .map(p => p.title);
    return _.uniqBy(titles, 'id');
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .exportList('geometric-plan-requests', 'geometricPlanRequests', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('geometricPlanRequests', columnId);
  }

  private getDefaultFilter() {
    return {
      filters: {
        loadCollections: [ 'areas', 'constructionObjects', 'parcels' ],
        searchText: { values: [{ id: '' }] },
        areaId: { values: [] },
        parcelId: { values: [] },
        constructionObjectId: { values: [] },
        type: { values: [] },
        status: { values: [] },
        deleted: { values: [ false ] }
      }
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get(
      'geometric-plan-requests',
      this.getDefaultFilter(),
      'geometricPlanRequestsFilter'
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
