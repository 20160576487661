<div class="list">
  <div class="clearfix">
    <div class="actions fl">
    </div>
    <div class="actions fr">
      <a uiSref=".create" [uiParams]="{ page: null }" class="button">
        <i class="fa fa-plus-square"></i>Vytvořit nového objednatele
      </a>
    </div>
  </div>

  <h1>Objednatelé</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <div class="tag" [ngClass]="{ active: showCustomerDepartments() }" (click)="onToggleCustomerDepartments()">
      <div>Zobrazit také správy/závody</div>
    </div>
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
      <div class="cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="bordered" *ngIf="list.itemCount">
      <table>
        <thead class="sticky">
          <tr>
            <!-- objednatel -->
            <th class="cell">
              <sort-by
                [selected]="'companyName' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('companyName', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Objednatel</sort-by>
            </th>
            <!-- ICO -->
            <th class="cell">
              <sort-by
                [selected]="'identificationNumber' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('identificationNumber', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >IČO</sort-by>
            </th>
            <!-- adresa -->
            <th class="cell">Adresa</th>
          </tr>
        </thead>
        <tbody>
          <tr class="row-group clickable" *ngFor="let customer of list.list" uiSref=".detail" [uiParams]="{ customerId: customer.idText, page: null }">
            <td class="cell">{{ customer.companyName }}</td>
            <td class="cell">{{ customer.identificationNumber }}</td>
            <td class="cell">{{ customer | fulladdress }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
      Nenalezena žádná data
    </div>
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  </div>

  <help [helpId]="helpIds.SETTINGS_USERS" class="cm-help-page"></help>
</div>

