<div class="list" style="overflow: hidden">
  <h1>{{ 'dashboard.header' | brandTranslate }} <span>Data byla pořízena k {{ captureDate }}</span></h1>

  <fieldset>
    <form (submit)="onFilter()">
        <div class="filter-items">
          <input type="text" autocomplete="off" placeholder="Evidenční číslo..." name="isprofondName" [(ngModel)]="filter.isprofond" />
          <input type="text" autocomplete="off" placeholder="{{ 'ps.project' | brandTranslate }}..." name="projectName" [(ngModel)]="filter.name" />
          <input type="text" autocomplete="off" placeholder="{{ 'ps.investor' | brandTranslate }}..." name="managementName" [(ngModel)]="filter.management" />
        </div>
        <gmt-button [clickAction]="onFilter" class="filter-submit">Filtrovat</gmt-button>
        <input type="submit" style="visibility: hidden; position: absolute; left: -9999px; width: 1px; height: 1px;" tabindex="-1" />
    </form>
  </fieldset>

  <div [loading]="loading" class="pt-20 mb-40">
    <div class="bordered" *ngIf="projects?.length">
      <table class="table">
        <thead>
          <tr>
            <th class="cell" width="9%">
              <sort-by [selected]="'isprofond' === sortOrder.sortBy" (sort)="onSort('isprofond')" [sortDir]="sortOrder.direction">
                Evidenční číslo
              </sort-by>
            </th>
            <th class="cell" width="15%">
              <sort-by [selected]="'name' === sortOrder.sortBy" (sort)="onSort('name')" [sortDir]="sortOrder.direction">
                {{ 'ps.project' | brandTranslate }}
              </sort-by>
            </th>
            <th class="cell" width="12%">
              <sort-by [selected]="'management' === sortOrder.sortBy" (sort)="onSort('management')" [sortDir]="sortOrder.direction">
                {{ 'ps.investor' | brandTranslate }}
              </sort-by>
            </th>
            <th class="cell" width="12%">Trvalý zábor</th>
            <th class="cell" width="12%">Dočasný zábor nad 1 rok</th>
            <th class="cell" width="12%">Dočasný zábor do 1 roku</th>
            <th class="cell" width="12%">Věcná břemena</th>
            <th class="cell" width="8%">Vývoj 1 měsíc</th>
            <th class="cell" width="8%"><div class="text-center" style="width:130px">Celkový stav vypořádání</div></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let project of projects">
            <td class="cell">
              <i class="small-text fa fa-circle pr-5"
                 [ngClass]="{'up-color': project.activity, 'down-color': !project.activity }"
                 [tooltip]="project.activity ? 'Za posledních 30 dní došlo ke změně stavu.' : 'Za posledních 30 dní nedošlo ke změně stavu.'"
              ></i>
              {{ project.isprofond }}
            </td>
            <td class="cell">
              <a *ngIf="getUserProject(project) as userProject; else noLinkTitle" uiSref="symap.project.home" [uiParams]="{ projectKey: userProject.key }">{{ project.name }}</a>
              <ng-template #noLinkTitle>{{ project.name }}</ng-template>
            </td>
            <td class="cell">
              {{ project.management }}
            </td>
            <td class="cell no-padding cell-max-height" [tooltip]="tooltipComponent" [data]="project.permanentTrend" position="center">
              <div class="fill-content flexbox-container flex-vertical-centered pl-10 pr-10">
                <gmt-progress-bar [total]="project.permanentTrend.total" [done]="project.permanentTrend.done"></gmt-progress-bar>
                <gmt-trend [trendData]="project.permanentTrend" withoutPercentage="true" class="ml-10"></gmt-trend>
              </div>
            </td>
            <td class="cell no-padding cell-max-height" [tooltip]="tooltipComponent" [data]="project.temporaryOverYearTrend" position="center">
              <div class="fill-content flexbox-container flex-vertical-centered pl-10 pr-10">
                <gmt-progress-bar [total]="project.temporaryOverYearTrend.total" [done]="project.temporaryOverYearTrend.done"></gmt-progress-bar>
                <gmt-trend [trendData]="project.temporaryOverYearTrend" withoutPercentage="true" class="ml-10"></gmt-trend>
              </div>
            </td>
            <td class="cell no-padding cell-max-height" [tooltip]="tooltipComponent" [data]="project.temporaryUnderYearTrend" position="center">
              <div class="fill-content flexbox-container flex-vertical-centered pl-10 pr-10">
                <gmt-progress-bar [total]="project.temporaryUnderYearTrend.total" [done]="project.temporaryUnderYearTrend.done"></gmt-progress-bar>
                <gmt-trend [trendData]="project.temporaryUnderYearTrend" withoutPercentage="true" class="ml-10"></gmt-trend>
              </div>
            </td>
            <td class="cell no-padding cell-max-height" [tooltip]="tooltipComponent" [data]="project.easementTrend" position="center">
              <div class="fill-content flexbox-container flex-vertical-centered pl-10 pr-10">
                <gmt-progress-bar [total]="project.easementTrend.total" [done]="project.easementTrend.done"></gmt-progress-bar>
                <gmt-trend [trendData]="project.easementTrend" withoutPercentage="true" class="ml-10"></gmt-trend>
              </div>
            </td>
            <td class="cell no-padding cell-max-height" [tooltip]="tooltipComponent" [data]="project.totalTrend" position="center">
              <div class="fill-content flexbox-container flex-centered pl-10 pr-10">
                <gmt-trend [trendData]="project.totalTrend"></gmt-trend>
              </div>
            </td>
            <td class="cell" tooltip="{{ project.key ? 'Dle počtu případů. Případ = složka dokumentů smluvního případu (výkupy, nájmy, atd...)' : 'Dle počtu parcel (není evidence po případech)' }}" position="center">
              <div class="text-center">
                {{ getDoneRate(project.totalTrend.done, project.totalTrend.total) | number:'1.0-0' }}%
                (z {{ project.totalTrend.total }} {{ project.key ? 'případů' : 'parcel' }})
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="projects && !projects.length">
      <span>Nenalezena žádná data</span>
    </div>
  </div>
  <help [helpId]="helpIds.DASHBOARD_PROJECTS" class="cm-help-page"></help>
</div>
