import { Component, Inject, Input, OnInit } from '@angular/core';

import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { approveOptions } from '@app/ps/enums/approve.enum';
import {
  GeometricPlanRequestTypeEnum,
  geometricPlanRequestTypeOptions
} from '@app/ps/enums/geometric-plan-request-type.enum';
import { SelectItem } from '@app/common/components/select/select.component';
import { StateService } from '@uirouter/angular';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { NoteTypeModel } from '@app/common/models/note-type.model';
import { NoteTypeKeyEnum } from '@app/ps/enums/note-type-key.enum';
import { AreaModel } from '@app/common/models/area.model';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { TitleModel } from '@app/common/models/title.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'geometric-plan-request-create',
  templateUrl: './geometric-plan-request-create.component.html',
  styleUrls: ['./geometric-plan-request-create.component.scss']
})
export class GeometricPlanRequestCreateComponent implements OnInit {

  @Input() noteTypes: NoteTypeModel[];
  geometricPlanRequest = GeometricPlanRequestModel.create();
  typeOptions: SelectItem[] = geometricPlanRequestTypeOptions;
  approveOptions: SwitchOption[] = approveOptions;
  area: AreaModel;
  titles: TitleModel[];
  similarGeometricPlanRequestExists = false;
  type: SelectItem = this.typeOptions[0];
  typeEnum = GeometricPlanRequestTypeEnum;
  parcelSplitPreApproveByBuildingAuthority = this.approveOptions[0];
  parcelSplitPreApproveByBuildingAuthorityNote: string;
  parcelSplitPreApproveByOwner = this.approveOptions[0];
  parcelSplitPreApproveByOwnerNote: string;

  titleFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  parcelFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
    titleId: { values: [] },
  };

  constructionObjectFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  constructor(
    public requestService: GeometricPlanRequestService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    private stateService: StateService,
    public titleNamePipe: TitleNamePipe,
    private restangular: Restangular,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
  }

  isValid(): boolean {
    return !!(
      this.type
      && this.geometricPlanRequest.name
      && this.area
      && this.geometricPlanRequest.parcels
      && this.geometricPlanRequest.parcels.length
    );
  }

  onCadastreChanged() {
    this.titleFilter.areaId.values = [this.area.id];
    this.parcelFilter.areaId.values = [this.area.id];
    this.constructionObjectFilter.areaId.values = [this.area.id];

    this.titles = undefined;
    this.geometricPlanRequest.parcels = undefined;
  }

  onTitleChanged() {
    this.parcelFilter.titleId.values = this.titles;
    this.geometricPlanRequest.parcels = undefined;
  }

  onParcelsChanged() {
    this.checkExistingSimilarOpinionRequest();
  }

  onSubmit(): Promise<any> {
    this.geometricPlanRequest.type = this.type.id as string;
    this.geometricPlanRequest.areas = this.area ? [this.area] : [];
    this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority = this.parcelSplitPreApproveByBuildingAuthority.id as string;
    this.geometricPlanRequest.parcelSplitPreApproveByOwner = this.parcelSplitPreApproveByOwner.id as string;

    const notes = [];

    if (this.geometricPlanRequest.type === GeometricPlanRequestTypeEnum.PARCEL_SPLIT && this.parcelSplitPreApproveByBuildingAuthorityNote) {
      notes.push({
        text: this.parcelSplitPreApproveByBuildingAuthorityNote,
        noteType: this.noteTypes.find(nt => nt.key === NoteTypeKeyEnum.ParcelSplitPreApproveByBuildingAuthority)
      });
    }

    if (this.geometricPlanRequest.type === GeometricPlanRequestTypeEnum.PARCEL_SPLIT && this.parcelSplitPreApproveByOwnerNote) {
      notes.push({
        text: this.parcelSplitPreApproveByOwnerNote,
        noteType: this.noteTypes.find(nt => nt.key === NoteTypeKeyEnum.ParcelSplitPreApproveByOwner)
      });
    }

    return this.requestService
      .create(this.geometricPlanRequest, notes)
      .then(geometricPlanRequest => {
        this.stateService.go(
          'symap.project.geometricPlanRequests.detail',
          { id: geometricPlanRequest.id, tab: 'parcels-budouc' });
      });
  }

  private checkExistingSimilarOpinionRequest() {
    if (!this.geometricPlanRequest.parcels || !this.geometricPlanRequest.parcels.length) {
      this.similarGeometricPlanRequestExists = false;
      return;
    }

    const parcelIds = this.geometricPlanRequest.parcels.map(p => p.id);
    const filter = { filter: { filters: { parcelId: parcelIds }}};

    this.restangular
      .all('geometric-plan-requests').customPOST(filter)
      .toPromise()
      .then(data => {
        const requests = data.plain().filter(or => or.status !== OpinionRequestStatusEnum.DELETED);
        this.similarGeometricPlanRequestExists = requests.length > 0;
      });
  }
}
