import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'opinion-basic-form',
  templateUrl: './opinion-basic-form.component.html',
  styleUrls: ['opinion-basic-form.component.scss']
})
export class OpinionBasicFormComponent implements OnInit {

  @Input() opinion: any;

  ngOnInit() {
  }
}
