import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  date = new Date();
  declinedInvestorName: string;
  approvedInvestorName: string;

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private caseService: CaseService,
    private stateService: StateService,
  ) {
    this.onApprove = this.onApprove.bind(this);
    this.onDecline = this.onDecline.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({ loadCollections: [ 'caseSubjects', 'titles' ]}).toPromise();
    this.declinedInvestorName = (await this.caseStatusService.loadStatusName('DeclinedInvestor')).name;
    this.approvedInvestorName = (await this.caseStatusService.loadStatusName('ApprovedInvestor')).name;
    this.loading = false;
  }

  isValid() {
    return !!this.date;
  }

  async onDecline(): Promise<any> {
    await this.restangular
      .one('cases', this.caseId)
      .customPUT({ declinedInvestorDate: this.date }, 'decline-investor')
      .toPromise();
    this.stateService.go('^');
  }

  async onApprove(): Promise<any> {
    await this.restangular
      .one('cases', this.caseId)
      .customPUT({ approvedInvestorDate: this.date }, 'approve-investor')
      .toPromise();
    this.stateService.go('^');
  }
}
