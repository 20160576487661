import { OpinionModel } from '@app/ps/models/opinion.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { BuildingModel } from '@app/common/models/building.model';

export enum ParcelPriceCoefficient {
  COEFFICIENT_8 = 8,
  COEFFICIENT_1_5 = 1.5,
  COEFFICIENT_1 = 1,
}

export interface ExpertOpinionCoefficient {
  type: 'land' | 'compensation' | 'building' | 'vegetation';
  coefficient: number;
  multiply: boolean;
  area?: number;
  price?: number;
  squarePrice?: number;
}

export class ExpertOpinionCoefficientsFlatten {
  land15Multiply = false;
  land15Area = 0;
  land15Price = 0;
  land15SquarePrice = 0;

  land8Multiply = false;
  land8Area = 0;
  land8Price = 0;
  land8SquarePrice = 0;

  land1Multiply = false;
  land1Area = 0;
  land1Price = 0;
  land1SquarePrice = 0;

  compensationMultiply = false;
  compensationCoefficient = 1;

  vegetationMultiply = false;
  vegetationCoefficient = 1;

  buildingsMultiply = false;
  buildingsCoefficient = 1;

  public static fromCoefficients(coefficients: ExpertOpinionCoefficient[]): ExpertOpinionCoefficientsFlatten {
    const coefficientsFlatten = new ExpertOpinionCoefficientsFlatten();

    if (!coefficients || coefficients.length === 0) {
      return coefficientsFlatten;
    }

    for (const coefficient of coefficients) {
      if (coefficient.type === 'land') {
        if (coefficient.coefficient === ParcelPriceCoefficient.COEFFICIENT_8) {
          coefficientsFlatten.land8Multiply = coefficient.multiply;
          coefficientsFlatten.land8Area = coefficient.area;
          coefficientsFlatten.land8Price = coefficient.price;
          coefficientsFlatten.land8SquarePrice = coefficient.squarePrice;
        } else if (coefficient.coefficient === ParcelPriceCoefficient.COEFFICIENT_1) {
          coefficientsFlatten.land1Multiply = coefficient.multiply;
          coefficientsFlatten.land1Area = coefficient.area;
          coefficientsFlatten.land1Price = coefficient.price;
          coefficientsFlatten.land1SquarePrice = coefficient.squarePrice;
        } else if (coefficient.coefficient === ParcelPriceCoefficient.COEFFICIENT_1_5) {
          coefficientsFlatten.land15Multiply = coefficient.multiply;
          coefficientsFlatten.land15Area = coefficient.area;
          coefficientsFlatten.land15Price = coefficient.price;
          coefficientsFlatten.land15SquarePrice = coefficient.squarePrice;
        }

      } else {
        coefficientsFlatten[coefficient.type + 'Multiply'] = coefficient.multiply;
        coefficientsFlatten[coefficient.type + 'Coefficient'] = coefficient.coefficient;
      }
    }

    return coefficientsFlatten;
  }

  public static toCoefficients(coefficientsFlatten: ExpertOpinionCoefficientsFlatten): ExpertOpinionCoefficient[] {
    const coefficients = [];
    if (coefficientsFlatten.land8Multiply) {
      coefficients.push({ type: 'land', coefficient: ParcelPriceCoefficient.COEFFICIENT_8, multiply: coefficientsFlatten.land8Multiply, area: coefficientsFlatten.land8Area, price: coefficientsFlatten.land8Price, squarePrice: coefficientsFlatten.land8SquarePrice });
    }
    if (coefficientsFlatten.land15Multiply) {
      coefficients.push({ type: 'land', coefficient: ParcelPriceCoefficient.COEFFICIENT_1_5, multiply: coefficientsFlatten.land15Multiply, area: coefficientsFlatten.land15Area, price: coefficientsFlatten.land15Price, squarePrice: coefficientsFlatten.land15SquarePrice });
    }
    if (coefficientsFlatten.land1Multiply) {
      coefficients.push({ type: 'land', coefficient: ParcelPriceCoefficient.COEFFICIENT_1, multiply: coefficientsFlatten.land1Multiply, area: coefficientsFlatten.land1Area, price: coefficientsFlatten.land1Price, squarePrice: coefficientsFlatten.land1SquarePrice });
    }
    if (coefficientsFlatten.compensationMultiply) {
      coefficients.push({ type: 'compensation', coefficient: coefficientsFlatten.compensationCoefficient, multiply: coefficientsFlatten.compensationMultiply, area: undefined, price: undefined, squarePrice: undefined });
    }
    if (coefficientsFlatten.vegetationMultiply) {
      coefficients.push({ type: 'vegetation', coefficient: coefficientsFlatten.vegetationCoefficient, multiply: coefficientsFlatten.vegetationMultiply, area: undefined, price: undefined, squarePrice: undefined });
    }
    if (coefficientsFlatten.buildingsMultiply) {
      coefficients.push({ type: 'buildings', coefficient: coefficientsFlatten.buildingsCoefficient, multiply: coefficientsFlatten.buildingsMultiply, area: undefined, price: undefined, squarePrice: undefined });
    }
    return coefficients;
  }
}

export class ParcelPriceModel {
  id: number;
  idpar: number;
  budId: number;

  preliminaryOpinionCoefficient: number;
  preliminaryOpinionLandPrice: number;

  // Pozemek
  expertOpinionLandPrice: number;
  expertOpinionLandPriceDefault: number;

  expertOpinionLandPriceCoefficient: number;
  expertOpinionLandSquarePrice: number;
  expertOpinionLandAreaPrice: number; // pomocny

  // Stavby
  expertOpinionBuildingsPrice: number;
  expertOpinionBuildingsPriceDefault: number;

  // Porosty
  expertOpinionVegetationPrice: number;
  expertOpinionVegetationPriceDefault: number;

  // Kompenzace
  expertOpinionCompensationPrice: number;
  expertOpinionCompensationPriceDefault: number;

  // Pomocne
  expertOpinionParcelPriceDefault: number;
  expertOpinionParcelPriceCoefficient: number;
  expertOpinionParcelPriceDefaultRounded: number;

  expertOpinionCoefficients: ExpertOpinionCoefficient[];
  expertOpinionCoefficientsFlatten: ExpertOpinionCoefficientsFlatten;

  // Jine urceni ceny
  otherBuildingsPrice: number;
  otherLandPrice: number;
  otherCompensationPrice: number;
  otherVegetationPrice: number;

  opinion?: OpinionModel;
  parcel?: ParcelModel;
  building?: BuildingModel;

  isCoefficientSet: boolean; // TODO unused

  static createDefaultExpertOpinionPermanent(): ParcelPriceModel {
    const parcelPrice = new ParcelPriceModel();
    parcelPrice.expertOpinionVegetationPriceDefault = 0;
    parcelPrice.expertOpinionCompensationPriceDefault = 0;
    parcelPrice.expertOpinionBuildingsPriceDefault = 0;
    parcelPrice.expertOpinionLandSquarePrice = 0;
    parcelPrice.expertOpinionParcelPriceDefault = 0;
    parcelPrice.expertOpinionCoefficients = ExpertOpinionCoefficientsFlatten.toCoefficients(new ExpertOpinionCoefficientsFlatten());
    parcelPrice.expertOpinionCoefficientsFlatten = new ExpertOpinionCoefficientsFlatten();
    return parcelPrice;
  }

  static createDefaultOtherSourcePermanent(): ParcelPriceModel {
    const parcelPrice = new ParcelPriceModel();
    parcelPrice.otherLandPrice = 0;
    parcelPrice.otherVegetationPrice = 0;
    parcelPrice.otherBuildingsPrice = 0;
    return parcelPrice;
  }
}

