﻿<div *ngIf="!loading">
  <fieldset *ngIf="data.entryProposalLink">
    <legend>Podání s využitím webové aplikace</legend>
    <div class="column-100">
      <label>Odkaz do aplikace Návrh na vklad</label>
      <div class="info"><a [attr.href]="data.entryProposalLink" target="_blank">{{data.entryProposalLink}}</a></div>
    </div>
    <div class="column-100">
      <label>Uživatelské jméno</label>
      <div class="info">{{data.entryProposalUser}}</div>
    </div>
    <div class="column-100">
      <label>Id návrhu na vklad</label>
      <div class="info">{{data.entryProposalId}}</div>
    </div>
  </fieldset>

  <fieldset *ngIf="!data.entryProposalLink">
    <legend>Dálkový přístup do ISKN</legend>
    <div class="column-100">
      <label class="required">Uživatel</label>
      <div class="input">
        <input [(ngModel)]="sendDataProposal.entryProposalUser" type="text"/>
      </div>
      <label class="required">Heslo</label>
      <div class="input">
        <input [(ngModel)]="sendDataProposal.entryProposalPassword" type="password"/>
      </div>
    </div>
    <div *ngIf="cadastreAgent" class="column-100">
      <label (click)="toggleUseCadastreAgent()">Navrhovatel {{investor|projectSubjectName}} zastoupen osobou
        {{cadastreAgent|projectSubjectName}}</label>
      <div class="info">
        <gmt-checkbox (click)="toggleUseCadastreAgent()"
                      [gmtChecked]="sendDataProposal.useCadastreAgent"></gmt-checkbox>
      </div>
    </div>
    <div *ngIf="actingPersons && actingPersons.length" class="column-100">
      <label>Jednající osoba za {{(sendDataProposal.useCadastreAgent ? cadastreAgent :
        investor)|projectSubjectName}}</label>
      <div class="input">
        <gmt-select (changed)="changedActingPerson()" [data]="sendDataProposal" [itemPipe]="actingPersonNamePipe"
                    [optionItems]="actingPersons" [reloadOptionsRegister]="registerActingPersonReload"
                    field="cadastreActingPerson"></gmt-select>
      </div>
    </div>
    <div class="column-100">
      <label>Kontaktní e-mail</label>
      <div class="input">
        <input [(ngModel)]="sendDataProposal.email" type="text"/>
      </div>
    </div>
    <div class="column-100">
      <label>Kontaktní telefon</label>
      <div class="input">
        <input [(ngModel)]="sendDataProposal.phoneNumber" type="text"/>
      </div>
    </div>
    <div *ngIf="accountNumber" class="column-100">
      <label>Číslo účtu a kód banky pro inkasní platbu</label>
      <div class="info">
        {{accountNumber}}
      </div>
    </div>
    <div class="column-100 flexbox-container">
      <label>Poznámka návrhu na vklad</label>
      <div class="input flex-rest">
        <gmt-select (changed)="cadastreNoteSelected($event.newValue, $event.data)"
                    *ngIf="cadastreNotes && cadastreNotes.length" [optionItems]="cadastreNotes"
                    [reloadOptionsRegister]="registerCadastreNotesReload"
                    selectTitle="Šablona poznámky"></gmt-select>
        <textarea [(ngModel)]="sendDataProposal.note" class="mt-5"></textarea>
      </div>
    </div>
    <div class="column-100 flexbox-container" *ngIf="data.obligation.type == 'RealBurdenLiability' || data.obligation.type == 'RealBurdenLiabilityAccelerated'">
      <label>Popis věcného břemene</label>
      <div class="input flex-rest">
        <textarea [(ngModel)]="sendDataProposal.easementDescription" class="mt-5"></textarea>
      </div>
    </div>
  </fieldset>
</div>
