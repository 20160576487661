// @TODO - remove and replace with ../common/models/area.model
export class CadastralArea {
  name: string;
  code: number;

  public static createFromApi(data: any): CadastralArea {
    const cadastralArea = new CadastralArea();
    cadastralArea.code = data.kod;
    cadastralArea.name = data.nazev;
    return cadastralArea;
  }

  public static createListFromApi(data: any[]): CadastralArea[] {
    return data.map(CadastralArea.createFromApi);
  }
}
