import { Component, Inject, Input, OnInit } from '@angular/core';
import { ExpertModel } from '@app/ps/models/expert.model';
import { DialogService } from '@app/common/services/dialog.service';
import { ExpertEditFormComponent } from '@app/ps/components/expert-edit-form/expert-edit-form.component';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expert-detail',
  templateUrl: './expert-detail.component.html',
})
export class ExpertDetailComponent implements OnInit {

  @Input() expertId: number;
  loading = true;
  expert: ExpertModel;

  constructor(
    private restangular: Restangular,
    private dialogService: DialogService,
  ) {
    this.onEdit = this.onEdit.bind(this);
  }

  ngOnInit() {
    this.restangular
      .one('experts', this.expertId)
      .get()
      .toPromise()
      .then(data => {
        this.expert = data.plain();
        this.loading = false;
      });
  }

  onEdit() {
    const dialog = this.dialogService.open(ExpertEditFormComponent, {
      data: { expert: this.expert }
    });

    const sub = dialog.afterClosed.subscribe((res: ExpertModel) => {
      if (res) {
        Object.assign(this.expert, res);
      }
      sub.unsubscribe();
    });
  }
}
