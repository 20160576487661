import {Component, Inject, OnInit} from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { NoteModel } from '@app/common/models/note.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'note-form',
  templateUrl: './note-form.component.html',
})
export class NoteFormComponent implements OnInit {

  // model
  title: string;
  allowType: boolean;
  note: NoteModel;
  caseId: number;
  subjects: any[];
  ownershipsList: any;

  constructor(
      private restangular: Restangular,
      private dialogConfig: DialogConfig,
      public dialogComponent: DialogRef,
      private listService: ListService,
      public subjectNamePipe: SubjectNamePipe,
  ) {
    this.onCreate = this.onCreate.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.note = (<DialogConfigData>this.dialogConfig.data).note;
    this.caseId = (<DialogConfigData>this.dialogConfig.data).caseId || (this.note.businessCase ? this.note.businessCase.id : undefined);
    this.allowType = (<DialogConfigData>this.dialogConfig.data).allowType && this.caseId !== undefined;
    this.title = (this.note.id ? "Editace poznámky" : "Nová poznámka");
    this.onTypeChange({
      newValue: this.note.noteType,
    });
  }

  onTypeChange(data: any) {
    if (data.newValue && data.newValue.key === 'PriceRequest') {
      if (this.caseId) {
        if (!this.ownershipsList) {
          this.ownershipsList = this.listService.createList('ownerships',
          {
            limit:null,
            filters: {
              caseId: this.caseId,
            },
          },
          undefined,
          {
            subject: {
              load: true,
            },
          });
          this.listService.fetchResult(this.ownershipsList).then(data => {
            this.subjects = data.list.map(ownership => ownership.subject);
          });
        }
        this.ownershipsList.promise.then(() => {
          if (this.subjects.length === 1) {
            this.note.subject = this.subjects[0];
          }
        });
      }
    } else if (this.caseId) {
      delete this.note.subject;
    }
  }

  onCreate() {
    return this.restangular.all('notes/create').post(this.note).toPromise().then((data) => {
      this.dialogComponent.close(data.plain());
    });
  }

  onUpdate() {
    return this.restangular.one("notes", this.note.id).customPUT(this.note).toPromise().then((data) => {
      this.dialogComponent.close(data.plain());
    });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }
}
