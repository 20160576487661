<h1>{{ article.id ? 'Editace aktuality' : 'Nová aktualita' }}</h1>

<div>
  <fieldset>
    <div class="column-50">
      <gmt-input label="Titulek" class="mt-10" [required]="true">
        <input type="text" [(ngModel)]="article.title"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Datum zveřejnění" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="article.publishDate"/>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset>
    <span (click)="onFileUpload()" class="file-upload-button"><i class="fa fa-upload"></i></span>
    <div id="editor"></div>
  </fieldset>

  <div class="actions">
    <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSave">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</div>
