<h1>{{ create ? 'Založení uživatele' : 'Editace uživatele' }}</h1>
<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Informace o uživateli</legend>
      <div class="column-50">
        <gmt-input label="Titul před jménem">
          <input type="text" [(ngModel)]="user.titleBefore" ng-trim="false"/>
        </gmt-input>
        <gmt-input label="Příjmení" required="true">
          <input type="text" [(ngModel)]="user.surname" ng-trim="false"/>
        </gmt-input>
        <gmt-input label="E-mail" required="true">
          <input type="text" [(ngModel)]="user.email" ng-trim="false"/>
        </gmt-input>
        <gmt-input label="Telefon">
          <input type="text" [(ngModel)]="user.phone" ng-trim="false"/>
        </gmt-input>
      </div>

      <div class="column-50">
        <gmt-input label="Křestní jméno" required="true">
          <input type="text" [(ngModel)]="user.name" ng-trim="false"/>
        </gmt-input>
        <gmt-input label="Titul za jménem">
          <input type="text" [(ngModel)]="user.degreeAfter" ng-trim="false"/>
        </gmt-input>
        <gmt-input label="Firma" required="true">
          <gmt-select
            resource="v2/customers"
            [data]="user"
            field="company"
            [filter]="{ sortOrder: { sortBy: 'companyName' }}"
            [restangularService]="globalRestangular"
            [itemPipe]="customerNamePipe"
          ></gmt-select>
        </gmt-input>
      </div>
    </fieldset>

    <fieldset class="flex1" *ngIf="create">
      <legend>Heslo</legend>
      <div class="column-100">
        <gmt-input label="Heslo">
          <input type="password" [(ngModel)]="user.password"/>
        </gmt-input>
      </div>
      <div class="column-100">
        <label>Potvrzení hesla</label>
        <div class="input" [attr.disabled]="!user.password">
          <input type="password" [(ngModel)]="user.passwordCheck"/>
        </div>
      </div>
    </fieldset>

    <fieldset class="flex1 mr-10" *ngIf="!create && APP_BRAND.NAME !== 'RSD' && APP_BRAND.NAME !== 'SZ'">
      <legend>Změna hesla</legend>
      <div class="column-100">
        <gmt-input label="Nové heslo">
          <input type="password" [(ngModel)]="user.password"/>
        </gmt-input>
      </div>
      <div class="column-100">
        <label>Potvrzení nového hesla</label>
        <div class="input" [attr.disabled]="!user.password">
          <input type="password" [(ngModel)]="user.passwordCheck"/>
        </div>
      </div>
    </fieldset>

    <fieldset class="flex1" *ngIf="create">
      <legend>Uživatelské jméno</legend>
      <div class="column-100">
        <gmt-input label="Uživatelské jméno">
          <input type="text" [(ngModel)]="user.username"/>
        </gmt-input>
      </div>
    </fieldset>

    <fieldset class="flex1" *ngIf="!create && APP_BRAND.NAME !== 'RSD' && APP_BRAND.NAME !== 'SZ'">
      <legend>Změna uživatelského jména</legend>
      <div class="column-100">
        <label>Stávající uživatelské jméno</label>
        <div class="info">
          {{username}}
        </div>
      </div>
      <div class="column-100">
        <gmt-input label="Nové uživatelské jméno">
          <input type="text" [(ngModel)]="user.username"/>
        </gmt-input>
      </div>
    </fieldset>

    <fieldset *ngIf="editor.superuser">
      <legend>Oprávnění</legend>
      <div class="column-100">
        <label (click)="user.superuser = !user.superuser">Developer</label>
        <div class="info" (click)="user.superuser = !user.superuser">
          <gmt-checkbox [gmtChecked]="user.superuser"></gmt-checkbox>
        </div>
      </div>
    </fieldset>

    <div class="actions">
      <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSave">Uložit</gmt-button>
    </div>
  </div>
</div>
