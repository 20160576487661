import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import * as L from 'leaflet';

const getVertexIcon = () => new L.DivIcon({
  iconSize: new L.Point(8,8),
  className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
});


// Localize strings in L.drawLocal, used for version 0.2.4-dev. Hopefully it will change in future versions,
// that's why we keep it there and don't put it in cm-angular-map component
const setDrawLocalization = () => {
  // Localize strings in L.drawLocal, used for version 0.2.4-dev. Hopefully it will change in future versions,
  // that's why we keep it there and don't put it in cm-angular-map component
  L.drawLocal.draw.toolbar.buttons.polyline = "Měření vzdálenosti";
  L.drawLocal.draw.toolbar.buttons.polygon = "Měření plochy";

  L.drawLocal.draw.toolbar.actions.title = "Zrušit měření";
  L.drawLocal.draw.toolbar.actions.text = "Zrušit";

  L.drawLocal.draw.toolbar.finish.title = "Ukončit měření";
  L.drawLocal.draw.toolbar.finish.text = "Ukončit";

  L.drawLocal.draw.toolbar.undo.title = "Smazat poslední označený bod";
  L.drawLocal.draw.toolbar.undo.text = "Smazat poslední bod";

  setDrawMeasurePolylineLocalization();

  L.drawLocal.draw.handlers.polygon.tooltip.start = "Klikněte a začněte vyznačovat plochu.";
  L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klikněte pro pokračování.";
  L.drawLocal.draw.handlers.polygon.tooltip.end = "Kliknutím na první bod ukončete měření.";
};

const setDrawMeasurePolylineLocalization = () => {
  L.drawLocal.draw.handlers.polyline.error = '<strong>Chyba:</strong> okraje oblasti se nemohou křížit.';
  L.drawLocal.draw.handlers.polyline.tooltip.start = "Klikněte a začněte měřit.";
  L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
  L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete měření.";
};

@Component({
  selector: 'map-module-measure',
  templateUrl: './map-module-measure.component.html',
})
export class MapModuleMeasureComponent implements OnInit, OnDestroy {
  @Input() mapId: string;
  measureOptions = [
    {id: 'polyline', value: 'Linie'},
    {id: 'polygon', value: 'Plochy'}
  ];

  measureOptionsSelections = 'polyline';
  draw = null;

  constructor(
    private mapService: MapService,
  ) {
    this.onDrawCreated = this.onDrawCreated.bind(this);
  }

  ngOnInit() {
    this.draw = new L.Control.Draw({
      // position: 'topleft',
      draw: {
          polyline: {
              showLength: true,
              metric: true,
              feet: false,
              icon: getVertexIcon(),
              precision: {
                m: 1,
              },
          },
          polygon: {
              allowIntersection: false,
              showArea: true,
              icon: getVertexIcon(),
          },
          rectangle: false,
          circle: false,
          circlemarker: false,
          marker: false
      }
    });

    this.mapService.getMap(this.mapId).then((map) => {
      L.DomEvent.on(map._container, 'keyup', this._cancelDrawing, this);
      map.addControl(this.draw);
      //remove toolbar from map
      this.draw._container.parentNode.removeChild(this.draw._container);

      setDrawLocalization();
    });

    this.onChangedMeasureType('polyline');
  }

  ngOnDestroy() {
    this.mapService.getMap(this.mapId).then((map) => {
      map.removeControl(this.draw);
      map.off(L.Draw.Event.CREATED, this.onDrawCreated);
      L.DomEvent.off(map._container, 'keyup', this._cancelDrawing, this);
    })
  }

  onDrawCreated() {
    this.onChangedMeasureType(this.measureOptionsSelections);
  }

  onChangedMeasureType(newVal) {
    //stop previous measure
    //detect if some measure tool is active
    if(this.draw._toolbars && this.draw._toolbars.draw && (this.draw._toolbars.draw._activeMode)) {
      this.draw._toolbars.draw._activeMode.handler.disable();
    }
    //start new measure
    this.mapService.getMap(this.mapId).then((map) => {
      this.draw._toolbars.draw._modes[newVal].handler.enable();
      map.once(L.Draw.Event.CREATED, this.onDrawCreated);
    })
  }

  _cancelDrawing(e) {
    if (e.keyCode === 27) {
      this.onChangedMeasureType(this.measureOptionsSelections);
    }
  }
}
