<div [loading]="ownershipList.loading">
  <div *ngIf="!ownershipList.loading">
    <div *ngIf="ownershipList.itemCount; else noTitles;">
      <div class="multiple-titles" *ngIf="groupedTitleList.length > 1 || groupedTitleList[0].length > 1; else singleTitle">
        <table>
          <thead>
            <tr>
              <th>Katastrální území</th>
              <th>Listy vlastnictví</th>
              <th *ngIf="options.showOwnerships">Podíl</th>
            </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let titleGroup of groupedTitleList">
            <tr *ngFor="let title of titleGroup">
              <td>{{title.area.name}}</td>
              <td>
                <a uiSref="symap.project.titles.detail" [uiParams]="{id: title.id, tab: null}">
                  {{title.lv}}
                </a>
                <span *ngIf="title.duplicityOwnership" title="Duplicitní zápis vlastnictví" class="tag tag--danger ml-10 tag--small is-uppercase">Duplicitní zápis vlastnictví</span>
              </td>
              <td *ngIf="options.showOwnerships">
                <ng-container *ngFor="let ownership of title.ownerships; last as isLast">
                  {{ownership.podilCitatel + '/' + ownership.podilJmenovatel}}
                  <ng-container *ngIf="ownership.isBsm"> (<a *ngIf="ownership.subject.id !== ownerId; else sjmNoLink" uiSref="symap.project.owners.detail" [uiParams]="{ownerId: ownership.subject.id, tab: 'about'}">SJM</a>)</ng-container>{{isLast ? "" : ", "}}
                </ng-container>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <ng-template #singleTitle>
        <div class="column-100">
          <label>Katastrální území</label>
          <div class="info">
            {{groupedTitleList[0][0].area.name}}
          </div>
          <label>List vlastnictví</label>
          <div class="info">
            <a uiSref="symap.project.titles.detail" [uiParams]="{id: groupedTitleList[0][0].id, tab: null}">
              {{groupedTitleList[0][0].lv}}
            </a>
            <span *ngIf="groupedTitleList[0][0].duplicityOwnership" title="Duplicitní zápis vlastnictví" class="tag tag--danger ml-10 tag--small is-uppercase">Duplicitní zápis vlastnictví</span>
          </div>
          <ng-container *ngIf="options.showOwnerships">
            <label>Podíl</label>
            <div class="info">
              <ng-container *ngFor="let ownership of groupedTitleList[0][0].ownerships; last as isLast">
                {{ownership.podilCitatel + '/' + ownership.podilJmenovatel}}
                <ng-container *ngIf="ownership.isBsm"> (<a *ngIf="ownership.subject.id !== ownerId; else sjmNoLink" uiSref="symap.project.owners.detail" [uiParams]="{ownerId: ownership.subject.id, tab: 'about'}">SJM</a>)</ng-container>{{isLast ? "" : ", "}}
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
    <ng-template #noTitles>
      Žádné dostupné LV
    </ng-template>
    <ng-template #sjmNoLink>SJM</ng-template>
  </div>
</div>

