<fieldset>
  <legend>Typ záboru</legend>
  <gmt-switch [options]="[{ id: 'u', value: 'Dočasné zábory do 1 roku'}, { id: 'o', value: 'Dočasné zábory nad 1 rok'}]" [selectedId]="occupationType" (selectedIdChange)="onTypeChange($event)"></gmt-switch>
</fieldset>

<div [loading]="loading">
  <div *ngIf="!loading">
    <div *ngIf="occupations?.length">
      <div class="jumbotron text-center">
        Nalezen{{ occupations.length === 1 ? '' : 'o' }} <strong>{{ occupations.length }}</strong> zábor{{ occupations.length === 1 ? '' : 'ů' }}, u kterch lze zadat cenu. Níže vyplňte údaje o ceně.
      </div>

      <fieldset>
        <legend>Způsob určení ceny</legend>
        <gmt-switch [options]="priceType" [(selectedId)]="rentalPrice.rentalPriceType"></gmt-switch>
      </fieldset>

      <fieldset>
        <legend>Cena</legend>
        <div class="column-100">
          <label class="required">Délka nájmu [<span class="unit">měsíce</span>]</label>
          <div class="input"><input type="text" [(ngModel)]="rentalPrice.rentalLength" gmtInputNumber/></div>
        </div>

        <div class="column-100">
          <ng-container  *ngIf="rentalPrice.rentalPriceType === 'F'">
            <label class="required">Rok cenového výměru MF</label>
            <div class="input"><input type="text" [(ngModel)]="rentalPrice.priceAssessmentYear" gmtInputNumber/></div>
          </ng-container>

          <ng-container  *ngIf="rentalPrice.rentalPriceType === 'E'">
            <label class="required">Číslo znaleckého posudku</label>
            <div class="input"><input type="text" [(ngModel)]="rentalPrice.rentalExpertOpinionNumber"/></div>

            <label class="required">Datum znaleckého posudku</label>
            <div class="input"><input type="text" pick-a-date [(ngModel)]="rentalPrice.rentalExpertOpinionDate"/></div>
          </ng-container>

          <ng-container  *ngIf="rentalPrice.rentalPriceType === 'O'">
            <label class="required">Původ ceny</label>
            <div class="input"><input type="text" [(ngModel)]="rentalPrice.rentalOtherPriceSource"/></div>
            <div class="column-100">
              <label class="required">Způsob výpočtu ceny</label>
              <gmt-switch [options]="priceUnit" [(selectedId)]="rentalPrice.rentalPriceUnit"></gmt-switch>
            </div>
          </ng-container>

          <ng-container  *ngIf="rentalPrice.rentalPriceType === 'O' && rentalPrice.rentalPriceUnit === 'T'">
            <label class="required">Cena za pozemek [<span class="unit">Kč</span>]</label>
            <div class="input"><input type="text" [(ngModel)]="rentalPrice.totalRentPrice" gmtInputNumber/></div>
          </ng-container>

          <ng-container *ngIf="rentalPrice.rentalPriceType !== 'O' || rentalPrice.rentalPriceUnit !== 'T'">
            <label class="required">Cena za pozemek [<span class="unit">Kč/m<sup>2</sup>/rok</span>]</label>
            <div class="input"><input type="text" [(ngModel)]="rentalPrice.priceAssessmentLandPrice" gmtInputNumber/></div>
          </ng-container>
        </div>
      </fieldset>

      <expert-select-form *ngIf="rentalPrice.rentalPriceType === 'E'" [expert]="rentalPrice.rentalExpert" (changed)="onChangeExpert($event)"></expert-select-form>
    </div>

    <div *ngIf="occupations && !occupations.length" class="jumbotron text-center">
      Nebyl nalezen žádný zábor u kterého by šla nastavit cena.
    </div>

    <div class="mt-10">
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
    </div>
  </div>
</div>
