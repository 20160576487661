<div class="login-wrap">
  <div class="login-background" [ngClass]="{ 'with-image': APP_BRAND.NAME !== 'RSD' }">
    <div class="login">
      <div class="text-center mb-50">
        <a href="{{ APP_BRAND.LOGIN.APP_URL }}" target="_blank">
          <img src="./img/logo/{{ APP_BRAND.LOGIN.LOGO_PATH }}" alt="GMtech s.r.o."/>
        </a>
      </div>

      <ng-container *ngIf="!loggedOut">
        <form *ngIf="showLogin" class="login-form" method="post" (submit)="onSubmitCredentials(user.username, user.password)">
          <div class="login-group login-bar mb-30" [ngClass]="{'focus':ufocus === true}">
            <input
              id="username"
              required
              autofocus
              name="username"
              autocomplete="username"
              type="text"
              placeholder="Uživatelské jméno"
              [(ngModel)]="user.username"
              (change)="autofilled = false"
              (focus)="ufocus = true"
              (blur)="ufocus = false"
              class="login-input"
            />
          </div>

          <div class="login-group login-bar mb-30" [ngClass]="{'focus':pfocus === true}">
            <input
              id="password"
              required
              name="password"
              type="password"
              autocomplete="current-password"
              placeholder="Heslo"
              [(ngModel)]="user.password"
              (change)="autofilled = false"
              (focus)="pfocus = true"
              (blur)="pfocus = false"
              class="login-input"
            />
          </div>
          <div class="login-group mb-20">
            <button [disabled]="sending || (autofilled ? false : !(user.password && user.username))" type="submit" class="login-button">
              <span *ngIf="!sending">Přihlásit</span>
              <span *ngIf="sending">Přihlašování...</span>
            </button>
          </div>
        </form>

        <div *ngIf="!showLogin" class="login-group mb-20">
          <span *ngIf="sending">{{ 'login.sending' | brandTranslate }}</span>
        </div>

        <div *ngIf="errMsg && this.APP_BRAND.NAME !== 'RSD'" class="login-group" style="height: 50px;">
          <div class="error-message text-center mb-10">{{ errMsg }}</div>
          <a href="mailto:podpora@gmtech.cz">Nemohu se přihlásit</a>
        </div>

        <div *ngIf="errMsg && this.APP_BRAND.NAME === 'RSD'" class="login-group" style="height: 50px;">
          <div class="error-message text-center mb-10">{{ errMsg }}</div>
          Pro přidělení přístupu kontaktujte HelpDesk ŘSD (služba 19) na adrese: <a href="https://podpora-it.rsd.cz">https://podpora-it.rsd.cz/</a>.
        </div>
      </ng-container>

      <div *ngIf="(loggedOut || invalid) && !showLogin" class="login-group mt-30">
        <p class="text-center mb-20" *ngIf="loggedOut">{{ 'login.loggedOut' | brandTranslate }}</p>
        <button uiSref="login" [uiParams]="{ loggedOut: false }" [uiOptions]="{ inherit: false }" class="login-button">Opět přihlásit</button>
      </div>
    </div>
  </div>
</div>
