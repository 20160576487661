import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';
import { StateService } from '@uirouter/angular';
import { OccupationGroupsType, ParcelService } from '@app/ps/services/parcel.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { AuthService } from '@app/common/services/auth.service';
import { ListModel } from '@app/common/models/list.model';
import { CaseModel } from '@app/ps/models/case.model';
import { NoteModel } from '@app/common/models/note.model';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { EasementModel } from '@app/common/models/easement.model';
import { DocumentModel } from '@app/common/models/document.model';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { TabModel } from '@app/common/models/tab.model';
import { TableUtils } from '@app/common/utils/table.utils';
import { NotificationModel } from '@app/ps/models/notification.model';
import { BuildingModel } from '@app/common/models/building.model';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { User } from '@app/models/user';
import { DialogService } from '@app/common/services/dialog.service';
import { SimpleTextModalComponent } from '@app/common/components/simple-text-modal/simple-text-modal.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail',
  templateUrl: './title-detail.component.html'
})
export class TitleDetailComponent implements OnInit {

  tabs: TabModel[] = [
    {
      name: 'Vlastníci',
      id: 'owners',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'owners' },
    },
    {
      name: 'Parcely',
      id: 'parcels',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'parcels' },
    },
    {
      name: 'Ostatní parcely',
      id: 'otherParcels',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'otherParcels' },
    },
    {
      name: 'Budovy',
      id: 'buildings',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'buildings' },
    },
    {
      name: 'Omezení vlastnického práva',
      id: 'otherLegalRelations',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'otherLegalRelations' },
    },
    {
      name: 'Jiné zápisy',
      id: 'otherEntries',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'otherEntries' },
    },
    {
      name: 'Případy',
      id: 'cases',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'cases' },
    },
    {
      name: 'Poznámky',
      id: 'notes',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'notes' },
    },
    {
      name: 'Historie',
      id: 'history',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'history' },
    },
    {
      name: 'Upozornění',
      id: 'notifications',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'notifications' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: 'symap.project.titles.detail',
      urlParams: { tab: 'documents' },
    },
  ];

  private static readonly OCCUPATION_PERMANENT_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_parcel_title', sortable: true },
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_phase', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_cases', sortable: true },
    { id: 'current_parcel_title', sortable: false },
    { id: 'current_protection', sortable: false },
    { id: 'current_concern_object', sortable: false },
    { id: 'update_occupation', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_parcel_title', sortable: true },
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_phase', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_cases', sortable: true },
    { id: 'current_parcel_title', sortable: false },
    { id: 'current_protection', sortable: false },
    { id: 'current_concern_object', sortable: false },
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: ColumnDefinition[] =
    TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly EASEMENT_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel', sortable: true },
    { id: 'construction_object', sortable: false },
    { id: 'title', sortable: true },
    { id: 'area', sortable: false },
    { id: 'geometric_plan', sortable: false },
    { id: 'length', sortable: false },
    { id: 'case', sortable: true },
    { id: 'vb_tz', sortable: false },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_price_parcel', sortable: false },
    { id: 'occupation_price_vegetation', sortable: false },
    { id: 'occupation_price_construction', sortable: false },
    { id: 'occupation_price_summary', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_assessment_price', sortable: false },
    { id: 'occupation_price_land_price', sortable: false },
    { id: 'occupation_price_rent_price_year', sortable: false },
    { id: 'occupation_price_rent_length', sortable: false },
    { id: 'occupation_price_total_rental_price', sortable: false },
  ];

  private static readonly PERMANENT_WITH_SOLUTION_TYPE_COLUMNS: ColumnDefinition[] =
    [...TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS, ...TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS];

  private static readonly OCCUPATION_OVER_YEAR_PRICE_COLUMNS: ColumnDefinition[] =
    TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  @Input() titleId: number;
  tab: string;
  data: any;
  loading = true;
  caseList: ListModel<CaseModel>;
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  occupationList: ListModel<OccupationModel>;
  easementList: ListModel<EasementModel>;
  documentList: ListModel<DocumentModel>;
  notificationList: ListModel<NotificationModel>;

  parcelsLoading = true;
  dataOccupations: OccupationModel[];
  dataEasements: EasementModel[];
  dataOccupationGroups: OccupationGroupsType;
  easementType: boolean;
  easementPriceByShare = this.authService.getActualProject().easementPriceByShare;
  easementsLoading = true;
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  permanentWithSolutionTypeColumns: ColumnDefinition[];
  easementColumns: ColumnDefinition[];
  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;
  tenantEdit = false;
  geometricPlanModuleEnabled: boolean;
  cadastreDataDate: string;
  notificationCounts = { archived: undefined, unarchived: undefined };
  parcelsOtherLoading = true;
  dataParcelsOther: any;
  dataOwnershipGroups = [];
  ownershipsLoading = true;
  dataBuildings: BuildingModel[];
  buildingsLoading = true;
  buildingsWithDifferentTitleExists = false;
  otherEntriesLoading = true;
  dataRestrictions: any[];
  restrictionsLoading = true;
  dataOtherEntries: any[];
  userSending = true;
  users: User[];
  parcelsData = [];
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: any,
    private restangular: Restangular,
    public userNamePipe: UserNamePipe,
    private listService: ListService,
    private stateService: StateService,
    private parcelService: ParcelService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'owners');

    this.isActiveTab = this.isActiveTab.bind(this);
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    this.hideColumn = this.hideColumn.bind(this);
    this.canEdit = this.canEdit.bind(this);
    this.onUpdateNotificationList = this.onUpdateNotificationList.bind(this);
    this.addNote = this.addNote.bind(this);
    this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.loadHistory = this.loadHistory.bind(this);
    this.onCaseTableSort = this.onCaseTableSort.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  async ngOnInit() {
    await this.loadTitle();
    await this.settingsService.getSettingsLoader();
    this.loading = false;
    this.prepareParcelColumns();
    await this.loadOccupations();
    await this.loadEasements();
    this.loadNotifications();
    this.loadParcels();
    this.loadOwnerships();
    this.loadBuildings();
    this.loadOtherEntries();
    this.loadHistory();
    this.loadNotes();
    this.loadDocuments();
    this.loadCases();
    this.loadUsers();
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  hideColumn(columnID, tableID = 'titleDetail') {
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }

  canEdit(): boolean {
    return this.authService.isAuthorized() || this.authService.hasPermission('assignable');
  }

  addNote() {
    const dialog = this.dialogService.open(SimpleTextModalComponent, {
      data: {
        title: this.data.blockedByUser ? 'Důvod odblokování LV' : 'Důvod blokace LV',
        label: 'Důvod',
        required: true
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe(async (text: string) => {
      if (text) {
        this.restangular.one('titles', this.titleId).all('change-block-state')
          .post({
            text: text,
            title: { id: this.titleId }
          })
          .toPromise()
          .then((data) => {
              this.data.blockedByUser = data.noteType.key === 'ReasonTitleBlock';
              this.loadNotes();
              this.loadHistory();
          });
      }
      sub.unsubscribe();
    });
  }

  private loadTitle(): Promise<any> {
    return this.restangular.one('titles', this.titleId).get({loadCollections: ['duplicityOwnership']}).toPromise().then(data => {
      this.data = data;
      if (!data.endDate && data.platnostK && this.authService.getActualProject().cadastreDataDate !== data.platnostK) {
        this.cadastreDataDate = data.platnostK;
      }
    });
  }

  private loadOccupations(): Promise<any> {
    return this.restangular
      .all('occupations')
      .customPOST({filter: {filters: {titleId: [this.titleId], validOrWithCase: [true], loadCollections: [
        'parcel.knBudouc.currentParcels',
        'parcel.knBudouc.currentParcels.bpejList',
        'parcel.buildings',
        'constructionObjects',
        'rentalExpert',
        ]}, sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}]}})
      .toPromise()
      .then(data => {
        // group by occupation type into three groups permanent, underOneYear, overOneYear
        // permanent occupations on one parcel are merged
        this.dataOccupations = data;
        this.dataOccupations.forEach((item) => {
          item.constructionObjects = item.constructionObjects.filter((co) => !item.constructionObjects.some(co2 => co2.id > co.id && co2.socis === co.socis && co2.sousek === co.sousek) );
        });
        this.parcelsLoading = false;
        this.onUpdateParcelsList();

        this.dataOccupationGroups = this.parcelService.groupOccupationsByParcel(this.dataOccupations, this.parcelsData);
        this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.underOneYear);
        this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.overOneYear);
      });
  }

  private loadEasements(): Promise<any> {
    return this.restangular
      .all('easements')
      .customPOST({
        filter: {
          filters: {titleId: [this.titleId], validOrWithCase: [true], loadCollections: ['parcel.buildings']},
          sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}],
        },
        attributes: {constructionObjects: {
          load: true,
          attributes: {
            administrator: {load: true},
          }
        },
      }})
      .toPromise()
      .then((data) => {
        this.dataEasements = data;
        // if find some easement with property oznacVB, then set this.easementType to true, else false
        this.easementType = data.some((easement) => easement.hasOwnProperty('oznacVb') && easement.oznacVb);
        if (!this.easementPriceByShare && !this.hideColumn('easementsPriceColumns')) {
          this.easementColumns.push({ id: 'title_price', sortable: false });
        }
        this.easementsLoading = false;
        this.onUpdateParcelsList();
    });
  }

  private onUpdateParcelsList() {
    const parcelTab = this.tabs.find((tab) => tab.id === 'parcels');
    const occupationsParIDs = this.dataOccupations ? this.dataOccupations.map(occupation => occupation.parcel.idpar) : [];
    const easementsParIDs = this.dataEasements ? this.dataEasements.map(easement => easement.parcel.idpar) : [];
    const parcelsIDs = [...new Set([...occupationsParIDs, ...easementsParIDs])];
    const parcelsCount = parcelsIDs.length;
    parcelTab.name = `Parcely (${parcelsCount})`;
  }

  private loadBuildings(): Promise<any> {
    return this.restangular
      .all('buildings')
      .customPOST({filter: {filters: {occupationOrEasementExists: [true], titleRelatedId: [this.titleId], validity: ['valid'], loadCollections: ['parcelsWithEasementOrOccupation', 'buildingProtections', 'parcels.parcelProtections']}}})
      .toPromise()
      .then((data) => {
        data.forEach((building) => {
          building.parcels.forEach(
              (parcel) => {
                if (parcel.soucasti) {
                  building.buildingProtections = building.buildingProtections.concat(parcel.parcelProtections);
                }
                if ((!building.title || building.title.id === this.titleId) && parcel.title.id !== this.titleId) {
                  this.buildingsWithDifferentTitleExists = true;
                }
              }
            );
          }
        );
        this.dataBuildings = data;
        this.buildingsLoading = false;
        this.onUpdateBuildingsList();
      });
  }

  private onUpdateBuildingsList() {
    const buildingsTab = this.tabs.find((tab) => tab.id === 'buildings');
    if (this.dataBuildings.length === 0) {
      this.tabs.splice(this.tabs.indexOf(buildingsTab), 1);
    } else {
      buildingsTab.name = `Budovy (${this.dataBuildings.length})`;
    }
  }

  private loadOtherEntries() {
    this.restangular.all('other-legal-relations')
      .customPOST({filter: {filters: {titleId: [this.titleId], validity: ['valid'], sections: ['C', 'D'], loadCollections: ['buildingTo.parcels', 'subjectsFor', 'subjectsTo']}}})
      .toPromise()
      .then((data) => {
        this.dataRestrictions = _.filter(data, {sekce: 'C'});
        this.restrictionsLoading = false;
        this.onUpdateOtherLegalRelationsList();

        this.dataOtherEntries = _.filter(data, {sekce: 'D'});
        this.otherEntriesLoading = false;
        this.onUpdateOtherEntriesList();
      });
  }

  private onUpdateOtherEntriesList() {
    const otherEntriesTab = this.tabs.find((tab) => tab.id === 'otherEntries');
    otherEntriesTab.name = `Jiné zápisy (${this.dataOtherEntries.length})`;
  }

  onUpdateOtherLegalRelationsList() {
    const otherLegalRelationsTab = this.tabs.find((tab) => tab.id === 'otherLegalRelations');
    otherLegalRelationsTab.name = `Omezení vlastnického práva (${this.dataRestrictions.length})`;
  }

  private loadNotifications() {
    this.notificationList = this.listService.createList(
      'notifications',
      {
        filters: {
          titleId: this.titleId,
          loadCollections: ['knBudouc.futureParcel'],
          archive: false
        },
        sortOrder: {sortBy: 'createdTimestamp', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.notificationList).then(() => {
      this.onUpdateNotificationList();
    });
  }

  onUpdateNotificationList() {
    const filter = {
      limit: 1,
      filters: {
        titleId: this.titleId,
        archive: false
      }
    };

    const archivedFilter = {...filter, filters: {...filter.filters, archive: true}};
    const notificationList = this.listService.createList('notifications', filter);
    const notificationListArchived = this.listService.createList('notifications', archivedFilter);

    this.listService.fetchResult(notificationList).then(data => {
      this.notificationCounts.unarchived = data.itemCount;
      this.updateNotificationTab();
    });

    this.listService.fetchResult(notificationListArchived).then(data => {
      this.notificationCounts.archived = data.itemCount;
      this.updateNotificationTab();
    });
  }

  private updateNotificationTab() {
    const notificationTab = this.tabs.find((tab) => tab.id === 'notifications');
    if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
      notificationTab.name = `Upozornění (${this.notificationCounts.unarchived}/${this.notificationCounts.archived})`;
    } else {
      notificationTab.name = 'Upozornění';
    }
  }

  private loadParcels(): Promise<any> {
    return this.restangular
      .all('parcels')
      .customPOST({filter: {filters: {titleId: [this.titleId], validity: ['valid'], occupationOrEasementExists: [false], loadCollections: []}, sortOrder: [{sortBy: 'id'}]}})
      .toPromise()
      .then((data) => {
        this.parcelsOtherLoading = false;
        this.dataParcelsOther = data;
        this.onUpdateOtherParcels();
      });
  }

  private onUpdateOtherParcels() {
    const otherParcelsTab = this.tabs.find((tab) => tab.id === 'otherParcels');
    const otherParcelsCount = this.dataParcelsOther ? this.dataParcelsOther.length : 0;
    if (otherParcelsCount === 0) {
      this.tabs.splice(this.tabs.indexOf(otherParcelsTab), 1);
    } else {
      otherParcelsTab.name = `Ostatní parcely (${otherParcelsCount})`;
    }
  }

  private loadOwnerships(): Promise<any> {
    return this.restangular
      .all('ownerships')
      .customPOST({filter: {filters: {titleId: [this.titleId], validity: ['valid']}, sortOrder: [{sortBy: 'typravNazev'}]},
          attributes: {
            subject: {load: false, attributes: {globalSubjectDetail: {}}},
          },
        }
      )
      .toPromise()
      .then((data) => {
        data.forEach(value => {
          const items = this.dataOwnershipGroups.filter(e => e.groupName === value.typravNazev);
          let group;
          if (items.length === 0) {
            group = { groupName: value.typravNazev, items: [] };
            this.dataOwnershipGroups.push(group);
          } else {
            group = items[0];
          }
          group.items.push(value);
        });
        this.ownershipsLoading = false;
        this.onUpdateOwnersList();
      });
  }

  private onUpdateOwnersList() {
    const ownersTab = this.tabs.find((tab) => tab.id === 'owners');
    let ownersCount = 0;

    this.dataOwnershipGroups.forEach((g) => {
      ownersCount += g.items.length;
    });

    ownersTab.name = `Vlastníci (${ownersCount})`;
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: {titleId: this.titleId, cancelled: false},
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}},
      undefined,
      {businessCase: {}}
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  onUpdateDocumentList() {
    const attachmentsTab = this.tabs.find((tab) => tab.id === 'documents');
    attachmentsTab.name = `Dokumenty (${this.documentList.list.length})`;
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { titleId: this.titleId, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { titleId: this.titleId, systemType: true },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      }
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  private loadCases() {
    this.caseList = this.listService.createList('cases', {filters: {parcelTitleId: this.titleId, cancelStatus: 'notCancelled', loadCollections: ['titles', 'caseOwnerships', 'constructionObjects']}, limit: null });
    this.listService.fetchResult(this.caseList).then(() => {
      this.onUpdateCaseList();
    });
  }

  private onUpdateCaseList() {
    const caseTab = this.tabs.find((tab) => tab.id === 'cases');
    caseTab.name = `Případy (${this.caseList.itemCount})`;
  }

  onCaseTableSort(event) {
    this.listService.sort(this.caseList, event.column, event.ascOrDesc);
  }

  private loadUsers() {
    const filter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [this.authService.getActiveApplication()],
        },
        permission: {
          values: ['assignable'],
        },
        projects: {
          values: [this.authService.getActualProject().key],
        },
      },
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };
    this.restangularSettings.all('users/list').customPOST({ filter: filter }).toPromise().then((data) => {
      this.users = data;
    });

    this.userSending = false;
  }

  updateUser() {
    this.userSending = true;

    this.restangular.one('titles', this.titleId).customPUT({id: this.data.user.id}, 'user').toPromise().then(() => {
      this.userSending = false;
    });
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [ ...TitleDetailComponent.OCCUPATION_PERMANENT_COLUMNS ];
    let occupationUnderOneYearColumns = [ ...TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS ];
    let occupationOverOneYearColumns = [ ...TitleDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS ];
    let permanentWithSolutionTypeColumns = [ ...TitleDetailComponent.PERMANENT_WITH_SOLUTION_TYPE_COLUMNS ];
    let easementColumns = [ ...TitleDetailComponent.EASEMENT_COLUMNS ];

    if (!this.hideColumn('permanentOccupationPriceColumns')) {
      occupationPermanentColumns = occupationPermanentColumns.concat(TitleDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
      occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryOverYearPriceColumns')) {
      occupationOverOneYearColumns = occupationOverOneYearColumns.concat(TitleDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
    }

    if (this.canEdit()) {
      if (!this.hideColumn('temporaryOverYearPriceColumns')) {
        occupationOverOneYearColumns.push({ id: 'update_occupation', sortable: false });
      }
      if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
        occupationUnderOneYearColumns.push({ id: 'update_occupation', sortable: false });
      }
      if (!this.hideColumn('permanentOccupationPriceColumns')) {
        occupationPermanentColumns.push({ id: 'update_occupation', sortable: false });
      }
      permanentWithSolutionTypeColumns.push({ id: 'update_occupation', sortable: false });
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.permanentWithSolutionTypeColumns = TableUtils.repairColumnsStructure(permanentWithSolutionTypeColumns);
    this.easementColumns = TableUtils.repairColumnsStructure(easementColumns);
  }

  /**
   * Finds objects added to the latest case
   * and increments the case count accordingly.
   *
   * @param {array} arr
   * @param {array} rowsToIncrement
   * @returns {void}
   */
  private incrementCaseCount(arr, rowsToIncrement) {
    arr.forEach((item) => {
      if (rowsToIncrement.includes(item.id)) {
        item.countCases += 1;
      }
    });
  }
}
