﻿import { Injectable, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { APP_BRAND } from '@app/common/services/config.service';
import {HttpParams} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class LoginService {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    private stateService: StateService,
  ) {
  }

  redirectToLogin(includeParams = false, loggedOut = false) {
    if (includeParams) {
      const redirectUrl = window.location.hash.split("#!")[1];
      const code = new URLSearchParams(window.location.search).get('code');
      // Přesměrování po SSO přihlášení
      if (this.APP_BRAND.LOGIN.SSO && redirectUrl === undefined && code) {
        const queryParams = new HttpParams()
          .set('code', code)
          .toString();
        window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#!/login?' + queryParams;
        return;
      }
      const params = {
        redirectUrl: redirectUrl === '/otherwise' ? undefined : redirectUrl,
      }
      return this.stateService.go('login', params);
    } else if (loggedOut && this.APP_BRAND.LOGIN && this.APP_BRAND.LOGIN.LOGOUT_URL) {
      window.location.href = this.APP_BRAND.LOGIN.LOGOUT_URL;
      return;
    } else {
      return this.stateService.go('login', { loggedOut: loggedOut });
    }
  }
}
