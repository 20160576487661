import {Ng2StateDeclaration, StateParams } from '@uirouter/angular';
import { CreateCaseComponent } from './components/create-case/create-case.component';
import { TitleListComponent } from './components/title-list/title-list.component';
import { PricingOverviewComponent } from '@app/ps/titles/components/pricing-overview/pricing-overview.component';
import { UpdateOccupationComponent } from '@app/ps/components/update-occupation/update-occupation.component';
import { PricingExpertOpinionComponent } from '@app/ps/titles/components/pricing-expert-opinion/pricing-expert-opinion.component';
import { PricingOtherSourceComponent } from '@app/ps/titles/components/pricing-other-source/pricing-other-source.component';
import { TitleDetailComponent } from '@app/ps/titles/components/title-detail/title-detail.component';
import { resolveId } from '@app/common/utils/state.utils';
import { PricingPreliminaryOpinionComponent } from '@app/ps/titles/components/pricing-preliminary-opinion/pricing-preliminary-opinion.component';
import { Restangular } from 'ngx-restangular';

export const titlesState: Ng2StateDeclaration = {
  name: 'symap.project.titles',
  data: {
    title: 'Listy vlastnictví',
  },
  url: '/titles?page&sef',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    sef: {
      type: 'string',
      inherit: false,
      array: true
    },
  },
  views: {
    'content@symap.project': {
      component: TitleListComponent,
    }
  }
};

export const titleDetailState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail',
  data: {
    title: 'Detail listu vlastnictví',
  },
  url: '/:id/:tab',
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'owners',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
  resolve: {
    titleId: ['$stateParams', resolveId]
  },
  views: {
    'content@symap.project': {
      component: TitleDetailComponent,
    },
  },
};

export const createCaseState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.createCase2',
  data: {
    title: 'Založení případu',
  },
  url: '/create-case',
  params: {},
  resolve: {
    title: [Restangular, 'titleId', resolveTitle],
  },
  views: {
    'content@symap.project': {
      component: CreateCaseComponent,
    },
  },
};

export const createPricingState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.pricing',
  url: '/pricing',
  abstract: true,
};

export const createPricingOverviewState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.pricing.overview',
  data: {
    title: 'Znalecké posudky',
  },
  url: '/overview',
  params: {},
  views: {
    'content@symap.project': {
      component: PricingOverviewComponent,
    },
  },
};

export const createPricingExpertOpinionCreateState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.pricing.expertOpinion',
  data: {
    title: 'Zadání cen',
  },
  url: '/expert-opinion?opinionId?occupationType?opinionRequestId',
  resolve: {
    opinionId: [ '$stateParams', resolveOpinionId ],
    opinionRequestId: [ '$stateParams', resolveOpinionRequestId ],
    occupationType: [ '$stateParams', resolveOccupationType ],
  },
  params: {
    opinionId: {
      type: 'int',
    },
    opinionRequestId: {
      type: 'int',
    },
    occupationType: {
      value: '',
    },
  },
  views: {
    'content@symap.project': {
      component: PricingExpertOpinionComponent,
    },
  },
};

export const createPricingOtherSourceCreateState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.pricing.otherSource',
  data: {
    title: 'Zadání cen',
  },
  url: '/other-source?opinionId?occupationType',
  resolve: {
    opinionId: [ '$stateParams', resolveOpinionId ],
    occupationType: [ '$stateParams', resolveOccupationType ],
  },
  params: {
    opinionId: {
      type: 'int',
    },
    occupationType: {
      value: '',
    },
  },
  views: {
    'content@symap.project': {
      component: PricingOtherSourceComponent,
    },
  },
};

export const createPreliminaryOpinionState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.pricing.preliminary-opinion',
  data: {
    title: 'Znalecké posudky',
  },
  url: '/preliminary-opinion?opinionId',
  resolve: {
    opinionId: [ '$stateParams', resolveOpinionId ],
  },
  params: {
    opinionId: {
      type: 'int',
    }
  },
  views: {
    'content@symap.project': {
      component: PricingPreliminaryOpinionComponent,
    },
  },
};

export async function resolveTitle(restangular: Restangular, titleId: number) {
  return restangular.one('titles', titleId).get().toPromise();
}

export function resolveOpinionId(stateParams: StateParams): number {
  return stateParams.opinionId;
}

export function resolveOpinionRequestId(stateParams: StateParams): number {
  return stateParams.opinionRequestId;
}

export function resolveOccupationType(stateParams: StateParams): string {
  return stateParams.occupationType;
}

export function resolveOccupationIdFunction(stateParams: StateParams) {
  return stateParams.occupationId;
}

export const updateOccupationState: Ng2StateDeclaration = {
  name: 'symap.project.titles.detail.updateOccupation',
  data: {
    title: 'Editace záboru',
  },
  url: '/occupation/:occupationId',
  resolve: {
    titleId: [ '$stateParams', resolveId ],
    occupationId: [ '$stateParams', resolveOccupationIdFunction ],
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationComponent,
    },
  },
};
