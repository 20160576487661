import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import * as _ from 'lodash';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'receive-owner',
  templateUrl: './receive-owner.component.html',
  styleUrls: ['./receive-owner.component.scss']
})
export class ReceiveOwnerComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  recipient = null;
  sendData = {
    receivedOwnerDate: new Date(),
    bonusDate: null,
    validityDate: null,
  };
  dataCaseOwnerships: any[];
  name: string;

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private caseService: CaseService,
    private stateService: StateService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onReceivedOwnerDateChanged = this.onReceivedOwnerDateChanged.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('ReceivedOwner')).name;

    this.sendData.bonusDate = this.data.bonusDate;
    this.dataCaseOwnerships = [...this.data.caseOwnerships];
    this.dataCaseOwnerships.forEach((item) => {
      let bsmSubject;
      if (item.ownership.isBsm) {
        bsmSubject = this.data.caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId).subject;
      }
      item.caseSubjects = this.data.caseSubjects.filter(
        (value) => (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId))
      );
    });

    this.recipient = _.find(this.caseService.getRecipients(this.dataCaseOwnerships),
      (value) => {
        return !value.receivedDate && (value.sentDate || value.personalVisitDate);
      }
    );
    this.onReceivedOwnerDateChanged(this.sendData.receivedOwnerDate)
    this.loading = false;
  }

  onReceivedOwnerDateChanged(value) {
      if (value) {
        const date = new Date(value);
        let newDate;
        if (this.data.obligation.bonusPeriod && this.data.bonusPossible && !this.data.bonusDate) {
          newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.data.obligation.bonusPeriod);
          this.sendData.bonusDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
        }
        if (this.data.obligation.validityPeriod) {
          newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.data.obligation.validityPeriod);
          this.sendData.validityDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
        }
      } else {
        this.sendData.validityDate = null;
        if (!this.data.bonusDate) {
          this.sendData.bonusDate = null;
        }
      }
  }

  isValid() {
    return !!this.sendData.receivedOwnerDate;
  }

  async onSubmit(): Promise<any> {

    await this.restangular
      .one('cases', this.caseId)
      .customPUT(this.sendData, 'receive-owner')
      .toPromise();
    this.stateService.go('^');
  }
}
