export enum GeometricPlanRequestTypeEnum {
  PARCEL_SPLIT = 'PARCEL_SPLIT',
  PARCEL_BOUNDARY_SPECIFICATION = 'PARCEL_BOUNDARY_SPECIFICATION',
  EASEMENT_EXTENT = 'EASEMENT_EXTENT',
  PARCEL_BOUNDARY_CHANGE = 'PARCEL_BOUNDARY_CHANGE',
}

export const geometricPlanRequestTypeOptions = [
  {
    id: GeometricPlanRequestTypeEnum.PARCEL_SPLIT,
    name: 'Rozdělení pozemku',
  },
  {
    id: GeometricPlanRequestTypeEnum.PARCEL_BOUNDARY_SPECIFICATION,
    name: 'Průběh vytyčené nebo vlastníky zpřesněné hranice pozemků',
  },
  {
    id: GeometricPlanRequestTypeEnum.EASEMENT_EXTENT,
    name: 'Vymezení rozsahu věcného břemene k části pozemku',
  },
  {
    id: GeometricPlanRequestTypeEnum.PARCEL_BOUNDARY_CHANGE,
    name: 'Změna hranice pozemku',
  }
];
