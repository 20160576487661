﻿import { Component, OnInit, Inject, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list.ts';
import { BulkOperationProcessDefinitionModel } from '@app/ps/models/bulk-operation-process-definition.model';
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { SettingsService } from '@app/ps/services/settings.service';
import { TableService } from '@app/ps/services/table.service';
import { ChecklistModel } from '@app/common/models/checklist.model';

@Component({
  templateUrl: './bulk-operation-select-entities.component.html',
  styleUrls: ['./bulk-operation-select-entities.component.scss']
})
export class BulkOperationSelectEntitiesComponent implements OnInit {
  list: any;
  callbacks: CallbackModel;
  pageableList: PageableList;
  inverseSelection = false;
  checklist: any;
  data: any;
  @ViewChild('entityTable', {
    read: ViewContainerRef
  }) tableViewContainerRef: ViewContainerRef;
  tableComponent: any;

  constructor(
    private pageableListService: PageableListService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private tableService: TableService,
    private settingsService: SettingsService,
    @Inject('processDefinition') private processDefinition: BulkOperationProcessDefinitionModel,
  ) {
    this.toggleCheckItem = this.toggleCheckItem.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.sort = this.sort.bind(this);
  }

  ngOnInit() {
    this.checklist = new ChecklistModel(!this.data.selectEntities ? undefined : this.data.selectEntities.checkedItems);
    this.inverseSelection = !this.data.selectEntities ? false : this.data.selectEntities.inverseSelection;
    this.initializeList();
  }

  checkAll() {
    this.inverseSelection = !this.inverseSelection;
    this.tableComponent.instance.inverseSelection = this.inverseSelection;
    this.checklist.empty();
    this.notifyParent();
  }

  toggleCheckItem(item) {
    this.checklist.toggleSelection(item);
    this.notifyParent();
  }

  notifyParent() {
    this.callbacks.get(Callbacks.DataValidityChanged)({
      isValid: () => this.getSelectedCount() > 0,
      data: {
        checkedItems: this.checklist.checkedItems,
        inverseSelection: this.inverseSelection,
        count: this.getSelectedCount(),
        filters: this.list.filter.filters,
      }
    });
  }

  getSelectedCount(): number {
    return this.inverseSelection ? this.list.itemCount - this.checklist.checkedItems.length : this.checklist.checkedItems.length;
  }

  sort(event) {
    this.pageableList.onSort(event.column, event.ascOrDesc);
  }

  initializeList() {
    this.pageableList = this.pageableListService.get(
      this.processDefinition.getBulkOperationId(),
      {filters: this.processDefinition.getDefaultFilters(this.settingsService)},
      this.processDefinition.getEntityIds() === null ? this.processDefinition.getFilterLocalStorageName() : undefined
    );
    this.pageableList.disableLocalStorage();
    this.list = this.pageableList.list;
    this.pageableList.load();

    const factory = this.componentFactoryResolver.resolveComponentFactory(this.processDefinition.getTableComponent());
    this.tableComponent = factory.create(this.tableViewContainerRef.parentInjector);
    this.tableViewContainerRef.insert(this.tableComponent.hostView);

    this.tableComponent.instance.list = this.list;
    this.tableComponent.instance.isChecked = this.checklist.isChecked;
    this.tableComponent.instance.inverseSelection = this.inverseSelection;

    this.tableComponent.instance.checkAll.subscribe(this.checkAll);
    this.tableComponent.instance.sort.subscribe(this.sort);
    this.tableComponent.instance.toggleCheckItem.subscribe(this.toggleCheckItem);
  }

  pageChanged(pageNumber: number) {
    this.pageableList.fetchResults(pageNumber);
    this.pageableList.onListChanged();
  }
}
