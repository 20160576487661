import { Injectable } from '@angular/core';
import { SettingsService } from '@app/ps/services/settings.service';
import { TableService } from '@app/ps/services/table.service';
import { ProjectStatusService } from '@app/ps/services/project-status.service';
import { Restangular } from 'ngx-restangular';
import { saveAs } from 'file-saver';
import { ColumnDefinition } from '@app/common/models/column-definition.model';

@Injectable({
  providedIn: 'root'
})
export class ExportListService {

  static readonly DEFAULT_EXPORT_CONFIG = {
    cases: { endpoint: 'cases', filter: { filters: { loadCollections: [ 'titles', 'caseOwnerships', 'constructionObjects', 'caseSubjects', ] }}},
    constructionObjects: { endpoint: 'construction-objects', filter: { filters: { validity: 'valid', loadCollections: [ 'occupationResolution', 'administrator' ] }}},
    geometricPlanRequests: { endpoint: 'geometric-plan-requests', filter: { filters: { deleted: { values: [ false ] }, loadCollections: [ 'areas', 'constructionObjects', 'parcels' ]}} },
    occupations: { endpoint: 'occupations', filter: { filters: { validOrWithCase: true, loadCollections: [ 'constructionObjects', 'parcel.title.ownerships', 'cases' ]}} },
    opinionRequests: { endpoint: 'opinion-requests', filter: { filters: { deleted: { values: [ false ] }, loadCollections: [ 'parcels' ]}} },
    owners: { endpoint: 'subjects', filter: { filters: { opsubType: {values: [{id: 'BSM'}], negation: true}, validity: {values: [ 'withOccupationOrEasement', 'withOwnership' ]}, loadCollections: [ 'ownerships', 'occupationResolution', 'cases']}} },
    parcels: { endpoint: 'parcels', filter: { filters: { validity: 'valid', loadCollections: [ 'title.ownerships', 'geometricPlanRequests', 'opinionRequests', 'constructionObjects', 'knBudoucPepvs', 'parcelPrices', 'buildings', 'notCancelledCases' ]}} },
    titles: { endpoint: 'titles', filter: { filters: { validity: 'valid', loadCollections: [ 'ownerships', 'occupationResolution', 'duplicityOwnership', 'cases' ]}} },
  };

  constructor(
    private settingsService: SettingsService,
    private tableService: TableService,
    private projectStatusService: ProjectStatusService,
    private restangular: Restangular,
  ) {
  }

  exportDefaultList(tableId) {
    const conf = ExportListService.DEFAULT_EXPORT_CONFIG[tableId];
    return this.exportList(conf.endpoint, tableId, conf.filter || { filter: {} });
  }

  exportList(endpoint: string, tableId: string, filter: any, attributes = {}, all = false, additionalColumnDefinitions: any[] = []) {
    const updatedFilter = this.getFilter(filter);
    const columnDefinitions = this.getColumnDefinitions(tableId, all).concat(additionalColumnDefinitions);

    return this.restangular
      .one(`${endpoint}/export`)
      .customPOST({
        attributes: attributes,
        filter: updatedFilter,
        columnDefinitions: columnDefinitions
      })
      .toPromise()
      .then((data) => {
        this.projectStatusService.refresh(data);
      });
  }

  downloadList(endpoint: string, tableId: string, filter: any, attributes = {}, all = false, additionalColumnDefinitions: any[] = []) {
    const updatedFilter = this.getFilter(filter);
    const columnDefinitions = this.getColumnDefinitions(tableId, all).concat(additionalColumnDefinitions);

    return this.restangular
      .one(`${endpoint}/download`)
      .withHttpConfig({ responseType: 'blob' })
      .customPOST({
        attributes: attributes,
        filter: updatedFilter,
        columnDefinitions: columnDefinitions
      })
      .toPromise()
      .then((data) => {
        saveAs(data, `${endpoint}.xlsx`, true);
      });
  }

  private getFilter(filter: any): any {
    filter.limit = null;

    if (filter.sortOrder && !(filter.sortOrder instanceof Array)) {
      filter.sortOrder = [ filter.sortOrder ];
    }

    return filter;
  }

  private getColumnDefinitions(tableId: string, all: boolean): any[] {
    const table = this.tableService.getTables().find(t => t.id === tableId);
    const columnDefinitions = [];

    for (const c of table.columns) {
      if (all) {
        for (const d of c.exportDefinitions) {
          columnDefinitions.push(d);
        }
      } else {
        if (!this.settingsService.shouldHideColumn(tableId, c.id) && c.exportDefinitions && c.exportDefinitions.length) {
          for (const d of c.exportDefinitions) {
            columnDefinitions.push(d);
          }
        }
      }
    }

    return columnDefinitions;
  }
}
