<h1>Příloha aktuality</h1>

<div>
  <fieldset>
    <gmt-input label="Text" class="mt-10">
      <input type="text" [(ngModel)]="text"/>
    </gmt-input>
  </fieldset>

  <fieldset>
    <div class="mt-50 text-center">
      <fileupload class="blue" (change)="onFilesChange($event)" [message]="filesMsg" inputId="fileHidden"></fileupload>
    </div>
  </fieldset>

  <div class="actions">
    <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSave">Uložit</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</div>
