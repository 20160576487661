import { Component, Inject, OnInit } from '@angular/core';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { AuthService } from '@app/common/services/auth.service';
import { SubjectBasicInfoModel } from '@app/ps/models/subject-basic-info.model';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';
import { CommonAddressModel } from '@app/ps/models/common-address.model';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { EnumUtils } from '@app/common/utils/enum.utils';
import { GendersEnum } from '@app/ps/enums/genders.enum';
import { MaritalStatusesEnum } from '@app/ps/enums/marital-statuses.enum';
import { SubjectService } from '@app/ps/services/subject.service';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { SubjectDetailModel } from '@app/ps/models/subject-detail.model';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'form-case-update-subject',
  templateUrl: './form-case-update-subject.component.html',
  styleUrls: ['./form-case-update-subject.component.scss'],
})
export class FormCaseUpdateSubjectComponent implements OnInit {
  loading = true;
  caseSubjectId: number;
  caseOwnershipId: number;
  caseSubject: CaseSubjectModel;
  subjectDetail: SubjectDetailModel;
  basicInfo: SubjectBasicInfoModel;
  contactPerson: Partial<ActingPersonModel>;
  address: CommonAddressModel;
  mailingAddress: CommonAddressModel;
  GENDERS: SwitchOption[];
  MARITAL_STATUSES: SwitchOption[];
  subjectFields = {};
  ownershipFields = {};
  hasSubjectFieldsToDisplay = false;
  hasOwnershipFieldsToDisplay = false;
  shouldShowPaymentForm: boolean;
  caseOwnership: any;
  private availableSubjectFields: string[] = [
    'bonusPrice',
    'declinedDate',
    'personalVisitDate',
    'receivedDate',
    'responseReceivedDate',
    'sentDate',
    'signatureVerificationPrice',
    'signedDate',
    'undeliveredDate',
    'meetingRequestDate',
    'acceptedGeometricalPlanPossibleDate',
    'callBeforeExpropriationDate',
    'dunningLetterDate',
    'callBeforeExpropriationReceivedDate',
    'expropriationCaseInProgressDate',
    'expropriationCaseStoppedDate',
    'expropriationCaseFinishedDate',
    'disapprovedOwnerDate',
    'approvedOwnerDate',
    'exchangeRequestDate',
    'otherSolutionRequestDate',
    'withoutResolutionDate',
  ];
  private mailingAddressBefore;
  private addressBefore;
  private contactBefore;
  private contactPersonBefore;
  private basicInfoBefore;

  constructor(
    private authService: AuthService,
    config: DialogConfig,
    public dialog: DialogRef,
    private restangular: Restangular,
    private dialogService: DialogService,
    private subjectService: SubjectService,
  ) {
    this.caseSubjectId = (<DialogConfigData>config.data).caseSubjectId;
    this.caseOwnershipId = (<DialogConfigData>config.data).caseOwnershipId;

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAll = this.onSubmitAll.bind(this);
    this.onSubmitCase = this.onSubmitCase.bind(this);
    this.onCreateAndAddRepresentative = this.onCreateAndAddRepresentative.bind(this);
    this.onRemoveRepresentative = this.onRemoveRepresentative.bind(this);
    this.removeRoleCadastreForAllExcept = this.removeRoleCadastreForAllExcept.bind(this);
  }

  async ngOnInit() {
    this.caseSubject = await this.restangular
      .one('case-subjects', this.caseSubjectId)
      .get({ loadCollections: ['businessCase', 'actingPersons'] })
      .toPromise()
      .then((data: any): CaseSubjectModel => {
        return <CaseSubjectModel>{
          ...data.plain(),
          actingPersons: data.actingPersons.map(ActingPersonModel.fromFlatObject)
        };
      });
    this.subjectDetail = this.caseSubject.subject.subjectDetail ? this.caseSubject.subject.subjectDetail : <SubjectDetailModel>{};
    this.caseOwnership = (await this.restangular.one('case-ownerships', this.caseOwnershipId).get().toPromise()).plain();

    this.MARITAL_STATUSES = <SwitchOption[]>EnumUtils.toObject(MaritalStatusesEnum, 'id', 'name');
    this.GENDERS = <SwitchOption[]>EnumUtils.toObject(GendersEnum, 'id', 'name');
    this.subjectFields = this.getDisplayedSubjectFields();
    this.ownershipFields = this.getDisplayedOwnershipFields();
    this.hasSubjectFieldsToDisplay = Object.keys(this.subjectFields).length > 0;
    this.hasOwnershipFieldsToDisplay = Object.keys(this.ownershipFields).length > 0;
    this.address = this.normalizeAddressFromSubject();
    this.basicInfo = this.normalizeBasicInfoFromSubject();
    this.mailingAddress = this.normalizeMailingAddressFromSubject();
    this.contactPerson = this.normalizeContactPerson();
    this.initializeActingPersonWhenEmpty();
    this.shouldShowPaymentForm = !this.caseOwnership.ownership.isBsm || this.caseOwnership.singlePayment;
    this.loading = false;

    this.basicInfoBefore = { ...this.basicInfo };
    this.addressBefore = { ...this.address };
    this.mailingAddressBefore = { ...this.mailingAddress };
    this.contactBefore = { ...{
     phoneNumber: this.caseSubject.phoneNumber,
     email: this.caseSubject.email,
     databoxId: this.caseSubject.databoxId
    }};
    this.contactPersonBefore = { ...this.contactPerson };
  }

  onSubmit(): Promise<any> {
    if (this.isValidForOwnerUpdate()) {
      return this.dialogService.confirmDialogPromise('Uložení změn. Chcete uložit změny údajů nejen u tohoto případu, ale i v detailu vlastníka?')
        .then(result => {
          if (result === true) {
            return this.onSubmitAll();
          } else {
            return this.onSubmitCase();
          }
        });
    }
    return this.onSubmitCase();
  }

  onSubmitAll(): Promise<any> {
    this.assembleSubjectDetail();

    return this.restangular
      .one(`subjects/${this.caseSubject.subject.id}/detail`)
      .customPUT(this.subjectDetail)
      .toPromise()
      .then(this.onSubmitCase);
  }

  onSubmitCase(): Promise<any> {
    this.assembleOwner();

    const caseSubject = {
      ...this.caseSubject,
      actingPersons: this.caseSubject.actingPersons.map(ActingPersonModel.toFlatObject)
    };

    return this.restangular
      .one('cases', this.caseSubject.businessCase.id)
      .all('ownerships-subjects')
      .customPUT({
        caseOwnerships: [ this.caseOwnership ],
        caseSubjects: [ caseSubject ],
      })
      .toPromise()
      .then((data) => {
        data = data.plain();
        const caseSubject = {
          ...data.caseSubjects[0],
          actingPersons: data.caseSubjects[0].actingPersons.map(ActingPersonModel.fromFlatObject)
        };
        this.dialog.close({
          caseSubject,
          caseOwnership: data.caseOwnerships[0],
        });
      });
  }

  /*
   * If there is at least one representative with any of its properties set,
   * his/her firstname, surname and ico (when legal entity) have to be set as well.
   */
  hasValidForm(): boolean {
    return this.caseSubject.actingPersons
      .filter(ActingPersonModel.hasAtLeastOneDefinedProperty)
      .every((r: ActingPersonModel) => {
        return <boolean>(
          r.firstName
          && r.surname
          && (r.companyName ? r.identificationNumber : true)
        );
      });
  }

  isValidForOwnerUpdate(): boolean {
    const contact = {
      phoneNumber: this.caseSubject.phoneNumber,
      email: this.caseSubject.email,
      databoxId: this.caseSubject.databoxId
    };
    const basicInfoDiff = JSON.stringify(this.basicInfoBefore) !== JSON.stringify(this.basicInfo);
    const mailAddressDiff = (JSON.stringify(this.mailingAddressBefore) !== JSON.stringify(this.mailingAddress)) || (this.subjectDetail.dorucovaciUse !== this.caseSubject.dorucovaciUse);
    const addressDiff = (JSON.stringify(this.addressBefore) !== JSON.stringify(this.address));
    const contactPersonDiff = JSON.stringify(this.contactPersonBefore) !== JSON.stringify(this.contactPerson);
    const contactDiff = JSON.stringify(this.contactBefore) !== JSON.stringify(contact);
    return basicInfoDiff || mailAddressDiff || contactPersonDiff || contactDiff || addressDiff;
  }

 removeRoleCadastreForAllExcept(representative: ActingPersonModel) {
    this.caseSubject.actingPersons = this.caseSubject.actingPersons
      .map((r: ActingPersonModel) => {
        if (representative !== r && representative.roleCadastre) {
          r.roleCadastre = false;
        }
        return {...r};
      });
  }

  onRemoveRepresentative(representative: ActingPersonModel): Function {
    return () => {
      const idx = this.caseSubject.actingPersons.indexOf(representative);
      if (idx !== -1) {
        this.caseSubject.actingPersons.splice(idx, 1);
      }

      if (this.caseSubject.actingPersons.length === 0) { // don't let the form disappear
        this.onCreateAndAddRepresentative();
      }
    };
  }

  shouldShowDeleteButton(): boolean {
    return <boolean>(this.caseSubject.actingPersons.length > 1 || (this.caseSubject.actingPersons.length === 1 && this.caseSubject.actingPersons[0].id));
  }

  onToggleAddress(addressUsed: boolean) {
    this.subjectDetail.customAddressUse = addressUsed;
  }


  onToggleMailingAddress(addressUsed: boolean) {
    this.caseSubject.dorucovaciUse = addressUsed;
  }

  onCreateAndAddRepresentative() {
    this.caseSubject.actingPersons.push(new ActingPersonModel());
  }

  private assembleOwner() {
    Object.assign(this.caseSubject, this.denormalizeMailingAddress());
    Object.assign(this.caseSubject, this.denormalizeSubjectAddress());
    Object.assign(this.caseSubject, this.denormalizeContactPerson());
    Object.assign(this.caseSubject, this.denormalizeBasicInfo());

    this.caseSubject.actingPersons = this.caseSubject.actingPersons
      .filter(ActingPersonModel.hasAtLeastOneDefinedProperty);

    if (this.caseSubject.identificationNumber === '') {
      this.caseSubject.identificationNumber = undefined;
    }
  }

  private assembleSubjectDetail() {
    Object.assign(this.subjectDetail, this.denormalizeSubjectAddress());
    Object.assign(this.subjectDetail, this.denormalizeBasicInfo());
    Object.assign(this.subjectDetail, this.denormalizeMailingAddress());
    Object.assign(this.subjectDetail, this.denormalizeContactPerson());
    this.subjectDetail.phoneNumber = this.caseSubject.phoneNumber;
    this.subjectDetail.email = this.caseSubject.email;
    this.subjectDetail.databoxId = this.caseSubject.databoxId;
    this.subjectDetail.dorucovaciUse = this.caseSubject.dorucovaciUse;

    if (!this.subjectDetail.customAddressUse) {
      this.subjectDetail.customAddressUse = false;
    }

    if (this.subjectDetail.identificationNumber === '') {
      this.subjectDetail.identificationNumber = undefined;
    }
  }

  private initializeActingPersonWhenEmpty() {
    if (this.caseSubject.actingPersons.length === 0) {
      this.caseSubject.actingPersons.push(new ActingPersonModel());
    }
  }

  // basic info
  private normalizeBasicInfoFromSubject(): SubjectBasicInfoModel {
    return this.subjectService.getAdaptedBasicInfo(this.caseSubject);
  }

  private denormalizeBasicInfo() {
    return this.subjectService.getSubjectCompatibleBasicInfo(this.basicInfo);
  }

  // address
  private normalizeAddressFromSubject(): CommonAddressModel {
    return this.subjectService.getAdaptedAddress(this.caseSubject);
  }

  private denormalizeSubjectAddress() {
    return this.subjectService.getSubjectCompatibleAddress(this.address);
  }

  // mailing address
  private normalizeMailingAddressFromSubject(): CommonAddressModel {
    return this.subjectService.getAdaptedMailingAddress(this.caseSubject);
  }

  private denormalizeMailingAddress() {
    return CommonAddressModel.toMailingAddress(this.mailingAddress);
  }

  // contact person
  private normalizeContactPerson(): Partial<ActingPersonModel> {
    return this.subjectService.getAdaptedContactPerson(this.caseSubject);
  }

  private denormalizeContactPerson(): any {
    return this.subjectService.getSubjectCompatibleContactPerson(this.contactPerson);
  }

  private userCanUpdateCase(): boolean {
    return this.authService.canUpdateCaseData();
  }

  private userCanUpdateCasePrice(): boolean {
    return this.authService.canUpdateCasePrice();
  }

  private subjectHasBonusDate(): boolean {
    return <boolean><any>this.caseSubject.businessCase.bonusDate;
  }

  private isCommunityProperty(): boolean {
    return this.caseOwnership.ownership.isBsm;
  }

  private getDisplayedOwnershipFields(): {[s: string]: boolean} {
    const fields = {};

    if (this.easementPriceByShareEnabled() && !this.isCommunityProperty()) {
      if (this.isRealBurdenLiability()) {
        fields['easementFutureContractNumber'] = true;
        fields['easementFutureContractStatus'] = true;
      }

      if (this.userCanUpdateCasePrice()) {
        fields['easementPriceByShare'] = true;
      }
    }

    return fields;
  }

  private getDisplayedSubjectFields(): {[s: string]: boolean} {
    const fields = {};
    const isDefinedAndZero = (field: string): boolean => this.caseSubject[field] || this.caseSubject[field] === 0;
    const bonusPriceEnabled = (field: string): boolean => !!(field !== 'bonusPrice' || (this.subjectHasBonusDate() && field === 'bonusPrice'));
    const showField = (field: string) => fields[field] = true;

    if (!this.userCanUpdateCase()) {
      return fields;
    }

    this.availableSubjectFields
      .filter(isDefinedAndZero)
      .filter(bonusPriceEnabled)
      .forEach(showField);

    return fields;
  }

  private isRealBurdenLiability() {
    return this.caseSubject.businessCase.obligation.type === 'RealBurdenLiability';
  }

  private easementPriceByShareEnabled(): boolean {
    return this.authService.getActualProject().easementPriceByShare;
  }
}
