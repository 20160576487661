import { StateService, StateParams, Transition, Ng2StateDeclaration } from '@uirouter/angular';

import { DialogService } from '@app/common/services/dialog.service';
import { ModulesService } from '@app/common/services/modules.service';
import { SelectItem } from '@app/common/components/select/select.component';
import { UserPermissionsComponent, Permission } from '@app/common/components/user-permissions/user-permissions.component';
import { UserListComponent } from '@app/settings/users/component/user-list/user-list.component';
import { resolveCallbacks, resolveId } from '@app/common/utils/state.utils';
import { UserDetailComponent } from '@app/settings/users/component/user-detail/user-detail.component';
import { CallbackModel } from '@app/common/models/callback.model';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import {PermissionsBulkComponent} from "@app/settings/users/component/permissions-bulk/permissions-bulk.component";
import {UIRouter} from "@uirouter/core";

export async function handleUserPermissionsModal(transition: Transition) {
  const getPermissionDetails = (permission: string, detailedPermissions: {id: number, name: string; description: string}[]): Permission => {
    const detail = detailedPermissions.find(dp => dp.name === permission);
    return {
      id: detail.name,
      value: detail.description,
    };
  };
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const restangular = transition.injector().get(RESTANGULAR_SETTINGS);
  const modulesService = transition.injector().get(ModulesService);
  const userPermissionsChangedCallback = transition.injector().get('userPermissionsChangedCallback');
  const user = transition.injector().get('user');
  const application = transition.injector().get('application');
  const projectKey = transition.injector().get('projectKey');

  const detailedPermissions = await modulesService.getPermissions(application);
  const rolesWithPermissions = await modulesService.getModuleRoles(application);
  const roles: SelectItem[] = await restangular.all(`${application}/roles?withDeprecated=true`).getList().toPromise().then(l => l.plain());
  const permissionsWithRoles = detailedPermissions
    .map((dp) => {
      dp.roles = [];
      rolesWithPermissions.forEach(role => {
        if (role.permissions.includes(dp.name)) {
          dp.roles.push(role.role);
        }
      });

      return dp;
    });
  const currentRole = user.roles.find(item => item.application === application && item.projectKey === projectKey);
  const currentRolePermissions: Permission[] = currentRole && permissionsWithRoles
    .filter(pwr => pwr.roles.includes(currentRole.role))
    .map(pwr => ({ id: pwr.name, value: pwr.description }));
  const currentUserPermissions = (await modulesService.getUserPermissions(application, user.id))[projectKey] || [];
  const normalizedCurrentUserPermissions: string[] = currentUserPermissions.map(item => item.permission);
  const additionalPermissions = normalizedCurrentUserPermissions
    .map(item => getPermissionDetails(item, detailedPermissions))
    .filter((item: Permission) => {
      return !currentRolePermissions || !currentRolePermissions.map(crp => crp.id).includes(item.id);
    });
  const dialog = dialogService.open(UserPermissionsComponent, {
    data: {
      additionalPermissions,
      application,
      currentRole,
      permissionsWithRoles,
      projectKey,
      roles: roles,
      userId: user.id,
    },
  });


  const sub = dialog.afterClosed.subscribe((res: boolean) => {
    stateService.go('^');

    if (res && typeof userPermissionsChangedCallback === 'function') {
      userPermissionsChangedCallback();
    }
    sub.unsubscribe();
  });
}

export async function resolveUser(stateParams: StateParams, transition: Transition): Promise<any> {
  const modulesService = transition.injector().get(ModulesService);
  return modulesService.getUser(stateParams.id);
}

export function resolvePermissionsCallback(callbacks: CallbackModel) {
  return callbacks.get('userPermissionsChangedCallback');
}

export function resolveApplication(stateParams: StateParams): string {
  return stateParams.tab;
}

export function resolveProjectKey(stateParams: StateParams): string {
  return stateParams.projectKey;
}

export const userListState: Ng2StateDeclaration = {
  name: 'settings.users',
  url: '/users?page',
  data: {
    title: 'Správa uživatelů',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
      squash: true,
    },
  },
  views: {
    'content@settings': {
      component: UserListComponent,
    },
  },
};

export const userDetailState: Ng2StateDeclaration = {
  name: 'settings.users.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail uživatele',
  },
  views: {
    'content@settings': {
      component: UserDetailComponent,
    }
  },
  resolve: {
    userId: [ '$stateParams', resolveId ],
    callbacks: [ resolveCallbacks ],
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'modules',
      dynamic: true,
      inherit: true,
    }
  },
};

export const editUserPermissionsBulkState: Ng2StateDeclaration = {
  name: 'settings.users.permissionsBulk',
  url: '/permissions-bulk',
  data: {
    title: 'Nastavení oprávnění',
  },
  views: {
    'content@settings': {
      component: PermissionsBulkComponent,
    },
  },
};

export const editUserPermissionsState: Ng2StateDeclaration = {
  name: 'settings.users.detail.editPermissions',
  url: '/edit-permissions',
  data: {
    title: 'Editace oprávnění uživatele',
  },
  resolve: {
    application: ['$stateParams', resolveApplication],
    projectKey: ['$stateParams', resolveProjectKey],
    user: ['$stateParams', '$transition$', resolveUser],
    userPermissionsChangedCallback: ['callbacks', resolvePermissionsCallback],
  },
  params: {
    projectKey: {
      type: 'string',
    },
  },
  onEnter: handleUserPermissionsModal
};
