<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <div *ngIf="showEntryProposalForm">

      <form-cadastre-entry-proposal [data]="data" (callbackRegister)="formCallbackRegister($event)" (load)="onLoad()"></form-cadastre-entry-proposal>

      <div *ngIf="loadingFinished">
        <gmt-button *ngIf="!data.entryProposalLink" [clickAction]="sendProposal" [gmtDisabled]="!hasValidProposalCredentials()">Odeslání Návrhu na vklad do webové aplikace</gmt-button>
        <gmt-button *ngIf="data.entryProposalLink" [clickAction]="deleteProposal">Smazat existující odkaz na Návrh na vklad</gmt-button>
      </div>
    </div>

    <fieldset *ngIf="loadingFinished">
      <div class="column-100">
        <label class="required">Datum podání návrhu</label>
        <div class="input">
          <input type="text" pick-a-date [(ngModel)]="sendDataCase.entryProposalDate" (ngModelChange)="onDateChanged($event)" [maxToday]="true"/>
        </div>

        <label>Číslo jednací KN</label>
        <div class="input">
          <input type="text" [(ngModel)]="sendDataCase.cadastreEntryNumber"/>
        </div>

        <div *ngIf="!data.paymentOrderDate && data.obligation.dueDateFromType === 'EntryProposalDate'">
          <label>Datum splatnosti</label>
          <div class="info">{{ data.dueDate | date }}</div>
        </div>
      </div>
    </fieldset>

    <div *ngIf="loadingFinished">
      <gmt-button [gmtDisabled]="!hasValidProposalDate()" [clickAction]="confirmProposal">{{ name }}</gmt-button>
    </div>
  </div>
</div>
