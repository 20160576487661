import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MapStorageHelpersService } from '@app/map/services/map-storage-helpers.service';

@Injectable({
  providedIn: 'root'
})
export class MapOptionsStorageService {
  private opts = {};
  constructor(
    private mapStorageHelpersService: MapStorageHelpersService
  ) {
    this.setOptions = this.setOptions.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.resetOptions = this.resetOptions.bind(this);
  }

   setOptions(o, id) {
      var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.opts, id);
      defer.resolve(o);
      this.mapStorageHelpersService.setResolvedDefer(this.opts, id);
  }

   getOptions(mapId){
      return this._getOptions(mapId).then(function (opts) {
          return _.cloneDeep(opts);
      });
  }

   resetOptions(id){
      this.opts[id] = undefined;
  }

  ///////////////////////// PRIVATE ////////////////////////////

   _getOptions(id) {
      var defer = this.mapStorageHelpersService.getDefer(this.opts, id);
      return defer.promise;
  }
}

