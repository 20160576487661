import { Component, Inject, OnInit } from '@angular/core';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'easement-update-global-price',
  templateUrl: './easement-update-global-price.component.html',
  styleUrls: []
})
export class EasementUpdateGlobalPriceComponent implements OnInit {

  loading = true;
  constructionObjectId: number;
  constructionObject: ConstructionObjectModel;

  priceTypes: SwitchOption[] = [
    {
      id: 'I',
      value: 'Individuálně',
    },
    {
      id: 'B',
      value: 'Hromadně',
    },
  ];

  unitTypes: SwitchOption[] = [
    {
      id: 'S',
      value: 'Jednorázová cena podle 416/2009 Sb.',
    },
    {
      id: 'A',
      value: 'Plocha',
    },
    {
      id: 'L',
      value: 'Délka',
    },
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
  ) {
    this.constructionObjectId = (<DialogConfigData>dialogConfig.data).constructionObjectId;
    this.onUpdate = this.onUpdate.bind(this);
  }

  ngOnInit() {
    this.restangular
      .one('construction-objects', this.constructionObjectId)
      .get()
      .toPromise()
      .then((res) => {
        this.constructionObject = res.plain();

        if (!this.constructionObject.easementGlobalPriceType) {
          this.constructionObject.easementGlobalPriceType = 'B';
          this.onPriceTypeUpdate(this.constructionObject.easementGlobalPriceType);
        }
        this.loading = false;
      });
  }

  onUnitTypeUpdate(value: string) {
    if (value === 'S') {
      this.constructionObject.easementGlobalOtherSource = 'zákon 416/2009 Sb.';
      this.constructionObject.easementGlobalOtherSinglePrice = 10000;
    } else {
      this.constructionObject.easementGlobalOtherSource = undefined;
      this.constructionObject.easementGlobalOtherSinglePrice = undefined;
    }
  }

  onPriceTypeUpdate(value: string) {
    if (value !== 'I' && !this.constructionObject.easementGlobalOtherUnitType) {
      this.constructionObject.easementGlobalOtherUnitType = 'S';
      this.onUnitTypeUpdate(this.constructionObject.easementGlobalOtherUnitType);
    } else if (value === 'I') {
      this.constructionObject.easementGlobalOtherUnitType = undefined;
      this.onUnitTypeUpdate(this.constructionObject.easementGlobalOtherUnitType);
    }
  }

  /**
   * Disables the save button when price is not properly set.
   * Price can be set either individually or globally.
   * If set globally, either price based on easement area or length has to be provided.
   *
   * @returns {boolean}
   */
  isValid() {
    const isIndividualPrice = this.constructionObject.easementGlobalPriceType === 'I';
    const priceByLength = this.constructionObject.easementGlobalOtherUnitType === 'L' && this.constructionObject.easementGlobalOtherLengthPrice;
    const priceByArea = this.constructionObject.easementGlobalOtherUnitType === 'A' && this.constructionObject.easementGlobalOtherAreaPrice;
    const singlePrice = this.constructionObject.easementGlobalOtherUnitType === 'S' && this.constructionObject.easementGlobalOtherSinglePrice;
    const isBulkPrice = this.constructionObject.easementGlobalPriceType === 'B';
    return isIndividualPrice || (isBulkPrice && (priceByLength || priceByArea || singlePrice));
  }

  onUpdate() {
    return this.restangular
      .one('construction-objects', this.constructionObjectId)
      .customPUT(this.constructionObject, undefined, { loadCollections: [ 'actingPersons', 'administrator' ]})
      .toPromise()
      .then((res) => {
        this.dialogRef.close(res.plain());
      });
  }
}
