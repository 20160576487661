﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-sign-administrator',
	templateUrl: './form-case-sign-administrator.component.html',
	styleUrls: ['./form-case-sign-administrator.component.scss']
})
export class FormCaseSignAdministratorComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.signedAdministratorDate = this.data.signedAdministratorDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.signedAdministratorDate;
  }
}
