<div>
  <h1 class="text-center">Editace oprávnění uživatele</h1>
  <fieldset *ngIf="roleEnabled">
    <legend>Role</legend>
    <div class="column-100">
      <gmt-select
        [data]="this"
        field="currentRole"
        [optionItems]="roles"
        (changed)="handleRoleSelect()"
        [itemPipe]="rolePipe"
        ></gmt-select>
    </div>

    <div class="column-100" *ngIf="currentRolePermissions && currentRolePermissions.length > 0">
      <span *ngFor="let permission of currentRolePermissions" class="mt-5 mr-5 tag tag--inactive tag--not-clickable">{{permission.value}}</span>
    </div>
  </fieldset>

  <mirrored-list (changed)="handleItemSelect($event)" [label]="roleEnabled ? 'Oprávnění nad rámec role' : 'Oprávnění'" [availableItems]="permissions" [selectedItems]="currentPermissions"></mirrored-list>

  <div class="actions">
    <gmt-button [clickAction]="handleSubmit">Uložit</gmt-button>
  </div>
</div>
