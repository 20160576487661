import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapOgcCommonService {

     getFilter(filterDef, toplevel) {
        var filter = '';

        if (toplevel) {
            filter += '<Filter xmlns:gml="http://www.opengis.net/gml">';
        }

        switch (filterDef.type) {
            case 'Or':
                filter += '<Or>';
                if (filterDef.args && _.isArray(filterDef.args)) {
                    _.forEach(filterDef.args, function (value) {
                        filter += this.getFilter(value, false);
                    });
                } else {
                    filter += this.getFilter(filterDef.arg1, false);
                    filter += this.getFilter(filterDef.arg2, false);
                }
                filter += '</Or>';
                break;
            case 'And':
                filter += '<And>';
                if (filterDef.args && _.isArray(filterDef.args)) {
                    _.forEach(filterDef.args, function (value) {
                        filter += this.getFilter(value, false);
                    });
                } else {
                    filter += this.getFilter(filterDef.arg1, false);
                    filter += this.getFilter(filterDef.arg2, false);
                }
                filter += '</And>';
                break;
            case 'Not':
                filter += '<Not>';
                filter += this.getFilter(filterDef.arg, false);
                filter += '</Not>';
                break;
            case 'PropertyIsEqualTo':
            case 'PropertyIsGreaterThan':
                filter += '<' + filterDef.type + '>';
                filter += '<PropertyName>';
                filter += filterDef.propertyName;
                filter += '</PropertyName>';
                filter += '<Literal>';
                filter += filterDef.literal;
                filter += '</Literal>';
                filter += '</' + filterDef.type + '>';
                break;
            case 'PropertyIsNull':
                filter += '<PropertyIsNull>';
                filter += '<PropertyName>';
                filter += filterDef.propertyName;
                filter += '</PropertyName>';
                filter += '</PropertyIsNull>';
                break;
            case 'BBOX':
                filter += '<BBOX>';
                filter += '<PropertyName>';
                filter += filterDef.propertyName;
                filter += '</PropertyName>';
                filter += '<gml:Box><gml:coordinates>';
                filter += filterDef.coordinates;
                filter += '</gml:coordinates></gml:Box>';
                filter += '</BBOX>';
                break;
            case 'XML':
                filter += filterDef.value;
                break;
            default:
                console.log('Unsupported filter type: ' + filterDef.type);
        }

        if (toplevel) {
            filter += '</Filter>';
        }

        return filter;
    }
}
