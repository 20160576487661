import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'roadName'
})
@Injectable({
  providedIn: 'root'
})
export class RoadNamePipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(roadSection: any) {
    return roadSection.kodTrKom ? (roadSection.kodTrKom === '1' ? '' : 'I' + (roadSection.kodTrKom === '2' ? '' : 'I' + (roadSection.kodTrKom === '3' ? '' : 'I' )) + '/') + roadSection.indsil7 : null;
  }
}
