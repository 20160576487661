import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TooltipCasesComponent } from '../tooltip-cases/tooltip-cases.component';
import { CellAbstractComponent, CellTooltipMappingType } from '../cell-abstract/cell-abstract.component';

@Component({
  selector: 'cell-cases',
  templateUrl: './cell-cases.component.html',
  styleUrls: ['./cell-cases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellCasesComponent extends CellAbstractComponent {

  // bindings
  @Input() casesCount: number;

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'cases': TooltipCasesComponent
  };
}
