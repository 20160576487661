import { Component, OnInit, Inject } from '@angular/core';
import { MenuConfig } from '@app/models/menuConfig';
import { AuthService } from '@app/common/services/auth.service';
import { StatusService } from '@app/pk/services/status.service';
import { User } from '@app/models/user';
import { ModulesService } from '@app/common/services/modules.service';
import { LoginService } from '@app/common/services/login.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

@Component({
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  collapsed = false;
  menuConfig: MenuConfig[] = [];
  servicedeskUrl: string = undefined;
  user: User;
  ENV = this.config.ENV;

  constructor(
    private authService: AuthService,
    private statusService: StatusService,
    private modulesService: ModulesService,
    private loginService: LoginService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(APPLICATIONS) private applications: any,
    private localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private config,
  ) { }

  ngOnInit() {
    if (!this.authService.isAuthenticatedApplication(this.applications.settings.name)) {
      return this.loginService.redirectToLogin(true);
    }

    this.authService.setActiveApplication(this.applications.settings.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('settings');

    this.collapsed = this.localStorageService.get('menuOpened');
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.user = this.authService.getUser();
    this.menuConfig = this.getMenuConfig();
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  logout() {
    this.authService.logout();
  }

  private getMenuConfig(): MenuConfig[] {
    return [
      new MenuConfig('Moduly', 'settings.modules', 'fa-cubes'),
      new MenuConfig('Uživatelé', 'settings.users', 'fa-users'),
      new MenuConfig('Objednatelé', 'settings.customers', 'fa-handshake-o'),
    ];
  }
}
