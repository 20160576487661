import { Component, Inject, Input, OnInit } from '@angular/core';
import { WordService } from '@app/common/services/word.service';
import { DialogService } from '@app/common/services/dialog.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

const BEFORESIGNSTATMENTSIDS = [ 'Created', 'SentInvestor', 'ApprovedInvestor', 'DeclinedInvestor', 'SignedInvestor', 'SentOwner', 'ReceivedOwner', 'SignedOwner', 'DeclinedOwner', 'Cancelled' ];
const AFTERSIGNSTATMENTSIDS = [ 'SignedAllOwners', 'EntryProposal', 'CadastreEntry', 'EntryDeclined', 'PaymentOrder', 'PaymentInstruction', 'EntryStopped' ];

@Component({
  selector: '[easement-create-parcel-row]',
  templateUrl: './easement-create-parcel-row.component.html',
})
export class EasementCreateParcelRowComponent implements OnInit {

  @Input() parcel: any;
  @Input() easement: any;

  existingEasementMsg = '';
  private casesFilter: any;

  constructor(
    private restangular: Restangular,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private wordService: WordService,
    private dialogService: DialogService,
  ) {
    this.onToggleIncluded = this.onToggleIncluded.bind(this);
  }

  ngOnInit() {
    if (this.easement.existingEasementInfo) {
      const so = this.wordService.getTranslation('CONSTRUCTION_OBJECT_AKUZATIV');
      this.existingEasementMsg = `Existující VB podle GP č. ${this.easement.existingEasementInfo.gpcis}, pro ${so} ${this.easement.existingEasementInfo.constructionObjects[0].socis} ${this.easement.existingEasementInfo.constructionObjects[0].sotx}`;
    }

    // if csvParcelIntersection && no warnings, then include
    const gpcis = this.easement.symapParcelIntersection.gpcis;
    const initWarnings = EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, gpcis, this.APP_BRAND.PS.TITLE);
    const integrityWarnings = EasementCreateParcelRowComponent.getIntegrityWarnings(initWarnings);
    const csvFilled = this.easement.csvParcelIntersection && this.easement.csvParcelIntersection.length && this.easement.csvParcelIntersection.area;

    // try automatically include parcel if there are no warnings and CSV is filled
    if (csvFilled && integrityWarnings) {
      this.easement.include = true;
      const includedWarnings = EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, gpcis, this.APP_BRAND.PS.TITLE);
      const includedIntegrityWarnings = EasementCreateParcelRowComponent.getIntegrityWarnings(includedWarnings);

      // there are cases when set include to "true" can broke integrity then we must reser include to false
      if (includedIntegrityWarnings) {
        this.easement.include = false;
      }
    }

    this.casesFilter = {
      filter: {
        filters: {
          cancelStatus: { values: [ 'notCancelled' ]},
          easementId: { values: [ this.easement.importParcel.id ]},
        },
        limit: 20,
        offset: 0,
        sortOrder: [{ sortBy: 'id', direction: 'asc' }]
      }
    };
  }

  onToggleIncluded() {
    // if editable means easement is not in symap
    if (!this.easement.editable && this.easement.include) {
      this.confirmChange().then((confirmed) => {
        if (confirmed) {
          this.toggleIncluded();
        }
      });
    } else {
      this.toggleIncluded();
    }
  }

  getEasementWarnings() {
    this.easement.warnings = (this.easement.include
      ? EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, this.easement.symapParcelIntersection.gpcis, this.APP_BRAND.PS.TITLE)
      : {}
    );

    return Object.keys(this.easement.warnings).length;
  }

  private confirmChange() {
    const parcelNumber = this.parcel.parcelName;
    if (!this.easement.countCases) {
      const gpcis = this.easement.existingEasementInfo.gpcis;
      const message = `Na parcele ${parcelNumber} již existuje VB podle GP ${gpcis}. Chcete toto VB smazat a použít pouze nové?`;
      return this.showConfirmChangeModal(message);
    }

    return this.restangular.one('cases').customPOST(this.casesFilter).toPromise().then((data) => {
      // chceck status ID
      const easementStateID = data[0].caseStatus.key;
      if (BEFORESIGNSTATMENTSIDS.includes(easementStateID)) {
        const easementNumber = data[0].mpp.number ? `č.${data[0].mpp.number}` : 'bez čísla';
        const easementState = data[0].caseStatus.commonName;
        const message = `Skutečně chcete smazat stávající VB na parcele ${parcelNumber} na které je navázán případ ${easementNumber}, ve stavu "${easementState}"?`;
        return this.showConfirmChangeModal(message);
      }

      if (AFTERSIGNSTATMENTSIDS.includes(easementStateID)) {
        const soNom = this.wordService.getTranslation('CONSTRUCTION_OBJECT_PLURAL_NOMINATIV');
        const message = `Původní smlouva je podepsána všemi stranami, nelze ji zrušit. Na parcele bude více ploch VB v tomto ${soNom}. Budou řešené různými případy.`;
        return this.showAlertChangeModal(message);
      }
    });
  }

  private showConfirmChangeModal(message): Promise<any> {
    return this.dialogService.confirmDialogPromise(message);
  }

  private showAlertChangeModal(message): Promise<any> {
    return this.dialogService.alertDialogPromise(message);
  }

  private toggleIncluded () {
    const gpcis = this.easement.existingEasementInfo && this.easement.existingEasementInfo.gpcis ?
      this.easement.existingEasementInfo.gpcis : this.easement.symapParcelIntersection.gpcis;
    if (this.easement.include) {
      this.easement.include = false;
      this.parcel.includedGPcis.splice(this.parcel.includedGPcis.indexOf(gpcis), 1);
    } else {
      this.easement.include = true;
      this.parcel.includedGPcis.push(gpcis);
    }
  }

  private static getWarnings(parcelValues, includedGPcis, actualGPcis, appName) {
    const warnings: any = {};

    const NULLAREALENGTHMSG = `Tato parcela je obsahem VFK, pro její import do ${appName} je potřeba doplnit údaj výměry a délky VB.`;

    if (!parcelValues.hasOwnProperty('length') || parcelValues.length === '') {
      warnings.nullAreaLength = NULLAREALENGTHMSG;
    } else {
      if ((parcelValues.length || parcelValues.length === '0') && isNaN(EasementCreateParcelRowComponent.getNumberFromString(parcelValues.length))) {
        warnings.nullLength = 'Hodnota délky není číslo.';
      }
    }

    // validate area user input
    if (parcelValues.area === '') {
      warnings.nullAreaLength = NULLAREALENGTHMSG;
    } else {
      if ((parcelValues.area || parcelValues.area === '0') && isNaN(EasementCreateParcelRowComponent.getNumberFromString(parcelValues.area))) {
        warnings.nullArea = 'Hodnota plochy není číslo.';
      }
    }

    // check if parcel contain more than one GP with equal name
    const sameGPCIS = includedGPcis.filter((gpcis) => gpcis === actualGPcis);
    if (sameGPCIS.length > 1) {
      warnings.moreGPcis = `Na této parcele již existuje VB podle GP ${actualGPcis}. Odstraňte jedno z VB z tohoto GP.`;
    }
    return warnings;
  }

  private static getIntegrityWarnings(warnings) {
    return Object.keys(warnings).length === 0 || Object.keys(warnings).length === 1 && Object.keys(warnings)[0] === 'moreGPcis';
  }

  private static getNumberFromString(strNumber) {
    return isNaN(strNumber) ? Number(strNumber.replace(',', '.')) : strNumber;
  }
}
