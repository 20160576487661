﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-entry-stopped',
	templateUrl: './form-case-entry-stopped.component.html',
})
export class FormCaseEntryStoppedComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.cadastreEntryStoppedDate = this.data.cadastreEntryStoppedDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.cadastreEntryStoppedDate;
  }
}
