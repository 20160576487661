import { EventEmitter, Input, Output } from '@angular/core';
import { SwitchOption } from '@app/common/components/switch/switch.component';

export abstract class BulkPriceStepComponent {
  @Input() data: any;
  @Output() backCallback = new EventEmitter();
  @Output() submitCallback = new EventEmitter();

  priceType: SwitchOption[] = [
    {
      value: 'Cenový výměr ministerstva financí',
      id: 'F',
    },
    {
      value: 'Znalecký posudek',
      id: 'E',
    },
    {
      value: 'Jiný způsob',
      id: 'O',
    },
  ];

  priceUnit: SwitchOption[] = [
    {
      value: 'Roční cena za metr čtvereční',
      id: 'A',
    },
    {
      value: 'Jednorázová cena',
      id: 'T',
    },
  ];
}
