import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'update-case-ownership',
  templateUrl: './update-case-ownership.component.html',
  styleUrls: ['./update-case-ownership.component.scss']
})
export class UpdateCaseOwnershipComponent implements OnInit {

  @Input() caseOwnershipId: number;
  @Input() caseId: number;

  loading = true;
  displayFields: any = {};
  caseOwnership: any = {};
  displayFieldsLength;
  caseSubjects;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private listService: ListService,
    private authService: AuthService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.update = this.update.bind(this);
    this.updateCaseOwnership = this.updateCaseOwnership.bind(this);
    this.updateCaseSubjects = this.updateCaseSubjects.bind(this);
    this.toggleSinglePayment = this.toggleSinglePayment.bind(this);
  }

  async ngOnInit() {

    const list = this.listService.createList('case-subjects', {
      limit: 2,
      filters: {
        caseOwnershipId: this.caseOwnershipId,
        opsubType: 'OFO',
      }
    });

    Promise.all([
      this.restangular.one('case-ownerships', this.caseOwnershipId).get({loadCollections: 'businessCase'}).toPromise(),
      this.listService.fetchResult(list)
    ]).then((data) => {
      this.caseOwnership = data[0];

      if (this.authService.getActualProject().easementPriceByShare) {
        if (
          this.caseOwnership.businessCase.obligation.type === 'RealBurdenLiability'
        ) {
          this.displayFields.easementFutureContractNumber = true;
          this.displayFields.easementFutureContractStatus = true;
        }

        if (this.authService.hasPermission('admin,update_case_price')) {
          this.displayFields.easementPriceByShare = true;
        }
      }
      this.displayFieldsLength = Object.keys(this.displayFields).length;


      this.caseSubjects = list.list;
      this.loading = false;
    });
  }

  update() {
    // save and return data to detail
    return this.restangular.one('cases', this.caseOwnership.businessCase.id).all('ownerships-subjects').customPUT({
      caseOwnerships: [this.caseOwnership.plain()],
      caseSubjects: this.caseSubjects,
    }).toPromise().then((data) => {
      this.stateService.go('^');
    });
  }

  updateCaseOwnership() {
    return this.caseOwnership.put().toPromise();
  }

  updateCaseSubjects() {
    return this.restangular.all('case-subjects').customPUT(this.caseSubjects).toPromise();
  }

  toggleSinglePayment() {
    this.caseOwnership.singlePayment = !this.caseOwnership.singlePayment;
    if (this.caseOwnership.singlePayment) {
      this.caseSubjects.forEach(this.clearPayment);
    } else {
      this.clearPayment(this.caseOwnership);
    }
  }

  clearPayment(object) {
    object.paymentType = undefined;
    object.accountNumber = null;
    object.bankCode = null;
    object.variableSymbol = null;
    object.specificSymbol = null;
    object.constantSymbol = null;
  }
}
