import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { OccupationModel } from '@app/ps/models/occupation.model';

/**
 * Display name of occupation
 */
@Pipe({
  name: 'occupationName',
})
@Injectable({
  providedIn: 'root'
})
export class OccupationNamePipe implements PipeTransform {

  transform(occupation: OccupationModel) {
    let detailString = '';
    if (occupation.parcel && ((occupation.parcel.title && occupation.parcel.title.lv) || (occupation.parcel.area && occupation.parcel.area.name))) {
      const detail = [];
      if (occupation.parcel.title && occupation.parcel.title.lv) {
        detail.push('LV' + occupation.parcel.title.lv)
      }
      if (occupation.parcel.area && occupation.parcel.area.name) {
        detail.push('KÚ ' + occupation.parcel.area.name)
      }
      detailString = ' (' + detail.join(' - ') + ')';
    }
    return occupation.zabcis + detailString;
  }
}
