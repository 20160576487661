<div [ngClass]="{'selector-collapsed': getCollapsed()}" id="map-content">
  <div class="map-layer-selector">
    <div id="selector-heading">
      <a class="toggle-layer-selector button" (click)="toggleLayerSelector()" alt="Zmenšit výběr vrstev">
        <i [ngClass]="['fa', getCollapsed() ? 'fa-angle-double-right' : 'fa-angle-double-left']"></i>
      </a>
      <h1 id="ls-header">Mapa</h1>
      <div class="cb"></div>
    </div>

    <perfect-scrollbar id="map-layer-selector">
      <map-search [mapId]="mapOptions.id" [searchResources]="searchResources"></map-search>
      <div [loading]="selectorConfig === null" id="ls-selector" [ngClass]="{'small-spinner': getCollapsed()}">
        <map-layer-selector *ngIf="selectorConfig" [mapId]="selectorConfig.id" [options]="selectorOptions" [config]="selectorConfig"></map-layer-selector>
      </div>
    </perfect-scrollbar>
  </div>

  <div id="map-window" class="scrollable">
    <div *ngIf="mapOptions && selectorConfig" leafletMap [options]="mapOptions" [config]="[]" style="height: 100%; width: 100%; display: block; z-index:10;"> </div>
    <map-tool-box *ngIf="!mapConfigLoading && selectorConfig" [tools]="selectorConfig.tools" [config]="selectorConfig" [mapId]="mapOptions.id">
    </map-tool-box>
  </div>

  <map-layer-selector-tooltip id="layer-tooltip"></map-layer-selector-tooltip>
  <help [helpId]="helpIds.VM_PANEL_MAP" class="cm-help-page"></help>
</div>
