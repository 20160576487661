import { Component, Inject, OnInit } from '@angular/core';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { SeriesService } from '@app/ps/services/series.service';
import * as moment from 'moment';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { AdministratorEditComponent } from '@app/ps/components/administrator-edit/administrator-edit.component';
import { DialogService } from '@app/common/services/dialog.service';
import { WordService } from '@app/common/services/word.service';
import { DatePipe } from '@angular/common';
import { createConstructionObject } from '@app/ps/models/construction-object-imported.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { SeriesModel } from '@app/ps/models/series.model';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import {FileUtils} from "@app/common/utils/file.utils";

@Component({
  selector: 'construction-object-create',
  templateUrl: './construction-object-create.component.html',
  styleUrls: []
})
export class ConstructionObjectCreateComponent implements OnInit {

  private static readonly MSG_EMPTY = `Vložte soubory přetažením nebo výběrem. Je možné vložit více souborů VFK`;
  private static readonly MSG_VFK_FILES_EXTENSION = 'Soubory musí být ve formátu VFK.';

  constructionObject = createConstructionObject();
  series: any;
  actingPersons: any;
  administrator: ProjectSubjectModel;
  cadastreDeliveryDate: Date;
  reloadAdministrators: Function;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  token = this.authService.getToken();
  seriesParsed: SeriesModel[] = [];

  files: UploadFileExtended[];
  filesValid = true;
  message = ConstructionObjectCreateComponent.MSG_EMPTY;

  cadastreDeliveryMonthPickerOptions = {
    minDate: new Date(),
    maxDate: moment().add(1, 'month').toDate(),
    defaultDate: new Date()
  };

  administratorTypes: SwitchOption[] = [
    { id: null, value: 'Neurčen' },
    { id: 'I', value: 'Investor je správcem' },
    { id: 'A', value: 'Jiný subjekt' },
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    public projectSubjectNamePipe: ProjectSubjectNamePipe,
    private authService: AuthService,
    private stateService: StateService,
    private seriesService: SeriesService,
    private dialogService: DialogService,
    private wordService: WordService,
    private datePipe: DatePipe,
  ) {
    this.onUpload = this.onUpload.bind(this);
  }

  ngOnInit() {
    this.actingPersons = new ChecklistModel();

    this.restangular.one('construction-objects', 'nextid').get().toPromise().then(data => {
      if (!this.constructionObject.usekEtapa) {
        this.constructionObject.usekEtapa = data.nextId;
      }
    });
  }

  onFilesChange(files) {
    this.files = files;

    const extensionValid = this.files.every(f => f.extension && f.extension.toLowerCase() === 'vfk');

    if (this.files.length === 0) {
      this.message = ConstructionObjectCreateComponent.MSG_EMPTY;
    } else if (!extensionValid) {
      this.message = ConstructionObjectCreateComponent.MSG_VFK_FILES_EXTENSION;
    } else {
      this.message = '';
    }

    this.filesValid = (extensionValid && this.files.length > 0);
  }

  reloadAdministratorRegister(reloadFn: Function) {
    this.reloadAdministrators = reloadFn;
  }

  onChangeAdministrator() {
    this.constructionObject.administratorId = this.administrator ? this.administrator.id : undefined;
  }

  onChangeAdministratorType(type: SwitchOption) {
    this.constructionObject.administratorType = type.id as string;
    this.administrator = undefined;
    this.actingPersons.empty();
  }

  formValid() {
    return this.filesValid
      && this.constructionObject.name
      && this.constructionObject.cislo
      && this.constructionObject.usekEtapa
      && (this.constructionObject.administratorType  !== 'A' || (this.constructionObject.administratorType === 'A' && this.constructionObject.administratorId));
  }

  onCreateAdministrator() {
    const dialog = this.dialogService.open(AdministratorEditComponent, { data: {} });
    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        this.administrator = res;
        this.reloadAdministrators();
      }
      sub.unsubscribe();
    });
  }

  onUpdateAdministrator() {
    const dialog = this.dialogService.open(AdministratorEditComponent, {
      data: { administratorId: this.administrator.id }
    });

    const sub = dialog.afterClosed.subscribe((res: ProjectSubjectModel) => {
      if (res) {
        this.administrator = res;
      }
      sub.unsubscribe();
    });
  }

  onCadastreDeliveryDateChange(date) {
    this.cadastreDeliveryDate = date;
  }

  onUpload() {
    return this.restangular.all('construction-objects').customPOST({ filter: { filters: { number: [ this.constructionObject.cislo ]}}})
      .toPromise()
      .then(list => {

        if (!list.length) {
          return this.uploadFormData();
        }

        const w1 = this.wordService.getTranslation('CONSTRUCTION_OBJECT');
        const w2 = this.wordService.getTranslation('CONSTRUCTION_OBJECT_OTHER');

        return this.dialogService.confirmDialogPromise(`Skutečně chcete založit ${w1} s číslem ${this.constructionObject.cislo}, číslo je již použité u ${w2}: ${list[0].sotx}`)
          .then(confirmed => {
            if (confirmed) {
              return this.uploadFormData();
            }
          });
      });
  }

  uploadFormData() {
    this.constructionObject.validityDate = this.datePipe.transform(this.cadastreDeliveryDate, 'yyyy-MM-dd');

    if (this.seriesParsed) {
      this.seriesService.sectionsToSeries(this.seriesParsed, this.constructionObject.series);
    }

    const coFile = FileUtils.jsonBlob(this.constructionObject);
    const formData = new FormData();

    formData.append('CONSTRUCTIONOBJECT', coFile, 'co.json');
    for (const f of this.files) {
      formData.append('VFK', f.fileEntry);
    }

    return this.uploadService.upload({
      url: `${this.restBaseUrl}/construction-objects/create?type=VFK`,
      formData: formData,
      arrayKey: '', // TODO cli - k cemu to je
      headers: { Authorization: this.token },
    }).then((data) => {
      if (data && data.requestId) {
        this.stateService.go('symap.project.constructionObjects.constructionObjectsImported');
      } else {
        return Promise.reject();
      }
    });
  }
}
