﻿import { Component, Inject, Input, OnDestroy } from "@angular/core";
import { AuthService } from '../../../common/services/auth.service';
import { BulkOperationProcessDefinitionModel } from '@app/ps/models/bulk-operation-process-definition.model';
import { SettingsService } from '@app/ps/services/settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './cases-change-status-confirm.component.html',
  styleUrls: ['./cases-change-status-confirm.component.scss']
})
export class CasesChangeStatusConfirmComponent implements OnDestroy {
  @Input()
  data: any;
  processed: number = 0;
  saving: boolean = false;
  interrupt: boolean = false;

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private restangular: Restangular,
    @Inject('processDefinition') private processDefinition: BulkOperationProcessDefinitionModel,
  ) {
    this.proceed = this.proceed.bind(this);
  }

  ngOnDestroy() {
    this.interrupt = true;
  }

  async proceed() {
    this.saving = true;
    for (const [i, item] of this.data.details.group.cases.entries()) {
      if (this.interrupt) {
        return false;
      }

      await this.save(item.id, Object.assign(this.data.details.cases[i] || {}, this.data.details.case));
      this.processed++;
    };
    // Resolves possible issues when status changes and case is not valid for filter anymore
    this.data.selectEntities.filters = this.processDefinition.getDefaultFilters(this.settingsService);
    if (this.data.selectEntities.inverseSelection) {
      this.data.selectEntities.inverseSelection = false;
      this.data.selectEntities.checkedItems = this.data.details.allCases;
    }
    this.processDefinition.setEntityIds(this.data.details.allCases.map(bCase => bCase.id));
  }

  async save(caseId: number, caseData: any) {
    return this.restangular
      .one('cases', caseId)
      .customPUT(caseData, this.data.details.group.saveEndpoint)
      .toPromise();
  };
}
