<div class="jumbotron">
  <div class="centered-text" *ngIf="!sending">
    Pro <b>{{data.easementsSelected.length}}</b> {{data.easementsSelected.length === 1 ? 'věcné břemeno' : (data.easementsSelected.length < 5 ? 'věcná břemena' : 'věcných břemen')}} na
    <b>{{parcelCount}}</b> {{parcelCount === 1 ? 'parcele' : 'parcelách'}} z
    <b>{{data.geometricPlansSelected.length}}</b> {{data.geometricPlansSelected.length === 1 ? 'geometrického plánu' : 'geometrických plánů'}} {{data.cases.length === 1 ? 'bude založen' : (data.cases.length <= 5 ? 'budou založeny' : 'bude založeno')}}
    <b>{{data.cases.length}}</b> {{data.cases.length === 1 ? 'případ' : (data.cases.length <= 5 ? 'případy' : 'případů')}} typu <b>{{data.obligation.name}}</b>
    <span *ngIf="data.obligation.computePrice">v celkové výši <b>{{priceTotal|number|price}}</b></span>.
  </div>

  <div class="centered-text" *ngIf="sending">
    <b>Zakládá se případ pro LV {{data.cases[data.cases.length - count].titles[0] | titleName}} ({{data.cases.length - count + 1}}/{{data.cases.length}})</b>
    <div [loading]="true"></div>
  </div>
</div>
<div class="mt-10">
  <gmt-button [clickAction]="create">Založit {{data.cases.length === 1 ? 'případ' : 'případy'}}</gmt-button>
</div>
