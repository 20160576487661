import { Component, Inject, OnInit } from '@angular/core';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { ExpertModel } from '@app/ps/models/expert.model';
import { ExpertEditFormComponent } from '@app/ps/components/expert-edit-form/expert-edit-form.component';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expert-list',
  templateUrl: './expert-list.component.html',
})
export class ExpertListComponent implements OnInit {

  list: ListModel<ExpertModel>;
  pageableList: PageableList;

  private defaultFilter = {
    filters: {
      searchText: { values: [{ id: '' }]},
    }
  };
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
  ) {
    this.onCreate = this.onCreate.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'experts',
      this.defaultFilter,
      'expertsFilter'
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  onCreate() {
    const dialog = this.dialogService.open(ExpertEditFormComponent, { data: {} });
    const sub = dialog.afterClosed.subscribe((res: ExpertModel) => {
      if (res) {
        this.pageableList.fetchResults();
      }
      sub.unsubscribe();
    });
  }
}
