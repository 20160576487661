<h1>Podmínky užití</h1>
<p class="modal-description">
  Prosíme o přečtení a potvrzení souhlasu s aktuálně platnými podmínkami užití aplikace.
  <br/>
  <br/>
</p>
<div [loading]="conditionLoading">
  <div *ngIf="!conditionLoading">
    <textarea rows="120" [(ngModel)]="conditions" name="'string'" class="tall" disabled="disabled"></textarea>
    <div class="actions">
      <gmt-button [clickAction]="onSave">Rozumím a souhlasím</gmt-button>
    </div>
  </div>
</div>
