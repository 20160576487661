import { Injectable } from '@angular/core';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import {GmtHttpError} from "@app/common/services/http.service";

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {

  constructor(
    private dialogService: DialogService
  ) {
    this.get = this.get.bind(this);
  }

  get(result: GmtHttpError) {
    const msg = result.status === 400 && result.data.code === 0
      ? result.data.message
      : (result.status === 403 ? 'Akce není povolena' : 'Vyskytla se neočekávaná chyba');

    this.dialogService.open(AlertComponent, {
      className: ClassName.ADJUSTED_DIALOG,
      data: {
        msg,
      },
    });
  }

}
