import { Component, Inject, OnInit } from '@angular/core';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { AreaModel } from '@app/common/models/area.model';
import { TitleModel } from '@app/common/models/title.model';
import { User } from '@app/models/user';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { RESTANGULAR_PROMAP_PROXY } from '@app/common/services/restangular-promap-proxy.service';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { SelectItem } from '@app/common/components/select/select.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { KnWsdpStatusModalComponent } from '@app/ps/project-settings/components/kn-wsdp-status-modal/kn-wsdp-status-modal.component';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';
import * as _ from "lodash";

@Component({
  selector: 'kn-wsdp-update',
  templateUrl: './kn-wsdp-update.component.html',
  styleUrls: ['./kn-wsdp-update.component.scss'],
})
export class KnWsdpUpdateComponent implements OnInit {

  project = this.authService.getActualProject();
  area: AreaModel;
  titles: TitleModel[] = [];
  gpTitles: SelectItem[] = [];
  newTitles = '';
  ticketNumber = 'INC-';
  user: User;
  geometricPlanRequest: GeometricPlanRequestModel;
  list: ListModel<any>;
  pageableList: PageableList;
  requestType: 'GP' | 'LV' = 'LV';
  gpTitleOptions: SelectItem[] = [];
  requestTypes: SwitchOption[] = [
    {
      id: 'LV',
      value: 'LV',
    },
    {
      id: 'GP',
      value: 'GP',
    },
  ];

  titleFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  geometricPlanRequestFilter = {
    areaId: { values: [] },
    loadCollections: { values: ['parcels'] }
  };

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(RESTANGULAR_PROMAP_PROXY) public restangularPromapProxy: any,
    private restangular: Restangular,
    private pageableListService: PageableListService,
    public titleNamePipe: TitleNamePipe,
    public userNamePipe: UserNamePipe,
    private authService: AuthService,
    private dialogService: DialogService
  ) {

    this.onCadastreChanged = this.onCadastreChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit(): void {
    this.initializeList();
  }

  onSwitchType() {
    this.clearContent();
  }

  onCadastreChanged() {
    this.titleFilter.areaId.values = [this.area.id];
    this.geometricPlanRequestFilter.areaId.values = [this.area.id];
    this.clearContent();
  }

  onGpChange(gp: any) {
    const titles = gp.parcels.map(p => { return { id: p.lv, name: p.lv + (p.area && p.area.name ? ' (' + p.area.name + ')' : '') }; });
    this.gpTitleOptions = _.uniqBy(titles, (t) => t.id);
    this.gpTitles = [];
  }

  isLvValid() {
    const newTitlesValid = this.newTitles && this.newTitles.split(',').every(t => {
      const candidate = t.trim();
      return parseInt(candidate) + '' === candidate;
    });
    const ticketNumberValid = this.ticketNumber && this.ticketNumber.replace('INC-', '').length > 0;
    return this.area && (newTitlesValid || this.titles.length || this.gpTitles.length) && ticketNumberValid && this.user;
  }

  onSubmit(): Promise<any> {
    let titles = [];

    if (this.titles) {
      titles = titles.concat(this.titles.map(t => t.lv));
    }

    if (this.newTitles) {
      titles = titles.concat(this.newTitles.split(',')
        .filter(t => {
          const candidate = t.trim();
          return parseInt(candidate) + '' === candidate;
        })
        .map(t => parseInt(t.trim())));
    }

    if (this.gpTitles) {
      titles = titles.concat(this.gpTitles.map(p => p.id));
    }

    const request = {
      type: this.requestType,
      projectKey: this.project.key,
      isprofond: this.project.isprofond,
      ticketNumber: this.ticketNumber,
      areaCode: this.area.id,
      areaName: this.area.name,
      lvs: titles.map(lv => { return { lv: lv, areaCode: this.area.id }; }),
      userIdText: this.user.idText,
      userFullName: this.user.fullName,
    };

    return this.restangularPromapProxy.all('wsdp/requests/create').customPOST(request)
      .toPromise()
      .then(() => {
        this.area = undefined;
        this.user = undefined;
        this.ticketNumber = 'INC-';
        this.clearContent();
        this.pageableList.load();
      });
  }

  pageChanged(pageNumber: number) {
    this.pageableList.fetchResults(pageNumber);
    this.pageableList.onListChanged();
  }

  formatTitles(lvs: any[]) {
    return lvs.map(lv => lv.lv).join(', ');
  }

  translateStatus(status: string) {
    const t = {
      CREATED: 'Vytvořený požadavek',
      LOADING_WSDP: 'Získávání dat WSDP',
      VALIDATION: 'Validace',
      IMPORT: 'Probíhá import dat do MPV',
      DONE: 'Požadavek byl zpracován',
      FAILED: 'Požadavek skončil s chybou',
    };
    return t[status] ? t[status] : status;
  }

  showStatusDescription(request: any) {
    const dialog = this.dialogService.open(KnWsdpStatusModalComponent, {
      data: {
        request: request,
      },
      className: ClassName.HIGHER_DIALOG,
    });
    //
    // const sub = dialog.afterClosed.subscribe(async (text: string) => {
    //
    //   sub.unsubscribe();
    // });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'wsdp/requests',
      {
        filters: {
          projectKey: { values: [this.project.key] }
        }
      },
      undefined,
      this.restangularPromapProxy
    );
    this.list = this.pageableList.list;

    setInterval(() => {
      this.pageableList.fetchResults();
    }, 20000);

    return this.pageableList.load();
  }

  private clearContent() {
    this.titles = [];
    this.newTitles = '';
    this.geometricPlanRequest = undefined;
    this.gpTitles = [];
    this.gpTitleOptions = [];
  }
}
