import { Injectable } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsService {

  constructor(
    private authService: AuthService
  ) {
  }

  onUserCome() {
    (window as any).GoogleAnalytics('set', 'userId', this.authService.getUser().id);
    (window as any).GoogleAnalytics('set', 'dimension1', (this.authService.getUser().company ? this.authService.getUser().company.companyName : null) || 'unknown');
    (window as any).GoogleAnalytics('set', 'dimension2', this.authService.getActualProject() ? this.authService.getActualProject().name : '' || this.authService.getActiveApplication());
    (window as any).GoogleAnalytics('set', 'dimension3', this.authService.getActiveApplication());
  }
}
