import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { StateService } from '@uirouter/angular';
import { TableService } from '@app/ps/services/table.service';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { APP_BRAND } from '@app/common/services/config.service';


@Component({
  selector: 'owner-list',
  templateUrl: './owner-list.component.html',
  styleUrls: ['./owner-list.component.scss']
})
export class OwnerListComponent implements OnInit {
  list: any;
  pageableList: PageableList;
  opsubTypes: any[];
  opsubTypeFilterChecklist: any;
  bsmOpsubType = {id: 'BSM'};
  private readonly INVALID_WITH_CASE_FLAG = 'invalidWithCase';
  private readonly WITH_OCCUPATION_OR_EASEMENT_FLAG = 'withOccupationOrEasement';
  private readonly WITH_OWNERSHIP_FLAG = 'withOwnership';
  private readonly VALID_FLAG = 'valid';
  deferredUpdateStatistics: any;
  settingsLoaded = false;
  exportEnable = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private pageableListService: PageableListService,
    private tableService: TableService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private exportListService: ExportListService
  ) {
    this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }
    const promise = new Promise((resolve, reject) => {
      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.deferredUpdateStatistics.promise = promise;
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  registerUpdateStatisticsEntityFilterKey(registerCallback) {
    this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('cases');
    return this.exportListService
      .exportList('subjects', 'owners', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      this.list.filter.filters.validity.values = [this.VALID_FLAG, this.INVALID_WITH_CASE_FLAG];
      this.opsubTypes = [];
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    } else {
      if (this.list.filter.filters.statisticsEntityFilterKey &&
        this.list.filter.filters.statisticsEntityFilterKey.values &&
        this.list.filter.filters.statisticsEntityFilterKey.values.length
      ) {
        this.list.filter.filters.validity.values = [this.VALID_FLAG, this.INVALID_WITH_CASE_FLAG];
      }
    }
  }

  statisticsEntityFilterChanged(changed) {
    if (!changed.state) {
      this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
    }
  }

  onOpsubTypeFilterChanged(data) {
    if (data.newValue.length === 0) {
      this.opsubTypeFilterChecklist.empty();
    } else {
      this.opsubTypeFilterChecklist.toggleSelection(data.oldValue);
    }
    if (data.newValue.length === 0 || (data.newValue.length === 1 && this.opsubTypeFilterChecklist.isChecked(this.bsmOpsubType))) {
      this.opsubTypeFilterChecklist.toggleSelection(this.bsmOpsubType);
      this.list.filter.filters.opsubType.negation = data.newValue.length === 0;
    }
  }

  onShowFormerOwnersToggle() {
    if (!this.includesFormerOwners()) {
      this.pageableList.list.filter.filters.validity.values.push(this.INVALID_WITH_CASE_FLAG);
    } else {
      this.pageableList.list.filter.filters.validity.values.splice(this.getFormerOwnersIndex(), 1);
    }
  }

  includesFormerOwners(): boolean {
    return this.getFormerOwnersIndex() > -1;
  }


  includesNeighbourOwners() {
    return this.getNeighbourOwnersIndex() > -1;
  }

  onShowNeighbourOwnersToggle() {
    if (!this.includesNeighbourOwners()) {
      this.pageableList.list.filter.filters.validity.values.push(this.WITH_OWNERSHIP_FLAG);
    } else {
      this.pageableList.list.filter.filters.validity.values.splice(this.getNeighbourOwnersIndex(), 1);
    }
  }

  private getDefaultFilter() {
    return {
      filters: {
        loadCollections: ['ownerships', 'occupationResolution'],
        searchText: {values: [{id: ''}]},
        validity: {values: [this.WITH_OCCUPATION_OR_EASEMENT_FLAG, this.WITH_OWNERSHIP_FLAG]},
        caseStatusId: { values: [], negation: false },
        obligationId: { values: [], negation: false },
        areaId: { values: [], negation: false },
        opsubType: {values: [this.bsmOpsubType], negation: true},
        problematic: {values: []},
      }
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get('subjects', this.getDefaultFilter(), 'ownersFilter', undefined, {globalSubjectDetail: {}});
    this.list = this.pageableList.list;
    this.opsubTypes = [...this.list.filter.filters.opsubType.values.filter(opsubType => opsubType.id !== 'BSM')];
    this.opsubTypeFilterChecklist = new ChecklistModel(this.list.filter.filters.opsubType.values);
    await this.initStatisticsEntityFilter();
    this.pageableList.load();
  }

  private getFormerOwnersIndex(): number {
    return this.pageableList.list.filter.filters.validity.values.findIndex(item => (item.id || item) === this.INVALID_WITH_CASE_FLAG);
  }

  private getNeighbourOwnersIndex(): number {
    return this.pageableList.list.filter.filters.validity.values.findIndex(item => (item.id || item) === this.WITH_OWNERSHIP_FLAG);
  }
}
