<div class="detail">
  <div>
    <a uiSref="symap.project.projectSettings" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
  </div>

  <h1>Založení nového projektu</h1>

  <fieldset>
    <div class="column-100">
      <label class="required" style="width: 250px;">Název projektu</label>
      <div class="input">
        <input type="text" [(ngModel)]="data.name"/>
      </div>
    </div>
    <div class="column-100">
      <label class="required" style="width: 250px;">Identifikátor projektu (bez prefixu sy_)</label>
      <div class="input">
        <input type="text" [(ngModel)]="data.key" (ngModelChange)="removePrefix()" />
      </div>
    </div>
    <div class="column-100">
      <label style="width: 250px;">Nastavit podle projektu</label>
      <div class="input">
        <gmt-select (changed)="onTemplateProjectChanged($event.newValue)" [optionItems]="projects"></gmt-select>
      </div>
    </div>
    <div class="column-100 flexbox-container" *ngIf="data.templateProjectKey">
      <label style="width: 250px;">Kopírovaná nastavení</label>
      <div class="input flex-rest">
        <gmt-switch [options]="templateSettingsBasic" (select)="onChangeTemplateSettings($event.id)" [(selectedId)]="selectedTemplateSettingsBasic"></gmt-switch>
        <div class="column-100 checklist cb mt-5" *ngIf="data.templateProjectSettings !== undefined">
          <div *ngFor="let item of templateSettings" class="checklist-item" (click)="onToggleSettings(item.id)">
            <gmt-checkbox gmtType="multiselect" [gmtChecked]="isCheckedSettings(item.id)"></gmt-checkbox>
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <div class="centered-text">
    <gmt-button [gmtDisabled]="isDisabled()" [clickAction]="update">Uložit</gmt-button>
  </div>
</div>
