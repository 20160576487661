import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'form-case-decline-owner',
  templateUrl: './form-case-decline-owner.component.html',
})
export class FormCaseDeclineOwnerComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  // data shared for each case
  public data: any;

  constructor(
  ) {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.declinedOwnerDate = new Date();
    this.data.note = {};

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.declinedOwnerDate && !!this.data.note.text && !!this.data.note.noteType;
  }
}
