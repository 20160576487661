<h1>Editace ceny pozemku</h1>

<div [loading]="caseOwnershipParcelList.loading">

  <div *ngIf="!caseOwnershipParcelList.loading">
    <fieldset *ngFor="let caseOwnershipParcel of caseOwnershipParcelList.list" class="subject">
      <legend>{{ caseOwnershipParcel.caseOwnership.ownership|ownershipName }} -
        <span *ngIf="caseOwnershipParcel.caseOwnership.ownership.isBsm">Osoba SJM</span>
        <span *ngIf="caseOwnershipParcel.caseOwnership.ownership.subjects[0].opsubType === 'OPO'">Společnost</span>
        <span *ngIf="!caseOwnershipParcel.caseOwnership.ownership.isBsm && caseOwnershipParcel.caseOwnership.ownership.subjects[0].opsubType === 'OFO'">Fyzická osoba</span>
      </legend>
      <div class="column-100">
        <div *ngIf="caseOwnershipParcel.landPrice !== undefined">
          <label>Cena za pozemek</label>
          <div class="input"><input type="text" [(ngModel)]="caseOwnershipParcel.landPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="caseOwnershipParcel.compensationPrice !== undefined">
          <label>Cena za náhrady</label>
          <div class="input"><input type="text" [(ngModel)]="caseOwnershipParcel.compensationPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="caseOwnershipParcel.vegetationPrice !== undefined">
          <label>Cena za porost<br/>(vedlejší úpravy)</label>
          <div class="input"><input type="text" [(ngModel)]="caseOwnershipParcel.vegetationPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="caseOwnershipParcel.buildingsPrice !== undefined">
          <label>Cena za stavbu</label>
          <div class="input"><input type="text" [(ngModel)]="caseOwnershipParcel.buildingsPrice" gmtInputNumber/></div>
        </div>
        <div *ngIf="caseOwnershipParcel.easementPrice !== undefined">
          <label>Cena služebnosti</label>
          <div class="input"><input type="text" [(ngModel)]="caseOwnershipParcel.easementPrice" gmtInputNumber/></div>
        </div>
      </div>
    </fieldset>

    <div class="actions">
      <gmt-button [clickAction]="updatePrice">Uložit</gmt-button>
    </div>
  </div>
</div>
