<div class="detail">
  <div>
    <a uiSref="sv.settings" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
  </div>

  <h1>Nový typ dokumentu</h1>

  <div class="row mt-30">
    <div class="column-100">
      <fieldset>
        <label class="required">Název</label>
        <div class="input">
          <input type="text" [(ngModel)]="documentType.name" />
        </div>
        <label class="required">Kategorie</label>
        <div class="input">
          <gmt-select
            [data]="this"
            field="category"
            [optionItems]="categoryOptions"
            (changed)="onCategorySelected($event.newValue)"
          ></gmt-select>
        </div>
        <label class="">Popis</label>
        <div class="input">
          <input type="text" [(ngModel)]="documentType.description" />
        </div>
        <label class="required">Typy případů</label>
        <div class="input" [loading]="!obligations">
          <div (click)="checklistObligations.toggleSelection(obligation)" *ngFor="let obligation of obligations"><gmt-checkbox [gmtChecked]="checklistObligations.isChecked(obligation)"></gmt-checkbox> {{obligation.name}}</div>
        </div>
      </fieldset>
      <div class="mt-20">
        <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">Uložit</gmt-button>
      </div>
    </div>
  </div>
</div>
