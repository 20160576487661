import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {StateService, TargetState, Transition} from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { MenuConfig } from '@app/models/menuConfig';
import { ApproveTermsService } from '@app/common/services/approve-terms.service';
import { ChangePasswordComponent } from '@app/common/components/change-password/change-password.component';
import { DialogService } from '@app/common/services/dialog.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

const multiSceneCloudLibs = [
  {
    src: 'https://api.multiscenecloud.com/client/v1.14/MultiSceneCore.js',
    element: undefined,
  },
  {
    src: 'https://api.multiscenecloud.com/client/v1.14/PointCloudCore.js',
    element: undefined,
  },
];

@Component({
  templateUrl: './main.component.html',
})

export class MainComponent implements OnInit, OnDestroy {
  loading = true;
  collapsed = false;
  user: User;
  servicedeskUrl: string;
  menuConfig: MenuConfig[] = [];
  projects;
  projectName;
  selProject;
  ENV = this.config.ENV;

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    @Inject('$transition$') private transition: Transition,
    private approveTermsService: ApproveTermsService,
    private dialogService: DialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(APP_CONFIG) private config,
    @Inject(APP_BRAND) public APP_BRAND,
    @Inject(APPLICATIONS) private applications,
    private localStorageService: LocalStorageService,
  ) {
    this.checkPasswordChange = this.checkPasswordChange.bind(this);
    this.changeProject = this.changeProject.bind(this);
  }

  async ngOnInit() {
    await this.authService.checkProjectStatus(this.transition, this.applications.di.name, 'di_', this.authService.loadDimapProjects, 'error')
      .then(
        () => {
          this.authService.switchProjectByKey(this.transition.params().projectKey, this.applications.di.name);
          this.loading = false;
        }, (newState: TargetState) => {
          this.stateService.go(newState.name(), newState.params(), newState.options());
        }
      );

    multiSceneCloudLibs.forEach((lib, idx) => {
      lib.element = document.createElement('script');
      lib.element.src = lib.src;
      lib.element.type = 'text/javascript';
      window.document.getElementsByTagName('head')[0].appendChild(lib.element);

      if (idx === multiSceneCloudLibs.length - 1) {
        return Promise.resolve();
      }
    });

    updateBody('dimap');
    this.authService.setActiveApplication(this.applications.di.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();

    this.collapsed = this.localStorageService.get('menuOpened');

    this.user = this.authService.getUser();

    const actualProject = this.authService.getActualProject();
    if (actualProject) {
      this.projectName = actualProject.name;
      this.selProject = actualProject;
    }

    const actualProjects = this.authService.getActualProjects();
    if (actualProjects) {
      this.projects = actualProjects.filter(p => p.key.match(/di_/));
    }

    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();

    this.approveTermsService.checkApprovedTerms(this.user.id, this.applications.di.name).then(this.checkPasswordChange);
  }

  ngOnDestroy() {
    multiSceneCloudLibs.forEach(lib => {
      if (window.document.getElementsByTagName('head')[0].contains(lib.element)) {
        window.document.getElementsByTagName('head')[0].removeChild(lib.element);
      }
    });
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  logout() {
    this.authService.logout();
  }

  changeProject(project) {
    this.stateService.go('dimap.project.propertyStatus', { projectKey: project.key });
  }

  checkPasswordChange() {
    if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
      this.dialogService.open(ChangePasswordComponent, undefined);
    }
  }

  getMenuConfig(): MenuConfig[] {
    return [
      new MenuConfig('Majetkoprávní přehled', 'dimap.project.propertyStatus', 'fa-home', undefined, undefined, undefined, undefined, 'manage_property_status,property_status'),
      new MenuConfig('Mapa', 'dimap.project.map', 'fa-home', undefined, undefined, undefined, undefined, 'access'),
      new MenuConfig('LV', 'dimap.project.titles', 'fa-users', undefined, undefined, undefined, undefined, 'cadastre'),
      new MenuConfig('Parcely', 'dimap.project.parcels', 'fa-toggle-up', undefined, undefined, undefined, undefined, 'cadastre'),
      new MenuConfig('Nastavení', 'dimap.project.settings', 'fa-wrench', undefined, undefined, undefined, undefined, 'access')
    ];
  }
}
