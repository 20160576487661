import { Component, OnInit, Input } from '@angular/core';

import { ContractService } from '@app/settings/customers/services/contract.service';

@Component({
  selector: 'contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
})
export class ContractListComponent implements OnInit {

  @Input() customerId: string;

  contracts: any[];
  loading = false;

  constructor(private contractService: ContractService) { }

  async ngOnInit() {
    this.contracts = await this.getContracts();
  }

  isReadyWithData(): boolean {
    return this.isReady() && this.contracts.length > 0;
  }

  isReadyWithNoData(): boolean {
    return this.isReady() && this.contracts.length === 0;
  }

  private async getContracts(): Promise<any[]> {
    this.loading = true;
    const contracts = await this.contractService.getForCustomer(this.customerId);
    this.loading = false;

    return contracts;
  }

  private isReady(): boolean {
    return !!(!this.loading && this.contracts);
  }
}
