﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { TitleModel } from '../models/title.model';

@Pipe({ name: 'titleName' })
@Injectable({
  providedIn: 'root'
})
export class TitleNamePipe implements PipeTransform {
	transform(title: TitleModel) : string {
        if (title.lv && title.area && title.area.name) {
          return title.lv + ' (' + title.area.name + ')';
        }
	}
}