import { Component, ElementRef, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { ArticleModel } from '@app/common/models/article.model';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list';
import { ArticleEditComponent } from '@app/common/components/article-edit/article-edit.component';
import { DialogService } from '@app/common/services/dialog.service';
import { AuthService } from '@app/common/services/auth.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Component({
  selector: 'articles',
  styleUrls: ['./articles.component.scss'],
  templateUrl: './articles.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ArticlesComponent implements OnInit {

  @Input() application;
  @Input() projectKey;
  @Input() published;
  @Input() canEdit = false;

  list: ListModel<ArticleModel>;
  pageableList: PageableList;
  token: string = this.authService.getToken();

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    private elementRef: ElementRef,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
    private authService: AuthService,
  ) {
  }

  async ngOnInit() {
    await this.initializeList();
  }

  onEdit(article?: ArticleModel) {
    const dialog = this.dialogService.open(ArticleEditComponent, {
      data: { article: (article
          ? {...article}
          : { application: this.application, projectKey: this.projectKey, content: '' }
      )}
    });

    const sub = dialog.afterClosed.subscribe((res: ArticleModel) => {
      if (res) {
        if (article) {
          Object.assign(article, res);
        } else {
          this.pageableList.fetchResults();
        }
      }
      sub.unsubscribe();
    });
  }

  onRemove(article: ArticleModel) {
    return this.configurationRestangular
      .one('articles', article.id)
      .remove()
      .toPromise()
      .then(() => {
        this.pageableList.fetchResults();
      });
  }

  pageChanged(pageNumber: number) {
    this.pageableList.fetchResults(pageNumber);
    this.pageableList.onListChanged();
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'articles',
      this.getFilter(),
      undefined,
      this.configurationRestangular
    );
    this.list = this.pageableList.list;
    this.registerLinkListener();
    return this.pageableList.load();
  }

  private getFilter() {
    return {
      filters: {
        application: this.application,
        projectKey: this.projectKey,
        published: this.published
      },
      sortOrder: { sortBy: 'publishDate', direction: 'desc' },
    };
  }

  private registerLinkListener() {
    $(this.elementRef.nativeElement).on('click', '.article-preview-content a', (event) => {
      event.preventDefault();
      event.stopPropagation();
      const href = $(event.target).attr('href');

      if (href.indexOf('./inner-') === 0) {
        window.open(`${this.configurationRestangular.configuration.baseUrl}/attachments/${href.replace('./inner-', '')}/data?t=${this.token}`, '_blank');
      } else {
        window.open(href, '_blank');
      }
    });
  }
}
