<ul *ngIf="data?.parcel">
  <li class="map">
    <a uiSref="dimap.project.map" [uiParams]="{ parcelId: data.parcel.parcelid || data.parcel.id }">Zobrazit v mapovém okně</a>
  </li>
  <li *ngIf="data.parcel.idpar || data.parcel.id" class="cuzk">
    <a href="http://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?&typ=parcela&id={{ data.parcel.idpar || data.parcel.id }}" target="_blank">Nahlížení do katastru</a>
  </li>
  <li class="title" *ngIf="data.parcel.titleid || data.parcel.title?.id">
    <a uiSref="dimap.project.titles.detail" [uiParams]="{ id: data.parcel.titleid || data.parcel.title.id }">List vlastnictví</a>
  </li>
  <li class="parcel">
    <a uiSref="dimap.project.parcels.detail" [uiParams]="{ id: data.parcel.id }">Detail parcely</a>
  </li>
</ul>
<div *ngIf="!data || !data.parcel">Parcela nenalezena v KN</div>


<!--<ul>-->
<!--  <li class="parcel">-->
<!--    <a ui-sref="dimap.project.parcels.detail({id: data.parcelid})">Detail parcely</a>-->
<!--  </li>-->
<!--  <li class="map">-->
<!--    <a ui-sref="dimap.project.map({parcelId: data.parcelid})">Zobrazit v mapovém okně</a>-->
<!--  </li>-->
<!--  <li class="title">-->
<!--    <a ui-sref="dimap.project.titles.detail({id: data.titleid})">List vlastnictví</a>-->
<!--  </li>-->
<!--  <li class="cuzk">-->
<!--    <a href="{{data.idpar|cuzkParcelURL}}" target="_blank">Nahlížení do katastru</a>-->
<!--  </li>-->
<!--</ul>-->
