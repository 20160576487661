import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { Component, Input, OnInit, Inject } from '@angular/core';
import { StateParams, StateService } from '@uirouter/angular';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model'
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { TabModel } from '@app/common/models/tab.model';
import { ListModel } from '@app/common/models/list.model';
import { AuthService } from '@app/common/services/auth.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { OpinionRequestEditComponent } from '@app/ps/opinions/components/opinion-request-edit/opinion-request-edit.component';
import { OpinionRequestStatusEnum, opinionRequestStatusOptions } from '@app/ps/enums/opinion-request-status.enum';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { DocumentModel } from '@app/common/models/document.model';
import { ListService } from '@app/common/services/list.service';
import { ParcelModel } from '@app/common/models/parcel.model';
import { OpinionService } from '@app/ps/services/opinion.service';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { NoteModel } from '@app/common/models/note.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'opinion-request-detail',
  templateUrl: './opinion-request-detail.component.html',
  styleUrls: ['./opinion-request-detail.component.scss']
})
export class OpinionRequestDetailComponent implements OnInit {

  @Input() opinionRequest: OpinionRequestModel;

  activeTab: string;
  statusEnum = OpinionRequestStatusEnum;
  tabs: TabModel[];
  documentList: ListModel<DocumentModel>;
  historyList: ListModel<NoteModel>;
  loading = false;

  readonly PARCELS_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel', sortable: false },
    { id: 'title', sortable: false },
    { id: 'area_name', sortable: false },
    { id: 'area', sortable: false },
    { id: 'parcel_price', sortable: false },
  ];

  readonly BUILDINGS_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel', sortable: false },
    { id: 'parcel_title', sortable: false },
    { id: 'building_title', sortable: false },
    { id: 'house_number', sortable: false },
    { id: 'price', sortable: false },
  ];
  helpIds = HelpService.HELP_IDS;

  readonly STATUS_KEYS_REGEXP = new RegExp(opinionRequestStatusOptions.map(opt => opt.id).join('|'));

  constructor(
    private restangular: Restangular,
    public requestService: OpinionRequestService,
    public authService: AuthService,
    private opinionService: OpinionService,
    private dialogService: DialogService,
    private stateService: StateService,
    private listService: ListService,
    private geometricPlanRequestService: GeometricPlanRequestService,
  ) {
    this.activeTab = this.stateService.params.tab;
  }

  ngOnInit() {
    this.tabs = this.getTabs();
    this.isActiveTab = this.isActiveTab.bind(this);
    this.loadDocuments();
    this.loadHistory();
  }

  isActiveTab(id: string): boolean {
    return id === this.activeTab;
  }

  uiOnParamsChanged(changedParams: StateParams) {
    this.activeTab = changedParams.tab;
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    this.historyList.list.forEach((note: NoteModel) => {
      note.text = note.text.replace(
        this.STATUS_KEYS_REGEXP,
        status => this.requestService.getStatusEnumLabel(status)
      );
    });
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  async onEdit(status: string) {
    if (status === this.statusEnum.PRICES_FILLED) {
      const parcelsOk = await this.pairGpParcelsToOpinion();

      if (!parcelsOk) {
        this.dialogService.open(AlertComponent, {
          className: ClassName.ADJUSTED_DIALOG,
          data: { msg: 'Některá z ručně zadaných parcel nebyla nalezena v GP. Kontaktujte podporu.' },
        });
        return;
      }

      const canFillPrices = await this.canFillPrices();

      if (!canFillPrices) {
        this.dialogService.open(AlertComponent, {
          className: ClassName.ADJUSTED_DIALOG,
          data: { msg: 'Posudek na některou z parcel či budov je již vytvořen nebo nejsou k dispozici trvalé zábory.' },
        });
      } else {
        this.stateService.go('symap.project.opinionRequests.detail.fillPricesForm', {
          id: this.opinionRequest.id, titleId: this.opinionRequest.title.id
        });
      }
      return;
    }

    if (status && !this.requestService.isStatusDialog(status)) {
      this.opinionRequest.status = status;
      this.uploadAndRefresh(this.opinionRequest, []);
      return;
    }

    const dialog = this.dialogService.open(OpinionRequestEditComponent, {
      data: {
        opinionRequest: { ...this.opinionRequest },
        editMode: (status ? 'change-status' : 'edit'),
        newStatus: status
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed
      .subscribe((result: { opinionRequest: OpinionRequestModel, files: UploadFileExtended[] } | boolean) => {
        sub.unsubscribe();

        if (typeof result === 'object') {
          this.uploadAndRefresh(result.opinionRequest, result.files);
        }

        return;
      });
  }

  canEdit(): boolean {
    return this.authService.hasPermission('manage_opinions');
  }

  isStatusButtonVisible(status: string) {
    if (status === OpinionRequestStatusEnum.PRICES_FILLED) {
      return this.authService.hasPermission('assignable');
    }
    return true;
  }

  private getTabs(): TabModel[] {
    return [
      {
        name: `Parcely (${this.opinionRequest.parcels.length})`,
        id: 'parcels',
        href: 'symap.project.opinionRequests.detail',
        urlParams: {
          id: this.opinionRequest.id,
          tab: 'parcels',
        },
      },
      {
        name: `Budovy (${this.opinionRequest.buildings.length})`,
        id: 'buildings',
        href: 'symap.project.opinionRequests.detail',
        urlParams: {
          id: this.opinionRequest.id,
          tab: 'buildings',
        },
      },
      {
        name: 'Historie',
        id: 'history',
        href: `symap.project.opinionRequests.detail`,
        urlParams: {
          id: this.opinionRequest.id,
          tab: 'history'
        }
      },
      {
        name: 'Dokumenty',
        id: 'documents',
        href: 'symap.project.opinionRequests.detail',
        urlParams: {
          id: this.opinionRequest.id,
          tab: 'documents',
        },
      },
    ];
  }

  private loadDocuments(): Promise<any> {
    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { opinionRequestId: this.opinionRequest.id, cancelled: false },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    return this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private loadHistory(): Promise<any> {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { opinionRequestId: this.opinionRequest.id, systemType: true },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    return this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private async uploadAndRefresh(opinionRequest: OpinionRequestModel, files: UploadFileExtended[]) {
    this.loading = true;
    this.opinionRequest = await this.requestService.update(opinionRequest, files);
    await this.loadDocuments();
    await this.loadHistory();
    this.loading = false;
  }

  private canFillPrices(): Promise<boolean> {
    const parcelFilter = {
      filters: {
        opinionRequestId: [ this.opinionRequest.id ],
        validity: ['valid'],
        permanentOccupationExists: [true],
      },
    };

    return this.opinionService
      .getParcels(parcelFilter)
      .then((parcels: ParcelModel[]) => {
        return (parcels.length === this.opinionRequest.parcels.length)
          && parcels.every(p => p.buyoutOpinionsShare < 100 || !p.buyoutOpinionsShare);
      });
  }

  private async pairGpParcelsToOpinion(): Promise<any> {
    const temporaryParcels = this.opinionRequest.parcels.filter(p => p.idpar < 0);

    if (temporaryParcels.length === 0 || !this.opinionRequest.geometricPlanRequest) {
      return Promise.resolve(true);
    }

    const geometricPlanRequest = await this.geometricPlanRequestService.get(
      this.opinionRequest.geometricPlanRequest.id,
      [ 'ngGp.knBudoucs', 'ngGp.knBudoucs.futureParcel' ]
    );

    if (!geometricPlanRequest || !geometricPlanRequest.knGp || !geometricPlanRequest.knGp.knBudoucs) {
      return Promise.resolve(false);
    }

    // pair gp parcels into opinion temp parcels
    const gpParcels: ParcelModel[] = geometricPlanRequest.knGp.knBudoucs
      .filter(kb => kb.parcel)
      .map(kb => kb.parcel);

    const newParcels = [];

    for (const parcel of this.opinionRequest.parcels) {
      if (parcel.idpar > 0) {
        newParcels.push(parcel);
        continue;
      }

      const gpParcel = gpParcels.find(p => {
        return (p.katuzeNazev === parcel.katuzeNazev
          && p.parcisKmen === parcel.parcisKmen
          && p.parcisPod === parcel.parcisPod);
      });

      if (!gpParcel) {
        return Promise.resolve(false);
      }

      newParcels.push(gpParcel);
    }

    // store paired parcels
    this.opinionRequest.parcels = newParcels;
    return this.requestService.update(this.opinionRequest);
  }
}
