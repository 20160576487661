import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { APP_BRAND } from '@app/common/services/config.service';

@Pipe({ name: 'brandTranslate' })
@Injectable({
  providedIn: 'root'
})
export class BrandTranslatePipe implements PipeTransform {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
  ) {}

  transform(key: string): string {
    if (!this.APP_BRAND.TRANSLATE || !this.APP_BRAND.TRANSLATE[key]) {
      return key;
    }
    return this.APP_BRAND.TRANSLATE[key];
  }
}
