import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vfze-detail',
  templateUrl: './vfze-detail.component.html',
  styleUrls: []
})
export class VfzeDetailComponent implements OnInit {

  @Input() vfzeId;

  ngOnInit() {
  }

}
