export interface CommonTypeModel {
  id: number;
  name: string;
}

export const parcelNumberTypes: CommonTypeModel[] = [
  { id: 1, name: 'stavební' },
  { id: 2, name: 'pozemková' },
];

export const parcelTypes: CommonTypeModel[] = [
  { id: 2, name: 'orná půda' },
  { id: 3, name: 'chmelnice' },
  { id: 4, name: 'vinice' },
  { id: 5, name: 'zahrada' },
  { id: 6, name: 'ovocný sad' },
  { id: 7, name: 'trvalý travní porost' },
  { id: 10, name: 'lesní pozemek' },
  { id: 11, name: 'vodní plocha' },
  { id: 13, name: 'zastavěná plocha a nádvoří' },
  { id: 14, name: 'ostatní plocha' },

];

export const parcelUsageTypes: CommonTypeModel[] = [
  { id: 1, name: 'skleník' },
  { id: 2, name: 'školka' },
  { id: 3, name: 'plantáž dřevin' },
  { id: 4, name: 'les jiný než hospodářský' },
  { id: 5, name: 'lesní pozemek na kterém je budova' },
  { id: 6, name: 'rybník' },
  { id: 7, name: 'koryto vodního toku přirozené nebo upravené' },
  { id: 8, name: 'koryto vodního toku umělé' },
  { id: 9, name: 'vodní nádrž přírodní' },
  { id: 10, name: 'vodní nádrž umělá' },
  { id: 11, name: 'zamokřená plocha' },
  { id: 12, name: 'společný dvůr' },
  { id: 13, name: 'zbořeniště' },
  { id: 14, name: 'dráha' },
  { id: 15, name: 'dálnice' },
  { id: 16, name: 'silnice' },
  { id: 17, name: 'ostatní komunikace' },
  { id: 18, name: 'ostatní dopravní plocha' },
  { id: 19, name: 'zeleň' },
  { id: 20, name: 'sportoviště a rekreační plocha' },
  { id: 21, name: 'pohřebiště' },
  { id: 22, name: 'kulturní a osvětová plocha' },
  { id: 23, name: 'manipulační plocha' },
  { id: 24, name: 'dobývací prostor' },
  { id: 25, name: 'skládka' },
  { id: 26, name: 'jiná plocha' },
  { id: 27, name: 'neplodná půda' },
  { id: 28, name: 'vodní plocha na které je budova' },
  { id: 29, name: 'fotovoltaická elektrárna' },
  { id: 30, name: 'mez, stráň' },

];
