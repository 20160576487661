<ng-template #occupationCell>
  <div [ngClass]="{disabled: occupation.endDate}">
    <b>č. {{occupation.zabcis}}</b>
    <div *ngIf="occupation.investor">Investor: {{occupation.investor}}</div>
    <div *ngIf="occupation.zabtyptx">
      {{occupation.zabtyptx}}
    </div>
    <div *ngIf="occupation.zabvym">
      {{occupation.zabvym}} <span class="unit">m<sup>2</sup></span>
    </div>
    <div *ngIf="occupation.nabyvatel">Nabyvatel: {{occupation.nabyvatel}}</div>
  </div>
</ng-template>

<ng-template #occupationPhaseCell>
  <div>
    {{ occupation.zabst }}
  </div>
</ng-template>

<div *ngIf="!occupation.endDate" class="fill-content">
  <div class="content-wrap">
    <ng-container *ngTemplateOutlet="type === 'phase' ? occupationPhaseCell : occupationCell"></ng-container>
    <div class="flex1" *ngIf="showUpdate">
      <a class="fr button" uiSref=".updateOccupation" [uiParams]="{occupationId: occupation.id}">
        <i class="fa fa-pencil"></i>
      </a>
    </div>
  </div>
</div>

<div *ngIf="occupation.endDate"
     [tooltip]="tooltipComponent"
     [activeContent]="true"
     [data]="{ filter: { filters: { occupationId: occupation.id }}, customData: { occupation: occupation }}"
     class="tooltip-hover-area fill-content"
>
  <div class="content-wrap">
    <div class="mr-10"><span class="error-message"><i class="fa fa-exclamation-triangle"></i></span></div>
    <ng-container *ngTemplateOutlet="type === 'phase' ? occupationPhaseCell : occupationCell"></ng-container>
    <div class="flex1" *ngIf="showSwitch">
      <a class="fr button" gmtAuth="admin" uiSref="symap.project.cases.detail.updateOccupation" [uiParams]="{occupationId: occupation.id}">
        <i class="fa fa-pencil"></i>
      </a>
    </div>
  </div>
</div>
