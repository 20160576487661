<div class="my-picker">
    <div class="my-picker-container">
        <div class="my-picker-input"
             (click)="monthPickerOpened = !monthPickerOpened"
             (click)="yearPickerOpened = false"
             (click)="$event.stopPropagation();$event.preventDefault()"
        >
            {{getMonthNameFromNumber(month)}}
            <i class="fa fa-calendar"></i>
        </div>
        <div class="my-picker-list" *ngIf="monthPickerOpened">
            <div class="my-picker-item"
                 [ngClass]="{active : (m === month)}"
                 *ngFor="let m of months"
                 (click)="onChange(m, 'month')"
            >{{getMonthNameFromNumber(m)}}</div>
        </div>
    </div>
    <div class="my-picker-container">
        <div class="my-picker-input"
             (click)="yearPickerOpened = !yearPickerOpened"
             (click)="monthPickerOpened = false"
             (click)="$event.stopPropagation();$event.preventDefault()"
        >
            {{year}}
            <i class="fa fa-calendar"></i>
        </div>
        <div class="my-picker-list" *ngIf="yearPickerOpened">
            <div class="my-picker-item"
                 [ngClass]="{active : (y === year)}"
                 *ngFor="let y of years"
                 (click)="onChange(y, 'year')"
            >{{y}}</div>
        </div>
    </div>
</div>
