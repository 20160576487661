import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'sign-investor',
  templateUrl: './sign-investor.component.html',
  styleUrls: ['./sign-investor.component.scss']
})
export class SignInvestorComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  name: string;

  constructor(
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get().toPromise();
    this.name = (await this.caseStatusService.loadStatusName('SignedInvestor')).name;
    this.data.signedInvestorDate = this.data.signedInvestorDate || new Date();
    this.data.financialControlDate = this.data.financialControlDate || new Date();
    this.loading = false;
  }

  isValid() {
    return !!this.data.signedInvestorDate;
  }

  async onSubmit(): Promise<any> {
    const sendData = {
      financialControlDate: this.data.financialControlDate,
      maxAuctionAmount: this.data.maxAuctionAmount,
      signedInvestorDate: this.data.signedInvestorDate,
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'sign-investor')
      .toPromise();
    this.stateService.go('^');
  }
}
