import { Injectable, Inject } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { DatePipe } from '@angular/common';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { SettingsService } from '@app/ps/services/settings.service';

export const vocabulary = [{
    id: 1,
    name: 'stavební objekt',
    key: 'CONSTRUCTION_OBJECT',
    commonName: 'stavební objekt'
  }, {
    id: 2,
    name: 'SO',
    key: 'CONSTRUCTION_OBJECT_SHORTCUT',
    commonName: 'SO'
  }, {
    id: 3,
    name: 'stavebního objektu',
    key: 'CONSTRUCTION_OBJECT_GENITIV',
    commonName: 'stavebního objektu'
    // example: Upozornit 5 dní před koncem realizace stavebního objektu
  }, {
    id: 4,
    name: 'stavebním objektu',
    key: 'CONSTRUCTION_OBJECT_LOCAL',
    commonName: 'stavebním objektu'
  }, {
    id: 5,
    name: 'stavebních objektů',
    key: 'CONSTRUCTION_OBJECT_PLURAL_GENITIV',
    commonName: 'stavebních objektů'
  }, {
    id: 6,
    name: 'stavební objekty',
    key: 'CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
    commonName: 'stavební objekty'
  }, {
    id: 7,
    name: 'dotčený stavební objekt',
    key: 'CONCERN_CONSTRUCTION_OBJECT',
    commonName: 'dotčený stavební objekt'
  }, {
    id: 8,
    name: 'SO',
    key: 'CONSTRUCTION_OBJECT_PLURAL_GENITIV_SHORTCUT',
    commonName: 'stavebních objektů (zkratka)'
  }, {
    id: 9,
    name: 'SO',
    key: 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT',
    commonName: 'stavebního objektu (zkratka)'
  }, {
    id: 10,
    name: 'dotčený SO',
    key: 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT',
    commonName: 'dotčený stavební objekt (zkratka)'
  }, {
    id: 11,
    name: 'dotčené SO',
    key: 'CONCERN_CONSTRUCTION_OBJECTS_SHORTCUT',
    commonName: 'dotčené stavební objekty (zkratka)'
  }, {
    id: 13,
    name: 'nový SO',
    key: 'NEW_CONSTRUCTION_OBJECT_SHORTCUT',
    commonName: 'nový stavební objekt (zkratka)'
  }, {
    id: 14,
    name: 'nový stavební objekt',
    key: 'NEW_CONSTRUCTION_OBJECT',
    commonName: 'nový stavební objekt'
  }, {
    id: 15,
    name: 'nového stavebního objektu',
    key: 'NEW_CONSTRUCTION_OBJECT_GENITIV',
    commonName: 'nového stavebního objektu'
  }, {
    id: 16,
    name: 'Etapa / úsek',
    key: 'ETAPA_USEK',
    commonName: 'Etapa / úsek'
  }, {
    id: 17,
    name: 'stavební objekt',
    key: 'CONSTRUCTION_OBJECT_AKUZATIV',
    commonName: 'stavební objekt/zakázku(4. pád)'
  }, {
    id: 18,
    name: 'jihého stavebního objektu',
    key: 'CONSTRUCTION_OBJECT_OTHER',
    commonName: 'jihého stavebního objektu'
  }, {
    id: 19,
    name: 'stav trvalých záborů',
    key: 'PERMANENT_OCCUPATIONS_STATE',
    commonName: 'stav trvalých záborů (popis semafor)'
  }, {
    id: 20,
    name: 'stav dočasných záborů nad 1 rok',
    key: 'OVER_YEAR_TEMPORARY_OCCUPATIONS_STATE',
    commonName: 'stav dočasných záborů nad 1 rok (popis semafor)'
  }, {
    id: 21,
    name: 'stav dočasných záborů do 1 roku',
    key: 'UNDER_YEAR_TEMPORARY_OCCUPATIONS_STATE',
    commonName: 'stav dočasných záborů do 1 roku (popis semafor)'
  }, {
    id: 22,
    name: 'stav služebností',
    key: 'EASEMENT_STATE',
    commonName: 'stav služebností (popis ouško, záhlaví tabulek)'
  }, {
    id: 23,
    name: 'trvalý zábor',
    key: 'PERMANENT_OCCUPATION',
    commonName: 'trvalý zábor (popis ouško, záhlaví tabulek)'
  }, {
    id: 24,
    name: 'dočasný zábor',
    key: 'TEMPORARY_OCCUPATION',
    commonName: 'dočasný zábor (popis ouško, záhlaví tabulek)'
  }, {
    id: 25,
    name: 'služebnost',
    key: 'EASEMENT',
    commonName: 'služebnost (popis ouško, záhlaví tabulek)'
  },
];

@Injectable({ providedIn: 'root' })
export class WordService {
  vocabulary = null;

  constructor(
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    private authService: AuthService,
    private datePipe: DatePipe,
    private settingsService: SettingsService,

  ) {
  }

  setVocabulary(projectId?) {
    const activeProjectId = projectId || this.authService.getActualProject().id;

    if (!Number.isInteger(activeProjectId)) {
      throw new Error(`activeProjectId is not valid integer value ${activeProjectId}.`);
    }

    if (this.vocabulary) {
      return Promise.resolve(this.vocabulary);
    }

    return this.settingsService.loadSettings(activeProjectId).then(
      (settings) => {
        if (!this.hasVocabulary()) {
          return this.parseVocabularyFromSettings(settings);
        } else {
          return this.vocabulary;
        }
      },
      (reason) => {
        if (!reason || reason.xhrStatus !== 'abort') {
          return Promise.reject(reason);
        }
      }
    );
  }

  parseVocabularyFromSettings(settings) {
    try {
      const nomenclature = settings && settings.nomenclature;
      const nomenclatureExist = !!(nomenclature && nomenclature.length > 0);
      this.vocabulary = nomenclatureExist ? new Set(settings.nomenclature) : new Set(vocabulary);

      const missingWords = this.getDifferenceWords([...this.vocabulary], vocabulary);
      missingWords.forEach(w => this.vocabulary.add(w));
      this.addUneditableVocabulary();
    } catch (e) {
      this.vocabulary = new Set(vocabulary);
      this.addUneditableVocabulary();
    }
  }

  getVocabulary() {
    return this.vocabulary;
  }

  hasVocabulary() {
    return !!this.vocabulary;
  }

  getDifferenceWords(mainVocabolary, childVocabolary) {
    const missingWords = [];
    for (const word of childVocabolary) {
      const contain = mainVocabolary.find((w) => w.key === word.key);
      if (!contain) {
        missingWords.push(word);
      }
    }
    return missingWords;
  }

  getWord(id, firstLeterUpper = false, toUpper = false) {
    if (!this.hasVocabulary()) {
      return this.setVocabulary().then(() => this.getWord(id, firstLeterUpper, toUpper));
    }

    if (id) {
      return Promise.resolve(this.getTranslation(id, firstLeterUpper, toUpper));
    } else {
      return Promise.reject(undefined);
    }
  }

  getTranslation(id, firstLeterUpper = false, toUpper = false) {
    let word;
    const nomenclatureItem = [...this.vocabulary].find((nom) => nom.key === id);
    if (!nomenclatureItem) {
      return id;
    }
    word = nomenclatureItem.name;

    if (firstLeterUpper) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    } else if (toUpper) {
      return word.toUpperCase();
    } else {
      return word;
    }
  }

  replaceVariablesInText(text = '') {
    if (text.includes('$')) {
      const replace = (str) => {
        const re = /\$\w+/g;
        const matchs = str.match(re);
        let finalPromise = Promise.resolve(str);
        if (matchs) {
          for (const m of matchs) {
            finalPromise = finalPromise.then((strChained) => {
              return this.getWord(m.split('$')[1]).then((tr) => strChained.replace(m, tr));
            });
          }
        }
        return finalPromise;
      };
      return replace(text);
    } else {
      return Promise.resolve(text);
    }
  }

  clearVocabulary() {
    this.vocabulary = null;
  }

  private addUneditableVocabulary() {
    const uneditableVocabulary = [
      {
        id: 12,
        name: this.authService.getActualProject() ? this.datePipe.transform(this.authService.getActualProject().cadastreDataDate, 'd.M.yyyy') : '',
        key: 'CADASTRE_DATA_DATE',
        commonName: 'datum aktualizace katastru (není možné editovat)',
        editable: false,
      },
    ];

    uneditableVocabulary.forEach(w => this.vocabulary.add(w));
  }
}
