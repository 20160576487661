import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { Project } from '@app/models/project';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { APPLICATIONS } from '@app/common/services/config.service';

export interface DashboardSettingsModel {
  show: boolean;
  statisticsPermanent: any;
  statisticsPermanentCategory: any;
  statisticsUnderOneYear: any;
  statisticsUnderOneYearCategory: any;
  statisticsOverOneYear: any;
  statisticsOverOneYearCategory: any;
  statisticsEasement: any;
  statisticsEasementCategory: any;
}

export interface DashboardSettingsPayloadModel {
  show: boolean;
  statisticsPermanentId?: any;
  statisticsPermanentCategoryId?: any;
  statisticsUnderOneYearId?: any;
  statisticsUnderOneYearCategoryId?: any;
  statisticsOverOneYearId?: any;
  statisticsOverOneYearCategoryId?: any;
  statisticsEasementId?: any;
  statisticsEasementCategoryId?: any;
}

@Component({
  selector: 'dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: [],
})
export class DashboardSettingsComponent implements OnInit {
  @Input() project: Project;
  @Input() dashboardDefaults: DashboardSettingsModel;
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
  public dashboardFields: any[] = [
     {type: 'statisticsPermanent', name: 'Trvalé zábory'},
     {type: 'statisticsUnderOneYear', name: 'Dočasné zábory do 1 roku'},
     {type: 'statisticsOverOneYear', name: 'Dočasné zábory nad 1 rok'},
     {type: 'statisticsEasement', name: 'Věcná břemena'},
  ];

  data: DashboardSettingsModel;
  public statistics: any[];

  constructor(
    private listService: ListService,
    @Inject(APPLICATIONS) private applications: any,
    @Inject(RESTANGULAR_CONFIGURATION) private restangularConfig: any,
    @Inject(RESTANGULAR_SYMAP) private symapRestangularService: any,
  ) { }

  ngOnInit() {
    this.onSubmit = this.onSubmit.bind(this);
    this.cleanCache = this.cleanCache.bind(this);
    this.initializeData();
  }

  private async initializeData() {
    const statisticsData: ListModel<any> = this.listService.createList(
      'statistics',
      { sortOrder: { sortBy: 'statisticsOrder' }, limit: null }
    );
    let loadedSettings: DashboardSettingsPayloadModel;

    await Promise.all([
      this.listService.fetchResult(statisticsData).then(() => {
        this.statistics = statisticsData.list;
        statisticsData.list.forEach(s => {
          // Resolve issues with categories with same id
          const uniqueIds = [...new Set(s.categories.map(category => category.id))];
          if (uniqueIds.length < s.categories.length) {
            let id = 1;
            s.categories.forEach(category => {
              category.id = id++;
            });
          }
          s.categories.forEach(category => {
            if (category.split) {
              category.name = category.splitName;
            }
          });
        });
      }),
      (this.dashboardDefaults ? Promise.resolve() : this.restangularConfig.one(`dashboard/${this.project.key}`).get().toPromise().then(
        data => loadedSettings = data,
        (response) => {
          if (response.status === 404) {
            return null;
          }
          return Promise.reject(response);
        }
      )),
    ]);
    if (this.dashboardDefaults) {
      this.data = this.dashboardDefaults;
    } else {
      this.data = {
        show: false,
        statisticsPermanent: undefined,
        statisticsPermanentCategory: undefined,
        statisticsUnderOneYear: undefined,
        statisticsUnderOneYearCategory: undefined,
        statisticsOverOneYear: undefined,
        statisticsOverOneYearCategory: undefined,
        statisticsEasement: undefined,
        statisticsEasementCategory: undefined,
      };
      if (loadedSettings) {
        this.data.show = !!loadedSettings.show;
        this.dashboardFields.forEach(field => {
          this.data[field.type] = loadedSettings[field.type + 'Id'] ? this.statistics.find(s => s.id === loadedSettings[field.type + 'Id']) : null;
          this.data[field.type + 'Category'] = this.data[field.type] && loadedSettings[field.type + 'CategoryId'] ? this.data[field.type].categories.find(c => c.id === loadedSettings[field.type + 'CategoryId']) : null;
        });
      }
    }
    this.loaded.emit({
      defaults: this.data,
      onSave: this.onSubmit,
    });
  }

  private onSubmit() {
    const payload: DashboardSettingsPayloadModel = {
      show: this.data.show
    };
    this.dashboardFields.forEach(field => {
      payload[field.type + 'Id'] = this.data[field.type] ? this.data[field.type].id : null;
      payload[field.type + 'CategoryId'] = this.data[field.type + 'Category'] ? this.data[field.type + 'Category'].id : null;
    });

    return this.restangularConfig.one(`dashboard/${this.project.key}`).customPUT(payload).toPromise();
  }

  public onStatisticsChanged(type, event) {
    this.data[type + 'Category'] = null;
  }

  public cleanCache(): Promise<any> {
    return this.symapRestangularService.one('dashboard', this.project.key).customDELETE('').toPromise();
  }
}

