﻿import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  templateUrl: './pickadate.component.html',
  styleUrls: [ './pickadate.component.scss' ]
})
export class PickADateComponent {
  @Input() dateInput: any;
  @Input() placeholder = 'd.m.rrrr';
  @Output() changed = new EventEmitter();

  onChanged($event) {
    this.dateInput = $event;
    this.changed.emit(this.dateInput);
  }
}
