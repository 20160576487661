import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';

@Pipe({ name: 'projectTypeName' })
@Injectable({
  providedIn: 'root'
})
export class ProjectTypeNamePipe implements PipeTransform {

  constructor(
    private brandTranslatePipe: BrandTranslatePipe
  ) { }

  transform(projectType: any) {
    return projectType ? this.brandTranslatePipe.transform('sv.section.' + projectType.toLowerCase()) : null;
  }
}
