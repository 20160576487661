<h2>Smlouvy</h2>
<div [loading]="loading" class="mt-10">
    <table *ngIf="isReadyWithData()"  class="bordered">
        <thead>
            <!-- cislo smlouvy -->
            <th class="cell">Číslo smlouvy</th>
            <!-- predmet smlouvy -->
            <th class="cell">Předmět smlouvy</th>
            <!-- datum uzavreni smlouvy -->
            <th class="cell">
                Datum uzavření smlouvy
            </th>
            <!-- poznámka -->
            <th class="cell">Poznámka</th>
        </thead>
        <tbody>
            <tr
                *ngFor="let contract of contracts"
                uiSref="settings.customers.detail.contract.detail"
                [uiParams]="{id: contract.idText}"
                class="row-group clickable">
                <td class="cell">{{ contract.contractNumber }}</td>
                <td class="cell">{{ contract.subject }}</td>
                <td class="cell">{{ contract.signDate | date:'d. M. yyyy' }}</td>
                <td class="cell">{{ contract.description }}</td>
            </tr>
        </tbody>
    </table>
    <div class="empty-info pb-20 pt-20 bordered centered-text" *ngIf="isReadyWithNoData()">K objednateli dosud nebyla přiřazena žádná smlouva.</div>
</div>
