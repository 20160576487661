import {Component, Inject, OnInit} from "@angular/core";
import {StateService} from "@uirouter/angular";

import {AuthService} from "../../services/auth.service";
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';

@Component({
  templateUrl: './user-credentials.component.html'
})
export class UserCredentialsComponent implements OnInit {

  // model
  oldPassword: string;
  newPassword: string;
  newPasswordCheck: string;
  username: string;
  helpIds = HelpService.HELP_IDS;

  // params
  backSref: string;

  constructor(
    @Inject(RESTANGULAR_SETTINGS) private globalRestangular: any,
    private authService: AuthService,
    private stateService: StateService
  ) {
    this.update = this.update.bind(this);
    this.isUpdateDisabled = this.isUpdateDisabled.bind(this);
  }

  ngOnInit() {
    this.backSref = this.stateService.params.backSref;
  }

  update() {
    if (!this.authService.isAuthenticated()) {
      return;
    }

    const data = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      newPasswordCheck: this.newPasswordCheck,
      username: this.username
    };

    return this.globalRestangular.all(`users/user/${this.authService.getUser().id}/password-change`).customPUT(data).toPromise().then(() => {
      this.authService.passwordChanged();
      if (this.backSref) {
        this.stateService.go(this.stateService.params.backSref);
      }
    });
  }

  isUpdateDisabled() {
    return !!this.oldPassword && !!(this.username || (this.newPassword && this.newPasswordCheck));
  }
}
