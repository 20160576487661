<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <h1>Návrh na vklad do webové aplikace ČUZK</h1>
  <div [loading]="!loadingFinished">
    <div *ngIf="!loading">
      <form-cadastre-entry-proposal [data]="data" (callbackRegister)="formCallbackRegister($event)" (load)="onLoad()"></form-cadastre-entry-proposal>

      <div *ngIf="loadingFinished">
        <gmt-button *ngIf="!data.entryProposalLink" [clickAction]="sendProposal" [gmtDisabled]="!hasValidProposalCredentials()">Odeslat</gmt-button>
        <gmt-button *ngIf="data.entryProposalLink" [clickAction]="deleteProposal">Smazat existující odkaz na Návrh na vklad</gmt-button>
      </div>
    </div>
  </div>
</div>
