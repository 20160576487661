<div class="list">
  <h1>Přehled požadavků</h1>

  <div [loading]="loading"></div>
  <ng-container *ngIf="!loading">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <gmt-select
        [optionItems]="documentTypeOptions"
        [data]="list.filter.filters.documentTypeId"
        field="values"
        [multiselect]="true"
        [itemPipe]="documentTypeFullname"
        selectTitle="Typ dokumentu"
      ></gmt-select>

      <gmt-select
        [optionItems]="obligationOptions"
        [data]="list.filter.filters.obligationTypes"
        field="values"
        [multiselect]="true"
        selectTitle="Typ případu"
      ></gmt-select>

      <gmt-select
        [optionItems]="statusOptions"
        [data]="list.filter.filters.status"
        field="values"
        [multiselect]="true"
        selectTitle="Stav požadavku"
      ></gmt-select>

      <gmt-select
        [optionItems]="organizationalUnitOptions"
        [data]="list.filter.filters.organizationalUnitId"
        [itemPipe]="organizationalUnitName"
        field="values"
        [multiselect]="true"
        selectTitle="{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}"
      ></gmt-select>

      <gmt-select
        visibleByBrand="sv.unit"
        [optionItems]="sectionOptions"
        [data]="list.filter.filters.section"
        field="values"
        [multiselect]="true"
        selectTitle="{{ 'sv.section' | brandTranslate }}"
      ></gmt-select>

      <gmt-select
        [data]="this"
        field="createdByUsers"
        [restangularService]="settingsRestangular"
        resource="users/list"
        [filter]="userFilter"
        [itemPipe]="usernamePipe"
        [multiselect]="true"
        (changed)="onUserChanged($event)"
        selectTitle="Zadavatel"
      ></gmt-select>

      <div class="tag" [ngClass]="{ active: !list.filter.filters.finished.values.length }" title="Zobrazení vč. požavků, které jsou ukončené" (click)="onToggleFinished()">
        <div>Zobrazit ukončené požadavky</div>
      </div>

      <div *ngIf="APP_BRAND.NAME === 'SZ'" class="tag" [ngClass]="{ active: !list.filter.filters.assignable.values.length }" title="Zobrazit požadavky na vzory neurčené pro {{ APP_BRAND.PS.TITLE }}" (click)="onToggleAssignable()">
        <div>Zobrazit požadavky na vzory neurčené pro {{ APP_BRAND.PS.TITLE }}</div>
      </div>

      <!-- Fulltext -->
      <div class="column-100">
        <input
          class="fulltext"
          type="text"
          autocomplete="off"
          placeholder="Fultextové hledání..."
          [(ngModel)]="list.filter.filters.searchText.values[0].id"
        />
      </div>
    </filter>

    <div [loading]="list.loading">
      <div class="table-top-container">
        <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      </div>
      <div class="bordered" *ngIf="list.itemCount">
        <div class="table">
          <div class="head-group">
            <div class="row">
              <span class="cell">
                <sort-by
                  [selected]="'number' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('number', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Číslo</sort-by>
              </span>

              <span class="cell">
                Typ požadavku
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'organizationalUnitName' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('organizationalUnitName', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}</sort-by>
              </span>

              <span class="cell" visibleByBrand="sv.unit">
                <sort-by
                  [selected]="'section' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('section', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >{{ 'sv.section' | brandTranslate }}</sort-by>
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'documentType' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('documentType', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Typ dokumentu</sort-by>
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'userFullName' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('userFullName', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Zadavatel</sort-by>
              </span>

              <span class="cell">
                <sort-by
                  [selected]="'status' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('status', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Stav požadavku</sort-by>
              </span>
            </div>
          </div>
          <div class="row-group">
            <a class="row" *ngFor="let ticket of list.list" uiSref="sv.tickets.detail" [uiParams]="{ id: ticket.id }">
              <span class="cell text-center">
                {{ ticket.number }}
              </span>

              <span class="cell">
                <ng-container *ngIf="ticket.actualizedSample">
                  aktualizace <a uiSref="sv.samples.detail" [uiParams]="{ id: ticket.actualizedSample.id }">{{ ticket.actualizedSample.number }}</a>
                </ng-container>
                <ng-container *ngIf="!ticket.actualizedSample">
                  nový vzor
                </ng-container>
                <ng-container *ngIf="ticket.assignable === false">
                  &nbsp;<span  class="tag tag--danger tag--small is-uppercase">není učen pro {{ APP_BRAND.PS.TITLE }}</span>
                </ng-container>
              </span>

              <span class="cell">
                {{ ticket.organizationalUnitName }}
              </span>

              <span class="cell" visibleByBrand="sv.unit">
                {{ ticket.section }}
              </span>

              <span class="cell">
                {{ ticket.documentType | documentTypeFullname }}
              </span>

              <span class="cell">
                {{ ticket.userFullName }}
              </span>

              <span class="cell">
                {{ ticket.status | ticketStatusName }} ({{ getStatusDate(ticket) | date: 'd.M.yyyy' }})
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
        Nenalezena žádná data
      </div>

      <div class="flexbox-container">
        <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"></itemscounter>
        <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)" class="flex1 mt-5 text-center"></pagescounter>
        <div class="page-count-select mt-5" *ngIf="list.itemCount && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>

      <div>
        <pagination
          *ngIf="list.itemCount && list.list !== null"
          [list]="list"
          (callbackRegister)="pageableList.paginationCallbackRegister($event)"
        ></pagination>
      </div>
    </div>
  </ng-container>
  <help [helpId]="helpIds.TICKET_LIST" class="cm-help-page"></help>
</div>
