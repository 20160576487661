<h1>Editace typu případu</h1>

<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Základní nastavení</legend>
      <div class="column-100">
        <label class="w-300">Název případu</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.overridenName"/></div>
        <div class="inline" style="color: #909090; padding: 7px 10px 7px 0;">({{ obligation.defaultName }})</div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Doručení smlouvy vlastníkovi</legend>
      <div class="column-100">
        <label class="w-300">Počet dní od odeslání smlouvy</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.deliveryPeriod"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.deliveryNotifyDaysBefore"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Platnost návrhu smlouvy</legend>
      <div class="column-100">
        <label class="w-300">Počet dní od doručení</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.validityPeriod"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.validityNotifyDaysBefore"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Akcelerační příplatek</legend>
      <div class="column-100">
        <label class="w-300">Počet dní od doručení</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.bonusPeriod"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.bonusNotifyDaysBefore"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Maximální výse příplatku na vlastníka (Kč)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.bonusOwnershipMax"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Maximální výše příplatku na smlouvu (Kč)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.bonusCaseMax"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Splatnost smlouvy</legend>
      <div class="column-100">
        <label class="w-300">Určit splatnost od data</label>
        <div class="input"><gmt-select [data]="obligation" field="dueDateFromType" [optionItems]="dueDateFromTypes" required="true"></gmt-select></div>
      </div>
      <div class="column-100">
        <label class="w-300">Počet dní lhůty splatnosti</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.duePeriod"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.dueNotifyDaysBefore"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Rozhodnutí o návrhu na vklad</legend>
      <div class="column-100">
        <label class="w-300">Lhůta pro rozhodnutí od data podání</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.cadastreDecisionPeriod"/></div>
      </div>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.cadastreDecisionNotifyDaysBefore"/></div>
      </div>
    </fieldset>
    <fieldset *ngIf="obligation.type === 'FulfillmentOfDecisionExpropriationLiability' || obligation.type === 'TemporaryExpropriationLiability'">
      <legend>Přerušení vyvlastňovacího řízení</legend>
      <div class="column-100">
        <label class="w-300">Upozornit notifikací před vypršením lhůty (počet dní)</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.expropriationProceedingInteruptionNotifyDaysBefore"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Reakce na urgenci</legend>
      <div class="column-100">
        <label class="w-300">Počet dní od odeslání</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.dunningLetterPeriod"/></div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Reakce na výzvu k vyvlastnění</legend>
      <div class="column-100">
        <label class="w-300">Počet dní od doručení</label>
        <div class="input"><input type="text" [(ngModel)]="obligation.callBeforeExpropriationReceivedPeriod"/></div>
      </div>
    </fieldset>
    <div class="actions">
      <gmt-button [clickAction]="update">Uložit</gmt-button>
    </div>
  </div>
</div>
