import { Component, Inject, OnInit, Type } from '@angular/core';
import * as _ from 'lodash';
import { ProjectModel } from '@app/dashboard/models/project.model';
import { DataService } from '@app/dashboard/services/data.service';
import { DataModel } from '@app/dashboard/models/data.model';
import { DatePipe } from '@angular/common';
import { TooltipTrendComponent } from '@app/dashboard/components/tooltip-trend/tooltip-trend.component';
import { SortModel } from '@app/common/models/sort.model';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';

export interface ProjectsFilter {
  name: string;
  management: string;
  isprofond: string;
}

@Component({
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  loading = true;
  sortOrder: SortModel = { sortBy: 'name', direction: 'asc' };
  filter: ProjectsFilter = { name: '', management: '', isprofond: '' };
  captureDate = '';
  projectsSource: ProjectModel[];
  projects: ProjectModel[];
  userProjects: any[];
  tooltipComponent: Type<TooltipTrendComponent> = TooltipTrendComponent;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private datePipe: DatePipe,
  ) {
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  ngOnInit() {
    this.loadUserProjects();
    this.loadProjects();
  }

  onSort(sortBy: string) {
    this.sortOrder.direction = sortBy === this.sortOrder.sortBy
      ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
      : 'asc';
    this.sortOrder.sortBy = sortBy;
    this.projects = _.orderBy(this.projects, this.sortOrder.sortBy, this.sortOrder.direction as any);
  }

  onFilter() {
    this.projects = this.projectsSource.filter(project => {
      if (this.filter.name && project.name.toLowerCase().indexOf(this.filter.name.toLowerCase()) === -1) {
        return false;
      }
      if (this.filter.management && project.management.toLowerCase().indexOf(this.filter.management.toLowerCase()) === -1) {
        return false;
      }
      if (this.filter.isprofond && project.isprofond.toLowerCase().indexOf(this.filter.isprofond.toLowerCase()) === -1) {
        return false;
      }
      return true;
    });
    this.projects = _.sortBy(this.projects, this.sortOrder.sortBy, this.sortOrder.direction);
  }

  getUserProject(project: ProjectModel): any {
    if (!project.key || !this.userProjects) {
      return false;
    }
    return _.find(this.userProjects, { key: project.key });
  }

  getDoneRate(done: number, total: number): number {
    if (total === 0 || done === 0) {
      return 0;
    }
    return (done / total * 100);
  }

  private loadProjects() {
    this.dataService.getStatisticsData().subscribe((data: DataModel[]) => {
      this.projectsSource = [];
      for (const part of data) {
        this.captureDate = this.datePipe.transform(part.date, 'shortDate');
        this.projectsSource = this.projectsSource.concat(part.data.map(ProjectModel.createFromAPI));
      }
      this.projectsSource = _.sortBy(this.projectsSource, this.sortOrder.sortBy, this.sortOrder.direction);
      this.projects = this.projectsSource;
      this.loading = false;
    });
  }

  private loadUserProjects() {
    this.authService.loadSymapProjects(this.authService.getUser().id)
      .then(data => this.userProjects = data.projects);
  }
}
