<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Nastavení</h1>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-12">
                <div class="mb-10">
                    <a uiSref="pk.settings.user-credentials" class="button">
                        <i class="fa fa-key"></i> Změna přihlašovacích údajů
                    </a>
                </div>
                <div class="mb-10">
                    <a uiSref="pk.settings.wsdp-credentials" class="button">
                        <i class="fa fa-key"></i> Změna WSDP
                    </a>
                </div>
            </div>
        </div>
    </div>
    <help [helpId]="helpIds.PK_SETTINGS" class="cm-help-page"></help>
</div>