import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import {DialogService} from "@app/common/services/dialog.service";

@Component({
  selector: 'generate-documents',
  templateUrl: './generate-documents.component.html',
  styleUrls: ['./generate-documents.component.scss']
})
export class GenerateDocumentsComponent implements OnInit {

  @Input() caseId: number;
  @Input() type = 'normal';
  private generate: Function;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private authService: AuthService,
    private restangular: Restangular,
    private dialogService: DialogService,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
    this.normalTemplateGetter = this.normalTemplateGetter.bind(this);
    this.financialTemplateGetter = this.financialTemplateGetter.bind(this);
    this.generateSetter = this.generateSetter.bind(this);
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {

  }

  generateSetter(generateDocuments) {
    this.generate = generateDocuments.generate;
  }

  normalTemplateGetter() {
    const templateTypes = [];

    const loadData = () => {
      return Promise.all([
        this.restangular.one('cases', this.caseId).get({ loadCollections: [ 'caseSubjects', 'caseOwnerships' ] }).toPromise(),
        this.restangular.one('cases', this.caseId).customGET('documents').toPromise()
      ]);
    };

    return loadData().then((data) => {
      const caseData = data[0];

      const templateExists = data[1].items.includes(caseData.obligation.type + 'Contract');
      const coveringLetterTemplateExists = data[1].items.includes(caseData.obligation.type + 'CoveringLetter');
      const otherDocumentTemplateExists = data[1].items.includes('OtherDocument');
      const informationLetterTemplateExists = data[1].items.includes('InformationLetter');

      if (templateExists) {
        templateTypes.push({
          key: caseData.obligation.type + 'Contract',
          name: 'Generování smlouvy',
          templates: []
        });
      }

      if (coveringLetterTemplateExists) {
        const coveringLetterTemplate = {
          key: caseData.obligation.type + 'CoveringLetter',
          name: 'Generování průvodního dopisu',
          templates: [],
          recipients: []
        };

        const caseOwnerships = this.caseService.getDataCaseOwnerships(caseData.caseOwnerships, caseData.caseSubjects);
        coveringLetterTemplate.recipients = this.caseService.getRecipients(caseOwnerships, true);
        templateTypes.push(coveringLetterTemplate);
      }

      if (informationLetterTemplateExists) {
        const informationLetterTemplate = {
          key: 'InformationLetter',
          name: 'Generování informačního dopisu',
          templates: [],
          recipients: []
        };

        const caseOwnerships = this.caseService.getDataCaseOwnerships(caseData.caseOwnerships, caseData.caseSubjects);
        informationLetterTemplate.recipients = this.caseService.getRecipients(caseOwnerships, true);
        templateTypes.push(informationLetterTemplate);
      }

      if (otherDocumentTemplateExists) {
        const otherDocumentTemplate = {
          key: 'OtherDocument',
          name: 'Generování ostatních dokumentů',
          templates: []
        };
        templateTypes.push(otherDocumentTemplate);
      }

      const keys = templateTypes.map(templateTypes => templateTypes.key);

      return this.restangular.all('templates').customPOST({filter: {filters: {validity: ['valid'], key: keys}}}).toPromise().then((data) => {
        templateTypes.forEach((templateType) => {
          templateType.templates = data.filter(i => i.key === templateType.key);

          templateType.templates.forEach(template => {
            template.commonName = template.attachment.baseName;

            if (this.APP_BRAND.NAME === 'RSD' && this.authService.getActualProject().useTemplatesService) {
              template.attachment.baseName = caseData.mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
            } else if (templateType.key === caseData.obligation.type + 'Contract') {
              template.attachment.baseName = caseData.mpp.number;
            }

            if (templateType.recipients) {
              template.recipients = templateType.recipients.map(recipient => {
                const r = {...recipient};
                if (this.APP_BRAND.NAME === 'RSD' && this.authService.getActualProject().useTemplatesService) {
                  r.commonTemplateName = caseData.mpp.number + (template.name ? `_${template.name}` : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '') + `_${recipient.name}`;
                  r.templateName = r.commonTemplateName;
                } else {
                  r.commonTemplateName = `${template.attachment.baseName}_${recipient.name}`;
                  r.templateName = `${template.attachment.baseName}_${recipient.name}`;
                }
                return r;
              });
            }
          });
        });

        return templateTypes;
      });
    });
  }

  financialTemplateGetter() {
    const templateTypes = [{
      key: 'FinancialControl',
      queryParam: 'templateId',
      required: true,
      templates: []
    }];

    const keys = templateTypes.map(templateTypes => templateTypes.key);

    return Promise.all([
      this.restangular.one('cases', this.caseId).get().toPromise(),
      this.restangular.all('templates').customPOST({ filter: {filters: {validity: ['valid'], key: keys}} }).toPromise()
    ])
      .then((data) => {
      templateTypes.forEach((templateType) => {
        templateType.templates = _.filter(data[1], {key: templateType.key});

        templateType.templates.forEach(template => {
          if (this.APP_BRAND.NAME === 'RSD' && this.authService.getActualProject().useTemplatesService) {
            template.commonName = template.attachment.baseName;
            template.attachment.baseName = data[0].mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
          } else {
            template.commonName = template.attachment.baseName;
          }
        });
      });
      return templateTypes;
    });
  }

  submit() {
    return this.generate().then((attachments) => {

      const attachmentsWithoutGeneratedMap = attachments.filter(a => a.mapSuccessfullyGenerated === false);

      if (attachmentsWithoutGeneratedMap.length > 0) {
        const msg = 'V následujícíh dokumentech se nepodařilo vygenerovat mapovou část: ' + attachmentsWithoutGeneratedMap.map(a => a.name).join(', ');
        this.dialogService.alertDialogPromise(msg).then(() => {
          this.stateService.go('^');
        });
      } else {
        this.stateService.go('^');
      }
    });
  }
}
