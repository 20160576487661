import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 import * as  L from 'leaflet';
import { LeafletWmsCommonService } from '@app/map/services/leaflet-wms-common.service';
import { MapUtilsCqlFilterService } from '@app/map/services/map-utils-cql-filter.service';
import { MapUtilsWfsFeatureService } from '@app/map/services/map-utils-wfs-feature.service';

/**
 * WMS layer extended with WFS-style filtering capabilities
 */
@Injectable({
  providedIn: 'root'
})
export class LeafletTileLayerWmsFiltered {

  private WmsTileLayer;

  constructor(
     http: HttpClient,
     leafletWmsCommonService: LeafletWmsCommonService,
     mapUtilsCqlFilterService: MapUtilsCqlFilterService,
     mapUtilsWFSFeatureService: MapUtilsWfsFeatureService
  ) {
    this.WmsTileLayer = L.TileLayer.WMS.extend({
        initialize: function (url, options) {
            if(!options || !options.id) {
                throw new Error("Missing layer id option in initialization.");
            }

            this._id = options.id;
            this.wmsParams = L.extend(options.wms);

            if (options.filter) {
                this.wmsParams['cql_filter'] = mapUtilsCqlFilterService.getFilter(options.filter);
            }

            this._httpService = http;

            L.TileLayer.WMS.prototype.initialize.call(this, url, this.wmsParams);

            if (options.hasOwnProperty('removeWmsParams')) {
                for (var i = 0; i < options['removeWmsParams'].length; i++) {
                    this.removeWmsParam(options['removeWmsParams'][i]);
                }
            }
        },

        loadFeatureData: function (options) {
            return leafletWmsCommonService.loadFeatureData({
              latlng: options.latlng,
              queryParams: options.queryParams,
              url: this._url,
              uppercase: this._uppercase,
              httpService: this._httpService,
              map: this._map,
              crs: this._crs,
              layers: this.wmsParams['layers'],
              cqlFilter: this.wmsParams['cql_filter'],
              featureCount: this.wmsParams['feature_count'],
              version: options.version
            });
        },

        loadFeatureDataByID: function (featureID, queryParams) {
          var wfsBaseURL = this._url.substring(0, this._url.lastIndexOf("wms")) + 'wfs';
          return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this.wmsParams.layers, featureID, queryParams);
        },

        removeWmsParam: function (param) {
            if (this.wmsParams.hasOwnProperty(param)) {
                delete this.wmsParams[param];
            }
        }
    });
  }

   getLayer(url, options) {
      return new this.WmsTileLayer(url, options);
  }
}
