  import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';
import { CaseService } from '@app/ps/services/case.service';
import { ParcelService } from '@app/ps/services/parcel.service';
import { WordService } from '@app/common/services/word.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { TitleCreateSubjectComponent } from '@app/ps/components/title-create-subject/title-create-subject.component';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class TitleDetailCreateCaseSelectionService {

  scope;
  checkedAllOwnerships = {value: false};
  mutuallyExclusiveObligationTypes = new Map([
    ['RentalContractShorterThan1YearLiability', ['RentalContractCommonLiability']],
    ['RentalContractLongerThan1YearLiability', ['RentalContractCommonLiability']],
    ['RentalContractCommonLiability', ['RentalContractShorterThan1YearLiability', 'RentalContractLongerThan1YearLiability']],
  ]);

  constructor(
    private restangular: Restangular,
    private wordService: WordService,
    private caseService: CaseService,
    private listService: ListService,
    private parcelService: ParcelService,
    private dialogService: DialogService
  ) {
    // this.disableByOpinions = this.disableByOpinions.bind(this);
    // this.constructionObjectToCase = this.constructionObjectToCase.bind(this);
    // this.addConstructionObjectsFiltered = this.addConstructionObjectsFiltered.bind(this);
    // this.removeConstructionObjectsFiltered = this.removeConstructionObjectsFiltered.bind(this);
    this.toggleSelectionGroup = this.toggleSelectionGroup.bind(this);
    this.toggleSelectionOwnership = this.toggleSelectionOwnership.bind(this);
    this.toggleSelectionCompensationPrice = this.toggleSelectionCompensationPrice.bind(this);
    this.toggleSelectionEasement = this.toggleSelectionEasement.bind(this);
    this.toggleSelectionConstructionObject = this.toggleSelectionConstructionObject.bind(this);
    this.toggleSelectionBuilding = this.toggleSelectionBuilding.bind(this);
    // this.checkMutuallyExclusiveObligationTypes = this.checkMutuallyExclusiveObligationTypes.bind(this);
    // this.ownershipCases = this.ownershipCases.bind(this);
    // this.getOwnershipDisabledMessage = this.getOwnershipDisabledMessage.bind(this);
    // this.isOwnershipEnabled = this.isOwnershipEnabled.bind(this);
    // this.getGroupDisabledMessage = this.getGroupDisabledMessage.bind(this);
    // this.isGroupEnabled = this.isGroupEnabled.bind(this);
    // this.getEasementDisabledMessage = this.getEasementDisabledMessage.bind(this);
    // this.isEasementEnabled = this.isEasementEnabled.bind(this);
    // this.constructionObjectMultipleTitles = this.constructionObjectMultipleTitles.bind(this);
    // this.getConstructionObjectDisabledMessage = this.getConstructionObjectDisabledMessage.bind(this);
    // this.isConstructionObjectEnabled = this.isConstructionObjectEnabled.bind(this);
    // this.getBuildingDisabledMessage = this.getBuildingDisabledMessage.bind(this);
    // this.isBuildingEnabled = this.isBuildingEnabled.bind(this);
    this.subjectReloadRegister = this.subjectReloadRegister.bind(this);
  }

  disableByOpinions() {
    // enable only one other opinion on contract or any number of expert opinions
    if (this.scope.data.obligation.computePrice && this.scope.data.obligation.objectTypes.occupationGroupKeys && this.scope.data.obligation.objectTypes.occupationGroupKeys.length) {

      const occupationType = _.some(this.scope.data.obligation.objectTypes.occupationGroupKeys, (key) => key === 'underOneYear' || key === 'overOneYear') ? 'T' : 'P';
      let selectedOpinions = _.union(this.scope.dataOpinions);
      let usedAnyParcelOpinion = false;

      const filterOpinionsForGroup = (groupParcel) => {
        // compensationPrice can be used only on one temporary occupation by parcel
        const compensationPriceAlreadyAttached = Object.keys(this.scope.dataOccupationGroupsFiltered)
          .find(key => {
            const group = this.scope.dataOccupationGroupsFiltered[key];
            return key !== 'permanent' && group.find(groupItem => {
              return (
                groupItem.occupations[0].compensationPrice
                && groupItem.occupations[0].id !== groupParcel.occupations[0].id
                && groupItem.occupations[0].parcel.id === groupParcel.parcel.id
              );
            });
          });

        if (occupationType === 'T' && (!groupParcel.occupations[0].compensationPrice || compensationPriceAlreadyAttached)) {
          return [];
        }
        return _.filter(selectedOpinions, (opinion: OpinionModel) => {
          // posudek obsahuje parcelu
          // pokud posudek není pro vlastníka, tak žádný jiný zvolený vlastník nemá pro sebe posudek na tuto parcelu
          // pokud posudek je pro vlastníka, tak nelze zvolit jiného vlastníka
          // kontrolovat parcely, vlastníky a budovy pouze v rámci jednoho lv
          return (opinion.otherOwnership ||
            _.every(this.scope.checklistOwnership.checkedItems, (caseOwnership) => {
                return caseOwnership.ownership.title.id != this.scope.titleId || !_.some(this.scope.dataParcelPrices, {parcel: {id: groupParcel.parcel.id}, opinion: { otherOwnership: {id: caseOwnership.ownership.id}}});
              }
            )) &&
            (!opinion.otherOwnership ||
              (_.every(this.scope.checklistOwnership.checkedItems, (caseOwnership) => {
                  return caseOwnership.ownership.title.id != this.scope.titleId || opinion.otherOwnership.id === caseOwnership.ownership.id;
                }) && _.some(opinion.parcelPrices, {parcel: {id: groupParcel.parcel.id}})
              ));
        });
      };

      const filterOpinionsForBuilding = (building) => {
        return _.filter(selectedOpinions, (opinion: any) => {
          return (opinion.otherOwnership ||
            _.every(this.scope.checklistOwnership.checkedItems, (caseOwnership) => {
                return caseOwnership.ownership.title.id != this.scope.titleId || !_.some(this.scope.dataParcelPrices, {building: {id: building.id}, opinion: { otherOwnership: {id: caseOwnership.ownership.id}}});
              }
            )) &&
            (!opinion.otherOwnership ||
              (_.every(this.scope.checklistOwnership.checkedItems, (caseOwnership) => {
                  return caseOwnership.ownership.title.id != this.scope.titleId || opinion.otherOwnership.id === caseOwnership.ownership.id;
                }) && _.some(opinion.parcelPrices, {building: {id: building.id}})
              ));
        });
      };

      const filterOpinionsForOwnership = (ownership) => {
        return _.filter(selectedOpinions, (opinion: any) => {
          return (opinion.otherOwnership && opinion.otherOwnership.id === ownership.id &&
              _.every(this.scope.checklistGroup.checkedItems, (groupParcel) => {
                return groupParcel.parcel.title.id != this.scope.titleId || _.some(opinion.parcelPrices, {parcel: {id: groupParcel.parcel.id}});
              }) &&
              _.every(this.scope.checklistBuilding.checkedItems, (building) => {
                return building.title.id != this.scope.titleId || _.some(opinion.parcelPrices, {building: {id: building.id}});
              })
            ) ||
            (!opinion.otherOwnership &&
              _.every(this.scope.checklistGroup.checkedItems, (groupParcel) => {
                return groupParcel.parcel.title.id != this.scope.titleId || !_.some(this.scope.dataParcelPrices, {parcel: {id: groupParcel.parcel.id}, opinion: { otherOwnership: {id: ownership.id}}});
              }) &&
              _.every(this.scope.checklistBuilding.checkedItems, (building) => {
                return building.title.id != this.scope.titleId || !_.some(this.scope.dataParcelPrices, {building: {id: building.id}, opinion: { otherOwnership: {id: ownership.id}}});
              })
            );
        });
      };

      this.scope.checklistGroup.checkedItems
        .filter(group => group.parcel.title.id == this.scope.titleId)
        .forEach((groupParcel) => {
        if (occupationType === 'P' || groupParcel.occupations[0].compensationPrice) {
          selectedOpinions = _.intersection(selectedOpinions, filterOpinionsForGroup(groupParcel));
          usedAnyParcelOpinion = true;
        }
      });

      this.scope.checklistBuilding.checkedItems
        .filter(building => building.title.id == this.scope.titleId)
        .forEach((building) => {
        selectedOpinions = _.intersection(selectedOpinions, filterOpinionsForBuilding(building));
      });

      this.scope.checklistOwnership.checkedItems
        .filter(caseOwnership => caseOwnership.ownership.title.id == this.scope.titleId)
        .forEach((caseOwnership) => {
        selectedOpinions = _.intersection(selectedOpinions, filterOpinionsForOwnership(caseOwnership.ownership));
      });

      // walks through available parcels (grouped occupations) and check its existence in opinions. Additionally checks if there is not
      // on opinion that is specific for any selected ownership
      Object.keys(this.scope.dataOccupationGroupsFiltered).forEach(key => {
        const group = this.scope.dataOccupationGroupsFiltered[key];
        group.forEach((groupParcel) => {
          groupParcel.disabled = false;
          groupParcel.disabledCompensationPrice = false;

          if ((this.scope.checklistGroup.checkedItems.length || this.scope.checklistOwnership.checkedItems.length) && !filterOpinionsForGroup(groupParcel).length && (occupationType === 'P' || groupParcel.occupations[0].compensationPrice)) {
            groupParcel.disabled = true;
          }
          if (!groupParcel.occupations[0].compensationPrice) {
            groupParcel.occupations[0].compensationPrice = true;
            if (occupationType === 'T' && groupParcel.parcel.compensationPrice > 0 && !filterOpinionsForGroup(groupParcel).length) {
              groupParcel.disabledCompensationPrice = true;
            }
            groupParcel.occupations[0].compensationPrice = false;
          }
        });
      });

      _.forEach(this.scope.dataBuildingsFiltered, (building) => {
        building.disabled = false;

        if (!filterOpinionsForBuilding(building.building).length) {
          building.disabled = true;
        }
      });

      // walks through available ownerships in same way as in previous code
      _.forEach(this.scope.dataOwnerships, (caseOwnership) => {
        caseOwnership.disabled = false;

        if ((this.scope.checklistGroup.checkedItems.length || this.scope.checklistOwnership.checkedItems.length) && !filterOpinionsForOwnership(caseOwnership.ownership).length && (occupationType === 'P' || usedAnyParcelOpinion)) {
          caseOwnership.disabled = true;
        }
      });
    } else {
      Object.keys(this.scope.dataOccupationGroupsFiltered).forEach((key) => {
        const group = this.scope.dataOccupationGroupsFiltered[key];
        group.forEach(groupParcel => {
          groupParcel.disabled = false;
          groupParcel.disabledCompensationPrice = false;
        });
      });

      _.forEach(this.scope.dataBuildingsFiltered, (building) => {
        building.disabled = false;
      });

      _.forEach(this.scope.dataOwnerships, (caseOwnership) => {
        caseOwnership.disabled = false;
      });
    }
  }

  constructionObjectToCase(constructionObject, selected = true) {
    const co = {
      id: constructionObject.id,
      constructionObject: constructionObject,
      occupations: this.scope.checklistGroupLocal ? _.filter(_.flatMap(this.scope.checklistGroupLocal.checkedItems, 'occupations'), (occupation) => _.some(occupation.constructionObjects, {id: constructionObject.id})) : [],
      easements: this.scope.checklistEasementLocal ? _.filter(this.scope.checklistEasementLocal.checkedItems, easement => _.some(easement.constructionObjects, {id: constructionObject.id})) : [],
      occupationsLength: 0,
      easementsLength: 0,
      selected: selected,
      selectedOriginal: selected,
    };

    return co;
  }

  addConstructionObjectsFiltered(constructionObject, listName, object) {
    let caseConstructionObject = _.find(this.scope.dataConstructionObjectsFiltered, {id: constructionObject.id});
    if (!caseConstructionObject) {
      const found = _.find(this.scope.checklistConstructionObject.checkedItems, {id: constructionObject.id});
      if (found) {
        caseConstructionObject = this.constructionObjectToCase(
          constructionObject,
          found.selectedOriginal && !this.constructionObjectMultipleTitles(constructionObject)
        );
        found.occupations = caseConstructionObject.occupations;
        found.easements = caseConstructionObject.easements;
        this.scope.dataConstructionObjectsFiltered.push(caseConstructionObject);
      }
    }
    if (!caseConstructionObject) {
      caseConstructionObject = this.constructionObjectToCase(constructionObject);
      this.scope.dataConstructionObjectsFiltered.push(caseConstructionObject);
      this.toggleSelectionConstructionObject(caseConstructionObject);
      // this.scope.checkedAllConstructionObjects = false;
    }
    if (listName && object) {
      if (!_.some(caseConstructionObject[listName], {id: object.id})) {
        caseConstructionObject[listName].push(object);
        caseConstructionObject.selected = caseConstructionObject.selectedOriginal && !this.constructionObjectMultipleTitles(constructionObject);
      }
      // Count length specifically for current scope
      caseConstructionObject[listName + 'Length']++;
    }
  }

  removeConstructionObjectsFiltered = (constructionObject, listName, object) => {
    const caseConstructionObject = _.find(this.scope.dataConstructionObjectsFiltered, (caseConstructionObject) => {
      return caseConstructionObject.id === constructionObject.id;
    });
    _.remove(caseConstructionObject[listName], {id: object.id});
    caseConstructionObject[listName + 'Length']--;
    caseConstructionObject.selected = caseConstructionObject.selectedOriginal && !this.constructionObjectMultipleTitles(constructionObject);
    if (caseConstructionObject.occupationsLength === 0 && caseConstructionObject.easementsLength === 0) {
      _.pull(this.scope.dataConstructionObjectsFiltered, caseConstructionObject);
      if (caseConstructionObject.selected && this.scope.checklistConstructionObject.isChecked(caseConstructionObject) && caseConstructionObject.occupations.length === 0 && caseConstructionObject.easements.length === 0) {
        this.scope.checklistConstructionObject.toggleSelection(caseConstructionObject);
        this.scope.checklistConstructionObjectLocal.toggleSelection(caseConstructionObject);
      }
    }
  }

  toggleSelectionGroup(item) {
    if (!this.isGroupEnabled(item)) {
      return;
    }

    const checked = this.scope.checklistGroup.toggleSelection(item);
    this.scope.checklistGroupLocal.toggleSelection(item);

    this.disableByOpinions();

    // update selection of construction objects
    if (this.scope.data.obligation.selectConstructionObjectRequired) {
      item.occupations.forEach((occupation) => {
        occupation.constructionObjects.forEach((constructionObject) => {
          if (!checked) {
            // remove occupation from occupation list and remove selection if not any remaining
            this.removeConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
          } else {
            // add to occupation list or add new contruction object
            this.addConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
          }
        });
      });
    }

    if (!checked) {
      this.scope.checkedAllGroups[item.key] = false;
    }
  }

  toggleSelectionOwnership(item) {
    if (!this.isOwnershipEnabled(item)) {
      return;
    }
    const checked = this.scope.checklistOwnership.toggleSelection(item);
    this.disableByOpinions();

    if (!checked) {
      this.checkedAllOwnerships.value = false;
    }
  }

  toggleSelectionCompensationPrice(item) {
    if (item.disabledCompensationPrice || item.parcel.compensationOpinionsShare !== 100) {
      return;
    }
    item.occupations[0].compensationPrice = !item.occupations[0].compensationPrice;
    this.disableByOpinions();
  }

  toggleSelectionEasement(item) {
    if (!this.isEasementEnabled(item)) {
      return;
    }

    const checked = this.scope.checklistEasement.toggleSelection(item);
    this.scope.checklistEasementLocal.toggleSelection(item);

    // update selection of construction objects
    if (this.scope.data.obligation.selectConstructionObjectRequired) {
      item.constructionObjects.forEach(constructionObject => {
        if (!checked) {
          // remove easement from easement list and remove selection if not any remaining
          this.removeConstructionObjectsFiltered(constructionObject, 'easements', item);
        } else {
          // add to easement list or add new contruction object
          this.addConstructionObjectsFiltered(constructionObject, 'easements', item);
        }
      });
    }

    if (!checked) {
      this.scope.checkedAllEasements = false;
    }

    this.disableByOpinions();
    return checked;
  }

  toggleSelectionConstructionObject(item) {
    if (!this.isConstructionObjectEnabled(item)) {
      return;
    }
    const selected = this.scope.checklistConstructionObject.toggleSelection(item);
    this.scope.checklistConstructionObjectLocal.toggleSelection(item);
    if (!selected) {
      this.scope.checkedAllConstructionObjects = false;
    }
  }

  toggleSelectionBuilding(dataBuilding) {
    if (!this.isBuildingEnabled(dataBuilding)) {
      return;
    } else {
      delete dataBuilding.disabled;
    }

    this.scope.checklistBuilding.toggleSelection(dataBuilding.building);
    const selected = this.scope.checklistBuildingLocal.toggleSelection(dataBuilding.building);
    this.disableByOpinions();
    if (!selected) {
      this.scope.checkedAllBuildings = false;
    }
  }

  checkMutuallyExclusiveObligationTypes(obligationType1, obligationType2) {
    if (obligationType1 === obligationType2) {
      return true;
    }

    const hasRestriction = this.mutuallyExclusiveObligationTypes.get(obligationType1);

    return hasRestriction
      ? hasRestriction.includes(obligationType2)
      : false;
  }

  ownershipCases(dataOwnership) {
    if (this.scope.dataCases === undefined) {
      return [];
    }
    return this.scope.dataCases.filter((businessCase) => {
      // of same or exclusive type. In case of transfer case also must not be observed against cadastre (expected not to be inserted)
      return this.checkMutuallyExclusiveObligationTypes(this.scope.data.obligation.type, businessCase.obligation.type) &&
        (this.scope.data.obligation.type !== 'SalesContractLiability' || businessCase.checkCadastre) &&
        // and containing any of already selected occupations
        ((this.scope.checklistGroup.checkedItems.length > 0 && _.some(businessCase.occupations, (occupation) => {
            return _.some(this.scope.checklistGroup.checkedItems, (group) => {
              return _.some(group.occupations, (groupOccupation) => {
                return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
              });
            });
          })) ||
          // or containing any of already selected easements
          (this.scope.checklistEasement.checkedItems.length > 0 && _.some(businessCase.easements, (caseEasement) => {
            return _.some(this.scope.checklistEasement.checkedItems, (dataEasement) => {
              return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id ===  dataEasement.geometricPlan.id;
            });
          }))
          ||
          // or containing any of already selected buildings
          (this.scope.checklistBuilding.checkedItems.length > 0 && _.some(businessCase.caseBuildings, (caseBuilding) => {
            return _.some(this.scope.checklistBuilding.checkedItems, (dataBuilding) => {
              return dataBuilding.budId === caseBuilding.building.budId;
            });
          }))) &&
        // and containing this ownership
        _.some(businessCase.caseOwnerships, (caseOwnership) => {
          return dataOwnership.ownership.opsubId === caseOwnership.ownership.opsubId;
        }) &&
        // containing any of already selected constructionObjects
        // when no construction object available to select then no need to check, but only if any group is selected
        (!this.scope.data.obligation.selectConstructionObjectRequired || _.some(businessCase.constructionObjects, (caseConstructionObject) => {
          return _.some(this.scope.checklistConstructionObject.checkedItems, (checkedItem) => {
            return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
          });
        }));
    });
  }

  getOwnershipDisabledMessage(dataOwnership) {
    const msgs = [];
    if (this.scope.checklistOwnership.isChecked(dataOwnership)) {
      return msgs;
    }
    if (dataOwnership.disabled) {
      msgs.push('Neplatná kombinace určení ceny');
    }
    // and does not exists any case
    const caseNumbers = this.ownershipCases(dataOwnership).map(businessCase => businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla');
    if (caseNumbers.length) {
      msgs.push('Založit duplicitní smlouvu nelze. Pro vlastníka existuje stávající smlouva na vybranou parcelu (' + _.uniq(caseNumbers).join(', ') + ').');
    }
    return msgs;
  }

  isOwnershipEnabled(dataOwnership) {
    return this.getOwnershipDisabledMessage(dataOwnership).length === 0;
  }

  /**
   * Checks if selection of group is disabled by missing price or ownership combination and returns messages array
   * @param {type} group
   * @returns {String[]}
   */
  getGroupDisabledMessage(group) {
    const msgs = [];
    if (this.scope.checklistGroup.isChecked(group)) {
      return msgs;
    }
    if (group.disabled !== undefined && group.disabled) {
      msgs.push('Neplatná kombinace určení ceny');
    }
    // parcel price for permanent occupation
    if (this.scope.data.obligation.computePrice && (
      (
        group.key === 'permanent' &&
        (
          group.parcel.landPrice === undefined ||
          group.parcel.buildingsPrice === undefined ||
          group.parcel.landPrice === undefined ||
          group.parcel.vegetationPrice === undefined ||
          group.priceSource === ''
        )
      )
      ||
      // parcel price for non permanent occupation
      (
        (group.key === 'underOneYear' || group.key === 'overOneYear') &&
        (
          group.occupations[0].totalRentPrice === undefined ||
          (group.occupations[0].priceAssessmentLandPrice === undefined && group.occupations[0].rentalPriceType !== 'O' && group.occupations[0].rentalPriceUnit !== 'A') ||
          (group.occupations[0].priceAssessmentYear === undefined && group.occupations[0].rentalPriceType === 'F') ||
          (group.occupations[0].rentalExpertOpinionNumber === undefined && group.occupations[0].rentalPriceType === 'E') ||
          (group.occupations[0].rentalExpertOpinionDate === undefined && group.occupations[0].rentalPriceType === 'E') ||
          (group.occupations[0].rentalExpert === undefined && group.occupations[0].rentalExpert === 'E') ||
          group.occupations[0].rentalLength === undefined
        )
      )
    )) {
      msgs.push('Nezadána cena nebo neúplné zadání posudku');
    }
    if (// allow only one checked item
      this.scope.data.obligation.type === 'FulfillmentOfDecisionExpropriationLiability' &&
      !this.scope.checklistEasement.isEmpty()
    ) {
      msgs.push('Typ smlouvy nedovoluje kombinovat zábory a věcná břemena');
    }

    // and does not exists any case
    const caseNumbers = this.scope.dataCases.filter((businessCase) => {
      // of same or exclusive type
      return this.checkMutuallyExclusiveObligationTypes(this.scope.data.obligation.type, businessCase.obligation.type) &&
        // and containing this occupations
        businessCase.occupations.some((occupation) => {
          return group.occupations.some((groupOccupation) => {
            return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
          });
        }) &&
        // and containing any of already selected ownerships
        businessCase.caseOwnerships.some((caseOwnership) => {
          return this.scope.checklistOwnership.checkedItems.some((checkedItem) => {
            return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
          });
        }) &&
        // containing any of already selected constructionObjects
        // when no construction object available to select then no need to check
        (!this.scope.data.obligation.selectConstructionObjectRequired || businessCase.constructionObjects.some((caseConstructionObject) => {
          return this.scope.checklistConstructionObject.checkedItems.some((checkedItem) => {
            return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
          });
        }));
    }).map(businessCase => businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla');
    if (caseNumbers.length) {
      msgs.push('Založit duplicitní smlouvu nelze. Na parcelu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
    }
    return msgs;
  }

  /**
   * Checks if selection of ownerships does not prevent from group selection and price data are filled in
   * @param {type} group
   * @returns {Boolean}
   */
  isGroupEnabled(group) {
    return this.getGroupDisabledMessage(group).length === 0;
  }

  getEasementDisabledMessage(dataEasement) {
    const msgs = [];
    if (this.scope.checklistEasement.isChecked(dataEasement)) {
      return msgs;
    }
    if (// allow only with price
      this.scope.data.obligation.computePrice &&
      dataEasement.easementPrice === undefined &&
      !this.caseService.easementPriceByShare(this.scope.data.obligation)
    ) {
      if (new Set(dataEasement.constructionObjects.map(co => co.easementGlobalPriceType)).size > 1 ||
        new Set(dataEasement.constructionObjects.map(co => co.easementGlobalOtherUnitType)).size > 1 ||
        new Set(dataEasement.constructionObjects.map(co => co.easementGlobalOtherLengthPrice)).size > 1 ||
        new Set(dataEasement.constructionObjects.map(co => co.easementGlobalOtherAreaPrice)).size > 1 ||
        new Set(dataEasement.constructionObjects.map(co => co.easementGlobalOtherSinglePrice)).size > 1) {
        msgs.push('Zadejte shodnou cenu VB všech souvisejích ' + this.wordService.getTranslation('CONSTRUCTION_OBJECT_PLURAL_GENITIV'));
      } else {
        msgs.push('Nezadána cena nebo neúplné zadání posudku');
      }
    }
    if (dataEasement.constructionObjects.some(co => !co.administrator)) {
      msgs.push('Nezadán správce ' + this.wordService.getTranslation('CONSTRUCTION_OBJECT_GENITIV'));
    }
    if  (// allow only one checked item
      this.scope.data.obligation.type === 'FulfillmentOfDecisionExpropriationLiability' &&
      !this.scope.checklistGroup.isEmpty()
    ) {
      msgs.push('Typ smlouvy nedovoluje kombinovat zábory a věcná břemena');
    }
    // and does not exists any case
    const caseNumbers = this.scope.dataCases.filter((businessCase) => {
      // of same or exclusive type
      return this.checkMutuallyExclusiveObligationTypes(this.scope.data.obligation.type, businessCase.obligation.type) &&
        // and containing any of already selected easements
        businessCase.easements.some((caseEasement) => {
          return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id === dataEasement.geometricPlan.id;
        }) &&
        // and containing any of already selected ownerships
        (this.scope.data.obligation.type === 'RealBurdenLiability' || this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated' || // only one case allowed globally
          businessCase.caseOwnerships.some((caseOwnership) => {
            return this.scope.checklistOwnership.checkedItems.some((checkedItem) => {
              return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
            });
          })) &&
        // containing any of already selected constructionObjects
        // when no construction object available to select then no need to check
        (!this.scope.data.obligation.selectConstructionObjectRequired || businessCase.constructionObjects.some((caseConstructionObject) => {
          return this.scope.checklistConstructionObject.checkedItems.some((checkedItem) => {
            return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
          });
        }));
    }).map(businessCase => businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla');
    if (caseNumbers.length) {
      if (this.scope.data.obligation.type === 'RealBurdenLiability' || this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated') {
        msgs.push('Na parcelu existuje stávající smlouva (' + _.uniq(caseNumbers).join(', ') + ').');
      } else {
        msgs.push('Založit duplicitní smlouvu nelze. Na parcelu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
      }
    }
    if (dataEasement.geometricPlan.fiktivni && (this.scope.data.obligation.type === 'RealBurdenLiability' || this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated')) {
      msgs.push('Na VB není do aplikace vložen potvrzený geometrický plán, data pochází ze záborového elaborátu. Tento typ případu nelze založit.');
    }
    return msgs;
  }

  isEasementEnabled(dataEasement) {
    return this.getEasementDisabledMessage(dataEasement).length === 0;
  }

  constructionObjectMultipleTitles(constructionObject) {
    const occupations = _.filter(_.flatMap(this.scope.checklistGroupLocal.checkedItems, 'occupations'), (occupation) => _.some(occupation.constructionObjects, {id: constructionObject.id}));
    const easements = _.filter(this.scope.checklistEasementLocal.checkedItems, easement => _.some(easement.constructionObjects, {id: constructionObject.id}));
    return _.union(_.map(occupations, 'parcel.title.id'), _.map(easements, 'parcel.title.id')).length > 1;
  }

  getConstructionObjectDisabledMessage(dataConstructionObject) {
    const msgs = [];
    if (this.scope.checklistConstructionObject.isChecked(dataConstructionObject)) {
      return msgs;
    }
    // and does not exists any case
    const caseNumbers = this.scope.dataCases.filter((businessCase) => {
      // of same or exclusive type
      return this.checkMutuallyExclusiveObligationTypes(this.scope.data.obligation.type, businessCase.obligation.type) &&
        // containing this constructionObject
        businessCase.constructionObjects.some((caseConstructionObject) => {
          return caseConstructionObject.sousek === dataConstructionObject.constructionObject.sousek && caseConstructionObject.socis === dataConstructionObject.constructionObject.socis;
        }) &&
        // and containing any of already selected occupations or easements
        (businessCase.occupations.some((occupation) => {
          return this.scope.checklistGroup.checkedItems.some((group) => {
            return group.occupations.some((groupOccupation) => {
              return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
            });
          });
        }) || businessCase.easements.some((caseEasement) => {
          return this.scope.checklistEasement.checkedItems.some((dataEasement) => {
            return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id === dataEasement.geometricPlan.id;
          });
        })) &&
        // and containing any of already selected ownerships
        businessCase.caseOwnerships.some((caseOwnership) => {
          return this.scope.checklistOwnership.checkedItems.some((checkedItem) => {
            return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
          });
        });
    }).map(businessCase => businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla');
    if (caseNumbers.length) {
      msgs.push('Založit duplicitní smlouvu nelze. Existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
    }
    return msgs;
  }

  isConstructionObjectEnabled(dataConstructionObject) {
    const checked = this.scope.checklistConstructionObject.isChecked(dataConstructionObject);
    // is already checked; If selection is caused by different title then disable deselection
    // allow deselection only if all easements and occupations are on same title (presumably current)
    return (checked && dataConstructionObject.selected) ||
      (!checked && this.getConstructionObjectDisabledMessage(dataConstructionObject).length === 0);
  }

  getBuildingDisabledMessage(dataBuilding) {
    const msgs = [];
    if (this.scope.checklistBuilding.isChecked(dataBuilding.building)) {
      return msgs;
    }
    if (// allow only with price
      this.scope.data.obligation.computePrice &&
      dataBuilding.building.buildingsPrice === undefined
    ) {
      msgs.push('Nezadána cena nebo neúplné zadání posudku');
    }
    if (dataBuilding.disabled) {
      msgs.push('Neplatná kombinace určení ceny');
    }
    // and does not exists any case
    const caseNumbers = this.scope.dataCases.filter((businessCase) => {
      // of same or exclusive type
      return this.checkMutuallyExclusiveObligationTypes(this.scope.data.obligation.type, businessCase.obligation.type) &&
        // and containing any of already selected building
        businessCase.caseBuildings.some((caseBuilding) => {
          return dataBuilding.building.budId === caseBuilding.building.budId;
        }) &&
        // and containing any of already selected ownerships
        businessCase.caseOwnerships.some((caseOwnership) => {
          return this.scope.checklistOwnership.checkedItems.some((checkedItem) => {
            return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
          });
        });
    }).map(businessCase => businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla');
    if (caseNumbers.length) {
      msgs.push('Založit duplicitní smlouvu nelze. Na budovu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
    }
    return msgs;
  }

  isBuildingEnabled(dataBuilding) {
    return this.getBuildingDisabledMessage(dataBuilding).length === 0;
  }

  subjectReloadRegister(subjectReload) {
    this.scope.subjectReload = subjectReload;
  }

  subjectChanged(newVal) {

    // remove already added
    this.scope.checklistOwnership.empty();

    // add new
    if (!!newVal) {
      const ownership = {id: 0, cases: [],
        ownership: {
          subjects: [newVal],
          opsubId: newVal.opsubId
        }
      };
      if (this.isOwnershipEnabled(ownership)) {
        this.scope.checklistOwnership.toggleSelection(ownership);
      } else {
        this.dialogService.alertDialogPromise('Případ pro tuto osobu a vybranou parcelu již existuje.');
      }
    }
  }

  createSubject() {
    const dialog = this.dialogService.open(TitleCreateSubjectComponent, {
      className: ClassName.ADJUSTED_DIALOG,
      data: {},
    });

    const sub = dialog.afterClosed.subscribe((result: any | boolean) => {
      if (result) {
        this.subjectChanged(result);
        this.scope.subjectReload();
      }
      sub.unsubscribe();
    });
  }

  refreshItems(obligation) {
    this.scope.dataOccupationGroupsFiltered = {};
    this.scope.dataConstructionObjectsFiltered = [];
    this.scope.dataBuildingsFiltered = [];

    if (obligation) {
      // determine which objects can be included on case of current type
      const objectTypes = obligation.objectTypes;
      if (objectTypes) {
        if (objectTypes.occupations) {
          this.scope.dataOccupationGroupsFiltered = {...this.scope.dataOccupationGroups};
          delete this.scope.dataOccupationGroupsFiltered['permanentWithSolutionType'];
          delete this.scope.dataOccupationGroupsFiltered['permanentWithSolutionTypeUnderOneYear'];
          delete this.scope.dataOccupationGroupsFiltered['permanentWithSolutionTypeOverOneYear'];
        } else if (objectTypes.occupationGroupKeys) {
          this.scope.dataOccupationGroupsFiltered = {};
          Object.keys(this.scope.dataOccupationGroups).forEach((key) => {
            if ((!key.startsWith('permanentWithSolutionType') && objectTypes.occupationGroupKeys.includes(key)) ||
              (key == 'permanentWithSolutionType' && objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeUnderOneYear') && objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeOverOneYear')) ||
              (key == 'permanentWithSolutionTypeUnderOneYear' && objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeUnderOneYear') && !objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeOverOneYear')) ||
              (key == 'permanentWithSolutionTypeOverOneYear' && !objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeUnderOneYear') && objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeOverOneYear'))
            ) {
              this.scope.dataOccupationGroupsFiltered[key] = this.scope.dataOccupationGroups[key];
            }
          });
        }
        if (objectTypes.buildings) {
          this.scope.dataBuildingsFiltered = this.scope.dataBuildings;
        }
      }

      // update locally checked items
      Object.keys(this.scope.dataOccupationGroupsFiltered).forEach((key) => {
        const group = this.scope.dataOccupationGroupsFiltered[key];
        group.forEach(groupItem => {
          if (this.scope.checklistGroup.isChecked(groupItem)) {
            this.scope.checklistGroupLocal.toggleSelection(groupItem);
          }
        });
      });

      // update locally checked items
      this.scope.dataEasements.forEach((easement) => {
        if (this.scope.checklistEasement.isChecked(easement)) {
          this.scope.checklistEasementLocal.checkedItems.push(easement);
        }
      });
      // update locally checked items
      this.scope.dataBuildingsFiltered.forEach((building) => {
        if (this.scope.checklistBuilding.isChecked(building)) {
          this.scope.checklistBuildingLocal.checkedItems.push(building);
        }
      });

      if (this.scope.data.obligation.selectConstructionObjectRequired) {
        this.scope.checklistGroupLocal.checkedItems.forEach((group) => {
          group.occupations.forEach((occupation) => {
            occupation.constructionObjects.forEach((constructionObject) => {
              this.addConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
            });
          });
        });

        this.scope.checklistEasementLocal.checkedItems.forEach((easement) => {
          easement.constructionObjects.forEach((constructionObject) => {
            this.addConstructionObjectsFiltered(constructionObject, 'easements', easement);
          });
        });

        // update locally checked items
        this.scope.dataConstructionObjectsFiltered.forEach((co) => {
          if (this.scope.checklistConstructionObject.isChecked(co)) {
            this.scope.checklistConstructionObjectLocal.checkedItems.push(co);
          }
        });
      }

      const toPriceSource = (filter) => _.reduce(
        _.uniqBy(
          _.filter(this.scope.dataParcelPrices, filter), (parcelPrice: any) => {
            return (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
          }),
        (result, parcelPrice) => {
          const priceSource = (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
          if (!priceSource) {
            return result;
          } else {
            return result += (result ? ', ' : '') + priceSource;
          }
        },
        ''
      );

      Object.keys(this.scope.dataOccupationGroupsFiltered).forEach((key) => {
        const group = this.scope.dataOccupationGroupsFiltered[key];
        group.forEach(groupItem => {
          groupItem.disabled = false;

          if (key === 'permanent' && obligation.computePrice) {
            groupItem.priceSource = toPriceSource({parcel: {id: groupItem.parcel.id}});
          }
        });
      });

      this.scope.dataBuildingsFiltered.forEach((buildingData) => {
        buildingData.disabled = false;

        if (obligation.computePrice) {
          buildingData.priceSource = toPriceSource({building: {id: buildingData.building.id}});
        }
      });
      this.disableByOpinions();
    }
  }

  // when obligation changes all selection are reset to default.
  obligationChanged(obligation) {
    this.scope.checklistOwnership.empty();
    this.scope.checklistGroup.empty();
    this.scope.checklistEasement.empty();
    this.scope.checklistConstructionObject.empty();
    this.scope.checklistBuilding.empty();

    this.scope.checklistGroupLocal.empty();
    this.scope.checklistEasementLocal.empty();
    this.scope.checklistBuildingLocal.empty();

    this.scope.checkedAllConstructionObjects = false;
    this.scope.checkedAllEasements = false;
    this.scope.checkedAllGroups = {};
    this.scope.checkedAllBuildings = false;

    this.refreshItems(obligation);
  }

  toggleAll(check, checklist, allItems, toggleSelectionFunction) {
    allItems.forEach((item) => {
      if (checklist.isChecked(item) === !check) {
        toggleSelectionFunction(item);
      }
    });
  }
}
