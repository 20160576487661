import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogInjector } from './dialog-injector';
import { DialogConfig } from '../models/dialog-config';
import { DialogRef } from './dialog-ref';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { AlertComponent } from '@app/common/components/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogComponentRef: ComponentRef<DialogComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) { }

  public open(componentType: Type<any>, config: DialogConfig) {
    const dialogRef = this.appendDialogComponentToBody(config);
    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  public confirmDialogPromise(confirmation: string): Promise<boolean> {
    return new Promise(resolve => {
      const dialog = this.open(ConfirmationComponent, {
        data: {
          msg: confirmation,
        },
        className: ClassName.ADJUSTED_DIALOG,
      });

      const sub = dialog.afterClosed.subscribe((result: boolean) => {
        sub.unsubscribe();
        resolve(result);
      });
    });
  }

  public alertDialogPromise(msg: string): Promise<boolean> {
    return new Promise(resolve => {
      const dialog = this.open(AlertComponent, {
        data: {
          msg: msg,
        },
        className: ClassName.ADJUSTED_DIALOG,
      });

      const sub = dialog.afterClosed.subscribe((result: boolean) => {
        sub.unsubscribe();
        resolve(result);
      });
    });
  }

  public appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    const dialogRef = new DialogRef();
    map.set(DialogConfig, config);
    map.set(DialogRef, dialogRef);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(new DialogInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody(componentRef);
      sub.unsubscribe();
    });

    return dialogRef;
  }

  private removeDialogComponentFromBody(componentRef:any) {
    this.appRef.detachView(componentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
