import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { TableService } from '@app/ps/services/table.service';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { ArrayUtils } from '@app/common/utils/array.utils';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { OccupationTypeModel } from '@app/ps/models/occupation-type.model';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'occupation-list',
  templateUrl: './occupation-list.component.html',
  styleUrls: ['./occupation-list.component.scss']
})
export class OccupationListComponent implements OnInit {

  static readonly COLLECTIONS = [
    'constructionObjects',
    'parcel.title.ownerships',
  ];

  readonly inputValidities = [
    { id: 'valid_with_parcel', name: 'Platný' },
    { id: 'invalid', name: 'Neplatný' },
  ];

  list: ListModel<OccupationModel>;
  pageableList: PageableList;
  isArray = Array.isArray;
  occupationTypesPermanent: OccupationTypeModel[];
  occupationTypesTemporary: OccupationTypeModel[];
  rowspan: number;
  permanentOccupationRowspan: number;
  temporaryOccupationRowspan: number;
  exportEnable = true;
  settingsLoaded = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public titleNamePipe: TitleNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private tableService: TableService,
    private stateService: StateService,
    private authService: AuthService,
    private exportListService: ExportListService
  ) {
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }

    const occupationTypes = JSON.parse(JSON.stringify(this.authService.getActualProject().occupationTypes));

    this.occupationTypesPermanent = _.filter(occupationTypes, (occupationType) => {
      return ['permanent'].includes(occupationType.category);
    });
    this.occupationTypesTemporary = _.filter(occupationTypes, (occupationType) => {
      return ['underOneYear', 'overOneYear'].includes(occupationType.category);
    });

    this.removeCommonStringFromTableHeading(this.occupationTypesPermanent, 'trvalý zábor');
    this.removeCommonStringFromTableHeading(this.occupationTypesTemporary, 'dočasný zábor');

    if (this.occupationTypesTemporary.length) {
      this.occupationTypesTemporary.push({ name: 'Délka nájmu (měsíc)', id: -1, number: undefined, category: 'rental_length' });
    }

    this.rowspan = (this.shouldHaveSecondRow() ? 2 : 1);
    this.permanentOccupationRowspan = (this.shouldHaveSecondRow() && this.shouldDrawSecondRowOf(this.occupationTypesPermanent) ? 1 : 2);
    this.temporaryOccupationRowspan = (this.shouldHaveSecondRow() && this.shouldDrawSecondRowOf(this.occupationTypesTemporary) ? 1 : 2);

    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('cases');

    const occupationTypes = this.authService.getActualProject().occupationTypes;
    const columnDefinitions = [];

    for (const occupationType of occupationTypes) {
      columnDefinitions.push({
        name: occupationType.name,
        key: 'zabvym',
        formatByEval: 'var result = listItem.zabtyp == ' + occupationType.number + ' ? (listItem.zabvym + "") : ""; result;',
      });
    }

    if (this.occupationTypesTemporary.length) {
      columnDefinitions.push({
        name: 'Délka nájmu (měsíc)',
        key: 'rentalLength'
      });
    }

    return this.exportListService
      .exportList('occupations', 'occupations', filter, {}, false, columnDefinitions)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  removeCommonStringFromTableHeading(heading: OccupationTypeModel[], commonString: string) {
    const headingNames = _.concat(_.map(heading, 'name'), commonString);
    const replaceFn = (str) => str.toLowerCase().replace(ArrayUtils.getLongestCommonSubstring(headingNames, true), '').trim();
    const wouldBeEmpty = heading.some((cur) => replaceFn(cur.name) === '');

    if (wouldBeEmpty && heading.length > 1) { // don't prevent rows merging in projects with just one occupation type
      return;
    }

    _.forEach(heading, (obj) => {
      obj.name = replaceFn(obj.name);
    });
  }

  shouldDrawSecondRowOf(what: OccupationTypeModel[]): boolean {
    return !(what.length === 1 && what[0].name === '');
  }

  shouldHaveSecondRow(): boolean {
    const temp = [ ...this.occupationTypesPermanent, ...this.occupationTypesTemporary ];
    return temp.filter((item) => item.name).length > 0;
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('occupations', columnId);
  }

  private getDefaultFilter() {
    return {
      filters: {
        validOrWithCase: true,
        areaId: { values: [], negation: false },
        titleId: {},
        parcelId: {},
        occupationTypeId: {},
        constructionObjectStageId: {},
        constructionObjectId: { values: [], negation: false },
        validityParcelCase: {},
        searchText: { values: [{ id: '' }]},
        loadCollections: OccupationListComponent.COLLECTIONS
      },
      sortOrder: { sortBy: 'zablv', direction: 'asc' },
    };
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'occupations',
      this.getDefaultFilter(),
      'occupationsFilter'
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
