import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectService } from '@app/ps/services/project.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ProjectRemoveComponent } from '@app/ps/project-settings/components/project-remove/project-remove.component';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'project-settings-main',
  templateUrl: './project-settings-main.component.html',
})
export class ProjectSettingsMainComponent implements OnInit {

  user = this.authService.getUser();
  useTemplatesService = this.authService.getActualProject().useTemplatesService;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private projectService: ProjectService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this.onRemoveProject = this.onRemoveProject.bind(this);
  }

  ngOnInit() {
  }

  onRemoveProject() {
    const project = this.authService.getActualProject();
    const dialog = this.dialogService.open(ProjectRemoveComponent, { data: { project: project }});
    const sub = dialog.afterClosed.subscribe(() => {
      sub.unsubscribe();
    });
  }
}
