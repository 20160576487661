<fieldset>
  <div *ngFor="let formField of config" class="column-100">
    <gmt-input [label]="formField.name" [required]="formField.required" [readonly]="!!formField.value">
      <ng-container *ngIf="formField.value">{{formField.value}}</ng-container>
      <ng-container *ngIf="!formField.value">
        <input *ngIf="formField.type === 'date'" type="text" pick-a-date [ngModel]="data[formField.field]" [maxToday]="!formField.allowFutureDate" (ngModelChange)="onChange($event, formField)"/>
        <input *ngIf="formField.type === 'string'" type="text" [ngModel]="data[formField.field]" (ngModelChange)="onChange($event, formField)"/>
        <gmt-switch *ngIf="formField.type === 'switch'" [selectedId]="data[formField.field]" [options]="formField.options" (selectedIdChange)="onChange($event, formField)"></gmt-switch>
        <gmt-select *ngIf="formField.type === 'select'" [data]="data" [field]="formField.field" [required]="formField.required" [optionItems]="formField.options" (changed)="onChange($event.newValue, formField)"></gmt-select>
      </ng-container>
    </gmt-input>
  </div>
</fieldset>
