import { Ng2StateDeclaration } from '@uirouter/angular';

import { MapComponent } from './components/map/map.component';

export const mapState: Ng2StateDeclaration = {
  name: 'dimap.project.map',
  data: {
    title: 'Mapa',
  },
  url: '/map?parcelId&titleId&intersectionId&divisionParcelId',
  params: {
    parcelId: {type: 'int'},
    titleId: {type: 'int'},
    intersectionId: {type: 'int'},
    divisionParcelId: {type: 'int'},
  },
  views: {
    'content@dimap.project': {
      component: MapComponent,
    }
  }
};
