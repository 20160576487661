import { Component, Input, Output, OnInit, OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { MapInfoboxService } from '@app/map/services/map-infobox.service';
import { MapService } from '@app/map/services/map.service';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { MapLayersStorageService } from '@app/map/services/map-layers-storage.service';
import { AuthService } from '@app/common/services/auth.service';
import * as L from 'leaflet';

// const MESSAGEDEFAULT = 'Kliknutím do mapy vyžádejte informace.';
const MESSAGEDEFAULT = null;
const MESSAGEEMPTY = 'Nic nenalezeno';

@Component({
  selector: 'map-module-info',
  templateUrl: './map-module-info.component.html',
})
export class MapModuleInfoComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() mapId: string;
  @Output()
  visibilityInfoboxCallback = new EventEmitter<any>();
  @Input()
  infoboxWidthGetter: Function;
  public mapinfodata;
  public featureItems: any[];
  public marker;
  public message = MESSAGEDEFAULT;
  public loading = false;
  private msgTimeout = null;
  private FIRSTRENDER = true;

  constructor(
    private mapInfoboxService: MapInfoboxService,
    private mapService: MapService,
    private elementRef: ElementRef,
    private mapHighlightService: MapHighlightService,
    private mapLayersStorage: MapLayersStorageService,
    private authService: AuthService,
  ) {
    this.onFeatureDataLoaded = this.onFeatureDataLoaded.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  ngOnInit() {
    this.mapinfodata = null;
    this.featureItems = [];
    this.marker = null;
    this.onClose();
    this.mapInfoboxService.register(this.mapId, this.config, this.clickHandler, this.onFeatureDataLoaded);
  }

  ngOnDestroy() {
    this.mapInfoboxService.deregister(this.mapId);
    // remove marker
    if (this.marker) {
      this.marker.remove();
    }

    this.mapinfodata = null;
    this.featureItems = null;

    // keep highlight for print
    // this.mapHighlightService.removeHighlight(this.mapId);
    this.FIRSTRENDER = false;
    if(this.msgTimeout) {
      this.stopTimeout();
    }
  }

  onClose() {
    this.visibilityInfoboxCallback.emit({infoboxVisibility:false});
    if(this.marker) {
      this.marker.remove();
      // keep highlight for print
      // this.mapHighlightService.removeHighlight(this.mapId);
    }

    this.message = MESSAGEDEFAULT;
    if(this.msgTimeout) {
      this.stopTimeout();
    }

  }

  clickHandler(data, clickEvt) {
    (<any>window).GoogleAnalytics('send', {
      hitType: 'event',
      eventCategory: 'map',
      eventAction: 'click',
    });

    this.visibilityInfoboxCallback.emit({infoboxVisibility:true});
    this.featureItems = [];
    if (data && data.featureDataLoaded) {
      this.mapinfodata = data;
    } else {
      this.mapinfodata = null;
    }
    //add marker
    if(this.marker) {
      this.marker.remove();
    }

    this.mapService.getMap(this.mapId).then((map) => {
      const icon = L.icon({
        iconUrl: require('src/img/map/marker-icon.png'),
        iconSize:     [25, 41],
        iconAnchor:   [13, 41]
      });
      this.marker = L.marker(clickEvt.latlng,{icon: icon}).addTo(map);
    });
  }

  /**
  Zpracování dat jedné vrstrvy
  */
  onFeatureDataLoaded(featureData) {
    if (this.msgTimeout) {
      this.stopTimeout();
    }
    this.loading = this.mapinfodata && this.mapinfodata.featureDataLoaded.includes(false);

    // if nothing found, show message for 3000ms
    if (!this.loading && ((this.mapinfodata && this.mapinfodata.featureData.length === 0) || !this.mapinfodata) && !this.msgTimeout) {
      this.message = MESSAGEEMPTY;
      this.mapinfodata = null;
      this.msgTimeout = setTimeout(() => {
        this.onClose();
      }, 3000);
    }

    for (const lastItem of featureData) {
      const item = <any>{};
      item.cadastreDataDate = this.authService.getActualProject().cadastreDataDate;
      item.featureData = lastItem;
      item.highlightFeature = lastItem.templateProperties.highlightFeature !== undefined ? lastItem.templateProperties.highlightFeature : (featureID, layerID) => {
        this.mapHighlightService.removeHighlight(this.mapId);
        this.mapLayersStorage.getLayers(this.mapId).then((layers) => {
          const layer = this.mapLayersStorage.getLayerById(layers, layerID);

          layer.loadFeatureDataByID(featureID, {
            t: this.authService.getToken(),
            p: this.authService.getActualProject().key,
          }).then((res) => {
            const feature = res;
            this.mapHighlightService.highlightFeature(this.mapId, feature, {
              paddingTopLeft: [10, 10],
              paddingBottomRight: [this.infoboxWidthGetter(), 10]
            });
          });
        });
      };
      Object.assign(item, lastItem.templateProperties.scope || {});
      item.jsonParse = (jsonString) => {
        try {
          return JSON.parse(jsonString);
        } catch (e) {
          return '';
        }
      };

      item.ownershipsParse = (jsonString) => {
        const ownerships = item.jsonParse(jsonString);

        if (!ownerships) {
          return [];
        }

        const notCzechRepublicFilter = (o) => {
          return !(
            o.typrav_kod === 30
            && o.subjects.length === 1
            && o.subjects[0].nazev === 'Česká republika'
          );
        };

        return ownerships
          .filter(notCzechRepublicFilter)
          .sort((a, b) => b.typrav_kod - a.typrav_kod);
      };

      item.header = (typeof lastItem.templateProperties.getHeader === 'function') ? lastItem.templateProperties.getHeader(item) : lastItem.templateProperties.header;
      item.rows = [];
      lastItem.templateProperties.rows.forEach(row => {
        if (typeof row.show !== 'function' || row.show(item)) {
          item.rows.push({
            data: row.data(item),
            label: (typeof row.getLabel === 'function') ? row.getLabel(item) : row.label,
            template: row.template,
            className: row.className,
          });
        }
      });
      this.featureItems.push(item);
    }
  }

  stopTimeout() {
    window.clearTimeout(this.msgTimeout);
    this.msgTimeout = null;
  }
}
