import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 import * as  L from 'leaflet';
import * as _ from 'lodash';
import { LeafletWmsCommonService } from '@app/map/services/leaflet-wms-common.service';
import { MapUtilsWfsFeatureService } from '@app/map/services/map-utils-wfs-feature.service';
import { MapUtilsCqlFilterService } from '@app/map/services/map-utils-cql-filter.service';

@Injectable({
  providedIn: 'root'
})
export class LeafletTileLayerWfs {
    private WfsTileLayer

    constructor(
         http: HttpClient,
         leafletWmsCommonService: LeafletWmsCommonService,
         mapUtilsCqlFilterService: MapUtilsCqlFilterService,
         mapUtilsWFSFeatureService: MapUtilsWfsFeatureService
    ) {
      this.WfsTileLayer = L.TileLayer.extend({
         defaultWfsParams: {
            service: 'WFS',
            request: 'GetFeature',
            version: '2.0.0',
            typeNames: '',
            outputFormat: 'application/json',
            startIndex: 0,
            count: 100,
            srsName: 'EPSG:5514',
        },
        initialize: function (url, options) {
            if(!options || !options.id) {
                throw new Error("Missing layer id option in initialization.");
            }

            this._id = options.id;
            this._url = url;
            this._wfsParams = L.extend(this.defaultWfsParams, options.wfs);
            this._filter = options.filter;
            this._geoJson = options.geojson;
            this._wfsFormatParams = options.format;
            this._geometryColumn = options.geometryColumn || 'geom';
            this._idColumn = options.idColumn || 'id';
            this._limit = options.limit || 2000;

            L.setOptions(this, options);
            this._httpService = http;
            this._features = {};
        },
        onAdd: function (map) {
            this._crs = this.options.crs || map.options.crs;

            L.TileLayer.prototype.onAdd.call(this, map);
        },
        _getFormatString: function (obj) {
            var params = [];
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    params.push(i + ':' + obj[i]);
                }
            }
            return params.join(';');
        },
        _getRequestUrl: function (_wfsParams, coords) {
            var ne = coords.getNorthEast();
            var sw = coords.getSouthWest();
            var bbox = sw.x + ',' + sw.y + ',' + ne.x + ',' + ne.y;
            // we need to supply BBOX as part of filter, because we cannot specify both bbox and filter
            const filters = [];
            if (this._filter) {
              filters.push(this._filter);
            }
            var bboxFilter = {
                type: 'BBOX',
                propertyName: this._geometryColumn,
                coordinates: bbox
            };
            filters.push(bboxFilter);
            const requestFilter = filters.length > 1 ? {
                type: 'And',
                args: filters,
            } : filters[0];
            _wfsParams['cql_filter'] = mapUtilsCqlFilterService.getFilter(requestFilter);

            // prepare format params
            var fo = this._getFormatString(this._wfsFormatParams);

            return this._url +
                    L.Util.getParamString(_wfsParams, this._url, this._uppercase) +
                    (this._uppercase ? '&FORMAT_OPTIONS=' : '&format_options=') + fo;
        },
        getTileUrl: function (tilePoint) {
            var map = this._map;
            var tileSize = this.options.tileSize;
            var nwPoint = tilePoint.multiplyBy(tileSize);
            var sePoint = nwPoint.add([tileSize, tileSize]);
            var nw = this._crs.project(map.unproject(nwPoint, tilePoint.z));
            var se = this._crs.project(map.unproject(sePoint, tilePoint.z));
            var sw = L.latLng(nw.y, se.x);
            var ne = L.latLng(se.y, nw.x);
            var coords = L.latLngBounds(sw, ne);

            var _wfsParams = _.cloneDeep(this._wfsParams);

            var url = this._getRequestUrl(_wfsParams, coords);

            return url;
        },
        _loadTile: function (tile, tilePoint) {
            var url = this.getTileUrl(tilePoint);

            var _this = this;
            this._httpService.get(url).toPromise()
                    .then(function (data) {
                        //                    var featureLayer = L.Proj.geoJson(data, this._geoJson);
                        //                    tile.addLayer(featureLayer);
                        //                    tile.addData(data);
                        // TODO: refactor to separate function
                        for (var i = 0; i < data.features.length; i++) {
                            if (!(data.features[i][this._idColumn] in _this._features)) {
                                _this._features[data.features[i][this._idColumn]] = true;
                                // add crs to feature data
                                if ("crs" in data) {
                                    data.features[i].crs = data.crs;
                                }
                                var featureLayer = L.Proj.geoJson(data.features[i], _this._geoJson);
                                tile.addLayer(featureLayer);
                            }
                        }

                        // TODO: record feature id in tile
                    });

            this.fire('tileloadstart', {
                tile: tile,
                url: url
            });
        },
        _addTile: function (tilePoint) {
            // create new tile
            // skip _getTail and _createTile since we don't reuse tiles
            var tile = L.layerGroup(); //L.Proj.geoJson([], this._geoJson);

            this._tiles[tilePoint.x + ':' + tilePoint.y] = tile;

            this._loadTile(tile, tilePoint);

            this._map.addLayer(tile);
        },
        loadFeatureData: function (options) {
            var filter = null;
            if (this._filter) {
                filter = mapUtilsCqlFilterService.getFilter(this._filter);
            }

            return leafletWmsCommonService.loadFeatureData({
              latlng: options.latlng,
              url: this._url,
              uppercase: this._uppercase,
              httpService: this._httpService,
              map: this._map,
              crs: this._crs,
              layers: this._wfsParams['typeNames'],
              cqlFilter: filter,
              featureCount: this._wfsParams['feature_count']
            });
        },
        loadFeatureDataByID: function (featureID) {
          var wfsBaseURL = this._url;
          const queryParams = undefined; // TODO missing
          return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this.wfsParams.layers, featureID, queryParams);
        }
      });
    }

    getLayer(url, options) {
        return new this.WfsTileLayer(url, options);
    }
}
