<ng-template #emptySubject>
  <span>-</span>
</ng-template>

<div class="detail" [loading]="!loaded">
  <div style="display:flex">
    <h1>{{ 'ps.projectInfo' | brandTranslate }}</h1>
    <div class="actions">
      <a class="button" *ngIf="APP_BRAND.NAME === 'RSD' || APP_BRAND.NAME === 'SZ'" gmtAuth="manager_report"  uiSref="landing.managerReportProjects.detail" target="_blank" [uiParams]="{ projectKey: project.key }">Manažerský report akce</a>
    </div>
  </div>

  <div *ngIf="loaded">
    <div class="mt-30 mb-70 clearfix">
      <div class="edit" *ngIf="canEdit()">
        <a uiSref="symap.project.projectSettings.update" [uiParams]="{ goToPrevState: true }" class="button">Upravit údaje</a>
      </div>
      <div class="column-33">
        <label>Evidenční číslo</label>
        <div class="info">{{ project.isprofond }}</div>

        <label>{{ 'ps.projectName' | brandTranslate }}</label>
        <div class="info">{{ project.name }}</div>

        <div *ngIf="APP_BRAND.NAME === 'GMT'">
          <label>Smluvní název stavby</label>
          <div class="info">{{ project.contractName }}</div>
        </div>

        <label>Kraj</label>
        <div class="info">{{ project.region }}</div>

        <div visibleByBrand="mpv">
          <label>Komunikace</label>
          <div class="info">{{ project.infrastructure }}</div>
        </div>

        <label>{{ 'ps.investor' | brandTranslate }}</label>

        <div visibleByBrand="mpv" class="info">{{ project.investor?.customerOrganizationalUnit | organizationalUnitName }}</div>
        <div visibleByBrand="maja" class="info">{{ project.investor | projectSubjectName }}</div>

        <div visibleByBrand="mpv">
          <label>{{ 'ps.unit' | brandTranslate }}</label>
          <div class="info">{{ project.investor?.customerOrganizationalUnit?.section }}</div>
        </div>

        <label>{{ 'ps.projectManager' | brandTranslate }}</label>
        <div class="info"><span *ngIf="project.manager; else emptySubject">{{ project.manager | username }}</span></div>

        <div visibleByBrand="mpv">
          <label>Typ akce</label>
          <div class="info">{{ project.type | projectTypeName }}</div>

          <label>Přiřazené vzory</label>
          <div class="info">{{ project.templatesOrganizationalUnit | templateOrganizationalUnitName}}</div>
        </div>
      </div>

      <div class="column-33">
        <label>{{ 'ps.propertyActivityProcessor' | brandTranslate }}</label>
        <div class="info"><span *ngIf="project.propertyActivityProcessor; else emptySubject">{{ project.propertyActivityProcessor | username }}</span></div>

        <label>Zpracovatel záb. elaborátu</label>
        <div class="info"><span *ngIf="project.landTakeDocumentationAuthor; else emptySubject">{{ project.landTakeDocumentationAuthor | projectSubjectName:true }}</span></div>

        <label>Projektant</label>
        <div class="info"><span *ngIf="project.designer; else emptySubject">{{ project.designer | projectSubjectName:true }}</span></div>

        <label>Stupeň</label>
        <div class="info">{{ project.phase }}</div>

        <label *ngIf="project.geodet">Geodet investora</label>
        <div class="info">{{ project.geodet | username }}</div>

        <label *ngIf="project.propertyActivityHead">Vedoucí MP činností</label>
        <div class="info">{{ project.propertyActivityHead | username }}</div>
      </div>

      <div class="column-33">
        <ng-container *ngIf="project.projectStartDate">
          <label>{{ 'ps.projectStartDate' | brandTranslate }}</label>
          <div class="info">{{ project.projectStartDate | date: 'mediumDate' }}</div>
        </ng-container>

        <ng-container *ngIf="project.state">
          <label>{{ 'ps.projectState' | brandTranslate }}</label>
          <div class="info">{{ project | projectState }}</div>
        </ng-container>

        <ng-container *ngIf="project.state === 'STARTED'">
          <label>Úplnost dat trvalého záboru</label>
          <div class="info">{{ project.readinessPermanentOccupations | readiness }}</div>

          <label>Úplnost dat dočasného záboru do 1 roku</label>
          <div class="info">{{ project.readinessTemporaryUnderOccupations | readiness }}</div>

          <label>Úplnost dat dočasného záboru nad 1 rok</label>
          <div class="info">{{ project.readinessTemporaryOverOccupations | readiness }}</div>

          <label>Úplnost dat věcných břemen</label>
          <div class="info">{{ project.readinessTemporaryEasements | readiness }}</div>
        </ng-container>
      </div>
    </div>
    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="true"
            [allowType]="false"
            [defaultData]="{projectInfo: true, systemType: false}"
            (updated)="onUpdateNoteList()"
          ></gmt-notes>
        </div>
        <div *ngIf="tab === 'land-take-documentation-notes'">
          <gmt-notes
            [noteList]="landTakeDocumentationNoteList"
            [editable]="canEdit()"
            [allowType]="false"
            [defaultData]="{landTakeDocumentation: true, systemType: false}"
            (updated)="onUpdateLandTakeDocumentationNoteList()"
          ></gmt-notes>
        </div>
        <div *ngIf="tab === 'documents'">
          <gmt-documents
            [documentList]="documentList"
            [defaultData]="{projectInfo: true}"
            [allowType]="false"
            (updated)="onUpdateDocumentList();"
          ></gmt-documents>
        </div>
        <div *ngIf="tab === 'additional-info'">
          <gmt-labeled-block class="mb-20" label="Údaje k akci" [collapsed]="additionalInfoCollapsed['Údaje k akci']" (changed)="additionalInfoCollapsed['Údaje k akci'] = $event">
            <fieldset class="no-legend">
              <div class="column-100">
                <label class="label">Odlišný název akce pro generování do dokumentů:</label>
                <input type="text" [(ngModel)]="project.contractName" (ngModelChange)="onAdditionalInfoChanged()" [attr.disabled]="authService.hasPermission('manage_properties') ? null : true" style="width: 400px"/>
              </div>
              <div visibleByBrand="mpv" class="column-100">
                <label class="label">Informace pro platební příkaz (ISPROFOND / GLOBÁL)</label>
                <label class="label">Pro výkup / věcná břemena</label>
                <div class="input">
                  <gmt-select
                    [restangularService]="restangularSymap"
                    resource="projects/isprofond-list"
                    [data]="this"
                    field="paymentEasement"
                    [itemPipe]="isprofondPipe"
                    (changed)="onAdditionalInfoChanged(); onChangePayment('Easement', $event)"
                    *ngIf="authService.hasPermission('manage_properties')"
                  ></gmt-select>
                  <div *ngIf="!authService.hasPermission('manage_properties')">{{ project.paymentEasement }}</div>
                </div>
              </div>
              <div visibleByBrand="mpv" class="column-100">
                <label class="label">Pro nájem</label>
                <div class="input">
                  <gmt-select
                    [restangularService]="restangularSymap"
                    resource="projects/isprofond-list"
                    [data]="this"
                    field="paymentTemporaryOccupation"
                    [itemPipe]="isprofondPipe"
                    (changed)="onAdditionalInfoChanged(); onChangePayment('TemporaryOccupation', $event)"
                    *ngIf="authService.hasPermission('manage_properties')"
                  ></gmt-select>
                  <div *ngIf="!authService.hasPermission('manage_properties')">{{ project.paymentTemporaryOccupation }}</div>
                </div>
              </div>
            </fieldset>
          </gmt-labeled-block>

          <div *ngIf="!projectProperties" [loading]="true"></div>
          <div *ngIf="projectProperties">
            <gmt-labeled-block class="mb-20" *ngFor="let group of projectProperties" [label]="group.groupName || 'Nezařazeno'" [collapsed]="additionalInfoCollapsed[group.groupName]" (changed)="additionalInfoCollapsed[group.groupName] = $event">
              <fieldset class="no-legend" [ngClass]="{bordered: !authService.hasPermission('manage_properties')}">
                <div *ngFor="let item of group.items; let $index = index">
                  <div *ngIf="item.type" title="Proměnná šablony: {{item.templateVariable}}">
                    <gmt-input *ngIf="item.type==='USER_SELECT' && authService.hasPermission('manage_properties')" [label]="item.name">
                      <gmt-select
                        [restangularService]="restangularSettings"
                        [data]="usersTemp"
                        [field]="item.templateVariable"
                        resource="users/list"
                        selectTitle="Vyberte"
                        [itemPipe]="userNamePipe"
                        (changed)="onUserChanged($event.newValue, group, item.templateVariable);onAdditionalInfoChanged()"
                      ></gmt-select>
                    </gmt-input>
                    <gmt-input *ngIf="item.type === 'LONGTEXT'" [label]="item.name" [readonly]="!authService.hasPermission('manage_properties')">
                      <textarea [(ngModel)]="item.valueText" (ngModelChange)="onAdditionalInfoChanged()" *ngIf="authService.hasPermission('manage_properties')"></textarea>
                      <pre *ngIf="!authService.hasPermission('manage_properties')">{{item.valueText}}</pre>
                    </gmt-input>
                    <gmt-input *ngIf="item.type === 'TEXT' && isVisible(group, item)" [label]="item.name" [readonly]="!authService.hasPermission('manage_properties')">
                      <input type="text" [(ngModel)]="item.valueText" (ngModelChange)="onAdditionalInfoChanged()" style="width:100%" *ngIf="authService.hasPermission('manage_properties')"/>
                      <span *ngIf="!authService.hasPermission('manage_properties')">{{item.valueText}}</span>
                    </gmt-input>
                    <gmt-input *ngIf="item.type === 'DATE'" [label]="item.name" [readonly]="!authService.hasPermission('manage_properties')">
                      <input pick-a-date type="text" [(ngModel)]="item.valueDate" (ngModelChange)="onAdditionalInfoChanged()" *ngIf="authService.hasPermission('manage_properties')"/>
                      <span *ngIf="!authService.hasPermission('manage_properties')">{{item.valueDate | date}}</span>
                    </gmt-input>
                    <gmt-input *ngIf="item.type === 'BOOLEAN'" [label]="item.name" [readonly]="!authService.hasPermission('manage_properties')">
                      <gmt-checkbox [gmtChecked]="item.valueBoolean" (click)="item.valueBoolean = !item.valueBoolean; onAdditionalInfoChanged()" *ngIf="authService.hasPermission('manage_properties')"></gmt-checkbox>
                      <pre *ngIf="!authService.hasPermission('manage_properties')">{{item.valueBoolean ? 'ano' : 'ne' }}</pre>
                    </gmt-input>
                  </div>
                  <div *ngIf="!item.type" class="mb-10 pt-10" [ngClass]="{ 'bordered-top mt-10' : $index > 0 }"><b>{{translate(item.name)}}</b></div>
                </div>
              </fieldset>
            </gmt-labeled-block>
            <div *ngIf="authService.hasPermission('manage_properties')">
              <gmt-button [clickAction]="saveProperties">Uložit</gmt-button>
            </div>
          </div>
        </div>
      </gmt-navbar>
    </div>
  </div>
  <help [helpId]="helpIds.PS_PROJECT_INFO" class="cm-help-page"></help>
</div>
