<div *ngIf="map" class="map-print-panel" [ngClass]="{ 'map-print-panel--opened': open }">

  <h2>Tisk přehledu {{ "ps.projectGenitive" | brandTranslate | lowercase }}</h2>
  <a class="button toggle-button" (click)="open = !open"><i class="fa" [ngClass]="{ 'fa-angle-double-up': open, 'fa-angle-double-down': !open }"></i></a>

  <div *ngIf="open">
    <fieldset  class="p-10 no-legend">
      <div class="column-100">
        <gmt-input label="Formát" class="mt-10" [required]="true">
          <gmt-select
            [optionItems]="formatTypes"
            [data]="this"
            field="formatType"
            (changed)="onFormatTypeChange($event.newValue)"
            selectTitle="Vyberte"
          ></gmt-select>
        </gmt-input>

        <gmt-input label="Měřítko" class="mt-10" [required]="true">
          <gmt-select
            [optionItems]="measureTypes"
            [data]="this"
            field="measureType"
            (changed)="onMeasureTypeChange($event.newValue, $event.oldValue)"
            selectTitle="Vyberte"
          ></gmt-select>
        </gmt-input>

        <div class="text-center mt-20" *ngIf="printStatus">{{ printStatus }}</div>

        <div class="mt-20 text-center">
          <gmt-button [clickAction]="onEditingModeStart" [gmtDisabled]="!(formatType && measureType)" *ngIf="!editing">Posunout</gmt-button>
          <gmt-button [clickAction]="onEditingModeStop" [gmtDisabled]="!(formatType && measureType)" *ngIf="editing" class="red">Ukončit posunutí</gmt-button>
          <gmt-button [clickAction]="onPrint" [gmtDisabled]="!!printStatus || !canPrint || !(formatType && measureType) || editing">Tisk</gmt-button>
        </div>
      </div>
    </fieldset>
  </div>
</div>
