import { Pipe, PipeTransform, Injectable, Inject } from '@angular/core';
import { OrganizationalUnitModel } from '@app/models/organizational-unit.model';
import { OrganizationalUnitNamePipe } from './organizational-unit-name.pipe';

@Pipe({
  name: 'templateOrganizationalUnitName'
})
@Injectable({
  providedIn: 'root'
})
export class TemplateOrganizationalUnitNamePipe implements PipeTransform {

  constructor(
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
  ) {}

  transform(organizationUnit: OrganizationalUnitModel): string {
    if (!organizationUnit) {
      return '';
    }

    return 'vzory ' + this.organizationalUnitNamePipe.transform(organizationUnit);
  }
}

