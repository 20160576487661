<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 190px;">
        <a uiSref="symap.project.projectSettings.administrators" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Seznam správců {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV_SHORTCUT' | wordtranslate }}</a>
      </div>
      <div class="actions">
        <a (click)="onEdit()" class="button"><i class="fa fa-pencil"></i>Editace správce {{ 'CONSTRUCTION_OBJECT_PLURAL_GENITIV_SHORTCUT' | wordtranslate }}</a>
      </div>
    </div>

    <h1>Správce {{ "CONSTRUCTION_OBJECT_GENITIV" | wordtranslate }}</h1>

    <fieldset class="bordered">
      <legend>Typ osoby</legend>
      <div class="column-100">
        <div class="info">
          {{ administrator.subjectType | projectSubjectTypeName }}
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType.indexOf('OPO') === 0" class="bordered">
      <legend>Základní údaje</legend>
      <div class="column-100">
        <label>Název</label>
        {{ administrator.companyName }}
      </div>

      <div class="column-100">
        <label>Závod / správa</label>
        <div class="info">{{ administrator.branchName }}</div>
      </div>

      <div class="column-50">
        <label>IČO</label>
        <div class="info">{{ administrator.identificationNumber }}</div>
      </div>

      <div class="column-50">
        <label>DIČ</label>
        <div class="info">{{ administrator.vatId }}</div>
      </div>

    </fieldset>

    <fieldset *ngIf="administrator.subjectType === 'OFO'" class="bordered">
      <legend>Základní údaje</legend>
      <div class="column-50">
        <label>Titul před jménem</label>
        <div class="info">{{ administrator.titleBefore }}</div>

        <label>Křestní jméno</label>
        <div class="info">{{ administrator.firstName }}</div>

        <label>Příjmení</label>
        <div class="info">{{ administrator.surname }}</div>

        <label>Titul za jménem</label>
        <div class="info">{{ administrator.degreeAfter }}</div>
      </div>
      <div class="column-50">
        <label>R.Č.</label>
        <div class="info">{{ administrator.personalIdentificationNumber }}</div>
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType.indexOf('OPO') === 0" class="bordered">
      <legend>Zápis obchodního rejtříku</legend>

      <div class="column-100">
        <label>Rejstříkový soud</label>
        <div class="info">{{ administrator.businessRegisterName }}</div>
      </div>
      <div class="column-50">
        <label>Oddíl</label>
        <div class="info">{{ administrator.businessRegisterSection }}</div>
      </div>
      <div class="column-50">
        <label>Vložka</label>
        <div class="info">{{ administrator.businessRegisterEntry }}</div>
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType" class="bordered">
      <legend>Adresa</legend>
      <div class="column-100">
        <label>Ulice</label>
        <div class="info">{{ administrator.street }}</div>
      </div>
      <div class="column-50">
        <label>Číslo popisné</label>
        <div class="info">{{ administrator.houseNumber }}</div>
      </div>
      <div class="column-50">
        <label>Číslo orientační</label>
        <div class="info">{{ administrator.streetNumber }}</div>
      </div>
      <div class="column-50">
        <label>Obec</label>
        <div class="info">{{ administrator.municipality }}</div>
      </div>
      <div class="column-50">
        <label>PSČ</label>
        <div class="info">{{ administrator.postCode | postCode }}</div>
      </div>
      <div class="column-50">
        <label>Část obce</label>
        <div class="info">{{ administrator.neighbourhood}}</div>
      </div>
      <div class="column-50">
        <label>Městská část</label>
        <div class="info">{{ administrator.quarter }}</div>
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType" class="bordered">
      <legend>Kontakt</legend>
      <div class="column-50">
        <label>E-mail</label>
        <div class="info">{{ administrator.email }}</div>
      </div>

      <div class="column-50">
        <label>Telefon</label>
        <div class="info">{{ administrator.phoneNumber}}</div>
      </div>

      <div class="column-50">
        <label>Číslo účtu</label>
        <div class="info">{{administrator.accountNumber}}</div>
      </div>

      <div class="column-50">
        <label>Kód banky</label>
        <div class="info">{{ administrator.bankCode }}</div>
      </div>

    </fieldset>

    <fieldset *ngIf="administrator.subjectType.indexOf('OPO') === 0" class="bordered">
      <legend>Jednající osoby</legend>
      <div class="preview-list" *ngIf="administrator.actingPersons.length">
        <div class="preview-list-item column-100" *ngFor="let acting of administrator.actingPersons">
          <label>Křestní jméno</label>
          <div class="info">{{ acting.firstName }}</div>

          <label>Příjmení</label>
          <div class="info">{{ acting.surname }}</div>

          <label>Titul před jménem</label>
          <div class="info">{{ acting.titleBefore }}</div>

          <label>Titul za jménem</label>
          <div class="info">{{ acting.degreeAfter }}</div>

          <label>Funkce</label>
          <div class="info">{{ acting.function }}</div>
        </div>
      </div>
      <div class="empty-info" *ngIf="!administrator.actingPersons.length">
        Žádné jednající osoby
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType.indexOf('OPO') === 0" class="bordered">
      <legend>Jednající osoba uvedená v návrhu na vklad KN</legend>

      <div class="column-100">
        <label>Osoba</label>
        <div class="info">
          {{ administrator.cadastreActingPerson | projectSubjectName }}
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="administrator.subjectType && administrator.discriminator === 'I'" class="bordered">
      <legend>Data katastru nemovitostí</legend>

      <div class="column-100">
        <label>ID oprávněného subjektu</label>
        <div class="info">{{ administrator.opsubId }}</div>
      </div>
      <div class="column-50" *ngIf="administrator.subjectType.length > 3">
        <label>ID nadřazeného subjektu</label>
        <div class="info">{{ administrator.parentOpsubId }}</div>
      </div>
    </fieldset>
  </div>
</div>

