import { Component, OnInit, Inject} from '@angular/core';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DescriptionPipe } from '@app/common/pipes/description.pipe';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';

@Component({
  selector: 'document-settings',
  templateUrl: './document-settings.component.html',
})
export class DocumentSettingsComponent implements OnInit {

  document: any;
  permissionOptions: any;

  constructor(
    private config: DialogConfig,
    private dialog: DialogRef,
    public descriptionPipe: DescriptionPipe,
    @Inject(RESTANGULAR_SETTINGS) private settingsRestangular: any,
  ) {
    this.document = (<DialogConfigData>config.data).document;
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
  }

  async ngOnInit(): Promise<any> {
    this.permissionOptions = await this.settingsRestangular.all('permissions/application/SY/list').getList().toPromise();
  }

  cancel() {
    this.dialog.close();
  }

  save() {
    this.dialog.close(this.document);
  }
}
