import { Inject, Injectable } from '@angular/core';
 import * as  L from 'leaflet';
import { fullScreenApi } from 'leaflet.fullscreen';

@Injectable({
  providedIn: 'root'
})
export class MapFullScreenService {

  constructor(

  ) { }

   getFullscreenControl(opts) {
    var FullscreenControl = L.Control.FullScreen.extend({
        toggleFullScreen: function (map) {

            map._exitFired = false;
            if (map._isFullscreen) {

                if (fullScreenApi.supportsFullScreen && !this.options.forcePseudoFullscreen) {
                    fullScreenApi.cancelFullScreen(map._container);
                } else {
                    L.DomUtil.removeClass(map._container, 'leaflet-pseudo-fullscreen');
                }
                map.invalidateSize();
                map.fire('exitFullscreen');
                map._exitFired = true;
                map._isFullscreen = false;
            } else {
                if (fullScreenApi.supportsFullScreen && !this.options.forcePseudoFullscreen) {
                    fullScreenApi.requestFullScreen(map._container);
                } else {
                    L.DomUtil.addClass(map._container, 'leaflet-pseudo-fullscreen');
                }
                map.invalidateSize();
                map.fire('enterFullscreen');
                map._isFullscreen = true;
            }
        }
    });

    return new FullscreenControl(opts);
  }

}

