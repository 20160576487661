import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommonAddressModel } from '@app/ps/models/common-address.model';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  @Input() address: CommonAddressModel;
  @Input() editable = true;
  @Input() editableToggle = false;
  @Input() toggleText: string;
  @Input() heading = 'Adresa';
  @Input() collapsed = true;
  @Input() requiredFields: string[] = [];
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() { }

  toggleCollapsed() {
    if (!this.editable) {
      return;
    }
    this.collapsed = !this.collapsed;
    this.toggle.emit(this.collapsed);
  }

  isRequired(fieldName: string): boolean {
    return this.requiredFields.includes(fieldName);
  }
}
