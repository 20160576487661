export enum GeometricPlanRequestStatusEnum {
  CREATED = 'CREATED',
  CONCEPT_REALIZATION = 'CONCEPT_REALIZATION',
  CONCEPT_SENT_TO_CONFIRMATION = 'CONCEPT_SENT_TO_CONFIRMATION',
  CONCEPT_DISAPPROVED = 'CONCEPT_DISAPPROVED',
  CONCEPT_APPROVED = 'CONCEPT_APPROVED',
  SENT_TO_CONFIRMATION = 'SENT_TO_CONFIRMATION',
  DISAPPROVED = 'DISAPPROVED',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  REGISTER_REQUESTED = 'REGISTER_REQUESTED',
  REGISTERED = 'REGISTERED',
  IMPORT_REQUESTED = 'IMPORT_REQUESTED',
  IMPORTED = 'IMPORTED',
  IMPORTED_AUTO = 'IMPORTED_AUTO',
}

export const geometricPlanRequestStatusOptions = [
  {
    id: GeometricPlanRequestStatusEnum.CREATED,
    name: 'Založení záznamu o GP',
    dialog: false,
  },
  {
    id: GeometricPlanRequestStatusEnum.CONCEPT_REALIZATION,
    name: 'Zpracování GP',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION,
    name: 'Koncept GP ke schválení',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED,
    name: 'Odmítnutý koncept GP',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
    name: 'Schválený koncept GP',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION,
    name: 'Odesláno k potvrzení na KP',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.DISAPPROVED,
    name: 'Odmítnutý GP (KP)',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.APPROVED,
    name: 'Potvrzený GP (KP)',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.DELETED,
    name: 'Zrušený GP',
    action: 'Zrušit GP',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.REGISTER_REQUESTED,
    name: 'Vklad do KN na základě ÚR',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.REGISTERED,
    name: 'GP zapsaný v KN (ÚR)',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.IMPORT_REQUESTED,
    name: 'Požadavek na vložení nového stavu dle GP do aplikace',
    action: 'Vytvořit požadavek na vložení nového stavu dle GP do aplikace',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.IMPORTED,
    name: 'GP zavedený do aplikace',
    action: 'Zavést GP do aplikace',
    dialog: true,
  },
  {
    id: GeometricPlanRequestStatusEnum.IMPORTED_AUTO,
    name: 'GP importovaný do aplikace',
    action: 'Import GP do aplikace',
    dialog: true,
  }
];
