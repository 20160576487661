﻿import { ChangeDetectorRef, Component, Type, Input, OnInit, Inject, ElementRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { UIRouterGlobals } from "@uirouter/angular";
import { ApplicationsComponent } from '@app/common/components/applications/applications.component';
import { ApplicationsHostDirective } from '@app/common/directives/applications/applicationsHost.directive';
import { AuthService } from '@app/common/services/auth.service';
import { BackendVersionService } from '@app/common/services/backend-version.service';
import { MenuConfig } from '@app/models/menuConfig';
import { TooltipSystemMetadataComponent } from '@app/common/components/tooltip-system-metadata/tooltip-system-metadata.component';
import { APP_BRAND, APP_CONFIG, APP_WIKI_URL } from '@app/common/services/config.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild(ApplicationsHostDirective) private applicationsHostDirective: ApplicationsHostDirective;
  @Input() collapsed = false;
  @Input() menuConfig: MenuConfig[] = [];

  applications: any;
  componentRef: any;
  copyrightDate = new Date();
  metadata: Object = null;
  tooltipSystemMetadataComponent: Type<any> = TooltipSystemMetadataComponent;
  user = this.authService.getUser();

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private elementRef: ElementRef,
    private uiRouterGlobals: UIRouterGlobals,
    private backendVersionService: BackendVersionService,
    @Inject(APP_CONFIG) private config: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  async ngOnInit() {
    this.applications = this.authService.getApplications().filter(a => this.authService.aplicationHasProjects(a));
    this.metadata = {
      backendVersion: await this.backendVersionService.getVersion(),
      frontendVersion: this.config.VERSION,
      wikiUrl: APP_WIKI_URL,
      cadastre: this.authService.getActualProject().cadastreDates,
    };
  }

  isActive(uiSref: string): boolean {
    return !!this.uiRouterGlobals.$current.includes[uiSref];
  }

  onDocumentClick(event: MouseEvent) {
    const isClickInside = $.contains(this.componentRef.location.nativeElement, event.target as Element);
    if (!isClickInside) {
      this.hideApplications();
    }
  }

  hideApplications() {
    if (this.componentRef) {
      document.removeEventListener('click', this.onDocumentClick);
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }

  onApplicationSwitcherMouseEnter() {
    this.elementRef.nativeElement.querySelector('#app-switcher').classList.add('active');
  }

  onApplicationSwitcherMouseLeave() {
    const switcherElm = this.elementRef.nativeElement.querySelector('#app-switcher.active');
    if (switcherElm) {
      switcherElm.classList.remove('active');
    }
  }

  showApplications(event: MouseEvent) {
    event.stopPropagation();
    if (this.componentRef) {
      this.hideApplications();
    } else {
      const box = this.elementRef.nativeElement.querySelector('#app-switcher').getBoundingClientRect();
      const top = box.top - (this.collapsed ? 5 : 0);
      const left = box.width - 5;
      const factory = this.componentFactoryResolver.resolveComponentFactory(ApplicationsComponent);
      this.componentRef = this.applicationsHostDirective.viewContainerRef.createComponent(factory);
      this.componentRef.instance.applications = this.applications;
      $(this.componentRef.location.nativeElement).css({
        cursor: 'default',
        visibility: 'hidden',
        top: '0px',
        left: '0px',
        position: 'fixed',
        zIndex: 400,
      });

      this.cdr.detectChanges(); // force change detection to get real height in the code below

      const applicationHeight = $(this.componentRef.location.nativeElement).outerHeight() - $(this.elementRef.nativeElement).find('#app-switcher')[0].getBoundingClientRect().height;
      $(this.componentRef.location.nativeElement).css({
        visibility: 'visible',
        transform: `translate3d(${left}px, ${top - applicationHeight}px, 0px)`,
      });
      document.addEventListener('click', this.onDocumentClick);
    }
  }
}
