export interface ChangelogModel {
  id?: number;
  name: string;
  description?: string;
  publishDate?: string;
  application: any;
}

export const createChangelog = (applicationName: string): ChangelogModel => {
  return {
    name: '',
    application: { name: applicationName }
  };
};
