import { Pipe, PipeTransform, Injectable, Inject } from '@angular/core';
import { User } from '@app/models/user';
import { APP_BRAND } from '@app/common/services/config.service';

@Pipe({name: 'username'})
@Injectable({
  providedIn: 'root'
})
export class UserNamePipe implements PipeTransform {
  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {}

  transform(user: User, withCompany = true, withUnitCode = true) {
    if (!user) {
      return '';
    }

    let words = [];

    if (user.titleBefore) {
      words.push(user.titleBefore);
    }

    if (user.name) {
      words.push(user.name);
    }

    if (user.surname) {
      words.push(user.surname);
    }

    const name = words.join(' ') + (user.degreeAfter ? ', ' + user.degreeAfter : '');
    words = [name];
    if (withCompany && user.company && user.company.companyName && this.APP_BRAND.NAME !== 'RSD') {
      words.push('(' + user.company.companyName + ')');
    } else if (withCompany && this.APP_BRAND.NAME === 'RSD' && user.ssoCompanyName && user.ssoCompanyName !== 'ŘSD') {
      words.push('(' + user.ssoCompanyName + ')');
    }

    if (withUnitCode && this.APP_BRAND.NAME === 'RSD' && user.departmentId && user.ssoCompanyName === 'ŘSD') {
      words.push('(' + user.departmentId + ')');
    }

    return words.join(' ');
  }
}
