import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader, LOCALE_ID } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';
import { CommonModule } from '../common/common.module';
import { ColorChromeModule } from 'ngx-color/chrome';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import * as  L from 'leaflet';

import { LeafletMapDirective } from './directives/leaflet-map/leaflet-map.directive';
import { MapLayerSelectorComponent } from './components/map-layer-selector/map-layer-selector.component';
import { MapLayerSelectorTooltipComponent } from './components/map-layer-selector-tooltip/map-layer-selector-tooltip.component';
import { MapSearchComponent } from './components/map-search/map-search.component';
import { MapToolBoxComponent } from './components/map-tool-box/map-tool-box.component';
import { MapModuleInfoComponent } from './components/map-module-info/map-module-info.component';
import { MapModuleMoveComponent } from './components/map-module-move/map-module-move.component';
import { MapModuleCuzkComponent } from './components/map-module-cuzk/map-module-cuzk.component';
import { MapModuleMeasureComponent } from './components/map-module-measure/map-module-measure.component';
import { MapFeatureItemComponent } from './components/map-feature-item/map-feature-item.component';
import { LayerSymbolComponent } from './components/layer-symbol/layer-symbol.component';
import { UIRouterModule } from '@uirouter/angular';
import { MapModulePrintComponent } from '@app/map/components/map-module-print/map-module-print.component';
import { MapModulePrintProjectComponent } from '@app/map/components/map-module-print-project/map-module-print-project.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    DimapCommonModule,
    ColorChromeModule,
    UIRouterModule.forChild(),
  ],
  providers: [
    {provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader},
  ],
  declarations: [
    LeafletMapDirective,
    MapLayerSelectorComponent,
    MapLayerSelectorTooltipComponent,
    MapToolBoxComponent,
    MapModuleInfoComponent,
    MapModuleMeasureComponent,
    MapModuleMoveComponent,
    MapModuleCuzkComponent,
    MapFeatureItemComponent,
    LayerSymbolComponent,
    MapSearchComponent,
    MapModulePrintComponent,
    MapModulePrintProjectComponent,
  ],
  entryComponents: [],
  exports: [
    LeafletMapDirective,
    MapLayerSelectorComponent,
    MapLayerSelectorTooltipComponent,
    MapToolBoxComponent,
    MapModuleInfoComponent,
    MapModuleMeasureComponent,
    MapModuleMoveComponent,
    MapModuleCuzkComponent,
    MapFeatureItemComponent,
    LayerSymbolComponent,
    MapSearchComponent,
    MapModulePrintComponent,
    MapModulePrintProjectComponent,
  ],
})
export class MapModule {
  // Hranice zobrazitelného uzemí pro WMTS vrstvy ČÚZK. První dlaždice nemusí odpovídat levému hornímu rohu, ale pro přehlednost se zde používá
  public static JTSKBOUNDS = [[-925000, -920000],[-400646.464000000040, -1444353.535999999800]];
  public static SRID_DEFS = {
    5514: "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs",
  };
  private LEAFLET_PROJ_DEFS;

  constructor(private mapUtilsCrsService: MapUtilsCrsService) {
    this.LEAFLET_PROJ_DEFS = {
     5514: {
       resolutions: this.getResolutions(MapModule.JTSKBOUNDS[1][0] - MapModule.JTSKBOUNDS[0][0], 18),
       origin: MapModule.JTSKBOUNDS[0],
       bounds: L.bounds(
         L.point(MapModule.JTSKBOUNDS[0][0], MapModule.JTSKBOUNDS[1][1]),
         L.point(MapModule.JTSKBOUNDS[1][0], MapModule.JTSKBOUNDS[0][1])
       )
     }
    };
    this.registerProj4Srid();
    this.registerLeafletSrid();
  }

  private registerProj4Srid() {
    for(var sridId in MapModule.SRID_DEFS) {
       this.mapUtilsCrsService.setProj4Srid(sridId, MapModule.SRID_DEFS[sridId]);
    }
  }

  private registerLeafletSrid(){
    for(var sridId in this.LEAFLET_PROJ_DEFS){
        this.mapUtilsCrsService.setLeafletSrid(sridId, new L.Proj.CRS('EPSG:' + sridId, MapModule.SRID_DEFS[sridId], this.LEAFLET_PROJ_DEFS[sridId]));
    }
  }

  private getResolutions = (width, maxZoom) => {
      let size = width / 256;
      let levels = maxZoom;
      let resolutions = new Array(levels);
      let matrixIds = new Array(levels);
      for (let z = 0; z < levels; ++z) {
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = (z < 10) ? `0${z}` : z.toString();
      }
      return resolutions;
  }
}
