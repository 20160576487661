import { Component } from '@angular/core';

import { DialogConfig } from '../../models/dialog-config';
import { DialogRef } from '../../services/dialog-ref';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
  ) {
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.dialog.close(true);
  }
}
