import { Injectable, Inject } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import * as _ from 'lodash';
import { CallbackModel } from '@app/common/models/callback.model';
import { LoginService } from '@app/common/services/login.service';
import { HttpParams } from '@angular/common/http';

import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { RESTANGULAR_DIMAP } from '@app/common/services/restangular-dimap.service';
import { RESTANGULAR_VFZE } from '@app/common/services/restangular-vfze.service';
import { RESTANGULAR_SV } from '@app/common/services/restangular-sv.service';
import { RESTANGULAR_GLOBAL } from '@app/common/services/restangular-global.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS, EVENTS } from '@app/common/services/config.service';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({providedIn: 'root'})
export class AuthService {
  callbacks = new CallbackModel();
  BACKEND_OPTIONS = this.config.BACKEND_OPTIONS;
  LOCALSTORAGEVERSION = this.config.LOCALSTORAGEVERSION;
  SERVICE_DESK = this.config.SERVICE_DESK;

  // current authentization token
  token = null;
  // details about the user logged in
  user = null;

  // default project key of the user
  SYdefaultProjectKey = null;
  DIdefaultProjectKey = null;

  // user avaliable applications
  applications = null;

  symapProjects = null;
  symapPermissions = null;

  dimapProjects = null;
  dimapPermissions = null;

  promapProjects = null;
  promapPermissions = null;
  settingsPermissions = null;

  dashboardPermissions = null;
  landingPermissions = null;

  vfzeProjects = null;
  vfzePermissions = null;

  svPermissions = null;
  svProjects = null;

  symapLastPojectKeys = null;

  activeApplication = null;

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    @Inject(APPLICATIONS) private APPLICATIONS: any,
    @Inject(APP_CONFIG) private config: any,
    private localStorageService: LocalStorageService,
    private restangular: Restangular,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    @Inject(RESTANGULAR_DIMAP) private restangularDimap: any,
    @Inject(RESTANGULAR_VFZE) private restangularVfze: any,
    @Inject(RESTANGULAR_SV) private restangularSv: any,
    @Inject(RESTANGULAR_CONFIGURATION) private restangulatrConfiguration: any,
    @Inject(RESTANGULAR_SETTINGS) private restangularUsers: any,
    @Inject(RESTANGULAR_GLOBAL) private globalRestangular: any,
    private loginService: LoginService,
    private stateService: StateService,
  ) {
    this.loadDimapProjects = this.loadDimapProjects.bind(this);
    this.loadSymapProjects = this.loadSymapProjects.bind(this);
    this.loadPromapProjects = this.loadPromapProjects.bind(this);
    this.loadSvProjects = this.loadSvProjects.bind(this);
    this.loadSvProjects = this.loadSvProjects.bind(this);
    this.loadVfzeProjects = this.loadVfzeProjects.bind(this);
    this.restangularErrorInterceptor = this.restangularErrorInterceptor.bind(this);

    (restangular as any).setErrorInterceptor(this.restangularErrorInterceptor);
    globalRestangular.setErrorInterceptor(this.restangularErrorInterceptor);
    restangularSymap.setErrorInterceptor(this.restangularErrorInterceptor);
    restangularDimap.setErrorInterceptor(this.restangularErrorInterceptor);
    restangularUsers.setErrorInterceptor(this.restangularErrorInterceptor);
    restangularVfze.setErrorInterceptor(this.restangularErrorInterceptor);
    restangularSv.setErrorInterceptor(this.restangularErrorInterceptor);
    restangulatrConfiguration.setErrorInterceptor(this.restangularErrorInterceptor);

    const localStorageActual = this.localStorageActual();
    if (!localStorageActual) {
      // clear storage
      this.localStorageService.clearAll();
      // set version
      this.localStorageService.set('version', this.LOCALSTORAGEVERSION);
    }

    // SERVICE INIT - called on application load (first time or on browser reload)

    // check if there is auth information in cookies
    const cookieToken = this.localStorageService.get('auth-token');
    const cookieUser = this.localStorageService.get('auth-user');
    const cookieApplications = this.localStorageService.get('auth-applications');
    const cookieSYdefaultProjectKey = this.localStorageService.get('auth-default-project-key-sy');
    const cookieDIdefaultProjectKey = this.localStorageService.get('auth-default-project-key-di');
    const cookiesymapLastPojectKeys = this.localStorageService.get('auth-last-visited-project-keys-sy');
    const activeApplication = this.localStorageService.get('auth-active-application');

    if (cookieToken && cookieUser) {
      this.token = cookieToken;
      this.user = cookieUser;
    }

    this.SYdefaultProjectKey = cookieSYdefaultProjectKey;
    this.DIdefaultProjectKey = cookieDIdefaultProjectKey;
    this.applications = cookieApplications;
    this.activeApplication = activeApplication;
    this.symapLastPojectKeys = cookiesymapLastPojectKeys;

    if (this.applications) {
      if (APP_BRAND.NAME === 'RSD' && this.applications.indexOf('LANDING') === -1) {
        this.applications.push('LANDING');
      }
    }

    this.updateAuthorizationHeader();
  }

  getUser() {
    return this.user;
  }

  getToken() {
    return this.token;
  }

  registerCallback(name, callback) {
    this.callbacks.add(name, callback);
  }

  unregisterCallback(name, callback) {
    this.callbacks.remove(name, callback);
  }

  onAuthProjectUpdated(project) {
    this.callbacks.get(EVENTS.authProjectUpdated)(project);
  }

  notifyLogout(includeParams = true) {
    (window as any).GoogleAnalytics('set', 'userId', 'unlogged');
    return this.loginService.redirectToLogin(includeParams, this.isSsoLoginEnabled());
  }

  onAuthTimeout() {
    this.callbacks.get(EVENTS.authTimeout)(null);
    return this.onAuthUserUpdated(null);
  }

  onAuthUserUpdated(userData, includeParams = true) {
    this.callbacks.get(EVENTS.authUserUpdated)(userData);
    if (!userData) {
      return this.notifyLogout(includeParams);
    }
  }

  // register Restangular interceptor to catch timeouts and user logout
  restangularErrorInterceptor(response) {
    if (response.status === 401) {
      if (response.data.message === 'Expired access token') {
        if (this.isAuthenticated()) {
          this.resetUserValues();
          this.updateAuthorizationHeader();
          this.onAuthTimeout();
        } else {
          this.resetUserValues();
        }
        return false;
      }

      if (response.data.message === 'Missing credentials' || response.data.message === 'Bad credentials') {
        if (this.isAuthenticated()) {
          this.resetUserValues();
          this.updateAuthorizationHeader();
          this.onAuthUserUpdated(null);
        } else {
          this.resetUserValues();
        }
        return false;
      }
    }
  }

  isAuthorized(user?) {
    return this.hasPermission('admin') || (user && this.user && this.user.id === user.id);
  }

  isAuthenticated() {
    return !!(this.user && this.token);
  }

  getApplications() {
    return this.applications || [];
  }

  getSymapProjects(): any[] {
    return this.symapProjects;
  }

  getSYdefaultProjectKey() {
    return this.SYdefaultProjectKey;
  }

  getDimapProjects() {
    return this.dimapProjects;
  }

  getDIdefaultProjectKey() {
    return this.DIdefaultProjectKey;
  }

  isAuthenticatedApplication(app) {
    return (!!this.user) && (this.getApplications().includes(app));
  }

  // FIXME - for user ID
  getActiveApplication() {
    return this.activeApplication && this.getApplications().includes(this.activeApplication) ? this.activeApplication : null;
  }

  setActiveApplication(appId) {
    // maybe save active project and application for specific userId
    this.localStorageService.set('auth-active-application', appId);
    this.activeApplication = appId;
  }

  getApplicationTransitionParams = (application) => {
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return {
          module: this.getApplicationBaseState(application),
          projectKey: this.SYdefaultProjectKey || 'sy_'
        };
      case this.APPLICATIONS.di.name:
        return {
          module: this.getApplicationBaseState(application),
          projectKey: this.DIdefaultProjectKey || 'di_'
        };
      case this.APPLICATIONS.pk.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      case this.APPLICATIONS.settings.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      case this.APPLICATIONS.dashboard.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      case this.APPLICATIONS.vfze.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      case this.APPLICATIONS.landing.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      case this.APPLICATIONS.sv.name:
        return {
          module: this.getApplicationBaseState(application),
        };
      default:
        return undefined;
    }
  };

  /**
   * Check if given applicationID has some projects.
   * Projects length are checked only in SyMAP and DiMAP
   */
  aplicationHasProjects = (applicationId) => {
    const app = this.user.applicationProjects && this.user.applicationProjects[applicationId];

    switch (applicationId) {
      case this.APPLICATIONS.sy.name:
        return app && app.length > 0;
      case this.APPLICATIONS.di.name:
        return app && app.length > 0;
      case this.APPLICATIONS.pk.name:
        return !!app;
      case this.APPLICATIONS.settings.name:
        return this.user.superuser;
      case this.APPLICATIONS.dashboard.name:
        return !!app;
      case this.APPLICATIONS.vfze.name:
        return !!app;
      case this.APPLICATIONS.landing.name:
        return this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ';
      case this.APPLICATIONS.sv.name:
        return !!app;
      default:
        return false;
    }
  };

  /**
   * Return first application, that is valid and can be set as active.
   */
  getAplicationWithProjects() {
    if (this.user && this.user.applicationProjects) {
      const appProjectArr = Object.entries(this.user.applicationProjects);

      // order user aps by default - sy, di, ...
      const appOrderArr = Object.keys(this.APPLICATIONS).map(e => this.APPLICATIONS[e].name);
      appProjectArr.sort((a, b) => {
        return appOrderArr.indexOf(a[0]) - appOrderArr.indexOf(b[0]);
      });

      // find user app
      for (const [key, value] of appProjectArr) {
        switch (key) {
          case this.APPLICATIONS.sy.name:
            if ((value as any).length > 0) {
              return this.APPLICATIONS.sy.name;
            }
            break;
          case this.APPLICATIONS.di.name:
            if ((value as any).length > 0) {
              return this.APPLICATIONS.di.name;
            }
            break;
          case this.APPLICATIONS.pk.name:
            return this.APPLICATIONS.pk.name;
          case this.APPLICATIONS.settings.name:
            return this.APPLICATIONS.settings.name;
          case this.APPLICATIONS.dashboard.name:
            return this.APPLICATIONS.dashboard.name;
          case this.APPLICATIONS.vfze.name:
            return this.APPLICATIONS.vfze.name;
          case this.APPLICATIONS.landing.name:
            return this.APPLICATIONS.landing.name;
          case this.APPLICATIONS.sv.name:
            return this.APPLICATIONS.sv.name;
        }
      }
      return null;
    } else {
      return null;
    }
  }

  goToActiveAplication() {
    // 1) if user have active application in local storage and application is valid (user has permission on it), then go to this application
    // 2) fallback is get first application with project
    // 3) fallback is first application, but without project, so user will see exception on error page
    const activeApplication = this.getActiveApplication();
    const application = activeApplication && this.aplicationHasProjects(activeApplication) ? activeApplication : this.getAplicationWithProjects() || this.getApplications()[0];

    let params;
    let opts;

    switch (application) {
      case this.APPLICATIONS.sy.name:
        params = this.getApplicationTransitionParams(application);
        opts = params.projectKey ? {projectKey: params.projectKey} : null;
        return this.stateService.go(params.module, opts);
      case this.APPLICATIONS.di.name:
        params = this.getApplicationTransitionParams(application);
        opts = params.projectKey ? {projectKey: params.projectKey} : null;
        return this.stateService.go(params.module, opts);
      case this.APPLICATIONS.pk.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      case this.APPLICATIONS.settings.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      case this.APPLICATIONS.dashboard.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      case this.APPLICATIONS.vfze.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      case this.APPLICATIONS.landing.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      case this.APPLICATIONS.sv.name:
        params = this.getApplicationTransitionParams(application);
        return this.stateService.go(params.module);
      default:
        return undefined;
    }
  }

  getApplicationBaseState(application) {
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return 'symap.project.home';
      case this.APPLICATIONS.di.name:
        return 'dimap.project.propertyStatus';
      case this.APPLICATIONS.pk.name:
        return 'pk.projects';
      case this.APPLICATIONS.settings.name:
        return 'settings.users';
      case this.APPLICATIONS.dashboard.name:
        return 'dashboard.projects';
      case this.APPLICATIONS.vfze.name:
        return 'vfze.validation';
      case this.APPLICATIONS.landing.name:
        return 'landing.home';
      case this.APPLICATIONS.sv.name:
        return 'sv.samples';
      default:
        return undefined;
    }
  }

  getGlobalApplicationRestUrl() {
    return `${this.BACKEND_OPTIONS.restUrl}/app`;
  }

  getActiveApplicationRestUrl() {
    const activeApplication = this.getActiveApplication();

    switch (activeApplication) {
      case this.APPLICATIONS.sy.name:
        return this.BACKEND_OPTIONS.restUrlSY + '/' + this.getActualProject().key;
      case this.APPLICATIONS.di.name:
        return this.BACKEND_OPTIONS.restUrlDI + '/' + this.getActualProject().key;
      case this.APPLICATIONS.pk.name:
        return this.BACKEND_OPTIONS.restUrlPK;
      case this.APPLICATIONS.settings.name:
        return this.BACKEND_OPTIONS.restUrlSETTINGS;
      case this.APPLICATIONS.dashboard.name:
        return this.BACKEND_OPTIONS.restUrlSY;
      case this.APPLICATIONS.vfze.name:
        return this.BACKEND_OPTIONS.restUrlVFZE;
      case this.APPLICATIONS.landing.name:
        return this.BACKEND_OPTIONS.restUrlSY;
      case this.APPLICATIONS.sv.name:
        return this.BACKEND_OPTIONS.restUrlSV;
    }
  }

  passwordChanged() {
    this.user.passwordChanged = true;
    this.localStorageService.set('auth-user', this.user);
  }

  isFirstLogin() {
    return this.user.passwordChanged === false;
  }

  hasProjectWithPermissions() {
    const actualProject = this.getActualProject();
    const actualPermissions = this.getActualPermissions();
    return actualProject && actualPermissions && actualPermissions[actualProject.key];
  }

  projectPermissionsContainsAction(actions) {
    const actualProject = this.getActualProject();
    const actualPermissions = this.getActualPermissions();
    return actions.split(',').some(a => actualPermissions[actualProject.key].includes(a));
  }

  // check if user has permission for one of given action
  // @Array.<string> action
  hasPermission(action) {
    const activeApplication = this.getActiveApplication();

    switch (activeApplication) {
      case this.APPLICATIONS.sy.name:
        return this.hasProjectWithPermissions() && this.projectPermissionsContainsAction(action);
      case this.APPLICATIONS.di.name:
        return this.hasProjectWithPermissions() && this.projectPermissionsContainsAction(action);
      case this.APPLICATIONS.pk.name:
        const actualPkPermissions = this.getActualPermissions();
        const projectPkPermissions = actualPkPermissions[Object.keys(actualPkPermissions)[0]];
        return projectPkPermissions && action.split(',').some(a => projectPkPermissions.includes(a));
      case this.APPLICATIONS.settings.name:
        // FIXME
        return true;
      case this.APPLICATIONS.dashboard.name:
        return true;
      case this.APPLICATIONS.vfze.name:
        const actualPermissions = this.getActualPermissions();
        const projectPermissions = actualPermissions[Object.keys(actualPermissions)[0]];
        return projectPermissions && action.split(',').some(a => projectPermissions.includes(a));
      case this.APPLICATIONS.landing.name:
        return true;
      case this.APPLICATIONS.sv.name:
        return true;
      default:
        return false;
    }
  }

  resetUserValues() {
    this.token = null;
    this.user = null;
    this.SYdefaultProjectKey = null;
    this.DIdefaultProjectKey = null;
    this.applications = null;

    this.symapProjects = null;
    this.symapPermissions = null;

    this.dimapProjects = null;
    this.dimapPermissions = null;

    this.promapPermissions = null;
    this.settingsPermissions = null;
    this.dashboardPermissions = null;
    this.landingPermissions = null;

    this.vfzePermissions = null;
    this.svPermissions = null;
    this.symapLastPojectKeys = null;

    this.localStorageService.remove('auth-token');
    this.localStorageService.remove('auth-user');
    this.localStorageService.remove('auth-applications');
  }

  localStorageActual() {
    // get version
    const lSVersion = parseInt(this.localStorageService.get('version'));
    return lSVersion && lSVersion >= this.LOCALSTORAGEVERSION;
  }

  updateAuthorizationHeader() {
    const defaultHeader = {
      'Authorization': this.token || 'xxx',
      'If-Modified-Since': '0',
    };

    (this.restangular as any).setDefaultHeaders(defaultHeader);
    this.globalRestangular.setDefaultHeaders(defaultHeader);
    this.restangularSymap.setDefaultHeaders(defaultHeader);
    this.restangularDimap.setDefaultHeaders(defaultHeader);
    this.restangularUsers.setDefaultHeaders(defaultHeader);
    this.restangulatrConfiguration.setDefaultHeaders(defaultHeader);
    this.restangularVfze.setDefaultHeaders(defaultHeader);
    this.restangularSv.setDefaultHeaders(defaultHeader);

    if (this.user) {
      // register user to intercom
      // this.intercom.boot({
      //   'user_id': this.user.id,
      //   'applications': (this.user.applications ? this.user.applications.join(', ') : ''),
      //   'superuser': this.user.superuser,
      //   'email': this.user.email,
      //   'name': this.user.fullName,
      //   'company': this.user.company && this.user.company.companyName ? {
      //     'id': this.user.company.companyName,
      //     'name': this.user.company.companyName
      //   } : null
      // });
    } else {
      // this.intercom.shutdown();
    }
  }

  updateBaseUrl() {
    this.restangular.provider.setBaseUrl(this.getActiveApplicationRestUrl());
  }

  getAplicationGlobalRestangularProvider() {
    const application = this.getActiveApplication();
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return this.restangularSymap;
      case this.APPLICATIONS.di.name:
        return this.restangularDimap;
      default:
        return null;
    }
  }

  loadProject(projectKey) {
    const provider = this.getAplicationGlobalRestangularProvider();
    if (provider) {
      return provider.one('projects', projectKey).get().toPromise();
    } else {
      return false;
    }
  }

  loadSymapProjects(userId, params = {}) {
    return this.loadProjects(userId, this.restangularSymap, this.APPLICATIONS.sy, params).then((data) => {
      this.symapProjects = data.projects;
      this.symapPermissions = data.permissions;
      return data;
    });
  }

  loadDimapProjects(userId) {
    return this.loadProjects(userId, this.restangularDimap, this.APPLICATIONS.di).then((data) => {
      this.dimapProjects = data.projects;
      this.dimapPermissions = data.permissions;
      return data;
    });
  }

  loadVfzeProjects(userId) {
    return this.restangularUsers
      .one(`permissions/application/VFZE/user/`, userId)
      .customGET('project-permissions')
      .toPromise()
      .then(data => {
        this.vfzePermissions = data.plain();
        return {
          projects: false,
          permissions: data,
        };
      });
  }

  loadPromapProjects(userId) {
    return this.restangularUsers
      .one(`permissions/application/PRO/user/`, userId)
      .customGET('project-permissions')
      .toPromise()
      .then(data => {
        this.promapPermissions = data.plain();
        return {
          projects: false,
          permissions: data,
        };
      });
  }

  loadSvProjects(userId) {
    return this.loadProjects(userId, null, this.APPLICATIONS.sv).then((data) => {
      this.svPermissions = data.permissions;
      return {
        projects: false,
        permissions: data,
      };
    });
  }

  loadProjects(userId, provider, app, params = {}) {
    const requests = [
      provider
        ? provider.one('users', userId).customGET('projects', params).toPromise().then((r) => r.plain())
        : Promise.resolve({}),
      this.restangularUsers
        .one(`permissions/application/${app.name}/user/`, userId)
        .customGET('project-permissions')
        .toPromise()
        .then((r) => r.plain())
    ];

    return Promise.all(requests).then((data) => {
      const projects = [];
      const permissions = {};

      if (data[0] && data[1]) {
        const reqProjects = data[0].items || [];
        const reqPermissions = data[1];

        projects.push(...reqProjects);
        Object.assign(permissions, reqPermissions);
      }

      return {
        projects: projects,
        permissions: permissions,
      };
    });
  }

  isSsoLoginEnabled(secret?) {
    return this.APP_BRAND.LOGIN.SSO && !(secret && secret === 'xs22c');
  }

  ssoLogin() {
    if (this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'IDENTITY_SERVER') {
      const generateRandomString = (length) => {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        for (let i = 0; i < length; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
      };

      const codeVerifier = generateRandomString(128);
      const state = generateRandomString(10);
      sessionStorage.setItem('code_verifier', codeVerifier);
      sessionStorage.setItem('state', state);

      const computeChallenge = (codeVerifier) => {
        return this.restangularUsers.all('auth')
          .withHttpConfig({responseType: 'text'})
          .customGET('sha256/base64url', {
            value: codeVerifier
          })
          .toPromise()
          .then(d => d.data);
      };

      return computeChallenge(codeVerifier)
        .then(challenge => {
          const queryParams = new HttpParams()
            .set('client_id', this.APP_BRAND.LOGIN.SSO_OPTIONS.CLIENT_ID)
            .set('redirect_uri', this.APP_BRAND.LOGIN.SSO_OPTIONS.REDIRECT_URI)
            .set('scope', this.APP_BRAND.LOGIN.SSO_OPTIONS.SCOPES.join(' '))
            .set('grant_type', 'authorization_code')
            .set('code_challenge', challenge)
            .set('code_challenge_method', 'S256')
            .set('response_type', 'code')
            .set('state', state)
            .toString();
          window.location.href = this.APP_BRAND.LOGIN.SSO + '?' + queryParams;
          return false;
        });
    } else if (this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'KEYCLOAK') {
      const domainConfig = this.APP_BRAND.LOGIN.SSO_OPTIONS.HOSTS[window.location.host];
      if (!domainConfig) {
        return false;
      }

      const queryParams = new HttpParams()
        .set('response_type', 'code')
        .set('redirect_uri', domainConfig.redirectUri)
        .set('client_id', domainConfig.clientId)
        .toString();

      let ssoUrl = this.APP_BRAND.LOGIN.SSO;
      Object.entries(domainConfig).forEach(([key, value]) => {
        ssoUrl = ssoUrl.replace('{' + key + '}', value);
      });

      window.location.href = ssoUrl + '?' + queryParams;
      return false;
    }

    const ssoLogoutToken = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    const redirectUrl = encodeURIComponent(window.location.protocol + '//' + window.location.host + '?ssoLogoutToken=' + ssoLogoutToken);
    const logoutUrl = encodeURIComponent(this.BACKEND_OPTIONS.restUrlSETTINGS + '/auth/sso/logout/' + ssoLogoutToken);
    let redirectURL = this.APP_BRAND.LOGIN.SSO;
    redirectURL = redirectURL.replace('xxx', redirectUrl);
    redirectURL = redirectURL.replace('yyy', logoutUrl);
    window.location.href = redirectURL;
    return false;
  }

  login(username, password, ssoToken?, ssoAuthorizationCode?, ssoCodeVerifier?, ssoRedirectURI?, ssoLogoutToken?) {
    this.resetUserValues();
    this.updateAuthorizationHeader();

    const resource = (ssoToken || ssoAuthorizationCode ? 'auth/sso' : 'auth');
    const postData = (ssoToken
        ? { ssoToken: ssoToken, ssoLogoutToken: ssoLogoutToken }
        : (ssoAuthorizationCode
          ? { ssoAuthorizationCode, ssoCodeVerifier, ssoRedirectURI }
          : { username: username, password: password })
    );

    return this.restangularUsers.all(resource).customPOST(postData, 'login', {loadCompany: true}).toPromise().then((data) => {
      if (data.value && data.user && data.user.applications.length > 0) {
        return data;
      } else {
        const msg = data.user.applications.length === 0 ? 'Uživatel nemá přiřazen žádný modul. Kontaktujte podporu.' : 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
        return Promise.reject({msg: msg});
      }
    })
    .then((data) => {
      this.token = data.value;
      this.user = data.user;
      this.applications = data.user.applications;

      // Filter "applicationProjects" to include only modules with user permissions.
      this.user.applicationProjects = Object.keys(this.user.applicationProjects)
        .filter(key => this.applications.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.user.applicationProjects[key];
          return obj;
        }, {});

      // TODO - remove, get settings module from BE
      // Every user with superuser has module SETTINGS
      if (this.user.superuser) {
        this.applications.push('SETTINGS');
        // this.applications.push('VZORY');
      }

      if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
        this.applications.push('LANDING');
      }

      this.SYdefaultProjectKey = this.localStorageService.get('auth-default-project-key-sy');
      this.DIdefaultProjectKey = this.localStorageService.get('auth-default-project-key-di');
      this.symapLastPojectKeys = this.localStorageService.get('auth-last-visited-project-keys-sy');

      this.localStorageService.set('auth-token', this.token);
      this.localStorageService.set('auth-user', this.user);
      this.localStorageService.set('auth-applications', this.getApplications());
      this.updateAuthorizationHeader();
      this.onAuthUserUpdated(data);
    });
  }

  switchProject(project, applicationKey) {
    switch (applicationKey) {
      case this.APPLICATIONS.sy.name:
        this.SYdefaultProjectKey = project.key;
        this.localStorageService.set('auth-default-project-key-sy', project.key);

        let lastProjects = this.symapLastPojectKeys ? this.symapLastPojectKeys : [];
        lastProjects.unshift(project.key);
        lastProjects = _.uniq(lastProjects);
        this.symapLastPojectKeys = lastProjects;
        this.localStorageService.set('auth-last-visited-project-keys-sy', lastProjects);
        break;
      case this.APPLICATIONS.di.name:
        this.DIdefaultProjectKey = project.key;
        this.localStorageService.set('auth-default-project-key-di', project.key);
        break;
    }

    this.updateBaseUrl();
  }

  switchProjectByKey(projectKey, applicationKey) {
    if (!this.getActualProjects()) {
      return;
    }

    switch (applicationKey) {
      case this.APPLICATIONS.sy.name:
        this.SYdefaultProjectKey = projectKey;
        break;
      case this.APPLICATIONS.di.name:
        this.DIdefaultProjectKey = projectKey;
        break;
    }

    const project = this.getActualProject();

    if (!project) {
      return;
    } else {
      this.switchProject(project, applicationKey);
    }
  }

  getActualProjects() {
    const application = this.getActiveApplication();
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return this.symapProjects;
      case this.APPLICATIONS.di.name:
        return this.dimapProjects;
      case this.APPLICATIONS.vfze.name:
        return this.vfzeProjects;
      case this.APPLICATIONS.sv.name:
        return this.svProjects;
      case this.APPLICATIONS.pk.name:
        return this.promapProjects;
      default:
        return false;
    }
  }

  getLastVisitedProjects(applicationKey) {
    switch (applicationKey) {
      case this.APPLICATIONS.sy.name:
        return this.symapLastPojectKeys;
      default:
        return [];
    }
  }

  getActualProject() {
    const application = this.getActiveApplication();
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return this.symapProjects && this.symapProjects.length > 0 ? this.symapProjects.find(p => p.key === this.SYdefaultProjectKey) || this.symapProjects[0] : false;
      case this.APPLICATIONS.di.name:
        return this.dimapProjects && this.dimapProjects.length > 0 ? this.dimapProjects.find(p => p.key === this.DIdefaultProjectKey) || this.dimapProjects[0] : false;
      default:
        return false;
    }
  }

  getActualPermissions() {
    const application = this.getActiveApplication();
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return this.symapPermissions;
      case this.APPLICATIONS.di.name:
        return this.dimapPermissions;
      case this.APPLICATIONS.pk.name:
        return this.promapPermissions;
      case this.APPLICATIONS.settings.name:
        return this.settingsPermissions;
      case this.APPLICATIONS.dashboard.name:
        return this.dashboardPermissions;
      case this.APPLICATIONS.vfze.name:
        return this.vfzePermissions;
      case this.APPLICATIONS.landing.name:
        return this.landingPermissions;
      case this.APPLICATIONS.sv.name:
        return this.svPermissions;
      default:
        return false;
    }
  }

  checkProjectStatus(transition: Transition, applicationName, projectLoadingShortcut, projectLoader, errorPage) {
    if (this.isAuthenticated()) {
      if (this.isAuthenticatedApplication(applicationName)) {
        this.setActiveApplication(applicationName);
        const projects = this.getActualProjects();

        if (projects === false) {
          return Promise.resolve();
        }
        let loaderPromise;
        if (!projects) {
          // Get only projects from object
          loaderPromise = projectLoader(this.user.id).then(data => data.projects);
        } else {
          loaderPromise = Promise.resolve(projects);
        }

        return loaderPromise.then((projects) => {
          if (projects === false) {
            return Promise.resolve();
          }

          if (projects.length === 0) {
            // user is autenticated and has permissions for requested module, but does not have any projects -> redirect to login
            return Promise.reject(transition.router.stateService.target(errorPage, {errorCode: 4}));
          }

          let projectKey = transition.params().projectKey;
          // First load of module without prefered project. projectKey === sy_ || di_
          if (projectKey === projectLoadingShortcut && projects.length) {
            projectKey = projects[0].key;
            const newParams = {...transition.params()};
            newParams.projectKey = projectKey;
            return Promise.reject(transition.router.stateService.target(transition.to(), newParams, transition.options()));
          }

          if (!projects.some(p => p.key === projectKey)) {
            // user is autenticated and has permissions for requested module, but does not have permissions for requested project -> redirect to login
            return Promise.reject(transition.router.stateService.target(errorPage, {errorCode: 1}));
          }
        }, () => {
          // some error on loading projects
          return Promise.reject(transition.router.stateService.target('error', {errorCode: 5}));
        });
      } else {
        // user is autenticated, but not for requested module -> redirect to login
        return Promise.reject(transition.router.stateService.target('error', {errorCode: 2}));
      }
    } else {
      // user is not autenticated -> redirect to login
      return Promise.reject(transition.router.stateService.target('error', {errorCode: 3, redirectUrl: window.location.hash.split('#!')[1]}));
    }
  }

  logout() {
    // set google analytics user as unlogged
    (window as any).GoogleAnalytics('set', 'userId', 'unlogged');

    this.restangularUsers.all('auth').one('logout').customPOST(this.token).toPromise();

    this.resetUserValues();
    this.updateAuthorizationHeader();
    this.onAuthProjectUpdated(null);
    return this.onAuthUserUpdated(null, false).then(() => {
      if (this.APP_BRAND.LOGIN.SSO_LOGOUT) {
        if (this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'KEYCLOAK') {
          const domainConfig = this.APP_BRAND.LOGIN.SSO_OPTIONS.HOSTS[window.location.host];

          if (!domainConfig) {
            return;
          }

          let logoutUrl = this.APP_BRAND.LOGIN.SSO_LOGOUT;
          Object.entries(domainConfig).forEach(([key, value]) => {
            logoutUrl = logoutUrl.replace('{' + key + '}', value);
          });

          window.location.href = logoutUrl;

        } else {
          window.location.href = this.APP_BRAND.LOGIN.SSO_LOGOUT;
        }
      }
      return true;
    });
  }

  getServiceDeskPortalID() {
    const application = this.getActiveApplication();
    switch (application) {
      case this.APPLICATIONS.sy.name:
        return this.SERVICE_DESK.PORTALSY;
      case this.APPLICATIONS.di.name:
        return this.SERVICE_DESK.PORTALDI;
      case this.APPLICATIONS.pk.name:
        return this.SERVICE_DESK.PORTALPRO;
      case this.APPLICATIONS.settings.name:
        return this.SERVICE_DESK.PORTALSETTINGS;
      default:
        return this.SERVICE_DESK.PORTALSY;
    }
  }

  getServiceDeskUrl(loginType?) {
    if (loginType !== true) {
      loginType = false;
    }
    if (!this.SERVICE_DESK.BASEURL) {
      return null;
    }
    let url = this.SERVICE_DESK.BASEURL;
    const portalId = this.getServiceDeskPortalID();
    if (!loginType && portalId) {
      url += '/portal/' + portalId;
    } else if (loginType && portalId) {
      url += '/portal/' + portalId;
      url += '/create/' + this.SERVICE_DESK.CREATELOGINERROR;
    }
    return url;
  }

  getProjectsByPermission(actions?: string, permissions?: any[]): string[] {
    const actualPermissions = permissions ? permissions : this.getActualPermissions();
    const projects = Object.keys(actualPermissions);
    return actions
      ? projects.filter(p => actions.split(',').some(a => actualPermissions[p].includes(a)))
      : projects.filter(p => actualPermissions[p].length > 0);
  }

  hasPermissionOnProject(actions: string, project?: string, permissions?: any[]): boolean {
    const actualPermissions = permissions ? permissions : this.getActualPermissions();

    // if project set, search action on the project
    if (project) {
      return actions.split(',').some(a => actualPermissions[project] && actualPermissions[project].includes(a));
    }

    const permissionsArrays = <any[][]>Object.values(actualPermissions);

    // if project not set, search action anywhere
    return actions.split(',').some(a => !!permissionsArrays.find(pa => pa.includes(a)));
  }

  canUpdateCaseData() {
    return true; // TODO
  }

  canUpdateCasePrice() {
    return true; // TODO
  }
}
