import { Component, Inject, OnInit } from '@angular/core';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'cadastre-update',
  templateUrl: './cadastre-update.component.html',
  styleUrls: ['./cadastre-update.component.scss']
})
export class CadastreUpdateComponent implements OnInit {

  constructor(
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.onSend = this.onSend.bind(this);
  }

  isValid(): boolean {
    return this.authService.getUser().superuser;
  }

  onSend(): Promise<any> {
    return this.restangularSymap.all('projects/cadastre/submit/monthly-update-projects').customPOST().toPromise();
  }
}

