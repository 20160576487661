import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'personal-visit',
  templateUrl: './personal-visit.component.html',
  styleUrls: ['./personal-visit.component.scss']
})
export class PersonalVisitComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  name: string;
  dataSend: any = {
    personalVisitDate: new Date(),
    recipientOpsubId: [],
  };
  dataSelected = {
    recipient: null
  };
  dataCaseOwnerships;
  recipients;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({ loadCollections: [ 'caseSubjects', 'caseOwnerships' ]}).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('PersonalVisit')).name;

    this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
    this.recipients = this.caseService.getRecipients(this.dataCaseOwnerships).filter((value) => {
      return !value.sentDate && !value.signedDate;
    });

    if (this.recipients.length === 1) {
      this.dataSelected.recipient = this.recipients[0];
    }

    this.loading = false;
  }

  isValid() {
    return !!this.dataSend.personalVisitDate;
  }

  async onSubmit(): Promise<any> {
    this.dataSend.recipientOpsubId = this.dataSelected.recipient.opsubIds || [this.dataSelected.recipient.id];
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(this.dataSend, 'personal-visit')
      .toPromise();
    this.stateService.go('^');
  }
}
