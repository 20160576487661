import { Inject, Injectable } from '@angular/core';
import { SampleModel, SampleProjectModel } from '@app/sv/models/sample.model';
import { TicketModel } from '@app/sv/models/ticket.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { NoteModel } from '@app/common/models/note.model';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
  ) {
  }

  getById(sampleId): Promise<SampleModel> {
    return this.restangular.one('samples', sampleId)
      .get()
      .toPromise()
      .then(sample => {
        return sample.plain();
      });
  }

  getByIdWithAttributes(sampleId, attributes): Promise<SampleModel> {
    return this.restangular.one('samples', sampleId)
      .customPOST(attributes)
      .toPromise()
      .then(sample => {
        return sample.plain();
      });
  }

  createByTicket(ticket: TicketModel): Promise<SampleModel> {
    return this.restangular
      .all('samples/create-by-ticket')
      .customPOST(ticket, '', { loadCollections: [] })
      .toPromise()
      .then(sample => {
        return sample.plain();
      });
  }

  update(sample: SampleModel, note?: NoteModel): Promise<SampleModel> {
    let updatedSample: SampleModel;
    return this.restangular
      .one('samples', sample.id)
      .customPUT(sample, '', { loadCollections: [] })
      .toPromise()
      .then(sample => {
        updatedSample = sample.plain();
      })
      .then(() => this.createNotes(updatedSample, (note ? [note] : [])))
      .then(() => updatedSample);
  }

  updateProjects(sample: SampleModel, note?: NoteModel): Promise<void> {
    return this.restangular
      .one('samples', sample.id).all('projects')
      .customPUT(sample.projects)
      .toPromise()
      .then(() => this.createNotes(sample, (note ? [note] : [])));
  }

  updateTemplate(sample: SampleModel, file: UploadFileExtended, note: NoteModel, reason: string): Promise<any> {
    return this.createDocuments(sample, [file], 'TEMPLATE', reason)
      .then(() => this.createNotes(sample, [note]));
  }

  createNotes(sample: SampleModel, notes: NoteModel[] = []): Promise<any> {
    const promises = notes.map(note => {
      return this.restangular
        .all('notes/create')
        .customPOST({ ...note, sample: sample })
        .toPromise();
    });
    return Promise.all(promises);
  }

  createDocuments(sample: SampleModel, files: UploadFileExtended[], type: string, note?: string): Promise<any> {
    const restBaseUrl = this.authService.getActiveApplicationRestUrl();
    const attachmentType = DocumentTypeModel.createById(type).id;
    const promises = files.map(file => {
      let filename = file.fileEntry.name;

      if (sample.number) {
        filename = sample.number + (type === 'TEMPLATE' ? '_s' : '') + '.' + file.extension.toLowerCase();
      }

      const sendData: any = {
        file: file.fileEntry,
        filename: file.fileEntry.name,
        sampleId: sample.id,
        attachmentType: attachmentType,
        baseName: filename
      };

      if (note) {
        sendData.note = note;
      }

      return this.uploadService.upload({
        url: restBaseUrl + '/attachments',
        data: sendData,
        headers: { Authorization: this.authService.getToken() }
      });
    });
    return Promise.all(promises);
  }
}
