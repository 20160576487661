import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { BuildingModel } from '@app/common/models/building.model';
import {APP_BRAND} from "@app/common/services/config.service";

@Pipe({ name: 'buildingNumber' })
@Injectable({
  providedIn: 'root'
})
export class BuildingNumberPipe implements PipeTransform {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
  ) {}

  transform(building: BuildingModel, onlyWithNumber = false): string {
    let short = '';

    switch (building.typKod) {
      case 1:
        short = 'č.p.';
        break;
      case 2:
        short = 'č.e.';
        break;
      case 3:
        short = 'bez čp/če';
        break;
      case 4:
        short = 'rozestav.';
        break;
      case 5:
        short = 'posch.gar';
        break;
      case 6:
        short = 'vod.dílo';
        break;
      case 7:
        short = 's roz.jed';
        break;
      default:
        short = building.cisloDomovni ? 'č.p.' : 'bez čp/če';
    }

    if (onlyWithNumber && !building.cisloDomovni) {
      return '';
    }

    return short + (building.cisloDomovni ? (' ' + building.cisloDomovni) : '');
  }
}
