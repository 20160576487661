<div>
  <div class="pl-40">
    <div class="table mb-10" *ngIf="seriesList?.length">
      <div *ngFor="let series of seriesList" style="display: flex;flex-direction: column;">
        <gmt-series
          [series]="series"
          [hideConstructionObject]="!withConstructionObjectSelection"
          (canceled)="onCancelSeries($event.series)"
          (restored)="onRestoreSeries($event.series)"
          (moved)="onMoveSeries($event.series, $event.step)"
          (changed)="onChangeSeries($event.series)"
        ></gmt-series>
      </div>
    </div>
  </div>
  <div class="column-100">
    <a class="button" (click)="onAddSeries()"><i class="fa fa-plus"></i></a>
  </div>
</div>
