import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { GeometricPlanRequestListComponent } from './components/geometric-plan-request-list/geometric-plan-request-list.component';
import {
  geometricPlanRequestCreateState,
  geometricPlanRequestDetailState,
  geometricPlanRequestListState,
  geometricPlanRequestCreateFromExistingState,
  geometricPlanRequestImportState
} from '@app/ps/geometric-plans/geometric-plan-requests.states';
import { GeometricPlanRequestCreateComponent } from './components/geometric-plan-request-create/geometric-plan-request-create.component';
import { GeometricPlanRequestDetailComponent } from './components/geometric-plan-request-detail/geometric-plan-request-detail.component';
import { GeometricPlanRequestEditComponent } from './components/geometric-plan-request-edit/geometric-plan-request-edit.component';
import { GeometricPlanRequestCreateFromExistingComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-create-from-existing/geometric-plan-request-create-from-existing.component';
import { UIRouterModule } from '@uirouter/angular';
import { GeometricPlanRequestImportComponent } from "@app/ps/geometric-plans/components/geometric-plan-request-import/geometric-plan-request-import.component";

const GEOMETRIC_PLAN_REQUEST_STATES = [
  geometricPlanRequestCreateState,
  geometricPlanRequestDetailState,
  geometricPlanRequestListState,
  geometricPlanRequestCreateFromExistingState,
  geometricPlanRequestImportState,
];

@NgModule({
  declarations: [
    GeometricPlanRequestListComponent,
    GeometricPlanRequestCreateComponent,
    GeometricPlanRequestDetailComponent,
    GeometricPlanRequestEditComponent,
    GeometricPlanRequestCreateFromExistingComponent,
    GeometricPlanRequestImportComponent,
  ],
  entryComponents: [
    GeometricPlanRequestEditComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [...GEOMETRIC_PLAN_REQUEST_STATES],
    }),
  ]
})
export class GeometricPlanRequestsModule { }
