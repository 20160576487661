import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Pipe({ name: 'gmtApplications' })
@Injectable({
  providedIn: 'root'
})
export class ApplicationsPipe implements PipeTransform {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    @Inject(APPLICATIONS) private APPLICATIONS: any,
  ) {}

  transform(applications = []): string {
    return applications.map(name => {
      return name === this.APPLICATIONS.sy.name ? this.APP_BRAND.PS.TITLE : name;
    }).join(', ');
  }
}
