import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';

import { ParcelModel } from '@app/common/models/parcel.model';
import { DialogService } from '@app/common/services/dialog.service';
import { ExternalParcelFormComponent } from '@app/ps/components/external-parcel-form/external-parcel-form.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ListModel } from '@app/common/models/list.model';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'create-case-select-external-parcels',
  templateUrl: './create-case-select-external-parcels.component.html',
})
export class CreateCaseSelectExternalParcelsComponent implements OnInit {

  @Output() selected = new EventEmitter<ParcelModel[]>();

  readonly PARCEL_COLS = [
    { id: 'parcel', sortable: true },
    { id: 'title', sortable: true },
    { id: 'area_name', sortable: true },
    { id: 'area', sortable: false },
  ];

  externalParcelList: ListModel<ParcelModel> = this.listService.createList('parcels', {});
  externalParcelListAdded: ListModel<ParcelModel> = { loading: false, list: [], itemCount: 0 };
  checklistExternalParcels: any;
  checklistExternalParcelsAdded: any;
  checkedAll = false;
  checkedAllAdded = false;
  loading: boolean;

  titleFilter = {
    subjectId: [],
    areaId: [],
    validity: ['valid'],
  };

  parcelFilter = {
    filters: {
      subjectId: [],
      validity: ['valid'],
      areaId: [],
      titleId: [],
      searchText: { values: [{ id: '' }]},
    },
    sortOrder: [{ sortBy: 'katuzeNazev' }, { sortBy: 'lv' }],
    limit: 20,
    offset: 0
  };

  constructor(
    private restangular: Restangular,
    public titleNamePipe: TitleNamePipe,
    private dialogService: DialogService,
    private listService: ListService,
    private authService: AuthService,
  ) {
    this.isChecked = this.isChecked.bind(this);
    this.isCheckedAdded = this.isCheckedAdded.bind(this);
    this.onAddParcel = this.onAddParcel.bind(this);
    this.onLoadNextParcels = this.onLoadNextParcels.bind(this);
    this.onCadastreChanged = this.onCadastreChanged.bind(this);
    this.onSubmitFilter = this.onSubmitFilter.bind(this);
  }

  ngOnInit() {
    this.loadSubject();
    this.checklistExternalParcels = new ChecklistModel();
    this.checklistExternalParcelsAdded = new ChecklistModel();
  }

  isChecked(parcel: ParcelModel): boolean {
    return this.checklistExternalParcels.isChecked(parcel);
  }

  isCheckedAdded(parcel: ParcelModel): boolean {
    return this.checklistExternalParcelsAdded.isChecked(parcel);
  }

  onToggleOne(parcel: ParcelModel) {
    this.checklistExternalParcels.toggleSelection(parcel);
    this.checkedAll = (this.externalParcelList.list.length === this.checklistExternalParcels.checkedItems);
    this.notify();
  }

  onToggleOneAdded(parcel: ParcelModel) {
    this.checklistExternalParcelsAdded.toggleSelection(parcel);
    this.checkedAllAdded = (this.externalParcelListAdded.list.length === this.checklistExternalParcelsAdded.checkedItems);
    this.notify();
  }

  onToggleAll() {
    this.checkedAll = !this.checkedAll;
    this.externalParcelList.list.forEach((parcel: ParcelModel) => {
      if (!(this.checkedAll && this.checklistExternalParcels.isChecked(parcel))) {
        this.checklistExternalParcels.toggleSelection(parcel);
      }
    });
    this.notify();
  }

  onToggleAllAdded() {
    this.checkedAllAdded = !this.checkedAllAdded;
    this.externalParcelListAdded.list.forEach((parcel: ParcelModel) => {
      if (!(this.checkedAllAdded && this.checklistExternalParcelsAdded.isChecked(parcel))) {
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
      }
    });
    this.notify();
  }

  onCadastreChanged() {
    this.titleFilter.areaId = this.externalParcelList.filter.filters.areaId;
  }

  onSubmitFilter() {
    this.checklistExternalParcels.empty();
    this.externalParcelList.filter.offset = 0;
    return this.listService.fetchResult(this.externalParcelList);
  }

  onLoadNextParcels(): Promise<any> {
    this.externalParcelList.filter.offset += this.externalParcelList.filter.limit;
    return this.listService.fetchResult(this.externalParcelList, true);
  }

  onAddParcel() {
    const dialog = this.dialogService.open(ExternalParcelFormComponent, { className: ClassName.HIGHER_DIALOG });
    const subscriber = dialog.afterClosed.subscribe((parcel: ParcelModel) => {
      if (parcel) {
        this.externalParcelListAdded.list = [parcel].concat(this.externalParcelListAdded.list);
        this.externalParcelListAdded.itemCount++;
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
        this.notify();
      }
      subscriber.unsubscribe();
    });
  }

  private loadSubject(): Promise<any> {
    const project = this.authService.getActualProject();

    if (!project.investor || !project.investor.opsubId) {
      this.externalParcelList.list = [];
      this.externalParcelList.itemCount = 0;
      return;
    }

    this.loading = true;
    return this.restangular.all('subjects')
      .post({ filter: { filters: { opsubId: [ project.investor.opsubId ], validity: ['valid']}}})
      .toPromise()
      .then((subjects) => {
        const subjectId = subjects[0] ? subjects[0].id : 0;
        this.parcelFilter.filters.subjectId = [subjectId];
        this.titleFilter.subjectId = [subjectId];
        this.externalParcelList.filter = this.parcelFilter;
        this.loading = false;
      });
  }

  private notify() {
    const checked = this.checklistExternalParcels.checkedItems.concat(this.checklistExternalParcelsAdded.checkedItems);
    this.selected.emit(checked);
  }
}
