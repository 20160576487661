import { Injectable } from '@angular/core';
import * as  L from 'leaflet';
import * as _ from 'lodash';
import { MapService } from '@app/map/services/map.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import { MapStorageService } from '@app/map/services/map-storage.service';
import { MapOptionsStorageService } from '@app/map/services/map-options-storage.service';
import { MapLayersStorageService } from '@app/map/services/map-layers-storage.service';
import { MapFullScreenService } from '@app/map/services/map-full-screen.service';
import { MapLayerService } from '@app/map/services/map-layer.service';

@Injectable({
  providedIn: 'root'
})
export class LeafletMapService {

  constructor(
    private mapService : MapService ,
    private mapUtilsCrsService : MapUtilsCrsService,
    private mapStorageService : MapStorageService,
    private mapOptionsStorageService : MapOptionsStorageService,
    private mapLayersStorageService : MapLayersStorageService,
    private mapLayerService : MapLayerService,
    private mapFullScreenService: MapFullScreenService
  ) {
    this.addSingleClickFeature();
  }

  setOptionsCrs(options){
    if (options['srid']) {
        var crs = this.mapUtilsCrsService.getCrs(options['srid']);
        if (crs === undefined) {
            throw new Error('CRS with srid ' + options['srid'] + ' not defined.');
        }
        options['crs'] = crs;
    }
  }

  setFullscreen(map, opts) {
      if (opts.fullscreen) {
          map.fullscreenControl = this.mapFullScreenService.getFullscreenControl({});
      }
  }

  setBounds(mapId, opts) {
      if (opts.bounds && (opts.bounds instanceof L.LatLngBounds)) {
          this.mapService.fitBounds(mapId, opts.bounds);
      }
  }

  setInitMapPos(map) {
      map.options.initCenter = _.cloneDeep(map.getCenter());
      map.options.initZoom = _.cloneDeep(map.getZoom());
  }

  storeMap(map, mapId) {
      this.mapStorageService.setMap(map, mapId);
  }

  resetMap(mapId) {
      this.mapStorageService.resetMap(mapId);
  }

  storeOptions(opts, mapId) {
      this.mapOptionsStorageService.setOptions(opts, mapId);
  }

  resetOptions(mapId) {
      this.mapOptionsStorageService.resetOptions(mapId);
  }

  resetLayers(mapId) {
      this.mapLayersStorageService.resetLayers(mapId);
  }

  initLayers(mapId, config) {
      this.mapLayersStorageService.setLayers({}, mapId);
      this.mapLayerService.initLayersForMap(mapId, config);
  }

  enableLoadingWatch(mapId){
      this.mapService.enableLoadingWatch(mapId);
  }

  addSingleClickFeature() {
    L.Evented.addInitHook( function () {
        this._singleClickTimeout = null;
        this.on( 'click', this._scheduleSingleClick, this );
        this.on( 'dblclick dragstart zoomstart', this._cancelSingleClick, this );
    });

    L.Evented.include({

        _cancelSingleClick : function(){
            // This timeout is key to workaround an issue where double-click events
            // are fired in this order on some touch browsers: ['click', 'dblclick', 'click']
            // instead of ['click', 'click', 'dblclick']
            setTimeout( this._clearSingleClickTimeout.bind(this), 0 );
        },

        _scheduleSingleClick: function(e) {
            this._clearSingleClickTimeout();

            this._singleClickTimeout = setTimeout(
                this._fireSingleClick.bind(this, e),
                (this.options.singleClickTimeout || 250)
            );
        },

        _fireSingleClick: function(e){
            if ( !e.originalEvent._stopped ) {
                this.fire( 'singleclick', L.Util.extend( e, { type : 'singleclick' } ) );
            }
        },

        _clearSingleClickTimeout: function(){
            if (this._singleClickTimeout) {
                clearTimeout( this._singleClickTimeout );
                this._singleClickTimeout = null;
            }
        }

    });
  }
}
