export enum DocumentTypeCategoryEnum {
  CONTRACT = 'CONTRACT',
  COVERING_LETTER = 'COVERING_LETTER',
  INFORMATION_LETTER = 'INFORMATION_LETTER',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
  OTHER = 'OTHER',
  FINANCIAL_CONTROL = 'FINANCIAL_CONTROL',
  PRELIMINARY_PAYMENT_ORDER = 'PRELIMINARY_PAYMENT_ORDER',
}

export const documentTypeCategoryOptions = [
  {
    id: DocumentTypeCategoryEnum.CONTRACT,
    name: 'Návrh smlouvy',
  },
  {
    id: DocumentTypeCategoryEnum.COVERING_LETTER,
    name: 'Průvodní dopis',
  },
  {
    id: DocumentTypeCategoryEnum.INFORMATION_LETTER,
    name: 'Informační dopis',
  },
  {
    id: DocumentTypeCategoryEnum.PAYMENT_ORDER,
    name: 'Platební příkaz',
  },
  {
    id: DocumentTypeCategoryEnum.OTHER,
    name: 'Ostatní',
  },
  {
    id: DocumentTypeCategoryEnum.FINANCIAL_CONTROL,
    name: 'Finanční kontrola před vznikem závazku',
  },
  {
    id: DocumentTypeCategoryEnum.PRELIMINARY_PAYMENT_ORDER,
    name: 'Proforma platebního příkazu',
  },
];
