<div class="detail">
  <div *ngIf="doneRedirect" class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <simple-case-status-form [data]="data" [config]="config.formFields" (callbackRegister)="formCallbackRegister($event)"></simple-case-status-form>

    <fieldset *ngIf="config.hasNote">
      <legend>Poznámka</legend>
      <div class="column-100" *ngIf="config.hasNoteType">
        <label class="required">Typ poznámky</label>
        <div class="input">
          <gmt-select
            [data]="data.note"
            field="noteType"
            resource="note-types"
            [filter]="config.noteTypeFilter"
          ></gmt-select>
        </div>
      </div>
      <div class="column-100">
        <label class="required">Text poznámky</label>
        <textarea [(ngModel)]="data.note.text"></textarea>
      </div>
    </fieldset>

    <div>
      <gmt-button *ngIf="!doneRedirect" [clickAction]="onCancel">Zrušit</gmt-button>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
