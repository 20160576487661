﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-entry-declined',
	templateUrl: './form-case-entry-declined.component.html',
})
export class FormCaseEntryDeclinedComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.cadastreEntryDeclinedDate = this.data.cadastreEntryDeclinedDate || new Date();
    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.cadastreEntryDeclinedDate;
  }
}
