import { User } from '@app/models/user';

export class DocumentModel {
  id: number;
  baseName: string;
  type: string;
  canViewPDF: boolean;
  createdUser: User;
  dataSize: number;
  extension: string;
  name: string;
  timestamp: number;
  note: string;
  userFullName?: string;
  cancelled: boolean;
  mapSuccessfullyGenerated: boolean;
}

export const allowedExtensions = [
  'txt', 'doc', 'docx', 'dot', 'dotx', 'odt', 'docs',
  'xls', 'xlsx', 'csv', 'ods',
  'pdf',
  'dgn', 'dxf', 'dwg', 'shp', 'shx', 'dbf', 'gpkg',
  'vfk', 'vfze',
  'xml',
  'jpg', 'jpeg', 'bmp', 'png', 'svg',
  'msg',
  'zip',
];
