<!-- first column for person -->
<ng-template #personLeft>
  <gmt-input *ngIf="editable" label="Stav">
    <gmt-select [data]="basicInfo" field="maritalStatus" [optionItems]="MARITAL_STATUSES"></gmt-select>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>Stav</label>
    <div class="info">{{ basicInfo.maritalStatus?.name }}</div>
  </ng-container>

  <gmt-input *ngIf="editable" label="IČO (OSVČ)">
    <input type="text" [(ngModel)]="basicInfo.identificationNumber"/>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>IČO (OSVČ)</label>
    <div class="info">{{ basicInfo.identificationNumber }}</div>
  </ng-container>

  <gmt-input *ngIf="editable" label="DIČ (OSVČ)">
    <input type="text" [(ngModel)]="basicInfo.dic"/>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>DIČ (OSVČ)</label>
    <div class="info">{{ basicInfo.dic }}</div>
  </ng-container>

  <gmt-input *ngIf="editable" label="Platba na základě faktury OSVČ">
    <gmt-checkbox
      gmtType="checkbox"
      [gmtDisabled]="!editable"
      [gmtChecked]="basicInfo.invoicePayment"
      (click)="basicInfo.invoicePayment = !basicInfo.invoicePayment">
    </gmt-checkbox>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>Platba na základě faktury OSVČ</label>
    <div class="info">{{ basicInfo.invoicePayment ? 'ano' : 'ne' }}</div>
  </ng-container>

</ng-template>

<!-- second column for person -->
<ng-template #personRight>
  <gmt-input *ngIf="editable" label="Pohlaví">
    <gmt-select [data]="basicInfo" field="gender" [optionItems]="GENDERS"></gmt-select>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>Pohlaví</label>
    <div class="info">{{ basicInfo.gender?.name }}</div>
  </ng-container>

  <gmt-input *ngIf="editable" label="Rodné číslo">
    <input type="text" [(ngModel)]="basicInfo.personalIdentificationNumber"/>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>Rodné číslo</label>
    <div class="info">{{ basicInfo.personalIdentificationNumber }}</div>
  </ng-container>

  <gmt-input *ngIf="editable" label="Datum narození">
    <input pick-a-date type="text" [(ngModel)]="basicInfo.dateOfBirth"/>
  </gmt-input>

  <ng-container *ngIf="!editable">
    <label>Datum narození</label>
    <div class="info">{{ basicInfo.dateOfBirth | date:'mediumDate' }}</div>
  </ng-container>

</ng-template>

<ng-template #company>
  <gmt-input *ngIf="editable && showAres">
    <gmt-button [gmtDisabled]="!basicInfo.ico" [clickAction]="findInEconomicSubjectsRegister">Vyhledat v systému ARES</gmt-button>
  </gmt-input>

  <div [loading]="searchingEconomicSubjectsRegister">
    <gmt-input *ngIf="editable" label="Rejstříkový soud">
      <gmt-select [restangularService]="restangular" [filter]="{filters: {businessRegister: true}}" [data]="basicInfo" field="businessRegisterRegionalCourt" resource="regional-courts"></gmt-select>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Rejstříkový soud</label>
      <div class="info">{{ basicInfo.businessRegisterRegionalCourt?.name }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Oddíl">
      <input type="text" [(ngModel)]="basicInfo.businessRegisterSection"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Oddíl</label>
      <div class="info">{{ basicInfo.businessRegisterSection }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Vložka">
      <input type="text" [(ngModel)]="basicInfo.businessRegisterEntry"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Vložka</label>
      <div class="info">{{ basicInfo.businessRegisterEntry }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="DIČ">
        <input type="text" [(ngModel)]="basicInfo.dic"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>DIČ</label>
      <div class="info">{{ basicInfo.dic }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Firma je plátcem DPH" (click)="basicInfo.vatPayer = !basicInfo.vatPayer">
      <gmt-checkbox [gmtChecked]="basicInfo.vatPayer"></gmt-checkbox>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Firma je plátcem DPH</label>
      <div class="info">{{ basicInfo.vatPayer ? 'ano' : 'ne' }}</div>
    </ng-container>
  </div>
</ng-template>

<fieldset>
  <legend>Informace o {{ basicInfo.opsubType === 'OFO' ? 'osobě' : 'společnosti' }} <a *ngIf="editableToggle" (click)="editable = !editable"><i class="fa fa-pencil"></i></a></legend>
  <div class="column-50">
    <ng-container *ngIf="!['OPO', 'OPO_OSS', 'OPO_PO'].includes(basicInfo.opsubType)">
      <ng-container *ngTemplateOutlet="personLeft"></ng-container>
    </ng-container>

    <ng-container *ngIf="['OPO', 'OPO_OSS', 'OPO_PO'].includes(basicInfo.opsubType)">
      <ng-container *ngTemplateOutlet="company"></ng-container>
    </ng-container>
  </div>

  <div class="column-50">
    <ng-container *ngIf="!['OPO', 'OPO_OSS', 'OPO_PO'].includes(basicInfo.opsubType)">
      <ng-container *ngTemplateOutlet="personRight"></ng-container>
    </ng-container>
  </div>
</fieldset>

