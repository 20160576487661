<div *ngFor="let opinionRequest of opinionRequests">
  <div (click)="$event.stopPropagation()">
    <a uiSref="symap.project.opinionRequests.detail" [uiParams]="{ id: opinionRequest.id, tab: 'parcels' }">
      {{ opinionRequest.name }}
    </a>
  </div>
</div>

<div *ngFor="let opinion of opinions">
  <ng-container *ngIf="shouldBeOpinionDisplayed(opinion)">
    {{ opinion.expertOpinionNumber || opinion.preliminaryOpinionNumber }}
  </ng-container>
</div>
