import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import * as L from 'leaflet';

@Component({
  selector: 'map-module-cuzk',
  templateUrl: './map-module-cuzk.component.html',
})
export class MapModuleCuzkComponent implements OnInit, OnDestroy {
  @Input() mapId: string;
  @Output() visibilityInfoboxCallback =  new EventEmitter();

  marker = null;

  constructor(
    private mapService: MapService,
    private mapUtilsCrsService: MapUtilsCrsService,
  ) {
  }

  ngOnInit() {
    this.visibilityInfoboxCallback.emit({ infoboxVisibility: true });
    this.mapService.getMap(this.mapId).then((map) => {
      map.on('singleclick', this.onMapClickHandler);
    });
  }

  ngOnDestroy() {
    this.mapService.getMap(this.mapId).then((map) => {
      map.off('singleclick', this.onMapClickHandler);
    });
    // remove marker
    if (this.marker) {
      this.marker.remove();
    }
  }

  onMapClickHandler(clickEvt) {
    // add marker
    if (this.marker) {
      this.marker.remove();
    }

    this.mapService.getMap(this.mapId).then((map) => {
      const icon = L.icon({
        iconUrl: require('src/img/map/marker-icon.png'),
        iconSize:     [25, 41],
        iconAnchor:   [13, 41]
      });
      this.marker = L.marker(clickEvt.latlng, { icon: icon }).addTo(map);
    });

    const point = this.mapUtilsCrsService.getCrs('5514').project(clickEvt.latlng);
    const url = 'http://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=' + Math.round(point.x) + '&y=' + Math.round(point.y);

    window.open(url, '_blank');
  }
}
