import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { HomeComponent } from '@app/ps/home/components/home/home.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { MpvStatisticsComponent } from '@app/ps/home/components/mpv-statistics/mpv-statistics.component';

export const homeState: Ng2StateDeclaration = {
  name: 'symap.project.home',
  url: '/home?statisticsEntityTab&statisticsStatistics',
  data: {
    title: 'Přehled',
  },
  params: {
    statisticsEntityTab: { dynamic: true },
    statisticsStatistics: { dynamic: true, type: 'int'},
  },
  views: {
    'content@symap.project': {
      component: HomeComponent,
    },
  },
};

@NgModule({
  declarations: [
    HomeComponent,
    MpvStatisticsComponent,
  ],
  entryComponents: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [
        homeState,
      ],
    }),
  ]
})
export class HomeModule { }
