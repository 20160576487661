import {Component, EventEmitter, Input, Output} from "@angular/core";

import {TabbedBlockModel} from "../../models/tabbed-block.model";

@Component({
  selector: 'gmt-tabbed-block',
  templateUrl: './tabbed-block.component.html',
  styleUrls: ['./tabbed-block.component.scss']
})
export class TabbedBlockComponent {

  @Input() tabs: TabbedBlockModel[];
  @Input() selected: string;
  @Output() updated = new EventEmitter<TabbedBlockModel>();

  constructor() {
    this.onUpdateSelection = this.onUpdateSelection.bind(this);
  }

  onUpdateSelection(tab: TabbedBlockModel) {
    this.selected = tab.id;
    this.updated.emit(tab);
  }
}
