<div>
  <fieldset>
    <legend>Základní údaje nájmu</legend>

    <div class="column-50">
      <gmt-input label="Typ nájmu" class="mt-10">
        <gmt-switch [options]="rentalTypes" [selected]="rentalTypeOption" (select)="onSelectRentalTypeOption($event)"></gmt-switch>
      </gmt-input>

      <div *ngIf="parcel.parcelDetail.rentalType">
        <gmt-input label="Nájem uzavřen do data" class="mt-10">
          <input type="text" pick-a-date [(ngModel)]="parcel.parcelDetail.rentalDateTo"/>
        </gmt-input>
      </div>
    </div>

    <div class="column-50">
      <div *ngIf="parcel.parcelDetail.rentalType">
        <gmt-input label="Typ nájemce" class="mt-10">
          <gmt-switch [options]="opsubTypes" [selected]="opsubTypeOption" (select)="onSelectOpsubTypeOption($event)"></gmt-switch>
        </gmt-input>
      </div>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType == 'OPO'">
    <legend>Základní údaje nájemce</legend>

    <div class="column-50">
      <gmt-input label="Název" [required]="true" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantNazev"/>
      </gmt-input>

      <gmt-input label="IČO" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantIco"/>
      </gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="DIČ" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantDic"/>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType == 'OFO'">
    <legend>Základní údaje nájemce</legend>

    <div class="column-50">
      <gmt-input label="Titul před jménem" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantTitulPredJmenem"/>
      </gmt-input>

      <gmt-input label="Křestní jméno" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantJmeno"/>
      </gmt-input>

      <gmt-input label="Příjmení" [required]="true" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantPrijmeni"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Titul za jménem" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantTitulZaJmenem"/>
      </gmt-input>

      <gmt-input label="R.Č." class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantRodneCislo"/>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType == 'OPO'">
    <legend>Podepisující osoba za nájemce</legend>

    <div class="column-50">
      <gmt-input label="Titul před jménem" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantActingTitleBefore"/>
      </gmt-input>

      <gmt-input label="Křestní jméno" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantActingFirstName"/>
      </gmt-input>

      <gmt-input label="Příjmení" class="mt-10" >
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantActingSurname"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Titul za jménem" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantActingDegreeAfter"/>
      </gmt-input>

      <gmt-input label="Funkce" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantActingFunction"/>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType == 'OPO'">
    <legend>Zápis OR</legend>

    <div class="column-50">
      <gmt-input label="Rejstříkový soud" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantBusinessRegisterName"/>
      </gmt-input>

      <gmt-input label="Oddíl" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantBusinessRegisterSection"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Vložka" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantBusinessRegisterEntry"/>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType && parcel.parcelDetail.tenantOpsubType">
    <legend>Adresa nájemce</legend>

    <div class="column-50">
      <gmt-input label="Ulice" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantUlice"/>
      </gmt-input>

      <gmt-input label="Číslo popisné" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantCisloDomovni"/>
      </gmt-input>

      <gmt-input label="Číslo orientační" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantCisloOrientacni"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Město" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantObec"/>
      </gmt-input>

      <gmt-input label="PSČ" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantPsc" gmtInputPostCode />
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="parcel.parcelDetail.rentalType">
    <legend>Kontakt</legend>

    <div class="column-50">
      <gmt-input label="Telefon" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantPhoneNumber"/>
      </gmt-input>
    </div>
    <div class="column-50">
      <gmt-input label="E-mail" class="mt-10">
        <input type="text" [(ngModel)]="parcel.parcelDetail.tenantEmail"/>
      </gmt-input>
    </div>
  </fieldset>

  <div class="actions">
    <gmt-button [gmtDisabled]="!isValid()" [clickAction]="update">Uložit nájemce</gmt-button>
  </div>
</div>
