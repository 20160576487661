import {Component, OnInit} from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'cadastre-data-date',
  templateUrl: './cadastre-data-date.component.html',
  styleUrls: []
})
export class CadastreDataDateComponent implements OnInit {

  cadastreDataDate: string;

  constructor(
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
     this.cadastreDataDate = this.authService.getActualProject() ? this.authService.getActualProject().cadastreDataDate : '';
  }
}
