import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ChangelogModel, createChangelog } from '@app/common/models/changelog.model';
import { EmailUtils } from '@app/common/utils/email.utils';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'changelog-edit',
  templateUrl: './changelog-edit.component.html',
})
export class ChangelogEditComponent implements OnInit {

  changelogId: number;
  applicationName: string;
  changelog: ChangelogModel;
  loading = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.changelogId = (<DialogConfigData>config.data).changelogId;
    this.applicationName = (<DialogConfigData>config.data).applicationName;
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    if (this.changelogId) {
      this.restangular
        .one('changelogs', this.changelogId)
        .get()
        .toPromise()
        .then(data => {
          this.changelog = data.plain();
          this.loading = false;
        });
    } else {
      this.changelog = createChangelog(this.applicationName);
      this.loading = false;
    }
  }

  onUpdate(): Promise<any> {
    return this.restangular
      .one('changelogs')
      .customPUT(this.changelog)
      .toPromise()
      .then(data => {
        this.dialog.close(data.plain());
      });
  }

  onCancel() {
    this.dialog.close();
  }

  getPreview(description: string): string {
    return EmailUtils.textToHtml(description);
  }

  isValid(): boolean {
    return !!this.changelog.name;
  }
}
