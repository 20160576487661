import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { OwnersModule } from '@app/ps/owners/owners.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import {
  casesState,
  contractsRegisterState,
  updateCaseState,
  updateCaseOccupation,
  updateCaseEasement,
  cadastreEntryProposalState,
  updateOccupationParcelState,
  meetingRequestState,
  acceptedGeometricalPlanPossibleState,
  expropriationInterimDecisionRequestState,
  expropriationInterimDecisionState,
  expropriationAdministrativeAppealState,
  expropriationAdministrativeAppealRejectedState,
  expropriationAdministrativeAppealAcceptedState,
  expropriationCompensationPaymentState,
  expropriationEnforceableState,
  expropriationAppealState,
  expropriationHearingRepetitionState,
  expropriationProceedingInteruptionExtendedState,
  expropriationProceedingInteruptedState,
  expropriationAssessmentInteruptedState,
  expropriationCalledHearingState,
  expropriationStopState,
  expropriationProceedingStartedState,
  handOverInvestorState,
  paymentInstructionState,
  paymentOrderState,
  sendAdministratorState,
  sendInvestorState,
  expropriationProposalState,
  signAdministratorState,
  signInvestorState,
  sendOwnerState,
  signOwnerState,
  receiveOwnerState,
  confirmState,
  cadastreEntryState,
  expropriationResultState,
  expropriationAppealResultState,
  auctionResultState,
  entryProposalState,
  paymentCheckPreliminaryState,
  paymentCheckState,
  generateState,
  generateFinancialControlState,
  undeliveredOwnerState,
  declinedOwnerState,
  personalVisitState,
  signedSentOwnersState,
  updateCaseOwnershipState,
  caseDetailState,
  callBeforeExpropriationState,
  callBeforeExpropriationReceivedState,
  expropriationCaseInProgressState,
  dunningLetterState,
  buildingAuthorityApprovalRequestState,
  buildingAuthorityApprovedState,
  buildingAuthorityRejectedState,
  sentAllOwnersState,
  approvedOwnerState,
  disapprovedOwnerState,
  exchangeRequestState,
  otherSolutionRequestState,
  withoutResolutionState,
} from './cases.states';

import { FormCaseSendInvestorComponent } from './components/form-case-send-investor/form-case-send-investor.component';
import { FormCaseSendAdministratorComponent } from './components/form-case-send-administrator/form-case-send-administrator.component';
import { FormCaseSignAdministratorComponent } from './components/form-case-sign-administrator/form-case-sign-administrator.component';
import { FormCaseHandOverInvestorComponent } from './components/form-case-hand-over-investor/form-case-hand-over-investor.component';
import { FormCasePaymentInstructionComponent } from './components/form-case-payment-instruction/form-case-payment-instruction.component';
import { FormCasePaymentOrderComponent } from './components/form-case-payment-order/form-case-payment-order.component';
import { FormCaseSignedSendOwnersComponent } from './components/form-case-signed-send-owners/form-case-signed-send-owners.component';
import { FormCaseSentAllOwnersComponent } from './components/form-case-sent-all-owners/form-case-sent-all-owners.component';
import { FormCaseSendOwnerComponent } from './components/form-case-send-owner/form-case-send-owner.component';
import { FormCaseUndeliveredOwnerComponent } from './components/form-case-undelivered-owner/form-case-undelivered-owner.component';
import { FormCaseReceiveOwnerComponent } from './components/form-case-receive-owner/form-case-receive-owner.component';
import { FormCaseDeclineOwnerComponent } from './components/form-case-decline-owner/form-case-decline-owner.component';
import { FormCaseSignOwnerComponent } from './components/form-case-sign-owner/form-case-sign-owner.component';
import { FormCaseEntryProposalComponent } from './components/form-case-entry-proposal/form-case-entry-proposal.component';
import { FormCaseEntryDeclinedComponent } from './components/form-case-entry-declined/form-case-entry-declined.component';
import { FormCaseEntryStoppedComponent } from './components/form-case-entry-stopped/form-case-entry-stopped.component';
import { FormCaseSignInvestorComponent } from './components/form-case-sign-investor/form-case-sign-investor.component';
import { FormCaseUpdateSubjectComponent } from './components/form-case-update-subject/form-case-update-subject.component';
import { FormCaseSubjectDatesComponent } from './components/form-case-subject-dates/form-case-subject-dates.component';
import { DistributionSystemPartsComponent } from './components/distribution-system-parts/distribution-system-parts.component';
import { FormCaseUpdateComponent } from './components/form-case-update/form-case-update.component';
import { FormContractsRegisterDialogComponent } from './components/form-contracts-register-dialog/form-contracts-register-dialog.component';
import { FormContractsRegisterComponent } from './components/form-contracts-register/form-contracts-register.component';
import { UpdateOccupationComponent } from './components/update-occupation/update-occupation.component';
import { UpdateEasementComponent } from './components/update-easement/update-easement.component';
import { CadastreEntryProposalComponent } from './components/cadastre-entry-proposal/cadastre-entry-proposal.component';
import { FormCadastreEntryProposalComponent } from './components/form-cadastre-entry-proposal/form-cadastre-entry-proposal.component';
import { CaseListComponent } from './components/case-list/case-list.component';
import { FormAcceptedGeometricalPlanPossibleComponent } from './components/form-accepted-geometrical-plan-possible/form-accepted-geometrical-plan-possible.component';
import { AcceptedGeometricalPlanPossibleComponent } from './components/accepted-geometrical-plan-possible/accepted-geometrical-plan-possible.component';
import { FormExpropriationInterimDecisionRequestComponent } from './components/form-expropriation-interim-decision-request/form-expropriation-interim-decision-request.component';
import { ExpropriationInterimDecisionRequestComponent } from './components/expropriation-interim-decision-request/expropriation-interim-decision-request.component';
import { FormExpropriationInterimDecisionComponent } from './components/form-expropriation-interim-decision/form-expropriation-interim-decision.component';
import { ExpropriationInterimDecisionComponent } from './components/expropriation-interim-decision/expropriation-interim-decision.component';
import { FormExpropriationAdministrativeAppealComponent } from './components/form-expropriation-administrative-appeal/form-expropriation-administrative-appeal.component';
import { ExpropriationAdministrativeAppealComponent } from './components/expropriation-administrative-appeal/expropriation-administrative-appeal.component';
import { FormExpropriationAdministrativeAppealRejectedComponent } from './components/form-expropriation-administrative-appeal-rejected/form-expropriation-administrative-appeal-rejected.component';
import { ExpropriationAdministrativeAppealRejectedComponent } from './components/expropriation-administrative-appeal-rejected/expropriation-administrative-appeal-rejected.component';
import { FormExpropriationAdministrativeAppealAcceptedComponent } from './components/form-expropriation-administrative-appeal-accepted/form-expropriation-administrative-appeal-accepted.component';
import { ExpropriationAdministrativeAppealAcceptedComponent } from './components/expropriation-administrative-appeal-accepted/expropriation-administrative-appeal-accepted.component';
import { FormExpropriationCompensationPaymentComponent } from './components/form-expropriation-compensation-payment/form-expropriation-compensation-payment.component';
import { ExpropriationCompensationPaymentComponent } from './components/expropriation-compensation-payment/expropriation-compensation-payment.component';
import { SimpleCaseStatusComponent } from './components/simple-case-status/simple-case-status.component';
import { SimpleCaseStatusFormComponent } from './components/simple-case-status-form/simple-case-status-form.component';
import { SignInvestorComponent } from './components/sign-investor/sign-investor.component';
import { SendOwnerComponent } from './components/send-owner/send-owner.component';
import { SignOwnerComponent } from './components/sign-owner/sign-owner.component';
import { ReceiveOwnerComponent } from './components/receive-owner/receive-owner.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { CadastreEntryComponent } from './components/cadastre-entry/cadastre-entry.component';
import { ExpropriationResultComponent } from './components/expropriation-result/expropriation-result.component';
import { AuctionResultComponent } from './components/auction-result/auction-result.component';
import { ExpropriationAppealResultComponent } from './components/expropriation-appeal-result/expropriation-appeal-result.component';
import { EntryProposalComponent } from './components/entry-proposal/entry-proposal.component';
import { PaymentCheckComponent } from './components/payment-check/payment-check.component';
import { GenerateDocumentsFormComponent } from './components/generate-documents-form/generate-documents-form.component';
import { GenerateDocumentsComponent } from './components/generate-documents/generate-documents.component';
import { DeclinedOwnerComponent } from './components/declined-owner/declined-owner.component';
import { PersonalVisitComponent } from './components/personal-visit/personal-visit.component';
import { UpdateCaseOwnershipComponent } from './components/update-case-ownership/update-case-ownership.component';
import { FormCaseUpdateParcelPriceComponent } from './components/form-case-update-parcel-price/form-case-update-parcel-price.component';
import { CaseDetailComponent } from './components/case-detail/case-detail.component';
import { CaseOwnerComponent } from './components/case-owner/case-owner.component';
import { ChangeCaseStatusComponent } from './components/change-case-status/change-case-status.component';
import { UIRouterModule } from '@uirouter/angular';
import { MapModule } from '@app/map/map.module';

const CASES_STATES = [
  casesState,
  caseDetailState,
  contractsRegisterState,
  updateCaseState,
  updateCaseOccupation,
  updateCaseEasement,
  cadastreEntryProposalState,
  updateOccupationParcelState,
  meetingRequestState,
  acceptedGeometricalPlanPossibleState,
  expropriationInterimDecisionRequestState,
  expropriationInterimDecisionState,
  expropriationAdministrativeAppealState,
  expropriationAdministrativeAppealRejectedState,
  expropriationAdministrativeAppealAcceptedState,
  expropriationCompensationPaymentState,
  expropriationEnforceableState,
  expropriationAppealState,
  expropriationHearingRepetitionState,
  expropriationProceedingInteruptionExtendedState,
  expropriationProceedingInteruptedState,
  expropriationAssessmentInteruptedState,
  expropriationCalledHearingState,
  expropriationStopState,
  expropriationProceedingStartedState,
  handOverInvestorState,
  paymentInstructionState,
  paymentOrderState,
  sendAdministratorState,
  sendInvestorState,
  expropriationProposalState,
  signAdministratorState,
  signInvestorState,
  sendOwnerState,
  signOwnerState,
  receiveOwnerState,
  confirmState,
  cadastreEntryState,
  expropriationResultState,
  expropriationAppealResultState,
  auctionResultState,
  entryProposalState,
  paymentCheckPreliminaryState,
  paymentCheckState,
  generateState,
  generateFinancialControlState,
  undeliveredOwnerState,
  declinedOwnerState,
  personalVisitState,
  signedSentOwnersState,
  updateCaseOwnershipState,
  callBeforeExpropriationState,
  callBeforeExpropriationReceivedState,
  expropriationCaseInProgressState,
  dunningLetterState,
  buildingAuthorityApprovalRequestState,
  buildingAuthorityApprovedState,
  buildingAuthorityRejectedState,
  sentAllOwnersState,
  approvedOwnerState,
  disapprovedOwnerState,
  exchangeRequestState,
  otherSolutionRequestState,
  withoutResolutionState,
];

@NgModule({
  imports: [
    CommonModule,
    PsCommonModule,
    OwnersModule,
    UIRouterModule.forChild({
      states: [...CASES_STATES],
    }),
    MapModule,
  ],
  providers: [],
  declarations: [
    FormCaseSendInvestorComponent,
    FormCaseSendAdministratorComponent,
    FormCaseSignAdministratorComponent,
    FormCaseHandOverInvestorComponent,
    FormCasePaymentInstructionComponent,
    FormCasePaymentOrderComponent,
    FormCaseSignedSendOwnersComponent,
    FormCaseSentAllOwnersComponent,
    FormCaseSendOwnerComponent,
    FormCaseUndeliveredOwnerComponent,
    FormCaseReceiveOwnerComponent,
    FormCaseDeclineOwnerComponent,
    FormCaseSignOwnerComponent,
    FormCaseEntryProposalComponent,
    FormCaseEntryDeclinedComponent,
    FormCaseEntryStoppedComponent,
    FormCaseSignInvestorComponent,
    FormCaseUpdateSubjectComponent,
    FormCaseSubjectDatesComponent,
    DistributionSystemPartsComponent,
    FormCaseUpdateComponent,
    FormContractsRegisterDialogComponent,
    FormContractsRegisterComponent,
    UpdateOccupationComponent,
    UpdateEasementComponent,
    CadastreEntryProposalComponent,
    FormCadastreEntryProposalComponent,
    CaseListComponent,
    FormAcceptedGeometricalPlanPossibleComponent,
    AcceptedGeometricalPlanPossibleComponent,
    FormExpropriationInterimDecisionRequestComponent,
    ExpropriationInterimDecisionRequestComponent,
    FormExpropriationInterimDecisionComponent,
    ExpropriationInterimDecisionComponent,
    FormExpropriationAdministrativeAppealComponent,
    ExpropriationAdministrativeAppealComponent,
    FormExpropriationAdministrativeAppealRejectedComponent,
    ExpropriationAdministrativeAppealRejectedComponent,
    FormExpropriationAdministrativeAppealAcceptedComponent,
    ExpropriationAdministrativeAppealAcceptedComponent,
    FormExpropriationCompensationPaymentComponent,
    ExpropriationCompensationPaymentComponent,
    SimpleCaseStatusComponent,
    SimpleCaseStatusFormComponent,
    SignInvestorComponent,
    SendOwnerComponent,
    SignOwnerComponent,
    ReceiveOwnerComponent,
    ConfirmComponent,
    CadastreEntryComponent,
    ExpropriationResultComponent,
    AuctionResultComponent,
    ExpropriationAppealResultComponent,
    EntryProposalComponent,
    PaymentCheckComponent,
    GenerateDocumentsFormComponent,
    GenerateDocumentsComponent,
    DeclinedOwnerComponent,
    PersonalVisitComponent,
    UpdateCaseOwnershipComponent,
    FormCaseUpdateParcelPriceComponent,
    CaseDetailComponent,
    CaseOwnerComponent,
    ChangeCaseStatusComponent,
  ],
  entryComponents: [
    FormCaseSendInvestorComponent,
    FormCaseSendAdministratorComponent,
    FormCaseSignAdministratorComponent,
    FormCaseHandOverInvestorComponent,
    FormCasePaymentInstructionComponent,
    FormCasePaymentOrderComponent,
    FormCaseSignedSendOwnersComponent,
    FormCaseSentAllOwnersComponent,
    FormCaseSendOwnerComponent,
    FormCaseUndeliveredOwnerComponent,
    FormCaseReceiveOwnerComponent,
    FormCaseDeclineOwnerComponent,
    FormCaseSignOwnerComponent,
    FormCaseEntryProposalComponent,
    FormCaseEntryDeclinedComponent,
    FormCaseEntryStoppedComponent,
    FormCaseSignInvestorComponent,
    FormCaseUpdateSubjectComponent,
    DistributionSystemPartsComponent,
    FormCaseUpdateComponent,
    FormContractsRegisterDialogComponent,
    FormCadastreEntryProposalComponent,
    FormCaseUpdateParcelPriceComponent,
    SimpleCaseStatusComponent,
    SimpleCaseStatusFormComponent,
  ],
  exports: [
    DistributionSystemPartsComponent,
  ]
})
export class CasesModule {}
