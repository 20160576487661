﻿<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Stavební objekt</a>
  </div>
  <h1>Založení případů o zřízení služebnosti</h1>
  <div class="mt-20">
    <div class="flexbox-container">
      <div class="content-menu" *ngIf="!loading">
        <div *ngFor="let step of menuSteps" (click)="onClickStep(step)" [ngClass]="{active : step.ids.includes(activeStepId), cancelled: !step.enabled || (sent && !step.ids.includes(activeStepId))}">
          {{step.name}}
        </div>
      </div>
      <div class="flex-rest">
        <construction-object-detail-create-case-select-obligation [data]="data" [constructionObjectId]="constructionObjectId" (submitCallback)="onSelectObligation($event)" *ngIf="activeStepId === 'select-obligation'"></construction-object-detail-create-case-select-obligation>
        <construction-object-detail-create-case-select-area [hasStep]="hasStep" [data]="data" (backCallback)="onBack()" [constructionObjectId]="constructionObjectId" (submitCallback)="onSelectArea()" *ngIf="activeStepId === 'select-area'"></construction-object-detail-create-case-select-area>
        <construction-object-detail-create-case-select-easement [data]="data" (backCallback)="onBack()"  (submitCallback)="onSelectEasement()" *ngIf="activeStepId === 'select-easement'"></construction-object-detail-create-case-select-easement>
        <construction-object-detail-create-case-select-parent-ownership [data]="data" (backCallback)="onBack()" (submitCallback)="onSelectParentOwnership($event?.skipped)" *ngIf="activeStepId === 'select-parent-ownership'"></construction-object-detail-create-case-select-parent-ownership>
        <construction-object-detail-create-case-price [data]="data" [constructionObjectId]="constructionObjectId" (backCallback)="onBack()" (submitCallback)="onPrice()" *ngIf="activeStepId === 'price'"></construction-object-detail-create-case-price>
        <construction-object-detail-create-case-confirm [data]="data" [constructionObjectId]="constructionObjectId" (backCallback)="onBack()" (submitCallback)="onConfirm()" *ngIf="activeStepId === 'confirm'"></construction-object-detail-create-case-confirm>
        <construction-object-detail-create-case-summary [data]="data" (submitCallback)="onGoToConstructionObject()" *ngIf="activeStepId === 'summary'"></construction-object-detail-create-case-summary>
      </div>
    </div>
  </div>
  <help [helpId]="helpId" class="cm-help-page" (callbackRegister)="onCallbackRegister($event)"></help>
</div>
