<div [loading]="!defaults?.availableLayerGroups?.length" (click)="onBackgroundClick()">
  <div class="column-100 checklist" *ngFor="let layerGroup of defaults.availableLayerGroups">
    <ng-container *ngIf="layerGroup.id !== 'smlouvy-vecna-bremena' || APP_BRAND.NAME !== 'RSD'">
      <div><label>{{layerGroup.title}}</label></div>
      <div class="checklist-item with-action cb clearfix" *ngFor="let layer of layerGroup.layers">
        <div class="info mr-10">
          <gmt-checkbox style="height: 15px;display: block;"
            gmtType="multiselect"
            [gmtChecked]="layersChecklist.isChecked(layer)"
            (click)="layersChecklist.toggleSelection(layer)"
          ></gmt-checkbox>
        </div>

        <div class="info mr-10">
          <i class="fa fa-eye" (click)="onVisibilityChange(layer)" [ngClass]="{'fa-eye': layer.visible, 'fa-eye-slash': !layer.visible}"></i>
        </div>

        <ng-container *ngIf="layer.stylable">
          <div class="input map-symbol" style="margin:3px 0;">
            <layer-symbol class="flexbox-container"
              [interactive]="true"
              [showColorpicker]="currentColorLayer?.id === layer.id"
              [style]="layer?.style"
              (click)="onLayerSymbolClick(layer, $event)"
              (stylechange)="onLayerStyleChange(layer, $event)"
            ></layer-symbol>
          </div>
          <div class="info mr-10">
            <a title="Obnovit výchozí styl" (click)="onLayerStyleResetToDefault(layer)"><i class="fa fa-undo"></i></a>
          </div>
        </ng-container>

        <div [ngClass]="{info: layer.id !== currentlyEditedLayer?.id, input: layer.id === currentlyEditedLayer?.id}">
          <div *ngIf="layer.id !== currentlyEditedLayer?.id; else interactiveInputBlock" (click)="onLayerNameEdit(layer)">{{ layer.title ? layer.title : layer.defaultTitle }}
            <span *ngIf="layer.defaultTitle && layer.title !== '' && layer.title !== layer.defaultTitle"> ({{layer.defaultTitle}})</span>
          </div>
        </div>

        <ng-template #interactiveInputBlock>
            <input gmtAutofocus type="text" class="w-350" [(ngModel)]="layer.title" (blur)="onLayerNameBlur(layer)"/>
        </ng-template>

        <div class="info ml-10 description" *ngIf="layer.description">{{ layer.description }}</div>

        <div *ngIf="layer.layers" class="cb">
          <div *ngFor="let layer of layer.layers" class="cb" [ngClass]="{info: layer.id !== currentlyEditedLayer?.id, input: layer.id === currentlyEditedLayer?.id}">
            <div *ngIf="layer.id !== currentlyEditedLayer?.id; else interactiveInputBlock2" (click)="onLayerNameEdit(layer)">{{ layer.title ? layer.title : layer.defaultTitle }}
              <span *ngIf="layer.defaultTitle && layer.title !== '' && layer.title !== layer.defaultTitle"> ({{layer.defaultTitle}})</span>
            </div>

            <ng-template #interactiveInputBlock2>
              <input gmtAutofocus type="text" class="w-350" [(ngModel)]="layer.title" (blur)="onLayerNameBlur(layer)"/>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
