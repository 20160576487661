﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ParcelService } from '@app/ps/services/parcel.service';
import { SeriesNamePipe } from '@app/ps/pipes/series-name.pipe';
import * as _ from 'lodash';
import { OtherLegalRelationsService } from '@app/common/services/other-legal-relations.service';
import { CaseService } from '@app/ps/services/case.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail-create-case-detail',
  templateUrl: './create-case-detail.component.html',
  styleUrls: ['./create-case-detail.component.scss'],
})
export class CreateCaseDetailComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  private submitCallback = new EventEmitter();
  @Input()
  private forwardStep = true;
  loading = true;
  seriesData = [];
  easementPriceByShare = this.caseService.easementPriceByShare;
  acceptedRestrictions = false;
  dataOccupationGroups: any = [];
  dataEasements: any = [];
  dataBuildings: any = [];
  dataConstructionObjects: any = [];
  restrictions: any;
  otherEntries: any;
  dataOtherLegalRelations: any;
  loadingSeries: boolean;
  priceSummary: any;
  flatCaseOwnerships: any[] = [];

  readonly PARCEL_COLS = [
    { id: 'parcel', sortable: false },
    { id: 'title', sortable: false },
    { id: 'area_name', sortable: false },
    { id: 'area', sortable: false },
  ];

  constructor(
    public subjectNamePipe: SubjectNamePipe,
    public seriesNamePipe: SeriesNamePipe,
    private parcelService: ParcelService,
    private otherLegalRelationsService: OtherLegalRelationsService,
    private caseService: CaseService,
    private errorHandlerService: ErrorHandlerService,
    private restangular: Restangular,
  ) {
    this.confirm = this.confirm.bind(this);
  }

  ngOnInit() {
    this.data.mpp = this.data.mpp || {};
    this.data.createdDate = this.data.createdDate || new Date();
    this.computePrice();
  }

  loadPrice() {
    if (this.forwardStep && this.data.obligation.computePrice && !this.caseService.easementPriceByShare(this.data.obligation)) {
      const data = _.cloneDeep(this.data);
      return this.restangular
        .one('titles')
        .all('case')
        .all('compute-price')
        .customPOST(data)
        .toPromise()
        .then((result) => Object.assign(this.data, result.plain()))
        .catch((result) => {
          this.submitCallback.emit({
            error: true
          });
          return Promise.reject(result);
        });
    }
    return Promise.resolve(this.data);
  }

  groupOccupationsByParcel() {
    this.dataOccupationGroups = this.parcelService.groupOccupationsByParcelSingle(this.data.occupations);
    this.dataEasements = this.data.easements.map((easement) => {
      return {
        easement: easement,
        price: this.caseService.easementPriceByShare(this.data.obligation) ? easement.easementPrice : null,
      };
    });
    this.dataBuildings = this.data.buildings.map((building) => {
      return {
        building: building,
        price: null,
      };
    });
  }

  assignOccupationsToConstructionObjects() {
    this.dataConstructionObjects = this.data.constructionObjects.map(constructionObject => {
      return {
        constructionObject: constructionObject,
        easements: this.data.easements.filter((easement) => easement.constructionObjects.some(item => item.id === constructionObject.id)),
        occupations: this.data.occupations.filter((occupation) => occupation.constructionObjects.some(item => item.id === constructionObject.id)),
      };
    });
  }

  filterRestrictionsAndOtherEntries() {
    this.restrictions = undefined;
    this.otherEntries = undefined;
    if (!this.dataEasements.length && !this.dataOccupationGroups.length && !this.data.buildings.length) {
      return;
    }
    if (!this.dataOtherLegalRelations) {
      const titleIds = this.data.titles.map(item => item.id);
      this.dataOtherLegalRelations = this.otherLegalRelationsService.loadOtherLegalRelations(titleIds);
    }

    this.dataOtherLegalRelations.promise.then(() => {
      this.restrictions = [];
      this.otherEntries = [];
      let filteredGroups;

      filteredGroups = this.otherLegalRelationsService.filterByEntityWithParcel(
        this.dataOtherLegalRelations.list,
        this.dataOccupationGroups,
        this.data.titles[0],
        this.data.caseOwnerships
      );
      this.restrictions.push(...filteredGroups.restrictions);
      this.otherEntries.push(...filteredGroups.otherEntries);

      filteredGroups = this.otherLegalRelationsService.filterByEntityWithEasement(
        this.dataOtherLegalRelations.list,
        this.dataEasements,
        this.data.titles[0],
        this.data.caseOwnerships
      );
      this.restrictions.push(...filteredGroups.restrictions);
      this.otherEntries.push(...filteredGroups.otherEntries);

      filteredGroups = this.otherLegalRelationsService.filterByEntityWithParcels(
        this.dataOtherLegalRelations.list,
        this.data.buildings,
        this.data.titles[0],
        this.data.caseOwnerships
      );
      this.restrictions.push(...filteredGroups.restrictions);
      this.otherEntries.push(...filteredGroups.otherEntries);

      filteredGroups = this.otherLegalRelationsService.filterByCaseOwnerships(
        this.dataOtherLegalRelations.list,
        this.data.caseOwnerships,
        this.data.titles[0],
      );
      this.restrictions.push(...filteredGroups.restrictions);
      this.otherEntries.push(...filteredGroups.otherEntries);
    });
  }

  requiresAcceptance() {
    return (this.dataOccupationGroups.length && this.dataOccupationGroups[0].key === 'permanent') || this.data.buildings.length > 0;
  }

  createEnabled() {
    return !this.loadingSeries && !!this.data.createdDate && (!this.requiresAcceptance() || this.acceptedRestrictions || !this.dataOtherLegalRelations || (!this.dataOtherLegalRelations.loading && this.restrictions.length === 0 && this.otherEntries.length === 0));
  }

  easementPriceByShareUpdated() {
    this.data.caseOwnerships.forEach((caseOwnership) => {
      this.priceSummary -= caseOwnership.easementPrice || 0;
      caseOwnership.easementPrice = Number(caseOwnership.easementPriceByShare) || 0;
      this.priceSummary += caseOwnership.easementPrice;
    });
  }

  confirm() {
    if (!this.createEnabled()) {
      return;
    }
    return this.submitCallback.emit();
  }

  loadSeries() {
    this.loadingSeries = true;
    const constructionObjectIds = [];
    if (this.data.constructionObjects.length) {
      this.data.constructionObjects.forEach((constructionObject) => {
        constructionObjectIds.push(constructionObject.id);
      });
    } else {
      this.data.easements.forEach((easement) => {
        easement.constructionObjects.forEach(constructionObject => {
          constructionObjectIds.push(constructionObject.id);
        });
      });

      this.data.occupations.forEach((occupation) => {
        occupation.constructionObjects.forEach((constructionObject) => {
          constructionObjectIds.push(constructionObject.id);
        });
      });

      this.data.buildings.forEach((building) => {
        building.parcels.forEach((parcel) => {
          parcel.constructionObjects.forEach((constructionObject) => {
            constructionObjectIds.push(constructionObject.id);
          });
        });
      });
    }
    this.restangular.all('series').customPOST({filter: {filters: {constructionObjectId: _.uniq(constructionObjectIds)}}}).toPromise().then((seriesData) => {
      this.seriesData = seriesData.plain();
      this.data.series = null;
      if (seriesData[0] && (seriesData.length === 1 || seriesData[0].constructionObject === null)) {
        this.data.series = this.seriesData[0];
      }
      this.loadingSeries = false;
    });
  }

  showPrice() {
    return this.priceSummary !== null;
  }

  acceptRestrictions() {
    this.acceptedRestrictions = !this.acceptedRestrictions;
  }

  flattenParents(caseOwnership) {
    return _.flattenDeep(caseOwnership.parentCaseOwnership ? [caseOwnership.parentCaseOwnership, this.flattenParents(caseOwnership.parentCaseOwnership)] : []);
  }

  mapCaseOwnerships(caseOwnerships: any[]): any[] {
    return caseOwnerships.map(caseOwnership => {
      return {
        caseOwnership: caseOwnership,
        parentCaseOwnerships: this.flattenParents(caseOwnership)
      };
    });
  }

  computePrice() {
    // remove parents
    _.remove(this.data.caseOwnerships, {isParent: true});
    this.loadPrice().then(() => {
      this.flatCaseOwnerships = this.mapCaseOwnerships(this.data.caseOwnerships);
      this.loading = false;
      this.loadSeries();
      this.groupOccupationsByParcel();
      this.assignOccupationsToConstructionObjects();
      this.filterRestrictionsAndOtherEntries();
      const prices = ['vegetation', 'land', 'buildings', 'easement', 'compensation'];
      if (this.caseService.easementPriceByShare(this.data.obligation)) {
        this.priceSummary = 0;
        return;
      } else {
        this.priceSummary = null;
      }
      if (!this.data.obligation.computePrice) {
        return;
      }
      // summarize prices
      this.data.caseOwnerships.forEach((caseOwnership) => {
        caseOwnership.price = null;
        caseOwnership.caseOwnershipParcels.forEach((caseOwnershipParcel) => {
          let occupationGroup, building, easement;
          if (caseOwnershipParcel.parcel) {
            occupationGroup = this.dataOccupationGroups.find((item) => {
              return item.parcel.id === caseOwnershipParcel.parcel.id &&
                (!caseOwnershipParcel.occupation || item.occupations.find(occ => occ.id === caseOwnershipParcel.occupation.id));
            });
            easement = this.dataEasements.find(item => item.easement.parcel.id === caseOwnershipParcel.parcel.id &&
                  item.easement.oznacVb === caseOwnershipParcel.easement.oznacVb &&
                  item.easement.geometricPlan.id === caseOwnershipParcel.easement.geometricPlan.id);
          }
          if (caseOwnershipParcel.building) {
            building = this.dataBuildings.find(item => item.building.id === caseOwnershipParcel.building.id);
          }
          prices.forEach((price) => {
            if (caseOwnershipParcel[price + 'Price'] !== undefined) {
              caseOwnership.price = (caseOwnership.price || 0) + caseOwnershipParcel[price + 'Price'];
              if (occupationGroup) {
                if (price !== 'compensation') {
                  occupationGroup.price = (occupationGroup.price || 0) + caseOwnershipParcel[price + 'Price'];
                }
                if (caseOwnershipParcel[price + 'ParcelPrice']) {
                  occupationGroup.parcelPrices = occupationGroup.parcelPrices || [];
                  if (!occupationGroup.parcelPrices.some(item => item.id === caseOwnershipParcel[price + 'ParcelPrice'].id)) {
                    occupationGroup.parcelPrices.push(caseOwnershipParcel[price + 'ParcelPrice']);
                  }
                }
              }
              if (easement) {
                easement.price = (easement.price || 0) + caseOwnershipParcel[price + 'Price'];
              }
              if (building) {
                building.price = (building.price || 0) + caseOwnershipParcel[price + 'Price'];
                if (caseOwnershipParcel[price + 'ParcelPrice']) {
                  building.parcelPrices = building.parcelPrices || [];
                  if (!building.parcelPrices.some(item => item.id === caseOwnershipParcel[price + 'ParcelPrice'].id)) {
                    building.parcelPrices.push(caseOwnershipParcel[price + 'ParcelPrice']);
                  }
                }
              }
            }
          });
        });
        if (caseOwnership.price !== null) {
          this.priceSummary = (this.priceSummary || 0) + caseOwnership.price;
        }
      });

      const parcelPricesToString = (parcelPrices) => parcelPrices.reduce(
                  (result, parcelPrice) => {
            const priceSource = (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
            if (!priceSource) {
              return result;
            } else {
              return result += (result ? ', ' : '') + priceSource;
            }
          },
          ''
      );

      // assign expert opinions
      this.dataOccupationGroups.forEach(occupationGroup => {
        if (occupationGroup.parcelPrices) {
          occupationGroup.compensationParcelPrices = occupationGroup.parcelPrices.filter(item => item.opinion.occupationType === 'T');
          occupationGroup.priceSource = parcelPricesToString(occupationGroup.parcelPrices);
        }
      });
      this.dataBuildings.forEach(building => {
        if (building.parcelPrices) {
          building.priceSource = parcelPricesToString(building.parcelPrices);
        }
      });
    }, this.errorHandlerService.get);
  }
}
