import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'auction-result',
  templateUrl: './auction-result.component.html',
  styleUrls: ['./auction-result.component.scss']
})
export class AuctionResultComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data = {
    auctionWinDate: new Date(),
    auctionWinLegalEffectsDate: new Date(),
    auctionWinAmount: null
  };
  name: string;
  sending = false;
  step = 1;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private dialogService: DialogService
  ) {
    this.onConfirm = this.onConfirm.bind(this);
    this.onDecline = this.onDecline.bind(this);
  }

  async ngOnInit() {
    this.name = (await this.caseStatusService.loadStatusName('AuctionWin')).name;
    this.loading = false;
  }

  setStep(step) {
    if (!this.sending) {
      this.step = step;
    }
  }

  onDecline(): Promise<any> {
    if (!this.sending) {
      return this.dialogService.confirmDialogPromise('Opravdu chcete případ zrušit?')
        .then(result => {
          if (result) {
            this.sending = true;
            return this.restangular.one('cases', this.caseId).customPUT({}, 'cancel').toPromise().then(() => {
              this.stateService.go('^');
            });
          }
        });
    }
  }

  onConfirm(): Promise<any> {
    if (this.data.auctionWinDate && this.data.auctionWinLegalEffectsDate && this.data.auctionWinAmount) {
      this.sending = true;
      return this.restangular.one('cases', this.caseId).customPUT(this.data, 'confirm-auction-win').toPromise().then(() => {
        this.stateService.go('^');
      });
    }
  }
}
