<div>
  <p class="centered-text">
    Odesláním bude vygenerován XML dokument hlášení, které je třeba poslat na ČÚZK.
  </p>

  <form>
    <fieldset class="mt-20">
      <div class="column-100">
        <gmt-input label="Za období" required="true">
          <month-picker [options]="monthPickerOptions" (date)="onReportDateChange($event)"></month-picker>
        </gmt-input>
      </div>
    </fieldset>
    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!reportDate || unfinishedRequests?.length">Odeslat</gmt-button>
      <gmt-button [clickAction]="onExistingReportDownload" class="fr">Stáhnout existující hlášení z tohoto měsíce</gmt-button>
    </div>
  </form>

  <div *ngIf="unfinishedRequests?.length" class="mt-40 mb-20">
    <h2>Existují žádosti na daný měsíc, které jsou obsaženy v XML hlášení podaném na ČUZK, ale nejsou dokončené</h2>
    <table class="table bordered">
      <thead>
        <tr>
          <th>Název žádosti</th>
          <th>ID žádosti</th>
          <th>Datum založení</th>
          <th>Stav</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let request of unfinishedRequests">
          <td>{{ request.name }}</td>
          <td>{{ request.requestId }}</td>
          <td>{{ request.recivedTime | date }}</td>
          <td>{{ request.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
