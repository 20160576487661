import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TableService } from '@app/ps/services/table.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { TitleModel } from '@app/common/models/title.model';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'table-subjects',
  templateUrl: './table-subjects.component.html',
  styleUrls: ['./table-subjects.component.scss']
})
export class TableSubjectsComponent implements OnInit {
  @Input() list: any;
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() sort = new EventEmitter();
  private readonly TABLE_ID = 'owners';
  public isArray = Array.isArray;

  constructor(
    private tableService: TableService,
    private settingsService: SettingsService,
    private authService: AuthService,
    public userNamePipe: UserNamePipe,
    private restangular: Restangular,
  ) {}

  ngOnInit() {
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  hideColumn(columnId: string): boolean {
    return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
  }

  getColumnName(columnId: string): string {
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  getUniqueCadastreCount(owner: Object): number {
    const uniqueCadastres = new Set(owner['ownerships'].map(o => o.katuzeKod));
    return [...uniqueCadastres].length;
  }

  getUniqueTitleCount(owner: Object): number {
    const uniqueTitles = new Set(owner['ownerships'].map(o => o.title.id));
    return [...uniqueTitles].length;
  }
}
