<h2 class="mb-10">Nový import</h2>
<form class="mb-10">
  <fieldset class="mt-5">

    <!-- url -->
    <div class="column-100">
      <gmt-input label="URL adresa" required="true">
        <input type="text" name="url" [(ngModel)]="cadastreImport.url"/>
      </gmt-input>
    </div>

    <!-- password -->
    <div class="column-100">
      <gmt-input label="Heslo" required="true">
        <input type="password" name="password" [(ngModel)]="cadastreImport.password"/>
      </gmt-input>
    </div>

    <!-- folder -->
    <div class="column-100">
      <gmt-input label="WebDAV adresář" required="true">
        <input placeholder="např. 201909-12/stav/201908" type="text" name="folder" [(ngModel)]="cadastreImport.folder"/>
      </gmt-input>
    </div>

    <!-- vfkVersion -->
    <div class="column-100">
      <gmt-input label="Verze VFK (ve formátu 1.0)" required="true">
        <input maxlength=3 type="text" name="vfkVersion" [(ngModel)]="cadastreImport.vfkVersion"/>
      </gmt-input>
    </div>

    <!-- cadastreCodes -->
    <div class="column-100">
      <gmt-input label="Kódy katastrálních území (oddělené čárkou)">
        <textarea class="w-350" placeholder="např. 123456,654321" type="text" name="cadastreCodes" [(ngModel)]="cadastreImport.cadastreCodes"></textarea>
      </gmt-input>
    </div>
  </fieldset>
  <div>
    <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Odeslat</gmt-button>
  </div>
</form>
