import { Component, Output, Input, EventEmitter, OnInit, HostListener } from "@angular/core";
import { DatePipe } from "@angular/common";

import { ArrayUtils } from "../../utils/array.utils";

export class MonthPickerOptionsInput {
  minDate: Date;
  maxDate: Date;
  defaultDate: Date;
}

@Component({
  selector: 'month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  @Input() options: MonthPickerOptionsInput;
  @Output() date = new EventEmitter<Date>();

  month: number;
  year: number;
  months: number[];
  years: number[];
  monthPickerOpened: boolean;
  yearPickerOpened: boolean;

  private minDate: Date;
  private maxDate: Date;
  private defaultDate: Date;

  constructor(private datePipe: DatePipe) {
    this.onChange = this.onChange.bind(this);
    this.onClickedOutside = this.onClickedOutside.bind(this);
  }

  ngOnInit() {
    if (this.options) {
      this.minDate = this.options.minDate;
      this.maxDate = this.options.maxDate;
      this.defaultDate = this.options.defaultDate;
    } else {
      this.minDate = new Date(new Date().getFullYear(), 0, 1);
      this.maxDate = new Date(new Date().getFullYear(), 11, 30);
      this.defaultDate = new Date();
    }

    this.year = this.defaultDate.getFullYear();
    this.years = ArrayUtils.generateIntegerArray(this.minDate.getFullYear(), this.maxDate.getFullYear() + 1);
    this.month = this.defaultDate.getMonth();
    this.refreshMonths();

    this.monthPickerOpened = false;
    this.yearPickerOpened = false;
    this.date.emit(this.formatEvent());
  }

  onChange(value: number, type: "month" | "year") {
    if (type === "month") {
      this.month = value;
    } else {
      this.year = value;
      this.refreshMonths();
    }
    this.date.emit(this.formatEvent());
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside() {
    this.yearPickerOpened = false;
    this.monthPickerOpened = false;
  }

  getMonthNameFromNumber(month: number): string {
    const date = new Date();
    date.setDate(1);
    date.setMonth(month);
    return this.datePipe.transform(date, "LLLL");
  }

  private formatEvent() {
    const date = new Date();
    date.setDate(1);
    date.setMonth(this.month);
    date.setFullYear(this.year);
    return date;
  }

  private refreshMonths() {
    const minYear = this.minDate.getFullYear();
    const maxYear = this.maxDate.getFullYear();
    const minMonth = this.minDate.getMonth();
    const maxMonth = this.maxDate.getMonth();

    if (minYear === maxYear) {
      this.months = ArrayUtils.generateIntegerArray(minMonth, maxMonth + 1);
    } else {
      if (minYear === this.year) {
        this.months = ArrayUtils.generateIntegerArray(minMonth, 12);
      } else if (maxYear === this.year) {
        this.months = ArrayUtils.generateIntegerArray(0, maxMonth + 1);
      } else {
        this.months = ArrayUtils.generateIntegerArray(0, 12);
      }
    }

    if (this.months.indexOf(this.month) === -1) {
      this.month = this.months[this.months.length - 1];
    }
  }
}