import { Component, Inject, OnInit } from '@angular/core';

import { AuthService } from '@app/common/services/auth.service';
import { TableService } from '@app/ps/services/table.service';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { StateService } from '@uirouter/angular';
import { ListModel } from '@app/common/models/list.model';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { User } from '@app/models/user';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ListService } from '@app/common/services/list.service';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'construction-object-list',
  templateUrl: './construction-object-list.component.html',
  styleUrls: []
})
export class ConstructionObjectListComponent implements OnInit {

  list: ListModel<ConstructionObjectModel>;
  pageableList: PageableList;
  settingsLoaded = false;
  user: User;
  userSending: any = {};
  users: User[] = [];
  importedObjectsExists = false;
  exportEnable = true;
  isArray = Array.isArray;

  mandataryFilter = {
    filters: {
      searchText: {
        values: [],
      },
      applications: {
        values: [ this.authService.getActiveApplication() ],
      },
      permission: {
        values: ['assignable'],
      },
      projects: {
        values: [ this.authService.getActualProject().key ],
      },
    },
    sortOrder: [
      { sortBy: 'surname' },
      { sortBy: 'name' },
    ],
  };

  private defaultFilter = {
    filters: {
      validity: 'valid',
      easementExists: { values: [] },
      administratorType: { values: [] },
      searchText: { values: [{ id: '' }]},
      constructionObjectStageId: {},
      constructionObjectStatusId: {},
      userId: {},
      loadCollections: [ 'occupationResolution' ],
    }
  };
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    private restangular: Restangular,
    public userNamePipe: UserNamePipe,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private tableService: TableService,
    private stateService: StateService,
    private authService: AuthService,
    private listService: ListService,
    private exportListService: ExportListService
  ) {
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    this.user = this.authService.getUser();

    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }

    if (this.authService.hasPermission('assignment_edit')) {
      this.restangularSettings.all('users/list').customPOST({ filter: this.mandataryFilter }).toPromise().then(data => {
        this.users = data.plain();
      });
    }

    this.initializeList();
    this.loadImported();
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('administrator');
    return this.exportListService
      .exportList('construction-objects', 'constructionObjects', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'construction-objects',
      this.defaultFilter,
      'constructionObjectsFilter',
      this.restangular,
        { administrator: { load: true }}
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private loadImported(): Promise<any> {
    const list = this.listService.createList(
      'construction-objects/imports',
      { limit: 1 },
    );

    return this.listService.fetchResult(list).then(data => {
      this.importedObjectsExists = data && data.itemCount > 0;
    });
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('constructionObjects', columnId);
  }

  getColumnName(columnId: string) {
    return this.tableService.getColumnById('constructionObjects', columnId).name;
  }

  isGeometricPlansChecked() {
    const exists = this.list.filter.filters.easementExists;
    return exists && exists.values.length === 1 ;
  }

  onHideWithoutGP() {
    this.list.filter.filters.easementExists.values = (this.isGeometricPlansChecked() ? [] : [ true ]);
  }

  isAdministratorInvestor() {
    const exists = this.list.filter.filters.administratorType;
    return exists && exists.values.length === 1 ;
  }

  onAdministratorInvestorToggle() {
    this.list.filter.filters.administratorType.values = (this.isAdministratorInvestor() ? [] : [ 'I' ]);
  }

  onUpdateUser(constructionObjectId, userId) {
    this.userSending[constructionObjectId] = true;

    this.restangular.one('construction-objects', constructionObjectId).customPUT({ id: userId }, 'user').toPromise().then(() => {
      this.userSending[constructionObjectId] = false;
    });
  }
}
