import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapUtilsWfsFeatureService {

    constructor(
       private http: HttpClient,
    ) { }

    //////////////////////////////////////////

     getFeatureByID(baseURL, typeName, featureID, queryParams) {
      var url = baseURL + '?request=GetFeature&service=WFS&version=1.0.0&outputFormat=application/json&typeName=' + encodeURIComponent(typeName) + '&CQL_FILTER=id=' + featureID;
      if (queryParams) {
        url += _.join(_.map(queryParams, (value, key) => '&' + key + '=' + value), '');
      }
      return this.http.get(url).toPromise();
    }
}
