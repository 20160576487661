import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectNamePipe } from '@app/common/pipes/project-name.pipe';

@Component({
  selector: 'gmt-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectComponent implements OnInit {

  @Input() projects: any[];
  @Input() selectedProject: any;
  @Output() selected: EventEmitter<any> = new EventEmitter();

  constructor(
    public projectNamePipe: ProjectNamePipe
  ) {
  }

  ngOnInit() {
  }

  onProjectSelect(selectEvent: any) {
    this.selected.emit(selectEvent.newValue);
  }
}
