﻿import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';

@Component({
  templateUrl: './bulk-operation-details.component.html',
  styleUrls: ['./bulk-operation-details.component.scss']
})
export class BulkOperationDetailsComponent implements OnInit {
  data: any;
  callbacks: CallbackModel;
  @ViewChild('operationDetails', {
    read: ViewContainerRef
  }) detailsViewContainerRef: ViewContainerRef;
  detailsComponent: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.notifyParent = this.notifyParent.bind(this);
  }

  ngOnInit() {
    if (this.data.selectAction && this.data.selectAction.component) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.data.selectAction.component);
      this.detailsComponent = factory.create(this.detailsViewContainerRef.parentInjector);
      this.detailsViewContainerRef.insert(this.detailsComponent.hostView);

      this.detailsComponent.instance.data = this.data;
      this.detailsComponent.instance.callbacks = this.callbacks;
      this.detailsComponent.instance.notifyParent = this.notifyParent;
    }
  }

  notifyParent(isValid: Function, inputData: any) {
    this.callbacks.get(Callbacks.DataValidityChanged)({
      isValid: isValid,
      data: inputData,
    });
  }
}
