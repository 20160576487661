import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';
import {Ng2StateDeclaration, StateParams, UIRouterModule} from '@uirouter/angular';
import { resolveId } from '@app/common/utils/state.utils';
import { TitleDetailComponent } from '@app/dimap/titles/title-detail/title-detail.component';
import { TitleListComponent } from '@app/dimap/titles/title-list/title-list.component';

export const titlesState: Ng2StateDeclaration = {
  name: 'dimap.project.titles',
  data: {
    title: 'Listy vlastnictví',
  },
  url: '/titles?page',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    }
  },
  views: {
    'content@dimap.project': {
      component: TitleListComponent,
    }
  }
};

export const titleDetailState: Ng2StateDeclaration = {
  name: 'dimap.project.titles.detail',
  url: '/:id',
  data: {
    title: 'Detail listu vlastnictví',
  },
  views: {
    'content@dimap.project': {
      component: TitleDetailComponent,
    }
  },
  resolve: {
    titleId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        titlesState,
        titleDetailState,
      ],
    }),
    CommonModule,
    DimapCommonModule,
  ],
  providers: [],
  declarations: [
    TitleListComponent,
    TitleDetailComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class TitlesModule {}
