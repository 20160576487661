import { Component, Inject, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { AuthService } from '@app/common/services/auth.service';
import { RESTANGULAR_SV } from '@app/common/services/restangular-sv.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { SampleModel } from '@app/sv/models/sample.model';
import { TicketStatusEnum } from '@app/sv/enums/ticket-status.enum';
import { TicketModel } from '@app/sv/models/ticket.model';
import { DialogService } from '@app/common/services/dialog.service';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { saveAs } from 'file-saver';
import { MenuConfig } from '@app/models/menuConfig';
import { HelpService } from '@app/common/services/help.service';
import {APP_CONFIG, APPLICATIONS} from '@app/common/services/config.service';
import {HttpService} from "@app/common/services/http.service";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: []
})
export class SampleListComponent implements OnInit {

  list: ListModel<any>;
  pageableList: PageableList;
  isOrganizationalUnitSet: boolean;
  hasUserAccessToSampleApp = this.authService.isAuthenticatedApplication(this.applications.sv.name);
  private defaultFilter;

  constructor(
    @Inject(RESTANGULAR_SV) private restangularSV: any,
    @Inject(APPLICATIONS) private applications: any,
    @Inject(APP_CONFIG) private config: any,
    private pageableListService: PageableListService,
    private authService: AuthService,
    private dialogService: DialogService,
    private httpService: HttpService
  ) {}

  async ngOnInit() {
    const investor = this.authService.getActualProject().investor;
    const templatesOrganizationalUnit = this.authService.getActualProject().templatesOrganizationalUnit;
    if (!investor || (!investor.customerOrganizationalUnit && !templatesOrganizationalUnit)) {
      this.isOrganizationalUnitSet = false;
      return;
    }
    this.isOrganizationalUnitSet = true;
    this.defaultFilter = {filters: {
      organizationalUnitId : templatesOrganizationalUnit != null ? templatesOrganizationalUnit.id : investor.customerOrganizationalUnit.id,
      exceptionOrProjectKey : this.authService.getActualProject().key,
    }};

    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'samples',
      this.defaultFilter,
      undefined,
      this.restangularSV,
      {
        actualizationTickets: { load: true },
        documentType: {},
        disabled: { projectKey: this.authService.getActualProject().key },
      }
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  getActualizationTicket(sample: SampleModel): TicketModel {
    if (!sample.actualizationTickets) {
      return undefined;
    }
    return sample.actualizationTickets.find(t => t.status !== TicketStatusEnum.DELETED && t.status !== TicketStatusEnum.DISAPPROVED);
  }

  downloadSampleFile(sample: SampleModel): Promise<any> {
    return this.httpService.call({
      path: `samples/${sample.id}/sample`,
      method: 'GET',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSV,
      fullResponse: true,
      responseType: 'blob'
    }).then((response: HttpResponse<any>) => {
      const filename = HttpService.parseFilename1(response)
        ? HttpService.parseFilename1(response)
        : sample.number + '.docx';
      saveAs(response.body, filename, true);
    });
  }

  onEnableToggle(sample: SampleModel) {
    if (sample.disabled === null || sample.status === 'WAITING_FOR_ACTUALIZATION') {
      return;
    }
    const result = !sample.disabled;

    sample.disabled = null;

    return this.restangularSV
      .one('samples', sample.id)
      .one('projects', this.authService.getActualProject().key)
      .all('disabled')
      .post({disabled: result})
      .toPromise()
      .then(() => {
        sample.disabled = result;
        this.dialogService.open(AlertComponent, {
          className: ClassName.ADJUSTED_DIALOG,
          data: {
            msg: result ?
              'Vzor nebude k dispozici pro generování dokumentů.' :
              'Zvolený vzor je nyní k dispozici pro generování dokumentů.',
          },
        });
      });
  }
}
