<div>
  {{ data.customData.occupation.nextOccupationId ? 'Změněný zábor' : 'Zrušený zábor' }} ke dni {{ data.customData.occupation.endDate | date:'mediumDate' }}

  <div *ngIf="data.customData.occupation.nextOccupationId">
    <div [loading]="loading">
      <div *ngIf="data.list.list?.length">
        Změněno:
        <span class="comma-list">
          <span *ngFor="let item of data.list.list">{{ item.description | wordtranslate:true }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
