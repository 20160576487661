import { CommonAddressModel } from '@app/ps/models/common-address.model';

export class ActingPersonModel {
  id: number;
  companyName: string;
  subjectType: 'OFO' | 'OPO' = 'OFO';
  identificationNumber: string;
  'function': string;
  titleBefore: string;
  firstName: string;
  surname: string;
  degreeAfter: string;
  cancelled = false;
  cadastreActingPerson: boolean;
  roleCadastre = false;
  roleContract = false;
  email: string;
  phoneNumber: string;
  opsubId: number;
  powerOfAttorneyDate: Date;

  // flat address
  street: string;
  houseNumber: number;
  streetNumber: string;
  neighbourhood: string;
  quarter: string;
  district: string;
  municipality: string;
  county: string;
  postCode: number;
  country: string;
  countryCode: string;
  countryCodeAl2: string;

  // nested address
  address = new CommonAddressModel();

  static fromFlatObject(actingPerson: ActingPersonModel): ActingPersonModel {
    actingPerson.address = {
      street: actingPerson && actingPerson.street,
      houseNumber: actingPerson && actingPerson.houseNumber,
      commonNumber: actingPerson && actingPerson.streetNumber,
      neighborhood: actingPerson && actingPerson.neighbourhood,
      cityDistrict: actingPerson && actingPerson.district,
      quarter: actingPerson && actingPerson.quarter,
      city: actingPerson && actingPerson.municipality,
      county: actingPerson && actingPerson.county,
      postalCode: (actingPerson.postCode ? String(actingPerson.postCode) : ''),
      country: actingPerson && actingPerson.country,
      countryCode: actingPerson && actingPerson.countryCode,
      countryShortCode: actingPerson && actingPerson.countryCodeAl2
    } as CommonAddressModel;

    return actingPerson as ActingPersonModel;
  }

  static toFlatObject(actingPerson: ActingPersonModel): ActingPersonModel {
    actingPerson.street = actingPerson.address.street;
    actingPerson.houseNumber = actingPerson.address.houseNumber;
    actingPerson.streetNumber = actingPerson.address.commonNumber;
    actingPerson.neighbourhood = actingPerson.address.neighborhood;
    actingPerson.district = actingPerson.address.cityDistrict;
    actingPerson.quarter = actingPerson.address.quarter;
    actingPerson.municipality = actingPerson.address.city;
    actingPerson.county = actingPerson.address.county;
    actingPerson.postCode = Number(actingPerson.address.postalCode);
    actingPerson.country = actingPerson.address.country;
    actingPerson.countryCode = actingPerson.address.countryCode;
    actingPerson.countryCodeAl2 = actingPerson.address.countryShortCode;
    const ret = {...actingPerson};
    delete ret.address;
    return ret;
  }

  static hasAtLeastOneDefinedProperty(representative: ActingPersonModel): boolean {
    return Object.keys(representative)
      .filter((k: string) => !['cancelled', 'roleCadastre', 'roleContract', 'address', 'subjectType'].includes(k))
      .some((k: string) => representative[k]);
  }
}
