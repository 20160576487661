<div>
  <div>
    <fieldset>
      <legend class="required">Vložení GP</legend>
      <fileupload class="blue" [inputId]="'hiddenInput1'" (change)="onChangeFile($event, 'vfk')" [message]="vfkMessage" [files]="vfkFiles"></fileupload>
    </fieldset>

    <fieldset *ngIf="vfkFiles?.length > 0">
      <legend>Nepovinné údaje - hromadné vložení dat GP</legend>
      <fileupload class="yellow" [inputId]="'hiddenInput2'" (change)="onChangeFile($event, 'csv')" [message]="csvMessage" [files]="csvFiles"></fileupload>
    </fieldset>
  </div>
  <div class="mt-10">
    <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isFormValid()">Další</gmt-button>
  </div>
</div>
