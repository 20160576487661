import { Component, OnInit, ElementRef } from '@angular/core';
import { MapCallbackEnum } from '@app/map/enums/map-callback.enum';
import { MapLayerSelectorService } from '@app/map/services/map-layer-selector.service';

@Component({
  selector: 'map-layer-selector-tooltip',
  templateUrl: './map-layer-selector-tooltip.component.html',
})
export class MapLayerSelectorTooltipComponent implements OnInit {
  public showTooltip = false;
  public tooltipContent = '';
  public collapsed: boolean;

  constructor(
    private mapLayerSelectorService: MapLayerSelectorService,
    private elementRef: ElementRef,
  ) {}

  async ngOnInit() {
    this.mapLayerSelectorService.registerCallback(MapCallbackEnum.layerMouseEnter, this.layerMouseEnter.bind(this));
    this.mapLayerSelectorService.registerCallback(MapCallbackEnum.layerMouseLeave, this.layerMouseLeave.bind(this));
    this.mapLayerSelectorService.registerCallback(MapCallbackEnum.searchMouseEnter, this.layerMouseEnter.bind(this));
    this.mapLayerSelectorService.registerCallback(MapCallbackEnum.searchMouseLeave, this.layerMouseLeave.bind(this));
    this.mapLayerSelectorService.registerCallback(MapCallbackEnum.collapsedStateChange, this.collapsedStateChange.bind(this));
    this.collapsed = this.mapLayerSelectorService.getCollapsedState();
  }

  layerMouseEnter(event) {
    var element = $(event.currentTarget);
    if (this.collapsed || event.option) {
        this.tooltipContent = event.option ? event.option.value : event.layer.title;
        const getParents = (layer) => {
          let tootlip = '';
          if (layer.parent) {
            tootlip = getParents(layer.parent);
            if (layer.parent.title) {
              if (tootlip) {
                tootlip += ' - ';
              }
              tootlip += layer.parent.title;
            }
          }
          return tootlip;
        };
        if (event.layer) {
          this.tooltipContent += ' (' + getParents(event.layer) + ')';
        }
        this.showTooltip = true;
        $(this.elementRef.nativeElement).css('top', element.offset().top - $('#top-bar').outerHeight());
        $(this.elementRef.nativeElement).css('left', Math.ceil(element.outerWidth()) + Math.ceil(element.position().left) + 5);
    }
  };

  layerMouseLeave(event) {
      this.showTooltip = false;
  }

  collapsedStateChange(collapsed) {
      this.collapsed = collapsed;
  }
}
