<h1>{{title}}</h1>
<fieldset>
  <div class="clearfix">
    <div class="column-100" *ngIf="allowType">
      <label>Typ poznámky</label>
      <div class="input">
        <gmt-select
          [data]="note"
          [filter]="{filters: {dialogTypes:['BusinessCase']}}"
          field="noteType"
          resource="note-types"
          (changed)="onTypeChange($event)"
        ></gmt-select>
      </div>
    </div>
    <div class="column-100" *ngIf="caseId && note.noteType?.key === 'PriceRequest'">
      <label class="required">Vlastník v případu</label>
      <div class="info small-spinner" [loading]="!subjects"></div>
      <div class="input" *ngIf="subjects">
        <gmt-select
          [data]="note"
          field="subject"
          [optionItems]="subjects"
          [itemPipe]="subjectNamePipe"
        ></gmt-select>
      </div>
    </div>
    <div class="column-100">
      <label>Text poznámky</label>
      <textarea [(ngModel)]="note.text"></textarea>
    </div>
  </div>

  <div class="text-center pt-20">
    <gmt-button *ngIf="!note.id" [clickAction]="onCreate" [gmtDisabled]="!(note.text && note.text.trim())">Uložit poznámku</gmt-button>
    <gmt-button *ngIf="note.id" [clickAction]="onUpdate" [gmtDisabled]="!(note.text && note.text.trim())">Editovat poznámku</gmt-button>
    <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
  </div>
</fieldset>
