import * as _ from 'lodash';

export class ParcelUtils {

  static getParcelSortNumber(parcisKmen: number, parcisPod: number, parcisDil: number) {
    const podStrNumber = parcisPod ? parcisPod.toString().padStart(5, '0') : '00000';
    const dilStrNumber = parcisDil ? parcisDil.toString().padStart(5, '0') : '00000';
    const sortStrNumber = parcisKmen.toString() + podStrNumber + dilStrNumber;
    return parseInt(sortStrNumber);
  }

  static getSortFunctionByParcelsNumbers(parcisKmenPath: string, parcisPodPath: string, parcisDilPath: string)  {
    return (item: any) => {
      // convert string path into data
      const parcisKmen = parcisKmenPath.split('.').reduce((o, i) => o[i], item);
      const parcisPod = parcisPodPath.split('.').reduce((o, i) => o[i], item);
      const parcisDil = parcisDilPath.split('.').reduce((o, i) => o[i], item);

      return ParcelUtils.getParcelSortNumber(parcisKmen, parcisPod, parcisDil);
    };
  }

  static sortDataByColumnStatic(data, dataPath, sortDir) {
    return _.orderBy(data, dataPath, sortDir);
  }
}
