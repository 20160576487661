import { requestStatusOptions } from './../../enums/request-status.enum';
import { Component, OnInit, Inject } from '@angular/core';

import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list';
import { ListModel } from '@app/common/models/list.model';
import { ProjectModel } from '@app/pk/models/project.model';
import { DatePipe } from '@angular/common';
import { MonthPickerOptionsInput } from '@app/common/components/month-picker/month-picker.component';
import * as moment from 'moment';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  // model
  list: ListModel<ProjectModel>;
  pageableList: PageableList;
  validityDate: Date;
  monthPickerOptions: MonthPickerOptionsInput = {
    minDate: moment().subtract(2, 'year').toDate(),
    maxDate: moment().add(1, 'month').toDate(),
    defaultDate: moment().add(1, 'month').toDate()
  };
  filterValidity = false;
  statusOptions = requestStatusOptions;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private pageableListService: PageableListService,
    private datePipe: DatePipe,
  ) {
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
  }

  ngOnInit() {
    this.initializeProjectList();
  }

  onValidDateChanged(date: Date) {
    this.validityDate = date;
    this.setFilter();
  }

  onFilterValidityChange() {
    this.filterValidity = !this.filterValidity;
    this.setFilter();
  }

  private initializeProjectList() {
    const filters = {
      filters: {
        searchText: { values: [{ id: '' }]},
        validity: null,
        status: { values: [] }
      }
    };

    this.pageableList = this.pageableListService.get('project/list', filters);
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private setFilter() {
    if (this.filterValidity) {
      this.list.filter.filters.validity = this.datePipe.transform(this.validityDate, 'yyyy-MM-dd');
    } else {
      this.list.filter.filters.validity = undefined;
    }
  }

  getExternalStatus(status, katuzeKod) {
    const katuzeStatus = status.find(s => s.katuzeKod === katuzeKod);
    return katuzeStatus ? katuzeStatus.exportCode + '/' + katuzeStatus.importCode : '-/-';
  }
}
