<div class="list">
  <div>
    <a uiSref="^"  class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Šablony</a>
  </div>

  <h1>Historie šablony</h1>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group">
          <div class="row">
            <span class="cell">Název souboru</span>
            <span class="cell">Datum a čas nahrání</span>
            <span class="cell">Datum a čas zneplatnění</span>
          </div>
        </div>
        <div class="row-group">
          <ng-container *ngFor="let template of list.list">
            <a href="{{ restBaseUrl }}/attachments/{{ template.attachment.id }}/data?t={{ authToken }}" class="row">
              <span class="cell">{{ template.attachment.name }}</span>
              <span class="cell centered-text">{{ template.startTimestamp | date:'short' }}</span>
              <span class="cell centered-text">{{ template.endTimestamp | date:'short' }}</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>

