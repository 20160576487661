import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DocumentTypeModel } from '@app/sv/models/document-type.model';


@Pipe({ name: 'documentTypeFullname' })
@Injectable({
  providedIn: 'root'
})
export class DocumentTypeFullnamePipe implements PipeTransform {
  transform(value: DocumentTypeModel): string {
    return value.name + (value.description ? (' - ' + value.description) : '');
  }
}
