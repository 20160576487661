﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { SubjectModel } from '@app/common/models/subject.model';
/**
 * Display name of project subject
 */
@Pipe({
  name: 'projectSubjectName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class ProjectSubjectNamePipe implements PipeTransform {

  transform(subject: any, withContact = false, withCompany = true) {
    if (!subject) {
      return '';
    }

    let words = [];

    if (subject.surname) {
      words.push(subject.surname);
    }

    if (subject.firstName) {
      words.push(subject.firstName);
    }

    const tmp = [];
    if (words.length !== 0) {
      tmp.push(words.join(' '));
    }

    words = [];

    if (subject.titleBefore) {
      words.push(subject.titleBefore);
    }

    if (subject.degreeAfter) {
      words.push(subject.degreeAfter);
    }
    if (words.length !== 0) {
      tmp.push(words.join(' '));
    }

    if (subject.companyName && withCompany) {
      tmp.push(subject.companyName);
    }

    if (withContact) {
      if (subject.phoneNumber) {
        tmp.push(subject.phoneNumber);
      }

      if (subject.email) {
        tmp.push(subject.email);
      }
    }

    return tmp.join(', ');
  }
}

