import { InjectionToken } from '@angular/core';

export const RESTANGULAR_VFZE = new InjectionToken<any>('RestangularVfze');

export function RestangularVfzeFactory(restangular: any, config: any) {
  const r = restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlVFZE);
  });
  return r;
}

