<div class="bordered">
  <table>
    <thead class="sticky">
      <tr>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_parcel">
          <sort-by *ngIf="sortableColumns.occupation_parcel" [selected]="'occupation_parcel' === sortOrder.sortBy" (sort)="onSortParcel('occupation_parcel')" [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_parcel">
            Parcela
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_parcel_title">
          <sort-by *ngIf="sortableColumns.occupation_parcel_title" [selected]="'occupation_parcel_title' === sortOrder.sortBy" (sort)="onSortParcel('occupation_parcel_title')" [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_parcel_title">
            Parcela
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_title">
          <sort-by *ngIf="sortableColumns.occupation_title" [selected]="'occupation_title' === sortOrder.sortBy" (sort)="onSort('occupation_title','parcel.title.lv')" [sortDir]="sortOrder.direction">
            LV
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_title">
            LV
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_area">
          <sort-by *ngIf="sortableColumns.occupation_area" [selected]="'occupation_area' === sortOrder.sortBy" (sort)="onSort('occupation_area','parcel.katuzeNazev')" [sortDir]="sortOrder.direction">
            K.Ú.
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_area">
            K.Ú.
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_concern_object">{{'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true}}</th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_phase">
          <sort-by *ngIf="sortableColumns.occupation_phase" [selected]="'occupation_phase' === sortOrder.sortBy" (sort)="onSort('occupation_phase','occupations[0].zabst')" [sortDir]="sortOrder.direction">
            {{'ETAPA_USEK' | wordtranslate}}
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_phase">
            {{'ETAPA_USEK' | wordtranslate}}
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_occupation">
          <sort-by *ngIf="sortableColumns.occupation_occupation" [selected]="'occupation_occupation' === sortOrder.sortBy" (sort)="onSort('occupation_occupation','occupations[0].zabcis')" [sortDir]="sortOrder.direction">
            Zábor
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_occupation">
            Zábor
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_cases">
          <sort-by *ngIf="sortableColumns.occupation_cases" [selected]="'occupation_cases' === sortOrder.sortBy" (sort)="onSort('occupation_cases', 'occupations[0].countCases')" [sortDir]="sortOrder.direction">
            Případy
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_cases">
            Případy
          </p>
        </th>
        <th [attr.rowspan]="2" *ngIf="visibleColumns.occupation_cases_parcel">
          <sort-by *ngIf="sortableColumns.occupation_cases_parcel" [selected]="'occupation_cases_parcel' === sortOrder.sortBy" (sort)="onSort('occupation_cases_parcel', 'occupations[0].countCases')" [sortDir]="sortOrder.direction">
            Případy
          </sort-by>
          <p *ngIf="!sortableColumns.occupation_cases_parcel">
            Případy
          </p>
        </th>
        <th [attr.colspan]="occupationPriceColumnsCount" *ngIf="occupationPriceColumnsCount > 0" class="bordered-bottom">
          Cena [<span class="unit">Kč</span>]
        </th>
      </tr>
      <tr>
        <th *ngIf="visibleColumns.occupation_price_parcel">
          Pozemek<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_parcel_cases">
          Pozemek<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_vegetation">
          Porost<br/>(vedlejší úpravy)<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_vegetation_cases">
          Porost<br/>(vedlejší úpravy)<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_construction">
          Stavba<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_construction_cases">
          Stavba<br/><span class="unit">celkem</span>
        </th>
        <th *ngIf="visibleColumns.occupation_price_summary">
          Celkem
        </th>
        <th *ngIf="visibleColumns.occupation_price_summary_cases">
          Celkem
        </th>
      </tr>
    </thead>
      <tbody *ngFor="let itemGroupOccupation of data" class="hover">
      <ng-container *ngIf="itemGroupOccupation.parcel; let parcel">
        <tr *ngIf="visibleColumns.label_gp && parcel.knBudouc">
          <th [attr.colspan]="occupationColumnsCount" class="bordered-top bordered-bottom">
            <a *ngIf="parcel.knBudouc.knGp.geometricPlanRequest && geometricPlanModuleEnabled; else withoutLink"
              uiSref="symap.project.geometricPlanRequests.detail"
              [uiParams]="{ id: parcel.knBudouc.knGp.geometricPlanRequest.id, tab: 'parcels-budouc' }"
            >GP č. {{ parcel.knBudouc.knGp.cis }}</a>
            <ng-template #withoutLink>GP č. {{ parcel.knBudouc.knGp.cis }}</ng-template>
          </th>
        </tr>
        <tr *ngFor="let itemOccupation of itemGroupOccupation.occupations; first as isFirst">
          <ng-container *ngIf="isFirst || parcel.endDate">
            <td *ngIf="visibleColumns.occupation_parcel" [attr.rowspan]="parcel.endDate ? 1 : itemGroupOccupation.occupations.length" class="no-padding cell-max-height">
              <cell-parcel
                [parcel]="parcel"
                [occupation]="itemOccupation"
                [updateOccupationParcelPath]="updateOccupationParcelPath"
                [tooltipType]="'parcel'"
                [tooltipData]="{parcel: parcel}"
                [showProtection]="true"
              ></cell-parcel>
            </td>
          </ng-container>
          <ng-container *ngIf="isFirst">
            <td *ngIf="visibleColumns.occupation_parcel_title" [attr.rowspan]="itemGroupOccupation.occupations.length" class="no-padding cell-max-height">
              <cell-parcel
                [parcel]="parcel"
                [tooltipType]="'parcel'"
                [tooltipData]="{parcel: parcel, withoutTitle: true}"
                [showProtection]="true"
              ></cell-parcel>
            </td>
            <td *ngIf="visibleColumns.occupation_title" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              <a *ngIf="parcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: parcel.title.id, tab: null}">
                <b>{{parcel.title.lv}}</b>
              </a>
            </td>
            <td *ngIf="visibleColumns.occupation_area" [attr.rowspan]="itemGroupOccupation.occupations.length">
              {{parcel.katuzeNazev}}
            </td>
          </ng-container>
          <td *ngIf="visibleColumns.occupation_concern_object">
            <gmt-construction-object-list [constructionObjects]="itemOccupation.constructionObjects"></gmt-construction-object-list>
          </td>
          <td *ngIf="visibleColumns.occupation_phase" class="no-padding cell-max-height">
            <cell-occupation
              [occupation]="itemOccupation"
              [type]="'phase'"
            ></cell-occupation>
          </td>
          <td *ngIf="visibleColumns.occupation_occupation" class="no-padding cell-max-height">
            <cell-occupation
              [occupation]="itemOccupation"
              [showSwitch]="visibleColumns.occupation_switch"
              [showUpdate]="visibleColumns.update_occupation"
            ></cell-occupation>
          </td>
          <ng-container *ngIf="isFirst">
            <td *ngIf="visibleColumns.occupation_cases" [attr.rowspan]="itemGroupOccupation.occupations.length" class="no-padding cell-max-height">
              <cell-cases
                [casesCount]="itemGroupOccupation.occupations[0].countCases"
                [tooltipType]="'cases'"
                [tooltipData]="{filter: {filters: {occupationId: itemOccupation.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
              ></cell-cases>
            </td>
            <td *ngIf="visibleColumns.occupation_cases_parcel" [attr.rowspan]="itemGroupOccupation.occupations.length" class="no-padding cell-max-height">
              <cell-cases
                [casesCount]="parcel.countCases"
                [tooltipType]="'cases'"
                [tooltipData]="{filter: {filters: {parcelId: parcel.id, cancelStatus: 'notCancelled'}}}"
              ></cell-cases>
            </td>
            <td *ngIf="visibleColumns.occupation_price_parcel" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ parcel.landPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_parcel_cases" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ itemOccupation.caseParcel.landPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_vegetation" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ parcel.vegetationPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_vegetation_cases" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ itemOccupation.caseParcel.vegetationPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_construction" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ parcel.buildingsPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_construction_cases" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              {{ itemOccupation.caseParcel.buildingsPrice | number }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_summary" [attr.rowspan]="itemGroupOccupation.occupations.length" class="number">
              <i class="fa" [ngClass]="parcel.buyoutOpinionsShare === 100 ? 'fa-check all-opinions' : 'fa-exclamation-triangle no-opinion'" title="{{ parcel.buyoutOpinionsShare }} %"></i>
              {{ parcel.totalPrice | number | price }}
            </td>
            <td *ngIf="visibleColumns.occupation_price_summary_cases" [attr.rowspan]="itemGroupOccupation.occupations.length" class="cell no-padding cell-max-height">
              <cell-price
                [price]="(itemOccupation.caseParcel.landPrice + itemOccupation.caseParcel.vegetationPrice + itemOccupation.caseParcel.buildingsPrice)"
                [editable]="canEdit('admin,update_case_price') && isCaseParcelPriceValid(itemOccupation.caseParcel)"
                [editFunction]="priceEditFunction"
                [editFunctionParams]="{parcelId: itemOccupation.caseParcel.parcel.id}"
                [casePrice]="itemOccupation.caseParcel"
                [tooltipType]="'occupation-price'"
                [tooltipData]="{filter: {filters: {caseId: data.caseId, idpar: itemOccupation.caseParcel.parcel.idpar}}}"
              ></cell-price>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="parcel.knBudouc && currentColumnsCount > 0" class="bordered-top">
          <th *ngIf="visibleColumns.current_title">
            LV
          </th>
          <th *ngIf="visibleColumns.current_parcel">
            Původní parcela
          </th>
          <th *ngIf="visibleColumns.current_parcel_title">
            Původní parcela
          </th>
          <th *ngIf="visibleColumns.current_protection">
            Ochrana
          </th>
          <th *ngIf="visibleColumns.current_concern_object">
            {{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}
          </th>
          <th [attr.colspan]="occupationColumnsCount - currentColumnsCount" class="bordered-bottom">
          </th>
        </tr>
        <tr *ngFor="let currentParcel of parcel.knBudouc?.parcels; first as isFirst" class="current-parcel" [ngClass]="{first: isFirst}">
          <td *ngIf="visibleColumns.current_title" class="number">
            <a *ngIf="currentParcel.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: currentParcel.title.id, tab: null}">
              <b>{{ currentParcel.title.lv }}</b>
            </a>
          </td>
          <td *ngIf="visibleColumns.current_parcel" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="currentParcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: currentParcel}"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.current_parcel_title" class="no-padding cell-max-height">
            <cell-parcel
              [parcel]="currentParcel"
              [tooltipType]="'parcel'"
              [tooltipData]="{parcel: currentParcel, withoutTitle: true}"
            ></cell-parcel>
          </td>
          <td *ngIf="visibleColumns.current_protection">
            <div>
              {{currentParcel | parcelprotection }}
            </div>
            <div *ngIf="currentParcel.bpejList.length > 0" class="comma-list">
              (<span *ngFor="let itemBpej of currentParcel.bpejList">{{itemBpej.bpejKod}}</span>)
            </div>
          </td>
          <td *ngIf="visibleColumns.current_concern_object">
            {{currentParcel.constructionObjectsText}}
          </td>
          <td [attr.colspan]="occupationColumnsCount - currentColumnsCount">
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
