<div class="detail">
  <div>
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 160px;">
        <gmt-back-button sref="^" text="Seznam objednatelů">
        </gmt-back-button>
      </div>
      <div class="actions">
        <gmt-button [clickAction]="showCustomerForm">
          <i class="fa fa-pencil"></i>
          Editovat objednatele
        </gmt-button>
        <gmt-button [clickAction]="showContractForm">
          <i class="fa fa-plus"></i>
          Založit smlouvu
        </gmt-button>
      </div>
    </div>

    <h1>{{ pageTitle }} <span *ngIf="customer.companyName">{{customer.companyName}}</span></h1>

    <customer-form [editable]="false" [customer]="customer"></customer-form>

    <contract-list [customerId]="customer.idText"></contract-list>
  </div>

