<h1>Nastavení generování dokumentu</h1>

<div class="column-100" (click)="document.mandatary = !document.mandatary">
  <gmt-input label="Mandatář">
    <gmt-checkbox [gmtChecked]="document.mandatary"></gmt-checkbox>
  </gmt-input>
</div>
<div class="column-100">
  <gmt-input label="Požadované oprávění">
    <div [loading]="!permissionOptions" class="fl">
      <gmt-select [data]="document" [optionItems]="permissionOptions" field="permission" [itemPipe]="descriptionPipe" *ngIf="permissionOptions"></gmt-select>
    </div>
  </gmt-input>
</div>

<div class="actions">
  <gmt-button [clickAction]="cancel" class="black">Zrušit</gmt-button>
  <gmt-button [clickAction]="save">Použít</gmt-button>
</div>
