import { Input, Type } from "@angular/core";

export type CellTooltipMappingType = {[s: string]: Type<any>};

export abstract class CellAbstractComponent {

  // binding
  @Input() tooltipData: any;
  @Input() set tooltipType(type: string) {
    this.tooltipComponent = CellAbstractComponent.getTooltipComponent(type, this.TOOLTIP_MAPPING);
  }

  // model
  tooltipComponent: Type<any>;

  // props
  protected abstract readonly TOOLTIP_MAPPING: CellTooltipMappingType;

  /**
   * Get Tooltip component by string name
   * @param type
   * @param mappings
   */
  protected static getTooltipComponent(type: string, mappings: CellTooltipMappingType): Type<any> {
    return type && mappings && mappings[type] ? mappings[type] : null;
  }
}
