import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseOwnershipModel } from '@app/ps/models/case-ownership.model';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'send-owner',
  templateUrl: './send-owner.component.html',
  styleUrls: ['./send-owner.component.scss']
})
export class SendOwnerComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  recipients: any[];
  dataSelected: any = {};
  name: string;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] }).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('SentOwner')).name;
    const ownerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
    this.recipients = this.caseService.getRecipients(ownerships)
      .filter(value => !value.sentDate && !value.signedDate && !value.acceptedGeometricalPlanPossibleDate);

    if (this.recipients.length === 1) {
      this.dataSelected.recipient = this.recipients[0];
    }

    this.data.sentOwnerDate = (this.data.sentOwnerDate || new Date());

    this.loading = false;
  }

  isValid() {
    return this.data.sentOwnerDate && this.dataSelected.recipient;
  }

  async onSubmit(): Promise<any> {
    const sendData = {
      sentOwnerDate: this.data.sentOwnerDate,
      recipientOpsubId: this.dataSelected.recipient.opsubIds || [this.dataSelected.recipient.id]
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'send-owner')
      .toPromise();
    this.stateService.go('^');
  }
}
