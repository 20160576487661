import { Component, ChangeDetectionStrategy, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ColorEvent } from 'ngx-color';
import { VectorLayerStyleModel } from '@app/ps/map/models/vector-layer-style.model';

@Component({
  selector: 'layer-symbol',
  templateUrl: './layer-symbol.component.html',
  styleUrls: ['./layer-symbol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerSymbolComponent implements OnInit {
  @Input() interactive: boolean;
  @Input() style: VectorLayerStyleModel;
  @Input() set showColorpicker(val: boolean) {
    if (!val) {
      this.fillOrStroke = undefined; // when switching between multiple layers, forces colorpicker to close and reopen
    }
    this.isColorpickerVisible = val;
  }
  @Output() stylechange = new EventEmitter<VectorLayerStyleModel>();

  fillOrStroke: 'fill' | 'stroke';
  isColorpickerVisible: boolean;
  svgViewBox: string;
  svgRootStyle: any;

  readonly interactiveSvgViewBox = '-2 -2 20 20';
  readonly staticSvgViewBox = '0 0 16 12';
  readonly staticSvgRootStyle = {
    'height.px': 12,
    'width.px': 16,
  };
  readonly interactiveSvgRootStyle = {
    'height.px': 20,
    'width.px': 20,
  };

  ngOnInit() {
    this.svgViewBox = this.interactive ? this.interactiveSvgViewBox : this.staticSvgViewBox;
    this.svgRootStyle = this.interactive ? this.interactiveSvgRootStyle : this.staticSvgRootStyle;
  }

  onColorChange($event: ColorEvent) {
    this.style[this.fillOrStroke] = $event.color.rgb;
    this.stylechange.emit(this.style);
  }

  onColorpickerToggle(fillOrStroke: 'fill' | 'stroke') {
    if (this.fillOrStroke === fillOrStroke) {
      this.fillOrStroke = undefined;
    } else {
      this.fillOrStroke = fillOrStroke;
    }
  }

  getFillStyle() {
    const result = {
      fill: `rgb(${this.style.fill.r}, ${this.style.fill.g}, ${this.style.fill.b})`,
      opacity: this.style.fill.a,
    };

    if (this.interactive) {
      result['height.px'] = 12;
      result['width.px'] = 12;
    } else {
      result['height.px'] = 10;
      result['width.px'] = 14;
    }

    return result;
  }

  getStrokeStyle() {
    const result = {
      'fill': 'none',
      'opacity': this.style.stroke.a,
      'stroke': `rgb(${this.style.stroke.r}, ${this.style.stroke.g}, ${this.style.stroke.b})`,
      'width.px': 16,
    };

    if (this.interactive) {
      result['height.px'] = 16;
      result['stroke-width'] = 4;
    } else {
      result['height.px'] = 12;
      result['stroke-width'] = 2;
    }

    return result;
  }
}
