<div class="mb-20 bordered">
  <table>
    <thead>
    <tr>
      <th *ngIf="isChecked" class="min">
        <gmt-checkbox
          gmtType="multiselect"
          [gmtChecked]="checkedAll"
          (click)="onToggleAll()"
        ></gmt-checkbox>
      </th>
      <th *ngIf="visibleColumns.parcel || visibleColumns.parcel_cell">
        <sort-by
          *ngIf="sortableColumns.parcel"
          [selected]="'parcel' === sortOrder.sortBy"
          (sort)="onSortParcel('parcel', false)"
          [sortDir]="sortOrder.direction">
          Parcela
        </sort-by>
        <span *ngIf="!sortableColumns.parcel">
          Parcela
        </span>
      </th>
      <th *ngIf="visibleColumns.title">
        <sort-by
          *ngIf="sortableColumns.title"
          [selected]="'title' === sortOrder.sortBy"
          (sort)="onSort('title', 'lv')"
          [sortDir]="sortOrder.direction">
          LV
        </sort-by>
        <span *ngIf="!sortableColumns.title">
          LV
        </span>
      </th>
      <th *ngIf="visibleColumns.area_name">
        <sort-by
          *ngIf="sortableColumns.area_name"
          [selected]="'area_name' === sortOrder.sortBy"
          (sort)="onSort('area_name', 'katuzeNazev')"
          [sortDir]="sortOrder.direction">
          K.Ú.
        </sort-by>
        <span *ngIf="!sortableColumns.area_name">
          K.Ú.
        </span>
      </th>
      <th *ngIf="visibleColumns.area">Výměra [<span class="unit">m<sup>2</sup></span>]</th>
      <th *ngIf="visibleColumns.parcel_price">Cena [<span class="unit">Kč</span>]</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let parcel of data" (click)="onToggleOne(parcel)">
      <td *ngIf="isChecked">
        <gmt-checkbox
          gmtType="multiselect"
          [gmtChecked]="isChecked(parcel)"
        ></gmt-checkbox>
      </td>
      <td *ngIf="visibleColumns.parcel || visibleColumns.parcel_cell" class="cell-max-height" [ngClass]="{'no-padding': visibleColumns.parcel_cell}">
        <cell-parcel *ngIf="visibleColumns.parcel_cell" [tooltipType]="'parcel'" [parcel]="parcel" [tooltipData]="{parcel: parcel}"></cell-parcel>
        <ng-container *ngIf="!visibleColumns.parcel_cell">
          {{ parcel | parcelnumber }}
        </ng-container>
      </td>
      <td *ngIf="visibleColumns.title">{{ parcel.lv }}</td>
      <td *ngIf="visibleColumns.area_name">{{ parcel.katuzeNazev }}</td>
      <td *ngIf="visibleColumns.area">{{ parcel.vymera }}</td>
      <td *ngIf="visibleColumns.parcel_price">
        <span *ngIf="parcel.totalPrice !== undefined">{{ parcel.totalPrice | number | price }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
