export class VfzeValidationResponseModel {
  errors: VfzeProblemModel[];
  fatal: VfzeProblemModel[];
  warnings: VfzeProblemModel[];
  validationMessage: string;
  modelDeserializationPassed: boolean;
  xmlStructureValid: boolean;
  occupationSummary: any;
  easementArea: number;
  xsdVersionPassed?: boolean;
  xsdVersion?: string;
  verzeFormat?: string;
  platnostDatKN?: string;
  platnostExportu?: string;
  software?: string;
  nazev?: string;
  autor?: string;
}
