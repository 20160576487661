<h1>Potvrzení smazání projektu</h1>
<div class="mt-40">
  <p *ngIf="!phase" class="text-center">Opravdu projekt "{{ project.name }}" smazat?</p>
  <div class="actions" *ngIf="!phase">
    <gmt-button [clickAction]="confirmPhase">Ano</gmt-button>
    <gmt-button [clickAction]="decline">Ne</gmt-button>
  </div>

  <fieldset *ngIf="phase">
    <div class="column-100">
      <label class="required">Heslo</label>
      <div class="input"><input type="password" [(ngModel)]="password"/></div>
    </div>
  </fieldset>
  <div class="actions" *ngIf="phase">
    <gmt-button [gmtDisabled]="!password" [clickAction]="confirm">Potvrdit</gmt-button>
  </div>
</div>
