import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import * as _ from 'lodash';
import { EVENTS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class CaseStatusService {

  private statuses;
  private list: ListModel<any>;
  private listPromise: Promise<any>;

  constructor(
    private restangular: Restangular,
    private listService: ListService,
    private authService: AuthService,
  ) {
    this.loadStatuses = this.loadStatuses.bind(this);
    this.loadStatusName = this.loadStatusName.bind(this);
    this.saveStatuses = this.saveStatuses.bind(this);
    this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);

    this.authService.registerCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
  }

  public loadStatuses(): Promise<any> {
    if (this.statuses) {
      return Promise.resolve(this.statuses);
    }
    if (this.list) {
      return this.listPromise.then(() => this.statuses);
    }

    this.list = this.listService.createList('case-statuses', { limit: null });
    this.listPromise = this.listService.fetchResult(this.list).then(() => {
      this.statuses = this.list.list;
      this.listPromise = this.list = undefined;
      return this.statuses;
    });

    return this.listPromise;
  }

  public loadStatusName(caseStatus: string): Promise<any> {
    return this.loadStatuses().then(() => {
      return _.find(this.statuses, { key: caseStatus });
    });
  }

  public saveStatuses(statuses): Promise<any> {
    if (statuses) {
      return this.restangular.all('case-statuses').customPUT(statuses).toPromise();
    }
  }

  public onAuthProjectUpdated() {
    if (this.list) {
      this.listService.cancelFetch(this.list);
      this.listPromise = this.list = undefined;
    }
    if (this.statuses) {
      this.statuses = undefined;
    }
  }
}
