import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';

import { AuthService } from '@app/common/services/auth.service';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'wanted-switch',
  templateUrl: './wanted-switch.component.html',
  styleUrls: ['./wanted-switch.component.scss'],
})
export class WantedSwitchComponent implements OnInit {
  @Input()
  public item;
  @Output()
  private changed = new EventEmitter();

  public selected;
  public areaLoading;
  public options;

  constructor(
    private authService: AuthService,
    private userNamePipe: UserNamePipe,
    private restangular: Restangular,
  ) {
  }

  ngOnInit() {

    this.selected = this.item.wanted;
    this.areaLoading = false;
    this.options = this.getOptionsCaption(this.item.owner_type);
  }

  updateSelection(v) {
    const oldValue = this.selected;
    this.selected = v;
    this.areaLoading = true;

    this.restangular.one('intersections', this.item.id).all('wanted')
      .customPOST({
        wanted: v
      })
      .toPromise()
      .then(() => {
        this.item.user_name = this.userNamePipe.transform(this.authService.getUser());
        this.item.revision_timestamp = new Date().getTime();
        this.item.wanted = v;
        this.areaLoading = false;

        this.changed.emit({newValue: this.item.wanted, oldValue: oldValue, ownerType: this.item.owner_type});
      });
  }

  getOptionsCaption(ownerType) {
    switch(ownerType) {
      case 'O':
        return {
          wanted: 'Ponechat',
          unwanted: 'Prodat'
        };
      case 'F':
        return {
          wanted: 'Koupit',
          unwanted: 'Ponechat'
        };
      case 'S':
        return {
          wanted: 'Koupit',
          unwanted: 'Prodat'
        };
    }
  }
}
