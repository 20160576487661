import { Component, Inject, Input, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { AuthService } from '@app/common/services/auth.service';
import { TemplateModel } from '@app/ps/models/template.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'template-history',
  templateUrl: './template-history.component.html',
})
export class TemplateHistoryComponent implements OnInit {

  @Input() key: string;

  list: ListModel<TemplateModel>;
  pageableList: PageableList;
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();

  private defaultFilter = {
    filters: {
      key: this.key,
    },
    sortOrder: {
      sortBy: 'startTimestamp',
      direction: 'desc',
    },
  };
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.initializeList();
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'templates',
      this.defaultFilter
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }
}
