<div class="list">
  <div class="clearfix">
    <div class="actions fl">
    </div>
    <div class="actions fr">
      <a visibleByBrand="maja" (click)="onCreate()" class="button">
        <i class="fa fa-plus-square"></i>Vytvořit nového uživatele
      </a>
      <a *ngIf="APP_BRAND.NAME !== 'RSD'" class="button" uiSref="settings.users.permissionsBulk">Nastavit oprávnění</a>
      <gmt-button [clickAction]="onUsersSync"><i class="fa fa-refresh"></i> Synchronizovat uživatele</gmt-button>
    </div>
  </div>

  <h1>Uživatelé</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <gmt-select
      visibleByBrand="maja"
      multiselect="true"
      [data]="list.filter.filters.applications"
      field="values"
      [optionItems]="selectModules"
      selectTitle="Aktivní moduly"
    ></gmt-select>
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container" *ngIf="list.itemCount && list.list !== null">
      <div class="cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">
            <span class="cell">
              Aplikace
            </span>
            <span class="cell">
              <sort-by
                [selected]="'fullName' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('fullName', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Celé jméno</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'username' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('username', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Přihlašovací jméno</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'email' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('email', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >E-mail</sort-by>
            </span>
            <span class="cell">
              Firma
            </span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let user of list.list" uiSref="settings.users.detail" [uiParams]="{ id: user.id, page: null }">
            <span class="cell">
              {{ user.applications | gmtApplications }}
            </span>
            <span class="cell">{{ user.fullName }}</span>
            <span class="cell">{{ user.username }}</span>
            <span class="cell">{{ user.email }}</span>
            <span class="cell">{{ user.company | projectSubjectName }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
      Nenalezena žádná data
    </div>
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
    <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>
  </div>

  <help [helpId]="helpIds.SETTINGS_USERS" class="cm-help-page"></help>
</div>
