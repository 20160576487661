import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonModule as AppCommonModule } from '@app/common/common.module';
import { PsCommonModule } from '../ps-common.module';
import { ProjectInfoComponent } from './components/project-info/project-info.component';
import { projectInfoState } from './projects.states';
import { UIRouterModule } from '@uirouter/angular';

const STATES = [
  projectInfoState,
];

@NgModule({
  declarations: [
    ProjectInfoComponent,
  ],
  entryComponents: [
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [...STATES]
    }),
  ]
})
export class ProjectsModule { }
