<h1>Editace hromadného zadání ceny věcného břemene</h1>

<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Způsob určení ceny</legend>
      <div class="column-100">
        <div class="input">
          <gmt-switch [options]="priceTypes" [(selectedId)]="constructionObject.easementGlobalPriceType" (select)="onPriceTypeUpdate($event.id)"></gmt-switch>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="constructionObject.easementGlobalPriceType === 'B'">
      <legend>Cena</legend>

      <div class="column-100">
        <gmt-input label="Způsob výpočtu ceny">
          <gmt-switch [options]="unitTypes" [(selectedId)]="constructionObject.easementGlobalOtherUnitType" (select)="onUnitTypeUpdate($event.id)"></gmt-switch>
        </gmt-input>

        <label>Původ ceny</label>
        <div class="input" *ngIf="constructionObject.easementGlobalOtherUnitType !== 'S'"><input type="text" [(ngModel)]="constructionObject.easementGlobalOtherSource"/></div>
        <div class="info" *ngIf="constructionObject.easementGlobalOtherUnitType === 'S'">{{ constructionObject.easementGlobalOtherSource }}</div>


        <div *ngIf="constructionObject.easementGlobalOtherUnitType === 'L'">
          <label>Cena VB [<span class="unit">Kč/bm</span>]</label>
          <div class="input"><input type="text" [(ngModel)]="constructionObject.easementGlobalOtherLengthPrice" gmtInputNumber/></div>
        </div>

        <div *ngIf="constructionObject.easementGlobalOtherUnitType === 'A'">
          <label>Cena VB [<span class="unit">Kč/m<sup>2</sup></span>]</label>
          <div class="input"><input type="text" [(ngModel)]="constructionObject.easementGlobalOtherAreaPrice" gmtInputNumber/></div>
        </div>

        <div *ngIf="constructionObject.easementGlobalOtherUnitType === 'S'">
          <label>Cena VB [<span class="unit">Kč</span>]</label>
          <div class="info">{{ constructionObject.easementGlobalOtherSinglePrice | number | price }}</div>
        </div>
      </div>
    </fieldset>

    <div class="text-center mt-20">
      <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onUpdate">Uložit údaje</gmt-button>
    </div>
  </div>

  <help [helpId]="helpIds.CONSTRUCTIONOBJECT_UPDATE_GLOBAL_EASEMENT_PRICE" class="cm-help-page"></help>
</div>
