import { KnGpModel } from '@app/common/models/kn-gp.model';
import { GeometricPlanRequestModel } from '../../models/geometric-plan-request.model';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cell-geometric-plans',
  templateUrl: './cell-geometric-plans.component.html',
  styleUrls: ['./cell-geometric-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellGeometricPlans {
  @Input() geometricPlanRequests: GeometricPlanRequestModel[];
  @Input() knGps: KnGpModel[];
  @Input() withLink: boolean;

  shouldBeKnGpDisplayed(knGp: KnGpModel) {
    // keep only knGps without geometric plan request relation
    if (knGp.geometricPlanRequest && this.geometricPlanRequests && this.geometricPlanRequests.length) {
      return !this.geometricPlanRequests.find(gpr => gpr.id === knGp.geometricPlanRequest.id);
    }
    return true;
  }
}
