﻿import {Pipe, PipeTransform, Injectable} from "@angular/core";
import {SubjectModel} from '../models/subject.model';

@Pipe({name: 'subjectName'})
@Injectable({
  providedIn: 'root'
})
export class SubjectNamePipe implements PipeTransform {

  transform(subject: SubjectModel) {
    if (!subject) {
      return '';
    }

    var words = [];

    if (subject.nazev) {
      words.push(subject.nazev);
    }

    if (subject.prijmeni) {
      words.push(subject.prijmeni);
    }

    if (subject.jmeno) {
      words.push(subject.jmeno);
    }

    var tmp = [];
    if (words.length !== 0) {
      tmp.push(words.join(" "));
    }
    words = [];

    if (subject.titulPredJmenem) {
      words.push(subject.titulPredJmenem);
    }

    if (subject.titulZaJmenem) {
      words.push(subject.titulZaJmenem);
    }
    if (words.length !== 0) {
      tmp.push(words.join(" "));
    }

    return tmp.join(", ");
  }
}
