import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { BuildingModel } from '@app/common/models/building.model';
import { BuildingNumberPipe } from '@app/common/pipes/building-number.pipe';

@Pipe({
  name: 'buildingName'
})
@Injectable({
  providedIn: 'root'
})
export class BuildingNamePipe implements PipeTransform {

  constructor(
    private parcelNumberPipe: ParcelNumberPipe,
    private buildingTypePipe: BuildingNumberPipe,
  ) {}

  transform(building: BuildingModel): string {
    if (!building) {
      return '-';
    }

    const cp = this.buildingTypePipe.transform(building);

    let parcels = '';

    if (building.parcels && building.parcels.length) {
      const parts = [
        'na parcel' + (building.parcels.length > 1 ? 'ách' : 'e'),
        this.parcelNumberPipe.transform(building.parcels[0]),
      ];

      if (building.parcels.length > 1) {
        parts.push('(+' + (parcels.length - 1) + ')');
      }

      parcels = parts.join(' ');

    }

    return (cp + ' ' + parcels);
  }
}
