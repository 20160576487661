import { Injectable, Inject } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';
import { OccupationModel } from '../models/occupation.model';
import * as _ from 'lodash';
import { WordService } from '@app/common/services/word.service';

export type OccupationGroupsType = {
  permanent: OccupationGroupedType[],
  overOneYear: OccupationGroupedType[],
  underOneYear: OccupationGroupedType[]
};

export type OccupationGroupedType = {
    id: string,
    key: string,
    parcel: ParcelModel,
    occupations: OccupationModel[],
};

@Injectable({
  providedIn: 'root'
})
export class ParcelService {

  constructor(
    private wordService: WordService,
  ) {
    this.groupOccupationsByParcel = this.groupOccupationsByParcel.bind(this);
    this.groupOccupationsByParcelSingle = this.groupOccupationsByParcelSingle.bind(this);
    this.getTemporaryOccupationName = this.getTemporaryOccupationName.bind(this);
    this._groupOccupationsByParcel = this._groupOccupationsByParcel.bind(this);
  }

  groupOccupationsByParcel(occupations: OccupationModel[], parcelsData: ParcelModel[] = null): OccupationGroupsType {
    return this._groupOccupationsByParcel(occupations, parcelsData, false) as OccupationGroupsType;
  }

  groupOccupationsByParcelSingle(occupations: OccupationModel[], parcelsData: ParcelModel[] = null): OccupationGroupedType[] {
    return this._groupOccupationsByParcel(occupations, parcelsData, true) as OccupationGroupedType[];
  }

  getTemporaryOccupationName(group: OccupationGroupedType[]): string {
    if (!group || !group[0] || !group[0].occupations || !group[0].occupations[0]) {
      return '';
    }

    return group[0].occupations[0].zabtyptx
      .replace(/dočasný zábor/i, this.wordService.getTranslation('TEMPORARY_OCCUPATION'));
  }

  /**
   * Určí skupinu záborů podle čísla typu záboru a seskupí záznamy. Trvalé zábory jsou navíc seskupeny podle parcely.
   * Pokud je určen způsob řešení trvalého záboru nájmem je skupina určena tímto. Pro specifické účely jsou tyto děleny do zvláštních skupin a zároveň jedné sloučené.
   * @param {type} data
   * @param {type} parcelsData
   * @param {type} singleLevel
   * @returns {Array}
   */
  private _groupOccupationsByParcel(data, parcelsData, singleLevel) {
    singleLevel = singleLevel === true;
    const dataOccupationGroups = singleLevel ? [] : {};
    data.forEach((occupation) => {
      const groupKey = occupation.solutionType || occupation.occupationType.category;

      let occupationGrouped;

      if (!singleLevel && !dataOccupationGroups[groupKey]) {
        dataOccupationGroups[groupKey] = [];
      }
      if (groupKey === 'permanent') {
        const itemFound = _.find(singleLevel ? dataOccupationGroups : dataOccupationGroups[groupKey], (group) => {
          return (!singleLevel || group.key === groupKey) && occupation.parcel && group.parcel.id === occupation.parcel.id;
        });
        if (itemFound) {
          itemFound.occupations.push(occupation);
          return;
        } else if (occupation.parcel) {
          if (parcelsData instanceof Array) {
            parcelsData.push(occupation.parcel);
          }
          occupationGrouped = {
            id: groupKey + '_' + occupation.parcel.id,
            key: groupKey,
            parcel: occupation.parcel,
            occupations: [occupation],
          };
        } else {
          // without parcel
          return;
        }
      } else {
        if (parcelsData instanceof Array) {
          parcelsData.push(occupation.parcel);
        }
        occupationGrouped = {
          id: groupKey + '_' + occupation.id,
          key: groupKey,
          parcel: occupation.parcel,
          occupations: [occupation], // there will be only one item
        };
      }
      (singleLevel ? dataOccupationGroups : dataOccupationGroups[groupKey]).push(occupationGrouped);

      if (!singleLevel && groupKey.startsWith('permanentWithSolutionType')) {
        dataOccupationGroups['permanentWithSolutionType'] = dataOccupationGroups['permanentWithSolutionType'] || [];
        dataOccupationGroups['permanentWithSolutionType'].push(occupationGrouped);
      }
    });

    return dataOccupationGroups;
  }
}
