import { Component, OnInit, Input, Output, EventEmitter, PipeTransform } from '@angular/core';

import { ListService } from '@app/common/services/list.service';
import { StatisticsService } from '../../services/statistics.service';
import { ConstructionObjectNamePipe } from '../../../common/pipes/construction-object-name.pipe';
import { SettingsService } from '@app/ps/services/settings.service';

@Component({
  selector: 'statistics-entity-filter',
  templateUrl: './statistics-entity-filter.component.html',
})
export class StatisticsEntityFilterComponent implements OnInit {

  @Input() filter: any;
  @Output() registerCallback = new EventEmitter();
  @Output() changed = new EventEmitter();

  filterNames: any[];
  defaultPipe: PipeTransform = new (class DefaultPipe implements PipeTransform {
    transform(item: any) {
      return item.name;
    }
  })();

  constructor(
      private statisticsService: StatisticsService,
      private listLoadService: ListService,
      private constructionObjectNamePipe: ConstructionObjectNamePipe,
      private settingsService: SettingsService,
  ) {
    this.updateEntityFilterKey = this.updateEntityFilterKey.bind(this);
  }

  ngOnInit() {
    this.registerCallback.emit({
      updateEntityFilterKey: this.updateEntityFilterKey,
    });
    this.loadFilterStatisticsName();
  }

  checkFilterStatistics() {
    const exists = this.filter.filters.statisticsEntityFilterKey;

    return exists && exists.values && exists.values.length > 0 && exists.values.every(k => {
      return [5, 6].includes(k.id.split('_').length);
    });
  }

  updateEntityFilterKey(value: string[]) {
    this.filter.filters.statisticsEntityFilterKey = {
      values: (value && value.length ? value.map(v => { return { id: v }; }) : [])
    };
    this.loadFilterStatisticsName();
    this.changed.emit({
      state: this.checkFilterStatistics(),
    });
  }

  getFilterStatisticsParam(index, paramIndex) {
    if (!this.checkFilterStatistics()) {
      return null;
    }
    const value = this.filter.filters.statisticsEntityFilterKey.values[index].id;
    return Number(value.split('_')[paramIndex]);
  }

  isSplitCategory(index) {
    const groups = this.filter.filters.statisticsEntityFilterKey.values[index].id.split('_');
    return 6 === groups.length && groups[5] === '1';
  }

  resolveCategoryName(index) {
    return this.statisticsService.getStatisticsFilters().promise.then((data) => {
      const foundStatistics = data.list.find((item) => item.id === this.getFilterStatisticsParam(index, 0));
      const names: any = {};
      if (foundStatistics) {
        names.name = foundStatistics.name;
        const categoryId = this.getFilterStatisticsParam(index, 1);
        if (categoryId === 0) {
          names.categoryName = 'Celkem';
        } else if (categoryId === 1) {
          names.categoryName = foundStatistics.defaultCategory;
        } else {
          const foundCategory = foundStatistics.categories[categoryId - 2];
          if (foundCategory) {
            names.categoryName = this.isSplitCategory(index) ? foundCategory.splitName : foundCategory.name;
          }
        }
      }
      return names;
    });
  }

  loadFilterStatisticsName() {
    if (!this.checkFilterStatistics()) {
      return;
    }

    this.filterNames = [];

    this.filter.filters.statisticsEntityFilterKey.values.forEach((v, i) => {

      const promises = [];

      const entities = [
        {
          entityId: this.getFilterStatisticsParam(i, 2),
          path: 'areas',
          nameAttr: 'areaName',
          filter: this.defaultPipe,
        },
        {
          entityId: this.getFilterStatisticsParam(i, 3),
          path: 'construction-objects',
          nameAttr: 'constructionObjectName',
          filter: this.constructionObjectNamePipe,
        },
        {
          entityId: this.getFilterStatisticsParam(i, 4),
          path: 'construction-object-stages',
          nameAttr: 'constructionObjectStageName',
          filter: this.defaultPipe,
        }
      ];

      entities.forEach((entity) => {
        promises.push(entity.entityId ? this.listLoadService.fetchResult(this.listLoadService.createList(entity.path, {
          entityIds: [entity.entityId],
          limit: 1,
        })).then(async (data) => {
          const names = {};
          if (data.list.length) {
            await this.settingsService.getSettingsLoader();
            names[entity.nameAttr] = entity.filter.transform(data.list[0]);
          }
          return names;
        }) : Promise.resolve({}));
      });

      promises.push(this.resolveCategoryName(i));

      Promise.all(promises).then((data) => {
        const namesObj = {};
        data.forEach((names) => {
          Object.assign(namesObj, names);
        });

        this.filterNames.push(namesObj);
      });
    });
  }
}
