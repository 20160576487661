<basic-info-form *ngIf="owner.opsubType === 'OFO' || owner.opsubType === 'OPO'" [basicInfo]="basicInfo"></basic-info-form>

<address-form
  [collapsed]="owner.subjectDetail && owner.subjectDetail.customAddressUse"
  [address]="address"
  toggleText="Jiná adresa než v datech KN"
  (toggle)="onToggleAddress($event)"
></address-form>

<address-form
  [collapsed]="owner.subjectDetail && owner.subjectDetail.dorucovaciUse"
  [address]="mailingAddress"
  heading="Doručovací adresa"
  toggleText="Jiná doručovací adresa"
  (toggle)="onToggleMailingAddress($event)"
></address-form>

<contact-form [contactInfo]="owner.subjectDetail"></contact-form>

<representative-form [representative]="contactPerson" [allowRoleAssignment]="false" heading="Kontaktní osoba"></representative-form>

<payment-form [paymentOptions]="owner.subjectDetail" [withPaymentDetails]="false"></payment-form>

<div class="centered-text">
  <div class="mb-5 text-center" [gmtAuth]="'admin,update_case_data'">
    <gmt-checkbox [gmtChecked]="actualizeAll" (click)="actualizeAll = !actualizeAll"></gmt-checkbox> Aktualizovat změny i u všech existujících případů (příp. odlišné údaje budou přepsané).<br>
  </div>
  <gmt-button [clickAction]="onSubmit">Uložit údaje</gmt-button>
</div>
