import { AuthService } from '@app/common/services/auth.service';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { VfzeUtils } from '@app/common/utils/vfze.utils';
import { VfzeValidationWrapperModel } from '@app/common/models/vfze-validation-wrapper.model';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import { APP_CONFIG } from '@app/common/services/config.service';
import { StateService } from '@uirouter/angular';
import { VfzeValidationResponseModel } from '@app/common/models/vfze-validation-response.model';
import { IndexedDbStorageService } from '@app/common/services/indexed-db-storage.service';

@Component({
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, OnDestroy {

  static readonly MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
  static readonly MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';
  static readonly MSG_FILES_TYPE = 'Je možné vložit soubor typu VFZE.';

  files: UploadFileExtended[] = [];
  filesMsg = ValidationComponent.MSG_FILES_DEFAULT;
  isFileValidToUpload = false;
  validationResponse: VfzeValidationResponseModel;
  xmlErrorsDisplayed = false;
  partialMessageLogHistory = [];
  partialMessageLog = [];
  keepWaitForValidationStatus = true;
  vfzeFileContent: string;
  vfzeMetadata: any;

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
    private indexedDbStorage: IndexedDbStorageService,
    private stateService: StateService,
    @Inject(APP_CONFIG) private config: any,
    private datePipe: DatePipe,
  ) {
    this.onUpload = this.onUpload.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onSaveValidationResponse = this.onSaveValidationResponse.bind(this);
  }

  ngOnInit() {
    this.indexedDbStorage.remove('vfze');
  }

  ngOnDestroy() {
    this.keepWaitForValidationStatus = false;
  }

  onFilesChange(files: UploadFileExtended[]) {
    this.files = files;
    this.validationResponse = undefined;
    this.xmlErrorsDisplayed = false;
    this.vfzeFileContent = undefined;
    this.vfzeMetadata = undefined;
    this.indexedDbStorage.remove('vfze');
    this.isFileValidToUpload = false;
    const extensionValid = this.files.every(f => f.extension && f.extension.toLowerCase() === 'vfze');

    if (this.files.length > 1) {
      this.filesMsg = ValidationComponent.MSG_FILES_QUANTITY;
    } else if (this.files.length === 0) {
      this.filesMsg = ValidationComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.filesMsg = ValidationComponent.MSG_FILES_TYPE;
    } else {
      this.filesMsg = '';
      this.isFileValidToUpload = this.files.length === 1;

      const reader = new FileReader();
      const file = this.files[0];
      file.uploading = true;
      file.progress = 10;
      const loader = setInterval(() => {
        if (file.progress < 100) {
          file.progress += 10;
        }
      }, 1000);
      reader.addEventListener('load', (event) => {
        file.progress = 100;
        this.vfzeFileContent = reader.result as string;

        // get metadata
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(this.vfzeFileContent, 'application/xml');

        const verzeFormatEl = xmlDoc.querySelector('export > verzeFormat');
        const platnostDatKnEl = xmlDoc.querySelector('export > platnostDatKN');
        const platnostExportuEl = xmlDoc.querySelector('export > platnostExportu');
        const softwareEl = xmlDoc.querySelector('export > software');
        const autorSubjektIdEl = xmlDoc.querySelector('export > autorSubjektId');
        const nazevEl = xmlDoc.querySelector('akce > nazev');
        let author = '';

        if (autorSubjektIdEl) {
          const subjekty = xmlDoc.querySelectorAll('subjekt');
          subjekty.forEach(s => {
            if (s.querySelector('id').textContent === autorSubjektIdEl.textContent) {
              author = s.querySelector('nazev').textContent;
            }
          });
        }

        this.vfzeMetadata = {
          verzeFormat: verzeFormatEl ? verzeFormatEl.textContent : '',
          platnostDatKn: platnostDatKnEl ? platnostDatKnEl.textContent : '',
          platnostExportu: platnostExportuEl ? platnostExportuEl.textContent : '',
          software: softwareEl ? softwareEl.textContent : '',
          autor: author,
          nazev: nazevEl ? nazevEl.textContent : '',
        };

        // storage (for map)
        this.indexedDbStorage.save('vfze', this.vfzeFileContent);
        clearInterval(loader);
        file.uploading = false;
      });
      reader.readAsText(file.fileEntry);
    }
  }

  onUpload(oldVersion = false): Promise<any> {
    this.validationResponse = undefined;
    this.xmlErrorsDisplayed = false;
    if (oldVersion) {
      this.partialMessageLogHistory = this.partialMessageLog;
    } else {
      this.partialMessageLogHistory = [];
      this.partialMessageLog = [];
    }

    const restBaseUrl = oldVersion ? this.config.BACKEND_OPTIONS.restUrlVFZEOld : this.authService.getActiveApplicationRestUrl();

    return this.uploadService.upload({
      url: restBaseUrl + '/async/validate',
      data: { vfze: this.files[0].fileEntry },
      headers: { Authorization: this.authService.getToken() }
    }).then((resp: VfzeValidationWrapperModel ) => {
      this.keepWaitForValidationStatus = true;

      const getValidationStatus = (resolve, reject) => {
        this.restangular.provider.setBaseUrl(restBaseUrl);
        this.restangular.one(`/async/status/${resp.validationId}`).get()
          .toPromise()
          .then((response: VfzeValidationWrapperModel) => {
            if (!this.keepWaitForValidationStatus) {
              reject();
            } else if (response.status === 'FINISHED' || response.status === 'FAILED') {
              if (response.status === 'FINISHED' && !response.validationResult.xsdVersionPassed && !oldVersion && this.config.BACKEND_OPTIONS.restUrlVFZEOld) {
                this.partialMessageLog.push('Spouští se validace starší verzí VFZE');
                return this.onUpload(true).then(() => resolve());
              }
              this.validationResponse = response.validationResult;
              this.partialMessageLog = [];
              resolve();
              this.keepWaitForValidationStatus = false;
            } else {
              this.partialMessageLog = this.partialMessageLogHistory.concat(response.message);
              setTimeout(() => getValidationStatus(resolve, reject), 1000);
            }
          }).catch((response) => {
            reject(response);
          });
        this.restangular.provider.setBaseUrl(this.authService.getActiveApplicationRestUrl());
      };

      return new Promise(getValidationStatus);
    });
  }

  onPreview() {
    this.stateService.go('vfze.map');
  }

  onSaveValidationResponse() {
    const blob = new Blob([ VfzeUtils.validationResponseToText(this.validationResponse, this.datePipe) ], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'validace-vfze.txt');
  }

  getValidationResult(): 'success' | 'warning' | 'error' | 'fatal' {
    return VfzeUtils.getValidationResult(this.validationResponse);
  }

  containResponseErrors(): boolean {
    if (!this.validationResponse) {
      return false;
    }
    const { errors, fatal } = this.validationResponse;
    return (!!errors.filter(p => p.xmlStructureError).length || !!fatal.filter(p => p.xmlStructureError).length);
  }
}
