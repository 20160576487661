<ng-template #backButtons>
    <div class="col-12 text-center mt-20">
        <gmt-button [clickAction]="cancel">Zpět na seznam projektů</gmt-button>
        <gmt-button [clickAction]="downloadOriginal"><i class="fa fa-file-archive-o"></i> Stažení zadaného území</gmt-button>
    </div>
</ng-template>

<ng-template #analogData let-project="project">
    <div class="col-12 mt-10">
        <div class="jumbotron" style="margin-bottom: 0;">
            <div *ngIf="project.addAllAnalog" class="text-center">
                Součástí žádosti jsou i analogová katastrální data v rozsahu všech analogových parcel v katastrálních územích dotčených zájmovým územím.
            </div>
            <div *ngIf="project.analogParcelsCsvAttached && !project.addAllAnalog" class="text-center">
                Součástí žádosti jsou i analogová katastrální data v rozsahu vloženého souboru csv.
            </div>
            <div *ngIf="!project.analogParcelsCsvAttached && !project.addAllAnalog" class="text-center">
                Součástí žádosti nejsou data o parcelách v analogové podobě.
            </div>
        </div>
    </div>
</ng-template>

<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>{{title}}</h1>
                <div class="header-subtitle">{{subtitle}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 offset-lg-3 col-lg-6">
                <div class="row">
                    <div class="col-12">
                        <fieldset *ngIf="project" class="no-legend project-info">
                            <div class="column-100">
                                <label>Název žádosti</label>
                                <div class="pt-7">{{ project.name }}</div>
                            </div>
                            <div class="column-100">
                                <label>Firma</label>
                                <div class="pt-7">{{ (project.prijemce.company || project.company) | customerName }}</div>
                            </div>
                            <div class="column-100">
                                <label>Označení (číslo) smlouvy</label>
                                <div class="pt-7">{{ project.identifikatorSmlouvy }}</div>
                            </div>
                            <div class="column-100">
                                <label>Předmět smlouvy</label>
                                <div class="pt-7">{{ project.predmetSmlouvy }}</div>
                            </div>
                            <div class="column-100">
                                <label>Smlouva uzavřená dne</label>
                                <div class="pt-7">{{ project.datumUzavreniSmlouvy | date}}</div>
                            </div>
                        </fieldset>
                        <div class="jumbotron" *ngIf="message">
                            <div class="text-center" *ngIf="loading || (!loading && status !== STATUSES.FAILED); else failedMessage">{{message}}</div>
                            <ng-template #failedMessage>
                                <div class="text-center">Zpracování žádosti se nezdařilo.</div>
                                <div class="bordered bordered-error p-20 mt-10">
                                    <div *ngIf="isGeometryFail">
                                        <div>Ve vstupním souboru je následující chyba:</div>
                                        <div class="error-message mt-10">{{message}}</div>
                                        <div class="mt-10"><strong>Vaši žádost nelze zpracovat, opravte zájmové území. Postup k přípravě vstupní geometrie naleznete v nápovědě.</strong></div>
                                    </div>
                                    <div *ngIf="!isGeometryFail">
                                        <div class="error-message">{{message}}</div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div [loading]="loading"></div>
                    </div>

                    <ng-container *ngIf="loading">
                        <ng-container *ngTemplateOutlet="backButtons"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <ng-container *ngIf="status === STATUSES.FAILED; else notFailed">
                            <div class="col-12 text-center mt-20">
                                <gmt-button [clickAction]="delete" class="red">Zrušit žádost</gmt-button>
                                <gmt-button [clickAction]="cancel">Zpět na seznam projektů</gmt-button>
                                <gmt-button [clickAction]="downloadOriginal"><i class="fa fa-file-archive-o"></i> Stažení zadaného území</gmt-button>
                            </div>
                        </ng-container>

                        <ng-template #notFailed>
                            <div class="col-12 mt-20">
                                <div class="column-100">
                                    <label class="w-250">Počet katastrálních území</label>
                                    <div class="info">
                                        <span>
                                            {{cadastralAreas.length}}
                                        </span>
                                    </div>
                                    <label class="w-250">Počet parcel</label>
                                    <div class="info">
                                        <span>
                                            {{price.landCount}}
                                        </span>
                                    </div>
                                    <label class="w-250">Počet LV</label>
                                    <div class="info">
                                        <span>
                                            {{price.lvCount}}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="isAdditionalOwnershipFail">
                                <div class="col-12 mt-10">
                                    <div class="jumbotron text-center" style="margin-bottom: 0;">
                                      <p class="text-center"><strong>V žádosti jsou LV s přídatným spoluvlastnictvím.</strong></p>
                                      <gmt-button *ngIf="additionalOwnershipTitles === undefined" [clickAction]="onAdditionalOwnershipTitilesLoad">Zobrazit problémová LV</gmt-button>
                                      <ng-container *ngIf="additionalOwnershipTitles !== undefined">
                                        <div *ngFor="let title of additionalOwnershipTitles" class="mt-5 text-center">
                                          LV: {{ title.lv }}, k.ú.: {{ title.katuzeNazev }}
                                        </div>
                                      </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="status === STATUSES.WAITING_FOR_PRICE_CONFIRMATION">
                                <div class="col-12">
                                    <div class="column-100">
                                        <label class="w-250">Předpokládaná cena dat</label>
                                        <div class="info">
                                            <span>
                                                {{price.price | currency:",-":"symbol":"1.0"}}
                                            </span>
                                        </div>
                                        <label class="w-250 required" (click)="priceConfirmation = !priceConfirmation">Souhlasím s částkou za data KN</label>
                                        <div class="info">
                                            <gmt-checkbox [gmtChecked]="priceConfirmation" (click)="priceConfirmation = !priceConfirmation"></gmt-checkbox>
                                        </div>
                                        <label class="w-250">
                                            <i>Výsledná částka dat se může lišit z důvodu jiné časové platnosti aktuálně dostupných dat SGI a nově získaných dat ISKN.</i>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 pt-20" *ngIf="project.hasAnalogParcels && !project.addAllAnalog && !project.analogParcelsCsvAttached">
                                    <p class="bordered bordered-error error-message p-20">
                                        V zadaném zájmovém území se nachazí katastrální mapy vedené analogovým způsobem. Pokud požadujete katastrální data i z těchto míst,
                                        je nutné vytvořit žádost novou a získání analogových parcel specifikovat v prvním kroku.
                                    </p>
                                </div>

                                <ng-container *ngTemplateOutlet="analogData; context: { project: project }"></ng-container>

                                <div class="col-12 mt-20 text-center">
                                    <gmt-button [clickAction]="delete" class="red">Zrušit žádost</gmt-button>
                                    <gmt-button [clickAction]="cancel">Uložit stav, odeslat později</gmt-button>
                                    <gmt-button [clickAction]="downloadOriginal"><i class="fa fa-file-archive-o"></i> Stažení zadaného území</gmt-button>
                                    <gmt-button [clickAction]="confirmPrice" [gmtDisabled]="!priceConfirmation">Odeslat žádost</gmt-button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="status === STATUSES.PRICE_CONFIRMED || status === STATUSES.CUZK_DATA_WAITING">
                                <ng-container *ngTemplateOutlet="analogData; context: { project: project }"></ng-container>
                                <ng-container *ngTemplateOutlet="backButtons"></ng-container>
                            </ng-container>

                            <ng-container *ngIf="status === STATUSES.FINISHED">
                                <ng-container *ngTemplateOutlet="analogData; context: { project: project }"></ng-container>

                                <div class="col-12 mt-20 text-center">
                                    <fieldset class="bordered">
                                        <legend>Stažení katastrálních dat</legend>
                                        <div class="text-center">
                                            <gmt-button [clickAction]="downloadVfk"><i class="fa fa-file-archive-o"></i> VFK</gmt-button>
                                            <gmt-button [clickAction]="downloadExcelSheets"><i class="fa fa-file-excel-o"></i> EXCEL - tabulky KN</gmt-button>
                                            <gmt-button [clickAction]="downloadZip"><i class="fa fa-file-archive-o"></i> CSV - tabulky KN</gmt-button>
                                            <gmt-button [clickAction]="downloadShp"><i class="fa fa-file-archive-o"></i> geometrie území SHP</gmt-button>
                                            <gmt-button [clickAction]="downloadExcelReport"><i class="fa fa-file-archive-o"></i> Sestavy z dat KN</gmt-button>
                                        </div>
                                    </fieldset>
                                </div>

                                <ng-container *ngTemplateOutlet="backButtons"></ng-container>
                            </ng-container>

                            <div class="col-12 mt-20" *ngIf="cadastralAreas.length">
                                <fieldset>
                                    <legend>Katastrální mapy ze zájmového území ke stažení</legend>
                                    <div *ngFor="let area of cadastralAreas" class="mt-5">
                                        <a class="underlined" href="//services.cuzk.cz/dgn/ku/{{area.code}}.zip" target="_blank">k.ú. {{area.name}}</a>
                                    </div>
                                </fieldset>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <help [helpId]="helpIds.PK_CREATE_THIRD_STEP" class="cm-help-page"></help>
</div>
