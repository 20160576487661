﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-signed-send-owners',
	templateUrl: './form-case-signed-send-owners.component.html',
	styleUrls: ['./form-case-signed-send-owners.component.scss']
})
export class FormCaseSignedSendOwnersComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.signedSentOwnersDate = this.data.signedSentOwnersDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.signedSentOwnersDate;
  }
}
