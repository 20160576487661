<div *ngIf="data.operation === 'u'" class="jumbotron mt-20 flexbox-container flex-centered flex-column">
  <div class="mb-10">Je vybráno <strong>{{ data.projects.length }}</strong> {{ 'ps.projectsAccusative' | brandTranslate }}</div>

  <div *ngIf="data.removePermissions" class="mb-10">U všech budou pro uživatele <strong>{{ data.user | username }}</strong> odebrána oprávnění</div>

  <div *ngIf="!data.removePermissions">
    <div *ngIf="data.role" class="mb-10">
      U všech {{ 'ps.projectsAccusative' | brandTranslate }} bude uživateli <strong>{{ data.user | username }}</strong> nastavena role <strong>{{ data.role | roleName }}</strong>
    </div>

    <div *ngIf="data.permissions?.length" class="mb-10">
      U všech {{ 'ps.projectsAccusative' | brandTranslate }} budou uživateli <strong>{{ data.user | username }}</strong> nastaveny náseledující <strong>oprávnění</strong>:

      <ul>
        <li *ngFor="let permission of data.permissions">{{ permission.value }}</li>
      </ul>
    </div>

    <div class="column-100 mb-10">
      <gmt-input *ngIf="data.projects.length !== data.common.projects.length" label="Odebrat oprávnění u zbývajících {{ data.common.projects.length - data.projects.length }} {{ 'ps.projectsAccusative' | brandTranslate }}?">
        <gmt-checkbox [gmtChecked]="clearRestProjects" (click)="clearRestProjects = !clearRestProjects"></gmt-checkbox>
      </gmt-input>
    </div>
  </div>

  <div *ngIf="uploading" class="mb-20">
    <div [loading]="uploading"></div>
    Nastaveno: {{ progress }} {{ 'ps.projectsAccusative' | brandTranslate }}.
  </div>
</div>

<div *ngIf="data.operation === 'p'" class="jumbotron mt-20 flexbox-container flex-centered flex-column">
  <div class="mb-10">Je vybráno <strong>{{ data.users.length }}</strong> uživatelů</div>

  <div *ngIf="data.removePermissions" class="mb-10">Všem budou pro {{ 'ps.projectAccusative' | brandTranslate }} <strong>{{ data.project.name }}</strong> odebrána oprávnění</div>

  <div *ngIf="!data.removePermissions">
    <div *ngIf="data.role" class="mb-10">
      Všem uživatelům bude pro {{ 'ps.projectAccusative' | brandTranslate }} <strong>{{ data.project.name }}</strong> nastavena role <strong>{{ data.role | roleName }}</strong>
    </div>

    <div *ngIf="data.permissions?.length" class="mb-10">
      Všem uživatelům budou pro {{ 'ps.projectAccusative' | brandTranslate }} <strong>{{ data.project.name }}</strong> nastaveny náseledující <strong>oprávnění</strong>:

      <ul>
        <li *ngFor="let permission of data.permissions">{{ permission.value }}</li>
      </ul>
    </div>
  </div>
</div>

<gmt-button [clickAction]="onSave">Potvrdit</gmt-button>
