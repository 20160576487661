export enum CountTypeEnum {
  TITLE = 'TITLE',
  OWNERSHIP = 'OWNERSHIP',
  CONSTRUCTION_OBJECT = 'CONSTRUCTION_OBJECT',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export enum TypeEnum {
  EASEMENT_GEOMETRIC_PLAN = 'EASEMENT_GEOMETRIC_PLAN',
  EASEMENT_LAND_TAKE = 'EASEMENT_LAND_TAKE',
  PERMANENT = 'PERMANENT',
  UNDER_ONE_YEAR = 'UNDER_ONE_YEAR',
  OVER_ONE_YEAR = 'OVER_ONE_YEAR',
}

export class StatisticsObjectTypeModel {
  countType: CountTypeEnum[];
  type: TypeEnum[];
  occupationTypes: number[];
}
