import { Injectable, Inject } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ListService } from '@app/common/services/list.service';
import { CaseService } from '@app/ps/services/case.service';
import * as _ from 'lodash';
import { DialogService } from '@app/common/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class TitleDetailCreateCaseSelectParentService {

  constructor(
    private dialogService: DialogService,
    private listService: ListService,
    private caseService: CaseService
  ) {
    this.getOwnerships = this.getOwnerships.bind(this);
    this.createOwnershipWithParent = this.createOwnershipWithParent.bind(this);
    this.checkOwnershipsWithParent = this.checkOwnershipsWithParent.bind(this);
    this.changedParent = this.changedParent.bind(this);
    this.ownershipsPossibleParents = this.ownershipsPossibleParents.bind(this);
    this.filterOwnershipsWithoutParent = this.filterOwnershipsWithoutParent.bind(this);
  }

  canBecomeParent(opsubType) {
    return opsubType === 'OPO';
  }

  getOwnerships(titleId) {
    const ownerships = this.listService
      .createList('ownerships', {
          limit: null,
          filters: {
            validity: 'valid',
            titleId: titleId,
          }},
        undefined,
        { parentOwnership: { load: true }}
      );
    return this.listService.fetchResult(ownerships).then((data) => data.list.filter(o => !o.ignoreType));
  }

  createOwnershipWithParent(caseOwnership, caseOwnerships, ownershipsWithParent, caseOwnershipsDefault) {
    return this.getOwnerships(caseOwnership.ownership.title.id).then((ownerships) => {
      let possibleParents = [];

      // Try to find parents by parent id
      if (caseOwnership.ownership.parentOwnership) {
        possibleParents = ownerships.filter(ownership => ownership.id === caseOwnership.ownership.parentOwnership.id);
      }

      // Try to find parents by type
      if (possibleParents.length === 0) {
        possibleParents = ownerships
          .filter(ownership => {
            return ownership.id !== caseOwnership.ownership.id && this.canBecomeParent(ownership.subjects[0].opsubType);
          });
      }

      const options = possibleParents.map(ownership => { return { ownership: ownership }; });

      const ownershipWithParentExt = {
        caseOwnership: caseOwnership,
        caseOwnershipsDefault: caseOwnershipsDefault,
        optionItems: options,
      };
      if (ownershipWithParentExt.optionItems.length === 1) {
        caseOwnership.parentCaseOwnership = ownershipWithParentExt.optionItems[0];
      }
      if (caseOwnership.parentCaseOwnership) {
        const promise = this.changedParent(caseOwnership.parentCaseOwnership, null, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
        if (promise) {
          return promise.then(() => ownershipWithParentExt);
        }
      }
      return ownershipWithParentExt;
    });
  }

  checkOwnershipsWithParent(caseOwnerships) {
    const ownershipsWithParent = [];
    const ownershipsWithoutParent = _.filter(caseOwnerships,
      (caseOwnership) => caseOwnership.ownership.typeNeedsParent);

    const promises = [];
    const caseOwnershipsDefault = _.clone(caseOwnerships);

    for (const owp of ownershipsWithoutParent) {
      if (owp.parentCaseOwnership === undefined) {
        owp.parentCaseOwnership = null;
      }
      promises.push(
        this.createOwnershipWithParent(owp, caseOwnerships, ownershipsWithParent, caseOwnershipsDefault)
          .then((owp2) => {
            ownershipsWithParent.push(owp2);
          })
      );
    }
    return Promise.all(promises).then(() => ownershipsWithParent);
  }

  changedParent(newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
    if (oldValue) {
      // if is not selected anywhere, then ownership is not considered parent anymore
      if (!_.some(ownershipsWithParent, (item) => item.parentCaseOwnership &&
        item.parentCaseOwnership.ownership.id === oldValue.ownership.id) &&
        _.some(ownershipWithParentExt.caseOwnershipsDefault, {ownership: {id: oldValue.ownership.id}})
      ) {
        _.filter(caseOwnerships, {ownership: {id: oldValue.ownership.id}}).forEach(caseOwnership => caseOwnership.isParent = false);
      }

      // caseSubjects that no longer need to have parent selected
      if (!_.some(ownershipsWithParent, (item) => item.caseOwnership.parentCaseOwnership &&
        item.caseOwnership.parentCaseOwnership.ownership.id === oldValue.ownership.id) &&
        !_.some(ownershipWithParentExt.caseOwnershipsDefault, {ownership: {id: oldValue.ownership.id}})) {
        _.remove(ownershipsWithParent, (item: any) => item.caseOwnership === oldValue);

        if (oldValue.parentCaseOwnership) {
          const tmpOldValue = oldValue.parentCaseOwnership;
          oldValue.parentCaseOwnership = null;
          this.changedParent(null, tmpOldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
        }
      }
    }

    if (newValue) {
      if (this.caseService.checkParentCaseOwnershipCycle(newValue, ownershipsWithParent)) {
        ownershipWithParentExt.caseOwnership.parentCaseOwnership = null;
        this.changedParent(null, newValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
        this.dialogService.alertDialogPromise('Zjištěn cyklický vztah v zadání nadřazených subjektů!');
        newValue = null;
      }

      if (newValue) {
        _.filter(caseOwnerships, {ownership: {id: newValue.ownership.id}}).forEach(caseOwnership => caseOwnership.isParent = true);
        const ownershipWithParent = _.find(ownershipsWithParent, (item) => item.caseOwnership.ownership.id === newValue.ownership.id);
        if (newValue.ownership.typeNeedsParent &&
          !ownershipWithParent) {
          return this.createOwnershipWithParent(newValue, caseOwnerships, ownershipsWithParent, ownershipWithParentExt.caseOwnershipsDefault).then((ownershipWithParentExt) => {
            ownershipsWithParent.push(ownershipWithParentExt);
          });
        } else if (ownershipWithParent) {
          ownershipWithParentExt.caseOwnership.parentCaseOwnership = ownershipWithParent.caseOwnership;
        }
      }
    }
  }

  ownershipsPossibleParents(caseOwnerships) {
    return _.filter(caseOwnerships, (caseOwnership) => this.canBecomeParent(caseOwnership.ownership.subjects[0].opsubType) && !caseOwnership.ownership.typeNeedsParent);
  }

  filterOwnershipsWithoutParent(caseOwnershipsSelected, obligationType) {
    if (!caseOwnershipsSelected[0].ownership.title) {
      return Promise.resolve([]);
    }

    return this.getOwnerships(caseOwnershipsSelected[0].ownership.title.id /*, obligationType @TODO service A7 */).then((ownerships) => {
      const caseOwnerships = _.map(ownerships, (ownership) => {
        return {ownership: ownership};
      });
      return _.filter(caseOwnerships,
        // needsParent means that ownership type is owned by another subject
        (caseOwnership) => caseOwnership.ownership.typeNeedsParent &&
          // parent will be selected
          !_.some(caseOwnershipsSelected, (caseOwnershipSelected) => caseOwnershipSelected.ownership.id === caseOwnership.ownership.id)
      );
    });
  }

  needsParentSelection(ownershipsWithParent) {
    return _.some(ownershipsWithParent, (ownershipWithParent) => ownershipWithParent.caseOwnership.ownership.typeNeedsParent && !ownershipWithParent.caseOwnership.parentCaseOwnership);
  }
}
