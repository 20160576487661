<div class="jumbotron text-center">
  U <strong>{{ data.occupations.length }}</strong> zábor{{ data.occupations.length === 1 ? 'u' : 'ů' }} bude nastavena cena. Potvrďte provedení operace.
</div>

<fieldset>
  <div class="column-100">
    <label>Délka nájmu [<span class="unit">měsíce</span>]</label>
    <div class="info">{{ data.rentalPrice.rentalLength }}</div>
  </div>

  <div class="column-100">
    <label>Způsob určení ceny</label>
    <div class="info">{{ rentalPriceTypeTxt }}</div>

    <ng-container  *ngIf="data.rentalPrice.rentalPriceType === 'F'">
      <label>Rok cenového výměru MF</label>
      <div class="info">{{ data.rentalPrice.priceAssessmentYear }}</div>
    </ng-container>

    <ng-container  *ngIf="data.rentalPrice.rentalPriceType === 'E'">
      <label>Číslo znaleckého posudku</label>
      <div class="info">{{ data.rentalPrice.rentalExpertOpinionNumber }}</div>

      <label>Datum znaleckého posudku</label>
      <div class="info">{{ data.rentalPrice.rentalExpertOpinionDate | date }}</div>
    </ng-container>

    <ng-container  *ngIf="data.rentalPrice.rentalPriceType === 'O'">
      <label>Původ ceny</label>
      <div class="info">{{ data.rentalPrice.rentalOtherPriceSource }}</div>

      <label>Způsob výpočtu ceny</label>
      <div class="info">{{ rentalPriceUnitTxt }}</div>
    </ng-container>

    <ng-container  *ngIf="data.rentalPrice.rentalPriceType === 'O' && data.rentalPrice.rentalPriceUnit === 'T'">
      <label>Cena za pozemek [<span class="unit">Kč</span>]</label>
      <div class="info">{{ data.rentalPrice.totalRentPrice }}</div>
    </ng-container>

    <ng-container *ngIf="data.rentalPrice.rentalPriceType !== 'O' || data.rentalPrice.rentalPriceUnit !== 'T'">
      <label>Cena za pozemek [<span class="unit">Kč/m<sup>2</sup>/rok</span>]</label>
      <div class="info">{{ data.rentalPrice.priceAssessmentLandPrice }}</div>
    </ng-container>
  </div>
</fieldset>

<div class="mt-10">
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid">Uložit</gmt-button>
</div>
