import { FileUtils } from '@app/common/utils/file.utils';

export class FileValidatorUtils {

  static readonly SHP_FILES: string[] = ['shp', 'shx', 'dbf', 'prj'];
  static readonly SINGLE_FILES: string[] = ['vfk', 'dxf'];
  static readonly SUPPORTED_FILES: string[] = [...FileValidatorUtils.SHP_FILES, ...FileValidatorUtils.SINGLE_FILES];

  static readonly MSG_VALID_VFK = `Vložen soubor vfk, pokračujte nahráním.`;
  static readonly MSG_VALID_DXF = `Vložen soubor dxf, pokračujte nahráním.`;
  static readonly MSG_VALID_SHP = `Vložen soubor shapefile, pokračujte nahráním.`;
  static readonly MSG_VALID_ADD_NEXT = `Vložte další soubory.`;
  static readonly MSG_INVALID = `Vložené soubory nejsou podporované. Vložte pouze soubory ve formátu VFK, DXF nebo SHP.`;
  static readonly MSG_INVALID_COUNT = `Je možné vložit pouze jeden soubor dxf, vfk nebo shapefile.`;
  static readonly MSG_INVALID_UNCOMPLEATE_SHP = `Nejsou vloženy všechny potřebné shapefile soubory [${FileValidatorUtils.SHP_FILES.join(', ')}].`;

  public static checkValidity(files: any[]) {
    let valid = FileValidatorUtils.checkFiles(files);

    if (valid) {
      const firstExtension = files[0].extension.toLowerCase();
      if (FileValidatorUtils.SINGLE_FILES.indexOf(firstExtension) !== -1) {
        valid = files.length === 1;
      } else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1) {
        valid = FileValidatorUtils.checkSHPValidity(files);
      }
    }
    return valid;
  }

  public static checkSHPValidity(files: any[]) {
    let valid = (files.length === FileValidatorUtils.SHP_FILES.length);
    if (valid) {
      const extensions = files.map(f => f.extension.toLowerCase());
      const diff = FileValidatorUtils.SHP_FILES.filter(value => extensions.indexOf(value) === -1);
      valid = (diff.length === 0 && FileUtils.hasFilesSameTitles(files));
    }
    return valid;
  }

  public static getInvalidMessage(files: any[]) {
    const valid = FileValidatorUtils.checkFiles(files);
    let msg = '';

    if (valid) {
      const firstExtension = files[0].extension.toLowerCase();
      if (FileValidatorUtils.SINGLE_FILES.indexOf(firstExtension) !== -1) {
        msg = FileValidatorUtils.MSG_INVALID_COUNT;
      } else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1 && FileUtils.hasFilesSameTitles(files)) {
        msg = FileValidatorUtils.MSG_INVALID_UNCOMPLEATE_SHP;
      } else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1 && !FileUtils.hasFilesSameTitles(files)) {
        msg = FileValidatorUtils.MSG_INVALID_COUNT;
      }
    } else {
      msg = FileValidatorUtils.MSG_INVALID;
    }
    return msg;
  }

  public static getValidMessage(files: any[]) {
    if (files.every(f => f.extension.toLowerCase() === 'vfk')) {
      return FileValidatorUtils.MSG_VALID_VFK;
    } else if (files.every(f => f.extension.toLowerCase() === 'dxf')) {
      return FileValidatorUtils.MSG_VALID_DXF;
    } else if (FileValidatorUtils.checkSHPValidity(files)) {
      return FileValidatorUtils.MSG_VALID_SHP;
    } else {
      return FileValidatorUtils.MSG_VALID_ADD_NEXT;
    }
  };

  private static checkFiles(files = []) {
    return files.length !== 0 && files.every(file => FileValidatorUtils.SUPPORTED_FILES.indexOf(file.extension.toLowerCase()) !== -1);
  }
}
