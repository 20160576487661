import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SubjectModel } from '@app/common/models/subject.model';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';

@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit {
  @Input() contactInfo: SubjectModel | CaseSubjectModel;
  @Input() editable = true;
  @Input() editableToggle = false;

  ngOnInit() { }
}
