import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { OpinionModel } from '@app/ps/models/opinion.model';

@Component({
  selector: '[pricing-other-source-row]',
  templateUrl: './pricing-other-source-row.component.html',
  styleUrls: ['pricing-other-source-row.component.scss']
})
export class PricingOtherSourceRowComponent implements OnInit {

  @Input() parcelPrice: ParcelPriceModel;
  @Input() parcel: ParcelModel;

  @Input() selectable: boolean;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() showParcel: boolean;
  @Input() opinion: OpinionModel;
  @Output() changed = new EventEmitter();

  constructor(
    private numericPipe: NumericPipe,
  ) {
    this.onChange = this.onChange.bind(this);
  }

  ngOnInit() {
  }

  computeParcelPrice() {
    const landPrice = this.parcelPrice.otherLandPrice && this.opinion.occupationType === 'P'
      ? this.numericPipe.transform(this.parcelPrice.otherLandPrice)
      : 0;
    const vegetationPrice = this.parcelPrice.otherVegetationPrice
      ? this.numericPipe.transform(this.parcelPrice.otherVegetationPrice)
      : 0;
    const compensationPrice = this.parcelPrice.otherCompensationPrice
      ? this.numericPipe.transform(this.parcelPrice.otherCompensationPrice)
      : 0;
    const buildingPrice = this.parcelPrice.otherBuildingsPrice && this.opinion.occupationType === 'P'
      ? this.numericPipe.transform(this.parcelPrice.otherBuildingsPrice)
      : 0;
    return landPrice + vegetationPrice + compensationPrice + buildingPrice;
  }

  onChange() {
    this.changed.emit();
  }
}
