import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import * as _ from 'lodash';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { CaseModel } from '@app/ps/models/case.model';
import { StateService } from '@uirouter/angular';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'change-case-status',
  templateUrl: './change-case-status.component.html',
  styleUrls: ['./change-case-status.component.scss']
})
export class ChangeCaseStatusComponent implements OnInit {

  @Input() case: CaseModel;
  @Input() actionButtons = [];
  @Input() previousState;
  @Output() back = new EventEmitter();
  opened = false;

  constructor(
    private restangular: Restangular,
    private caseService: CaseService,
    private caseStatusService: CaseStatusService,
    private stateService: StateService,
    private eRef: ElementRef
  ) {
  }

  ngOnInit() {

  }

  toggle() {
    this.opened = !this.opened;
  }

  onBack() {
    this.back.emit();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }
}
