import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ExpertModel } from '@app/ps/models/expert.model';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { ExpertEditFormComponent } from '@app/ps/components/expert-edit-form/expert-edit-form.component';

@Component({
  selector: 'expert-select-form',
  templateUrl: './expert-select-form.component.html',
  styleUrls: ['expert-select-form.component.scss']
})
export class ExpertSelectFormComponent implements OnInit {

  @Input() expert: ExpertModel;
  @Output() changed = new EventEmitter<ExpertModel>();
  reloadExperts: Function;

  constructor(
    public subjectNamePipe: SubjectNamePipe,
    private dialogService: DialogService,
  ) {
    this.onAddExpert = this.onAddExpert.bind(this);
    this.onUpdateExpert = this.onUpdateExpert.bind(this);
    this.onChange = this.onChange.bind(this);
    this.expertsReloadRegister = this.expertsReloadRegister.bind(this);
  }

  ngOnInit() {
  }

  expertsReloadRegister(reloadFn: Function) {
    this.reloadExperts = reloadFn;
  }

  onChange() {
    this.changed.emit(this.expert);
  }

  onAddExpert() {
    this.showDialog();
  }

  onUpdateExpert() {
    this.showDialog(this.expert);
  }

  private showDialog(expert: ExpertModel = null) {
    const dialog = this.dialogService.open(ExpertEditFormComponent, {
      className: ClassName.HIGHER_DIALOG,
      data: { expert }
    });
    const subscriber = dialog.afterClosed.subscribe((expert: ExpertModel) => {
      if (expert) {
        this.expert = expert;
        this.reloadExperts();
        this.changed.emit(this.expert);
      }
      subscriber.unsubscribe();
    });
  }
}
