import { CaseStatusModel } from './case-status.model';
import { CaseSubjectModel } from './case-subject.model';
import { TitleModel } from '@app/common/models/title.model';
import { User } from '@app/models/user';
import { NoteModel } from '@app/common/models/note.model';

export class CaseModel {
  activeRental: boolean;
  approvedInvestorDate?: Date;
  bonusDate: Date;
  bonusGranted: boolean;
  bonusPossible: boolean;
  buildings: [];
  cadastreEntryDate?: Date;
  cadastreEntryDeclinedDate?: Date;
  cadastreEntryDeliveryDate?: Date;
  cadastreEntryNumber?: any;
  cadastreEntryStoppedDate?: Date;
  cadastreValid: boolean;
  caseBuildings: any[];
  caseOwnerships: any[];
  caseParcels: [];
  caseStatus: CaseStatusModel;
  caseSubjects: CaseSubjectModel[];
  caseTransferErrors: [];
  checkCadastre: boolean;
  constructionObjects: [];
  contractRegisterPublicationContractId?: number;
  contractRegisterPublicationDate?: Date;
  contractRegisterPublicationException?: string;
  contractRegisterPublicationNecessary?: boolean;
  contractRegisterPublicationVersionId?: number;
  countOwnerships: number;
  createdDate: string;
  declinedInvestorDate?: Date;
  deliveryDeadlineDate?: Date;
  distributionSystemParts?: any;
  dueDate?: Date | string;
  easements: [];
  entryProposalDate?: Date;
  expropriationAmount?: any;
  expropriationAmountSource?: any;
  expropriationAppealDate?: Date;
  expropriationAppealDeadlineDate?: Date;
  expropriationAppealReason?: any;
  expropriationAppealResultArbitrator?: any;
  expropriationAppealResultDate?: Date;
  expropriationAppealResultReason?: any;
  expropriationAppealSide?: any;
  expropriationAssessmentInteruptionDate?: Date;
  expropriationAssessmentInteruptionDeadlineDate?: Date;
  expropriationAssessmentInteruptionReason?: any;
  expropriationCalledHearingCalledDate?: Date;
  expropriationCalledHearingDate?: Date;
  expropriationEnforceableDate?: Date;
  expropriationHearingRepetitionCalledDate?: Date;
  expropriationHearingRepetitionDate?: Date;
  expropriationHearingRepetitionReason?: any;
  expropriationProceedingInteruptionDate?: Date;
  expropriationProceedingInteruptionDeadlineDate?: Date;
  expropriationProceedingInteruptionExtendedDate?: Date;
  expropriationProceedingInteruptionExtendedDeadlineDate?: Date;
  expropriationProceedingInteruptionExtendedReason?: any;
  expropriationProceedingInteruptionReason?: any;
  expropriationProceedingStartedDate?: Date;
  expropriationProposalDate?: Date;
  expropriationResultDate?: Date;
  expropriationResultDeliveryDate?: Date;
  expropriationResultRealizationDeadlineDate?: Date;
  expropriationStoppedBySide?: any;
  expropriationStoppedDate?: Date;
  expropriationStoppedReason?: any;
  id: number;
  inMpp: boolean;
  inputValid: boolean;
  isOwnerInvestor?: boolean;
  legalEffectsDate?: Date;
  mandatary: User;
  officer: User;
  mpp: any;
  networkOwnerContractNumber?: any;
  networkOwnerContractSignedDate?: Date;
  obligation: any;
  occupations: [];
  parcels: [];
  paymentInstructionDate?: Date;
  paymentOrderDate?: Date;
  price: number;
  sentAdministratorDate?: Date;
  sentInvestorDate?: Date;
  signedAdministratorDate?: Date;
  signedInvestorDate?: Date;
  sentAllOwnersDate?: Date;
  signedSentOwnersDate?: Date;
  siteDeliveryDate?: Date;
  statusRespectiveDate: string;
  tenantNames?: string[];
  timestampUpdatedStatus: string;
  titles?: TitleModel[];
  validityDate?: Date;
  meetingRequestDate?: Date;
  acceptedGeometricalPlanPossibleDate?: Date;
  responseReceivedDate?: Date;
  expropriationInterimDecisionRequestDate?: Date;
  expropriationInterimDecisionDate?: Date;
  expropriationAdministrativeAppealDate?: Date;
  expropriationAdministrativeAppealRejectedDate?: Date;
  expropriationAdministrativeAppealAcceptedDate?: Date;
  expropriationCompensationPaymentDate?: Date;
  expropriationStartDeadlineDate?: Date;
  financialControlDate?: Date;
  maxAuctionAmount?: number;
  sentOwnerDate?: Date;
  entryProposalLink?: any;
  caseOccupations?: any;
  caseEasements?: any;
  parcelsExternal?: any;
  rentalStartConditions?: any;
  rentalStartDate?: any;
  rentalPaymentNotificationDate?: any;
  innominationSubject?: any;
  innominationPrice?: number;
  fromCase?: CaseModel;
  ssEU?: boolean;
  ssNonEU?: boolean;
  ssRuskyVliv?: boolean;
  textId?: string;
  note?: NoteModel;
  fulfillmentPrice?: number;
}
