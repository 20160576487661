import { Component, Inject, Input, OnInit } from '@angular/core';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ParcelModel } from '@app/common/models/parcel.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'parcel-tenant-edit-form',
  templateUrl: './parcel-tenant-edit-form.component.html',
  styleUrls: []
})
export class ParcelTenantEditFormComponent implements OnInit {

  @Input() parcel: ParcelModel;
  rentalTypeOption: SwitchOption;
  opsubTypeOption: SwitchOption;

  rentalTypes: SwitchOption[] = [
    {
      id: '',
      value: 'Bez nájmu',
    },
    {
      id: 'L',
      value: 'Pronájem',
    },
    {
      id: 'T',
      value: 'Pacht',
    },
  ];

  opsubTypes: SwitchOption[] = [
    {
      id: 'OPO',
      value: 'Právnická osoba',
    },
    {
      id: 'OFO',
      value: 'Fyzická osoba',
    },
  ];

  constructor(
    private restangular: Restangular,
  ) {
    this.isValid = this.isValid.bind(this);
    this.update = this.update.bind(this);
  }

  ngOnInit() {
    this.rentalTypeOption = this.rentalTypes
      .find(option => option.id === this.parcel.parcelDetail.rentalType);

    this.opsubTypeOption = this.opsubTypes
      .find(option => option.id === this.parcel.parcelDetail.tenantOpsubType);
  }

  isValid() {
    const isOPOValid = this.parcel.parcelDetail.rentalType !== '' && this.parcel.parcelDetail.tenantOpsubType === 'OPO' && this.parcel.parcelDetail.tenantNazev;
    const isOFOValid = this.parcel.parcelDetail.rentalType !== '' && this.parcel.parcelDetail.tenantOpsubType === 'OFO' && this.parcel.parcelDetail.tenantPrijmeni;
    const isNotRented = this.parcel.parcelDetail.rentalType === '';
    return isOPOValid || isOFOValid || isNotRented;
  }

  update() {
    return this.restangular
      .one('parcels', this.parcel.id)
      .customPUT(this.parcel)
      .toPromise();
  }

  onSelectRentalTypeOption(option: SwitchOption) {
    this.rentalTypeOption = option;
    this.parcel.parcelDetail.rentalType = <any>option.id;
  }

  onSelectOpsubTypeOption(option: SwitchOption) {
    this.opsubTypeOption = option;
    this.parcel.parcelDetail.tenantOpsubType = <any>option.id;
  }
}
