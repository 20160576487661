import { Inject, Injectable } from '@angular/core';
import { ApproveTermsComponent } from '@app/common/components/approve-terms/approve-terms.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ModulesService } from '@app/common/services/modules.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Injectable({ providedIn: 'root' })
export class ApproveTermsService {

  constructor(
    private modulesService: ModulesService,
    private dialogService: DialogService,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
  }

  checkApprovedTerms(userId, module): Promise<any> {
    return this.modulesService.getUserApprovedTerms(userId)
      .then((approvedTerms) => {
        if (this.APP_BRAND.NAME !== 'RSD' && this.APP_BRAND.NAME !== 'SZ' && !approvedTerms.includes(module)) {
          return new Promise(resolve => {
            const dialog = this.dialogService.open(ApproveTermsComponent, { data: { module: module }});
            const sub = dialog.afterClosed.subscribe((res) => {
              sub.unsubscribe();
              if (res) {
                this.modulesService.approveTerms(userId, module);
                resolve();
              } else {
                return this.checkApprovedTerms(userId, module);
              }
            });
          });
        }

        return Promise.resolve({});
      });
  }

}
