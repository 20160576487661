<div [loading]="loading">
  <ng-container *ngIf="!loading">
    <ul *ngIf="data.list.list">
      <li *ngFor="let parcel of data.list.list">
        <a uiSref="dimap.project.parcels.detail" [uiParams]="{id: parcel.id}">{{ parcel|parcelnumber }}</a>
      </li>
    </ul>

    <div *ngIf="data.list.list.length === 0">Žádné parcely</div>

    <div *ngIf="data.list.itemCount > data.list.list.length">
      <gmt-button [clickAction]="loadNext">Načíst další</gmt-button>
    </div>
  </ng-container>
</div>



