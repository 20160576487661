import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[gmtAuthNot]'
})
export class AuthNotDirective implements OnInit {
  @Input('gmtAuthNot') gmtAuth: string;

  constructor(
      private elementRef: ElementRef,
      private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.authService.hasPermission(this.gmtAuth)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
