<div class="detail">
  <div class="mb-10">
    <a (click)="onClose()" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Zpět</a>
  </div>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <h1>{{ opinion.id ? ( readonly ? 'Náhled ' : 'Úprava ') + 'jiného' : 'Nové jiné' }} určení ceny
        <span>LV {{ title.lv }}</span>
      </h1>

      <div *ngIf="parcelPrices.length">
        <div class="bordered mt-20">
          <table>
            <thead>
            <tr>
              <td class="centered-text" (click)="checkAll()" *ngIf="!readonly">
                <gmt-checkbox gmtType="multiselect" [gmtChecked]="checkedAll"></gmt-checkbox>
              </td>
              <th>Parcela {{ occupationType === 'P' ? '/ Budova' : '' }}</th>
              <th *ngIf="opinion.occupationType === 'P'">Cena za pozemek</th>
              <th *ngIf="opinion.occupationType === 'T'">Cena náhrady</th>
              <th *ngIf="opinion.occupationType === 'P'">Cena porost<br/>(vedlejší úpravy)</th>
              <th *ngIf="opinion.occupationType === 'P'">Cena stavby</th>
              <th>Celková cena parcely</th>
            </tr>
            </thead>
            <tbody *ngIf="!readonly">
            <tr *ngFor="let parcelPrice of parcelPrices" [ngClass]="checklistParcelPrices.isChecked(parcelPrice) ? 'parcel-price-selected' : ''">
              <td class="centered-text" (click)="toggleSelection(parcelPrice)">
                <gmt-checkbox gmtType="multiselect" [gmtDisabled]="isDisabled(parcelPrice)" [gmtChecked]="checklistParcelPrices.isChecked(parcelPrice)"></gmt-checkbox>
              </td>
              <td *ngIf="parcelPrice.parcel" class="no-padding cell-max-height">
                <cell-parcel
                  [parcel]="parcelPrice.parcel"
                  [tooltipType]="'parcel'"
                  [tooltipData]="{ parcel: parcelPrice.parcel, withoutTitle: true }"
                ></cell-parcel>
              </td>
              <td *ngIf="parcelPrice.building" class="top">
                {{ parcelPrice.building | buildingNumber }}<br/>
                na parcel{{ parcelPrice.building.parcels.length > 1 ? 'ách' : 'e' }}
                <span class="comma-list"><span *ngFor="let itemParcel of parcelPrice.building.parcels">{{ itemParcel | parcelnumber }}</span></span>
              </td>
              <td *ngIf="opinion.occupationType === 'P'">
                <input *ngIf="parcelPrice.parcel" type="text" [(ngModel)]="parcelPrice.otherLandPrice" (ngModelChange)="computeTotalPrice()" gmtInputNumber />
              </td>
              <td *ngIf="opinion.occupationType === 'T'">
                <input *ngIf="parcelPrice.parcel" type="text" [(ngModel)]="parcelPrice.otherCompensationPrice" (ngModelChange)="computeTotalPrice()" gmtInputNumber />
              </td>
              <td *ngIf="opinion.occupationType === 'P'">
                <input *ngIf="parcelPrice.parcel" type="text" [(ngModel)]="parcelPrice.otherVegetationPrice" (ngModelChange)="computeTotalPrice()"  gmtInputNumber />
              </td>
              <td *ngIf="opinion.occupationType === 'P'">
                <input type="text" [(ngModel)]="parcelPrice.otherBuildingsPrice" (ngModelChange)="computeTotalPrice()"  gmtInputNumber />
              </td>
              <td class="number">{{ computeParcelPrice(parcelPrice) | number | price }}
              </td>
            </tr>
            </tbody>
            <tbody *ngIf="readonly">
            <tr *ngFor="let parcelPrice of parcelPrices">
              <td>
                   <span *ngIf="parcelPrice.parcel">
                    {{ parcelPrice.parcel | parcelnumber }}<br/>
                    {{ parcelPrice.parcel.vymera }} m<sup>2</sup>
                  </span>
                <span *ngIf="parcelPrice.building">
                    {{ parcelPrice.building | buildingNumber }}<br/>
                    na parcel{{ parcelPrice.building.parcels.length > 1 ? 'ách' : 'e' }}
                    <span class="comma-list"><span *ngFor="let itemParcel of parcelPrice.building.parcels">{{ itemParcel | parcelnumber }}</span></span>
                  </span>
              </td>
              <td *ngIf="opinion.occupationType === 'P'" class="number">
                {{ parcelPrice.otherLandPrice | number }}
              </td>
              <td *ngIf="opinion.occupationType === 'T'" class="number">
                {{ parcelPrice.otherCompensationPrice | number}}
              </td>
              <td *ngIf="opinion.occupationType === 'P'" class="number">
                {{ parcelPrice.otherVegetationPrice | number}}
              </td>
              <td *ngIf="opinion.occupationType === 'P'" class="number">
                {{ parcelPrice.otherBuildingsPrice | number}}
              </td>
              <td class="number">
                {{ ((parcelPrice.otherLandPrice ? parcelPrice.otherLandPrice : 0) * 1) + ((parcelPrice.otherCompensationPrice ? parcelPrice.otherCompensationPrice : 0) * 1) + ((parcelPrice.otherVegetationPrice ? parcelPrice.otherVegetationPrice : 0) * 1) + ((parcelPrice.otherBuildingsPrice ? parcelPrice.otherBuildingsPrice : 0) * 1) | number | price}}
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td *ngIf="!readonly">

              </td>
              <th [attr.colspan]="opinion.occupationType === 'P' ? 4 : 2" class="number">
                Celkem
              </th>
              <td class="number">
                {{ totalPrice | number | price}}
              </td>
            </tr>
            </tfoot>
          </table>
        </div>

        <fieldset>
          <legend>Jiné určení ceny</legend>
          <div class="column-100">
            <gmt-input *ngIf="!readonly" label="Typ" [required]="true">
              <gmt-switch (selectedIdChange)="togglePriceType($event)" [options]="switchTypes" [(selectedId)]="type"></gmt-switch>
            </gmt-input>
            <div class="info" *ngIf="readonly">{{ getCurrentPriceType() }}</div>
          </div>
          <div class="column-100" *ngIf="type === 'O'">
            <gmt-input *ngIf="!readonly" label="Vlastník" [required]="true">
              <gmt-select [itemPipe]="ownershipNamePipe" [optionItems]="selectOwnerships" [data]="opinion" field="otherOwnership" required="true"></gmt-select>
            </gmt-input>
            <div class="info" *ngIf="readonly">{{ opinion.otherOwnership | ownershipName }}</div>
          </div>
          <div class="column-100">
            <gmt-input *ngIf="!readonly" label="Původ ceny" [required]="true">
              <input type="text" [(ngModel)]="opinion.otherSource"/>
            </gmt-input>
            <div class="info" *ngIf="readonly">{{ opinion.otherSource }}</div>
          </div>
        </fieldset>

        <div class="mt-30 text-center">
          <gmt-button *ngIf="!readonly" [gmtDisabled]="!isFormValid()" [clickAction]="onSave">Uložit</gmt-button>
          <gmt-button *ngIf="canDelete" [clickAction]="onDelete"  class="red">Smazat</gmt-button>
        </div>
      </div>
    </div>
  </div>
</div>
