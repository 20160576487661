import { Injectable } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OtherLegalRelationsService {

  constructor(
    private listService: ListService,
  ) { }

  loadOtherLegalRelations(titleIds) {
    const dataOtherLegalRelations = this.listService.createList(
      'other-legal-relations',
      {
        limit: null,
        filters: {
          titleId: titleIds, validity: ['valid'],
          sections: ['C', 'D'],
          loadCollections: ['buildingTo.parcels', 'subjectsFor', 'subjectsTo']
        }
      }
    );
    this.listService.fetchResult(dataOtherLegalRelations);
    return dataOtherLegalRelations;
  }

  // if only ownership is set then restrictions and otherEntries with parcel or building are not filtered.
  // These are supposed to be filtered when both ownership and parcel variables are set
  filter(otherLegalRelations, section, primaryTitle, ownership, parcel?) {
    const filtered = [];
    // filter restrictions by subject and parcel
    // filter other entries by subject and parcel
    otherLegalRelations.forEach((otherLegalRelation) => {
      if (section !== otherLegalRelation.sekce) {
        return;
      }
      if (!otherLegalRelation.parcelTo && !otherLegalRelation.buildingTo && ownership && ownership.title.id !== primaryTitle.id) {
        return;
      }
      if (ownership !== undefined && otherLegalRelation.subjectsTo.length) {
        otherLegalRelation.subjectsTo.forEach((restrictionSubject) => {
          ownership.subjects.forEach((ownershipSubject) => {
            if (ownershipSubject.id === restrictionSubject.id &&
              (
                (parcel === undefined && otherLegalRelation.parcelTo === null && otherLegalRelation.buildingTo === null) ||
                (parcel !== undefined && otherLegalRelation.parcelTo && parcel.id === otherLegalRelation.parcelTo.id) ||
                (parcel !== undefined && otherLegalRelation.buildingTo && parcel.building && parcel.building.id === otherLegalRelation.buildingTo.id) ||
                (parcel !== undefined && otherLegalRelation.parcelTo && parcel.future === true && parcel.knBudouc && parcel.knBudouc.parcels && parcel.knBudouc.parcels.find(p => p.id === otherLegalRelation.parcelTo.id))
              )) {
              filtered.push(otherLegalRelation);
            }
          });
        });
      } else if (ownership === undefined && parcel !== undefined) {
        if (
          !otherLegalRelation.subjectsTo.length &&
          (
            (otherLegalRelation.parcelTo && parcel.id === otherLegalRelation.parcelTo.id) ||
            (otherLegalRelation.buildingTo && parcel.building && parcel.building.id === otherLegalRelation.buildingTo.id) ||
            (otherLegalRelation.parcelTo && parcel.future === true && parcel.knBudouc && parcel.knBudouc.parcels && parcel.knBudouc.parcels.find(p => p.id === otherLegalRelation.parcelTo.id))
          )) {
            filtered.push(otherLegalRelation);
          }
      }
    });
    return filtered;
  }

  filterByEntityWithParcel(otherLegalRelations, dataEntities, primaryTitle, caseOwnerships) {
    const restrictions = [];
    const otherEntries = [];
    // filter specifically for parcel without owner
    _.forEach(dataEntities, (entity) => {
      restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, undefined, entity.parcel));
      otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, undefined, entity.parcel));

      caseOwnerships.forEach((caseOwnership) => {
        if (!caseOwnership.ownership.title || entity.parcel.title.id !== caseOwnership.ownership.title.id) {
          return;
        }
        // filter for combination of ownership and parcel
        restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, entity.parcel));
        otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, entity.parcel));
      });
    });

    return {
      restrictions: restrictions,
      otherEntries: otherEntries,
    };
  }

  filterByEntityWithEasement(otherLegalRelations, dataEasements, primaryTitle, caseOwnerships) {
    const restrictions = [];
    const otherEntries = [];
    // filter specifically for parcel without owner
    _.forEach(dataEasements, (dataEasement) => {
      restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, undefined, dataEasement.easement.parcel));
      otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, undefined, dataEasement.easement.parcel));

      caseOwnerships.forEach((caseOwnership) => {
        if (!caseOwnership.ownership.title || dataEasement.easement.parcel.title.id !== caseOwnership.ownership.title.id) {
          return;
        }
        // filter for combination of ownership and parcel
        restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, dataEasement.easement.parcel));
        otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, dataEasement.easement.parcel));
      });
    });

    return {
      restrictions: restrictions,
      otherEntries: otherEntries,
    };
  }

  filterByEntityWithParcels(otherLegalRelations, dataBuildings, primaryTitle, caseOwnerships) {
    const restrictions = [];
    const otherEntries = [];

    dataBuildings.forEach((building) => {
      building.parcels.forEach((parcel) => {
        caseOwnerships.forEach((caseOwnership) => {
          if (!caseOwnership.ownership.title || parcel.title.id !== caseOwnership.ownership.title.id) {
            return;
          }
          // filter for combination of ownership and parcel
          restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, parcel));
          otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, parcel));
        });
      });
    });

    return {
      restrictions: restrictions,
      otherEntries: otherEntries,
    };
  }

  filterByCaseOwnerships(otherLegalRelations, caseOwnerships, primaryTitle) {
    const restrictions = [];
    const otherEntries = [];

    // filter for ownership without parcel
    caseOwnerships.forEach((caseOwnership) => {
      restrictions.push(...this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership));
      otherEntries.push(...this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership));
    });

    return {
      restrictions: restrictions,
      otherEntries: otherEntries,
    };
  }

}

