import { NgModule } from '@angular/core';
import {Ng2StateDeclaration, Transition, UIRouterModule} from '@uirouter/angular';
import { CommonModule } from '@app/common/common.module';
import { ErrorComponent } from '@app/error/components/error/error.component';

export function resolveError(transition: Transition) {
  return transition.params().errorCode;
}

export function resolveRedirect(transition: Transition) {
  return transition.params().redirectUrl;
}

export const errorState: Ng2StateDeclaration = {
  name: 'error',
  url: '/error?errorCode&redirectUrl',
  component: ErrorComponent,
  data: {
    title: 'Error',
  },
  params: {
    errorCode: {
      type: 'int',
    },
    redirectUrl: {
      type: 'string',
    }
  },
  resolve: {
    errorCode: [ '$transition$', resolveError ],
    redirectUrl: [ '$transition$', resolveRedirect ]
  }
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        errorState,
      ]
    }),
    CommonModule,
  ],
  providers: [],
  declarations: [
    ErrorComponent
  ],
  entryComponents: []
})
export class ErrorModule {
}
