﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { TitleModel } from '../models/title.model';

@Pipe({ name: 'titleAreaNames' })
@Injectable({
  providedIn: 'root'
})
export class TitleAreaNamesPipe implements PipeTransform {
	transform(titles: TitleModel[]) {
        return titles.length > 1 ? 'Více K.Ú.' : titles[0].area.name;
	}
}