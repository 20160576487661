<div class="list">
  <h1>Listy vlastnictví</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.areaId"
      field="values"
      [filter]="filterArea"
      resource="areas/list"
      selectTitle="Katastrální území"></gmt-select>
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.subjectId"
      field="values"
      [filter]="filterSubject"
      resource="subjects/list"
      selectTitle="Vlastník"
      [attributes]="{subjectSjm1: {}, subjectSjm2: {}}"
      [itemPipe]="subjectNamePipe"
    ></gmt-select>
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.parcelId"
      field="values"
      [filter]="filterParcel"
      resource="parcels/list"
      selectTitle="Parcela"
      [attributes]="{title: {mapping: {attributes: {area: {}}}}}"
      [itemPipe]="parcelNumberPipe"
    ></gmt-select>

    <!-- Fulltext -->
    <div class="column-100">
      <input
        class="fulltext"
        type="text"
        autocomplete="off"
        placeholder="Fultextové hledání..."
        [(ngModel)]="list.filter.filters.searchText.values[0].id"
      />
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">
            <sort-by class="cell" [selected]="'lv' === list.filter.sortOrder.sortBy" (sort)="pageableList.onSort('lv', $event)" [sortDir]="list.filter.sortOrder.direction">LV</sort-by>
            <span class="cell">K.Ú.</span>
            <span class="cell">Vlastník</span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let title of list.list" uiSref="dimap.project.titles.detail" [uiParams]="{id: title.id}">
            <span class="cell">{{title.lv}}</span>
            <span class="cell">{{title.area.name}}</span>
            <span class="cell no-padding" style="max-width: 335px; width: 335px;">
              <cell-ownership
                [count]="title.countOwnerships"
                [tooltipData]="{ filter: {sortOrder: {sortBy: 'subjectName'},filters: {titleId: {values: [title.id]}}}, attributes: {subject: {mapping: {attributes: {subjectSjm1: {}, subjectSjm2: {}}}}}}"
                [tooltipType]="'ownership-dimap-list'"
              ></cell-ownership>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.VM_PANEL_TITLES" class="cm-help-page"></help>
</div>
