import { Component, OnInit, Inject } from '@angular/core';
import { TransitionService, UIRouterGlobals} from '@uirouter/angular';
import { StringUtils } from '@app/common/utils/string.utils';
import { APP_BRAND, APP_CONFIG } from '@app/common/services/config.service';
import { Transition } from '@uirouter/core';
import {StateObject} from "@uirouter/core/lib/state/stateObject";


@Component({
  selector: 'gmt-app',
  template: `
      <ui-view class='app'></ui-view>
  `
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(APP_CONFIG) private config: any,
    @Inject(APP_BRAND) private APP_BRAND: any,
    private transitionService: TransitionService,
    private uiRouter: UIRouterGlobals,
  ) {
  }

  ngOnInit(): void {
    (window as any).GoogleAnalytics('create', this.config.GOOGLEANALYTICSKEY, {cookieDomain: 'auto'});

    // on init
    const state: StateObject = this.uiRouter.transition.targetState().$state();

    if (state) {
      if (state.data && state.data.title) {
        const title = state.data.title;

        if (this.APP_BRAND.TRANSLATE[title]) {
          const text = this.APP_BRAND.TRANSLATE[title];
          document.title = StringUtils.capitalize(text);
        } else if (!title.includes('$')) {
          document.title = StringUtils.capitalize(title);
        }
      }

      let toTab = null;
      if (state.params && state.params.tab) {
        toTab = state.params.tab;
      }

      (window as any).GoogleAnalytics('send', {
        'hitType': 'pageview',
        'page': `${state.name}${toTab ? '.' + toTab : ''}`,
      });
    }

    const faviconLink = document.querySelector('link[rel="icon"]') as any;
    faviconLink.href = faviconLink.href.replace('ICO_PATH', this.APP_BRAND.FAVICON);
    const manifestLink = document.querySelector('link[rel="manifest"]') as any;
    manifestLink.href = manifestLink.href.replace('ICO_PATH', this.APP_BRAND.FAVICON);
    const touchLink = document.querySelector('link[rel="apple-touch-icon"]') as any;
    touchLink.href = touchLink.href.replace('ICO_PATH', this.APP_BRAND.FAVICON);

    // on transition
    this.transitionService.onStart({}, (transition: Transition) => {
      const to = transition.to();

      // if title equals brand translation key
      if (to.data && to.data.title && this.APP_BRAND.TRANSLATE && this.APP_BRAND.TRANSLATE[to.data.title]) {
        const text = this.APP_BRAND.TRANSLATE[to.data.title];
        document.title = StringUtils.capitalize(text);
      } else if (to.data && to.data.title && !to.data.title.includes('$')) {
        document.title = StringUtils.capitalize(to.data.title);
      }

      // wait until page title changes
      window.setTimeout(() => {
        // sometime filtering with fulltext emit transitions on same site
        const fromToSame = to.name === transition.from().name;
        let toTab = null;
        // handle transition between tabs
        if (to.params && to.params.tab) {
          toTab = to.params.tab;
        }
        if (!fromToSame || (fromToSame && toTab)) {
          (window as any).GoogleAnalytics('send', {
            'hitType': 'pageview',
            'page': `${transition.to().name}${toTab ? '.' + toTab : ''}`,
          });
        }
      }, 100);
    });
  }
}
