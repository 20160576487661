import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { PermissionsBulkStepComponent } from '@app/settings/users/component/permission-bulk-step/permissions-bulk-step.component';
import { SelectItem } from '@app/common/components/select/select.component';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { ModulesService } from '@app/common/services/modules.service';
import { RolePipe } from '@app/common/pipes/role.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { APPLICATIONS } from '@app/common/services/config.service';
import { ChecklistModel } from "@app/common/models/checklist.model";
import {ListModel} from "@app/common/models/list.model";
import {User} from "@app/models/user";
import {PageableList} from "@app/models/pageable.list";
import {PageableListService} from "@app/common/services/pageable.list.service";

@Component({
  selector: 'permissions-bulk-entities',
  templateUrl: './permissions-bulk-entities.component.html',
  styleUrls: ['./permissions-bulk-entities.component.scss']
})
export class PermissionsBulkEntitiesComponent extends PermissionsBulkStepComponent implements OnInit {

  // project list
  loading = true;
  projectChecklist: ChecklistModel;
  allProjectsSelected = false;
  allProjects: any[];
  projects: any[];

  projectsFilters = [
    {id: 'name', name: 'Název'},
    {id: 'isprofond', name: 'Evidenční číslo'},
    {id: 'investor', name: 'Organizační jednotka'},
  ];
  projectsFilterData: any = {};
  projectsCustomFilterData = {
    searchText: '',
    hasIsprofond: true,
  };

  // user list
  userChecklist: ChecklistModel;
  allUsersSelected = false;
  list: ListModel<User>;
  pageableList: PageableList;

  constructor(
    @Inject(APPLICATIONS) public applications: any,
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private moduleService: ModulesService,
    private authService: AuthService,
    private pageableListService: PageableListService,
    public rolePipe: RolePipe,
  ) {
    super();
    this.getFilterItemsByColumn = this.getFilterItemsByColumn.bind(this);
    this.sortAndFilterProjects = this.sortAndFilterProjects.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
  }

  async ngOnInit() {
    if (this.data.operation === 'u') {
      await this.initProjects();
    } else {
      await this.initUsers();
    }
  }

  /* Project selection part */

  private async initProjects() {
    this.projectChecklist = new ChecklistModel(this.data.projects ? this.data.projects : undefined);

    const user = await this.moduleService.loadUser(this.data.user.id)
      .then(() => this.moduleService.getUser(this.data.user.id))
      .then((d) => d.plain());
    const userProjectKeys = user.applicationProjects[this.applications.sy.name];
    const userPermissions = await this.authService.loadSymapProjects(this.data.user.id).then(d => d.permissions);

    this.allProjects = this.data.common.projects.map(p => {
      p.enabled = userProjectKeys && userProjectKeys.includes(p.key);
      p.roles = user.roles.filter(r => p.key === r.projectKey).map(r => this.rolePipe.transform(r.role)).join(', ');
      p.investor = this.organizationalUnitNamePipe.transform(p.investor ? p.investor.customerOrganizationalUnit : undefined);
      p.permissions = userPermissions[p.key] ? userPermissions[p.key].map(pe => this.data.common.permissions.find(dp => dp.name === pe).description) : [];
      return p;
    });

    this.sortAndFilterProjects();
    this.loading = false;
  }

  getFilterItemsByColumn(column: any): SelectItem[] {
    const columnItems = this.allProjects
      .map(p => { return { id: p[column.id], name: p[column.id] }; })
      .filter(i => i.id !== '' && i.id !== undefined)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    return _.uniqBy(columnItems, 'id');
  }

  sortAndFilterProjects() {
    let projects = this.allProjects;

    // filters
    for (const column of this.projectsFilters) {
      if (this.projectsFilterData[column.id] && this.projectsFilterData[column.id].length) {
        const filter = this.projectsFilterData[column.id].map(i => i.id);
        projects = projects.filter(p => filter.includes(p[column.id]));
      }
    }

    // fulltext
    if (this.projectsCustomFilterData.searchText) {
      const searchText = this.projectsCustomFilterData.searchText.toLowerCase();
      projects = projects.filter(p => {
        if (
          (p.isprofond && p.isprofond.toLowerCase().indexOf(searchText) !== -1)
          || (p.name && p.name.toLowerCase().indexOf(searchText) !== -1)
        ) {
          return true;
        }
        return false;
      });
    }

    // isprofond
    if (this.projectsCustomFilterData.hasIsprofond) {
      projects = projects.filter(p => p.isprofond);
    }

    this.projects = projects;

    this.allProjectsSelectedRecalculate();
  }

  allProjectsSelectedToggle() {
    const selectedAll = this.projectChecklist.checkedItems.length >= this.projects.length;
    if (selectedAll) {
      this.projectChecklist.empty();
    } else {
      this.projectChecklist.empty();
      this.projects.forEach(p => {
        this.projectChecklist.toggleSelection(p);
      });
    }

    this.allProjectsSelectedRecalculate();
  }

  allProjectsSelectedRecalculate() {
    this.allProjectsSelected = !!this.projectChecklist.checkedItems.length && this.projects.every(p => this.projectChecklist.isChecked(p));
  }

  /* User selection part */

  private async initUsers() {
    this.userChecklist = new ChecklistModel(this.data.users ? this.data.users : undefined);

    this.pageableList = this.pageableListService.get(
      'users/list',
      {filters: {searchText: {values: [{id: ''}]}}}
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  pageChanged(pageNumber: number) {
    this.pageableList.fetchResults(pageNumber);
    this.pageableList.onListChanged();
  }

  allUsersSelectedToggle() {
    const selectedAll = this.userChecklist.checkedItems.length >= this.list.list.length;
    if (selectedAll) {
      this.userChecklist.empty();
    } else {
      this.userChecklist.empty();
      this.list.list.forEach(u => {
        this.userChecklist.toggleSelection(u);
      });
    }

    this.allUsersSelectedRecalculate();
  }

  allUsersSelectedRecalculate() {
    this.allUsersSelected = this.list && this.list.list && !!this.userChecklist.checkedItems.length && this.list.list.every(p => this.userChecklist.isChecked(p));
  }

  isProjectEnabled(user: User): boolean {
    return user.applicationProjects[this.applications.sy.name] && user.applicationProjects[this.applications.sy.name].find(pk => this.data.project.key === pk);
  }

  getProjectRoles(user: User): string {
    return user.roles.filter(r => this.data.project.key === r.projectKey).map(r => this.rolePipe.transform(r.role)).join(', ');
  }

  /* Common part */

  isValid(): boolean {
    return (this.data.operation === 'u' && this.projectChecklist && !this.projectChecklist.isEmpty())
      || (this.data.operation === 'p' && this.userChecklist && !this.userChecklist.isEmpty());
  }

  onSubmit() {
    this.data.projects = this.projectChecklist ? this.projectChecklist.checkedItems : [];
    this.data.users = this.userChecklist ? this.userChecklist.checkedItems : [];
    this.submitCallback.emit();
  }
}
