export class ColumnDefinition {
  id: string;
  sortable?: boolean = false;
  name?: string;

  static createFromString(id: string): ColumnDefinition {
    return {
      id: id,
      name: id,
      sortable: false
    }
  }
}
