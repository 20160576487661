import { Component, OnInit, Inject } from '@angular/core';
import { BulkPriceStepComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price-step/bulk-price-step.component';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'bulk-price-result',
  templateUrl: './bulk-price-result.component.html',
})
export class BulkPriceResultComponent extends BulkPriceStepComponent implements OnInit {
  success = 0;
  fail = 0;

  constructor(
    private restangular: Restangular,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    for (const occupation of this.data.occupations) {
      const res = await this.upload(occupation);

      if (res) {
        this.success++;
      } else {
        this.fail++;
      }
    }

    const occupationType = this.data.occupationType = 'u' ? 'Dočasný zábor do 1 roku' : 'Dočasný zábor nad jeden rok';
    const priceType = this.priceType.find(i => i.id === this.data.rentalPrice.rentalPriceType).value;
    let price = '';

    if (this.data.rentalPrice.rentalPriceType === 'O' && this.data.rentalPrice.rentalPriceUnit === 'T') {
      price = `jednorázová cena ${this.data.rentalPrice.totalRentPrice},- Kč`;
    } else {
      price = `cena ${this.data.rentalPrice.priceAssessmentLandPrice},- Kč/m2`;
    }

    this.restangular.all('notes/create').post({
      text: `Hromadné nastavení ceny za ${occupationType}, ${priceType}, ${price}. Cena nastavena u záborů, na které není navázaný aktuálně platný případ nájemní smlouvy.`,
      projectInfo: true,
      systemType: false
    }).toPromise();
  }

  onSubmit() {
    this.submitCallback.emit();
  }

  private upload(occupation: OccupationModel): Promise<boolean> {

    Object.assign(occupation, this.data.rentalPrice);
    return this.restangular.one('occupations', occupation.id)
      .customPUT(occupation)
      .toPromise()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}
