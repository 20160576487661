import { Component, Inject, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ListService } from "@app/common/services/list.service";
import { OccupationModel } from '@app/ps/models/occupation.model';
import { CaseModel } from '@app/ps/models/case.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ListModel } from '@app/common/models/list.model';
import { TableService } from '@app/ps/services/table.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './update-occupation-parcel.component.html',
})
export class UpdateOccupationParcelComponent implements OnInit {
  @Input() caseId: number;
  @Input() occupationId: number;
  occupationObject: OccupationModel;
  parcelList: ListModel<ParcelModel>;
  caseList: ListModel<CaseModel>;
  caseChecklist: any;
  selected: ParcelModel;

  constructor(
    private stateService: StateService,
    private listService: ListService,
    private tableService: TableService,
    private restangular: Restangular,
  ) {
    this.goToCase = this.goToCase.bind(this);
    this.update = this.update.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isCaseDisabled = this.isCaseDisabled.bind(this);
    this.caseChecklist = new ChecklistModel();
  }

  async ngOnInit() {
    await this.loadOccupation();
    this.parcelList = this.listService.createList(
        'parcels',
        {
          limit: undefined,
          filters: {
            geometry: this.occupationObject.geometry,
            validity: 'valid',
            loadCollections: [
              'title.ownerships',
            ],
          },
          sortOrder: [{sortBy: 'parcisKmen'}, {sortBy: 'parcisPod'}],
        }
    );
    this.caseList = this.listService.createList(
        'cases',
        {
          limit: undefined,
          filters: {
            occupationId: this.occupationObject.id,
            loadCollections: [
              'titles',
              'caseOwnerships',
              'caseStatusSubjects',
            ],
          },
          sortOrder: [],
        }
    );
    this.listService.fetchResult(this.parcelList);
    this.listService.fetchResult(this.caseList);
  }

  async loadOccupation() {
    this.occupationObject = await this.restangular.one('occupations', this.occupationId).get({
      loadCollections: ['constructionObjects', 'geometry'],
    }).toPromise();
  }

  goToCase() {
    this.stateService.go('^');
  }

  onToggleCheckItem(parcel: ParcelModel) {
    this.selected = parcel;
  }

  onToggleCheckCaseItem(bCase: CaseModel) {
    this.caseChecklist.toggleSelection(bCase);
  }

  isCaseChecked(bCase: CaseModel) : boolean {
    return this.caseChecklist.isChecked(bCase);
  }

  getColumnName(tableId: string, columnId: string) {
    return this.tableService.getColumnById(tableId, columnId).name;
  }

  isValid() : boolean {
    return !!this.selected;
  }

  isCaseDisabled(bCase: CaseModel) : boolean {
    return bCase.obligation.computePrice &&
      ![
        'RentalContractShorterThan1YearLiability',
        'RentalContractLongerThan1YearLiability',
        'RentalContractCommonLiability'
      ].includes(bCase.obligation.type);
  }

  async update() {
    await this.restangular.one("occupations", this.occupationId).one('update-parcel', this.selected.id).customPUT({
      caseIds: this.caseChecklist.checkedItems.map(bCase => bCase.id),
    }).toPromise();
    this.stateService.go('^');
  }
}

