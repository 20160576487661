﻿import { Component } from '@angular/core';

import { TooltipAbstractListComponent } from "../tooltip-abstract-list/tooltip-abstract-list.component";
import { OwnershipModel } from "../../models/ownership.model";

@Component({
  selector: 'tooltip-ownership',
  templateUrl: './tooltip-ownerships.component.html',
  styleUrls: ['./tooltip-ownerships.component.scss'],
})
export class TooltipOwnershipsComponent extends TooltipAbstractListComponent<OwnershipModel> {
  protected resource = 'ownerships';
}
