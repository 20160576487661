import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'statistics-custom-form',
  templateUrl: './statistics-custom-form.component.html',
})
export class StatisticsCustomFormComponent implements OnInit {

  data: any = {};

  constructor(
    private restangular: Restangular,
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    const statistics = (<DialogConfigData>this.dialogConfig.data).statistics;
    const filter = (<DialogConfigData>this.dialogConfig.data).filter;

    this.data.constructionObjectId = filter.constructionObjectId;
    this.data.constructionObjectStageId = filter.constructionObjectStageId;
    this.data.areaId = filter.areaId;
    this.data.name = statistics.name;
    this.data.statisticsId = statistics.id;
  }

  onSave() {
    return this.restangular.all('statistics').one('create').customPOST(this.data).toPromise().then((data) => {
      this.dialogComponent.close(data.plain());
    });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }
}
