﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'title-detail-create-case-summary',
	templateUrl: './create-case-summary.component.html',
})
export class CreateCaseSummaryComponent implements OnInit {
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();

  constructor(
    private stateService: StateService,
  ) {
    this.submit = this.submit.bind(this);
    this.showCreatedCase = this.showCreatedCase.bind(this);
  }

  ngOnInit() {
  }

  submit() {
    this.submitCallback.emit();
  }

  showCreatedCase() {
    this.stateService.go('symap.project.cases.detail', { id: this.data.id });
  }
}
