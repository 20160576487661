<div>
  <div *ngIf="!validationResponse" class="centered-text jumbotron">
    Probíhá validace VFZE
    <div [loading]="true"></div>
  </div>
  <div *ngIf="validationResponse" class="jumbotron">
    <div class="text-center">
      <ng-container *ngIf="isResponseSuccessfully()">
        <p class="success-message text-center">
          Validace úspěšně dokončena, bez chyb.
        </p>

        <p *ngIf="validationResponse.validationMessage" class="text-center">
          {{ validationResponse.validationMessage }}
        </p>
      </ng-container>

      <ng-container *ngIf="!isResponseSuccessfully()">
        <p class="error-message text-center">
          Validace skončila neúspěšně.
        </p>

        <p *ngIf="validationResponse.validationMessage" class="text-center">
          {{ validationResponse.validationMessage }}
        </p>
      </ng-container>
    </div>
    <div class="text-center mt-20">
      <gmt-button [clickAction]="onSaveValidationResponse">Stáhnout protokol validace</gmt-button>
      <a *ngIf="containResponseErrors()"
         (click)="xmlErrorsDisplayed = !xmlErrorsDisplayed"
         class="button ml-10"
      >{{ xmlErrorsDisplayed ? 'Skrýt chyby v XML' : 'Zobrazit chyby v XML' }}</a>
    </div>
  </div>

  <div *ngIf="xmlErrorsDisplayed && validationResponse && vfzeFileContent" class="mt-20">
    <vfze-problems [validationResponse]="validationResponse" [vfzeSource]="vfzeFileContent"></vfze-problems>
  </div>

  <div class="text-left" *ngIf="validationResponse && !isResponseSuccessfully()">
    <p class="error-message text-center">
      VFZE je nevalidní. Import dat se nemusí podařit pokud je struktura XML poškozena.
    </p>
  </div>

  <div class="mt-10">
    <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!validationResponse">Importovat Data z VFZE</gmt-button>
  </div>
</div>
