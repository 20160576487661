<h1>Změna přihlašovacích údajů</h1>
<div>
  <div class="centered-text">
    Zde je možné provést změnu hesla nebo přihlašovacího jména. Pro provedení změny vždy zadejte vaše aktuální heslo.
  </div>
  <div>
    <fieldset class="mt-20">
      <div class="column-100">
        <label class="required">Aktuální heslo</label>
        <div class="input">
          <input type="password" [(ngModel)]="data.oldPassword"/>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Změna hesla</legend>
      <div class="column-100">
        <label>Nové heslo</label>
        <div class="input">
          <input type="password" [(ngModel)]="data.newPassword"/>
        </div>
      </div>
      <div class="column-100">
        <label [ngClass]="{required: data.newPassword.length > 0}">Potvrzení nového hesla</label>
        <div class="input">
          <input type="password" [(ngModel)]="data.newPasswordCheck"/>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Změna uživatelského jména</legend>
      <div class="column-100">
        <label>Stávající uživatelské jméno</label>
        <div class="info">
          {{ user.username }}
        </div>
        <label>Nové uživatelské jméno</label>
        <div class="input">
          <input type="text" [(ngModel)]="data.username"/>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="actions">
    <gmt-button [clickAction]="onSave" [gmtDisabled]="disabledSave()">Uložit</gmt-button>
  </div>
</div>

<help [helpId]="helpIds.SETTINGS_CHANGE_PASSWORD" class="cm-help-page"></help>
