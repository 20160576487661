import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';

import * as GMTCK from 'gmt-ckeditor/dist';
import {DialogRef} from "@app/common/services/dialog-ref";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";

@Component({
  selector: 'map-print-description',
  styleUrls: ['./map-print-description.component.scss'],
  templateUrl: './map-print-description.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MapPrintDescriptionComponent implements OnInit {
  description = '';
  editor;

  constructor(
    private elementRef: ElementRef,
    private dialogRef: DialogRef,
    private dialogConfig: DialogConfig,
  ) {
    this.description = (dialogConfig.data as DialogConfigData).description;
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async ngOnInit() {
    await this.prepareEditor();
  }

  isValid() {
    return this.editor && this.editor.getData();
  }

  onSave() {
    this.dialogRef.close(this.editor.getData());
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  private async prepareEditor() {
    GMTCK.ClassicEditor.defaultConfig = {
      toolbar: [
        'heading', '|',
        'bold', 'italic', 'strikethrough', 'underline', '|',
        'bulletedList', 'numberedList',
      ],
      image: {
        toolbar: [
          'imageStyle:inline',
          'imageStyle:block',
          'imageStyle:side',
        ]
      },
      language: 'cs'
    };

    this.editor = await GMTCK.ClassicEditor.create(document.querySelector('#editor'));
    this.editor.setData(this.description);
  }
}
