import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { AreaModel } from '@app/common/models/area.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { GeometricPlanRequestTypeEnum } from '@app/ps/enums/geometric-plan-request-type.enum';
import { ApproveEnum } from '@app/ps/enums/approve.enum';
import { KnGpModel } from '@app/common/models/kn-gp.model';

export class GeometricPlanRequestModel {
  id: number;
  name: string;
  type: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  supplier: string;
  parcelSplitPreApproveByBuildingAuthority: string;
  parcelSplitPreApproveByOwner: string;
  conceptRealizationInputDate: string;
  conceptRealizationOutputDate: string;
  conceptToApproveDate: string;
  conceptDisapprovedDate: string;
  conceptApprovedDate: string;
  sentToConfirmationDate: string;
  verificationDate: string;
  disapprovedDate: string;
  approvedDate: string;
  realizationPrice: number;
  pgpNumber: string;
  deletedDate: string;
  registerRequestedDate: string;
  registeredDate: string;
  importRequestedDate: string;
  importedDate: string;
  knGp: KnGpModel;
  nextStatuses: string[];
  lastStatusChangeDate: string;
  countParcels: number;
  vfkImportedId: number;

  areas: AreaModel[];
  parcels: ParcelModel[];
  constructionObjects: ConstructionObjectModel[];

  static create(): GeometricPlanRequestModel {
    const instance = new GeometricPlanRequestModel();
    instance.type = GeometricPlanRequestTypeEnum.PARCEL_SPLIT;
    instance.parcelSplitPreApproveByBuildingAuthority = ApproveEnum.NOT_ENSURE;
    instance.parcelSplitPreApproveByOwner = ApproveEnum.NOT_ENSURE;
    return instance;
  }
}
