<div [loading]="noteList.loading">
  <div class="mb-10" [gmtAuth]="'admin'" *ngIf="type != 'history'">
    <div class="tag" [ngClass]="{active: checkCancelled()}"
         title="Zobrazení uživateli zrušených poznámek "
         (click)="onCancelledCheckboxClicked()">
      <div>Zobrazit zrušené</div>
    </div>
  </div>
  <div class="edit mb-20" gmtAuth="manage_notes" *ngIf="editable">
    <a class="button" (click)="onEditNote()">Přidat poznámku</a>
  </div>
  <div class="notes" *ngIf="noteList.itemCount">
    <perfect-scrollbar [wheelPropagation]="true" (callbackRegister)="onCanScrollTop($event)" [ngStyle]="{'max-height': maxHeight ? (maxHeight + 'px') : '700px'}">
      <ul *ngIf="noteList.list.length">
        <li *ngFor="let note of noteList.list" class="notes__item mb-10 mt-10 pt-10 pb-10 pr-10 pl-10" [ngClass]="{cancelled: note.cancelled}">
          <div style="display: flex; flex: 1 0 100%;">
            <div class="notes__meta mr-20">
              <ul>
                <li class="mb-10"><strong>{{note.timestamp | date: 'short'}}</strong></li>
                <li *ngIf="note.user">{{ note.user | username }}</li>
                <li *ngIf="!note.user">{{ note.userFullName }}</li>
                <li *ngIf="note.noteType">{{ resolveNoteType(note.noteType) }}</li>
                <li *ngIf="note.title">list vlastnictví č. <a uiSref="symap.project.titles.detail" [uiParams]="{id: note.title.id, tab: null}">{{note.title.lv}}</a></li>
                <li *ngIf="note.businessCase">případ <a uiSref="symap.project.cases.detail" [uiParams]="{id: note.businessCase.id}">{{note.businessCase.mpp.number || 'bez čísla'}}</a></li>
                <li *ngIf="note.parcel">parcela <a uiSref="symap.project.parcels.detail" [uiParams]="{id: note.parcel.id, tab: null}">{{note.parcel | parcelnumber}}</a></li>
                <li *ngIf="note.subject">vlastník <a uiSref="symap.project.owners.detail" [uiParams]="{ownerId: note.subject.id, tab: null}">{{note.subject | subjectName}}</a></li>
                <li *ngIf="type != 'history'">id: {{note.id}}</li>
              </ul>
            </div>
            <div class="notes__text" [innerHTML]="note.text | urlhighlight"></div>
          </div>

          <div class="note-actions" *ngIf="!note.systemType && canModify('manage_notes', note.user) && !note.cancelled">
            <div><a class="button" (click)="onEditNote(note)">Upravit</a></div>
            <div class="pl-5"><a class="button" (click)="onDeleteNote(note)">Smazat</a></div>
          </div>
        </li>
      </ul>
      <span *ngIf="noteList.list.length === 0">Žádné poznámky</span>
      <div class="load-next" *ngIf="noteList.itemCount > noteList.list.length">
        <input type="button" value="Načíst další" (click)="onLoadNextNotes()" [disabled]="noteList.loading"/>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="bordered" *ngIf="noteList.list && noteList.itemCount === 0">
    <div class="empty-info">
      <span>{{type === "notes" ? "Žádné poznámky" : "Žádná historie"}}</span>
    </div>
  </div>
</div>
