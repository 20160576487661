<div [loading]="loading">
	<ul>
		<li *ngFor="let case of data?.list?.list" class="case">
			<a uiSref="symap.project.cases.detail" [uiParams]="{id: case.id}" target="{{ data.customData?.openNewTab ? '_blank' : '_self' }}">{{case.mpp.number||'bez čísla'}}<br/>
				{{case.obligation.name}}<br/>
				{{case.caseStatus.name}}
				<span *ngFor="let caseOwnership of case.caseOwnerships">
					<ng-container *ngIf="!caseOwnership.isParent"><br/>{{caseOwnership.ownership | ownershipName}}</ng-container>
				</span>
			</a>
		</li>
	</ul>
	<div *ngIf="data?.list?.list && data.list.list.length === 0">Žádné případy</div>
</div>
