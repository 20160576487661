import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { DataModel } from '@app/dashboard/models/data.model';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  static readonly SOURCE_URL_LIST = [
  ];

  // static readonly SOURCE_URL_LIST_RSD = [
  //   'https://mpvold.rsd.cz/public/data/export/maja_dashboard.json',
  // ];

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    private http: HttpClient
  ) {
  }

  public getStatisticsData(): Observable<any[]> {
    // const urls = (this.APP_BRAND.NAME === 'RSD' ? DataService.SOURCE_URL_LIST_RSD : DataService.SOURCE_URL_LIST);

    const responses = [];
    // for (const url of urls) {
    //   responses.push(this.http.get(url));
    // }
    responses.push(this.restangularSymap.all('dashboard').post().toPromise());
    return forkJoin(responses);
  }
}
