<fieldset class="no-legend">
	<div class="column-100">
		<label class="required">Typ případu</label>
		<div class="input">
			<gmt-select [data]="inputData" field="obligation" resource="obligations" [filter]="obligationFilter" (changed)="updatedObligation()" [required]="true"></gmt-select>
		</div>
	</div>

	<div class="column-100">
		<label class="required">Metodika uzavírání případů</label>
		<div class="input">
			<div class="input">
				<div class="checklist">
					<div class="checklist-item" *ngFor="let createGroup of createGroups" (click)="toggleCreateGroup(createGroup)">
						<gmt-checkbox [gmtChecked]="isCheckeCreateGroup(createGroup)"></gmt-checkbox> {{createGroup.name}}
					</div>
				</div>
			</div>
		</div>
	</div>
</fieldset>

<div *ngIf="inputData.obligation">
	<div class="jumbotron" *ngIf="!list.list || countResolved !== list.list.length">
		<div *ngIf="list.loading" class="centered-text">Načítá se seznam listů vlastnictví.</div>
		<div *ngIf="!list.loading" class="centered-text">Probíhá vyhodnocení dostupných typů případů a oprávnění.</div>
		<div *ngIf="!list.loading" class="centered-text"><b>{{countResolved}}/{{list.list.length}}</b></div>
		<div [loading]="true"></div>
	</div>

	<div class="jumbotron" *ngIf="list.list?.length === 0">
		Nebyly nalezeny žádné listy vlastnictví s možností založení případu zvoleného typu
	</div>

	<div *ngIf="list.list && countResolved === list.list.length && countResolved > 0">
    <fieldset *ngIf="inputData.obligation && inputData.obligation.type === 'FutureRealBurdenLiabilityAccelerated'">
      <legend>Způsob výpočtu ceny</legend>
      <div>
        <div class="checklist">
          <div class="checklist-item" *ngFor="let acceleratedPriceType of acceleratedPriceTypes" (click)="toggleAcceleratedPriceType(acceleratedPriceType)">
            <gmt-radio [gmtChecked]="isCheckedAcceleratedPriceType(acceleratedPriceType)"></gmt-radio>
            {{acceleratedPriceType.name}}
          </div>
        </div>
      </div>
    </fieldset>

		<fieldset>
			<legend>Detaily případů</legend>
			<div class="column-100" *ngIf="step.key === 'selectObjects' && objectTypes.length">
				<label class="required">Objekty zahrnuté do případů</label>
				<div class="input">
					<div class="checklist">
						<div class="checklist-item" *ngFor="let objectType of objectTypes" (click)="toggleObjectType(objectType)">
							<gmt-checkbox [gmtChecked]="isCheckedObjectType(objectType)"></gmt-checkbox> {{objectType.name}}
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="step.key === 'selectObjects' && !objectTypes.length" class="empty-info">
				Žádný ze zvolených listů vlastnictví nemá navázané objekty, které je možné zahrnout do případu daného typu.
			</div>

			<div class="column-100" *ngIf="step.key === 'selectConstructionObjects'">
				<label class="required">Stavební objekt</label>
				<div class="input">
					<div class="checklist">
						<div class="checklist-item">
							<div (click)="checkAllConstructionObjects()">
								<gmt-radio [gmtChecked]="inputData.constructionObjects === null"></gmt-radio> Zahrnout všechny stavební objekty
							</div>
						</div>
						<div class="checklist-item">
							<div>
								<gmt-radio (click)="checkSelectedConstructionObjects()" [gmtChecked]="inputData.constructionObjects !== null"></gmt-radio>
                <gmt-select
                  [data]="this"
                  field="selectedConstructionObjects"
                  [optionItems]="constructionObjects"
                  [multiselect]="true" (changed)="changedSelectedConstructionObjects()"
                  [itemPipe]="constructionObjectNamePipe"
                ></gmt-select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="column-100" *ngIf="step.key === 'selectTimeline'">
				<label>Číselná řada</label>
				<div class="input">
					<gmt-select [optionItems]="seriesData" [itemPipe]="seriesNamePipe" [data]="inputData" field="series"></gmt-select>
				</div>
			</div>

			<div *ngIf="step.key === 'selectParent'">
				<ng-container *ngFor="let titleParentSelection of titleParentSelections">
					<fieldset class="bordered" *ngFor="let item of titleParentSelection.ownershipsWithParent">
						<legend *ngIf="item.caseOwnership.ownership.isBsm">Vlastník - Osoba SJM</legend>
						<legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OPO'">Vlastník - Společnost</legend>
						<legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OFO'">Vlastník - Fyzická osoba</legend>
						<div class="column-100">
							<label>{{item.caseOwnership.ownership.subjects[0].opsubType === 'OPO' ? 'Název' : 'Jméno'}}</label>
							<div class="info">
								<b>{{item.caseOwnership.ownership|ownershipName}}</b>
							</div>
						</div>
						<div class="column-100">
							<label>LV</label>
							<div class="info">
								<b>{{item.caseOwnership.ownership.title|titleName}}</b>
							</div>
						</div>
						<div class="column-100">
							<label>Typ práv</label>
							<div class="info">{{item.caseOwnership.ownership.typravNazev}}</div>
						</div>
						<div class="column-100">
							<label>Nadřazený subjekt</label>
							<div class="input"><gmt-select [optionItems]="item.optionItems" [itemPipe]="parentCaseOwnershipNamePipe" [data]="item.caseOwnership" field="parentCaseOwnership" (changed)="changedParent($event, item, titleParentSelection.caseOwnerships, titleParentSelection.ownershipsWithParent)"></gmt-select></div>
						</div>
					</fieldset>
				</ng-container>
			</div>

			<div class="column-100" *ngIf="step.key === 'summary'" class="empty-info">
				Všechny povinné údaje zadány. Pokračujte tlačítkem "Další" níže.
			</div>

			<div class="column-100 mt-20">
				<gmt-button [gmtDisabled]="step === steps[0]" [clickAction]="previous">Předchozí</gmt-button>
				<gmt-button [gmtDisabled]="step.isValid && !step.isValid()" *ngIf="step !== steps[steps.length - 1]" [clickAction]="next">Následující</gmt-button>
			</div>
		</fieldset>
	</div>
</div>
