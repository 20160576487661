<div [loading]="!data">
  <div *ngIf="data" class="flexbox-container flex-column">
    <div class="column-100">
      <div class="input">
        <gmt-button [clickAction]="cleanCache">Smazat datovou cache pro Dashboard</gmt-button>
      </div>
    </div>

    <div class="column-100">
      <label (click)="data.show=!data.show">Zobrazit v Dashboardu</label>
      <div class="info">
        <gmt-checkbox [gmtChecked]="data.show" (click)="data.show=!data.show"></gmt-checkbox>
      </div>
    </div>

    <div class="column-100" *ngFor="let dashboardField of dashboardFields">
      <fieldset class="bordered">
        <legend>{{dashboardField.name}}</legend>
        <div class="column-100">
          <label>Statistika</label>
          <div class="input">
            <gmt-select [optionItems]="statistics" [data]="data" field="{{dashboardField.type}}" (changed)="onStatisticsChanged(dashboardField.type, $event)"></gmt-select>
          </div>
        </div>
        <div class="column-100" *ngIf="data[dashboardField.type]">
          <label>Finální kategorie</label>
          <div class="input">
            <gmt-select [optionItems]="data[dashboardField.type].categories" [data]="data" field="{{dashboardField.type}}Category"></gmt-select>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>
