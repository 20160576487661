<div>
  <div class="centered">
    <div *ngIf="!loading && message && (!mapinfodata || !mapinfodata.featureData || mapinfodata.featureData?.length === 0)" class="centered-text">
      <p>{{message}}</p>
    </div>
    <div class="content-layers" *ngIf="featureItems && featureItems.length">
      <map-feature-item *ngFor="let feature of featureItems" [feature]="feature" class="layer-item"></map-feature-item>
    </div>
    <div [loading]="true" *ngIf="loading"></div>
    <span *ngIf="mapinfodata && mapinfodata.featureData?.length > 0" class="popup-close-button" (click)="$event.stopPropagation();onClose();">×</span>
  </div>
</div>
