import { NgModule } from '@angular/core';

import { CommonModule } from '../common/common.module';
import { CasesModule } from './cases/cases.module';
import { TitlesModule } from './titles/titles.module';
import { GeometricPlanRequestsModule } from '@app/ps/geometric-plans/geometric-plan-requests.module';
import { OpinionRequestsModule } from '@app/ps/opinions/opinion-requests.module';
import { ParcelsModule } from './parcels/parcels.module';
import { ProjectsModule } from './projects/projects.module';
import { ConstructionObjectsModule } from './construction-objects/construction-objects.module';
import { ProjectSettingsModule } from '@app/ps/project-settings/project-settings.module';
import { OwnersModule } from './owners/owners.module';
import { PsCommonModule } from './ps-common.module';
import { OccupationsModule } from '@app/ps/occupations/occupations.module';
import { ExportsModule } from '@app/ps/exports/exports.module';
import { VfzeModule } from '@app/ps/vfze/vfze.module';
import { HomeModule } from '@app/ps/home/home.module';
import { MapModule } from './map/map.module';

import { BulkOperationComponent } from './components/bulk-operation/bulk-operation.component';
import { BulkOperationSelectEntitiesComponent } from './components/bulk-operation-select-entities/bulk-operation-select-entities.component';
import { BulkOperationSelectActionComponent } from './components/bulk-operation-select-action/bulk-operation-select-action.component';
import { BulkOperationDetailsComponent } from './components/bulk-operation-details/bulk-operation-details.component';
import { BulkOperationConfirmComponent } from './components/bulk-operation-confirm/bulk-operation-confirm.component';
import { BulkOperationFinishComponent } from './components/bulk-operation-finish/bulk-operation-finish.component';
import { TitleMandataryChangeComponent } from './components/title-mandatary-change/title-mandatary-change.component';
import { TitlesMandataryChangeConfirmComponent } from './components/titles-mandatary-change-confirm/titles-mandatary-change-confirm.component';
import { CasesChangeStatusComponent } from './components/cases-change-status/cases-change-status.component';
import { CasesChangeStatusConfirmComponent } from './components/cases-change-status-confirm/cases-change-status-confirm.component';
import { CasesGenerateDocumentsComponent } from './components/cases-generate-documents/cases-generate-documents.component';
import { CasesGenerateDocumentsConfirmComponent } from './components/cases-generate-documents-confirm/cases-generate-documents-confirm.component';
import { CasesGenerateDocumentsFinishComponent } from './components/cases-generate-documents-finish/cases-generate-documents-finish.component';
import { TitlesCreateCasesComponent } from './components/titles-create-cases/titles-create-cases.component';
import { TitlesCreateCasesConfirmComponent } from './components/titles-create-cases-confirm/titles-create-cases-confirm.component';
import { TitlesCreateCasesFinishComponent } from './components/titles-create-cases-finish/titles-create-cases-finish.component';

import { BulkOperationStepHostDirective } from './directives/bulk-operation-step-host/bulk-operation-step-host.directive';

import { SeriesNamePipe } from './pipes/series-name.pipe';

import { BulkOperationType } from '@app/ps/models/bulk-operation-type.model';
import { BulkOperationProcessDefinitionModel } from './models/bulk-operation-process-definition.model';

import { MainComponent } from '@app/ps/components/main/main.component';
import { Ng2StateDeclaration, StateParams, TargetState, UIRouterModule } from '@uirouter/angular';
import { TitleCasePipe } from '@angular/common';
import { CallbackModel } from '@app/common/models/callback.model';
import { APPLICATIONS, ROOT_CALLBACKS } from '@app/common/services/config.service';
import { SymapComponent } from '@app/ps/components/symap/symap.component';
import { resolveCallbacks } from '@app/common/utils/state.utils';
import { brand } from '@src/environments/brand';
import { Transition } from '@uirouter/core';
import { AuthService } from '@app/common/services/auth.service';

export function symapOnEnter(transition: Transition) {
  const authService: AuthService = transition.injector().get(AuthService);
  const applications = transition.injector().get(APPLICATIONS);

  return authService.checkProjectStatus(transition, applications.sy.name, 'sy_', authService.loadSymapProjects, 'error')
    .then(
      () => {
        authService.switchProjectByKey(transition.params().projectKey, applications.sy.name);
      }, (newState: TargetState) => {
        return newState;
      }
    );
}

export const symapState: Ng2StateDeclaration = {
  name: 'symap',
  abstract: true,
  url: `/${brand.PS.URL_BASE_PATH}`,
  component: SymapComponent
};

export const symapProjectState: Ng2StateDeclaration = {
  name: 'symap.project',
  url: '/project/{projectKey:sy_[^/]*}',
  params: {
    projectKey: {
      squash: true,
    }
  },
  views: {
    'content@symap': {
      component: MainComponent,
    },
  },
  onEnter: symapOnEnter
};

export function titlesProcessDefinitionResolveFunction(stateParams: StateParams) { return new BulkOperationProcessDefinitionModel(BulkOperationType.TITLES, stateParams.entityIds); }
export function casesProcessDefinitionResolveFunction(stateParams: StateParams) { return new BulkOperationProcessDefinitionModel(BulkOperationType.CASES, stateParams.entityIds); }
export function entityIdsResolveFunction(stateParams: StateParams) { return stateParams.entityIds; }

export const bulkOperationTitlesState: Ng2StateDeclaration = {
  name: 'symap.project.titles.bulkOperation',
  url: '/bulk-operation',
  data: {
    title: 'Hromadná operace',
  },
  views: {
    'content@symap.project': {
      component: BulkOperationComponent,
    }
  },
  params: {
    entityIds: null,
  },
  resolve: {
    processDefinition: ['$stateParams', titlesProcessDefinitionResolveFunction],
    entityIds: ['$stateParams', entityIdsResolveFunction],
    callbacks: [resolveCallbacks]
  }
};

export const bulkOperationCasesState: Ng2StateDeclaration = {
  name: 'symap.project.cases.bulkOperation',
  url: '/bulk-operation',
  data: {
    title: 'Hromadná operace',
  },
  views: {
    'content@symap.project': {
      component: BulkOperationComponent,
    }
  },
  params: {
    entityIds: null,
  },
  resolve: {
    processDefinition: ['$stateParams', casesProcessDefinitionResolveFunction],
    entityIds: ['$stateParams', entityIdsResolveFunction],
    callbacks: [resolveCallbacks]
  }
};

@NgModule({
  imports: [
    CommonModule,
    MapModule,
    PsCommonModule,
    CasesModule,
    GeometricPlanRequestsModule,
    OpinionRequestsModule,
    TitlesModule,
    ParcelsModule,
    ProjectsModule,
    ConstructionObjectsModule,
    OccupationsModule,
    ExportsModule,
    VfzeModule,
    HomeModule,
    UIRouterModule.forChild({
      states: [
        symapState,
        symapProjectState,
        bulkOperationTitlesState,
        bulkOperationCasesState,
      ]
    }),
    OwnersModule,
    ProjectSettingsModule,
  ],
  providers: [
    TitleCasePipe,
    {
      provide: ROOT_CALLBACKS,
      useFactory: () => new CallbackModel(),
      deps: [ ]
    },
  ],
  declarations: [
    BulkOperationComponent,
    BulkOperationSelectEntitiesComponent,
    BulkOperationSelectActionComponent,
    BulkOperationDetailsComponent,
    BulkOperationConfirmComponent,
    BulkOperationFinishComponent,
    TitleMandataryChangeComponent,
    TitlesMandataryChangeConfirmComponent,
    CasesChangeStatusComponent,
    CasesChangeStatusConfirmComponent,
    CasesGenerateDocumentsComponent,
    CasesGenerateDocumentsConfirmComponent,
    CasesGenerateDocumentsFinishComponent,
    TitlesCreateCasesComponent,
    TitlesCreateCasesConfirmComponent,
    TitlesCreateCasesFinishComponent,
    BulkOperationStepHostDirective,
    MainComponent,
    SymapComponent,
    SeriesNamePipe,
  ],
  entryComponents: [
    BulkOperationSelectEntitiesComponent,
    BulkOperationSelectActionComponent,
    BulkOperationDetailsComponent,
    BulkOperationConfirmComponent,
    BulkOperationFinishComponent,
    TitleMandataryChangeComponent,
    TitlesMandataryChangeConfirmComponent,
    CasesChangeStatusComponent,
    CasesChangeStatusConfirmComponent,
    CasesGenerateDocumentsComponent,
    CasesGenerateDocumentsConfirmComponent,
    CasesGenerateDocumentsFinishComponent,
    TitlesCreateCasesComponent,
    TitlesCreateCasesConfirmComponent,
    TitlesCreateCasesFinishComponent,
    SymapComponent,
  ],
  exports: [
  ]
})
export class PsAppModule {}
