<div [loading]="loading">
  <div *ngIf="!loading">
    <fieldset>
      <legend>Typ případu</legend>
      <div class="checklist">
        <div class="checklist-item" *ngFor="let obligation of obligations" (click)="onSelectObligation(obligation)">
          <div class="cell">
            <gmt-radio [gmtChecked]="data.obligation && data.obligation.id === obligation.id"></gmt-radio>
            {{obligation.name}}
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.obligation">
      <legend>Metodika uzavírání případů</legend>
      <div>
        <div class="checklist">
          <div class="checklist-item" *ngFor="let createGroup of createGroups" (click)="toggleCreateGroup(createGroup)">
            <gmt-checkbox [gmtChecked]="isCheckeCreateGroup(createGroup)" [gmtDisabled]="isCreateGroupDisabled()"></gmt-checkbox>
            {{createGroup.name}}
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.obligation && (data.obligation.type === 'FutureRealBurdenLiabilityAccelerated' || data.obligation.type === 'RealBurdenLiabilityAccelerated')">
      <legend>Způsob výpočtu ceny</legend>
      <div>
        <div class="checklist">
          <div class="checklist-item" *ngFor="let acceleratedPriceType of acceleratedPriceTypes" (click)="toggleAcceleratedPriceType(acceleratedPriceType)">
            <gmt-radio [gmtChecked]="isCheckedAcceleratedPriceType(acceleratedPriceType)"></gmt-radio>
            {{ acceleratedPriceType.name }}
          </div>
        </div>
      </div>
    </fieldset>

    <div class="mt-10">
      <gmt-button [clickAction]="submit" [gmtDisabled]="!data.obligation || (!data.acceleratedPriceType && (data.obligation.type === 'FutureRealBurdenLiabilityAccelerated' || data.obligation.type === 'RealBurdenLiabilityAccelerated'))">Další</gmt-button>
    </div>
  </div>
</div>
