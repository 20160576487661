<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>{{ requestId ? 'Opakovaná žádost' : 'Nová žádost' }}</h1>
                <div class="header-subtitle">{{ requestId ? 'Aktualizace rozsahu zájmového území' : 'Vložení zájmového území' }}</div>
            </div>
        </div>

        <div *ngIf="requestId && keepPreviousGeometry;" class="row mt-20">
          <div class="col-12 offset-lg-3 col-lg-6">
            <fieldset class="bordered">
              <h2 class="mb-10 text-center">Rozsah zájmového území</h2>
              <div class="text-center">
                <gmt-button [clickAction]="continue" [gmtDisabled]="!isFormValid()" class="mr-10">Použít rozsah původní žádosti</gmt-button>
                <a (click)="keepPreviousGeometry = false" class="button">Vložit nový rozsah území</a>
              </div>
            </fieldset>
          </div>
        </div>

        <div *ngIf="!requestId || !keepPreviousGeometry" class="row mt-20">
            <div class="col-12 offset-lg-3 col-lg-6">
                <fieldset class="mb-70">
                    <legend>Zadání zájmového území jeho obvodem</legend>
                    <fileupload class="blue" [inputId]="'hiddenInput1'" (change)="onChange($event)" [message]="message"></fileupload>
                    <p class="text-center"><i>Pokud víte, že se v zadaném obvodu nachází analogové parcely, vložte jejich seznam v dialogu níže.</i></p>
                </fieldset>

                <fieldset>
                    <legend>Doplnění žádosti o analogové parcely</legend>
                    <fileupload class="yellow" [inputId]="'hiddenInput2'" [validation]="analogLandsFileValidator" (change)="onChangeAnalogLandsFile($event)" [message]="analogLandMessage"></fileupload>
                    <gmt-checkbox [gmtChecked]="allAnalogLands" (click)="allAnalogLands = !allAnalogLands"></gmt-checkbox>
                    <label (click)="allAnalogLands = !allAnalogLands">
                        Zažádat o všechny analogové parcely v katastrálních územích dotčených zájmovým územím (parcel zjednodušených evidencí, analogové parcely KN s/bez LV)
                    </label>
                    <div class="analog-lands-error-panel bordered bordered-error p-20 mt-20" *ngIf="analogLandFileErrors.length > 0">
                        <p>V nahraném souboru jsou tyto chyby:</p>
                        <ul>
                            <li class="error-message mt-5" *ngFor="let fileError of analogLandFileErrors">
                                Řádek: {{fileError.line}}: {{fileError.error}}
                            </li>
                        </ul>
                    </div>
                </fieldset>
            </div>

            <div class="col-12 text-center mt-20">
                <gmt-button [clickAction]="cancel">Ukončit</gmt-button>
                <gmt-button [clickAction]="continue" [gmtDisabled]="!isFormValid()">Pokračovat</gmt-button>
            </div>
        </div>
    </div>

    <help [helpId]="helpIds.PK_CREATE_FIRST_STEP" class="cm-help-page"></help>
</div>
