import { Component, Inject, Input, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/angular';
import { TitleModel } from '@app/common/models/title.model';
import { AuthService } from '@app/common/services/auth.service';
import { PricingExpertOpinionStepDataModel } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { OpinionService } from '@app/ps/services/opinion.service';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

export interface PricingCreateStep {
  id: string;
  name: string;
  enabled: boolean;
  help: string;
}

@Component({
  selector: 'pricing-expert-opinion',
  templateUrl: './pricing-expert-opinion.component.html',
})
export class PricingExpertOpinionComponent implements OnInit {

  @Input() opinionId: number;
  @Input() opinionRequestId: number;
  @Input() titleId: number;
  @Input() occupationType: 'P' | 'T' = 'P';
  @Input() returnDestination: 'overview' | 'opinionRequest' = 'overview';

  loading = true;
  readonly = false;
  canDelete = false;
  caseExistsAndNotCancelled = false;

  title: TitleModel;
  opinion: OpinionModel;
  opinionRequest: OpinionRequestModel;
  parcelPrices: ParcelPriceModel[] = [];

  data: PricingExpertOpinionStepDataModel;
  activeStep: PricingCreateStep;
  steps: PricingCreateStep[] = [
    { id: 'base', name: 'Základní údaje', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_ADD_FILES' },
    { id: 'parcels', name: 'Zadání cen', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_INFO' },
    { id: 'summary', name: 'Souhrn', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_SUMMARY' },
  ];
  helpIds = HelpService.HELP_IDS;

  private stepsHistory: string[] = [];
  private deregisterLeaveListener: Function;

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private opinionService: OpinionService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
    this.onDelete = this.onDelete.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async ngOnInit() {
    await this.loadTitle();
    await this.loadOpinion();
    await this.loadOpinionRequest();

    if (this.opinion) {
      this.readonly = !this.authService.hasPermission('assignable') || this.opinion.caseExistsAndNotCancelled;
      this.canDelete = !!(this.authService.hasPermission('assignable') && this.opinionId && !this.opinion.caseExistsAndNotCancelled);
      this.caseExistsAndNotCancelled = this.opinion.caseExistsAndNotCancelled;
    }

    this.data = {
      readonly: this.readonly,
      title: this.title,
      opinion: this.opinion,
      opinionRequest: this.opinionRequest,
      occupationType: this.occupationType,
      updated: false,
    };
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    this.loading = false;
    this.onStep('base', true);
  }

  onDelete(): Promise<any> {
    return this.dialogService.confirmDialogPromise('Všechna zadaná data budou ztracena.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return this.opinionService.deleteOne(this.opinion.id).then(() => true);
        } else {
          return false;
        }
      }).then(deleted => {
        if (deleted) {
          this.onClose();
        }
      });
  }

  onStep(id: string, enable = false) {
    const step = this.steps.find(s => s.id === id);

    if (step.enabled === false && enable === false) {
      return;
    }

    // step back
    if (this.stepsHistory.includes(id)) {
      this.activeStep = step;
      this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
      this.steps.forEach(v => v.enabled = this.stepsHistory.includes(v.id));

    // step forward
    } else {
      this.activeStep = step;
      this.stepsHistory.push(id);
      step.enabled = true;
    }
  }

  onFinish() {
    this.deregisterLeaveListener();
    this.onClose();
  }

  onClose() {
    if (this.returnDestination === 'opinionRequest') {
      this.stateService.go('symap.project.opinionRequests.detail', { id: this.opinionRequestId }, { reload: true });
    } else {
      this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
    }
  }

  private loadTitle(): Promise<any> {
    return this.restangular
      .one('titles', this.titleId)
      .get({ loadCollections: [ 'ownerships', 'ownerships.subjects' ]})
      .toPromise()
      .then(res => { this.title = res.plain(); });
  }

  private loadOpinion(): Promise<any> {
    if (!this.opinionId) {
      return Promise.resolve();
    }
    return this.opinionService
      .getOne(this.opinionId)
      .then(res => this.opinion = res);
  }

  private loadOpinionRequest(): Promise<any> {
    if (!this.opinionRequestId) {
      return Promise.resolve();
    }
    return this.restangular
      .one('opinion-requests', this.opinionRequestId)
      .get({ loadCollections: [ 'parcels', 'buildings' ] })
      .toPromise()
      .then(res => { this.opinionRequest = res.plain(); });
  }

  private beforeExit(): Promise<any> {
    if (this.readonly) {
      this.deregisterLeaveListener();
      return Promise.resolve(true);
    }

    return this.dialogService.confirmDialogPromise('Všechny úpravy budou ztraceny.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      });
  }
}
