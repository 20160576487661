import { Component, OnInit, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { OrganizationalUnitModel } from '@app/models/organizational-unit.model';
import { SvPermissionComponent } from '@app/settings/users/component/sv-permisson/sv-permission.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ModulesService } from '@app/common/services/modules.service';
import {APPLICATIONS} from "@app/common/services/config.service";
import {RESTANGULAR_CONFIGURATION} from "@app/common/services/restangular-configuration.service";
import {ChecklistModel} from "@app/common/models/checklist.model";

@Component({
  selector: 'sv-permission-list',
  templateUrl: './sv-permission-list.component.html',
})
export class SvPermissionListComponent implements OnInit {

  @Input() userId: number;
  @Input() callbacks: any;
  @Input() set userOrganizationalUnits(userOrganizationalUnits: any[]) {
    this.checklistSelection = new ChecklistModel(userOrganizationalUnits);
  }

  loading = true;
  organizationalUnits: OrganizationalUnitModel[];
  checklistSelection;

  constructor(
    private modulesService: ModulesService,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    @Inject(APPLICATIONS) public applications: any,
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private dialogService: DialogService,
  ) {
    this.onPermissionsChange = this.onPermissionsChange.bind(this);
  }

  ngOnInit() {
    this.configurationRestangular
      .all('organizational-units').customPOST({
        filter: {
          limit: null,
          sortOrder: [{ sortBy: 'code', direction: 'asc' }],
          filters: {
            cancelled: {
              values: [false],
            },
          }
        }
      })
      .toPromise()
      .then(data => {
        this.organizationalUnits = data.plain();
        this.loading = false;
      });
  }

  onPermissionsChange(organizationalUnit, changeStatus = false) {
    if (this.checklistSelection.isChecked(organizationalUnit.code) && changeStatus) {
      this.dialogService.confirmDialogPromise(`Opravdu chcete odebrat uživateli veškerá práva k organizační jednotce ${this.organizationalUnitNamePipe.transform(organizationalUnit)}?`)
        .then((data) => {
          if (data === true) {
            const payload = { projectKey: organizationalUnit.code, application: this.applications.sv.name, permissions: [] };
            this.modulesService.saveUserPermissions(this.applications.sv.name, organizationalUnit.code, this.userId, payload).then(() => {
              this.checklistSelection.toggleSelection(organizationalUnit.code);
              this.callbacks.get('userPermissionsChangedCallback')();
            });
          }
        });
    } else {
      const dialog = this.dialogService.open(SvPermissionComponent, { data: {
        userId: this.userId,
        organizationalUnitCode: organizationalUnit.code
      }});
      const sub = dialog.afterClosed.subscribe(() => {
        sub.unsubscribe();
        this.callbacks.get('userPermissionsChangedCallback')();
      });
    }
  }
}
