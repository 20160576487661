import { Component, Input, OnInit } from '@angular/core';

import { TabModel } from '@app/common/models/tab.model';

@Component({
  selector: 'landing-pk',
  templateUrl: './landing-pk.component.html',
  styleUrls: ['./landing-pk.component.scss'],
})
export class LandingPkComponent implements OnInit {
  @Input() activeTab: string;

  tabs: TabModel[] = [
    {
      name: 'Podmínky použití',
      id: 'terms-of-use',
      href: 'settings.modules.pk',
      urlParams: { tab: 'terms-of-use' },
    },
    {
      name: 'Žádost o data KN',
      id: 'cadastre-data-request',
      href: 'settings.modules.pk',
      urlParams: { tab: 'cadastre-data-request' },

    },
    {
      name: 'Import dat KN',
      id: 'cadastre-data-import',
      href: 'settings.modules.pk',
      urlParams: { tab: 'cadastre-data-import' },
    },
    {
      name: 'Hlášení o šíření dat KN',
      id: 'cadastre-data-report',
      href: 'settings.modules.pk',
      urlParams: { tab: 'cadastre-data-report' },
    },
  ];

  constructor() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  isActiveTab(tabId: string): boolean {
    return tabId === this.activeTab;
  }
}
