import { Ng2StateDeclaration, StateParams } from '@uirouter/angular';

import { ParcelListComponent } from './components/parcel-list/parcel-list.component';
import { ParcelDetailComponent } from '@app/ps/parcels/components/parcel-detail/parcel-detail.component';
import { UpdateOccupationParcelComponent } from '@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component';
import { resolveId } from '@app/common/utils/state.utils';
import { UpdateOccupationComponent } from '@app/ps/components/update-occupation/update-occupation.component';

export const parcelsState: Ng2StateDeclaration = {
  name: 'symap.project.parcels',
  data: {
    title: 'Parcely',
  },
  url: '/parcels?page&sef',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    sef: {
      type: 'string',
      inherit: false,
      array: true
    },
  },
  views: {
    'content@symap.project': {
      component: ParcelListComponent,
    }
  }
};

export const parcelDetailState: Ng2StateDeclaration = {
  name: 'symap.project.parcels.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail parcely',
  },
  views: {
    'content@symap.project': {
      component: ParcelDetailComponent,
    }
  },
  resolve: {
    parcelId: [ '$stateParams' , resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
    tab: {
      value: 'occupations',
      dynamic: true,
      inherit: false,
      squash: true,
    }
  },
};

export const updateOccupationParcelState: Ng2StateDeclaration = {
  name: 'symap.project.parcels.detail.updateOccupationParcel',
  url: '/occupation/{occupationId}/update-parcel',
  data: {
    title: 'Aktualizace parcely záboru',
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  resolve: {
    occupationId: ['$stateParams', resolveOccupationIdFunction],
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationParcelComponent,
    },
  },
};

export function resolveOccupationIdFunction(stateParams: StateParams) {
  return stateParams.occupationId;
}

export const updateOccupationState: Ng2StateDeclaration = {
  name: 'symap.project.parcels.detail.updateOccupation',
  data: {
    title: 'Editace záboru',
  },
  url: '/occupation/:occupationId',
  resolve: {
    parcelId: [ '$stateParams', resolveId ],
    occupationId: [ '$stateParams', resolveOccupationIdFunction ],
  },
  params: {
    occupationId: {
      type: 'int',
    },
  },
  views: {
    'content@symap.project': {
      component: UpdateOccupationComponent,
    },
  },
};
