import { Inject, Injectable } from '@angular/core';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    @Inject(RESTANGULAR_SYMAP) private symapRestangularService: any,
  ) { }

  async getProjects(customerId: string, args?: any): Promise<any> {
    return this.symapRestangularService
      .all(`projects/customers/${customerId}`)
      .post(args)
      .toPromise();
  }
}
