import { EventEmitter, Input, Output } from '@angular/core';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { TitleModel } from '@app/common/models/title.model';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';
import {
  ExpertOpinionCoefficientsFlatten,
  ParcelPriceCoefficient,
  ParcelPriceModel
} from '@app/ps/models/parcel-price.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import {AuthService} from "@app/common/services/auth.service";

export interface PricingExpertOpinionStepDataModel {
  readonly?: boolean;
  title?: TitleModel;
  opinion?: OpinionModel;
  opinionRequest?: OpinionRequestModel;
  occupationType?: string;
  parcelPrices?: ParcelPriceModel[];
  checklistParcelPrices?: any;
  sum?: any;
  files?: UploadFileExtended[];
  updated: boolean;
}

export abstract class PricingExpertOpinionStepComponent {

  protected static readonly DEFAULT_SUM = {
    priceDefault: 0,
    priceDefaultRounded: 0,
    priceCoefficient: 0,
    priceCoefficientRounded: 0,
    priceCoefficientWithLand: 0,
    priceCoefficientWithoutLand: 0,
  };

  @Input() data: PricingExpertOpinionStepDataModel;
  @Output() backCallback = new EventEmitter();
  @Output() submitCallback = new EventEmitter();

  loading = true;
  hasCoefficient = this.authService.getActualProject().showExpertOpinionCoefficient;

  readonly COEFFICIENT_8 = ParcelPriceCoefficient.COEFFICIENT_8;
  readonly COEFFICIENT_1_5 = ParcelPriceCoefficient.COEFFICIENT_1_5;
  readonly COEFFICIENT_1 = ParcelPriceCoefficient.COEFFICIENT_1;

  constructor(
    protected authService: AuthService
  ) {
  }
}
