import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@app/common/services/dialog.service';
import { User } from '@app/models/user';
import { AuthService } from '@app/common/services/auth.service';
import { Project } from '@app/models/project';
import { ModulesService } from '@app/common/services/modules.service';
import { UserEditComponent } from '@app/common/components/user-edit/user-edit.component';
import { CallbackModel } from '@app/common/models/callback.model';
import { ListService } from '@app/common/services/list.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit, OnDestroy {

  @Input() userId: number;
  @Input() callbacks: CallbackModel;

  loading = true;
  user: User;
  module = this.authService.getActiveApplication();
  project = this.authService.getActualProject();
  userPermissions: any;
  modulePermissions: any[] = [];
  userRole: any;
  projects: Project[] = [];
  editor: User = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private modulesService: ModulesService,
    private authService: AuthService,
    private dialogService: DialogService,
    private listService: ListService,
  ) {
    this.onEdit = this.onEdit.bind(this);
    this.getProject = this.getProject.bind(this);
    this.onPermissionsRefreshed = this.onPermissionsRefreshed.bind(this);
  }

  async ngOnInit() {
    this.user = await this.modulesService.getUser(this.userId).then(d => d.plain());
    this.userRole = this.user.roles.find(r => r.projectKey === this.project.key);
    this.userPermissions = await this.modulesService.getUserPermissions(this.module, this.userId).then(d => d.plain());
    this.modulePermissions = await this.modulesService.getPermissions(this.module).then(d => d.plain());
    this.projects = await this.modulesService.getProjects(this.module).then(d => d.plain());
    this.user.applicationProjects[this.module] = this.user.applicationProjects[this.module].filter(this.getProject);
    this.loading = false;
    this.callbacks.add('userPermissionsChangedCallback', this.onPermissionsRefreshed);
  }

  ngOnDestroy() {
    this.callbacks.remove('userPermissionsChangedCallback', this.onPermissionsRefreshed);
  }

  onEdit() {
    const dialog = this.dialogService.open(UserEditComponent, {
      data: { userId: this.user.id }
    });

    const sub = dialog.afterClosed.subscribe((res: User) => {
      if (res) {
        Object.assign(this.user, res);
      }
      sub.unsubscribe();
    });
  }

  getPermissionName(id) {
    const permission = this.modulePermissions && this.modulePermissions.find(p => p.name === id);
    return permission && permission.description;
  }

  getProjectName(key) {
    const project = this.getProject(key);
    return project && project.name;
  }

  private async onPermissionsRefreshed() {
    this.user = await this.modulesService.loadUser(this.userId).then(() => this.modulesService.getUser(this.userId)).then(d => d.plain());
    this.userPermissions = await this.modulesService.getUserPermissions(this.module, this.userId).then(d => d.plain());
    this.userRole = this.user.roles.find(r => r.projectKey === this.project.key);
  }

  private getProject(key: string) {
    return this.projects.find((p) => p.key === key);
  }
}
