<div class="detail">
  <h1>Import VFZE</h1>

  <div class="mt-40">
    <fieldset>
      <legend>Import VFZE</legend>
      <gmt-input label="Projekt" [required]="true">
        <input type="text" [ngModel]="projectName" (ngModelChange)="onChangeProjectName($event)"/>
      </gmt-input>
    </fieldset>

    <div class="text-center">
      <fileupload (change)="onFilesChange($event)" [message]="filesMsg" [files]="files"></fileupload>
    </div>

    <div *ngIf="importResponse" class="mt-30 jumbotron">
      <p class="success-message text-center">
        {{ importResponse }}
      </p>
    </div>

    <gmt-button [gmtDisabled]="!isValidToImport()" [clickAction]="onImport">Vložit</gmt-button>
  </div>
</div>
