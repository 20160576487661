import {Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import {
  GeometricPlanRequestEditComponent
} from '@app/ps/geometric-plans/components/geometric-plan-request-edit/geometric-plan-request-edit.component';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { AreaModel } from '@app/common/models/area.model';
import { GeometricPlanRequestStatusEnum } from '@app/ps/enums/geometric-plan-request-status.enum';
import { StateService } from '@uirouter/angular';
import { GeometricPlanRequestTypeEnum } from '@app/ps/enums/geometric-plan-request-type.enum';
import { ParcelModel } from '@app/common/models/parcel.model';
import { Restangular } from 'ngx-restangular';
import { HelpService } from '@app/common/services/help.service';
import { ParcelUtils } from '@app/common/utils/parcel.utils';

@Component({
  selector: 'geometric-plan-request-import',
  templateUrl: './geometric-plan-request-import.component.html',
  styleUrls: ['./geometric-plan-request-import.component.scss'],
})
export class GeometricPlanRequestImportComponent implements OnInit {

  @Input() geometricPlanRequest: GeometricPlanRequestModel;
  step;
  data = [];
  parcels: ParcelModel[];
  typeEnum = GeometricPlanRequestTypeEnum;
  currentSum = 0;
  budoucSum = 0;
  private srcData;

  area: AreaModel;
  constructionObjectFilter = {
    validity: { values: ['valid'] },
    areaId: { values: [] },
  };

  static readonly MSG_GP_CSV_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor CSV.';
  static readonly MSG_GP_CSV_FILES_EXTENSION = 'Soubor musí být ve formátu CSV.';

  gpFiles: UploadFileExtended[] = [];
  gpVfkFiles: UploadFileExtended[] = [];
  gpCsvFiles: UploadFileExtended[] = [];
  gpFilesValid = false;
  gpVfkFilesValid = false;
  gpCsvFilesValid = false;
  hasFile = false;
  hasVfkFile = false;

  gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
  gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
  gpCsvFilesMsg = GeometricPlanRequestImportComponent.MSG_GP_CSV_FILES_DEFAULT;

  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public parcelNumberPipe: ParcelNumberPipe,
    public requestService: GeometricPlanRequestService,
    private stateService: StateService,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onFirstStep = this.onFirstStep.bind(this);
    this.onSecondStep = this.onSecondStep.bind(this);
  }

  ngOnInit() {
    this.area = this.geometricPlanRequest.areas[0];
    this.checkStep();
    this.checkDocuments();
  }

  onCadastreChanged() {
    this.constructionObjectFilter.areaId.values = [this.area.id];
  }

  onGpFilesChange(files: UploadFileExtended[]) {
    this.gpFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'pdf');

    if (this.gpFiles.length === 0) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_EXTENSION;
    } else if (this.gpFiles.length > 1) {
      this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpFilesMsg = '';
    }

    this.gpFilesValid = (extensionValid && this.gpFiles.length === 1);
  }

  onGpVfkFilesChange(files: UploadFileExtended[]) {
    this.gpVfkFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'vfk');

    if (this.gpVfkFiles.length === 0) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_EXTENSION;
    } else if (this.gpVfkFiles.length > 1) {
      this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpVfkFilesMsg = '';
    }

    this.gpVfkFilesValid = (extensionValid && this.gpVfkFiles.length === 1);
  }

  onGpCsvFilesChange(files: UploadFileExtended[]) {
    this.gpCsvFiles = files;

    const extensionValid = files.every(f => f.extension && f.extension.toLowerCase() === 'csv');

    if (this.gpCsvFiles.length === 0) {
      this.gpCsvFilesMsg = GeometricPlanRequestImportComponent.MSG_GP_CSV_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.gpCsvFilesMsg = GeometricPlanRequestImportComponent.MSG_GP_CSV_FILES_EXTENSION;
    } else if (this.gpCsvFiles.length > 1) {
      this.gpCsvFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
    } else {
      this.gpCsvFilesMsg = '';
    }

    this.gpCsvFilesValid = (extensionValid && this.gpCsvFiles.length === 1);
  }

  isValidForSecondStep(): boolean {
    return !!(
      this.geometricPlanRequest.name
      && this.geometricPlanRequest.supplier
      && this.geometricPlanRequest.verificationDate
      && this.geometricPlanRequest.approvedDate
      && this.geometricPlanRequest.pgpNumber
      && this.area
      && (this.geometricPlanRequest.type !== GeometricPlanRequestTypeEnum.EASEMENT_EXTENT || this.geometricPlanRequest.constructionObjects.length)
      && ((this.gpFiles.length === 0 && this.hasFile) || this.gpFiles.length > 0 && this.gpFilesValid)
      && ((this.gpVfkFiles.length === 0 && this.hasVfkFile) || this.gpVfkFiles.length > 0 && this.gpVfkFilesValid)
    );
  }

  checkStep(): Promise<any> {
    if (this.geometricPlanRequest.vfkImportedId) {
      return this.processGp()
        .then(() => {
          this.prepareData();
          this.step = 2;
        });
    } else {
      this.step = 1;
    }
  }

  onFirstStep() {
    this.gpFiles = [];
    this.gpVfkFiles = [];
    this.gpCsvFiles = [];
    this.gpFilesValid = false;
    this.gpVfkFilesValid = false;
    this.gpCsvFilesValid = false;
    this.hasFile = false;
    this.hasVfkFile = false;

    this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
    this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
    this.gpCsvFilesMsg = GeometricPlanRequestImportComponent.MSG_GP_CSV_FILES_DEFAULT;

    this.checkDocuments();
    this.step = 1;
  }

  onSecondStep(): Promise<any> {
    this.geometricPlanRequest.areas = this.area ? [this.area] : [];
    return this.requestService.update(this.geometricPlanRequest, [], this.gpFiles, this.gpVfkFiles, [], this.gpCsvFiles)
      .then(gpr => {
        this.geometricPlanRequest = gpr;
      }).then(() => {
        return this.processGp();
      }).then(() => {
        this.prepareData();
        this.step = 2;
      });
  }

  onSave() {
    // put changes into srcData
    for (const d of this.data) {
      for (const b of d.budouc) {
        const srcB = this.srcData.budouc.find(bb => b.id === bb.id);
        srcB.toImport = b.toImport;

        for (const p of b.pepv) {
          const srcP = this.srcData.pepv.find(pp => p.id === pp.id);
          srcP.vymeraDilu = p.vymeraDilu;
          srcP.oznaceniDilu = p.oznaceniDilu;
        }
      }
    }

    return this.restangular.one(`vfks/data/${this.geometricPlanRequest.vfkImportedId}`)
      .customPUT({ knBudouc: this.srcData.budouc, knBudoucPepv: this.srcData.pepv }, undefined, { updateCollections: ['knBudouc', 'knBudoucPepv'] })
      .toPromise()
      .then(() => {
        return this.restangular.one(`geometric-plan-requests/${this.geometricPlanRequest.id}/import-vfk-perform`).get().toPromise();
      })
      .then(() => {
        this.geometricPlanRequest.status = GeometricPlanRequestStatusEnum.IMPORTED_AUTO;
        return this.requestService.update(this.geometricPlanRequest).then(gpr => {
          this.stateService.go('symap.project.geometricPlanRequests.detail', { id: this.geometricPlanRequest.id, tab: 'parcels' });
        });
      });
  }

  private checkDocuments() {
    this.restangular
      .all('attachments')
      .customPOST({ filter: { filters: { geometricPlanRequestId: this.geometricPlanRequest.id, cancelled: false }}})
      .toPromise()
      .then((data => {
        this.hasFile = data.filter(d => d.type === 'GEOMETRIC_PLAN').length > 0;
        this.hasVfkFile = data.filter(d => d.type === 'GEOMETRIC_PLAN_VFK').length > 0;
      }));
  }

  private processGp(): Promise<any> {
    const initPromise = this.geometricPlanRequest.vfkImportedId
      ? Promise.resolve()
      : this.restangular.one(`geometric-plan-requests/${this.geometricPlanRequest.id}/import-vfk-prepare`).get().toPromise()
        .then(gpr => { this.geometricPlanRequest.vfkImportedId = gpr.vfkImportedId; });

    return initPromise
      .then(() => {
        return this.restangular.one(`vfks/data/${this.geometricPlanRequest.vfkImportedId}`).get({ loadCollections: ['knBudouc', 'knBudoucPepv'] }).toPromise();
      })
      .then((vfk) => {
        this.srcData = {
          pepv: vfk.knBudoucPepv,
          budouc: vfk.knBudouc
        };

        const idpars = this.srcData.pepv.map(p => p.idpar);

        if (!idpars.length) {
          return Promise.resolve([]);
        }

        return this.restangular.all('parcels').customPOST({ filter: { filters: { idpar: idpars, validity: [ 'valid' ] }}}).toPromise();
      })
      .then((parcels) => {
        this.parcels = parcels;
      });
  }

  private prepareData() {
    this.data = [];
    const budouc = this.srcData.budouc.map(b => {
      const pepvFiltered = this.srcData.pepv
         .filter((pp: any) => b.katuzeKod === pp.katuzeKod && b.parcis === pp.parcisBudouci && b.cislKod === pp.cislKod)
         .sort((i1, i2) => {
           return i1.parcis < i2.parcis ? -1 : 1;
       });
      const dily = pepvFiltered.filter(p => p.toImport).length > 1;
      if (dily) {
        pepvFiltered.forEach(p => { p.toImport = false });
      }
      return {
        ...b,
        pepv : pepvFiltered,
        dily : false,
        // přiřadit prioritně k parcele stávající se stejným číslem
        parcelCurrent : pepvFiltered.find(p => p.parcis == b.parcis) || pepvFiltered[0],
        validPepv : !dily,
      }
    });

    for (const p of this.srcData.pepv) {
      let d = this.data.find(dd => dd.parcel.idpar === p.idpar);
      if (!d) {
        d = {};
        d.current = {...p};
        d.parcel = this.parcels.find(pp => pp.idpar === p.idpar);
        d.budouc = budouc
          .filter(b => b.parcelCurrent.idpar == p.idpar)
          .sort((i1, i2) => {
            return i1.parcis == p.parcis || (i2.parcis != p.parcis && i1.parcis < i2.parcis) ? -1 : 1;
          });
        this.data.push(d);
      }
    }

    this.data = _.sortBy(this.data, d => ParcelUtils.getParcelSortNumber(d.parcel.parcisKmen, d.parcel.parcisPod, d.parcel.parcisDil));

    // control sum
    this.currentSum = this.data.reduce((t, d) => t + parseInt(d.parcel.vymera), 0);
    this.budoucSum = this.data.reduce((t, d) => {
      return t + d.budouc.reduce((tt, b) => tt + parseInt(b.vymera), 0);
    }, 0);
  }

  public onPepvToImportClick(pepv, budouc) {
    if (!budouc.toImport || budouc.vstupExists) {
      return;
    }
    pepv.toImport = !pepv.toImport;
    budouc.validPepv = !!budouc.pepv.filter(p => p.toImport).length;
    budouc.dily = budouc.validPepv && budouc.pepv.filter(p => p.toImport).length > 1;
  }

  public isValid() : boolean {
    for (const d of this.data) {
      if (d.budouc.some(b => b.toImport && !b.validPepv)) {
        return false;
      }
    }
    return true;
  }
}
