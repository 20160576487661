import { Component, Input, OnInit } from '@angular/core';
import { ReadinessStateEnum, readinessStateOptions } from '@app/ps/enums/readiness-state.enum';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'readiness',
  templateUrl: './readiness.component.html',
})
export class ReadinessComponent implements OnInit {

  @Input() project: any;

  totalState = {
    readinessPermanentOccupations: { ok: undefined, occupationExists: undefined },
    readinessTemporaryUnderOccupations: { ok: undefined, occupationExists: undefined },
    readinessTemporaryOverOccupations: { ok: undefined, occupationExists: undefined },
    readinessTemporaryEasements: { ok: undefined, occupationExists: undefined },
  };

  readinessStateOptions = readinessStateOptions;

  constructor(
    public restangular: Restangular,
  ) {
  }

  ngOnInit() {
    this.loadOccupationStates();
  }

  refreshReadiness(field: string) {
    if (
      (this.totalState[field].occupationExists === true && (this.project[field] === ReadinessStateEnum.NO_DATA || this.project[field] === ReadinessStateEnum.NOT_DELIVERED_DATA))
      ||
      (this.totalState[field].occupationExists === false && (this.project[field] === ReadinessStateEnum.COMPLETE_DATA || this.project[field] === ReadinessStateEnum.PARTIAL_DATA))
    ) {
      this.totalState[field].ok = false;
    } else {
      this.totalState[field].ok = true;
    }

    this.project.readinessOk = Object.keys(this.totalState).every(k => this.totalState[k].ok !== false);
  }

  private loadOccupationStates() {
    const permanent = [1, 2, 3, 4, 7, 8, 11, 12, 41, 42, 111, 112, 113, 121];
    const tempUnder = [5, 9, 22, 31];
    const tempOver = [6, 10, 21];

    this.resolveOccupationType('readinessPermanentOccupations', permanent);
    this.resolveOccupationType('readinessTemporaryUnderOccupations', tempUnder);
    this.resolveOccupationType('readinessTemporaryOverOccupations', tempOver);

    this.restangular
      .all('easements')
      .customPOST({filter: {filters: { validity: ['valid'] }, limit: 1 }})
      .toPromise()
      .then(data => {
        this.totalState.readinessTemporaryEasements.occupationExists = data.itemCount > 0;
        this.refreshReadiness('readinessTemporaryEasements');
      });
  }

  private resolveOccupationType(field: string, typeNumbers: number[]) {
    this.restangular.all('occupation-types')
      .customPOST({filter: {filters: {validity: ['valid'], number: typeNumbers }}})
      .toPromise()
      .then(ot => {
        ot = ot.plain();

        if (ot.length === 0) {
          this.totalState[field].occupationExists = false;
          this.refreshReadiness(field);
          return;
        }

        this.restangular
          .all('occupations')
          .customPOST({filter: {filters: { validity: ['valid'],  occupationTypeId: ot.map(i => i.id) }, limit: 1 }})
          .toPromise()
          .then(data => {
            this.totalState[field].occupationExists = data.itemCount > 0;
            this.refreshReadiness(field);
          });
      });
  }
}
