import { Component, Inject, Input, OnInit } from '@angular/core';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { TitleModel } from '@app/common/models/title.model';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionService } from '@app/ps/services/opinion.service';
import { StateService, TransitionService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'pricing-preliminary-opinion',
  templateUrl: './pricing-preliminary-opinion.component.html',
})
export class PricingPreliminaryOpinionComponent implements OnInit {

  @Input() opinionId: number;
  @Input() titleId: number;

  title: TitleModel;
  loading = true;
  opinion: OpinionModel;
  sum = { price: 0 };
  readonly = !this.authService.hasPermission('assignable');
  helpIds = HelpService.HELP_IDS;

  private deregisterLeaveListener: Function;

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private opinionService: OpinionService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async ngOnInit() {
    await this.loadTitle();
    await this.loadOpinion();
    await this.loadOpinionPricing();
    this.computeSum();
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    this.loading = false;
  }

  onDelete(): Promise<any> {
    return this.dialogService.confirmDialogPromise('Všechna zadaná data budou ztracena.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return this.opinionService.deleteOne(this.opinion.id).then(() => true);
        } else {
          return false;
        }
      }).then(deleted => {
        if (deleted) {
          this.onClose();
        }
      });
  }

  onClose() {
    this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
  }

  onSave() {
    this.deregisterLeaveListener();

    return this.opinionService
      .updateOne(this.opinion.id, this.opinion)
      .then(() => this.onClose());
  }

  /**
   * Computes the total price of the preliminary opinion.
   *
   * @returns {void}
   */
  computeSum() {
    this.sum.price = 0;
    this.opinion.parcelPrices.forEach((parcelPrice) => {
      const coefficient = parcelPrice.preliminaryOpinionCoefficient ? parcelPrice.preliminaryOpinionCoefficient : 1;
      const landPrice = parcelPrice.preliminaryOpinionLandPrice ? parcelPrice.preliminaryOpinionLandPrice : 0;
      this.sum.price += Math.round(coefficient * landPrice * parcelPrice.parcel.vymera);
    });
  }

  isValid(): boolean {
    return this.opinion.parcelPrices && this.opinion.parcelPrices.length && this.opinion.parcelPrices.every(this.isValidPrice, this);
  }

  /**
   * Checks if a parcel price is valid.
   * Parcel price is considered valid when both price and coefficient are filled.
   *
   * @returns {boolean}
   */
  private isValidPrice(row) {
    const preliminaryOpinionLandPrice = isFinite(row.preliminaryOpinionLandPrice);
    const preliminaryOpinionCoefficient = isFinite(row.preliminaryOpinionCoefficient);
    return preliminaryOpinionLandPrice && preliminaryOpinionCoefficient;
  }

  private loadTitle(): Promise<any> {
    return this.restangular
      .one('titles', this.titleId)
      .get({ loadCollections: [ 'ownerships', 'ownerships.subjects' ]})
      .toPromise()
      .then(res => {
        this.title = res.plain();
      });
  }

  private loadOpinion(): Promise<any> {
    return this.opinionService
      .getOne(this.opinionId)
      .then(res => this.opinion = res);
  }

  private loadOpinionPricing(): Promise<any> {
    return this.restangular
      .all('parcel-prices')
      .customPOST({
        filter: {
          filters: {
            opinionId: [this.opinionId],
          },
        },
      }).toPromise().then((data) => {
        data = data.plain();

        data.forEach((parcelPrice) => {
          this.opinion.parcelPrices.push(parcelPrice);
        });
      });
  }

  private beforeExit(): Promise<any> {
    if (this.readonly) {
      this.deregisterLeaveListener();
      return Promise.resolve(true);
    }

    return this.dialogService.confirmDialogPromise('Všechny úpravy budou ztraceny.')
      .then(data => {
        if (data === true) {
          this.deregisterLeaveListener();
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      });
  }
}
