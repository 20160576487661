<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset>
      <div class="column-100">
        <gmt-input label="Adresát" [required]="recipients.length > 1" [readonly]="recipients.length === 1">
          <gmt-select
            *ngIf="recipients.length > 1"
            [data]="dataSelected"
            field="recipient"
            required="true"
            [optionItems]="recipients"
          ></gmt-select>
          <ng-container *ngIf="recipients.length === 1">{{ dataSelected.recipient.name }}</ng-container>
        </gmt-input>
      </div>

      <div class="column-100">
        <gmt-input label="Datum odeslání" [required]="true">
          <input type="text" pick-a-date [(ngModel)]="data.sentOwnerDate" [maxToday]="true"/>
        </gmt-input>
      </div>
    </fieldset>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
