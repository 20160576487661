import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { ParcelModel } from '@app/common/models/parcel.model';

import { getOrder } from '../../pure/listLoadOrder';
import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { HelpService } from '@app/common/services/help.service';

@Component({
  selector: 'parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrls: []
})
export class ParcelListComponent implements OnInit {

  list: ListModel<ParcelModel>;
  pageableList: PageableList;
  helpIds = HelpService.HELP_IDS;

  filterArea = {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('areas/list')};
  filterSubject = {sortOrder: getOrder('subjects/list')};
  filterTitle = {sortOrder: getOrder('titles/list')};

  constructor(
    public titleNamePipe: TitleNamePipe,
    public subjectNamePipe: SubjectNamePipe,
    private pageableListService: PageableListService,
  ) {
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  private getDefaultFilter() {
    return {
      filters: {
        areaId : {values: []},
        titleId : {values: []},
        subjectId : {values: []},
        requestArea : {values: [{id: true}]},
        searchText: {values: [{id: ''}]}
      },
      sortOrder: getOrder('parcels/list')
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get(
      'parcels/list',
      this.getDefaultFilter(),
      'parcelsFilter',
      undefined,
      {title: {mapping: {attributes: {area: {}}}}}
    );
    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
