﻿import { Injectable, Inject } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';
import {HttpOptions, HttpService} from "@app/common/services/http.service";

@Injectable({providedIn: 'root'})
export class ListService {
  constructor(
    private localStorageService: LocalStorageService,
    private restangular: Restangular,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService,
    private httpService: HttpService,
  ) {}

  fetchListPerPage(list, page, onListChanged, onOverfloatPages) {
    list.filter.offset = (page - 1) * list.filter.limit;

    // check if requested items fit with possible offset and limit
    if (list.list && list.filter.offset >= this.getItemsCount(list) && page !== 1) {
      list.filter.offset = 0;

      if (typeof onOverfloatPages === 'function') {
        onOverfloatPages();
      }

      return Promise.reject();
    } else {
      return this.fetchResult(list).then(() => {
        if (typeof onListChanged === 'function') {
          onListChanged();
        }
      });
    }
  }

  fetchResult(list, additive = false) {
    if (additive === undefined) {
      additive = false;
    }
    if (list.abortFetch) {
      list.abortFetch();
      list.promise.catch(() => {
        list.loading = true;
      });
    } else {
      list.loading = true;
    }

    const cancelPromise = new Promise<any>((resolve, reject) => {
      list.abortFetch = resolve;
    });

    const filter = transformListToFilter(list.filter);
    const filterRequest = _.cloneDeep(filter);

    if (filterRequest.sortOrder && !(filterRequest.sortOrder instanceof Array)) {
      filterRequest.sortOrder = [filterRequest.sortOrder];
    }

    if (list.filterLocalStorageName) {
      filter.filters.loadCollections = undefined;
      this.localStorageService.set(list.filterLocalStorageName, filter);
    }

    this.toFilterRequestMessage(filterRequest);

    const options: HttpOptions = {
      path: list.path,
      baseUrl: list.restangularService.configuration.baseUrl,
      method: 'POST',
      data: {
        attributes: list.attributes,
        filter: filterRequest
      },
      cancelPromise: cancelPromise
    };

    list.promise = this.requestCall(options).then(
      (data) => {
        list.loading = false;
        if (list.list === null || !additive) {
          list.list = [];
        }
        list.list = list.list.concat(data.items || data);
        list.itemCount = data.itemCount;

        list.abortFetch = null;
        return list;
      }, (reason) => {
        list.loading = false;
        if (reason && reason.xhrStatus !== 'abort') {
          this.errorHandlerService.get(reason);
        }
        return Promise.reject(reason);
      });
    return list.promise;
  }

  requestCall(options: HttpOptions, dataCallback?) {
    return this.httpService.call(options).then((data) => {
      if (typeof dataCallback === 'function') {
        return dataCallback(data);
      } else {
        return data;
      }
    });
  }

  toFilterRequestMessage(filterRequest) {
    if (filterRequest.filters.searchText) {
      if (filterRequest.filters.searchText.values.length === 1) {
        filterRequest.filters.searchText.values = filterRequest.filters.searchText.values[0].split(' ');
      }
      filterRequest.filters.searchText.values = filterRequest.filters.searchText.values.filter((value) => !!value);
    }

    for (const key of Object.keys(filterRequest.filters)) {
      if (filterRequest.filters[key] && filterRequest.filters[key].searchText !== undefined) {
        if (!(filterRequest.filters[key].searchText instanceof Array)) {
          filterRequest.filters[key].searchText = filterRequest.filters[key].searchText.split(' ');
        }
        filterRequest.filters[key].searchText = filterRequest.filters[key].searchText.filter((text) => !!text);
      }
    }
    return filterRequest;
  }

  fetchResultCount(list) {
    const filter = _.cloneDeep(list.filter);
    filter.offset = 0;
    delete filter.sortOrder;
    const filterRequest = transformListToFilter(filter);
    this.toFilterRequestMessage(filterRequest);

    const options: HttpOptions = {
      path: list.path + '/count',
      baseUrl: list.restangularService.configuration.baseUrl,
      method: 'POST',
      data: {
        attributes: list.attributes,
        filter: filterRequest
      },
    };

    return this.requestCall(options, (data) => { list.itemCountTotal = data; });
  }

  cancelFetch(list) {
    if (list.abortFetch) {
      list.abortFetch();
    }
  }

  attachFilterStorage(list, filterLocalStorageName, executeAfter?) {
    filterLocalStorageName = this.authService.getActualProject() ? `${this.authService.getActualProject().key}.${filterLocalStorageName}` : `${this.authService.getActiveApplication()}.${filterLocalStorageName}`;
    const localStorageFilter = this.localStorageService.get(filterLocalStorageName);
    if (localStorageFilter) {
      for (const attrname in localStorageFilter) {
        if (attrname !== 'filters' && list.filter[attrname] !== undefined) {
          list.filter[attrname] = localStorageFilter[attrname];
        } else {
          // expands arrays to objects
          for (const key in localStorageFilter[attrname]) {
            const value = localStorageFilter[attrname][key];
            let found = list.filter.filters[key];
            if (typeof found === 'undefined' || typeof found.values === 'undefined' || typeof found.values === typeof value.values) {
              found = list.filter.filters[key] = {};
              found.values = value.values;
              if (value.negation && value.negation === true) {
                found.negation = value.negation;
              }
              if (value.searchText !== undefined) {
                found.searchText = value.searchText;
              }
              if (found.values instanceof Array) {
                found.values.forEach((e, i) => {
                  found.values[i] = {id: found.values[i]};
                });
              }
            }
          }
        }
      }
    }

    list.filterLocalStorageName = filterLocalStorageName;

    if (typeof executeAfter === 'function') {
      executeAfter();
    }
  }

  createList(path, defaultFilter, restangularService: any = this.restangular, attributes = {}) {
    const filter = {
      offset: 0,
      limit: 20,
      sortOrder: {sortBy: 'id', direction: 'asc'},
      filters: {}
    };

    if (defaultFilter) {
      for (const attrname in defaultFilter) {
        filter[attrname] = defaultFilter[attrname];
      }
    }

    return {
      abortFetch: null,
      path: path,
      list: null,
      loading: false,
      itemCount: 0,
      filter: filter,
      restangularService: restangularService,
      attributes: attributes,
      promise: undefined
    };
  }


  getCurrentPage(list) {
    return list && list.filter ? (list.filter.offset / list.filter.limit) + 1 : 0;
  }

  getTotalPages(list) {
    return list && list.filter ? Math.ceil(this.getItemsCount(list) / list.filter.limit) : 0;
  }

  getItemsCount(list) {
    if (list && list.filter && list.itemCountTotal) {
      return list.itemCountTotal;
    } else if (list && list.filter && list.itemCount) {
      return list.itemCount;
    } else {
      return 0;
    }
  }

  sort(list, sortValues, sortDir) {
    if (sortValues instanceof Array) {
      list.filter.sortOrder = _.map(sortValues, (sortValue) => {
        return {sortBy: sortValue, direction: (sortDir === 'asc' ? 'desc' : 'asc')};
      });
    } else {
      // for backward compatibility if sortBy is checked
      list.filter.sortOrder = {sortBy: sortValues, direction: (sortDir === 'asc' ? 'desc' : 'asc')};
    }
    list.filter.offset = 0;
    return this.fetchResult(list);
  }
}
