<div class="detail">
  <div style="display:flex">
    <div class="line-height-l">
      <gmt-back-button sref="^" text="Seznam modulů">
      </gmt-back-button>
    </div>
  </div>
  <h1>VFZE</h1>
  <gmt-navbar class="mt-40" [tabs]="tabs" [isActiveTab]="isActiveTab">
    <div ui-view="{{ activeTab }}"></div>
  </gmt-navbar>
</div>
