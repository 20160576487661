<div class="row-group">
  <div class="row pb-10">
    <div *ngIf="!hideActionsButtons" style="margin-left: -45px;display: flex;flex-direction: column;min-width: 30px;width: 30px;">
      <a class="button red" (click)="onCancelSeries(series)" *ngIf="!series.series.cancelled" style="display: flex;align-items: center;justify-content: center;"><i class="fa fa-minus"></i></a>
      <a class="button green" (click)="onRestoreSeries(series)" *ngIf="series.series.cancelled" style="display: flex;align-items: center;justify-content: center;"><i class="fa fa-repeat"></i></a>
      <a class="button mt-5" (click)="onMoveSeries(series, -1)" *ngIf="!series.series.cancelled" style="display: flex;align-items: center;justify-content: center;"><i class="fa fa-arrow-up"></i></a>
      <a class="button mt-5" (click)="onMoveSeries(series, 1)" *ngIf="!series.series.cancelled" style="display: flex;align-items: center;justify-content: center;"><i class="fa fa-arrow-down"></i></a>
    </div>
    <div class="cell" [ngClass]="{ cancelled : series.series.cancelled || !series.series.available }" style="line-height: 15px;">
      <div class="column-100" *ngIf="!hideConstructionObject">
        <gmt-input label="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}">
          <gmt-select
            resource="construction-objects"
            [filter]="{ filters: { validity: 'valid' }, sortOrder: { sortBy: 'socis' }}"
            [data]="series.series"
            field="constructionObject"
            [itemPipe]="constructionObjectNamePipe"
            (changed)="onChange()"
          ></gmt-select>
        </gmt-input>
      </div>

      <div class="column-100" *ngFor="let section of series.sections">
        <div *ngIf="section.type === 'text'">
          <div class="label">
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, -1)"><i class="fa fa-arrow-up"></i></a>
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, 1)"><i class="fa fa-arrow-down"></i></a>
            <div (click)="onToggleSection(section, series.sections)" class="fl mr-10">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Text
            </div>
          </div>
          <div class="input">
            <input type="text" [(ngModel)]="section.text" (change)="onChange()"/>
          </div>
        </div>
        <div *ngIf="section.type === 'order'">
          <div class="label">
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, -1)"><i class="fa fa-arrow-up"></i></a>
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, 1)"><i class="fa fa-arrow-down"></i></a>
            <div (click)="onToggleSection(section, series.sections)" class="fl mr-10">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Pořadové číslo
            </div>
          </div>
          <div class="info">Od&nbsp;</div>
          <div class="input">
            <input class="w-50" type="text" [(ngModel)]="section.from" (change)="onChange()"/>
          </div>
          <div class="info">Do&nbsp;</div>
          <div class="input">
            <input class="w-50" type="text" [(ngModel)]="section.to" (change)="onChange()"/>
          </div>
          <div class="info">Počet míst&nbsp;</div>
          <div class="input">
            <input class="w-50" type="text" [(ngModel)]="section.padNumber" [disabled]="!section.show" (change)="onChange()"/>
          </div>
          <div class="info ml-10" (click)="section.restart = !section.restart">
            <gmt-checkbox [gmtChecked]="section.restart" (changed)="onChange()"></gmt-checkbox> Obnovovat 1.1.
          </div>
        </div>
        <div *ngIf="section.type === 'year'">
          <div class="label">
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, -1)"><i class="fa fa-arrow-up"></i></a>
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, 1)"><i class="fa fa-arrow-down"></i></a>
            <div (click)="onToggleSection(section, series.sections)" class="fl mr-10">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Aktuální rok
            </div>
          </div>
          <div class="info">Od&nbsp;</div>
          <div class="input">
            <input class="w-50" type="text" [(ngModel)]="section.from" (change)="onChange()"/>
          </div>
          <div class="info">Do&nbsp;</div>
          <div class="input">
            <input class="w-50" type="text" [(ngModel)]="section.to" (change)="onChange()"/>
          </div>
          <div class="info ml-10" (click)="section.short = !section.short">
            <gmt-checkbox [gmtChecked]="section.short" (changed)="onChange()"></gmt-checkbox> Zkrácený
          </div>
        </div>
        <div *ngIf="section.type === 'title'">
          <div class="label">
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, -1)"><i class="fa fa-arrow-up"></i></a>
            <a class="fl mr-10" (click)="onMoveSection(section, series.sections, 1)"><i class="fa fa-arrow-down"></i></a>
            <div (click)="onToggleSection(section, series.sections)" class="fl mr-10">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="section.show"></gmt-checkbox> Číslo LV
            </div>
          </div>
        </div>
      </div>

      <div class="column-100">
        <label>Následující číslo</label><div class="info">{{ generateNumber() }}</div>
      </div>
    </div>
  </div>
</div>
