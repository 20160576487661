import { Component, OnInit } from '@angular/core';
import { PricingExpertOpinionStepComponent } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { OpinionService } from '@app/ps/services/opinion.service';
import { OpinionRequestService } from '@app/ps/services/opinion-request.service';
import { OpinionRequestStatusEnum } from '@app/ps/enums/opinion-request-status.enum';
import { Restangular } from 'ngx-restangular';
import { ParcelPriceUtils } from '@app/ps/utils/parcel-price.utils';

@Component({
  selector: 'pricing-expert-opinion-summary',
  templateUrl: './pricing-expert-opinion-summary.component.html',
})
export class PricingExpertOpinionSummaryComponent extends PricingExpertOpinionStepComponent implements OnInit {

  priceDefault = 0;
  priceCoefficient = 0;

  constructor(
    private restangular: Restangular,
    private opinionService: OpinionService,
    private opinionRequestService: OpinionRequestService,
    private numericPipe: NumericPipe,
    protected authService: AuthService,
  ) {
    super(authService);
    this.onSave = this.onSave.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  ngOnInit() {
    this.setupComputedPrices();

    if (!this.data.updated) {
      this.computeFromExisting();
    } else {
      this.computeFromInitial();
    }

    this.data.updated = false;

    this.loading = false;
  }

  onBack() {
    this.backCallback.emit();
  }

  onSave(): Promise<any> {
    if (this.data.opinion.id) {
      return this.opinionService
        .updateOne(this.data.opinion.id, this.data.opinion)
        .then(() => this.submitCallback.emit());
    }

    // create opinion and create opinion request or update existing one
    return this.opinionService
      .createOne(this.data.opinion)
      .then(opinion => {
        if (this.data.opinionRequest) {
          this.data.opinionRequest.opinion = opinion.plain();
          this.data.opinionRequest.status = OpinionRequestStatusEnum.PRICES_FILLED;
          return this.opinionRequestService.update(this.data.opinionRequest);
        } else {
          this.data.opinion.id = opinion.id;
          return this.opinionRequestService.createByOpinion(this.data.opinion);
        }
      })
      .then(opinionRequest => {
        return this.opinionRequestService.createDocuments(opinionRequest, this.data.files ? this.data.files : []);
      })
      .then(() => this.submitCallback.emit());
  }

  isFormValid() {
    const defaultPriceValid = this.data.sum && isFinite(this.data.sum.priceDefaultRounded) && this.data.sum.priceDefaultRounded;
    const coefficientPriceValid = this.data.sum && isFinite(this.data.sum.priceCoefficientRounded);
    return defaultPriceValid && coefficientPriceValid;
  }

  computeDefaultPrice(init = false) {
    this.data.sum.priceDefault = 0;
    this.data.sum.priceDefaultWithLand = 0;
    this.data.sum.priceDefaultWithoutLand = 0;
    this.data.sum.countWithLand = 0;

    this.data.opinion.parcelPrices.forEach((p) => {
      const hasLand = this.data.opinion.expertOpinionLand && p.expertOpinionParcelPriceDefault > 0;
      if (this.data.occupationType === 'P') {
        this.data.sum.priceDefault += this.numericPipe.transform(p.expertOpinionParcelPriceDefault) || 0;
      } else {
        this.data.sum.priceDefault += this.numericPipe.transform(p.expertOpinionCompensationPriceDefault) || 0;
      }

      if (hasLand) {
        this.data.sum.priceDefaultWithLand += p.expertOpinionParcelPriceDefault;
        this.data.sum.countWithLand += 1;
      } else {
        this.data.sum.priceDefaultWithoutLand += p.expertOpinionParcelPriceDefault;
      }
    });

    if (init) {
      this.data.sum.priceDefaultRounded = this.data.sum.priceDefault;
    }
  }

  computeCoefficientPrice(init = false) {
    this.data.sum.priceCoefficient = 0;
    this.data.sum.priceCoefficientWithLand = 0;
    this.data.sum.priceCoefficientWithoutLand = 0;

    let parcelCount = this.data.sum.countWithLand;
    let priceDefaultRoundedRemain = this.data.sum.priceDefaultRounded - this.data.sum.priceDefaultWithoutLand;

    // Compute default price proportionally to input summary amount
    // Then computed price with coefficient applied
    this.data.opinion.parcelPrices.forEach((p) => {
      // Rounding is applied only to land price
      if (this.data.opinion.expertOpinionLand && priceDefaultRoundedRemain && this.data.sum.priceDefaultRounded && p.expertOpinionLandSquarePrice > 0) {
        if (!--parcelCount) {
          p.expertOpinionLandPriceDefault = Math.round(priceDefaultRoundedRemain);
        } else {
          p.expertOpinionLandPriceDefault = Math.round((this.data.sum.priceDefaultRounded - this.data.sum.priceDefaultWithoutLand) * p.expertOpinionParcelPriceDefault / this.data.sum.priceDefaultWithLand);
        }

        p.expertOpinionLandPriceDefault -= (p.expertOpinionVegetationPriceDefault || 0);
        p.expertOpinionLandPriceDefault -= (p.expertOpinionBuildingsPriceDefault || 0);
        priceDefaultRoundedRemain -= p.expertOpinionLandPriceDefault + (p.expertOpinionVegetationPriceDefault || 0) + (p.expertOpinionBuildingsPriceDefault || 0);
      } else {
        p.expertOpinionLandPriceDefault = 0;
      }

      p.expertOpinionParcelPriceDefaultRounded = (p.expertOpinionLandPriceDefault || 0) + (p.expertOpinionVegetationPriceDefault || 0) + (p.expertOpinionBuildingsPriceDefault || 0);

      // Compute coefficient price by rounded land price
      p.expertOpinionLandPriceCoefficient = ParcelPriceUtils.computeLandPriceCoefficientFromDefault(p);

      const sum = Math.round(
        (p.expertOpinionLandPriceCoefficient || 0)
        + (p.expertOpinionCompensationPrice || 0)
        + (p.expertOpinionVegetationPrice || 0)
        + (p.expertOpinionBuildingsPrice || 0)
      );

      this.data.sum.priceCoefficient += sum;

      if (p.expertOpinionLandPriceCoefficient > 0) {
        this.data.sum.priceCoefficientWithLand += sum;
      } else {
        this.data.sum.priceCoefficientWithoutLand += sum;
      }

      if (init) {
        this.data.sum.priceCoefficientRounded = this.data.sum.priceCoefficient;
      }
    });
  }

  computeRoundedPrice() {
    let parcelCount = this.data.sum.countWithLand;
    let priceCoefficientRoundedRemain = this.data.sum.priceCoefficientRounded - this.data.sum.priceCoefficientWithoutLand;
    this.data.sum.price = 0;

    // Compute final price proportionally to input summary amount
    this.data.opinion.parcelPrices.forEach((p) => {
      if (this.hasCoefficient) {
        if (this.data.opinion.expertOpinionLand && priceCoefficientRoundedRemain && this.data.sum.priceCoefficientRounded && p.expertOpinionLandSquarePrice > 0) {
          if (!--parcelCount) {
            p.expertOpinionLandPrice = Math.round(priceCoefficientRoundedRemain);
          } else {
            p.expertOpinionLandPrice = Math.round((this.data.sum.priceCoefficientRounded - this.data.sum.priceCoefficientWithoutLand) *
              ((p.expertOpinionLandPriceCoefficient || 0) + (p.expertOpinionVegetationPrice || 0) + (p.expertOpinionBuildingsPrice || 0))
              / this.data.sum.priceCoefficientWithLand
            );
          }
          p.expertOpinionLandPrice -= (p.expertOpinionVegetationPrice || 0);
          p.expertOpinionLandPrice -= (p.expertOpinionBuildingsPrice || 0);
          priceCoefficientRoundedRemain -= (p.expertOpinionLandPrice || 0) + (p.expertOpinionVegetationPrice || 0) + (p.expertOpinionBuildingsPrice || 0);
        } else {
          p.expertOpinionLandPrice = null;
        }
      } else {
        p.expertOpinionLandPrice = p.expertOpinionLandPriceCoefficient;
      }
    });
  }

  private computeFromInitial() {
    this.data.sum = { ...PricingExpertOpinionSummaryComponent.DEFAULT_SUM };

    this.computeDefaultPrice(true);
    this.computeCoefficientPrice(true);
    this.computeRoundedPrice();
  }

  private setupComputedPrices() {
    this.priceDefault = 0;
    this.priceCoefficient = 0;

    this.data.opinion.parcelPrices.forEach((p) => {
      if (this.data.opinion.expertOpinionLand && p.parcel) {
        this.priceDefault += Math.round(p.expertOpinionLandSquarePrice * p.parcel.vymera);
        this.priceCoefficient += ParcelPriceUtils.computeLandPriceCoefficient(p);
      }

      if (this.data.opinion.expertOpinionCompensation) {
        this.priceDefault += p.expertOpinionCompensationPriceDefault;
        this.priceCoefficient += p.expertOpinionCompensationPrice;
      }

      if (this.data.opinion.expertOpinionVegetation) {
        this.priceDefault += p.expertOpinionVegetationPriceDefault;
        this.priceCoefficient += p.expertOpinionVegetationPrice;
      }

      if (this.data.opinion.expertOpinionBuildings) {
        this.priceDefault += p.expertOpinionBuildingsPriceDefault;
        this.priceCoefficient += p.expertOpinionBuildingsPrice;
      }
    });
  }

  private computeFromExisting() {
    this.data.sum = { ...PricingExpertOpinionSummaryComponent.DEFAULT_SUM };

    this.data.opinion.parcelPrices.forEach(parcelPrice => {
      const expertOpinionVegetationPriceDefault = (parcelPrice.expertOpinionVegetationPriceDefault && this.data.opinion.expertOpinionVegetation ? parcelPrice.expertOpinionVegetationPriceDefault : 0);
      const expertOpinionBuildingsPriceDefault = (parcelPrice.expertOpinionBuildingsPriceDefault && this.data.opinion.expertOpinionBuildings ? parcelPrice.expertOpinionBuildingsPriceDefault : 0);
      const expertOpinionCompensationPriceDefault = (parcelPrice.expertOpinionCompensationPriceDefault && this.data.opinion.expertOpinionCompensation ? parcelPrice.expertOpinionCompensationPriceDefault : 0);
      const expertOpinionLandPriceDefault = (parcelPrice.expertOpinionLandPriceDefault && this.data.opinion.expertOpinionLand ? parcelPrice.expertOpinionLandPriceDefault : 0);

      parcelPrice.expertOpinionParcelPriceDefault = expertOpinionLandPriceDefault + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault + expertOpinionCompensationPriceDefault;
      parcelPrice.expertOpinionParcelPriceDefaultRounded = (parcelPrice.expertOpinionLandPriceDefault || 0) + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault + expertOpinionCompensationPriceDefault;

      this.data.sum.priceDefault += parcelPrice.expertOpinionParcelPriceDefault;
      this.data.sum.priceDefaultRounded += parcelPrice.expertOpinionParcelPriceDefaultRounded;

      this.data.sum.priceCoefficient += Math.round(
        (parcelPrice.expertOpinionLandPriceCoefficient || 0)
        + (parcelPrice.expertOpinionVegetationPrice || 0)
        + (parcelPrice.expertOpinionBuildingsPrice || 0)
        + (parcelPrice.expertOpinionCompensationPrice || 0)
      );

      this.data.sum.priceCoefficientRounded +=
        (parcelPrice.expertOpinionLandPrice || 0)
        + (parcelPrice.expertOpinionVegetationPrice || 0)
        + (parcelPrice.expertOpinionBuildingsPrice || 0)
        + (parcelPrice.expertOpinionCompensationPrice || 0);
    });
  }
}
