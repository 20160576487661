import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '../common/common.module';
import { WantedSwitchComponent } from '@app/dimap/components/wanted-switch/wanted-switch.component';
import { MapModuleIntersectionComponent } from '@app/dimap/map/components/map-module-intersection/map-module-intersection.component';
import { MapModuleCutParcelsComponent } from '@app/dimap/map/components/map-module-cut-parcels/map-module-cut-parcels.component';
import { ParcelAreaComponent } from '@app/dimap/map/components/parcel-area/parcel-area.component';
import { MultiSceneCloudComponent } from '@app/dimap/map/components/multi-scene-cloud/multi-scene-cloud.component';
import { CellAreaComponent } from '@app/dimap/components/cell-area/cell-area.component';
import { TooltipIntersectionAreaComponent } from '@app/dimap/components/tooltip-intersection-area/tooltip-intersection-area.component';

import { RoadSectionNamePipe } from './pipes/road-section-name.pipe';
import { RoadNamePipe } from '@app/dimap/pipes/road-name.pipe';


@NgModule({
  imports: [
    UIRouterModule,
    CommonModule,
  ],
  providers: [],
  declarations: [

    WantedSwitchComponent,
    MapModuleIntersectionComponent,
    MapModuleCutParcelsComponent,
    ParcelAreaComponent,
    MultiSceneCloudComponent,
    RoadSectionNamePipe,
    RoadNamePipe,
    CellAreaComponent,
    TooltipIntersectionAreaComponent,
  ],
  entryComponents: [
    MultiSceneCloudComponent,
    TooltipIntersectionAreaComponent,
  ],
  exports: [
    WantedSwitchComponent,
    MapModuleIntersectionComponent,
    MapModuleCutParcelsComponent,
    ParcelAreaComponent,
    MultiSceneCloudComponent,
    RoadSectionNamePipe,
    RoadNamePipe,
    CellAreaComponent,
    TooltipIntersectionAreaComponent,
  ],
})
export class DimapCommonModule {}
