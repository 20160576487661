<div [loading]="loading">
  <ng-container *ngIf="data.customData.notStartedParcelIds.length > 0 && !loading && data?.list?.list">
    <span>Parcely k řešení</span>
    <ul>
      <ng-container *ngFor="let parcel of getNotStartedParcels()">
        <li>
          <a uiSref="symap.project.parcels.detail" [uiParams]="{id: parcel.id}">{{parcel|parcelnumber}}</a>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="data.customData.inProgressParcelIds.length > 0 && !loading && data?.list?.list">
    <span>Řešené parcely</span>
    <ul>
      <ng-container *ngFor="let parcel of getInProgressParcels()">
        <li>
          <a uiSref="symap.project.parcels.detail" [uiParams]="{id: parcel.id}">{{parcel|parcelnumber}}</a>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="data.customData.doneParcelIds.length > 0 && !loading && data?.list?.list">
    <span>Vyřešené parcely</span>
    <ul>
      <ng-container *ngFor="let parcel of getDoneParcels()">
        <li>
          <a uiSref="symap.project.parcels.detail" [uiParams]="{id: parcel.id}">{{parcel|parcelnumber}}</a>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>

