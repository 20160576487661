import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { PropertyStatusComponent } from '@app/dimap/property-status/property-status/property-status.component';

export const propertyStatusState: Ng2StateDeclaration = {
  name: 'dimap.project.propertyStatus',
  data: {
    title: 'Majetkoprávní přehled',
  },
  url: '/property-status?page&requestid',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
    requestid: {
      type: 'int',
      inherit: false,
    }
  },
  views: {
    'content@dimap.project': {
      component: PropertyStatusComponent,
    }
  },
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        propertyStatusState,
      ],
    }),
    CommonModule,
    DimapCommonModule,
  ],
  providers: [],
  declarations: [
    PropertyStatusComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class PropertyStatusModule {}
