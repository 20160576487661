<div class="detail">
  <div>
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 160px;">
        <gmt-back-button sref="^" text="Detail objednatele">
        </gmt-back-button>
      </div>
    </div>

    <h1>{{ pageTitle }} <span *ngIf="customer.companyName">{{customer.companyName}}</span></h1>

    <customer-form (submit)="onSubmit()" [editable]="true" [customer]="customer"></customer-form>
  </div>

