<h1>Vložení dokumentu</h1>
<fieldset>
  <div class="column-100">
    <label class="required">Název</label>
    <input class="info" type="text" [(ngModel)]="baseName"/>
    <ng-container *ngIf="allowType">
      <label class="required">Typ</label>
      <div class="info">
        <div class="checklist">
          <div class="checklist-item" *ngFor="let documentType of documentTypes" (click)="!documentType.disabled && onUpdateType(documentType)">
            <gmt-radio [gmtChecked]="documentType.id === selectedDocumentType.id" [gmtDisabled]="!!documentType.disabled"></gmt-radio>
            <span [ngClass]="{ disabled: !!documentType.disabled }">
              <i [ngClass]="['ml-10 mr-5 fa ' + documentType.icon]"></i>
              {{ documentType.title }}
              <span *ngIf="documentType.extensions">(<span class="comma-list"><span *ngFor="let extension of documentType.extensions">{{extension}}</span></span>)</span>
            </span>
            <span *ngIf="!!documentType.disabled" class="error-message"> ({{ documentType.disabled }})</span>
          </div>
        </div>
      </div>
    </ng-container>
    <label>Poznámka k dokumentu</label>
    <textarea [(ngModel)]="note"></textarea>
  </div>
</fieldset>
<div class="actions">
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Vložit dokument</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
