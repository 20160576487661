<div class="detail">
  <gmt-button [clickAction]="goToDetail" class="mb-10"><i class="fa fa-chevron-left mr-5"></i> Zpět</gmt-button>
  <div [loading]="!occupationObject">
    <ng-container *ngIf="occupationObject">
      <h1>Editace záboru
        <span>{{occupationTypeName}} / {{occupationObject.parcel|parcelnumber}}</span>
      </h1>
      <div class="fieldset" *ngIf="!occupationLongerThanOneYear() && !occupationShorterThanOneYear()">
        <div class="legend">Způsob řešení záboru</div>
        <gmt-switch [options]="occupationSolutionOptions" [(selectedId)]="occupationObject.solutionType">
        </gmt-switch>
      </div>
      <div class="fieldset" *ngIf="occupationObject.solutionType || occupationLongerThanOneYear() || occupationShorterThanOneYear()">
        <div class="legend">Způsob určení ceny</div>
        <gmt-switch [options]="priceType" [(selectedId)]="occupationObject.rentalPriceType"></gmt-switch>
      </div>
      <div class="fieldset" *ngIf="occupationObject.solutionType || occupationLongerThanOneYear() || occupationShorterThanOneYear()">
        <div class="legend">Cena</div>
        <div class="clearfix">
          <div class="column-100">
            <label class="required">Délka nájmu [<span class="unit">měsíce</span>]</label>
            <div class="input"><input type="text" [(ngModel)]="occupationObject.rentalLength" gmtInputNumber/></div>
          </div>

          <div class="column-100">
            <ng-container  *ngIf="occupationObject.rentalPriceType === 'F'">
              <label class="required">Rok cenového výměru MF</label>
              <div class="input"><input type="text" [(ngModel)]="occupationObject.priceAssessmentYear" gmtInputNumber/></div>
            </ng-container>

            <ng-container  *ngIf="occupationObject.rentalPriceType === 'E'">
              <label class="required">Číslo znaleckého posudku</label>
              <div class="input"><input type="text" [(ngModel)]="occupationObject.rentalExpertOpinionNumber"/></div>

              <label class="required">Datum znaleckého posudku</label>
              <div class="input"><input type="text" pick-a-date [(ngModel)]="occupationObject.rentalExpertOpinionDate"/></div>
            </ng-container>

            <ng-container  *ngIf="occupationObject.rentalPriceType === 'O'">
              <label class="required">Původ ceny</label>
              <div class="input"><input type="text" [(ngModel)]="occupationObject.rentalOtherPriceSource"/></div>
              <div class="column-100">
                <label class="required">Způsob výpočtu ceny</label>
                <gmt-switch [options]="priceUnit" [(selectedId)]="occupationObject.rentalPriceUnit"></gmt-switch>
              </div>
            </ng-container>

            <ng-container *ngIf="occupationObject.rentalPriceType === 'O' && occupationObject.rentalPriceUnit === 'T'">
              <label class="required">Cena za pozemek [<span class="unit">Kč</span>]</label>
              <div class="input"><input type="text" [(ngModel)]="occupationObject.totalRentPrice" gmtInputNumber/></div>
            </ng-container>

            <ng-container *ngIf="occupationObject.rentalPriceType !== 'O' || occupationObject.rentalPriceUnit !== 'T'">
              <label class="required">Cena za pozemek [<span class="unit">Kč/m<sup>2</sup>/rok</span>]</label>
              <div class="input"><input type="text" [(ngModel)]="occupationObject.priceAssessmentLandPrice" gmtInputNumber/></div>
            </ng-container>
          </div>
        </div>
      </div>

      <expert-select-form *ngIf="(occupationObject.solutionType || occupationLongerThanOneYear() || occupationShorterThanOneYear()) && occupationObject.rentalPriceType === 'E'" [expert]="occupationObject.rentalExpert" (changed)="onChangeExpert($event)"></expert-select-form>

      <div>
        <gmt-button [clickAction]="update" [gmtDisabled]="!isValid()">Uložit</gmt-button>
      </div>
    </ng-container>
  </div>
</div>
