import { Component, Input, OnInit } from '@angular/core';
import { SeriesModel } from '@app/ps/models/series.model';
import * as _ from 'lodash';

@Component({
  selector: 'series-list',
  templateUrl: './series-list.component.html',
})
export class SeriesListComponent implements OnInit {
  @Input() seriesList: SeriesModel[] = [];
  @Input() withConstructionObjectSelection = true;

  ngOnInit() {
    this.onCancelSeries = this.onCancelSeries.bind(this);
    this.onRestoreSeries = this.onRestoreSeries.bind(this);
    this.onMoveSeries = this.onMoveSeries.bind(this);
    this.onChangeSeries = this.onChangeSeries.bind(this);
    this.onAddSeries = this.onAddSeries.bind(this);
  }

  onCancelSeries(series: SeriesModel) {
    if (series.series.id) {
      series.series.cancelled = true;
    } else {
      _.remove(this.seriesList, series);
    }
  }

  onRestoreSeries(series: SeriesModel) {
    series.series.cancelled = false;
  }

  onMoveSeries(series: SeriesModel, step: number) {
    const i = _.indexOf(this.seriesList, series);
    if (i + step < 0 || i + step >= this.seriesList.length) {
      return;
    }
    this.seriesList[i] = this.seriesList.splice(i + step, 1, this.seriesList[i])[0];
  }

  onChangeSeries(series: SeriesModel) {
    const i = _.indexOf(this.seriesList, series);
    this.seriesList[i] = series;
  }

  onAddSeries() {
    this.seriesList.push(SeriesModel.createDefault());
  }
}
