import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'expropriation-result',
  templateUrl: './expropriation-result.component.html',
  styleUrls: ['./expropriation-result.component.scss']
})
export class ExpropriationResultComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: any;
  step: any;
  expropriationRejectedName: string;
  expropriationAcceptedName: string;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.setStep = this.setStep.bind(this);
    this.onRejected = this.onRejected.bind(this);
    this.onAccepted = this.onAccepted.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  async ngOnInit() {
    this.data = {
      expropriationResultDate: new Date(),
      expropriationResultDeliveryDate: new Date(),
      expropriationResultType: null,
      expropriationAppealDate: null,
      expropriationResultRealizationDeadlineDate: null,
      expropriationAmount: null,
      expropriationAmountSource : null
    };

    this.expropriationRejectedName = (await this.caseStatusService.loadStatusName('ExpropriationRejected')).name;
    this.expropriationAcceptedName = (await this.caseStatusService.loadStatusName('ExpropriationAccepted')).name;
    this.loading = false;
  }

  setStep(step) {
    this.step = step;
  }

  async onRejected() {
    if (this.data.expropriationResultDate &&
      this.data.expropriationResultDeliveryDate &&
      this.data.expropriationAppealDeadlineDate
    ) {
      await this.restangular
        .one('cases', this.caseId)
        .customPUT(this.data, 'expropriation-rejected')
        .toPromise();
      this.stateService.go('^');
    }
  }

  async onAccepted() {
    if (this.data.expropriationResultDate &&
      this.data.expropriationResultDeliveryDate &&
      this.data.expropriationAppealDeadlineDate &&
      this.data.expropriationResultRealizationDeadlineDate &&
      this.data.expropriationAmount &&
      this.data.expropriationAmountSource &&
      this.data.dueDate
    ) {
      await this.restangular
        .one('cases', this.caseId)
        .customPUT(this.data, 'expropriation-accepted')
        .toPromise();
      this.stateService.go('^');
    }
  }
}
