<ng-template #parcelCell>
  <div class="fill-content flexbox-container {{cellClass}}">
    <ng-container *ngIf="parcel.parcelnumber">
      {{ parcel.parcelnumber }}
    </ng-container>
    <ng-container *ngIf="!parcel.parcelnumber">
      <div *ngIf="parcel.endDate" class="mr-10"><span class="error-message"><i class="fa fa-exclamation-triangle"></i></span></div>
      <div [ngClass]="{ cancelled: parcel.endDate }">
        <b>{{ parcel | parcelnumber }}</b>
        <div *ngIf="!parcel.dilvym">
          {{ parcel.vymera }}
          <span class="unit">m<sup>2</sup></span>
        </div>
        <div title="Druh pozemku">
          {{ parcel.drupozNazev }}
        </div>
        <div *ngIf="parcel.dilvym" title="Díl podle GP [m2]">
          {{ parcel.dilvym }} <span class="unit">m<sup>2</sup></span>
        </div>
        <div *ngIf="parcel.dilgp">
          (díl {{ parcel.dilgp }})
        </div>
        <div *ngIf="parcel.zpusobVyuziti" title="Způsob využití">
          ({{ parcel.zpusobVyuziti }})
        </div>
        <div *ngIf="showTitle && parcel.title">LV{{ parcel.title | titleName }}</div>
        <div *ngIf="showProtection && parcel.parcelProtectionsText" title="Ochrana">
          {{ parcel | parcelprotection }}
        </div>
      </div>
      <span class="cell--flex__container_side" *ngIf="parcel.building && parcel.building.cisloTel !== parcel.lv">
        <span title="Budova na jiném LV" class="tag tag--inactive tag--small ml-5">cBUD</span>
      </span>
      <div class="ml-5 flex-rest align-items-end flex-column flexbox-container" *ngIf="parcel.endDate && occupation && !occupation.endDate && updateOccupationParcelPath" gmtAuth="admin">
        <a [uiSref]="updateOccupationParcelPath" [uiParams]="{ occupationId: occupation.id, page: null }" class="button"><i class="fa fa-pencil"></i></a>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="!tooltipComponent || !tooltipData">
  <ng-container *ngTemplateOutlet="parcelCell"></ng-container>
</ng-container>

<div *ngIf="tooltipComponent && tooltipData"
     [tooltip]="tooltipComponent"
     [data]="tooltipData"
     [activeContent]="true"
     class="tooltip-hover-area fill-content"
>
  <ng-container *ngTemplateOutlet="parcelCell"></ng-container>
</div>
