import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { Restangular } from 'ngx-restangular';

import { EasementModel } from '@app/common/models/easement.model';
import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';
import { SettlementProgressIds } from '@app/ps/models/settlement-progress';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'tooltip-easements',
  templateUrl: './tooltip-easements.component.html',
})
export class TooltipEasementsComponent extends TooltipAbstractListComponent<EasementModel> {
  protected resource = 'easements';
  public doneParcels: EasementModel[];
  public notStartedParcels: EasementModel[];
  public inProgressParcels: EasementModel[];
  public loadingEasements = true;

  constructor(
    private restangular: Restangular,
    cdr: ChangeDetectorRef,
    listLoadService: ListService,
    @Inject('tooltipConfig') tooltipConfig: any
  ) {
    super(cdr, listLoadService, tooltipConfig);
    this.map = this.map.bind(this);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.data.list.promise.then(async () => {
      [this.doneParcels, this.notStartedParcels, this.inProgressParcels] = await Promise.all([
        this.getDoneParcels(),
        this.getNotStartedParcels(),
        this.getInProgressParcels()
      ]);

      this.loadingEasements = false;
      this.cdr.detectChanges();
    });
  }

  async map(ids: SettlementProgressIds): Promise<EasementModel> {
    if (!this.data.list) {
      return;
    }
    const easement: EasementModel = this.data.list.list.find(e => e.id == ids.easementId);
    if (!easement.parcel) {
      easement.parcel = await this.restangular.one('parcels', ids.parcelId).get().toPromise();
    }
    return easement;
  }

  async getDoneParcels(): Promise<EasementModel[]> {
    return await Promise.all(<Promise<EasementModel>[]> this.data.customData.doneParcelIds.map(this.map));
  }

  async getNotStartedParcels(): Promise<EasementModel[]> {
    return await Promise.all(<Promise<EasementModel>[]> this.data.customData.notStartedParcelIds.map(this.map));
  }

  async getInProgressParcels(): Promise<EasementModel[]> {
    return await Promise.all(<Promise<EasementModel>[]> this.data.customData.inProgressParcelIds.map(this.map));
  }
}

