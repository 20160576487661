<div class="statistics exports">
  <h1 class="mb-20">Uživatelské exporty</h1>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <div class="statistic-line" *ngFor="let exportCfg of exports; index as i" [ngClass]="{ active: i === displayed }">
        <gmt-export-form
          [exportCfg]="exportCfg"
          [displayedPrevious]="(i - 1) === displayed"
          [displayed]="i === displayed"
          (displayChanged)="displayed = i">
        </gmt-export-form>
      </div>
    </div>
  </div>
  <p *ngIf="!loading && exports.length === 0">Žádné exporty nejsou ve zvoleném projektu dostupné</p>
  <help [helpId]="helpIds.PANEL_EXPORTS" class="cm-help-page"></help>
</div>
