<div *ngIf="!changelogList.loading && changelogList.itemCount">
  <div *ngFor="let changelog of changelogList.list" (mouseenter)="collapsed = false" (mouseleave)="collapsed = true" class="changelog-container">
    <div class="changelog-item">
      <div class="changelog-icon">
        <i class="fa fa-info-circle"></i>
      </div>
      <div class="changelog-name">
        {{ changelog.publishDate | date:'mediumDate' }} - {{ changelog.name }}
      </div>
      <div class="changelog-accept" (click)="onAccept(changelog)">
        <a><i class="fa fa-times"></i></a>
      </div>
    </div>
    <div *ngIf="!collapsed && changelog.description" [innerHtml]="getPreview(changelog.description)" class="changelog-description"></div>
  </div>
</div>
