import { TitleModel } from '@app/common/models/title.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { OpinionModel } from '@app/ps/models/opinion.model';

export class OpinionRequestModel {
  id: number;
  name: string;
  status: string;
  title: TitleModel;
  createdDate: string;
  updatedDate: string;
  deletedDate: string;
  realizationStartedDate: string;
  expert: any;
  realizationFinishExpectedDate: string;
  realizedDate: string;
  realizationPrice: number;
  parcels: ParcelModel[];
  buildings: any[];
  geometricPlanRequest: GeometricPlanRequestModel;
  opinion: OpinionModel;
  nextStatuses: string[];
  countParcels: number;
  lastStatusChangeDate: string;
}
