import { Directive, Inject, OnInit, OnDestroy, HostListener, Input, ViewContainerRef} from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { LeafletMapService } from '@app/map/services/leaflet-map.service';
import ResizeObserver from '@juggle/resize-observer';
 import * as  L from 'leaflet';

@Directive({
  selector: '[leafletMap]'
})
export class LeafletMapDirective implements OnInit, OnDestroy {

  @Input()
  options;
  @Input()
  config;

  map = null;

  constructor(
    private mapService : MapService,
    private readonly viewRef: ViewContainerRef,
    private leafletMapService : LeafletMapService,
  ) {

  }

  ngOnInit() {
    try {
      var options = this.options,
          config = this.config,
          mapElement = this.viewRef.element.nativeElement,
          mapId = this.options.id;

      mapElement.setAttribute('id', mapId);
      this.leafletMapService.setOptionsCrs(options);
      this.map = new L.Map(mapElement, options);

      this.map.whenReady(() => {
          this.leafletMapService.enableLoadingWatch(mapId);
          this.leafletMapService.initLayers(mapId, config);
          this.leafletMapService.setBounds(mapId, options);
          this.leafletMapService.setInitMapPos(this.map);
          this.leafletMapService.setFullscreen(this.map, options);
          this.leafletMapService.storeMap(this.map, mapId);
          this.leafletMapService.storeOptions(options, mapId);
          this.mapService.setPositionListener(mapId, options);
      });


      // this.map.on('zoomend', function() {
      //     MapLayerVisibility.checkLayersVisibility(this.config, this.map);
      // });

    } catch(e){
        console.error(e);
    }

    new ResizeObserver(this.doResizeCheck).observe(this.viewRef.element.nativeElement);
  }

  doResizeCheck() {
    if (this.map) {
        this.map.invalidateSize();
    }
  }

  ngOnDestroy() {
      if (this.map) {
          this.map.remove();
      }

      this.viewRef.element.nativeElement.remove();
      this.leafletMapService.resetMap(this.options.id);
      this.leafletMapService.resetOptions(this.options.id);
      this.leafletMapService.resetLayers(this.options.id);
  }
}
