import { Pipe, PipeTransform, Injectable, OnInit, Inject } from "@angular/core";
import { ConstructionObjectModel } from '../models/construction-object.model';
import { AuthService } from '../services/auth.service';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe'
import { EVENTS } from '@app/common/services/config.service';

const getsocis = (co) => co.socis ? `${co.socis} - ` : '';
const getsoucsek = (co, ETAPA_USEK) => co.sousek ? ` (${ETAPA_USEK} ${co.sousek})` : '';
const getsotx = (co) => co.sotx || '';
const format = (co, translation) => `${getsocis(co)}${getsotx(co)}${getsoucsek(co, translation)}`;

/**
 * Display name of contruction object
 */
@Pipe({
  name: 'constructionObjectName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class ConstructionObjectNamePipe implements PipeTransform {
  private translation = {};

  constructor(
    private authService: AuthService,
    private wordTranslatePipe: WordTranslatePipe,
  ) {
    this.transform = this.transform.bind(this);
    this.authService.registerCallback(EVENTS.authProjectUpdated, () => {
      this.translation = {};
    });
  }

  transform(constructionObject: ConstructionObjectModel) {
    const id = constructionObject.id;
    if (!this.translation[id] || this.translation[id] === '-') {
      const translated = this.wordTranslatePipe.transform('ETAPA_USEK');
      if (translated) {
        this.translation[id] = format(constructionObject, translated);
      } else {
        this.translation[id] = '-';
      }
    }
    return this.translation[id];
  }
}
