import { Component, Input } from '@angular/core';

import { ParcelModel } from '../../models/parcel.model';

export interface TooltipDimapParcelComponentData {
  parcel: ParcelModel;
}

@Component({
  selector: 'tooltip-dimap-parcel',
  templateUrl: './tooltip-dimap-parcel.component.html',
})
export class TooltipDimapParcelComponent {
  @Input() data: TooltipDimapParcelComponentData;
}
