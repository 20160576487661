import { Component, Input } from '@angular/core';

import { ParcelOccupationGroupType, TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { AuthService } from '@app/common/services/auth.service';

@Component({
  selector: 'table-occupations-permanent',
  templateUrl: './table-occupations-permanent.component.html',
  styleUrls: ['./table-occupations-permanent.component.scss']
})
export class TableOccupationsPermanentComponent extends TableParcelsAbstractComponent<ParcelOccupationGroupType> {

  @Input() updateOccupationParcelPath: string;
  geometricPlanModuleEnabled: boolean;

  protected readonly COMPONENT_COLUMNS = [
    'label_gp', 'occupation_area', 'occupation_title', 'occupation_parcel', 'occupation_parcel_title',
    'occupation_concern_object', 'occupation_phase', 'occupation_occupation', 'occupation_cases', 'occupation_cases_parcel',
    'occupation_price_parcel', 'occupation_price_parcel_cases', 'occupation_price_vegetation', 'occupation_price_vegetation_cases',
    'occupation_price_construction', 'occupation_price_construction_cases', 'occupation_price_summary', 'occupation_price_summary_cases',
    'current_title', 'current_parcel', 'current_parcel_title', 'current_protection', 'current_concern_object',
    'occupation_switch', 'update_occupation'
  ];

  constructor(protected authService: AuthService) {
    super();

    this.geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
  }

  canEdit(permissions: string): boolean {
    return this.authService.hasPermission(permissions);
  }

  isCaseParcelPriceValid(caseParcel: any): boolean {
    return !isNaN(caseParcel.landPrice + caseParcel.vegetationPrice + caseParcel.buildingsPrice);
  }
}
