<div class="list">
  <div class="actions">
    <a [gmtAuth]="'manage_opinions'" uiSref="symap.project.opinionRequests.create" class="button">
      <i class="fa fa-plus-square"></i>Založit nový znalecký posudek
    </a>
  </div>

  <h1>Znalecké posudky</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">

    <!-- katastralni uzemi -->
    <gmt-select
      resource="areas"
      [filter]="{ sortOrder: { sortBy: 'name' }}"
      [data]="list.filter.filters.areaId"
      field="values"
      [multiselect]="true"
      selectTitle="Katastrální území"
    ></gmt-select>

    <!-- stav ze -->
    <gmt-select
      [optionItems]="statusOptions"
      [data]="list.filter.filters.status"
      field="values"
      [multiselect]="true"
      selectTitle="Stav ZE"
    ></gmt-select>

    <!-- LV -->
    <gmt-select
      resource="titles"
      [data]="list.filter.filters.titleId"
      field="values"
      [multiselect]="true"
      [itemPipe]="titleNamePipe"
      selectTitle="LV"
    ></gmt-select>

    <!-- parcely -->
    <gmt-select
      resource="parcels"
      [filter]="{ filters: { validity: 'valid' }}"
      [data]="list.filter.filters.parcelId"
      field="values"
      [multiselect]="true"
      [itemPipe]="parcelNumberPipe"
      selectTitle="Parcela"
    ></gmt-select>

    <!-- Odhadci -->
    <gmt-select
      resource="experts"
      [data]="list.filter.filters.expertId"
      field="values"
      [multiselect]="true"
      [itemPipe]="subjectNamePipe"
      selectTitle="Znalec"
    ></gmt-select>

    <div class="tag" [ngClass]="{ active: !list.filter.filters.deleted.values.length }" title="Zobrazení vč. ZP, které jsou smazané" (click)="onToggleDeleted()">
      <div>Zobrazit zrušené ZP</div>
    </div>

    <div>
      <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list.itemCount && settingsLoaded">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">

            <!-- k.u. -->
            <span class="cell" *ngIf="!hideColumn('area')">
              <sort-by
                [selected]="'title.area.name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('title.area.name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Katastrální území</sort-by>
            </span>

            <!-- LV -->
            <span class="cell" *ngIf="!hideColumn('lv')">
              <sort-by
                [selected]="'title.lv' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('title.lv', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >LV</sort-by>
            </span>

            <!-- cislo ZP -->
            <span class="cell" *ngIf="!hideColumn('name')">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Číslo ZP</sort-by>
            </span>

            <!-- znalec -->
            <span class="cell" *ngIf="!hideColumn('expert')">
              <sort-by
                [selected]="'expert' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('expert', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Znalec</sort-by>
            </span>

            <!-- stav -->
            <span class="cell" *ngIf="!hideColumn('status')">
              <sort-by
                [selected]="'status' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('status', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Stav</sort-by>
            </span>

            <!-- k datu -->
            <span class="cell" *ngIf="!hideColumn('date')">
              <sort-by
                [selected]="'sortDate' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('sortDate', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >K datu</sort-by>
            </span>

            <!-- resene parcely -->
            <span class="cell" *ngIf="!hideColumn('parcels')">Řešené parcely</span>
          </div>
        </div>

        <div class="row-group">
          <a class="row" *ngFor="let opinion of list.list"  uiSref="symap.project.opinionRequests.detail" [uiParams]="{ id: opinion.id, tab: 'parcels' }">

            <!-- katastralni uzemi -->
            <span class="cell" *ngIf="!hideColumn('area')">
              {{ opinion.title && opinion.title.area ? opinion.title.area.name : '' }}
            </span>

            <!-- LV -->
            <span class="cell" *ngIf="!hideColumn('lv')">
              <a *ngIf="opinion.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: opinion.title.id, tab: null }" (click)="$event.stopPropagation()">
                {{ opinion.title.lv }}
              </a>
            </span>

            <!-- cislo ZP -->
            <span class="cell" *ngIf="!hideColumn('name')">{{ opinion.name }}</span>

            <!-- znalec -->
            <span class="cell" *ngIf="!hideColumn('expert')">{{ opinion.expert | subjectName }}</span>

            <!-- stav -->
            <span class="cell" *ngIf="!hideColumn('status')">{{ requestService.getStatusEnumLabel(opinion.status) }}</span>

            <!-- k datu -->
            <span class="cell text-center" *ngIf="!hideColumn('date')">{{ (opinion.updatedDate ? opinion.updatedDate : opinion.createdDate) | date }}</span>

            <!-- resene parcely -->
            <span class="cell no-padding" *ngIf="!hideColumn('parcels')">
              <cell-parcels
                [countParcels]="opinion.countParcels"
                [tooltipType]="'parcels'"
                [tooltipData]="{ filter: { filters: { opinionRequestId: opinion.id }}}"
              ></cell-parcels>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>

  <help [helpId]="helpIds.OPINION_REQUEST_LIST" class="cm-help-page"></help>
</div>
