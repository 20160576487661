import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { SortModel } from '@app/common/models/sort.model';
import { ProjectsColumnDefinitionModel } from '@app/landing/models/projects-column-definition.model';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { ProjectsService } from '@app/landing/services/projects.service';
import { ProjectDataModel } from '@app/landing/models/project-data.model';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { ProjectColumnsEditComponent } from '@app/landing/components/project-columns-edit/project-columns-edit.component';
import { ListModel } from '@app/common/models/list.model';
import { SelectItem } from '@app/common/components/select/select.component';
import { XlsxService } from '@app/common/services/xlsx.service';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS, UICONSTANTS} from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'project-list',
  styleUrls: ['./project-list.component.scss'],
  templateUrl: './project-list.component.html',
})
export class ProjectListComponent implements OnInit, OnDestroy {

  loading = true;
  projects: ProjectDataModel[];
  list: ListModel<ProjectDataModel>;
  columns: ProjectsColumnDefinitionModel[];
  selectedColumns: any;
  sortedColumns: any;
  sortOrder: SortModel = { sortBy: 'isprofond', direction: 'asc' };
  itemsPerPage = UICONSTANTS.itemsPerPage10;
  dataPaging: any = { itemsPerPage: { id: 10, name: 10 }};
  onListChangedPagination: Function;
  onListChangedItemCounter: Function;
  onListChangedPagesCounter: Function;
  statuses = [ 'CREATED', 'STARTED_PARTIAL', 'STARTED_COMPLETE', 'PAUSED', 'FINISHED' ];
  private xhrCancelResolve: Function;
  private xhrDashCancelResolve: Function;
  private dashboard;
  @ViewChild('projectList') tableEl: ElementRef;
  private scrolling;

  private static readonly DEFAULT_COLUMNS = [
    'isprofond',
    'name',
    'manager',
    'investor',
    'type',
    'region',
    'donePercentage',
    'stateName',
    'projectStartDate',
    'lastActivityDate',
    'lastActivityAuthor',
    'propertyActivityProcessorName',
    // 'total',
    // 'done',
    // 'expropriationTotal',
  ];

  filtersData: any = {};
  searchText: string;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APP_CONFIG) private config: any,
    private localStorageService: LocalStorageService,
    private dialogService: DialogService,
    private authService: AuthService,
    private xlsxService: XlsxService,
    private brandTranslatePipe: BrandTranslatePipe,
    private projectsService: ProjectsService,
    private httpService: HttpService,
  ) {
    this.reloadList = this.reloadList.bind(this);
    this.ngOnInit = this.ngOnInit.bind(this);
  }

  async ngOnInit() {
    this.columns = this.getColumnDefinitions();

    // load settings from cache
    const selectedColumns = this.localStorageService.get('syProjectListSelectedColumns_4');
    const sortedColumns = this.localStorageService.get('syProjectListSortedColumns_4');
    const dataPaging = this.localStorageService.get('syProjectListPageItems_4');
    const sortOrder = this.localStorageService.get('syProjectListSortOrder_4');

    if (selectedColumns && sortedColumns) {
      this.selectedColumns = selectedColumns;
      this.sortedColumns = sortedColumns;
    } else {
      this.selectedColumns = ProjectListComponent.DEFAULT_COLUMNS;
      this.sortedColumns = this.columns.map(c => c.id);
    }

    if (dataPaging) {
      this.dataPaging = dataPaging;
    }

    if (sortOrder) {
      this.sortOrder = sortOrder as SortModel;
    }

    this.sortColumns();

    // project data
    await this.reloadProjectData();

    // dashboard data
    const cancelPromise = new Promise<any>((resolve, reject) => {
      this.xhrDashCancelResolve = resolve;
    });

    this.httpService.call({
      path: 'dashboard',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
      method: 'POST',
      cancelPromise: cancelPromise
    }).then(d => {
      this.dashboard = d.data;
      this.mapDashboardToProjects();
    });

    $('#content-wrap').on('scroll', () => { this.onScroll(); });
  }

  ngOnDestroy() {
    if (this.xhrCancelResolve) {
      this.xhrCancelResolve();
    }

    if (this.xhrDashCancelResolve) {
      this.xhrDashCancelResolve();
    }

    $('#content-wrap').unbind('scroll');
  }

  @HostListener('window:resize')
  onScroll() {
    clearTimeout(this.scrolling);
    this.scrolling = setTimeout(() => {
      const tableTop = this.tableEl.nativeElement.getBoundingClientRect().top;
      const ths = $(this.tableEl.nativeElement.querySelectorAll('thead th div'));
      if (tableTop < 80) {
        ths.css('top', (80 - tableTop) + 'px');
      } else {
        ths.css('top', '0px');
      }
    });
  }

  onEditColumns() {
    const dialog = this.dialogService.open(ProjectColumnsEditComponent, {
      data: {
        columns: [...this.columns],
        selectedColumns: [...this.selectedColumns],
      },
      className: ClassName.HIGHER_DIALOG
    });

    const sub = dialog.afterClosed.subscribe((result: { selectedColumns: string[], sortedColumns: string[] }) => {
      if (result) {
        this.localStorageService.set('syProjectListSelectedColumns_4', result.selectedColumns);
        this.localStorageService.set('syProjectListSortedColumns_4', result.sortedColumns);
        this.selectedColumns = result.selectedColumns;
        this.sortedColumns = result.sortedColumns;
        this.sortColumns();
        if (this.sortOrder && !this.selectedColumns.includes(this.sortOrder.sortBy)) {
          this.sortOrder = { sortBy: 'isprofond', direction: 'asc' };
          this.localStorageService.set('syProjectListSortOrder_4', this.sortOrder);
        }
        this.reloadProjectData();
      }
      sub.unsubscribe();
    });
  }

  onSort(sortBy: string) {
    this.sortOrder.direction = sortBy === this.sortOrder.sortBy
      ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
      : 'asc';
    this.sortOrder.sortBy = sortBy;
    this.localStorageService.set('syProjectListSortOrder_4', this.sortOrder);
    this.reloadList();
  }

  getFilterItemsByColumn(column: ProjectsColumnDefinitionModel): SelectItem[] {
    const columnItems = this.projects
      .map(p => { return { id: p[column.id], name: p[column.id] }; })
      .filter(i => i.id !== '' && i.id !== undefined)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    return _.uniqBy(columnItems, 'id');
  }

  reloadList() {
    const projects = this.sortAndFilterProjects();
    this.list.list = projects.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
    this.list.itemCount = projects.length;
    this.list.filter.limit = this.dataPaging.itemsPerPage.id;
    this.onListChanged();
  }

  onPageChanged(event) {
    this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
    this.reloadList();
  }

  getVisibleColumns(): ProjectsColumnDefinitionModel[] {
    return this.columns.filter(c => this.selectedColumns.includes(c.id));
  }

  pagesCounterCallbackRegister(event) {
    this.onListChangedPagesCounter = event.onListChanged;
  }

  itemCounterCallbackRegister(event) {
    this.onListChangedItemCounter = event.onListChanged;
  }

  paginationCallbackRegister(event) {
    this.onListChangedPagination = event.onListChanged;
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
    if (this.onListChangedItemCounter) {
      this.onListChangedItemCounter();
    }
    if (this.onListChangedPagesCounter) {
      this.onListChangedPagesCounter();
    }
  }

  onPageItemsChanged() {
    this.list.filter.offset = 0;
    this.localStorageService.set('syProjectListPageItems_4', this.dataPaging);
    this.reloadList();
  }

  onExport() {
    const data = this.projectsService.transformForExport(this.sortAndFilterProjects(), this.columns, this.selectedColumns);
    const cellOpinions = this.getVisibleColumns().map(c => c.exportStyle ? c.exportStyle : undefined);
    this.xlsxService.createFile(
      data,
      this.brandTranslatePipe.transform('ps.projectList') + '.xlsx',
      undefined,
      cellOpinions
    );
  }

  private reloadProjectData(): Promise<any> {
    const cancelPromise = new Promise<any>((resolve, reject) => {
      this.xhrCancelResolve = resolve;
    });

    const lastActivity =
      this.selectedColumns.indexOf('lastActivityDate') !== -1
      || this.selectedColumns.indexOf('lastActivityAuthor') !== -1;

    this.loading = true;

    return this.httpService.call({
      path: `users/${this.authService.getUser().id}/projects`,
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
      method: 'GET',
      params: { loadCollections: lastActivity ? ['lastActivity'] : [] },
      cancelPromise: cancelPromise
    }).then(d => {
        // @TODO - temp hide construction
        const projects = d.items.filter(p => p.key !== 'sy_statistics' && p.key !== 'sy_statistics_emh');

        this.projects = this.projectsService.createFromApi(projects);
        this.mapDashboardToProjects();
        this.initList();
        this.loading = false;
      });
  }

  private mapDashboardToProjects() {
    if (!this.dashboard) {
      return;
    }
    this.projects.forEach(project => {
      const dashboard = this.dashboard.find(dashboard => dashboard.key === project.key);
      if (dashboard) {
        ProjectsService.mapDashboardDataOnProject(project, dashboard);
      }
    });
  }

  private initList() {
    const projects = this.sortAndFilterProjects();

    this.list = {
      loading: false,
      list: projects.slice(0, this.dataPaging.itemsPerPage.id),
      itemCount: projects.length,
      filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
    };
  }

  private getColumnById(id: string): ProjectsColumnDefinitionModel {
    return this.columns.find(c => c.id === id);
  }

  private sortAndFilterProjects(): ProjectDataModel[] {
    let projects = this.projects;

    // filters
    for (const column of this.columns) {
      if (column.filterable && this.filtersData[column.id] && this.filtersData[column.id].length) {
        const filter = this.filtersData[column.id].map(i => i.id);
        projects = projects.filter(p => filter.includes(p[column.id]));
      }
    }

    // fulltext
    if (this.searchText) {
      const searchText = this.searchText.toLowerCase();
      projects = projects.filter(p => {
        for (const column of this.columns) {
          if (column.searchable) {
            if (p[column.id] && p[column.id].toLowerCase().indexOf(searchText) !== -1) {
              return true;
            }
          }
        }
        return false;
      });
    }

    // order
    if (!this.sortOrder) {
      return projects;
    }

    const sortColumn = this.getColumnById(this.sortOrder.sortBy);
    return _.orderBy(projects, this.getSortFunction(sortColumn), this.sortOrder.direction as any);
  }

  private getSortFunction(column: ProjectsColumnDefinitionModel): Function {
    return (p: ProjectDataModel): any => {
      const value = p[column.id];

      if (value === undefined) {
        return value;
      }

      if (column.customSort) {
        return column.customSort(value);
      }

      return typeof value === 'string' ? value.toLowerCase() : value;
    };
  }

  private getColumnDefinitions(): ProjectsColumnDefinitionModel[] {
    let columns = [
      {
        id: 'isprofond',
        name: 'Evidenční číslo',
        sortable: true,
        selectable: false,
        searchable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'word-break': 'break-all' },
      },
      {
        id: 'name',
        name: this.brandTranslatePipe.transform('ps.projectName'),
        sortable: true,
        selectable: false,
        searchable: true,
        style: { 'min-width': '300px' },
      },
      {
        id: 'manager',
        name: this.brandTranslatePipe.transform('ps.projectManager'),
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '160px', 'max-width': '160px' },
      },
      {
        id: 'investor',
        name: this.brandTranslatePipe.transform('ps.investor'),
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '165px', 'max-width': '165px' },
      },
      {
        id: 'customerOrganizationalUnitSection',
        name: this.brandTranslatePipe.transform('ps.unit'),
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '120px', 'max-width': '120px' },
      },
      {
        id: 'type',
        name: this.brandTranslatePipe.transform('sv.section'),
        sortable: true,
        filterable: true,
        style: { 'min-width': '120px', 'max-width': '120px' },
      },
      {
        id: 'templatesOrganizationalUnit',
        name: 'Přiřazení vzorů',
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '120px', 'max-width': '120px' },
      },
      {
        id: 'region',
        name: 'Kraj',
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '150px', 'max-width': '150px' },
      },
      {
        id: 'donePercentage',
        name: this.brandTranslatePipe.transform('ps.projectList.donePercentage'),
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'stateName',
        name: this.brandTranslatePipe.transform('ps.projectState'),
        sortable: true,
        searchable: true,
        filterable: true,
        style: { 'min-width': '100px', 'max-width': '100px' },
      },
      {
        id: 'projectStartDate',
        name: this.brandTranslatePipe.transform('ps.projectStartDate'),
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px' },
      },
      {
        id: 'lastActivityDate',
        name: 'Datum poslední změny',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'lastActivityAuthor',
        name: 'Autor poslední změny',
        sortable: true,
        style: { 'min-width': '160px' },
      },
      {
        id: 'propertyActivityProcessorName',
        name: this.brandTranslatePipe.transform('ps.propertyActivityProcessor') + ' (příjmení jméno)',
        sortable: true,
      },
      {
        id: 'total',
        name: this.brandTranslatePipe.transform('ps.projectList.total'),
        sortable: true,
        style: { 'min-width': '90px', 'max-width': '90px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'done',
        name: this.brandTranslatePipe.transform('ps.projectList.done'),
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'expropriationTotal',
        name: this.brandTranslatePipe.transform('ps.projectList.expropriationTotal'),
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'infrastructure',
        name: this.brandTranslatePipe.transform('ps.infrastructure'),
        sortable: true,
        filterable: true,
        searchable: true,
        style: { 'min-width': '120px', 'max-width': '120px' },
      },
      {
        id: 'tzTotal',
        name: 'TZ počet případů',
        sortable: true,
        style: { 'min-width': '90px', 'max-width': '90px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'tzDone',
        name: 'TZ vypořádáno případů',
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dznrTotal',
        name: 'DZ nad 1 rok počet případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dznrDone',
        name: 'DZ nad 1 rok vypořádáno případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dzdrTotal',
        name: 'DZ do 1 roku počet případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dzdrDone',
        name: 'DZ do 1 roku vypořádáno případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'vbTotal',
        name: 'VB počet případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'vbDone',
        name: 'VB vypořádáno případů',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'tzDonePercentage',
        name: 'Stav vypořádání trvalého záboru v %',
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dzdrDonePercentage',
        name: 'Stav vypořádání dočasného záboru do 1 roku v %',
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'dznrDonePercentage',
        name: 'Stav vypořádání dočasného záboru nad 1 rok v %',
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'vbDonePercentage',
        name: 'Stav vypořádání věcných břemen  v %',
        sortable: true,
        style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'readinessPermanentOccupations',
        name: 'Úplnost dat trvalého záboru',
        sortable: true,
        style: { 'min-width': '160px' },
      },
      {
        id: 'readinessTemporaryUnderOccupations',
        name: 'Úplnost dat dočasného záboru do 1 roku',
        sortable: true,
        style: { 'min-width': '160px' },
      },
      {
        id: 'readinessTemporaryOverOccupations',
        name: 'Úplnost dat dočasného záboru nad 1 rok',
        sortable: true,
        style: { 'min-width': '160px' },
      },
      {
        id: 'readinessTemporaryEasements',
        name: 'Úplnost dat věcných břemen',
        sortable: true,
        style: { 'min-width': '160px' },
      },
      {
        id: 'parcelTotal',
        name: 'Cekový počet parcel',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
        exportStyle: { style: { alignment: { horizontal: 'center' }}},
      },
      {
        id: 'phase',
        name: 'Stupeň',
        sortable: true,
        style: { 'min-width': '100px', 'max-width': '100px' },
      },
      {
        id: 'propertyActivityProcessorCompany',
        name: this.brandTranslatePipe.transform('ps.propertyActivityProcessor') + ' (firma)',
        sortable: true,
      },
      {
        id: 'landTakeDocumentationAuthorCompany',
        name: 'Zpracovatel záb. elaborátu (firma)',
        sortable: true,
      },
      {
        id: 'landTakeDocumentationAuthorName',
        name: 'Zpracovatel záb. elaborátu (příjmení jméno)',
        sortable: true,
      },
      {
        id: 'designerCompany',
        name: 'Projektant (firma)',
        sortable: true,
      },
      {
        id: 'designerName',
        name: 'Projektant (příjmení jméno)',
        sortable: true,
      },
    ];

    if (this.APP_BRAND.NAME === 'SZ') {
      const denyCols = ['infrastructure', 'unit'];
      return columns.filter(c => !denyCols.includes(c.id));
    }

    return columns;
  }

  private sortColumns() {
    this.columns = _.sortBy(this.columns, c => {
      return this.sortedColumns.indexOf(c.id);
    });
  }
}
