﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail-create-case-confirm',
	templateUrl: './create-case-confirm.component.html',
})
export class CreateCaseConfirmComponent implements OnInit {
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();
  public priceTotal: number = 0;
  public parcelCount: number = 0;

  constructor(
    private authService: AuthService,
    private restangular: Restangular,
  ) {
    this.create = this.create.bind(this);
  }

  ngOnInit() {
    this.parcelCount = new Set([...this.data.easements.map(easement => easement.parcel.id), ...this.data.occupations.map(occupation => occupation.parcel.id)]).size;
    if (this.data.obligation.computePrice) {
      this.data.caseOwnerships.forEach((caseOwnership) => {
        this.priceTotal += caseOwnership.easementPrice || caseOwnership.price;
      });
    }
  }

  async create() {
    // save note and return data to detail
    var queryParams = {loadCollections: []};
    if (this.data.occupations.length) {
      queryParams.loadCollections.push('occupations');
    }
    if (this.data.easements.length) {
      queryParams.loadCollections.push('easements');
    }
    if (this.data.buildings.length) {
      queryParams.loadCollections.push('caseBuildings');
    }

    await this.restangular.all('cases/create').post(this.data, queryParams).toPromise().then((data) => {
      Object.assign(this.data, data);
      return this.submitCallback.emit();
    });
  }
}
