import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { OpinionRequestListComponent } from './components/opinion-request-list/opinion-request-list.component';
import {
  opinionRequestCreateState,
  opinionRequestDetailState,
  opinionRequestListState,
  opinionRequestFillPricesFormState
} from '@app/ps/opinions/opinion-requests.states';
import { OpinionRequestCreateComponent } from './components/opinion-request-create/opinion-request-create.component';
import { OpinionRequestDetailComponent } from './components/opinion-request-detail/opinion-request-detail.component';
import { OpinionRequestEditComponent } from './components/opinion-request-edit/opinion-request-edit.component';
import { UIRouterModule } from '@uirouter/angular';

const OPINION_REQUEST_STATES = [
  opinionRequestCreateState,
  opinionRequestDetailState,
  opinionRequestListState,
  opinionRequestFillPricesFormState,
];

@NgModule({
  declarations: [
    OpinionRequestListComponent,
    OpinionRequestCreateComponent,
    OpinionRequestDetailComponent,
    OpinionRequestEditComponent,
  ],
  entryComponents: [
    OpinionRequestEditComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [...OPINION_REQUEST_STATES],
    }),
  ]
})
export class OpinionRequestsModule { }
