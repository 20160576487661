import { Component, Inject, OnInit, HostListener} from '@angular/core';
import * as L from 'leaflet';
import { AuthService } from '@app/common/services/auth.service';
import { MapService } from '@app/map/services/map.service';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { MapLayerSelectorService } from '@app/map/services/map-layer-selector.service';
import { MapConfigService } from '@app/map/services/map-config.service';
import { Transition } from '@uirouter/core';

import { TitleNamePipe } from '@app/common/pipes/title-name.pipe';
import { ParcelNamePipe } from '@app/common/pipes/parcel-name.pipe';
import { RoadSectionNamePipe } from '@app/dimap/pipes/road-section-name.pipe';

import { getLayersConfig } from '../../config/layers-config';
import { getOrder } from '../../../pure/listLoadOrder';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
})
export class MapComponent implements OnInit {
  searchResources: any[] = null;
  mapConfigLoading: boolean = true;
  defaultEnableCuzkInfo: boolean = false;
  selectorConfig: any = null;
  selectorOptions: any = null;
  mapOptions: any = null;
  private localStorageCollapsedKey: string;
  helpIds = HelpService.HELP_IDS;
  private customCRS: any = {};

  constructor(
    private localStorageService: LocalStorageService,
    private restangular: Restangular,
    private authService: AuthService,
    @Inject('$transition$') private transition: Transition,
    private mapService: MapService,
    private mapHighlightService: MapHighlightService,
    private mapConfigService: MapConfigService,
    private mapLayerSelectorService: MapLayerSelectorService,
    private titleNamePipe: TitleNamePipe,
    private parcelNamePipe: ParcelNamePipe,
    private roadSectionNamePipe: RoadSectionNamePipe,
  ) {
    this.toggleLayerSelector = this.toggleLayerSelector.bind(this);
    this.getCollapsed = this.getCollapsed.bind(this);
    this.fitHeight = this.fitHeight.bind(this);
  }

  ngOnInit() {
    const mapID = 'main-map';
    const project = this.authService.getActualProject();

    this.searchResources = [
      {id: 'areas/list', value: 'KÚ', filter: {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('areas/list')}, formatItem: item => item.name},
      {id: 'titles/list', value: 'LV', filter: {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('titles/list')}, attributes: {area: {}}, pipe: this.titleNamePipe},
      {id: 'parcels/list', value: 'Parcela', filter: {filters: {requestArea: {values: [true]}}, sortOrder: getOrder('parcels/list')}, attributes: {title: {mapping: {attributes: {area: {}}}}}, pipe: this.parcelNamePipe},
      {id: 'road-sections/list', value: 'Silnice', filter: {sortOrder: getOrder('road-sections/list')}, pipe: this.roadSectionNamePipe}
    ];

    const mapConfig = getLayersConfig(this.authService);
    this.mapConfigService.setLayers(mapConfig, mapID)
      .then((mapConfigComplete) => {
        this.selectorConfig = this.mapConfigService.filterMapConfig(mapConfigComplete, project.mapLayers);
        Object.assign(this.customCRS, this.mapConfigService.processCustomCRS(this.selectorConfig.layers));
        this.mapConfigLoading = false;
        this.selectorOptions = {
          saveLayersVisibility: true,
          saveGroupCollapseStatus: true,
          showCollapsedVisibleLayersCount: true,
          storagePrefix: project.key
        };
    });

    const savedPosition: any = this.localStorageService.get('map-' + project.key);
    let mapLat;
    let mapLon;
    let mapZoom;

    const defaultLat = project.mapLat ? project.mapLat : 49.75;
    const defaultLon = project.mapLon ? project.mapLon : 15.75;
    const defaultZoom = project.mapZoom ? project.mapZoom : 4;

    if (savedPosition) {
      mapLat = savedPosition.lat;
      mapLon = savedPosition.lon;
      mapZoom = savedPosition.zoom;
    } else {
      mapLat = defaultLat;
      mapLon = defaultLon;
      mapZoom = defaultZoom;
    }

    this.mapOptions = {
      id: mapID,
      srid: 5514,
      bounds: null,
      center: [mapLat, mapLon],
      zoom: mapZoom,
      minZoom: 4,
      maxZoom: 15,
      customCRS: this.customCRS,
    };

    // resizing window will change height of map frame
    $('#map-layer-selector').height(0);
    this.fitHeight();

    this.mapService.getMap(this.mapOptions.id).then((map) => {
      this.fitHeight();
      L.control.fullscreen({
        position: 'topleft',
        title: 'Celoobrazovkový režim',
        titleCancel: 'Ukončit celoobrazovkový režim'
      }).addTo(map);

      L.control.graphicScale({fill: 'hollow'}).addTo(map);

      const button = L.easyButton('fa-home map-home-icon', (btn, map) => {
        map.setView(new L.LatLng(defaultLat, defaultLon), defaultZoom);

        (<any>window).GoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'mapModule',
          eventAction: '',
          eventLabel: 'home',
        });
      });
      button.addTo(map);

      const logMapUrlHighlight = (type) => {
        (<any>window).GoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'mapModule',
          eventAction: type,
          eventLabel: 'map url highlight',
        });
      };

      if (this.transition.params().titleId > 0) {
        const type = 'titles';
        logMapUrlHighlight(type);

        this.restangular.one(type, this.transition.params().titleId).customPOST({attributes: {geometry: {}}}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().parcelId > 0) {
        const type = 'parcels';
        logMapUrlHighlight(type);

        this.restangular.one(type, this.transition.params().parcelId).customPOST({attributes: {geometry: {}}}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().intersectionId) {
        const type = 'intersections';
        logMapUrlHighlight(type);

        this.restangular.one(type, this.transition.params().intersectionId).customPOST({attributes: {geometry: {}}}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      if (this.transition.params().divisionParcelId) {
        const type = 'division-parcels';
        logMapUrlHighlight(type);

        this.restangular.one(type, this.transition.params().divisionParcelId).customPOST({attributes: {geometry: {}}}).toPromise().then((data) => {
          this.mapHighlightService.highlightFeature(this.mapOptions.id, data.geometry);
        });
      }

      map.on('moveend', () => {
        const center = map.getCenter(),
          zoom = map.getZoom();

        const position = {
          lat: center.lat,
          lon: center.lng,
          zoom: zoom
        };
        this.localStorageService.set('map-' + project.key, position);
      });
    });

    this.localStorageCollapsedKey = project.key + ".layerSelector.collapsed";
    const collapsedStatus = this.localStorageService.get(this.localStorageCollapsedKey);

    this.mapLayerSelectorService.setCollapsedState((collapsedStatus === 1));
  }

  toggleLayerSelector() {
    const collapsed = !this.getCollapsed();
    this.localStorageService.set(this.localStorageCollapsedKey, collapsed ? 1 : 0);
    $('#map-layer-selector').height(0);
    this.mapLayerSelectorService.setCollapsedState(collapsed);
    // also forces recentering of map window
    setTimeout(this.fitHeight);
  }

  getCollapsed() {
    return this.mapLayerSelectorService.getCollapsedState();
  }

  @HostListener('window:resize')
  fitHeight() {
    const topBarHeight = $('#top-bar').outerHeight();
    const windowHeight = $(window).height();
    $('#map-window').height(windowHeight - topBarHeight);
    $('#map-content').height(windowHeight - topBarHeight);
    const layerSelectorHeight = windowHeight - topBarHeight - Math.round($('#selector-heading').outerHeight());
    $('#map-layer-selector').height(layerSelectorHeight);
  }
}
