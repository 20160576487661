import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { CaseService } from '@app/ps/services/case.service';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ListService } from '@app/common/services/list.service';
import { ListModel } from '@app/common/models/list.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'form-case-update-parcel-price',
  templateUrl: './form-case-update-parcel-price.component.html',
  styleUrls: ['./form-case-update-parcel-price.component.scss']
})
export class FormCaseUpdateParcelPriceComponent implements OnInit {

  caseOwnershipParcelList: ListModel<any>;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private caseService: CaseService,
    private cdr: ChangeDetectorRef,
    private config: DialogConfig,
    private dialog: DialogRef,
    private listService: ListService,
  ) {
    this.updatePrice = this.updatePrice.bind(this);
  }

  ngOnInit() {
    this.caseOwnershipParcelList = this.listService.createList('case-ownership-parcels', {filters: {
        caseId: (<DialogConfigData>this.config.data).caseId,
        parcelId: (<DialogConfigData>this.config.data).parcelId,
        easementId: (<DialogConfigData>this.config.data).easementId
      }, limit: null });
    this.listService.fetchResult(this.caseOwnershipParcelList);
  }

  updatePrice() {
    return this.restangular.all('case-ownership-parcels').customPUT(this.caseOwnershipParcelList.list)
      .toPromise()
      .then(() => {
        this.dialog.close(true);
      });
  }
}
