import { Component, Inject, OnInit } from '@angular/core';
import { EasementCreateStepComponent } from '@app/ps/construction-objects/components/easement-create-step/easement-create-step.component';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { AreaModel } from '@app/common/models/area.model';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Component({
  selector: 'easement-create-info',
  templateUrl: './easement-create-info.component.html',
})
export class EasementCreateInfoComponent extends EasementCreateStepComponent implements OnInit {

  // model
  area: AreaModel;
  constructionObject: ConstructionObjectModel;
  gpNumber: string;
  gpCreator: string;
  dateOfCheck: string;
  dateOfConfirm: string;
  processNumber: string;

  // aux
  restBaseUrl = this.authService.getActiveApplicationRestUrl();

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    private authService: AuthService,
  ) {
    super();

    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    if (!this.data.vfkId) {
      this.backCallback.emit();
    }
  }

  isFormValid() {
    return !!(
      this.dateOfConfirm
      && this.dateOfCheck
      && this.constructionObject
      && this.area
      && this.gpNumber
      && this.gpCreator
      && this.processNumber
    );
  }

  onSubmit() {
    const data = {
      vfkId: this.data.vfkId,
      constructionObjectId: this.constructionObject.id,
      kuId: this.area.id,
      cisloGp: this.gpNumber,
      zhotovitelGp: this.gpCreator,
      datumOvereniGp: this.dateOfCheck,
      datumZhotoveniGp: this.dateOfConfirm,
      cisloJednaci: this.processNumber
    };

    return this.uploadService.upload({
      url: this.restBaseUrl + '/vfks/user/upload',
      data: data,
      headers: { Authorization: this.authService.getToken() }
    }).then(() => {
      this.submitCallback.emit();
    });
  }
}
