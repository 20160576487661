import { NgModule } from '@angular/core';

import {editUserPermissionsBulkState, editUserPermissionsState, userDetailState, userListState} from './users.states';
import { UserListComponent } from '@app/settings/users/component/user-list/user-list.component';
import { UserDetailComponent } from '@app/settings/users/component/user-detail/user-detail.component';
import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { ProjectsComponent } from '@app/settings/users/component/projects/projects.component';
import { SvPermissionListComponent } from '@app/settings/users/component/sv-permission-list/sv-permission-list.component';
import { UIRouterModule } from '@uirouter/angular';
import { SvPermissionComponent } from '@app/settings/users/component/sv-permisson/sv-permission.component';
import { PermissionsBulkComponent } from '@app/settings/users/component/permissions-bulk/permissions-bulk.component';
import { PermissionsBulkTypeComponent } from '@app/settings/users/component/permissions-bulk-type/permissions-bulk-type.component';
import { PermissionsBulkEntitiesComponent } from '@app/settings/users/component/permissions-bulk-entities/permissions-bulk-entities.component';
import { PermissionsBulkPermissionsComponent } from '@app/settings/users/component/permissions-bulk-permissions/permissions-bulk-permissions.component';
import { PermissionsBulkConfirmComponent } from '@app/settings/users/component/permissions-bulk-confirm/permissions-bulk-confirm.component';

const STATES = [
  userListState,
  editUserPermissionsState,
  userDetailState,
  editUserPermissionsBulkState
];

@NgModule({
  declarations: [
    UserListComponent,
    UserDetailComponent,
    ProjectsComponent,
    SvPermissionListComponent,
    SvPermissionComponent,
    PermissionsBulkComponent,
    PermissionsBulkTypeComponent,
    PermissionsBulkEntitiesComponent,
    PermissionsBulkPermissionsComponent,
    PermissionsBulkConfirmComponent,
  ],
  entryComponents: [
    UserListComponent,
    UserDetailComponent,
    ProjectsComponent,
    SvPermissionListComponent,
    SvPermissionComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [ ...STATES ],
    }),
    UIRouterModule,
  ]
})
export class UsersModule { }
