import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ListService } from '@app/common/services/list.service';

@Injectable({
  providedIn: 'root'
})
export class TitleDetailCreateCaseSelectTitlesService {

  constructor(
    private listService: ListService,
  ) {
    this.loadTitlesBySubject = this.loadTitlesBySubject.bind(this);
  }

  loadTitlesBySubject(subjectId, originalTitleId, obligationType, setByBusinessCase) {
    const filters = {validity: 'valid', subjectId: [subjectId], obligationType: [obligationType]};
    const list = this.listService.createList('ownerships', {
        limit: null,
        sortOrder: [{sortBy: 'title.area.name', direction: 'asc'}, {sortBy: 'title.lv', direction: 'asc'}],
        filters: filters},
      undefined,
      { parentOwnership: { load: true }});

    let itemsSelectedPromiseResolve;
    const itemsSelectedPromise = new Promise((resolutionFunc, rejectionFunc) => {
      itemsSelectedPromiseResolve = resolutionFunc;
    });

    this.listService.fetchResult(list).then(() => {
      list.list = list.list.filter(o => o.title.id != originalTitleId && !o.ignoreType).map((ownership) => {
        const newItem: any = {
          id: ownership.id,
          ownership: ownership,
          callbackRegister: (callbacks) => {
            newItem.unselect = callbacks.unselect;
            newItem.selectByCase = callbacks.selectByCase;
            newItem.isSelectionValidLocal = callbacks.isSelectionValidLocal;
            newItem.checkObjectsByData = callbacks.checkObjectsByData;

            if (setByBusinessCase) {
              callbacks.selectByCase();
              itemsSelectedPromiseResolve();
            }
          },
          getOwnerships: () => {
            if (!newItem.ownershipsData) {
              const ownerships = this.listService.createList('ownerships', {
                limit: null,
                filters: {validity: 'valid', titleId: [ownership.title.id]}
              });
              newItem.ownershipsData = this.listService.fetchResult(ownerships).then(list => {
                list.list = list.list.filter(o => !o.ignoreType);
                list.itemCount = list.list.length;

                return list;
              });
            }
            return newItem.ownershipsData;
          },
          getCaseOwnerships: () => {
            return newItem.getOwnerships().then((data) => {
              if (!newItem.caseOwnerships) {
                newItem.caseOwnerships = _.map(data.list, (ownership) => {
                  return {
                    id: ownership.id,
                    ownership: ownership
                  };
                });
              }
              return newItem.caseOwnerships;
            });
          },
        };
        return newItem;
      });
      list.itemCount = list.list.length;
    });
    return list;
  }
}
