import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ParcelNamePipe } from '@app/common/pipes/parcel-name.pipe';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';


@Component({
  selector: 'title-list',
  templateUrl: './title-list.component.html',
  styleUrls: []
})
export class TitleListComponent implements OnInit {
  list: any;
  pageableList: PageableList;
  deferredUpdateStatistics: any;
  statuses = [
      {id: 'resolved', name: 'Uzavřené'},
      {id: 'not_in_progress', name: 'Neřešené'},
      {id: 'in_progress', name: 'Řešené'},
      {id: 'all_signed', name: 'Podepsáno všemi na LV'}
  ];
  blockStatuses = [
      {id: true, name: 'Blokováno'},
      {id: false, name: 'Není blokováno'}
  ];
  settingsLoaded = false;
  mandataryFilter: any;
  exportEnable = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    private restangular: Restangular,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private stateService: StateService,
    private authService: AuthService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public subjectNamePipe: SubjectNamePipe,
    public userNamePipe: UserNamePipe,
    public parcelNamePipe: ParcelNamePipe,
    private exportListService: ExportListService
  ) {
    this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    const activeApplication = this.authService.getActiveApplication();
    const activeProjectKey = this.authService.getActualProject().key;
    this.mandataryFilter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [activeApplication],
        },
        permission: {
          values: ['assignable'],
        },
        projects: {
          values: [activeProjectKey],
        },
      },
      sortOrder: [
        { sortBy: 'surname' },
        { sortBy: 'name' },
      ],
    };
    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }
    const promise = new Promise((resolve, reject) => {
      this.deferredUpdateStatistics = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.deferredUpdateStatistics.promise = promise;
    this.initializeList();
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('cases');
    return this.exportListService
      .exportList('titles', 'titles', filter, { caseMandatary: {}, subject: {load: false}})
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  private getDefaultFilter() {
    return {
      filters: {
        validity: 'valid',
        occupationOrEasementExists: {},
        searchText: {values: [{id: ''}]},
        areaId: { values: [], negation: false },
        subjectId: {},
        userId: {},
        statusPermanentOccupation: {},
        statusTemporaryOccupation: {},
        statusEasement: {},
        parcelId: {},
        blockedByUser: {},
        constructionObjectId: { values: [], negation: false },
        caseMandataryId: {},
        titleDefinition: {values: [{id: ''}]},
        loadCollections: ['ownerships', 'occupationResolution', 'duplicityOwnership'],
      }
    };
  }
  private async initializeList() {
    await this.settingsService.getSettingsLoader();
    let attributes = undefined;
    if (!this.hideColumn('mandatary')) {
      attributes = {caseMandatary: {}};
    }
    this.pageableList = this.pageableListService.get('titles', this.getDefaultFilter(), 'titlesFilter', undefined, attributes);
    if (this.hideColumn('mandatary')) {
      this.pageableList.list.filter.filters.caseMandataryId = {};
    }
    if (this.hideColumn('user')) {
      this.pageableList.list.filter.filters.userId = {};
    }
    this.list = this.pageableList.list;
    await this.initStatisticsEntityFilter();
    this.pageableList.load();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  registerUpdateStatisticsEntityFilterKey(registerCallback) {
    this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      delete this.list.filter.filters.validity;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    } else {
      if (this.list.filter.filters.statisticsEntityFilterKey &&
        this.list.filter.filters.statisticsEntityFilterKey.values &&
        this.list.filter.filters.statisticsEntityFilterKey.values.length
      ) {
        delete this.list.filter.filters.validity;
      }
    }
  }

  statisticsEntityFilterChanged(changed) {
    if (!changed.state) {
      this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
    }
  }

  checkNeighbors() {
    const exists = this.list.filter.filters.occupationOrEasementExists;
    return exists && (!exists.values || exists.values.length === 0);
  }

  onTitlesCheckboxClicked() {
    this.list.filter.filters.occupationOrEasementExists = {values: this.checkNeighbors() ? [{id:true}] : []};
  }

  hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('titles', columnId);
  }
}
