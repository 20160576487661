<ng-template #list let-list="list" let-checklist="checklist">
  <div class="bordered">
    <div *ngIf="list.length === 0" class="empty-info">Žádné položky</div>
    <div class="item flexbox-container" *ngFor="let item of list" (click)="checklist.toggleSelection(item)">
      <gmt-checkbox
        class="item-checkbox"
        [gmtChecked]="checklist.isChecked(item)"
        gmtType="multiselect"
        ></gmt-checkbox> {{ item.value }}
    </div>
  </div>
</ng-template>

<fieldset class="mirrored-list">
  <legend>{{ label }}</legend>
  <div class="flexbox-container">
    <div class="label-options">K dispozici</div>
    <div class="label-selected">Aktivní</div>
  </div>
  <div class="flexbox-container">
    <div class="column-selected">
      <ng-container *ngTemplateOutlet="list; context: {list: availableItems, checklist: availableChecklist}"></ng-container>
    </div>

    <div class="transfer">
      <a (click)="onToggle()"><i class="fa fa-chevron-left"></i><i class="fa fa-chevron-right"></i></a>
    </div>

    <div class="column-options">
      <ng-container *ngTemplateOutlet="list; context: {list: selectedItems, checklist: selectedChecklist}"></ng-container>
    </div>
  </div>
</fieldset>
