<!--
  Used as the second column in owner-detail -> representation tab.
  Used in the same column as the rest of the data in update-case-subject.
-->
<ng-template #additionalInfo>
  <!-- for representative only -->
  <ng-container *ngIf="allowRoleAssignment">
    <gmt-input *ngIf="editable" label="Funkce">
      <input [(ngModel)]="representative.function" type="text" />
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Funkce</label>
      <div class="info">{{ representative.function }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Datum plné moci">
      <input pick-a-date type="text" [(ngModel)]="representative.powerOfAttorneyDate"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Datum plné moci</label>
      <div class="info">{{ representative.powerOfAttorneyDate | date:'medium' }}</div>
    </ng-container>

    <gmt-input label="Role">
      <gmt-checkbox (click)="onToggleRoleCadastre(representative)" [gmtChecked]="representative.roleCadastre"  gmtLabelPosition="after" style="display: block">Jednání v návrhu na vklad</gmt-checkbox>
      <gmt-checkbox (click)="representative.roleContract = !representative.roleContract" [gmtChecked]="representative.roleContract"  gmtLabelPosition="after" style="display: block">Podpis všech smluv (plný zástup)</gmt-checkbox>
    </gmt-input>
  </ng-container>

  <!-- for contact person only -->
  <ng-container *ngIf="!allowRoleAssignment">
    <gmt-input *ngIf="editable" label="Telefon">
      <input type="text" [(ngModel)]="representative.phoneNumber"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Telefon</label>
      <div class="info">{{ representative.phoneNumber }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="E-mail">
      <input type="text" [(ngModel)]="representative.email"/>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>E-mail</label>
      <div class="info">{{ representative.email }}</div>
    </ng-container>
  </ng-container>
</ng-template>

<fieldset>
  <legend>{{ heading  }} <a *ngIf="editableToggle" (click)="editable = !editable"><i class="fa fa-pencil"></i></a></legend>
  <div>
    <ng-content select="[slot='deleteButton']"></ng-content>
  </div>
  <div [ngClass]="{'column-33': !compact, 'column-50': compact}">
    <ng-container *ngIf="allowRoleAssignment">
      <gmt-input *ngIf="editable" label="Název právnické osoby">
        <input [(ngModel)]="representative.companyName" (change)="representative.subjectType = (!!representative.companyName ? 'OPO' : 'OFO')" type="text" />
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Název právnické osoby</label>
        <div class="info">{{ representative.companyName }}</div>
      </ng-container>

      <gmt-input *ngIf="editable" label="IČO" [required]="requiredFields && !!representative.companyName">
        <input [(ngModel)]="representative.identificationNumber" type="text" />
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>IČO</label>
        <div class="info">{{ representative.identificationNumber }}</div>
      </ng-container>
    </ng-container>

    <gmt-input *ngIf="editable" label="Titul před jménem">
      <input [(ngModel)]="representative.titleBefore" type="text" />
      <ng-content select="[slot='a']"></ng-content>
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Titul před jménem</label>
      <div class="info">{{ representative.titleBefore }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Křestní jméno" [required]="requiredFields">
      <input [(ngModel)]="representative.firstName" type="text" />
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Křestní jméno</label>
      <div class="info">{{ representative.firstName }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Příjmení" [required]="requiredFields">
      <input [(ngModel)]="representative.surname" type="text" />
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Příjmení</label>
      <div class="info">{{ representative.surname }}</div>
    </ng-container>

    <gmt-input *ngIf="editable" label="Titul za jménem">
      <input [(ngModel)]="representative.degreeAfter" type="text" />
    </gmt-input>

    <ng-container *ngIf="!editable">
      <label>Titul za jménem</label>
      <div class="info">{{ representative.degreeAfter }}</div>
    </ng-container>
  </div>

  <div [ngClass]="{'column-33': !compact, 'column-50': compact}">
    <ng-container *ngTemplateOutlet="additionalInfo"></ng-container>
  </div>
</fieldset>

<address-form
    *ngIf="representative.roleCadastre"
    [editable]="editable"
    [collapsed]="true"
    [address]="representative.address"
    [requiredFields]="['houseNumber', 'city', 'postalCode']"
    heading="Doručovací adresa"
></address-form>

<ng-content select="[slot='addButton']"></ng-content>
