﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { SeriesModel } from '../models/series.model';

@Pipe({ name: 'seriesName' })
@Injectable({
  providedIn: 'root'
})
export class SeriesNamePipe implements PipeTransform {
  transform(series: SeriesModel) {
    return series.nextNumber;
  }
}