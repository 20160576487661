<div [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.small">
      <div class="logo">
          <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault();" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
              <i [ngClass]="['fa', 'fa-bars']"></i>
          </a>
          <div class="image-container">
              <img src="./img/logo/dashboard_xxl.svg" alt="logo"/>
          </div>
      </div>
      <login [user]="user" (logoutClick)="onLogout()"></login>
  </div>

  <div id="top-bar"  class="big-bar" [ngClass]="{ test: ENV !== 'production' }" visibleByBrand="common.navbar.big">
    <div class="top-bar-container left">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault()" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <div class="top-bar-container left logo">
      <a uiSref="landing.home"><img src="./img/logo/mpv_top.svg" alt="logo" /></a>
    </div>

    <div class="left top-bar-container top-bar-container-padding" *ngIf="ENV !== 'production'" style="color: red">
      TEST
    </div>

    <div class="top-bar-container top-bar-container-padding left">
      <div class="header">
        <div class="header-title">{{ 'dashboard.name' | brandTranslate }}</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a>
          >
          <span>{{ 'dashboard.name' | brandTranslate }}</span>
        </div>
      </div>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-10">
      <img src="./img/logo/rsd_main.svg" alt="logo" height="60"/>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-20">
      <login [user]="user" [withCompany]="false" [servicedeskUrl]="servicedeskUrl" (logoutClick)="onLogout()"></login>
    </div>
  </div>

  <div class="main">
      <app-menu [ngClass]="{collapsed: collapsed}" [collapsed]="collapsed" class="flexbox-container" id="menu" [menuConfig]="menuConfig"></app-menu>
      <div id="content-wrap">
          <div id="content" ui-view="content"></div>
      </div>
  </div>
</ng-container>
