<gmt-construction-object-list *ngIf="!(tooltipComponent && tooltipData)" [constructionObjects]="constructionObjects"></gmt-construction-object-list>

<div *ngIf="tooltipComponent && tooltipData"
     [tooltip]="tooltipComponent"
     [data]="tooltipData"
     [activeContent]="true"
     class="tooltip-hover-area fill-content"
>
  <div class="fill-content flexbox-container flex-centered p-10">
    <div class="icon-count flexbox-container">
      <i class="fa fa-th"></i>
    </div>
    <div class="number ml-4">{{ constructionObjects.length }}</div>
  </div>
</div>
