import { Component, Inject, OnInit } from '@angular/core';
import { VfzeImportStepComponent } from '@app/ps/vfze/components/vfze-import-step/vfze-import-step.component';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { VfzeValidationResponseModel } from '@app/common/models/vfze-validation-response.model';
import { VfzeUtils } from '@app/common/utils/vfze.utils';
import { saveAs } from 'file-saver';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Component({
  selector: 'vfze-import-upload',
  templateUrl: './vfze-import-upload.component.html',
})
export class VfzeImportUploadComponent extends VfzeImportStepComponent implements OnInit {

  static readonly MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
  static readonly MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';

  filesMsg = VfzeImportUploadComponent.MSG_FILES_DEFAULT;
  files: UploadFileExtended[] = [];
  isFileValidToUpload = false;

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {
    this.files = this.data.files || [];
    this.onFilesChange(this.files);
  }

  onFilesChange(files: UploadFileExtended[]) {
    this.files = files;

    if (this.files.length > 1) {
      this.filesMsg = VfzeImportUploadComponent.MSG_FILES_QUANTITY;
    } else if (this.files.length === 0) {
      this.filesMsg = VfzeImportUploadComponent.MSG_FILES_DEFAULT;
    } else {
      this.filesMsg = '';
    }

    this.isFileValidToUpload = this.files.length === 1;
  }

  onSubmit() {
    this.data.files = this.files;
    this.submitCallback.emit();
  }
}
