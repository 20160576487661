import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { ManagerReportProjectsService } from '@app/landing/services/manager-report-projects.service';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { AuthService } from '@app/common/services/auth.service';
import { ManagerReportProjectDataModel } from '@app/landing/models/manager-report-project-data.model';
import { ModulesService } from '@app/common/services/modules.service';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { WordService } from '@app/common/services/word.service';
import { StatisticsService } from '@app/ps/services/statistics.service';
import { ListService } from '@app/common/services/list.service';
import { DatePipe } from '@angular/common';
import { PercentagePipe } from '@app/common/pipes/percentage.pipe';
import { TransitionService } from '@uirouter/angular';
import { ReadinessStateEnum } from '@app/ps/enums/readiness-state.enum';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { Restangular } from 'ngx-restangular';
import {HttpResponse} from "@angular/common/http";
import {HttpService} from "@app/common/services/http.service";
import {BrandService} from "@app/common/services/brand.service";

@Component({
  selector: 'manager-report-project-detail',
  templateUrl: './manager-report-project-detail.component.html',
  styleUrls: ['./manager-report-project-detail.component.scss']
})
export class ManagerReportProjectDetailComponent implements OnInit {

  @Input() projectKey: string;

  loading = true;
  project: ManagerReportProjectDataModel;
  dashboard: any;
  totalAvailable: boolean;
  tabsLoading = false;
  showPercentage = true;
  canVisitDetail = false;
  deregisterTransition: Function;
  helpIds = HelpService.HELP_IDS;

  totalCategoryTabs: any[] = [
    {
      name: 'Celkový počet všech případů'
    },
    {
      name: 'Nezaložené případy'
    },
    {
      name: 'Případy v řešení'
    },
    {
      name: 'Vypořádané případy'
    },
    {
      name: 'Vyvlastnění'
    },
  ];

  casesCategoryTabs: any[] = [
    {
      name: 'Trvalý zábor',
      prefix: 'tz',
      mapLayerPrefix: 'permanent_occupation_status_',
      readinesName: 'readinessPermanentOccupations',
    },
    {
      name: 'Dočasný zábor do 1 roku',
      prefix: 'dzdr',
      mapLayerPrefix: 'temporary_occupation_under_one_year_status_',
      readinesName: 'readinessTemporaryUnderOccupations',
    },
    {
      name: 'Dočasný zábor nad 1 rok',
      prefix: 'dznr',
      mapLayerPrefix: 'temporary_occupation_over_one_year_status_',
      readinesName: 'readinessTemporaryOverOccupations',
    },
    {
      name: 'Věcné břemeno',
      prefix: 'vb',
      mapLayerPrefix: 'easement_status2_',
      readinesName: 'readinessTemporaryEasements',
    },
  ];

  entityTabs: any[] = [
    {
      value: 'Případy',
      id: 'cases',
      endpoint: 'cases',
      columns: ['caseNumber', 'areaName', 'lv', 'owner', 'caseType', 'caseStatus', 'respectiveDate', 'constructionObjects', 'price'],
      defaultFilter: {
        filters: {
          loadCollections: [
            'titles',
            'caseOwnerships',
            'constructionObjects',
            'caseSubjects',
          ],
          cancelStatus: {values: [{id: 'notCancelled'}]},
          searchText: {values: [{id: ''}]},
        }
      },
    },
    {
      value: 'Vlastníci',
      id: 'subjects',
      endpoint: 'subjects',
      defaultFilter: {
        filters: {
          loadCollections: ['ownerships', 'occupationResolution'],
          searchText: {values: [{id: ''}]},
          validity: {values: ['withOccupationOrEasement', 'invalidWithCase']},
          opsubType: {values: [{id: 'BSM'}], negation: true},
        }
      },
    },
    {
      value: 'Parcely',
      id: 'parcels',
      endpoint: 'parcels',
      defaultFilter: {
        filters: {
          validity: 'valid',
          searchText: { values: [{ id: '' }]},
          loadCollections: [
            'title.ownerships',
            'geometricPlanRequests',
            'opinionRequests',
            'constructionObjects',
            'knBudoucPepvs',
            'parcelPrices'
          ]
        }
      },
    },
    {
      value: 'Listy vlastnictví',
      id: 'titles',
      endpoint: 'titles',
      columns: ['lv', 'areaName', 'owner', 'case', 'pernamentOccupationState', 'overOneYearOccupationState', 'underOneYearOccupationState', 'easementCaseState', 'blocked'],
      defaultFilter: {
        filters: {
          validity: 'valid',
          searchText: {values: [{id: ''}]},
          loadCollections: [
            'ownerships',
            'occupationResolution',
            'duplicityOwnership'
          ],
        }
      },
    },
    {
      value: 'Vyvlastnění',
      id: 'expropriation',
      endpoint: 'cases',
      columns: ['caseNumber', 'areaName', 'lv', 'owner', 'caseType', 'caseStatus', 'respectiveDate', 'constructionObjects', 'price'],
      defaultFilter: {
        filters: {
          loadCollections: [
            'titles',
            'caseOwnerships',
            'constructionObjects',
            'caseSubjects',
          ],
          cancelStatus: {values: [{id: 'notCancelled'}]},
          searchText: {values: [{id: ''}]},
          obligationId: {values: []}
        }
      },
    },
  ];

  selectedEntity: any;
  private xhrCancelResolve: Function;
  private brand = this.brandService.isVisible('mpv') ? 'mpv' : 'maja';

  constructor(
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(RESTANGULAR_CONFIGURATION) private restangularConfig: any,
    @Inject(APP_CONFIG) private config: any,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    private percentagePipe: PercentagePipe,
    private datePipe: DatePipe,
    private listService: ListService,
    private statisticsService: StatisticsService,
    private wordService: WordService,
    private settingsService: SettingsService,
    private pageableListService: PageableListService,
    private authService: AuthService,
    private projectsService: ManagerReportProjectsService,
    private modulesService: ModulesService,
    private transitionService: TransitionService,
    private httpService: HttpService,
    private brandService: BrandService,
  ) {
    this.onExportXlsx = this.onExportXlsx.bind(this);
    this.onExportPdf = this.onExportPdf.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  async ngOnInit() {
    this.deregisterTransition = this.transitionService.onBefore({}, (transition => {
      const params = transition.targetState().params();
      const name = transition.targetState().name();

      if (!name.includes('symap.project') || params.projectKey) {
        return;
      }

      params.projectKey = this.projectKey;
      return transition.router.stateService.target(transition.to(), params);
    }));

    this.restangular.provider.setBaseUrl(this.config.BACKEND_OPTIONS.restUrlSY + '/' + this.projectKey);
    await this.loadProject();
    await this.loadDashboard();
    this.loading = false;
    await this.settingsService.loadSettings(this.project.id);
    await this.wordService.setVocabulary(this.project.id);

    this.canVisitDetail = this.authService.hasPermissionOnProject('web', this.projectKey, this.authService.symapPermissions);

    if (this.canVisitDetail) {
      await this.loadExpropriationObligationIds();
    }
    this.onChangeEntity('cases');
  }

  ngOnDestroy() {
    if (this.xhrCancelResolve) {
      this.xhrCancelResolve();
    }

    this.deregisterTransition();
  }

  onChangeEntity(entityId: string) {
    const selectedEntity = _.find(this.entityTabs, { id: entityId });

    if (!selectedEntity.list) {
      selectedEntity.pageableList = this.pageableListService.get(
        selectedEntity.endpoint,
        selectedEntity.defaultFilter,
        undefined,
        this.restangular
      );
      selectedEntity.list = selectedEntity.pageableList.list;
      this.selectedEntity = selectedEntity;
    } else {
      this.selectedEntity = selectedEntity;
    }
  }

  onExportXlsx() {
    return this.restangularSymap.one(`manager-report/${this.projectKey}?type=xlsx&brand=${this.brand}`)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .toPromise()
      .then((response) => {
        saveAs(response, 'manager-report-detail.xlsx', true);
      });
  }

  onExportPdf() {
    return this.restangularSymap.one(`manager-report/${this.projectKey}?type=pdf&brand=${this.brand}`)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .toPromise()
      .then((response) => {
        saveAs(response, 'manager-report-detail.pdf', true);
      });
  }

  onPageChanged(event) {
    this.selectedEntity.pageableList.fetchResults(event);
  }

  private loadProject() {
    return this.modulesService.loadSyMAPProjectById(this.projectKey).then(data => {
      this.project = this.projectsService.createFromApi(data);
    });
  }

  private loadDashboard() {
    const cancelPromise = new Promise<any>((resolve, reject) => {
      this.xhrCancelResolve = resolve;
    });

    return this.httpService.call({
      path: 'dashboard',
      method: 'POST',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
    }).then((data: any) => {
      const dashboard = data.data.find(d => d.key === this.projectKey);

      if (!dashboard) {
        this.totalAvailable = false;
        return;
      }

      this.totalAvailable = true;
      ManagerReportProjectsService.mapDashboardDataOnProject(this.project, dashboard);

      this.totalCategoryTabs[0].value = dashboard.total;
      this.totalCategoryTabs[0].total = dashboard.total;
      this.totalCategoryTabs[1].value = dashboard.toCreate;
      this.totalCategoryTabs[1].total = dashboard.total;
      this.totalCategoryTabs[2].value = dashboard.inProgress;
      this.totalCategoryTabs[2].total = dashboard.total;
      this.totalCategoryTabs[3].value = dashboard.done;
      this.totalCategoryTabs[3].total = dashboard.total;
      this.totalCategoryTabs[4].value = dashboard.expropriationTotal;

      this.casesCategoryTabs.forEach(tab => {
        tab.total = (dashboard[tab.prefix + 'Total'] ? dashboard[tab.prefix + 'Total'] : 0);
        tab.done = (dashboard[tab.prefix + 'Done'] ? dashboard[tab.prefix + 'Done'] : 0);
        tab.toCreate = (dashboard[tab.prefix + 'ToCreate'] ? dashboard[tab.prefix + 'ToCreate'] : 0);
        tab.progress = tab.total - tab.done - tab.toCreate;
        tab.donePercentage = this.percentagePipe.transform(tab.done, tab.total);
        tab.toCreatePercentage = this.percentagePipe.transform(tab.toCreate, tab.total);
        tab.progressPercentage = this.percentagePipe.transform(tab.progress, tab.total);
        tab.available = tab.total || tab.done || tab.toCreate || tab.progress;
        tab.partialData = this.project[tab.readinesName] === ReadinessStateEnum.PARTIAL_DATA;
        tab.readiness = this.project[tab.readinesName];

        tab.chartData = [
          { value: tab.toCreate, color: '#ffc90e', tooltip: 'Nezaloženo ' + (this.showPercentage ? tab.toCreatePercentage : tab.toCreate)},
          { value: tab.progress, color: '#30a2e8', tooltip: 'V řešení ' + (this.showPercentage ? tab.progressPercentage : tab.progress)},
          { value: tab.done, color: '#22b14c', tooltip: 'Vypořádáno ' + (this.showPercentage ? tab.donePercentage : tab.done)},
        ];
      });
    });
  }

  private loadExpropriationObligationIds() {
    const obligations = this.listService.createList(
      'obligations',
      {filters: {obligationType: ['FulfillmentOfDecisionExpropriationLiability', 'TemporaryExpropriationLiability']}},
      this.restangular
    );
    return this.listService.fetchResult(obligations).then(() => {
      this.entityTabs[4].defaultFilter.filters.obligationId.values = obligations.list.map(o => o.id);
    });
  }
}
