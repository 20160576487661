import { Component, Input, OnInit, Inject, HostListener, NgZone} from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { MultiSceneCloudService } from '@app/dimap/map/services/multi-scene-cloud.service';
import { APP_CONFIG } from '@app/common/services/config.service';

declare namespace MultiSceneCore {
  const Api: any;
}

@Component({
  selector: 'multi-scene-cloud',
  templateUrl: './multi-scene-cloud.component.html',
})
export class MultiSceneCloudComponent implements OnInit {

  @Input()
  private trjName;
  @Input()
  private photoName;
  @Input()
  private x;
  @Input()
  private y;
  @Input()
  private z;
  @Input()
  private yaw;
  @Input()
  private pitch;
  @Input()
  private layers;

  private containerId;
  private multiSceneCloudKey;
  private projectUrl;
  private viewer;
  public message = 'Probíhá inicializace okna panoramatického snímku';
  private MULTI_SCENE_CLOUD = this.config.MULTI_SCENE_CLOUD;

  constructor(
    private authService: AuthService,
    private multiSceneCloudService: MultiSceneCloudService,
    private ngZone: NgZone,
    @Inject(APP_CONFIG) private config,
  ) {
    this.onLicenseValid = this.onLicenseValid.bind(this);
    this.showPicture = this.showPicture.bind(this);
    this.onCameraChange = this.onCameraChange.bind(this);
  }

  async ngOnInit() {
    this.containerId = 'multi-scene-cloud';
    this.multiSceneCloudKey = this.MULTI_SCENE_CLOUD.key;
    this.projectUrl = this.MULTI_SCENE_CLOUD.projects[this.authService.getActualProject().key];
    this.viewer = undefined;

    if (this.projectUrl) {
      this.initialize();
      this.message = null;
    } else {
      this.message = 'Není nastavena URL adresa snímků daného projektu!';
    }

    if (this.pitch) {
      this.multiSceneCloudService.pitch = this.pitch;
    }

    if (this.yaw) {
      this.multiSceneCloudService.yaw = this.yaw;
    }
  }

  initialize() {
    this.ngZone.runOutsideAngular(() => {
      this.viewer = MultiSceneCore.Api.createViewer();
      MultiSceneCore.Api.initLicense(this.viewer, this.multiSceneCloudKey, this.onLicenseValid);
      MultiSceneCore.Api.addEventListener(this.viewer, 'onready', this.showPicture);
      MultiSceneCore.Api.addEventListener(this.viewer, 'camerachange', this.onCameraChange);
    });
  }

  onCameraChange(e) {
    this.multiSceneCloudService.pitch = e.cameraRotation.pitch;
    this.multiSceneCloudService.yaw = e.cameraRotation.yaw;
  }

  onLicenseValid() {
    this.ngZone.runOutsideAngular(() => {
      MultiSceneCore.Api.initProject(
        this.viewer,
        this.containerId,
        this.projectUrl,
        { camera: false }
      );
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.ngZone.runOutsideAngular(() => {
      MultiSceneCore.Api.updateViewerSize(this.viewer);
    });
  }

  showPicture() {
    if (!this.viewer) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      MultiSceneCore.Api.showPositionOnTrajectory(
        this.viewer,
        this.trjName,
        this.photoName,
        this.x,
        this.y,
        this.z,
        this.multiSceneCloudService.yaw,
        this.multiSceneCloudService.pitch,
      );

      if (this.layers) {
        this.layers.forEach(layerId => {
          MultiSceneCore.Api.setDataLayerVisibility(
              this.viewer, layerId, true
          );
        });
      }
    });
  }
}
