﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { TitleModel } from '../models/title.model';

@Pipe({ name: 'titleNames' })
@Injectable({
  providedIn: 'root'
})
export class TitleNamesPipe implements PipeTransform {
  transform(titles: TitleModel[]) {
    if (!titles || !titles.length) {
      return '-';
    }
    return titles.length > 1 ? 'Více LV' : titles[0].lv;
  }
}
