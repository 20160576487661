import { Component, OnInit } from '@angular/core';
import { DialogConfig } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';

@Component({
  selector: 'create-case-select-titles-multiple-dialog',
  templateUrl: './create-case-select-titles-multiple-dialog.component.html',
})
export class CreateCaseSelectTitlesMultipleDialogComponent implements OnInit {
  subjectName;
  obligationName;

  constructor(
    public config: DialogConfig,
    private dialog: DialogRef,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.subjectName = (this.config.data as any).subjectName;
    this.obligationName = (this.config.data as any).obligationName;

  }

  onSubmit() {
    this.dialog.close(true);
  }

  onCancel() {
    this.dialog.close(false);
  }
}
