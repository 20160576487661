import {Component, Inject, OnInit} from '@angular/core';
import {ListModel} from '@app/common/models/list.model';
import {TicketModel} from '@app/sv/models/ticket.model';
import {PageableList} from '@app/models/pageable.list';
import {PageableListService} from '@app/common/services/pageable.list.service';
import {SelectItem} from '@app/common/components/select/select.component';
import {TicketStatusEnum, ticketStatusOptions} from '@app/sv/enums/ticket-status.enum';
import {RESTANGULAR_SYMAP} from '@app/common/services/restangular-symap.service';
import {UserNamePipe} from '@app/common/pipes/username.pipe';
import {User} from '@app/models/user';
import {AuthService} from '@app/common/services/auth.service';
import {OrganizationalUnitNamePipe} from '@app/common/pipes/organizational-unit-name.pipe';
import {DocumentTypeFullnamePipe} from "@app/common/pipes/document-type-fullname.pipe";
import { RoleEnum } from '@app/common/enums/role.enum';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { ExportListService } from '@app/ps/services/export-list.service';
import {BrandTranslatePipe} from "@app/common/pipes/brand-translate.pipe";

@Component({
  selector: 'ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  list: ListModel<TicketModel>;
  pageableList: PageableList;
  statusOptions: SelectItem[] = ticketStatusOptions;
  statusEnum = TicketStatusEnum;
  documentTypeOptions: SelectItem[] = [];
  sectionOptions: SelectItem[] = [];
  organizationalUnitOptions: SelectItem[] = [];
  obligationOptions: SelectItem[] = [];
  createdByUsers: User[];
  userFilter = {
    filters: {
      searchText: {
        values: [],
      },
      applications: {
        values: ['VZORY'],
      },
      permission: {
        values: ['template_new']
      },
      roles: {
        values: [RoleEnum.VZORY_ZADAVATEL]
      }
    },
  };
  loading = true;
  organizationalUnitCode = [];
  helpIds = HelpService.HELP_IDS;
  exportEnable = true;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    @Inject(RESTANGULAR_SETTINGS) public settingsRestangular: any,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    public authService: AuthService,
    public usernamePipe: UserNamePipe,
    public documentTypeFullname: DocumentTypeFullnamePipe,
    private pageableListService: PageableListService,
    private exportListService: ExportListService,
    private brandTranslatePipe: BrandTranslatePipe,
  ) {
    this.onExport = this.onExport.bind(this);
  }

  async ngOnInit() {
    this.sectionOptions.push({
      id: this.brandTranslatePipe.transform('sv.section.invest'),
      name: this.brandTranslatePipe.transform('sv.section.invest')
    });
    this.sectionOptions.push({
      id: this.brandTranslatePipe.transform('sv.section.additional'),
      name: this.brandTranslatePipe.transform('sv.section.additional')
    });
    await this.loadOptions();
    this.loading = false;
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onToggleFinished() {
    const finished = this.list.filter.filters.finished;
    finished.values = (finished.values.length ? [] : [ false ]);
  }

  onToggleAssignable() {
    const assignable = this.list.filter.filters.assignable;
    assignable.values = (assignable.values.length ? [] : [true]);
  }

  onUserChanged(event: any) {
    this.createdByUsers = event.newValue;
    this.list.filter.filters.userIdText = this.createdByUsers
      ? this.createdByUsers.map(u => u.idText)
      : [];
  }

  getStatusDate(ticket: TicketModel): string {
    switch (ticket.status) {
      case TicketStatusEnum.CREATED:
        return ticket.createdDate;
      case TicketStatusEnum.SENT_TO_CONFIRMATION:
        return ticket.sentToConfirmationDate;
      case TicketStatusEnum.APPROVED:
        return ticket.approvedDate;
      case TicketStatusEnum.DISAPPROVED:
        return ticket.disapprovedDate;
      case TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING:
        return ticket.templateSentToProcessingDate;
      case TicketStatusEnum.IMPORTED:
        return ticket.importedDate;
      case TicketStatusEnum.DELETED:
        return ticket.deletedDate;
      case TicketStatusEnum.REQUEST_FOR_CLARIFICATION:
        return ticket.requestForClarificationDate;
      case TicketStatusEnum.CLARIFICATION_REQUEST_DONE:
        return ticket.clarificationRequestDoneDate;
      case TicketStatusEnum.REQUEST_FOR_INFORMATION:
        return ticket.requestForInformationDate;
      case TicketStatusEnum.INFORMATION_REQUEST_DONE:
        return ticket.informationRequestDoneDate;
    }
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    return this.exportListService
      .downloadList('tickets', 'tickets', filter, {actualizationTickets : {load: true}}, true)
      .then(() => { this.exportEnable = true; });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'tickets',
      this.getDefaultFilter(),
      'ticketsFilter',
      this.restangular,
      { actualizedSample: {load: true} }
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private loadOptions(): Promise<any> {
    const p1 = this.restangular
      .all('document-types')
      .getList()
      .toPromise()
      .then(list => {
        this.documentTypeOptions = list.plain();
      });

    const p2 = this.configurationRestangular
      .all('organizational-units').customPOST({
        filter: {
          limit: null,
          sortOrder: [{ sortBy: 'code', direction: 'asc' }],
          filters: {
            cancelled: {
              values: [false],
            },
          }
        }
      })
      .toPromise()
      .then(data => {
        this.organizationalUnitOptions = data.plain();
      });

    const p3 = this.restangularSymap
      .all('obligations')
      .getList()
      .toPromise()
      .then(list => {
        this.obligationOptions = list.plain().map(o => {
          return { id: o.type, name: o.name };
        });
      });

    this.organizationalUnitCode = this.authService.hasPermissionOnProject('template_aproval,template_manage')
      ? []
      : this.authService.getProjectsByPermission();

    return Promise.all([p1, p2, p3]);
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: { values: [{ id: '' }] },
        documentTypeId: { values: [] },
        obligationTypes: { values: [] },
        status: { values: [] },
        organizationalUnitId: { values: [] },
        organizationalUnitCode: { values: this.organizationalUnitCode },
        section: { values: [] },
        finished: { values: [ false ] },
        userIdText: { values: [] },
        assignable: { values: [ ] },
      }
    };
  }
}
