export enum NoteTypeEnum {
  REQUEST_FOR_CLARIFICATION = 'REQUEST_FOR_CLARIFICATION',
  CLARIFICATION_REQUEST_DONE = 'CLARIFICATION_REQUEST_DONE',
  REQUEST_FOR_INFORMATION = 'REQUEST_FOR_INFORMATION',
  INFORMATION_REQUEST_DONE = 'INFORMATION_REQUEST_DONE',
  SAMPLE_WAITING_FOR_ACTUALIZATION = 'SAMPLE_WAITING_FOR_ACTUALIZATION',
  SAMPLE_CHANGED_BY_APPROVER = 'SAMPLE_CHANGED_BY_APPROVER',
}

export const noteTypeOptions = [
  {
    id: NoteTypeEnum.REQUEST_FOR_CLARIFICATION,
    name: 'Žádost o upřesnění požadavku',
  },
  {
    id: NoteTypeEnum.CLARIFICATION_REQUEST_DONE,
    name: 'Doplnění upřesnění požadavku',
  },
  {
    id: NoteTypeEnum.REQUEST_FOR_INFORMATION,
    name: 'Žádost o informaci k zpracování',
  },
  {
    id: NoteTypeEnum.INFORMATION_REQUEST_DONE,
    name: 'Doplnění informace ke zpracování',
  },
  {
    id: NoteTypeEnum.SAMPLE_WAITING_FOR_ACTUALIZATION,
    name: 'Vynucení aktualizace vzoru',
  },
  {
    id: NoteTypeEnum.SAMPLE_CHANGED_BY_APPROVER,
    name: 'Schvalovatel vzoru upravil původní dokumenty vzoru',
  },
];
