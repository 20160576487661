import { Ng2StateDeclaration, StateParams } from '@uirouter/angular';

import { GeometricPlanRequestDetailComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-detail/geometric-plan-request-detail.component';
import { GeometricPlanRequestCreateComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-create/geometric-plan-request-create.component';
import { GeometricPlanRequestListComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-list/geometric-plan-request-list.component';
import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { NoteTypeModel } from '@app/common/models/note-type.model';
import { GeometricPlanRequestCreateFromExistingComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-create-from-existing/geometric-plan-request-create-from-existing.component';
import { Restangular } from 'ngx-restangular';
import {
  GeometricPlanRequestImportComponent
} from "@app/ps/geometric-plans/components/geometric-plan-request-import/geometric-plan-request-import.component";

export function resolveGeometricPlan(restangular: Restangular, stateParams: StateParams): Promise<GeometricPlanRequestModel> {
  return restangular
    .one('geometric-plan-requests', stateParams.id)
    .get({ loadCollections: [ 'areas', 'constructionObjects', 'parcels', 'ngGp.knBudoucs', 'ngGp.knBudoucs.futureParcel' ] })
    .toPromise();
}

export function resolveNoteTypes(restangular): NoteTypeModel[] {
  return restangular
    .all('note-types')
    .customPOST({ filter: { filters: { dialogTypes: ['GeometricPlanRequest']}}})
    .toPromise()
    .then(response => response.plain());
}

export const geometricPlanRequestListState: Ng2StateDeclaration = {
  name: 'symap.project.geometricPlanRequests',
  url: '/geometric-plan-requests?page',
  data: {
    title: 'Geometrické plány',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  views: {
    'content@symap.project': {
      component: GeometricPlanRequestListComponent,
    },
  },
};

export const geometricPlanRequestCreateState: Ng2StateDeclaration = {
  name: 'symap.project.geometricPlanRequests.create',
  url: '/new',
  data: {
    title: 'Nový geometrický plán',
  },
  views: {
    'content@symap.project': {
      component: GeometricPlanRequestCreateComponent,
    },
  },
  resolve: {
    noteTypes: [ Restangular, resolveNoteTypes ],
  },
};

export const geometricPlanRequestCreateFromExistingState: Ng2StateDeclaration = {
  name: 'symap.project.geometricPlanRequests.createFromExisting',
  url: '/new-from-existing',
  data: {
    title: 'Nový geometrický plán',
  },
  views: {
    'content@symap.project': {
      component: GeometricPlanRequestCreateFromExistingComponent,
    },
  },
};

export const geometricPlanRequestDetailState: Ng2StateDeclaration = {
  name: 'symap.project.geometricPlanRequests.detail',
  url: '/:id/:tab',
  data: {
    title: 'Detail geometrického plánu'
  },
  params: {
    id: {
      type: 'int',
    },
    tab: {
      type: 'string',
      value: undefined,
      dynamic: true,
      inherit: false,
      squash: true,
    },
  },
  views: {
    'content@symap.project': {
      component: GeometricPlanRequestDetailComponent,
    },
  },
  resolve: {
    geometricPlanRequest: [ Restangular, '$stateParams', resolveGeometricPlan ],
    noteTypes: [ Restangular, resolveNoteTypes ],
  },
};

export const geometricPlanRequestImportState: Ng2StateDeclaration = {
  name: 'symap.project.geometricPlanRequests.import',
  url: '/import/:id',
  data: {
    title: 'Import GP',
  },
  views: {
    'content@symap.project': {
      component: GeometricPlanRequestImportComponent,
    },
  },
  resolve: {
    geometricPlanRequest: [ Restangular, '$stateParams', resolveGeometricPlan ],
  },
};
