<h1>Osoba zastupující v návrhu na vklad KN</h1>

<gmt-project-subject [subject]="agent"></gmt-project-subject>

<div class="text-center pt-20">
  <gmt-button class="button black" [clickAction]="onCancel">
    Zrušit změny
  </gmt-button>
  <gmt-button class="button" [clickAction]="onSave">
    Použít změny
  </gmt-button>
</div>
