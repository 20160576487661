import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '@app/common/services/dialog-ref';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {

  data: any = {};
  user = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_SETTINGS) private restangularSettings: any,
    private authService: AuthService,
    private dialogRef: DialogRef,
  ) {
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
  }

  onSave() {
    if (!this.authService.isAuthenticated()) {
      return;
    }

    const data = {
      oldPassword: this.data.oldPassword,
      newPassword: this.data.newPassword,
      newPasswordCheck: this.data.newPasswordCheck,
      username: this.data.username,
    };

    return this.restangularSettings.all(`users/user/${this.authService.getUser().id}/password-change`)
      .customPUT(data)
      .toPromise()
      .then(() => {
        this.authService.passwordChanged();
        this.dialogRef.close(true);
      });
  }

  disabledSave() {
    // user must fill current password and new password or fields for changing username
    return !!this.data.oldPassword && !!(this.data.username || (this.data.newPassword && this.data.newPasswordCheck));
  }
}
