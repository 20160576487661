import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { AlertComponent } from '@app/common/components/alert/alert.component';
import { BusinessRegisterRecordModel } from '@app/ps/models/business-register-record.model';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { EnumUtils } from '@app/common/utils/enum.utils';
import { GendersEnum } from '@app/ps/enums/genders.enum';
import { ListService } from '@app/common/services/list.service';
import { MaritalStatusesEnum } from '@app/ps/enums/marital-statuses.enum';
import { AuthService } from '@app/common/services/auth.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { APPLICATIONS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['./basic-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoFormComponent implements OnInit {
  @Input() basicInfo: any;
  @Input() editable = true;
  @Input() editableToggle = false;
  @Input() showAres = true;

  private aresEndpoint: any;
  public restangular: any;
  public GENDERS: any[];
  public MARITAL_STATUSES: any[];
  public searchingEconomicSubjectsRegister = false;
  private cachedInfoFromEconomicSubjectsRegister: any;

  constructor(
    @Inject(APPLICATIONS) applications,
    private restangularSymap: Restangular,
    @Inject(RESTANGULAR_CONFIGURATION) restangularConfig: any,
    authService: AuthService,
    private dialogService: DialogService,
    private listService: ListService,
  ) {
    if (authService.getActiveApplication() === 'SETTINGS') {
      this.restangular = restangularConfig;
    } else {
      this.restangular = restangularSymap;
    }
    this.aresEndpoint = restangularConfig.all('ares');
  }

  ngOnInit() {
    this.MARITAL_STATUSES = EnumUtils.toObject(MaritalStatusesEnum, 'id', 'name');
    this.GENDERS = EnumUtils.toObject(GendersEnum, 'id', 'name');
    this.findInEconomicSubjectsRegister = this.findInEconomicSubjectsRegister.bind(this);

    if (!this.basicInfo.invoicePayment) {
      this.basicInfo.invoicePayment = false; // invoicePayment is expected to be set
    }

    if (!this.basicInfo.vatPayer) {
      this.basicInfo.vatPayer = false; // vatPayer is expected to be set
    }
  }

  async findInEconomicSubjectsRegister() {
    this.searchingEconomicSubjectsRegister = true;
    const company = await this.getCompanyFromEconomiSubjectsRegister();

    if (!company) {
      this.alertOnCompanyNotFoundInEconomicSubjectsRegister();
    } else {
      const courtName = company.businessRegisterName;
      const courtDetail = await this.findBusinessRegisterRegionalCourt(courtName);
      this.basicInfo.businessRegisterRegionalCourt = courtDetail || this.basicInfo.businessRegisterRegionalCourt;
      this.basicInfo.businessRegisterEntry = company.businessRegisterEntry;
      this.basicInfo.businessRegisterSection = company.businessRegisterSection;
      this.basicInfo.dic = company.vatId;
      this.basicInfo.vatPayer = company.vatPayer;
    }

    this.searchingEconomicSubjectsRegister = false;
  }

  private async findBusinessRegisterRegionalCourt(courtName: string): Promise<any> {
    if (!courtName) {
      return;
    }
    const filter = {
      limit: 1,
      filters: {
        businessRegister: true,
        searchText: courtName
      }
    };
    const courts = this.listService.createList('regional-courts', filter, this.restangular);
    await this.listService.fetchResult(courts);
    return courts.list && courts.list[0];
  }

  private alertOnCompanyNotFoundInEconomicSubjectsRegister() {
    this.dialogService.open(AlertComponent, {
      data: {
        msg: 'Subjekt nebyl nalezen.',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });
  }

  private async getCompanyFromEconomiSubjectsRegister(): Promise<BusinessRegisterRecordModel> {
    if (!this.basicInfo.ico) {
      return;
    }

    if (this.cachedInfoFromEconomicSubjectsRegister) {
      return this.cachedInfoFromEconomicSubjectsRegister;
    }

    const companies = await this.aresEndpoint.get('search-by-id', { search: this.basicInfo.ico }).toPromise();
    const result = companies.items && companies.items[0];
    this.cachedInfoFromEconomicSubjectsRegister = result;

    return result;
  }
}
