<div>
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>

<div>
  <fieldset>
    <legend>Odebrat oprávnění</legend>
    <div class="column-100">
      <gmt-input label="Odebrat oprávnění">
        <gmt-checkbox [gmtChecked]="data.removePermissions" (click)="data.removePermissions = !data.removePermissions"></gmt-checkbox>
      </gmt-input>
    </div>
  </fieldset>

  <fieldset *ngIf="!data.removePermissions">
    <legend>Role</legend>
    <div class="column-100">
      <gmt-select
        [data]="data"
        field="role"
        [optionItems]="data.common.roles"
        (changed)="handleRoleSelect()"
        [itemPipe]="rolePipe"
      ></gmt-select>
    </div>

    <div class="column-100" *ngIf="currentRolePermissions && currentRolePermissions.length > 0">
      <span *ngFor="let permission of currentRolePermissions" class="mt-5 mr-5 tag tag--inactive tag--not-clickable">{{permission.value}}</span>
    </div>
  </fieldset>

  <div *ngIf="!data.removePermissions">
    <mirrored-list (changed)="handleItemSelect($event)" [label]="'Oprávnění nad rámec role'" [availableItems]="permissions" [selectedItems]="data.permissions"></mirrored-list>
  </div>
</div>

<div class="mt-10">
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>

