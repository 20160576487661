import { AuthService } from '@app/common/services/auth.service';
import { TableService } from '@app/ps/services/table.service';
import { Component, Inject, OnInit } from '@angular/core';

import { PageableList } from '@app/models/pageable.list';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { StateService } from '@uirouter/angular';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ListModel } from '@app/common/models/list.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ExportListService } from '@app/ps/services/export-list.service';
import { HelpService } from '@app/common/services/help.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrls: []
})
export class ParcelListComponent implements OnInit {

  static readonly COLLECTIONS = [
    'title.ownerships',
    'geometricPlanRequests',
    'opinionRequests',
    'constructionObjects',
    'knBudoucPepvs',
    'parcelPrices',
    'buildings',
  ];

  list: ListModel<ParcelModel>;
  pageableList: PageableList;
  defaultFilter: any;
  deferredUpdateStatistics: any;
  settingsLoaded = false;
  geometricPlanModuleEnabled: boolean;
  opinionModuleEnabled: boolean;
  vfzeModuleEnabled: boolean;
  exportEnable = true;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    private pageableListService: PageableListService,
    private settingsService: SettingsService,
    private tableService: TableService,
    private stateService: StateService,
    private authService: AuthService,
    private exportListService: ExportListService
  ) {
    this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  ngOnInit() {
    const actualProject = this.authService.getActualProject();
    this.geometricPlanModuleEnabled = actualProject.geometricPlanModuleEnabled;
    this.opinionModuleEnabled = actualProject.opinionModuleEnabled;

    this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
    if (!this.settingsLoaded) {
      this.settingsService.getSettingsLoader().then(() => this.settingsLoaded = true);
    }
    const promise = new Promise((resolve, reject) => {
      this.deferredUpdateStatistics = { resolve, reject };
    });
    this.deferredUpdateStatistics.promise = promise;
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  registerUpdateStatisticsEntityFilterKey(registerCallback) {
    this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
  }

  async initStatisticsEntityFilter() {
    if (this.stateService.params.sef) {
      this.list.filter.offset = 0;
      this.list.filter.filters = this.getDefaultFilter().filters;
      delete this.list.filter.filters.validity;
      await this.deferredUpdateStatistics.promise.then((updateEntityFilterKey) => {
        updateEntityFilterKey(this.stateService.params.sef);
      });
    } else {
      if (this.list.filter.filters.statisticsEntityFilterKey &&
        this.list.filter.filters.statisticsEntityFilterKey.values &&
        this.list.filter.filters.statisticsEntityFilterKey.values.length
      ) {
        delete this.list.filter.filters.validity;
      }
    }
  }

  statisticsEntityFilterChanged(changed) {
    if (!changed.state) {
      this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
    }
  }

  checkNeighbors() {
    const exists = this.list.filter.filters.occupationOrEasementExists;
    return exists && (!exists.values || exists.values.length === 0);
  }

  onParcelsCheckboxClicked() {
    this.list.filter.filters.occupationOrEasementExists = { values: this.checkNeighbors() ? [{ id : true }] : [] };
  }

  onExport() {
    this.exportEnable = false;
    const filter = {...this.list.filter};
    filter.filters.loadCollections.push('notCancelledCases');
    return this.exportListService
      .exportList('parcels', 'parcels', filter)
      .then(() => {
        new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
          this.exportEnable = true;
        });
      });
  }

  private getDefaultFilter() {
    return {
      filters: {
        occupationOrEasementExists: {},
        validity: 'valid',
        searchText: { values: [{ id: '' }]},
        areaId: { values: [], negation: false },
        constructionObjectStageId: {},
        constructionObjectId: { values: [], negation: false },
        loadCollections: ParcelListComponent.COLLECTIONS
      }
    };
  }

  private async initializeList() {
    this.pageableList = this.pageableListService.get('parcels', this.getDefaultFilter(), 'parcelsFilter');
    this.list = this.pageableList.list;
    await this.initStatisticsEntityFilter();
    this.pageableList.load();
  }
}
