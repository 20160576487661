const orders = {
  'areas/list':
    [{
      sortBy: 'name',
      direction: 'asc'
    }],
  'titles/list':
    [{
      sortBy: 'area',
      direction: 'asc'
    }, {
      sortBy: 'lv',
      direction: 'asc'
    }],
  'subjects/list':
    [{
      sortBy: 'prijmeni',
      direction: 'asc'
    }, {
      sortBy: 'jmeno',
      direction: 'asc'
    }],
  'parcels/list':
    [{
      sortBy: 'katuzeNazev',
      direction: 'asc'
    }, {
      sortBy: 'parcisKmen',
      direction: 'asc'
    }, {
      sortBy: 'parcisPod',
      direction: 'asc'
    }],
  'road-sections/list':
    [{
      sortBy: 'silnice',
      direction: 'asc'
    }, {
      sortBy: 'poradiUs',
      direction: 'asc'
    }],
  'division-types/list':
    [{
      sortBy: 'name',
      direction: 'asc'
    }],
  'opsub-group/list':
    [{
      sortBy: 'name',
      direction: 'asc'
    }],
};

const defaultOrder = [{
 sortBy: 'id',
 direction: 'asc'
}];

export const getOrder = (path) => orders[path] || defaultOrder;
