<div class="list">
  <div *ngIf="!loading" class="text-right">
    <gmt-button [clickAction]="onExportXlsx" [gmtDisabled]="list.list.length === 0"><i class="fa fa-download"></i> Export seznamu XLSX</gmt-button>
    <gmt-button [clickAction]="onExportPdf" [gmtDisabled]="list.list.length === 0"><i class="fa fa-download"></i> Export seznamu PDF</gmt-button>
  </div>

  <h1>Manažerský report</h1>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <fieldset>
        <form (submit)="onFilter()">
          <div class="filter-items">
            <ng-container *ngFor="let column of columns">
              <gmt-select
                [data]="filtersData"
                field="{{ column.id }}"
                required="true"
                multiselect="true"
                [optionItems]="getFilterItemsByColumn(column)"
                [selectTitle]="column.name"
              ></gmt-select>
            </ng-container>

            <div class="flexbox-container flex-centered"><input type="text" name="startFrom" pick-a-date placeholder="Datum spuštění akce od" [(ngModel)]="filtersData.startFrom" [maxToday]="true"/></div>
            <div class="flexbox-container flex-centered"><input type="text" name="startTo" pick-a-date placeholder="Datum spuštění akce do" [(ngModel)]="filtersData.startTo" [maxToday]="true"/></div>
          </div>
          <div class="column-100">
            <input class="fulltext" name="search" type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="searchText" />
          </div>
          <gmt-button [clickAction]="onFilter" class="filter-submit">Filtrovat</gmt-button>
          <input type="submit" style="visibility: hidden; position: absolute; left: -9999px; width: 1px; height: 1px;" tabindex="-1" />
        </form>
      </fieldset>

      <div class="pt-20 mb-40">
        <div class="bordered-bottom bordered-left bordered-right" *ngIf="list.itemCount">
          <perfect-scrollbar [wheelPropagation]="true" style="width: 100%" [horizontal]="true">
            <table class="table" #managerList>
              <thead>
              <tr>
                <th rowspan="2" class="state-column"><div class="dark-bg"></div></th>
                <th rowspan="2" class="cell" style="min-width: 350px;">
                  <div class="dark-bg">
                    <table visibleByBrand="mpv" class="manager-table manager-table--header">
                      <tr><td colspan="2"><span style="font-size: 1.2rem">Identifikace akce</span></td></tr>
                      <tr><td colspan="2"><b>Název akce</b></td></tr>
                      <tr><td>Evidenční číslo</td><td>Komunikace</td></tr>
                      <tr><td>Organizační jednotka</td><td>Kraj</td></tr>
                      <tr><td>Manažer akce</td><td>Typ akce</td></tr>
                    </table>
                    <table visibleByBrand="maja" class="manager-table manager-table--header">
                      <tr><td colspan="2"><span style="font-size: 1.2rem">Identifikace akce</span></td></tr>
                      <tr><td colspan="2"><b>Název akce</b></td></tr>
                      <tr><td>Evidenční číslo</td><td>Kraj</td></tr>
                      <tr><td>Organizační jednotka</td><td>Manažer akce</td></tr>
                    </table>
                  </div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Celkový stav vypořádání případů v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Počet případů vyvlastnění</div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Trvalý zábor stav vypořádání případů v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Dočasný zábor do 1 roku stav vypořádání případů v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Dočasný zábor nad 1 rok stav vypořádání případů v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
                <th rowspan="2" class="cell">
                  <div>Věcné břemeno stav vypořádání případů v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
                <th colspan="4" class="bordered-bottom">
                  <div>Změny za posledních 30 dní</div>
                </th>
                <th rowspan="2">
                  <div>Datum spuštění akce v {{ APP_BRAND.PS.TITLE }}</div>
                </th>
              </tr>
              <tr>
                <th><div class="bordered-top__none">Počet změn stavů případů</div></th>
                <th><div class="bordered-top__none">Počet případů se změnou stavu</div></th>
                <th><div class="bordered-top__none">Počet založených případů</div></th>
                <th><div class="bordered-top__none">Počet založených znaleckých posudků</div></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let project of list.list">
                <td class="state-column {{ 'bg-' + projectStatePipe.getEnum(project) }}"></td>
                <td style="min-width: 350px;">
                  <table visibleByBrand="mpv" class="manager-table">
                    <tr><td colspan="2"><b>{{ project.name }}</b></td></tr>
                    <tr><td>{{ project.isprofond }}</td><td>{{ project.infrastructure }}</td></tr>
                    <tr><td>{{ project.investor }}</td><td>{{ project.region }}</td></tr>
                    <tr><td>{{ project.manager }}</td><td>{{ project.type }}</td></tr>
                  </table>
                  <table visibleByBrand="maja" class="manager-table">
                    <tr><td colspan="2"><b>{{ project.name }}</b></td></tr>
                    <tr><td>{{ project.isprofond }}</td><td>{{ project.region }}</td></tr>
                    <tr><td>{{ project.investor }}</td><td>{{ project.manager }}</td></tr>
                  </table>
                </td>
                <td class="percentage-cell h-100">
                  <a uiSref="landing.managerReportProjects.detail" [uiParams]="{ projectKey: project.key }" target="_blank">
                    <div class="arrow-1"></div>
                    <div class="arrow-2"></div>
                    <div class="arrow-3">DETAIL</div>
                  </a>
                  <div *ngIf="project.hasDashboard" style="padding-left: 20px;">
                    <ng-container *ngIf="project.total">
                      <gmt-progress-bar [total]="project.total" [done]="project.done" [background]="projectStatePipe.getEnum(project)"></gmt-progress-bar>
                      <div class="text-center pt-7">({{ project.done }} z {{ project.total }})</div>
                      <div class="text-center pt-7 disabled" *ngIf="projectStatePipe.isPartial(project)">(částečná data)</div>
                    </ng-container>
                    <ng-container *ngIf="!project.total">
                      <div class="text-center disabled">-</div>
                      <div class="text-center pt-7 disabled">(neobsahuje data)</div>
                    </ng-container>
                  </div>
                </td>
                <td class="small-column">{{ project.expropriationTotal }}</td>
                <td class="percentage-cell">
                  <div *ngIf="project.hasDashboard">
                    <ng-container *ngIf="project.tzTotal">
                      <gmt-progress-bar [total]="project.tzTotal" [done]="project.tzDone" [background]="projectStatePipe.getEnum(project)"></gmt-progress-bar>
                      <div class="text-center pt-7">({{ project.tzDone }} z {{ project.tzTotal }})</div>
                      <div class="text-center pt-7 disabled" *ngIf="project.readinessPermanentOccupations === 'PARTIAL_DATA'">(částečná data)</div>
                    </ng-container>
                    <ng-container *ngIf="!project.tzTotal">
                      <div class="text-center">-</div>
                      <div class="text-center pt-7 disabled">
                        <span *ngIf="project.readinessPermanentOccupations">({{ project.readinessPermanentOccupations | readiness }})</span>
                        <span *ngIf="!project.readinessPermanentOccupations">(neobsahuje data)</span>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td class="percentage-cell">
                  <div *ngIf="project.hasDashboard">
                    <ng-container *ngIf="project.dzdrTotal">
                      <gmt-progress-bar [total]="project.dzdrTotal" [done]="project.dzdrDone" [background]="projectStatePipe.getEnum(project)"></gmt-progress-bar>
                      <div class="text-center pt-7">({{ project.dzdrDone }} z {{ project.dzdrTotal }})</div>
                      <div class="text-center pt-7 disabled" *ngIf="project.readinessTemporaryUnderOccupations === 'PARTIAL_DATA'">(částečná data)</div>
                    </ng-container>
                    <ng-container *ngIf="!project.dzdrTotal">
                      <div class="text-center disabled">-</div>
                      <div class="text-center pt-7 disabled">
                        <span *ngIf="project.readinessTemporaryUnderOccupations">({{ project.readinessTemporaryUnderOccupations | readiness }})</span>
                        <span *ngIf="!project.readinessTemporaryUnderOccupations">(neobsahuje data)</span>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td class="percentage-cell">
                  <div *ngIf="project.hasDashboard">
                    <ng-container *ngIf="project.dznrTotal">
                      <gmt-progress-bar [total]="project.dznrTotal" [done]="project.dznrDone" [background]="projectStatePipe.getEnum(project)"></gmt-progress-bar>
                      <div class="text-center pt-7">({{ project.dznrDone }} z {{ project.dznrTotal }})</div>
                      <div class="text-center pt-7 disabled" *ngIf="project.readinessTemporaryOverOccupations === 'PARTIAL_DATA'">(částečná data)</div>
                    </ng-container>
                    <ng-container *ngIf="!project.dznrTotal">
                      <div class="text-center disabled">-</div>
                      <div class="text-center pt-7 disabled">
                        <span *ngIf="project.readinessTemporaryOverOccupations">({{ project.readinessTemporaryOverOccupations | readiness }})</span>
                        <span *ngIf="!project.readinessTemporaryOverOccupations">(neobsahuje data)</span>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td class="percentage-cell">
                  <div *ngIf="project.hasDashboard">
                    <ng-container *ngIf="project.vbTotal">
                      <gmt-progress-bar [total]="project.vbTotal" [done]="project.vbDone" [background]="projectStatePipe.getEnum(project)"></gmt-progress-bar>
                      <div class="text-center pt-7">({{ project.vbDone }} z {{ project.vbTotal }})</div>
                      <div class="text-center pt-7 disabled" *ngIf="project.readinessTemporaryEasements === 'PARTIAL_DATA'">(částečná data)</div>
                    </ng-container>
                    <ng-container *ngIf="!project.vbTotal">
                      <div class="text-center disabled">-</div>
                      <div class="text-center pt-5 disabled">
                        <span *ngIf="project.readinessTemporaryEasements">({{project.readinessTemporaryEasements | readiness }})</span>
                        <span *ngIf="!project.readinessTemporaryEasements">(neobsahuje data)</span>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td class="small-column">{{ project.changedCaseStatuses }}</td>
                <td class="small-column">{{ project.changedCases }}</td>
                <td class="small-column">{{ project.createdCases }}</td>
                <td class="small-column">{{ project.createdOpinions }}</td>
                <td class="small-column">{{ project.projectStartDate | date:'mediumDate' }}</td>
              </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <div class="empty-info bordered" *ngIf="!list.itemCount">
          <span>Nenalezena žádná data</span>
        </div>
        <div class="mt-10">
          Legenda:
          <span *ngFor="let status of statuses">
              <span class="legend bg-{{ status }}"></span> {{ ('ps.projectState.' + status) | brandTranslate }}
            </span>
        </div>
        <div class="flexbox-container mt-10" *ngIf="list.itemCount">
          <itemscounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="itemCounterCallbackRegister($event)" class="flex-start"></itemscounter>
          <pagescounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="pagesCounterCallbackRegister($event)" class="flex1"></pagescounter>
          <div class="page-count-select">
            <span>Záznamů na stránce</span>
            <gmt-select
              required="true"
              [data]="dataPaging"
              field="itemsPerPage"
              [optionItems]="itemsPerPage"
              class="min-width-unset"
              (changed)="onPageItemsChanged()"
            ></gmt-select>
          </div>
        </div>
        <div class="mt-10">
          <pagination *ngIf="list.itemCount" [shouldUpdateUrl]="false" [list]="list" (pageChanged)="onPageChanged($event)" (callbackRegister)="paginationCallbackRegister($event)"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
