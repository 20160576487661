<div>
  <div *ngIf="editable" class="actions">
    <gmt-button class="fr" [clickAction]="onAresClick">Vyhledat subjekt v systému ARES</gmt-button>
  </div>

  <!-- typ osoby -->
  <fieldset *ngIf="editable" [ngClass]="{'bordered': !editable}">
    <legend>Typ osoby</legend>
    <gmt-switch
      [selected]="currentCustomerType"
      [options]="customerTypes"
      (select)="onCustomerTypeSelect($event)"
    ></gmt-switch>
  </fieldset>

  <fieldset [ngClass]="{'bordered': !editable}">
    <legend>Základní údaje</legend>

    <div class="column-100">
      <gmt-input *ngIf="editable" label="IČO">
        <input type="text" [ngModel]="basicInfo.ico" (ngModelChange)="onIdentificationNumberChange($event)" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>IČO</label>
        <div class="info">{{ basicInfo.ico }}</div>
      </ng-container>

      <gmt-input *ngIf="editable" label="Název">
        <input type="text" [(ngModel)]="customer.companyName" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>Název</label>
        <div class="info">{{ customer.companyName }}</div>
      </ng-container>

      <gmt-input *ngIf="editable" label="Zkratka">
        <input type="text" [(ngModel)]="customer.abbreviation" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>Zkratka</label>
        <div class="info">{{ customer.abbreviation }}</div>
      </ng-container>

      <ng-container *ngIf="!editable">
        <label>Typ osoby</label>
        <div class="info">{{ currentCustomerType.value }}</div>
      </ng-container>
    </div>
  </fieldset>

  <fieldset [ngClass]="{'bordered': !editable}" *ngIf="editable || customer.rootCustomer">
    <legend>Je správou/závodem</legend>

    <div class="column-100">
      <gmt-input *ngIf="editable" label="Nadřazený subjekt">
        <gmt-select
          [data]="customer"
          field="rootCustomer"
          [itemPipe]="customerNamePipe"
          [restangularService]="restangular"
          [filter]="parentCustomerFilter"
          resource="v2/customers"
        ></gmt-select>
      </gmt-input>

      <ng-container *ngIf="!editable">
        <label>Nadřazený subjekt</label>
        <div class="info"> {{ customer.rootCustomer?.companyName }}</div>
      </ng-container>
    </div>
  </fieldset>

  <basic-info-form [showAres]="false" [editable]="editable" [basicInfo]="basicInfo"></basic-info-form>

  <fieldset [ngClass]="{'bordered': !editable}">
    <legend>WSDP údaje CtiOS</legend>
    <div class="column-100">

      <gmt-input *ngIf="editable" label="ČÚZK WSDP jméno">
        <input type="text" [(ngModel)]="customer.cuzkWsdpUsername" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>ČÚZK WSDP jméno</label>
        <div class="info">{{ customer.cuzkWsdpUsername }}</div>
      </ng-container>

      <gmt-input *ngIf="editable" label="ČÚZK WSDP heslo">
        <input type="text" [(ngModel)]="customer.cuzkWsdpPassword" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>ČÚZK WSDP heslo</label>
        <div class="info">{{ customer.cuzkWsdpPassword }}</div>
      </ng-container>
    </div>
  </fieldset>

  <fieldset [ngClass]="{'bordered': !editable}">
    <legend>WSDP údaje pro online aktualizaci</legend>
    <div class="column-100">

      <gmt-input *ngIf="editable" label="ČÚZK WSDP jméno">
        <input type="text" [(ngModel)]="customer.cuzkWsdpOnlineUsername" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>ČÚZK WSDP jméno</label>
        <div class="info">{{ customer.cuzkWsdpOnlineUsername }}</div>
      </ng-container>

      <gmt-input *ngIf="editable" label="ČÚZK WSDP heslo">
        <input type="text" [(ngModel)]="customer.cuzkWsdpOnlinePassword" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>ČÚZK WSDP heslo</label>
        <div class="info">{{ customer.cuzkWsdpOnlinePassword }}</div>
      </ng-container>
    </div>
  </fieldset>

  <address-form
    class="no-country-code"
    [address]="address"
    [collapsed]="true"
    [editable]="editable"
  ></address-form>

  <address-form
    *ngIf="editable || customer.deliveryUse"
    class="no-country-code"
    [address]="mailingAddress"
    [collapsed]="!editable || customer.deliveryUse"
    [editable]="editable"
    heading="Adresa doručovací pro zápis do KN"
    toggleText="Jiná doručovací adresa"
    (toggle)="onToggleMailingAddress($event)"
  ></address-form>

  <contact-form [editable]="editable" [contactInfo]="customer"></contact-form>

  <fieldset [ngClass]="{'bordered': !editable}">
    <legend>Platební údaje</legend>
    <div class="column-33">
      <gmt-input *ngIf="editable" label="Číslo účtu">
        <input type="text" [(ngModel)]="customer.accountNumber" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>Číslo účtu</label>
        <div class="info">{{ customer.accountNumber }}</div>
      </ng-container>
    </div>

    <div class="column-33">
      <gmt-input *ngIf="editable" label="Kód banky">
        <input type="text" [(ngModel)]="customer.bankCode" />
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>Kód banky</label>
        <div class="info">{{ customer.bankCode }}</div>
      </ng-container>
    </div>
  </fieldset>

  <fieldset *ngIf="basicInfo.opsubType.indexOf('OPO') === 0" [ngClass]="{'bordered': !editable, 'no-padding': !editable && !customer.actingPersons.length}">
    <legend>Jednající osoby</legend>
    <div class="empty-info" *ngIf="!customer.actingPersons.length">Subjekt nemá žádné jednající osoby</div>
    <div class="table mb-10" *ngIf="customer.actingPersons.length" [ngClass]="{'pl-40': editable}">
      <div class="row-group" *ngFor="let acting of customer.actingPersons; let $index = index; let $count = count;">
        <div class="row pb-10">
          <div class="action-cell" *ngIf="editable">
            <a class="button red" (click)="onCancelActing(acting)" *ngIf="!acting.cancelled"><i class="fa fa-minus"></i></a>
            <a class="button green" (click)="onRestoreActing(acting)" *ngIf="acting.cancelled"><i class="fa fa-repeat"></i></a>
          </div>
          <div class="cell" [ngClass]="{ cancelled : acting.cancelled, 'bordered-bottom': editable || $index < $count-1}">
            <div class="column-100" *ngIf="editable">
              <gmt-input label="Křestní jméno"><input type="text" [(ngModel)]="acting.firstName"/></gmt-input>
              <gmt-input label="Příjmení"><input type="text" [(ngModel)]="acting.surname"/></gmt-input>
              <gmt-input label="Titul před jménem"><input type="text" [(ngModel)]="acting.titleBefore"/></gmt-input>
              <gmt-input label="Titul za jménem"><input type="text" [(ngModel)]="acting.degreeAfter"/></gmt-input>
              <gmt-input label="Funkce"><input type="text" [(ngModel)]="acting.function"/></gmt-input>
              <gmt-input label="Na základě pověření ze dne"><input type="text" pick-a-date [(ngModel)]="acting.powerOfAttorneyDate"/></gmt-input>
              <gmt-input label="E-mail"><input type="text" [(ngModel)]="acting.email"/></gmt-input>
              <gmt-input label="Telefon"><input type="text" [(ngModel)]="acting.phoneNumber"/></gmt-input>
              <gmt-input label="Role">
                <div (click)="acting.roleContract = !acting.roleContract">
                  <gmt-checkbox [gmtChecked]="acting.roleContract"></gmt-checkbox> Podpis smlouvy
                </div>
                <div (click)="acting.roleCadastre = !acting.roleCadastre;">
                  <gmt-checkbox [gmtChecked]="acting.roleCadastre"></gmt-checkbox> Jednání v návrhu na vklad
                </div>
              </gmt-input>
            </div>
            <div class="column-100" *ngIf="!editable">
              <label>Křestní jméno</label>
              <div class="info">{{ acting.firstName }}</div>
              <label>Příjmení</label>
              <div class="info">{{ acting.surname }}</div>
              <label>Titul před jménem</label>
              <div class="info">{{ acting.titleBefore }}</div>
              <label>Titul za jménem</label>
              <div class="info">{{ acting.degreeAfter }}</div>
              <label>Funkce</label>
              <div class="info">{{ acting.function }}</div>
              <label>Na základě pověření ze dne</label>
              <div class="info">{{ acting.powerOfAttorneyDate | date:'d.M.yyyy' }}</div>
              <label>E-mail</label>
              <div class="info">{{ acting.email }}</div>
              <label>Telefon</label>
              <div class="info">{{ acting.phoneNumber }}</div>
              <label>Role</label>
              <div class="info comma-list">
                <span *ngIf="acting.roleContract">Podpis smlouvy</span>
                <span *ngIf="acting.roleCadastre">Jednání v návrhu na vklad</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column-100" *ngIf="editable">
      <a class="button" (click)="onAddActing()"><i class="fa fa-plus"></i></a>
    </div>
  </fieldset>

  <fieldset [ngClass]="{'bordered': !editable}">
    <legend>Data katastru nemovitostí</legend>

    <div class="column-100">
      <gmt-input *ngIf="editable"  label="ID oprávněného subjektu"><input type="text" [(ngModel)]="customer.opsubId"/></gmt-input>
      <ng-container *ngIf="!editable">
        <label>ID oprávněného subjektu</label>
        <div class="info">{{ customer.opsubId }}</div>
      </ng-container>
    </div>
    <div class="column-50" *ngIf="basicInfo.opsubType === 'OPO_PO'">
      <gmt-input *ngIf="editable" label="ID nadřazeného subjektu"><input type="text" [(ngModel)]="customer.parentOpsubId"/></gmt-input>
      <ng-container *ngIf="!editable">
        <label>ID nadřazeného subjektu</label>
        <div class="info">{{ customer.parentOpsubId }}</div>
      </ng-container>
    </div>
    <div class="column-50" *ngIf="basicInfo.opsubType !== 'OPO'">
      <gmt-input *ngIf="editable" label="Typ právního vztahu">
        <gmt-select *ngIf="basicInfo.opsubType === 'OPO_PO'"
                    [data]="this"
                    field="ownershipType"
                    [optionItems]="ownershipTypePOItems"
                    (changed)="ownershipType = $event.newValue; customer.ownershipType = $event.newValue ? $event.newValue.id : null"
        ></gmt-select>
        <gmt-select  *ngIf="basicInfo.opsubType === 'OPO_OSS'"
                     [data]="this"
                     field="ownershipType"
                     [optionItems]="ownershipTypeOSSItems"
                     (changed)="ownershipType = $event.newValue; customer.ownershipType = $event.newValue ? $event.newValue.id : null"
        ></gmt-select>
      </gmt-input>
      <ng-container *ngIf="!editable">
        <label>Typ právního vztahu</label>
        <div class="info">{{ ownershipType ? ownershipType.name : '' }}</div>
      </ng-container>
    </div>
  </fieldset>

  <fieldset *ngIf="basicInfo.opsubType.indexOf('OPO') === 0" [ngClass]="{'bordered': !editable, 'no-padding': !editable && !customer.organizationalUnits.length}">
    <legend>Organizační jednotky</legend>
    <div class="empty-info" *ngIf="!customer.organizationalUnits.length">Subjekt nemá žádné organzační jednotky</div>
    <div class="table mb-10" *ngIf="customer.organizationalUnits.length" [ngClass]="{'pl-40': editable}">
      <div class="row-group" *ngFor="let unit of customer.organizationalUnits; let $index = index; let $count = count;">
        <div class="row pb-10">
          <div class="action-cell" *ngIf="editable">
            <a class="button red" (click)="onCancelUnit(unit)" *ngIf="!unit.cancelled"><i class="fa fa-minus"></i></a>
            <a class="button green" (click)="onRestoreUnit(unit)" *ngIf="unit.cancelled"><i class="fa fa-repeat"></i></a>
          </div>
          <div class="cell" [ngClass]="{ cancelled : unit.cancelled, 'bordered-bottom': editable || $index < $count-1}">
            <div class="column-100" *ngIf="editable">
              <gmt-input label="Kód"><input type="text" [(ngModel)]="unit.code"/></gmt-input>
              <gmt-input label="Úsek"><gmt-select
                [data]="{selected: unit.section !== undefined ? {id: unit.section, name: unit.section} : undefined}"
                [optionItems]="getOptions('section')"
                (changed)="onSectionChanged(unit, $event.newValue)"
                selectTitle="vybrat úsek"
              ></gmt-select></gmt-input>
              <gmt-input label="Název"><input type="text" [(ngModel)]="unit.name"/></gmt-input>
            </div>
            <div class="column-100" *ngIf="!editable">
              <label>Kód</label>
              <div class="info">{{ unit.code }}</div>
              <label>Úsek</label>
              <div class="info">{{ unit.section }}</div>
              <label>Název</label>
              <div class="info">{{ unit.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column-100" *ngIf="editable">
      <a class="button" (click)="onAddUnit()"><i class="fa fa-plus"></i></a>
    </div>
  </fieldset>
</div>

<div *ngIf="editable" class="actions" style="justify-content: center">
  <gmt-button [clickAction]="onSubmit">Uložit</gmt-button>
</div>

