import {Inject, Injectable, Pipe, PipeTransform} from '@angular/core';
import { SvAppModule } from '@app/sv/sv.modules';
import { TicketStatusEnum, ticketStatusOptions } from '@app/sv/enums/ticket-status.enum';
import {APP_BRAND} from "@app/common/services/config.service";

@Pipe({ name: 'ticketStatusName' })
@Injectable({
  providedIn: 'root'
})
export class TicketStatusNamePipe implements PipeTransform {

  constructor(
    @Inject(APP_BRAND) private APP_BRAND: any
  ) {
  }

  transform(value: TicketStatusEnum): string {
    const option = ticketStatusOptions.find(option => option.id === value);
    return this.translate(option.name);
  }

  translate(name: string): string {
    return name.replace('PS.TITLE', this.APP_BRAND.PS.TITLE);
  }
}
