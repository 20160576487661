<div [loading]="!ownershipsWithParent">
  <div *ngIf="ownershipsWithParent">
    <h2>Výběr nadřazeného subjektu</h2>


    <fieldset class="bordered" *ngFor="let item of ownershipsWithParent">
      <legend *ngIf="item.caseOwnership.ownership.isBsm">Vlastník - Osoba SJM</legend>
      <legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OPO'">Vlastník - Společnost</legend>
      <legend *ngIf="item.caseOwnership.ownership.subjects[0].opsubType === 'OFO'">Vlastník - Fyzická osoba</legend>
      <div class="column-100">
        <label>{{item.caseOwnership.ownership.subjects[0].opsubType === 'OPO' ? 'Název' : 'Jméno'}}</label>
        <div class="info">
          <b>{{item.caseOwnership.ownership|ownershipName}}</b>
        </div>
      </div>
      <div class="column-100" *ngIf="data.titles.length > 1">
        <label>LV</label>
        <div class="info">
          <b>{{item.caseOwnership.ownership.title|titleName}}</b>
        </div>
      </div>
      <div class="column-100">
        <label>Typ práv</label>
        <div class="info">{{item.caseOwnership.ownership.typravNazev}}</div>
      </div>
      <div class="column-100">
        <label>Nadřazený subjekt</label>
        <div class="input"><gmt-select [optionItems]="item.optionItems" [itemPipe]="parentCaseOwnershipNamePipe" [data]="item.caseOwnership" field="parentCaseOwnership" (changed)="changedParent($event.newValue, $event.oldValue, item, data.caseOwnerships, ownershipsWithParent)"></gmt-select></div>
      </div>
    </fieldset>

    <fieldset class="bordered" *ngIf="loading"><div [loading]="loading"></div></fieldset>

    <div class="mt-20">
      <gmt-button [gmtDisabled]="needsParentSelection()" [clickAction]="submit">Další</gmt-button>
    </div>
  </div>
</div>
