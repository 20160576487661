import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { PkAppModule } from '../pk.module';
import { RequestStatusEnum } from '../enums/request-status.enum';

@Pipe({ name: 'statusMessage' })
@Injectable({
  providedIn: 'root'
})
export class StatusMessagePipe implements PipeTransform {
  transform(value: RequestStatusEnum): string {
    switch (value) {
      case RequestStatusEnum.FAILED:
        return 'Chyba';
      case RequestStatusEnum.FINISHED:
        return 'Hotovo';
      case RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION:
        return 'Odsouhlasit cenu';
      case RequestStatusEnum.CUZK_DATA_RECEIVED:
      case RequestStatusEnum.INTERSECTION:
        return 'Protíná se';
      default:
        return 'Vyřizuje se';
    }
  }
}
