import * as _ from 'lodash';

export class CallbackModel {

  callbacks = {};

  constructor() {
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.get = this.get.bind(this);
  }

  add(name, callbackFunction) {
    if (this.callbacks[name]) {
      this.callbacks[name].push(callbackFunction);
    } else {
      this.callbacks[name] = [callbackFunction];
    }
  }

  remove(name, callbackFunction) {
    _.pull(this.callbacks[name], callbackFunction);
    if (this.callbacks[name] && this.callbacks[name].length === 0) {
      delete this.callbacks[name];
    }
  }

  get(name) {
    return (...callbackArguments) => {
      return this.callbacks[name] ? this.callbacks[name].forEach(cb => cb(...callbackArguments)) : null;
    };
  }
}
