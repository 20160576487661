import { Injectable, Inject } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { ListService } from '@app/common/services/list.service';
import * as _ from 'lodash';
import { AuthService } from '@app/common/services/auth.service';
import { EVENTS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  statisticsSettings;
  statisticsFilterSettings;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private listService: ListService,
  ) {
    this.removeLoadedStatistics = this.removeLoadedStatistics.bind(this);
    this.authService.registerCallback(EVENTS.authProjectUpdated, this.removeLoadedStatistics);
  }

  getStatisticsList(): ListModel<any> {
    if (!this.statisticsSettings) {
      this.statisticsSettings = this.listService.createList('statistics', {sortOrder: [ { sortBy: 'statisticsOrder' }, { sortBy: 'name' } ]});
      // this.listService.fetchResult(this.statisticsSettings);
    }

    return this.statisticsSettings;
  }

  getStatisticsFilters(): ListModel<any> {
    if (!this.statisticsFilterSettings) {
      this.statisticsFilterSettings = this.listService.createList(
        'statistics/filters',
        {
          filters: { available: true },
          sortOrder: [ { sortBy: 'statistics.statisticsOrder' }, { sortBy: 'name' } ]
        }
      );
      this.listService.fetchResult(this.statisticsFilterSettings);
    }

    return this.statisticsFilterSettings;
  }

  removeLoadedStatistics() {
    if (this.statisticsSettings) {
      this.listService.cancelFetch(this.statisticsSettings);
      this.statisticsSettings = undefined;
    }
    this.removeLoadedStatisticsFilters();
  }

  updateStorageValue(statistics) {
    const key = this.geStorageKey();
    if (key) {
      this.localStorageService.set(key, _.assign(this.getStorageValue(), statistics));
    }
  }

  getStorageKeyValue(valueKey) {
    let value = this.getStorageValue();
    if (value && value[valueKey] !== undefined) {
      return value[valueKey];
    }
    value = this.getDefaultValue();
    if (value[valueKey] !== undefined) {
      return value[valueKey];
    }
  }

  getDefaultKeyValue(valueKey) {
    const value = this.getDefaultValue();
    if (value) {
      return value[valueKey];
    }
  }

  removeLoadedStatisticsFilters() {
    if (this.statisticsFilterSettings) {
      this.listService.cancelFetch(this.statisticsFilterSettings);
      this.statisticsFilterSettings = undefined;
    }
  }

  geStorageKey() {
    return this.authService.getActualProject()
      ? this.authService.getActualProject().key + '.' + 'homeSelectedStatistics'
      : null;
  }

  getDefaultValue() {
    return {
      entityTab: 'cases',
      categoryTab: 1,
      intervalTab: 0,
    };
  }

  getStorageValue() {
    const key = this.geStorageKey();
    if (!key) {
      return null;
    }
    let value = this.localStorageService.get(key);

    if (!value || typeof value !== 'object') {
      value = {};
    }
    return value;
  }
}
