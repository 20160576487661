import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { RequestStatusEnum } from '@app/pk/enums/request-status.enum';
import { RequestModel } from '@app/pk/models/request.model';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list';
import { ListModel } from '@app/common/models/list.model';
import { User } from '@app/models/user';
import { AuthService } from '@app/common/services/auth.service';
import { StatusService } from '@app/pk/services/status.service';
import { HelpService } from '@app/common/services/help.service';

@Component({
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  // model
  dragging = false;
  list: ListModel<RequestModel>;
  pageableList: PageableList;
  user: User;
  readonly STATUSES: typeof RequestStatusEnum = RequestStatusEnum;
  showRunningCadastreImportNotification = false;
  helpIds = HelpService.HELP_IDS;

  constructor(
      private pageableListService: PageableListService,
      private stateService: StateService,
      private authService: AuthService,
      private statusService: StatusService,
  ) {
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
  }

  async ngOnInit() {
    this.initializeProjectList();
    this.user = this.authService.getUser();
    this.showRunningCadastreImportNotification = await this.isCadastreUpdateRunning();
  }

  private async initializeProjectList() {
    const filters = {
      filters: {
        searchText: {values: [{id: ''}]}
      }
    };

    this.pageableList = this.pageableListService.get('projects', filters, 'pkProjectsFilter');
    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  goToProjectDetail(wasTextSelected: boolean, project: RequestModel) {
    if (wasTextSelected) {
      return;
    }

    const projectDetailState = 'pk.projects.create-project-third';

    this.stateService.go(projectDetailState, {
      requestId: project.requestId,
      title: project.name,
      subtitle: 'Získání dat'
    });
  }

  private async isCadastreUpdateRunning(): Promise<boolean> {
    const state = await this.statusService.getStatus();
    return state.runningUpdate;
  }
}
