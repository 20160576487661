import { Pipe, PipeTransform, Injectable } from "@angular/core";
import {EasementModel} from "@app/common/models/easement.model";

@Pipe({
  name: 'easementOccupation',
})
@Injectable({
  providedIn: 'root'
})
export class EasementOccupationPipe implements PipeTransform {

  transform(easement: EasementModel) {
    switch (easement.vbTz) {
      case 1:
        return 'v trvalém záboru';
      case 2:
        return 'mimo trvalý zábor';
      default:
        return 'neurčeno';
    }
  }
}
