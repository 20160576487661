import { Ng2StateDeclaration, Transition, StateDeclaration, StateParams, StateService } from '@uirouter/angular';

import { ClassName } from '@app/common/enums/class-name.enum';
import { ContractDetailComponent } from './customers/components/contract-detail/contract-detail.component';
import { ContractFormComponent } from './customers/components/contract-form/contract-form.component';
import { ContractService } from '../settings/customers/services/contract.service';
import { CustomerDetailComponent } from '@app/settings/customers/components/customer-detail/customer-detail.component';
import { CustomerEditComponent } from '@app/settings/customers/components/customer-edit/customer-edit.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ProjectFormComponent } from './customers/components/project-form/project-form.component';
import { CustomerListComponent } from '@app/settings/customers/components/customer-list/customer-list.component';
import { MainComponent } from '@app/settings/main/main.component';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import {UIRouter} from "@uirouter/core";
import {AuthService} from "@app/common/services/auth.service";

export const settingsState: Ng2StateDeclaration = {
  name: 'settings',
  url: '/settings',
  abstract: true,
  component: MainComponent,
};

export const customerListState: Ng2StateDeclaration = {
  name: 'settings.customers',
  url: '/customers?page',
  data: {
    title: 'Správa objednatelů',
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
      squash: true,
    },
  },
  views: {
    'content@settings': {
      component: CustomerListComponent,
    },
  },
};

export const customerCreateState: Ng2StateDeclaration = {
  name: 'settings.customers.create',
  url: '/create',
  data: {
    title: 'Nový objednatel',
  },
  views: {
    'content@settings': {
      component: CustomerEditComponent,
    }
  },
};

export const customerDetailState: Ng2StateDeclaration = {
  name: 'settings.customers.detail',
  url: '/:customerId',
  data: {
    title: 'Detail objednatele',
  },
  resolve: {
    customer: ['$transition$', resolveCustomer],
  },
  views: {
    'content@settings': {
      component: CustomerDetailComponent,
    }
  }
};

export const customerEditState: Ng2StateDeclaration = {
  name: 'settings.customers.detail.edit',
  data: {
    backButtonText: 'Detail objednatele',
    title: 'Editace objednatele',
  },
  url: '/edit',
  views: {
    'content@settings': {
      component: CustomerEditComponent,
    }
  },
};

export const customerContractAbstractState: Ng2StateDeclaration = {
  abstract: true,
  name: 'settings.customers.detail.contract',
  url: '/contract',
};

export const customerContractState: Ng2StateDeclaration = {
  name: 'settings.customers.detail.contract.new',
  url: '/new',
  data: {
    title: 'Nová smlouva',
  },
  resolve: {
    customerId: ['$stateParams', getCustomerId],
  },
  onEnter: newContractDialog,
};

export const customerContractDetailState: Ng2StateDeclaration = {
  name: 'settings.customers.detail.contract.detail',
  url: '/:id',
  data: {
    title: 'Detail smlouvy',
  },
  resolve: {
    contract: ['$transition$', '$stateParams', getContract],
  },
  views: {
    'content@settings': {
      component: ContractDetailComponent,
    }
  },
};

export const customerContractEditState: Ng2StateDeclaration = {
  name: 'settings.customers.detail.contract.detail.edit',
  url: '/edit',
  data: {
    title: 'Editace smlouvy',
  },
  onEnter: editContractDialog,
};

export const customerContractProjectState: Ng2StateDeclaration = {
  name: 'settings.customers.detail.contract.detail.project',
  url: '/project',
  data: {
    title: 'Přidat projekt',
  },
  onEnter: newProjectDialog,
};

export async function resolveCustomer(transition: Transition) {
  transition.injector().get(AuthService); // magie - tohle se musi zavolat, aby byl nastaven restangular auth token
  const restangular = transition.injector().get(RESTANGULAR_CONFIGURATION);
  return restangular.one('v2/customers', transition.params().customerId).get().toPromise();
}

export function newProjectDialog(transition: Transition, state: StateDeclaration) {
  const stateService = transition.injector().get(UIRouter).stateService;
  const dialogService = transition.injector().get(DialogService);

  const dialog = dialogService.open(ProjectFormComponent, {
    data: {
      customerId: transition.params().customerId,
      contractId: transition.params().id,
    },
    className: ClassName.HIGHER_DIALOG,
  });

  const sub = dialog.afterClosed.subscribe((res: boolean) => {
    stateService.go('^', {}, {reload: res === true});
    sub.unsubscribe();
  });
}

export function getCustomerId(stateParams: StateParams) {
  return stateParams.customerId;
}

export function getContract(transition: Transition, stateParams: StateParams) {
  const contractService = transition.injector().get(ContractService);
  return contractService.getOne(stateParams.id);
}

export function newContractDialog(transition: Transition) {
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const customerId = transition.injector().get('customerId');
  const dialog = dialogService.open(ContractFormComponent, {
    data: {
      customerId,
    },
  });

  const sub = dialog.afterClosed.subscribe((res: boolean) => {
    stateService.go('^.^', {}, {reload: res === true});
    sub.unsubscribe();
  });
}

export function editContractDialog(transition: Transition) {
  const dialogService = transition.injector().get(DialogService);
  const stateService = transition.injector().get(UIRouter).stateService;
  const contract = transition.injector().get('contract');
  const dialog = dialogService.open(ContractFormComponent, {
    data: {
      contract,
    },
  });

  const sub = dialog.afterClosed.subscribe(() => {
    stateService.go('^');
    sub.unsubscribe();
  });
}
