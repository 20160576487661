import { Component, OnInit, Inject, Input } from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';

import {
  Permission,
  PermissionPayload,
  PermissionWithRoles
} from '@app/common/components/user-permissions/user-permissions.component';
import { SelectItem } from '@app/common/components/select/select.component';
import { RolePipe } from '@app/common/pipes/role.pipe';
import {DialogRef} from "@app/common/services/dialog-ref";
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";
import {ModulesService} from "@app/common/services/modules.service";
import {APPLICATIONS} from "@app/common/services/config.service";
import {RESTANGULAR_CONFIGURATION} from "@app/common/services/restangular-configuration.service";
import {RESTANGULAR_SETTINGS} from "@app/common/services/restangular-settings.service";

// @TODO - preneseno z modalu UserPermission - zaslouzilo by to refaktor
@Component({
  selector: 'sv-permission',
  templateUrl: './sv-permission.component.html',
})
export class SvPermissionComponent implements OnInit {
  loading = true;
  userId: number;
  organizationalUnitCode: string;

  user;
  permissionsWithRoles;
  currentRole;
  currentPermissions;
  roles: SelectItem[];

  permissions;
  initialPermissions;
  currentRolePermissions;

  constructor(
    private modulesService: ModulesService,
    @Inject(APPLICATIONS) private applications: any,
    @Inject(RESTANGULAR_CONFIGURATION) public configurationRestangular: any,
    @Inject(RESTANGULAR_SETTINGS) public restangular: any,
    private dialogRef: DialogRef,
    dialogConfig: DialogConfig,
    public rolePipe: RolePipe,
  ) {
    this.userId = (dialogConfig.data as DialogConfigData).userId;
    this.organizationalUnitCode = (dialogConfig.data as DialogConfigData).organizationalUnitCode;
    this.onSave = this.onSave.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
  }

  async ngOnInit() {
    this.user = await this.modulesService.getUser(this.userId);
    this.roles = await this.restangular.all(`${this.applications.sv.name}/roles?withDeprecated=true&withGlobal=false`).getList().toPromise();

    const detailedPermissions = await this.modulesService.getPermissions(this.applications.sv.name);
    const rolesWithPermissions = await this.modulesService.getModuleRoles(this.applications.sv.name);
    const currentUserPermissions = (await this.modulesService.getUserPermissions(this.applications.sv.name, this.user.id))[this.organizationalUnitCode] || [];
    const currentRole = this.user.roles.find(item => item.application === this.applications.sv.name && item.projectKey === this.organizationalUnitCode);

    this.currentRole = currentRole && this.roles.find(role => role.id === currentRole.role);
    this.permissionsWithRoles = detailedPermissions
      .map((dp) => {
        dp.roles = [];
        rolesWithPermissions.forEach(role => {
          if (role.permissions.includes(dp.name)) {
            dp.roles.push(role.role);
          }
        });

        return dp;
      });

    const currentRolePermissions: Permission[] = currentRole && this.permissionsWithRoles
      .filter(pwr => pwr.roles.includes(currentRole.role))
      .map(pwr => ({ id: pwr.name, value: pwr.description }));

    const normalizedCurrentUserPermissions: string[] = currentUserPermissions.map(item => item.permission);
    this.currentPermissions = normalizedCurrentUserPermissions
      .map(item => this.getPermissionDetails(item, detailedPermissions))
      .filter((item: Permission) => {
        return !currentRolePermissions || !currentRolePermissions.map(crp => crp.id).includes(item.id);
      });

    this.initialPermissions = this.getPermissions(this.permissionsWithRoles);
    this.setRoleAndPermissions();
    this.loading = false;
  }

  handleRoleSelect() {
    this.currentPermissions = [];
    this.setRoleAndPermissions();
  }

  handleItemSelect(items: Permission[]) {
    this.currentPermissions = items;
  }

  async onSave() {
    const permissions = this.currentPermissions && this.currentPermissions.map(item => item.id);
    const currentRoleId: string = <string>(this.currentRole && this.currentRole.id);
    const payload: PermissionPayload = {
      application: this.applications.sv.name,
      projectKey: this.organizationalUnitCode,
      permissions: permissions || [],
    };

    if (currentRoleId) {
      payload.projectRole = currentRoleId;
    }

    await this.modulesService.saveUserPermissions(this.applications.sv.name, this.organizationalUnitCode, this.userId, payload);
    this.dialogRef.close();
  }

  getPermissionDetails(permission: string, detailedPermissions: { id: number, name: string; description: string }[]): Permission {
    const detail = detailedPermissions.find(dp => dp.name === permission);
    return {
      id: detail.name,
      value: detail.description,
    };
  }

  private getPermissions(source: PermissionWithRoles[]): Permission[] {
    return source.map(item => ({ id: item.name, value: item.description }));
  }

  private getCurrentRolePermissions(): Permission[] {
    const rolePermissions = this.permissionsWithRoles.filter(pwr => pwr.roles.includes(this.currentRole && this.currentRole.id)) || [];
    return rolePermissions.map(rp => ({ id: rp.name, value: rp.description }));
  }

  private setRoleAndPermissions() {
    const currentPermissions = this.getCurrentRolePermissions().concat(this.currentPermissions);
    this.permissions = _.differenceBy(this.initialPermissions, currentPermissions, 'id');
    this.currentRolePermissions = _.sortBy(this.getCurrentRolePermissions(), 'value');
  }
}
