<h1>Založení nové osoby</h1>

<div>
  <div *ngIf="data" class="cb">
    <fieldset>
      <legend>Typ osoby</legend>
      <div class="column-100">
        <div class="input">
          <gmt-switch [options]="[{ id: 11, value:'Právnická osoba' }, { id: 4, value:'Organizační složka státu' }, { id: 15, value:'Příspěvková organizace' }, { id: 2, value:'Fyzická osoba' }]" (selectedIdChange)="data.charosKod = $event"></gmt-switch>
        </div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.charosKod > 2">
      <legend>Základní údaje</legend>
      <div class="column-100">
        <label class="required">Název</label>
        <div class="input">
          <input type="text" [(ngModel)]="data.nazev" class="button-padding"/>
        </div>
      </div>

      <div class="column-50">
        <label class="required">IČO</label>
        <div class="input"><input type="text" [(ngModel)]="data.ico"/></div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.charosKod === 2">
      <legend>Základní údaje</legend>
      <div class="column-50">
        <label class="required">Jméno</label>
        <div class="input"><input type="text" [(ngModel)]="data.jmeno"/></div>

        <label>Titul před jménem</label>
        <div class="input"><input type="text" [(ngModel)]="data.titulPredJmenem"/></div>

        <label class="required">Příjmení</label>
        <div class="input"><input type="text" [(ngModel)]="data.prijmeni"/></div>

        <label>Titul za jménem</label>
        <div class="input"><input type="text" [(ngModel)]="data.titulZaJmenem"/></div>
      </div>
      <div class="column-50">
        <label>R.Č.</label>
        <div class="input"><input type="text" [(ngModel)]="data.rodneCislo"/></div>
      </div>
    </fieldset>

    <fieldset *ngIf="data.charosKod">
      <legend>Adresa</legend>
      <div class="column-100">
        <label>Ulice</label>
        <div class="input"><input type="text" [(ngModel)]="data.ulice"/></div>
      </div>
      <div class="column-50">
        <label>Číslo popisné</label>
        <div class="input"><input type="text" [(ngModel)]="data.cisloDomovni"/></div>
      </div>
      <div class="column-50">
        <label>Číslo orientační</label>
        <div class="input"><input type="text" [(ngModel)]="data.cisloOrientacni"/></div>
      </div>
      <div class="column-50">
        <label class="required">Obec</label>
        <div class="input"><input type="text" [(ngModel)]="data.obec"/></div>
      </div>
      <div class="column-50">
        <label class="required">PSČ</label>
        <div class="input"><input [(ngModel)]="data.psc" /></div>
      </div>
      <div class="column-50">
        <label>Část obce</label>
        <div class="input"><input type="text" [(ngModel)]="data.castObce"/></div>
      </div>
      <div class="column-50">
        <label>Městská část</label>
        <div class="input"><input type="text" [(ngModel)]="data.mestskaCast"/></div>
      </div>
    </fieldset>
  </div>
</div>

<div class="actions">
  <gmt-button [clickAction]="create" [gmtDisabled]="sending">Uložit</gmt-button>
</div>
