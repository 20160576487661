import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { ParcelModel } from "../../models/parcel.model";
import { OccupationModel } from "@app/ps/models/occupation.model";
import { TooltipParcelComponent } from "../tooltip-parcel/tooltip-parcel.component";
import { CellAbstractComponent, CellTooltipMappingType } from "../cell-abstract/cell-abstract.component";
import { TooltipDimapParcelComponent } from '@app/common/components/tooltip-dimap-parcel/tooltip-dimap-parcel.component';

@Component({
  selector: 'cell-parcel',
  templateUrl: './cell-parcel.component.html',
  styleUrls: ['./cell-parcel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellParcelComponent extends CellAbstractComponent {

  // bindings
  @Input() parcel: ParcelModel;
  @Input() occupation: OccupationModel;
  @Input() updateOccupationParcelPath: string;
  @Input() showTitle: boolean = false;
  @Input() showProtection: boolean = false;
  @Input() cellClass='flex-vertical-centered p-10';

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'parcel': TooltipParcelComponent,
    'dimap-parcel': TooltipDimapParcelComponent,
  };
}
