<div class="jumbotron centered-text" *ngIf="!saving && !casesProcessed">
	<ul>
		<li>Na <b>{{data.details.titles.length}}</b>
			<ng-container *ngIf="data.details.titles.length !== 1"> listech</ng-container>
			<ng-container *ngIf="data.details.titles.length === 1"> listu</ng-container> vlastnictví

			<ng-container *ngIf="cases.length === 1"> bude založen </ng-container>
			<ng-container *ngIf="cases.length >= 2 && cases.length <= 4"> budou založeny </ng-container>
			<ng-container *ngIf="cases.length >= 5"> bude založeno </ng-container>
			<b>{{cases.length}}</b>
			<ng-container *ngIf="cases.length === 1"> případ</ng-container>
			<ng-container *ngIf="cases.length >= 2 && cases.length <= 4"> případy</ng-container>
			<ng-container *ngIf="cases.length >= 5"> případů</ng-container> typu <b style="text-transform:lowercase;">{{data.details.obligation.name}}</b>
			<ng-container *ngIf="data.details.series">
				v číselné řadě s počátečním číslem <b>{{data.details.series | seriesName}}</b></ng-container>.</li>
		<li>
			<ng-container *ngIf="cases.length === 1">Případ bude</ng-container>
			<ng-container *ngIf="cases.length !== 1">Případy budou</ng-container>
			zahrnovat
			<span class="comma-list">
				<span *ngFor="let objectType of data.details.objectTypes" style="text-transform:lowercase;"><b>{{objectType.name}}</b></span>
			</span><ng-container *ngIf="data.details.constructionObjects !== null">
        se vztahem na stavební
				<ng-container *ngIf="data.details.constructionObjects !== 1">objekty </ng-container>
				<ng-container *ngIf="data.details.constructionObjects === 1">objekt </ng-container>
				<span class="comma-list"><span *ngFor="let constructionObject of data.details.constructionObjects"><b>{{constructionObject | constructionObjectName}}</b></span></span>
			</ng-container>.</li>
		<li *ngIf="data.details.createGroups.length > 1">Pro každou kombinaci
			<span class="comma-list"><span *ngIf="hasCreateGroup('owner')"><b>vlastníka</b></span>
			<span *ngIf="hasCreateGroup('title')"><b>listu vlastnictví</b></span>
			<span *ngIf="hasCreateGroup('constructionObject')"><b>stavebního objektu</b></span></span>
			bude uzavřen separátní případ.</li>
		<li *ngIf="data.details.createGroups.length === 1 && hasCreateGroup('owner')">Pro každého <b>vlastníka</b> bude založen separátní případ.</li>
		<li *ngIf="data.details.createGroups.length === 1 && hasCreateGroup('title')">Pro každý <b>list vlastnictví</b> bude založen separátní případ.</li>
		<li *ngIf="data.details.createGroups.length === 1 && hasCreateGroup('constructionObject')">Pro každý <b>stavební objekt</b> bude založen separátní případ.</li>
		<li *ngIf="data.details.createGroups.length === 0">Bude založen jeden souhrnný případ.</li>
	</ul>
</div>
<div class="jumbotron centered-text" *ngIf="saving">
	<b>{{casesProcessed}}/{{cases.length}}</b>
	<div [loading]="true"></div>
</div>
