<div [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <ng-template #processTemplate>
    <div>
      <ul>
        <li *ngFor="let process of processes.processes" class="process">
          {{process.status === 'W' ? 'Čekající' : (process.status === 'R' ? 'Probíhající' : (process.status === 'F' ? 'Dokončeno' : 'Neúspěšně ukončeno'))}} "{{process.name}}"<br/>
          <span *ngIf="process.created">Zadáno {{process.created|date:'shortTime'}}<br/></span>
          <span *ngIf="process.started">Spuštěno: {{process.started|date:'shortTime'}}<br/></span>
          <span *ngIf="process.finished">Dokončeno: {{process.finished|date:'shortTime'}}<br/></span>
          <a class="fa fa-download p-5" *ngIf="process.status === 'F' && process.hasData" (click)="projectStatusService.download([process.id])"></a>
          <a class="fa fa-times p-5" *ngIf="process.status !== 'R'" (click)="projectStatusService.remove(process)"></a>
        </li>
      </ul>
      <div *ngIf="processes.processes.length === 0">Žádné aktivní nebo dokončené procesy</div>
    </div>
  </ng-template>

  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }" *ngIf="APP_BRAND.VISIBILITY['common.navbar.small']">
    <div class="logo">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault()" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i [ngClass]="['fa', 'fa-bars']"></i>
      </a>
      <div class="image-container" style="width: 100px;">
        <img src="./img/logo/{{APP_BRAND.PS.ICON_PATH}}_xxl.svg" alt="logo"/>
      </div>
    </div>

    <div class="fl">
      <changelog></changelog>
    </div>

    <div>
      <login [user]="user" [servicedeskUrl]="servicedeskUrl" (logoutClick)="logout()"></login>
    </div>

    <gmt-project-select [projects]="projects" [selectedProject]="selProject" (selected)="changeProject($event)"></gmt-project-select>

    <div class="right small-spinner count-with-warning" [loading]="countWithWarningLoading">
      <div *ngIf="!countWithWarningLoading" [tooltip]="(countWithWarning ? countWithWarning : 'Žádná') + ' upozornění s výstrahou'">
        <i class="fa fa-bell"></i>
        <span class="number-plate" [ngClass]="{green: countWithWarning == 0, blue: countWithWarning > 0}">{{countWithWarning|number}}</span>
      </div>
    </div>

    <div class="right small-spinner count-with-warning" [loading]="!processes || processes.procesCount === undefined">
      <div *ngIf="processes !== undefined" [tooltip]="processTemplate" [activeContent]="true">
        <i class="fa fa-cog" [ngClass]="{ 'fa-spin': processes.running, 'blink-me': processes.running }"></i>
        <span class="number-plate blue">{{ processes.procesCount|number }}</span>
      </div>
    </div>
  </div>

  <div id="top-bar" class="big-bar" [ngClass]="{ test: ENV !== 'production' }" *ngIf="APP_BRAND.VISIBILITY['common.navbar.big']">
    <div class="top-bar-container left">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault()" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <div class="top-bar-container left logo">
      <a uiSref="landing.home"><img src="./img/logo/{{APP_BRAND.PS.ICON_PATH}}_top.svg" alt="logo" /></a>
    </div>

    <div class="left top-bar-container top-bar-container-padding" *ngIf="ENV !== 'production'" style="color: red">
      TEST
    </div>

    <div class="top-bar-container top-bar-container-padding left">
      <div class="header">
        <div class="header-title">Detail akce</div>
        <div>
          <a uiSref="landing.home">Úvodní stránka</a>
          >
          <a uiSref="landing.projects">Seznam akcí</a>
          >
          <span>Detail akce</span>
        </div>
      </div>
    </div>

    <div class="top-bar-container top-bar-container-padding left top-bar-container__main">
      <div class="">
        <b class="pr-10">{{ selProject.isprofond ? ('(' + selProject.isprofond + ') ' + selProject.name) : selProject.name }}</b>
        <span *ngIf="selProject.manager">Manažer akce {{ formatName(selProject.manager) }}</span>
      </div>
    </div>

    <div *ngIf="APP_BRAND.NAME === 'RSD'" class="top-bar-container top-bar-container-padding right pt-10">
      <img src="./img/logo/rsd_main.svg" alt="logo" height="60"/>
    </div>

    <div *ngIf="APP_BRAND.NAME === 'SZ'" class="top-bar-container top-bar-container-padding right pt-10">
      <img src="./img/logo/sz.png" alt="logo" height="60"/>
    </div>

    <div class="top-bar-container top-bar-container-padding right pt-20" style="padding-left: 0">
      <login [user]="user" [withCompany]="false" [servicedeskUrl]="servicedeskUrl" (logoutClick)="logout()"></login>
    </div>

    <div class="count-with-warning right" [loading]="countWithWarningLoading">
      <div *ngIf="!countWithWarningLoading" [tooltip]="(countWithWarning ? countWithWarning : 'Žádná') + ' upozornění s výstrahou'">
        <i class="fa fa-bell"></i>
        <span class="number-plate" [ngClass]="{green: countWithWarning == 0, blue: countWithWarning > 0}">{{countWithWarning|number}}</span>
      </div>
    </div>

    <div class="count-with-warning right" [loading]="!processes || processes.procesCount === undefined">
      <div *ngIf="processes !== undefined" [tooltip]="processTemplate" [activeContent]="true">
        <i class="fa fa-cog" [ngClass]="{'fa-spin': processes.running, 'blink-me': processes.running}"></i>
        <span class="number-plate blue">{{processes.procesCount|number}}</span>
      </div>
    </div>
  </div>

  <div class="main">
    <app-menu id="menu" [menuConfig]="menuConfig" [collapsed]="collapsed" [ngClass]="{collapsed: collapsed}" class="flexbox-container"></app-menu>
    <div id="content-wrap">
      <div ui-view="content" id="content"> </div>
    </div>
  </div>
</ng-container>
