import { Inject, Injectable } from '@angular/core';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APPLICATIONS } from '@app/common/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class NewMapConfigService {
  private layersConfigs: any;

  constructor(
    @Inject(APPLICATIONS) private APPLICATIONS,
    @Inject(RESTANGULAR_SETTINGS) private restangularUsers
  ) {
    this.layersConfigs = {};
  }

  setModuleLayers(module, projectKey, layers) {
    this.layersConfigs[module] = this.layersConfigs[module] || {};
    this.layersConfigs[module][projectKey] = layers;
    return this.layersConfigs[module][projectKey];
  }

  getLayerById(layerId, layerGroups) {
    const lookIn = (layers) => {
      let layer = layers.find((l) => l.id === layerId);
      if (layer) {
        return layer;
      }
      for (const layer2 of layers) {
        if (layer2.layers) {
          layer = lookIn(layer2.layers);
          if (layer) {
            return layer;
          }
        }
      }
      return null;
    };

    for (const group of layerGroups) {
       const layer = lookIn(group.layers);
       if (layer) {
         return layer;
       }
    }
    return null;
  }

  loadModuleLayers(module, projectKey) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        return this.restangularUsers.one(`application/${this.APPLICATIONS.sy.name}/project/${projectKey}/map`).get().toPromise().then((data) => {
          const result = {
            type: 'node',
            selector: 'hidden',
            layers: data
          };
          this.setModuleLayers(module, projectKey, result);
          return Promise.resolve(result);
        });
      case this.APPLICATIONS.di.name:
        // TODO unimplemented
        return Promise.resolve(null);
      default:
    }
  }

  getLayersConfig(module, projectKey, fresh = false) {
    const loadedAlready = this.layersConfigs[module] && this.layersConfigs[module][projectKey];

    if (fresh || !loadedAlready) {
      return this.loadModuleLayers(module, projectKey).then((response) => {
        return response;
      });
    } else {
      return Promise.resolve(this.layersConfigs[module][projectKey]);
    }
  }
}

