<div class="cmLayerSelector">
  <ng-template #layerTreeRef let-layer>
    <span *ngIf="layer.selector==='switch' && layer.selected != false">
      <div *ngIf="layer.type !== 'node' || !getCollapsedState()" class="switch"
           (click)="switchLayer(layer, layer.type === 'node')" [ngClass]="{'layer-visible': layer.visible}"
           (mouseenter)="layerMouseEnter($event, layer)" (mouseleave)="layerMouseLeave($event)">
          <span class="selector" *ngIf="layer.icon || layer.style">
              <span *ngIf="layer.icon && layer.visible" class="icon">
                  <img [src]="layer.icon"/>
              </span>

              <span *ngIf="layer.style && layer.visible" class="icon">
                <layer-symbol [interactive]="false" [style]="layer.style"></layer-symbol>
              </span>
              <span class="fa" [ngClass]="{'fa-plus': !layer.visible}"></span>
          </span>

          <span class="selector" *ngIf="!layer.icon && !layer.style && layer.visible !== undefined"
                (click)="switchLayer(layer);$event.stopPropagation();">
            <gmt-checkbox gmtType="tristate" [gmtChecked]="layer.visible"></gmt-checkbox>
          </span>

          <span class="title">
              {{layer.title}}
          </span>
      </div>
      <div *ngIf="!layer.collapsed && layer.layers" [ngClass]="{'pl-20': !getCollapsedState()}">
          <ng-template
            ngFor
            [ngForOf]="layer.layers.slice().reverse()"
            [ngForTemplate]="layerTreeRef"></ng-template>
      </div>
   </span>

    <span *ngIf="layer.selector==='hidden'">
        <ul *ngIf="layer.layers">
          <li *ngFor="let layer of getSelectorLayers(layer)">
            <ng-template
              [ngTemplateOutlet]="layerTreeRef"
              [ngTemplateOutletContext]="{ $implicit: layer }">
            </ng-template>
          </li>
        </ul>
    </span>

    <span *ngIf="layer.selector==='group' && layer.selected != false">
        <div class="group" [ngClass]="{'group-collapsed': layer.collapsed || !layer.hasVisibleItems}">
            <div class="group-title" (click)="switchGroup(layer)">
                {{layer.title}} <span *ngIf="showCollapsedVisibleLayersCount() && layer.collapsed"
                                      title="Počet zobrazených vrstev" style="cursor: help;">({{ getDisplayedLayersCount(layer) }}/{{ getTotalLayersCount(layer) }})</span>
            </div>

            <ul *ngIf="layer.layers && !layer.collapsed">
                <li *ngFor="let layer of layer.layers.slice().reverse()">
                  <ng-template
                    [ngTemplateOutlet]="layerTreeRef"
                    [ngTemplateOutletContext]="{ $implicit: layer }">
                  </ng-template>
                </li>
            </ul>
        </div>
    </span>
  </ng-template>

  <ng-template
    [ngTemplateOutlet]="layerTreeRef"
    [ngTemplateOutletContext]="{ $implicit: config }">
  </ng-template>
</div>
