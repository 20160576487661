<div>
  <span class="state-label pr-10">Stav:</span>
  <a class="button active" (click)="opened = !opened">{{ case.caseStatus.name }}&nbsp;&nbsp;<i class="fa fa-chevron-circle-down"></i></a>

  <div *ngIf="opened" class="content-container">
    <div class="bordered">
      <a *ngFor="let actionButton of actionButtons" class="bordered-bottom p-10" (click)="actionButton.click ? actionButton.click() : undefined" uiSref="{{ actionButton.click ? '' : ('symap.project.cases.detail.' + actionButton.key) }}">
        <i class="fa {{ actionButton.icon }}"></i> {{ actionButton.name }}
      </a>

      <div *ngIf="!actionButtons || actionButtons.length === 0" class="text-center p-10">
        Stav nelze změnit
      </div>

      <div *ngIf="previousState">
        <a (click)="onBack()" class="p-10"><i class="fa fa-step-backward"></i> Vrátit do stavu "{{ previousState }}"</a>
      </div>
    </div>
  </div>
</div>
