import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { ProjectModel } from '@app/vfze/models/project-model';

@Pipe({ name: 'projectName' })
@Injectable({
  providedIn: 'root'
})
export class ProjectNamePipe implements PipeTransform {

  transform(project: ProjectModel) {
    return project.projectKey;
  }
}
