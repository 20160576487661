import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ChecklistModel } from '@app/common/models/checklist.model';

export interface MirroredListItem {
  id: string|number;
  value: string|number;
}

@Component({
  selector: 'mirrored-list',
  templateUrl: './mirrored-list.component.html',
  styleUrls: ['./mirrored-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MirroredListComponent implements OnInit {
  @Input() availableItems: MirroredListItem[];
  @Input() selectedItems: MirroredListItem[];
  @Input() label: string;
  @Output() changed = new EventEmitter<MirroredListItem[]>();

  availableChecklist: any;
  selectedChecklist: any;

  constructor(
  ) {
    this.availableChecklist = new ChecklistModel(this.availableItems);
    this.selectedChecklist = new ChecklistModel(this.selectedItems);
  }

  ngOnInit() {
    this.availableItems = this.sortByValue(this.availableItems);
    this.selectedItems = this.sortByValue(this.selectedItems);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    this.availableChecklist.checkedItems.forEach((item) => {
      this.selectedItems.push(item);
      this.availableItems.splice(this.availableItems.indexOf(item), 1);
    });

    this.selectedChecklist.checkedItems.forEach((item) => {
      this.availableItems.push(item);
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    });

    this.availableItems = this.sortByValue(this.availableItems);
    this.selectedItems = this.sortByValue(this.selectedItems);

    this.availableChecklist.checkedItems = [];
    this.selectedChecklist.checkedItems = [];

    this.changed.emit(this.selectedItems);
  }

  private sortByValue(items: MirroredListItem[]): MirroredListItem[] {
    return _.sortBy(items, ['value']);
  }
}
