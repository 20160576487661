<fieldset class="no-legend">
  <div class="column-100">
    <label class="required">Datum odeslání</label>
    <div class="input"><input type="text" pick-a-date [(ngModel)]="data.sentOwnerDate" [maxToday]="true"/></div>
  </div>
</fieldset>

<fieldset>
  <legend>Případy s více možnými adresáty</legend>
  <div class="column-100" [loading]="true" *ngIf="firstCaseId === undefined && caseIndex!==cases.length"></div>
  <div *ngIf="firstCaseId !== undefined && caseIndex < cases.length && caseIndex >= 0">
    <div class="column-100">
      <label>Číslo případu</label>
      <div class="info">
        <a uiSref="symap.project.cases.detail" [uiParams]="{id: cases[caseIndex].id}">{{cases[caseIndex].mpp.number || 'bez čísla'}}</a>
      </div>
    </div>
    <div class="column-100" *ngIf="cases[caseIndex].recipients">
      <label [ngClass]="{required: cases[caseIndex].recipients.length > 1}">Adresát</label>
      <div class="input" *ngIf="cases[caseIndex].recipients.length > 1">
        <gmt-select [data]="cases[caseIndex]" field="recipient" [required]="true" [optionItems]="cases[caseIndex].recipients" [reloadOptionsRegister]="reloadOptionsRegister"></gmt-select>
      </div>
      <div class="info" *ngIf="cases[caseIndex].recipients.length === 1">{{cases[caseIndex].recipient.name}}</div>
    </div>
  </div>
  <div *ngIf="caseIndex===cases.length" class="empty-info">
    Všechny zvolené případy mají zadaného adresáta. Pokračujte tlačítkem "Další" níže.
  </div>
  <div class="column-100 mt-20" *ngIf="firstCaseId !== undefined">
    <gmt-button [gmtDisabled]="firstCaseId === undefined || caseIndex===firstCaseId" [clickAction]="previous">Předchozí</gmt-button>
    <gmt-button [gmtDisabled]="!cases[caseIndex].recipient" *ngIf="caseIndex<cases.length" [clickAction]="next">Následující</gmt-button>
  </div>
</fieldset>
