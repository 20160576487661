import {Ng2StateDeclaration, StateParams} from '@uirouter/angular';


import { resolveId } from '@app/common/utils/state.utils';
import { MapPrintComponent } from '@app/ps/map/components/map-print/map-print.component';
import { MapComponent } from '@app/ps/map/components/map/map.component';

export const mapState: Ng2StateDeclaration = {
  name: 'symap.project.map',
  data: {
    title: 'Mapa',
  },
  url: '/map?title&parcel&constructionObject&occupation&subject&businessCase&layer&tool',
  params: {
    title: {type: 'int'},
    parcel: {type: 'int'},
    constructionObject: {type: 'int'},
    occupation: {type: 'int'},
    subject: {type: 'int'},
    businessCase: {type: 'int' },
    layer: {type: 'string', array: true},
    tool: {type: 'string'},
  },
  views: {
    'content@symap.project': {
      component: MapComponent,
    }
  }
};

export function resolveType(stateParams: StateParams): number {
  return stateParams.type;
}

export const mapPrintState: Ng2StateDeclaration = {
  name: 'symap.project.mapPrint',
  data: {
    title: 'Nastavení tisku',
  },
  url: '/map-print?id',
  params: {
    id: { type: 'int' },
    type: { type: 'string' },
  },
  resolve: {
    id: [ '$stateParams', resolveId ],
    type: [ '$stateParams', resolveType ],
  },
  views: {
    'content@symap.project': {
      component: MapPrintComponent,
    },
  },
};
