import { Component, OnInit, Inject } from '@angular/core';
import { ProjectNamePipe } from '@app/vfze/pipes/project-name.pipe';
import { ProjectModel } from '@app/vfze/models/project-model';
import { saveAs } from 'file-saver';
//import { Restangular } from 'ngx-restangular';
import { APP_CONFIG } from '@app/common/services/config.service';
import { HttpService } from '@app/common/services/http.service';
import {HttpResponse} from "@angular/common/http";
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './generate.component.html',
  styleUrls: []
})
export class GenerateComponent implements OnInit {

  project: ProjectModel;

  oldVersion: boolean;
  public restangularService;
  private reloadOptions: Function;
  public version: any;

  constructor(
    private restangular: Restangular,
    private httpService: HttpService,
    public projectNamePipe: ProjectNamePipe,
    @Inject(APP_CONFIG) public config: any,
  ) {
    this.onGenerate = this.onGenerate.bind(this);
    this.setReloadOptionRegister = this.setReloadOptionRegister.bind(this);
  }

  ngOnInit() {
    this.restangularService = this.restangular.withConfig(() => {});
    this.setOldVersion(false);
  }

  setOldVersion(value) {
    this.oldVersion = value;
    this.restangularService.setBaseUrl(value ? this.config.BACKEND_OPTIONS.restUrlVFZEOld : this.config.BACKEND_OPTIONS.restUrlVFZE);
    if (this.reloadOptions) {
      this.reloadOptions();
    }
    this.version = null;
    this.restangularService.one(`version`).get().toPromise()
    .then(data => {
      this.version = data.plain();
    });
  }

  onGenerate() {
    /*return this.restangular.one(`generate/${this.project.projectKey}`)
      .withHttpConfig({ responseType: 'blob' })
      .get()
      .toPromise()
      .then(response => {
        saveAs(response, `${this.project ? this.project.projectKey : 'generated'}.vfze`, true);
      });*/
    return this.httpService.call({
          path: `generate/${this.project.projectKey}`,
          method: 'GET',
          fullResponse: true,
          responseType: 'blob',
          baseUrl: this.oldVersion ? this.config.BACKEND_OPTIONS.restUrlVFZEOld : this.config.BACKEND_OPTIONS.restUrlVFZE,
        }).then((response: HttpResponse<any>) => {
          saveAs(response.body, `${this.project ? this.project.projectKey : 'generated'}.vfze`, true);
        });
  }


  setReloadOptionRegister(reloadFn: Function) {
    this.reloadOptions = reloadFn;
  }
}
