import { Component, Inject, Input, OnInit } from '@angular/core';
import { ListModel } from '@app/common/models/list.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail',
  templateUrl: './title-detail.component.html',
})
export class TitleDetailComponent implements OnInit {

  @Input() titleId: number;
  loading = true;
  data;
  list: ListModel<any>;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
  ) {
  }

  ngOnInit(): void {
    this.restangular.one('titles', this.titleId).customPOST({
      attributes: {
        area: {},
        ownerships: {
          mapping: {
            attributes: {
              subject: {
                mapping: {
                  attributes: {
                    subjectSjm1: {},
                    subjectSjm2: {}
                  }
                }
              }
            }
          }
        },
        parcels: { }
      }
    }).toPromise().then((data) => {
      this.data = data;
      this.loading = false;
    });
  }

  getSubject(ownership) {
    return ownership.subject.opsubType === 'BSM' ? ownership.subject.subjectSjm1 : ownership.subject;
  }
}
