import { Injectable } from '@angular/core';
import {BrandTranslatePipe} from "@app/common/pipes/brand-translate.pipe";

@Injectable({
  providedIn: 'root'
})
export class MapModuleService {
  private modules: any[];
  private activeModule: any;

  constructor(
    private brandTranslatePipe: BrandTranslatePipe
  ) {
    this.modules = [
      {
        id: 'move',
        icon: 'fa fa-arrows',
        title: 'Pohyb v mapě',
        showInfobox: false,
      },
      {
        id: 'measure',
        icon: 'fa fa-arrows-h',
        title: 'Měření v mapě',
      },
      {
        id: 'intersection',
        icon: 'fa fa-crop',
        title: 'Výběr parcel',
      },
      {
        id: 'info',
        icon: 'fa fa-hand-pointer-o',
        title: 'Informace kliknutím do mapy',
      },
      {
        id: 'cutParcels',
        icon: 'fa fa-cut',
        title: 'Dělení parcel',
      },
      {
        id: 'cuzk',
        icon: 'cuzk',
        title: 'Informace o parcele v katastru nemovitostí',
      },
      {
        id: 'print-case',
        icon: 'fa fa-print',
        title: 'Generovaní mapové přílohy smlouvy',
      },
      {
        id: 'print',
        icon: 'fa fa-image',
        title: 'Tisk z mapového okna',
      },
      {
        id: 'print-project',
        icon: 'fa fa-table',
        title: 'Tisk přehledu ' + this.brandTranslatePipe.transform('ps.projectGenitive').toLowerCase(),
      },
    ];

    this.activeModule = null;
  }

  getModules() {
    return this.modules;
  }

  getModuleById(id) {
    return this.modules.find(m => m.id === id);
  }

  setActiveModuleId(moduleId) {
    this.activeModule = moduleId;
  }

  getActiveModuleId() {
    return this.activeModule;
  }
}
