import { Component, OnInit, Inject } from '@angular/core';
import {StateService, TargetState, Transition} from '@uirouter/angular';

import { AuthService } from '@app/common/services/auth.service';
import { MenuConfig } from '@app/models/menuConfig';
import { StatusService } from '@app/pk/services/status.service';
import { User } from '@app/models/user';
import { ApproveTermsService } from '@app/common/services/approve-terms.service';
import { ChangePasswordComponent } from '@app/common/components/change-password/change-password.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ModulesService } from '@app/common/services/modules.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {
    loading = true;
    collapsed = false;
    showRunningCadastreImportNotification = false;
    user: User;

    servicedeskUrl: string = undefined;
    menuConfig: MenuConfig[] = [];
    ENV = this.config.ENV;

    constructor(
      private authService: AuthService,
      private statusService: StatusService,
      private approveTermsService: ApproveTermsService,
      private dialogService: DialogService,
      private googleAnalyticsService: GoogleAnalyticsService,
      @Inject('$transition$') private transition: Transition,
      @Inject(APPLICATIONS) private applications: any,
      private localStorageService: LocalStorageService,
      @Inject(APP_CONFIG) private config,
      private stateService: StateService,
    ) {
      this.checkPasswordChange = this.checkPasswordChange.bind(this);
    }

    async ngOnInit() {
      await this.authService.checkProjectStatus(this.transition, this.applications.pk.name, null, this.authService.loadPromapProjects, 'error')
        .then(
          () => {
            this.loading = false;
          }, (newState: TargetState) => {
            this.stateService.go(newState.name(), newState.params(), newState.options());
          }
        );

      this.authService.setActiveApplication(this.applications.pk.name);
      this.authService.updateBaseUrl();
      this.authService.updateAuthorizationHeader();
      this.googleAnalyticsService.onUserCome();
      updateBody('pk');

      this.collapsed = this.localStorageService.get('menuOpened');
      this.user = this.authService.getUser();
      this.servicedeskUrl = this.authService.getServiceDeskUrl();
      this.menuConfig = this.getMenuConfig();
      this.approveTermsService.checkApprovedTerms(this.user.id, this.applications.pk.name).then(this.checkPasswordChange);
      this.showRunningCadastreImportNotification = await this.isCadastreUpdateRunning();
    }

  checkPasswordChange() {
    if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
      this.dialogService.open(ChangePasswordComponent, undefined);
    }
  }

    onCollapse() {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    }

    logout() {
        this.authService.logout();
    }

    private getMenuConfig(): MenuConfig[] {
        const menu = [
            new MenuConfig('Projekty', 'pk.projects', 'fa-folder-open'),
            new MenuConfig('Nastavení', 'pk.settings', 'fa fa-wrench'),
        ];

        if (this.user.superuser) {
          menu.push(new MenuConfig('Projekty a žádosti', 'pk.projectsGlobal', 'fa-list-alt'));
        }

        return menu;
    }

  private async isCadastreUpdateRunning(): Promise<boolean> {
    const state = await this.statusService.getStatus();
    return state.runningUpdate;
  }
}
