<div>
    <p visibleByBrand="maja">
        Právě pracujete ve verzi {{ applicationName }}:<br/>
        client: {{ data.frontendVersion }}
        <ng-container *ngIf="data.backendVersion">
            <br/>server: {{ data.backendVersion }}
        </ng-container>
    </p>
    <p *ngIf="data.cadastre">
        Data KN:<br/>
        platnost: {{ (data.cadastre && data.cadastre.platnostKnDat) | date }}<br/>
        aktualizováno: {{ (data.cadastre && data.cadastre.knDataLastUpdateTime) | date: 'medium' }}
    </p>
</div>
