<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <h1>{{ type === 'normal' ? 'Generování dokumentů' : 'Generování dokumentu finanční kontroly' }}</h1>

  <gmt-generate-documents-form [caseId]="caseId" [templatesGetter]="type === 'normal' ? normalTemplateGetter : financialTemplateGetter" [generateSetter]="generateSetter"></gmt-generate-documents-form>

  <div>
    <gmt-button [clickAction]="submit">Generovat</gmt-button>
  </div>
  <help [helpId]="helpIds.CASES_DETAIL_GENERATE_DOCUMENTS" class="cm-help-page"></help>
</div>
