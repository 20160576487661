<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset *ngIf="step === 'accepted'">
      <div class="column-100">
        <label>Datum příklepu</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.auctionWinDate" [maxToday]="true"/></div>

        <label>Datum nabytí právní moci rozhodnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.auctionWinLegalEffectsDate"/></div>

        <label>Celková cena podání</label>
        <div class="input"><input type="text" [(ngModel)]="data.auctionWinAmount" gmtInputNumber/></div>
      </div>
    </fieldset>

    <div class="mt-30">
      <gmt-button class="red" [clickAction]="onDecline" *ngIf="step === 1">Nevydraženo</gmt-button>
      <gmt-button (click)="setStep('accepted')" *ngIf="step === 1">{{ name }}</gmt-button>
      <gmt-button class="black" (click)="setStep(1)" *ngIf="step === 'accepted'">Zpět</gmt-button>
      <gmt-button [clickAction]="onConfirm" *ngIf="step === 'accepted'">Uložit</gmt-button>
    </div>
  </div>
</div>
