﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { StateService, TransitionService } from "@uirouter/angular";
import { ListService } from '@app/common/services/list.service';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe';
import { SettingsService } from '@app/ps/services/settings.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail-create-case-select-obligation',
	templateUrl: './create-case-select-obligation.component.html',
})
export class CreateCaseSelectObligationComponent implements OnInit {
  @Input()
  private generalDataPromise: Promise<any>;
  @Input()
  private titleId: number;
  @Input()
  private data: any;
  @Output()
  private submitCallback = new EventEmitter();
  public loading: boolean = true;
  public obligations: any[];
  acceleratedPriceTypes: {id: string, name: string}[];

  constructor(
    private listService: ListService,
    private wordTranslatePipe: WordTranslatePipe,
    private settingsService: SettingsService,
    private restangular: Restangular,
  ) {
    this.submit = this.submit.bind(this);
  }

  async ngOnInit() {
    await this.settingsService.getSettingsLoader();
    this.generalDataPromise.then((dataLoaded) => {
       this.obligations = dataLoaded.dataObligationsFiltered;
       this.loading = false;
    });

    this.setupAcceleratedPriceOptions();
  }

  toggleAcceleratedPriceType(acceleratedPriceType) {
    this.data.acceleratedPriceType = acceleratedPriceType.id;
  }

  isCheckedAcceleratedPriceType(acceleratedPriceType): boolean {
    return this.data.acceleratedPriceType === acceleratedPriceType.id;
  }


  submit() {
    return this.submitCallback.emit();
  }

  onSelectObligation(obligation) {
    this.data.obligation = obligation;
    this.data.acceleratedPriceType = undefined;
    this.setupAcceleratedPriceOptions();
  }

  private setupAcceleratedPriceOptions() {
    this.acceleratedPriceTypes = this.data.obligation && this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated' ? [
      {id: 'SINGLE', name: 'Jednotná cena za parcelu (parcely)'},
      {id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu'},
      {id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu věcných břemen na parcele (parcelách)'},
      {id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV')},
    ] : [
      {id: 'SINGLE', name: 'Jednotná cena za parcelu (parcely)'},
      {id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu věcných břemen na parcele (parcelách)'},
    ];
  }
}
