<div *ngIf="project.state === 'STARTED'">
  <label>Úplnost dat trvalého záboru</label>
  <div class="input flexbox-container">
    <gmt-switch
      [(selectedId)]="project.readinessPermanentOccupations"
      [options]="readinessStateOptions"
      (selectedIdChange)="refreshReadiness('readinessPermanentOccupations')"
    ></gmt-switch>

    <span style="padding-left: 10px;" class="error-message" *ngIf="totalState.readinessPermanentOccupations.ok === false">
      Toto nastavení nesouhlasí s aktuálním stavem
    </span>
  </div>


  <label>Úplnost dat dočasného záboru do 1 roku</label>
  <div class="input flexbox-container">
    <gmt-switch
      [(selectedId)]="project.readinessTemporaryUnderOccupations"
      [options]="readinessStateOptions"
      (selectedIdChange)="refreshReadiness('readinessTemporaryUnderOccupations')"
    ></gmt-switch>

    <span style="padding-left: 10px;" class="error-message" *ngIf="totalState.readinessTemporaryUnderOccupations.ok === false">
      Toto nastavení nesouhlasí s aktuálním stavem
    </span>
  </div>

  <label>Úplnost dat dočasného záboru nad 1 rok</label>
  <div class="input flexbox-container">
    <gmt-switch
      [(selectedId)]="project.readinessTemporaryOverOccupations"
      [options]="readinessStateOptions"
      (selectedIdChange)="refreshReadiness('readinessTemporaryOverOccupations')"
    ></gmt-switch>

    <span style="padding-left: 10px;" class="error-message" *ngIf="totalState.readinessTemporaryOverOccupations.ok === false">
      Toto nastavení nesouhlasí s aktuálním stavem
    </span>
  </div>

  <label>Úplnost dat věcných břemen</label>
  <div class="input flexbox-container">
    <gmt-switch
      [(selectedId)]="project.readinessTemporaryEasements"
      [options]="readinessStateOptions"
      (selectedIdChange)="refreshReadiness('readinessTemporaryEasements')"
    ></gmt-switch>

    <span style="padding-left: 10px;" class="error-message" *ngIf="totalState.readinessTemporaryEasements.ok === false">
      Toto nastavení nesouhlasí s aktuálním stavem
    </span>
  </div>
</div>

