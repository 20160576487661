import { EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';

import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { TableUtils } from '@app/common/utils/table.utils';
import { ParcelUtils } from '@app/common/utils/parcel.utils';
import { OccupationModel } from '../../models/occupation.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { SortModel } from '@app/common/models/sort.model';

export interface ParcelOccupationGroupType {
  occupations: OccupationModel[];
  parcel: ParcelModel;
}

export abstract class TableParcelsAbstractComponent<T> {

  // bindings
  @Input() data: T[] = [];

  @Input() set columns(columns: ColumnDefinition[]) {
    this._columns = TableUtils.getAvailableColumns(columns, this.COMPONENT_COLUMNS);
    this.visibleColumns = _.keyBy(this._columns, c => c.id);
    this.sortableColumns = _.keyBy(this._columns.filter(column => column.sortable), c => c.id);

    this.occupationColumnsCount = TableUtils.getColumnsCount(this._columns, 'occupation_');
    this.occupationPriceColumnsCount = TableUtils.getColumnsCount(this._columns, 'occupation_price_');
    this.currentColumnsCount = TableUtils.getColumnsCount(this._columns, 'current_');
  }

  @Input() isChecked: Function;
  @Input() checkedAll: boolean;
  @Input() priceEditFunction: Function;

  @Output() toggleAll = new EventEmitter();
  @Output() toggleOne = new EventEmitter<T>();

  // props
  protected abstract readonly COMPONENT_COLUMNS: string[];

  // model
  _columns: ColumnDefinition[];
  easementType: boolean;
  sortOrder: SortModel = new SortModel();
  visibleColumns: any;
  sortableColumns: any;
  occupationColumnsCount: number;
  occupationPriceColumnsCount: number;
  currentColumnsCount: number;

  setSortData(sortBy) {
    this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
    // every first sort will be sorted 'desc'
    if (this.sortOrder.sortBy !== sortBy) {
      this.sortOrder.direction = 'desc';
    }
    this.sortOrder.sortBy = sortBy;
  }

  onSort(sortBy, dataPath) {
    this.setSortData(sortBy);
    this.data = TableUtils.sortDataByColumnStatic<T>(this.data, dataPath, this.sortOrder.direction);
  }

  onSortParcel(sortBy: string, nested = true) {
    this.setSortData(sortBy);
    this.data = _.orderBy(
        this.data,
        ParcelUtils.getSortFunctionByParcelsNumbers(
          nested ? 'parcel.parcisKmen' : 'parcisKmen',
          nested ? 'parcel.parcisPod' : 'parcisPod',
          nested ? 'parcel.parcisDil' : 'parcisDil'
        ),
        this.sortOrder.direction as any
    );
  }

  onToggleAll() {
    this.toggleAll.emit();
  }

  onToggleOne(entity: T) {
    this.toggleOne.emit(entity);
  }
}
