<div [loading]="statisticsList.loading">
  <div *ngIf="!statisticsList.loading">
    <fieldset *ngIf="totalPrecheck === true">
      <h2>Přehled všech případů <i class="fa" [ngClass]="{'fa-arrow-down': !totalSectionCollapsed, 'fa-arrow-left': totalSectionCollapsed}" (click)="onSectionCollapse('totalSectionCollapsed')"></i></h2>

      <div *ngIf="!totalSectionCollapsed" [loading]="totalLoading">
        <div class="empty-info bordered mt-20" *ngIf="!totalLoading && totalAvailable === false">
          Nenalezena žádná data
        </div>

        <perfect-scrollbar *ngIf="!totalLoading && totalAvailable" [wheelPropagation]="true" [horizontal]="true" class="category-select bordered mt-20">
          <div class="flexbox-container" *ngIf="!totalLoading">
            <ng-container *ngFor="let category of totalCategoryTabs; index as i">
              <div *ngIf="i === totalCategoryTabs.length - 1" class="category-separator"></div>
              <div class="flex1 basis0">
                <div class="category">
                  <a class="filter-nav" *ngIf="i === 2 && category.value > 0 && statisticsEntityFilterKeyUnfinished.length" uiSref="symap.project.cases" [uiParams]="{ sef: statisticsEntityFilterKeyUnfinished }" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                  <span class="filter-nav" *ngIf="i === 2 && (category.value === 0 || !statisticsEntityFilterKeyUnfinished.length)"><i class="fa fa-filter"></i></span>
                  <a class="filter-nav" *ngIf="i === 3 && category.value > 0 && statisticsEntityFilterKeyFinished.length" uiSref="symap.project.cases" [uiParams]="{ sef: statisticsEntityFilterKeyFinished }" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                  <span class="filter-nav" *ngIf="i === 3 && (category.value === 0 || !statisticsEntityFilterKeyUnfinished.length)"><i class="fa fa-filter"></i></span>
                  <a class="filter-nav" *ngIf="i === 4 && category.value > 0" uiSref="symap.project.cases" [uiParams]="{ obligationId: expropriationObligationIds, cancelStatus: 'notCancelled', sefReset: 'true' }" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                  <span class="filter-nav" *ngIf="i === 4 && category.value === 0"><i class="fa fa-filter"></i></span>
                  <div class="category-nav">
                    <div class="count">
                      <span *ngIf="category">{{ category.value }}</span>
                      <ng-container *ngIf="category?.total !== undefined">
                        <span class="count-separator">/</span>
                        <span class="count-total" visibleByBrand="mpv">{{ category.total }}</span>
                        <span class="count-total" visibleByBrand="maja">{{ category.value | percentage:category.total }}</span>
                      </ng-container>
                    </div>
                    <div class="label">{{ category.name }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </perfect-scrollbar>
      </div>
    </fieldset>

    <fieldset>
      <h2>Přehled případů dle typů <i class="fa" [ngClass]="{'fa-arrow-down': !casesSectionCollapsed, 'fa-arrow-left': casesSectionCollapsed}"  (click)="onSectionCollapse('casesSectionCollapsed')"></i></h2>

      <ng-container *ngIf="!casesSectionCollapsed">
        <ng-container *ngIf="statisticsList.list.length > 0">
          <div class="mb-20 mt-20">
            <gmt-input label="Typ přehledu">
              <gmt-switch [options]="statisticsOptions" (select)="fetchStatusData($event.id)" [selected]="selectedStatisticsOption" [selectedId]="statisticsId"></gmt-switch>
            </gmt-input>
            <div style="padding-left: 200px; padding-top: 10px;">
              <gmt-select
                class="mr-10"
                [filter]="{sortOrder: {sortBy: 'name'}}"
                resource="areas"
                [data]="this"
                field="area"
                (changed)="onCadastreChanged()"
                selectTitle="Katastrální území"
              ></gmt-select>
              <gmt-select
                class="mr-10"
                [globalFilterConfig]="constructionObjectFilter"
                [filter]="{filters: {validity: 'valid'}, sortOrder: {sortBy: 'socis'}}"
                resource="construction-objects"
                [itemPipe]="constructionObjectNamePipe"
                [data]="this"
                field="constructionObject"
                (changed)="fetchStatusData(statisticsId)"
                selectTitle="Stavební objekt"
              ></gmt-select>
            </div>
          </div>
        </ng-container>

        <div class="tabbable-line tabs-below mt-20">
          <div class="empty-info bordered" *ngIf="statisticsList.list.length === 0">
            Nenalezena žádná data
          </div>

          <ng-container *ngIf="statisticsList.list.length > 0">
            <div class="tab-content bordered">
              <perfect-scrollbar [wheelPropagation]="true" [horizontal]="true" class="category-select">
                <div [loading]="!categoryTabs || statusDataList.loading ||expropriation === undefined">
                  <div class="flexbox-container" *ngIf="categoryTabs && statusDataList && !statusDataList.loading && expropriation !== undefined">
                    <ng-container *ngFor="let category of categoryTabs; index as i">
                      <div *ngIf="!category.splitTab" class="flex1 basis0">
                        <div class="category">
                          <a class="filter-nav" *ngIf="currentData && currentData['cases'].data[i].url && currentData['cases'].data[i].value" href="{{ currentData['cases'].data[i].url }}" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                          <span class="filter-nav" *ngIf="currentData && currentData['cases'].data[i].url && !currentData['cases'].data[i].value" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></span>
                          <div class="category-nav">
                            <div class="count">
                              <span *ngIf="currentData">{{ currentData['cases'].data[i].value }}</span>
                              <ng-container *ngIf="currentData['cases'].data[i].total !== undefined">
                                <span class="count-separator">/</span>
                                <span class="count-total" visibleByBrand="mpv">{{ currentData['cases'].data[i].total }}</span>
                                <span class="count-total" visibleByBrand="maja">{{ currentData['cases'].data[i].value | percentage:currentData['cases'].data[i].total }}</span>
                              </ng-container>
                            </div>
                            <div class="label">{{ i === 0 ? getTotalCategoryName('cases') : category.name }}</div>
                          </div>
                          <div
                            class="status tooltip-min"
                            [ngClass]="{ 'status--onhold': i === 1, 'status--progress': i > 1 && i < categoryTabs.length - 1, 'status--done': i === categoryTabs.length -1 }"
                            title="{{ i === 1 ? 'nezahájeno' : (i > 1 && i < categoryTabs.length - 1 ? 'v řešení' : (i === categoryTabs.length -1 ? 'vypořádáno' : undefined)) }}"
                          ></div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="expropriation !== -1">
                      <div class="category-separator"></div>
                      <div class="flex1 basis0">
                        <div class="category">
                          <a class="filter-nav" *ngIf="expropriation > 0" uiSref="symap.project.cases" [uiParams]="{ sef: [statisticsId + '_0___'], obligationId: expropriationObligationIds }" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                          <span class="filter-nav" *ngIf="expropriation === 0"><i class="fa fa-filter"></i></span>
                          <div class="category-nav">
                            <div class="count">
                              <span *ngIf="currentData">{{ expropriation }}</span>
                            </div>
                            <div class="label">Vyvlastnění</div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </ng-container>
        </div>

        <h2 class="mt-40">Přehled dle dalších parametrů</h2>

        <div class="mt-20">
          <div class="empty-info bordered" *ngIf="statisticsList.list.length === 0">
            Nenalezena žádná data
          </div>

          <ng-container *ngIf="statisticsList.list.length > 0">
            <div class="tabbable-line tabs-below mt-10">
              <ul class="nav nav-tabs">
                <ng-container *ngFor="let tab of entityTabs">
                  <li class="nav-item" *ngIf="tab.id !== 'cases'" [ngClass]="{ active: entityTab === tab.id }" title="{{ tab.name }}">
                    <a class="nav-link" [ngClass]="{ active: entityTab === tab.id }" (click)="changeEntity(tab.id)">{{ tab.name }}</a>
                  </li>
                </ng-container>
              </ul>

              <div class="tab-content">
                <perfect-scrollbar [wheelPropagation]="true" [horizontal]="true" class="category-select">
                  <div [loading]="!categoryTabs || statusDataList?.loading">
                    <div class="flexbox-container" *ngIf="categoryTabs && statusDataList && !statusDataList.loading">
                      <ng-container *ngFor="let category of categoryTabs; index as i">
                        <div *ngIf="!category.splitTab || entityTab !== 'cases'" class="flex1 basis0">
                          <div class="category">
                            <a class="filter-nav" *ngIf="currentData && currentData[entityTab].data[i].url && currentData[entityTab].data[i].value" href="{{ currentData[entityTab].data[i].url }}" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></a>
                            <span class="filter-nav" *ngIf="currentData && currentData[entityTab].data[i].url && !currentData[entityTab].data[i].value" (click)="$event.stopPropagation();"><i class="fa fa-filter"></i></span>
                            <div class="category-nav">
                              <div class="count">
                                <span *ngIf="currentData">{{ currentData[entityTab].data[i].value }}</span>
                              </div>
                              <div class="label">{{ i === 0 ? getTotalCategoryName(entityTab) : (category.nameAlternative ? category.nameAlternative : category.name) }}</div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </fieldset>
  </div>
</div>
