import { Component } from '@angular/core';

import { TableParcelsAbstractComponent } from '../table-parcels-abstract/table-parcels-abstract.component';
import { ParcelModel } from '@app/common/models/parcel.model';

@Component({
  selector: 'table-parcels-small',
  templateUrl: './table-parcels-small.component.html',
  styleUrls: ['./table-parcels-small.component.scss']
})
export class TableParcelsSmallComponent extends TableParcelsAbstractComponent<ParcelModel> {

  protected readonly COMPONENT_COLUMNS = [
    'parcel', 'parcel_cell', 'title', 'area_name', 'area', 'parcel_price'
  ];
}
