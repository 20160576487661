import {Component, Inject, Input, OnInit} from "@angular/core";
import * as _ from "lodash";
import {Transition} from '@uirouter/core';

import {TitleModel} from "../../../common/models/title.model";
import {ListModel} from "../../../common/models/list.model";
import {OwnershipModel} from "../../../common/models/ownership.model";

export interface TitlesShortListOptions {
  showOwnerships: boolean;
}

@Component({
  selector: 'titles-short-list',
  templateUrl: './titles-short-list.component.html',
  styleUrls: ['./titles-short-list.component.scss']
})
export class TitlesShortListComponent implements OnInit {

  // bindings
  @Input() ownershipList: ListModel<OwnershipModel>;
  @Input() options: TitlesShortListOptions = {showOwnerships: false};
  groupedTitleList: TitleModel[][] = [];
  ownerId: number;

  constructor(
    @Inject('$transition$') transition: Transition,
  ) {
    this.ownerId = transition.params().ownerId;
  }

  ngOnInit() {
    this.ownershipList.promise.then(() => {
      this.groupedTitleList = this.refreshTitleGroups();
    });
  }

  /**
   * Returns titles grouped by cadastral area
   */
  private refreshTitleGroups(): TitleModel[][] {
    if (this.ownershipList.loading || !this.ownershipList.list) {
      return [];
    }

    let aux = [...this.ownershipList.list];
    let titleGroups: TitleModel[][] = [];

    aux = _.sortBy(aux, (ownership: OwnershipModel) => ownership.title.area.name);
    aux.forEach((ownership: OwnershipModel) => {
      const groupIndex = _.findIndex(titleGroups, (ta) => ta[0].area.id === ownership.title.area.id);
      if (groupIndex === -1) {
        titleGroups.push([ownership.title]);
        ownership.title.ownerships.push(ownership);
      } else {
        let titleIndex = _.findIndex(titleGroups[groupIndex], (t) => t.id === ownership.title.id);
        if (titleIndex === -1) {
          titleIndex = titleGroups[groupIndex].length;
          titleGroups[groupIndex].push(ownership.title);
        }
        titleGroups[groupIndex][titleIndex].ownerships.push(ownership);
      }
    });
    return titleGroups;
  }
}
