<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col-6">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Přehled požadavků</a>
      </div>
      <div class="col-6 text-right">

      </div>
    </div>

    <h1>Požadavek {{ ticket.number }}</h1>

    <div class="case-status">
      <span>
        Typ požadavku:
        <b *ngIf="ticket.actualizedSample">Aktualizace <a uiSref="sv.samples.detail" [uiParams]="{ id: ticket.actualizedSample.id }">{{ ticket.actualizedSample.number }}</a></b>
        <b *ngIf="!ticket.actualizedSample">Nový vzor</b>
      </span>
      <span class="state-label pr-10">Stav:
        <action-buttons name="{{ ticket.status | ticketStatusName }}" align="left">
          <a *ngFor="let nextStatus of nextStatuses" class="bordered-bottom p-10" (click)="onEdit(nextStatus)">
            <i class="fa fa-plus-square mr-5" [ngClass]="{'fa-plus-square': nextStatus !== ticketStatusEnum.DELETED, 'fa-remove' : nextStatus === ticketStatusEnum.DELETED }"></i>
            {{ ticketService.getStatusEnumAction(nextStatus) }}
          </a>
          <a *ngIf="nextStatuses.length === 0" class="bordered-bottom p-10">Nelze změnit stav</a>
        </action-buttons>
      </span>
    </div>

    <span *ngIf="ticket.assignable === false" class="tag tag--danger tag--small is-uppercase">není učen pro {{ APP_BRAND.PS.TITLE }}</span>

    <div class="mt-30 mb-70 flexbox-container">
      <div class="column-50">
        <div visibleByBrand="sv.unit">
          <label>{{ 'sv.section' | brandTranslate }}:</label>
          <div class="info">
            {{ ticket.section }}
          </div>
        </div>
        <label>{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}:</label>
        <div class="info">
          {{ ticket.organizationalUnitName }}
        </div>
        <label>Typ dokumentu:</label>
        <div class="info">
          {{ ticket.documentType | documentTypeFullname }}
        </div>
        <label>Důvod požadavku:</label>
        <div class="info">
          {{ ticket.description }}
        </div>
      </div>

      <div class="column-50">
        <label>Zadavatel:</label>
        <div class="info">
          {{ ticket.userFullName }}
        </div>
        <label>Vytvořený vzor:</label>
        <div class="info">
          <a *ngIf="ticket.producedSample" uiSref="sv.samples.detail" [uiParams]="{ id: ticket.producedSample.id }">{{ ticket.producedSample.number }}</a>
        </div>
      </div>
    </div>

    <div class="mt-80">
      <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">

        <div *ngIf="tab === 'notes'">
          <gmt-notes
            [noteList]="noteList"
            [editable]="canEditNote()"
            [allowType]="true"
            [defaultData]="{ ticket: { id: ticket.id }, systemType: false }"
            [noteTypeResolver]="noteTypeResolver"
            (updated)="onUpdateNoteList();loadHistory();"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'history'">
          <gmt-notes
            [type]="'history'"
            [noteList]="historyList"
            [editable]="false"
            [allowType]="true"
            [noteTypeResolver]="noteTypeResolver"
          ></gmt-notes>
        </div>

        <div *ngIf="tab === 'documents'">
          <div class="bordered" *ngIf="documentList.itemCount">
            <table>
              <thead>
              <tr>
                <th>
                  <sort-by
                    [selected]="'name' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('name', documentList.filter.sortOrder.direction)"
                  >Název</sort-by>
                </th>
                <th>
                  <sort-by
                    [selected]="'createdUser.name' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('createdUser.name', documentList.filter.sortOrder.direction)"
                  >Nahrál</sort-by>
                </th>
                <th>
                  <sort-by
                    [selected]="'timestamp' === documentList.filter.sortOrder.sortBy"
                    [sortDir]="documentList.filter.sortOrder.direction"
                    (sort)="onDocumentsSort('timestamp', documentList.filter.sortOrder.direction)"
                  >Datum nahrání</sort-by>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let document of documentList.list">
                <td class="attachment-item">
                  <div>
                    <div>
                      <a href="{{ restBaseUrl }}/attachments/{{ document.id }}/data?t={{ authToken }}">
                        <i class="mr-10 fa fa-lg flex-icon {{ document | documentType : 'icon' }}"></i>
                      </a>
                    </div>
                    <div>
                      <a href="{{ restBaseUrl }}/attachments/{{document.id}}/data?t={{ authToken }}">
                        {{ document.baseName + (document.extension ? '.' + document.extension : '') }}
                      </a>
                      <p>{{document | documentType : 'title'}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  {{ document.userFullName }}
                </td>
                <td>
                  {{ document.timestamp | date : 'short' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="bordered" *ngIf="!documentList.itemCount">
            <div class="empty-info">
              <span>Žádné dokumenty</span>
            </div>
          </div>
          <div *ngIf="ticket.status === ticketStatusEnum.SENT_TO_CONFIRMATION" gmtAuthProject actions="template_aproval" [project]="ticket.organizationalUnitCode" visibleByBrand="maja" class="mt-20">
            <fileupload
              (change)="onFilesChange($event)"
              [message]="filesMsg"
              inputId="fileHidden"
            ></fileupload>
          </div>
        </div>
      </gmt-navbar>
    </div>
  </div>
</div>
