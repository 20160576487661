<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Změna přihlašovacích údajů</h1>
            </div>
        </div>

        <div class="row mt-20">
            <div class="col-12 offset-lg-3 col-lg-6">
                <div class="centered-text">
                    Zde je možné provést změnu hesla nebo přihlašovacího jména. Pro provedení změny vždy zadejte
                    vaše aktuální heslo.
                </div>
                <div>
                    <fieldset class="mt-20">
                        <div class="column-100">
                            <label class="required">Aktuální heslo</label>
                            <div class="input">
                                <input type="password" [(ngModel)]="oldPassword"/>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Změna hesla</legend>
                        <div class="column-100">
                            <label>Nové heslo</label>
                            <div class="input">
                                <input type="password" [(ngModel)]="newPassword"/>
                            </div>
                        </div>
                        <div class="column-100">
                            <label ng-class="{required: newPassword.length > 0}">Potvrzení nového hesla</label>
                            <div class="input">
                                <input type="password" [(ngModel)]="newPasswordCheck"/>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Změna uživatelského jména</legend>
                        <div class="column-100">
                            <label>Stávající uživatelské jméno</label>
                            <div class="info">
                                {{username}}
                            </div>
                            <label>Nové uživatelské jméno</label>
                            <div class="input">
                                <input type="text" [(ngModel)]="username"/>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="text-center">
                    <a class="button" *ngIf="backSref" uiSref="{{backSref}}">Ukončit</a>
                    <gmt-button [clickAction]="update" [gmtDisabled]="!isUpdateDisabled()">Uložit</gmt-button>
                </div>
            </div>
        </div>
    </div>
    <help [helpId]="helpIds.SETTINGS_CHANGE_PASSWORD" class="cm-help-page"></help>
</div>
