﻿export class MenuConfig {
  constructor(
    public title: string,
    public uiSref: string,
    public icon: string,
    public uiParams?: any,
    public tooltip?: string,
    public href?: string,
    public target?: string,
    public permissions?: string
  ) {}
}
