<div>
  <p class="centered-text">
    Odesláním žádosti o data bude vygenerováno jeden nebo více XML dokumentů, které je třeba poslat na ČÚZK. V dokumentech budou obsaženy ty požadavky, jejichž datum platnosti není vyšší než zvolená platnost dat.
  </p>
  <form>
    <fieldset class="mt-20">
      <div class="column-100">
        <gmt-input label="Platnost dat" required="true">
          <month-picker [options]="monthPickerOptions" (date)="onRequestDateChange($event)"></month-picker>
        </gmt-input>
      </div>
    </fieldset>

    <div class="flexbox-container">
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!requestDate">Odeslat</gmt-button>
      <div class="flex1">
        <gmt-input>
          <gmt-checkbox [gmtChecked]="lastRequest" (click)="lastRequest = !lastRequest"></gmt-checkbox>
          Žádost označit jako <strong>poslední žádost</strong> na zvolený měsíc. Dále nebude možné podat další žádosti pro tento měsíc.
        </gmt-input>
      </div>

      <div class="flex-rest">
        <gmt-button [clickAction]="onExistingRequestsDownload" class="fr">Stáhnout existující žádosti z tohoto měsíce</gmt-button>
      </div>
    </div>
  </form>

  <div *ngIf="unprocessedRequests?.length" class="mt-40 mb-20">
    <h2>Existují žádosti, které nebyly odeslané na ČUZK</h2>
    <table class="table bordered">
      <thead>
        <tr>
          <th>Název žádosti</th>
          <th>ID žádosti</th>
          <th>Datum založení</th>
          <th>Datum platnosti</th>
          <th>Stav</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let request of unprocessedRequests">
          <td>{{ request.name }}</td>
          <td>{{ request.requestId }}</td>
          <td>{{ request.recivedTime | date }}</td>
          <td>{{ request.datumPlatnosti | date }}</td>
          <td>{{ request.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
