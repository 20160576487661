import { Component, OnInit, Inject } from '@angular/core';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { User } from '@app/models/user';
import { UserEditComponent } from '@app/common/components/user-edit/user-edit.component';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {
  list: ListModel<User>;
  pageableList: PageableList;

  selectModules = [
    {name: this.applications.sy.title, id: this.applications.sy.name},
    {name: this.applications.di.title, id: this.applications.di.name},
    {name: this.applications.pk.title, id: this.applications.pk.name},
    {name: this.applications.dashboard.title, id: this.applications.dashboard.name},
    {name: this.applications.vfze.title, id: this.applications.vfze.name},
    {name: 'Nic', id: ''},
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APPLICATIONS) private applications: any,
    @Inject(RESTANGULAR_SETTINGS) private settingsRestangular: any,
    private pageableListService: PageableListService,
    private dialogService: DialogService,
  ) {
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    this.onUsersSync = this.onUsersSync.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  onUsersSync() {
    return this.settingsRestangular.one(`users/broadcast-users-update`).post().toPromise();
  }

  onCreate() {
    const dialog = this.dialogService.open(UserEditComponent, { data: {} });
    const sub = dialog.afterClosed.subscribe((res: User) => {
      if (res) {
        this.pageableList.fetchResults();
      }
      sub.unsubscribe();
    });
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'users/list',
      this.getDefaultFilter(),
      'settingsUsersFilter',
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: {
          values: [{id: ''}]
        },
        applications: {},
      }
    };
  }
}
