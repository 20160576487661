import { Component, OnInit, Input } from '@angular/core';
import { MapCallbackEnum } from '@app/map/enums/map-callback.enum';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { MapLayerSelectorService } from '@app/map/services/map-layer-selector.service';
import { ListService } from '@app/common/services/list.service';

@Component({
  selector: 'map-search',
  templateUrl: './map-search.component.html',
})
export class MapSearchComponent implements OnInit {
  @Input()
  public mapId;
  @Input()
  public searchResources;

  public search = {
    text: '',
    resource: null,
    list: null,
    templateUrl: null,
    formatItem: null,
    itemFilter: null,
  };

  searchOld;

  constructor(
    private mapLayerSelectorService: MapLayerSelectorService,
    private mapHighlightService: MapHighlightService,
    private listService: ListService,
  ) {}

  async ngOnInit() {
    // Remove on initialization if set from past
    this.mapHighlightService.removeHighlight(this.mapId);
    this.searchOld = Object.assign({}, this.search);
  }

  searchChange(resource = undefined) {
    if (resource !== undefined) {
      this.search.resource = resource;
    }
    if (this.search.resource && this.search.text !== '') {
      let found: any, attributes, filter;
      if (this.search.resource !== this.searchOld.resource || !this.search.list) {

        found = this.getActiveSearchConfig();
        attributes = {geometry: {}};
        filter = found.filter || {};
        if (found.attributes) {
          Object.assign(attributes, found.attributes);
        }

        this.search.formatItem = found.formatItem ||
          (
            found.pipe ?
              found.pipe.transform :
              function(item) {
                return item.name;
              }
          );

        this.search.list = this.listService.createList(this.search.resource, filter, undefined, attributes);
        this.search.itemFilter = found.itemFilter;
      }
      this.search.list.filter.offset = 0;
      this.search.list.filter.filters.searchText = {
        values: [{id:this.search.text}]
      };
      this.listService.fetchResult(this.search.list).then(() => {
        (<any>window).GoogleAnalytics('send', {
          hitType: 'event',
          eventCategory: 'mapModule',
          eventAction: `item searched ${this.getActiveSearchConfig().name}`,
          eventLabel: 'search',
        });
      });
    } else if (this.search.list) {
      this.listService.cancelFetch(this.search.list);
      this.search.list = null;
    }

    this.searchOld = Object.assign({}, this.search);
  }

  getActiveSearchConfig() {
    return this.searchResources.find(r => r.id === this.search.resource);
  }

  loadNext() {
    if (!this.search.list.loading) {
      this.search.list.filter.offset += this.search.list.filter.limit;
      this.listService.fetchResult(this.search.list, true);
    }
  }

  highlightExists() {
    return this.mapHighlightService.highlightExists(this.mapId);
  }

  async removeHighlight() {
    this.mapHighlightService.removeHighlight(this.mapId);
  }

  async highlightFeature(item) {
    this.mapHighlightService.highlightFeature(this.mapId, item.geometry, {}, undefined, item);
  }

  onMouseEnterOption(event, option) {
    if (!option.icon) {
      return;
    }
    event.option = option;
    this.mapLayerSelectorService.fireCallback(MapCallbackEnum.searchMouseEnter, event);
  }

  onMouseLeaveOption(event) {
    this.mapLayerSelectorService.fireCallback(MapCallbackEnum.searchMouseLeave, event);
  }
}
