import { Component, Inject, Input, OnInit } from '@angular/core';

import { TabModel } from '@app/common/models/tab.model';
import { BrandService } from '@app/common/services/brand.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'landing-sy',
  templateUrl: './landing-sy.component.html',
  styleUrls: ['./landing-sy.component.scss'],
})
export class LandingSyComponent implements OnInit {
  @Input() activeTab: string;

  tabs: TabModel[];

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private brandService: BrandService,
  ) {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit() {
    this.isActiveTab = this.isActiveTab.bind(this);
    this.tabs = [
      {
        name: 'KN',
        id: 'cadastre-update',
        href: 'settings.modules.ps',
        urlParams: { tab: 'cadastre-update' },
      }
    ];

    if (this.brandService.isVisible('maja')) {
      this.tabs.push({
        name: 'Podmínky použití',
        id: 'terms-of-use',
        href: 'settings.modules.ps',
        urlParams: { tab: 'terms-of-use' },
      });
      this.tabs.push({
        name: 'Změny v aplikaci',
        id: 'changelog',
        href: 'settings.modules.ps',
        urlParams: { tab: 'changelog' },
      });
    }
  }

  isActiveTab(tabId: string): boolean {
    return tabId === this.activeTab;
  }
}
