import { User } from '@app/models/user';

export class EmailUtils {
  public static readonly USER_LOGIN_REGEXP = /\[login\]/g;

  public static readonly LINE_BREAK_REGEXP = /(?:\r\n|\r|\n)/g;

  // for replace [text|url] => <a href="url">text</a>
  public static readonly ANCHOR_REGEXP = /\[([^\]]+)\|([^\]]+)\]/g;

  static textToHtml(text: string, user?: User): string {
    // user placeholders
    if (user) {
      text = text.replace(EmailUtils.USER_LOGIN_REGEXP, user.username);
    }

    // line breaks to <br>
    text = text.replace(EmailUtils.LINE_BREAK_REGEXP, '<br>');

    // replace link placeholders
    text = text.replace(EmailUtils.ANCHOR_REGEXP, (fullMatch, text, url) => {
      return '<a href="' + url + '" target="_blank">' + text + '</a>';
    });

    return text;
  }
}
