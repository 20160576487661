import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CustomerContract } from '@app/models/customerContract';



@Pipe({ name: 'customerContractName' })
@Injectable({
  providedIn: 'root'
})
export class CustomerContractNamePipe implements PipeTransform {
  transform(contract: CustomerContract) {
    return (contract ? contract.subject : '');
  }
}
