interface Color {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export class VectorLayerStyleModel {
  constructor(public fill: Color, public stroke: Color) { }

  fillToRgbaString(): string {
    return this.toRgbaString(this.fill);
  }

  fillToRgbString(): string {
    return this.toRgbString(this.fill);
  }

  strokeToRgbaString(): string {
    return this.toRgbaString(this.stroke);
  }

  strokeToRgbString(): string {
    return this.toRgbString(this.stroke);
  }

  private toRgbString(obj: Color): string {
    const {r, g, b } = obj;
    return `rgb(${r}, ${g}, ${b})`;
  }

  private toRgbaString(obj: Color): string {
    const {r, g, b, a } = obj;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  static fromRgbStrings(fill: string, stroke: string): VectorLayerStyleModel {
    const [ fillRed, fillGreen, fillBlue ] = getRgbFromString(fill);
    const [ strokeRed, strokeGreen, strokeBlue ] = getRgbFromString(stroke);
    const fillColor: Color = {
      r: fillRed,
      g: fillGreen,
      b: fillBlue,
    };
    const strokeColor: Color = {
      r: strokeRed,
      g: strokeGreen,
      b: strokeBlue,
    };

    return new this(fillColor, strokeColor);
  }
}

function getRgbFromString(rgb: string): number[] {
  return rgb.split(',').map((rgbColor: string) => rgbColor.replace(/\D/g,'')).map((color: string) => parseInt(color));
}

