export const backend = {
  "restUrl": "/backend/rest",
  "restUrlSY": "/backend/sy/rest",
  "restUrlDI": "/backend/di/rest",
  "restUrlPK": "/backend/pro/rest",
  "restUrlPkProxy": "/backend/promap-proxy",
  "restUrlVFZE": "/backend/vfze/vfze/v1",
  "restUrlVFZEOld": "/backend/vfze-old/vfze/v1",
  "restUrlSV": "/backend/templates",
  "restUrlSETTINGS": "/backend/users",
  "restUrlConfig": "/backend/configuration",
  "geoserverUrl": "/geoserver",
  "geoserverDIUrl": "/geoserver"
};
