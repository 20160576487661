import { Injectable } from '@angular/core';
import { ProjectDataModel } from '../models/project-data.model';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { ProjectsColumnDefinitionModel } from '@app/landing/models/projects-column-definition.model';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ProjectTypeNamePipe } from '@app/common/pipes/project-type-name.pipe';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { TemplateOrganizationalUnitNamePipe } from '@app/common/pipes/template-organizational-unit-name.pipe';
import { ProjectStatePipe } from '@app/ps/pipes/project-state.pipe';
import { ReadinessPipe } from '@app/ps/pipes/readiness.pipe';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(
    private projectSubjectPipe: ProjectSubjectNamePipe,
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private templateOrganizationalUnitNamePipe: TemplateOrganizationalUnitNamePipe,
    private userNamePipe: UserNamePipe,
    private authService: AuthService,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private projectTypeNamePipe: ProjectTypeNamePipe,
    private projectStatePipe: ProjectStatePipe,
    private readinessPipe: ReadinessPipe,
  ) {
  }

  public transformForExport(projects: ProjectDataModel[], columnDefinitions: ProjectsColumnDefinitionModel[], selectedColumns: string[]): any[] {
    const columnIds = columnDefinitions.filter(c => selectedColumns.includes(c.id)).map(c => c.id);
    const columnNames = columnDefinitions.filter(c => selectedColumns.includes(c.id)).map(c => c.name);
    const body = [];

    for (const project of projects) {
      const row = [];
      for (const id of columnIds) {
        row.push(this.transformValueForExport(project, id));
      }
      body.push(row);
    }

    return [columnNames].concat(body);
  }

  private transformValueForExport(project: ProjectDataModel, id: string): any {
    if (id === 'donePercentage') {
      return (project[id] !== undefined ? this.numberPipe.transform(project[id], '1.0-0') : undefined);
    }
    if (['lastActivityDate', 'projectStartDate'].includes(id)) {
      return (project[id] !== undefined ? this.datePipe.transform(project[id], 'mediumDate') : undefined);
    }
    return project[id];
  }

  public createFromApi (projects: any[]): ProjectDataModel[] {
    return projects.map(p => {
      return {
        isprofond: p.isprofond,
        type: this.projectTypeNamePipe.transform(p.type),
        customerOrganizationalUnitSection: p.investor && p.investor.customerOrganizationalUnit ? p.investor.customerOrganizationalUnit.section : undefined,
        templatesOrganizationalUnit: p.templatesOrganizationalUnit ? this.templateOrganizationalUnitNamePipe.transform(p.templatesOrganizationalUnit) : undefined,
        name: p.name,
        key: p.key,
        unit: p.unit,
        region: p.region,
        manager: this.userNamePipe.transform(p.manager, false, false),
        investor: this.organizationalUnitNamePipe.transform(p.investor ? p.investor.customerOrganizationalUnit : undefined),
        infrastructure: p.infrastructure,
        lastActivityDate: p.lastActivity ? p.lastActivity.timestamp : undefined,
        lastActivityAuthor: p.lastActivity ? this.userNamePipe.transform(p.lastActivity.user, false) : undefined,
        projectStartDate: p.projectStartDate,
        state: p.state,
        stateName: this.projectStatePipe.transform(p),
        stateEnum: this.projectStatePipe.getEnum(p),
        phase: p.phase,
        propertyActivityProcessorCompany: p.propertyActivityProcessor ? p.propertyActivityProcessor.ssoCompanyName : undefined,
        propertyActivityProcessorName: this.userNamePipe.transform(p.propertyActivityProcessor, false),
        landTakeDocumentationAuthorCompany: p.landTakeDocumentationAuthor ? p.landTakeDocumentationAuthor.companyName : undefined,
        landTakeDocumentationAuthorName: this.projectSubjectPipe.transform(p.landTakeDocumentationAuthor, false, false),
        designerCompany: p.designer ? p.designer.companyName : undefined,
        designerName: this.projectSubjectPipe.transform(p.designer, false, false),
        readinessPermanentOccupations: this.readinessPipe.transform(p.readinessPermanentOccupations),
        readinessTemporaryUnderOccupations: this.readinessPipe.transform(p.readinessTemporaryUnderOccupations),
        readinessTemporaryOverOccupations: this.readinessPipe.transform(p.readinessTemporaryOverOccupations),
        readinessTemporaryEasements: this.readinessPipe.transform(p.readinessTemporaryEasements),
      };
    });
  }

  public static mapDashboardDataOnProject(project: ProjectDataModel, dashboard: any): ProjectDataModel {
    project.activity = dashboard.activity;
    project.total = dashboard.total;
    project.done = dashboard.done;
    project.donePercentage = ProjectsService.getPercentage(dashboard.done, dashboard.total);

    project.tzTotal = dashboard.tzTotal;
    project.tzDone = dashboard.tzDone;
    project.dznrTotal = dashboard.dznrTotal;
    project.dznrDone = dashboard.dznrDone;
    project.dzdrTotal = dashboard.dzdrTotal;
    project.dzdrDone = dashboard.dzdrDone;
    project.vbTotal = dashboard.vbTotal;
    project.vbDone = dashboard.vbDone;
    project.expropriationTotal = dashboard.expropriationTotal;
    project.parcelTotal = dashboard.parcelTotal;
    project.tzDonePercentage = ProjectsService.getPercentage(dashboard.tzDone, dashboard.tzTotal);
    project.dzdrDonePercentage = ProjectsService.getPercentage(dashboard.dzdrDone, dashboard.dzdrTotal);
    project.dznrDonePercentage = ProjectsService.getPercentage(dashboard.dznrDone, dashboard.dznrTotal);
    project.vbDonePercentage = ProjectsService.getPercentage(dashboard.vbDone, dashboard.vbTotal);
    return project;
  }

  private static getPercentage(done: number, total: number): number {
    if (total === undefined || done === undefined) {
      return undefined;
    }
    if (total === 0 || done === 0) {
      return 0;
    }
    return (done / total * 100);
  }
}
