﻿import { Renderer2, Component, TemplateRef, Type, Injector, ViewChild, OnInit, Input, Inject, ElementRef, ComponentFactoryResolver } from "@angular/core";
import { TooltipContentDirective } from '../../directives/tooltip/tooltipContent.directive';
import { TooltipHostDirective } from '../../directives/tooltip/tooltipHost.directive';

@Component({
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
    @ViewChild(TooltipContentDirective, { read: ElementRef }) private tooltipContentContainer;
    @ViewChild(TooltipHostDirective) private tooltipHostDirective;
    @Input() data: any;

	constructor(
	  @Inject('tooltipConfig') private tooltipConfig,
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private renderer: Renderer2,
    ) { }

	ngOnInit() {
	  this.generateContent();
	}

	generateContent() {
      if (typeof this.tooltipConfig.content === 'string') {
        const element = this.renderer.createText(this.tooltipConfig.content);
        this.renderer.appendChild(this.tooltipContentContainer.nativeElement, element);
      } else if (this.tooltipConfig.content instanceof TemplateRef) {
        const context = {};
        const viewRef = this.tooltipHostDirective.viewContainerRef.createEmbeddedView(this.tooltipConfig.content, context);
      } else if (this.tooltipConfig.content instanceof Type) {
        // Else it's a component
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.tooltipConfig.content);
        const componentRef = this.tooltipHostDirective.viewContainerRef.createComponent(factory);
        if (this.tooltipConfig.data) {
          componentRef.instance.data = this.tooltipConfig.data;
        }
       }
    }
}
