import { Injectable } from '@angular/core';

/**
 * Service that produces dimensions of scrollable div (or window object) that is ancestor to selected element
 */
@Injectable({ providedIn: 'root' })
export class WindowDimensionService {

  get(element, paddings: any = 0) {
    if (typeof paddings !== 'object' && typeof paddings !== 'number') {
      paddings = 0;
    }
    paddings = {
      top: paddings === 'object' ? paddings.top : paddings,
      right: paddings === 'object' ? paddings.right : paddings,
      bottom: paddings === 'object' ? paddings.bottom : paddings,
      left: paddings === 'object' ? paddings.left : paddings
    };

    const scrollableContent = $(element).closest('div.scrollable');
    // height
    const scrollableHeightContent = scrollableContent.length ? scrollableContent : $(element).closest('#content-wrap');
    // width
    const scrollableWidthContent = scrollableContent.length ? scrollableContent : $(element).closest('#content');

    let windowHeight = 0;
    let windowWidth = 0;
    let windowOffsetTop = 0;
    let windowOffsetLeft = 0;

    // offset is used for modal window to adjust to its position
    // scroll top is used to adjust position outside modal window
    if (scrollableHeightContent.length && scrollableWidthContent.length) {
      windowHeight = scrollableHeightContent.height() - paddings.top - paddings.bottom;
      windowWidth = scrollableWidthContent.width() - paddings.left - paddings.right;
      windowOffsetTop = scrollableHeightContent.offset().top + ((scrollableHeightContent.innerHeight() - scrollableHeightContent.height()) / 2) + paddings.top;
      windowOffsetLeft = scrollableWidthContent.offset().left + ((scrollableWidthContent.innerWidth() - scrollableWidthContent.width()) / 2) + paddings.left;
    } else {
      const w = $(window);
      windowHeight = w.height() - paddings.top - paddings.bottom;
      windowWidth = w.width() - paddings.left - paddings.right;
      windowOffsetTop = w.scrollTop() + paddings.top;
      windowOffsetLeft = w.scrollLeft() + paddings.left;
    }

    return {
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      windowOffsetTop: windowOffsetTop,
      windowOffsetLeft: windowOffsetLeft
    };
  }

}
