import { Injectable } from '@angular/core';
import { MapFeatureFilterService } from '@app/map/services/map-feature-filter.service';

@Injectable({
  providedIn: 'root'
})
export class DimapFeatureFilterService {
  constructor(
    private mapFeatureFilterService : MapFeatureFilterService,
  ) {}

  // green color
  filterPropertyInfoOwnedInside() {
    return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['O']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted', true),
                        this.mapFeatureFilterService.propertyIsNull('wanted')
                    ])
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key'),
                    this.mapFeatureFilterService.propertyEquals('wanted', true)
                ])
            ])
        ]);
  }


      // blue color
  filterPropertyInfoOwnedOutside() {
    return this.mapFeatureFilterService.statementsAnd([
          this.mapFeatureFilterService.propertyOr('owner_type', ['O', 'S']),
          this.mapFeatureFilterService.statementsOr([
              this.mapFeatureFilterService.statementsAnd([
                  this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                  this.mapFeatureFilterService.propertyEquals('wanted', false)
              ]),
              this.mapFeatureFilterService.statementsAnd([
                  this.mapFeatureFilterService.propertyIsNull('key'),
                  this.mapFeatureFilterService.statementsOr([
                      this.mapFeatureFilterService.propertyEquals('wanted', false),
                      this.mapFeatureFilterService.propertyIsNull('wanted')
                  ])
              ])
          ])
      ]);
  }

      // red color
  filterPropertyInfoOthersInside() {
    return this.mapFeatureFilterService.statementsAnd([
          this.mapFeatureFilterService.propertyOr('owner_type', ['S', 'F']),
          this.mapFeatureFilterService.statementsOr([
              this.mapFeatureFilterService.statementsAnd([
                  this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                  this.mapFeatureFilterService.statementsOr([
                      this.mapFeatureFilterService.propertyEquals('wanted', true),
                      this.mapFeatureFilterService.propertyIsNull('wanted')
                  ])
              ]),
              this.mapFeatureFilterService.statementsAnd([
                  this.mapFeatureFilterService.propertyIsNull('key'),
                  this.mapFeatureFilterService.propertyEquals('wanted', true)
              ])
          ])
      ]);
  }

      // white color
  filterPropertyInfoOthersOutside() {
    return this.mapFeatureFilterService.statementsAnd([
          this.mapFeatureFilterService.propertyEquals('owner_type', 'F'),
          this.mapFeatureFilterService.statementsOr([
              this.mapFeatureFilterService.propertyEquals('wanted', false),
              this.mapFeatureFilterService.statementsAnd([
                  this.mapFeatureFilterService.propertyIsNull('key'),
                  this.mapFeatureFilterService.propertyIsNull('wanted')
              ])
          ])
      ]);
  }

  areaToKeep(areaNum) {
    return this.mapFeatureFilterService.statementsAnd([
        this.mapFeatureFilterService.propertyOr('owner_type', ['O']),
        this.mapFeatureFilterService.statementsOr([
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key'+areaNum)),
                this.mapFeatureFilterService.statementsOr([
                    this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, true),
                    this.mapFeatureFilterService.propertyIsNull('wanted'+areaNum)
                ])
            ]),
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.propertyIsNull('key'+areaNum),
                this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, true)
            ])
        ])
    ]);
  }

  areaToSell(areaNum) {
    return this.mapFeatureFilterService.statementsAnd([
        this.mapFeatureFilterService.propertyOr('owner_type', ['O', 'S']),
        this.mapFeatureFilterService.statementsOr([
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key'+areaNum)),
                this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, false)
            ]),
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.propertyIsNull('key'+areaNum),
                this.mapFeatureFilterService.statementsOr([
                    this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, false),
                    this.mapFeatureFilterService.propertyIsNull('wanted'+areaNum)
                ])
            ])
        ])
    ]);
  }

  areaToBuy(areaNum) {
    return this.mapFeatureFilterService.statementsAnd([
      this.mapFeatureFilterService.propertyOr('owner_type', ['S', 'F']),
      this.mapFeatureFilterService.statementsOr([
          this.mapFeatureFilterService.statementsAnd([
              this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key'+areaNum)),
              this.mapFeatureFilterService.statementsOr([
                  this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, true),
                  this.mapFeatureFilterService.propertyIsNull('wanted'+areaNum)
              ])
          ]),
          this.mapFeatureFilterService.statementsAnd([
              this.mapFeatureFilterService.propertyIsNull('key'+areaNum),
              this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, true)
          ])
      ])
    ]);
  }

  areaOther(areaNum) {
    return this.mapFeatureFilterService.statementsAnd([
        this.mapFeatureFilterService.propertyEquals('owner_type', 'F'),
        this.mapFeatureFilterService.statementsOr([
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key'+areaNum)),
                this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, false)
            ]),
            this.mapFeatureFilterService.statementsAnd([
                this.mapFeatureFilterService.propertyIsNull('key'+areaNum),
                this.mapFeatureFilterService.statementsOr([
                    this.mapFeatureFilterService.propertyEquals('wanted'+areaNum, false),
                    this.mapFeatureFilterService.propertyIsNull('wanted'+areaNum)
                ])
            ])
        ])
    ]);
  }

  filterGeometricPlan() {
    return this.mapFeatureFilterService.statementsOr([
      this.mapFeatureFilterService.statementsAnd([this.areaToKeep(1), this.areaToSell(2)]),
      this.mapFeatureFilterService.statementsAnd([this.areaToBuy(1),  this.areaToSell(2)]),
      this.mapFeatureFilterService.statementsAnd([this.areaToBuy(1),  this.areaOther(2)])
    ]);
  }
}
