import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

import { AuthService } from '@app/common/services/auth.service';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { DialogService } from '@app/common/services/dialog.service';
import { ListModel } from '@app/common/models/list.model';
import { ListService } from '@app/common/services/list.service';
import { NoteFormComponent } from '@app/common/components/note-form/note-form.component';
import { NoteModel } from '@app/common/models/note.model';
import { PerfectScrollbarEvent } from '@app/common/components/perfectscrollbar/perfectscrollbar.component';
import { User } from '@app/models/user';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'gmt-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  // bindings
  @Input() type: 'notes' | 'history' = 'notes';
  @Input() allowType: boolean;
  @Input() defaultData: any = {};
  @Input() noteList: ListModel<NoteModel>;
  @Input() editable: boolean;
  @Input() maxHeight: number;
  @Input() noteTypeResolver: Function;

  @Output() updated = new EventEmitter<boolean>();

  // fields
  private scrollTop: () => void = () => {};

  constructor(
      private restangular: Restangular,
      private authService: AuthService,
      private listService: ListService,
      private dialogService: DialogService
  ) {
    this.onEditNote = this.onEditNote.bind(this);
    this.onCanScrollTop = this.onCanScrollTop.bind(this);
    this.onDeleteNote = this.onDeleteNote.bind(this);
    this.onLoadNextNotes = this.onLoadNextNotes.bind(this);
    this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
    this.checkCancelled = this.checkCancelled.bind(this);
  }

  ngOnInit() {}

  onEditNote(note: NoteModel = null) {
    const dialog = this.dialogService.open(NoteFormComponent, {
      data: {
        allowType: this.allowType,
        note: (note ? {...note} : {...this.defaultData}),
        caseId: this.defaultData && this.defaultData.businessCase ? this.defaultData.businessCase.id : undefined,
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: NoteModel | boolean) => {
      if (result) {
        Object.keys(this.defaultData).forEach(key => {
          delete result[key];
        });
        if (note) {
          const index = _.findIndex(this.noteList.list, note);
          this.noteList.list.splice(index, 1, result as NoteModel);
        } else {
          this.noteList.list.unshift(result as NoteModel);
          this.noteList.itemCount++;
          this.scrollTop();
        }
        this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  onDeleteNote(note: NoteModel) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete poznámku smazat? Její obnovení nebude možné!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.restangular.one('notes', note.id).remove().toPromise().then(() => {
          if (!this.checkCancelled()) {
            this.noteList.list = _.without(this.noteList.list, note);
          } else {
            note.cancelled = true;
          }
          this.noteList.itemCount--;
          this.updated.emit(true);
        });
      }
      sub.unsubscribe();
    });
  }

  onLoadNextNotes() {
    this.noteList.filter.offset += this.noteList.filter.limit;
    this.listService.fetchResult(this.noteList, true);
  }

  onCanScrollTop(scrollbarEvent: PerfectScrollbarEvent) {
    this.scrollTop = scrollbarEvent.scrollTop;
  }

  canModify(permissions: string, user: User): boolean {
    return this.authService.hasPermission(permissions) && this.authService.isAuthorized(user);
  }

  onFilterChanged() {
    return this.listService.fetchResult(this.noteList);
  }

  checkCancelled() {
    return this.noteList.filter.filters.cancelled !== false;
  }

  onCancelledCheckboxClicked() {
    if (this.checkCancelled()) {
      this.noteList.filter.filters.cancelled = false;
    } else {
      delete this.noteList.filter.filters.cancelled;
    }
    this.onFilterChanged();
  }

  resolveNoteType(noteType: any): string {
    if (!noteType) {
      return '';
    }

    if (noteType.name) {
      return noteType.name;
    }

    if (this.noteTypeResolver) {
      return this.noteTypeResolver(noteType);
    }

    return '';
  }
}
