<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col-6">
        <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Detail uživatele</a>
      </div>
    </div>

    <h1>Email uživateli</h1>

    <gmt-email-form [email]="welcomeEmail" label="Uvítací email" type="welcome"></gmt-email-form>

    <div class="centered-text">
      <gmt-button [gmtDisabled]="!isEmailValid(welcomeEmail)" [clickAction]="onSendWelcomeEmail">Odeslat</gmt-button>
    </div>
  </div>
</div>
