<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>Rozhodnutí o odvolání <span *ngIf="step">{{step === 'accepted' ? expropriationAppealAcceptedName : expropriationAppealRejectedName}}</span></h1>
    <fieldset *ngIf="step">
      <div class="column-100">
        <label>Datum rozhodnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.expropriationAppealResultDate" [maxToday]="true"/></div>
      </div>
      <div class="column-100">
        <label>Rozhodl</label>
        <div class="input"><input type="text" [(ngModel)]="data.expropriationAppealResultArbitrator"/></div>
      </div>
      <div class="column-100">
        <label>Odůvodnění</label>
        <textarea [(ngModel)]="data.expropriationAppealResultReason"></textarea>
      </div>
    </fieldset>

    <div class="mt-30">
      <gmt-button class="red" (click)="setStep('rejected')" *ngIf="!step">{{ expropriationAppealRejectedName }}</gmt-button>
      <gmt-button (click)="setStep('accepted')" *ngIf="!step">{{ expropriationAppealAcceptedName }}</gmt-button>
      <gmt-button class="black" (click)="setStep(null)" *ngIf="step">Zpět</gmt-button>
      <gmt-button [clickAction]="step === 'accepted' ? onAccepted : onRejected" *ngIf="step">Uložit</gmt-button>
    </div>
  </div>
</div>
