import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { CommonModule } from '../common/common.module';
import { MainComponent } from '@app/landing/components/main/main.component';
import { HomeComponent } from '@app/landing/components/home/home.component';
import { ProjectListComponent } from '@app/landing/components/project-list/project-list.component';
import { ProjectColumnsEditComponent } from '@app/landing/components/project-columns-edit/project-columns-edit.component';
import { UserListComponent } from '@app/landing/components/user-list/user-list.component';
import { UserDetailComponent } from '@app/landing/components/user-detail/user-detail.component';
import { Ng2StateDeclaration, StateParams, UIRouterModule } from '@uirouter/angular';
import { resolveCallbacks, resolveId } from '@app/common/utils/state.utils';
import { ManagerReportProjectListComponent } from '@app/landing/components/manager-report-project-list/manager-report-project-list.component';
import { ManagerReportProjectDetailComponent } from '@app/landing/components/manager-report-project-detail/manager-report-project-detail.component';
import { AuthService } from '@app/common/services/auth.service';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APPLICATIONS } from '@app/common/services/config.service';

registerLocaleData(localeCs, 'cs');

export const landingState: Ng2StateDeclaration = {
  name: 'landing',
  url: '/landing',
  abstract: true,
  component: MainComponent
};

export const homeState: Ng2StateDeclaration = {
  name: 'landing.home',
  url: '/home',
  data: {
    title: 'Úvodní stránka',
  },
  views: {
    'content@landing': {
      component: HomeComponent,
    }
  },
};

export const projectsState: Ng2StateDeclaration = {
  name: 'landing.projects',
  url: '/projects',
  data: {
    title: 'ps.projectList',
  },
  views: {
    'content@landing': {
      component: ProjectListComponent,
    }
  },
};

export const userListState: Ng2StateDeclaration = {
  name: 'landing.users',
  url: '/users?page',
  data: {
    title: 'Uživatelé',
  },
  views: {
    'content@landing': {
      component: UserListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const userDetailState: Ng2StateDeclaration = {
  name: 'landing.users.detail',
  url: '/:id',
  data: {
    title: 'Detail uživatele',
  },
  views: {
    'content@landing': {
      component: UserDetailComponent,
    }
  },
  resolve: {
    userId: [ '$stateParams', resolveId ],
    callbacks: [ resolveCallbacks ],
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export const managerReportProjectsState: Ng2StateDeclaration = {
  name: 'landing.managerReportProjects',
  url: '/manager-report-projects',
  data: {
    title: 'Manažerský report - Přehled akcí',
  },
  views: {
    'content@landing': {
      component: ManagerReportProjectListComponent,
    }
  },
};

export function resolveProjectKey(stateParams: StateParams): string {
  return stateParams.projectKey;
}

export const managerReportProjectDetailState: Ng2StateDeclaration = {
  name: 'landing.managerReportProjects.detail',
  url: '/manager-report-projects/{projectKey:sy_[^/]*}',
  data: {
    title: 'Manažerský report akce',
  },
  params: {
    projectKey: {
      squash: true
    }
  },
  resolve: {
    projectKey: [ '$stateParams', resolveProjectKey ],
  },
  views: {
    'content@landing': {
      component: ManagerReportProjectDetailComponent,
    }
  },
};


@NgModule({
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [
        landingState,
        homeState,
        projectsState,
        userListState,
        userDetailState,
        managerReportProjectsState,
        managerReportProjectDetailState,
      ]
    }),
  ],
  providers: [
    DatePipe,
    DecimalPipe,
  ],
  declarations: [
    MainComponent,
    HomeComponent,
    ProjectListComponent,
    ProjectColumnsEditComponent,
    UserListComponent,
    UserDetailComponent,
    ManagerReportProjectListComponent,
    ManagerReportProjectDetailComponent,
  ],
  entryComponents: [
    ProjectColumnsEditComponent,
  ]
})
export class LandingAppModule {
}
