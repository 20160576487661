import { Injectable } from '@angular/core';
import { MapService } from '@app/map/services/map.service';

@Injectable({
  providedIn: 'root'
})
export class MapReloadLayersService {

  constructor(
    private mapService : MapService,
  ) { }

  reload(mapId, layersIDs) {
    this.mapService.getMap(mapId).then((map) => {
      map.eachLayer((l) => {
        if(layersIDs.includes(l._id)) {
          l.setParams({random: new Date().getTime()}, false);
        }
      })
    })
  }
}

