import { Component } from "@angular/core";

import { CaseModel } from "../../../ps/models/case.model";
import { TooltipAbstractListComponent } from "../tooltip-abstract-list/tooltip-abstract-list.component";

@Component({
  selector: 'tooltip-cases',
  templateUrl: './tooltip-cases.component.html',
  styleUrls: ['./tooltip-cases.component.scss']
})
export class TooltipCasesComponent extends TooltipAbstractListComponent<CaseModel> {
  protected resource = 'cases';
}
