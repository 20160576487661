﻿import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { ListService } from '../../services/list.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: [ './filter.component.scss' ]
})
export class FilterComponent implements OnInit {

  @Output() onChanged: EventEmitter<any> = new EventEmitter();
  @Input() list: any;
  @Input() showLegend: boolean = false;
  @Input() filterButtonName = 'Filtrovat';

  constructor(private listService: ListService, private stateService: StateService) {
  }

  ngOnInit() {
    this.showLegend = this.showLegend !== false;
  }

  getActualPage = (params) => parseInt(params.page, 10) || 1;

  needsToResetPage = () => this.getActualPage(this.stateService.params) !== 1;

  applyFilter = () => {
    if (this.needsToResetPage()) {
      // we must reset page
      this.stateService.go('.', {
        page: 1
      });
      // TODO: to by se asi melo nastavit automaticky
      this.list.filter.offset = 0;
    }

    // log every filtering with searchText
    if (this.list.filter.filters.searchText && this.list.filter.filters.searchText.values[0].id) {
      (<any>window).GoogleAnalytics('send', {
        hitType: 'event',
        eventCategory: 'filterFulltext',
        eventAction: this.list.path,
        eventLabel: this.stateService.current.name,
      });
    }

    const changingPage = this.needsToResetPage();
    this.onChanged.emit(changingPage);
  };
}
