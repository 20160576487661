﻿import {Component, Inject, Input} from "@angular/core";
import {StateService} from "@uirouter/angular";
import { AuthService } from '@app/common/services/auth.service';
import { APPLICATIONS } from '@app/common/services/config.service';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Input() errorCode: number;
  @Input() redirectUrl: string;
  errors = {
    // user is autenticated and has permissions for requested module, but does not have permissions for requested project -> redirect to login
    1: 'Do požadovaného projektu nemáte přidělena oprávnění. Kontaktujte podporu, nebo přejděte do jiného projektu.', //
    2: 'Do požadovaného modulu nemáte přidělena oprávnění.',
    3: 'Nejprve se přihlašte.',
    4: 'Nemáte přiřazen žádný projekt. Kontaktujte podporu.',
    5: 'Vyskytla se interní chyba při inicializaci modulu.',
  };

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  getProjects() {
    const projects = this.authService.getActualProjects();
    if (!projects || projects.length === 0) {
      this.errorCode = 4;
    }
    return projects;
  }

  getModuleName(module) {
    return (<any>Object.entries(this.applications).find((a: any) => a[1].name === module)[1]).title;
  }

  getModules() {
    return this.authService.getApplications();
  }

  getActiveModuleBaseState() {
    const application = this.authService.getActiveApplication();
    return this.authService.getApplicationBaseState(application);
  }

  getModuleBaseState(module) {
    const params = this.authService.getApplicationTransitionParams(module);
    const opts = <any>{};

    if (params.projectKey) {
      opts.projectKey = params.projectKey;
    }

    return {
      module: params.module,
      opts,
    };
  }

  logout = () => {
    return this.authService.logout();
  }

  goToProjectFunction(projectKey) {
    return this.stateService.go(this.getActiveModuleBaseState(), { projectKey });
  }

  goToModuleFunction(module) {
    return () => {
      const destination = this.getModuleBaseState(module);
      return this.stateService.go(destination.module, destination.opts);
    };
  };

  goToLoginFunction(redirect) {
    return () => {
      if (redirect) {
        return this.stateService.go('login', { redirectUrl: this.redirectUrl });
      } else {
        return this.stateService.go('login');
      }
    }
  }
}
