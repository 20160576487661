import { Component, Input, OnInit } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { Restangular } from 'ngx-restangular';
import {CaseStatusService} from "@app/ps/services/case-status.service";

@Component({
  selector: 'accepted-geometrical-plan-possible',
  templateUrl: './accepted-geometrical-plan-possible.component.html',
})
export class AcceptedGeometricalPlanPossibleComponent implements OnInit {
  @Input() caseId: number;
  public loading = true;
  public data: CaseModel;
  public isValid: Function;
  public recipient: any;
  acceptedGeometricalPlanPossibleName: string;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    this.acceptedGeometricalPlanPossibleName = (await this.caseStatusService.loadStatusName('AcceptedGeometricalPlanPossible')).name;
    this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise().then((data) => {
      this.data = data;
      this.loading = false;

      const dataCaseOwnerships = this.caseService.getDataCaseOwnerships(data.caseOwnerships, data.caseSubjects);
      this.recipient = this.caseService.getRecipients(dataCaseOwnerships).find(
          (value) => {
              return !value.acceptedGeometricalPlanPossibleDate && (value.meetingRequestDate || value.sentDate || value.personalVisitDate || value.receivedDate);
          }
      );
    });
  }

  formCallbackRegister($event) {
    this.isValid = $event.isValid;
  }

  async onSubmit(): Promise<any> {
    const sendData = {
      responseReceivedDate: this.data.responseReceivedDate,
      caseSubjects: this.data.caseSubjects,
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'accepted-geometrical-plan-possible')
      .toPromise();

    this.stateService.go('^');
  }
}

