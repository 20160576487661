<div [loading]="loading">
  <div *ngIf="!loading">
    <div class="column-100">
      <label>Číslo znaleckého posudku</label><div class="info">{{ data.opinion.expertOpinionNumber }}</div>
      <label>Datum vyhotovení</label><div class="info">{{ data.opinion.expertOpinionDate | date }}</div>
      <label>Odhadce</label><div class="info">{{ data.opinion.expert.jmeno }} {{ data.opinion.expert.prijmeni }}</div>
    </div>

    <div class="column-100">
      <fieldset>
        <legend>Celková cena za znalecký posudek</legend>
        <div class="column-100">
          <label>Celková vypočtená cena za ZP</label>
          <div class="info">{{ priceDefault | number | price }}</div>

          <div *ngIf="data.opinion.expertOpinionLand">
            <div class="label">Konečná cena ZP (zaokrouhlení)</div>
            <div class="input" *ngIf="!data.readonly">
              <input
                type="text"
                [(ngModel)]="data.sum.priceDefaultRounded"
                (ngModelChange)="computeDefaultPrice(); computeCoefficientPrice(true); computeRoundedPrice();"
                gmtInputNumber
              />&nbsp;Kč
            </div>
            <div class="info" *ngIf="data.readonly">{{ data.sum.priceDefaultRounded | number | price }}</div>
          </div>
        </div>
      </fieldset>
    </div>

    <div *ngIf="hasCoefficient" class="column-100">
      <fieldset>
        <legend>Celková cena za znalecký posudek včetně koeficientu podle zákona 416/2009 Sb.</legend>
        <div class="column-100">
          <label>Celková vypočtená cena za znalecký posudek včetně koeficientu podle zákona 416/2009 Sb.</label>
<!--          <div class="info">{{ data.sum.priceCoefficient | number | price }}</div>-->
          <div class="info">{{ priceCoefficient | number | price }}</div>

          <div *ngIf="data.opinion.expertOpinionLand">
            <div class="label">Konečná cena (zaokrouhlení)</div>
            <div *ngIf="!data.readonly" class="input">
              <input
                type="text"
                [(ngModel)]="data.sum.priceCoefficientRounded"
                (ngModelChange)="computeDefaultPrice(); computeCoefficientPrice(); computeRoundedPrice();"
                gmtInputNumber
              />&nbsp;Kč
            </div>
            <div class="info" *ngIf="data.readonly">{{ data.sum.priceCoefficientRounded | number | price }}</div>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="mt-30">
      <gmt-button [clickAction]="onBack">Zpět</gmt-button>
      <gmt-button *ngIf="!data.readonly" [clickAction]="onSave" [gmtDisabled]="!isFormValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
