import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TableService } from '@app/ps/services/table.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { ParcelModel } from '@app/common/models/parcel.model';
import { KnGpModel } from '@app/common/models/kn-gp.model';
import { OpinionModel } from '@app/ps/models/opinion.model';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'table-parcels',
  templateUrl: './table-parcels.component.html',
  styleUrls: ['./table-parcels.component.scss']
})
export class TableParcelsComponent implements OnInit {

  @Input() list: any;
  @Input() geometricPlanModuleEnabled = false;
  @Input() opinionModuleEnabled = false;
  @Input() customProjectKey: string;
  @Input() showNavIcons = false;
  @Output() sort = new EventEmitter();

  private readonly TABLE_ID = 'parcels';
  public isArray = Array.isArray;

  constructor(
    private tableService: TableService,
    private settingsService: SettingsService,
    private authService: AuthService,
    public userNamePipe: UserNamePipe,
    private restangular: Restangular,
  ) {}

  ngOnInit() {
  }

  onSort(column: string, ascOrDesc: string) {
    this.sort.emit({
      column,
      ascOrDesc,
    });
  }

  hideColumn(columnId: string): boolean {
    return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
  }

  getColumnName(columnId: string): string {
    return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
  }

  getKnGpsFromParcel(parcel: ParcelModel): KnGpModel[] {
    let gps = [];

    if (parcel.knBudouc) {
      gps.push(parcel.knBudouc.knGp);
    }

    if (parcel.knBudoucPepvs && parcel.knBudoucPepvs.length) {
      gps = gps.concat(parcel.knBudoucPepvs.map(knbp => knbp.knBudouc.knGp));
    }

    return _.uniqBy(gps, (gp) => {
      return gp.id;
    });
  }

  getOpinionsFromParcel(parcel: ParcelModel): OpinionModel[] {
    return parcel.parcelPrices
      .filter(pp => pp.opinion && pp.opinion.valid === true)
      .map(pp => pp.opinion);
  }
}
