﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { SeriesNamePipe } from '@app/ps/pipes/series-name.pipe';
import { ListService } from '@app/common/services/list.service';
import { TitleNamesPipe } from '@app/common/pipes/title-names.pipe';
import * as _ from 'lodash';
import * as _Fraction from 'fraction.js';
import { OtherLegalRelationsService } from '@app/common/services/other-legal-relations.service';
import { CaseService } from '@app/ps/services/case.service';
import { SeriesService } from '@app/ps/services/series.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';
let Fraction : any = _Fraction;

@Component({
  selector: 'construction-object-detail-create-case-price',
	templateUrl: './create-case-price.component.html',
})
export class CreateCasePriceComponent implements OnInit {
  @Input()
  private constructionObjectId: number;
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();
  @Output()
  private backCallback = new EventEmitter();
  public loading: boolean = true;
  public easementPriceByShare: boolean;
  public distributionSystemPartsOptions: any[] = null;
  public seriesData: any[] = [];
  public seriesDataParsed: any[] = [];
  public generatedSeriesNumbers: any[] = [];
  public showEasementDistributionSystemParts: boolean;
  public restrictions: any[] = [];
  public otherEntries: any[] = [];
  private distributionSystemPartOptionsReload: any[];
  public businessCaseIndex: number = 0;
  public flatCaseOwnerships: any[] = [];
  dataOtherLegalRelations: any;

  constructor(
    private authService: AuthService,
    private listService: ListService,
    public seriesNamePipe: SeriesNamePipe,
    private titleNamesPipe: TitleNamesPipe,
    private otherLegalRelationsService: OtherLegalRelationsService,
    private restangular: Restangular,
    private dialogService: DialogService,
    private seriesService: SeriesService,
    private caseService: CaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.submit = this.submit.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  ngOnInit() {
    const project = this.authService.getActualProject();
    this.easementPriceByShare = project.easementPriceByShare;
    this.showEasementDistributionSystemParts = project.easementDistributionSystemParts;
    this.loadInitialData();
  }

  loadInitialData() {
    const promises = [this.computeShares(), this.loadSeries()];

    if (this.showEasementDistributionSystemParts) {
      promises.push(this.loadDistributionSystemParts());
    }

    Promise.all(promises).then(() => {
      this.loading = false;
      this.loadOtherLegalRelations();
    }).catch((r) => {
      this.errorHandlerService.get(r);
      this.backCallback.emit();
    });
  }

  loadOtherLegalRelations() {
    this.restrictions;
    this.otherEntries;
    const titleIds = this.data.cases.flatMap((businessCase) => businessCase.titles.map(title => title.id));
    this.dataOtherLegalRelations = this.otherLegalRelationsService.loadOtherLegalRelations(titleIds);
    return this.dataOtherLegalRelations.promise.then(() => {
      this.data.cases.forEach((businessCase, index) => {
        this.restrictions[index] = [];
        this.otherEntries[index] = [];
        let filteredGroups;
        filteredGroups = this.otherLegalRelationsService.filterByEntityWithParcel(
          this.dataOtherLegalRelations.list,
          businessCase.easements,
          businessCase.titles[0],
          businessCase.caseOwnerships
        );
        this.restrictions[index].push(...filteredGroups.restrictions);
        this.otherEntries[index].push(...filteredGroups.otherEntries);

        filteredGroups = this.otherLegalRelationsService.filterByCaseOwnerships(
          this.dataOtherLegalRelations.list,
          businessCase.caseOwnerships,
          businessCase.titles[0],
        );
        this.restrictions[index].push(...filteredGroups.restrictions);
        this.otherEntries[index].push(...filteredGroups.otherEntries);
      });
    });
  }

  loadSeries() {
    return this.restangular.all('series').customPOST({filter: {filters: {constructionObjectId: [this.constructionObjectId]}}}).toPromise().then((data) => {
      this.seriesData = data.plain();
      this.seriesService.parseSeries(_.cloneDeep(this.seriesData), this.seriesDataParsed);
      if (this.seriesData[0]) {
        const seriesToChoose = this.seriesData[0];
        this.data.cases.forEach((businessCase) => {
          businessCase.series = seriesToChoose;
        });
      }
      this.refreshSeriesNumbers(-1);
    });
  }

  loadDistributionSystemParts() {
    this.distributionSystemPartOptionsReload = [];
    this.distributionSystemPartsOptions = null;
    const list = this.listService.createList('distribution-system-parts', {limit: null});
    this.listService.fetchResult(list).then(() => {
      this.distributionSystemPartsOptions = list.list;
    });
    return list.promise;
  }

  distributionSystemPartAdded(businessCaseIndex) {

    this.distributionSystemPartOptionsReload.forEach((reload, index) => {
      if (index !== businessCaseIndex) {
        reload();
      }
    });
  }

  distributionSystemPartReloadRegister(index, reload) {
    this.distributionSystemPartOptionsReload[index] = reload;
  }

  refreshSeriesNumbers(updateSeriesFrom) {
    this.seriesDataParsed.forEach((seriesParsed) => {
      seriesParsed.series = _.cloneDeep(_.find(this.seriesData, {id: seriesParsed.series.id}));
    });

    this.data.cases.forEach((businessCase, index) => {
      if (index > updateSeriesFrom && (!businessCase.mpp.number || businessCase.series)) {
        businessCase.series = this.seriesData[_.findIndex(this.seriesDataParsed, {series: {available: true}})];
      } else if (businessCase.series && index === updateSeriesFrom) {
        businessCase.mpp.number = null;
      }
      if (businessCase.series) {
        const seriesParsed = _.find(this.seriesDataParsed, {series: {id: businessCase.series.id}});
        this.generatedSeriesNumbers[index] = seriesParsed.series.available ? seriesParsed.series.nextNumber : null;
        this.seriesService.advanceSeries(seriesParsed);
      } else {
        this.generatedSeriesNumbers[index] = null;
      }
    });
  }

  flattenParents(caseOwnership) {
    return _.flattenDeep(caseOwnership.parentCaseOwnership ? [caseOwnership.parentCaseOwnership, this.flattenParents(caseOwnership.parentCaseOwnership)] : []);
  }

  mapCaseOwnerships(caseOwnerships: any[]) : any[] {
    return _.map(caseOwnerships, caseOwnership => {
      return {
        caseOwnership: caseOwnership,
        parentCaseOwnerships: this.flattenParents(caseOwnership)
      };
    });
  }

  computeShares() {
    let result = true;
    const promises = [];
    // compute shares of owners
    _.forEach(this.data.cases, (businessCase, index) => {
      businessCase.obligation = this.data.obligation;
      businessCase.acceleratedPriceType = this.data.acceleratedPriceType;
      let totalShare = new Fraction(0);
      businessCase.caseOwnerships.forEach((caseOwnership) => {
        if (this.caseService.isOwnerPresent(caseOwnership)) {
          caseOwnership.easementPrice = 0;
          caseOwnership.caseOwnershipParcels = [];
          totalShare = totalShare.add(new Fraction(caseOwnership.ownership.podilCitatel, caseOwnership.ownership.podilJmenovatel));
        }
      });

      if (!totalShare.equals(1) && this.data.createGroups.length === 1 && this.data.createGroups.some(group => group.id === 'title')) {
        result = false;
        const msg = 'Na LV ' + this.titleNamesPipe.transform(businessCase.titles[0]) + ' byl zjištěn chybný celkový podíl ' + totalShare.toFraction() + '.';
        this.dialogService.alertDialogPromise(msg);
        return false;
      }

      if (!this.easementPriceByShare && this.data.obligation.computePrice) {
        businessCase.totalEasementPrice = null;
        delete businessCase.series;
        const promise = this.restangular.all('titles/case/compute-price')
          .customPOST(businessCase)
          .toPromise()
          .then((result) => {
            _.assign(businessCase, result.plain());
            this.flatCaseOwnerships[index] = this.mapCaseOwnerships(businessCase.caseOwnerships);
          })
          .then(() => {
            businessCase.totalEasementPrice = 0;
            businessCase.caseOwnerships.forEach((caseOwnership) => {
              caseOwnership.easementPrice += _.reduce(caseOwnership.caseOwnershipParcels, (acc, caseOwnershipParcel) => {
                return acc + caseOwnershipParcel.easementPrice;
              }, 0);
              businessCase.totalEasementPrice += caseOwnership.easementPrice;
            });
          });

        promises.push(promise);
      } else {
        businessCase.totalEasementPrice = 0;
        this.flatCaseOwnerships[index] = this.mapCaseOwnerships(businessCase.caseOwnerships);
      }
    });

    return Promise.all(promises).then(() => result);
  }

  easementPriceByShareUpdated(businessCase) {
    businessCase.caseOwnerships.forEach((caseOwnership) => {
      businessCase.totalEasementPrice -= caseOwnership.easementPrice;
      caseOwnership.easementPrice = Number(caseOwnership.easementPriceByShare);
      caseOwnership.easementPrice = caseOwnership.easementPrice || 0;
      businessCase.totalEasementPrice += caseOwnership.easementPrice;
    });
  }

  submit() {
    this.submitCallback.emit();
  }

  previous() {
    this.businessCaseIndex--;
  }

  next() {
    this.businessCaseIndex++;
  }
}
