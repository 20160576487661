<div [loading]="loading" class="bordered">
  <div *ngIf="!loading" class="p-10">
    <p>Výběr parcely investora. Pokud nebude parcela nalezena pomocí výběru k.ú., LV a čísla parcely, lze zadat údaje o parcele tlačítkem "Přidat parcleu".</p>

    <div class="bordered list p-10 mt-10" style="padding: 10px">
      <h3>Výběr parcely</h3>
      <fieldset class="mb-10 mt-0" style="margin-top: 10px">
        <div class="filter-items">
          <gmt-select
            resource="areas"
            [filter]="{ sortOrder: { sortBy: 'name' }}"
            [data]="externalParcelList.filter.filters"
            field="areaId"
            [multiselect]="true"
            selectTitle="Katastrální území"
            (changed)="onCadastreChanged()"
          ></gmt-select>

          <gmt-select
            resource="titles"
            [globalFilterConfig]="titleFilter"
            [data]="externalParcelList.filter.filters"
            field="titleId"
            [itemPipe]="titleNamePipe"
            [multiselect]="true"
            selectTitle="LV"
            required="false"
          ></gmt-select>

          <input
            class="fulltext"
            type="text"
            autocomplete="off"
            placeholder="Fultextové hledání..."
            [(ngModel)]="externalParcelList.filter.filters.searchText?.values[0].id"
          />

          <a (click)="onSubmitFilter()" class="button">Filtrovat</a>
        </div>
      </fieldset>

      <div [loading]="externalParcelList.loading">
        <div *ngIf="externalParcelList.list">
          <table-parcels-small
            *ngIf="externalParcelList.list.length > 0"
            [data]="externalParcelList.list"
            [columns]="PARCEL_COLS"
            [checkedAll]="checkedAll"
            [isChecked]="isChecked"
            (toggleOne)="onToggleOne($event)"
            (toggleAll)="onToggleAll()"
          ></table-parcels-small>

          <div *ngIf="externalParcelList.list.length === 0" class="empty-info">
            Žádné parcely
          </div>

          <div class="p-10" *ngIf="externalParcelList.itemCount > externalParcelList.list.length">
            <gmt-button [clickAction]="onLoadNextParcels" [gmtDisabled]="externalParcelList.loading">Načíst další</gmt-button>
          </div>
        </div>
      </div>
    </div>

    <div class="bordered p-10">
      <h3>Přidání parcely</h3>
      <div class="mt-10">
        <gmt-button [clickAction]="onAddParcel"><i class="fa fa-plus"></i> Přidat parcelu</gmt-button>
      </div>
      <div *ngIf="externalParcelListAdded.list?.length > 0" class="mt-10">
        <table-parcels-small
          [data]="externalParcelListAdded.list"
          [columns]="PARCEL_COLS"
          [checkedAll]="checkedAllAdded"
          [isChecked]="isCheckedAdded"
          (toggleOne)="onToggleOneAdded($event)"
          (toggleAll)="onToggleAllAdded()"
        ></table-parcels-small>
      </div>
    </div>
  </div>
</div>
