<div class='content'>
  <p *ngIf="!intersectLayerExist && !cutParcels" class="mt-10 mb-10">Nakreslete linii a potvrďtě dělení plochy</p>
  <div class="table mb-20 bordered" *ngIf="cutParcels">
    <div class="head-group">
      <div class="row">
        <span class="cell">Plocha</span>
        <span class="cell">Výměra [<span class="unit">m<sup>2</sup></span>]</span>
        <span class="cell" style="min-width: 260px;">Vlastnictví</span>
      </div>
    </div>
    <div [ngClass]="{'row-group': true, 'hover':true, 'hovered': parcel.properties.highlighted}" *ngFor="let parcel of cutParcels" (mouseenter)="highlightParcel(parcel)" (mouseleave)="removeHighlightParcel()">
        <span class="cell">
          {{parcel.properties.parcelNumber}}
        </span>
      <span [ngClass]="{'cell': true}" class="{{getAreaClass(parcel.properties.owner_type, parcel.properties.wanted, parcel.properties.key)}}">
          {{parcel.properties.area}}
        </span>
      <span class="cell">
          <td class="intersection item-{{ parcel.properties.id }}">
            <wanted-switch [item]="parcel.properties" (changed)="onWantedChanged($event.newValue, $event.oldValue, $event.ownerType)"></wanted-switch>
          </td>
        </span>
    </div>
  </div>
  <p *ngIf="intersectLayerExist" class="mt-10 mb-10" style="max-width: 200px;">Rozdělení ploch je nevratné. Ověřte správnost vedení linie.</p>
  <gmt-button *ngIf="intersectLayerExist" [clickAction]="onCutClick">Rozdělit</gmt-button>
  <gmt-button *ngIf="intersectLayerExist || cutParcels" [clickAction]="onNewCut">Nové dělení</gmt-button>
</div>
