<fieldset>
  <legend>Data</legend>
  <!-- datum osobni navstevy -->
  <div class="column-50" *ngIf="visibleFields['personalVisitDate']">
    <gmt-input label="Datum osobní návštěvy">
      <input type="text" pick-a-date [(ngModel)]="dates.personalVisitDate"/>
    </gmt-input>
  </div>

  <!-- datum odeslani -->
  <div class="column-50" *ngIf="visibleFields['sentDate']">
    <gmt-input label="Datum odeslání">
      <input type="text" pick-a-date [(ngModel)]="dates.sentDate"/>
    </gmt-input>
  </div>

  <!-- datum vraceni nedoruceneho pripadu -->
  <div class="column-50" *ngIf="visibleFields['undeliveredDate']">
    <gmt-input label="Datum vrácení nedoručené smlouvy">
      <input type="text" pick-a-date [(ngModel)]="dates.undeliveredDate"/>
    </gmt-input>
  </div>

  <!-- datum požadavku k jednání -->
  <div class="column-50" *ngIf="visibleFields['meetingRequestDate']">
    <gmt-input label="Datum požadavku k jednání">
      <input type="text" pick-a-date [(ngModel)]="dates.meetingRequestDate"/>
    </gmt-input>
  </div>

  <!-- datum souhlasu -->
  <div class="column-50" *ngIf="visibleFields['acceptedGeometricalPlanPossibleDate']">
    <gmt-input label="Datum souhlasu">
      <input type="text" pick-a-date [(ngModel)]="dates.acceptedGeometricalPlanPossibleDate"/>
    </gmt-input>
  </div>

  <!-- datum prijeti -->
  <div class="column-50" *ngIf="visibleFields['receivedDate']">
    <gmt-input label="Datum přijetí">
      <input type="text" pick-a-date [(ngModel)]="dates.receivedDate"/>
    </gmt-input>
  </div>

  <div class="column-50" *ngIf="visibleFields['dunningLetterDate']">
    <gmt-input label="Datum urgence">
      <input type="text" pick-a-date [(ngModel)]="dates.dunningLetterDate"/>
    </gmt-input>
  </div>

  <div class="column-50" *ngIf="visibleFields['callBeforeExpropriationDate']">
    <gmt-input label="Datum odeslání výzvy před vyvlastněním">
      <input type="text" pick-a-date [(ngModel)]="dates.callBeforeExpropriationDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['callBeforeExpropriationReceivedDate']">
    <gmt-input label="Datum doručení výzvy před vyvlastněním">
      <input type="text" pick-a-date [(ngModel)]="dates.callBeforeExpropriationReceivedDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['expropriationCaseInProgressDate']">
    <gmt-input label="Datum zahájení vyvlastňovacího řízení">
      <input type="text" pick-a-date [(ngModel)]="dates.expropriationCaseInProgressDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['expropriationCaseStoppedDate']">
    <gmt-input label="Datum zastavení vyvlastňovacího řízení">
      <input type="text" pick-a-date [(ngModel)]="dates.expropriationCaseStoppedDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['expropriationCaseFinishedDate']">
    <gmt-input label="Datum vyvlastnění">
      <input type="text" pick-a-date [(ngModel)]="dates.expropriationCaseFinishedDate"/>
    </gmt-input>
  </div>

  <!-- datum podpisu -->
  <div class="column-50" *ngIf="visibleFields['signedDate']">
    <gmt-input label="Datum podpisu">
      <input type="text" pick-a-date [(ngModel)]="dates.signedDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['exchangeRequestDate']">
    <gmt-input label="Datum požadavku na směnu">
      <input type="text" pick-a-date [(ngModel)]="dates.exchangeRequestDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['otherSolutionRequestDate']">
    <gmt-input label="Datum požadavku na jiné řešení">
      <input type="text" pick-a-date [(ngModel)]="dates.otherSolutionRequestDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['disapprovedOwnerDate']">
    <gmt-input label="Datum nesouhlasu vlastníka">
      <input type="text" pick-a-date [(ngModel)]="dates.disapprovedOwnerDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['withoutResolutionDate']">
    <gmt-input label="Datum označení případu bez výsledku">
      <input type="text" pick-a-date [(ngModel)]="dates.withoutResolutionDate"/>
    </gmt-input>
  </div>
  <div class="column-50" *ngIf="visibleFields['approvedOwnerDate']">
    <gmt-input label="Datum souhlasu vlastníka">
      <input type="text" pick-a-date [(ngModel)]="dates.approvedOwnerDate"/>
    </gmt-input>
  </div>

  <!-- datum prijeti odpovedi -->
  <div class="column-50" *ngIf="visibleFields['responseReceivedDate']">
    <gmt-input label="Datum přijetí odpovědi">
      <input type="text" pick-a-date [(ngModel)]="dates.responseReceivedDate"/>
    </gmt-input>
  </div>

  <!-- datum zamitnuti -->
  <div class="column-50" *ngIf="visibleFields['declinedDate']">
    <gmt-input label="Datum zamítnutí">
      <input type="text" pick-a-date [(ngModel)]="dates.declinedDate"/>
    </gmt-input>
  </div>

  <!-- akceleracni priplatek -->
  <div class="column-50" *ngIf="visibleFields['bonusPrice']">
    <gmt-input label="Akcelerační příplatek">
      <input type="text" [(ngModel)]="dates.bonusPrice"/>
    </gmt-input>
  </div>

  <!-- priplatek za overeni podpisu -->
  <div class="column-50" *ngIf="visibleFields['signatureVerificationPrice'] || visibleFields['signatureVerificationPrice'] === 0">
    <gmt-input label="Příplatek za ověření podpisu">
      <input type="text" [(ngModel)]="dates.signatureVerificationPrice"/>
    </gmt-input>
  </div>
</fieldset>
