<div class="detail" [loading]="loading">
  <div class="row">
    <div class="col-6">
      <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam geometrických plánů</a>
    </div>
    <div [gmtAuth]="'manage_geometric_plans'" class="col-6 text-right">
      <ng-container *ngFor="let nextStatus of geometricPlanRequest.nextStatuses">
        <a *ngIf="showNextButton(nextStatus);" (click)="nextStatus === statusEnum.IMPORTED_AUTO ? onImport() : onEdit(nextStatus)" class="button ml-10 mb-10">
          <i class="fa fa-plus-square mr-5" [ngClass]="{'fa-plus-square': nextStatus !== statusEnum.DELETED, 'fa-remove' : nextStatus === statusEnum.DELETED }"></i>
          {{ requestService.getStatusEnumAction(nextStatus) }}
        </a>
      </ng-container>
    </div>
  </div>

  <h1>Geometrický plán č. {{ geometricPlanRequest.name }}<span class="ml-10">{{ requestService.getEnumLabel('type', geometricPlanRequest.type) }}</span></h1>
  <div class="case-status">
    <span>Stav: <b>{{ requestService.getEnumLabel('status', geometricPlanRequest.status) }}</b></span>
  </div>

  <div class="edit">
    <a  [gmtAuth]="'manage_geometric_plans'" (click)="onEdit(null)" class="button mb-10">
      <i class="fa fa-pencil mr-5"></i>Upravit údaje
    </a>
  </div>

  <div class="mt-30 mb-70">
    <div class="flex-rest">
      <div class="column-50">
        <label>Katastrální území</label>
        <div class="info">
          <span *ngFor="let area of geometricPlanRequest.areas">
            {{ area.name }}
          </span>
        </div>

        <label>Zhotovitel</label>
        <div class="info">{{ geometricPlanRequest.supplier }}</div>

        <label>GP potvrzen dne</label>
        <div class="info">{{ geometricPlanRequest.approvedDate | date }}</div>

        <label>Cena GP</label>
        <div class="info">{{ geometricPlanRequest.realizationPrice | price }}</div>

        <label>Stavební objekt</label>
        <div class="info">
          <gmt-construction-object-list [constructionObjects]="geometricPlanRequest.constructionObjects"></gmt-construction-object-list>
        </div>
      </div>

      <div class="column-50">
        <label>Datum založení</label>
        <div class="info">{{ geometricPlanRequest.createdDate | date }}</div>

        <label>Poslední změna stavu</label>
        <div class="info">{{ geometricPlanRequest.lastStatusChangeDate ? (geometricPlanRequest.lastStatusChangeDate | date) : '' }}</div>

        <label>Číslo potvrzení GP (PGP)</label>
        <div class="info">{{ geometricPlanRequest.pgpNumber }}</div>

        <ng-container *ngIf="geometricPlanRequest.type === typeEnum.PARCEL_SPLIT">
          <label>Předběžný souhlas vlastníka</label>
          <div class="info">{{ requestService.getEnumLabel('approve', geometricPlanRequest.parcelSplitPreApproveByOwner) }}</div>

          <label>Předběžný souhlas SÚ</label>
          <div class="info">{{ requestService.getEnumLabel('approve', geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority) }}</div>
        </ng-container>
      </div>
    </div>
  </div>

  <div>
    <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
      <div *ngIf="activeTab === 'parcels'">
        <table-parcels-small
          *ngIf="geometricPlanRequest.parcels?.length > 0"
          [data]="geometricPlanRequest.parcels"
          [columns]="PARCELS_COLUMNS"
        ></table-parcels-small>
        <div *ngIf="geometricPlanRequest.parcels?.length === 0" class="bordered">
          <div class="empty-info">Žádné parcely</div>
        </div>
      </div>
      <div *ngIf="activeTab === 'all-parcels-budouc'">
        <table-parcels-small
          [data]="gpParcels"
          [columns]="PARCELS_COLUMNS"
        ></table-parcels-small>
      </div>
      <div *ngIf="activeTab === 'parcels-budouc'">
        <div [loading]="easementList?.loading || occupationList?.loading">
          <gmt-labeled-block *ngIf="occupationGroups?.permanent" [collapsed]="false" label="{{ 'PERMANENT_OCCUPATION' | wordtranslate }}">
            <table-occupations-permanent [data]="occupationGroups.permanent" [columns]="occupationPermanentColumns"></table-occupations-permanent>
          </gmt-labeled-block>
          <gmt-labeled-block *ngIf="occupationGroups?.underOneYear" [collapsed]="false" [label]="occupationUnderYearTableName">
            <table-occupations-temporary [data]="occupationGroups.underOneYear" [columns]="occupationUnderOneYearColumns"></table-occupations-temporary>
          </gmt-labeled-block>
          <gmt-labeled-block *ngIf="occupationGroups?.overOneYear" [collapsed]="false" [label]="occupationOverYearTableName">
            <table-occupations-temporary [data]="occupationGroups.overOneYear" [columns]="occupationOverOneYearColumns"></table-occupations-temporary>
          </gmt-labeled-block>
          <gmt-labeled-block *ngIf="easementList?.list?.length > 0" [collapsed]="false" label="{{ 'EASEMENT' | wordtranslate }}">
            <table-easements [data]="easementList.list" [columns]="easementColumns"></table-easements>
          </gmt-labeled-block>
        </div>
      </div>
      <div *ngIf="activeTab === 'validated-parcels'" [loading]="!validatedParcelList.list">
        <table-parcels-small
          *ngIf="validatedParcelList.list?.length"
          [data]="validatedParcelList.list"
          [columns]="PARCELS_COLUMNS"
        ></table-parcels-small>
        <div *ngIf="validatedParcelList.list?.length === 0" class="bordered">
          <div class="empty-info">Žádné parcely</div>
        </div>
      </div>
      <div *ngIf="activeTab === 'notes'">
        <gmt-notes
          [noteList]="noteList"
          [editable]="canEdit()"
          [allowType]="true"
          [defaultData]="{ geometricPlanRequest: { id: geometricPlanRequest.id }, systemType: false }"
          (updated)="onUpdateNoteList();loadHistory();"
        ></gmt-notes>
      </div>
      <div *ngIf="activeTab === 'history'">
        <gmt-notes
          [type]="'history'"
          [noteList]="historyList"
          [editable]="false"
          [allowType]="true"
        ></gmt-notes>
      </div>
      <div *ngIf="activeTab === 'documents'">
        <gmt-documents
          [documentCategory]="'gp'"
          [documentList]="documentList"
          [defaultData]="{ geometricPlanRequestId: geometricPlanRequest.id }"
          [authorizedUser]="canEdit()"
          [allowType]="true"
          (updated)="onUpdateDocumentList();loadHistory();"
        ></gmt-documents>
      </div>
    </gmt-navbar>
  </div>

  <help [helpId]="helpIds.GEOMETRIC_PLAN_REQUEST_DETAIL" class="cm-help-page"></help>
</div>
