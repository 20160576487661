import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { ReadinessStateEnum, readinessStateOptions } from '@app/ps/enums/readiness-state.enum';

@Pipe({ name: 'readiness' })
@Injectable({
  providedIn: 'root'
})
export class ReadinessPipe implements PipeTransform {

  transform(readiness: ReadinessStateEnum, field = 'value') {
    if (!readiness) {
      return '';
    }
    return readinessStateOptions.find(r => r.id === readiness)[field];
  }
}
