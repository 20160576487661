import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';

import { vfzeDetailState, vfzeImportState, vfzeListState } from '@app/ps/vfze/vfze.states';
import { VfzeListComponent } from '@app/ps/vfze/components/vfze-list/vfze-list.component';
import { VfzeDetailComponent } from '@app/ps/vfze/components/vfze-detail/vfze-detail.component';
import { VfzeImportComponent } from '@app/ps/vfze/components/vfze-import/vfze-import.component';
import { VfzeImportUploadComponent } from '@app/ps/vfze/components/vfze-import-upload/vfze-import-upload.component';
import { VfzeImportCadastreComponent } from '@app/ps/vfze/components/vfze-import-cadastre/vfze-import-cadastre.component';
import { VfzeImportValidationComponent } from '@app/ps/vfze/components/vfze-import-validation/vfze-import-validation.component';
import { VfzeImportSelectComponent } from '@app/ps/vfze/components/vfze-import-select/vfze-import-select.component';
import { VfzeImportImportComponent } from '@app/ps/vfze/components/vfze-import-import/vfze-import-import.component';
import { VfzeImportRawDataComponent } from '@app/ps/vfze/components/vfze-import-raw-data/vfze-import-raw-data.component';
import { UIRouterModule } from '@uirouter/angular';

const STATES = [
  vfzeListState,
  vfzeDetailState,
  vfzeImportState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...STATES],
    }),
    CommonModule,
    PsCommonModule,
  ],
  providers: [],
  declarations: [
    VfzeListComponent,
    VfzeImportComponent,
    VfzeDetailComponent,
    VfzeImportUploadComponent,
    VfzeImportCadastreComponent,
    VfzeImportValidationComponent,
    VfzeImportSelectComponent,
    VfzeImportImportComponent,
    VfzeImportRawDataComponent
  ],
  entryComponents: [
  ]
})
export class VfzeModule {}
