import { Inject, Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(
    private restangular: Restangular,
  ) { }

  getStatus() {
    return this.restangular
      .one('import/status')
      .get();
  }
}
