<div [loading]="loadingEasements">
  <ng-container *ngIf="data.customData.notStartedParcelIds.length > 0 && !loadingEasements && data?.list?.list">
    <span>VB k řešení</span>
    <ul>
      <ng-container *ngFor="let easement of this.notStartedParcels">
        <li>
          <a *ngIf="easement.parcel" uiSref="symap.project.parcels.detail" [uiParams]="{id: easement.parcel.id}">{{easement.parcel|parcelnumber}}</a>
          <ng-container *ngIf="easement.geometricPlan"> ({{easement.geometricPlan.fiktivni ? 'ZE' : 'GP'}})</ng-container>
          <gmt-construction-object-list *ngIf="easement.constructionObjects.length" [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="data.customData.inProgressParcelIds.length > 0 && !loadingEasements && data?.list?.list">
    <span>Řešená VB</span>
    <ul>
      <ng-container *ngFor="let easement of this.inProgressParcels">
        <li>
          <a *ngIf="easement.parcel" uiSref="symap.project.parcels.detail" [uiParams]="{id: easement.parcel.id}">{{easement.parcel|parcelnumber}}</a>
          <ng-container *ngIf="easement.geometricPlan"> ({{easement.geometricPlan.fiktivni ? 'ZE' : 'GP'}})</ng-container>
          <gmt-construction-object-list *ngIf="easement.constructionObjects.length" [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="data.customData.doneParcelIds.length > 0 && !loadingEasements && data?.list?.list">
    <span>Vyřešená VB</span>
    <ul>
      <ng-container *ngFor="let easement of this.doneParcels">
        <li>
          <a *ngIf="easement.parcel" uiSref="symap.project.parcels.detail" [uiParams]="{id: easement.parcel.id}">{{easement.parcel|parcelnumber}}</a>
          <ng-container *ngIf="easement.geometricPlan"> ({{easement.geometricPlan.fiktivni ? 'ZE' : 'GP'}})</ng-container>
          <gmt-construction-object-list *ngIf="easement.constructionObjects.length" [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>

