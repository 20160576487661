<div class="mb-20 bordered">
  <table>
    <thead>
      <tr>
        <th *ngIf="visibleColumns.parcel">
          <sort-by
            *ngIf="sortableColumns.parcel"
            [selected]="'parcel' === sortOrder.sortBy"
            (sort)="onSortParcel('parcel')"
            [sortDir]="sortOrder.direction">
            Parcela
          </sort-by>
          <p *ngIf="!sortableColumns.parcel">
            Parcela
          </p>
        </th>
        <th *ngIf="visibleColumns.title">
          <sort-by
            *ngIf="sortableColumns.title"
            [selected]="'occupation_title' === sortOrder.sortBy"
            (sort)="onSort('occupation_title', 'parcel.lv')"
            [sortDir]="sortOrder.direction">
            LV
          </sort-by>
          <p *ngIf="!sortableColumns.title">
            LV
          </p>
        </th>
        <th *ngIf="showVbColumn">
          Označení VB
        </th>
        <th *ngIf="visibleColumns.area">
          <sort-by
            *ngIf="sortableColumns.area"
            [selected]="'area' === sortOrder.sortBy"
            (sort)="onSort('area', 'vymera')"
            [sortDir]="sortOrder.direction">
            Plocha VB [<span class="unit">m<sup>2</sup></span>]
          </sort-by>
          <p *ngIf="!sortableColumns.area">
            Plocha VB [<span class="unit">m<sup>2</sup></span>]
          </p>
        </th>
        <th *ngIf="visibleColumns.length">
          <sort-by
            *ngIf="sortableColumns.length"
            [selected]="'length' === sortOrder.sortBy"
            (sort)="onSort('length', 'delka')"
            [sortDir]="sortOrder.direction">
            Délka VB [<span class="unit">m</span>]
          </sort-by>
          <p *ngIf="!sortableColumns.length">
            Délka VB [<span class="unit">m</span>]
          </p>
        </th>
        <th *ngIf="visibleColumns.construction_objects_by_shared_easement">
          Společné Věcné břemeno pro SO
        </th>
        <th *ngIf="visibleColumns.vb_tz">
          Poloha VB
        </th>
        <th *ngIf="visibleColumns.price || visibleColumns.price_edit_construction_object">
          <sort-by
            *ngIf="sortableColumns.price || sortableColumns.price_edit_construction_object"
            [selected]="'easementPrice' === sortOrder.sortBy"
            (sort)="onSort('easementPrice', 'easementPrice')"
            [sortDir]="sortOrder.direction">
            Cena VB
          </sort-by>
          <p *ngIf="!sortableColumns.price && !sortableColumns.price_edit_construction_object">
            Cena VB
          </p>
        </th>
        <th *ngIf="visibleColumns.cases">
          <sort-by
            *ngIf="sortableColumns.cases"
            [selected]="'countCases' === sortOrder.sortBy"
            (sort)="onSort('countCases', 'countCases')"
            [sortDir]="sortOrder.direction">
            Případy
          </sort-by>
          <p *ngIf="!sortableColumns.cases">
            Případy
          </p>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let easement of data">
        <td *ngIf="visibleColumns.parcel" class="no-padding cell-max-height">
          <cell-parcel
            [parcel]="easement.parcel"
            [tooltipType]="'parcel'"
            [tooltipData]="{ parcel: easement.parcel }"
          ></cell-parcel>
        </td>
        <td *ngIf="visibleColumns.title" class="number">
          <b>
            <a *ngIf="!easement.parcel.endDate && easement.parcel.title.id" uiSref="symap.project.titles.detail"  [uiParams]="{ id: easement.parcel.title.id, tab: null }">
              {{ easement.parcel.lv }}
            </a>
          </b>
        </td>
        <td *ngIf="showVbColumn" class="no-padding" [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" [ngClass]="{ 'tooltip-hover-area': easement.endDate }">
          <div class="fill-content" [ngClass]="{cancelled: easement.endDate}">
            <div class="content-wrap">{{ easement.oznacVb }}</div>
          </div>
        </td>
        <td *ngIf="visibleColumns.area" class="number no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content" [ngClass]="{ 'tooltip-hover-area': easement.endDate }">
            <div class="content-wrap"  [ngClass]="{cancelled: easement.endDate}">
              <div class="number">{{ easement.vymera | number }}</div>
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.length" class="no-padding">
          <div [tooltip]="easement.endDate ? 'Neplatné/zrušené VB' : null" class="fill-content" [ngClass]="{ 'tooltip-hover-area': easement.endDate }">
            <div class="content-wrap"  [ngClass]="{cancelled: easement.endDate}">
              <div class="number">{{ easement.delka | number }}</div>
            </div>
          </div>
        </td>
        <td *ngIf="visibleColumns.construction_objects_by_shared_easement">
          <div *ngIf="easement.constructionObjects.length > 1">
            <gmt-construction-object-list [constructionObjects]="easement.constructionObjects"></gmt-construction-object-list>
          </div>
        </td>
        <td *ngIf="visibleColumns.vb_tz" class="text-center">
          {{ easement | easementOccupation }}
        </td>
        <td *ngIf="visibleColumns.price || visibleColumns.price_edit_construction_object" class="no-padding cell-max-height">
          <cell-price
            [editFunction]="onPriceEdit"
            [editFunctionParams]="easement"
            [price]="easement.easementPrice"
            [editable]="isEditablePrice(easement)"
            [tooltipType]="'easement-price'"
            [tooltipData]="{ easement: easement }"
          ></cell-price>
        </td>
        <td *ngIf="visibleColumns.cases" class="no-padding">
          <cell-cases
            [casesCount]="easement.countCases"
            [tooltipType]="'cases'"
            [tooltipData]="{ filter: { filters: { easementId: easement.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships'] }}}"
          ></cell-cases>
        </td>
      </tr>
    </tbody>
  </table>
</div>
