import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { OpinionModel } from '@app/ps/models/opinion.model';
import { ExpertModel } from '@app/ps/models/expert.model';
import { OpinionService } from '@app/ps/services/opinion.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'external-parcel-form',
  templateUrl: './external-parcel-form.component.html',
  styleUrls: ['external-parcel-form.component.scss']
})
export class ExternalParcelFormComponent implements OnInit {

  parcel = new ParcelModel();
  enablePricing: boolean;
  areaSelect: boolean;
  opinion: OpinionModel;
  parcelPrice: ParcelPriceModel;
  pricingType: 'E' | 'O';

  constructor(
    private restangular: Restangular,
    private opinionService: OpinionService,
    private dialogConfig: DialogConfig,
    private dialogComponent: DialogRef,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onExpertSelected = this.onExpertSelected.bind(this);
  }

  ngOnInit() {
    if (<DialogConfigData>this.dialogConfig.data) {
      const enablePricing = (<DialogConfigData>this.dialogConfig.data).enablePricing;
      const areaSelect = (<DialogConfigData>this.dialogConfig.data).areaSelect;
      if (enablePricing !== undefined) {
        this.enablePricing = enablePricing;
      }

      if (areaSelect !== undefined) {
        this.areaSelect = areaSelect;
      }
    }
  }

  isParcelValid(): boolean {
    return !!(
      this.parcel.parcisKmen
      && this.parcel.katuzeNazev
      && this.parcel.katuzeKod
      && this.parcel.vymera
      && this.parcel.lv
      && this.parcel.cislKod
    );
  }

  isPricingValid(): boolean {
    return !!this.opinion && !!(
        (this.opinion.priceType === 'E' && this.opinion.expert && this.opinion.expertOpinionDate)
        ||
        (this.opinion.priceType === 'O' && this.opinion.otherSource)
    );
  }

  isValid(): boolean {
    return this.isParcelValid() && (!this.pricingType || this.isPricingValid());
  }

  onPricingTypeSelect(pricingType: 'E' | 'O'): Function {
    return () => {
      if (!pricingType || this.pricingType === pricingType) {
        this.pricingType = undefined;
        this.parcelPrice = undefined;
        this.opinion = undefined;
        return;
      }

      this.pricingType = pricingType;
      this.parcelPrice = (pricingType === 'E'
        ? ParcelPriceModel.createDefaultExpertOpinionPermanent()
        : ParcelPriceModel.createDefaultOtherSourcePermanent()
      );
      this.opinion = (pricingType === 'E'
        ? OpinionModel.createDefaultExpertOpinionPermanent()
        : OpinionModel.createDefaultOtherSourcePermanent()
      );
    };
  }

  onExpertSelected(expert: ExpertModel) {
    if (this.opinion) {
      this.opinion.expert = expert;
    }
  }

  onSave(): Promise<any> {
    let resultParcel;

    return this.restangular.all('parcels/create').customPOST(this.parcel)
      .toPromise()
      .then((parcel) => {
        resultParcel = parcel.plain();

        if (this.pricingType) {
          const opinionMsg = {
            ...this.opinion,
            parcelPrices: [{ ...this.parcelPrice, parcel: resultParcel }]
          };

          return this.opinionService.createOne(opinionMsg);
        }

        return true;
      })
      .then(() => {
        this.dialogComponent.close(resultParcel);
      });
  }

  onCancel() {
    this.dialogComponent.close(null);
  }
}
