import { NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { CommonModule } from '../common/common.module';
import { MainComponent } from './components/main/main.component';
import { ProjectsComponent } from '@app/dashboard/components/projects/projects.component';
import { HttpClientModule } from '@angular/common/http';
import { TooltipTrendComponent } from '@app/dashboard/components/tooltip-trend/tooltip-trend.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';

registerLocaleData(localeCs, 'cs');

export const dashboardState: Ng2StateDeclaration = {
  name: 'dashboard',
  url: '/dashboard',
  abstract: true,
  component: MainComponent
};

export const projectsState: Ng2StateDeclaration = {
  name: 'dashboard.projects',
  url: '/projects',
  data: {
    title: 'dashboard.name',
  },
  views: {
    'content@dashboard': {
      component: ProjectsComponent,
    }
  },
};

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        dashboardState,
        projectsState
      ]
    }),
    HttpClientModule,
  ],
  providers: [
    DatePipe,
  ],
  declarations: [
    MainComponent,
    ProjectsComponent,
    TooltipTrendComponent,
  ],
  entryComponents: [
    TooltipTrendComponent,
  ]
})
export class DashboardAppModule {
}
