import { Injectable } from '@angular/core';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { CaseSubjectModel } from '@app/ps/models/case-subject.model';
import { CommonAddressModel } from '@app/ps/models/common-address.model';
import { SubjectBasicInfoModel } from '@app/ps/models/subject-basic-info.model';
import { SubjectModel } from '@app/common/models/subject.model';
import { SubjectDetailModel } from '@app/ps/models/subject-detail.model';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  // basic info
  getAdaptedBasicInfo(subject: CaseSubjectModel | SubjectModel): SubjectBasicInfoModel {
    let _subject: any;
    const result = new SubjectBasicInfoModel();

    if (subject && subject['subject']) { // CaseSubjectModel
      _subject = subject;
      result.ico = _subject && _subject.subject && _subject.subject.ico;
      result.opsubType = _subject && _subject.subject && _subject.subject.opsubType;
    } else { // SubjectModel
      _subject = subject['subjectDetail'];
      result.ico = subject['ico'];
      result.opsubType = subject['opsubType'];
    }

    result.personalIdentificationNumber = _subject && _subject.personalIdentificationNumber;
    result.maritalStatus = _subject && _subject.maritalStatus;
    result.identificationNumber = _subject && _subject.identificationNumber;
    result.invoicePayment = _subject && _subject.invoicePayment;
    result.gender = _subject && _subject.gender;
    result.dateOfBirth = _subject && _subject.dateOfBirth;
    result.businessRegisterRegionalCourt = _subject && _subject.businessRegisterRegionalCourt;
    result.businessRegisterSection = _subject && _subject.businessRegisterSection;
    result.businessRegisterEntry = _subject && _subject.businessRegisterEntry;
    result.dic = _subject && _subject.dic;
    result.vatPayer = _subject && _subject.vatPayer;

    return result;
  }

  getSubjectCompatibleBasicInfo(basicInfo: SubjectBasicInfoModel): Partial<SubjectDetailModel> {
    return {
      maritalStatus: basicInfo.maritalStatus,
      identificationNumber: basicInfo.identificationNumber,
      invoicePayment: basicInfo.invoicePayment,
      gender: basicInfo.gender,
      personalIdentificationNumber: basicInfo.personalIdentificationNumber,
      dateOfBirth: basicInfo.dateOfBirth,
      businessRegisterRegionalCourt: basicInfo.businessRegisterRegionalCourt,
      businessRegisterSection: basicInfo.businessRegisterSection,
      businessRegisterEntry: basicInfo.businessRegisterEntry,
      dic: basicInfo.dic,
      vatPayer: basicInfo.vatPayer,
    };
  }

  // address
  getAdaptedAddress(subject: SubjectModel | CaseSubjectModel | SubjectDetailModel): CommonAddressModel {
    return CommonAddressModel.fromSubjectAddress(subject);
  }

  getSubjectCompatibleAddress(address: CommonAddressModel): Partial<SubjectModel | CaseSubjectModel | SubjectDetailModel> {
    return CommonAddressModel.toAddress(address);
  }

  // mailing address
  getAdaptedMailingAddress(subject: SubjectDetailModel | CaseSubjectModel): CommonAddressModel {
    return CommonAddressModel.fromMailingAddress(subject);
  }

  getSubjectCompatibleMailingAddress(address: CommonAddressModel): Partial<SubjectModel | CaseSubjectModel | SubjectDetailModel> {
    return CommonAddressModel.toMailingAddress(address);
  }

  // contact person
  getAdaptedContactPerson(subject: SubjectDetailModel | CaseSubjectModel): Partial<ActingPersonModel> {
    return <ActingPersonModel>{
      titleBefore: subject && subject.contactPersonTitleBefore,
      firstName: subject && subject.contactPersonFirstName,
      surname: subject && subject.contactPersonSurname,
      degreeAfter: subject && subject.contactPersonDegreeAfter,
      'function': subject && subject.contactPersonFunction,
      phoneNumber: subject && subject.contactPersonPhoneNumber,
      email: subject && subject.contactPersonEmail,
    };
  }

  getSubjectCompatibleContactPerson(contactPerson: Partial<ActingPersonModel>): any {
    return {
      contactPersonTitleBefore: contactPerson && contactPerson.titleBefore,
      contactPersonFirstName: contactPerson && contactPerson.firstName,
      contactPersonSurname: contactPerson && contactPerson.surname,
      contactPersonDegreeAfter: contactPerson && contactPerson.degreeAfter,
      contactPersonFunction: contactPerson && contactPerson['function'],
      contactPersonPhoneNumber: contactPerson && contactPerson.phoneNumber,
      contactPersonEmail: contactPerson && contactPerson.email,
    };
  }
}
