import { Component, Inject, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';

export interface EasementCreateStep {
  id: string;
  name: string;
  enabled: boolean;
  help: string;
}

@Component({
  selector: 'easement-create',
  templateUrl: './easement-create.component.html',
})
export class EasementCreateComponent implements OnInit {

  private deregisterLeaveListener: Function;
  private stepsHistory: string[] = [];
  activeStep: EasementCreateStep;
  data: any = {};
  helpIds = HelpService.HELP_IDS;

  steps: EasementCreateStep[] = [
    { id: 'add-files', name: 'Vložení souborů', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_ADD_FILES' },
    { id: 'info', name: 'Údaje', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_INFO' },
    { id: 'summary', name: 'Výsledek', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_SUMMARY' },
  ];

  constructor(
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.onStep('add-files', true);
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
  }

  private beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
    });
  }

  onStep(id: string, enable = false) {
    const step = this.steps.find(s => s.id === id);

    if (step.enabled === false && enable === false) {
      return;
    }

    // step back
    if (this.stepsHistory.includes(id)) {
      this.activeStep = step;
      this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
      this.steps.forEach(v => v.enabled = this.stepsHistory.includes(v.id));

    // step forward
    } else {
      this.activeStep = step;
      this.stepsHistory.push(id);
      step.enabled = true;
    }
  }

  onFinish() {
    this.deregisterLeaveListener();
    this.stateService.go('symap.project.constructionObjects');
  }
}
