<div class="detail">
  <h1>Validátor VFZE</h1>

  <div class="mt-50 text-center">
    <fileupload (change)="onFilesChange($event)" [message]="filesMsg" [files]="files"></fileupload>
    <gmt-button [clickAction]="onUpload" [gmtDisabled]="!isFileValidToUpload">Spustit validaci souboru</gmt-button>
    <a uiSref="vfze.map" target="_blank" (click)="!isFileValidToUpload ? $event.preventDefault() : null" [ngClass]="{ disabled: !isFileValidToUpload }" class="button">Náhled</a>
  </div>

  <div *ngIf="vfzeMetadata" class="mt-20 flexbox-container justify-content-center">
    <div style="width: 400px;" class="column-100 bordered p-10">
      Informace o VFZE:<br>

      <label>verze VFZE:</label> <div class="info">{{ vfzeMetadata.verzeFormat }}</div>
      <label>datum platnosti KN dat:</label> <div class="info">{{ vfzeMetadata.platnostDatKn | date }}</div>
      <label>datum vytvoření VFZE:</label> <div class="info">{{ vfzeMetadata.platnostExportu | date }}</div>
      <label>software:</label> <div class="info">{{ vfzeMetadata.software }}</div>
      <label>autor:</label> <div class="info">{{ vfzeMetadata.autor }}</div>
      <label>název akce:</label> <div class="info">{{ vfzeMetadata.nazev }}</div>
    </div>
  </div>

  <div *ngIf="partialMessageLog?.length > 0" class="mt-20">
    <div *ngFor="let log of partialMessageLog; index as i" class="text-center">{{ i + 1 }} - {{ log }}</div>
  </div>

  <div *ngIf="validationResponse" class="mt-30 jumbotron">
    <div class="text-center" *ngIf="getValidationResult() as result">
      <p class="{{ result === 'fatal' ? 'error' : (result === 'warning' ? 'success' : result) }}-message text-center">
        <span *ngIf="result === 'success'">Validace proběhla úspěšně.</span>
        <span *ngIf="result === 'warning'">Validace proběhla úspěšně. Protokol obsahuje varování na možné nesoulady. Doporučena kontrola.</span>
        <span *ngIf="result === 'error'">Validace skončila neúspěšně, s chybami.</span>
        <span *ngIf="result === 'fatal'">Validace skončila neúspěšně. Nesoulad s XSD šablonou.</span>
      </p>

      <p *ngIf="validationResponse.validationMessage" class="text-center">
        {{ validationResponse.validationMessage }}
      </p>
    </div>
    <div class="text-center mt-20">
      <gmt-button [clickAction]="onSaveValidationResponse">Stáhnout protokol validace</gmt-button>
      <a *ngIf="containResponseErrors()"
         (click)="xmlErrorsDisplayed = !xmlErrorsDisplayed"
         class="button ml-10"
      >{{ xmlErrorsDisplayed ? 'Skrýt chyby v XML' : 'Zobrazit chyby v XML' }}</a>
    </div>
  </div>

  <div *ngIf="xmlErrorsDisplayed && validationResponse && vfzeFileContent" class="mt-20">
    <vfze-problems [validationResponse]="validationResponse" [vfzeSource]="vfzeFileContent"></vfze-problems>
  </div>

  <div class="mt-50 p-10">
    <div class="row">
      <div class="col-md-6">
        <h2>Soulad souboru vfze s validační šablonou</h2>
        <div><strong>Validátor VFZE disponuje aktuálně platnou validační šablonou VFZE. Kontroluje se:</strong></div>
        <ul class="mt-10">
          <li>správný datový typ pole</li>
          <li>max. a min. délka textových polí</li>
          <li>max. a min. rozsah číselných polí</li>
          <li>počet desetinných míst</li>
          <li>vyplnění povinných polí</li>
          <li>počet výskytů (0, 1, ..., n)</li>
          <li>vyplnění polí dle platného číselníku</li>
          <li>formát textových polí (PKN, PZE, IČO apod.)</li>
        </ul>
      </div>

      <div class="col-md-6">
        <h2>Další datové a topologické kontroly souboru vfze</h2>
        <div><strong>Validátor VFZE dále kontroluje nezbytný datový soulad dat souboru a topologii geometrie. Kontroluje se:</strong></div>
        <ul class="mt-10">
          <li>existence parcel v ISKN (k 1. dni aktuálního měsíce)</li>
          <li>kontrola výměry parcely</li>
          <li>suma výměr záborů nemůže být větší než výměra parcely</li>
          <li>existence parcely v KN (RUIAN)</li>
          <li>přesahy ploch záborů vůči sobě</li>
          <li>souřadnicový systém</li>
          <li>kontrola veškerých ID (duplicity), a kontrola vazeb a odkazů na další elementy</li>
          <li>kód katastrálního území u parcely a budoucí parcely</li>
          <li>blok gpPEPV musí být uveden, pokud existuje blok gpParcela</li>
          <li>hodnoty Úsek/Etapa mezi daty u Záborů a Objektů</li>
          <li>duplicitní označení objektu</li>
          <li>výměra trvalého záboru a výměra zbytkové parcely pro ZE DSP, DUSP</li>
          <li>validace geometrie</li>
        </ul>
      </div>
    </div>
  </div>
</div>
