<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam geometrických plánů</a>
  </div>

  <h1>Zavedený geometrický plán</h1>

  <div class="row mt-30">
    <div class="col-12 offset-lg-3 col-lg-6">
      <fieldset>
        <legend>Základní údaje</legend>

        <!-- KN GP -->
        <gmt-input label="Záznam kn_gp" [required]="true" class="mt-20">
          <gmt-select
            resource="geometric-plans"
            [filter]="{ sortOrder: { sortBy: 'cis' }, filters: { cis: geometricPlanRequest.name, withRequest: false, loadCollections: ['knBudoucs', 'knBudoucs.currentParcels', 'easements'] }}"
            [data]="geometricPlanRequest"
            field="knGp"
            required="true"
            selectTitle="Vyberte GP"
            [itemPipe]="knGpNamePipe"
            (changed)="onKnGpChange($event.newValue)"
          ></gmt-select>
        </gmt-input>

        <div *ngIf="similarGeometricPlanRequestExists" class="mt-20 text-center bordered error-message p-20">
          Existuje GP, který obsahuje jednu nebo více vybraných parcel.
        </div>

        <div *ngIf="emptyKnGp" class="mt-20 text-center bordered error-message p-20">
          Nebyly nalezeny žádné parcely.
        </div>

        <!-- ucel GP -->
        <gmt-input label="Účel" [required]="true" class="mt-20">
          <gmt-select
            [optionItems]="typeOptions"
            [data]="this"
            field="type"
            required="true"
          ></gmt-select>
        </gmt-input>

        <!-- stavebni objekty -->
        <gmt-input label="{{ 'CONSTRUCTION_OBJECT' | wordtranslate:true }}" class="mt-20">
          <gmt-select
            resource="construction-objects"
            [globalFilterConfig]="constructionObjectFilter"
            [filter]="{ sortOrder: { sortBy: 'socis' }}"
            [data]="geometricPlanRequest"
            field="constructionObjects"
            [multiselect]="true"
            required="false"
            [itemPipe]="constructionObjectNamePipe"
          ></gmt-select>
        </gmt-input>
      </fieldset>

      <!-- PDF GP -->
      <fieldset>
        <legend class="required">Dokument PDF potvrzeného geometrického plánu katastrálním pracovištěm</legend>
        <fileupload class="blue" (change)="onGpFilesChange($event)" [message]="gpFilesMsg" inputId="gpHidden"></fileupload>
        <p class="text-center"><i>Vkládaný dokument PDF musí obsahovat elektronické razítko katastrálního pracoviště.</i></p>
      </fieldset>

      <!-- VFK GP -->
      <fieldset>
        <legend class="required">Soubor VFK předaný na katastrální pracoviště</legend>
        <fileupload class="blue" (change)="onGpVfkFilesChange($event)" [message]="gpVfkFilesMsg" inputId="gpVfkHidden"></fileupload>
      </fieldset>

      <!-- GP DOCUMENTATION -->
      <fieldset>
        <legend [ngClass]="{ required: geometricPlanRequest.type === typeEnum.EASEMENT_EXTENT }">Geodetická dokumentace GP (Tabulka výměr a délek pro případ GP na vyznačení VB, ZIP soubor obsahující ZPMZ, DGN kresbu a pod.)</legend>
        <fileupload class="yellow" (change)="onGpDocFilesChange($event)" [message]="gpDocFilesMsg" inputId="gpDocHidden"></fileupload>
      </fieldset>

      <div class="mt-20 text-center">
        <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">Vložit zavedený geometrický plán</gmt-button>
      </div>
    </div>
  </div>
</div>
