<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div>
      <a uiSref="^.detail" [uiParams]="{ id: constructionObject.id }" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>detail {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</a>
    </div>

    <h1>Editace {{constructionObject.socis}} {{constructionObject.sotx}}
      <span>{{ 'ETAPA_USEK' | wordtranslate }}: {{constructionObject.sousek}}</span>
    </h1>

    <div class=" mt-30 mb-70">
      <fieldset>
        <legend>Základní údaje</legend>
        <div class="column-100">
          <label>Název {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</label>
          <div class="info">{{ constructionObject.sotx }}</div>
        </div>
        <div class="column-100">
          <label>Číslo {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</label>
          <div class="info">{{ constructionObject.socis }}</div>
        </div>
        <div class="column-100">
          <label>{{ 'ETAPA_USEK' | wordtranslate: true }}</label>
          <div class="info">{{ constructionObject.sousek }}</div>
        </div>
        <div class="column-100" *ngIf="!hideColumnList('realizationDate')">
          <gmt-input label="Termín realizace">
            <input pick-a-date type="text" [(ngModel)]="constructionObject.realizationDate"/>
          </gmt-input>
        </div>
        <div class="column-100">
          <gmt-input label="Povolující úřad">
            <input type="text" [(ngModel)]="constructionObject.sourad"/>
          </gmt-input>
        </div>
      </fieldset>

      <fieldset>
        <legend>Správce {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</legend>
        <div class="column-100">
          <gmt-input label="Typ správce">
            <gmt-switch (select)="onChangeAdministratorType()" [options]="administratorTypes" [(selectedId)]="constructionObject.administratorType"></gmt-switch>
          </gmt-input>
        </div>
        <div class="column-100" *ngIf="constructionObject.administratorType == 'A'">
          <label>Správce</label>
          <div class="input">
            <gmt-select
              [reloadOptionsRegister]="reloadAdministratorRegister"
              resource="administrators"
              [data]="constructionObject"
              field="administrator"
              [itemPipe]="projectSubjectNamePipe"
              (changed)="onChangeAdministrator()"
            ></gmt-select>
            <a (click)="onUpdateAdministrator()" title="Upravit správce {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }}" *ngIf="constructionObject.administrator" class="button ml-5 not-underline">
              <span class="fa fa-pencil"></span>
            </a>
            <a (click)="onCreateAdministrator()" title="Nový správce {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }}" class="button ml-5">
              <span class="fa fa-plus"></span>
            </a>
          </div>
        </div>
      </fieldset>

      <fieldset *ngIf="constructionObject.administrator && constructionObject.administratorType === 'A'">
        <legend>Podepisující osoby za správce</legend>
        <div [loading]="loadingActingPersons">
          <div class="checklist" *ngIf="constructionObject.administrator.actingPersons">
            <div class="checklist-item" *ngFor="let actingPerson of constructionObject.administrator.actingPersons">
              <div (click)="actingPersons.toggleSelection(actingPerson)">
                <gmt-checkbox
                  gmtType="multiselect"
                  [gmtChecked]="actingPersons.isChecked(actingPerson)"
                ></gmt-checkbox>
                {{ actingPerson | projectSubjectName}}
              </div>
            </div>
          </div>
          <div *ngIf="constructionObject.administrator.actingPersons && constructionObject.administrator.actingPersons.length === 0" class="empty-info">Žádné jednající osoby</div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Číselná řada</legend>
        <series-list [seriesList]="seriesParsed" [withConstructionObjectSelection]="false"></series-list>
      </fieldset>

      <div class="text-center mt-20">
        <gmt-button [clickAction]="onUpdate">Uložit údaje</gmt-button>
      </div>
    </div>
  </div>
</div>
