﻿import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ListService } from '../../services/list.service';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() list: any;
  @Input() shouldUpdateUrl = true;
  @Output() callbackRegister = new EventEmitter();
  @Output() pageChanged = new EventEmitter();
  currentPage = 0;
  totalPages = 0;
  cachedPages = {
    totalPages: 0, currentPage: 0, pages: {}
  };
  range = 5;
  math: any;

  constructor(
    private listService: ListService,
    private stateService: StateService
  ) {
    this.shouldUpdateUrl = this.shouldUpdateUrl === true;
    this.math = Math;
    this.onListChanged = this.onListChanged.bind(this);
  }

  ngOnInit() {
    this.callbackRegister.emit({
      onListChanged: this.onListChanged.bind(this),
    });
    this.onListChanged();
  }

  onListChanged() {
    this.currentPage = this.listService.getCurrentPage(this.list);
    this.totalPages = this.listService.getTotalPages(this.list);
    if (this.shouldUpdateUrl && ((this.totalPages > 0 && this.currentPage > this.totalPages) || parseInt(this.stateService.params.page, 10) !== this.currentPage)) {
      this.stateService.go('.', {page: 1});
    }
  }

  pages = () => {
    if (this.cachedPages.totalPages === this.totalPages && this.cachedPages.currentPage === this.currentPage) {
      return this.cachedPages.pages;
    }

    // if no ellipsis to be shown range is increased
    const currentpages = [];
    const tmpRange = this.range;
    let start = this.math.min(this.currentPage - this.math.floor(tmpRange / 2), this.totalPages - this.range + 1);
    if (start <= 2) {
      start = 2;
    }

    const end = this.math.min(this.totalPages - 1, start + tmpRange - 1);
    if (end === this.totalPages - 1) {
      start = this.totalPages > tmpRange ? this.totalPages - tmpRange : start;
    }

    for (; start <= end; start++) {
      currentpages.push(start);
    }

    if (currentpages[0] === 1 && currentpages.length === this.range) {
      currentpages.splice(0, 1);
    }

    this.cachedPages.currentPage = this.currentPage;
    this.cachedPages.pages = currentpages;
    this.cachedPages.totalPages = this.totalPages;

    return this.cachedPages.pages;
  };

  showPage = (pageNumber) => {
    this.list.filter.offset = (pageNumber - 1) * this.list.filter.limit;
    // this.listService.fetchResult(this.list);
    // this.onListChanged();

    this.pageChanged.emit(pageNumber);
  };
}
