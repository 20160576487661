<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Přehled importů VFZE</a>
  </div>
  <h1>Import VFZE</h1>
  <div class="mt-20">
    <div class="flexbox-container">
      <div class="content-menu">
        <div
          *ngFor="let step of steps"
          (click)="onStep(step.id)"
          [ngClass]="{ active : step.id === activeStep.id, cancelled: !step.enabled }
        ">{{ step.name }}</div>
      </div>
      <div class="flex-rest">
        <vfze-import-upload
          *ngIf="activeStep.id === 'upload'"
          [data]="data"
          (submitCallback)="onStep('validation', true)"
        ></vfze-import-upload>
        <vfze-import-validation
          *ngIf="activeStep.id === 'validation'"
          [data]="data"
          (submitCallback)="onStep('save', true)"
          (backCallback)="onStep('upload', true)"
        ></vfze-import-validation>
        <vfze-import-raw-data
          *ngIf="activeStep.id === 'save'"
          [data]="data"
          (submitCallback)="onFinish()"
          (backCallback)="onStep('upload', true)"
        ></vfze-import-raw-data>

<!--    <vfze-import-cadastre
          *ngIf="activeStep.id === 'cadastre'"
          [data]="data"
          (submitCallback)="onStep('select', true)"
          (backCallback)="onStep('validation', true)"
        ></vfze-import-cadastre>
        <vfze-import-select
          *ngIf="activeStep.id === 'select'"
          [data]="data"
          (submitCallback)="onStep('import', true)"
          (backCallback)="onStep('cadastre', true)"
        ></vfze-import-select>
        <vfze-import-import
          *ngIf="activeStep.id === 'import'"
          [data]="data"
          (submitCallback)="onFinish()"
          (backCallback)="onStep('select', true)"
        ></vfze-import-import> -->
      </div>
    </div>
  </div>

  <help [helpId]="helpIds[activeStep.help]" class="cm-help-page"></help>
</div>
