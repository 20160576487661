import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'description'
})
@Injectable({
  providedIn: 'root'
})
export class DescriptionPipe implements PipeTransform {

  transform(item: any): string {

    return item.description;
  }
}
