<div [loading]="loading"></div>

<ng-container *ngIf="!loading">
  <div id="top-bar" [ngClass]="{ test: ENV !== 'production' }">
    <div class="logo">
      <a href="#" class="menu-indicator" (click)="onCollapse();$event.preventDefault();" [attr.title]="collapsed ? 'Otevřít menu' : 'Zavřít menu'">
        <i [ngClass]="['fa', 'fa-bars']"></i>
      </a>
      <div class="image-container">
        <img src="./img/logo/promap_xxl.svg" alt="logo"/>
      </div>
    </div>
    <login [user]="user" (logoutClick)="logout()" [servicedeskUrl]="servicedeskUrl"></login>
  </div>
  <div class="main">
    <app-menu [ngClass]="{collapsed: collapsed}" [collapsed]="collapsed" class="flexbox-container" id="menu" [menuConfig]="menuConfig"></app-menu>
    <div id="content-wrap">
      <notification *ngIf="showRunningCadastreImportNotification" class="ml-30 mr-30 mt-10" level="danger">Právě probíhá aktualizace katastrálních dat. Vaše požadavky mohou být vyřizovány pomaleji. Zkuste to prosím později.</notification>
      <div id="content" ui-view="content"></div>
    </div>
  </div>
</ng-container>
