<div class="detail">
  <div [loading]="loading">
    <div *ngIf="!loading">
      <div style="display:flex">
        <div class="line-height-l" style="min-width: 120px;">
          <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>seznam parcel</a>
        </div>
        <div class="actions">
          <a class="button" uiSref="dimap.project.map" [uiParams]="{parcelId: data.id}"><i class="fa fa-map-marker"></i>Zobrazit v mapovém okně</a>
          <a class="button" href="http://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?&typ=parcela&id={{data.idpar}}" *ngIf="data.idpar" target="_blank"><i class="cuzk"></i>Nahlížení do katastru</a>
          <a class="button" uiSref="dimap.project.titles.detail" [uiParams]="{id: data.title.id}" *ngIf="data.title.id"><i class="fa fa-list-alt"></i>Náhled listu vlastnictví</a>
        </div>
      </div>

      <h1>Parcela {{data | parcelnumber}}
        <span>{{data.cislNazev}} / částečný výpis /</span>
        <span *ngIf="!data.endDate">Data KN aktuální k {{data.title.cadastreDataDate | date: 'd.M.yyyy'}}</span>
        <span *ngIf="data.endDate">Data platná do aktualizace KN {{data.endDate | date: 'd.M.yyyy'}}</span>
      </h1>

      <fieldset class="bordered">
        <legend>Základní informace katastru nemovitostí</legend>
        <div class="column-50">
          <label>Parcelní číslo</label><div class="info">{{data | parcelnumber}}</div>
        </div>

        <div class="column-50">
          <label>Obec</label><div class="info">{{data.obecNazev}}</div>
        </div>

        <div class="column-50">
          <label>Katastrální území</label><div class="info">{{data.katuzeNazev}}</div>
        </div>

        <div class="column-50">
          <label>List vlastnictví</label><div class="info">{{data.title.lv}}</div>
        </div>

        <div class="column-50">
          <label>Výměra</label><div class="info">{{data.vymera}} m<sup>2</sup> </div>
        </div>

        <div class="column-50">
          <label>Druh pozemku</label><div class="info">{{data.drupozNazev}}</div>
        </div>

        <div class="column-50" *ngIf="data.zpusobVyuziti">
          <label>Využití pozemku</label><div class="info">{{data.zpusobVyuziti}}</div>
        </div>

        <div class="column-100" *ngIf="data.parcelProtectionsText">
          <label>Ochrana</label><div class="info">{{data.parcelProtectionsText}}</div>
        </div>
      </fieldset>

      <fieldset *ngFor="let ownership of data.title.ownerships" class="bordered">
        <legend *ngIf="ownership.subject.opsubType === 'BSM'">Vlastník - Osoba SJM</legend>
        <legend *ngIf="ownership.subject.opsubType === 'OPO'">Vlastník - Společnost</legend>
        <legend *ngIf="ownership.subject.opsubType === 'OFO'">Vlastník - Fyzická osoba</legend>

        <div class="column-100">
          <label>Podíl</label>
          <div class="info">{{ownership.podilCitatel}}/{{ownership.podilJmenovatel}}<br/>{{ownership.typravNazev}}</div>
        </div>

        <div class="column-50">
          <div *ngIf="getSubject(ownership) as currentSubject">
            <label>{{currentSubject.opsubType == 'OPO' ? 'Název' : 'Jméno'}}</label>
            <div class="info"><b>{{currentSubject|subjectName}}</b></div>

            <label>Adresa</label>
            <div class="info">
              {{currentSubject.ulice || !currentSubject.cisloDomovni ? currentSubject.ulice : 'č.p.'}} {{currentSubject.cisloDomovni}}<span *ngIf="currentSubject.cisloOrientacni">/{{currentSubject.cisloOrientacni}}</span><br/>
              {{currentSubject.psc}} {{currentSubject.obec}}
            </div>

            <div *ngIf="currentSubject.opsubType === 'OPO'">
              <label>IČ</label><div class="info">{{currentSubject.ico}}</div>
            </div>
          </div>
        </div>

        <div class="column-50" *ngIf="ownership.subject.opsubType === 'BSM'">
          <label>Jméno</label>
          <div class="info"><b>{{ownership.subject.subjectSjm2|subjectName}}</b></div>

          <label>Adresa</label>
          <div class="info">
            {{ownership.subject.subjectSjm2.ulice || !ownership.subject.subjectSjm2.cisloDomovni ? ownership.subject.subjectSjm2.ulice : 'č.p.'}} {{ownership.subject.subjectSjm2.cisloDomovni}}<span *ngIf="ownership.subject.subjectSjm2.cisloOrientacni">/{{ownership.subject.subjectSjm2.cisloOrientacni}}</span><br/>
            {{ownership.subject.subjectSjm2.psc}} {{ownership.subject.subjectSjm2.obec}}
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div [loading]="list.loading" gmtAuth="property_status,manage_property_status">
    <div *ngIf="!list.loading && list.itemCount > 0">
      <h2>Plochy silničního pozemku</h2>
      <div class="bordered">
        <div class="table">
          <div class="head-group">
            <div class="row">
              <sort-by class="cell" [selected]="'wanted' === list.filter.sortOrder.sortBy" (sort)="onSort('wanted', $event)" [sortDir]="list.filter.sortOrder.direction">Vlastnictví</sort-by>
              <sort-by class="cell" [selected]="'division_type_name' === list.filter.sortOrder.sortBy" (sort)="onSort('division_type_name', $event)" [sortDir]="list.filter.sortOrder.direction">Druh plochy</sort-by>
              <sort-by class="cell" [selected]="'indsil7' === list.filter.sortOrder.sortBy" (sort)="onSort('indsil7', $event)" [sortDir]="list.filter.sortOrder.direction">Číslo silnice</sort-by>
              <sort-by class="cell" [selected]="'area' === list.filter.sortOrder.sortBy" (sort)="onSort('area', $event)" [sortDir]="list.filter.sortOrder.direction">Výměra plochy [<span class="unit">m<sup>2</sup></span>]</sort-by>
              <sort-by class="cell" [selected]="'user_name' === list.filter.sortOrder.sortBy" (sort)="onSort('user_name', $event)" [sortDir]="list.filter.sortOrder.direction">Naposledy upravil</sort-by>
              <sort-by class="cell" [selected]="'revision_timestamp' === list.filter.sortOrder.sortBy" (sort)="onSort('revision_timestamp', $event)" [sortDir]="list.filter.sortOrder.direction">Naposledy upraveno</sort-by>
            </div>
          </div>
          <div class="row-group hover" *ngFor="let item of list.list">
            <span class="row intersection">
              <span class="cell">
                <td class="intersection item-{{ item.id }}">
                  <wanted-switch [item]="item" style="line-height:15px"></wanted-switch>
                </td>
              </span>
              <span class="cell">{{ item.division_type_name }}</span>
              <span class="cell">{{ {indsil7: item.indsil7, kodTrKom: item.kod_tr_kom} | roadName }}</span>
              <span class="cell no-padding">
                <cell-area [item]="item" [tooltipType]="'intersection-area'" [tooltipData]="{item: item}"></cell-area>
              </span>

              <span class="cell">{{ item.user_name }}</span>
              <span class="cell">{{ item.revision_timestamp|date:'dd.MM.yyyy' }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <help [helpId]="helpIds.VM_PARCEL_DETAIL" class="cm-help-page"></help>
</div>
