import { Pipe, PipeTransform, Injectable, Inject } from '@angular/core';
import { OrganizationalUnitModel } from '@app/models/organizational-unit.model';
import { APP_BRAND } from '@app/common/services/config.service';

@Pipe({
  name: 'organizationalUnitName'
})
@Injectable({
  providedIn: 'root'
})
export class OrganizationalUnitNamePipe implements PipeTransform {

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {}

  transform(organizationUnit: OrganizationalUnitModel): string {
    if (!organizationUnit) {
      return '';
    }

    const res = [];

    // v mpv obsaženo v názvu
    /*if (organizationUnit.code) {
      res.push(organizationUnit.code);
    }*/

    if (organizationUnit.name) {
      res.push(organizationUnit.name);
    }

    if (!res.length) {
      return '';
    }

    if (this.APP_BRAND.NAME === 'RSD') {
      /*switch (organizationUnit.section) {
        case 'Úsek výstavby':
          res.push('(úv)');
        break;
        case 'Úsek provozní':
          res.push('(úp)');
        break;
      }*/
    }

    return res.join(' ');
  }
}

