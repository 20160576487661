<h1>Podmínění zařazení stavu do kategorie</h1>
<fieldset>
  <div class="column-100" (click)="caseStatus.requiredStatusesNegation = !caseStatus.requiredStatusesNegation"><div class="label">Negace podmínek</div><div class="info"><gmt-checkbox [gmtChecked]="caseStatus.requiredStatusesNegation"></gmt-checkbox></div></div>
  <div class="column-100">
    <div class="label">Stavy</div>
    <div class="input checklist">
      <ng-container *ngFor="let requiredStatus of requiredStatuses">
        <div class="checklist-item cb">
          <div class="mr-10">
            <a class="fa fa-close" (click)="remove(requiredStatus)"></a>&nbsp;
            <gmt-checkbox
              class="item-checkbox"
              [gmtChecked]="requiredStatus.negation"
              title="Negace existence stavu"
              (click)="requiredStatus.negation = !requiredStatus.negation"
            ></gmt-checkbox> {{ requiredStatus.name }}
          </div>
        </div>
      </ng-container>
      <div class="pt-10 cb">
        <gmt-select
          [optionItems]="statuses"
          [reloadOptionsRegister]="reloadRegister"
          (changed)="selectedStatus($event.newValue, $event.data)"></gmt-select>
      </div>
    </div>
  </div>
</fieldset>
<div class="text-center pt-20">
  <gmt-button [clickAction]="onUpdate" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
