import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { KnBudoucPepvModel } from './kn-budouc-pepv.model';
import { KnBudoucModel } from './kn-budouc.model';
import { GeometricPlanRequestModel } from './../../ps/models/geometric-plan-request.model';
import { OpinionRequestModel } from './../../ps/models/opinion-request.model';
import { TitleModel } from './title.model';
import { AreaModel } from './area.model';
import { ParcelDetailModel } from '@app/common/models/parcel-detail.model';
import { BuildingModel } from '@app/common/models/building.model';

export class ParcelModel {
  id: number;
  idpar: number;
  parcisKmen: number;
  parcisPod?: number;
  parcisDil?: number;
  zeKod?: number;
  kndruhp?: string;
  knsk12?: number;
  lv: number;
  vymera: number;
  katuzeKod: number;
  katuzeNazev: string;
  bpejList: any[];
  cislKod: number;
  drupozKod: number;
  drupozNazev: string;
  businessCases: any[];
  buyoutOpinionsShare: number;
  cislNazev: string;
  compensationOpinionsShare: number;
  constructionObjects: any[];
  countCases: number;
  cuzk: string;
  easements: any[];
  future: boolean;
  krajKod: number;
  krajNazev: string;
  maplis: string;
  obecKod: number;
  obecNazev: string;
  occupations: any[];
  parcelDetail: ParcelDetailModel;
  okresKod: number;
  okresNazev: string;
  parType: string;
  parcelProtections: any[];
  soucasti: boolean;
  validBuyoutOpinionBuildings: boolean;
  validBuyoutOpinionLand: boolean;
  validBuyoutOpinionVegetation: boolean;
  validCompensationOpinion: boolean;
  zpurvyKod: number;
  zpurvyNazev: string;
  zpusobVyuziti?: string;
  startDate: string;
  endDate: string;

  parcelProtectionsText?: string;
  constructionObjectsText?: string;

  title?: TitleModel;
  area?: AreaModel;
  casePrice?: number;
  totalPrice?: number;
  geometricPlanRequests?: GeometricPlanRequestModel[];
  opinionRequests?: OpinionRequestModel[];
  knBudouc?: KnBudoucModel;
  knBudoucPepvs?: KnBudoucPepvModel[];
  parcelPrices?: ParcelPriceModel[];
  building?: BuildingModel;
  buildings?: BuildingModel[];
}
