import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { SubjectModel } from '../models/subject.model';
import { PostCodePipe } from '@app/common/pipes/post-code.pipe';

const STATUTORY_CITIES = ['Brno', 'Ostrava', 'Plzeň', 'Ústí nad Labem', 'Pardubice', 'Liberec', 'Opava'];
const CAPITAL_CITY = 'Praha';

export interface AddressSubject {
  obec?: string;
  psc?: string;
  ulice?: string;
  cisloDomovni?: string;
  cisloOrientacni?: string;
  cpCe?: number;
  castObce?: string;
  mestskaCast?: string;
}

@Pipe({
  name: 'address'
})
@Injectable({
  providedIn: 'root'
})
export class AddressPipe implements PipeTransform {

  constructor(
    private postCodePipe: PostCodePipe
  ) {}

  transform(subject: AddressSubject): string {
    let address = '';
    const city = subject.obec;
    const postCode = subject.psc;
    const street = subject.ulice;
    const houseNumber = getHouseNumber(subject);
    const cpCe = subject.cpCe ? subject.cpCe : 1;
    const cityPart = subject.castObce || subject.mestskaCast;
    const postCodeCity = getPostCodeWithCity(subject, this.postCodePipe);

    /*
     * Dle Vyhlášky o základním registru územní identifikace, adres a nemovitostí 359/2011 Sb.
     */

    if (city === CAPITAL_CITY) {
    // 1. v Praze
      if (street) {
        address += street;
      }

      if (cpCe === 2) {
        address = addPartToAddress(address, 'č.e.', ' ');
      }

      if (houseNumber) {
        address = addPartToAddress(address, houseNumber, ' ');
      }

      if (cityPart) {
        address = addPartToAddress(address, cityPart, ', ');
      }

      if (postCode) {
        address = addPartToAddress(address, postCode, ', ');
      }

      if (city || cityPart) {
        address = addPartToAddress(address, city || cityPart, ' ');
      }
    } else if (STATUTORY_CITIES.includes(city) || (street && city && cityPart && city !== cityPart)) {
    // 2. v územně členěném statutárním městě
    // 3. v místě, kde se užívá uliční systém a název obce není shodný s názvem městské části
      if (street) {
        address += street;
      }

      if (cpCe === 2) {
        address = addPartToAddress(address, 'č.e.', ' ');
      }

      if (houseNumber) {
        address = addPartToAddress(address, houseNumber, ' ');
      }

      if (cityPart) {
        address = addPartToAddress(address, cityPart, ', ');
      }

      if (postCodeCity) {
        address = addPartToAddress(address, postCodeCity, ', ');
      }
    } else {
      if ((street && city === cityPart) || (!street && (city && cityPart && city !== cityPart))) {
      // 4. v místě, kde se užívá uliční systém a název obce je shodný s názvem části obce
      // 5. v místě, kde se neužívá uliční systém a název obce a její části nejsou shodné
        if (street || cityPart) {
          address += street || cityPart;
        }

        if (cpCe === 2) {
          address = addPartToAddress(address, 'č.e.', ' ');
        }

        if (houseNumber) {
          address = addPartToAddress(address, houseNumber, ' ');
        }

        if (postCodeCity) {
          address = addPartToAddress(address, postCodeCity, ', ');
        }
      } else if (!street && (city === cityPart || !cityPart)) {
      // 6. v místě, kde se neužívá uliční systém a název obce a její části jsou shodné
        if (houseNumber) {
          address += `${cpCe === 1 ? 'č.p.' : 'č.e.'} ${houseNumber}`;
        }

        if (postCodeCity) {
          address = addPartToAddress(address, postCodeCity, ', ');
        }
      } else {
      // fallback
        if (street) {
          address += street;
        }

        if (!street && houseNumber) {
          address += `${cpCe === 1 ? 'č.p.' : 'č.e.'}`;
        } else if (street && houseNumber && cpCe === 2) {
          address = addPartToAddress(address, 'č.e.', ' ');
        }

        if (houseNumber) {
          address = addPartToAddress(address, houseNumber, ' ');
        }

        if (cityPart) {
          address = addPartToAddress(address, cityPart, ', ');
        }

        if (postCodeCity) {
          address = addPartToAddress(address, postCodeCity, ', ');
        }
      }
    }
    return address;
  }
}

function addPartToAddress(address: string, part: string, sep: string) {
  return address += address.length > 0 ? `${sep}${part}` : `${part}`;
}

function getHouseNumber(subject: AddressSubject): string {
  if (subject.cisloDomovni) {
    if (subject.cisloOrientacni) {
      return `${subject.cisloDomovni.toString()}/${subject.cisloOrientacni}`;
    } else {
      return `${subject.cisloDomovni.toString()}`;
    }
  } else {
    return null;
  }
}

function getPostCodeWithCity(subject: AddressSubject, postCodePipe): string {
  if (subject.psc || subject.obec) {
    return [postCodePipe.transform(subject.psc), subject.obec]
      .filter(elm => elm !== '' && elm !== null && typeof elm !== 'undefined')
      .join(' ');
  } else {
    return null;
  }
}
