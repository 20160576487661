import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { UserNamePipe } from '../../../common/pipes/username.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';

@Component({
  templateUrl: './title-mandatary-change.component.html',
  styleUrls: ['./title-mandatary-change.component.scss']
})
export class TitleMandataryChangeComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  callback = new EventEmitter();
  @Input()
  notifyParent: Function;

  inputData: any = {};
  helpIds = HelpService.HELP_IDS;
  filter: any = {};

  constructor(
    private authService: AuthService,
    public usernamePipe: UserNamePipe,
    @Inject(RESTANGULAR_SETTINGS) public restangular: any,
  ) { }

  ngOnInit() {
    const currentApplication = this.authService.getActiveApplication();
    const currentProject = this.authService.getActualProject().key;
    this.filter = {
      filters: {
        searchText: {
          values: [],
        },
        applications: {
          values: [currentApplication],
        },
        permission: {
          values: ['assignable'],
        },
        projects: {
          values: [currentProject],
        },
      },
    };
    this.inputData = this.data.details || {};
  }

  updated() {
    this.notifyParent(() => !!this.inputData.user, this.inputData);
  }
}
