<h1>{{ administratorId ? 'Editace správce' : 'Založení správce' }} {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</h1>

<div [loading]="loading">
  <div *ngIf="!loading">
    <div>
      <gmt-project-subject [subject]="administrator"></gmt-project-subject>
    </div>

    <div class="actions">
      <gmt-button *ngIf="administrator.id === undefined" [gmtDisabled]="!isValid()" [clickAction]="onCreate">Uložit</gmt-button>
      <gmt-button *ngIf="administrator.id !== undefined" [gmtDisabled]="!isValid()" [clickAction]="onUpdate">Uložit</gmt-button>
    </div>
  </div>
</div>
