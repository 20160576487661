import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[gmtDraggable]'
})
export class DraggableDirective {
  @Output('mousedone') mouseDone: EventEmitter<boolean> = new EventEmitter();
  dragging = false;

  @HostListener('mousedown') onMouseDown() {
    this.setDragging(false);
  }

  @HostListener('mousemove') onMouseMove() {
    this.setDragging(true);
  }

  @HostListener('mouseup') onMouseUp() {
    const wasDragging = this.dragging;
    this.setDragging(false);
    this.mouseDone.emit(wasDragging);
  }

  private setDragging(value: boolean) {
    this.dragging = value;
  }
}
