import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AuthService } from '@app/common/services/auth.service';
import { StringUtils } from '@app/common/utils/string.utils';
import { FileValidatorUtils } from '@app/pk/utils/file-validator.utils';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './create-project-first-step.component.html',
  styleUrls: ['./create-project-first-step.component.scss']
})
export class CreateProjectFirstStepComponent implements OnInit {

  // model
  fileValid = true;
  files: any = [];
  message = CreateProjectFirstStepComponent.MSG_EMPTY;
  analogLandFile: any;
  analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_EMPTY;
  analogLandFileValid = true;
  analogLandFileErrors = [];
  allAnalogLands = false;
  helpIds = HelpService.HELP_IDS;

  // params
  requestId: number;
  keepPreviousGeometry = true;

  private static readonly MSG_EMPTY = `Je možné vložit pouze jeden soubor dxf, vfk nebo shapefile.`;

  private static readonly ANALOG_LAND_ERROR_LIST = {
    TOO_FEW_COLUMNS: 'špatný počet sloupců',
    WRONG_KATUZE: 'špatné katastrální území',
    WRONG_CISL_KOD: 'špatný kód číslování',
    WRONG_PARCELNI_CISLO: 'špatné parcelní číslo',
    WRONG_DIL_PARCELY: 'špatný díl parcely',
    WRONG_PAR_TYPE: 'špatný typ parcely',
    WRONG_ZE_KOD: 'špatný kód ZE',
    WRONG_KATUZE_PUVODNI: 'špatné původní katastrální území',
    WRONG_PARCEL_ID: 'špatný identifikátor parcely',
    PARCEL_NOT_FOUND: 'parcela nebyla nalezena',
    WRONG_CSV_FORMAT: 'Nebyly nalezeny žádné parcely. Ujistěte se, že řádky nejsou uloženy s uvozovkami na začátku (tedy začíná číslem) a je ve formátu katuze_kod;cisl_kod;parcis;parcis_dil;par_type;ze_kod;katuze_kod_puv;id_par'
  };

  private static readonly ANALOG_LAND_MESSAGES = {
    MSG_EMPTY: 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor CSV.',
    MSG_ONLY_ONE: 'Vložte jeden soubor CSV s analogovými parcelami.',
    MSG_VALIDATION: 'Probíhá validace...',
    MSG_OK: 'Soubor s analogovými parcelami je v pořádku.',
    MSG_FAIL: 'V souboru CSV se vyskytují chyby.'
  };

  constructor(
    private restangular: Restangular,
    private authService: AuthService,
    private stateService: StateService
  ) {
    this.onChange = this.onChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
    this.analogLandsFileValidator = this.analogLandsFileValidator.bind(this);
    this.onChangeAnalogLandsFile = this.onChangeAnalogLandsFile.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  ngOnInit() {
    this.requestId = this.stateService.params.requestId;
  }

  onChange(files) {
    this.files = files;
    this.fileValid = FileValidatorUtils.checkValidity(files);

    if (this.fileValid) {
      this.message = FileValidatorUtils.getValidMessage(files);
    } else {
      this.message = FileValidatorUtils.getInvalidMessage(files);
    }

    if (files.length === 0) {
      this.onClearData();
    }
  }

  onChangeAnalogLandsFile(files) {
    if (files.length === 0) {
      this.onClearAnalogLandsFile();
    } else if (files.length > 1) {
      this.analogLandFileErrors = [];
      this.analogLandFileValid = false;
      this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_ONLY_ONE;
    } else {
      this.analogLandFile = files[0];
      this.validateAnalogLandsFile(files[0]);
    }
  }

  validateAnalogLandsFile(file) {
    this.analogLandFileErrors = [];
    this.analogLandFileValid = false;

    if (!this.analogLandsFileValidator(file)) {
      this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_ONLY_ONE;
    } else {
      this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_VALIDATION;

      const formData = new FormData();
      formData.append('ANALOG_PARCELS_CSV', file.fileEntry);

      return this.restangular.all('validate').customPOST(
          formData,
          'analog-parcels',
          undefined,
          {'Content-Type': undefined}
      ).toPromise().then((data) => {
        this.analogLandFileValid = data.valid;
        if (!data.valid) {
          this.analogLandFileErrors = data.results
            .filter(line => line.valid === false)
            .map(line => {
              return {
                line: line.lineNumber,
                error: CreateProjectFirstStepComponent.ANALOG_LAND_ERROR_LIST[line.message]
              };
            });
        }
        this.analogLandMessage = (this.analogLandFileValid
            ? CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_OK
            : CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_FAIL
        );
      });
    }
  }

  analogLandsFileValidator(file) {
    return file.extension.toLowerCase() === 'csv';
  }

  isFormValid() {
    return (
      (this.requestId && this.keepPreviousGeometry)
      ||
      (this.files.length > 0 && this.fileValid && this.analogLandFileValid)
    );
  }

  continue() {
      const formData = new FormData();
      const filesPackage = CreateProjectFirstStepComponent.getUploadFilesPackage(this.files);

      for (const key in filesPackage) {
        formData.append(key, filesPackage[key], filesPackage[key].name);
      }

      if (this.analogLandFile) {
        formData.append('ANALOG_PARCELS_CSV', this.analogLandFile.fileEntry);
      }

      formData.append('ANALOG_ALL', StringUtils.valueToString(this.allAnalogLands));

      const type = CreateProjectFirstStepComponent.getFilesTypes(this.files);
      this.stateService.go('pk.projects.create-project-second', {
        formData: formData,
        type: type,
        requestId: this.requestId
      });
  }

  cancel() {
    this.stateService.go('pk.projects');
  }

  private onClearData() {
    this.fileValid = false;
    this.message = CreateProjectFirstStepComponent.MSG_EMPTY;
    this.files = [];
  }

  private onClearAnalogLandsFile() {
    this.analogLandFile = null;
    this.analogLandFileValid = true;
    this.analogLandFileErrors = [];
    this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_EMPTY;
  }

  private static getUploadFilesPackage(files) {
    const filesPackage = {};

    if (!files.length) {
      return filesPackage;
    }

    for (const file of files) {
      filesPackage[file.extension.toUpperCase()] = file.fileEntry;
    }
    return filesPackage;
  }

  private static getFilesTypes = (files) => {
    if (!files.length) {
      return null;
    }

    if (FileValidatorUtils.checkSHPValidity(files)) {
      return 'SHP';
    } else {
      return files[0].extension.toUpperCase();
    }
  };
}
