import { Component, Input, Inject, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-receive-owner',
	templateUrl: './form-case-receive-owner.component.html',
})
export class FormCaseReceiveOwnerComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  public data: any;
  @Input()
  // data set per case
  private dataCases: any[];
  @Input()
  // list of cases to fill in
  cases: any[];

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
    this.onReceiveDateUpdate(year + "-" + ("00" + month).slice(-2) + "-" + ("00" + day).slice(-2));
  }

  onReceiveDateUpdate(value: string) {
    this.data.receivedOwnerDate = value;
    this.cases.forEach((bCase, i) => {
      if (value) {
        const date = new Date(value);
        this.dataCases[i] = {};
        let newDate;
        if (bCase.obligation.bonusPeriod && bCase.bonusPossible && !bCase.bonusDate) {
          newDate = new Date(date.getFullYear(),date.getMonth(),date.getDate()+bCase.obligation.bonusPeriod);
          this.dataCases[i].bonusDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
        }
        if (bCase.obligation.validityPeriod) {
          newDate = new Date(date.getFullYear(),date.getMonth(),date.getDate()+bCase.obligation.validityPeriod);
          this.dataCases[i].validityDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
        }
      } else if (this.dataCases[i]) {
        this.dataCases[i].validityDate = null;
        if (!bCase.bonusDate) {
          this.dataCases[i].bonusDate = null;
        }
      }
    });
  }

  isValid() {
    return !!this.data.receivedOwnerDate;
  }
}
