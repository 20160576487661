﻿import { Component } from '@angular/core';
import { TooltipAbstractListComponent } from '@app/common/components/tooltip-abstract-list/tooltip-abstract-list.component';

@Component({
  selector: 'tooltip-dimap-ownership',
  templateUrl: './tooltip-dimap-ownerships.component.html',
  styleUrls: ['./tooltip-dimap-ownerships.component.scss'],
})
export class TooltipDimapOwnershipsComponent extends TooltipAbstractListComponent<any> {
  protected resource = 'ownerships/list';
}
