<fieldset class="no-legend">
  <div class="column-100">
    <label class="required">Datum podpisu</label>
    <div class="input"><input type="text" pick-a-date [(ngModel)]="data.signedInvestorDate" [maxToday]="true"/></div>
  </div>
</fieldset>

<fieldset *ngIf="firstCaseId !== undefined">
  <legend>Parametry případu typu {{executionCaseName}}</legend>
  <div *ngIf="caseIndex < cases.length && caseIndex >= 0">
    <div class="column-100">
      <label>Číslo případu</label>
      <div class="info">
        <a uiSref="symap.project.cases.detail" [uiParams]="{id: cases[caseIndex].id}">{{cases[caseIndex].mpp.number || 'bez čísla'}}</a>
      </div>
    </div>
    <div class="column-100">
      <label>Datum finanční kontroly</label>
      <div class="input"><input type="text" pick-a-date [(ngModel)]="cases[caseIndex].financialControlDate"/></div>
    </div>
    <div class="column-100">
      <label>Maximální částka pro dražbu</label>
      <div class="input"><input type="text" [(ngModel)]="cases[caseIndex].maxAuctionAmount"/></div>
    </div>
  </div>
  <div *ngIf="caseIndex===cases.length" class="empty-info">
    Všechny zvolené případy mají zadané povinné údaje. Pokračujte tlačítkem "Další" níže.
  </div>
  <div class="column-100 mt-20" *ngIf="firstCaseId !== undefined">
    <gmt-button [gmtDisabled]="firstCaseId === undefined || caseIndex===firstCaseId" [clickAction]="previous">Předchozí</gmt-button>
    <gmt-button [gmtDisabled]="false" *ngIf="caseIndex<cases.length" [clickAction]="next">Následující</gmt-button>
  </div>
</fieldset>
