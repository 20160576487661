import { Component, Inject, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';

export interface BulkPriceStep {
  id: string;
  name: string;
  enabled: boolean;
  help: string;
}

@Component({
  selector: 'bulk-price',
  templateUrl: './bulk-price.component.html',
  styleUrls: []
})
export class BulkPriceComponent implements OnInit {

  private deregisterLeaveListener: Function;
  private stepsHistory: string[] = [];
  activeStep: BulkPriceStep;
  data: any = {};

  steps: BulkPriceStep[] = [
    { id: 'init', name: 'Nastavení cen', enabled: false, help: '' },
    { id: 'confirm', name: 'Potvrzení', enabled: false, help: '' },
    { id: 'result', name: 'Nahrání', enabled: false, help: '' },
  ];

  helpIds = HelpService.HELP_IDS;

  constructor(
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.onStep('init', true);
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
  }

  private beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
      });
  }

  onStep(id: string, enable = false) {
    const step = this.steps.find(s => s.id === id);

    if (step.enabled === false && enable === false) {
      return;
    }

    // step back
    if (this.stepsHistory.includes(id)) {
      this.activeStep = step;
      this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
      this.steps.forEach(v => v.enabled = this.stepsHistory.includes(v.id));

    // step forward
    } else {
      this.activeStep = step;
      this.stepsHistory.push(id);
      step.enabled = true;

      if (step.id === 'result') {
        this.steps.filter(step => step.id !== 'result').forEach((step) => {
          step.enabled = false;
        });
      }
    }
  }

  onFinish() {
    this.deregisterLeaveListener();
    this.stateService.go('symap.project.projectSettings');
  }
}
