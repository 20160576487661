import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { CommonAddressModel } from '@app/ps/models/common-address.model';
import { Customer } from '@app/models/customer';
import { OrganizationalUnitModel } from '@app/models/organizational-unit.model';
import { SelectItem } from '@app/common/components/select/select.component';
import { CustomerNamePipe } from '@app/common/pipes/customer-name.pipe';
import { SubjectBasicInfoModel } from '@app/ps/models/subject-basic-info.model';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { DialogService } from '@app/common/services/dialog.service';
import { AresFormComponent, AresData } from '@app/ps/components/ares-form/ares-form.component';
import {ListService} from '@app/common/services/list.service';
import { CustomerActingPersonModel } from '@app/models/customer-acting-person.model';
import * as _ from 'lodash';
import { CommonTypeModel } from '@app/common/models/common-type.model';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Component({
  selector: 'customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
  @Input() editable = true;
  @Input() customer: Partial<Customer>;
  @Output() submit = new EventEmitter<Partial<Customer>>();

  private static readonly SECTION_LIST = [ 'Úsek výstavby', 'Úsek provozní' ];
  public ownershipTypeOSSItems = [
    {id: 92, name: 'Příslušnost hospodařit s majetkem státu'},
    {id: 109, name: 'Právo hospodařit s majetkem státu'}];
  public ownershipTypePOItems = [
    {id: 160, name: 'Hospodaření se svěřeným majetkem kraje'},
    {id: 161, name: 'Hospodaření se svěřeným majetkem obce'}];
  public ownershipType: CommonTypeModel;
  backButtonText = 'Seznam objednatelů';
  currentCustomerType: SwitchOption;
  parentCustomer: Partial<Customer>;
  parentCustomerFilter = {
    filters: {
      excludeCustomerIds: {
        values: [],
      },
      includeDepartments: {
        values: ['true']
      },
    },
    sortOrder: [{
      sortBy: 'companyName',
      direction: 'asc',
    }],
  };
  customerTypes: SwitchOption[] = [
    {
      id: 'OPO',
      value: 'Právnická osoba'
    },
    {
      id: 'OPO_OSS',
      value: 'Organizační složka státu'
    },
    {
      id: 'OPO_PO',
      value: 'Příspěvková organizace'
    },
  ];

  address = {} as Partial<CommonAddressModel>;
  basicInfo = {} as Partial<SubjectBasicInfoModel>;
  mailingAddress = {} as Partial<CommonAddressModel>;
  pageTitle: string;

  constructor(
    private dialogService: DialogService,
    private listService: ListService,
    private stateService: StateService,
    @Inject(RESTANGULAR_CONFIGURATION) private restangular,
    public customerNamePipe: CustomerNamePipe,
  ) {
    this.onAresClick = this.onAresClick.bind(this);
    this.onToggleMailingAddress = this.onToggleMailingAddress.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCustomerTypeSelect = this.onCustomerTypeSelect.bind(this);
  }

  ngOnInit() {
    this.pageTitle = this.stateService.current.data.title;
    this.backButtonText = this.stateService.current.data.backButtonText || this.backButtonText;
    this.parentCustomerFilter.filters.excludeCustomerIds.values = this.customer.idText ? [this.customer.idText] : [];
    this.address = CommonAddressModel.fromCustomerAddress(this.customer);
    this.mailingAddress = CommonAddressModel.fromCustomerMailingAddress(this.customer);
    this.currentCustomerType = this.getCustomerType();
    this.basicInfo = this.getBasicInfo();
    this.onAddActing = this.onAddActing.bind(this);
    this.onCancelActing = this.onCancelActing.bind(this);

    this.onIdentificationNumberChange = this.onIdentificationNumberChange.bind(this);

    if (this.customer.type === 'OPO_OSS' && this.customer.ownershipType) {
      this.ownershipType = this.ownershipTypeOSSItems.find(type => type.id === this.customer.ownershipType);
    } else if (this.customer.type === 'OPO_PO' && this.customer.ownershipType) {
      this.ownershipType = this.ownershipTypePOItems.find(type => type.id === this.customer.ownershipType);
    }
  }

  getOptions(type: 'section'): SelectItem[] {

      let list = [];
      switch (type) {
        case 'section':
          list = CustomerFormComponent.SECTION_LIST;
          break;
      }

      return list.map(i => { return { id: i, name: i }; });
    }

  onAresClick() {
    const dialog = this.dialogService.open(AresFormComponent, {
      data: {
        icoToSearch: this.customer.identificationNumber,
        nameToSearch: this.customer.companyName,
      },
    });
    const sub = dialog.afterClosed.subscribe(async (customer: AresData | undefined) => {
      if (customer) {
        const { organizationalUnits, actingPersons, identificationNumber, postCode, subjectType, ...rest } = customer;
        const courtName = customer.businessRegisterName;
        const courtDetail = await this.findBusinessRegisterRegionalCourt(courtName);
        this.customer = { ...this.customer, ...rest, actingPersons: new Array(), organizationalUnits: new Array() };
        this.customer.businessRegisterRegionalCourt = courtDetail || this.customer.businessRegisterRegionalCourt;
        this.customer.identificationNumber = <string><unknown>identificationNumber;
        this.customer.postCode = <string><unknown>postCode;
        this.customer.type = subjectType;
        this.currentCustomerType = this.getCustomerType();
        this.address = { ...CommonAddressModel.fromCustomerAddress(this.customer) };
        this.basicInfo = this.getBasicInfo();
      }
      sub.unsubscribe();
    });
  }

  async onSubmit() {
    const {opsubType, ico: identificationNumber, dic: vatId,  ...basicInfo} = this.basicInfo;
    const mailingAddress = CommonAddressModel.toCustomerMailingAddress(this.mailingAddress);
    const address = CommonAddressModel.toCustomerAddress(this.address);
    const customer = {
      ...this.customer,
      ...address,
      ...mailingAddress,
      ...basicInfo,
      type: this.currentCustomerType.id,
      businessRegisterName: basicInfo.businessRegisterRegionalCourt && basicInfo.businessRegisterRegionalCourt.name,
      vatId,
      identificationNumber,
    };
    customer.deliveryUse = customer.deliveryUse || false;

    const result = this.customer.idText
      ? await this.restangular.one('v2/customers', this.customer.idText).customPUT(customer).toPromise()
      : await this.restangular.all('v2/customers/create').post(customer).toPromise();
    this.submit.emit(result);
  }

  onCustomerTypeSelect(customerType: SwitchOption) {
    this.currentCustomerType = customerType;
    this.basicInfo = {...this.basicInfo, opsubType: <string>customerType.id };
    this.customer.ownershipType = null;
    this.ownershipType = null;
  }

  onIdentificationNumberChange(value: number) {
    this.basicInfo = {...this.basicInfo, ico: value };
  }

  onToggleMailingAddress(event: boolean) {
    this.customer.deliveryUse = event;
  }

  private getBasicInfo() {
    const { businessRegisterEntry, businessRegisterRegionalCourt, businessRegisterSection, identificationNumber, opsubType, vatId: dic, vatPayer } = this.customer;
    return {
      businessRegisterRegionalCourt,
      businessRegisterEntry,
      businessRegisterSection,
      dic,
      ico: this.customer.identificationNumber && parseInt(this.customer.identificationNumber),
      identificationNumber,
      opsubType: opsubType || <string>this.currentCustomerType.id,
      vatPayer,
    };
  }

  private getCustomerType(): SwitchOption {
    return this.customer.type
      ? this.customerTypes.find(customerType => customerType.id === this.customer.type)
      : this.customerTypes[0];
  }

  private async findBusinessRegisterRegionalCourt(courtName: string): Promise<any> {
    if (!courtName) {
      return;
    }
    const filter = {
      limit: 1,
      filters: {
        businessRegister: true,
        searchText: courtName
      }
    };
    const courts = this.listService.createList('regional-courts', filter, this.restangular);
    await this.listService.fetchResult(courts);
    return courts.list && courts.list[0];
  }

  onAddActing() {
    this.customer.actingPersons.push({ cancelled: false } as CustomerActingPersonModel);
  }

  onCancelActing(acting) {
    if (acting.id) {
      acting.cancelled = true;
    } else {
      _.remove(this.customer.actingPersons, a => _.isEqual(a, acting));
    }
  }

  onRestoreActing(acting) {
    acting.cancelled = false;
  }

  onAddUnit() {
    this.customer.organizationalUnits.push({ cancelled: false } as OrganizationalUnitModel);
  }

  onCancelUnit(unit) {
    if (unit.id) {
      unit.cancelled = true;
    } else {
      _.remove(this.customer.organizationalUnits, u => _.isEqual(u, unit));
    }
  }

  onRestoreUnit(unit) {
    unit.cancelled = false;
  }

  onSectionChanged(unit, section) {
    unit.section = section ? section.id : null;
  }
}
