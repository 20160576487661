import { Component, Inject, OnInit } from '@angular/core';
import {StateService, TransitionService, Transition, TargetState} from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { User } from '@app/models/user';
import { MenuConfig } from '@app/models/menuConfig';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { ModulesService } from '@app/common/services/modules.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {
  loading = true;
  collapsed = false;
  user: User;
  servicedeskUrl: string;
  menuConfig: MenuConfig[] = [];
  ENV = this.config.ENV;

  constructor(
    private authService: AuthService,
    private brandTranslatePipe: BrandTranslatePipe,
    private modulesService: ModulesService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private transitionService: TransitionService,
    private stateService: StateService,
    @Inject('$transition$') private transition: Transition,
    @Inject(APP_CONFIG) public config,
    @Inject(APPLICATIONS) private applications,
    private localStorageService: LocalStorageService,
  ) {
  }

  async ngOnInit() {
    await this.authService.checkProjectStatus(this.transition, this.applications.dashboard.name, null, null, 'error')
      .then(
        () => {
          this.loading = false;
        }, (newState: TargetState) => {
          this.stateService.go(newState.name(), newState.params(), newState.options());
        }
      );

    this.authService.setActiveApplication(this.applications.dashboard.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('dashboard');

    this.collapsed = this.localStorageService.get('menuOpened');
    this.user = this.authService.getUser();
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  onLogout() {
    this.authService.logout();
  }

  private getMenuConfig(): MenuConfig[] {
    return [
      new MenuConfig(this.brandTranslatePipe.transform('dashboard.name'), 'dashboard.projects', 'fa-folder-open'),
    ];
  }
}
