import { Component, OnInit, Inject } from '@angular/core';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { AuthService } from '@app/common/services/auth.service';
import { StringUtils } from '@app/common/utils/string.utils';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { APP_CONFIG } from '@app/common/services/config.service';
import {GmtHttpError} from "@app/common/services/http.service";

@Component({
  templateUrl: './import.component.html',
  styleUrls: []
})
export class ImportComponent implements OnInit {

  static readonly MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
  static readonly MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';

  projectName = '';
  files: UploadFileExtended[] = [];
  filesMsg = ImportComponent.MSG_FILES_DEFAULT;
  isFileValidToUpload = false;
  importResponse = '';

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService,
    @Inject(APP_CONFIG) private config: any,
  ) {
    this.onImport = this.onImport.bind(this);
    this.onChangeProjectName = this.onChangeProjectName.bind(this);
  }

  ngOnInit() {
  }

  onImport(oldVersion = false) {
    this.importResponse = '';
    const restBaseUrl = oldVersion ? this.config.BACKEND_OPTIONS.restUrlVFZEOld : this.authService.getActiveApplicationRestUrl();
    const headers = { Authorization: this.authService.getToken() };

    return this.uploadService.upload({
      url: restBaseUrl + '/convert',
      data: {
        vfze: this.files[0].fileEntry,
        project: this.projectName,
      },
      headers
    }).then(response => {
      this.importResponse = `Soubor '${this.files[0].fileEntry.name}' pro projekt '${this.projectName}' byl importován do schema '` + (response.schema || 'app_vfze') + '\'';
      this.files = [];
      this.filesMsg = ImportComponent.MSG_FILES_DEFAULT;
      this.projectName = '';
      this.isFileValidToUpload = false;
    }, (result: GmtHttpError) => {
      if (!oldVersion && result.status === 400 && result.data && result.data.code === 2 && this.config.BACKEND_OPTIONS.restUrlVFZEOld) {
        return this.onImport(true);
      }
      return this.errorHandlerService.get(result);
    });
  }

  onChangeProjectName(projectName: string) {
    this.projectName = StringUtils.removeSpaces(projectName);
  }

  isValidToImport(): boolean {
    return !!(this.isFileValidToUpload && this.projectName);
  }

  onFilesChange(files: UploadFileExtended[]) {
    this.files = files;

    if (this.files.length > 1) {
      this.filesMsg = ImportComponent.MSG_FILES_QUANTITY;
    } else if (this.files.length === 0) {
      this.filesMsg = ImportComponent.MSG_FILES_DEFAULT;
    } else {
      this.filesMsg = '';
    }

    this.isFileValidToUpload = this.files.length === 1;
  }
}
