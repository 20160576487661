﻿import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
/**
 * Display name of acting person
 */
@Pipe({
  name: 'actingPersonName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class ActingPersonNamePipe implements PipeTransform {

  constructor(
    private projectSubjectNamePipe: ProjectSubjectNamePipe,
  ) {}


  transform(subject: any) {
    if (!subject) {
        return null;
    }

    const contacts = [];

    if (subject.email) {
        contacts.push('e-mail: ' + subject.email);
    }

    if (subject.phoneNumber) {
        contacts.push('tel.: ' + subject.phoneNumber);
    }

    const contactsText = contacts.join(', ');

    return this.projectSubjectNamePipe.transform(subject) + (contactsText ? ' (' + contactsText + ')': '');
  }
}
