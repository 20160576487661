<div class="detail">

  <h1>Založení {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</h1>

  <div class="mt-30 mb-70" style="display:flex; align-items: center; justify-content: center;">
    <div style="flex: 1 0 auto; max-width: 700px">
      <div class="column-100" style="overflow: inherit">
        <fieldset>
          <legend>Geometrie stavebního objektu</legend>
          <fileupload class="blue" [inputId]="'hiddenInput1'" (change)="onFilesChange($event)" [message]="message"></fileupload>
        </fieldset>

        <fieldset>
          <legend>Základní údaje</legend>
          <label class="required">Název {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</label>
          <div class="input">
            <input type="text" [(ngModel)]="constructionObject.name"/>
          </div>
          <label class="required">Číslo {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</label>
          <div class="input">
            <input type="text" [(ngModel)]="constructionObject.cislo"/>
          </div>
          <label class="required">{{ 'ETAPA_USEK' | wordtranslate }}</label>
          <div class="input">
            <input type="text" [(ngModel)]="constructionObject.usekEtapa"/>
          </div>
          <label>Termín realizace</label>
          <div class="input">
            <input pick-a-date type="text" [(ngModel)]="constructionObject.realizationDate"/>
          </div>
          <label>Povolující úřad</label>
          <div class="input">
            <input type="text" [(ngModel)]="constructionObject.urad"/>
          </div>
          <label>Data KN na měsíc</label>
          <div class="input">
            <month-picker (date)="onCadastreDeliveryDateChange($event)" [options]="cadastreDeliveryMonthPickerOptions"></month-picker>
          </div>
        </fieldset>

        <fieldset>
          <legend>Správce {{ 'CONSTRUCTION_OBJECT_GENITIV' | wordtranslate }}</legend>
          <div class="column-100">
            <gmt-input label="Typ správce">
              <gmt-switch (select)="onChangeAdministratorType($event)" [options]="administratorTypes" [(selectedId)]="constructionObject.administratorType"></gmt-switch>
            </gmt-input>
          </div>
          <div class="column-100" *ngIf="constructionObject.administratorType == 'A'">
            <label>Správce</label>
            <div class="input">
              <gmt-select
                [reloadOptionsRegister]="reloadAdministratorRegister"
                resource="administrators"
                [data]="this"
                field="administrator"
                [itemPipe]="projectSubjectNamePipe"
                (changed)="onChangeAdministrator()"
              ></gmt-select>
              <a (click)="onUpdateAdministrator()" title="Upravit správce {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }}" *ngIf="constructionObject.administrator" class="button ml-5 not-underline">
                <span class="fa fa-pencil"></span>
              </a>
              <a (click)="onCreateAdministrator()" title="Nový správce {{ 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT' | wordtranslate }}" class="button ml-5">
                <span class="fa fa-plus"></span>
              </a>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Číselná řada</legend>
          <series-list [seriesList]="seriesParsed" [withConstructionObjectSelection]="false"></series-list>
        </fieldset>
      </div>

      <div class="text-center mt-20">
        <gmt-button [gmtDisabled]="!formValid()" [clickAction]="onUpload">Založit</gmt-button>
      </div>
    </div>

    <help [helpId]="helpIds.CONSTRUCTION_OBJECT_NEW_SELECT" class="cm-help-page"></help>
  </div>
</div>
