<div class="list">
  <div class="row">
    <div class="col-6">
      <h1>Uživatelé</h1>
    </div>
    <div class="col-6 text-right">
      <gmt-button [clickAction]="export" class="button"><i class="fa fa-plus-square"></i> Základní export</gmt-button>
      <gmt-button [clickAction]="exportExtended" class="button"><i class="fa fa-plus-square"></i> Rozšířený export</gmt-button>
    </div>
  </div>

  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
    <div class="column-100">
      <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
    </div>
  </filter>

  <div [loading]="list.loading">
    <div class="bordered" *ngIf="list.itemCount">
      <div class="table">
        <div class="head-group sticky">
          <div class="row">
            <span class="cell">
              <sort-by
                [selected]="'surname' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('surname', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Příjmení</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Jméno</sort-by>
            </span>
            <span class="cell" visibleByBrand="mpv">
              <sort-by
                [selected]="'employeePersonalNumber' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('employeePersonalNumber', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Číslo uživatele</sort-by>
            </span>
            <span class="cell" visibleByBrand="mpv">
              <sort-by
                [selected]="'ssoCompanyName' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('ssoCompanyName', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Organizace</sort-by>
            </span>
            <span class="cell" visibleByBrand="maja">
              Organizace
            </span>
            <span class="cell" visibleByBrand="mpv">
              <sort-by
                [selected]="'departmentName' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('departmentName', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Organizační jednotka</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'username' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('username', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Přihlašovací jméno</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'email' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('email', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >E-mail</sort-by>
            </span>
            <span class="cell">
              <sort-by
                [selected]="'lastLogin' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('lastLogin', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Poslední přihlášení</sort-by>
            </span>
          </div>
        </div>
        <div class="row-group">
          <a class="row" *ngFor="let user of list.list" uiSref="landing.users.detail" [uiParams]="{ id: user.id }">
            <span class="cell">{{ user.surname }}</span>
            <span class="cell">{{ user.name }}</span>
            <span class="cell" visibleByBrand="mpv">{{ user.employeePersonalNumber }}</span>
            <span class="cell" visibleByBrand="mpv">{{ user.ssoCompanyName }}</span>
            <span class="cell" visibleByBrand="maja">{{ user.company?.companyName }}</span>
            <span class="cell" visibleByBrand="mpv">{{ user.departmentName }}</span>
            <span class="cell">{{ user.username }}</span>
            <span class="cell">{{ user.email }}</span>
            <span class="cell">{{ user.lastLogin | date:'medium'}}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nebyla nalezena žádná data.</span>
    </div>
  </div>

  <div class="flexbox-container">
    <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"></itemscounter>
    <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)" class="flex1 mt-5 text-center"></pagescounter>
    <div class="page-count-select mt-5" *ngIf="list.itemCount && list.list !== null">
      <span>Záznamů na stránce</span>
      <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
    </div>
  </div>

  <div>
    <pagination
      *ngIf="list.itemCount && list.list !== null"
      [list]="list"
      (callbackRegister)="pageableList.paginationCallbackRegister($event)"
    ></pagination>
  </div>
</div>
