import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sort-by',
  templateUrl: './sortby.component.html',
  styleUrls: ['./sortby.component.scss']
})

export class SortByComponent implements OnInit {
  @Input() selected: boolean;
  @Input() sortDir: string;
  @Output() sort: EventEmitter<string> = new EventEmitter();

  ngOnInit() {}

  onSort() {
    this.sort.emit(this.sortDir);
  }
}
