// @TODO - move to ../common/models...
export class Project {
  id?: number;
  application?: string;
  key?: string;
  name?: string;
  projectKey?: string;
  projectName?: string;
  mapLayers?: any[];
  occupationTypes?: any[];
}
