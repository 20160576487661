import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapUtilsCqlFilterService {
    constructor() {
      this.getFilter = this.getFilter.bind(this);
    }

     getFilter(filterDef) {
      var filter = '';

      if (filterDef && filterDef.type) {
        switch (filterDef.type) {
          case 'Or':
          if(filterDef.args && Array.isArray(filterDef.args)) {
            var orResult = [];
            filterDef.args.forEach((value) => {
              orResult.push( this.getFilter(value));
            });
            filter += orResult.join(' OR ');
          } else {
            filter += '(' +  this.getFilter(filterDef.arg1) + ')';
            filter += ' OR ';
            filter += '(' +  this.getFilter(filterDef.arg2) + ')';
          }
          break;
          case 'And':
          if(filterDef.args && Array.isArray(filterDef.args)) {
            var andResult = [];
            filterDef.args.forEach((value) => {
              andResult.push( this.getFilter(value));
            });
            filter += andResult.join(' AND ');
          } else {
            filter += '(' +  this.getFilter(filterDef.arg1) + ')';
            filter += ' AND ';
            filter += '(' +  this.getFilter(filterDef.arg2) + ')';
          }
          break;
          case 'Not':
          filter += 'NOT (' +  this.getFilter(filterDef.arg) + ')';
          break;
          case 'PropertyIsEqualTo':
          filter += filterDef.propertyName + '=\''+filterDef.literal+'\'';
          break;
          case 'PropertyIsGreaterThan':
          filter += filterDef.propertyName + '>\''+filterDef.literal+'\'';
          break;
          case 'PropertyIsNull':
          filter += filterDef.propertyName + ' IS NULL';
          break;
          case 'BBOX':
          filter += 'BBOX(' + filterDef.propertyName + ', ' + filterDef.coordinates + ')';
          break;
          default:
          console.log('Unsupported filter type: ' + filterDef.type);
        }
      }

      return filter;
  }
}
