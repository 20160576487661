import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { OwnerListComponent } from './components/owner-list/owner-list.component';
import { OwnerDetailComponent } from './components/owner-detail/owner-detail.component';
import { ownerListState, ownerDetailState, problematicOwnerNoteState } from './owners.states';
import { AboutComponent } from './components/tabs/about/about.component';
import { RepresentationComponent } from './components/tabs/representation/representation.component';
import { PsCommonModule } from '../ps-common.module';
import { ProblematicNoteComponent } from './components/problematic-note/problematic-note.component';
import { RepresentativeFormComponent } from './components/forms/representative-form/representative-form.component';
import { BasicInfoFormComponent } from './components/forms/basic-info-form/basic-info-form.component';
import { AddressFormComponent } from './components/forms/address-form/address-form.component';
import { ContactFormComponent } from './components/forms/contact-form/contact-form.component';
import { PaymentFormComponent } from './components/forms/payment-form/payment-form.component';
import { UIRouterModule } from '@uirouter/angular';

const OWNERS_STATES = [ ownerListState, ownerDetailState, problematicOwnerNoteState ];

@NgModule({
  declarations: [
      OwnerListComponent,
      OwnerDetailComponent,
      AboutComponent,
      RepresentationComponent,
      ProblematicNoteComponent,
      RepresentativeFormComponent,
      BasicInfoFormComponent,
      AddressFormComponent,
      ContactFormComponent,
      PaymentFormComponent,
  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [...OWNERS_STATES],
    }),
  ],
  entryComponents: [
    BasicInfoFormComponent,
    ProblematicNoteComponent,
    RepresentativeFormComponent,
  ],
  exports: [
    AddressFormComponent,
    BasicInfoFormComponent,
    ContactFormComponent,
    PaymentFormComponent,
    RepresentativeFormComponent,
    RepresentationComponent,
  ],
})
export class OwnersModule { }
