<div [loading]="loading">
  <ul>
    <li *ngFor="let parcelPrice of data.list.list; index as i">
      <div class="column-100" [ngClass]="{'bordered-top mt-5' : i > 0}">
        <label>Způsob určení ceny</label>
        <div class="cb">{{parcelPrice.opinion.priceType === "E" ? 'Znalecký posudek' : (parcelPrice.opinion.priceType === "O" ? 'Jiný způsob' : '')}}</div>
      </div>
      <div class="column-100" *ngIf="parcelPrice.opinion.priceType === 'E'">
        <label>Číslo znaleckého posudku</label>
        <div class="cb">{{parcelPrice.opinion.expertOpinionNumber}}</div>
        <label>Odhadce</label>
        <div class="cb">{{parcelPrice.opinion.expert | subjectName}}</div>
        <!-- Default ceny ze ZP -->
        <div *ngIf="parcelPrice.opinion.expertOpinionLand">
          <label>Cena pozemek dle ZP</label>
          <div class="cb">{{parcelPrice.expertOpinionLandPriceDefault | number}}</div>
        </div>
        <div *ngIf="parcelPrice.opinion.expertOpinionVegetation">
          <label>Cena porost dle ZP (vedlejší úpravy)</label>
          <div class="cb">{{parcelPrice.expertOpinionVegetationPriceDefault | number}}</div>
        </div>
        <div *ngIf="parcelPrice.opinion.expertOpinionBuildings">
          <label>Cena stavby dle ZP</label>
          <div class="cb">{{parcelPrice.expertOpinionBuildingsPriceDefault | number}}</div>
        </div>

        <!-- Ceny s koeficientem -->
        <div *ngIf="parcelPrice.opinion.expertOpinionLand">
          <label>Cena pozemek</label>
          <div class="cb">{{parcelPrice.expertOpinionLandPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.opinion.expertOpinionVegetation">
          <label>Cena porost (vedlejší úpravy)</label>
          <div class="cb">{{parcelPrice.expertOpinionVegetationPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.opinion.expertOpinionBuildings">
          <label>Cena stavby</label>
          <div class="cb">{{parcelPrice.expertOpinionBuildingsPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.opinion.expertOpinionCompensation">
          <label>Cena náhrady</label>
          <div class="cb">{{parcelPrice.expertOpinionCompensationPrice | number}}</div>
        </div>
      </div>

      <div class="column-100" *ngIf="parcelPrice.opinion.priceType === 'O'">
        <label>Původ ceny</label>
        <div class="cb">{{parcelPrice.opinion.otherSource}}</div>
        <div *ngIf="parcelPrice.otherLandPrice !== null">
          <label>Cena pozemek</label>
          <div class="cb">{{parcelPrice.otherLandPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.otherVegetationPrice !== null">
          <label>Cena porost (vedlejší úpravy)</label>
          <div class="cb">{{parcelPrice.otherVegetationPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.otherBuildingsPrice !== null">
          <label>Cena stavby</label>
          <div class="cb">{{parcelPrice.otherBuildingsPrice | number}}</div>
        </div>
        <div *ngIf="parcelPrice.otherCompensationPrice !== null">
          <label>Cena náhrady</label>
          <div class="cb">{{parcelPrice.otherCompensationPrice | number}}</div>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="data.list.list?.length === 0">Bez určení celkové ceny parcely</div>
</div>
