import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TooltipOwnershipsComponent } from '../tooltip-ownerships/tooltip-ownerships.component';
import { OwnershipModel } from '../../models/ownership.model';
import { CellAbstractComponent, CellTooltipMappingType } from '../cell-abstract/cell-abstract.component';
import { TooltipDimapOwnershipsComponent } from '@app/common/components/tooltip-dimap-ownerships/tooltip-dimap-ownerships.component';
import {SubjectNamePipe} from "@app/common/pipes/subject-name.pipe";

@Component({
  selector: 'cell-ownership',
  templateUrl: './cell-ownership.component.html',
  styleUrls: ['./cell-ownership.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellOwnershipComponent extends CellAbstractComponent implements OnInit {

  // bindings
  @Input() count: number;
  @Input() ownerships: any;
  @Input() limit = 3;

  // model
  filteredOwnerships: OwnershipModel[];

  // props
  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    'ownership-list': TooltipOwnershipsComponent,
    'ownership-dimap-list': TooltipDimapOwnershipsComponent,
  };

  constructor(
    public subjectNamePipe: SubjectNamePipe,
  ) {
    super();
    this.sortByCadastreOwnershipCode = this.sortByCadastreOwnershipCode.bind(this);
  }

  ngOnInit() {
    if (!this.ownerships) {
      return;
    }

    this.filteredOwnerships = this.ownerships
      .map(this.repairStructure)
      .filter(this.isNotCzechRepublicDelegatedOwnership)
      .sort(this.sortByCadastreOwnershipCode);
  }

  /**
   * Repair structure if ownership is nested... For example cases endpoint returns nested structure.
   * @param ownership
   */
  private repairStructure(ownership: any): OwnershipModel {
    return (ownership.ownership ? ownership.ownership : ownership);
  }

  /**
   * Proper ownership codes are represented with smaller numbers
   * than delegated ownership codes.
   */
  private sortByCadastreOwnershipCode(a: OwnershipModel, b: OwnershipModel): number {
    return b.typravKod - a.typravKod || this.subjectNamePipe.transform(a.subjects[0]).localeCompare(this.subjectNamePipe.transform(b.subjects[0]));
  }

  /**
   * The Czech Republic is not a subject to deal with
   * when its ownership is delegated to one of the state organizations.
   */
  private isNotCzechRepublicDelegatedOwnership(ownership: OwnershipModel): boolean {
    const CADASTRE_OWNERSHIP_CODE = 30;
    const isOwner = ownership.typravKod === CADASTRE_OWNERSHIP_CODE;
    const isSingleSubject = ownership.subjects.length === 1;
    const isCzechRepublic = ownership.subjects[0].nazev === 'Česká republika';

    return !(isOwner && isSingleSubject && isCzechRepublic);
  }
}
