import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { PricingExpertOpinionStepComponent } from '@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component';
import { AuthService } from '@app/common/services/auth.service';
import {
  ExpertOpinionCoefficient,
  ExpertOpinionCoefficientsFlatten, ParcelPriceCoefficient,
  ParcelPriceModel
} from '@app/ps/models/parcel-price.model';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { ParcelUtils } from '@app/common/utils/parcel.utils';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import { ParcelPriceUtils } from '@app/ps/utils/parcel-price.utils';

@Component({
  selector: 'pricing-expert-opinion-parcels',
  templateUrl: './pricing-expert-opinion-parcels.component.html',
  styleUrls: ['./pricing-expert-opinion-parcels.component.scss']
})
export class PricingExpertOpinionParcelsComponent extends PricingExpertOpinionStepComponent implements OnInit {

  private static readonly DEFAULT_PRICE = {
    expertOpinionLandSquarePrice: 0,
    expertOpinionLandAreaPrice: 0,

    expertOpinionLandPrice: 0,
    expertOpinionLandPriceDefault: 0,

    expertOpinionVegetationPrice: 0,
    expertOpinionVegetationPriceDefault: 0,

    expertOpinionCompensationPrice: 0,
    expertOpinionCompensationPriceDefault: 0,

    expertOpinionBuildingsPrice: 0,
    expertOpinionBuildingsPriceDefault: 0,

    expertOpinionParcelPriceDefault: 0,
  };

  checkedAll = false;
  sortOrder: any = {};

  pricingMethodOptions: SwitchOption[] = [
    { id: 'M', value: 'Cena za metr čtvereční pozemku' },
    { id: 'A', value: 'Cena za pozemek' },
  ];

  private parcelsFilter: any;
  private buildingsFilter: any;
  private parcelPricesFilter: any;

  constructor(
    private restangular: Restangular,
    private numericPipe: NumericPipe,
    protected authService: AuthService,
  ) {
    super(authService);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  async ngOnInit() {
    this.data.opinion.expertOpinionPricingMethod = this.data.opinion.expertOpinionPricingMethod || 'M';

    if (!this.data.parcelPrices) {
      this.setupFilters();
      this.data.opinion.id ? await this.loadOpinionPricing() : await this.loadParcelPrices();
    }

    if (!this.data.opinion.id && !this.data.updated) {
      this.data.parcelPrices.forEach(parcelPrice => {
        this.populateBaseLandPrice(parcelPrice);
        // this.computeParcelPrice(parcelPrice);
      });
    }

    this.loading = false;
  }

  isFormValid() {
    return this.data.readonly || (this.data.opinion.parcelPrices.length && this.data.opinion.parcelPrices.every(this.isPriceRowValid, this));
  }

  onBack() {
    this.backCallback.emit();
  }

  onSubmit() {
    this.submitCallback.emit();
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.data.opinion.parcelPrices.splice(0);

    if (!this.checkedAll) {
      return;
    }

    this.data.parcelPrices
      .filter(this.isParcelPriceEnabled, this)
      .forEach((item) => {
        this.data.opinion.parcelPrices.push(item);
      });
  }

  toggleParcelPrice(parcelPrice: ParcelPriceModel) {
    if (this.isParcelPriceEnabled(parcelPrice)) {
      this.data.checklistParcelPrices.toggleSelection(parcelPrice);
    }

    this.checkedAll = (this.data.checklistParcelPrices.checkedItems.length === this.data.parcelPrices.filter(this.isParcelPriceEnabled, this).length);
  }

  toggleMultiply(parcelPrice: ParcelPriceModel, type: 'compensation' | 'vegetation' | 'buildings') {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    // turn off coefficient
    if (coefficients[type + 'Multiply']) {
      coefficients[type + 'Coefficient'] = this.COEFFICIENT_1;

    // turn on coefficient - try to set automatically same as on land
    } else {
      coefficients[type + 'Coefficient'] = coefficients.land15Multiply ? this.COEFFICIENT_1_5 : this.COEFFICIENT_8;
    }

    coefficients[type + 'Multiply'] = !coefficients[type + 'Multiply'];
  }

  toggleCoefficient(parcelPrice: ParcelPriceModel, type: 'compensation' | 'vegetation' | 'buildings', coefficient: number) {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;
    if (coefficients[type + 'Coefficient'] === coefficient) {
      coefficients[type + 'Coefficient'] = this.COEFFICIENT_1;
    } else {
      coefficients[type + 'Coefficient'] = coefficient;
    }

    coefficients[type + 'Multiply'] = coefficients[type + 'Coefficient'] > this.COEFFICIENT_1;
  }

  isParcelPriceEnabled(parcelPrice: ParcelPriceModel): boolean {
    const hasLand = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionLand) || !this.data.opinion.expertOpinionLand;
    const hasVegetation = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionVegetation) || !this.data.opinion.expertOpinionVegetation;
    const hasBuilding = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionBuildings) || !this.data.opinion.expertOpinionBuildings;
    const isBuilding = parcelPrice.building && (!parcelPrice.building.validBuyoutOpinionBuildings || !this.data.opinion.expertOpinionBuildings);
    return (hasLand && hasVegetation && hasBuilding) || isBuilding;
  }

  isPriceRowValid(p: ParcelPriceModel): boolean {
    const coefficients = p.expertOpinionCoefficientsFlatten;

    const isLandPriceValid = ((p.expertOpinionLandSquarePrice || p.expertOpinionLandSquarePrice === 0) && this.data.opinion.expertOpinionLand) || !this.data.opinion.expertOpinionLand || !p.parcel;
    const isVegetationPriceValid = ((p.expertOpinionVegetationPriceDefault || p.expertOpinionVegetationPriceDefault === 0) && this.data.opinion.expertOpinionVegetation) || !this.data.opinion.expertOpinionVegetation || !p.parcel;
    const isBuildingPriceValid = ((p.expertOpinionBuildingsPriceDefault || p.expertOpinionBuildingsPriceDefault === 0) && this.data.opinion.expertOpinionBuildings) || !this.data.opinion.expertOpinionBuildings;
    const isCompensationPriceValid = ((p.expertOpinionCompensationPriceDefault || p.expertOpinionCompensationPriceDefault === 0) && this.data.opinion.expertOpinionCompensation) || !this.data.opinion.expertOpinionCompensation;

    let landCoefficientValid = coefficients.land15Multiply || coefficients.land8Multiply || coefficients.land1Multiply;
    if (ParcelPriceUtils.multipleLandCoefficient(p)) {
      landCoefficientValid = coefficients.land1Area + coefficients.land8Area + coefficients.land15Area === p.parcel.vymera;
    }

    const isCoefficientValid = (!this.hasCoefficient || landCoefficientValid || p.parcel === undefined || !this.data.opinion.expertOpinionLand);

    if (this.data.occupationType === 'P') {
      return isLandPriceValid && isVegetationPriceValid && isBuildingPriceValid && isCoefficientValid;
    }

    return isCompensationPriceValid && isCoefficientValid;
  }

  populateBaseLandPrice(parcelPrice: ParcelPriceModel) {
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (this.data.opinion.expertOpinionPricingMethod === 'A') {
       parcelPrice.expertOpinionLandSquarePrice = parcelPrice.parcel && parcelPrice.parcel.vymera ? parcelPrice.expertOpinionLandAreaPrice / parcelPrice.parcel.vymera : 0;

       coefficients.land1SquarePrice = parcelPrice.parcel && coefficients.land1Price && coefficients.land1Area ? coefficients.land1Price / coefficients.land1Area : 0;
       coefficients.land15SquarePrice = parcelPrice.parcel && coefficients.land15Price && coefficients.land15Area ? coefficients.land15Price / coefficients.land15Area : 0;
       coefficients.land8SquarePrice = parcelPrice.parcel && coefficients.land8Price && coefficients.land8Area ? coefficients.land8Price / coefficients.land8Area : 0;
    } else {
      parcelPrice.expertOpinionLandAreaPrice = Math.round(
        parcelPrice.parcel && parcelPrice.parcel.vymera
            ? this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * parcelPrice.parcel.vymera
            : 0
      );

      coefficients.land1Price = parcelPrice.parcel && coefficients.land1Area && coefficients.land1SquarePrice ? Math.round(coefficients.land1Area * coefficients.land1SquarePrice) : 0;
      coefficients.land15Price = parcelPrice.parcel && coefficients.land15Area && coefficients.land15SquarePrice ? Math.round(coefficients.land15Area * coefficients.land15SquarePrice) : 0;
      coefficients.land8Price = parcelPrice.parcel && coefficients.land8Area && coefficients.land8SquarePrice ? Math.round(coefficients.land8Area * coefficients.land8SquarePrice) : 0;
    }
  }

  computeParcelPrice(parcelPrice: ParcelPriceModel) {
    this.data.updated = true;
    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    parcelPrice.expertOpinionParcelPriceDefault = 0;

    // Land price
    if (this.data.opinion.expertOpinionLand && parcelPrice.parcel) {
      // If multiple coefficients, then compute land price
      if (ParcelPriceUtils.multipleLandCoefficient(parcelPrice)) {
        parcelPrice.expertOpinionLandAreaPrice = ParcelPriceUtils.computeLandPriceFromMultiple(parcelPrice);
        parcelPrice.expertOpinionLandSquarePrice = parcelPrice.parcel && parcelPrice.parcel.vymera ? parcelPrice.expertOpinionLandAreaPrice / parcelPrice.parcel.vymera : 0;
      }

      const squarePrice = this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
      const landPriceCoefficient = ParcelPriceUtils.computeLandPriceCoefficient(parcelPrice);
      parcelPrice.expertOpinionParcelPriceDefault = Math.round(squarePrice * parcelPrice.parcel.vymera);
      parcelPrice.expertOpinionLandPriceDefault = Math.round(squarePrice * parcelPrice.parcel.vymera);
      parcelPrice.expertOpinionLandPriceCoefficient = landPriceCoefficient;
      parcelPrice.expertOpinionLandPrice = landPriceCoefficient;
    } else {
      parcelPrice.expertOpinionLandSquarePrice = null;
      parcelPrice.expertOpinionLandAreaPrice = null;
    }

    // Compensation price
    if (this.data.opinion.expertOpinionCompensation) {
      const compensationPrice = this.numericPipe.transform(parcelPrice.expertOpinionCompensationPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += compensationPrice;
      parcelPrice.expertOpinionCompensationPrice = coefficients.compensationCoefficient !== this.COEFFICIENT_1
        ? Math.round(compensationPrice * coefficients.compensationCoefficient)
        : compensationPrice;
    } else {
      parcelPrice.expertOpinionCompensationPrice = null;
      parcelPrice.expertOpinionCompensationPriceDefault = null;
    }

    // Vegetation price
    if (this.data.opinion.expertOpinionVegetation) {
      const vegetationPrice = this.numericPipe.transform(parcelPrice.expertOpinionVegetationPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += vegetationPrice;
      parcelPrice.expertOpinionVegetationPrice = coefficients.vegetationCoefficient !== this.COEFFICIENT_1
        ? Math.round(vegetationPrice * coefficients.vegetationCoefficient)
        : vegetationPrice;
    } else {
      parcelPrice.expertOpinionVegetationPrice = null;
      parcelPrice.expertOpinionVegetationPriceDefault = null;
    }

    // Buildings price
    if (this.data.opinion.expertOpinionBuildings) {
      const buildingsPrice = this.numericPipe.transform(parcelPrice.expertOpinionBuildingsPriceDefault) || 0;
      parcelPrice.expertOpinionParcelPriceDefault += buildingsPrice;
      parcelPrice.expertOpinionBuildingsPrice = coefficients.buildingsCoefficient !== this.COEFFICIENT_1
        ? Math.round(buildingsPrice * coefficients.buildingsCoefficient)
        : buildingsPrice;
    } else {
      parcelPrice.expertOpinionBuildingsPrice = null;
      parcelPrice.expertOpinionBuildingsPriceDefault = null;
    }

    // Store configuration
    parcelPrice.expertOpinionCoefficients = ExpertOpinionCoefficientsFlatten.toCoefficients(coefficients);
  }

  setSortData(sortBy) {
    this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
    // every first sort will be sorted 'asc'
    if (this.sortOrder.sortBy !== sortBy) {
      this.sortOrder.direction = 'asc';
    }
    this.sortOrder.sortBy = sortBy;
  }

  onSortParcel() {
    const sortFn = (item) => {
      let parcel = item.parcel;
      if (item.building) {
        parcel = item.building.parcels[0];
      }
      return ParcelUtils.getParcelSortNumber(parcel.parcisKmen, parcel.parcisPod, parcel.parcisDil);
    };

    this.setSortData('parcel');
    this.data.parcelPrices = _.orderBy(
      this.data.parcelPrices,
      sortFn,
      this.sortOrder.direction
    );
  }

  multipleLandCoefficient(parcelPrice: ParcelPriceModel) {
    return ParcelPriceUtils.multipleLandCoefficient(parcelPrice);
  }

  isUsedCoefficient(coefficients: ExpertOpinionCoefficient[]) {
    return coefficients.some(coefficient => coefficient.multiply !== false);
  }

  getCoefficientName(type: 'land' | 'compensation' | 'vegetation' | 'buildings') {
    switch (type) {
      case 'land':
        return 'Pozemek';
      case 'buildings':
        return 'Budova';
      case 'compensation':
        return 'Náhrada';
      case 'vegetation':
        return 'Porost';
      default:
        return '';
    }
  }

  private loadParcelPrices(): Promise<any> {
    this.data.checklistParcelPrices = new ChecklistModel(this.data.opinion.parcelPrices);

    return Promise.all(this.prepareApiCalls())
      .then(res => {
        this.data.parcelPrices = [];

        res[0].forEach((parcel: any) => {
          const item = {
            parcel: parcel,
            expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
          };

          this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
        });

        res[1].forEach((building) => {
          const doesBuildingMatch = this.data.parcelPrices.find(parcelPrice => ParcelPriceUtils.buildingsMatch(building, parcelPrice));

          const item = {
            building: building,
            expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
          };

          if (!doesBuildingMatch && this.data.occupationType === 'P') {
            this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
          }
        });

        // filter parcelPrices by parcels and buildings from opinion request
        if (this.data.opinionRequest) {
          this.data.parcelPrices = this.data.parcelPrices.filter((item) => {
            return this.data.opinionRequest.parcels.find(e => item.parcel.id === e.id) || this.data.opinionRequest.buildings.find(e => item.building.id === e.id);
          });
        }

        this.checkAll();
      });
  }

  private loadOpinionPricing(): Promise<any> {
    return this.restangular
      .all('parcel-prices')
      .customPOST({ filter: this.parcelPricesFilter })
      .toPromise()
      .then(data => {
        this.data.parcelPrices = [];
        data = data.plain();
        this.data.parcelPrices = this.data.parcelPrices.concat(data);
        data.forEach(this.loadParcelPrice, this);
        this.data.checklistParcelPrices = new ChecklistModel(this.data.opinion.parcelPrices);
      })
      .then(() => {
        if (this.data.readonly) {
          return;
        }

        Promise.all(this.prepareApiCalls()).then((res) => {
          res[0].forEach((parcel) => {
            const doesParcelMatch = this.data.parcelPrices.find(parcelPrice => ParcelPriceUtils.parcelsMatch(parcel, parcelPrice));
            const item = {
              parcel: parcel,
              expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
            };

            if (!doesParcelMatch) {
              this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
            }
          });

          res[1].forEach((building) => {
            const doesBuildingMatch = this.data.parcelPrices.find(parcelPrice => ParcelPriceUtils.buildingsMatch(building, parcelPrice));
            const item = {
              building: building,
              expertOpinionCoefficientsFlatten: new ExpertOpinionCoefficientsFlatten(),
            };

            if (!doesBuildingMatch) {
              this.data.parcelPrices.push(<ParcelPriceModel>Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
            }
          });
        });
      });
  }

  private loadParcelPrice(parcelPrice: ParcelPriceModel) {
    const expertOpinionVegetationPriceDefault = (parcelPrice.expertOpinionVegetationPriceDefault && this.data.opinion.expertOpinionVegetation ? parcelPrice.expertOpinionVegetationPriceDefault : 0);
    const expertOpinionBuildingsPriceDefault = (parcelPrice.expertOpinionBuildingsPriceDefault && this.data.opinion.expertOpinionBuildings ? parcelPrice.expertOpinionBuildingsPriceDefault : 0);
    const expertOpinionCompensationPriceDefault = (parcelPrice.expertOpinionCompensationPriceDefault && this.data.opinion.expertOpinionCompensation ? parcelPrice.expertOpinionCompensationPriceDefault : 0);
    const expertOpinionLandPriceDefault = (parcelPrice.expertOpinionLandPriceDefault && this.data.opinion.expertOpinionLand ? parcelPrice.expertOpinionLandPriceDefault : 0);

    parcelPrice.expertOpinionParcelPriceDefault = expertOpinionLandPriceDefault + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault + expertOpinionCompensationPriceDefault;
    // parcelPrice.expertOpinionParcelPriceDefaultRounded = (parcelPrice.expertOpinionLandPriceDefault || 0) + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault + expertOpinionCompensationPriceDefault;
    parcelPrice.expertOpinionCoefficientsFlatten = ExpertOpinionCoefficientsFlatten.fromCoefficients(parcelPrice.expertOpinionCoefficients);
    parcelPrice.expertOpinionLandAreaPrice = Math.round(
      parcelPrice.parcel && parcelPrice.parcel.vymera
        ? this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * parcelPrice.parcel.vymera
        : 0
    );

    if (parcelPrice.parcel) {
      parcelPrice.parcel.validBuyoutOpinionLand = !this.data.opinion.expertOpinionLand && parcelPrice.parcel.validBuyoutOpinionLand;
      parcelPrice.parcel.validBuyoutOpinionVegetation = !this.data.opinion.expertOpinionVegetation && parcelPrice.parcel.validBuyoutOpinionVegetation;
      parcelPrice.parcel.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.parcel.validBuyoutOpinionBuildings;
    } else {
      parcelPrice.building.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.building.validBuyoutOpinionBuildings;
    }

    this.data.opinion.parcelPrices.push(parcelPrice);
  }

  private prepareApiCalls(): any[] {
    const loadParcels = this.restangular.all('parcels').customPOST({ filter: this.parcelsFilter }).toPromise();
    const loadBuildings = this.restangular.all('buildings').customPOST({ filter: this.buildingsFilter }).toPromise();
    return [loadParcels, loadBuildings];
  }

  private setupFilters() {
    this.parcelsFilter = {
      filters: {
        titleId: [ this.data.title.id ],
        validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
      },
    };
    this.parcelsFilter.filters[ (this.data.occupationType === 'P' ? 'permanent' : 'temporary') + 'OccupationExists' ] = [ true ];

    this.buildingsFilter = {
      filters: {
        occupationOrEasementExists: [ true ],
        titleId: [ this.data.title.id ],
        validBuyoutOpinion: [ false ],
        validity: [ 'valid' ],
        differentTitle: [ true ],
        loadCollections: [
          'parcelsWithEasementOrOccupation',
          'buildingProtections',
          'parcels.parcelProtections',
        ],
      },
    };

    this.parcelPricesFilter = {
      filters: {
        opinionId: [ this.data.opinion.id ],
        validity: ['valid'],
        loadCollections: [
          'building.parcelsWithEasementOrOccupation',
          'building.buildingProtections',
          'building.parcels.parcelProtections',
        ],
      },
    };
  }
}
