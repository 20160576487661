import { Component } from '@angular/core';

import { ParcelModel } from '@app/common/models/parcel.model';
import { TooltipAbstractListComponent } from '../tooltip-abstract-list/tooltip-abstract-list.component';

@Component({
  selector: 'tooltip-parcels',
  templateUrl: './tooltip-parcels.component.html',
})
export class TooltipParcelsComponent extends TooltipAbstractListComponent<ParcelModel> {
  protected resource = 'parcels';

  ngOnInit() {
    super.ngOnInit();
  }

  getDoneParcels(): ParcelModel[] {
    return this.data.list && this.data.list.list.filter((p: ParcelModel) => this.data.customData.doneParcelIds.some(i => i.parcelId === p.id));
  }

  getNotStartedParcels(): ParcelModel[] {
    return this.data.list && this.data.list.list.filter((p: ParcelModel) => this.data.customData.notStartedParcelIds.some(i => i.parcelId === p.id));
  }

  getInProgressParcels(): ParcelModel[] {
    return this.data.list && this.data.list.list.filter((p: ParcelModel) => this.data.customData.inProgressParcelIds.some(i => i.parcelId === p.id));
  }
}

