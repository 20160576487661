import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapFeatureFilterService {

  constructor() { }

  /**
   * Creates OR filter for a list of property values
   * @param attrName
   * @param values
   * @returns {*}
   */
  propertyOr(attrName, values) {
      var filter = null;
      values.forEach(function (value) {
          var operand = {
              type: 'PropertyIsEqualTo',
              propertyName: attrName,
              literal: value
          };
          if (filter === null) {
              filter = operand;
          } else {
              filter = {
                  type: 'Or',
                  arg1: filter,
                  arg2: operand
              };
          }
      });
      return filter;
  }

  /**
   * Joins statements into And statement
   * @param values
   * @returns {*}
   */
  statementsAnd(values) {
      var filter = null;
      values.forEach(function (value) {
          if (filter === null) {
              filter = value;
          } else {
              filter = {
                  type: 'And',
                  arg1: filter,
                  arg2: value
              };
          }
      });
      return filter;
  }

  /**
   * Joins statements into Or statement
   * @param values
   * @returns {*}
   */
  statementsOr(values) {
      var filter = null;
      values.forEach(function (value) {
          if (filter === null) {
              filter = value;
          } else {
              filter = {
                  type: 'Or',
                  arg1: filter,
                  arg2: value
              };
          }
      });
      return filter;
  }

  propertyEquals(property, value) {
      return {
          type: 'PropertyIsEqualTo',
          propertyName: property,
          literal: value
      };
  }

  not(value) {
      return {
          type: 'Not',
          arg: value
      };
  }

  propertyIsNull(property) {
      return {
          type: 'PropertyIsNull',
          propertyName: property
      };
  }
}

