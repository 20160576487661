import { Inject, Injectable } from '@angular/core';
import { MapStorageHelpersService } from '@app/map/services/map-storage-helpers.service';

@Injectable({
  providedIn: 'root'
})
export class MapStorageService {
  map = {};

  constructor(
    private mapStorageHelpersService: MapStorageHelpersService
  ) { }

   setMap(m, id) {
      var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.map ,id);
      defer.resolve(m);
      this.mapStorageHelpersService.setResolvedDefer(this.map, id);
  }

   getMap(id) {
      var defer = this.mapStorageHelpersService.getDefer(this.map, id);
      return defer.promise;
  }

   resetMap(id){
      this.map[id] = undefined;
  }
}

