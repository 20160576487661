<fieldset>
  <legend *ngIf="legend">{{ legend }}</legend>
  <div class="column-100">
      <gmt-input *ngIf="isPublished" label="Datum zveřejnění" [required]="required">
        <input type="text" pick-a-date [(ngModel)]="data.contractRegisterPublicationDate"/>
      </gmt-input>

    <ng-container *ngIf="isPublished">
      <gmt-input label="ID smlouvy" [required]="required">
        <input type="text" [(ngModel)]="data.contractRegisterPublicationContractId"/>
      </gmt-input>

      <gmt-input label="ID verze" [required]="required">
        <input type="text" [(ngModel)]="data.contractRegisterPublicationVersionId"/>
      </gmt-input>
    </ng-container>

    <gmt-input *ngIf="!isPublished" label="Důvod nezveřejnění" [required]="required">
      <gmt-select
        [optionItems]="options"
        [data]="contractsRegister"
        field="publicationException"
        required="true"
        (changed)="onPublicationExceptionSelect($event)"
      >
      </gmt-select>
    </gmt-input>
  </div>
</fieldset>
