import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import {
  landingDiState,
  landingSyState, modulesState,
  promapLandingState,
  vfzeLandingState
} from '@app/settings/modules/modules.states';
import { LandingSyComponent } from '@app/settings/modules/components/landing-sy/landing-sy.component';
import { LandingPkComponent } from '@app/settings/modules/components/landing-pk/landing-pk.component';
import { LandingVfzeComponent } from '@app/settings/modules/components/landing-vfze/landing-vfze.component';
import { CadastreDataImportComponent } from '@app/settings/modules/components/cadastre-data-import/cadastre-data-import.component';
import { CadastreDataImportFormComponent } from '@app/settings/modules/components/cadastre-data-import-form/cadastre-data-import-form.component';
import { CadastreDataImportListComponent } from '@app/settings/modules/components/cadastre-data-import-list/cadastre-data-import-list.component';
import { CadastreDataReportComponent } from '@app/settings/modules/components/cadastre-data-report/cadastre-data-report.component';
import { CadastreDataRequestComponent } from '@app/settings/modules/components/cadastre-data-request/cadastre-data-request.component';
import { ChangelogEditComponent } from '@app/settings/modules/components/changelog-edit/changelog-edit.component';
import { ChangelogListComponent } from '@app/settings/modules/components/changelog-list/changelog-list.component';
import { CadastreUpdateComponent } from '@app/settings/modules/components/cadastre-update/cadastre-update.component';
import { MainComponent } from '@app/settings/modules/components/main/main.component';
import { LandingDiComponent } from '@app/settings/modules/components/landing-di/landing-di.component';
import { UIRouterModule } from '@uirouter/angular';


const STATES = [
  modulesState, landingDiState, landingSyState, promapLandingState, vfzeLandingState,
];

@NgModule({
  declarations: [
    LandingDiComponent,
    LandingSyComponent,
    LandingPkComponent,
    LandingVfzeComponent,
    CadastreDataImportComponent,
    CadastreDataImportFormComponent,
    CadastreDataImportListComponent,
    CadastreDataReportComponent,
    CadastreDataRequestComponent,
    CadastreUpdateComponent,
    ChangelogEditComponent,
    ChangelogListComponent,
    MainComponent,
  ],
  entryComponents: [
    LandingDiComponent,
    LandingSyComponent,
    LandingPkComponent,
    LandingVfzeComponent,
    ChangelogEditComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [...STATES],
    }),
  ],
})
export class ModulesModule { }
