import { NgModule } from '@angular/core';
import { ExportFormComponent } from '@app/ps/exports/components/export-form/export-form.component';
import { CommonModule } from '@app/common/common.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { ExportListComponent } from '@app/ps/exports/components/export-list/export-list.component';

export const exportListState: Ng2StateDeclaration = {
  name: 'symap.project.exports',
  url: '/exports',
  data: {
    title: 'Exporty',
  },
  views: {
    'content@symap.project': {
      component: ExportListComponent,
    },
  },
};

@NgModule({
  declarations: [
    ExportFormComponent,
    ExportListComponent,
  ],
  entryComponents: [

  ],
  imports: [
    CommonModule,
    PsCommonModule,
    UIRouterModule.forChild({
      states: [
        exportListState,
      ],
    }),
  ]
})
export class ExportsModule { }
