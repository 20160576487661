/**
 * Main map configuration
 */

import * as _ from 'lodash';

import { Project } from '@app/models/project';

const layers = [
  {
    id: 'main',
    type: 'node',
    title: 'Další mapy',
    visible: true,
    selector: 'group',
    layers: [
      'dtm_osm_overview',
      'base_orto',
      'base_zm',
      'sy_base_lpis',
      'parcel_pupfl',
      'parcel_zpf',
      'geom_km',
      'base_additional_project_2',
      'base_additional_project',
      'base_project',
    ]
  },
  {
    id: 'km',
    type: 'node',
    title: 'Katastrální mapy',
    selector: 'group',
    visible: true,
    layers: [
      'base_pk',
      'base_kn',
      'kn_occupation',
      'geom_gp',
      'gp_parcels',
      'geom_ku',
      'kn_latest',
      'kn_zab',
    ]
  },
  {
    id: 'stav-reseni',
    type: 'node',
    title: 'Majetkoprávní stav',
    selector: 'group',
    visible: true,
    layers: [
      'easement_status2',
      'temporary_occupation_over_one_year_status',
      'temporary_occupation_under_one_year_status',
      'permanent_occupation_status',

      'parcel_investor',
      'occupation_problem_status',
      'problematic_owners',
      'easement_expropriation',
      'occupation_expropriation',
    ]
  },
  {
    id: 'vecna-bremena',
    type: 'node',
    title: 'Věcná břemena',
    selector: 'group',
    visible: true,
    layers: [
      'easement_geom_land_take',
      'easement_geom_geometric_plan',
      'geom_vbze'
    ]
  },
  {
    id: 'zabory',
    type: 'node',
    title: 'Zábory',
    selector: 'group',
    visible: true,
    layers: [
      'occupation_acquirer',
      'geom_ochrpas',
      'construction_object',
      'occupation_permanent_other',
      'occupation_permanent_investor',
      'occupation_type_41',
      'occupation_type_31',
      'occupation_type_113',
      'occupation_type_112',
      'occupation_type_10',
      'occupation_type_9',
      'occupation_type_8',
      'occupation_type_7',
      'occupation_type_6',
      'occupation_type_5',
      'occupation_type_4',
      'occupation_type_3',
      'occupation_type_2',
      'occupation_type_1',
    ]
  }
];

export function getLayersConfig(project: Project) {
  const config = {
    id: 'main-map',
    tools: [ 'info', 'measure' ],
    layers,
  };

  const layersConfig = _.cloneDeep(config);

  return layersConfig;
}
