import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { OwnersModule } from '@app/ps/owners/owners.module';
import { CasesModule } from '@app/ps/cases/cases.module';
import { PsCommonModule } from '@app/ps/ps-common.module';
import {
  createCaseState,
  constructionObjectListState,
  constructionObjectDetailState,
  constructionObjectDetailUpdateState,
  updateGlobalEasementPrice,
  constructionObjectImportedListState,
  constructionObjectCreateState,
  easementCreateState
} from './construction-objects.states';

import { CreateCaseComponent } from './components/create-case/create-case.component';
import { CreateCaseSelectObligationComponent } from './components/create-case-select-obligation/create-case-select-obligation.component';
import { CreateCaseSelectAreaComponent } from './components/create-case-select-area/create-case-select-area.component';
import { CreateCaseSelectEasementComponent } from './components/create-case-select-easement/create-case-select-easement.component';
import { CreateCaseSelectParentOwnershipComponent } from './components/create-case-select-parent-ownership/create-case-select-parent-ownership.component';
import { CreateCasePriceComponent } from './components/create-case-price/create-case-price.component';
import { CreateCaseConfirmComponent } from './components/create-case-confirm/create-case-confirm.component';
import { CreateCaseSummaryComponent } from './components/create-case-summary/create-case-summary.component';
import { CreateCasePriceCaseOwnershipComponent } from './components/create-case-price-case-ownership/create-case-price-case-ownership.component';
import { ConstructionObjectListComponent } from './components/construction-object-list/construction-object-list.component';
import { ConstructionObjectDetailComponent } from './components/construction-object-detail/construction-object-detail.component';
import { ConstructionObjectUpdateComponent } from './components/construction-object-update/construction-object-update.component';
import { EasementUpdateGlobalPriceComponent } from './components/easement-update-global-price/easement-update-global-price.component';
import { ConstructionObjectImportedListComponent } from './components/construction-object-imported-list/construction-object-imported-list.component';
import { ConstructionObjectCreateComponent } from './components/construction-object-create/construction-object-create.component';
import { EasementCreateComponent } from './components/easement-create/easement-create.component';
import { EasementCreateAddFilesComponent } from './components/easement-create-add-files/easement-create-add-files.component';
import { EasementCreateInfoComponent } from './components/easement-create-info/easement-create-info.component';
import { EasementCreateSummaryComponent } from './components/easement-create-summary/easement-create-summary.component';
import { EasementCreateParcelRowComponent } from './components/easement-create-parcel-row/easement-create-parcel-row.component';
import { UIRouterModule } from '@uirouter/angular';

const STATES = [
  createCaseState,
  constructionObjectListState,
  constructionObjectDetailState,
  constructionObjectDetailUpdateState,
  updateGlobalEasementPrice,
  constructionObjectImportedListState,
  constructionObjectCreateState,
  easementCreateState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...STATES],
    }),
    CommonModule,
    OwnersModule,
    CasesModule,
    PsCommonModule,
  ],
  providers: [],
  declarations: [
    CreateCaseComponent,
    CreateCaseSelectObligationComponent,
    CreateCaseSelectAreaComponent,
    CreateCaseSelectEasementComponent,
    CreateCaseSelectParentOwnershipComponent,
    CreateCasePriceComponent,
    CreateCaseConfirmComponent,
    CreateCaseSummaryComponent,
    CreateCasePriceCaseOwnershipComponent,
    ConstructionObjectListComponent,
    ConstructionObjectDetailComponent,
    ConstructionObjectUpdateComponent,
    ConstructionObjectImportedListComponent,
    ConstructionObjectCreateComponent,
    EasementUpdateGlobalPriceComponent,
    EasementCreateComponent,
    EasementCreateAddFilesComponent,
    EasementCreateInfoComponent,
    EasementCreateSummaryComponent,
    EasementCreateParcelRowComponent,
  ],
  entryComponents: [
    EasementUpdateGlobalPriceComponent,
    EasementCreateComponent,
  ]
})
export class ConstructionObjectsModule {}
