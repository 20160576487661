import { Component, OnInit, Inject } from '@angular/core';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { OrganizationalUnitModel } from '@app/models/organizational-unit.model';
import { AlertComponent } from '@app/common/components/alert/alert.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DialogService } from '@app/common/services/dialog.service';
import { SwitchOption } from '@app/common/components/switch/switch.component';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

export interface AresData {
  organizationalUnits: OrganizationalUnitModel[];
  actingPersons: ActingPersonModel[];
  businessRegisterName: string;
  businessRegisterSection: string;
  businessRegisterEntry: string;
  companyName: string;
  country: string;
  houseNumber: number;
  identificationNumber: number;
  municipality: string;
  neighbourhood: string;
  district: string;
  postCode: number;
  quarter: string;
  street: string;
  streetNumber: string;
  subjectType: 'OPO' | 'OPO_PO' | 'OPO_OSS';
  vatId: string;
  vatPayer: true;
}

@Component({
  selector: 'ares-form',
  templateUrl: './ares-form.component.html',
  styleUrls: ['./ares-form.component.scss']
})
export class AresFormComponent implements OnInit {
  options: SwitchOption[] = [
    {
      id: 'search-by-id',
      value: 'Podle IČO',
    },
    {
      id: 'search-by-name',
      value: 'Podle názvu',
    }];
  currentOption: SwitchOption;
  loading = false;
  icoToSearch: string;
  nameToSearch: string;

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private dialogService: DialogService,
    private errorHandlerService: ErrorHandlerService,
    @Inject(RESTANGULAR_CONFIGURATION) private restangular: any,
  ) { }

  ngOnInit() {
    this.currentOption = this.options[0];
    this.icoToSearch = (<DialogConfigData>this.dialogConfig.data).icoToSearch;
    this.nameToSearch = (<DialogConfigData>this.dialogConfig.data).nameToSearch;

    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.showFailedNotification = this.showFailedNotification.bind(this);
  }

  handleSelectOption(option: SwitchOption) {
    this.currentOption = option;
  }

  async handleSearch() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const companies = await this.restangular
        .all('ares')
        .customGET(this.currentOption.id, { search: this.getSearchedValue() })
        .toPromise();
      const result = companies.items && companies.items[0];
      if (!result) {
        this.showFailedNotification('Subjekt nebyl nalezen');
        return;
      }

      this.dialogRef.close(result);
    } catch (e) {
      this.errorHandlerService.get(e);
    } finally {
      this.loading = false;
    }
  }

  private showFailedNotification(msg: string) {
    this.dialogService.open(AlertComponent, {
      className: ClassName.ADJUSTED_DIALOG,
      data: {
        msg,
      },
    });
  }

  private getSearchedValue(): string {
    return this.currentOption.id === 'search-by-id'
      ? this.icoToSearch
      : this.nameToSearch;
  }
}
