import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, Inject } from '@angular/core';
import { MapService } from '@app/map/services/map.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import * as L from 'leaflet';
import * as _ from 'lodash';
import { MapHighlightService } from '@app/map/services/map-highlight.service';
import { MapLayerService } from '@app/map/services/map-layer.service';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { LocalStorageService } from 'angular-2-local-storage';
import { FormatType, MapPrintData, MapPrintResult, MeasureType, PrintType } from '@app/ps/map/models/map-print.model';

@Component({
  selector: 'map-module-print',
  templateUrl: './map-module-print.component.html',
  styleUrls: ['./map-module-print.component.scss']
})
export class MapModulePrintComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() mapId: string;
  @Input() printData: MapPrintData;
  @Input() config: any;
  @Output() visibilityInfoboxCallback =  new EventEmitter();

  readonly MAX_DENOMINATOR = 10000;

  entity: any;
  open = true;
  map;
  rect;
  printType: PrintType;
  formatType: FormatType;
  measureType: MeasureType;
  editing = false;
  editingFrom;

  private printReloadOptionRegister: Function;
  private formatReloadOptionRegister: Function;
  private measureReloadOptionRegister: Function;

  printTypes: PrintType[] = [];
  formatTypes: FormatType[] = [];
  measureTypes: MeasureType[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private mapService: MapService,
    private mapUtilsCrsService: MapUtilsCrsService,
    private mapLayerService: MapLayerService,
    private mapHighlightService: MapHighlightService,
  ) {
    this.onItemSelected = this.onItemSelected.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.onEditingModeStart = this.onEditingModeStart.bind(this);
    this.onEditingModeStop = this.onEditingModeStop.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.onEditingProgress = this.onEditingProgress.bind(this);
    this.onEditingStop = this.onEditingStop.bind(this);

    this.setPrintReloadOptionRegister = this.setPrintReloadOptionRegister.bind(this);
    this.setFormatReloadOptionRegister = this.setFormatReloadOptionRegister.bind(this);
    this.setMeasureReloadOptionRegister = this.setMeasureReloadOptionRegister.bind(this);
    this.recalculateRect = this.recalculateRect.bind(this);
    this.setupLayers = this.setupLayers.bind(this);
  }

  ngOnInit() {
    this.visibilityInfoboxCallback.emit({ infoboxVisibility: true });
    this.mapService.getMap(this.mapId).then(map => {
      this.map = map;
      this.map.on('move', this.recalculateRect);
    });

    const highlightedItem = this.mapHighlightService.getItem(this.mapId);
    this.entity = highlightedItem ? this.printData.onSelectItemFilter(highlightedItem) : undefined;
    this.mapHighlightService.addSubscriber('mapPrint', this.onItemSelected);

    this.printTypes = this.printData.printTypes;
    this.formatTypes = this.printData.formatTypes;
    this.measureTypes = this.printData.measureTypes;
  }

  ngOnDestroy() {
    if (this.rect) {
      this.rect.remove();
    }

    this.map.dragging.enable();

    this.map.off('move', this.recalculateRect);
    this.map.off('mousedown', this.onEditingStart);
    this.map.off('mousemove', this.onEditingProgress);
    this.map.off('mouseup', this.onEditingStop);
  }

  onPrintTypeChange(printType) {
    this.printType = printType;

    if (this.printType) {
      if (this.printType.custom) {
        this.formatType = this.formatTypes.find(f => f.id === this.printType.format);
        this.measureType = this.printType.measureType;
        this.setupLayers(this.printType.customLayers.map(l => l.id));
      } else {
        this.formatType = this.formatTypes.find(f => f.id === this.printType.format);
        this.measureType = this.measureTypes.find(m => m.id === this.printType.measure);
      }
    } else {
      this.formatType = undefined;
      this.measureType = undefined;
    }
    this.formatReloadOptionRegister();
    this.measureReloadOptionRegister();
    this.recalculateRect();
  }

  onFormatTypeChange(formatType) {
    this.formatType = formatType;
    this.printType = this.printTypes.find(p => this.formatType && this.measureType && p.format === this.formatType.id && p.measure === this.measureType.id);
    this.printReloadOptionRegister();
    this.recalculateRect();
  }

  onMeasureTypeChange(measureType, oldMeasureType) {
    this.measureType = measureType;

    if (measureType.id === 'custom' && oldMeasureType !== undefined) {
      this.measureType.denominator = oldMeasureType.denominator;
    }

    this.printType = this.printTypes.find(p => this.formatType && this.measureType && p.format === this.formatType.id && p.measure === this.measureType.id);
    this.printReloadOptionRegister();
    this.recalculateRect();
  }

  onPrint() {
    if (this.printData.onPrintFunction) {
      const crs = this.mapUtilsCrsService.getCrs('5514');
      const center = this.map.getCenter();
      const centerJtsk = crs.project(center);
      const bbJtsk = this.getRectBoundingBox(centerJtsk);
      const layers = this.getActiveLayers();

      this.printData.onPrintFunction({
        entity: this.entity,
        printType: this.printType,
        measureType: this.measureType,
        formatType: this.formatType,
        boundingBox: bbJtsk,
        layers: layers
      } as MapPrintResult);
    }
  }

  onDelete(printType) {
    this.printType = undefined;
    this.formatType = undefined;
    this.measureType = undefined;
    if (this.printData.onCustomPrintRemoveFunction) {
      this.printData.onCustomPrintRemoveFunction(printType);
    }
    _.remove(this.printTypes, p => p.id === printType.id);
    this.storeCustomPrints();
    this.printReloadOptionRegister();
  }

  onEditingModeStart() {
    this.editing = true;
    this.map.dragging.disable();
    this.map.on('mousedown', this.onEditingStart);
    this.map.on('mousemove', this.onEditingProgress);
    this.map.on('mouseup', this.onEditingStop);
  }

  onEditingModeStop() {
    this.editing = false;
    this.map.dragging.enable();
    this.map.off('mousedown', this.onEditingStart);
    this.map.off('mousemove', this.onEditingProgress);
    this.map.off('mouseup', this.onEditingStop);
  }

  onItemSelected(item: any) {
    this.entity = item ? this.printData.onSelectItemFilter(item) : undefined;
  }

  private onEditingStart(e) {
    this.editingFrom = e.latlng;
  }

  private onEditingProgress(e) {
    if (this.editingFrom) {
      this.recalculateDenominator(this.map.getCenter(), e.latlng, this.map.getZoom());
      this.recalculateRect();
    }
  }

  private onEditingStop() {
    this.editingFrom = undefined;
  }

  setPrintReloadOptionRegister(reloadFn: Function) {
    this.printReloadOptionRegister = reloadFn;
  }

  setFormatReloadOptionRegister(reloadFn: Function) {
    this.formatReloadOptionRegister = reloadFn;
  }

  setMeasureReloadOptionRegister(reloadFn: Function) {
    this.measureReloadOptionRegister = reloadFn;
  }

  recalculateRect() {
    if (this.rect) {
      this.rect.remove();
    }

    if (!(this.measureType && this.formatType)) {
      return;
    }

    if (this.measureType.denominator > this.MAX_DENOMINATOR) {
      this.measureType.denominator = this.MAX_DENOMINATOR;
    }

    const crs = this.mapUtilsCrsService.getCrs('5514');
    const center = this.map.getCenter();
    const centerJtsk = crs.project(center);
    const bbJtsk = this.getRectBoundingBox(centerJtsk);
    const coordinates = this.getCoordinatesFromBoundingBox(bbJtsk, crs);
    this.rect = L.polygon(coordinates, {color: '#ce06ba', weight: 2, dashArray: [5, 5], fillOpacity: 0}).addTo(this.map);
  }

  private getRectBoundingBox(center) {
    const dx = (this.formatType.width / 1000) * this.measureType.denominator / 2;
    const dy = (this.formatType.height / 1000) * this.measureType.denominator / 2;

    return {
      southWest: this.moveCoordinates(center.x, center.y, dx, dy, - 1, -1),
      northEast: this.moveCoordinates(center.x, center.y, dx, dy, 1, 1),
    };
  }

  private recalculateDenominator(center, editingTo, zoom) {
    const from = this.editingFrom;
    const to = editingTo;

    const fromCenterDistance = this.getPointDistance(center.lat, center.lng, from.lat, from.lng);
    const toCenterDistance = this.getPointDistance(center.lat, center.lng, to.lat, to.lng);
    const fromToDistance = this.getPointDistance(from.lat, from.lng, to.lat, to.lng);
    const direction =  fromCenterDistance > toCenterDistance ? -2 : 2;
    const newDenominator = (((this.formatType.width / 1000)  * this.measureType.denominator) + (direction * fromToDistance)) / (this.formatType.width / 1000);

    this.measureType.denominator = Math.floor(Math.abs(newDenominator));
    this.editingFrom = editingTo;
  }

  private moveCoordinates(x, y, dx, dy, dxt, dyt) {
    return {
      x: x + (dxt * dx),
      y: y + (dyt * dy),
    };
  }

  private getCoordinatesFromBoundingBox(bbJtsk, crs) {
    const corner1Jtsk = { x: bbJtsk.southWest.x, y: bbJtsk.southWest.y }; // levy spodni
    const corner2Jtsk = { x: bbJtsk.northEast.x, y: bbJtsk.southWest.y }; // pravy spodni
    const corner3Jtsk = { x: bbJtsk.northEast.x, y: bbJtsk.northEast.y }; // pravy horni
    const corner4Jtsk = { x: bbJtsk.southWest.x, y: bbJtsk.northEast.y }; // levy horni

    const corner1 = crs.unproject(corner1Jtsk);
    const corner2 = crs.unproject(corner2Jtsk);
    const corner3 = crs.unproject(corner3Jtsk);
    const corner4 = crs.unproject(corner4Jtsk);

    return [
      [corner1.lat, corner1.lng],
      [corner2.lat, corner2.lng],
      [corner3.lat, corner3.lng],
      [corner4.lat, corner4.lng],
      [corner1.lat, corner1.lng],
    ];
  }

  private getPointDistance(lat1, lon1, lat2, lon2) {
    const r = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return r * c * 1000;
  }

  private storeCustomPrints() {
    this.localStorageService.set('mapCustomPrintTypes', this.printTypes.filter(p => p.custom));
  }

  private getActiveLayers(): any[] {
    const layers = [];

    // groups
    for (const group of this.config.layers) {
      // layers
      for (const layer of group.layers) {
        // node
        if (layer.type === MapLayerTypeEnum.node && layer.layers) {
          for (const nestedLayer of layer.layers) {
            if (nestedLayer.visible) {
              layers.push(this.mapLayer(nestedLayer, layer));
            }
          }
          // layer
        } else if (layer.visible) {
          layers.push(this.mapLayer(layer));
        }
      }
    }

    return layers;
  }

  /**
   * Set all layerIds visible, other hide
   */
  private setupLayers(layerIds) {
    // groups
    for (const group of this.config.layers) {
      // layers
      for (const layer of group.layers) {
        // node
        if (layer.type === MapLayerTypeEnum.node && layer.layers) {
          for (const nestedLayer of layer.layers) {
            if (layerIds.includes(nestedLayer.id)) {
              nestedLayer.visible = true;
              this.mapLayerService.addLayerToMap(this.mapId, nestedLayer, nestedLayer.id);
            } else {
              nestedLayer.visible = false;
              this.mapLayerService.removeLayerFromMap(this.mapId, nestedLayer.id);
            }
          }
          layer.visible = layer.layers.some(l => l.visible);
        // layer
        } else {
          if (layerIds.includes(layer.id)) {
            layer.visible = true;
            this.mapLayerService.addLayerToMap(this.mapId, layer, layer.id);
          } else {
            layer.visible = false;
            this.mapLayerService.removeLayerFromMap(this.mapId, layer.id);
          }
        }
      }
    }
  }

  private mapLayer(layer: any, parentLayer?: any) {
    return {
      id: layer.id,
      visible: true,
      title: (parentLayer ? (parentLayer.title + ' - ') : '') + layer.title,
      icon: layer.icon,
      style: layer.style
    };
  }
}
