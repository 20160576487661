import { Component, Input } from '@angular/core';

import { ParcelModel } from '../../models/parcel.model';

export interface TooltipParcelComponentData {
  parcel: ParcelModel;
  withoutTitle?: boolean;
}

@Component({
  selector: 'tooltip-parcel',
  templateUrl: './tooltip-parcel.component.html',
  styleUrls: ['./tooltip-parcel.component.scss']
})
export class TooltipParcelComponent {
  @Input() data: TooltipParcelComponentData;
}
