import { ChangeDetectorRef, Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';

@Component({
  selector: 'gmt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Output() callback = new EventEmitter();
  @Input() clickAction: Function;
  @Input() gmtDisabled: boolean;
  processing = false;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.callback.emit({doAction: this.doAction});
  }

  isDisabled(): boolean {
    return this.gmtDisabled || this.processing;
  }

  /**
   * Runs underlying action and checks its state.
   *
   * @param {object} event
   * @returns {void}
   */
  doAction(event) {
    if (typeof this.clickAction !== 'function') {
      return;
    }
    event.stopPropagation();
    if (this.isDisabled()) {
      return;
    }

    const actionPromise = this.clickAction();

    // is promise?
    if (actionPromise && !!actionPromise.finally && typeof actionPromise.finally === 'function') {
      this.processing = true;
      actionPromise.finally(() => {
        this.processing = false;
        this.cdr.markForCheck();
      }).catch(this.errorHandlerService.get);
    }
  }
}
