import {Injectable, Pipe, PipeTransform} from '@angular/core';
@Pipe({ name: 'customerContractName' })
@Injectable({
  providedIn: 'root'
})
export class IsprofondPipe implements PipeTransform {
  transform(isprofond: any) {
    return (isprofond ? (isprofond.isprofond + (isprofond.actionName ? (' - ' + isprofond.actionName) : '')) : '');
  }
}
