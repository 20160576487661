<gmt-tabbed-block [tabs]="notificationFilter" [selected]="notificationFilterSelectionVisible.id" (updated)="onNotificationFilterChanged($event)">
  <div class="notifications">
    <div [loading]="notificationList.loading">
      <div class="bordered" *ngIf="!notificationList.itemCount && notificationList.list !== null">
        <div class="empty-info">
          <span>Žádná upozornění</span>
        </div>
      </div>

      <div *ngIf="notificationList.itemCount">
        <a *ngIf="!readonlyState && canModifyAllNotifications()" (click)="onCheckAllNotifications()" class="mr-5">
          <gmt-checkbox gmtType="multiselect" [gmtChecked]="checkedAllNotifications"></gmt-checkbox>
        </a>
        <a (click)="onChangeNotificationsOrder()" class="button">
          Seřadit dle data <span class="fa {{notificationList.filter.sortOrder && notificationList.filter.sortOrder.direction === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'}}"></span>
        </a>
      </div>

      <perfect-scrollbar *ngIf="notificationList.itemCount" class="result" [wheelPropagation]="true" (callbackRegister)="onCanScrollTop($event)">
        <ul>
          <li *ngFor="let notification of notificationList.list" [ngClass]="{'with-warning': notification.withWarning, selected: isNotificationChecked(notification)}" (click)="onToggleNotificationSelection(notification, $event)">
            <div class="notification-actions">
              <a *ngIf="notification.user && canModify(notification) && this.APP_BRAND.NAME !== 'RSD'" (click)="onDeleteNotification([notification])">Smazat</a>
              <a *ngIf="notification.user && !notification.archived && canModify(notification)" (click)="onArchiveNotification([notification])">Archivovat</a>
            </div>
            <div *ngIf="notification.user" class="check">
              <gmt-checkbox gmtType="multiselect" [gmtChecked]="isNotificationChecked(notification)" [gmtDisabled]="!canModify(notification)"></gmt-checkbox>
            </div>
            <div class="date">
              {{notification.createdTimestamp | date}}
            </div>
            <div class="type">
              {{notification.type == "cadastre_update" ? 'Aktualizace KN' : ''}}
              {{notification.type == "case_update" ? 'Změna obchodního případu' : ''}}
              {{notification.type == "case_status" ? 'Stav případu' : ''}}
              {{notification.type == "easements_update" || notification.type == "occupation_update" ? 'Vstupní data' : ''}}
              {{(notification.type === 'construction_object_status' ? 'CONSTRUCTION_OBJECT' : '') | wordtranslate}}
            </div>
            <div class="message">
              <div *ngIf="notification.type === 'occupation_update' && (notification.subtype === 'occupation_change' || notification.subtype === 'occupation_ended')">
                Aktualizací záborového elaborátu došlo ke {{notification.subtype === 'occupation_change' ? 'změně' : 'zrušení'}} záboru č. {{notification.data.occupationNumber}}, na parcele č. <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a>, v kú {{notification.parcel.katuzeNazev}}. Na {{notification.subtype === 'occupation_change' ? 'změněný' : 'zrušený'}} zábor je navázán případ č. <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>. Případ nyní obsahuje NEPLATNÁ DATA.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'validated_future_parcel'">
                Byla zplatněna parcela budoucí <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a> (GP č. <span *ngIf="!notification.geometricPlanRequest">{{notification.knBudouc.knGp.cis}}</span><a *ngIf="notification.geometricPlanRequest" uiSref="symap.project.geometricPlanRequests.detail" [uiParams]="{ id: notification.geometricPlanRequest.id, tab: 'parcels-budouc' }">{{notification.knBudouc.knGp.cis}}</a>) v kú {{notification.parcel.katuzeNazev}}, vedená na smlouvě <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>. Smlouva je navázaná na nový stav parcely.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'parcel_lv'">
                Zjištěna změna LV v kú {{notification.title.area.name}} u parcely <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a><span *ngIf="notification.businessCase">, dotčené případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span>.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && (notification.subtype === 'parcel_geometry_vymera' || notification.subtype === 'parcel_vymera') && notification.withWarning">
                Zjištěna změna{{notification.subtype !== 'parcel_vymera' ? ' geometrie' : ''}}{{notification.subtype === 'parcel_geometry_vymera' ? ' a' : ''}} výměry parcely <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a> v kú {{notification.title.area.name}}<span *ngIf="notification.businessCase">, dotčené případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span><span *ngIf="!notification.businessCase"><span *ngIf="notification.data?.occupations?.length || notification.data?.easements?.length" class="comma-list">,
                            na které se nachází
                            <span *ngIf="notification.data?.occupations?.length > 0">
                                zábor{{notification.data.occupations.length > 1 ? 'y' : ''}} č.
                                <span class="comma-list"><span *ngFor="let occ of notification.data.occupations">{{occ.cislo}} (<span class="comma-list"><span *ngFor="let o of occ.occupations">{{o.typNazev}}</span></span>)</span></span>
                            </span>
                            <span *ngIf="notification.data?.easements?.length">
                                VB pro
                                <span class="comma-list"><span *ngFor="let eas of notification.data.easements">{{'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate}} {{eas.cisloSo}} - {{eas.nazevSo}}</span></span>
                            </span>
                          </span>
                        </span>.{{(notification.subtype === 'parcel_vymera' ? ' Geometrie parcely nezměněna.' : '')}}
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'parcel_ended'">
                Zjištěna neexistující parcela číslo {{notification.parcel|parcelnumber}} v kú {{notification.parcel.katuzeNazev}}
                <span *ngIf="notification.title">na LV <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.title.lv}}</a></span>
                <span *ngIf="notification.businessCase">, dotčené případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span>
                <span *ngIf="notification.data?.occupations?.length || notification.data?.easements?.length" class="comma-list">,
                      na které se nachází
                      <span *ngIf="notification.data?.occupations?.length > 0">
                              NEPLATN{{notification.data.occupations.length > 1 ? 'É' : 'Ý'}} ZÁBOR{{notification.data.occupations.length > 1 ? 'Y' : ''}} č.
                              <span class="comma-list"><span *ngFor="let occ of notification.data.occupations">{{occ.cislo}} (<span class="comma-list"><span *ngFor="let o of occ.occupations">{{o.typNazev}}</span></span>)</span></span>.
                              Aktualizujte záborový elaborát.
                      </span>

                      <span *ngIf="notification.data.easements?.length">
                              VB pro
                              <span class="comma-list"><span *ngFor="let eas of notification.data.easements">{{'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate}} {{eas.cisloSo}} - {{eas.nazevSo}}</span></span></span></span>.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'parcel_future_wrong_title'">
                V kú {{notification.title.area.name}} zjištěna změna LV parcely <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a>, ze které podle GP č. {{notification.knBudouc.knGp.cis}} vzniká
                nová parcela <a *ngIf="notification.knBudouc.title?.id" uiSref="symap.project.titles.detail" [uiParams]="{id: notification.knBudouc.title.id, tab: null}">{{notification.knBudouc.parcel|parcelnumber}}</a><span *ngIf="!notification.knBudouc.title?.id">{{notification.knBudouc.kncis}}</span>
                <span *ngIf="notification.businessCase">, dotčené případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span>
                <span *ngIf="!notification.businessCase"><span *ngIf="notification.data?.occupations?.length || notification.data?.easements?.length" class="comma-list">,
                          na které se nachází
                          <span *ngIf="notification.data?.occupations?.length > 0">
                              zábor{{notification.data.occupations.length > 1 ? 'y' : ''}} č.
                              <span class="comma-list"><span *ngFor="let occ of notification.data.occupations">{{occ.cislo}} (<span class="comma-list"><span *ngFor="let o of occ.occupations">{{o.typNazev}}</span></span>)</span></span></span><span *ngIf="notification.data?.easements?.length">
                              VB pro
                              <span class="comma-list"><span *ngFor="let eas of notification.data.easements">{{'CONSTRUCTION_OBJECT_SHORTCUT'|wordtranslate}} {{eas.cisloSo}} - {{eas.nazevSo}}</span></span></span></span></span>.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === null">
                Provedena aktualizace dat KN s platností k datu {{notification.cadastreDataDate|date:'d.M.yyyy'}}
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'ownership_podil'">
                Zjištěna změna podílu vlastnictví na LV <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.title.lv}}</a> v kú {{notification.title.area.name}} u subjektu {{notification.ownership.isBsm ? 'SJM ' : ''}}
                <span class="comma-list"><a *ngFor="let subject of notification.ownership.subjects" uiSref="symap.project.owners.detail" [uiParams]="{ownerId: subject.id, tab: 'about'}">{{subject | subjectName}}</a></span><span *ngIf="notification.businessCase">, dotčeného případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span>.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && (notification.subtype === 'ownership_ended')">
                Zjištěn zánik podílu vlastnictví na LV <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.title.lv}}</a> v kú {{notification.title.area.name}}<ng-container *ngIf="notification.ownership"> u subjektu {{notification.ownership.isBsm ? 'SJM ' : ''}}</ng-container>
                <span class="comma-list" *ngIf="notification.ownership"><a *ngFor="let subject of notification.ownership.subjects" uiSref="symap.project.owners.detail" [uiParams]="{ownerId: subject.id, tab: 'about'}">{{subject | subjectName}}</a></span><span *ngIf="notification.businessCase">, dotčeného případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a></span>.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'delivery_to_be_expired'">
                Termín pro doručení návrhu smlouvy č. <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší za {{notification.data.deliveryDeadlineDate|daysdiff:notification.createdTimestamp}}. Vlastník {{notification.data.detail}}
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'delivery_expired'">
                Vypršel termín pro doručení návrhu smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>. Vlastník {{notification.data.detail}}
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'dunning_letter_expired'">
                Vypršel termín pro reakci na urgenci <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>. Vlastník {{notification.data.detail}}
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'call_before_expropriation_received_expired'">
                Vypršel termín pro reakci na výzvu před vyvlastněním <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>. Vlastník {{notification.data.detail}}
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'to_be_undelivered'">
                Zaslanému návrhu smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší za {{notification.businessCase.validityDate|daysdiff:notification.createdTimestamp}}
                termín doručení podepsaného návrhu vlastníkem<span *ngIf="notification.data.detail"> ({{notification.data.detail}})</span>
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'undelivered'">
                Zaslanému návrhu smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vypršel termín pro doručení podepsaného návrhu vlastníkem<span *ngIf="notification.data.detail"> ({{notification.data.detail}})</span>
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'bonus_to_be_expired'">
                Možnost akceleračního příplatku pro smlouvu <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší za {{notification.businessCase.bonusDate|daysdiff:notification.createdTimestamp}}.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'bonus_expired'">
                U smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> nebude realizován akcelerační příplatek z důvodu vypršení lhůty pro jeho přiznání.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'due_date_to_be_expired'">
                Termín proplacení smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší za {{notification.businessCase.dueDate|daysdiff:notification.createdTimestamp}}.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'due_date_expired'">
                Vypršel termín lhůty pro proplacení smlouvy <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'cadastre_decision_to_be_expired'">
                Smlouvě <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší lhůta pro Rozhodnutí o Návrhu na vklad dne {{notification.businessCase.cadastreDecisionDeadlineDate|date:'d.M.yyyy'}}.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'cadastre_decision_deadline_expired'">
                Smlouvě <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vypršela lhůta pro Rozhodnutí o Návrhu na vklad ({{notification.businessCase.cadastreDecisionDeadlineDate|date:'d.M.yyyy'}}).
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'rental_payment'">
                Smlouvě <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> se blíží termín pro novou platbu nájmu.
              </div>
              <div *ngIf="notification.type === 'cadastre_update' && notification.subtype === 'subject_change'">
                Zjištěna změna údajů vlastníka {{notification.data.detail}} dotčeného případem <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>.
              </div>
              <div *ngIf="notification.type === 'case_status' && (notification.subtype === 'expropriation_proceeding_interuption_to_be_expired' || notification.subtype === 'expropriation_proceeding_interuption_extended_to_be_expired')">
                Lhůta pro přerušení vyvlastňovacího řízení případu <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a> vyprší za {{(notification.subtype === 'expropriation_proceeding_interuption_to_be_expired'
                ? notification.businessCase.expropriationProceedingInteruptionDeadlineDate : notification.businessCase.expropriationProceedingInteruptionExtendedDeadlineDate)|daysdiff:notification.createdTimestamp}}.
              </div>
              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'expropriation_proceeding_interuption_deadline_expired'">
                Vypršela lhůta pro přerušení vyvlastňovacího řízení případu <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>.
              </div>

              <div *ngIf="notification.type === 'case_status' && notification.subtype === 'status_changed'">
                Stav případu <a uiSref="symap.project.cases.detail" [uiParams]="{ id: notification.businessCase.id }">{{ notification.businessCase.mpp.number||'bez čísla' }}</a> byl změněn na {{ notification.caseStatus.commonName }}.
              </div>

              <div *ngIf="notification.type === 'easements_update' && notification.subtype === 'easement_ended'">
                <span *ngIf="notification.userSource">Uživatel {{notification.userSource|username}} zrušil původní</span>
                <span *ngIf="!notification.userSource">Aktualizací bylo zrušeno</span> VB na parcele <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.parcel|parcelnumber}}</a> v kú {{notification.title.area.name}}, LV
                <a uiSref="symap.project.titles.detail" [uiParams]="{id: notification.title.id, tab: null}">{{notification.title.lv}}</a>. Na zrušené VB je navázán případ <a uiSref="symap.project.cases.detail" [uiParams]="{id: notification.businessCase.id}">{{notification.businessCase.mpp.number||'bez čísla'}}</a>.
                Případ nyní obsahuje NEPLATNÁ DATA VB.
              </div>
              <div *ngIf="notification.type === 'construction_object_status' && notification.subtype === 'realization_to_be_expired'">
                Termín realizace {{ "CONSTRUCTION_OBJECT_GENITIV" | wordtranslate }} <a uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: notification.constructionObject.id }">{{notification.constructionObject|constructionObjectName}}</a> vyprší za
                {{notification.data.realizationDate|daysdiff:notification.createdTimestamp}}.
              </div>
              <div *ngIf="notification.type === 'construction_object_status' && notification.subtype === 'realization_expired'">
                Vypršel termín realizace {{ "CONSTRUCTION_OBJECT_GENITIV" | wordtranslate }} <a uiSref="symap.project.constructionObjects.detail" [uiParams]="{ id: notification.constructionObject.id }">{{notification.constructionObject|constructionObjectName}}</a>.
              </div>
            </div>
          </li>
        </ul>
        <div class="notifications-actions">
          <div *ngIf="selectedNotifications.length > 0" class="actions-selected">
            <a class="button" visibleByBrand="maja" (click)="onDeleteNotification(selectedNotifications)">Smazat zvolené</a>
            <a class="button" (click)="onArchiveNotification(selectedNotifications)" *ngIf="notificationFilterSelectionVisible.id === 'U'">Archivovat zvolené</a>
          </div>
          <div class="load-next" *ngIf="notificationList.itemCount > notificationList.list.length">
            <input type="button" value="Načíst další" (click)="onLoadNextNotifications()" [disabled]="notificationList.loading" />
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</gmt-tabbed-block>
