<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 120px;">
        <a uiSref="^"  class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Seznam uživatelů</a>
      </div>
    </div>

    <h1>Detail uživatele</h1>

    <fieldset class="bordered">
      <div class="column-50">
        <label>Křestní jméno</label>
        <div class="info">{{ user.name }}</div>
        <label>Příjmení</label>
        <div class="info">{{ user.surname }}</div>
        <div visibleByBrand="mpv">
          <label>Organizace</label>
          <div class="info">{{ user.ssoCompanyName }}</div>
          <label >Název organizační jednotky</label>
          <div class="info">{{ user.departmentName }}</div>
          <label>Číslo organizační jednotky</label>
          <div class="info">{{ user.departmentId }}</div>
        </div>
        <div visibleByBrand="maja">
          <label>Organizace</label>
          <div class="info">{{ user.company?.companyName }}</div>
          <label >Název organizační jednotky</label>
        </div>
      </div>

      <div class="column-50">
        <div visibleByBrand="mpv">
          <label>Číslo uživatele</label>
          <div class="info">{{ user.employeePersonalNumber }}</div>
        </div>
        <label>Uživatelské jméno</label>
        <div class="info">{{ user.username }}</div>
        <label>E-mail</label>
        <div class="info">{{ user.email }}</div>
      </div>
    </fieldset>

    <div class="pt-20 mb-40">
      <div class="bordered" *ngIf="list.itemCount">
        <perfect-scrollbar [wheelPropagation]="true" style="width: 100%">
          <table class="table">
            <thead>
            <tr>
              <th class="cell">
                <sort-by [selected]="'roleName' === sortOrder.sortBy" (sort)="onSort('roleName')" [sortDir]="sortOrder.direction">
                  Role
                </sort-by>
              </th>
              <th class="cell">
                <sort-by [selected]="'isprofond' === sortOrder.sortBy" (sort)="onSort('isprofond')" [sortDir]="sortOrder.direction">
                  Evidenční číslo akce
                </sort-by>
              </th>
              <th class="cell">
                <sort-by [selected]="'projectName' === sortOrder.sortBy" (sort)="onSort('projectName')" [sortDir]="sortOrder.direction">
                  Název akce
                </sort-by>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let role of list.list">
              <td class="cell">
                {{ role.roleName }}
              </td>
              <td class="cell">
                <span>{{ role.isprofond }}</span>
              </td>
              <td class="cell">
                <a uiSref="symap.project.home" [uiParams]="{ projectKey: role.projectKey }">{{ role.projectName }}</a>
              </td>
            </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="empty-info bordered" *ngIf="!list.itemCount">
        <span>Nenalezena žádná data</span>
      </div>
      <div class="flexbox-container mt-10" *ngIf="list.itemCount">
        <itemscounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="itemCounterCallbackRegister($event)" class="flex-start"></itemscounter>
        <pagescounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="pagesCounterCallbackRegister($event)" class="flex1"></pagescounter>
        <div class="page-count-select">
          <span>Záznamů na stránce</span>
          <gmt-select
            required="true"
            [data]="dataPaging"
            field="itemsPerPage"
            [optionItems]="itemsPerPage"
            class="min-width-unset"
            (changed)="onPageItemsChanged()"
          ></gmt-select>
        </div>
      </div>
      <div class="mt-10">
        <pagination *ngIf="list.itemCount" [shouldUpdateUrl]="false" [list]="list" (pageChanged)="onPageChanged($event)" (callbackRegister)="paginationCallbackRegister($event)"></pagination>
      </div>
    </div>
  </div>
</div>
