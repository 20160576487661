﻿import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: 'form-case-payment-order',
	templateUrl: './form-case-payment-order.component.html',
})
export class FormCasePaymentOrderComponent implements OnInit {

  @Output()
  callbackRegister = new EventEmitter();
  @Input()
  data: any;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.paymentOrderDate = this.data.paymentOrderDate || new Date();

    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.paymentOrderDate;
  }
}
