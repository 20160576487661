import { Component, Input, OnInit, Inject } from '@angular/core';
import { StateParams, StateService } from '@uirouter/angular';

import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model'
import { TabModel } from '@app/common/models/tab.model';
import { GeometricPlanRequestService } from '@app/ps/services/geometric-plan-request.service';
import { ListModel } from '@app/common/models/list.model';
import { NoteModel } from '@app/common/models/note.model';
import { ListService } from '@app/common/services/list.service';
import { DocumentModel } from '@app/common/models/document.model';
import { AuthService } from '@app/common/services/auth.service';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { ClassName } from '@app/common/enums/class-name.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { GeometricPlanRequestEditComponent } from '@app/ps/geometric-plans/components/geometric-plan-request-edit/geometric-plan-request-edit.component';
import {
  GeometricPlanRequestStatusEnum,
  geometricPlanRequestStatusOptions
} from '@app/ps/enums/geometric-plan-request-status.enum';
import { NoteTypeModel } from '@app/common/models/note-type.model';
import { GeometricPlanRequestTypeEnum } from '@app/ps/enums/geometric-plan-request-type.enum';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { OccupationGroupsType, ParcelService } from '@app/ps/services/parcel.service';
import { TableUtils } from '@app/common/utils/table.utils';
import { SettingsService } from '@app/ps/services/settings.service';
import { EasementModel } from '@app/common/models/easement.model';
import { HelpService } from '@app/common/services/help.service';
import { ParcelModel } from '@app/common/models/parcel.model';

export interface EditDialogResponse {
  geometricPlanRequest: GeometricPlanRequestModel;
  notes: NoteModel[];
  gpFiles: UploadFileExtended[];
  gpVfkFiles: UploadFileExtended[];
  gpDocFiles: UploadFileExtended[];
  gpOtherFiles: UploadFileExtended[];
}

@Component({
  selector: 'geometric-plan-request-detail',
  templateUrl: './geometric-plan-request-detail.component.html',
  styleUrls: ['./geometric-plan-request-detail.component.scss']
})
export class GeometricPlanRequestDetailComponent implements OnInit {

  @Input() geometricPlanRequest: GeometricPlanRequestModel;
  @Input() noteTypes: NoteTypeModel[];

  private static readonly OCCUPATION_PERMANENT_COLUMNS: (ColumnDefinition | string)[] = [
    'label_gp',
    {id: 'occupation_title', sortable: true},
    {id: 'occupation_parcel_title', sortable: true},
    'occupation_concern_object',
    {id: 'occupation_phase', sortable: true},
    {id: 'occupation_occupation', sortable: true},
    {id: 'occupation_cases', sortable: true},
    'current_parcel_title',
    'current_protection',
    'current_concern_object'
  ];

  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: (ColumnDefinition | string)[] = [
    'label_gp',
    {id: 'occupation_title', sortable: true},
    {id: 'occupation_parcel_title', sortable: true},
    'occupation_concern_object',
    {id: 'occupation_phase', sortable: true},
    {id: 'occupation_occupation', sortable: true},
    {id: 'occupation_cases', sortable: true},
    'current_parcel_title',
    'current_protection',
    'current_concern_object'
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: (ColumnDefinition | string)[] =
    GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly EASEMENT_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel_title', sortable: true },
    { id: 'construction_object', sortable: false },
    { id: 'title', sortable: true },
    { id: 'area', sortable: false },
    { id: 'geometric_plan', sortable: false },
    { id: 'length', sortable: false },
    { id: 'case', sortable: true },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: (ColumnDefinition | string)[] = [
    'occupation_price_parcel',
    'occupation_price_vegetation',
    'occupation_price_construction',
    'occupation_price_summary',
  ];

  private static readonly  OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: (ColumnDefinition | string)[] = [
    'occupation_assessment_price',
    'occupation_price_land_price',
    'occupation_price_rent_price_year',
    'occupation_price_rent_length',
    'occupation_price_total_rental_price',
  ];

  private static readonly  OCCUPATION_OVER_YEAR_PRICE_COLUMNS: (ColumnDefinition | string)[] =
    GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  private static readonly EASEMENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel_price', sortable: false },
  ];

  activeTab: string;
  tabs: TabModel[];
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  documentList: ListModel<DocumentModel>;
  occupationList: ListModel<OccupationModel>;
  easementList: ListModel<EasementModel>;
  validatedParcelList: ListModel<ParcelModel>;
  typeEnum = GeometricPlanRequestTypeEnum;
  statusEnum = GeometricPlanRequestStatusEnum;
  occupationGroups: OccupationGroupsType;
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  easementColumns: ColumnDefinition[];
  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;
  loading = false;
  user = this.authService.getUser();
  helpIds = HelpService.HELP_IDS;
  gpParcels: ParcelModel[];

  readonly PARCELS_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel_cell', sortable: false },
    { id: 'title', sortable: false },
    { id: 'area_name', sortable: false },
  ];

  readonly STATUS_KEYS_REGEXP = new RegExp(geometricPlanRequestStatusOptions.map(opt => opt.id).join('|'));

  constructor(
    public requestService: GeometricPlanRequestService,
    public authService: AuthService,
    private stateService: StateService,
    private listService: ListService,
    private dialogService: DialogService,
    private parcelService: ParcelService,
    private settingsService: SettingsService,
  ) {
    this.activeTab = this.stateService.params.tab;
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit() {
    this.tabs = this.getTabs();
    if (this.geometricPlanRequest.type === GeometricPlanRequestTypeEnum.EASEMENT_EXTENT || !this.geometricPlanRequest.knGp) {
        let gItem = this.tabs.find(i => i.id === 'all-parcels-budouc');
        this.tabs.splice(this.tabs.indexOf(gItem), 1);
        gItem = this.tabs.find(i => i.id === 'validated-parcels');
        this.tabs.splice(this.tabs.indexOf(gItem), 1);
    }
    this.prepareParcelColumns();
    this.loadNotes();
    this.loadHistory();
    this.loadDocuments();

    if (this.geometricPlanRequest.knGp) {
      if (this.geometricPlanRequest.type === GeometricPlanRequestTypeEnum.EASEMENT_EXTENT) {
        this.loadEasements();
      } else {
        this.loadOccupations();
        this.loadValidatedParcels();
        const futureParcelsTab = this.tabs.find((tab) => tab.id === 'all-parcels-budouc');
        futureParcelsTab.name = `Budoucí parcely (${this.geometricPlanRequest.knGp.knBudoucs.length})`;
        this.gpParcels = this.geometricPlanRequest.knGp.knBudoucs
          .filter(kb => kb.parcel)
          .map(kb => kb.parcel)
          .sort((i1, i2) => {
               return i1.parcisKmen < i2.parcisKmen ? -1 : i1.parcisKmen == i2.parcisKmen && i1.parcisPod < i2.parcisPod ? -1 : 1;
           });
      }
    }

    const parcelsTab = this.tabs.find((tab) => tab.id === 'parcels');
    parcelsTab.name = `Původní parcely (${this.geometricPlanRequest.parcels.length})`;
  }

  onEdit(status: string): Promise<any> {
    if (status && !this.requestService.isStatusDialog(status)) {
      this.geometricPlanRequest.status = status;
      return this.requestService.update(this.geometricPlanRequest);
    }

    const dialog = this.dialogService.open(GeometricPlanRequestEditComponent, {
      data: {
        geometricPlanRequest: { ...this.geometricPlanRequest },
        noteTypes: this.noteTypes,
        editMode: (status ? 'change-status' : 'edit'),
        newStatus: status
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: EditDialogResponse | boolean) => {
        sub.unsubscribe();

        if (typeof result === 'object') {
          this.uploadAndRefresh(
            status,
            result.geometricPlanRequest,
            result.notes,
            result.gpFiles,
            result.gpVfkFiles,
            result.gpDocFiles,
            result.gpOtherFiles
          );
        }

        return;
      });
  }

  onImport() {
    this.stateService.go('symap.project.geometricPlanRequests.import', { id: this.geometricPlanRequest.id });
  }

  isActiveTab(id: string): boolean {
    return id === this.activeTab;
  }

  uiOnParamsChanged(changedParams: StateParams) {
    this.activeTab = changedParams.tab;
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    this.historyList.list.forEach((note: NoteModel) => {
      note.text = note.text.replace(
        this.STATUS_KEYS_REGEXP,
        status => this.requestService.getEnumLabel('status', status)
      );
    });
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateParcelList() {
    const parcelTab = this.tabs.find((tab) => tab.id === 'parcels-budouc');
    const occupationsParIDs = this.occupationList && this.occupationList.list
      ? this.occupationList.list.map((occupation: OccupationModel) => occupation.parcel.idpar)
      : [];
    const easementsParIDs = this.easementList && this.easementList.list
      ? this.easementList.list.map((easement: EasementModel) => easement.parcel.idpar)
      : [];
    const parcelsIDs = [...new Set([...occupationsParIDs, ...easementsParIDs])];
    parcelTab.name = `Majetkoprávně řešené parcely (${parcelsIDs.length})`;
  }

  canEdit(): boolean {
    return this.authService.hasPermission('manage_geometric_plans');
  }

  private getTabs(): TabModel[] {
    const tabs = [
      {
        name: 'Původní parcely',
        id: 'parcels',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'parcels',
        },
      },
      {
        name: 'Budoucí parcely',
        id: 'all-parcels-budouc',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'all-parcels-budouc',
        },
      },
      {
        name: 'Majetkopávně řešené parcely',
        id: 'parcels-budouc',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'parcels-budouc',
        },
      },
      {
        name: 'Vyřešené parcely',
        id: 'validated-parcels',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'validated-parcels',
        },
      },
      {
        name: 'Poznámky',
        id: 'notes',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'notes',
        },
      },
      {
        name: 'Historie',
        id: 'history',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'history',
        },
      },
      {
        name: 'Dokumenty',
        id: 'documents',
        href: 'symap.project.geometricPlanRequests.detail',
        urlParams: {
          id: this.geometricPlanRequest.id,
          tab: 'documents',
        },
      },
    ];

    if (!this.geometricPlanRequest.knGp) {
      const parcelsBudoucTab = tabs.find((tab) => tab.id === 'parcels-budouc');
      tabs.splice(tabs.indexOf(parcelsBudoucTab), 1);

      if (this.activeTab === 'parcels-budouc') {
        this.stateService.go('.', { tab: 'parcels' }, { location: 'replace' });
      }
    }

    return tabs;
  }

  loadNotes(): Promise<any> {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { geometricPlanRequestId: this.geometricPlanRequest.id, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    return this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  showNextButton(nextStatus: string): boolean {
    if (nextStatus === GeometricPlanRequestStatusEnum.IMPORTED || nextStatus === GeometricPlanRequestStatusEnum.IMPORTED_AUTO) {
      return !!this.user.superuser || this.authService.hasPermission('admin');
    } else {
      return true;
    }
  }

  private loadHistory(): Promise<any> {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { geometricPlanRequestId: this.geometricPlanRequest.id, systemType: true },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    return this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadDocuments(): Promise<any> {
    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: { geometricPlanRequestId: this.geometricPlanRequest.id, cancelled: false },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined
    );
    return this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private loadOccupations() {
    this.occupationList = this.listService.createList(
      'occupations',
      {
        limit: undefined,
        filters: {
          knGpId: this.geometricPlanRequest.knGp.id,
          validOrWithCase: true,
          //validity: ['valid'], // zobrazit i parcely, které byly řešeny
          loadCollections: [
            'parcel.knBudouc.currentParcels',
            'parcel.knBudouc.currentParcels.bpejList',
            'constructionObjects',
            'rentalExpert',
          ]},
        sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
      }
    );
    this.listService.fetchResult(this.occupationList).then((data: ListModel<OccupationModel>) => {
      this.occupationGroups = this.parcelService.groupOccupationsByParcel(data.list, []);
      this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.underOneYear);
      this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.overOneYear);
      this.onUpdateParcelList();
    });
  }

  private loadEasements() {
    this.easementList = this.listService.createList(
      'easements',
      {
        limit: undefined,
        filters: {
          gpId: this.geometricPlanRequest.knGp.id,
          validity: ['valid'],
          loadCollections: [ 'parcel.bpejList' ]
        },
        sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
      },
      undefined,
      {
        constructionObjects: {
          load: true,
          attributes: {
            administrator: {load: true},
          }
        }
      }
    );
    this.listService.fetchResult(this.easementList).then(() => {
      this.onUpdateParcelList();
    });
  }

  private loadValidatedParcels() {
    this.validatedParcelList = this.listService.createList(
      'parcels',
      {
        limit: undefined,
        filters: {
          validatedGeometricPlanId: this.geometricPlanRequest.knGp.id,
        },
        sortOrder: [{ sortBy: 'parcisKmen' }, { sortBy: 'parcisPod' }]
      },
      undefined,
      undefined
    );
    this.listService.fetchResult(this.validatedParcelList).then(() => {
      const tab = this.tabs.find((tab) => tab.id === 'validated-parcels');
      tab.name = `Vyřešené parcely (${this.validatedParcelList.list.length})`;
    });
  }

  private async uploadAndRefresh(
    status: string,
    geometricPlanRequest: GeometricPlanRequestModel,
    notes: NoteModel[],
    gpFiles: UploadFileExtended[],
    gpVfkFiles: UploadFileExtended[],
    gpDocFiles: UploadFileExtended[],
    gpOtherFiles: UploadFileExtended[],
  ) {
    this.loading = true;
    const updatetdRequest = await this.requestService.update(geometricPlanRequest, notes, gpFiles, gpVfkFiles, gpDocFiles, gpOtherFiles);
    if (status) {
      updatetdRequest.lastStatusChangeDate = new Date().toISOString();
    }
    this.geometricPlanRequest = updatetdRequest;
    await this.loadHistory();
    await this.loadNotes();
    await this.loadDocuments();
    this.loading = false;
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [...GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_COLUMNS];
    let occupationUnderOneYearColumns = [...GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS];
    let occupationOverOneYearColumns = [...GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS];
    let easementColumns = [...GeometricPlanRequestDetailComponent.EASEMENT_COLUMNS];

    if (!this.hideColumn('permanentOccupationPriceColumns')) {
      occupationPermanentColumns = occupationPermanentColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
      occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryOverYearPriceColumns')) {
      occupationOverOneYearColumns = occupationOverOneYearColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
      easementColumns = easementColumns.concat(GeometricPlanRequestDetailComponent.EASEMENT_PRICE_COLUMNS);
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.easementColumns = TableUtils.repairColumnsStructure(easementColumns);
  }

  private hideColumn(columnID: string): boolean {
    const tableID = 'parcelDetail'; // @TODO - replace
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }
}
