export interface ExpertModel {
  cisloDomovni: number;
  cisloOrientacni: string;
  id: number;
  jmeno: string;
  obec: string;
  prijmeni: string;
  psc: string;
  titulPredJmenem: string;
  titulZaJmenem: string;
  ulice: string;
}

export const expertModelDefault = {
  cisloDomovni: undefined,
  cisloOrientacni: undefined,
  id: undefined,
  jmeno: undefined,
  obec: undefined,
  prijmeni: undefined,
  psc: undefined,
  titulPredJmenem: undefined,
  titulZaJmenem: undefined,
  ulice: undefined,
};
