import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MapCallbackEnum } from '@app/map/enums/map-callback.enum';
import { CallbackModel } from '@app/common/models/callback.model';

@Injectable({
  providedIn: 'root'
})
export class MapLayerSelectorService {

    private callbacks;
    collapsed = false;

    constructor(
    ) {
      this.callbacks = new CallbackModel();
      this.getCollapsedState = this.getCollapsedState.bind(this);
      this.setCollapsedState = this.setCollapsedState.bind(this);
      this.fireCallback = this.fireCallback.bind(this);
      this.registerCallback = this.registerCallback.bind(this);
      this.unregisterCallback = this.unregisterCallback.bind(this);
    }

     getObjectConfig(mainConfig, id) {
        var config = null;
        if (mainConfig.id === id) {
            config = mainConfig;
        } else {
            mainConfig.layers.forEach(function (group) {
                if (group.id === id) {
                    config = group;
                } else {
                    group.layers.forEach(function(layer) {
                        if (layer.id === id) {
                            config = layer;
                        }
                    });
                }
            });
        }
        return config;
    }

    /**
     * Returns id path from root to layer,
     * skipping root id element and layer id element.
     * Returned path could be used as argument for cmLeafletMapDirective.updateNode()
     * @param config map layer configuration
     * @param id id of searched layer
     * @param result used for recursion
     * @returns array|null
     */
     getConfigPath(config, id, result) {
        if (config.id === id) {
            return result;
        } else if (_.isArray(config.layers)) {
            let r;

            if (!result) {
                r = [];
            } else {
                r = result.concat(config.id);
            }

            for (let t = 0; t < config.layers.length; t++) {
                const up = this.getConfigPath(config.layers[t], id, r);

                if (up) {
                    return up;
                }
            }
        }

        return null;
    }

    fireCallback(eventName, event) {
        this.callbacks.get(eventName)(event);
    }

    registerCallback(eventName, callback) {
        this.callbacks.add(eventName, callback);
    }

    unregisterCallback(eventName, callback) {
        this.callbacks.remove(eventName, callback);
    }

    setCollapsedState(newCollapsed) {
        this.collapsed = newCollapsed;
        this.fireCallback(MapCallbackEnum.collapsedStateChange, this.collapsed);
    }

    getCollapsedState() {
        return this.collapsed;
    }
}
