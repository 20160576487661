import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';

@Component({
  selector: 'form-expropriation-administrative-appeal-accepted',
  templateUrl: './form-expropriation-administrative-appeal-accepted.component.html',
})
export class FormExpropriationAdministrativeAppealAcceptedComponent implements OnInit {
  @Output()
  callbackRegister = new EventEmitter();
  @Input() data: CaseModel;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.expropriationAdministrativeAppealAcceptedDate = new Date();
    this.callbackRegister.emit({
      isValid: this.isValid,
    });
  }

  isValid() {
    return !!this.data.expropriationAdministrativeAppealAcceptedDate;
  }
}

