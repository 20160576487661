<h1>{{ editMode === 'edit' ? 'Úprava údajů GP' : requestService.getEnumLabel('status', newStatus) }}</h1>
<fieldset>
  <div class="column-100">
    <!-- cislo GP -->
    <div *ngIf="isFieldVisible('name')" class="column-50">
      <gmt-input label="Číslo GP" class="mt-10" [required]="editMode === 'change-status'">
        <input type="text" [(ngModel)]="geometricPlanRequest.name"/>
      </gmt-input>
    </div>

    <!-- zhotovitel -->
    <div *ngIf="isFieldVisible('supplier')" class="column-50">
      <gmt-input label="Zhotovitel" class="mt-10" [required]="editMode === 'change-status'">
        <input type="text" [(ngModel)]="geometricPlanRequest.supplier"/>
      </gmt-input>
    </div>

    <!--datum zadani zpracovani -->
    <div *ngIf="isFieldVisible('conceptRealizationInputDate')" class="column-50">
      <gmt-input label="Datum zadání zpracování" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.conceptRealizationInputDate"/>
      </gmt-input>
    </div>

    <!-- datum pro vyhotoveni -->
    <div *ngIf="isFieldVisible('conceptRealizationOutputDate')" class="column-50">
      <gmt-input label="Termín pro vyhotovení konceptu" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.conceptRealizationOutputDate"/>
      </gmt-input>
    </div>

    <!-- datum predani ke schvaleni-->
    <div *ngIf="isFieldVisible('conceptToApproveDate')" class="column-50">
      <gmt-input label="Datum předání ke schválení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.conceptToApproveDate"/>
      </gmt-input>
    </div>

    <!-- datum odmitnuti konceptu -->
    <div *ngIf="isFieldVisible('conceptDisapprovedDate')" class="column-50">
      <gmt-input label="Datum odmítnutí konceptu" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.conceptDisapprovedDate"/>
      </gmt-input>
    </div>

    <!-- datum schvaleni konceptu -->
    <div *ngIf="isFieldVisible('conceptApprovedDate')" class="column-50">
      <gmt-input label="Datum schválení konceptu" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.conceptApprovedDate"/>
      </gmt-input>
    </div>

    <!-- datum odeslani potvrzeni -->
    <div *ngIf="isFieldVisible('sentToConfirmationDate')" class="column-50">
      <gmt-input label="Datum odeslání potvrzení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.sentToConfirmationDate"/>
      </gmt-input>
    </div>

    <!-- datum overeni -->
    <div *ngIf="isFieldVisible('verificationDate')" class="column-50">
      <gmt-input label="Datum ověření GP" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.verificationDate"/>
      </gmt-input>
    </div>

    <!-- datum zamitnuti -->
    <div *ngIf="isFieldVisible('disapprovedDate')" class="column-50">
      <gmt-input label="Datum zamítnutí" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.disapprovedDate"/>
      </gmt-input>
    </div>

    <!-- datum schvaleni -->
    <div *ngIf="isFieldVisible('approvedDate')" class="column-50">
      <gmt-input label="Datum potvrzení KP" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.approvedDate"/>
      </gmt-input>
    </div>

    <!-- cena za vyhotoveni -->
    <div *ngIf="isFieldVisible('realizationPrice')" class="column-50">
      <gmt-input label="Cena za vyhotovení" class="mt-10">
        <input type="text" [(ngModel)]="geometricPlanRequest.realizationPrice" gmtInputNumber />
      </gmt-input>
    </div>

    <!-- cislo PGP -->
    <div *ngIf="isFieldVisible('pgpNumber')" class="column-50">
      <gmt-input label="Číslo potvrzení GP (PGP)" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" [(ngModel)]="geometricPlanRequest.pgpNumber" />
      </gmt-input>
    </div>

    <!-- datum zruseni -->
    <div *ngIf="isFieldVisible('deletedDate')" class="column-50">
      <gmt-input label="Datum zrušení" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.deletedDate"/>
      </gmt-input>
    </div>

    <!-- datum pozadavku na vklad do KN -->
    <div *ngIf="isFieldVisible('registerRequestedDate')" class="column-50">
      <gmt-input label="Datum vkladu do KN na základě ÚR" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.registerRequestedDate"/>
      </gmt-input>
    </div>

    <!-- datum pozadavku na vklad do KN -->
    <div *ngIf="isFieldVisible('registeredDate')" class="column-50">
      <gmt-input label="Datum zápisu GP do KN (ÚR)" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.registeredDate"/>
      </gmt-input>
    </div>

    <!-- datum pozadavku na import -->
    <div *ngIf="isFieldVisible('importRequestedDate')" class="column-50">
      <gmt-input label="Datum požadavku na import" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.importRequestedDate"/>
      </gmt-input>
    </div>

    <!-- datum importu -->
    <div *ngIf="isFieldVisible('importedDate') && authService.hasPermission('admin')" class="column-50">
      <gmt-input label="Datum importu" [required]="editMode === 'change-status'" class="mt-10">
        <input type="text" pick-a-date [(ngModel)]="geometricPlanRequest.importedDate"/>
      </gmt-input>
    </div>

    <!-- KN GP -->
    <div *ngIf="editMode === 'change-status' && isFieldVisible('knGp') && authService.hasPermission('admin')" class="column-50">
      <gmt-input label="Vstup KN GP" [required]="true" class="mt-10">
        <gmt-select
          resource="geometric-plans"
          [filter]="{ sortOrder: { sortBy: 'cis' }, filters: { cis: geometricPlanRequest.name, withRequest: false } }"
          [data]="geometricPlanRequest"
          field="knGp"
          required="true"
          selectTitle="Vyberte GP"
          [itemPipe]="knGpNamePipe"
        ></gmt-select>
      </gmt-input>
    </div>
  </div>
</fieldset>

<!-- predbezny souhlas stavebniho uradu -->
<fieldset *ngIf="newStatus === statusEnum.CONCEPT_REALIZATION && geometricPlanRequest.type === typeEnum.PARCEL_SPLIT">
  <legend>Předběžné schválení dělení pozemků od SÚ</legend>
  <gmt-switch
    [options]="approveOptions"
    [selected]="parcelSplitPreApproveByBuildingAuthority"
    (select)="parcelSplitPreApproveByBuildingAuthority = $event"
  ></gmt-switch>
  <div class="column-100 mt-10">
    <label class="">Poznámka</label>
    <textarea [(ngModel)]="parcelSplitPreApproveByBuildingAuthorityNote"></textarea>
  </div>
</fieldset>

<!-- Duvod odmitnuti konceptu -->
<fieldset *ngIf="newStatus === statusEnum.CONCEPT_DISAPPROVED">
  <legend>Důvod odmítnutí konceptu</legend>
  <div class="column-100">
    <label class="">Poznámka</label>
    <textarea [(ngModel)]="conceptDisapprovedReasonNote"></textarea>
  </div>
</fieldset>

<!-- PDF GP -->
<fieldset *ngIf="editMode === 'change-status' && isFieldVisible('attachments')">
  <legend class="required">Dokument PDF potvrzeného geometrického plánu katastrálním pracovištěm</legend>
  <fileupload class="blue" (change)="onGpFilesChange($event)" [message]="gpFilesMsg" inputId="gpHidden"></fileupload>
  <p class="text-center"><i>Vkládaný dokument PDF musí obsahovat elektronické razítko katastrálního pracoviště.</i></p>
</fieldset>

<!-- VFK GP -->
<fieldset *ngIf="editMode === 'change-status' && isFieldVisible('attachments')">
  <legend class="required">Soubor VFK předaný na katastrální pracoviště</legend>
  <fileupload class="blue" (change)="onGpVfkFilesChange($event)" [message]="gpVfkFilesMsg" inputId="gpVfkHidden"></fileupload>
</fieldset>

<!-- GP DOCUMENTATION -->
<fieldset *ngIf="editMode === 'change-status' && isFieldVisible('attachments')">
  <legend [ngClass]="{ required: geometricPlanRequest.type === typeEnum.EASEMENT_EXTENT }">Geodetická dokumentace GP (Tabulka výměr a délek pro případ GP na vyznačení VB, ZIP soubor obsahující ZPMZ, DGN kresbu a pod.)</legend>
  <fileupload class="yellow" (change)="onGpDocFilesChange($event)" [message]="gpDocFilesMsg" inputId="gpDocHidden"></fileupload>
</fieldset>

<!-- OTHER DOCUMENTS -->
<fieldset *ngIf="editMode === 'change-status' && isFieldVisible('attachmentsOther')">
  <legend [ngClass]="{ required: geometricPlanRequest.type === typeEnum.EASEMENT_EXTENT }">Dokumenty</legend>
  <fileupload class="yellow" (change)="onGpOtherFilesChange($event)" [message]="gpOtherFilesMsg" inputId="gpOtherHidden"></fileupload>
</fieldset>

<div class="actions">
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
