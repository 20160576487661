<div class="list">
  <h1>Projekty</h1>

  <filter (onChanged)="pageableList.onFilterChanged($event)" [list]="list">
    <gmt-select
      [optionItems]="statusOptions"
      [data]="list.filter.filters.status"
      field="values"
      [multiselect]="true"
      selectTitle="Stav"
    ></gmt-select>

    <div class="input pt-5">
      <label>Datum platnosti </label>
    </div>
    <div class="input pt-5">
      <gmt-checkbox [gmtChecked]="filterValidity" (click)="onFilterValidityChange()"></gmt-checkbox>
    </div>
    <div class="input">
      <month-picker (date)="onValidDateChanged($event)" [options]="monthPickerOptions"></month-picker>
    </div>
    <input class="fulltext" type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id" />
  </filter>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div class="bordered" *ngIf="list.itemCount">
      <table class="table">
        <thead>
          <tr>
            <th class="cell">
              <sort-by
                [selected]="'name' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('name', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Název projektu</sort-by>
            </th>
            <th class="cell" style="max-width: 300px;">
              <sort-by
                [selected]="'version' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('version', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Datum platnosti</sort-by>
            </th>
            <th class="cell">ID projektu<span visibleByBrand="mpv"> / SHKN ID</span></th>
            <th class="cell">
              <sort-by
                [selected]="'createdTime' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('createdTime', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Vytvořeno</sort-by>
            </th>
            <th class="cell">Příjemce</th>
          </tr>
        </thead>
        <tbody *ngFor="let project of list.list" class="hover">
          <tr class="bordered-bottom">
            <td class="cell">
              <b>{{ project.name }}</b>
            </td>
            <td class="cell">
              <b>{{ project.version | date }}</b>
            </td>
            <td class="cell">
              <b>{{ project.projectId }}<span visibleByBrand="mpv" *ngIf="project.externalProject?.externalProjectId"> / {{ project.externalProject.externalProjectId }}</span></b>
            </td>
            <td class="cell">
              <b>{{ project.createdTime | date }}</b>
            </td>
            <td class="cell">
              <b>{{ project.prijemce.nazev }}</b>
            </td>
          </tr>
          <ng-container *ngIf="project.requests?.length">
            <tr class="bordered-bottom">
              <th>ID žádosti</th>
              <th>Katastrální území<span *ngIf="project.externalProject?.lastStatusCheck" visibleByBrand="mpv" [title]="datePipe.transform(project.externalProject?.lastStatusCheck, 'short', 'UTC')"> (SHKN stav export / import)</span></th>
              <th>Stav</th>
              <th>Vytvořeno</th>
              <th>LPIS</th>
            </tr>
            <tr *ngFor="let request of project.requests" class="bordered-bottom">
              <td><a uiSref="pk.projects.create-project-third" [uiParams]="{ requestId: request.requestId }">{{ request.requestId }}</a></td>
              <td style="max-width: 300px;">
                <div class="comma-list">
                  <span *ngFor="let area of request.ruianKatuze">
                    {{ area.nazev }}<span *ngIf="project.externalProject?.lastStatusCheck && project.externalProject?.status" visibleByBrand="mpv"> ({{ getExternalStatus(project.externalProject.status, area.kod) }})</span>
                  </span>
                </div>
              </td>
              <td>{{ request.status }}</td>
              <td>{{ request.recivedTime | date }}</td>
              <td>{{ request.includeLpis ? 'ANO' : 'NE' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null && !list.loading">
      <span>Nenalezena žádná data</span>
    </div>
  </div>

  <itemscounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.itemcounterCallbackRegister($event)" style="max-width: 100px;"></itemscounter>
  <pagination *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.paginationCallbackRegister($event)"></pagination>

  <div class="mt-10" visibleByBrand="mpv">
    Stavy katastrálních území v SHKN:
    <div><strong>stav export</strong></div>
    <div>0 určené k žádosti pro ČÚZK</div>
    <div>1 žádost pro ČÚZK uzavřena</div>
    <div>2 soubory VFK jsou vyskladněné</div>
    <div>3 soubory VFK není třeba žádat, určené pro reimport</div>

    <div class="mt-5"><strong>stav import:</strong></div>
    <div>0 soubory VFK ještě nejsou importovány</div>
    <div>1 zahájena příprava importu (stažení VFK souborů)</div>
    <div>2 zahájen import SGI</div>
    <div>3 zahájen import SPI</div>
    <div>4 zahájena aktualizace duplicit, indexů v databázi</div>
    <div>5 ukončen import souborů VFK</div>
    <div>6 ukončen reimport</div>
  </div>
</div>
