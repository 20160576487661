<representative-form
    *ngFor="let r of representatives; last as isLast"
    [heading]="label"
    [allowRoleAssignment]="true"
    [representative]="r"
    (toggleRoleCadastre)="removeRoleCadastreForAllExcept($event)"
    [ngClass]="{'mb-60' : !isLast}"
>
  <gmt-button *ngIf="shouldShowDeleteButton()" class="red mb-10" slot="deleteButton" [clickAction]="removeRepresentative(r)">
    <i class="fa fa-minus"></i>
  </gmt-button>
  <gmt-button *ngIf="isLast" class="ml-20" [clickAction]="createAndAddRepresentative" slot="addButton"><i class="fa fa-plus"></i></gmt-button>
</representative-form>

<div class="centered-text">
  <gmt-button class="button-submit" [gmtDisabled]="!hasValidForm()" [clickAction]="onSubmit">Uložit</gmt-button>
</div>
