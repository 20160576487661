import {Component, EventEmitter, Inject, Input, OnInit, Output, OnDestroy} from "@angular/core";
import * as _ from 'lodash';

import {ListModel} from "../../../common/models/list.model";
import {ListService} from "../../../common/services/list.service";
import {PerfectScrollbarEvent} from "../../../common/components/perfectscrollbar/perfectscrollbar.component";
import {AuthService} from "../../../common/services/auth.service";
import {DialogService} from "../../../common/services/dialog.service";
import {ConfirmationComponent} from "../../../common/components/confirmation/confirmation.component";
import {ClassName} from "../../../common/enums/class-name.enum";
import {NotificationModel} from "../../models/notification.model";
import {TabbedBlockModel} from "../../../common/models/tabbed-block.model";
import { CallbackModel } from '@app/common/models/callback.model';
import { APP_BRAND, EVENTS, ROOT_CALLBACKS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'gmt-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit, OnDestroy {

  public static readonly NOTIFICATION_FILTER: TabbedBlockModel[] = [
    {id: 'U', name: 'Doručená'},
    {id: 'A', name: 'Archivovaná'}
  ];

  // bindings
  @Input() readonly = false;
  @Input() notificationList: ListModel<NotificationModel>;
  @Output() updated = new EventEmitter<{type: String, data: ListModel<NotificationModel>}>();
  readonlyState = true;

  // model
  notificationFilter: any[] = [];
  notificationFilterSelectionVisible: TabbedBlockModel = NotificationsComponent.NOTIFICATION_FILTER[0];
  selectedNotifications = [];
  checkedAllNotifications = false;

  // fields
  private storageKey: string;
  private scrollTop: () => void = () => {};

  constructor(
      private restangular: Restangular,
      private localStorageService: LocalStorageService,
      @Inject(ROOT_CALLBACKS) private rootCallbackService: CallbackModel,
      @Inject(APP_BRAND) public APP_BRAND: any,
      private authService: AuthService,
      private listService: ListService,
      private dialogService: DialogService
  ) {
    this.onCanScrollTop = this.onCanScrollTop.bind(this);
    this.onNotificationFilterChanged = this.onNotificationFilterChanged.bind(this);
    this.onChangeNotificationsOrder = this.onChangeNotificationsOrder.bind(this);
    this.onLoadNextNotifications = this.onLoadNextNotifications.bind(this);
    this.onCheckAllNotifications = this.onCheckAllNotifications.bind(this);
    this.onDeleteNotification = this.onDeleteNotification.bind(this);
    this.onArchiveNotification = this.onArchiveNotification.bind(this);
    this.onToggleNotificationSelection = this.onToggleNotificationSelection.bind(this);
  }

  ngOnInit() {
    if (!this.readonly) {
      this.notificationFilter = NotificationsComponent.NOTIFICATION_FILTER;
    }

    if (!this.notificationList) {
      this.storageKey = this.authService.getActualProject().key + "." + 'homeNotificationsOrder';
      this.loadNotificationList();
      this.onNotificationFilterChanged();
    } else {
      this.notificationList.promise.then(() => {
        this.readonlyState = this.readonly || !this.notificationList.list.some(notification => !!notification.user);
      });
    }
  }

  ngOnDestroy() {
    if (this.storageKey) {
      this.listService.cancelFetch(this.notificationList);
    }
  }

  onCanScrollTop(scrollbarEvent: PerfectScrollbarEvent) {
    this.scrollTop = scrollbarEvent.scrollTop;
  };

  notifyChange() {
    this.readonlyState = this.readonly || !this.notificationList.list.some(notification => !!notification.user);
    this.updated.emit({
      type: this.notificationList.filter.filters.archive ? 'archived' : 'unarchived',
      data: this.notificationList,
    });
  }

  onNotificationFilterChanged(value: TabbedBlockModel = this.notificationFilterSelectionVisible) {
    this.scrollTop();
    this.notificationList.filter.filters.archive = (value.id === 'A');
    this.notificationList.filter.offset = 0;
    this.listService.fetchResult(this.notificationList).then(() => {
      this.notificationFilterSelectionVisible = value;
      this.uncheckAllNotifications();
      this.notifyChange();
    });
  };

  onChangeNotificationsOrder() {
    const order = this.loadNotificationsOrderSetting() === 'desc' ? 'asc' : 'desc';
    this.notificationList.filter.sortOrder.direction = order;
    if (this.storageKey) {
      this.localStorageService.set(this.storageKey, order);
    }
    this.notificationList.filter.offset = 0;
    this.listService.fetchResult(this.notificationList).then(() => {this.uncheckAllNotifications()});
  };

  onLoadNextNotifications() {
    this.notificationList.filter.offset += this.notificationList.filter.limit;
    this.listService.fetchResult(this.notificationList, true);
    this.checkedAllNotifications = false;
  };

  onCheckAllNotifications() {
    if (this.checkedAllNotifications) {
      this.selectedNotifications = [];
    } else {
      this.selectedNotifications = [].concat(this.notificationList.list.filter(notification => notification.user));
    }
    this.checkedAllNotifications = !this.checkedAllNotifications;
  }

  onToggleNotificationSelection(notification: NotificationModel, event) {
    if (event.target.nodeName === 'A' || !this.canModify(notification)) {
      return null;
    }

    const index = this.selectedNotifications.indexOf(notification);
    if (index !== -1) {
      this.selectedNotifications.splice(index, 1);
      this.checkedAllNotifications = false;
    } else {
      this.selectedNotifications.push(notification);
      if (this.selectedNotifications.length === this.notificationList.list.length) {
        this.checkedAllNotifications = true;
      }
    }
  }

  onDeleteNotification(notifications: NotificationModel[]) {
    const ids = _.map(notifications, 'id');

    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete upozornění smazat?',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.notificationList.loading = true;
        this.restangular.all('notifications').customPUT(ids, 'hide').toPromise().then(() => {
          this.selectedNotifications = _.difference(this.selectedNotifications, notifications);
          this.notificationList.list = _.difference(this.notificationList.list, notifications);
          this.notificationList.itemCount -= notifications.length;
          this.notificationList.filter.offset -= notifications.length;

          const countWithWarning = notifications.reduce((total, item) => {
            return total + (item.withWarning && !item.archived ? 1 : 0);
          }, 0);

          if (countWithWarning > 0) {
            this.rootCallbackService.get(EVENTS.notificationWithWarningHidden)(countWithWarning);
          }

          if (this.notificationList.list.length === 0 && this.notificationList.itemCount > 0) {
            this.onLoadNextNotifications();
          }

          this.uncheckAllNotifications();
          this.notifyChange();
          this.notificationList.loading = false;
        });
      }
      sub.unsubscribe();
    });
  }

  onArchiveNotification(notifications: NotificationModel[]) {
    const ids = _.map(notifications, 'id');

    this.notificationList.loading = true;
    this.restangular.all('notifications').customPUT(ids, 'archive').toPromise().then(() => {
      this.selectedNotifications = _.difference(this.selectedNotifications, notifications);
      this.notificationList.list = _.difference(this.notificationList.list, notifications);
      this.notificationList.itemCount -= notifications.length;
      this.notificationList.filter.offset -= notifications.length;
      this.notificationList.loading = false;

      const countWithWarning = notifications.reduce((total, item) => {
        return total + (item.withWarning && !item.archived ? 1 : 0);
      }, 0);
      if (countWithWarning > 0) {
        this.rootCallbackService.get(EVENTS.notificationWithWarningHidden)(countWithWarning);
      }

      if (this.notificationList.list.length === 0 && this.notificationList.itemCount > 0) {
        this.onLoadNextNotifications();
      }
      this.uncheckAllNotifications();
      this.notifyChange();
    });
  }

  isNotificationChecked(notification: NotificationModel): boolean {
    return _.findIndex(this.selectedNotifications, notification) !== -1;
  }

  canModify(notification: NotificationModel) {
    return this.authService.isAuthorized(notification.user);
  }

  canModifyAllNotifications() {
    for (const notification of this.notificationList.list) {
      if (!this.canModify(notification)) {
        return false;
      }
    }
    return true;
  }

  private loadNotificationList() {
    this.notificationList = this.listService.createList('notifications', {
      sortOrder: {sortBy: 'createdTimestamp', direction: this.loadNotificationsOrderSetting()},
      filters: {
        loadCollections: ['knBudouc.futureParcel'],
        hidden: [false],
        currentUser: [true],
      }
    });
  }

  private uncheckAllNotifications() {
    this.checkedAllNotifications = false;
    this.selectedNotifications = [];
  }

  private loadNotificationsOrderSetting(): string {
    const value = this.storageKey ? this.localStorageService.get(this.storageKey) : this.notificationList.filter.sortOrder.direction;
    return (value && value === 'desc') ? "desc" : "asc";
  };
}
