﻿import { Directive, ComponentRef, ViewContainerRef, ComponentFactoryResolver, OnInit, EventEmitter, Input, Output, ElementRef, Renderer2 } from "@angular/core";
import { PickADateComponent } from '../../components/pickadate/pickadate.component';
import { WindowDimensionService } from '@app/common/services/window-dimension.service';

@Directive({
  selector: '[pick-a-date]',
})
export class PickADateDirective implements OnInit {
  private componentRef: ComponentRef<PickADateComponent>;
  dateSelectorElement: any;
  pickAdateElement: any;
  @Input() maxToday = false;
  @Input()
  get ngModel(): string { return this._ngModel; }
  set ngModel(ngModel: string) {
    this._ngModel = ngModel;
    if (this.pickAdateElement) {
      this.onModelChange(ngModel);
    }
  }
  private _ngModel = undefined;
  @Input() placeholder;
  @Output() ngModelChange:EventEmitter<any> = new EventEmitter();
  picker: any;
  blockEvent = false;

  constructor(
    private elementRef: ElementRef,
    private windowDimensionService: WindowDimensionService,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.checkPosition = this.checkPosition.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onModelChange = this.onModelChange.bind(this);
  }

   onModelChange(newValue: number | string) {
    if (this.blockEvent) {
      this.blockEvent = false;
      return;
    }

    if (this.getPickerDate() === newValue) {
      return;
    }

    if (!newValue) {
        this.blockEvent = true;
        this.pickAdateElement.pickadate('picker').clear();
    } else {
      if (typeof newValue === 'string') {
        this.blockEvent = true;
        this.pickAdateElement.pickadate('picker').set('select', new Date(new Date(newValue).getTime() + (new Date(newValue).getTimezoneOffset() * 60 * 1000)));
      } else {
        this.blockEvent = true;
        this.pickAdateElement.pickadate('picker').set('select', new Date(newValue));
      }
    }
    this.pickAdateElement.removeClass('invalid');
  }

  onInputChange(newValue: string) {
    const parsed = this.parseDate(newValue);
    if (parsed !== undefined) {
      this.blockEvent = true;
      this.picker.set('select', parsed);
      this.picker.set('highlight', parsed);
      this.ngModelChange.emit(this.getPickerDate());
      this.pickAdateElement.removeClass('invalid');
    } else {
      this.blockEvent = true;
      this.ngModelChange.emit(null);
      this.pickAdateElement.addClass('invalid');
    }
  }

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
    const factory = this.componentFactoryResolver.resolveComponentFactory(PickADateComponent);
    this.componentRef = this.viewContainerRef.createComponent(factory);

    this.componentRef.instance.changed.subscribe(this.onInputChange);
    this.dateSelectorElement = $(this.componentRef.location.nativeElement.querySelector('.date-selector'));
    this.pickAdateElement = $(this.componentRef.location.nativeElement.querySelector('input'));
    this.pickAdateElement.pickadate({
      editable: true,
      onOpen: this.checkPosition,
      min: new Date(1900, 0, 1),
      max: (this.maxToday ? new Date() : new Date(2099, 11, 31))
    });

    this.picker = this.pickAdateElement.pickadate('picker');
    $(this.pickAdateElement).on('click', (event) => {
        if (this.picker.get('open')) {
          this.picker.close();
        } else {
          this.picker.open();
        }
        event.stopPropagation();
    });
    this.picker.on({
      set: (thingSet) => {
        if (this.blockEvent) {
          this.blockEvent = false;
          return;
        }
        if (thingSet.select) {
          this.blockEvent = true;
          const selected = this.getPickerDate();
          this.ngModelChange.emit(selected);
        } else if (thingSet.clear === null) {
          this.blockEvent = true;
          this.ngModelChange.emit(null);
        }
      },
    });

    if (this.ngModel) {
      this.onModelChange(this.ngModel);
      this.componentRef.instance.dateInput = this.picker.get('select', 'd.m.yyyy');
    }

    if (this.placeholder) {
      this.componentRef.instance.placeholder = this.placeholder;
    }
  }

  getPickerDate(): string {
    return this.picker.get('select') ? this.picker.get('select', 'yyyy-mm-dd') : null;
  }

  checkPosition() {
    const pickerElement = $(this.dateSelectorElement.find('div.picker'));
    const dimensions = this.windowDimensionService.get(this.elementRef.nativeElement, 5);
    pickerElement.css({
      marginTop: (dimensions.windowHeight + dimensions.windowOffsetTop < this.pickAdateElement.offset().top + this.pickAdateElement.outerHeight() + pickerElement.outerHeight()
              && this.pickAdateElement.offset().top - dimensions.windowOffsetTop > pickerElement.outerHeight()
          ? -(pickerElement.outerHeight() + this.pickAdateElement.outerHeight()) + 'px' : 0),
      marginLeft: this.pickAdateElement.offset().left + pickerElement.outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft
              && pickerElement.outerWidth() > this.pickAdateElement.outerWidth()
          ? -(pickerElement.outerWidth() - this.pickAdateElement.outerWidth()) + 'px' : ''
    });
  }

  parseDate(input: string): Date {
    if (!input) {
        return null;
    }

    const parts: number[] = input.split('.').map((part) => isFinite(parseInt(part)) ? Number(part) : null);

    if (parts.length !== 3) {
        return undefined;
    }
    if (!isFinite(parts[2]) || parts[2] < 1000) {
        return undefined;
    }
    if (!isFinite(parts[1]) || parts[1] < 1 || parts[1] > 12) {
        return undefined;
    }
    if (!isFinite(parts[0]) || parts[0] < 1 || parts[0] > 31) {
        return undefined;
    }

    // sets date in local timezone
    const date = new Date(parts[2], parts[1]-1, parts[0]);
    if (date.getDate() !== parts[0] ||
            date.getMonth() + 1 !== parts[1] ||
            date.getFullYear() !== parts[2]) {
        return undefined;
    }

    return date;
  }
}
