import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { GeometricPlanRequestStatusEnum } from '@app/ps/enums/geometric-plan-request-status.enum';
import { CaseStatusModel } from '@app/ps/models/case-status.model';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { ModulesService } from '@app/common/services/modules.service';
import {
  NotificationConfigMappedModel,
  NotificationConfigModel
} from '@app/ps/models/notification-config.model';
import { User } from '@app/models/user';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { RoleModel } from '@app/common/models/role.model';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { EmailNotificationTypeEnum } from '@app/ps/enums/email-notification-type.enum';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'notification-config',
  templateUrl: './notification-config.component.html',
  styleUrls: ['./notification-config.component.scss']
})
export class NotificationConfigComponent implements OnInit {

  @Input() saveNotificationConfigRegister: Function;

  loading = true;
  module = this.authService.getActiveApplication();
  project = this.authService.getActualProject();
  roles: RoleModel[];
  users: User[];
  userSelected: User;
  caseStatuses: CaseStatusModel[];
  geometricPlanStatuses = [
    { key: GeometricPlanRequestStatusEnum.CREATED, name: 'Založení záznamu o GP' },
    { key: GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION, name: 'Koncept GP ke schválení' },
    { key: GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED, name: 'Odmítnutý koncept GP' },
    { key: GeometricPlanRequestStatusEnum.CONCEPT_APPROVED, name: 'Schválený koncept GP' },
    { key: GeometricPlanRequestStatusEnum.IMPORT_REQUESTED, name: 'Požadavek na vložení nového stavu dle GP do aplikace' },
    { key: GeometricPlanRequestStatusEnum.IMPORTED, name: 'GP zavedený do aplikace' },
    { key: GeometricPlanRequestStatusEnum.REGISTERED, name: 'GP zapsaný v KN (ÚR)' },
  ];
  emailNotificationTypes = [];
  emailNotificationTypesEnabled = false;
  geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
  notificationConfigsMapped: NotificationConfigMappedModel[];
  private notificationConfigs: NotificationConfigModel[];

  constructor(
    @Inject(RESTANGULAR_SETTINGS) public restangularSettings: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    public userNamePipe: UserNamePipe,
    private listService: ListService,
    private authService: AuthService,
    private modulesService: ModulesService,
  ) {
    this.reloadOptionsRegisterUser = this.reloadOptionsRegisterUser.bind(this);
    this.onUserSelected = this.onUserSelected.bind(this);
    this.onUserUnselected = this.onUserUnselected.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async ngOnInit() {
    this.roles = await this.restangularSettings.all(`${this.module}/roles`).getList().toPromise();
    this.users = await this.loadUsers();
    this.caseStatuses = await this.loadCaseStatuses();
    this.caseStatuses.forEach(s => {
      if (['SentInvestor', 'ApprovedInvestor'].includes(s.key)) {
        s.name += ' a krok zpět ze stavu';
      }
    });
    await this.loadNotificationConfigs();
    this.loading = false;

    if (this.saveNotificationConfigRegister) {
      this.saveNotificationConfigRegister(this.onSave);
    }

    if (this.authService.getActualProject().caseWorkflowAutoCancelEnabled) {
      this.emailNotificationTypes.push(
        { key: EmailNotificationTypeEnum.AUTO_CANCEL, name: 'Automatické zrušení případu pro nesoulad' }
      );
    }
    this.emailNotificationTypesEnabled = this.emailNotificationTypes.length > 0;
  }

  getStatusByConfig(config: NotificationConfigMappedModel): any {
    const caseStatus = _.find(this.caseStatuses, { key: config.statusKey });

    if (caseStatus) {
      return caseStatus;
    }

    const geometricPlanStatus = _.find(this.geometricPlanStatuses, { key: config.statusKey });

    if (geometricPlanStatus) {
      return geometricPlanStatus;
    }

    return _.find(this.emailNotificationTypes, { key: config.statusKey });
  }

  isRoleEnabled(config: NotificationConfigMappedModel, role: RoleModel): RoleModel {
    return _.find(config.roles, r => r.id === role.id);
  }

  onUserSelected(config: NotificationConfigMappedModel, user: any) {
    config.users.push(user);
    config.reloadOptionsUser();
    this.userSelected = undefined;
  }

  onUserUnselected(config: NotificationConfigMappedModel, user: any) {
    _.remove(config.users, u => u.id === user.id);
    config.reloadOptionsUser();
  }

  onToggleRole(config: NotificationConfigMappedModel, role: RoleModel) {
    if (this.isRoleEnabled(config, role)) {
      _.remove(config.roles, r => r.id === role.id);
    } else {
      config.roles.push(role);
    }
  }

  onToggleCustomUsers(config: NotificationConfigMappedModel) {
    config.customUsers = !config.customUsers;

    if (!config.customUsers) {
      _.remove(config.users);
    }
  }

  reloadOptionsRegisterUser(config: NotificationConfigMappedModel) {
    if (!config.reloadOptionsRegisterUser) {
      config.reloadOptionsRegisterUser = (reloadOptions) => {
        config.reloadOptionsUser = reloadOptions;
      };
      config.userFilter = (user: User) => {
        return !_.find(config.users, u => u.id === user.id);
      };
    }
    return config.reloadOptionsRegisterUser;
  }

  getUserRole(user: User): string {
    const role = _.find(user.roles, { projectKey: this.project.key });

    if (!role) {
      return '';
    }

    return role.roleDescription;
  }

  onSave(): Promise<any> {
    const configsMapped = this.mapToResource(this.notificationConfigsMapped);
    return this.modulesService.uploadProjectNotificationConfigs(this.project.key, configsMapped)
      .then(() => {
        return this.loadNotificationConfigs();
      });
  }

  private loadCaseStatuses(): Promise<CaseStatusModel[]> {
    const statuses = [ 'SentInvestor', 'ApprovedInvestor', 'DeclinedInvestor' ];

    if (this.APP_BRAND.NAME !== 'RSD') {
      statuses.push('SignedAllOwners');
    }

    const statusList = this.listService.createList(
      'case-statuses',
      { filters: { key:  statuses }, limit: null }
    );
    return this.listService.fetchResult(statusList).then(() => {
      return statusList.list;
    });
  }

  private async loadNotificationConfigs(): Promise<any> {
    this.notificationConfigs = await this.modulesService.getNotificationConfigs(this.module, this.project.key, undefined);
    this.notificationConfigsMapped = this.mapFromResource(this.notificationConfigs);
  }

  private loadUsers(): Promise<User[]> {
    const projectUsersFilter = {
      filters: {
        applications: {
          values: [ this.module ]
        },
        projects: {
          values: [ this.project.key ]
        },
      },
      limit: null,
    };

    const projectUserList = this.listService.createList('users/list', projectUsersFilter, this.restangularSettings);

    return this.listService.fetchResult(projectUserList).then(() => {
      return projectUserList.list;
    });
  }

  private mapFromResource(configs: NotificationConfigModel[]): NotificationConfigMappedModel[] {
    let result: NotificationConfigMappedModel[] = [];

    const populate = (user: User, role: RoleModel, statusKey: string, statusType: 'caseStatuses' | 'geometricPlanStatuses' | 'notificationTypes') => {
      const configMapped = _.find(result, { statusKey: statusKey });

      if (configMapped) {
        if (user) {
          configMapped.users.push(user);
        }
        if (role) {
          configMapped.roles.push(role);
        }
      } else {
        result.push({ statusKey: statusKey, statusType: statusType, users: (user ? [user] : []), roles: (role ? [role] : []) });
      }
    };

    configs.forEach((config: NotificationConfigModel) => {
      config.emailNotification.caseStatuses.forEach((statusKey: string) => populate(config.user, config.role, statusKey, 'caseStatuses'));
      config.emailNotification.geometricPlanStatuses.forEach((statusKey: string) => populate(config.user, config.role, statusKey, 'geometricPlanStatuses'));
      if (config.emailNotification.notificationTypes) {
        config.emailNotification.notificationTypes.forEach((statusKey: string) => populate(config.user, config.role, statusKey, 'notificationTypes'));
      }
    });

    const fillEmpty = (statusKey: string, statusType: 'caseStatuses' | 'geometricPlanStatuses' | 'notificationTypes') => {
      const exists = _.find(result, { statusKey: statusKey });
      if (!exists) {
        result.push({ statusKey: statusKey, statusType: statusType, users: [], roles: [] });
      }
    };

    this.caseStatuses.forEach(status => fillEmpty(status.key, 'caseStatuses'));
    this.geometricPlanStatuses.forEach(status => fillEmpty(status.key, 'geometricPlanStatuses'));
    this.emailNotificationTypes.forEach(status => fillEmpty(status.key, 'notificationTypes'));

    result = result.map(c => {
      c.customUsers = c.users.length > 0;
      return c;
    });

    return result;
  }

  private mapToResource(configsMapped: NotificationConfigMappedModel[]): NotificationConfigModel[] {
    const result: NotificationConfigModel[] = [];

    const populate = (type: string, entity: User | RoleModel, configMapped: NotificationConfigMappedModel) => {
      const matcher = (type === 'user'
        ? (c => c.user && c.user.id === (entity as User).id)
        : (c => c.role && c.role.id === (entity as RoleModel).id)
      );
      const config = _.find(result, matcher);

      if (config) {
        config.emailNotification[configMapped.statusType].push(configMapped.statusKey);
      } else {
        const newConfig = {
          project: this.project,
          emailNotification: { caseStatuses: [], geometricPlanStatuses: [], notificationTypes: [] }
        };
        newConfig[type] = entity;
        newConfig.emailNotification[configMapped.statusType].push(configMapped.statusKey);
        result.push(newConfig);
      }
    };

    configsMapped.forEach((configMapped: NotificationConfigMappedModel) => {
      configMapped.users.forEach((user: User) => populate('user', user, configMapped));
      configMapped.roles.forEach((role: RoleModel) => populate('role', role, configMapped));
    });

    result.forEach((config: NotificationConfigModel) => {
      let existing;
      if (config.user) {
        existing = _.find(this.notificationConfigs, c => c.user && c.user.id === config.user.id);
      }
      if (config.role) {
        existing = _.find(this.notificationConfigs, c => c.role && c.role.id === config.role.id);
      }
      if (existing) {
        config.id = existing.id;
      }

      config.projectKey = this.project.key;
      config.application = this.module;
    });

    return result;
  }
}
