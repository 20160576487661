import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { DocumentTypeModel } from '../../models/document-type.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { DocumentTypeCategoryEnum } from '@app/sv/enums/document-type-category.enum';
import { APP_BRAND } from '@app/common/services/config.service';

export interface DisabledDocumentType {
  documentType: string;
  reason: string;
}

@Component({
  selector: 'document-choose-type-form',
  templateUrl: './document-choose-type-form.component.html',
})
export class DocumentChooseTypeFormComponent implements OnInit {

  // model
  allowType: boolean;
  documentTypes: DocumentTypeModel[];
  selectedDocumentType: DocumentTypeModel = DocumentTypeModel.createById('OTHER');
  note = '';
  baseName = '';
  file: UploadFileExtended;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef,
  ) {
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    const data = this.dialogConfig.data as DialogConfigData;
    const documentCategory = data.documentCategory;
    const defaultDocumentType = data.defaultDocumentType;
    this.allowType = data.allowType;
    this.file = data.file;
    this.documentTypes = _.cloneDeep(DocumentTypeModel.getByCategory(documentCategory));
    if (this.APP_BRAND.NAME === 'RSD') {
      _.remove(this.documentTypes, (i) => i.id == DocumentTypeCategoryEnum.INFORMATION_LETTER);
    }

    // check disabled types
    for (const disabledDocumentType of (data.disabledDocumentTypes || [])) {
      const found = this.documentTypes.find(dt => dt.id === disabledDocumentType.documentType);
      if (found) {
        found.disabled = disabledDocumentType.reason;
      }
    }

    this.baseName = this.file.title;
    if (defaultDocumentType) {
      this.selectedDocumentType = defaultDocumentType;
    }
  }

  onUpdateType(documentType: DocumentTypeModel) {
    this.selectedDocumentType = documentType;
  }

  isValid() {
    return this.baseName && this.selectedDocumentType && (!this.selectedDocumentType.extensions || this.selectedDocumentType.extensions.includes(this.file.extension));
  }

  onSave() {
    this.dialogComponent.close({ type: this.selectedDocumentType.id, note: this.note, baseName: this.baseName });
  }

  onCancel() {
    this.dialogComponent.close(false);
  }
}
