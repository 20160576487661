import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { RoadNamePipe } from './road-name.pipe';

@Pipe({
  name: 'roadSectionName'
})
@Injectable({
  providedIn: 'root'
})
export class RoadSectionNamePipe implements PipeTransform {

  constructor(
    private roadNamePipe: RoadNamePipe,
  ) {
    this.transform = this.transform.bind(this);
  }

  transform(roadSection: any) {
    const roadName = this.roadNamePipe.transform(roadSection);
    return roadName ? roadName + ', Úsek č. ' + roadSection.poradiUs : null;
  }
}
