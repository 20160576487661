<div [loading]="loading">
  <div *ngIf="!loading">
    <textarea rows="120" [(ngModel)]="termsOfUse" name="string" class="tall" [disabled]="!editable"></textarea>
    <div class="mt-10">
      <ng-container *ngIf="!editable; else saveButton">
        <gmt-button [clickAction]="enableEditing" class="fr">Upravit</gmt-button>
      </ng-container>
    </div>
    <ng-template #saveButton>
      <gmt-button [clickAction]="saveTermsOfUse" class="fr" [ngClass]="{'disabled': !conditionsChanged()}">Uložit</gmt-button>
    </ng-template>
  </div>
</div>
