export enum TicketStatusEnum {
  CREATED = 'CREATED',
  SENT_TO_CONFIRMATION = 'SENT_TO_CONFIRMATION',
  REQUEST_FOR_CLARIFICATION = 'REQUEST_FOR_CLARIFICATION',
  CLARIFICATION_REQUEST_DONE = 'CLARIFICATION_REQUEST_DONE',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  TEMPLATE_SENT_TO_PROCESSING = 'TEMPLATE_SENT_TO_PROCESSING',
  REQUEST_FOR_INFORMATION = 'REQUEST_FOR_INFORMATION',
  INFORMATION_REQUEST_DONE = 'INFORMATION_REQUEST_DONE',
  IMPORTED = 'IMPORTED',
  DELETED = 'DELETED',
}

export const ticketStatusOptions = [
  {
    id: TicketStatusEnum.CREATED,
    name: 'Nový požadavek',
  },
  {
    id: TicketStatusEnum.SENT_TO_CONFIRMATION,
    name: 'Ke schválení',
    action: '',
  },
  {
    id: TicketStatusEnum.REQUEST_FOR_CLARIFICATION,
    name: 'Žádost o upřesnění požadavku',
    action: '',
  },
  {
    id: TicketStatusEnum.CLARIFICATION_REQUEST_DONE,
    name: 'Doplnění upřesnění požadavku',
    action: '',
  },
  {
    id: TicketStatusEnum.APPROVED,
    name: 'Schváleno',
  },
  {
    id: TicketStatusEnum.DISAPPROVED,
    name: 'Zamítnutí požadavku',
    action: '',
  },
  {
    id: TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING,
    name: 'Zpracování šablony',
  },
  {
    id: TicketStatusEnum.REQUEST_FOR_INFORMATION,
    name: 'Žádost o informaci k zpracování',
    action: '',
  },
  {
    id: TicketStatusEnum.INFORMATION_REQUEST_DONE,
    name: 'Doplnění informace ke zpracování',
    action: '',
  },
  {
    id: TicketStatusEnum.IMPORTED,
    name: 'Zavedeno do PS.TITLE',
  },
  {
    id: TicketStatusEnum.DELETED,
    name: 'Zrušený požadavek',
    action: 'Zrušit požadavek'
  },
];
