import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'entry-proposal',
  templateUrl: './entry-proposal.component.html',
  styleUrls: ['./entry-proposal.component.scss']
})
export class EntryProposalComponent implements OnInit {

  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  sendDataCase: any = {
    entryProposalDate: new Date(),
    cadastreEntryNumber: null
  };
  name: string;
  showEntryProposalForm = true;
  loadingFinished = false;
  sendProposal;
  deleteProposal;
  hasValidProposalCredentials;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onDateChanged = this.onDateChanged.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get().toPromise();
    this.name = (await this.caseStatusService.loadStatusName('EntryProposal')).name;
    if (this.data.obligation.type === 'TemporaryExpropriationLiability') {
      this.showEntryProposalForm = false;
      this.onLoad();
    }
    this.loading = false;
  }

  onDateChanged(value) {
    const duePeriod = this.data.obligation.duePeriod;
    const dueDataFromType = this.data.obligation.dueDateFromType;

    if (!this.data.paymentOrderDate && duePeriod && dueDataFromType === 'EntryProposalDate') {
      if (value) {
        const date = new Date(value);
        this.data.dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
      } else {
        this.data.dueDate = null;
      }
    }
  }

  hasValidProposalDate() {
    return this.sendDataCase.entryProposalDate;
  }

  onLoad() {
    this.loadingFinished = true;
  }

  formCallbackRegister(event) {
    this.sendProposal = event.submit;
    this.deleteProposal = event.delete;
    this.hasValidProposalCredentials = event.isValid;
  }

  confirmProposal = () => {
    return this.restangular.one('cases', this.caseId)
      .customPUT(this.sendDataCase, 'entry-proposal')
      .toPromise()
      .then(() => {
        this.stateService.go('^');
      });
  };
}
