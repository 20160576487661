import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

import { RESTANGULAR_PROMAP } from '@app/common/services/restangular-promap.service';
import { CadastreImportModel } from '@app/settings/modules/models/cadastre-import.model';

@Component({
  selector: 'cadastre-data-import-form',
  templateUrl: './cadastre-data-import-form.component.html',
  styleUrls: ['./cadastre-data-import-form.component.scss'],
})
export class CadastreDataImportFormComponent implements OnInit {
  @Output() importAdded: EventEmitter<CadastreImportModel> = new EventEmitter<CadastreImportModel>();

  public cadastreImport: CadastreImportModel = {
    url: 'https://bd.cuzk.cz/owncloud/index.php/s/7QpYxcdNpXEP0Bp',
    password: '',
    folder: '',
    vfkVersion: '6.1',
    cadastreCodes: '',
  };

  constructor(
    @Inject(RESTANGULAR_PROMAP) public restangularPromap: any,
  ) { }

  ngOnInit() {
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(): Promise<CadastreImportModel> {
    const data: CadastreImportModel = {...this.cadastreImport};
    data.kuIdsToDownload = data.cadastreCodes;
    delete data.cadastreCodes;

    if (typeof data.vfkVersion === 'string') {
      data.vfkVersion = data.vfkVersion.replace('.', '');
    }

    const newImport = await this.restangularPromap.all('cuzk/data-import').customPOST(data, 'new').toPromise();
    this.importAdded.emit(newImport);

    return newImport;
  }

  isValid(): boolean {
    const requiredFieldsValid =  this.cadastreImport.url && this.cadastreImport.password && this.cadastreImport.folder && this.cadastreImport.vfkVersion;
    const cadastreCodesEmpty = !this.cadastreImport.cadastreCodes || this.cadastreImport.cadastreCodes.length === 0;
    const cadastreCodesValid = cadastreCodesEmpty || this.cadastreCodesToArray(this.cadastreImport.cadastreCodes).every(this.isCadastreCodeValid);

    return requiredFieldsValid && cadastreCodesValid;
  }

  private cadastreCodesToArray(codes: string): string[] {
    const SEPARATOR = ',';

    return codes.split(SEPARATOR);
  }

  private isCadastreCodeValid(code: string): boolean {
    const VALID_CADASTRE_CODE_LENGTH = 6;
    const isSixCharsLong = code.length === VALID_CADASTRE_CODE_LENGTH;
    const isFiniteNumber = _.isFinite(_.toNumber(code));

    return isFiniteNumber && isSixCharsLong;
  }
}
