import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { ContractDetailComponent } from '@app/settings/customers/components/contract-detail/contract-detail.component';
import { ContractFormComponent } from '@app/settings/customers/components/contract-form/contract-form.component';
import { ContractListComponent } from '@app/settings/customers/components/contract-list/contract-list.component';
import { CustomerFormComponent } from '@app/settings/customers/components/customer-form/customer-form.component';
import { OwnersModule } from '@app/ps/owners/owners.module';
import { UsersModule } from './users/users.module';
import { ProjectFormComponent } from './customers/components/project-form/project-form.component';
import { ProjectListComponent } from '@app/settings/customers/components/project-list/project-list.component';
import { CustomerDetailComponent } from './customers/components/customer-detail/customer-detail.component';
import { CustomerEditComponent } from './customers/components/customer-edit/customer-edit.component';
import { MainComponent } from '@app/settings/main/main.component';
import { CustomerListComponent } from '@app/settings/customers/components/customer-list/customer-list.component';

import {
  settingsState,
  customerListState,
  customerContractDetailState,
  customerContractEditState,
  customerContractProjectState,
  customerContractState,
  customerContractAbstractState,
  customerCreateState,
  customerDetailState,
  customerEditState,
} from '@app/settings/settings.states';
import { ModulesModule } from '@app/settings/modules/modules.module';
import { UIRouterModule } from '@uirouter/angular';

const SETTINGS_STATES = [
  settingsState,
  customerListState,
  customerContractDetailState,
  customerContractEditState,
  customerContractProjectState,
  customerContractState,
  customerContractAbstractState,
  customerCreateState,
  customerDetailState,
  customerEditState,
];

@NgModule({
  declarations: [
    MainComponent,
    CustomerListComponent,
    ContractFormComponent,
    ContractListComponent,
    ContractDetailComponent,
    ProjectListComponent,
    ProjectFormComponent,
    CustomerFormComponent,
    CustomerDetailComponent,
    CustomerEditComponent,
  ],
  entryComponents: [
    MainComponent,
    CustomerListComponent,
    ContractListComponent,
    ContractDetailComponent,
    ContractFormComponent,
    CustomerFormComponent,
    ProjectFormComponent,
  ],
  imports: [
    CommonModule,
    OwnersModule,
    ModulesModule,
    UsersModule,
    UIRouterModule.forChild({
      states: [...SETTINGS_STATES],
    }),
  ],
  providers: [],
})
export class SettingsModule { }
