<div>
  <div class="row">
    <div class="col-6">
      <h2 class="text-uppercase">Informace uživatelům</h2>
    </div>
    <div *ngIf="canEdit" class="col-6 text-right">
      <a (click)="onEdit()" class="button"><i class="fa fa-plus-square"></i> Přidat aktualitu</a>
    </div>
  </div>

  <div [loading]="list.loading">


    <table *ngIf="list.itemCount" class="table bordered article-table">
      <tbody>
        <tr *ngFor="let article of list.list">
          <td class="article-column">
            <div class="article-preview">
              <a *ngIf="canEdit" (click)="onEdit(article)" class="button edit"><i class="fa fa-pencil"></i></a>
              <a *ngIf="canEdit" (click)="onRemove(article)" class="button remove red"><i class="fa fa-trash"></i></a>
              <h1>
                <a (click)="article.collapsed = !!!article.collapsed">
                  <i class="fa fa-caret-{{ article.collapsed === undefined ? 'down' : (article.collapsed ? 'right' : 'down') }}"></i>
                </a>
                <ng-container *ngIf="article.publishDate">{{ article.publishDate | date:'mediumDate' }} - </ng-container>
                <ng-container *ngIf="!article.publishDate">V přípravě - </ng-container>
                {{ article.title }}
              </h1>
              <div *ngIf="article.content && (article.collapsed === undefined || !article.collapsed)" class="article-preview-content ck-content" [innerHTML]="article.content | safeHtml"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
      Nenalezena žádná data
    </div>
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    [shouldUpdateUrl]="false"
    (pageChanged)="pageChanged($event)"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>
