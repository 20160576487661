<h1>{{ this.expert.id ? 'Editace odhadce' : 'Založení odhadce' }}</h1>

<div>
  <fieldset>
    <div class="column-50">
      <gmt-input label="Titul před jménem" [required]="false">
        <input type="text" [(ngModel)]="expert.titulPredJmenem"/>
      </gmt-input>

      <gmt-input label="Křestní jméno" [required]="false">
        <input type="text" [(ngModel)]="expert.jmeno"/>
      </gmt-input>

      <gmt-input label="Příjmení" [required]="true">
        <input type="text" [(ngModel)]="expert.prijmeni"/>
      </gmt-input>

      <gmt-input label="Titul za jménem" [required]="false">
        <input type="text" [(ngModel)]="expert.titulZaJmenem"/>
      </gmt-input>
    </div>

    <div class="column-50">
      <gmt-input label="Ulice" [required]="false">
        <input type="text" [(ngModel)]="expert.ulice"/>
      </gmt-input>

      <gmt-input label="Číslo orientační" [required]="false">
        <input type="text" [(ngModel)]="expert.cisloOrientacni"/>
      </gmt-input>

      <gmt-input label="Číslo popisné" [required]="false">
        <input type="text" [(ngModel)]="expert.cisloDomovni" gmtInputNumber/>
      </gmt-input>

      <gmt-input label="Město" [required]="false">
        <input type="text" [(ngModel)]="expert.obec"/>
      </gmt-input>

      <gmt-input label="PSČ" [required]="false">
        <input type="text" [(ngModel)]="expert.psc" gmtInputPostCode/>
      </gmt-input>
    </div>
  </fieldset>
</div>

<div class="text-center pt-20">
  <gmt-button [clickAction]="onSave" [gmtDisabled]="!isValid()">Uložit</gmt-button>
  <gmt-button [clickAction]="onCancel" class="red">Zrušit</gmt-button>
</div>
