<div class="detail">
	<div>
		<a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>{{processDefinition.getBackButtonText()}}</a>
	</div>
	<h1>
		Hromadná operace
		<span>{{processDefinition.getTypeName()}}</span>
	</h1>

	<div class="mt-20">
		<div class="flexbox-container">
			<div class="content-menu">
				<div *ngFor="let step of steps" (click)="clickStep(step)" [ngClass]="{active : activeStep === step, cancelled: !step.enabled || (sent && activeStep !== step)}">
					{{step.name}}
				</div>
			</div>
			<div>
				<div class="mb-10">
					<gmt-button [gmtDisabled]="!isNextEnabled()" [clickAction]="nextStatus" *ngIf="!finalStep && !done">Další</gmt-button>
				</div>
				<div>
					<ng-template bulk-operation-step-host></ng-template>
				</div>
				<div class="mt-10">
					<gmt-button [gmtDisabled]="!isNextEnabled()" [clickAction]="nextStatus" *ngIf="!done">{{finalStep ? 'Potvrdit' : 'Další'}}</gmt-button>
				</div>
			</div>
		</div>
	</div>
</div>
