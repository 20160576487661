import { Component, Input, OnInit } from '@angular/core';
import { ParcelModel } from '@app/common/models/parcel.model';

@Component({
  selector: 'parcel-costs-form',
  templateUrl: './parcel-costs-form.component.html',
})
export class ParcelCostsFormComponent implements OnInit {

  // model
  @Input() parcel: ParcelModel;

  ngOnInit() {}
}
