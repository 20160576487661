import { Inject, Injectable } from '@angular/core';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { NewMapConfigService } from '@app/map/services/new-map-config.service';
import { WordService } from "@app/common/services/word.service";
import { AuthService } from '@app/common/services/auth.service';
import { LayersConfigService } from '@app/map/services/layers-config.service';
import { APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { VectorLayerStyleModel } from '@app/ps/map/models/vector-layer-style.model';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import { MapModule } from '@app/map/map.module';
import * as  L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MapConfigService {

  private zIndex = 0;

  constructor(
    private wordService: WordService,
    private authService: AuthService,
    private layersConfigService: LayersConfigService,
    private newMapConfigService: NewMapConfigService,
    private mapUtilsCrsService: MapUtilsCrsService,
    @Inject(APP_CONFIG) private config: any,
    @Inject(APPLICATIONS) private APPLICATIONS: any,
  ) {
    this.setBaseConfigVariables = this.setBaseConfigVariables.bind(this);
    this.setLayers = this.setLayers.bind(this);
  }

  /**
   * Get a recursive copy of config, replage layers IDs by Layer config object.
   * Využívá se jak v modulu nastavení, kde jsou vrstvy pouze jako identifikátory, ale také před zobrazením mapového okna,
   * kde se načítá prve nastavení z BE, které limituje vrstvy na výstupu
   *
   * @param {Object} config
   * @param {string} mapId
   */
  async setLayers(config, mapId) {
    let returnConfig;
    if (!config.type || config.type !== MapLayerTypeEnum.node) {
      returnConfig = this.layersConfigService.getLayerById(config.id || config, mapId);
      if (!returnConfig) {
        return undefined;
      }
      await this.setBaseConfigVariables(returnConfig, config.id ? config : returnConfig);
    } else {
      returnConfig = Object.assign({}, config);
    }
    switch (returnConfig.type) {
      case MapLayerTypeEnum.node:
        const layers = [];
        for (const l of returnConfig.layers) {
          layers.push(await this.setLayers(l, mapId));
        }
        returnConfig.layers = layers.filter(l => l);
        break;
      default:
        ++this.zIndex;
    }

    return returnConfig;
  }

  /**
   * Get a recursive copy of config, strip all layers that are of type wfs or wms and are not included in userLayers
   * Set layer visibility on the way
   *
   * @param {Object} config
   * @param {Object} userLayers
   * @returns {null|Object} config
   */
  filterMapConfig(config, layerGroups) {
    if (config.auth && !this.authService.hasPermission(config.auth)) {
      return null;
    }
    switch (config.type) {
      case MapLayerTypeEnum.node:
        if (config.id) {
          const layerConfig = this.getLayerConfigByLayerID(config.id, layerGroups);
          config.title = layerConfig && layerConfig.title ? layerConfig.title : config.title;
        }
        let returnLayers = [];
        for (const layerConfig of config.layers) {
          const cfg = this.filterMapConfig(layerConfig, layerGroups);
          if (cfg) {
            cfg.parent = config;
            returnLayers.push(cfg);
          }
        }

        if (returnLayers.length > 0) {
          config.layers = returnLayers;
          return config;
        }
        break;
      case MapLayerTypeEnum.wfs:
      case MapLayerTypeEnum.wms:
      case MapLayerTypeEnum.wmts:
        //get layer config by layerID
        const layerConfig = this.getLayerConfigByLayerID(config.id, layerGroups);
        if (layerConfig) {
          config.title = config.origTitle === config.title && layerConfig.title ? layerConfig.title : config.title;
          return config
        }
        break;
    }
    return null;
  }

  /**
   * Applies func recursively for every layer in config.
   * @param layerConfig
   * @param func
   */
  forEachLayer(layerConfig, func) {
    func(layerConfig);

    if (layerConfig.type === MapLayerTypeEnum.node) {
      for (let i = 0; i < layerConfig.layers.length; i++) {
        this.forEachLayer(layerConfig.layers[i], func);
      }
    }
  }

  getLayerConfigByLayerID(layerID, userLayers) {
    if (this.authService.getActiveApplication() === this.APPLICATIONS.sy.name) {
    // symap
      return this.newMapConfigService.getLayerById(layerID, userLayers)
    // dimap
    } else {
      return userLayers.find((layerConfig) => layerConfig.mapPK ? layerConfig.mapPK.layerId === layerID : layerConfig.id === layerID);
    }
  }

  setBaseConfigVariables(layerConfig, userConfig) {
    const title = layerConfig.title;
    return this.wordService.replaceVariablesInText(title).then((tr) => {
      layerConfig.visible = !!userConfig.visible;
      if (layerConfig[layerConfig.type]) {
        layerConfig[layerConfig.type].zIndex = this.zIndex;
        if ((this.config.BACKEND_OPTIONS.geoserverUrl && layerConfig.url.startsWith(this.config.BACKEND_OPTIONS.geoserverUrl)) || (this.config.BACKEND_OPTIONS.geoserverDIUrl && layerConfig.url.startsWith(this.config.BACKEND_OPTIONS.geoserverDIUrl))) {
          layerConfig[layerConfig.type].t = this.authService.getToken();
          layerConfig[layerConfig.type].p = this.authService.getActualProject().key;
        }
        layerConfig.style = userConfig.style;
      }
      if (layerConfig.type != MapLayerTypeEnum.node) {
        layerConfig.zIndex = this.zIndex;
      }
      layerConfig.title = tr || layerConfig.title;
      layerConfig.origTitle = title;

      if (layerConfig.type === 'wfs' && layerConfig.geojson && layerConfig.geojson.style) {
        if (layerConfig.style) {
          layerConfig.geojson.style.color = `rgb(${userConfig.style.stroke.r}, ${userConfig.style.stroke.g}, ${userConfig.style.stroke.b})`;
          layerConfig.geojson.style.opacity = userConfig.style.stroke.a;

          layerConfig.geojson.style.fillColor = `rgb(${userConfig.style.fill.r}, ${userConfig.style.fill.g}, ${userConfig.style.fill.b})`;
          layerConfig.geojson.style.fillOpacity = userConfig.style.fill.a;
        } else {
          layerConfig.style = VectorLayerStyleModel.fromRgbStrings(layerConfig.geojson.style.fillColor, layerConfig.geojson.style.color);
          layerConfig.style.fill.a = layerConfig.geojson.style.fillOpacity;
          layerConfig.style.stroke.a = layerConfig.geojson.style.opacity;
        }
      }

      return layerConfig;
    });
  }

  processCustomCRS(layers) {
    const customCRS = {};
    layers.forEach(obj => {
      if (obj.crs) {
        const crsName = 'EPSG:' + obj.crs.srid + '_' + obj.crs.name;
        if (!customCRS[crsName]) {
          const crs = this.mapUtilsCrsService.getCrs(5514);
          customCRS[crsName] = {
             startZoom: obj.crs.startZoom || 0,
             crs: new L.Proj.CRS(crsName, MapModule.SRID_DEFS[obj.crs.srid], {
               resolutions: crs.options.resolutions,
               bounds: obj.crs.bounds,
               origin: obj.crs.origin,
             })
           };
        }
      } else if (obj.layers) { Object.assign(customCRS, this.processCustomCRS(obj.layers)); }
    });
    return customCRS;
  }
}

