import { GeometricPlanRequestModel } from '@app/ps/models/geometric-plan-request.model';
import { Inject, Injectable } from '@angular/core';
import { NoteModel } from '@app/common/models/note.model';
import { geometricPlanRequestTypeOptions } from '@app/ps/enums/geometric-plan-request-type.enum';
import { geometricPlanRequestStatusOptions } from '@app/ps/enums/geometric-plan-request-status.enum';
import { approveOptions } from '@app/ps/enums/approve.enum';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { AuthService } from '@app/common/services/auth.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Injectable({
  providedIn: 'root'
})
export class GeometricPlanRequestService {
  constructor(
    private uploadService: UploadService,
    private restangular: Restangular,
    private authService: AuthService,
  ) {
  }

  create(geometricPlanRequest: GeometricPlanRequestModel, notes: NoteModel[] = []): Promise<GeometricPlanRequestModel> {
    let createdGeometricPlanRequest: GeometricPlanRequestModel;
    return this.restangular
      .all('geometric-plan-requests/create')
      .customPOST(
        geometricPlanRequest,
        '',
        { loadCollections: [ 'areas', 'constructionObjects', 'parcels' ] }
      )
      .toPromise()
      .then(response => {
        createdGeometricPlanRequest = response.plain();
        return this.createNotes(createdGeometricPlanRequest, notes);
      })
      .then(() => createdGeometricPlanRequest);
  }

  createFromExisting(
    geometricPlanRequest: GeometricPlanRequestModel,
    gpFiles: UploadFileExtended[] = [],
    gpVfkFiles: UploadFileExtended[] = [],
    gpDocFiles: UploadFileExtended[] = [],
  ): Promise<GeometricPlanRequestModel> {
    let createdGeometricPlanRequest: GeometricPlanRequestModel;
    return this.restangular
      .all('geometric-plan-requests/create-from-existing')
      .customPOST(
        geometricPlanRequest,
        '',
        { loadCollections: [ 'areas' ] }
      )
      .toPromise()
      .then(response => {
        createdGeometricPlanRequest = response.plain();
      })
      .then(() => this.createDocuments(createdGeometricPlanRequest, gpFiles, 'GEOMETRIC_PLAN'))
      .then(() => this.createDocuments(createdGeometricPlanRequest, gpVfkFiles, 'GEOMETRIC_PLAN_VFK'))
      .then(() => this.createDocuments(createdGeometricPlanRequest, gpDocFiles, 'GEOMETRIC_PLAN_DOCUMENTATION'))
      .then(() => createdGeometricPlanRequest);
  }

  get(id: number, collections = []): Promise<GeometricPlanRequestModel> {
    return this.restangular
      .one('geometric-plan-requests', id)
      .get({ loadCollections: collections })
      .toPromise();
  }

  update(
    geometricPlanRequest: GeometricPlanRequestModel,
    notes: NoteModel[] = [],
    gpFiles: UploadFileExtended[] = [],
    gpVfkFiles: UploadFileExtended[] = [],
    gpDocFiles: UploadFileExtended[] = [],
    gpOtherFiles: UploadFileExtended[] = [],
  ): Promise<GeometricPlanRequestModel> {
    let updatedGeometricPlanRequest: GeometricPlanRequestModel;
    return this.restangular
      .one('geometric-plan-requests', geometricPlanRequest.id)
      .customPUT(
        geometricPlanRequest,
        '',
        { loadCollections: [ 'areas', 'constructionObjects', 'parcels' ] }
      )
      .toPromise()
      .then(response => {
        updatedGeometricPlanRequest = response.plain();
        return this.createNotes(updatedGeometricPlanRequest, notes);
      })
      .then(() => this.createDocuments(updatedGeometricPlanRequest, gpFiles, 'GEOMETRIC_PLAN'))
      .then(() => this.createDocuments(updatedGeometricPlanRequest, gpVfkFiles, 'GEOMETRIC_PLAN_VFK'))
      .then(() => this.createDocuments(updatedGeometricPlanRequest, gpDocFiles, 'GEOMETRIC_PLAN_DOCUMENTATION'))
      .then(() => this.createDocuments(updatedGeometricPlanRequest, gpOtherFiles, 'OTHER'))
      .then(() => updatedGeometricPlanRequest);
  }

  createNotes(geometricPlanRequest: GeometricPlanRequestModel, notes: NoteModel[] = []): Promise<any> {
    const promises = notes.map(note => {
      return this.restangular
        .all('notes/create')
        .customPOST({ ...note, geometricPlanRequest: geometricPlanRequest })
        .toPromise();
    });
    return Promise.all(promises);
  }

  createDocuments(geometricPlan: GeometricPlanRequestModel, files: UploadFileExtended[], type: string): Promise<any> {
    const restBaseUrl = this.authService.getActiveApplicationRestUrl();
    const attachmentType = DocumentTypeModel.createById(type).id;
    const deletePromises = [];

    if (files.length && ['GEOMETRIC_PLAN', 'GEOMETRIC_PLAN_VFK', 'GEOMETRIC_PLAN_DOCUMENTATION'].includes(type) && geometricPlan.id) {
      const promise = this.restangular
        .all('attachments')
        .customPOST({ filter: { filters: { geometricPlanRequestId: geometricPlan.id, cancelled: false, attachmentType: type }}})
        .toPromise()
        .then((data => {
          data.forEach((d) => {
            this.restangular.one('attachments', d.id).remove();
          });
        }));

      deletePromises.push(promise);
    }

    const uploadPromises = files.map(file => {
      const sendData = {
        file: file.fileEntry,
        filename: file.fileEntry.name,
        geometricPlanRequestId: geometricPlan.id,
        attachmentType: attachmentType
      };
      return this.uploadService.upload({
        url: restBaseUrl + '/attachments',
        data: sendData,
        headers: { Authorization: this.authService.getToken() }
      });
    });

    return Promise.all(deletePromises.concat(uploadPromises));
  }

  getEnumLabel(type: 'type' | 'status' | 'approve', value: string): string {
    let src;

    switch (type) {
      case 'type':
        src = geometricPlanRequestTypeOptions;
        break;
      case 'status':
        src = geometricPlanRequestStatusOptions;
        break;
      case 'approve':
        src = approveOptions;
        break;
    }
    return src.find(option => option.id === value).name;
  }

  getStatusEnumAction(value: string): string {
    const option = geometricPlanRequestStatusOptions.find(option => option.id === value);
    return (option.action ? option.action : option.name);
  }

  isStatusDialog(status: string): boolean {
    return geometricPlanRequestStatusOptions.find(option => option.id === status).dialog;
  }
}
