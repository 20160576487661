import { Component, OnInit } from '@angular/core';
import { createAgent, ProjectSubjectModel } from '@app/ps/models/project-subject.model';
import {DialogConfig, DialogConfigData} from "@app/common/models/dialog-config";
import {DialogRef} from "@app/common/services/dialog-ref";

@Component({
  selector: 'project-subject-agent',
  templateUrl: './project-subject-agent.component.html',
  styleUrls: []
})
export class ProjectSubjectAgentComponent implements OnInit {

  agent: ProjectSubjectModel;

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
  ) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    const inputAgent = (this.dialogConfig.data as DialogConfigData).agent;
    this.agent = inputAgent ? inputAgent : createAgent();
    this.agent.showPublicationNote = this.agent.showPublicationNote || false;
    this.agent.showNoPublicationNote = this.agent.showNoPublicationNote || false;
    this.agent.publicationNoteTemplate = this.agent.publicationNoteTemplate === undefined ? 'Tato smlouva byla uveřejněna v registru smluv pod id [ID]' : this.agent.publicationNoteTemplate;
    this.agent.noPublicationNoteTemplate = this.agent.noPublicationNoteTemplate === undefined ? 'Tato smlouva nebyla uveřejněna v registru smluv. Výjimka: [VYJIMKA]' : this.agent.noPublicationNoteTemplate;
    this.agent.easementDescription = this.agent.easementDescription === undefined
      ? 'Věcné břemeno - služebnost inženýrské sítě spočívající v právu zřídit, provozovat a udržovat na pozemku inženýrskou síť'
      : this.agent.easementDescription;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.agent);
  }
}
