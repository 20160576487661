﻿<div class="pagination" *ngIf="totalPages > 1">
	<div>
		<a *ngIf="!shouldUpdateUrl && currentPage !== 1" [ngClass]="['not-underline']" (click)="showPage(currentPage - 1);" title="Předchozí">
			<i class="fa fa-chevron-left"></i>
		</a>
		<a *ngIf="shouldUpdateUrl && currentPage !== 1" uiSref="." [uiParams]="{page: currentPage - 1}" [ngClass]="['not-underline']" title="Předchozí">
			<i class="fa fa-chevron-left"></i>
		</a>
		<ul>
			<li *ngIf="!shouldUpdateUrl" [ngClass]="{'active': currentPage == 1}">
				<a (click)="showPage(1)" [ngClass]="{'not-underline': currentPage == 1}">1</a>
			</li>
			<li *ngIf="shouldUpdateUrl" [ngClass]="{'active': currentPage == 1}">
				<a uiSref="." [uiParams]="{page: 1}" [ngClass]="{'not-underline': currentPage == 1}">1</a>
			</li>
			<li *ngIf="pages().length && (pages()[0] - 1)  !== 1" class="fa fa-ellipsis-h"></li>
			<li *ngFor="let i of pages()" [ngClass]="{'active': currentPage == i}">
				<a *ngIf="!shouldUpdateUrl" (click)="showPage(i)" [ngClass]="{'not-underline': currentPage == i}">{{ i }}</a>
				<a *ngIf="shouldUpdateUrl" uiSref="." [uiParams]="{page: i}" [ngClass]="{'not-underline': currentPage == i}">{{ i }}</a>
			</li>
			<li *ngIf="pages().length && (pages()[pages().length - 1] + 1)  !== totalPages" class="fa fa-ellipsis-h"></li>
			<li *ngIf="totalPages > 1" [ngClass]="{'active': currentPage == totalPages}">
				<a *ngIf="!shouldUpdateUrl" (click)="showPage(totalPages)" [ngClass]="{'not-underline': currentPage == totalPages}">{{ totalPages }}</a>
				<a *ngIf="shouldUpdateUrl" uiSref="." [uiParams]="{page: totalPages}" [ngClass]="{'not-underline': currentPage == totalPages}">{{ totalPages }}</a>
			</li>
		</ul>
		<a *ngIf="!shouldUpdateUrl && currentPage !== totalPages" (click)="showPage(currentPage + 1)" [ngClass]="['not-underline']" title="Další">
			<i class="fa fa-chevron-right"></i>
		</a>
		<a *ngIf="shouldUpdateUrl && currentPage !== totalPages" uiSref="." [uiParams]="{page: currentPage + 1}" [ngClass]="['not-underline']" title="Další">
			<i class="fa fa-chevron-right"></i>
		</a>
	</div>
</div>