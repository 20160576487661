import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { StatisticsStatusModel } from '@app/ps/models/statistics-status.model';
import { CaseStatusModel } from '@app/ps/models/case-status.model';
import { CaseStatusEnum } from '@app/ps/enums/case-status.enum';

@Component({
  selector: 'statistics-settings-status-edit',
  styleUrls: ['./statistics-settings-status-edit.component.scss'],
  templateUrl: './statistics-settings-status-edit.component.html',
})
export class StatisticsSettingsStatusEditComponent implements OnInit {

  caseStatus: StatisticsStatusModel;
  statuses: CaseStatusModel[];
  requiredStatuses: { name: string; key: CaseStatusEnum; negation?: boolean }[] = [];
  reloadFn: Function;

  constructor(
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef,
  ) {
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.reloadRegister = this.reloadRegister.bind(this);
  }

  ngOnInit() {
    this.caseStatus = (<DialogConfigData>this.dialogConfig.data).caseStatus;
    this.caseStatus.requiredStatuses = this.caseStatus.requiredStatuses || [];
    this.statuses = (<DialogConfigData>this.dialogConfig.data).statuses.filter(status => status.key != this.caseStatus.status);
    this.caseStatus.requiredStatuses.forEach(requiredStatus => {
      const status: CaseStatusModel = this.statuses.find(status => status.key === requiredStatus.status);
      if (status) {
        this.requiredStatuses.push({
          key: status.key,
          name: status.name,
          negation: requiredStatus.negation,
        });
      }
    });
  }

  selectedStatus(statusSelected : CaseStatusModel, data : any) {
    data.selected = null;
    this.requiredStatuses.push({
      name: statusSelected.name,
      key: statusSelected.key,
      negation: false,
    });

    _.remove(this.statuses, status => status.key === statusSelected.key);
    this.reloadFn();
  }

  remove(requiredStatus) {
    _.pull(this.requiredStatuses, requiredStatus);
    this.statuses.push({
      name: requiredStatus.name,
      key: requiredStatus.key,
      commonName: null,
      id: null,
    });
    this.reloadFn();
  }

  reloadRegister(reloadFn: Function) {
    this.reloadFn = reloadFn;
  }

  isValid() {
    return true;
  }

  onUpdate() {
    this.caseStatus.requiredStatuses = this.requiredStatuses.map(status => {
      return {
        status: status.key,
        negation: status.negation,
      }
    });
    this.dialogComponent.close({ caseStatus: this.caseStatus });
  }

  onCancel() {
    this.dialogComponent.close();
  }
}
