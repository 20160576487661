<div>
  <h2 class="pb-0">Log aktivit</h2>

  <div [loading]="list.loading">
    <div class="table-top-container">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <div *ngIf="list.itemCount" class="bordered">
      <table class="table">
        <thead>
          <tr>
            <th class="cell">
              <sort-by
                [selected]="'time' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('time', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Datum</sort-by>
            </th>
            <th class="cell">
              <sort-by
                [selected]="'user' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('user', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Uživatel</sort-by>
            </th>
            <th class="cell">
              <sort-by
                [selected]="'type' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('type', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Typ</sort-by>
            </th>
            <th class="cell">
              <sort-by
                [selected]="'validity' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('validity', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Platnost</sort-by>
            </th>
            <th class="cell">
              <sort-by
                [selected]="'promapProjectId' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('promapProjectId', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >ID projektu promap</sort-by>
            </th>
            <th class="cell">Detaily</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let activity of list.list" class="hover">
            <td class="cell">
              {{ activity.time | date:'dd.MM.yyyy HH:mm' }}
            </td>
            <td class="cell">
              {{ activity.user | username }}
            </td>
            <td class="cell">
              {{ activity.type }}
            </td>
            <td class="cell">
              {{ activity.validity | date }}
            </td>
            <td class="cell">
              {{ activity.promapProjectId }}
            </td>
            <td class="cell">
              {{ activity.details }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!list.itemCount && list.list !== null" class="empty-info bordered">
      Nenalezena žádná data
    </div>
  </div>

  <itemscounter
    *ngIf="list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list.itemCount && list.list !== null"
    [list]="list"
    [shouldUpdateUrl]="false"
    (pageChanged)="pageChanged($event)"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>
