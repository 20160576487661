import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

import { DialogService } from '@app/common/services/dialog.service';
import { ConfirmationComponent } from '@app/common/components/confirmation/confirmation.component';
import { ClassName } from '@app/common/enums/class-name.enum';
import { ListModel } from '@app/common/models/list.model';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { DocumentModel } from '../../models/document.model';
import { DocumentEditFormComponent } from '../document-edit-form/document-edit-form.component';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { DisabledDocumentType, DocumentChooseTypeFormComponent } from '@app/common/components/document-choose-type-form/document-choose-type-form.component';
import { FileUtils } from '@app/common/utils/file.utils';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';

@Component({
  selector: 'gmt-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public static readonly VIEWER_URL = window.location.pathname + `pdf-viewer/web/viewer.html?file=`;

  // bindings
  @Input() defaultData: any = {};
  @Input() defaultDocumentType: DocumentTypeModel;
  @Input() documentList: ListModel<DocumentModel>;
  @Input() allowType: boolean;
  @Input() maxHeight: number;
  @Input() authorizedUser: boolean;
  @Input() filesSelectSetter: any;
  @Input() draggable = true;
  @Input() documentCategory: 'case' | 'gp' | 'o' | 'subject' | 'sample' = 'case';
  @Input() disabledDocumentTypes: DisabledDocumentType[];
  @Output() updated = new EventEmitter<boolean>();

  // model
  restBaseUrl: string;
  authToken: string;
  uploadingFiles: UploadFileExtended[] = [];

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private listService: ListService,
    private authService: AuthService,
    private dialogService: DialogService
  ) {
    this.onSort = this.onSort.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onFileSelectExternal = this.onFileSelectExternal.bind(this);
    this.onEditDocument = this.onEditDocument.bind(this);
    this.onDeleteDocument = this.onDeleteDocument.bind(this);
    this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
    this.checkCancelled = this.checkCancelled.bind(this);
  }

  ngOnInit() {
    if (this.filesSelectSetter) {
      this.filesSelectSetter(this.onFileSelectExternal);
    }
    this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
    this.authToken = this.authService.getToken();
  }

  async onFileSelect(files: UploadFileExtended[]) {
    if (files.length === 1) {
      const dialog = this.dialogService.open(DocumentChooseTypeFormComponent, {
        data: {
          allowType: this.allowType,
          documentCategory: this.documentCategory,
          file: files[0],
          defaultDocumentType: this.defaultDocumentType,
          disabledDocumentTypes: this.disabledDocumentTypes
        },
        className: ClassName.HIGHER_DIALOG,
      });

      const sub = dialog.afterClosed.subscribe((result: { type: string, note: string, baseName: string } | boolean) => {
        if (typeof result === 'object') {
          files
            .filter(file => !file.uploading)
            .forEach(file => this.startUpload(file, result.type, result.note, result.baseName));
        } else {
          this.uploadingFiles.splice(0, this.uploadingFiles.length);
          files.splice(0, files.length);
        }
        sub.unsubscribe();
      });
    } else {
      for (const file of files.filter(file => !file.uploading)) {
        await this.startUpload(file);
      }
    }
  }

  onFileSelectExternal(files: File[]) {
    const extendedFiles = files.map(file => {
      return FileUtils.fileToUpload(file, () => true);
    });
    this.onFileSelect(extendedFiles);
  }

  onFilterChanged() {
    return this.listService.fetchResult(this.documentList);
  }

  checkCancelled() {
    return this.documentList.filter.filters.cancelled !== false;
  }

  onCancelledCheckboxClicked() {
    if (this.checkCancelled()) {
      this.documentList.filter.filters.cancelled = false;
    } else {
      delete this.documentList.filter.filters.cancelled;
    }
    this.onFilterChanged();
  }

  onSort(sortValue: any, sortDir: any) {
    this.listService.sort(this.documentList, sortValue, sortDir);
  }

  onEditDocument(document: DocumentModel) {
    const dialog = this.dialogService.open(DocumentEditFormComponent, {
      data: {
        allowType: this.allowType,
        document: {...document},
        documentCategory: this.documentCategory,
        disabledDocumentTypes: this.disabledDocumentTypes
      },
      className: ClassName.HIGHER_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: DocumentModel | boolean) => {
      if (result) {
          const index = _.findIndex(this.documentList.list, document);
          this.documentList.list.splice(index, 1, result as DocumentModel);
          this.updated.emit(true);
      }
      sub.unsubscribe();
    });
  }

  onDeleteDocument(document: DocumentModel) {
    const dialog = this.dialogService.open(ConfirmationComponent, {
      data: {
        msg: 'Opravdu chcete soubor smazat? Jeho obnovení nebude možné!',
      },
      className: ClassName.ADJUSTED_DIALOG,
    });

    const sub = dialog.afterClosed.subscribe((result: boolean) => {
      if (result) {
        this.restangular.one('attachments', document.id).remove().toPromise().then(() => {
          if (!this.checkCancelled()) {
            this.documentList.list = _.without(this.documentList.list, document);
          } else {
            document.cancelled = true;
          }
          this.documentList.itemCount--;
          this.updated.emit(true);
        });
      }
      sub.unsubscribe();
    });
  }

  getDocumentUrl(document) {
    return `${DocumentsComponent.VIEWER_URL}${this.restBaseUrl}/attachments/${document.id}/data-pdf%3Ft%3D${this.authToken}`;
  }

  canModify(permissions, user?) {
    return (
      (this.authService.hasPermission(permissions) || this.authService.isAuthorized())
      &&
      (!user || this.authService.isAuthorized(user) || this.authorizedUser)
    );
  }

  private startUpload(file: UploadFileExtended, type = '', note = '', baseName?) {
    // if uploaded from external source (not by file-upload)
    if (this.uploadingFiles.indexOf(file) === -1) {
      this.uploadingFiles.push(file);
    }

    file.progress = 0;

    let sendData: any = {
      file: file.fileEntry,
      filename: file.fileEntry.name,
      baseName: baseName,
    };

    if (this.defaultData) {
      sendData = {...sendData, ...this.defaultData};
    }

    if (type) {
      sendData.attachmentType = type;
    }

    if (note) {
      sendData.note = note;
    }

    file.uploading = this.uploadService.upload({
      url: this.restBaseUrl + '/attachments',
      data: sendData,
      headers: { Authorization: this.authToken }
    }).progress(event => {
      file.progress = (100.0 * event.loaded / event.total);
    }).success(data => {
      this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1);
      this.documentList.list.unshift(data as DocumentModel);
      this.documentList.itemCount++;
      this.updated.emit(true);
    }).error(() => {
      file.error = true;
    });

    return file.uploading;
  }
}
