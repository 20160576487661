import { Directive, HostListener, Input } from '@angular/core';
import { NumericPipe } from '@app/common/pipes/numeric.pipe';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[gmtInputNumber]'
})
export class InputNumberDirective {

  constructor(
    private ngControl: NgModel,
    private numericPipe: NumericPipe,
  ) { }

  @Input() gmtInputNumber: boolean;
  @Input() maxNumber: number;

  @HostListener('blur')
  onBlur() {
    let value = this.numericPipe.transform(this.ngControl.control.value);
    if (this.maxNumber !== undefined && value > this.maxNumber) {
      value = this.maxNumber;
    }
    this.ngControl.control.setValue(
      value
    );
  }
}
