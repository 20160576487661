import { Pipe, PipeTransform, Injectable } from '@angular/core';
import {ParcelModel} from "../models/parcel.model";

@Pipe({
  name: 'parcelprotection'
})
@Injectable({
  providedIn: 'root'
})
export class ParcelProtectionPipe implements PipeTransform {

  transform(parcel: ParcelModel): string {
    switch (parcel.parcelProtectionsText) {
      case 'zemědělský půdní fond':
        return 'ZPF';
      case 'pozemek určený k plnění funkcí lesa':
        return 'PUPFL';
      default:
        return parcel.parcelProtectionsText;
    }
  }
}
