import { Injectable } from '@angular/core';
import { ProjectDataModel } from '../models/project-data.model';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ProjectTypeNamePipe } from '@app/common/pipes/project-type-name.pipe';
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { ManagerReportProjectDataModel } from '@app/landing/models/manager-report-project-data.model';
import { ProjectStatePipe } from '@app/ps/pipes/project-state.pipe';

@Injectable({
  providedIn: 'root'
})
export class ManagerReportProjectsService {

  constructor(
    private projectSubjectPipe: ProjectSubjectNamePipe,
    private organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private userNamePipe: UserNamePipe,
    private authService: AuthService,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private projectTypeNamePipe: ProjectTypeNamePipe,
    private projectStatePipe: ProjectStatePipe,
  ) {
  }

  public createFromApiBulk(projects: any[]): ManagerReportProjectDataModel[] {
    return projects.map(p => this.createFromApi(p));
  }

  public createFromApi(project: any): ManagerReportProjectDataModel {
      return {
        hasDashboard: false,
        id: project.id,
        type: this.projectTypeNamePipe.transform(project.type),
        isprofond: project.isprofond,
        name: project.name,
        key: project.key,
        unit: project.unit,
        region: project.region,
        manager: this.userNamePipe.transform(project.manager, false, false),
        investor: this.organizationalUnitNamePipe.transform(project.investor ? project.investor.customerOrganizationalUnit : undefined),
        infrastructure: project.infrastructure,
        lastActivityDate: project.lastActivity ? project.lastActivity.timestamp : undefined,
        lastActivityAuthor: project.lastActivity ? this.userNamePipe.transform(project.lastActivity.user, false) : undefined,
        projectStartDate: project.projectStartDate,
        state: project.state,
        stateName: this.projectStatePipe.transform(project),
        phase: project.phase,
        propertyActivityProcessorCompany: project.propertyActivityProcessor ? project.propertyActivityProcessor.ssoCompanyName : undefined,
        propertyActivityProcessorName: this.userNamePipe.transform(project.propertyActivityProcessor, false),
        landTakeDocumentationAuthorCompany: project.landTakeDocumentationAuthor ? project.landTakeDocumentationAuthor.companyName : undefined,
        landTakeDocumentationAuthorName: this.projectSubjectPipe.transform(project.landTakeDocumentationAuthor, false, false),
        designerCompany: project.designer ? project.designer.companyName : undefined,
        designerName: this.projectSubjectPipe.transform(project.designer, false, false),
        readinessPermanentOccupations: project.readinessPermanentOccupations,
        readinessTemporaryUnderOccupations: project.readinessTemporaryUnderOccupations,
        readinessTemporaryOverOccupations: project.readinessTemporaryOverOccupations,
        readinessTemporaryEasements: project.readinessTemporaryEasements,
      };
  }

  public static mapDashboardDataOnProject(project: ManagerReportProjectDataModel, dashboard: any): ProjectDataModel {
    project.hasDashboard = true;
    project.activity = dashboard.activity;
    project.total = dashboard.total;
    project.exists = dashboard.total - dashboard.toCreate;
    project.done = dashboard.done;
    project.donePercentage = ManagerReportProjectsService.getPercentage(dashboard.done, dashboard.total);
    project.tzTotal = dashboard.tzTotal;
    project.tzDone = dashboard.tzDone;
    project.tzToCreate = dashboard.tzToCreate;
    project.dznrTotal = dashboard.dznrTotal;
    project.dznrDone = dashboard.dznrDone;
    project.dznrToCreate = dashboard.dznrToCreate;
    project.dzdrTotal = dashboard.dzdrTotal;
    project.dzdrDone = dashboard.dzdrDone;
    project.dzdrToCreate = dashboard.dzdrToCreate;
    project.vbTotal = dashboard.vbTotal;
    project.vbDone = dashboard.vbDone;
    project.vbToCreate = dashboard.vbToCreate;
    project.expropriationTotal = dashboard.expropriationTotal;
    project.parcelTotal = dashboard.parcelTotal;
    project.createdOpinions = dashboard.createdOpinions;
    project.createdCases = dashboard.createdCases;
    project.changedCases = dashboard.changedCases;
    project.changedCaseStatuses = dashboard.changedCaseStatuses;
    return project;
  }

  private static getPercentage(done: number, total: number): number {
    if (total === undefined || done === undefined) {
      return undefined;
    }
    if (total === 0 || done === 0) {
      return 0;
    }
    return (done / total * 100);
  }
}
