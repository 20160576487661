import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'numericPipe'
})
@Injectable({
  providedIn: 'root'
})
export class NumericPipe implements PipeTransform {

  transform(text: any): number {
    const isString = typeof text === 'string';

    if (_.isNumber(text) || !isString || text.length === 0) {
      return text;
    }

    const res = parseFloat(text.replace(',', '.').replace(/[^\d\.]*/g, ''));

    if (isNaN(res)) {
      return;
    }

    return res;
  }
}
