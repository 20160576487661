import { Component } from '@angular/core';

import { DialogConfig } from '../../models/dialog-config';
import { DialogRef } from '../../services/dialog-ref';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  constructor(
    public config: DialogConfig,
    public dialog: DialogRef,
  ) {
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel() {
    this.dialog.close(false);
  }

  onSubmit() {
    this.dialog.close(true);
  }
}
