import { Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { DocumentTypeModel } from '@app/sv/models/document-type.model';
import { DocumentTypeService } from '@app/sv/services/document-type.service';
import { SelectItem } from '@app/common/components/select/select.component';
import { DocumentTypeCategoryEnum, documentTypeCategoryOptions } from '@app/sv/enums/document-type-category.enum';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { ObligationModel } from '@app/ps/models/obligation.model';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';

@Component({
  selector: 'document-type-create',
  templateUrl: './document-type-create.component.html',
  styleUrls: ['./document-type-create.component.scss']
})
export class DocumentTypeCreateComponent implements OnInit {

  documentType: DocumentTypeModel = {obligations : new Array()};
  category: SelectItem;
  categoryOptions = [...documentTypeCategoryOptions];
  obligations: ObligationModel[];
  checklistObligations;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    public documentTypeService: DocumentTypeService,
    private stateService: StateService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  async ngOnInit() {
    if (this.APP_BRAND.NAME == 'RSD') {
      _.remove(this.categoryOptions, (i) => i.id == DocumentTypeCategoryEnum.INFORMATION_LETTER);
    }
    this.checklistObligations = new ChecklistModel(this.documentType.obligations);
    await this.loadObligations();
  }

  private async loadObligations() {
    this.obligations = await this.restangularSymap.all('obligations').getList().toPromise();
  }

  isValid(): boolean {
    return !!(this.documentType.name && this.documentType.category && this.documentType.obligations.length);
  }

  onCategorySelected(category) {
    this.documentType.category = category.id as DocumentTypeCategoryEnum;
  }

  onSubmit(): Promise<any> {
    return this.documentTypeService
      .upload(this.documentType)
      .then(document => {
        this.stateService.go('sv.settings');
      });
  }
}
