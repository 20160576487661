import {Inject, Injectable} from '@angular/core';
import * as  L from 'leaflet';
import * as _ from 'lodash';
import { MapService } from '@app/map/services/map.service';
import { APP_BRAND } from '@app/common/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class MapHighlightService {
  // Keep track of active highlights
  private readonly layers = {};
  // Keep track of active listeners
  private readonly listeners = {};
  private readonly subscribers = {};
  private readonly items = {};

  constructor(
    private mapService: MapService,
    @Inject(APP_BRAND) private APP_BRAND: any,
  ) {
    this.layeraddListenerFactory = this.layeraddListenerFactory.bind(this);
    this.highlightExists = this.highlightExists.bind(this);
    this.highlightFeature = this.highlightFeature.bind(this);
    this.removeHighlight = this.removeHighlight.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  layeraddListenerFactory(mapId) {
    return () => {
      if (this.layers[mapId]) {
        this.layers[mapId].bringToFront();
      }
    };
  }

  addSubscriber(name, subscriber) {
    this.subscribers[name] = subscriber;
  }

  highlightExists(mapId) {
    return !!this.layers[mapId];
  }

  async highlightFeature(mapId, feature: string | any, fitBoundsOptions = {}, customStyle?, item?) {
    if (typeof feature === 'string') {
      feature = JSON.parse(feature);
    }
    await this.removeHighlight(mapId);
    this.mapService.getMap(mapId).then((map) => {
      feature.crs = {
        type: 'name',
        properties: {
          'name': 'urn:ogc:def:crs:EPSG::5514'
        }
      };
      // Point and polygon style shares basic style properties, while point is shown as circle
      const style = {
        fillOpacity: 0,
        color: '#ff00ff',
        weight: 4
      };

      if (customStyle) {
        Object.assign(style, customStyle);
      }

      const geojsonMarkerOptions = _.extend({
        radius: 8,
      }, style);

      const layerLeaflet = L.Proj.geoJson(feature, {
        style: _.extend(style),
        pointToLayer: (feature, latlng) => {
          return L.circleMarker(latlng, geojsonMarkerOptions);
        }
      });

      map.addLayer(layerLeaflet);
      this.layers[mapId] = layerLeaflet;
      this.listeners[mapId] = this.layeraddListenerFactory(mapId);

      if (fitBoundsOptions !== false) {
        map.fitBounds(layerLeaflet.getBounds(), fitBoundsOptions);
      }
      map.on('layeradd', this.listeners[mapId]);

      if (item || feature) {
        this.items[mapId] = item || feature;
        Object.keys(this.subscribers).forEach(subscriber => this.subscribers[subscriber](item || feature));
      }
    });
  }

  async removeHighlight(mapId) {
    if (this.highlightExists(mapId)) {
      this.items[mapId] = undefined;
      Object.keys(this.subscribers).forEach(subscriber => this.subscribers[subscriber](undefined));

      return this.mapService.getMap(mapId).then((map) => {
        if (this.highlightExists(mapId)) {
          map.off('layeradd', this.listeners[mapId]);
          map.removeLayer(this.layers[mapId]);
          this.layers[mapId] = null;
          this.listeners[mapId] = null;
        }
      });
    }
  }

  /**
   * Returns highligted items
   */
  getItem(mapId) {
    return this.items[mapId];
  }
}
