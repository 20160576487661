﻿import { Component, OnInit, Input, Output, EventEmitter, ElementRef, KeyValueDiffers, NgZone } from "@angular/core";
import PerfectScrollbar from 'perfect-scrollbar';
import ResizeObserver from '@juggle/resize-observer';

export interface PerfectScrollbarEvent {
  scrollTop: () => void,
  updateScrollbar: () => void
}

@Component({
	selector: 'perfect-scrollbar',
	templateUrl: './perfectscrollbar.component.html',
	styleUrls: ['./perfectscrollbar.component.scss']
})

export class PerfectScrollbarComponent implements OnInit {
  @Input() wheelSpeed: number;
  @Input() wheelPropagation: boolean;
  @Input() minScrollbarLength: number;
  @Input() horizontal: boolean = false;
  @Output() callbackRegister = new EventEmitter<PerfectScrollbarEvent>(); // function called that parent scope can scroll to top
	private ps: any;
	private resizeElem: any;
	private differ: any;
	private scrollableElement: any;
	private scrollableInnerElement: any;

	constructor(
      private elementRef: ElementRef,
      private differs: KeyValueDiffers,
      private ngZone: NgZone,
    ) {
      this.scrollTop = this.scrollTop.bind(this);
      this.updateScrollbar = this.updateScrollbar.bind(this);
      this.update = this.update.bind(this);
      this.doResizeCheck = this.doResizeCheck.bind(this);
	}

	ngOnInit() {
    this.horizontal = this.horizontal !== false;
    this.scrollableElement = $(this.elementRef.nativeElement.querySelector('div.scrollable'));
    this.scrollableInnerElement = $(this.elementRef.nativeElement.querySelector('div.scrollable-inner'));

    this.ngZone.runOutsideAngular(() => {
      this.ps = new PerfectScrollbar(this.scrollableElement[0], {
        wheelSpeed: this.wheelSpeed || 2,
        wheelPropagation: this.wheelPropagation || false,
        minScrollbarLength: this.minScrollbarLength || null,
        suppressScrollY: this.horizontal,
      });

      $(window).bind('resize', () => {
        this.update({
          widthContainer: this.scrollableElement.width(),
          heightContainer: this.scrollableElement.height(),
          widthContent: this.scrollableInnerElement.width(),
          heightContent: this.scrollableInnerElement.height()
        });
      });
      new ResizeObserver(this.doResizeCheck).observe(this.scrollableElement[0]);
      new ResizeObserver(this.doResizeCheck).observe(this.scrollableInnerElement[0]);
    });
    this.differ = this.differs.find(this.getDiffObject()).create();

	  this.callbackRegister.emit({
      scrollTop: this.scrollTop,
      updateScrollbar: this.updateScrollbar
    });
	}

  doResizeCheck() {
    const diffObject = this.getDiffObject();
    const change = this.differ.diff(diffObject);
    if (change) {
      this.update(diffObject);
    }
  }

	scrollTop() {
      this.scrollableElement.scrollTop(0);
	}

	updateScrollbar() {
	  this.ps.update();
	}

  update(newValue) {
    if (newValue.widthContainer > 0 && newValue.heightContainer > 0 && newValue.widthContent > 0 && newValue.heightContent > 0) {
      setTimeout(() => {
        this.ps.update();
        this.ps.update();
      });
      // when content gets smaller but container is scolled to bottom
      if (newValue.heightContainer + $(this.scrollableElement).scrollTop() > newValue.heightContent) {
        this.scrollableElement.scrollTop(Math.max(0, newValue.heightContent - newValue.heightContainer));
      }
      if (newValue.widthContainer + $(this.scrollableElement).scrollLeft() > newValue.widthContent) {
        this.scrollableElement.scrollLeft(Math.max(0, newValue.widthContent - newValue.widthContainer));
      }
    }
  }

  getDiffObject() {
    return {
      widthContainer: this.scrollableElement.width(),
      heightContainer: this.scrollableElement.height(),
      widthContent: this.scrollableInnerElement.width(),
      heightContent: this.scrollableInnerElement.height()
    };
  }
}
