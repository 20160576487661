<div class="detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>{{requestId ? "Opakovaná žádost" : "Nová žádost"}}</h1>
                <div class="header-subtitle">Identifikace žádosti</div>
            </div>
        </div>
        <div [loading]="loading"></div>
        <div class="row" *ngIf="!loading">
            <div class="col-12 offset-lg-1 col-lg-10 mt-20">
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <fieldset>
                            <legend>Identifikace žádosti o data KN</legend>
                            <div class="column-100">
                                <label class="required">Název žádosti</label>
                                <div class="input">
                                  <input type="text" [disabled]="!!requestId" [(ngModel)]="projectName"/>
                                </div>

                                <label [ngClass]="{'required': canSelectCustomer}">Firma</label>
                                <div class="input" [ngClass]="{'disabled': requestId}">
                                  <div *ngIf="!canSelectCustomer" class="mt-5">
                                    <span *ngIf="customer">{{ customer | customerName }}</span>
                                    <span *ngIf="!customer">{{ user.company | customerName }}</span>
                                  </div>

                                  <gmt-select
                                    *ngIf="canSelectCustomer"
                                    [data]="this"
                                    field="customer"
                                    resource="customers"
                                    [restangularService]="configRestangular"
                                    [itemPipe]="customerNamePipe"
                                    (changed)="onCustomerSelected()"
                                    class="keep-input-size"
                                  ></gmt-select>
                                </div>

                                <ng-container *ngIf="canSelectCustomer && customer">
                                  <label [ngClass]="'required'">Smlouva</label>
                                  <div class="input">
                                    <gmt-select
                                      [data]="this"
                                      field="contract"
                                      resource="contracts"
                                      [filter]="{ filters: { customerId: customer.id, application: 'PRO' }}"
                                      [restangularService]="configRestangular"
                                      [itemPipe]="customerContractNamePipe"
                                      [reloadOptionsRegister]="setReloadContractOptions"
                                      (changed)="onContractSelected()"
                                      class="keep-input-size"
                                    ></gmt-select>
                                  </div>
                                </ng-container>

                                <label class="required">Označení (číslo) smlouvy</label>
                                <div class="input">
                                    <input type="text" [disabled]="!!requestId || canSelectCustomer" [(ngModel)]="prijemceIdentifikatorSmlouvy"/>
                                </div>

                                <label class="required">Předmět smlouvy</label>
                                <div class="input">
                                    <input type="text" [disabled]="!!requestId || canSelectCustomer" [(ngModel)]="prijemcePredmetSmlouvy"/>
                                </div>

                                <label class="required">Smlouva uzavřená dne</label>
                                <div class="input">
                                    <input pick-a-date *ngIf="!requestId && !canSelectCustomer" [(ngModel)]="prijemceDatumUzavreniSmlouvy"/>
                                    <input type="text" *ngIf="requestId || canSelectCustomer" disabled value="{{ prijemceDatumUzavreniSmlouvy | date}}"/>
                                </div>
                            </div>
                            <div class="mt-20">
                                <span class="error-message" *ngIf="cuzkAccountExpirationMessage">{{ cuzkAccountExpirationMessage }}</span>
                            </div>
                        </fieldset>

                        <fieldset *ngIf="!hasValidWsdpAccount && !requestId">
                            <legend>Přístupové údaje do Webové služby dálkového přístupu (WSDP)</legend>

                            <div class="error-message mb-10" *ngIf="cuzkAccountValidationMessage">{{cuzkAccountValidationMessage}}</div>

                            <div class="column-100">
                                <label class="required">Přihlašovací jméno</label>
                                <div class="input">
                                    <input type="text" [(ngModel)]="cuzkWebserviceName"/>
                                </div>

                                <label class="required">Heslo</label>
                                <div class="input">
                                    <input type="password" [(ngModel)]="cuzkWebservicePassword"/>
                                </div>

                                <label>Uložit</label>
                                <div class="info">
                                    <gmt-checkbox [gmtChecked]="saveWsdpCredentials" (click)="saveWsdpCredentials = !saveWsdpCredentials"></gmt-checkbox>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12 col-xl-6">
                        <fieldset>
                            <legend>Parametry požadovaných dat KN</legend>
                            <div class="column-100">
                                <!--<label class="required">Platnost dat</label>-->
                                <!--<div class="input">-->
                                    <!--<input pick-a-date [(ngModel)]="validityDate"/>-->
                                <!--</div>-->

                                <label class="required">Platnost dat</label>
                                <div class="input">
                                    <month-picker (date)="onValidDateChanged($event)" [options]="monthPickerOptions"></month-picker>
                                </div>

                                <!--<label (click)="oneTimeRequest = !oneTimeRequest">Jednorázová žádost</label>-->
                                <!--<div class="info">-->
                                    <!--<gmt-checkbox [gmtChecked]="oneTimeRequest" (click)="oneTimeRequest = !oneTimeRequest; periodicalRequest = !periodicalRequest;"></gmt-checkbox>-->
                                <!--</div>-->

                                <!--<label (click)="periodicalRequest = !periodicalRequest">Opakovaná žádost</label>-->
                                <!--<div class="info">-->
                                    <!--<gmt-checkbox [gmtChecked]="periodicalRequest" (click)="periodicalRequest = !periodicalRequest; oneTimeRequest = !oneTimeRequest;"></gmt-checkbox>-->
                                <!--</div>-->

                                <!--<label>S měsíční periodou bude automaticky žádost podána až do</label>-->
                                <!--<div class="input">-->
                                    <!--<input pick-a-date [(ngModel)]="periodRequestEndDate"/>-->
                                <!--</div>-->

                                <label (click)="otherLegalRelationships = !otherLegalRelationships">Údaje JPV (Jiné právní vztahy)</label>
                                <div class="info">
                                    <gmt-checkbox *ngIf="!requestId" [gmtChecked]="otherLegalRelationships" (click)="otherLegalRelationships = !otherLegalRelationships"></gmt-checkbox>
                                    <span class="disabled" *ngIf="requestId">{{otherLegalRelationships ? 'Ano' : 'Ne'}}</span>
                                </div>

                                <div class="info" *ngIf="!requestId">
                                    <i>*Povinné zaškrtnout pro data určená do aplikací typu GDiS, MAJA, MPV a dalších</i>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="col-12" *ngIf="!requestId">
                    <gmt-checkbox [gmtChecked]="conditions" (click)="conditions = !conditions"></gmt-checkbox>
                    <label (click)="conditions = !conditions">
                        Prohlašuji, že uvedené údaje jsou správné a souhlasím s jejich zveřejněním v Žádosti o šíření dat KN, kterou podává provozovatel služby společnost GMtech s.r.o. u správce dat ČÚZK. Prohlašuji, že uvedené přístupové údaje do Webových služeb dálkového přístupu (WSDP) jsou platné a souhlasím s tím, že budou na straně ČÚZK použity pro mou identifikaci, jakožto příjemce osobních údajů, které jsou součástí poskytnutých dat katastru nemovitostí.
                    </label>
                </div>

                <div class="col-12 text-center mt-20">
                    <gmt-button [clickAction]="cancel">Ukončit</gmt-button>
                    <gmt-button [clickAction]="continue" [gmtDisabled]="!isFormValid()">Pokračovat</gmt-button>
                </div>
            </div>
        </div>
    </div>

    <help [helpId]="helpIds.PK_CREATE_SECOND_STEP" class="cm-help-page"></help>
</div>
