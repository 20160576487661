import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';

@Component({
  selector: 'form-accepted-geometrical-plan-possible',
  templateUrl: './form-accepted-geometrical-plan-possible.component.html',
})
export class FormAcceptedGeometricalPlanPossibleComponent implements OnInit {
  @Output()
  callbackRegister = new EventEmitter();
  @Input() data: CaseModel;
  enabledSubjects: any = {};
  alreadySigned = 0;

  constructor() {
    this.isValid = this.isValid.bind(this);
  }

  ngOnInit() {
    this.data.responseReceivedDate = new Date();
    this.callbackRegister.emit({
      isValid: this.isValid,
    });

    this.data.caseSubjects = this.data.caseSubjects.filter(
      (value) => value.subject.opsubType !== "BSM" &&
        this.data.caseOwnerships.some((value2) => {
          let bsmSubject;
          if (value2.ownership.isBsm) {
            bsmSubject = this.data.caseSubjects.find((cs) => cs.subject.opsubId === value2.ownership.opsubId).subject;
          }
          return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)))
        })
    );
    this.data.caseSubjects.forEach((item) => {
        this.enabledSubjects[item.id] = !item.acceptedGeometricalPlanPossibleDate;
    });

    this.alreadySigned = this.data.caseSubjects.filter(subject => subject.acceptedGeometricalPlanPossibleDate).length;
  }

  isValid() {
    return this.data.responseReceivedDate && this.data.caseSubjects.filter(subject => subject.acceptedGeometricalPlanPossibleDate).length > this.alreadySigned;
  }
}

