﻿<fieldset>
  <form (submit)="applyFilter()">
    <legend *ngIf="showLegend">Filtrování</legend>
    <div class="filter-items">
      <ng-content></ng-content>
    </div>
    <gmt-button [clickAction]="applyFilter" class="filter-submit">{{ filterButtonName }}</gmt-button>
    <input
      type="submit"
      style="visibility: hidden; position: absolute; left: -9999px; width: 1px; height: 1px;"
      tabindex="-1" />
  </form>
</fieldset>
