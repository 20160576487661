import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { SeriesService } from '@app/ps/services/series.service';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';

export interface SeriesEvent {
  series: any;
  step?: any;
}

@Component({
  selector: 'gmt-series',
  templateUrl: './series.component.html',
})
export class SeriesComponent implements OnInit {

  @Input() series;
  @Input() hideConstructionObject;
  @Input() hideActionsButtons;

  @Output() canceled = new EventEmitter<SeriesEvent>();
  @Output() restored = new EventEmitter<SeriesEvent>();
  @Output() moved = new EventEmitter<SeriesEvent>();
  @Output() changed = new EventEmitter<SeriesEvent>();

  constructor(
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    private seriesService: SeriesService,
  ) {
    this.onChange = this.onChange.bind(this);
    this.onCancelSeries = this.onCancelSeries.bind(this);
    this.onRestoreSeries = this.onRestoreSeries.bind(this);
    this.onMoveSeries = this.onMoveSeries.bind(this);
    this.onMoveSection = this.onMoveSection.bind(this);
    this.onToggleSection = this.onToggleSection.bind(this);
  }

  ngOnInit() {
  }

  onChange() {
    this.changed.emit({ series: this.series });
  }

  onCancelSeries(series) {
    this.canceled.emit({ series: series });
  }

  onRestoreSeries(series) {
    this.restored.emit({ series: series });
  }

  onMoveSeries(series, step) {
    this.moved.emit({ series: series, step: step });
  }

  onMoveSection(section, sections, step) {
    const x = _.indexOf(sections, section);
    if (x + step < 0 || x + step >= sections.length) {
      return;
    }
    sections[x] = sections.splice(x + step, 1, sections[x])[0];
  }

  onToggleSection(section, sections) {
    if (section.type === 'text') {
      if (section.show) { // remove
        sections.splice(_.indexOf(sections, section), 1);
      } else { // add new empty line
        sections.splice(0, 0, {
          type: 'text'
        });
      }
    }
    section.show = !section.show;
  }

  generateNumber(): string {
    return this.seriesService.generateNumber(this.series);
  }
}
