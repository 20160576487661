import { Pipe, PipeTransform, Injectable } from "@angular/core";

import { SettlementProgress } from '@app/ps/models/settlement-progress';

@Pipe({
  name: 'settlementCategory',
  pure: true,
})
@Injectable({
  providedIn: 'root'
})
export class SettlementCategoryPipe implements PipeTransform {
  transform(settlementData: SettlementProgress[], category: string) {
    return settlementData && settlementData.filter(sd => sd.occupationCategory === category)[0];
  }
}
