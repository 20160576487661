import { Component, OnInit, Input } from '@angular/core';

import { Customer } from '@app/models/customer';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  @Input() customer: Partial<Customer>;

  pageTitle: string;

  constructor(
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.showContractForm = this.showContractForm.bind(this);
    this.showCustomerForm = this.showCustomerForm.bind(this);
    this.pageTitle = this.stateService && this.stateService.current.data.title;
  }

  showContractForm() {
    this.stateService.go('.contract.new');
  }

  showCustomerForm() {
    this.stateService.go('.edit');
  }
}
