<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <h1>Editace vlastníka</h1>
  <div [loading]="loading"></div>
  <div *ngIf="!loading">
    <fieldset>
      <legend>Platební údaje</legend>
      <div class="column-100">
        <label (click)="toggleSinglePayment()">Jedna platba za podíl SJM</label><div class="info">
        <gmt-checkbox [gmtChecked]="caseOwnership.singlePayment" (click)="toggleSinglePayment()"></gmt-checkbox>
      </div>
      </div>

      <div *ngIf="caseOwnership.singlePayment">
        <div class="column-100">
          <label>Manželé</label>
          <div class="info">
            <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseSubjects[0].subject.id, tab: 'about' }">{{ caseSubjects[0].subject | subjectName }}</a>
            a
            <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseSubjects[1].subject.id, tab: 'about' }">{{ caseSubjects[1].subject | subjectName }}</a>
          </div>
        </div>
        <payment-form [paymentOptions]="caseOwnership" [nestedForm]="true"></payment-form>
      </div>

      <ng-container *ngIf="!caseOwnership.singlePayment">
        <div  *ngFor="let caseSubject of caseSubjects">
          <div class="column-100 bordered-top mt-10 pt-10">
            <label>Jméno</label>
            <div class="info">
              <a uiSref="symap.project.owners.detail" [uiParams]="{ ownerId: caseSubject.subject.id, tab: 'about' }">{{ caseSubject.subject | subjectName }}</a>
            </div>
          </div>
          <payment-form  [paymentOptions]="caseSubject" [nestedForm]="true"></payment-form>
        </div>
      </ng-container>
    </fieldset>

    <fieldset *ngIf="displayFieldsLength">
      <div class="column-50" *ngIf="displayFields.easementPriceByShare">
        <label>Cena VB za podíl</label>
        <div class="input"><input type="text" [(ngModel)]="caseOwnership.easementPriceByShare" cm-input-number/></div>
      </div>
      <div class="column-50" *ngIf="displayFields.easementFutureContractNumber">
        <label>Číslo SOSB</label>
        <div class="input"><input type="text" [(ngModel)]="caseOwnership.easementFutureContractNumber"/></div>
      </div>
      <div class="column-50" *ngIf="displayFields.easementFutureContractStatus">
        <label>Stav SOSB</label>
        <div class="input"><input type="text" [(ngModel)]="caseOwnership.easementFutureContractStatus"/></div>
      </div>
    </fieldset>

    <div class="actions">
      <gmt-button [clickAction]="update">Uložit vlastníka</gmt-button>
    </div>
  </div>
</div>

