import { VfzeAppModule } from '@app/vfze/vfze.modules';
import { PkAppModule } from '@app/pk/pk.module';
import { PsAppModule } from '@app/ps/ps.module';
import { SettingsModule } from '@app/settings/settings.module';
import { NgModule } from '@angular/core';
import { LoginAppModule } from '@app/login/login.module';
import { MapModule } from '@app/map/map.module';
import { DashboardAppModule } from '@app/dashboard/dashboard.module';
import { LandingAppModule } from '@app/landing/landing.modules';
import { SvAppModule } from '@app/sv/sv.modules';
import { DimapAppModule } from '@app/dimap/dimap.module';
import { ErrorModule } from '@app/error/error.module';
import { UIRouterModule } from '@uirouter/angular';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UIRouterModule.forRoot({
      useHash: true,
      initial: { state: 'otherwise' },
      otherwise: '/otherwise'
    }),
    LoginAppModule,
    PkAppModule,
    PsAppModule,
    DimapAppModule,
    SettingsModule,
    DashboardAppModule,
    VfzeAppModule,
    LandingAppModule,
    SvAppModule,
    MapModule,
    ErrorModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '!' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
