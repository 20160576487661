<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <h1>Podána správní žaloba</h1>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <form-expropriation-administrative-appeal [data]="data" (callbackRegister)="formCallbackRegister($event)"></form-expropriation-administrative-appeal>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
