<div class="detail" [loading]="loading">
  <div *ngIf="!loading">
    <div style="display:flex">
      <div class="line-height-l" style="min-width: 190px;">
        <a uiSref="symap.project.projectSettings.experts" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Seznam odhadců</a>
      </div>
      <div class="actions">
        <a (click)="onEdit()" class="button"><i class="fa fa-pencil"></i>Editace odhadce</a>
      </div>
    </div>

    <h1>Odhadce</h1>

    <fieldset class="bordered">
      <!-- left column -->
      <div class="column-50">
        <label>Titul před jménem</label>
        <div class="input">{{ expert.titulPredJmenem }}</div>
        <label>Křestní jméno</label>
        <div class="input">{{ expert.jmeno }}</div>
        <label>Příjmení</label>
        <div class="input">{{ expert.prijmeni }}</div>
        <label>Titul za jménem</label>
        <div class="input">{{ expert.titulZaJmenem }}</div>
      </div>

      <!-- right column -->
      <div class="column-50">
        <label>Ulice</label>
        <div class="input">{{ expert.ulice }}</div>
        <label>Číslo orientační</label>
        <div class="input">{{expert.cisloOrientacni }}</div>
        <label>Číslo popisné</label>
        <div class="input">{{ expert.cisloDomovni }}</div>
        <label>Město</label>
        <div class="input">{{ expert.obec }}</div>
        <label>PSČ</label>
        <div class="input">{{ expert.psc | postCode }}</div>
      </div>
    </fieldset>
  </div>
</div>
