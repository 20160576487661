<div class="list">
  <div *ngIf="!loading" class="text-right">
    <a class="button ml-10" (click)="onExport()"><i class="fa fa-download"></i> Export seznamu</a>
    <a class="button ml-10" (click)="onEditColumns()"><i class="fa fa-wrench"></i> Upravit sloupce</a>
  </div>

  <div [loading]="loading">
    <div *ngIf="!loading">
      <fieldset>
        <form (submit)="reloadList()">
          <div class="filter-items">
            <ng-container *ngFor="let column of columns">
              <gmt-select
                *ngIf="column.filterable"
                [data]="filtersData"
                field="{{ column.id }}"
                required="true"
                multiselect="true"
                [optionItems]="getFilterItemsByColumn(column)"
                [selectTitle]="column.name"
              ></gmt-select>
            </ng-container>
          </div>
          <div class="column-100">
            <input class="fulltext" name="search" type="text" autocomplete="off" placeholder="Fultextové hledání..." [(ngModel)]="searchText" />
          </div>
          <gmt-button [clickAction]="reloadList" class="filter-submit">Filtrovat</gmt-button>
          <input type="submit" style="visibility: hidden; position: absolute; left: -9999px; width: 1px; height: 1px;" tabindex="-1" />
        </form>
      </fieldset>

      <div class="pt-20 mb-40">
        <div class="bordered bordered-top__none" *ngIf="list.itemCount">
          <perfect-scrollbar [wheelPropagation]="true" style="width: 100%">
            <table class="table" #projectList>
              <thead>
              <tr>
                <th class="state-column"><div></div></th>
                <th *ngFor="let column of getVisibleColumns()" class="cell">
                  <div>
                    <sort-by *ngIf="column.sortable" [selected]="column.id === sortOrder.sortBy" (sort)="onSort(column.id)" [sortDir]="sortOrder.direction">
                      {{ column.name }}
                    </sort-by>
                    <span *ngIf="!column.sortable">{{ column.name }}</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let project of list.list" uiSref="symap.project.home" [uiParams]="{ projectKey: project.key }">
                <td class="state-column {{ 'bg-' + project.stateEnum }}"></td>
                <td *ngFor="let column of getVisibleColumns()" class="cell" [ngStyle]="column.style ? column.style : { }">
                  <ng-container *ngIf="column.id === 'name'">
                    <a uiSref="symap.project.home" [uiParams]="{ projectKey: project.key }">{{ project.name }}</a>
                  </ng-container>
                  <ng-container *ngIf="['donePercentage', 'tzDonePercentage', 'dzdrDonePercentage', 'dznrDonePercentage', 'vbDonePercentage'].includes(column.id)">
                    <span *ngIf="project[column.id] !== undefined">{{ project[column.id] | number:'1.0-0' }} %</span>
                  </ng-container>
                  <ng-container *ngIf="['lastActivityDate', 'projectStartDate'].includes(column.id)">
                    <span *ngIf="project[column.id] !== undefined">{{ project[column.id] | date:'mediumDate' }}</span>
                  </ng-container>
                  <ng-container *ngIf="!['name', 'donePercentage', 'lastActivityDate', 'projectStartDate', 'tzDonePercentage', 'dzdrDonePercentage', 'dznrDonePercentage', 'vbDonePercentage'].includes(column.id)">
                    {{ project[column.id] }}
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
        <div class="empty-info bordered" *ngIf="!list.itemCount">
          <span>Nenalezena žádná data</span>
        </div>
        <div class="mt-10">
          Legenda:
          <span *ngFor="let status of statuses">
            <span class="legend bg-{{ status }}"></span> {{ ('ps.projectState.' + status) | brandTranslate }}
          </span>
        </div>
        <div class="flexbox-container mt-10" *ngIf="list.itemCount">
          <itemscounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="itemCounterCallbackRegister($event)" class="flex-start"></itemscounter>
          <pagescounter *ngIf="list.itemCount" [list]="list" (callbackRegister)="pagesCounterCallbackRegister($event)" class="flex1"></pagescounter>
          <div class="page-count-select">
            <span>Záznamů na stránce</span>
            <gmt-select
              required="true"
              [data]="dataPaging"
              field="itemsPerPage"
              [optionItems]="itemsPerPage"
              class="min-width-unset"
              (changed)="onPageItemsChanged()"
            ></gmt-select>
          </div>
        </div>
        <div class="mt-10">
          <pagination *ngIf="list.itemCount" [shouldUpdateUrl]="false" [list]="list" (pageChanged)="onPageChanged($event)" (callbackRegister)="paginationCallbackRegister($event)"></pagination>
        </div>
      </div>
    </div>

    <help [helpId]="helpIds.SY_PROJECTS" class="cm-help-page"></help>
  </div>
</div>
