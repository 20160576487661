﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { AuthService } from '@app/common/services/auth.service';
import { SeriesNamePipe } from '@app/ps/pipes/series-name.pipe';
import { ListService } from '@app/common/services/list.service';
import { TitleNamesPipe } from '@app/common/pipes/title-names.pipe';
import * as _ from 'lodash';
import * as _Fraction from 'fraction.js';
import { CaseService } from '@app/ps/services/case.service';
import { SeriesService } from '@app/ps/services/series.service';
import { Restangular } from 'ngx-restangular';
let Fraction : any = _Fraction;

@Component({
  selector: 'construction-object-detail-create-case-confirm',
	templateUrl: './create-case-confirm.component.html',
})
export class CreateCaseConfirmComponent implements OnInit {
  @Input()
  private constructionObjectId: number;
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();
  @Output()
  private backCallback = new EventEmitter();
  public count: number;
  public sending: boolean = false;
  public priceTotal: number = 0;
  public parcelCount: number = 0;

  constructor(
    private authService: AuthService,
    private listService: ListService,
    private titleNamesPipe: TitleNamesPipe,
    private restangular: Restangular,
  ) {
    this.create = this.create.bind(this);
  }

  ngOnInit() {
    this.data.createdCases = [];
    this.count = this.data.cases.length;
    this.data.cases.forEach(businessCase => {
      this.priceTotal += businessCase.totalEasementPrice;
    });
    this.parcelCount = new Set(this.data.easementsSelected.map(easement => easement.parcel.id)).size;
  }

  create() {
    this.sending = true;
    let createNext = () => {
      let promise = Promise.resolve();
      const businessCase = this.data.cases[this.data.cases.length - this.count];
      if (businessCase.id) {
        this.count--;
        if (this.count === 0) {
          this.submitCallback.emit();
        } else {
          return createNext();
        }
      }
      return promise.then(() => this.restangular.all('cases/create').post(businessCase, {loadCollections: ['titles']}).toPromise().then(
              (data) => {
        this.data.createdCases.push(data);
        this.data.cases[this.data.cases.length - this.count] = _.assign(
          data.plain(),
          this.data.cases[this.data.cases.length - this.count]
        );
        this.count--;
        if (this.count === 0) {
          this.submitCallback.emit();
        } else {
          return createNext();
        }
      }, (result) => {
        this.sending = false;
        return Promise.reject(result);
      }));
    };
    return createNext();
  }
}
