import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { RESTANGULAR_SYMAP } from '@app/common/services/restangular-symap.service';
import { AuthService } from '@app/common/services/auth.service';
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { ManagerReportProjectDataModel } from '@app/landing/models/manager-report-project-data.model';
import { ManagerReportProjectsService } from '@app/landing/services/manager-report-projects.service';
import { ListModel } from '@app/common/models/list.model';
import { SortModel } from '@app/common/models/sort.model';
import { SelectItem } from '@app/common/components/select/select.component';
import { DatePipe } from '@angular/common';
import { ProjectStatePipe } from '@app/ps/pipes/project-state.pipe';
import { HelpService } from '@app/common/services/help.service';
import {APP_BRAND, APP_CONFIG, APPLICATIONS, UICONSTANTS} from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import {BrandService} from "@app/common/services/brand.service";
import {HttpService} from "@app/common/services/http.service";

@Component({
  selector: 'manager-report-project-list',
  templateUrl: './manager-report-project-list.component.html',
  styleUrls: ['./manager-report-project-list.component.scss']
})
export class ManagerReportProjectListComponent implements OnInit, OnDestroy {

  loading = true;
  projects: ManagerReportProjectDataModel[];
  list: ListModel<ManagerReportProjectDataModel>;
  sortOrder: SortModel = { sortBy: 'isprofond', direction: 'asc' };
  itemsPerPage = UICONSTANTS.itemsPerPage10;
  dataPaging = { itemsPerPage: { id: 10, name: 10 }};
  onListChangedPagination: Function;
  onListChangedItemCounter: Function;
  onListChangedPagesCounter: Function;
  filtersData: any = {};
  searchText: string;
  statuses = [ 'CREATED', 'STARTED_PARTIAL', 'STARTED_COMPLETE', 'PAUSED', 'FINISHED' ];
  columns = [
    {id: 'type', name: 'Typ akce'},
    {id: 'name', name: 'Název akce'},
    {id: 'isprofond', name: 'Evidenční číslo'},
    {id: 'investor', name: 'Organizační jednotka'},
    {id: 'infrastructure', name: 'Komunikace'},
    {id: 'region', name: 'Kraj'},
    {id: 'stateName', name: 'Stav akce'},
  ];
  @ViewChild('managerList') tableEl: ElementRef;
  private xhrCancelResolve: Function;
  private scrolling;
  private brand = this.brandService.isVisible('mpv') ? 'mpv' : 'maja';

  constructor(
    @Inject(APPLICATIONS) public APPLICATIONS: any,
    @Inject(RESTANGULAR_SYMAP) private restangularSymap: any,
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APP_CONFIG) private config: any,
    private localStorageService: LocalStorageService,
    public projectStatePipe: ProjectStatePipe,
    private authService: AuthService,
    private projectsService: ManagerReportProjectsService,
    private datePipe: DatePipe,
    private brandService: BrandService,
    private httpService: HttpService
) {
    this.onFilter = this.onFilter.bind(this);
    this.reloadList = this.reloadList.bind(this);
    this.ngOnInit = this.ngOnInit.bind(this);
    this.onExportXlsx = this.onExportXlsx.bind(this);
    this.onExportPdf = this.onExportPdf.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  async ngOnInit() {
    const dataPaging = this.localStorageService.get('syManagerReportListPageItems');
    const searchText = this.localStorageService.get('syManagerReportListSearchText');
    const filtersData = this.localStorageService.get('syManagerReportListFiltersData');

    if (!this.brandService.isVisible('mpv')) {
      _.remove(this.columns, c => c.id === 'infrastructure');
      _.remove(this.columns, c => c.id === 'type');
    }

    if (dataPaging) {
      this.dataPaging = dataPaging as any;
    }

    if (searchText) {
      this.searchText = searchText as any;
    }

    if (filtersData) {
      this.filtersData = filtersData as any;
    }

    await this.loadData();
    this.initList();
    $('#content-wrap').on('scroll', () => { this.onScroll(); });
    this.loading = false;
  }

  ngOnDestroy() {
    if (this.xhrCancelResolve) {
      this.xhrCancelResolve();
    }

    $('#content-wrap').unbind('scroll');
  }

  @HostListener('window:resize')
  onScroll() {
    clearTimeout(this.scrolling);
    this.scrolling = setTimeout(() => {
      const tableTop = this.tableEl.nativeElement.getBoundingClientRect().top;
      const ths = $(this.tableEl.nativeElement.querySelectorAll('thead th div'));
      if (tableTop < 80) {
        ths.css('top', (80 - tableTop) + 'px');
      } else {
        ths.css('top', '0px');
      }
    });
  }

  onPageChanged(event) {
    this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
    this.reloadList();
  }

  pagesCounterCallbackRegister(event) {
    this.onListChangedPagesCounter = event.onListChanged;
  }

  itemCounterCallbackRegister(event) {
    this.onListChangedItemCounter = event.onListChanged;
  }

  paginationCallbackRegister(event) {
    this.onListChangedPagination = event.onListChanged;
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
    if (this.onListChangedItemCounter) {
      this.onListChangedItemCounter();
    }
    if (this.onListChangedPagesCounter) {
      this.onListChangedPagesCounter();
    }
  }

  onPageItemsChanged() {
    this.list.filter.offset = 0;
    this.localStorageService.set('syManagerReportListPageItems', this.dataPaging);
    this.reloadList();
  }

  reloadList() {
    const projects = this.sortAndFilterProjects();
    this.list.list = projects.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
    this.list.itemCount = projects.length;
    this.list.filter.limit = this.dataPaging.itemsPerPage.id;
    this.onListChanged();
  }

  onFilter() {
    this.onPageChanged(1);
    this.localStorageService.set('syManagerReportListSearchText', this.searchText);
    this.localStorageService.set('syManagerReportListFiltersData', this.filtersData);
  }

  getFilterItemsByColumn(column: any): SelectItem[] {
    const columnItems = this.projects
      .map(p => { return { id: p[column.id], name: p[column.id] }; })
      .filter(i => i.id !== '' && i.id !== undefined)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    return _.uniqBy(columnItems, 'id');
  }

  onExportXlsx() {
    const projects = this.sortAndFilterProjects();
    return this.restangularSymap.one(`manager-report?type=xlsx&brand=${this.brand}`)
      .withHttpConfig({ responseType: 'blob' })
      .customPOST(projects.map(p => p.key))
      .toPromise()
      .then((response) => {
        saveAs(response, 'manager-report.xlsx', true);
      });
  }

  onExportPdf() {
    const projects = this.sortAndFilterProjects();
    return this.restangularSymap.one(`manager-report?type=pdf&brand=${this.brand}`)
      .withHttpConfig({ responseType: 'blob' })
      .customPOST(projects.map(p => p.key))
      .toPromise()
      .then((response) => {
        saveAs(response, 'manager-report.pdf', true);
      });
  }

  private async loadData() {
    const cancelPromise = new Promise<any>((resolve, reject) => {
      this.xhrCancelResolve = resolve;
    });

    const projects = await this.restangularSymap
      .one('users', this.authService.getUser().id)
      .customGET('projects', { permission: 'manager_report' })
      .toPromise()
      .then((d) => {
        return _.sortBy(d.items.filter(p => p.key !== 'sy_statistics' && p.key !== 'sy_statistics_emh' && !!p.isprofond), 'isprofond');
      });

    this.projects = this.projectsService.createFromApiBulk(projects);

    return this.httpService.call({
      path: 'dashboard',
      method: 'POST',
      baseUrl: this.config.BACKEND_OPTIONS.restUrlSY + '/app',
      cancelPromise: cancelPromise,
    }).then((data: any) => {
      if (!data) {
        return;
      }
      this.projects.forEach(project => {
        const dashboard = data.data.find(d => d.key === project.key);
        if (dashboard) {
          ManagerReportProjectsService.mapDashboardDataOnProject(project, dashboard);
        }
      });
    });
  }

  private initList() {
    const projects = this.sortAndFilterProjects();

    this.list = {
      loading: false,
      list: projects.slice(0, this.dataPaging.itemsPerPage.id),
      itemCount: projects.length,
      filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
    };
  }

  private sortAndFilterProjects(): ManagerReportProjectDataModel[] {
    let projects = this.projects;

    // filters
    for (const column of this.columns) {
      if (this.filtersData[column.id] && this.filtersData[column.id].length) {
        const filter = this.filtersData[column.id].map(i => i.id);
        projects = projects.filter(p => filter.includes(p[column.id]));
      }
    }

    // date filters
    if (this.filtersData.startFrom) {
      projects = projects.filter(p => p.projectStartDate && this.datePipe.transform(p.projectStartDate, 'yyyy-MM-dd') >= this.filtersData.startFrom);
    }

    if (this.filtersData.startTo) {
      projects = projects.filter(p => p.projectStartDate && this.datePipe.transform(p.projectStartDate, 'yyyy-MM-dd') <= this.filtersData.startTo);
    }

    // fulltext
    if (this.searchText) {
      const searchText = this.searchText.toLowerCase();
      projects = projects.filter(p => {
          if (
            (p.isprofond && p.isprofond.toLowerCase().indexOf(searchText) !== -1)
            || (p.name && p.name.toLowerCase().indexOf(searchText) !== -1)
          ) {
            return true;
          }
        return false;
      });
    }

    return projects;
  }
}
