import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import { MapModuleService } from '@app/map/services/map-module.service';
import { WindowDimensionService } from '@app/common/services/window-dimension.service';

@Component({
  selector: 'map-tool-box',
  templateUrl: './map-tool-box.component.html',
})
export class MapToolBoxComponent implements OnInit {
  @Input() tools: any;
  @Input() config: any;
  @Input() mapId: string;
  public infoboxVisible = true;

  constructor(
    private windowDimensionService: WindowDimensionService,
    private elementRef: ElementRef,
    private mapModuleService: MapModuleService
  ) {
    this.setContentHeight = this.setContentHeight.bind(this);
    this.onModuleClick = this.onModuleClick.bind(this);
    this.visibilitySetter = this.visibilitySetter.bind(this);
    this.getInfoboxWidth = this.getInfoboxWidth.bind(this);
  }

  ngOnInit() {
    // Map has zero height on init
    setTimeout(this.setContentHeight, 100);

    this.tools = this.tools.map(t => this.mapModuleService.getModuleById(t));
    this.mapModuleService.setActiveModuleId(this.config.selectedTool || this.tools[0].id);
    this.visibilitySetter(this.getActiveTool().showInfobox !== false);
  }

  visibilitySetter(infoboxVisibility) {
    this.infoboxVisible = infoboxVisibility;
  }

  getActiveTool() {
    const activeModuleId = this.mapModuleService.getActiveModuleId();
    return this.mapModuleService.getModuleById(activeModuleId);
  }

  onModuleClick(moduleId) {
    if (moduleId !== this.getActiveTool().id) {
      this.mapModuleService.setActiveModuleId(moduleId);
      this.visibilitySetter(this.getActiveTool().showInfobox !== false);
    }
  }

  getInfoboxWidth() {
    return this.elementRef.nativeElement.querySelector('.cm-map-infobox').getBoundingClientRect().width;
  }

  // resizing window will change height of scrollable content
  @HostListener('window:resize')
  setContentHeight() {
    // set height for scrollbar
    const dimensions = this.windowDimensionService.get(this.elementRef.nativeElement);
    $(this.elementRef.nativeElement.querySelector('.content .scrollable')).css('max-height', dimensions.windowHeight - 50 - 70 - 12);
  }
}
