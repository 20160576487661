import { ParcelModel } from '@app/common/models/parcel.model';
import { ConstructionObjectModel } from '@app/common/models/construction-object.model';
import { OccupationTypeModel } from './occupation-type.model';
import { ExpertModel } from '@app/ps/models/expert.model';

export class OccupationModel {
  id: number;
  countCases: number;
  countCasesFuture: number;
  parcel: ParcelModel;
  pardil: number;
  rentalPriceType: string;
  rentalExpert: ExpertModel;
  rentalExpertOpinionNumber: string;
  rentalExpertOpinionDate: string;
  rentalOtherPriceSource: string;
  priceAssessmentLandPrice: number;
  priceAssessmentYear: number;
  rentalLength: number;
  rentalPriceUnit: string;
  totalRentPrice: number;
  yearRentPrice: number;
  zabcis: number;
  zabciskn: string;
  zabcisknp: string;
  zabidpar: number;
  zabku: number;
  zabkutx: string;
  zablv: number;
  zabsk12: number;
  zabst: number;
  zabsttx: string;
  zabtyp: number;
  zabtyptx: string;
  zabvym: number;
  constructionObjects?: ConstructionObjectModel[];
  occupationType: OccupationTypeModel;
  nabyvatel: string;
  investor: string;
  endDate?: string;
  nextOccupationId?: number;

  caseOccupation?: any;
  caseParcel?: any;
  geometry?: string;
  solutionType?: string;
}
