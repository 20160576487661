﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { ListService } from '@app/common/services/list.service';
import { ParcelService } from '@app/ps/services/parcel.service';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { ProjectSubjectNamePipe } from '@app/ps/pipes/project-subject-name.pipe';
import * as _ from 'lodash';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ParcelUtils } from '@app/common/utils/parcel.utils';
import { TitleDetailCreateCaseDataService } from '@app/ps/services/title-detail-create-case-data.service';
import { TitleDetailCreateCaseSelectionService } from '@app/ps/services/title-detail-create-case-selection.service';
import { CaseService } from '@app/ps/services/case.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { EasementModel } from '@app/common/models/easement.model';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'title-detail-create-case-select-objects-items',
  templateUrl: './create-case-select-objects-items.component.html',
})
export class CreateCaseSelectObjectsItemsComponent implements OnInit {
  @Input() data: any;
  @Input() checklistGroup: any;
  @Input() checklistEasement: any;
  @Input() checklistConstructionObject: any;
  @Input() checklistBuilding: any;
  @Input() private checklistOwnership: any;
  @Input() private titleId: number;
  @Output() private callbackRegister = new EventEmitter();
  @Input() private generalDataPromise: Promise<any>;
  @Input() private titleDataPromise: Promise<any>;
  @Output() private submitCallback = new EventEmitter();
  loading = false;
  dataEasements: any[];
  dataCases: any[];
  dataBuildings: any[];
  dataParcelPrices: any[];
  temporaryOccupationTableNames: any;
  dataOccupationGroupsFiltered: any;
  dataConstructionObjectsFiltered: any[];
  dataOwnerships: any[];
  checkedAllConstructionObjects: boolean;
  checkedAllEasements: boolean;
  checkedAllGroups: {permanent?: boolean, underOneYear?: boolean, overOneYear?: boolean} = {};
  checkedAllBuildings: boolean;
  dataOpinions: any[];
  sortOrder: any = {};

  private dataOccupationGroups: any = {};
  private dataOccupations: any[];
  private checklistGroupLocal: any;
  private checklistEasementLocal: any;
  private checklistBuildingLocal: any;
  private checklistConstructionObjectLocal: any;

  constructor(
    private listService: ListService,
    public subjectNamePipe: SubjectNamePipe,
    private parcelService: ParcelService,
    private parcelNumberPipe: ParcelNumberPipe,
    private projectSubjectNamePipe: ProjectSubjectNamePipe,
    private constructionObjectNamePipe: ConstructionObjectNamePipe,
    private caseService: CaseService,
    public selection: TitleDetailCreateCaseSelectionService,
    private titleDetailCreateCaseDataService: TitleDetailCreateCaseDataService,
    private restangular: Restangular,
  ) {
    this.checkObjectsByData = this.checkObjectsByData.bind(this);
    this.isSelectionValidLocal = this.isSelectionValidLocal.bind(this);
    this.resetSelection = this.resetSelection.bind(this);
    this.unselect = this.unselect.bind(this);
    this.toggleSelectionEasement = this.toggleSelectionEasement.bind(this);
    this.selectByCase = this.selectByCase.bind(this);
  }

  ngOnInit() {
    // Variables to distinguish between globally added objects and those added in current component
    this.checklistGroupLocal = new ChecklistModel();
    this.checklistEasementLocal = new ChecklistModel();
    this.checklistBuildingLocal = new ChecklistModel();
    this.checklistConstructionObjectLocal = new ChecklistModel();

    this.checkedAllConstructionObjects = false;
    this.checkedAllEasements = false;
    this.checkedAllGroups = {};
    this.checkedAllBuildings = false;

    this.selection.scope = this;
    this.dataOccupationGroupsFiltered = {};
    this.dataConstructionObjectsFiltered = [];

    this.loading = true;
    const promise = this.titleDataPromise || this.titleDetailCreateCaseDataService.loadTitleData(this.titleId);
    promise.then((dataLoaded) => {
      this.loading = false;
      this.dataOpinions = dataLoaded.dataOpinions;
      this.dataParcelPrices = dataLoaded.dataParcelPrices;
      this.dataOccupationGroups = dataLoaded.dataOccupationGroups;
      this.dataOccupations = dataLoaded.dataOccupations;
      this.dataEasements = dataLoaded.dataEasements;
      this.dataCases = dataLoaded.dataCases;
      this.dataBuildings = dataLoaded.dataBuildings;
      this.selection.refreshItems(this.data.obligation);
      this.callbackRegister.emit({
        obligationChanged: this.selection.obligationChanged,
        refreshItems: this.selection.refreshItems,
        resetSelection: this.resetSelection,
        unselect: this.unselect,
        isSelectionValidLocal: this.isSelectionValidLocal,
        checkObjectsByData: this.checkObjectsByData,
        selectByCase: this.selectByCase,
        selection: this.selection,
      });
      this.temporaryOccupationTableNames = {
        underOneYear: this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.underOneYear),
        overOneYear: this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.overOneYear),
        permanentWithSolutionType: 'Trvalý zábor bez výkupu (nájem)',
        permanentWithSolutionTypeUnderOneYear: 'Trvalý zábor bez výkupu (nájem)',
        permanentWithSolutionTypeOverOneYear: 'Trvalý zábor bez výkupu (nájem)',
      };
    });
    const generalDataPromise = this.generalDataPromise || this.titleDetailCreateCaseDataService.loadGeneralData(this.titleId, promise);
    generalDataPromise.then((data) => {
      this.dataOwnerships = data.dataOwnerships;
    });
  }

  selectByCase() {
    this.data.fromCase.caseEasements.forEach(caseEasement => {
      const easement = this.dataEasements.find(easement =>
        easement.geometricPlan.id === caseEasement.easement.geometricPlan.id &&
        easement.idpar === caseEasement.easement.idpar &&
        easement.oznacVb === caseEasement.easement.oznacVb &&
        easement.parcel.title.id === this.titleId
      );

      if (easement) {
        this.selection.toggleSelectionEasement(easement);
      }
    });
    this.data.fromCase.caseOccupations.forEach(caseOccupation => {
      let occupationGroup;
      Object.keys(this.dataOccupationGroupsFiltered).forEach(key => {
        occupationGroup = this.dataOccupationGroupsFiltered[key].find(occupationGroup =>
          occupationGroup.occupations.some(occupation =>
            caseOccupation.occupation.zabtyp === occupation.zabtyp &&
            caseOccupation.occupation.zabst === occupation.zabst &&
            caseOccupation.occupation.zabcis === occupation.zabcis &&
            occupation.parcel.title.id === this.titleId
          )
        );
      });
      if (occupationGroup && !this.checklistGroup.isChecked(occupationGroup)) {
        this.selection.toggleSelectionGroup(occupationGroup);
      }
    });

    this.data.fromCase.caseBuildings.forEach(caseBuilding => {
      const building = this.dataBuildings.find(building =>
        caseBuilding.building.zabtyp === building.budId &&
        caseBuilding.building.title.id === this.titleId
      );

      if (building) {
        this.selection.toggleSelectionBuilding(building);
      }
    });
  }

  setSortData(sortedBy: string, key: string) {
    const sortOrder = this.sortOrder[key] || {};
    this.sortOrder[key] = sortOrder;
    sortOrder.direction = sortOrder.direction === 'asc' ? 'desc' : 'asc';
    // every first sort will be sorted 'desc'
    if (sortOrder.sortBy !== sortedBy) {
      sortOrder.direction = 'desc';
    }
    sortOrder.sortBy = sortedBy;
  }

  onSortParcel(sortBy: string, data: any[], key: string) {
    this.setSortData(sortBy, key);
    return _.orderBy(data, ParcelUtils.getSortFunctionByParcelsNumbers('parcel.parcisKmen', 'parcel.parcisPod', 'parcel.parcisDil'), this.sortOrder[key].direction);
  }

   onSortAdministrator(sortBy: string, data: any[], key: string) {
      this.setSortData(sortBy, key);
      return _.orderBy(data, (item: any) => {
        const administrator = item.constructionObjects[0].administrator
          ? this.projectSubjectNamePipe.transform(item.constructionObjects[0].administrator).toLowerCase()
          : '-';
        const constructionObjectName = this.constructionObjectNamePipe.transform(item.constructionObjects[0]);
        return administrator + ' ' + constructionObjectName;
      }, this.sortOrder[key].direction);
   }

  onSortOccupationParcel(sortBy: string, key: string) {
    this.dataOccupationGroupsFiltered[key] = this.onSortParcel(sortBy, this.dataOccupationGroupsFiltered[key], key);
  }

  onSortEasementsParcel(sortBy) {
    this.dataEasements = this.onSortParcel(sortBy, this.dataEasements, 'easement');
  }

  onSortEasementsAdministrator(sortBy) {
    this.dataEasements = this.onSortAdministrator(sortBy, this.dataEasements, 'easement');
  }

  onSort(sortBy: string, dataPath: string, data: any[], key: string) {
    this.setSortData(sortBy, key);
    return ParcelUtils.sortDataByColumnStatic(data, dataPath, this.sortOrder[key].direction);
  }

  onSortOccupation(sortBy, dataPath, key) {
    this.dataOccupationGroupsFiltered[key] = this.onSort(sortBy, dataPath, this.dataOccupationGroupsFiltered[key], key);
  }

  onSortEasements(sortBy, dataPath) {
    this.dataEasements = this.onSort(sortBy, dataPath, this.dataEasements, 'easement');
  }

  priceColumnShown() {
    return this.data.obligation && this.data.obligation.computePrice && !this.caseService.easementPriceByShare(this.data.obligation);
  }

  selectConstructionObjectRequired() {
    return this.data.obligation && this.data.obligation.selectConstructionObjectRequired;
  }

  /**
   * Make selectino echual to this scope
   * @return {undefined}
   */
  resetSelection() {
    // remove selected occupations, easements, buildings, construction objects
    this.checklistGroup.empty();
    this.checklistGroup.checkedItems.push(...this.checklistGroupLocal.checkedItems);
    this.checklistEasement.empty();
    this.checklistEasement.checkedItems.push(...this.checklistEasementLocal.checkedItems);
    this.checklistBuilding.empty();
    this.checklistBuilding.checkedItems.push(...this.checklistBuildingLocal.checkedItems);
    this.checklistConstructionObject.empty();
    this.checklistConstructionObject.checkedItems.push(...this.checklistConstructionObjectLocal.checkedItems);
    this.checklistConstructionObject.checkedItems.forEach((co) => {
      _.remove(co.easements, (easement: EasementModel) => !this.checklistEasement.checkedItems.some(item => item.id === easement.id));
      _.remove(co.occupations, (occupation: OccupationModel) => !this.checklistGroup.checkedItems.some((group) => group.occupations.some(item => item.id === occupation.id)));
    });
  }

  /**
   * Unselect all object selected in this scope
   * @return {undefined}
   */
  unselect() {
    this.checklistGroupLocal.checkedItems.forEach((group) => {
      this.checklistGroup.toggleSelection(group);
    });
    this.checklistEasementLocal.checkedItems.forEach((easement) => {
      this.checklistEasement.toggleSelection(easement);
    });
    this.checklistBuildingLocal.checkedItems.forEach((building) => {
      this.checklistBuilding.toggleSelection(building);
    });

    this.checklistConstructionObject.checkedItems.forEach((co) => {
      _.remove(co.easements, (easement: EasementModel) => !this.checklistEasement.checkedItems.some(item => item.id === easement.id));
      _.remove(co.occupations, (occupation: OccupationModel) => !this.checklistGroup.checkedItems.some((group) => group.occupations.some(item => item.id === occupation.id)));
    });

    this.checklistConstructionObjectLocal.checkedItems.forEach((co) => {
      if (co.selected) {
        this.checklistConstructionObject.toggleSelection(co);
      }
    });
    this.checklistGroupLocal.empty();
    this.checklistEasementLocal.empty();
    this.checklistBuildingLocal.empty();
    this.checklistConstructionObjectLocal.empty();
  }

  /**
   * Is something selected?
   */
  isSelectionValidLocal() {
    const accumulateOccupations = (accumulator, item) => {
      return item.occupations
        .filter(itemOccupation => !accumulator.some(accItem => accItem.id === itemOccupation.id))
        .reduce((acc, itemOccupation) => [...acc, itemOccupation], accumulator);
    };

    const accumulateEasements = (accumulator, item) => {
      return item.easements
        .filter(itemEasement => !accumulator.some(accItem => accItem.id === itemEasement.id))
        .reduce((acc, itemEasement) => [...acc, itemEasement], accumulator);
    };
    return (this.checklistBuildingLocal.checkedItems.length ||
      this.checklistGroupLocal.checkedItems.length ||
      this.checklistEasementLocal.checkedItems.length) &&
      (!this.data.obligation.selectConstructionObjectRequired || (this.checklistConstructionObject.checkedItems.length
          && this.checklistGroupLocal.checkedItems.reduce(accumulateOccupations, []).length === this.checklistConstructionObject.checkedItems.reduce(accumulateOccupations, []).length
          && this.checklistEasementLocal.checkedItems.length === this.checklistConstructionObject.checkedItems.reduce(accumulateEasements, []).length));
  }

  checkObjectsByData(data) {
    Object.entries(this.parcelService.groupOccupationsByParcelSingle(
            (data.occupations || []).map(
              occupation => Object.assign(this.dataOccupations.find(item => item.id === occupation.id), occupation)
            )
    )).forEach(([key, group]) => this.selection.toggleSelectionGroup(group));
    (data.easements || []).forEach(easement => this.selection.toggleSelectionEasement(easement));
    (data.buildings || []).forEach(building => this.selection.toggleSelectionBuilding(building));
    (data.constructionObjects || []).map(co => this.selection.constructionObjectToCase(co, true)).forEach(constructionObject => this.selection.toggleSelectionConstructionObject(constructionObject));
  }

  toggleSelectionEasement(easementCheck) {
    const check = this.selection.toggleSelectionEasement(easementCheck);
    const globalPromise = Promise.resolve();
    this.dataEasements.forEach((easement) => {
      if (this.selection.isEasementEnabled(easement) && easementCheck.parcel.id === easement.parcel.id) {
        let promise;
        if (easement.id === easementCheck.id) {
          promise = Promise.resolve(true);
        } else if (check === this.checklistEasement.isChecked(easement)) {
          promise = Promise.resolve(false);
        } else {
          promise = Promise.resolve(false);
          /*promise = globalPromise = globalPromise.then(() => new this.confirmService({
              title: 'Další věcné břemeno',
              message: 'Na parcele ' + this.parcelNumberPipe.transform(easementCheck.parcel) + ' se nachází další věcné břemeno geometrického plánu ' + easement.geometricPlan.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?',
          }));
          promise.then((result) => {
            if (result) {
              this.selection.toggleSelectionEasement(easement);
            }
          });*/
        }
      }
    });

    return globalPromise;
  }

  isPermanentOccupationOnParcel(parcel: ParcelModel): boolean {
    if (!parcel || !this.dataOccupations) {
      return false;
    }

    return !!this.dataOccupations.find(o => {
      return o.parcel.id === parcel.id && this.isPermanentCategoryOccupation(o);
    });
  }

  isPermanentCategoryOccupation(occupation: OccupationModel) {
    return occupation.occupationType.category === 'permanent';
  }
}
