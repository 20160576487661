import { NgModule } from '@angular/core';

import { CommonModule } from '@app/common/common.module';
import { MapModule as MapLibModule } from '@app/map/map.module';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';

import { mapState } from './map.states';

import { MapComponent } from './components/map/map.component';
import { UIRouterModule } from '@uirouter/angular';

const STATES = [
  mapState,
];

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [...STATES],
    }),
    CommonModule,
    DimapCommonModule,
    MapLibModule,
  ],
  providers: [],
  declarations: [
    MapComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class MapModule {}
