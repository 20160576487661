import { Component, Input } from '@angular/core';

@Component({
  selector: 'map-feature-item',
  templateUrl: `./map-feature-item.component.html`,
})
export class MapFeatureItemComponent {
  @Input() feature;
  public isArray = Array.isArray;
}
