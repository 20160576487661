<div>
    <div [loading]="loading">
        <h1 class="text-center">Přidat projekt</h1>
        <p class="text-center info pt-20 pr-15">Lze přidat pouze projekty, které jsou přiřazeny k danému objednateli a nejsou vedeny na jiné smlouvě.</p>
        <div *ngIf="projects" class="column-50 mt-20">
            <label class="required">Projekt</label>
            <gmt-select
                [data]="selected"
                field="project"
                [optionItems]="projects"
                [required]="true">
            </gmt-select>
        </div>
    </div>
    <div class="centered-text mt-10 column-100">
        <gmt-button [clickAction]="save" [gmtDisabled]="!isProjectSet()">Uložit</gmt-button>
    </div>
</div>
