import { Inject, Injectable } from '@angular/core';
import { MapLayerTypeEnum } from '@app/map/enums/map-layer-type.enum';
import { LeafletProjGeoJsonWfs } from '@app/map/services/leaflet-proj-geo-json-wfs.service';
import { LeafletTileLayerWfs } from '@app/map/services/leaflet-tile-layer-wfs.service';
import { LeafletTileLayerWmsFiltered } from '@app/map/services/leaflet-tile-layer-wms-filtered.service';
import { LeafletLayerGroupService } from '@app/map/services/leaflet-layer-group.service';
import { LeafletTileLayerWmts } from '@app/map/services/leaflet-tile-layer-wmts.service';

@Injectable({
  providedIn: 'root'
})
export class MapLayerFactoryService {

   constructor(
      private leafletProjGeoJsonWfs : LeafletProjGeoJsonWfs,
      private leafletTileLayerWfs : LeafletTileLayerWfs,
      private leafletTileLayerWmsFiltered : LeafletTileLayerWmsFiltered,
      private leafletLayerGroup : LeafletLayerGroupService,
      private leafletTileLayerWmts : LeafletTileLayerWmts,
    ) { }

    ///////////////////////////////////////////////////////////////////////////

    // create layer from configuration
    createLayer(config, id) {
        switch (config['type']) {
            case MapLayerTypeEnum.wms:
                config.id = id;
                return this.createWmsLayer(config);

            case MapLayerTypeEnum.wmts:
                config.id = id;
                return this.createWmtsLayer(config);

            case MapLayerTypeEnum.wfs:
                config.id = id;
                return this.createGeoJsonWfsLayer(config);

            case 'wfs-tiled':
                config.id = id;
                return this.createTileWfsLayer(config);

            default:
                throw new Error('Unknown layer type ' + config.type);
        }
    }

    /**
     * TODO check id param !!!
     */
     createLayerGroup(layers, id) {
        return this.leafletLayerGroup.getLayerGroup(layers, id);
    }

    ///////////////////////////////////////////////////////////////////////////

    // create GeoJSON layer
     createGeoJsonWfsLayer(layerConfig) {
        return this.leafletProjGeoJsonWfs.getLayer(
            layerConfig['url'],
            layerConfig);
    }

    // create tileds WFS layer
     createTileWfsLayer(layerConfig) {
        return this.leafletTileLayerWfs.getLayer(
            layerConfig['url'],
            layerConfig);
    }

    // create WMS layer
    createWmsLayer(layerConfig) {
      return this.leafletTileLayerWmsFiltered.getLayer(
        layerConfig['url'],
        layerConfig);
    }

   createWmtsLayer(layerConfig) {
        return this.leafletTileLayerWmts.getLayer(
                layerConfig['url'],
                layerConfig);
   }
}
