import { NgModule } from '@angular/core';
import { CommonModule } from '@app/common/common.module';
import { DimapCommonModule } from '@app/dimap/dimap-common.module';
import { ParcelListComponent } from '@app/dimap/parcels/parcel-list/parcel-list.component';
import {Ng2StateDeclaration, StateParams, UIRouterModule} from '@uirouter/angular';
import { ParcelDetailComponent } from '@app/dimap/parcels/parcel-detail/parcel-detail.component';
import { resolveId } from '@app/common/utils/state.utils';

export const parcelsState: Ng2StateDeclaration = {
  name: 'dimap.project.parcels',
  data: {
    title: 'Parcely',
  },
  url: '/parcels?page',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    }
  },
  views: {
    'content@dimap.project': {
      component: ParcelListComponent,
    }
  }
};

export const parcelDetailState: Ng2StateDeclaration = {
  name: 'dimap.project.parcels.detail',
  url: '/:id',
  data: {
    title: 'Detail parcely',
  },
  views: {
    'content@dimap.project': {
      component: ParcelDetailComponent,
    }
  },
  resolve: {
    parcelId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        parcelsState,
        parcelDetailState,
      ],
    }),
    CommonModule,
    DimapCommonModule,
  ],
  providers: [],
  declarations: [
    ParcelListComponent,
    ParcelDetailComponent,
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class ParcelsModule {}
