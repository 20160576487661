import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { CaseStatusModel } from '@app/ps/models/case-status.model';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'sign-owner',
  templateUrl: './sign-owner.component.html',
  styleUrls: ['./sign-owner.component.scss']
})
export class SignOwnerComponent implements OnInit {
  @Input() caseId: number;
  loading = true;
  data: CaseModel;
  name: string;
  enabledSubjects = {};
  alreadySigned = 0;
  dataSend = {
    caseSubjects: null,
    responseReceivedDate: new Date()
  };
  deliveredInTime = null;

  constructor(
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
    private caseService: CaseService,
    private stateService: StateService,
    private datePipe: DatePipe,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onSubjectSignDateChanged = this.onSubjectSignDateChanged.bind(this);
    this.onResponseReceivedDataChanged = this.onResponseReceivedDataChanged.bind(this);
    this.signatureVerificationPriceEnabled = this.signatureVerificationPriceEnabled.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise();
    this.name = (await this.caseStatusService.loadStatusName('SignedOwner')).name;

    this.dataSend.caseSubjects = this.data.caseSubjects.filter(
      (value) => value.subject.opsubType !== 'BSM' &&
        this.data.caseOwnerships.some((value2) => {
          let bsmSubject;
          if (value2.ownership.isBsm) {
            bsmSubject = this.data.caseSubjects.find((cs) => cs.subject.opsubId === value2.ownership.opsubId).subject;
          }
          return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)))
        })
    );
    this.dataSend.caseSubjects.forEach((item) => {
      this.enabledSubjects[item.id] = !item.signedDate;
      if (item.signatureVerificationPrice === null && this.signatureVerificationPriceEnabled()) {
        item.signatureVerificationPrice = 0;
      }
    });

    this.alreadySigned = this.dataSend.caseSubjects.filter(subject => subject.signedDate).length;
    this.onResponseReceivedDataChanged();

    this.loading = false;
  }

  onSubjectSignDateChanged(signedDate: string) {
    if ((this.data.bonusDate || this.dueDateCompute())) {
      this.checkBonus();
    }
  }

  onResponseReceivedDataChanged() {
    if (this.data.validityDate || this.data.bonusDate || this.dueDateCompute()) {
      this.checkBonus();
      if (this.dataSend.responseReceivedDate && this.data.validityDate) {
        this.deliveredInTime = +new Date(this.data.validityDate) >= +new Date(this.dataSend.responseReceivedDate);
      } else {
        this.deliveredInTime = false;
      }
    }
  }

  isValid() {
    return this.dataSend.caseSubjects.filter(subject => subject.signedDate).length > this.alreadySigned;
  }

  signatureVerificationPriceEnabled() {
    const whitelist = [
      'AccessToLandLiability',
      'AgreementOnAFutureNetworkConstructionContractLiability',
      'AgreementOnAFutureTransferContractLiability',
      'AgreementOnAOtherFutureContractLiability',
      'BuildingPlacementLiability',
      'BorrowingLiability',
      'OtherLiability',
      'OwnersStatementLiability',
      'RightToRealizeABuildingLiability',
      'FutureRealBurdenLiabilityAccelerated',
    ];

    return !whitelist.includes(this.data.obligation.type);
  }

  async onSubmit(): Promise<any> {
    if (_.some(this.dataSend.caseSubjects, (item) => {
      return item.signedDate && (!this.dataSend.responseReceivedDate || (item.signatureVerificationPrice && !isFinite(item.signatureVerificationPrice)));
    })) {
      return;
    }

    await this.restangular
      .one('cases', this.caseId)
      .customPUT(this.dataSend, 'sign-owner')
      .toPromise();
    this.stateService.go('^');
  }

  private dueDateCompute() {
    const duePeriod = this.data.obligation.duePeriod;
    const dueDataFromType = this.data.obligation.dueDateFromType;
    return !this.data.paymentOrderDate && duePeriod && (dueDataFromType === 'ResponseReceivedSignedDate' || dueDataFromType === 'SignedAllOwnersDate');
  }

  private checkBonus() {
    let allSigned = false;
    let lastSignedDate = null;

    if ((this.data.bonusDate || this.dueDateCompute()) && this.dataSend.responseReceivedDate) {
      allSigned = true;
      _.forEach(this.dataSend.caseSubjects, (item) => {
        if (!item.signedDate) {
          allSigned = false;
        } else if (lastSignedDate === null || +lastSignedDate < +new Date(item.signedDate)) {
          lastSignedDate = new Date(item.signedDate);
        }
        return allSigned;
      });
    }

    if (this.data.bonusDate && this.dataSend.responseReceivedDate) {
      this.data.bonusGranted = this.data.bonusPossible && allSigned && +new Date(this.data.bonusDate) >= +new Date(this.dataSend.responseReceivedDate);
    } else {
      this.data.bonusGranted = false;
    }

    if (this.dueDateCompute()) {
      const duePeriod = this.data.obligation.duePeriod;
      const dueDataFromType = this.data.obligation.dueDateFromType;

      if (this.dataSend.responseReceivedDate && allSigned) {
        const date = dueDataFromType === 'ResponseReceivedSignedDate' ? new Date(this.dataSend.responseReceivedDate) : lastSignedDate;
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
        this.data.dueDate = this.datePipe.transform(newDate, 'yyyy-MM-dd');
      } else {
        this.data.dueDate = null;
      }
    }
  }
}
