﻿import { Component, Inject, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { ConstructionObjectNamePipe } from '../../../common/pipes/construction-object-name.pipe';
import { SeriesNamePipe } from '../../pipes/series-name.pipe';
import { ParentCaseOwnershipNamePipe } from '../../pipes/parent-case-ownership-name.pipe';
import { AuthService } from '@app/common/services/auth.service';
import { ListService } from '@app/common/services/list.service';
import { CreateCaseSelectParentOwnershipService } from '@app/ps/services/create-case-select-parent-ownership.service';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe';
import { TitleDetailCreateCaseDataService } from '@app/ps/services/title-detail-create-case-data.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { transformListToFilter } from '@app/common/utils/list.utils';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './titles-create-cases.component.html',
})
export class TitlesCreateCasesComponent implements OnDestroy, OnInit {
  @Input() data: any;
  @Output() callback = new EventEmitter();
  @Input() notifyParent: Function;
  list: any;
  countResolved = 0;
  allowAssignment = false;
  interrupt = false;
  obligationType = [
    'GratuitousTransferContractLiability',
    'NonGratuitousTransferContractLiability',
    'RightToRealizeABuildingLiability',
    'AgreementOnAOtherFutureContractLiability',
    'AgreementOnAFutureTransferContractLiability',
    'AgreementOnAFutureNetworkConstructionContractLiability',
    'FutureRealBurdenLiabilityAccelerated',
    'AccessToLandLiability',
    'BuildingPlacementLiability',
    'BorrowingLiability',
    'OtherLiability',
    'OwnersStatementLiability',
    'RentalContractShorterThan1YearLiability',
    'RentalContractLongerThan1YearLiability',
    'RentalContractCommonLiability',
  ];
  obligationFilter =  {
    filters: {
      obligationType: this.obligationType,
    }
  };
  inputData: any = {};
  createGroups = [
    {id: 'title', name: 'Po listech vlastnictví'},
    {id: 'constructionObject', name:  'Po stavebních objektech'},
    {id: 'owner', name:  'Po vlastnících'},
  ];
  objectTypes: any[] = [];
  constructionObjects: any[];
  seriesData: any[];
  titleParentSelections: any[];
  private groupsByConstructionObject: any[];
  private selectParentShown = false;

  steps: any[] = [
    {
      key: 'selectObjects',
      skip: () => false,
      initData: () => {
        this.objectTypes = [];
        if ((this.inputData.obligation.objectTypes.easementsGeometricPlan || this.inputData.obligation.objectTypes.easementsLandTake) && this.titlesWithEasementsSelected()) {
          this.objectTypes.push({
            key: 'easement',
            name: 'Věcná břemena',
          });
        }

        if (this.titlesWithOccupationGroupSelected('underOneYear') && (this.inputData.obligation.objectTypes.occupations || (this.inputData.obligation.objectTypes.occupationGroupKeys && this.inputData.obligation.objectTypes.occupationGroupKeys.includes('underOneYear')))) {
          this.objectTypes.push({
            key: 'underOneYear',
            name: 'Dočasné zábory do jednoho roku',
          });
        }

        if (this.titlesWithOccupationGroupSelected('overOneYear') && (this.inputData.obligation.objectTypes.occupations || (this.inputData.obligation.objectTypes.occupationGroupKeys && this.inputData.obligation.objectTypes.occupationGroupKeys.includes('overOneYear')))) {
          this.objectTypes.push({
            key: 'overOneYear',
            name: 'Dočasné zábory nad jeden rok',
          });
        }

        if (this.titlesWithOccupationGroupSelected('permanent') && (this.inputData.obligation.objectTypes.occupations || (this.inputData.obligation.objectTypes.occupationGroupKeys && this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanent')))) {
          this.objectTypes.push({
            key: 'permanent',
            name: 'Trvalé zábory',
          });
        }

        if (this.inputData.obligation.objectTypes.occupationGroupKeys &&
          (
            (this.titlesWithOccupationGroupSelected('permanentWithSolutionTypeUnderOneYear') && this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeUnderOneYear')) ||
            (this.titlesWithOccupationGroupSelected('permanentWithSolutionTypeOverOneYear') && this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeOverOneYear'))
          )
        ) {
          this.objectTypes.push({
            key: this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeUnderOneYear') ?
              (this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanentWithSolutionTypeOverOneYear') ? 'permanentWithSolutionType' : 'permanentWithSolutionTypeUnderOneYear') :
              'permanentWithSolutionTypeOverOneYear',
            name: 'Trvalé zábory bez výkupu (nájem)',
          });
        }
      },
      isValid: () => this.inputData.objectTypes.length
    },
    {
      key: 'selectConstructionObjects',
      skip: () => !this.inputData.obligation.selectConstructionObjectRequired,
      initData: () => {
        this.constructionObjects = [];
        this.groupsByConstructionObject = [];
        const addConstructionObject = (constructionObject, titleData) => {
          let data = this.groupsByConstructionObject.find(co => co.constructionObject.id === constructionObject.id);
          if (!data) {
            data = {
              constructionObject: constructionObject,
              titles: [],
            };
            this.groupsByConstructionObject.push(data);
            this.constructionObjects.push(constructionObject);
          }
          data.titles.push(titleData);
        };
        this.inputData.objectTypes.forEach(objectType => {
          this.titles.forEach(titleData => {
            switch (objectType.key) {
              case 'easement':
                titleData.dataEasements.forEach(easement => {
                  easement.constructionObjects.forEach(constructionObject => {
                    addConstructionObject(constructionObject, titleData);
                  });
                });
              break;
              default:
                if (titleData.dataOccupationGroups[objectType.key]) {
                  titleData.dataOccupationGroups[objectType.key].forEach(group => {
                    group.occupations.forEach(occupation => {
                      occupation.constructionObjects.forEach(constructionObject => {
                        addConstructionObject(constructionObject, titleData);
                      });
                    });
                  });
                }
            }
          });
        });
      },
      isValid: () => this.inputData.constructionObjects === null || this.inputData.constructionObjects.length
    },
    {
      key: 'selectTimeline',
      skip: () => false,
      initData: async () => {
        const constructionObjectIds = (this.inputData.constructionObjects === null ? this.constructionObjects : this.inputData.constructionObjects)
          .map(co => co.id);
        return await this.restangular.all('series').customPOST({filter: {filters: {constructionObjectId: constructionObjectIds}}}).toPromise().then((seriesData) => {
          this.seriesData = seriesData.plain();
        });
      }
    },
    {
      key: 'selectParent',
      skip: () => !this.selectParentShown,
      initData: async () => {
        let titles = this.titles;
        if (this.inputData.constructionObjects !== null) {
          titles = [];
          this.inputData.constructionObjects.forEach(co => {
            const gco = this.groupsByConstructionObject.find(gco => gco.constructionObject === co);
            gco.titles.forEach(title => {
              if (!titles.includes(title)) {
                titles.push(title);
              }
            });
          });
        }

        this.inputData.titles = [];
        titles.forEach(titleData => {
          if (
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'easement') || titleData.dataEasements.length === 0) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'underOneYear') || !titleData.dataOccupationGroups.underOneYear) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'overOneYear') || !titleData.dataOccupationGroups.overOneYear) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'permanent') || !titleData.dataOccupationGroups.permanent) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'permanentWithSolutionTypeUnderOneYear') || !titleData.dataOccupationGroups.permanentWithSolutionTypeUnderOneYear) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'permanentWithSolutionTypeOverOneYear') || !titleData.dataOccupationGroups.permanentWithSolutionTypeOverOneYear) &&
            (!this.inputData.objectTypes.some(objectType => objectType.key === 'permanentWithSolutionType') || !titleData.dataOccupationGroups.permanentWithSolutionType)
          ) {
            return;
          }
          this.inputData.titles.push(titleData);
        });

        this.selectParentShown = false;
        this.titleParentSelections = [];
        for (const titleData of this.inputData.titles) {
          titleData.dataOwnerships.forEach(dataOwnership => {
            delete dataOwnership.isParent;
            delete dataOwnership.parentCaseOwnership;
          });

          const ownershipsWithParent = await this.createCaseSelectParentOwnershipService.checkOwnershipsWithParent(titleData.dataOwnerships);

          this.titleParentSelections.push({
            ownershipsWithParent: ownershipsWithParent,
            caseOwnerships: titleData.dataOwnerships,
          });
          this.selectParentShown = this.selectParentShown || this.createCaseSelectParentOwnershipService.needsParentSelection(ownershipsWithParent);
        }
      },
      isValid: () => !this.titleParentSelections.some(titleParentSelection => this.createCaseSelectParentOwnershipService.needsParentSelection(titleParentSelection.ownershipsWithParent))
    },
    {
      key: 'summary',
      skip: () => false
    },
  ];
  step: any;
  private checklistObjectTypes: any;
  private checklistCreateGroups: any;
  private selectedConstructionObjects: any[] = [];
  private titles: any[];
  acceleratedPriceTypes: {id: string, name: string}[];

  constructor(
    private usernamePipe: UserNamePipe,
    private listService: ListService,
    private authService: AuthService,
    public constructionObjectNamePipe: ConstructionObjectNamePipe,
    public seriesNamePipe: SeriesNamePipe,
    private createCaseSelectParentOwnershipService: CreateCaseSelectParentOwnershipService,
    public parentCaseOwnershipNamePipe: ParentCaseOwnershipNamePipe,
    private wordTranslatePipe: WordTranslatePipe,
    private restangular: Restangular,
    private titleDetailCreateCaseDataService: TitleDetailCreateCaseDataService,
  ) {
    this.arrangeTitles = this.arrangeTitles.bind(this);
    this.updatedObligation = this.updatedObligation.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  titlesWithEasementsSelected(): boolean {
    return this.titles.some(titleData => !!titleData.dataEasements.length);
  }

  titlesWithOccupationGroupSelected(key: string): boolean {
    return this.titles.some(titleData => titleData.dataOccupationGroups[key]);
  }

  ngOnInit() {
    this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
    this.inputData = this.data.details || {};
    this.notifyParent(
      () => {
        return this.inputData.obligation
          && (this.inputData.obligation.type !== 'FutureRealBurdenLiabilityAccelerated' || this.inputData.acceleratedPriceType)
          && this.step === this.steps[this.steps.length - 1]
          && (this.authService.hasPermission('admin') || this.inputData.createGroups.some(group => group.id === 'title' || group.id === 'owner'));
      }, this.inputData
    );
    if (!this.inputData.createGroups) {
      this.inputData.createGroups = [];
    } else {
      this.updatedObligation();
    }
    this.checklistCreateGroups = new ChecklistModel(this.inputData.createGroups);
    this.acceleratedPriceTypes = [
      {id: 'SINGLE', name: 'Jednotná cena za parcelu (parcely)'},
      {id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu'},
      {id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu věcných břemen na parcele (parcelách)'},
      {id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV')},
    ];
  }

  async loadTitles() {
    const filter = {
      limit: null,
      filters: Object.assign(
        transformListToFilter({filters: this.data.selectEntities.filters}).filters,
        {
          entityId: {
            values: this.data.selectEntities.checkedItems.map(entity => entity.id),
            negation: this.data.selectEntities.inverseSelection,
          },
          obligationType: this.inputData.obligation.type,
          loadCollections: [],
        }
      )
    };

    this.list = this.listService.createList('titles', filter);
    return await this.listService.fetchResult(this.list);
  }

  ngOnDestroy() {
    this.interrupt = true;
  }

  async updatedObligation() {
    this.inputData.objectTypes = [];
    this.checklistObjectTypes = new ChecklistModel(this.inputData.objectTypes);
    this.inputData.constructionObjects = null;
    this.inputData.series = null;
    this.step = this.steps[0];
    this.countResolved = 0;

    await this.loadTitles();
    const titles = await this.arrangeTitles();
    if (titles) {
      this.titles = titles;
      this.step.initData();
    }
  }

  async arrangeTitles(): Promise<any> {
    const titlesData = [];
    const list = this.list.list;
    for (const item of list) {
      if (this.interrupt) {
        return false;
      }
      const titleDataPromise = this.titleDetailCreateCaseDataService.loadTitleData(item.id);
      const data = await this.titleDetailCreateCaseDataService.loadGeneralData(item.id, titleDataPromise);
      if (list !== this.list.list) {
        return false;
      }
      this.countResolved++;
      titlesData.push(data);
    }

    return titlesData;
  }

  previous() {
    let use = false;
    let tmpStep;
    for (const step of this.steps) {
      if (step.key === this.step.key) {
        use = true;
        break;
      }
      if (!step.skip || !step.skip()) {
        tmpStep = step;
      }
    }

    if (use) {
      this.step = tmpStep;
    }
  }

  async next() {
    let use = false;
    for (const step of this.steps) {
      if (use) {
        if (step.initData) {
          await step.initData();
        }
        if (step.skip && step.skip()) {
          continue;
        }
        this.step = step;
        return false;
      }
      if (step.key === this.step.key) {
        use = true;
      }
    }
  }

  toggleObjectType(objectType) {
    this.checklistObjectTypes.toggleSelection(objectType);
  }

  isCheckedObjectType(objectType): boolean {
    return this.checklistObjectTypes.isChecked(objectType);
  }

  toggleCreateGroup(createGroup) {
    this.checklistCreateGroups.toggleSelection(createGroup);
  }

  isCheckeCreateGroup(createGroup): boolean {
    return this.checklistCreateGroups.isChecked(createGroup);
  }

  checkAllConstructionObjects() {
    this.selectedConstructionObjects.splice(0, this.selectedConstructionObjects.length);
    this.inputData.constructionObjects = null;
  }

  checkSelectedConstructionObjects() {
    this.inputData.constructionObjects = this.selectedConstructionObjects;
  }

  changedSelectedConstructionObjects() {
    this.inputData.constructionObjects = this.selectedConstructionObjects;
  }

  toggleAcceleratedPriceType(acceleratedPriceType) {
    this.inputData.acceleratedPriceType = acceleratedPriceType.id;
  }

  isCheckedAcceleratedPriceType(acceleratedPriceType): boolean {
    return this.inputData.acceleratedPriceType === acceleratedPriceType.id;
  }

  async changedParent(data, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
    await this.createCaseSelectParentOwnershipService.changedParent(data.newValue, data.oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
  }
}
