import {Ng2StateDeclaration, StateParams} from '@uirouter/angular';
import { VfzeListComponent } from '@app/ps/vfze/components/vfze-list/vfze-list.component';
import { VfzeDetailComponent } from '@app/ps/vfze/components/vfze-detail/vfze-detail.component';
import { resolveId } from '@app/common/utils/state.utils';
import { VfzeImportComponent } from '@app/ps/vfze/components/vfze-import/vfze-import.component';

export const vfzeListState: Ng2StateDeclaration = {
  name: 'symap.project.vfze',
  url: '/vfze?page',
  data: {
    title: 'Přehled importů VFZE',
  },
  views: {
    'content@symap.project': {
      component: VfzeListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const vfzeDetailState: Ng2StateDeclaration = {
  name: 'symap.project.vfze.detail',
  url: '/:id',
  data: {
    title: 'Detail VFZE',
  },
  views: {
    'content@symap.project': {
      component: VfzeDetailComponent,
    }
  },
  resolve: {
    vfzeId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export const vfzeImportState: Ng2StateDeclaration = {
  name: 'symap.project.vfze.import',
  url: '/import',
  data: {
    title: 'Import VFZE',
  },
  views: {
    'content@symap.project': {
      component: VfzeImportComponent,
    }
  }
};
