import { ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import { ExpertModel } from '@app/ps/models/expert.model';
import { TitleModel } from '@app/common/models/title.model';
import { OpinionRequestModel } from '@app/ps/models/opinion-request.model';

export class OpinionModel {
  id: number;
  priceType: 'E' | 'O' | 'P';
  occupationType: 'P' | 'T';
  expertOpinionPricingMethod: 'M' | 'A';
  preliminaryOpinionNumber: string;
  expertOpinionNumber: string;
  expert: ExpertModel;
  expertOpinionDate: string;
  otherSource: string;
  parcelPrices: ParcelPriceModel[];
  expertOpinionLand: boolean;
  expertOpinionCompensation: boolean;
  expertOpinionVegetation: boolean;
  expertOpinionBuildings: boolean;
  valid: boolean;
  title: TitleModel;

  otherOwnership: any;
  countParcelPrices: number;
  compensationPrice: number;
  landPrice: number;
  vegetationPrice: number;
  buildingsPrice: number;
  caseExistsAndNotCancelled?: boolean;
  opinionRequest: OpinionRequestModel;

  static createDefaultExpertOpinionPermanent(): OpinionModel {
    const opinion = new OpinionModel();
    opinion.priceType = 'E';
    opinion.occupationType = 'P';
    opinion.expertOpinionPricingMethod = 'M';
    opinion.expertOpinionLand = true;
    opinion.expertOpinionVegetation = true;
    opinion.expertOpinionBuildings = true;
    opinion.expertOpinionCompensation = false;
    return opinion;
  }

  static createDefaultExpertOpinionTemporary(): OpinionModel {
    const opinion = new OpinionModel();
    opinion.priceType = 'E';
    opinion.occupationType = 'T';
    opinion.expertOpinionPricingMethod = 'M';
    opinion.expertOpinionLand = false;
    opinion.expertOpinionVegetation = false;
    opinion.expertOpinionBuildings = false;
    opinion.expertOpinionCompensation = true;
    return opinion;
  }

  static createDefaultOtherSourcePermanent(): OpinionModel {
    const opinion = new OpinionModel();
    opinion.priceType = 'O';
    opinion.occupationType = 'P';
    return opinion;
  }

  static createDefaultOtherSourceTemporary(): OpinionModel {
    const opinion = new OpinionModel();
    opinion.priceType = 'O';
    opinion.occupationType = 'T';
    return opinion;
  }
}
