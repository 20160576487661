export enum ReadinessStateEnum {
  COMPLETE_DATA = 'COMPLETE_DATA',
  PARTIAL_DATA = 'PARTIAL_DATA',
  NO_DATA = 'NO_DATA',
  NOT_DELIVERED_DATA = 'NOT_DELIVERED_DATA'
}

export const readinessStateOptions = [
  { id: ReadinessStateEnum.COMPLETE_DATA, value: 'úplná data' },
  { id: ReadinessStateEnum.PARTIAL_DATA, value: 'částečná data' },
  { id: ReadinessStateEnum.NO_DATA, value: 'neobsahuje data' },
  { id: ReadinessStateEnum.NOT_DELIVERED_DATA, value: 'nedodaná data' },
];
