import { Component, OnInit } from '@angular/core';

import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { createSubject, ProjectSubjectModel } from '@app/ps/models/project-subject.model';

@Component({
  selector: 'project-subject-simple-ofo',
  templateUrl: './project-subject-simple-ofo.component.html',
  styleUrls: []
})
export class ProjectSubjectSimpleOfoComponent implements OnInit {

  projectSubject: ProjectSubjectModel;

  constructor(
    private dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
  ) {
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  ngOnInit() {
    const projectSubject = (<DialogConfigData>this.dialogConfig.data).projectSubject;
    const discriminator = (<DialogConfigData>this.dialogConfig.data).discriminator;
    this.projectSubject = projectSubject ? projectSubject : createSubject(discriminator);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.projectSubject);
  }
}
