import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';

interface DistributionPart {
  id: number;
  name: string;
}

@Component({
  selector: 'distribution-system-parts',
  templateUrl: './distribution-system-parts.component.html',
  styleUrls: ['./distribution-system-parts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributionSystemPartsComponent implements OnInit {
  @Input() parts: DistributionPart[];
  @Input() options: any;
  @Output() optionAdded = new EventEmitter<any>();

  newPart: Partial<DistributionPart> = {};
  reloadOptions: Function;

  constructor(
    private restangular: Restangular,
  ) { }

  ngOnInit() {
    this.createPart = this.createPart.bind(this);
    this.filter = this.filter.bind(this);
    this.onSelectedPartChange = this.onSelectedPartChange.bind(this);
    this.onNewPartNameChange = this.onNewPartNameChange.bind(this);
    this.addOnOptionsReloadCallback = this.addOnOptionsReloadCallback.bind(this);
  }

  onNewPartNameChange(event: any) {
    this.newPart.name = event;
  }

  removePart(part: DistributionPart) {
    _.pull(this.parts, part);
    this.reloadOptions();
  }

  onSelectedPartChange(event: any) {
    event.data.selected = undefined;
    this.parts.push(event.newValue);
    this.reloadOptions();
  }

  filter(item: DistributionPart): boolean {
      return !_.some(this.parts, { id: item.id });
  }

  addOnOptionsReloadCallback(reloadFn: Function) {
    this.reloadOptions = reloadFn;
  }

  async createPart(): Promise<DistributionPart> {
    const data: Promise<DistributionPart> = await this.restangular
      .all('distribution-system-parts')
      .all('create')
      .post(_.clone(this.newPart))
      .toPromise()
      .then(d => d.plain());

    const strippedData: any = data;

    if (!_.some(this.parts, { id: strippedData.id })) {
      this.parts.push(strippedData);
    }

    if (!_.some(this.options, { id: strippedData.id })) {
      this.options.push(strippedData);
    } else {
      this.reloadOptions();
    }

    return strippedData;
  }
}

