import { Injectable } from '@angular/core';
 import * as  L from 'leaflet';

/**
 * Extension of LeafletGroup, added id attribute.
 */
@Injectable({
  providedIn: 'root'
})
export class LeafletLayerGroupService {
    private LayerGroup = L.LayerGroup.extend({
        initialize: function (layers, id) {
            if(!id) {
                throw new Error("Missing layer group id option in initialization.");
            }

            this._id = id;

            L.LayerGroup.prototype.initialize.call(this, layers);
        }
    });

     getLayerGroup(layers, id) {
        return new this.LayerGroup(layers, id);
    }
}
