import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '@app/common/services/auth.service';
import { ProjectModel } from '@app/ps/models/project.model';
import { StateService } from '@uirouter/angular';
import { StringUtils } from '@app/common/utils/string.utils';
import { DialogService } from '@app/common/services/dialog.service';
import { Restangular } from 'ngx-restangular';
import { UploadService } from '@app/common/services/upload.service';
import { CaseModel } from '@app/ps/models/case.model';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import { saveAs } from 'file-saver';
import { MapPrintResult } from '@app/ps/map/models/map-print.model';
import { MapPrintDescriptionComponent } from '@app/ps/map/components/map-print-description/map-print-description.component';
import { MapPrintLayersComponent } from '@app/ps/map/components/map-print-layers/map-print-layers.component';
import { MapPrintService } from '@app/ps/map/services/map-print.service';

@Component({
  selector: 'map-print',
  templateUrl: './map-print.component.html',
  styleUrls: ['./map-print.component.scss']
})
export class MapPrintComponent implements OnInit, OnDestroy {

  static readonly ENTITY_MAPPER = {
    businessCase: { id: 'caseId', layer: 'case_geometry', name: 'Zvýraznění předmětu smlouvy' },
    occupation: { id: 'occupationId', layer: 'occupation_geometry', name: 'Zvýraznění záboru' },
    parcel: { id: 'parcelId', layer: 'parcel_geometry', name: 'Zvýraznění parcely' },
    constructionObject: { id: 'constructionObjectId', layer: 'construction_object_geometry', name: 'Zvýraznění stavebního objektu' },
    subject: { id: 'subjectId', layer: 'subject_geometry', name: 'Zvýraznění vlastníka' },
    title: { id: 'titleId', layer: 'title_geometry', name: 'Zvýraznění LV' },
  };

  @Input() id;
  @Input() type;

  step = 1;
  project: ProjectModel;
  data: MapPrintResult;
  case: CaseModel;
  store = false;
  attachmentName: string;
  documentName: string;
  storeName: string;
  descriptionBefore = '';
  descriptionAfter = '';
  previewLoading: boolean;
  storeExists = false;
  showOwnership = false;
  showCaseNumber = false;
  ownership = '';
  caseNumber = '';

  constructor(
    private restangular: Restangular,
    private uploadService: UploadService,
    private authService: AuthService,
    private mapPrintService: MapPrintService,
    private dialogService: DialogService,
    private stateService: StateService,
    private subjectNamePipe: SubjectNamePipe,
  ) {
    this.onSecondStep = this.onSecondStep.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onPrintPdf = this.onPrintPdf.bind(this);
    this.onPrintDocx = this.onPrintDocx.bind(this);
    this.onPrintPng = this.onPrintPng.bind(this);
  }

  ngOnInit() {
    this.data = this.mapPrintService.getData(this.id);
    this.project = this.authService.getActualProject();
    this.mapPrintService.removeData(this.id);

    // add default layer to layers
    if (this.data.entity) {

      if (this.data.entity.type === 'businessCase') {
        this.loadCase();
      }

      const entityGeometry = {
        id: MapPrintComponent.ENTITY_MAPPER[this.data.entity.type].layer,
        style: {
          stroke: { r: 255, g: 0, b: 255, a: 1 },
          fill: { r: 255, g: 255, b: 255, a: 0 }
        },
        title: MapPrintComponent.ENTITY_MAPPER[this.data.entity.type].name,
        visible: true,
      };

      this.data.layers.push(entityGeometry);
    }

    // fill values from stored print type
    const storedPrintTypes = this.mapPrintService.getStoredPrintTypes();

    if (storedPrintTypes && this.data.printType && storedPrintTypes.find(p => p.id === this.data.printType.id)) {
      this.attachmentName = (this.data.printType as any).attachmentName;
      this.descriptionBefore = (this.data.printType as any).descriptionBefore;
      this.descriptionAfter = (this.data.printType as any).descriptionAfter;
      this.storeName = this.data.printType.name;
      this.attachmentName = (this.data.printType as any).attachmentName;
      this.showOwnership = (this.data.printType as any).showOwnership || false;
      this.showCaseNumber = (this.data.printType as any).showCaseNumber || false;

      // set layers by stored legend
      if ((this.data.printType as any).customLayers) {
        this.data.layers.forEach(l => {
          const layer = (this.data.printType as any).customLayers.find(ll => l.id === ll.id);

          if (layer) {
            l.title = layer.title;
            l.visible = layer.visible;
          }
        });
      }
    }
  }

  ngOnDestroy() {
  }

  onEditDescription(type: 'descriptionBefore' | 'descriptionAfter' | 'ownership' | 'attachmentName') {
    const dialog = this.dialogService.open(MapPrintDescriptionComponent, {
      data: { description: this[type] }
    });

    const sub = dialog.afterClosed.subscribe((res) => {
      if (res !== false) {
        this[type] = res;
      }
      sub.unsubscribe();
    });
  }

  onEditLayers() {
    const dialog = this.dialogService.open(MapPrintLayersComponent, {
      data: { layers: this.data.layers }
    });

    const sub = dialog.afterClosed.subscribe((res) => {
      if (res !== false) {
        this.data.layers = res;
      }
      sub.unsubscribe();
    });
  }

  computePreviewHeight() {
    const width = document.getElementById('mapPreview').getBoundingClientRect().width;
    const height = width / this.data.formatType.paperWidth * this.data.formatType.paperHeight;
    return height + 'px';
  }

  isFirstStepValid(): boolean {
    return this.type !== 'case' || !!this.attachmentName;
  }

  isSecondStepValid(): boolean {
    return !!(this.documentName && (!this.store || this.storeName));
  }

  checkStoreExists(storeName: string) {
    this.storeExists = !!this.mapPrintService.getStoredPrintType(storeName);
  }

  onSecondStep() {
    this.step = 2;
    this.previewLoading = undefined;
  }

  onPreview(): Promise<any> {
    this.previewLoading = true;
    return this.getFile('pdf')
      .then((file) => {
        this.previewLoading = false;
        const url = URL.createObjectURL(file) + '#toolbar=0';
        const obj = document.getElementById('mapPreviewObj') as any;
        obj.data = url;
      });
  }

  onPrintPdf(): Promise<any> {
    return this.onPrint('pdf');
  }

  onPrintDocx(): Promise<any> {
    return this.onPrint('docx');
  }

  onPrintPng(): Promise<any> {
    return this.onPrint('png');
  }

  private onPrint(type: 'pdf' | 'docx' | 'png'): Promise<any> {
    this.storeSettings();

    if (this.type === 'common') {
      return this.getFile(type)
        .then((file) => {
          saveAs(file, this.documentName + '.' + type, true);
        });
    }

    return this.getFile(type)
      .then((file) => {
        const restBaseUrl = this.authService.getActiveApplicationRestUrl();
        const sendData = {
          file: new File([file], this.documentName + '.' + type, { type: file.type }),
          filename: this.documentName + '.' + type,
          caseId: this.data.entity.id,
          attachmentType: 'CONTRACT_MAP_ATTACHMENT'
        };
        return this.uploadService.upload({
          url: restBaseUrl + '/attachments',
          data: sendData,
          headers: { Authorization: this.authService.getToken() }
        });
      })
      .then(() => {
        this.stateService.go('symap.project.cases.detail', { id: this.data.entity.id, tab: 'documents' });
      });
  }

  private getFile(type: 'pdf' | 'docx' | 'png'): Promise<Blob> {
    const request = {
      denominator: this.data.measureType.denominator,
      format: { width: this.data.formatType.width, height: this.data.formatType.height },
      paperFormat: { width: this.data.formatType.paperWidth, height: this.data.formatType.paperHeight },
      boundingBox: this.data.boundingBox,
      layers: this.data.layers,
      descriptionBefore: this.descriptionBefore,
      descriptionAfter: this.descriptionAfter,
      attachmentName: this.attachmentName,
      ownership: this.ownership,
      caseNumber: this.caseNumber,
      showCaseNumber: this.showCaseNumber,
      showOwnership: this.showOwnership,
      layout: this.data.formatType.layout,
    };

    if (this.data.entity) {
      request[MapPrintComponent.ENTITY_MAPPER[this.data.entity.type].id] = this.data.entity.id;
    }

    return this.covertLayers()
      .then(() => {
        if (type === 'pdf') {
          return this.restangular.all('map/pdf')
            .withHttpConfig({responseType: 'blob'})
            .customPOST(request)
            .toPromise()
            .then((res) => {
              this.previewLoading = false;
              return new Blob([res], { type: 'application/pdf' });
            });
        } else if (type === 'docx') {
          return this.restangular.all('map/docx')
            .withHttpConfig({responseType: 'blob'})
            .customPOST(request)
            .toPromise()
            .then((res) => {
              this.previewLoading = false;
              return new Blob([res], { type: 'application/xml' });
            });
        } else if (type === 'png') {
          return this.restangular.all('map/png')
            .withHttpConfig({responseType: 'blob'})
            .customPOST(request)
            .toPromise()
            .then((res) => {
              this.previewLoading = false;
              return new Blob([res], { type: 'image/png' });
            });
        }
      });
  }

  private storeSettings() {
    if (!this.store) {
      return;
    }

    const printType = {
      id: this.storeName,
      name: this.storeName,
      custom: true,
      format: this.data.formatType.id,
      measureType: this.data.measureType,
      customLayers: this.data.layers,

      attachmentName: this.attachmentName,
      descriptionBefore: this.descriptionBefore,
      descriptionAfter: this.descriptionAfter,
      showCaseNumber: this.showCaseNumber,
      showOwnership: this.showOwnership,
    };

    this.mapPrintService.addStoredPrintType(printType);
  }

  private covertLayers(): Promise<any[]> {
    const promises = [];

    for (const l of this.data.layers) {
      if (l.icon) {
        const promise = StringUtils.imageToBase64(l.icon).then(data => l.iconBase64 = data);
        promises.push(promise);
      }
    }

    return Promise.all(promises);
  }

  private loadCase() {
    return this.restangular.one('cases', this.data.entity.id)
      .get({loadCollections: ['caseSubjects', 'caseOwnerships']})
      .toPromise()
      .then(data => {
        const b = [];

        for (const ownership of data.caseOwnerships) {
          for (const subject of ownership.ownership.subjects) {
            b.push(this.subjectNamePipe.transform(subject));
          }
        }

        this.ownership = [...new Set(b)].join(', ');
        this.caseNumber = data.mpp.number;
      });
  }
}
