import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { KnGpModel } from '@app/common/models/kn-gp.model';

@Pipe({
  name: 'knGpName',
  pure: false,
})
@Injectable({
  providedIn: 'root'
})
export class KnGpNamePipe implements PipeTransform {

  transform(knGp: KnGpModel) {
    return `${knGp.cis} [id: ${knGp.id}]`;
  }
}
