import { Injectable, Inject } from '@angular/core';
import { ParcelService } from '@app/ps/services/parcel.service';
import * as _ from 'lodash';
import { Restangular } from 'ngx-restangular';
import { TitleDetailCreateCaseDataModel } from "@app/ps/models/title-detail-create-case-data.model";

@Injectable({
  providedIn: 'root'
})
export class TitleDetailCreateCaseDataService {

  constructor(
    private restangular: Restangular,
    private parcelService: ParcelService
  ) {
    this.filterObligations = this.filterObligations.bind(this);
    this.assignCasesObjects = this.assignCasesObjects.bind(this);
    this.assignCasesToOwnerships = this.assignCasesToOwnerships.bind(this);
    this.groupOccupationsByParcel = this.groupOccupationsByParcel.bind(this);
    this.checkLoadedTitleData = this.checkLoadedTitleData.bind(this);
    this.checkLoadedGeneralData = this.checkLoadedGeneralData.bind(this);
    this.loadTitleData = this.loadTitleData.bind(this);
    this.loadGeneralData = this.loadGeneralData.bind(this);
  }

  // filters obligations to be available depending on occupation types
  filterObligations(data: TitleDetailCreateCaseDataModel) {
    data.dataObligationsFiltered = data.dataObligations.filter((obligation) => {
      const objectTypes = obligation.objectTypes;
      return objectTypes &&
        (((objectTypes.easementsGeometricPlan || objectTypes.easementsLandTake) && !!data.dataEasements.length) ||
          (objectTypes.occupations && !!data.dataOccupations.length) ||
          (objectTypes.buildings && !!data.dataBuildings.length) ||
          (objectTypes.occupationTypes && objectTypes.occupationGroupKeys && _.some(data.dataOccupations, (occupation) => objectTypes.occupationGroupKeys.includes(occupation.solutionType) || (!occupation.solutionType && objectTypes.occupationTypes.includes(occupation.zabtyp)))));
    });
  }

  // assign cases to occupations/easements/ownerships, these data are later filtered by case.obligation to disable some entity selections
  assignCasesObjects(data: TitleDetailCreateCaseDataModel) {
    const equals = (occupation1, occupation2) => {
      return occupation1.zabidpar === occupation2.zabidpar && occupation1.zabku === occupation2.zabku && occupation1.zabst === occupation2.zabst && occupation1.zabcis === occupation2.zabcis && occupation1.zabtyp === occupation2.zabtyp;
    };
    data.dataCases.forEach((businessCase) => {
      // push case to grouped occupations
      businessCase.occupations.forEach((occupation) => {
        Object.keys(data.dataOccupationGroups).forEach(key => {
          const group = data.dataOccupationGroups[key];
          group.forEach(groupItem => {
            if (groupItem.occupations.some((occupation2) => equals(occupation, occupation2))) {
              // make it unique for grouped permanent occupations
              if (!groupItem.cases.includes(businessCase)) {
                groupItem.cases.push(businessCase);
              }
            }
          });
        });
      });

      // push case to easements
      businessCase.easements.forEach((easement) => {
        const foundEasement = _.find(data.dataEasements, (dataEasement) => {
          return dataEasement.geometricPlan.id === easement.geometricPlan.id && dataEasement.oznacVb === easement.oznacVb && dataEasement.idpar === easement.idpar;
        });

        if (foundEasement !== undefined) {
          if (foundEasement.cases === undefined) {
            foundEasement.cases = [];
          }
          foundEasement.cases.push(businessCase);
        }
      });

      // push case to buildings
      businessCase.caseBuildings.forEach((caseBuilding) => {
        const foundBuilding = _.find(data.dataBuildings, (dataBuilding) => {
          return dataBuilding.building.budId === caseBuilding.building.budId;
        });

        if (foundBuilding !== undefined) {
          if (foundBuilding.cases === undefined) {
            foundBuilding.cases = [];
          }
          foundBuilding.cases.push(businessCase);
        }
      });
    });
  }

  assignCasesToOwnerships(data: TitleDetailCreateCaseDataModel) {
    data.dataCases.forEach((businessCase) => {
      // push case to ownerships
      businessCase.caseOwnerships.forEach((caseOwnership) => {

        const foundOwnerships = _.filter(data.dataOwnerships, (value) => value.ownership.opsubId === caseOwnership.ownership.opsubId);

        foundOwnerships.forEach((ownership) => {
          ownership.cases.push(businessCase);
        });
      });
    });
  }

  // group permanent occupations by parcel
  groupOccupationsByParcel(data: TitleDetailCreateCaseDataModel) {
    data.dataOccupationGroups = this.parcelService.groupOccupationsByParcel(data.dataOccupations);
    Object.keys(data.dataOccupationGroups).forEach(key => {
      const group = data.dataOccupationGroups[key];
      group.forEach((occupationGroup) => {
        occupationGroup.cases = [];
        occupationGroup.compensationParcelPrices = _.filter(data.dataParcelPrices, {idpar: occupationGroup.parcel.idpar, opinion: {occupationType: 'T'}});
      });
    });
  }

  extendBuildings(data) {
    return _.map(data, (building) => {
      return {
        building: building
      };
    });
  }

  // when all data id loaded prepares them for preview
  checkLoadedTitleData(datas) {
    const data: TitleDetailCreateCaseDataModel = {};
    data.dataParcelPrices = datas[0].plain();;
    data.dataOpinions = [];
    data.dataParcelPrices.forEach((parcelPrice) => {
      let opinion = _.find(data.dataOpinions, {id: parcelPrice.opinion.id});
      if (!opinion) {
        opinion = {...parcelPrice.opinion};
        data.dataOpinions.push(opinion);
      }

      opinion.parcelPrices.push(parcelPrice);
    });
    data.dataOccupations = datas[1].plain();
    data.dataOccupations.forEach((occupation) => {
      occupation.constructionObjects = _.filter(occupation.constructionObjects, (co) => !co.endDate);
    });
    this.groupOccupationsByParcel(data);
    data.dataEasements = datas[2].plain();
    data.dataCases = datas[3].plain();
    data.dataBuildings = this.extendBuildings(datas[4].plain());
    data.dataOwnerships = [];
    this.assignCasesObjects(data);
    return data;
  }

  checkLoadedGeneralData(datas) {
    const data = datas[3];
    data.dataObligations = datas[0].plain();

    data.dataTitle = datas[1].plain();

    data.dataOwnerships = datas[2].plain().filter(o => !o.typeIgnore).map((value) => {
      return {
        id: value.id,
        ownership: value,
        cases: []
      };
    });

    this.filterObligations(data);
    this.assignCasesToOwnerships(data);
    return data;
  }

  loadTitleData(titleId) {
    return Promise.all([
      this.restangular.all('parcel-prices').customPOST({filter: {filters: {titleId: [titleId], validity: ['valid'], opinionPriceType: ['E', 'O']}}}).toPromise(),
      this.restangular.all('occupations').customPOST({filter: {filters: {titleId: [titleId], validityParcelCase: ['valid_with_parcel'], loadCollections: ['constructionObjects', 'parcel.bpejList', 'parcel.parcelProtections', 'parcel.knBudouc.currentParcels']}}}).toPromise(),
      this.restangular.all('easements').customPOST({
        filter: {filters: {titleId: [titleId], validity: ['valid'], parcelValidity: ['valid']}},
        attributes: {constructionObjects: {
            load: true,
            attributes: {
              administrator: {load: true},
            }
          }}
      }).toPromise(),
      this.restangular.all('cases').customPOST({filter: {filters: {parcelTitleId: [titleId], cancelStatus: ['notCancelled'], loadCollections: ['caseBuildings', 'constructionObjects', 'occupations', 'easements', 'caseOwnerships']}}}).toPromise(),
      this.restangular.all('buildings').customPOST({filter: {filters: {occupationOrEasementExists: [true], titleId: [titleId], validity: ['valid'], differentTitle: [true], loadCollections: ['parcelsWithEasementOrOccupation', 'buildingProtections', 'parcels.parcelProtections', 'parcels.constructionObjects']}}}).toPromise()
    ]).then(this.checkLoadedTitleData);
  }

  loadGeneralData(titleId, titleDataPromise) {
    return Promise.all([
      this.restangular.all('obligations').customPOST({filter: {filters: {}}}).toPromise(),
      this.restangular.one('titles', titleId).get().toPromise(),
      this.restangular.all('ownerships').customPOST({
        filter: { filters: { titleId: [titleId], validity: ['valid'] }},
        attributes: { parentOwnership: { load: true }}
      }).toPromise(),
      titleDataPromise
    ]).then(this.checkLoadedGeneralData);
  }
}
