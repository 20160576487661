import { Component, Inject, Input, OnInit } from '@angular/core';
import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import * as _ from 'lodash';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'payment-check',
  templateUrl: './payment-check.component.html',
  styleUrls: ['./payment-check.component.scss']
})
export class PaymentCheckComponent implements OnInit {

  @Input() caseId: number;
  @Input() preliminary = false;
  loading = true;
  data: CaseModel;
  dataCaseOwnerships = [];
  templateTypeKey;
  getCheckedItems: Function;
  generatePayment;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private authService: AuthService,
    private restangular: Restangular,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
    this.templatesGetter = this.templatesGetter.bind(this);
    this.generateSetter = this.generateSetter.bind(this);
    this.getCheckedItemsGetter = this.getCheckedItemsGetter.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.toggleSinglePayment = this.toggleSinglePayment.bind(this);
  }

  async ngOnInit() {
    this.data = await this.restangular.one('cases', this.caseId).get({loadCollections: ['caseSubjects', 'caseOwnerships']}).toPromise();
    this.data.caseOwnerships = _.filter(this.data.caseOwnerships, ['isParent', false]);
    this.data.caseOwnerships.forEach((item) => { // add Subjects as array into Ownership
      this.dataCaseOwnerships.push({
        caseOwnership: item,
        caseSubjects: this.data.caseSubjects.filter(
          (value) => {
            let bsmSubject;
            if (item.ownership.isBsm) {
              bsmSubject = this.data.caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId).subject;
            }
            return (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId));
          })
      });
    });
    this.templateTypeKey = this.data.obligation.type + 'PaymentInstruction' + (this.preliminary ? 'Preliminary' : '');

    this.loading = false;
  }

  disabled() {
    return !(this.getCheckedItems().length > 0);
  }

  templatesGetter() {
    const templateTypes = [{
      key: this.templateTypeKey,
      required: true,
      templates: []
    }];

    const keys = templateTypes.map(templateTypes => templateTypes.key);

    return this.restangular.all('templates').customPOST({filter: {filters: {validity: ['valid'], key: keys}}}).toPromise().then((data) => {
      templateTypes.forEach((templateType) => {
        templateType.templates = _.filter(data, {key: templateType.key});
        templateType.templates.forEach(template => {
          if (this.APP_BRAND.NAME === 'RSD' && this.authService.getActualProject().useTemplatesService) {
            template.commonName = template.attachment.baseName;
            template.attachment.baseName = this.data.mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
          } else {
            template.commonName = template.attachment.baseName;
          }
        });
      });
      return templateTypes;
    });
  }

  generateSetter(generateDocuments) {
    this.generatePayment = generateDocuments.generate;
  }

  getCheckedItemsGetter(checkedItemsGetter) {
    this.getCheckedItems = checkedItemsGetter.checkedItemsGetter;
  }

  updatePayment() {
    return (this.data as any).put({loadCollections: ['caseSubjects', 'caseOwnerships']})
      .toPromise()
      .then((caseData) => {
        return this.generatePayment().then((attachment) => {
          this.stateService.go('^');
          // TODO - cases - puvodne se zde volal nejaky callback pro update priloh atp v detailu smlouvy
        });
    });
  }

  toggleSinglePayment(caseOwnership) {
    caseOwnership.caseOwnership.singlePayment = !caseOwnership.caseOwnership.singlePayment;
    if (caseOwnership.caseOwnership.singlePayment) {
      caseOwnership.caseSubjects.forEach(this.clearPayment);
    } else {
      this.clearPayment(caseOwnership.caseOwnership);
    }
  }

  clearPayment(object) {
    object.paymentType = undefined;
    object.accountNumber = null;
    object.bankCode = null;
    object.variableSymbol = null;
    object.specificSymbol = null;
    object.constantSymbol = null;
  }
}
