﻿import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SubjectNamePipe } from '@app/common/pipes/subject-name.pipe';
import * as _ from 'lodash';
import { ParcelModel } from '@app/common/models/parcel.model';
import { ChecklistModel } from '@app/common/models/checklist.model';

@Component({
  selector: 'title-detail-create-case-select-objects',
  templateUrl: './create-case-select-objects.component.html',
})
export class CreateCaseSelectObjectsComponent implements OnInit {
  @Input()
  generalDataPromise: Promise<any>;
  @Input()
  titleDataPromise: Promise<any>;
  @Input()
  titleId: number;
  @Input()
  data: any;
  @Output()
  private submitCallback = new EventEmitter();

  loading = false;
  dataSubject: any;
  checklistOwnership: any;
  checklistGroup: any;
  checklistEasement: any;
  checklistBuilding: any;
  checklistConstructionObject: any;
  dataOwnerships: any[];
  dataEasements: any[];
  dataOccupationGroups: any[];
  dataBuildings: any[];
  parcelsExternal: ParcelModel[];
  selection: any;
  selectObjectsCheckObjectsByData: Function;
  private refreshItems: Function;
  private resetSelection: Function;
  private isSelectionValidLocal: Function;
  private setByBusinessCase = false;

  constructor(
    public subjectNamePipe: SubjectNamePipe,
  ) {
    this.submit = this.submit.bind(this);
  }

  ngOnInit() {
    this.dataSubject = {};
    this.checklistOwnership = new ChecklistModel;
    this.checklistGroup = new ChecklistModel;
    this.checklistEasement = new ChecklistModel;
    this.checklistBuilding = new ChecklistModel;
    this.checklistConstructionObject = new ChecklistModel();
    this.prepareData();
  }

  prepareData() {
    if (this.data.fromCase && !this.data.titles) {
      this.setByBusinessCase = true;
    }
    this.data.titles = this.data.titles || [];
    this.loading = true;
    this.generalDataPromise.then((dataLoaded) => {
      this.dataOwnerships = dataLoaded.dataOwnerships;
      this.dataEasements = dataLoaded.dataEasements;
      this.dataOccupationGroups = dataLoaded.dataOccupationGroupsFiltered;
      this.dataBuildings = dataLoaded.dataBuildings;
      if (!this.data.titles.length) {
        this.data.titles.push(dataLoaded.dataTitle);
      }
      this.loading = false;
    });
  }

  checkOwnershipsByData(data) {
    (data.caseOwnerships || []).forEach((caseOwnership) => {
      // if ownership of another title
      const ownership = this.dataOwnerships.find((item) => item.id === caseOwnership.ownership.id);
      if (ownership) {
        this.selection.toggleSelectionOwnership(ownership);
      }
    });
  }

  onSelectAllOwnerships() {
    if (this.selection) {
      this.selection.checkedAllOwnerships.value = !this.selection.checkedAllOwnerships.value;
      this.selection.toggleAll(
        this.selection.checkedAllOwnerships.value,
        this.checklistOwnership,
        this.dataOwnerships,
        this.selection.toggleSelectionOwnership
      );
    }
  }

  callbackRegister(callbacks) {
    this.selection = callbacks.selection;
    this.refreshItems = callbacks.refreshItems;
    this.resetSelection = callbacks.resetSelection;
    this.isSelectionValidLocal = callbacks.isSelectionValidLocal;
    this.selectObjectsCheckObjectsByData = callbacks.checkObjectsByData;
    this.checkOwnershipsByData(this.data);
    if (!this.data.caseOwnerships && (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated')) {
      this.onSelectAllOwnerships();
    }
    this.selectObjectsCheckObjectsByData(this.data);

    // výběr podle existující smlouvy
    if (this.setByBusinessCase) {
      callbacks.selectByCase();
      const dataTitle = this.data.titles[this.data.titles.length - 1];
      this.data.fromCase.caseOwnerships.forEach(caseOwnership => {
         const dataOwnership = this.dataOwnerships.find(dataOwnership =>
          dataOwnership.ownership.opsubId === caseOwnership.ownership.opsubId &&
          caseOwnership.ownership.lv === dataTitle.lv &&
          caseOwnership.ownership.katuzeKod === dataTitle.area.id
         );
         if (dataOwnership) {
           this.selection.toggleSelectionOwnership(dataOwnership);
         }
      });

      this.submit();
    }
  }



  isSelectionValid() {
    return this.checklistOwnership.checkedItems.length
            && this.isSelectionValidLocal && this.isSelectionValidLocal()
            && (this.data.obligation.type !== 'InnominateSalesContractLiability'
                    || (!!this.data.innominationSubject && !!this.data.innominationPrice))
            && ((this.data.obligation.type !== 'RealBurdenLiability' && this.data.obligation.type !== 'RealBurdenLiabilityAccelerated')
                    || this.checklistOwnership.checkedItems.length === this.dataOwnerships.length)
            && (this.data.obligation.type !== 'ExchangeContractLiability'
                    || (this.parcelsExternal && this.parcelsExternal.length !== 0));
  }

  submit() {
    if (this.isSelectionValid()) {
      const data: any = {};
      data.occupations = [];
      data.easements = [];
      data.buildings = [];
      data.constructionObjects = [];
      data.caseOwnerships = [];
      data.parcelsExternal = this.parcelsExternal;

      this.checklistGroup.checkedItems.forEach((group) => {
        group.occupations.forEach((occupation) => {
          data.occupations.push(occupation);
        });
      });

      this.checklistBuilding.checkedItems.forEach((building) => {
        data.buildings.push(building);
      });

      this.checklistEasement.checkedItems.forEach((easement) => {
        easement = _.cloneDeep(easement);
        delete easement.cases;
        data.easements.push(easement);
      });

      this.checklistConstructionObject.checkedItems.forEach((caseConstructionObject) => {
        data.constructionObjects.push(caseConstructionObject.constructionObject);
      });

      this.checklistOwnership.checkedItems.forEach((caseOwnership) => {
        data.caseOwnerships.push({ownership: caseOwnership.ownership});
      });

      Object.assign(this.data, data);
    }
    return this.submitCallback.emit();
  }

  onParcelsExternalSelected(parcels: ParcelModel[]) {
    this.parcelsExternal = parcels;
  }
}
