import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ListModel } from '@app/common/models/list.model';
import { SortModel } from '@app/common/models/sort.model';
import { UICONSTANTS } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';
import {saveAs} from "file-saver";

@Component({
  selector: 'data-ze',
  templateUrl: './data-ze.component.html',
})
export class DataZeComponent implements OnInit {
  @Input() project: any;
  precheckCompleted = false;
  precheckList: ListModel<any>;
  sortOrder: SortModel = { sortBy: 'roleName', direction: 'asc' };
  itemsPerPage = UICONSTANTS.itemsPerPage10;
  dataPaging = { itemsPerPage: { id: 10, name: 10 }};
  onListChangedPagination: Function;
  onListChangedItemCounter: Function;
  onListChangedPagesCounter: Function;
  precheck: any[] = [];
  updateResult;
  globalSeverity = null;

  constructor(
    private restangular: Restangular,
  ) {
    this.onPrecheck = this.onPrecheck.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onDownloadCsv = this.onDownloadCsv.bind(this);
  }

  ngOnInit(): void {
  }

  onPrecheck(): Promise<any> {
    this.updateResult = undefined;
    this.globalSeverity = null;
    return this.restangular
      .one('construction-objects/precheck-symap-update')
      .post()
      .toPromise()
      .then(data => {
        const domain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
        this.precheck = data.plain().map(i => {
          i.url = i.url ? i.url.replace('https://app.gmtech.cz', domain) : null;
          const severity = i.duvod.startsWith('nevalidní geometrie') || i.duvod.startsWith('geometrie projektu je v některém místě užší') ? 1 : 10;
          i.severity = severity;
          this.globalSeverity = Math.min(this.globalSeverity || severity, severity);
          return i;
        }).sort((a, b) => a.severity - b.severity);

        this.initList();
        this.precheckCompleted = true;
      });
  }

  onDownloadCsv() {
    let data = 'id;url;typ;sloupec;důvod' + '\n\r';

    data += this.precheck.map(row => {
      return Object.values(row).map(v => JSON.stringify(v)).join(';');
    }).join('\n\r');

    const blob = new Blob([data], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, 'export.csv');
  }

  onUpdate(): Promise<any> {
    this.updateResult = undefined;

    return this.restangular
      .one('construction-objects/update-occupation-symap-tables')
      .post()
      .toPromise()
      .then(r => {
        if (r && r.message) {
          this.updateResult = r.message;
        } else {
          this.updateResult = 'Update dokončen';
        }
      });
  }

  pagesCounterCallbackRegister(event) {
    this.onListChangedPagesCounter = event.onListChanged;
  }

  itemCounterCallbackRegister(event) {
    this.onListChangedItemCounter = event.onListChanged;
  }

  paginationCallbackRegister(event) {
    this.onListChangedPagination = event.onListChanged;
  }

  onSort(sortBy: string) {
    this.sortOrder.direction = sortBy === this.sortOrder.sortBy
      ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
      : 'asc';
    this.sortOrder.sortBy = sortBy;
    this.reloadList();
  }

  onPageChanged(event) {
    this.precheckList.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
    this.reloadList();
  }

  onPageItemsChanged() {
    this.precheckList.filter.offset = 0;
    this.reloadList();
  }

  onListChanged() {
    if (this.onListChangedPagination) {
      this.onListChangedPagination();
    }
    if (this.onListChangedItemCounter) {
      this.onListChangedItemCounter();
    }
    if (this.onListChangedPagesCounter) {
      this.onListChangedPagesCounter();
    }
  }

  reloadList() {
    this.precheck = _.orderBy(this.precheck, this.sortOrder.sortBy, this.sortOrder.direction as any);
    this.precheckList.list = this.precheck.slice(this.precheckList.filter.offset, this.precheckList.filter.offset + this.dataPaging.itemsPerPage.id);
    this.precheckList.itemCount = this.precheck.length;
    this.precheckList.filter.limit = this.dataPaging.itemsPerPage.id;
    this.onListChanged();
  }

  private initList() {
    this.precheckList = {
      loading: false,
      list: this.precheck.slice(0, this.dataPaging.itemsPerPage.id),
      itemCount: this.precheck.length,
      filter: this.precheckList ? this.precheckList.filter : { offset: 0, limit: this.dataPaging.itemsPerPage.id }
    };
    this.precheckList.filter.offset = 0;
    this.onListChanged();
  }
}
