import { ChangeDetectionStrategy, Input, Component, OnInit } from '@angular/core';

import { SettlementProgress } from '@app/ps/models/settlement-progress';
import { SettlementCategoryPipe } from '@app/ps/pipes/settlement-category.pipe';
import { TooltipParcelsComponent } from '@app/common/components/tooltip-parcels/tooltip-parcels.component';
import { TooltipEasementsComponent } from '@app/common/components/tooltip-easements/tooltip-easements.component';
import { CellAbstractComponent, CellTooltipMappingType } from '@app/common/components/cell-abstract/cell-abstract.component';

type OccupationCategoryType = 'permanent' | 'overOneYear' | 'underOneYear' | 'easement';

@Component({
  selector: 'settlement-progress-chart',
  templateUrl: './settlement-progress-chart.component.html',
  styleUrls: ['./settlement-progress-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettlementProgressChartComponent extends CellAbstractComponent implements OnInit {
  @Input() tooltipData: {
    occupationCategory: OccupationCategoryType,
    occupationResolution: SettlementProgress[],
  };
  @Input() data: SettlementProgress;
  public tooltipDataResult: any;

  protected TOOLTIP_MAPPING: CellTooltipMappingType = {
    parcels: TooltipParcelsComponent,
    easements: TooltipEasementsComponent,
  };

  defaultTooltipFilter: any = {
    customData: {},
    filter: {
      limit: null,
      filters: {},
    }
  };

  constructor(
    private settlementCategoryPipe: SettlementCategoryPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.tooltipDataResult = {...this.getTooltipFilter()};
  }

  /**
   * Filtering could be done in owner-list/titles component as well,
   * but it caused infinite digest cycle in angularjs titles component.
   */
  private getTooltipFilter() {
    const filter = this.defaultTooltipFilter;
    const occupationResolutionForCategory = this.settlementCategoryPipe.transform(this.tooltipData.occupationResolution, this.tooltipData.occupationCategory);

    if (!occupationResolutionForCategory) {
      filter.filter.entityIds = [];
      filter.customData.doneParcelIds = [];
      filter.customData.notStartedParcelIds = [];
      filter.customData.inProgressParcelIds = [];
    } else {
      filter.filter.entityIds = [
        ...occupationResolutionForCategory.doneParcelIds,
        ...occupationResolutionForCategory.notStartedParcelIds,
        ...occupationResolutionForCategory.inProgressParcelIds,
      ].map(i => this.data.occupationCategory == 'easement' ? i.easementId : i.parcelId);
      if (this.data.occupationCategory == 'easement') {
        filter.attributes = {'constructionObjects' : {}};
      }
      filter.customData.doneParcelIds = occupationResolutionForCategory.doneParcelIds;
      filter.customData.notStartedParcelIds = occupationResolutionForCategory.notStartedParcelIds;
      filter.customData.inProgressParcelIds = occupationResolutionForCategory.inProgressParcelIds;
    }

    return filter;
  }
}
