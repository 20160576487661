import { SendUserEmailComponent } from '@app/ps/project-settings/components/send-user-email/send-user-email.component';
import { Ng2StateDeclaration, StateParams, Transition } from '@uirouter/angular';

import { resolveCallbacks, resolveId } from '@app/common/utils/state.utils';
import { handleUserPermissionsModal, resolveProjectKey } from '@app/settings/users/users.states';

import { AdministratorListComponent } from '@app/ps/project-settings/components/administrator-list/administrator-list.component';
import { AdministratorDetailComponent } from '@app/ps/project-settings/components/administrator-detail/administrator-detail.component';
import { ExpertListComponent } from '@app/ps/project-settings/components/expert-list/expert-list.component';
import { ExpertDetailComponent } from '@app/ps/project-settings/components/expert-detail/expert-detail.component';
import { TemplateListComponent } from '@app/ps/project-settings/components/template-list/template-list.component';
import { SampleListComponent } from '@app/ps/project-settings/components/sample-list/sample-list.component';
import { ProjectSettingsMainComponent } from '@app/ps/project-settings/components/project-settings-main/project-settings-main.component';
import { TemplateHistoryComponent } from '@app/ps/project-settings/components/template-history/template-history.component';
import { UserListComponent } from '@app/common/components/user-list/user-list.component';
import { UserDetailComponent } from '@app/common/components/user-detail/user-detail.component';
import { UserCredentialsComponent } from '@app/common/components/user-credentials/user-credentials.component';
import { ProjectUpdateComponent } from '@app/ps/project-settings/components/project-update/project-update.component';
import { ProjectCreateComponent } from '@app/ps/project-settings/components/project-create/project-create.component';

import { AuthService } from '@app/common/services/auth.service';
import { ModulesService } from '@app/common/services/modules.service';
import { CallbackModel } from '@app/common/models/callback.model';
import { BulkPriceComponent } from '@app/ps/project-settings/components/bulk-price/bulk-price/bulk-price.component';
import {UIRouter} from "@uirouter/core";

export const projectSettingsMainState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings',
  url: '/settings',
  data: { title: 'Nastavení' },
  views: {
    'content@symap.project': {
      component: ProjectSettingsMainComponent
    }
  }
};

export const projectCreateState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.create',
  url: '/project-create',
  data: { title: 'Vytvoření projektu' },
  views: {
    'content@symap.project': {
      component: ProjectCreateComponent
    }
  }
};

export function resolveGToPrevState(stateParams: StateParams) {
  return stateParams.goToPrevState;
}

export function resolvePrevState(transition: Transition) {
  return transition.$from().name;
}

export const projectUpdateState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.update',
  url: '/project-update',
  data: { title: 'Nastavení' },
  views: {
    'content@symap.project': {
      component: ProjectUpdateComponent
    }
  },
  resolve: {
    goToPrevState: [ '$stateParams', resolveGToPrevState ],
    prevState: [ '$transition$', resolvePrevState ]
  },
  params: {
    goToPrevState: {
      type: 'bool',
      value: false
    },
  }
};

export const userCredentialsState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.userCredentials',
  url: '/user-credentials',
  data: {
    title: 'Změna přihlašovacích údajů',
  },
  views: {
    'content@symap.project': {
      component: UserCredentialsComponent,
    }
  },
  params: {
    backSref: {
      type: 'string',
      value: 'symap.project.projectSettings'
    },
  }
};

export const sendUserEmailState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.users.detail.email',
  url: '/send-user-email',
  data: { title: 'Email uživateli' },
  views: {
    'content@symap.project': {
      component: SendUserEmailComponent
    }
  }
};

export const administratorListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.administrators',
  url: '/administrators?page',
  data: {
    title: 'Správci $CONSTRUCTION_OBJECT_PLURAL_GENITIV',
  },
  views: {
    'content@symap.project': {
      component: AdministratorListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const administratorDetailState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.administrators.detail',
  url: '/:id',
  data: {
    title: 'Detail správce',
  },
  views: {
    'content@symap.project': {
      component: AdministratorDetailComponent,
    }
  },
  resolve: {
    administratorId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export const expertListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.experts',
  url: '/experts?page',
  data: {
    title: 'Odhadci',
  },
  views: {
    'content@symap.project': {
      component: ExpertListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const expertDetailState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.experts.detail',
  url: '/:id',
  data: {
    title: 'Detail odhadce',
  },
  views: {
    'content@symap.project': {
      component: ExpertDetailComponent,
    }
  },
  resolve: {
    expertId: [ '$stateParams', resolveId ]
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export const templateListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.templates',
  url: '/templates?page',
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
  onEnter: handleTemplateListState,
};

export async function handleTemplateListState(transition: Transition) {
  const authService = transition.injector().get(AuthService);
  const stateService = transition.injector().get(UIRouter).stateService;
  if (transition.$to().name !== templateListState.name) {
    return;
  }
  if (authService.getActualProject().useTemplatesService === true) {
    return stateService.go(templateExternalListState.name, transition.params());
  } else {
    return stateService.go(templateInternalListState.name, transition.params());
  }
}

export const templateInternalListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.templates.internal',
  data: {
    title: 'Šablony',
  },
  views: {
    'content@symap.project': {
      component: TemplateListComponent,
    },
  }
};

export const templateExternalListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.templates.external',
  data: {
    title: 'Vzory dokumentů',
  },
  views: {
    'content@symap.project': {
      component: SampleListComponent,
    },
  }
};

export function keyResolveFunc(stateParams: StateParams) {
  return stateParams.key;
}

export const templateHistoryState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.templates.history',
  url: '/history/:key?page',
  data: {
    title: 'Historie šablon',
  },
  views: {
    'content@symap.project': {
      component: TemplateHistoryComponent,
    },
  },
  resolve: {
    key: [ '$stateParams', keyResolveFunc ]
  },
  params: {
    key: {
      type: 'path',
      value: undefined
    },
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const userListState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.users',
  url: '/users?page',
  data: {
    title: 'Uživatelé',
  },
  views: {
    'content@symap.project': {
      component: UserListComponent,
    },
  },
  params: {
    page: {
      dynamic: true,
      type: 'int',
      inherit: false,
    },
  },
};

export const userDetailState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.users.detail',
  url: '/:id',
  data: {
    title: 'Detail uživatele',
  },
  views: {
    'content@symap.project': {
      component: UserDetailComponent,
    }
  },
  resolve: {
    userId: [ '$stateParams', resolveId ],
    callbacks: [ resolveCallbacks ],
  },
  params: {
    id: {
      type: 'int',
      value: undefined
    },
  },
};

export function resolveApplication(stateParams: StateParams, transition: Transition): string {
  const authService = transition.injector().get(AuthService);
  return authService.getActiveApplication();
}

export async function resolveUser(stateParams: StateParams, transition: Transition): Promise<any> {
  const modulesService = transition.injector().get(ModulesService);
  return modulesService.loadUser(stateParams.id).then(() => modulesService.getUser(stateParams.id));
}

export function resolvePermissionsCallback(callbacks: CallbackModel) {
  return callbacks.get('userPermissionsChangedCallback');
}

export const updateUserPermissionsState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.users.detail.updateUserPermissions',
  url: '/user-permissions',
  data: {
    title: 'Editace oprávnění uživatele',
  },
  resolve: {
    application: ['$stateParams', '$transition$', resolveApplication],
    projectKey: ['$stateParams', resolveProjectKey],
    user: ['$stateParams', '$transition$', resolveUser],
    userPermissionsChangedCallback: [ 'callbacks', resolvePermissionsCallback ],
  },
  onEnter: handleUserPermissionsModal
};

export const bulkPricesState: Ng2StateDeclaration = {
  name: 'symap.project.projectSettings.bulkPrices',
  url: '/bulk-prices',
  data: { title: 'Hromadné nastavení cen' },
  views: {
    'content@symap.project': {
      component: BulkPriceComponent
    }
  }
};
