import { Component, OnInit, Input, Inject } from '@angular/core';
import { Customer } from '@app/models/customer';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { ListModel } from '@app/common/models/list.model';
import { PageableList } from '@app/models/pageable.list';
import { HelpService } from '@app/common/services/help.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html',
})
export class CustomerListComponent implements OnInit {
  @Input() customer: Partial<Customer>;

  list: ListModel<any>;
  pageableList: PageableList;
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) private configurationRestangular: any,
    private pageableListService: PageableListService,
  ) {
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    this.showCustomerDepartments = this.showCustomerDepartments.bind(this);
    this.onToggleCustomerDepartments = this.onToggleCustomerDepartments.bind(this);
  }

  ngOnInit() {
    this.initializeList();
  }

  private getDefaultFilter() {
    return {
      filters: {
        searchText: {
          values: [{ id: '' }],
        },
      },
    };
  }

  uiOnParamsChanged(changedParams, transition) {
    this.pageableList && this.pageableList.uiOnParamsChanged(changedParams, transition);
  }

  showCustomerDepartments() {
    return this.list && this.list.filter.filters.includeDepartments && this.list.filter.filters.includeDepartments.values.length === 1;
  }

  onToggleCustomerDepartments() {
    if (!this.list.filter.filters.includeDepartments || this.list.filter.filters.includeDepartments.values.length === 0) {
      this.list.filter.filters.includeDepartments = {
        values: ['true'],
      };
    } else {
      this.list.filter.filters.includeDepartments = {
        values: [],
      };
    }
  }

  private initializeList() {
    this.pageableList = this.pageableListService.get(
      'v2/customers',
      this.getDefaultFilter(),
      'settings.customers.filter',
      this.configurationRestangular
    );

    this.list = this.pageableList.list;
    this.pageableList.load();
  }
}
