import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { TabModel } from '@app/common/models/tab.model';
import { StateService } from '@uirouter/angular';
import { UserEditComponent } from '@app/common/components/user-edit/user-edit.component';
import { User } from '@app/models/user';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { ModulesService } from '@app/common/services/modules.service';
import { RESTANGULAR_SETTINGS } from '@app/common/services/restangular-settings.service';
import { APP_BRAND, APPLICATIONS } from '@app/common/services/config.service';

@Component({
  selector: 'user-detail',
  templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @Input() userId;
  @Input() callbacks;

  data: any;
  tab: string;
  loading = true;
  vfzeLoading = true;
  pkLoading = true;
  vfzeProject;
  pkProject;
  availableModules = [this.applications.sy.name, this.applications.di.name, this.applications.pk.name, this.applications.dashboard.name, this.applications.vfze.name, this.applications.sv.name];
  disabledTabs = [];
  syProjects = [];
  diProjects = [];
  svProjects = [];
  tabs: TabModel[] = [
    {
      name: 'Moduly',
      id: 'modules',
      href: 'settings.users.detail',
      urlParams: { tab: 'modules' },
    },
    {
      name: `${this.applications.sy.title} (0)`,
      id: this.applications.sy.name,
      href: 'settings.users.detail',
      urlParams: { tab: this.applications.sy.name }
    },
    {
      name: this.applications.dashboard.title,
      id: this.applications.dashboard.name,
      href: 'settings.users.detail',
      urlParams: {tab: this.applications.dashboard.name }
    },
  ];
  modules = [
    {
      id: 'SY',
      name: this.applications.sy.title,
    },
    {
      id: 'DASH',
      name: this.applications.dashboard.title,
    },
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    @Inject(APPLICATIONS) public applications: any,
    @Inject(RESTANGULAR_SETTINGS) private settingsRestangular: any,
    private modulesService: ModulesService,
    private dialogService: DialogService,
    private stateService: StateService,
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'modules');
    this.isActiveTab = this.isActiveTab.bind(this);
    this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDeactivate = this.onDeactivate.bind(this);
    this.onUserSync = this.onUserSync.bind(this);
    this.onModuleClick = this.onModuleClick.bind(this);
    this.moduleAvailable = this.moduleAvailable.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
  }

  ngOnInit() {
    if (this.APP_BRAND.NAME !== 'RSD') {
      this.tabs = this.tabs.concat([
        {
          name: `${this.applications.di.title} (0)`,
          id: this.applications.di.name,
          href: 'settings.users.detail',
          urlParams: {tab: this.applications.di.name }
        },
        {
          name: this.applications.pk.title,
          id: this.applications.pk.name,
          href: 'settings.users.detail',
          urlParams: {tab: this.applications.pk.name }
        },
        {
          name: this.applications.vfze.title,
          id: this.applications.vfze.name,
          href: 'settings.users.detail',
          urlParams: {tab: this.applications.vfze.name }
        },
        {
          name: this.applications.sv.title,
          id: this.applications.sv.name,
          href: 'settings.users.detail',
          urlParams: {tab: this.applications.sv.name }
        },
      ]);

      this.modules = this.modules.concat([
        {
          id: 'DI',
          name: this.applications.di.title,
        },
        {
          id: 'PRO',
          name: this.applications.pk.title,
        },
        {
          id: 'VFZE',
          name: this.applications.vfze.title,
        },
        {
          id: 'VZORY',
          name: this.applications.sv.title,
        },
      ]);
    } else {
      this.tabs = this.tabs.concat([
        {
          name: this.applications.pk.title,
          id: this.applications.pk.name,
          href: 'settings.users.detail',
          urlParams: {tab: this.applications.pk.name }
        },
      ]);

      this.modules = this.modules.concat([
        {
          id: 'PRO',
          name: this.applications.pk.title,
        },
      ]);
    }

    this.loadUserData();
    this.loadVfzeProjects();
    this.loadPkProjects();
    this.callbacks.add('userPermissionsChangedCallback', this.loadUserData);
  }

  ngOnDestroy() {
    this.callbacks.remove('userPermissionsChangedCallback', this.loadUserData);
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    if (changedParams.tab) {
      this.tab = changedParams.tab;
    }
  }

  onEdit() {
    const dialog = this.dialogService.open(UserEditComponent, {
      data: { userId: this.userId }
    });

    const sub = dialog.afterClosed.subscribe((res: User) => {
      if (res) {
        Object.assign(this.data, res);
      }
      sub.unsubscribe();
    });
  }

  onDeactivate() {
    this.dialogService.confirmDialogPromise('Potvrzením dojde k odebrání veškerých práv uživatele na moduly a přidružené projekty.')
      .then((data) => {
        if (data === true) {
          this.modulesService.getUser(this.userId).then((user) => {
            user.applications = [];
            this.modulesService.emptyUserPermissions(this.userId);
            this.modulesService.saveUser(user)
              .then(() => {
                return Promise.all([ /* callbacks.get('userPermissionsChangedCallback')(), callbacks.get('userPermissionsDeactivateCallback')() */ ]);
              }).then(() => {
                this.stateService.go('^', undefined, { location: 'replace', reload: true });
              });
          });
        }
      });
  }

  onUserSync() {
    return this.settingsRestangular.one(`users/user/${this.data.id}/broadcast-user-update`).post().toPromise();
  }

  onModuleClick(module) {
    const newModules = [...this.data.applications];
    const oldModules = [...this.data.applications];
    const moduleIndex = this.data.applications.indexOf(module.id);

    if (moduleIndex === -1) {
      newModules.push(module.id);
    } else {
      newModules.splice(moduleIndex, 1);
    }

    this.onModulesUpdated(newModules, oldModules);
  }

  moduleAvailable(module): boolean {
    return this.data.applications.indexOf(module.id) !== -1;
  }

  private onModulesUpdated(newModules, oldModules) {

    const saveChanges = () => {
      this.setModules(newModules);
      return this.saveChanges().then(() => {
        this.modulesService.loadUser(this.userId).then(() => {
          this.loadUserData();
        });
      });
    };

    // if removing authorisation for module
    if (oldModules.length > newModules.length) {
      const changedModule = this.getChangedModule(newModules, oldModules);

      // if removing authorisation for module SY or DI
      if (changedModule === this.applications.sy.name || changedModule === this.applications.di.name || changedModule === this.applications.sv.name) {
        const module = (Object.entries(this.applications) as any[]).find(e => e[1].name === changedModule)[1].title;
        return this.dialogService.confirmDialogPromise(`Opravdu chcete odebrat uživateli práva na modul ${module}? Práva ve stávajících projektech budou odstraněna.`)
          .then((data) => {
            if (data === true) {
              // clear cached user data
              this.modulesService.emptyUserPermissions(this.userId);
              saveChanges();
            } else {
              // reject changes
              this.setModules(oldModules);
              return Promise.reject();
            }
          });
      } else {
        saveChanges();
      }
    } else {
      saveChanges();
    }
  }

  private loadUserData() {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'modules');
    return this.modulesService.loadUser(this.userId)
      .then(() => {
        return this.modulesService.getUser(this.userId);
      })
      .then((data) => {
        this.data = data;
        this.loading = false;
        this.syProjects = data.applicationProjects.SY ? data.applicationProjects.SY : [];
        this.diProjects = data.applicationProjects.DI ? data.applicationProjects.DI : [];
        this.svProjects = data.applicationProjects.VZORY ? data.applicationProjects.VZORY : [];
        this.setTabsProjectsCounts();
        this.setModules(this.data.applications);
      });
  }

  private getChangedModule(newModules, oldModules) {
    return oldModules.find((m) => !newModules.includes(m));
  }

  private setModules(modules) {
    this.setDisabledModules(modules);
    this.data.applications = modules;
  }

  private setDisabledModules(availableModules) {
    this.disabledTabs = this.availableModules.filter((m) => !availableModules.includes(m));
  }

  private saveChanges() {
    this.loading = true;

    return this.data.put().toPromise().then(() => {
      this.loading = false;
    });
  }

  private loadVfzeProjects() {
    this.modulesService.getProjects(this.applications.vfze.name).then(data => {
      this.vfzeProject = data.items[0] || undefined;
      this.vfzeLoading = false;
    });
  }

  private loadPkProjects() {
    this.modulesService.getProjects(this.applications.pk.name).then(data => {
      this.pkProject = data.items[0] || undefined;
      this.pkLoading = false;
    });
  }

  private setTabsProjectsCounts() {
    const symapTab = this.tabs.find(t => t.id === this.applications.sy.name);
    const dimapTab = this.tabs.find(t => t.id === this.applications.di.name);
    const svTab = this.tabs.find(t => t.id === this.applications.sv.name);
    const bracketRegexp = new RegExp('\\(.+?\\)');
    symapTab.name = symapTab.name.replace(bracketRegexp, `(${this.syProjects.length})`);
    if (dimapTab) {
      dimapTab.name = dimapTab.name.replace(bracketRegexp, `(${this.diProjects.length})`);
    }
    if (svTab) {
      svTab.name = svTab.name.replace(bracketRegexp, `(${this.svProjects.length})`);
    }
  }
}
