import { Component, Input, OnInit } from '@angular/core';
import { TabModel } from '../../models/tab.model';

@Component({
  selector: 'gmt-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {

  @Input() tabs: TabModel[];
  @Input() disabledTabs: string[];
  @Input() isActiveTab: (id: string) => {};

  constructor() {}

  ngOnInit() {}

  isActive(id: string) {
    return this.isActiveTab(id);
  }

  isDisabled(id: string) {
    return this.disabledTabs && this.disabledTabs.includes(id);
  }
}
