<ng-template #textValue let-data="data">
  {{data}}
</ng-template>
<ng-template #iconTextValue let-data="data">
  <span [ngClass]="data.ngClass">{{data.content}}</span> <i class="{{data.iconClass}}"></i>
</ng-template>
<ng-template #uiSrefValue let-data="data">
  <a [uiSref]="data.uiSref" [uiParams]="data.uiParams" [target]="data.target">{{data.content}}</a>
</ng-template>
<ng-template #hrefValue let-data="data">
  <a target="_blank" href="{{data.href}}"><i class="{{data.iconClass}}" *ngIf="data.iconClass"></i>{{data.content}}</a>
</ng-template>
<ng-template #uiSrefListValue let-data="data">
  <span class="comma-list" *ngIf="data">
    <ng-container *ngFor="let item of data"><ng-container *ngTemplateOutlet="uiSrefValue; context:{data: item}"></ng-container></ng-container>
  </span>
</ng-template>
<ng-template #dateValue let-data="data">
  {{data | date}}
</ng-template>
<ng-template #unitMeterSquareValue let-data="data">
  <span [ngClass]="data.ngClass" *ngIf="data.value !== null || (data.value === undefined && data !== null)">{{(data.value || data) | number : data.digitsInfo}} <span class="unit">m<sup>2</sup></span></span>
</ng-template>
<ng-template #unitValue let-data="data">
  <span [ngClass]="data.ngClass" *ngIf="data.value !== null">{{data.value | number : data.decimalPoints}} <span class="unit">{{ data.unit }}</span></span>
</ng-template>
<ng-template #cuzkValue let-data="data">
  <a href="{{data}}" target="_blank" class="underlined">Nahlížení do katastru<i class="cuzk small ml-5"></i></a>
</ng-template>
<ng-template #imgValue let-data="data">
  <img src="{{data.src}}" />
</ng-template>
<ng-template #ownershipsValue let-data="data">
  <span *ngIf="data.ownerships">
    <ul>
      <li *ngFor="let ownership of data.ownerships.slice(0, data.ownerships.length > 3 ? 2 : 3)">
        <span *ngFor="let subject of ownership.subjects; let $last = last;">{{ subject | subjectName }}{{ $last ? '' : ' a ' }}</span>
        ({{ ownership.podil_citatel }}/{{ ownership.podil_jmenovatel }})
      </li>
      <li class="mt-5" *ngIf="data.length > 3">a další ({{ data.length - 2 }})</li>
    </ul>
  </span>
</ng-template>
<ng-template #wantedSwitchValue let-data="data" let-onWantedChanged="onWantedChanged">
  <wanted-switch [item]="{wanted: data.featureData.properties.wanted, id: data.featureData.properties.id, owner_type: data.featureData.properties.owner_type}" (changed)="onWantedChanged($event.newValue, $event.oldValue, $event.ownerType)"></wanted-switch>
</ng-template>
<ng-template #parcelAreaValue let-parcelId="data">
  <parcel-area [parcelId]="parcelId" class="mt-10"></parcel-area>
</ng-template>

<ng-template #roww let-row="row" let-feature="feature">
  <ng-container [ngSwitch]="row.template">
    <ng-container *ngSwitchCase="'uiSref'"><ng-container *ngTemplateOutlet="uiSrefValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'uiSrefList'"><ng-container *ngTemplateOutlet="uiSrefListValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'date'"><ng-container *ngTemplateOutlet="dateValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'unitMeterSquare'"><ng-container *ngTemplateOutlet="unitMeterSquareValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'cuzk'"><ng-container *ngTemplateOutlet="cuzkValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'ownerships'"><ng-container *ngTemplateOutlet="ownershipsValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'wantedSwitch'"><ng-container *ngTemplateOutlet="wantedSwitchValue; context:{data: row.data, onWantedChanged: feature.onWantedChanged}"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'iconText'"><ng-container *ngTemplateOutlet="iconTextValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'href'"><ng-container *ngTemplateOutlet="hrefValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'img'"><ng-container *ngTemplateOutlet="imgValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'unit'"><ng-container *ngTemplateOutlet="unitValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchCase="'parcelArea'"><ng-container *ngTemplateOutlet="parcelAreaValue; context:row"></ng-container></ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="row.data && isArray(row.data)">
        <div *ngFor="let row1 of row.data">
          <ng-container *ngTemplateOutlet="roww; context: {row: row1, feature: feature}"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!row.data || !isArray(row.data)">
        <ng-container *ngTemplateOutlet="textValue; context:row"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<h2>
  {{feature.header}}
  <a *ngIf="feature.highlightFeature && feature.featureData.featureID" (click)="feature.highlightFeature(feature.featureData.featureID, feature.featureData.layerID)"><i class="fa fa-search" aria-hidden="true"></i></a>
</h2>
<div class="column-100" *ngFor="let row of feature.rows">
  <label *ngIf="row.label !== undefined">{{row.label || ''}}</label>
  <div [ngClass]="{cb: row.label === undefined && row.className === undefined, info: row.label !== undefined && row.className === undefined}" class="{{row.className || ''}}">
    <ng-container *ngTemplateOutlet="roww; context: {row: row, feature: feature}"></ng-container>
   </div>
</div>
