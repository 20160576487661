﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'pagescounter',
  templateUrl: './pagescounter.component.html',
  styleUrls: [ './pagescounter.component.scss' ]
})
export class PagesCounterComponent implements OnInit {
  @Input() list: any;
  @Output() callbackRegister = new EventEmitter();
  totalPages = 0;
  currentPage = 0;

  constructor(private listService: ListService) {
    this.onListChanged = this.onListChanged.bind(this);
  }

  ngOnInit() {
    this.callbackRegister.emit({
      onListChanged: this.onListChanged,
    });
    this.onListChanged();
  }

  onListChanged() {
    this.totalPages = this.listService.getTotalPages(this.list);
    this.currentPage = this.listService.getCurrentPage(this.list);
  }
}
