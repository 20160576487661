import getAffectedLayers from './pure/MapAffectedPropertyLayers';
import * as  L from 'leaflet';
import * as _ from 'lodash';

import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG } from '@app/common/services/config.service';
import { AuthService } from '@app/common/services/auth.service';
import { MapUtilsCrsService } from '@app/map/services/map-utils-crs.service';
import { DimapFeatureFilterService } from '@app/map/services/dimap-feature-filter.service';
import { MapReloadLayersService } from '@app/map/services/map-reload-layers.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FullAddressPipe } from '@app/common/pipes/full-address.pipe';
import { WordService } from '@app/common/services/word.service';
import { DialogService } from '@app/common/services/dialog.service';
import { ConstructionObjectNamePipe } from '@app/common/pipes/construction-object-name.pipe';
import { ClassName } from '@app/common/enums/class-name.enum';
import { MultiSceneCloudComponent } from '@app/dimap/map/components/multi-scene-cloud/multi-scene-cloud.component';
import { getPropertyAreaColorClass } from '@app/common/utils/map.utils';


@Injectable({
  providedIn: 'root'
})
export class LayersConfigService {
  private iconPath: string;
  private SYwfsLayersUrl: string;
  private SYwmsLayersUrl: string;
  private DIwmsLayersUrl: string;
  private DIwfsLayersUrl: string;

  constructor(
    @Inject(APP_CONFIG) config: any,
    private authService: AuthService,
    private mapUtilsCrsService: MapUtilsCrsService,
    private dimapFeatureFilterService: DimapFeatureFilterService,
    private mapReloadLayersService: MapReloadLayersService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private fullAddressPipe: FullAddressPipe,
    private wordService: WordService,
    private constructionObjectNamePipe: ConstructionObjectNamePipe,
    private dialogService: DialogService,
  ) {
    this.iconPath = 'img/map/';

    this.SYwmsLayersUrl = config.BACKEND_OPTIONS.geoserverUrl + '/wms';
    this.SYwfsLayersUrl = config.BACKEND_OPTIONS.geoserverUrl + '/wfs';

    this.DIwmsLayersUrl = config.BACKEND_OPTIONS.geoserverDIUrl + '/wms';
    this.DIwfsLayersUrl = config.BACKEND_OPTIONS.geoserverDIUrl + '/wfs';

    this.openMultiSceneCloudViewer = this.openMultiSceneCloudViewer.bind(this);
  }

  getSYlayerPrefix() {
    return 'symap:symap_' + this.getProjectKey() + '_';
  }

  getDIlayerPrefix() {
    return 'dimap:dimap_' + this.getProjectKey() + '_';
  }

  getProjectKey() {
    const project = this.authService.getActualProject();
    return project.key;
  }

  reloadAffecterPropertyLayers(newValue, oldValue, ownerType, mapID) {
    const layersIDs = getAffectedLayers(newValue, oldValue, ownerType);
    layersIDs.push('property_info_geometric_plan');
    this.mapReloadLayersService.reload(mapID, layersIDs);
  }

  statusTemplate(mapId) {
    return {
      header: 'Majetkoprávní stav',
      scope: {
        onWantedChanged: (newValue, oldValue, ownerType) => this.reloadAffecterPropertyLayers(newValue, oldValue, ownerType, mapId),
      },
      rows: [
        {
          label:'Parcela:',
          data: f => f.featureData.properties.parcel_number,
        },
        {
          label:'Výměra:',
          template:'unitMeterSquare',
          data: f => { return {value: f.featureData.properties.area, ngClass: [getPropertyAreaColorClass(f.featureData.properties.owner_type, f.featureData.properties.wanted, f.featureData.properties.key)]}},
        },
        {
          label:'Řešení:',
          className: 'input',
          template:'wantedSwitch',
          data: f => f,
        },
      ]
    };
  }

  openMultiSceneCloudViewer(event, layerData, layerConfig) {
    const data = layerData[0].properties;
    const dialog = this.dialogService.open(MultiSceneCloudComponent, {
      data: {
        trjName: data.trj_name,
        photoName: data.photo_name,
        x: data.x,
        y: data.y,
        z: data.z,
        layers: layerConfig.multiSceneLayers,
      },
      className: ClassName.NO_PADDING_DIALOG,
    });
  }

  getLayers(mapId) {
    const CUZKATTRIBUTION = 'ČÚZK <a href="http://www.cuzk.cz" target="blank">http://www.cuzk.cz</a>';
    const HFBIZ_WMS_URL = 'https://portal.nasemapy.cz/wms/suspce';
    const HFBIZ_WMS_URL_RSD = 'https://portal.nasemapy.cz/wms/rsdpce';
    const HFBIZ_ATTRIBUTION = 'HF Biz s.r.o.';

    const getJTSKMatrix = (topLeftCorner = new L.LatLng(-925000, -920000)) => {
      const matrixIds = new Array(22);
      for (let i = 0; i < 22; i++) {
        matrixIds[i] = {
          identifier: '' + i,
          topLeftCorner: topLeftCorner,
        };
      }
      return matrixIds;
    };


    const getGeoJsonSettings = (color) => {
      return {
        style: {
          clickable: true,
          'color': color,
          'fillColor': color,
          'weight': 1,
          'opacity': 1,
          'fillOpacity': 0.5
        },
        onEachFeature: function (feature, layer) {
          layer.on({
            mouseover: function () {
              layer.setStyle({fillOpacity: 1.0});
            },
            mouseout: function () {
              layer.setStyle({fillOpacity: layer.defaultOptions.style.fillOpacity});
            }
          });
        }
      };
    };
    const ownershipsRow = {
      label: 'Vlastníci:',
      template: 'ownerships',
      data: (f) => { return {ownerships: f.ownershipsParse(f.featureData.properties.ownerships) }; },
    };

    //
    // SyMAP
    const problematicOwnerTemplate = {
      header: 'Problematický vlastník',
      rows: [
        {
          show: f => f.featureData.properties.partner_1_jmeno_prijmeni,
          label: 'Jméno',
          template: 'uiSref',
          data: (f) => { return { uiSref: "symap.project.owners.detail", uiParams: {ownerId: f.featureData.properties.partner_1_id, tab: 'about'}, content: '(SJM) ' + f.featureData.properties.partner_1_jmeno_prijmeni }; },
        },
        {
          show: f => f.featureData.properties.partner_1_jmeno_prijmeni,
          label: 'Adresa',
          data: f => this.fullAddressPipe.transform({street: f.featureData.properties.partner_1_ulice, houseNumber: f.featureData.properties.partner_1_cislo_domovni, postCode: f.featureData.properties.partner_1_psc, municipality: f.featureData.properties.partner_1_obec, quarter: f.featureData.properties.partner_1_cast_obce}),
        },

        {
          show: f => f.featureData.properties.partner_2_jmeno_prijmeni,
          label: 'Jméno',
          template: 'uiSref',
          data: (f) => { return { uiSref: "symap.project.owners.detail", uiParams: {ownerId: f.featureData.properties.partner_2_id, tab: 'about'}, content: '(SJM) ' + f.featureData.properties.partner_2_jmeno_prijmeni }; },
        },
        {
          show: f => f.featureData.properties.partner_2_jmeno_prijmeni,
          label: 'Adresa',
          data: f => this.fullAddressPipe.transform({street: f.featureData.properties.partner_2_ulice, houseNumber: f.featureData.properties.partner_2_cislo_domovni, postCode: f.featureData.properties.partner_2_psc, municipality: f.featureData.properties.partner_2_obec, quarter: f.featureData.properties.partner_2_cast_obce}),
        },
        {
          show: f => !f.featureData.properties.partner_1_jmeno_prijmeni,
          label: 'Jméno',
          template: 'uiSref',
          data: (f) => { return { uiSref: "symap.project.owners.detail", uiParams: {ownerId: f.featureData.properties.id, tab: 'about'}, content: f.featureData.properties.jmeno }; },
        },
        {
          show: f => !f.featureData.properties.partner_1_jmeno_prijmeni,
          label: 'Adresa',
          data: f => this.fullAddressPipe.transform({street: f.featureData.properties.ulice, houseNumber: f.featureData.properties.cislo_domovni, postCode: f.featureData.properties.psc, municipality: f.featureData.properties.obec, quarter: f.featureData.properties.cast_obce}),
        },
        {
          label: 'Označení stavu',
          template: 'date',
          data: f => f.featureData.properties.oznaceni_stavu,
        },
      ],
    };

    const gpTemplate = {
      header: 'Nepoužitý geometrický plán',
      rows: [
        {
          label: 'Číslo GP',
          data: f => f.featureData.properties.cislo,
        },
        {
          label: 'Název k. ú.',
          data: f => f.featureData.properties.katuze_nazev,
        },
        {
          label: 'Zhotovitel GP',
          data: f => f.featureData.properties.firma,
        },
        {
          label: 'Datum potvrzení GP',
          template: 'date',
          data: f => f.featureData.properties.potvrzeni_datum,
        },
      ],
    };

    const constructionObjectTemplate = {
      header: 'Majetkoprávně řešená stavba',
      rows: [
        {
          label: 'Název stavby',
          data: f => f.featureData.properties.nazev,
        },
        {
          label: 'Označení stavby',
          data: f => f.featureData.properties.cislo,
        },
        {
          label: 'Otevřít v MAJA',
          template: 'uiSref',
          data: (f) => { return { target:"_blank", uiSref: "symap.project.constructionObjects.detail", uiParams: {id: f.featureData.properties.id, projectKey: f.featureData.properties.project_key}, content: 'Detail stavby' }; },
        }
      ],
    };

    const occupationTemplate = {
      header: 'Zábor',
      rows: [
        {
          getLabel: () => this.wordService.getTranslation("ETAPA_USEK", true) + ':',
          data: f => f.featureData.properties.usek_etapa
        },
        {
          label:'Číslo záboru:',
          data: f => f.featureData.properties.cislo,
        },
        {
          label:'Typ:',
          data: f => f.featureData.properties.typ_nazev,
        },
        {
          label: 'Parcelní číslo:',
          show: f => !f.featureData.properties.parcel_end_date,
          template: 'uiSref',
          data: f => { return { uiSref: "symap.project.parcels.detail", uiParams: {id: f.featureData.properties.parcel_id}, content: f.featureData.properties.cislo_parcely }; },
        },
        {
          label: 'Parcelní číslo:',
          show: f => f.featureData.properties.parcel_end_date,
          template: 'iconText',
          data: f => {
            return { content: f.featureData.properties.cislo_parcely + ' (Neplatná parcela)', ngClass: {cancelled:true}, iconClass: 'fa fa-exclamation-triangle error-message' };
          },
        },
        {
          label:'Výměra:',
          template: 'unitMeterSquare',
          data: f => f.featureData.properties.vymera,
        },
        {
          getLabel: () => this.wordService.getTranslation("CONCERN_CONSTRUCTION_OBJECT_SHORTCUT", true) + ':',
          template: 'uiSrefList',
          data: f => f.featureData.properties.construction_objects ? f.jsonParse(f.featureData.properties.construction_objects).map(co => {
            return {
              uiSref: "symap.project.constructionObjects.detail", uiParams: {id: co.id}, content: co.cislo
            }; }) : null,
        },
        {
          label:'LV:',
          template: 'uiSref',
          data: f => { return { uiSref: "symap.project.titles.detail", uiParams: {id: f.featureData.properties.title_id}, content: f.featureData.properties.lv }; },
        },
        ownershipsRow,
        {
          label:'Nabyvatel:',
          show: f => f.featureData.properties.nabyvatel,
          data: f => f.featureData.properties.nabyvatel,
        },
      ]
    };

    const occupationAcquirerTemplate = {
      header: 'Nabyvatel',
      rows: [
        {
          label:'Nabyvatel:',
          data: f => f.featureData.properties.nabyvatel,
        },
        {
          label:'IČO:',
          data: f => f.featureData.properties.ico_nabyvatele,
        },
        {
          label:'Typ nabyvatele:',
          data: f => f.featureData.properties.name,
        },
      ]
    };

    const caseRows =[
      {
        label:'Číslo případu:',
        show: f => f.featureData.properties.cases,
        template: 'uiSrefList',
        data: f => f.featureData.properties.cases ? f.jsonParse(f.featureData.properties.cases).map(c => {
          return {
            uiSref: "symap.project.cases.detail", uiParams: {id: c.id}, content: (c.number || 'bez čísla') + (c.statusName ? ' (' + c.statusName + ')' : '')
          }; }) : null,
      },
      {
        label:'Číslo případu:',
        show: f => !f.featureData.properties.cases,
        data: f => 'bez případu',
      },
    ];

    //add cases row into occupations tamplate
    (<any[]>occupationTemplate.rows).push(...caseRows);

    const occupationCaseStatusTemplate = {
      header: 'Případ VB',
      rows: caseRows
    };


    const parcelRows = [
      {
        label:'Název KÚ:',
        data: f => f.featureData.properties.areaname,
      },
      {
        label:'Parcelní číslo:',
        template:'uiSref',
        data: (f) => { return { uiSref: "symap.project.parcels.detail", uiParams: {id: f.featureData.properties.id}, content: f.featureData.properties.parcis }; },
      },
      {
        label:'Výměra:',
        template:'unitMeterSquare',
        data: f => f.featureData.properties.vymera
      },
      {
        show: (f) => f.featureData.properties.druhpoz_nazev,
        label:'Druh pozemku:',
        data: (f) => f.featureData.properties.druhpoz_nazev,
      },
      {
        show: (f) => f.featureData.properties.vyuz,
        label:'Způsob využití:',
        data: (f) => f.featureData.properties.vyuz
      },
      {
        label:'LV:',
        template:'uiSref',
        data: (f) => { return { uiSref: "symap.project.titles.detail", uiParams: {id: f.featureData.properties.title_id}, content: f.featureData.properties.lv }; },
      },
    ];

    const gpParcelRows = parcelRows.slice();
    //add row on second possition
    gpParcelRows.splice(1, 0, {
      label:'Číslo GP:',
      data: f => f.featureData.properties.gpcis,
    });

    const actualKNParcels = parcelRows.slice();
    actualKNParcels.push(
      ownershipsRow,
      {
        label:'',
        template: 'cuzk',
        data: f => f.featureData.properties.cuzk,
      }
    );

    // Replace row definition on 1 and 5 position.
    const occupationParcelsRows = Object.assign([], parcelRows.slice(), {
      1: {
        label:'Parcelní číslo:',
        data: f => f.featureData.properties.parcis ? f.featureData.properties.parcis : '',
      },
      5: {
        label:'LV:',
        data: f => f.featureData.properties.lv,
      }
    });

    const easementTemplate = {
      header: 'Věcné břemeno',
      rows: [
        {
          getLabel: () => this.wordService.getTranslation("CONSTRUCTION_OBJECT", true) + ':',
          template: 'uiSrefList',
          data: f => { return f.featureData.properties.construction_objects ? f.jsonParse(f.featureData.properties.construction_objects).map(co => {
            return {
              uiSref: "symap.project.constructionObjects.detail", uiParams: {id: co.id}, content: this.constructionObjectNamePipe.transform(<any>{socis:co.cislo,sousek:co.usekEtapa,sotx:co.nazev, id: co.id})
            }; }) : null; },
        },
        {
          label:'Číslo GP:',
          data: f => f.featureData.properties.gpcis,
        },
        {
          label:'Výměra:',
          template:'unitMeterSquare',
          data: f => f.featureData.properties.vymera,
          show: f => f.featureData.properties.vymera,
        },
        {
          label:'Délka:',
          template: 'unit',
          data: f => { return { value: f.featureData.properties.delka, unit: 'm' }; },
          show: f => f.featureData.properties.delka,
        },

        {
          label: 'Parcelní číslo:',
          show: f => !f.featureData.properties.parcel_end_date,
          template: 'uiSref',
          data: f => { return { uiSref: "symap.project.parcels.detail", uiParams: {id: f.featureData.properties.parcel_id}, content: f.featureData.properties.cislo_parcely }; },
        },
        {
          label: 'Parcelní číslo:',
          show: f => f.featureData.properties.parcel_end_date,
          template: 'iconText',
          data: f => {
            return { content: f.featureData.properties.cislo_parcely + ' (Neplatná parcela)', ngClass: {cancelled:true}, iconClass: 'fa fa-exclamation-triangle error-message' };
          },
        },
        {
          label:'LV:',
          template:'uiSref',
          data: (f) => { return { uiSref: "symap.project.titles.detail", uiParams: {id: f.featureData.properties.title_id}, content: f.featureData.properties.lv }; },
        },
        ownershipsRow,
        ...caseRows,
      ]
    };


    //
    // SyMAP - END
    //


    //
    // DiMAP
    //

    const orFilter = function(attrName, values) {
      var filter = null;
      values.forEach(function(value) {
        var operand = {
          type: 'PropertyIsEqualTo',
          propertyName: attrName,
          literal: value
        };
        if (filter === null) {
          filter = operand;
        } else {
          filter = {
            type: 'Or',
            arg1: filter,
            arg2: operand
          };
        }
      });
      return filter;
    };

    const getBridgeGeoJsonSettings = () => {
      const geojsonMarkerOptions = {
        radius: 8,
        fillColor: "#ff7800",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.5
      };
      return {
        pointToLayer: (feature, latlng) => {
          const marker = L.circleMarker(latlng, geojsonMarkerOptions);
          marker.on({
            mouseover: () => {
              marker.setStyle({fillOpacity: 1.0});
            },
            mouseout: () => {
              marker.setStyle({fillOpacity: geojsonMarkerOptions.fillOpacity});
            }
          });
          return marker;
        }
      };
    };

    const faultTemplate = {
      header: 'Porucha',
      rows: [
        {
          label:'Typ:',
          data: f => f.featureData.properties.name,
        },
        {
          label:'Plocha:',
          template: 'unitMeterSquare',
          data: f => { return {value: f.featureData.properties.area, digitsInfo: '1.0-2'};},
        },
        {
          label:'Číslo úseku:',
          data: f => f.featureData.properties.cis_useku,
        },
        {
          label:'Silnice:',
          data: f => f.featureData.properties.silnice,
        },
        {
          label:'Staničení:',
          data: f => f.featureData.properties.staniceni_start + ', ' + f.featureData.properties.staniceni_konec,
        },
      ]
    };

    const bridgeTemplate = {
      header: 'Most',
      scope: {
        filterFiles: (files) => _.filter(files,  {type: 'thumb'}),
      },
      rows: [
        {
          data: f => {
            return f.jsonParse(f.featureData.properties.files).filter(file => file.type === 'thumb').map(img => {
              return {
                template: 'img',
                data: { src: img.url, },
              };
            });
          }
        },
        {
          label:'Název:',
          data: f => [{data: f.featureData.properties.title}, {data: f.featureData.properties.subtitle}],
        },
        {
          template: 'href',
          data: f => { return {
            href: f.featureData.properties.url,
            content: 'zobrazit v aplikaci Mostař',
          };},
        },
      ]
    };

    const repairTemplate = {
      header: 'Klasifikace vozovky',
      rows: [
        {
          label:'Klasifikační stupeň:',
          data: f => f.featureData.properties.total_index,
          show: f => f.featureData.properties.total_index > 0,
        },
        {
          label:'Staničení start:',
          data: f => this.decimalPipe.transform(f.featureData.properties.staniceni_start),
        },
        {
          label:'Staničení konec:',
          data: f => this.decimalPipe.transform(f.featureData.properties.staniceni_konec),
        },
        {
          label:'Datum diagnostiky:',
          data: f => f.featureData.properties.datum_diagnostiky,
        },
        {
          label:'Typ akce:',
          data: f => f.featureData.properties.tech_typ_akce,
        },
        {
          label:'Typ údržby:',
          data: f => f.featureData.properties.tech_typ_udrzby,
        },
        {
          label:'Cena - Vysprávka asfaltovou směsí:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_vas) + '\xa0Kč',
          show: f => f.featureData.properties.tech_vas > 0,
        },
        {
          label:'Cena - Zalití trhlin a jednovrstvý nátěr (lokálně):',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_jvrst) + '\xa0Kč',
          show: f => f.featureData.properties.tech_jvrst > 0,
        },
        {
          label:'Cena - Dvojitý nátěr:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_dvrst) + '\xa0Kč',
          show: f => f.featureData.properties.tech_dvrst > 0,
        },
        {
          label:'Cena - EMK emulzní mikrokoberec:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_emk) + '\xa0Kč',
          show: f => f.featureData.properties.tech_emk > 0,
        },
        {
          label:'Cena - Oprava obrusné vrstvy s lokální sanací podkladu:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_oprava) + '\xa0Kč',
          show: f => f.featureData.properties.tech_oprava > 0,
        },
        {
          label:'Cena - Rekonstrukce ACO +ACL:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_rekonstrukce) + '\xa0Kč',
          show: f => f.featureData.properties.tech_rekonstrukce > 0,
        },
        {
          label:'Cena - Recyklace (studená):',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_recyklace) + '\xa0Kč',
          show: f => f.featureData.properties.tech_recyklace > 0,
        },
        {
          label:'Celková cena:',
          data: f => this.decimalPipe.transform(f.featureData.properties.tech_celkem) + '\xa0Kč',
          show: f => f.featureData.properties.tech_celkem > 0,
        },
      ]
    };

    const parcelSummaryTemplate = {
      header: 'Parcela KN',
      rows: [
        {
          label:'Název k.ú.:',
          data: f => f.featureData.properties.area_name,
        },
        {
          label:'Parcelní číslo:',
          template:'uiSref',
          data: (f) => { return { uiSref: "dimap.project.parcels.detail", uiParams: {id: f.featureData.properties.parcel_id}, content: f.featureData.properties.parcel_number }; },
        },
        {
          label:'LV:',
          template:'uiSref',
          data: (f) => { return { uiSref: "dimap.project.titles.detail", uiParams: {id: f.featureData.properties.title_id}, content: f.featureData.properties.title_lv }; },
        },
        {
          label:'Výměra:',
          template:'unitMeterSquare',
          data: f => f.featureData.properties.vymera,
        },
        {
          label:'Druh pozemku:',
          data: f => f.featureData.properties.drupoz_nazev,
        },
        {
          label:'Způsob využití:',
          data: f => f.featureData.properties.zpusob_vyuziti,
          show: f => f.featureData.properties.zpusob_vyuziti,
        },
        {
          label:'Počet vlastníků:',
          data: f => f.featureData.properties.owners_count,
          show: f => f.featureData.properties.owners_count,
        },
        {
          label:'',
          template: 'cuzk',
          data: f => 'http://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?&typ=parcela&id=' + f.featureData.properties.idpar,
          show: f => f.featureData.properties.idpar,
        },
        {
          template: 'parcelArea',
          data: f => f.featureData.properties.parcel_id,
        },
      ]
    };

    const divisionSummaryTemplate = {
      getHeader: f => f.featureData.properties.division_type_name,
      rows: [
        {
          label:'Číslo silnice:',
          data: f => f.featureData.properties.silnice,
        },
        {
          label:'Číslo úseku:',
          data: f => f.featureData.properties.cis_useku,
        },
      ]
    };

    const dimapOccupationTemplate = {
      header: 'Majetkoprávně řešená parcela staré zátěže',
      rows: [
        {
          label: 'Název k.ú.',
          data: f => f.featureData.properties.katuze_nazev,
        },
        {
          label: 'Parcelní číslo',
          data: f => (f.featureData.properties.budouci ? 'budoucí ' : '') + f.featureData.properties.cislo_parcely,
        },
        {
          label: 'LV',
          data: f => f.featureData.properties.lv,
        },
        {
          label: 'Výměra',
          template:'unitMeterSquare',
          data: f => f.featureData.properties.vymera_parcely,
        },
        {
          show: f => f.featureData.properties.druhpoz_nazev,
          label: 'Druh pozemku',
          data: f => f.featureData.properties.druh_pozemku,
        },
        {
          show: f => f.featureData.properties.zpusob_vyuziti,
          label: 'Způsob využití',
          data: f => f.featureData.properties.zpusob_vyuziti,
        },
        {
          label: 'Otevřít v MAJA',
          template:'uiSref',
          data: (f) => { return { target: '_blank',  uiSref: "symap.project.parcels.detail", uiParams: {id: f.featureData.properties.parcel_id, projectKey: f.featureData.properties.project_key}, content: 'Detail parcely' }; },
        }
      ]
    };

    const lpisTemplate = {
      header: 'Veřejný registr půdy - LPIS',
      rows: [
        {
          label: 'Obchodní jméno',
          data: f => f.featureData.properties.obchodni_jmeno,
        },
        {
          label: 'IČO',
          data: f => f.featureData.properties.ico,
        },
        {
          label: 'Adresa',
          data: f => f.featureData.properties.adresa,
        },
        {
          label: 'Národní kód půdního bloku',
          data: f => f.featureData.properties.nkodfb,
        },
        {
          label: 'Kultura',
          data: f => f.featureData.properties.kultura,
        }
      ]
    };

    const hfbizTreesTemplate = {
      header: 'Stromy',
      rows: [
        {
          show: f => f.featureData.properties.druh,
          label: 'Druh',
          data: f => f.featureData.properties.druh,
        },
        {
          show: f => f.featureData.properties.stav,
          label: 'Stav',
          data: f => f.featureData.properties.stav,
        },
        {
          show: f => f.featureData.properties.rok_vysadb,
          label: 'Rok výsadby',
          data: f => f.featureData.properties.rok_vysadb,
        },
        {
          show: f => f.featureData.properties.url,
          label: 'Detail',
          template: 'href',
          data: f => { return {
            href: f.featureData.properties.url,
            content: 'Pasport',
          };},
        }
      ],
    };

    const hfbizHorizontalRoadSignTemplate = {
      header: 'Vodorovné dopravní značení',
      rows: [
        {
          show: f => f.featureData.properties.oznaceni,
          label: 'Označení',
          data: f => f.featureData.properties.oznaceni,
        },
        {
          show: f => f.featureData.properties.stav,
          label: 'Stav',
          data: f => f.featureData.properties.stav,
        },
        {
          show: f => f.featureData.properties.rok_osazen,
          label: 'Rok osazení',
          data: f => f.featureData.properties.rok_osazen,
        },
        {
          show: f => f.featureData.properties.url,
          label: 'Detail',
          template: 'href',
          data: f => { return {
            href: f.featureData.properties.url,
            content: 'Pasport',
          };},
        }
      ]
    };

    const hfbizVerticalRoadSignTemplate = {
      header: 'Svislé dopravní značení',
      rows: [
        {
          show: f => f.featureData.properties.tab_1,
          label: 'Druh značky',
          data: f => f.featureData.properties.tab_1,
        },
        {
          show: f => f.featureData.properties.druh_nosic,
          label: 'Druh nosiče',
          data: f => f.featureData.properties.druh_nosic,
        },
        {
          show: f => f.featureData.properties.txt_napis,
          label: 'Nápis',
          data: f => f.featureData.properties.txt_napis,
        },
        {
          show: f => f.featureData.properties.stav,
          label: 'Stav',
          data: f => f.featureData.properties.stav,
        },
        {
          show: f => f.featureData.properties.provedeni,
          label: 'Provedení',
          data: f => f.featureData.properties.provedeni,
        },
        {
          show: f => f.featureData.properties.rok_osazeni,
          label: 'Rok osazení',
          data: f => f.featureData.properties.rok_osazen,
        },
        {
          show: f => f.featureData.properties.url,
          label: 'Detail',
          template: 'href',
          data: f => { return {
            href: f.featureData.properties.url,
            content: 'Pasport',
          };},
        }
      ]
    };

    const hfbizCrashBarrierTemplate = {
      header: 'Záchytné bezpečnostní zařízení',
      rows: [
        {
          show: f => f.featureData.properties.typ,
          label: 'Typ',
          data: f => f.featureData.properties.typ,
        },
        {
          show: f => f.featureData.properties.material,
          label: 'Materiál',
          data: f => f.featureData.properties.material,
        },
        {
          show: f => f.featureData.properties.stranove_u,
          label: 'Stranové umístění',
          data: f => f.featureData.properties.stranove_u,
        },
        {
          show: f => f.featureData.properties.stav,
          label: 'Stav',
          data: f => f.featureData.properties.stav,
        },
        {
          show: f => f.featureData.properties.rok_osazen,
          label: 'Rok osazení',
          data: f => f.featureData.properties.rok_osazen,
        },
        {
          show: f => f.featureData.properties.url,
          label: 'Detail',
          template: 'href',
          data: f => { return {
            href: f.featureData.properties.url,
            content: 'Pasport',
          };},
        }
      ]
    };
    //
    // DiMAP - END
    //


    return [

      {
        id: 'dtm_osm_overview',
        type: 'wmts',
        title: 'OSM přehledová mapa',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://services7.tmapserver.cz/mapproxy/wmts/tmce5514/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png',
        matrixIds: getJTSKMatrix(new L.LatLng(-1025000, -920000)),
        tilematrixSet: 'jtsk',
        crs: {
          name: "tmap",
          srid: 5514,
          bounds: L.bounds(
            L.point(-1115400.5161282580811530,-1489640.5005293001886457),
            L.point(-64052.4872908258839743,-823811.3057912944350392)
          ),
          origin: [-1025000, -920000]
        },
      },

      // PAK WMTS
      {
        id: 'pak_wmts_ortofoto',
        type: 'wmts',
        title: 'Ortofoto DTM',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'orthofoto.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/ortofoto/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },
      {
        id: 'pak_wmts_ti_ver',
        type: 'wmts',
        title: 'TI - veřejná část',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/dtm_ti_ver/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },
      {
        id: 'pak_wmts_di_ver',
        type: 'wmts',
        title: 'DI - veřejná část',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/dtm_di_ver/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },
      {
        id: 'pak_wmts_zps_linie_body',
        type: 'wmts',
        title: 'ZPS linie a body',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/dtm_zps_linie_body/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },
      {
        id: 'pak_wmts_zps_plochy_bar',
        type: 'wmts',
        title: 'ZPS plochy, barevná',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/dtm_zps_plochy_bar/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },
      {
        id: 'pak_wmts_geod_kart_prvky',
        type: 'wmts',
        title: 'Geodetické a kartografické prvky',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'zm.png',
        url: 'https://app.gmtech.cz/pak_mapservice/wmts/1.0.0/dtm_geod_kart_prvky/default/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png',
        matrixIds: getJTSKMatrix(),
        tilematrixSet: 'grid_5514',
      },

      // PAK WMS
      {
        id: 'pak_wms_ortofoto',
        type: 'wms',
        title: 'Ortofoto DTM',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/ortofoto',
        icon: this.iconPath + 'orthofoto.png',
        wms: {
          layers: 'ortofoto',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_zps_linie_body',
        type: 'wms',
        title: 'ZPS linie a body',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_zps_linie_body',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_zps_linie_body',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_zps_plochy_bar',
        type: 'wms',
        title: 'ZPS plochy, barevná',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_zps_plochy_bar',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_zps_plochy_bar',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_di_ver',
        type: 'wms',
        title: 'DI - veřejná část',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_di_ver',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_di_ver',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_di_ver_prim',
        type: 'wms',
        title: 'DI - veřejná část - primární',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_di_ver_prim',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_di_ver_prim',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_op_bp_di',
        type: 'wms',
        title: 'OP, BP Dopravní infrastruktury',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/op-bp-di',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_op_bp_di',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_ti_ver',
        type: 'wms',
        title: 'TI - veřejná část',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_ti_ver',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_ti_ver',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_ti_ver_prim',
        type: 'wms',
        title: 'TI - veřejná část - primární',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_ti_ver_prim',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_ti_ver_prim',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_op_bp_ti',
        type: 'wms',
        title: 'OP, BP Technické infrastruktury',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/op-bp-ti',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_op_bp_ti',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_pripojky',
        type: 'wms',
        title: 'Domovní přípojky inženýrských sítí',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_pripojky',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_pripojky',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'pak_wms_geod_kart_prvky',
        type: 'wms',
        title: 'Geodetické a kartografické prvky',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/pak_mapservice/wms/dtm_geod_kart_prvky',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'dtm_geod_kart_prvky',
          format: 'image/png',
          transparent: true,
        }
      },

      //DIMAP
      {
        id: 'base_orto',
        type: 'wmts',
        title: 'Ortofoto ČÚZK',
        selector: 'switch',
        visible: false,
        maxNativeZoom: 14,
        icon: this.iconPath + 'orthofoto.png',
        url: 'https://geoportal.cuzk.cz/WMTS_ORTOFOTO/WMTService.aspx?',
        attribution: CUZKATTRIBUTION,
        matrixIds: getJTSKMatrix(),
        wmts: {
          layer: 'orto',
          format: 'image/png',
          noWrap: true,
          transparent: false,
          tilematrixSet: 'jtsk:epsg:5514',
          style: 'default',
        }
      },
      {
        id: 'base_zm',
        type: 'wmts',
        title: 'Základní mapy',
        maxNativeZoom: 14,
        selector: 'switch',
        visible: false,
        icon: this.iconPath + 'zm.png',
        url: 'https://geoportal.cuzk.cz/WMTS_ZM/WMTService.aspx',
        matrixIds: getJTSKMatrix(),
        attribution: CUZKATTRIBUTION,
        wmts: {
          layer: 'zm',
          format: 'image/png',
          transparent: false,
          tilematrixSet: 'jtsk:epsg:5514',
          style: 'default',
        }
      },
      {
        id: 'orto_dtm_pak',
        type: 'wms',
        title: 'Ortofoto DTM',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/orto_dtm/wms.aspx',
        icon: this.iconPath + 'orthofoto.png',
        wms: {
          layers: 'PAK_orto',
          format: 'image/jpeg',
          transparent: false,
          noWrap: true
        }
      },
      {
        id: 'orto_dtm_khk',
        type: 'wms',
        title: 'Ortofoto DTM',
        selector: 'switch',
        visible: false,
        url: 'https://app.gmtech.cz/orto_dtm/wms.aspx',
        icon: this.iconPath + 'orthofoto.png',
        wms: {
          layers: 'KHK_orto',
          format: 'image/jpeg',
          transparent: false,
          noWrap: true
        }
      },
      {
        //DIMAP lpis base layer
        id: 'base_lpis',
        type: 'wms',
        title: 'LPIS',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-EDCE61-border-94813D.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'lpis',
          format: 'image/png',
          transparent: true,
          noWrap: true
        },
        featureSummaryTemplate: lpisTemplate
      },
      {
        id: 'base_history_pardubicky_kraj',
        type: 'wms',
        title: 'Historická',
        selector: 'switch',
        visible: false,
        url: 'http://195.113.178.19/wms_historto.dll',
        icon: this.iconPath + 'zm.png',
        wms: {
          layers: 'historto',
          format: 'image/png',
          transparent: false,
          noWrap: true
        }
      },
      {
        id: 'base_regions',
        type: 'wms',
        title: 'Kraje',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-4-solid-A6A08B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'regions',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'base_counties',
        type: 'wms',
        title: 'Okresy',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-3-solid-A6A08B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'counties',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'base_municipalities',
        type: 'wms',
        title: 'Obce',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-2-solid-A6A08B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'municipalities',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'base_areas',
        type: 'wms',
        title: 'Katastrální území',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-A6A08B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'areas',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'base_pk',
        type: 'wms',
        title: 'WMS ČÚZK PK mapa',
        selector: 'switch',
        visible: false,
        url: 'http://services.cuzk.cz/wms/local-km-wms.asp?',
        icon: this.iconPath + 'border-1-solid-852325.svg',
        attribution: CUZKATTRIBUTION,
        wms: {
          layers: 'RST_PK',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'base_kn',
        type: 'wms',
        title: 'WMS ČÚZK KN mapa',
        selector: 'switch',
        visible: false,
        url: 'http://services.cuzk.cz/wms/wms.asp?',
        icon: this.iconPath + 'border-1-solid-000000.svg',
        attribution: CUZKATTRIBUTION,
        wms: {
          layers: 'KN',
          format: 'image/png',
          transparent: true,
        },
        featureSummaryTemplate: baseKnTemplate.bind(this),
      },
      {
        id: 'base_hranice_parcel_barevne',
        type: 'wms',
        title: 'WMS ČÚZK hranice parcel podle kvality',
        selector: 'switch',
        visible: false,
        url: 'http://services.cuzk.cz/wms/wms.asp',
        icon: this.iconPath + 'border-1-solid-00FF00.svg',
        attribution: CUZKATTRIBUTION,
        wms: {
          layers: 'hranice_parcel_barevne',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'base_podrobne_body_barevne',
        type: 'wms',
        title: 'WMS ČÚZK podrobné body podle kódu kvality',
        selector: 'switch',
        visible: false,
        url: 'http://services.cuzk.cz/wms/wms.asp',
        icon: this.iconPath + 'dot-00ff00.svg',
        attribution: CUZKATTRIBUTION,
        wms: {
          layers: 'podrobne_body_barevne',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'base_def_budovy',
        type: 'wms',
        title: 'WMS ČÚZK definiční body budov - KN',
        selector: 'switch',
        visible: false,
        url: 'http://services.cuzk.cz/wms/wms.asp',
        icon: this.iconPath + 'dot-fe0000.svg',
        attribution: CUZKATTRIBUTION,
        wms: {
          layers: 'DEF_BUDOVY',
          format: 'image/png',
          transparent: true,
        }
      },
      {
        id: 'mm_roadway',
        type: 'wms',
        title: 'Polohopis',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'roadway.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'roadway',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'mm_tree_line',
        type: 'wms',
        title: 'Linie stromořadí',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'circle-2E400B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'mm_tree_line',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'mm_divisions_a',
        type: 'wms',
        title: 'Silnice',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-87866B.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'mm_divisions',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'key',
          literal: 'a'
        },
        featureSummaryTemplate: divisionSummaryTemplate
      },
      {
        id: 'mm_divisions_p',
        type: 'wms',
        title: 'Příkopy',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-875228.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'mm_divisions',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'key',
          literal: 'p'
        },
        featureSummaryTemplate: divisionSummaryTemplate
      },
      {
        id: 'mm_divisions_s',
        type: 'wms',
        title: 'Stromořadí',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-5E8744.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'mm_divisions',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'key',
          literal: 's'
        },
        featureSummaryTemplate: divisionSummaryTemplate
      },
      {
        id: 'management_areas',
        type: 'wms',
        title: 'Majetkové správy',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-3-solid-D75732.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'management_areas',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0'
        }
      },
      {
        id: 'property_info_owned_inside',
        type: 'wms',
        title: 'V pořádku ve vlastnictví',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-70C320.svg',
        featureSummaryTemplate: this.statusTemplate(mapId),
        wms: {
          layers: this.getDIlayerPrefix() + 'property_info',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
          styles: ['dimap:dimap_property_info_inside_owned']
        },
        filter: this.dimapFeatureFilterService.filterPropertyInfoOwnedInside()
      },
      {
        id: 'property_info_owned_outside',
        type: 'wms',
        title: 'Nadbytečné',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-3B5B94.svg',
        featureSummaryTemplate: this.statusTemplate(mapId),
        wms: {
          layers: this.getDIlayerPrefix() + 'property_info',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
          styles: ['dimap:dimap_property_info_to_sell']
        },
        filter: this.dimapFeatureFilterService.filterPropertyInfoOwnedOutside()
      },
      {
        id: 'property_info_others_inside',
        type: 'wms',
        title: 'Výkup',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-E16149.svg',
        featureSummaryTemplate: this.statusTemplate(mapId),
        wms: {
          layers: this.getDIlayerPrefix() + 'property_info',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
          styles: ['dimap:dimap_property_info_to_buy'],
        },
        filter: this.dimapFeatureFilterService.filterPropertyInfoOthersInside()
      },
      {
        id: 'property_info_others_outside',
        type: 'wms',
        title: 'Cizí v pořádku',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-ffffff.svg',
        featureSummaryTemplate: this.statusTemplate(mapId),
        wms: {
          layers: this.getDIlayerPrefix() + 'property_info',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
          styles: ['dimap:dimap_property_info_outside_others']
        },
        filter: this.dimapFeatureFilterService.filterPropertyInfoOthersOutside()
      },
      {
        id: 'property_info_geometric_plan',
        type: 'wms',
        title: 'Geometrický plán',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'line-1-solid-D90007.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'geometric_plan',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: this.dimapFeatureFilterService.filterGeometricPlan()
      },
      {
        id: 'di_repairs_5',
        type: 'wms',
        title: 'Klas. stupeň 5 - havarijní',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-d7191c.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [5]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_repairs_4',
        type: 'wms',
        title: 'Klas. stupeň 4 - nevyhovující',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-fdae61.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [4]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_repairs_3',
        type: 'wms',
        title: 'Klas. stupeň 3 - vyhovující',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-ffffbf.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [3]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_repairs_2',
        type: 'wms',
        title: 'Klas. stupeň 2 - dobrý',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-a6d96a.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [2]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_repairs_1',
        type: 'wms',
        title: 'Klas. stupeň 1 - výborný',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1a9641.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [1]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_repairs_0',
        type: 'wms',
        title: 'Neklasifikováno',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-ffffff-border-1-solid-222222.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'repairs',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: orFilter('total_index', [0]),
        featureSummaryTemplate: repairTemplate
      },
      {
        id: 'di_faults_12',
        type: 'wms',
        title: 'Prolomení vozovky',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [27])
      },
      {
        id: 'di_faults_11',
        type: 'wms',
        title: 'Poklesy, místní, příčné a podélné hrboly, plošné deformace vozovky',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [20, 21, 22, 23, 24, 25, 26])
      },
      {
        id: 'di_faults_10',
        type: 'wms',
        title: 'Trhliny síťové',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [17])
      },
      {
        id: 'di_faults_9',
        type: 'wms',
        title: 'Trhliny rozvětvené',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [15, 16])
      },
      {
        id: 'di_faults_8',
        type: 'wms',
        title: 'Trhliny široké příčné',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [13, 14])
      },
      {
        id: 'di_faults_7',
        type: 'wms',
        title: 'Trhliny úzké, nepravidelné a mozaikové',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [10, 11, 12])
      },
      {
        id: 'di_faults_6',
        type: 'wms',
        title: 'Vysprávky',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [9])
      },
      {
        id: 'di_faults_5',
        type: 'wms',
        title: 'Výtluky',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [8])
      },
      {
        id: 'di_faults_4',
        type: 'wms',
        title: 'Hloubková koroze obrusné vrstvy',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [7])
      },
      {
        id: 'di_faults_3',
        type: 'wms',
        title: 'Koroze kalové vrstvy, ztráta kameniva z nátěru',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
          cacheKey: this.getDIlayerPrefix() + 'faults'
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [4, 5])
      },
      {
        id: 'di_faults_2',
        type: 'wms',
        title: 'Ztráta makrotextury (pocení, vystoupení tmelu)',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [2, 19])
      },
      {
        id: 'di_faults_1',
        type: 'wms',
        title: 'Ztráta asfaltového tmelu a kaverny v obrusné vrstvě',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'fill-1-border-CC0000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'faults',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: faultTemplate,
        filter: orFilter('code', [3, 6])
      },
      {
        id: 'kn_parcels',
        type: 'wms',
        title: 'Parcely KN',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-000000.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'kn_parcels',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: parcelSummaryTemplate
      },
      {
        id: 'di_construction_object',
        type: 'wms',
        title: 'Majetkoprávně řešené stavby',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-c184ad.svg',
        wms: {
          layers: 'di_pardubicky_kraj_majetkopravne_resene_stavby',
          format: 'image/png',
          feature_count: 10,
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: constructionObjectTemplate
      },
      {
        id: 'di_occupation',
        type: 'wms',
        title: 'Majetkoprávně řešené parcely staré zátěže',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-7d3f69.svg',
        wms: {
          layers: 'di_pardubicky_kraj_stara_zatez_parcely',
          format: 'image/png',
          feature_count: 10,
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        featureSummaryTemplate: dimapOccupationTemplate
      },
      {
        id: 'roads_third_class',
        type: 'wms',
        title: 'III. třída',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'line-1-solid-FF7F00.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'roads',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'kod_tr_kom',
          literal: 4
        }
      },
      {
        id: 'roads_second_class',
        type: 'wms',
        title: 'II. třída',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'line-2-solid-1F78B4.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'roads',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'kod_tr_kom',
          literal: 3
        }
      },
      {
        id: 'roads_first_class',
        type: 'wms',
        title: 'I. třída',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'line-3-solid-E31A1C.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'roads',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'kod_tr_kom',
          literal: 2
        }
      },
      {
        id: 'roads_highway',
        type: 'wms',
        title: 'Dálnice',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'line-4-solid-C429A5.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'roads',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'kod_tr_kom',
          literal: 1
        }
      },
      {
        id: 'roads_stationing',
        type: 'wms',
        title: 'Staničení',
        selector: 'switch',
        visible: false,
        url: this.DIwmsLayersUrl,
        icon: this.iconPath + 'circle-stationing.svg',
        wms: {
          layers: this.getDIlayerPrefix() + 'stationing',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: 'true',
          tilesOrigin: '0,0',
        }
      },
      {
        id: 'bridges',
        type: 'wfs',
        title: 'Mosty',
        selector: 'switch',
        visible: false,
        url: this.DIwfsLayersUrl,
        icon: this.iconPath + 'bridge.svg',
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getDIlayerPrefix() + 'bridges'
        },
        geometryColumn: 'geom',
        chunked: true,
        geojson: getBridgeGeoJsonSettings(),
        featureSummaryTemplate: bridgeTemplate,
      },

      // HFBiz layers start
      {
        id: 'hfbiz_vertical_road_signs',
        type: 'wms',
        title: 'Svislé dopravní značení',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/pasport`,
        attribution: HFBIZ_ATTRIBUTION,
        icon: this.iconPath + '/hfbiz/sdz.png',
        wms: {
          version: '1.3.0',
          feature_count: 10,
          layers: 'sdz_txt',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        featureSummaryTemplate: hfbizVerticalRoadSignTemplate
      },
      {
        id: 'hfbiz_horizontal_road_signs',
        type: 'wms',
        title: 'Vodorovné dopravní značení',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/pasport`,
        attribution: HFBIZ_ATTRIBUTION,
        icon: this.iconPath + '/hfbiz/vdz.png',
        wms: {
          version: '1.3.0',
          feature_count: 10,
          layers: 'vdz_txt',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        featureSummaryTemplate: hfbizHorizontalRoadSignTemplate
      },
      {
        id: 'hfbiz_trees',
        type: 'wms',
        title: 'Stromy (jehličnaté a listnaté)',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/pasport`,
        attribution: HFBIZ_ATTRIBUTION,
        icon: this.iconPath + '/hfbiz/trees.png',
        wms: {
          version: '1.3.0',
          feature_count: 10,
          layers: 'stromy',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        featureSummaryTemplate: hfbizTreesTemplate
      },
      {
        id: 'hfbiz_zbz',
        type: 'wms',
        title: 'ZBZ (svodidla a zábradlí)',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/pasport`,
        attribution: HFBIZ_ATTRIBUTION,
        icon: this.iconPath + '/hfbiz/zbz.png',
        wms: {
          version: '1.3.0',
          feature_count: 10,
          layers: 'zbz',
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        featureSummaryTemplate: hfbizCrashBarrierTemplate
      },

      {
        id: 'mm_spherephoto',
        type: 'wms',
        title: 'Panoramatické snímky 2/2019',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/mms`,
        icon: this.iconPath + '/hfbiz/point.png',
        wms: {
          layers: 'mms_point_1',
          feature_count: 1,
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        onLayerClick: this.openMultiSceneCloudViewer,
      },

      {
        id: 'mm_spherephoto_d35_201911',
        type: 'wms',
        title: 'Panoramatické snímky objízdných tras D35 11/2019',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/mms`,
        icon: this.iconPath + '/hfbiz/point2.png',
        wms: {
          layers: 'mms_point_2',
          feature_count: 1,
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        onLayerClick: this.openMultiSceneCloudViewer,
      },

      {
        id: 'mm_spherephoto_d35_202103',
        type: 'wms',
        title: 'Panoramatické snímky objízdných tras D35 03/2021',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/d35_objizdne_trasy`,
        icon: this.iconPath + '/hfbiz/point3.png',
        wms: {
          layers: 'mms_line_3,mms_point_3',
          feature_count: 1,
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        onLayerClick: this.openMultiSceneCloudViewer,
      },
      {
        id: 'mm_spherephoto_d35_202109',
        type: 'wms',
        title: 'Panoramatické snímky objízdných tras D35 09/2021',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL}/d35_objizdne_trasy`,
        icon: this.iconPath + '/hfbiz/point3.png',
        wms: {
          layers: 'mms_line_4,mms_point_4',
          feature_count: 1,
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        onLayerClick: this.openMultiSceneCloudViewer,
      },
      {
        id: 'mm_spherephoto_rsdpce',
        type: 'wms',
        title: 'Panoramatické snímky',
        selector: 'switch',
        visible: false,
        url: `${HFBIZ_WMS_URL_RSD}/mms`,
        icon: this.iconPath + '/hfbiz/point.png',
        multiSceneLayers: ['km', 'dimap'],
        wms: {
          layers: 'mms_line,mms_point',
          feature_count: 1,
          format: 'image/png',
          transparent: true,
          minZoom: 0,
          tiled: false,
          tileSize: 1024,
        },
        onLayerClick: this.openMultiSceneCloudViewer,
      },

      // HFBiz layers end

      {
        id: 'easement_status2',
        type: 'node',
        title: 'Stav řešení VB',
        selector: 'switch',
        visible: false,
        layers: ['easement_status2_resolved', 'easement_status2_in_progress', 'easement_status2_not_in_progress'],
      },
      {
        id: 'easement_status2_resolved',
        type: 'wms',
        title: 'Vyřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-299631.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'easement_status2',
          format: 'image/png',
          transparent: true
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '3'
        },
        featureSummaryTemplate: {
          header: 'Smlouva',
          rows: caseRows
        }
      },
      {
        id: 'easement_status2_in_progress',
        type: 'wms',
        title: 'V řešení',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-66b2ff.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'easement_status2',
          format: 'image/png',
          transparent: true
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '2'
        },
        featureSummaryTemplate: {
          header: 'Smlouva',
          rows: caseRows
        }
      },
      {
        id: 'easement_status2_not_in_progress',
        type: 'wms',
        title: 'Neřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-ffa500.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'easement_status2',
          format: 'image/png',
          transparent: true
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '1'
        },
        featureSummaryTemplate: {
          header: 'Smlouva',
          rows: caseRows
        }
      },



      //SYMAP
      {
        id: 'problematic_owners',
        type: 'wms',
        title: 'Problematičtí vlastníci',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-b11600-border-1-solid-b11600.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'problematic_owners',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: problematicOwnerTemplate,
      },
      {
        id: 'sy_base_lpis',
        type: 'wms',
        title: 'LPIS',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-edce61-border-1-solid-94813D.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'lpis',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'LPIS',
          rows: [
            {
              label:'Národní kód DPB:',
              data: f => f.featureData.properties.nkodfb,
            },
            {
              label:'Zkrácený kód DPB:',
              data: f => f.featureData.properties.zkodfb,
            },
            {
              label:'Uživatel:',
              data: f => f.featureData.properties.obchodni_jmeno
            },
            {
              label:'Adresa:',
              data: f => this.fullAddressPipe.transform({street: f.featureData.properties.ulice, houseNumber: f.featureData.properties.cislo_popisne, postCode: f.featureData.properties.psc, municipality: f.featureData.properties.obec, quarter: f.featureData.properties.cast_obce}),
            },
            {
              label:'IČO:',
              data: f => f.featureData.properties.ico,
            },
            {
              label:'Telefon:',
              data: f => f.featureData.properties.telefon,
            }
          ]
        }
      },
      {
        id: 'geom_km',
        type: 'wms',
        title: 'Kilometráž',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-circle-D41111.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'geom_km',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'kn_occupation',
        type: 'wms',
        title: 'WMS ČÚZK VB',
        selector: 'switch',
        visible: false,
        url: 'https://services.cuzk.cz/wms/wms.asp?',
        icon: this.iconPath + 'border-1-dash-blue-pink.svg',
        wms: {
          layers: 'vb_linie,vb_linie_i,vb_plochy_cast,vb_plochy_parcela',
          opacity: '0.7',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'base_project',
        type: 'wms',
        title: 'Projekt',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-ff0000.svg',
        wms: {
          layers: 'raster:' + this.getProjectKey() + '_project',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'base_additional_project',
        type: 'wms',
        title: 'Projekt 2',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-ff0000.svg',
        wms: {
          layers: 'raster:' + this.getProjectKey() + '_additional_project',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'base_additional_project_2',
        type: 'wms',
        title: 'Projekt 3',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-ff0000.svg',
        wms: {
          layers: 'raster:' + this.getProjectKey() + '_additional_project_2',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'easement_expropriation',
        type: 'wfs',
        title: 'Vyvlastnění věcného břemene',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getSYlayerPrefix() + 'easement_expropriation',
        },
        featureSummaryTemplate: {
          header: 'Vyvlastnění věcného břemene',
          rows: caseRows
        },
        geojson: getGeoJsonSettings('rgb(74, 37, 62)'),
      },

      {
        id: 'easement_geom_land_take',
        type: 'wfs',
        title: 'Řešená VB podle ZE',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'easement_geom',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: {
            type: 'PropertyIsEqualTo',
            propertyName: 'fiktivni',
            literal: 'true'
          },
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: easementTemplate,
        geojson: getGeoJsonSettings('rgb(102, 102, 102)'),
      },

      {
        id: 'easement_geom_geometric_plan',
        type: 'wfs',
        title: 'Řešená VB z GP',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'easement_geom',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: {
            type: 'PropertyIsEqualTo',
            propertyName: 'fiktivni',
            literal: 'false'
          },
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: easementTemplate,
        geojson: getGeoJsonSettings('rgb(0, 50, 200)'),
      },

      {
        id: 'geom_vbze',
        type: 'wfs',
        title: 'Orientační kresba VB podle ZE',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'geom_vbze',
          sortBy: 'fid',
        },
        geojson: getGeoJsonSettings('rgb(200, 200, 200)'),
      },

      {
        id: 'geom_gp',
        type: 'wms',
        title: 'Nové hranice podle GP',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-ff0000.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'geom_gp',
          format: 'image/png',
          transparent: true,
        },
        featureSummaryTemplate: gpTemplate,
      },
      {
        id: 'parcel_pupfl',
        type: 'wms',
        title: 'Pozemky PUPFL',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-54c654.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'parcel_pupfl',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'parcel_zpf',
        type: 'wms',
        title: 'Pozemky ZPF',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-9edf9e.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'parcel_zpf',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'parcel_investor',
        type: 'wms',
        title: 'Vlastnictví investora',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-299631.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'parcel_investor',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'geom_ku',
        type: 'wms',
        title: 'Katastrální území',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-2-solid-990000.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'geom_ku',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'gp_parcels',
        type: 'wms',
        title: 'Parcely nezapsaných GP',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-cc0000.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'gp_parcels',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Parcela GP',
          rows: gpParcelRows
        }
      },
      {
        id: 'kn_latest',
        type: 'wms',
        title: 'KN mapa stav k $CADASTRE_DATA_DATE',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-000000.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'kn_parcels_latest',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          getHeader: ((f) => {
            return 'Parcela stav k ' + this.datePipe.transform(f.cadastreDataDate);
          }),
          rows: actualKNParcels,
        }
      },
      {
        id: 'kn_zab',
        type: 'wms',
        title: 'KN mapa zab. elaborátu',
        selector: 'switch',
        visible: true,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-129b34.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'kn_parcels',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Parcela podle stavu ZAB',
          rows: occupationParcelsRows
        }
      },
      {
        id: 'occupation_expropriation',
        type: 'wfs',
        title: 'Vyvlastnění trvalého záboru',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getSYlayerPrefix() + 'occupation_expropriation',
        },
        featureSummaryTemplate: {
          header: 'Vyvlastnění trvalého záboru',
          rows: caseRows
        },
        geojson: getGeoJsonSettings('rgb(74, 37, 62)'),
      },
      {
        id: 'permanent_occupation_status',
        type: 'node',
        title: 'Stav řešení trvalých záborů',
        selector: 'switch',
        visible: false,
        layers: ['permanent_occupation_status_resolved', 'permanent_occupation_status_in_progress', 'permanent_occupation_status_not_in_progress'],
      },
      {
        id: 'permanent_occupation_status_resolved',
        type: 'wms',
        title: 'Vyřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-299631.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'permanent_occupation_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '3'
        },
      },
      {
        id: 'permanent_occupation_status_in_progress',
        type: 'wms',
        title: 'V řešení',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-66b2ff.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'permanent_occupation_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '2'
        },
      },
      {
        id: 'permanent_occupation_status_not_in_progress',
        type: 'wms',
        title: 'Neřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-ffa500.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'permanent_occupation_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '1'
        },
      },
      {
        id: 'temporary_occupation_under_one_year_status',
        type: 'node',
        title: 'Stav řešení dočasných záborů do jednoho roku',
        selector: 'switch',
        visible: false,
        layers: ['temporary_occupation_under_one_year_status_resolved', 'temporary_occupation_under_one_year_status_in_progress', 'temporary_occupation_under_one_year_status_not_in_progress'],
      },
      {
        id: 'temporary_occupation_under_one_year_status_resolved',
        type: 'wms',
        title: 'Vyřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-299631.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_under_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '3',
        },
      },
      {
        id: 'temporary_occupation_under_one_year_status_in_progress',
        type: 'wms',
        title: 'V řešení',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-66b2ff.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_under_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '2',
        },
      },
      {
        id: 'temporary_occupation_under_one_year_status_not_in_progress',
        type: 'wms',
        title: 'Neřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-ffa500.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_under_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '1',
        },
      },
      {
        id: 'temporary_occupation_over_one_year_status',
        type: 'node',
        title: 'Stav řešení dočasných záborů nad jeden rok',
        selector: 'switch',
        visible: false,
        layers: ['temporary_occupation_over_one_year_status_resolved', 'temporary_occupation_over_one_year_status_in_progress', 'temporary_occupation_over_one_year_status_not_in_progress'],
      },
      {
        id: 'temporary_occupation_over_one_year_status_resolved',
        type: 'wms',
        title: 'Vyřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-299631.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_over_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '3'
        },
      },
      {
        id: 'temporary_occupation_over_one_year_status_in_progress',
        type: 'wms',
        title: 'V řešení',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-66b2ff.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_over_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '2'
        },
      },
      {
        id: 'temporary_occupation_over_one_year_status_not_in_progress',
        type: 'wms',
        title: 'Neřešeno',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-ffa500.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'temporary_occupation_over_one_year_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Případ',
          rows: caseRows
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'category',
          literal: '1'
        },
      },
      {
        id: 'occupation_problem_status',
        type: 'wms',
        title: 'Problémy v řešení případů',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-problem_status.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'occupation_problem_status',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          header: 'Problémy v řešení případů',
          rows: caseRows
        }
      },
      {
        id: 'geom_ochrpas',
        type: 'wms',
        title: 'Ochranné pásmo',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-2-dot_dash-ff13ff.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'geom_ochrpas',
          format: 'image/png',
          transparent: true
        }
      },
      {
        id: 'construction_object',
        type: 'wms',
        title: '$CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'fill-ffab56.svg',
        wms: {
          feature_count: 10,
          layers: this.getSYlayerPrefix() + 'construction_object',
          format: 'image/png',
          transparent: true
        },
        featureSummaryTemplate: {
          getHeader: () => this.wordService.getTranslation("CONSTRUCTION_OBJECT", true),
          rows: [
            {
              label:'Číslo:',
              template: 'uiSref',
              data: (f) => { return { uiSref: "symap.project.constructionObjects.detail", uiParams: {id: f.featureData.properties.id}, content: f.featureData.properties.cislo }; },
            },
            {
              label:'Název:',
              data: f => f.featureData.properties.nazev,
            },
            {
              getLabel: () => this.wordService.getTranslation("ETAPA_USEK") + ':',
              data: f => f.featureData.properties.usek_etapa,
            },
          ]
        }
      },
      {
        id: 'occupation_permanent_other',
        type: 'wfs',
        title: 'Trvalý zábor pro Jiné',
        description: 'typ záboru: 2, 3, 4, 111, 121',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getSYlayerPrefix() + 'occupation'
        },
        geometryColumn: 'geom',
        chunked: true,
        filter: {
          type: 'And',
          arg1: orFilter('typ', [2,3,4,111,121]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        geojson: getGeoJsonSettings('rgb(255, 166, 201)'),
        featureSummaryTemplate: occupationTemplate,
      },
      {
        id: 'occupation_permanent_investor',
        type: 'wfs',
        title: 'Trvalý zábor pro investora',
        description: 'typ záboru: 1, 7, 8, 11, 12',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getSYlayerPrefix() + 'occupation'
        },
        geometryColumn: 'geom',
        chunked: true,
        filter: {
          type: 'And',
          arg1: orFilter('typ', [1,7,8,11,12]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        geojson: getGeoJsonSettings('rgb(232, 127, 255)'),
        featureSummaryTemplate: occupationTemplate,
      },

      //
      // occupations
      //
      {
        id: 'occupation_type_1',
        type: 'wfs',
        title: 'Trvalý zábor',
        description: 'typ záboru: 1,11',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          sortBy: 'id',
          typeNames: this.getSYlayerPrefix() + 'occupation',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [1,11]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        geojson: getGeoJsonSettings('rgb(232, 127, 255)'),
        featureSummaryTemplate: occupationTemplate,
      },
      {
        id: 'occupation_type_2',
        type: 'wfs',
        title: 'Trvalý zábor pro jiné s výkupem',
        description: 'typ záboru: 2, 111',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        wfs: {
          count: 1000,
          feature_count: 10,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        geometryColumn: 'geom',
        chunked: true,
        filter: {
          type: 'And',
          arg1: orFilter('typ', [2,111]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        geojson: getGeoJsonSettings('rgb(255, 166, 201)'),
        featureSummaryTemplate: occupationTemplate,
      },
      {
        id: 'occupation_type_3',
        type: 'wfs',
        title: 'Trvalý zábor pro jiné bez výkupu',
        description: 'typ záboru: 3, 121',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [3,121]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(243, 209, 223)'),
      },

      {
        id: 'occupation_type_4',
        type: 'wfs',
        title: 'Výkup zbytkových pozemků',
        description: 'typ záboru: 4, 42',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [4,42]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(241, 210, 248)'),
      },

      {
        id: 'occupation_type_5',
        type: 'wfs',
        title: 'Dočasný zábor do 1 roku',
        description: 'typ záboru: 5, 22',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [5,22]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(191, 255, 150)'),
      },

      {
        id: 'occupation_type_6',
        type: 'wfs',
        title: 'Dočasný zábor nad 1 rok',
        description: 'typ záboru: 6, 21',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [6,21]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(145, 229, 255)'),
      },

      {
        id: 'occupation_type_7',
        type: 'wfs',
        title: 'Trvalý zábor bez výkupu',
        description: 'typ záboru: 7, 12',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [7,12]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(234, 188, 245)'),
      },

      {
        id: 'occupation_type_8',
        type: 'wfs',
        title: 'Trvalý zábor',
        description: 'typ záboru: 8',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: {
            type: 'PropertyIsEqualTo',
            propertyName: 'typ',
            literal: 8
          },
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(234, 188, 245)'),
      },

      {
        id: 'occupation_type_9',
        type: 'wfs',
        title: 'Dočasný zábor do 1 roku s trv. vynětím ZPF (PUPFL)',
        description: 'typ záboru: 9, 221',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        geometryColumn: 'geom',
        chunked: true,
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [9,221]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(255, 255, 51)'),
      },

      {
        id: 'occupation_type_10',
        type: 'wfs',
        title: 'Dočasný zábor nad 1 rok s trv. vynětím ZPF (PUPFL)',
        description: 'typ záboru: 10, 211',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [10,211]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(255, 255, 51)'),
      },

      {
        id: 'occupation_type_112',
        type: 'wfs',
        title: 'UMVŽST-A',
        description: 'typ záboru: 112',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [112]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(80, 180, 255)'),
      },

      {
        id: 'occupation_type_113',
        type: 'wfs',
        title: 'UMVŽST-B',
        description: 'typ záboru: 113',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [113]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(50, 150, 255)'),
      },

      {
        id: 'occupation_type_31',
        type: 'wfs',
        title: 'Jiné dotčení',
        description: 'typ záboru: 31',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [31]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(255, 191, 0)'),
      },

      {
        id: 'occupation_type_41',
        type: 'wfs',
        title: 'Vyvedení',
        description: 'typ záboru: 41',
        selector: 'switch',
        visible: true,
        url: this.SYwfsLayersUrl,
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation',
          sortBy: 'id',
        },
        filter: {
          type: 'And',
          arg1: orFilter('typ', [41]),
          arg2: {
            type: 'PropertyIsNull',
            propertyName: 'end_date'
          }
        },
        featureSummaryTemplate: occupationTemplate,
        geojson: getGeoJsonSettings('rgb(255, 191, 0)'),
      },

      {
        id: 'occupation_acquirer',
        type: 'node',
        title: 'Nabyvatel TZ',
        selector: 'switch',
        visible: true,
        layers: ['occupation_acquirer_name', 'occupation_acquirer_7', 'occupation_acquirer_6', 'occupation_acquirer_5', 'occupation_acquirer_4', 'occupation_acquirer_3', 'occupation_acquirer_2', 'occupation_acquirer_1'],
      },
      {
        id: 'occupation_acquirer_1',
        type: 'wfs',
        title: 'Investor',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 1',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '1'
        },
        geojson: getGeoJsonSettings('rgb(110, 135, 193)'),
      },
      {
        id: 'occupation_acquirer_2',
        type: 'wfs',
        title: 'Organizační složky státu',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 2',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '2'
        },
        geojson: getGeoJsonSettings('rgb(98, 201, 239)'),
      },
      {
        id: 'occupation_acquirer_3',
        type: 'wfs',
        title: 'Kraje, krajské organizace',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 3',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '3'
        },
        geojson: getGeoJsonSettings('rgb(149, 114, 26)'),
      },
      {
        id: 'occupation_acquirer_4',
        type: 'wfs',
        title: 'Obce, městysy, města',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 4',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '4'
        },
        geojson: getGeoJsonSettings('rgb(206, 165, 78)'),
      },
      {
        id: 'occupation_acquirer_5',
        type: 'wfs',
        title: 'Právnické osoby',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 5',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '5'
        },
        geojson: getGeoJsonSettings('rgb(160, 175, 160)'),
      },
      {
        id: 'occupation_acquirer_6',
        type: 'wfs',
        title: 'Fyzické osoby',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 6',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '6'
        },
        geojson: getGeoJsonSettings('rgb(226, 121, 120)'),
      },
      {
        id: 'occupation_acquirer_7',
        type: 'wfs',
        title: 'Ostatní',
        selector: 'switch',
        visible: false,
        url: this.SYwfsLayersUrl,
        description: 'typ nabyvatele: 7',
        chunked: true,
        geometryColumn: 'geom',
        wfs: {
          feature_count: 10,
          count: 1000,
          typeNames: this.getSYlayerPrefix() + 'occupation_acquirer',
          sortBy: 'id',
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '7'
        },
        geojson: getGeoJsonSettings('rgb(241, 188, 187)'),
      },
      {
        id: 'occupation_acquirer_name',
        type: 'wms',
        title: 'Název nabyvatele',
        selector: 'switch',
        visible: false,
        url: this.SYwmsLayersUrl,
        icon: this.iconPath + 'border-1-solid-000000-N.svg',
        wms: {
          layers: this.getSYlayerPrefix() + 'occupation_acquirer',
          format: 'image/png',
          transparent: true,
        },
        filter: {
          type: 'PropertyIsEqualTo',
          propertyName: 'typ_nabyvatele',
          literal: '1'
        },
        featureSummaryTemplate: occupationAcquirerTemplate,
        geojson: getGeoJsonSettings('rgb(110, 135, 193)'),
      },
    ];
  }

  getLayerById(layerId, mapId) {
    return this.getLayers(mapId).find(l => l.id === layerId);
  }
}

export function baseKnTemplate(event) {
  const point = this.mapUtilsCrsService.getCrs('5514').project(event.latlng);
  return [{
    header: 'Online katastr nemovitostí',
    highlightFeature: null,
    rows: [
      {
        template: 'href',
        data: f => { return {
          href: 'http://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=' + Math.round(point.x) + '&y=' + Math.round(point.y),
          iconClass: 'cuzk small pr-5',
          content: 'Informace o parcele v katastru nemovitostí',
        }},
      },
    ],
  }];
}
