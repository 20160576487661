import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { BrandTranslatePipe } from '@app/common/pipes/brand-translate.pipe';
import { ReadinessStateEnum } from '@app/ps/enums/readiness-state.enum';

@Pipe({ name: 'projectState' })
@Injectable({
  providedIn: 'root'
})
export class ProjectStatePipe implements PipeTransform {

  constructor(
    private brandTranslatePipe: BrandTranslatePipe
  ) {
  }

  transform(project: any) {
    const state = this.getEnum(project);

    if (!state) {
      return '';
    }

    return this.brandTranslatePipe.transform('ps.projectState.' + state);
  }

  getEnum(project: any): string {
    if (!project.state) {
      return undefined;
    }

    if (project.state !== 'STARTED' || (
      !project.readinessPermanentOccupations &&
      !project.readinessTemporaryUnderOccupations &&
      !project.readinessTemporaryOverOccupations &&
      !project.readinessTemporaryEasements
    )) {
      return project.state;
    }

    return this.isPartial(project)
      ? project.state + '_PARTIAL'
      : project.state + '_COMPLETE';
  }

  isPartial(project: any): boolean {
    const states = [ReadinessStateEnum.PARTIAL_DATA, ReadinessStateEnum.NOT_DELIVERED_DATA];
    const fields = [ 'readinessPermanentOccupations', 'readinessTemporaryUnderOccupations', 'readinessTemporaryOverOccupations', 'readinessTemporaryEasements' ];

    for (const field of fields) {
      if (project[field] && states.includes(project[field])) {
        return true;
      }
    }

    return false;
  }
}
