<h1 class="pb-20">Detail zpracování</h1>

<div style="min-height: 300px" class="text-center pb-10">
  <div class="text-center pb-10" *ngFor="let msg of request.statusDescription.split(';')">
    {{ msg }}
  </div>
</div>

<div class="text-center">
  <gmt-button [clickAction]="onClose" class="red">Zavřít</gmt-button>
</div>
