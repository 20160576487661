import {  Component, Input, OnInit } from '@angular/core';

import { CaseModel } from '@app/ps/models/case.model';
import { CaseService } from '@app/ps/services/case.service';
import { StateService } from '@uirouter/angular';
import { Restangular } from 'ngx-restangular';
import {CaseStatusService} from "@app/ps/services/case-status.service";

@Component({
  selector: 'expropriation-interim-decision-request',
  templateUrl: './expropriation-interim-decision-request.component.html',
})
export class ExpropriationInterimDecisionRequestComponent implements OnInit {
  @Input() caseId: number;
  public loading = true;
  public data: CaseModel;
  public isValid: Function;
  public recipient: any;
  expropriationInterimDecisionRequestName: string;

  constructor(
    private caseService: CaseService,
    private stateService: StateService,
    private restangular: Restangular,
    private caseStatusService: CaseStatusService,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
  }

  async   ngOnInit() {
    this.expropriationInterimDecisionRequestName = (await this.caseStatusService.loadStatusName('ExpropriationInterimDecisionRequest')).name;
    this.restangular.one('cases', this.caseId).get().toPromise().then((data) => {
      this.data = data;
      this.loading = false;
    });
  }

  formCallbackRegister($event) {
    this.isValid = $event.isValid;
  }

  async onSubmit(): Promise<any> {
    const sendData = {
      expropriationInterimDecisionRequestDate: this.data.expropriationInterimDecisionRequestDate,
    };
    await this.restangular
      .one('cases', this.caseId)
      .customPUT(sendData, 'expropriation-interim-decision-request')
      .toPromise();

    this.stateService.go('^');
  }
}

