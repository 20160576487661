import { Component, OnInit, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';
import { ModulesService } from '@app/common/services/modules.service';
import { ChecklistModel } from '@app/common/models/checklist.model';

@Component({
  selector: 'sydi-projects',
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit {

  @Input() module: string;
  @Input() userId: number;
  @Input() callbacks: any;
  @Input() set userProjects(userProjects: any[]) {
    this.checklistSelection = new ChecklistModel(userProjects);
  }

  loading = true;
  projects;
  checklistSelection;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private dialogService: DialogService,
    private stateService: StateService,
    private modulesService: ModulesService,
  ) {
    this.onPermissionsChange = this.onPermissionsChange.bind(this);
  }

  ngOnInit() {
    this.modulesService.getProjects(this.module).then(data => this.projects = data.sort((a, b) => a.name.localeCompare(b.name))).then(() => {
      this.loading = false;
    });
  }

  onPermissionsChange(project, changeStatus = false) {
    if (this.checklistSelection.isChecked(project.key) && changeStatus) {
      return this.dialogService.confirmDialogPromise(`Opravdu chcete odebrat uživateli veškerá práva v projektu ${project.name}?`)
        .then((data) => {
          if (data === true) {
            const payload = { projectKey: project.key, application: this.module, permissions: [] };
            this.modulesService.saveUserPermissions(this.module, project.key, this.userId, payload).then(() => {
              this.checklistSelection.toggleSelection(project.key);
              this.callbacks.get('userPermissionsChangedCallback')();
            });
          }
        });
    } else {
      this.stateService.go('settings.users.detail.editPermissions', { projectKey: project.key, tab: this.module });
    }
  }
}
