import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CaseModel } from '@app/ps/models/case.model';
import { TabModel } from '@app/common/models/tab.model';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { ParcelService } from '@app/ps/services/parcel.service';
import { AuthService } from '@app/common/services/auth.service';
import { TableUtils } from '@app/common/utils/table.utils';
import { CaseService } from '@app/ps/services/case.service';
import { ListModel } from '@app/common/models/list.model';
import { DocumentModel } from '@app/common/models/document.model';
import { ListService } from '@app/common/services/list.service';
import { NoteModel } from '@app/common/models/note.model';
import { NotificationModel } from '@app/ps/models/notification.model';
import { UserNamePipe } from '@app/common/pipes/username.pipe';
import { CaseStatusService } from '@app/ps/services/case-status.service';
import { StateService } from '@uirouter/angular';
import { TooltipParcelComponent } from '@app/common/components/tooltip-parcel/tooltip-parcel.component';
import { CallbackModel } from '@app/common/models/callback.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { DialogService } from '@app/common/services/dialog.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { FormCaseUpdateParcelPriceComponent } from '@app/ps/cases/components/form-case-update-parcel-price/form-case-update-parcel-price.component';
import { FormCaseUpdateSubjectComponent } from '@app/ps/cases/components/form-case-update-subject/form-case-update-subject.component';
import { HelpService } from '@app/common/services/help.service';
import { ErrorHandlerService } from '@app/common/services/error-handler.service';
import { EasementModel } from '@app/common/models/easement.model';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from '@app/common/services/config.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})
export class CaseDetailComponent implements OnInit, OnDestroy {

  @Input() caseId: number;
  @Input() callbacks: CallbackModel;

  tab: string;
  data: CaseModel;
  loading = true;
  sending = false;
  userSending = false;
  mandatarySending = false;
  actionButtons = [];
  statusActions = [];
  restBaseUrl = this.authService.getActiveApplicationRestUrl();
  authToken = this.authService.getToken();
  loggedUser = this.authService.getUser();
  tooltipParcelComponent = TooltipParcelComponent;

  attachmentList: ListModel<DocumentModel>;
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  notificationList: ListModel<NotificationModel>;

  showEasementDistributionSystemParts;
  caseTransferErrorsData;
  dataConstructionObjects;
  dataCaseOwnerships;
  dataCaseSubjects;
  dataRecipients;
  notificationsReadonly;
  notificationCounts = {archived: undefined, unarchived: undefined};

  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;

  occupationLoading = true;
  easementsLoading = true;
  dataOccupationGroups;
  dataOccupations;
  dataEasements;
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  permanentWithSolutionTypeColumns: ColumnDefinition[];
  easementColumns: ColumnDefinition[];

  caseStatuses;
  nextStates;
  documents;
  allowCadastreEntryProposal;
  previousState;
  helpIds = HelpService.HELP_IDS;
  disabledDocumentTypes = [];

  mandataryFilter = {
    filters: {
      searchText: {
        values: [],
      },
      applications: {
        values: [this.authService.getActiveApplication()],
      },
      permission: {
        values: ['assignable'],
      },
      projects: {
        values: [this.authService.getActualProject().key],
      },
    },
    sortOrder: [
      { sortBy: 'surname' },
      { sortBy: 'name' },
    ],
  };

  tabs: TabModel[] = [
    {
      name: 'Vlastníci',
      id: 'owners',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'owners' },
    },
    {
      name: 'Podrobné informace',
      id: 'detailInfo',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'detailInfo' },
    },
    {
      name: 'Parcely',
      id: 'parcels',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'parcels' },
    },
    {
      name: 'Poznámky',
      id: 'notes',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'notes' },
    },
    {
      name: 'Historie',
      id: 'history',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'history' },
    },
    {
      name: 'Upozornění',
      id: 'notifications',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'notifications' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: 'symap.project.cases.detail',
      urlParams: { tab: 'documents' },
    },
  ];

  private static readonly TABS_ORDER = ['owners', 'detailInfo', 'parcels', 'notes', 'history', 'documents'];

  private static readonly OCCUPATION_PERMANENT_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_parcel' , sortable: true },
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_switch', sortable: false },
    { id: 'current_parcel_title', sortable: false },
    { id: 'current_concern_object', sortable: false },
  ];
  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_parcel', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_switch', sortable: false },
    { id: 'current_parcel_title', sortable: false },
    { id: 'current_concern_object', sortable: false },
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: ColumnDefinition[] =
    CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly EASEMENT_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel', sortable: true },
    { id: 'geometric_plan', sortable: false },
    { id: 'construction_object', sortable: false },
    { id: 'title', sortable: false },
    { id: 'area', sortable: false },
    { id: 'length', sortable: false },
    { id: 'vb_tz', sortable: false },
  ];

  readonly EXT_PARCELS_COLUMNS = [
    { id: 'parcel', sortable: false },
    { id: 'title', sortable: false },
    { id: 'area_name', sortable: false },
    { id: 'area', sortable: false },
    { id: 'parcel_price', sortable: false },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_price_land_price', sortable: false },
    { id: 'occupation_price_rent_price', sortable: false },
    { id: 'occupation_price_summary_cases', sortable: false },
    { id: 'occupation_assessment_price', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_price_land_price', sortable: false },
    { id: 'occupation_price_rent_length', sortable: false },
    { id: 'occupation_price_summary_cases', sortable: false },
    { id: 'occupation_assessment_price', sortable: false },
  ];

  private static readonly PERMANENT_WITH_SOLUTION_TYPE_COLUMNS: ColumnDefinition[] =
    [...CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS, ...CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS];

  private static readonly OCCUPATION_OVER_YEAR_PRICE_COLUMNS: ColumnDefinition[] =
    CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  private static readonly EASEMENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'case_price', sortable: false }
  ];

  private static readonly FORBIDER_PERMANENT = [
    'AgreementOnAOtherFutureContractLiability',
    'FulfillmentOfDecisionExpropriationLiability',
    'FulfillmentOfDecisionAuctionSaleExecutionLiability',
    'AgreementOnAFutureTransferContractLiability',
    'RightToRealizeABuildingLiability',
    'InnominateSalesContractLiability',
    'BuildingPlacementLiability',
    'BorrowingLiability',
    'OtherLiability',
    'OwnersStatementLiability'
  ];

  private static readonly FORBIDER_TEMPORARY = [
    'TemporaryExpropriationLiability',
    'AgreementOnAOtherFutureContractLiability',
    'RightToRealizeABuildingLiability',
    'InnominateSalesContractLiability',
    'AccessToLandLiability',
    'BuildingPlacementLiability',
    'BorrowingLiability',
    'OtherLiability',
    'OwnersStatementLiability'
  ];
  private static readonly FORBIDER_EASEMENT = [
    'FulfillmentOfDecisionExpropriationLiability',
    'AgreementOnAFutureNetworkConstructionContractLiability',
    'RightToRealizeABuildingLiability'
  ];

  private static readonly PARCELS_EXTERNAL_TAB = {
    name: 'Parcely investora',
    id: 'parcelsExternal',
    href: 'symap.project.cases.detail',
    urlParams: { tab: 'parcelsExternal' },
  };

  public allowExpropriation = true;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND: any,
    private restangular: Restangular,
    public userNamePipe: UserNamePipe,
    private errorHandlerService: ErrorHandlerService,
    private caseStatusService: CaseStatusService,
    private authService: AuthService,
    private caseService: CaseService,
    private parcelService: ParcelService,
    private listService: ListService,
    private dialogService: DialogService,
    private stateService: StateService,
    private settingsService: SettingsService,
  ) {
    this.cancel = this.cancel.bind(this);
    this.renew = this.renew.bind(this);
    this.backStatus = this.backStatus.bind(this);
    this.isThreeParty = this.isThreeParty.bind(this);
    this.canEdit = this.canEdit.bind(this);
    this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    this.onUpdateHistoryList = this.onUpdateHistoryList.bind(this);
    this.onUpdateAttachmentsList = this.onUpdateAttachmentsList.bind(this);
    this.onUpdateOwnersList = this.onUpdateOwnersList.bind(this);
    this.onUpdateParcelsList = this.onUpdateParcelsList.bind(this);
    this.onUpdateNotificationList = this.onUpdateNotificationList.bind(this);
    this.caseUpdated = this.caseUpdated.bind(this);
    this.caseSubjectUpdated = this.caseSubjectUpdated.bind(this);
    this.caseParcelPriceUpdated = this.caseParcelPriceUpdated.bind(this);
    this.updateCaseSubject = this.updateCaseSubject.bind(this);
    this.updateCaseOwnership = this.updateCaseOwnership.bind(this);
    this.loadSetting = this.loadSetting.bind(this);
    this.filterActionButtons = this.filterActionButtons.bind(this);
    this.updateParcelPriceDialogFunction = this.updateParcelPriceDialogFunction.bind(this);
    this.updateCaseSubjectDialogFunction = this.updateCaseSubjectDialogFunction.bind(this);
    this.getBtnActionFunction = this.getBtnActionFunction.bind(this);
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'owners');
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  async ngOnInit() {
    await this.loadCase();
    await this.loadSetting();
    this.prepareParcelColumns();
    await this.loadOccupations();
    await this.loadEasements();
    this.onUpdateOwnersList();
    this.onUpdateParcelsList();

    this.loadAttachments();
    this.loadNotes();
    this.loadHistory();
    this.loadNotifications();
    this.filterActionButtons();

    this.registerCallbacks();
    this.loading = false;
  }

  ngOnDestroy() {
    this.removeCallbacks();
  }


  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  void() {}

  cancel() {
    return this.dialogService.confirmDialogPromise('Opravdu chcete případ zrušit?')
      .then((data) => {
        if (data === true) {
          this.sending = true;
          this.restangular.one('cases', this.caseId).customPUT({}, 'cancel').toPromise().then(data => {
            delete data.constructionObjects;
            delete data.caseBuildings;
            delete data.titles;
            Object.assign(this.data, data.plain());
            this.loadHistory();
            this.loadSetting().then(this.filterActionButtons);
            this.sending = false;
          });
        }
      });
  }

  renew() {
    return this.dialogService.confirmDialogPromise('Opravdu chcete obnovit případ? Obnovením případu do stavu Založeno se smažou údaje o datumech předchozích stavů případu. Tyto informace zůstanou v záložce Historie.')
      .then((data) => {
        if (data === true) {
          this.sending = true;
          this.restangular.one('cases', this.caseId).customPUT({}, 'renew').toPromise().then(data => {
            delete data.constructionObjects;
            delete data.caseBuildings;
            delete data.titles;
            Object.assign(this.data, data.plain());
            this.loadHistory();
            this.loadSetting().then(this.filterActionButtons);
            this.sending = false;
          });
        }
      });
  }

  backStatus() {
    if (!this.sending) {
      return this.dialogService.confirmDialogPromise('Opravdu vrátit případ do předcházejícího stavu?')
        .then((data) => {
          if (data === true) {
            this.sending = true;
            this.restangular.one('cases', this.caseId)
              .customPUT({}, 'back', {loadCollections: ['caseSubjects']})
              .toPromise()
              .then((data) => {
                delete data.constructionObjects;
                delete data.titles;
                Object.assign(this.data, data.plain());
                this.sending = false;
                this.dataCaseOwnerships.forEach((item) => {
                  let bsmSubject;
                  if (item.ownership.isBsm) {
                    bsmSubject = this.data.caseSubjects.find((value) => value.subject.opsubId === item.ownership.opsubId).subject;
                  }
                  item.caseSubjects = this.data.caseSubjects.filter(
                    (value) => (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId))
                  );
                });
                this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
                this.loadHistory();
                this.loadSetting().then(this.filterActionButtons);
              },
              this.errorHandlerService.get
            );
          }

          this.sending = false;
        });
    }
  }

  isThreeParty() {
    return this.hasAdministrator() && this.hasNotInvestorOwner();
  }

  canEdit() {
    return this.authService.isAuthorized() || this.authService.hasPermission('assignable');
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onUpdateAttachmentsList() {
    this.reloadDisabledDocumentTypes();
    const attachmentsTab = this.tabs.find((tab) => tab.id === 'documents');
    attachmentsTab.name = `Dokumenty (${this.attachmentList.list.filter(a => !a.cancelled).length})`;
  }

  reloadDisabledDocumentTypes() {
    const uniqueDocumentTypes = ['SIGNED_CONTRACT_SCAN', 'EXPROPRIATION_DECISION', 'CONTRACT_ADDENDUM'];
    this.disabledDocumentTypes = [];

    this.restangular
      .all('attachments')
      .customPOST({ filter: { filters: { caseId: this.caseId, cancelled: false, attachmentType: uniqueDocumentTypes }}})
      .toPromise()
      .then((data => {
        for (const documentType of uniqueDocumentTypes) {
          if (data.filter(d => d.type === documentType).length > 0) {
            this.disabledDocumentTypes.push({ documentType: documentType, reason: 'Nelze vložit, již existuje' });
          }
        }
      }));
  }

  onUpdateOwnersList() {
    const ownersTab = this.tabs.find((tab) => tab.id === 'owners');
    ownersTab.name = `Vlastníci (${this.data.countOwnerships})`;
  }

  onUpdateParcelsList() {
    const parcelTab = this.tabs.find((tab) => tab.id === 'parcels');
    parcelTab.name = `Parcely (${this.data.caseParcels.length + this.data.caseBuildings.length})`;
  }

  onUpdateNotificationList() {
    const filter = {
      limit: 1,
      filters: {
        caseId: this.caseId,
        archive: false
      }
    };

    const archivedFilter = {...filter, filters: {...filter.filters, archive: true}};
    const notificationList = this.listService.createList('notifications', filter);
    const notificationListArchived = this.listService.createList('notifications', archivedFilter);

    this.listService.fetchResult(notificationList).then(data => {
      this.notificationCounts.unarchived = data.itemCount;
      this.updateNotificationTab();
    });

    this.listService.fetchResult(notificationListArchived).then(data => {
      this.notificationCounts.archived = data.itemCount;
      this.updateNotificationTab();
    });
  }

  updateParcelPriceDialogFunction(params: any) {
    const data = { caseId: this.caseId };
    Object.assign(data, params);

    const dialog = this.dialogService.open(FormCaseUpdateParcelPriceComponent, {
      data: data
    });

    const sub = dialog.afterClosed.subscribe((res) => {
      if (res) {
        this.caseParcelPriceUpdated(res);
      }
      sub.unsubscribe();
    });
  }

  updateCaseSubjectDialogFunction(params) {
    const dialog = this.dialogService.open(FormCaseUpdateSubjectComponent, {
      data: params
    });

    const sub = dialog.afterClosed.subscribe((res) => {
      if (res.caseSubject && res.caseOwnership) {
        this.caseSubjectUpdated(res);
      }
      sub.unsubscribe();
    });

  }

  private loadCase() {
    return this.restangular.one('cases', this.caseId).get({loadCollections: [
        'caseBuildings',
        'caseBuildings.parcelsWithEasementOrOccupation',
        'caseBuildings.parcels.parcelProtections',
        'caseBuildings.buildingProtections',
        'caseParcels',
        'caseOccupations',
        'caseEasements',
        'constructionObjects',
        'parcelsExternal',
        'caseSubjects',
        'caseSubjects.actingPersons',
        'caseOwnerships',
        'caseTransferErrors',
        'titles',
        'fromCase',
        'nextCase',
        'caseCommonData'
    ]}).toPromise().then(data => {
      this.data = data.plain();
      this.showEasementDistributionSystemParts = this.caseService.showEasementDistributionSystemParts(this.data.obligation);
      this.caseTransferErrorsData = this.data.caseTransferErrors;
      this.dataConstructionObjects = this.data.constructionObjects;
      this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
      this.dataCaseSubjects = this.data.caseSubjects.filter((caseSubject) => {
        return this.dataCaseOwnerships.some((caseOwnership) => {
          return caseOwnership.ownership.opsubId === caseSubject.subject.opsubId;
        });
      });
      this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
      this.notificationsReadonly = false;

      if (this.data.parcelsExternal && this.data.parcelsExternal.length) {
        CaseDetailComponent.PARCELS_EXTERNAL_TAB.name = `Parcely investora (${this.data.parcelsExternal.length})`;
        this.tabs.splice(3, 0, CaseDetailComponent.PARCELS_EXTERNAL_TAB);
      }
    });
  }

  private loadSetting() {
    return Promise.all([
      this.caseStatusService.loadStatuses(),
      this.restangular.one('cases', this.caseId).one('setting').get().toPromise()
    ]).then(data => {
      this.caseStatuses = data[0];
      this.nextStates = data[1].nextStates.items;
      this.documents = data[1].documents.items;
      this.allowCadastreEntryProposal = data[1].allowCadastreEntryProposal;
      this.caseStatusService.loadStatusName(data[1].previousState).then((caseStatus) => {
        this.previousState = caseStatus ? caseStatus.name : null;
      });
    });
  }

  private loadOccupations() {
    return this.restangular.all('occupations').customPOST({
      filter: {filters: {caseId: [this.caseId], loadCollections: ['parcel.knBudouc.currentParcels', 'constructionObjects', 'rentalExpert']}}
    }).toPromise().then((data) =>  {
      this.dataOccupations = data;
      this.dataOccupations.forEach((item) => {
        item.constructionObjects = item.constructionObjects.filter((co) => !item.constructionObjects.some(co2 => co2.id > co.id && co2.socis === co.socis && co2.sousek === co.sousek));
      });
      this.attachParcels();
    });
  }

  private loadEasements() {
    return this.restangular.all('easements').customPOST({
      filter: {filters: {caseId: [this.caseId]}},
      attributes: {constructionObjects: {
          load: true,
          attributes: {
            administrator: {load: true},
          }
        }}
    }).toPromise().then((data) => {
      this.dataEasements = data;
      this.easementsLoading = false;
      this.attachParcels();
    });
  }

  private loadAttachments() {
    this.attachmentList = this.listService.createList(
      'attachments',
      {limit: null, filters: {caseId: this.caseId, cancelled: false}, sortOrder: {sortBy: 'timestamp', direction: 'desc'}}
    );
    this.listService.fetchResult(this.attachmentList).then(() => {
      this.onUpdateAttachmentsList();
    });
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: {
          caseIdRelated: this.caseId,
          systemType: false,
          cancelled: false,
        },
        sortOrder: {
          sortBy: 'timestamp',
          direction: 'desc'
        },
      },
      undefined,
      {
        title: {
          load: true
        },
        subject: {
          load: true
        },
        parcel: {
          load: true
        }
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  loadHistory() {
    if (!this.historyList) {
      this.historyList = this.listService.createList(
      'notes',
      {filters: {caseId: this.caseId, systemType: true}, sortOrder: {sortBy: 'timestamp', direction: 'desc'}}
      );
    }
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadNotifications() {
    this.notificationList = this.listService.createList(
      'notifications',
      {
        filters: {
          caseId: this.caseId,
          loadCollections: ['knBudouc.futureParcel'],
          archive: false
        },
        sortOrder: {sortBy: 'createdTimestamp', direction: 'desc'}
      }
    );
    this.listService.fetchResult(this.notificationList).then(() => {
      this.onUpdateNotificationList();
    });
  }

  private updateNotificationTab() {
    const notificationTab = this.tabs.find((tab) => tab.id === 'notifications');
    if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
      notificationTab.name = `Upozornění (${this.notificationCounts.unarchived}/${this.notificationCounts.archived})`;
    } else {
      notificationTab.name = 'Upozornění';
    }
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [...CaseDetailComponent.OCCUPATION_PERMANENT_COLUMNS];
    let occupationUnderOneYearColumns = [...CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS];
    let occupationOverOneYearColumns = [...CaseDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS];
    let permanentWithSolutionTypeColumns = [ ...CaseDetailComponent.PERMANENT_WITH_SOLUTION_TYPE_COLUMNS ];
    let easementColumns = [...CaseDetailComponent.EASEMENT_COLUMNS];

    if (this.data.titles.length > 1) {
      const titleColumn = { id: 'occupation_title' , sortable: true };
      occupationPermanentColumns.push(titleColumn);
      occupationUnderOneYearColumns.push(titleColumn);
      occupationOverOneYearColumns.push(titleColumn);
      permanentWithSolutionTypeColumns.push(titleColumn);
      easementColumns.push({ id: 'title_name', sortable: true });

      const areaColumn = { id: 'occupation_area', sortable: true };
      occupationPermanentColumns.push(areaColumn);
      occupationUnderOneYearColumns.push(areaColumn);
      occupationOverOneYearColumns.push(areaColumn);
      permanentWithSolutionTypeColumns.push(areaColumn);
      easementColumns.push({ id: 'area_name', sortable: true });
    }

    if (!CaseDetailComponent.FORBIDER_PERMANENT.includes(this.data.obligation.type)) {
      occupationPermanentColumns = occupationPermanentColumns.concat(CaseDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
    }

    if (!CaseDetailComponent.FORBIDER_TEMPORARY.includes(this.data.obligation.type)) {
      occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
      occupationOverOneYearColumns = occupationOverOneYearColumns.concat(CaseDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
    }

    if (!this.authService.getActualProject().easementPriceByShare && !CaseDetailComponent.FORBIDER_EASEMENT.includes(this.data.obligation.type)) {
      easementColumns = easementColumns.concat(CaseDetailComponent.EASEMENT_PRICE_COLUMNS);
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.permanentWithSolutionTypeColumns = TableUtils.repairColumnsStructure(permanentWithSolutionTypeColumns);
    this.easementColumns = TableUtils.repairColumnsStructure(easementColumns);
  }

  private attachParcels() {
    if (this.easementsLoading || !this.dataOccupations || !this.data) {
      return;
    }
    this.dataOccupationGroups = {};

    this.dataOccupations.forEach(occupation => {
        const title = occupation.parcel ? occupation.parcel.title : null;
        occupation.caseParcel = _.find(this.data.caseParcels, (caseParcel: any) => {
          return caseParcel.parcel.idpar === occupation.zabidpar;
        });

        if (occupation.caseParcel) {
          occupation.caseParcel.parcel.titles = occupation.caseParcel.parcel.title || title;
        }

        occupation.caseOccupation = _.find(this.data.caseOccupations, (caseOccupation) => {
          return (
            caseOccupation.occupation.zabtyp === occupation.zabtyp
            && caseOccupation.occupation.zabst === occupation.zabst
            && caseOccupation.occupation.zabcis === occupation.zabcis
            && caseOccupation.occupation.zabku === occupation.zabku
            && caseOccupation.occupation.zabciskn === occupation.zabciskn
          );
        });
      }
    );
    this.occupationLoading = false;
    this.dataOccupationGroups = this.parcelService.groupOccupationsByParcel(
      this.dataOccupations,//_.filter(this.dataOccupations, (occupation) => { return !!occupation.caseParcel; }),
      null
    );
    _.forEach(this.dataOccupationGroups, (group) => {
      group.caseId = this.caseId;
    });
    this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.underOneYear);
    this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.overOneYear);

    this.dataEasements.forEach((easement: any) => {
        const title = easement.parcel ? easement.parcel.title : null;
        easement.caseParcel = _.find(this.data.caseParcels, (caseParcel: any) => {
          return caseParcel.parcel.idpar === easement.idpar;
        });

        if (!easement.caseParcel) {
          return;
        }
        easement.caseEasement = this.data.caseEasements.find((caseEasement) => caseEasement.easement.id === easement.id);
        easement.caseParcel.parcel.titles = easement.caseParcel.parcel.title || title;
      }
    );
  }

  private filterActionButtons() {
    this.statusActions = _.map(_.filter(this.caseService.getActionButtons(), (action) => {
      return _.includes(this.nextStates, action.caseStatus);
    }), (action) => {
      return {
        key: action.key,
        click: action.click ? this.getBtnActionFunction(action.click) : undefined,
        icon: action.icon || 'fa-plus-square',
        name: action.name ? action.name : _.find(this.caseStatuses, {key: action.caseStatus}).name
      };
    });

    this.actionButtons = _.map(_.filter(this.caseService.getGenerateButtons(this.data.obligation.type), (action) => {
      return _.some(action.templates, (template) => _.includes(this.documents, template));
    }), (action: any) => {
      return {
        key: action.key,
        click: action.click ? this.getBtnActionFunction(action.click) : undefined,
        icon: action.icon || 'fa-files-o',
        name: action.name ? action.name : _.find(this.caseStatuses, {key: action.caseStatus}).name
      };
    });//.concat(this.actionButtons);
  }

  private getBtnActionFunction(action: string): Function {
    switch (action) {
      case 'cancel':
        return () => { this.cancel(); };
      case 'renew':
        return () => { this.renew(); };
    }
  }

  private hasNotInvestorOwner() {
    let result = false;
    _.forEach(this.data.caseOwnerships, (caseOwnership) => {
      if (!caseOwnership.isParent &&
        (!this.authService.getActualProject().investor || caseOwnership.ownership.opsubId !== this.authService.getActualProject().investor.opsubId)
      ) {
        result = true;
      }
    });
    return result;
  }

  private hasAdministrator() {
    return (this.data &&
        (
          this.data.obligation.type === 'RealBurdenLiability' ||
          this.data.obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' ||
          this.data.obligation.type === 'RealBurdenLiabilityAccelerated' ||
          this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated'
        )
      ) &&
      // @ts-ignore
      this.data.constructionObjects[0].administratorType === 'A';
  }

  // Callbacks

  private registerCallbacks() {
    this.callbacks.add(Callbacks.CaseUpdated, this.caseUpdated);
  }
  private removeCallbacks() {
    this.callbacks.remove(Callbacks.CaseUpdated, this.caseUpdated);
  }

  private caseUpdated(data) {
    if (data !== undefined) {
      delete data.constructionObjects;
      delete data.caseBuildings;
      delete data.titles;

      // delete previous contract register related values not to duplicate them on frontend
      delete this.data.contractRegisterPublicationContractId;
      delete this.data.contractRegisterPublicationDate;
      delete this.data.contractRegisterPublicationException;
      delete this.data.contractRegisterPublicationNecessary;
      delete this.data.contractRegisterPublicationVersionId;
      delete this.data.price;

      Object.assign(this.data, data);

      if (data.caseOwnerships.length && data.caseSubjects.length) {
        this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(data.caseOwnerships, this.data.caseSubjects);
        this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
      }
      this.loadHistory();
      this.loadSetting().then(this.filterActionButtons);
    }
  }

  private caseSubjectUpdated(data) {
    if (data !== undefined) {
      this.updateCaseSubject(data.caseSubject);
      this.updateCaseOwnership(data.caseOwnership);
      this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
      this.loadHistory();
    }
  }

  private caseParcelPriceUpdated(data) {
    if (data !== undefined) {
      this.occupationLoading = true;
      this.easementsLoading = true;
      this.restangular.one('cases', this.caseId).get({loadCollections: ['caseParcels', 'caseOccupations', 'caseEasements', 'caseOwnerships']}).toPromise().then((data) => {
        this.data.caseParcels = data.caseParcels;
        this.data.caseOccupations = data.caseOccupations;
        this.data.caseEasements = data.caseEasements;
        this.data.caseOwnerships = data.caseOwnerships;
        this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(data.caseOwnerships, this.data.caseSubjects);
        this.occupationLoading = false;
        this.easementsLoading = false;
        this.attachParcels();
      });
      this.loadHistory();
    }
  }

  private updateCaseSubject(caseSubject) {
    const found: any = _.find(this.data.caseSubjects, { id: caseSubject.id });
    if (found) {
      const responseReceivedDate = found.responseReceivedDate;
      for (const attrname in caseSubject) {
        // when sent to SJM subjects, then both date is changed at once
        if (((attrname === 'sentDate' || attrname === 'receivedDate') && found.signedDate === null) || attrname === 'responseReceivedDate') {
          this.data.caseSubjects.forEach((cs) => {
            if (cs[attrname] !== null && (cs['signedDate'] === null ||
              (attrname === 'responseReceivedDate' &&
                cs['responseReceivedDate'] === responseReceivedDate &&
                found['sentDate'] === cs['sentDate'] &&
                found['receivedDate'] === cs['receivedDate']))) {
              cs[attrname] = caseSubject[attrname];
            }
          });
        } else {
          found[attrname] = caseSubject[attrname];
        }
      }
    }
  }

  private updateCaseOwnership(caseOwnership) {
    if (caseOwnership !== undefined) {
      const found = _.find(this.dataCaseOwnerships, { id: caseOwnership.id });
      if (found) {
        for (const attrname in caseOwnership) {
          found[attrname] = caseOwnership[attrname];
        }
      }
    }
  }

  public hideColumn(columnId: string) {
    return this.settingsService.shouldHideColumn('cases', columnId);
  }
}
