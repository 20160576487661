import { Component, OnInit, Inject } from '@angular/core';
import {StateService, TargetState, Transition} from '@uirouter/angular';
import { AuthService } from '@app/common/services/auth.service';
import { MenuConfig } from '@app/models/menuConfig';
import { User } from '@app/models/user';
import { ApproveTermsService } from '@app/common/services/approve-terms.service';
import { ChangePasswordComponent } from '@app/common/components/change-password/change-password.component';
import { DialogService } from '@app/common/services/dialog.service';
import { GoogleAnalyticsService } from '@app/common/services/google-analytics.service';
import { APP_BRAND, APP_CONFIG, APPLICATIONS } from '@app/common/services/config.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { updateBody } from '@app/common/utils/state.utils';

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  loading = true;
  collapsed = false;
  user: User;
  servicedeskUrl: string = undefined;
  menuConfig: MenuConfig[] = [];
  ENV = this.config.ENV;

  constructor(
    @Inject(APPLICATIONS) private applications: any,
    private localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private config,
    @Inject(APP_BRAND) public APP_BRAND,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dialogService: DialogService,
    private approveTermsService: ApproveTermsService,
    private authService: AuthService,
    @Inject('$transition$') private transition: Transition,
    private stateService: StateService,
  ) {
    this.checkPasswordChange = this.checkPasswordChange.bind(this);
  }

  async ngOnInit() {
    await this.authService.checkProjectStatus(this.transition, this.applications.vfze.name, null, this.authService.loadVfzeProjects, 'error')
      .then(
        () => {
          this.loading = false;
        }, (newState: TargetState) => {
          this.stateService.go(newState.name(), newState.params(), newState.options());
        }
      );

    this.authService.setActiveApplication(this.applications.vfze.name);
    this.authService.updateBaseUrl();
    this.authService.updateAuthorizationHeader();
    this.googleAnalyticsService.onUserCome();
    updateBody('vfze');

    this.collapsed = this.localStorageService.get('menuOpened');
    this.user = this.authService.getUser();
    this.servicedeskUrl = this.authService.getServiceDeskUrl();
    this.menuConfig = this.getMenuConfig();
    this.approveTermsService.checkApprovedTerms(this.user.id, this.applications.vfze.name).then(this.checkPasswordChange);
  }

  checkPasswordChange() {
    if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
      this.dialogService.open(ChangePasswordComponent, undefined);
    }
  }

  onCollapse() {
    this.collapsed = !this.collapsed;
    this.localStorageService.set('menuOpened', this.collapsed);
  }

  logout() {
    this.authService.logout();
  }

  private getMenuConfig(): MenuConfig[] {
    const menu = [
        new MenuConfig('Validátor', 'vfze.validation', 'fa-check'),
        new MenuConfig('Náhled', 'vfze.map', 'fa fa-map', undefined, undefined, undefined, '_blank'),
        new MenuConfig('Generování', 'vfze.generate', 'fa-cloud-download'),
        new MenuConfig('Import', 'vfze.import', 'fa fa-cloud-upload'),
    ];

    if (this.APP_BRAND.NAME === 'GMT') {
      menu.push(new MenuConfig('Nastavení', 'vfze.settings', 'fa fa-wrench'));
    }

    if (!this.authService.hasPermission('generate_vfze')) {
      const gItem = menu.find(i => i.title === 'Generování');
      menu.splice(menu.indexOf(gItem), 1);
      const iItem = menu.find(i => i.title === 'Import');
      menu.splice(menu.indexOf(iItem), 1);
    }

    return menu;
  }
}
