﻿import { Component, Inject, Input, OnDestroy } from "@angular/core";
import { AuthService } from '../../../common/services/auth.service';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { CallbackModel } from '@app/common/models/callback.model';
import { CaseService } from '@app/ps/services/case.service';
import { Restangular } from 'ngx-restangular';
import {DocumentModel} from "@app/common/models/document.model";

@Component({
  templateUrl: './cases-generate-documents-confirm.component.html',
})
export class CasesGenerateDocumentsConfirmComponent implements OnDestroy {
  @Input()
  data: any;
  callbacks: CallbackModel;
  processed: number = 0;
  saving: boolean = false;
  interrupt: boolean = false;

  constructor(
    private authService: AuthService,
    private restangular: Restangular,
    private caseService: CaseService,
  ) {
    this.proceed = this.proceed.bind(this);
  }

  ngOnDestroy() {
    this.interrupt = true;
  }

  async proceed() {
    this.saving = true;
    const attachmentIds: number[] = [];
    const attachmentsWithoutGeneratedMap: string[] = [];
    const cases = this.data.details.group.cases;
    for (const [i, item] of cases.entries()) {
      if (this.interrupt) {
        return false;
      }

      (await this.generate(item)).forEach((attachment: DocumentModel) => {
        attachmentIds.push(attachment.id);
        if (attachment.mapSuccessfullyGenerated === false) {
          attachmentsWithoutGeneratedMap.push(attachment.name);
        }
      });
      this.processed++;
    }
    this.notifyParent(attachmentIds, attachmentsWithoutGeneratedMap);
  }

  async generate(caseData: any) {
    if (this.data.details.group.allowOwner) {
      caseData = await this.getCase(caseData.id);
    } else {
      caseData.dataRecipients = [null];
    }
    const generateDocuments = [];
    caseData.dataRecipients.forEach(recipient => {
      generateDocuments.push({
        name: this.getDocumentName(caseData, recipient),
        recipientOpsubId: recipient ? recipient.id : null,
        id: this.data.details.group.template.id,
      });
    });
    return this.restangular
      .one('cases', caseData.id)
      .all('generate')
      .customPOST(generateDocuments)
      .toPromise();
  }

  async getCase(caseId: number) {
    return await this.restangular
      .one('cases', caseId).get({
        loadCollections: [
          'caseSubjects',
          'caseOwnerships',
          'titles',
        ],
      }).toPromise().then(bCase => {
        const dataCaseOwnerships = this.caseService.getDataCaseOwnerships(bCase.caseOwnerships, bCase.caseSubjects);
        bCase.dataRecipients = this.caseService.getRecipients(dataCaseOwnerships, true);
        return bCase;
      });
  }

  getDocumentName(caseData: any, recipient: any): string {
    let name = '';
    for (const section of this.data.details.filename) {
      if (section.show) {
        switch (section.type) {
          case 'text':
            name += section.text;
            break;
          case 'caseNumber':
            name += caseData.mpp.number;
            break;
          case 'titleNumber':
            name += caseData.titles.map(title => title.lv).join(',');
            break;
          case 'owner':
            name += recipient.name;
            break;
        }
      }
    }

    return name;
  }

  notifyParent(attachmentIds: number[], attachmentsWithoutGeneratedMap: string[]) {
     this.callbacks.get(Callbacks.DataValidityChanged)({
       isValid: () => false,
       data: {
         attachmentIds: attachmentIds,
         attachmentsWithoutGeneratedMap: attachmentsWithoutGeneratedMap
       },
     });
   }
}
