<div class="list">
  <div>
    <a uiSref="symap.project.projectSettings" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
  </div>

  <h1>Vzory dokumentů</h1>

  <div [loading]="(!list || list.loading) && isOrganizationalUnitSet === true">
    <div class="table-top-container" *ngIf="list">
      <div class="invisible cm-select-placeholder"></div>
      <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>

    <div class="bordered" *ngIf="list && list.itemCount">
      <div class="table">
        <div class="head-group">
          <div class="row">
            <span class="cell">
              Dostupnost
            </span>

            <span class="cell">
              <sort-by
                [selected]="'documentType' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('documentType', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Typ dokumentu</sort-by>
            </span>

            <span class="cell">
              Typ případu
            </span>

            <span class="cell">
              Platnost
            </span>

            <span class="cell">
              <sort-by
                [selected]="'number' === list.filter.sortOrder.sortBy"
                (sort)="pageableList.onSort('number', $event)"
                [sortDir]="list.filter.sortOrder.direction"
              >Označení</sort-by>
            </span>

            <span class="cell">
              Účel
            </span>

            <span class="cell">
              Účinnost od
            </span>

            <span class="cell">
              Vzor
            </span>
          </div>
        </div>
        <div class="row-group">
          <span class="row" *ngFor="let sample of list.list">
            <span class="cell">
              <div class="small-spinner" [loading]="sample.disabled === null"></div>
              <gmt-checkbox gmtAuth="manage_templates" [gmtChecked]="!sample.disabled && sample.status !== 'WAITING_FOR_ACTUALIZATION'" (click)="onEnableToggle(sample);" [gmtDisabled]="sample.status === 'WAITING_FOR_ACTUALIZATION'" *ngIf="sample.disabled !== null"></gmt-checkbox>
            </span>

            <span class="cell">
              {{ sample.documentType.name }}
            </span>

            <span class="cell">
              <td class="comma-list"><span *ngFor="let obligation of sample.documentType.obligations">{{obligation.name}}</span></td>
            </span>

            <span class="cell">
              {{ sample.status | sampleStatusName }}
              <span *ngIf="getActualizationTicket(sample)" class="tag tag--danger tag--not-clickable">A</span>
            </span>

            <span class="cell">
              <a *ngIf="hasUserAccessToSampleApp" uiSref="sv.samples.detail" [uiParams]="{ id: sample.id }">{{ sample.number }}</a>
            </span>

            <span class="cell">
              {{ sample.description }}
            </span>

            <span class="cell">
              {{ sample.createdDate | date }}
            </span>

            <span class="cell">
              <a (click)="downloadSampleFile(sample); $event.stopPropagation(); $event.preventDefault();">
                <i class="fa fa-download"></i>
              </a>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="(list && !list.itemCount && list.list !== null && !list.loading) || isOrganizationalUnitSet === false">
      <span>Nebyly nalezeny žádné vzory.</span>
    </div>
  </div>

  <itemscounter
    *ngIf="list && list.itemCount && list.list !== null" [list]="list"
    (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
    style="max-width: 100px;"
  ></itemscounter>

  <pagination
    *ngIf="list && list.itemCount && list.list !== null"
    [list]="list"
    (callbackRegister)="pageableList.paginationCallbackRegister($event)"
  ></pagination>
</div>

