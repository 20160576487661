<div class="mb-20 bordered">
  <table>
    <thead>
    <tr>
      <th *ngIf="visibleColumns.parcel"><span>Parcela</span></th>
      <th *ngIf="visibleColumns.parcel_title"><span>LV parcely</span></th>
      <th *ngIf="visibleColumns.building_title"><span>LV budovy</span></th>
      <th *ngIf="visibleColumns.cast_obce"><span>Část obce</span></th>
      <th *ngIf="visibleColumns.house_number"><span>Číslo budovy</span></th>
      <th *ngIf="visibleColumns.usage"><span>Typ a Využití</span></th>
      <th *ngIf="visibleColumns.protection"><span>Ochrana</span></th>
      <th *ngIf="visibleColumns.cases"><span>Případy</span></th>
      <th *ngIf="visibleColumns.price"><span>Cena [<span class="unit">Kč</span>]</span></th>
    </tr>
    </thead>
    <tbody *ngFor="let building of data">
    <tr *ngFor="let parcel of building.parcels; index as i">
      <td *ngIf="visibleColumns.parcel" class="no-padding cell-max-height">
        <cell-parcel
          [parcel]="parcel"
          [tooltipType]="'parcel'"
          [tooltipData]="{ parcel: parcel, withoutTitle: true }"
        ></cell-parcel>
      </td>

      <td *ngIf="visibleColumns.parcel_title" class="number">
        <span *ngIf="!parcel.title">{{ parcel.lv }}</span>
        <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }">
          {{ parcel.title.lv }}
        </a>
      </td>

      <td *ngIf="i === 0 && visibleColumns.building_title" [attr.rowspan]="building.parcels.length" class="number">
        <span *ngIf="!building.title">{{ building.cisloTel }}</span>
        <a *ngIf="building.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: building.title.id, tab: null }">
          {{building.title.lv}}
        </a>
      </td>

      <td *ngIf="i === 0 && visibleColumns.cast_obce" [attr.rowspan]="building.parcels.length">
        {{ building.castobce }}
      </td>

      <td *ngIf="i === 0 && visibleColumns.house_number" [attr.rowspan]="building.parcels.length">
        {{ building | buildingNumber:true }}
      </td>

      <td *ngIf="i === 0 && visibleColumns.usage" [attr.rowspan]="building.parcels.length">
        {{ building.typ }}
        <br>
        {{ building.vyuziti }}
      </td>

      <td *ngIf="i === 0 && visibleColumns.protection" [attr.rowspan]="building.parcels.length">
          <span class="comma-list">
            <span *ngFor="let itemProtection of building.buildingProtections">
              {{ itemProtection.nazevOchrany }}
            </span>
          </span>
      </td>

      <td *ngIf="i === 0 && visibleColumns.cases" [attr.rowspan]="building.parcels.length" class="no-padding cell-max-height">
        <cell-cases
          [casesCount]="building.countCases"
          [tooltipType]="'cases'"
          [tooltipData]="{ filter: { filters: { buildingId: building.id, cancelStatus: 'notCancelled' }}}"
        ></cell-cases>
      </td>

      <td *ngIf="i === 0 && visibleColumns.price" [attr.rowspan]="building.parcels.length">
        {{ building.buildingsPrice | number | price }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
