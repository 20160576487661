<div *ngIf="map" class="map-print-panel" [ngClass]="{ 'map-print-panel--opened': open }">

  <h2 style="padding-right: 40px;">{{ printData.title }}</h2>

  <a class="button toggle-button" (click)="open = !open"><i class="fa" [ngClass]="{ 'fa-angle-double-up': open, 'fa-angle-double-down': !open }"></i></a>

  <div *ngIf="open">
    <fieldset  class="p-10 no-legend">
      <div class="column-100">
        <gmt-input label="Uložené nastavení tisku" class="mt-10">
          <gmt-select
            [optionItems]="printTypes"
            [data]="this"
            field="printType"
            (changed)="onPrintTypeChange($event.newValue)"
            [reloadOptionsRegister]="setPrintReloadOptionRegister"
            selectTitle="Vyberte"
          ></gmt-select>
          <a *ngIf="printType && printType.custom" (click)="onDelete(printType)" title="Odstranit" class="ml-4 button red"><i class="fa fa-remove"></i></a>
        </gmt-input>

        <gmt-input label="Formát" class="mt-10" [required]="true">
          <gmt-select
            [optionItems]="formatTypes"
            [data]="this"
            field="formatType"
            (changed)="onFormatTypeChange($event.newValue)"
            [reloadOptionsRegister]="setFormatReloadOptionRegister"
            selectTitle="Vyberte"
          ></gmt-select>
        </gmt-input>

        <gmt-input label="Měřítko" class="mt-10" [required]="true">
          <gmt-select
            [optionItems]="measureTypes"
            [data]="this"
            field="measureType"
            (changed)="onMeasureTypeChange($event.newValue, $event.oldValue)"
            [reloadOptionsRegister]="setMeasureReloadOptionRegister"
            selectTitle="Vyberte"
          ></gmt-select>
        </gmt-input>


        <gmt-input *ngIf="measureType && measureType.id === 'custom'" label="Měřítko">
          1: <input type="text" [(ngModel)]="measureType.denominator" (ngModelChange)="recalculateRect()" gmtInputNumber [maxNumber]="MAX_DENOMINATOR" style="width: 147px">
          <a *ngIf="formatType && measureType && measureType.id === 'custom' && editing === false" (click)="onEditingModeStart()" title="Editovat v mapě" class="ml-4 button"><i class="fa fa-arrows-h"></i></a>
          <a *ngIf="formatType && measureType && measureType.id === 'custom' && editing === true" (click)="onEditingModeStop()" title="Ukončit editaci v mapě" class="ml-4 button active"><i class="fa fa-arrows-h"></i></a>
        </gmt-input>
      </div>
    </fieldset>

    <div class="error-message text-center">
      {{ printData.validationText(formatType, measureType, entity) }}
    </div>

    <div class="flexbox-container flex-centered">
      <gmt-button [clickAction]="onPrint" [gmtDisabled]="!printData.validationButton(formatType, measureType, entity)">Další</gmt-button>
    </div>
  </div>
</div>
