<fieldset>
  <div class="column-100">
      <gmt-input label="Datum mezitimního rozhodnutí" required="true">
        <input type="text" pick-a-date [(ngModel)]="data.expropriationInterimDecisionDate" [maxToday]="true"/>
      </gmt-input>

      <gmt-input label="Datum lhůty pro zahájení uskutečňování účelu" required="true">
        <input type="text" pick-a-date [(ngModel)]="data.expropriationStartDeadlineDate"/>
      </gmt-input>
  </div>
</fieldset>
