import { Component, Inject, OnInit } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { DialogService } from '@app/common/services/dialog.service';
import { HelpService } from '@app/common/services/help.service';

export interface VfzeImportStep {
  id: string;
  name: string;
  enabled: boolean;
  help: string;
}

@Component({
  selector: 'vfze-import',
  templateUrl: './vfze-import.component.html',
  styleUrls: []
})
export class VfzeImportComponent implements OnInit {

  private deregisterLeaveListener: Function;
  private stepsHistory: string[] = [];
  activeStep: VfzeImportStep;
  data: any = {};

  steps: VfzeImportStep[] = [
    { id: 'upload', name: 'Nahrání dat', enabled: false, help: '' },
    { id: 'validation', name: 'Validace VFZE', enabled: false, help: '' },
    { id: 'save', name: 'Uložení VFZE', enabled: false, help: '' },
/*  { id: 'cadastre', name: 'Katastrální data', enabled: false, help: '' },
    { id: 'select', name: 'Data k importu', enabled: false, help: '' },
    { id: 'import', name: 'Import dat', enabled: false, help: '' }, */
  ];
  helpIds = HelpService.HELP_IDS;

  constructor(
    private transitionService: TransitionService,
    private stateService: StateService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.onStep('upload', true);
    this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
  }

  private beforeExit(transition) {
    if (transition && transition.to() === transition.from()) {
      return;
    }
    return this.dialogService.confirmDialogPromise('Opravdu chcete odejít? Všechna zadaná data budou ztracena.')
      .then((data) => {
        if (data === true) {
          this.deregisterLeaveListener();
        } else {
          return Promise.resolve(false);
        }
      });
  }

  onStep(id: string, enable = false) {
    const step = this.steps.find(s => s.id === id);

    if (step.enabled === false && enable === false) {
      return;
    }

    // step back
    if (this.stepsHistory.includes(id)) {
      this.activeStep = step;
      this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
      this.steps.forEach(v => v.enabled = this.stepsHistory.includes(v.id));

      // step forward
    } else {
      this.activeStep = step;
      this.stepsHistory.push(id);
      step.enabled = true;

      if (step.id === 'import') {
        this.steps.filter(step => step.id !== 'import').forEach((step) => {
          step.enabled = false;
        });
      }
    }
  }

  onFinish() {
    this.deregisterLeaveListener();
    this.stateService.go('symap.project.vfze');
  }
}
