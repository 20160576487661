<div class="detail">
  <div>
    <a uiSref="sv.samples" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Přehled vzorů</a>
  </div>

  <div [loading]="loading"></div>

  <ng-container *ngIf="!loading">
    <h1>{{ sample ? 'Požadavek na aktualizaci vzoru' : ('Požadavek na zavedení nového vzoru do ' + APP_BRAND.PS.TITLE)  }}</h1>

    <div class="row mt-30">
      <div class="col-12 offset-lg-3 col-lg-6">
        <p *ngIf="!sample" class="jumbotron">
          Požadavek na aktualizaci stávajícího vzoru zadejte přes ikonu Aktualizovat v přehledu vzorů, nebo ve složce vzoru. Touto úlohou se zadává požadavek na zavedení vzoru, který v {{ APP_BRAND.PS.TITLE }} není.
        </p>

        <fieldset *ngIf="!sample">
          <div class="column-100">
            <label class="required">Typ dokumentu</label>
            <gmt-select
              [data]="ticket"
              required="true"
              field="documentType"
              [optionItems]="documentTypes"
              [itemPipe]="documentTypeFullnamePipe"
              (changed)="checkExistingSample()"
            ></gmt-select>
          </div>

          <div class="column-100">
            <label class="required">{{ 'sv.templatesOrganizationalUnit' | brandTranslate }}</label>
            <gmt-select
              required="true"
              resource="organizational-units"
              [restangularService]="configRestangular"
              [itemPipe]="organizationalUnitNamePipe"
              [filter]="organizationalUnitFilter"
              (changed)="onOrganizationalUnitSelected($event); checkExistingSample()"
            ></gmt-select>
          </div>
        </fieldset>

        <fieldset *ngIf="APP_BRAND.NAME === 'SZ' && !sample">
          <legend class="required">Typ vzoru</legend>
          <div class="mb-5">Typ "JINÝ VZOR" zvolte pouze v případě, kdy nemá vzor sloužit ke generování dokumentů v {{ APP_BRAND.PS.TITLE }}.</div>
          <gmt-switch [options]="assignableOptions" selectedId="y" (select)="onChangeAssignable($event.id)"></gmt-switch>
        </fieldset>

        <div *ngIf="existingSample" class="mt-20 text-center bordered error-message p-20">
          Již existuje vzor, který je shodného typu. Ověřte, zda je opravdu nutné zakládat nový vzor a není vhodnější aktualizovat existující vzor. Pokud je nutný nový vzor, pokračujte dále v založení požadavku.
        </div>

        <fieldset>
          <legend class="required">Poznámka / důvody {{ sample ? 'aktualizace' : 'zavedení nového vzoru' }}</legend>
          <div class="column-100 mt-10">
            <textarea [(ngModel)]="ticket.description"></textarea>
          </div>
        </fieldset>

        <fieldset visibleByBrand="maja">
          <legend class="required">Vzor s vyznačenými úpravami (revize, zvýraznění změn, komentáře)</legend>
          <fileupload class="blue" (change)="onRevisionSampleFilesChange($event)" [message]="sampleFilesMsg" inputId="revisionFileHidden"></fileupload>
        </fieldset>

        <fieldset>
          <legend visibleByBrand="mpv" class="required">Vzor</legend>
          <legend visibleByBrand="maja" class="required">Originál nového vzoru (bude k dispozici jako vzor ke stažení)</legend>
          <fileupload class="blue" (change)="onSampleFilesChange($event)" [message]="sampleFilesMsg" inputId="fileHidden"></fileupload>
        </fieldset>

        <div class="mt-20 text-center">
          <gmt-button [gmtDisabled]="!isValid()" [clickAction]="onSubmit">Založit požadavek</gmt-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
