import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { Callbacks } from '@app/ps/enums/callbacks.enum';
import { CallbackModel } from '@app/common/models/callback.model';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'tab-owner-representation',
  styleUrls: ['./representation.component.scss'],
  templateUrl: './representation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepresentationComponent implements OnInit {

  @Input() representatives: ActingPersonModel[];
  @Input() label = 'Jednající/zastupující osoby';
  private API_URL: string;

  constructor(
    stateService: StateService,
    @Inject('callbacks') private callbacks: CallbackModel,
    private restangular: Restangular,
  ) {
    this.API_URL = `subjects/${stateService.params.ownerId}/acting-persons`;
  }

  ngOnInit() {
    if (this.representatives.length === 0) {
      this.createAndAddRepresentative();
    }

    this.addRepresentative = this.addRepresentative.bind(this);
    this.removeRepresentative = this.removeRepresentative.bind(this);
    this.createAndAddRepresentative = this.createAndAddRepresentative.bind(this);
    this.removeRoleCadastreForAllExcept = this.removeRoleCadastreForAllExcept.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  createAndAddRepresentative() {
    this.representatives.push(new ActingPersonModel());
  }

  addRepresentative(representative: ActingPersonModel) {
    this.representatives.push(representative);
  }

  removeRoleCadastreForAllExcept(representative: ActingPersonModel) {
    this.representatives = this.representatives
      .map((r: ActingPersonModel) => {
        if (representative !== r && representative.roleCadastre) {
          r.roleCadastre = false;
        }
        return {...r};
      });
  }

  removeRepresentative(representative: ActingPersonModel) {
    return () => {
      const idx = this.representatives.indexOf(representative);
      if (idx !== -1) {
        this.representatives.splice(idx, 1);
      }

      if (this.representatives.length === 0) { // don't let the form disappear
        this.createAndAddRepresentative();
      }
    };
  }

  shouldShowDeleteButton(): boolean {
    return <boolean>(this.representatives.length > 1 || (this.representatives.length === 1 && this.representatives[0].id));
  }

  async onSubmit(): Promise<ActingPersonModel[]> {
    const validRepresentatives: ActingPersonModel[] = this.representatives.filter(ActingPersonModel.hasAtLeastOneDefinedProperty);
    validRepresentatives.forEach((r: ActingPersonModel) => r.cancelled = false);
    const flattenRepresentatives = validRepresentatives.map(ActingPersonModel.toFlatObject);

    const response = await this.restangular.one(this.API_URL).customPUT(flattenRepresentatives).toPromise();
    const representatives = response.plain().map(ActingPersonModel.fromFlatObject);
    this.callbacks.get(Callbacks.RepresentativesUpdated)(representatives);
    return response;
  }

  hasValidForm(): boolean {
    return this.representatives
      .filter(ActingPersonModel.hasAtLeastOneDefinedProperty)
      .every((r: ActingPersonModel) => {
        return <boolean>(
          r.firstName
          && r.surname
          && (r.companyName ? r.identificationNumber : true)
        );
      });
    // const exactlyOne: boolean = this.representatives.length === 1;
    // return allFilled || exactlyOne;
  }
}
