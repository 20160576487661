export enum RequestStatusEnum {
  RECEIVED = "RECEIVED",
  VALIDATED = "VALIDATED",
  IMPORTED = "IMPORTED",
  WAITING_FOR_PRICE_CONFIRMATION = "WAITING_FOR_PRICE_CONFIRMATION",
  PRICE_CONFIRMED = "PRICE_CONFIRMED",
  CUZK_DATA_WAITING = "CUZK_DATA_WAITING",
  CUZK_DATA_RECEIVED = "CUZK_DATA_RECEIVED",
  INTERSECTION = "INTERSECTION",
  FINISHED = "FINISHED",
  FINISHED_WITH_ANALOG = "FINISHED_WITH_ANALOG",
  FAILED = "FAILED",
  ARCHIVED = "ARCHIVED"
}

export const requestStatusOptions = [
  { id: RequestStatusEnum.RECEIVED, name: 'Přijato' },
  { id: RequestStatusEnum.VALIDATED, name: 'Validováno' },
  { id: RequestStatusEnum.IMPORTED, name: 'Importováno' },
  { id: RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION, name: 'Čeká na potvrzení ceny' },
  { id: RequestStatusEnum.PRICE_CONFIRMED, name: 'Cena potvrzena' },
  { id: RequestStatusEnum.CUZK_DATA_WAITING, name: 'Čeká na data z KN' },
  { id: RequestStatusEnum.CUZK_DATA_RECEIVED, name: 'Obdržena data z KN' },
  { id: RequestStatusEnum.INTERSECTION, name: 'Protínání' },
  { id: RequestStatusEnum.FINISHED, name: 'Dokončeno' },
  { id: RequestStatusEnum.FINISHED_WITH_ANALOG, name: 'Dokončeno s analogovými parcelami' },
  { id: RequestStatusEnum.FAILED, name: 'Chyba' },
  { id: RequestStatusEnum.ARCHIVED, name: 'Archivováno' },
];
