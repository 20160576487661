import { Component, Inject, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TicketService } from '@app/sv/services/ticket.service';
import { TicketModel } from '@app/sv/models/ticket.model';
import { UploadFileExtended } from '@app/common/components/fileupload/fileupload.component';
import { SampleModel } from '@app/sv/models/sample.model';
import { SampleService } from '@app/sv/services/sample.service';
import { DocumentTypeModel } from '@app/common/models/document-type.model';
import { SampleStatusEnum } from '@app/sv/enums/sample-status.enum';
import { AuthService } from '@app/common/services/auth.service';
import {DocumentTypeFullnamePipe} from "@app/common/pipes/document-type-fullname.pipe";
import { OrganizationalUnitNamePipe } from '@app/common/pipes/organizational-unit-name.pipe';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';
import { Restangular } from 'ngx-restangular';
import { APP_BRAND } from "@app/common/services/config.service";
import {BrandTranslatePipe} from "@app/common/pipes/brand-translate.pipe";

@Component({
  selector: 'ticket-create',
  templateUrl: './ticket-create.component.html',
  styleUrls: ['./ticket-create.component.scss']
})
export class TicketCreateComponent implements OnInit {

  static readonly MSG_FILES_DEFAULT = 'Vložte vzor přetažením, nebo jej vyberte z počítače. Povolen je jeden dokument typu doc, docx.';
  static readonly MSG_FILES_EXTENSION = 'Soubor musí být ve formátu doc, docx.';
  static readonly MSG_FILES_QUANTITY  = 'Je možné vložit pouze jeden soubor.';

  @Input() sampleId;
  loading = true;
  sample: SampleModel;
  ticket: TicketModel = {};
  documentTypes: DocumentTypeModel[];
  existingSample = false;
  organizationalUnitFilter;
  assignableOptions = [
    { id: 'y', value: `Nový vzor pro generování dokumentů v ${this.APP_BRAND.PS.TITLE}` },
    { id: 'n', value: 'Jiný vzor' },
  ];

  sampleFiles: UploadFileExtended[];
  sampleFilesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
  sampleFilesValid = false;

  revisionSampleFiles: UploadFileExtended[];
  revisionSampleFilesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
  revisionSampleFilesValid = false;

  constructor(
    @Inject(APP_BRAND) public APP_BRAND,
    private restangular: Restangular,
    @Inject(RESTANGULAR_CONFIGURATION) public configRestangular: any,
    public ticketService: TicketService,
    public documentTypeFullnamePipe: DocumentTypeFullnamePipe,
    public organizationalUnitNamePipe: OrganizationalUnitNamePipe,
    private stateService: StateService,
    private sampleService: SampleService,
    private authService: AuthService,
    private brandTranslatePipe: BrandTranslatePipe,
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.onOrganizationalUnitSelected = this.onOrganizationalUnitSelected.bind(this);
    this.onChangeAssignable = this.onChangeAssignable.bind(this);
  }

  async ngOnInit() {
    await this.loadSample();
    await this.loadDocumentTypes();
    this.checkExistingSample();

    this.organizationalUnitFilter = {
      filters: {
        cancelled: [ false ],
        code: this.authService.getProjectsByPermission('template_new')
      }
    };
    this.loading = false;
  }

  onSampleFilesChange(files: UploadFileExtended[]) {
    this.sampleFiles = files;

    const extensionValid = files.every(f => ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()));

    if (this.sampleFiles.length === 0) {
      this.sampleFilesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.sampleFilesMsg = TicketCreateComponent.MSG_FILES_EXTENSION;
    } else if (this.sampleFiles.length > 1) {
      this.sampleFilesMsg = TicketCreateComponent.MSG_FILES_QUANTITY;
    } else {
      this.sampleFilesMsg = '';
    }

    this.sampleFilesValid = (extensionValid && this.sampleFiles.length === 1);
  }

  onRevisionSampleFilesChange(files: UploadFileExtended[]) {
    this.revisionSampleFiles = files;

    const extensionValid = files.every(f => ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()));

    if (this.revisionSampleFiles.length === 0) {
      this.revisionSampleFilesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
    } else if (!extensionValid) {
      this.revisionSampleFilesMsg = TicketCreateComponent.MSG_FILES_EXTENSION;
    } else if (this.revisionSampleFiles.length > 1) {
      this.revisionSampleFilesMsg = TicketCreateComponent.MSG_FILES_QUANTITY;
    } else {
      this.revisionSampleFilesMsg = '';
    }

    this.revisionSampleFilesValid = (extensionValid && this.revisionSampleFiles.length === 1);
  }

  onOrganizationalUnitSelected(event) {
    this.ticket.section = event.newValue && event.newValue.section ? ('Úsek výstavby' ===  event.newValue.section ? this.brandTranslatePipe.transform('sv.section.invest') : this.brandTranslatePipe.transform('sv.section.additional')) : null;
    this.ticket.organizationalUnitName = event.newValue ? event.newValue.name : null;
    this.ticket.organizationalUnitId = event.newValue ? event.newValue.id : null;
    this.ticket.organizationalUnitCode = event.newValue ? event.newValue.code : null;
  }

  onChangeAssignable(event) {
    this.ticket.assignable = event === 'y';
  }

  isValid(): boolean {
    return this.sampleFilesValid
      && (this.APP_BRAND.NAME !== 'SZ' || this.revisionSampleFilesValid)
      && !!this.ticket.description
      && (!!this.sample || (!!this.ticket.documentType && !!this.ticket.organizationalUnitId));
  }

  onSubmit(): Promise<any> {
    if (this.sample) {
      return this.ticketService
        .createBySample(this.ticket, this.sample, this.sampleFiles, this.revisionSampleFiles || [])
        .then(ticket => {
          this.stateService.go('sv.tickets.detail', { id: ticket.id });
        });
    }

    return this.ticketService
      .create(this.ticket, this.sampleFiles, this.revisionSampleFiles || [])
      .then(ticket => {
        this.stateService.go('sv.tickets.detail', { id: ticket.id });
      });
  }

  checkExistingSample() {
    if (this.sample || !(this.ticket.documentType && this.ticket.organizationalUnitId)) {
      this.existingSample = false;
      return;
    }

    const filter = { filter: { filters: {
      documentTypeId: this.ticket.documentType.id,
      organizationalUnitId: this.ticket.organizationalUnitId,
      status: [SampleStatusEnum.ACTIVE, SampleStatusEnum.EXCEPTION]
    }}};

    this.restangular
      .all('samples').customPOST(filter)
      .toPromise()
      .then(data => {
        this.existingSample = data.length > 0;
      });
  }

  private loadSample(): Promise<any> {
    if (this.sampleId) {
      return this.sampleService.getById(this.sampleId).then(sample => {
        this.sample = sample;
      });
    }
    return Promise.resolve();
  }

  private loadDocumentTypes(): Promise<any> {
    return this.restangular
      .all('document-types')
      .getList()
      .toPromise()
      .then(list => {
        this.documentTypes = list.plain();
      });
  }
}
