import { Component, Inject, OnInit } from '@angular/core';
import { EasementCreateStepComponent } from '@app/ps/construction-objects/components/easement-create-step/easement-create-step.component';
import * as _ from 'lodash';
import { ParcelUtils } from '@app/common/utils/parcel.utils';
import { TableUtils } from '@app/common/utils/table.utils';
import { DialogService } from '@app/common/services/dialog.service';
import { APP_BRAND } from '@app/common/services/config.service';
import { Restangular } from 'ngx-restangular';

@Component({
  selector: 'easement-create-summary',
  templateUrl: './easement-create-summary.component.html',
})
export class EasementCreateSummaryComponent extends EasementCreateStepComponent implements OnInit {

  sortOrder: any = {};
  sortDir;
  roundTo0DecimalPlaces = false;
  uploadingData = false;
  includeAllEasements = false;
  loadingHeader = true;
  header: any = {};
  constructionObject: number;

  list = {
    list: undefined,
    itemCount: null,
    loading: true
  };

  constructor(
    private restangular: Restangular,
    private dialogService: DialogService,
    @Inject(APP_BRAND) public APP_BRAND: any,
  ) {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.toggleAllEasements = this.toggleAllEasements.bind(this);
  }

  async ngOnInit() {
    if (!this.data.vfkId) {
      this.backCallback.emit();
    }

    await this.getHeader();
    this.getIntersectData();
  }

  showConfirmUpload(message) {
    return this.dialogService.confirmDialogPromise(message);
  }

  toggleAllEasements() {
    this.includeAllEasements = !this.includeAllEasements;
    for (const item of this.list.list) {
      for (const e of item.easements) {
        e.include = this.includeAllEasements;
      }
    }
  }

  isValid() {
    const withWarnings = EasementCreateSummaryComponent.getInvalidData(this.list);
    const includedData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
    return !this.uploadingData && withWarnings.length === 0 && includedData.length > 0;
  }

  onSubmit() {
    const deleteEasements = this.getDeleteEasements();
    const deleteData = deleteEasements.map((item) => item.easement.importParcel.id);

    const uploadEasements = EasementCreateSummaryComponent.getIncludedEasements(this.list);
    const uploadData = this.getUploadData().map(d => { delete d.warnings; return d; });

    const upload = {
      removedEasementIds: deleteData,
      symapParcelIntersectionVbs: uploadData,
      roundTo0DecimalPlaces: this.roundTo0DecimalPlaces
    };

    const deleteEasementsList = deleteEasements.map(EasementCreateSummaryComponent.mapEasementRow);
    const addEasementsList = uploadEasements.map(EasementCreateSummaryComponent.mapEasementRow);

    const easementsList = `
        <h2>Potvrzením budou provedeny následující úpravy.</h2>
        <h3>Nová VB:</h3>
        <ul>${addEasementsList.join('')}</ul>
        <h3>VB ke smazání:</h3>
        <ul>${deleteEasementsList.join('')}</ul>
        <br/>${this.roundTo0DecimalPlaces ? `<h3>Hodnoty budou zaokrouhleny.</h3>` : `<h3>Hodnoty nebudou zaokrouhleny.</h3>`}
    `;

    return this.showConfirmUpload(easementsList).then(confirmed => {
      if (confirmed) {
        return this.uploadParcels(upload);
      }
    });
  }

  dataContainsWarnings() {
    const withWarnings = EasementCreateSummaryComponent.getInvalidData(this.list);
    const includedData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
    return withWarnings.length > 0 && includedData.length > 0;
  }

  getUploadData() {
    const includeData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
    return includeData.map((parcel) => {
      return {
        id: parcel.easement.importParcel.id,
        vymera: parcel.easement.importParcel.area,
        delka: parcel.easement.importParcel.length,
        warnings: parcel.easement.warnings
      };
    });
  }

  getDeleteEasements() {
    const deleteData = [];
    if (this.list && this.list.list && this.list.list.length > 0) {
      this.list.list.forEach((parcel) => {
        parcel.easements.forEach((easement) => {
          if (easement.editable === false && easement.include === false) {
            deleteData.push({
              easement: easement,
              parcel: parcel.parcel
            });
          }
        });
      });
    }
    return deleteData;
  }

  /* SORTING */

  setSortData(sortedBy) {
    this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';

    // every first sort will be sorted 'desc'
    if (this.sortOrder.sortBy !== sortedBy) {
      this.sortOrder.direction = 'desc';
    }
    this.sortOrder.sortBy = sortedBy;
  }

  onSort(sortedBy, dataPath) {
    this.setSortData(sortedBy);
    this.list.list = TableUtils.sortDataByColumnStatic(this.list.list, dataPath, this.sortOrder.direction);
  }

  onSortParcel(sortedBy) {
    this.setSortData(sortedBy);
    this.list.list = _.orderBy(this.list.list, ParcelUtils.getSortFunctionByParcelsNumbers('parcel.parcelCis.parcisKmen', 'parcel.parcelCis.parcisPod', 'parcel.parcelCis.parcisDil'), this.sortDir);
  }

  /* DOWNLOADS & UPLOADS */

  private getHeader(): Promise<any> {
    return this.restangular.one(`/vfks/intersect/${this.data.vfkId}`, `header`).get().toPromise().then(data => {
      this.loadingHeader = false;
      this.constructionObject = data.constructionObject.id;
      this.header.so = `${data.constructionObject.socis} - ${data.constructionObject.sotx}`;
      this.header.sousek = data.constructionObject.sousek;
      this.header.cisloGp = data.cisloGp;
      this.header.zhotovitel = data.zhotovitel;
      this.header.datumOvereni = data.datumOvereni;
      this.header.datumZhotoveni = data.datumZhotoveni;
      this.header.ku = data.katastralniUzemi.name;
    });
  }

  private getIntersectData() {
    return this.restangular.one(`/vfks/intersect/`, this.data.vfkId).get().toPromise().then(data => {
      const list = data.plain();
      const csvOnlyParcels = list.filter((parcel) => parcel.result === 'CSV_ONLY');
      const csvOnlyParcelsNames = csvOnlyParcels.map((parcel) => parcel.parcelName);

      if (csvOnlyParcels.length > 0) {
        this.dialogService.confirmDialogPromise(`V CSV zjištěny neexistující parcely ${csvOnlyParcelsNames.join(', ')}. Tyto parcely nejsou zpracované, chcete pokračovat? `)
          .then(confirm => {
            if (!confirm) {
              this.backCallback.emit();
            }
          });
      }

      this.list.list = list
        .filter(parcel => parcel.result !== 'CSV_ONLY')
        .map(parcel => EasementCreateSummaryComponent.getParcelData(parcel, this.header.cisloGp));
      this.list.loading = false;
      this.list.itemCount = list.length;
    });
  }

  private uploadParcels(data) {
    this.uploadingData = true;
    return this.restangular.all(`/vfks/create/${this.data.vfkId}`).post(data).toPromise().then(() => {
      this.submitCallback.emit();
    });
  }

  /* HELPERS */

  private static getInvalidData(list) {
    const includedData = EasementCreateSummaryComponent.getIncludedEasements(list);
    return includedData.filter(data => {
      return data.easement.include && Object.keys(data.easement.warnings).length > 0;
    });
  }

  private static getIncludedEasements(list) {
    const data = [];
    if (list && list.list && list.list.length > 0) {
      list.list.forEach((parcel) => {
        parcel.easements.forEach((easement) => {
          if (easement.editable && easement.include === true) {
            data.push({
              easement: easement,
              parcel: parcel.parcel
            });
          }
        });
      });
    }
    return data;
  }

  private static getParcelData(data, cisloGp) {
    const parcel = {
      easements: [],
      parcel: {
        id: data.symapParcelIntersection ? data.symapParcelIntersection.id : null,
        lv: data.parcel ? data.parcel.lv : '-',
        future: data.parcel.future,
        drupozNazev: data.parcel ? data.parcel.drupozNazev : '-',
        parcelName: data.parcelName,
        parcelCis: {
          parcisDil: data.parcel.parcisDil,
          parcisKmen: data.parcel.parcisKmen,
          parcisPod: data.parcel.parcisPod,
        },
        includedGPcis: []
      },
    };

    const importParcel = {
      area: '',
      length: '',
      id: data.symapParcelIntersection.id
    };

    let csvParcelIntersection = null;
    if (data.csvParcelIntersection) {
      csvParcelIntersection = {
        length: data.csvParcelIntersection.delka,
        area: data.csvParcelIntersection.vymera
      };

      // prefill import data from csv
      importParcel.area = data.csvParcelIntersection.vymera;
      importParcel.length = data.csvParcelIntersection.delka;
    }

    let symapParcelIntersection = null;
    if (data.symapParcelIntersection) {
      symapParcelIntersection = {
        length: data.symapParcelIntersection.delka,
        area: data.symapParcelIntersection.vymera,
        gpcis: cisloGp
      };
    }

    // add intersect (new) easement into stack
    const intersectionEasement = EasementCreateSummaryComponent.getEasementData(true, false, csvParcelIntersection, symapParcelIntersection, importParcel, null);
    parcel.easements.push(intersectionEasement);

    if (data.easements) {
      const easements = data.easements.map((easementData) => {
        const importParcel = {
          length: easementData.delka,
          area: easementData.vymera,
          id: easementData.id
        };
        const existingEasementInfo = {
          constructionObjects: easementData.constructionObjects,
          gpcis: easementData.geometricPlan.cis,
        };
        // add gpcis to the list of included gpcis for latest check
        parcel.parcel.includedGPcis.push(easementData.geometricPlan.cis);
        return EasementCreateSummaryComponent.getEasementData(false, true, {}, {}, importParcel, easementData.countCases, existingEasementInfo);
      });

      // add current easements into stack
      parcel.easements.push(...easements);
    }

    return parcel;
  }

  private static getEasementData(editable = false, include = false, csvParcelIntersection = {}, symapParcelIntersection = {}, importParcel = {}, countCases = null, existingEasementInfo = null) {
    return {
      countCases: countCases,
      editable: editable,
      include: include,
      csvParcelIntersection: csvParcelIntersection,
      symapParcelIntersection: symapParcelIntersection,
      warnings: {},
      importParcel: importParcel,
      existingEasementInfo: existingEasementInfo
    };
  }

  private static mapEasementRow(easement) {
    const future = easement.parcel.future ? 'budoucí' : '';
    const row = [
      '<li>',
      `Parcela ${future} ${easement.parcel.parcelName}`,
      `plocha ${easement.easement.importParcel.area} [<span class="unit">m<sup>2</sup></span>]`,
      `délka ${easement.easement.importParcel.length} [<span class="unit">m</span>]`,
      '</li>',
    ];

    return row.join(' ');
  }
}
