<div class="list">
  <h1>Vlastníci</h1>
  <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">

    <!-- typ pripadu -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.obligationId"
      [filter]="{sortOrder: {sortBy: 'name'}}"
      field="values"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.obligationId.negation"
      resource="obligations"
      selectTitle="Typ případu"
    ></gmt-select>

    <!-- stav pripadu -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.caseStatusId"
      [filter]="{filters: {key: {values: ['Cancelled'], negation: true}}}"
      field="values"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.caseStatusId.negation"
      resource="case-statuses"
      selectTitle="Stav případu"
    ></gmt-select>

    <!-- katastralni uzemi -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.areaId"
      [filter]="{sortOrder: {sortBy: 'name'}}"
      field="values"
      enableSelectAll="true"
      [(selectAll)]="list.filter.filters.areaId.negation"
      resource="areas"
      selectTitle="Katastrální území"
    ></gmt-select>

    <!-- typ vlastnika -->
    <gmt-select
      [multiselect]="true"
      [data]="this"
      field="opsubTypes"
      (changed)="onOpsubTypeFilterChanged($event)"
      [optionItems]="[{id: 'OFO', name: 'Fyzická osoba'}, {id: 'OPO', name: 'Právnická osoba'}]"
      selectTitle="Typ vlastníka"
    ></gmt-select>

    <!-- problematicky vlastnik -->
    <gmt-select
      [multiselect]="true"
      [data]="list.filter.filters.problematic"
      field="values"
      [optionItems]="[{id: false, name: 'Vlastník bez záznamu'}, {id: true, name: 'Problematický vlastník'}]"
      selectTitle="Problematický vlastník"
    ></gmt-select>

    <div (click)="onShowFormerOwnersToggle()" [ngClass]="{'tag--active': includesFormerOwners()}" class="tag" title="Zobrazit i vlastníky, kteří již nejsou vedeni v aktuálních datech KN">
      <div>Zobrazit bývalé vlastníky</div>
    </div>
    <div (click)="onShowNeighbourOwnersToggle()" [ngClass]="{'tag--active': includesNeighbourOwners()}" class="tag" title="Zobrazit i osoby, které vlastní pouze parcely na kterých není žádná stavba přímo umístěna, ale jsou dostupné v {{APP_BRAND.PS.TITLE}}">
      <div>Zobrazit sousední vlastníky</div>
    </div>
    <statistics-entity-filter (changed)="statisticsEntityFilterChanged($event)" [filter]="list.filter" (registerCallback)="registerUpdateStatisticsEntityFilterKey($event)" title="Zobrazení parcel, které spadají do kategorie příslušné statistiky">
    </statistics-entity-filter>

    <div class="column-100">
      <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
    </div>
  </filter>

  <div [loading]="list.loading || !settingsLoaded">
    <div class="table-top-container">
      <div class="flex1"><gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button></div>
      <pagescounter *ngIf="list.itemCount && settingsLoaded && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
      <div class="page-count-select" *ngIf="list.itemCount && settingsLoaded && list.list !== null">
        <span>Záznamů na stránce</span>
        <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
      </div>
    </div>
    <table-subjects [list]="list" (sort)="pageableList.onSort($event.column, $event.ascOrDesc)" *ngIf="settingsLoaded"></table-subjects>
    <itemscounter
      *ngIf="list.itemCount && list.list !== null" [list]="list"
      (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
      style="max-width: 100px;"
    ></itemscounter>
    <pagination
      *ngIf="list.itemCount && list.list !== null"
      [list]="list"
      (callbackRegister)="pageableList.paginationCallbackRegister($event)"
    ></pagination>
  </div>

  <help [helpId]="helpIds.PS_SUBJECT_LIST" class="cm-help-page"></help>
</div>
