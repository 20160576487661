<div>
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>

<fieldset>
  <legend>Výběr operace</legend>

  <div class="column-100">
    <gmt-input label="Výběr operace">
      <gmt-switch [(selectedId)]="data.operation" [options]="operationTypes"></gmt-switch>
    </gmt-input>

    <gmt-input *ngIf="data.operation === 'p'" label="{{ 'ps.project' | brandTranslate }}" [required]="true">
      <gmt-select
        [data]="data"
        field="project"
        [optionItems]="data.common.projects"
        [itemPipe]="projectNamePipe"
        selectTitle="{{ 'ps.project' | brandTranslate }}"
      ></gmt-select>
    </gmt-input>

    <gmt-input *ngIf="data.operation === 'u'" label="Uživatel" [required]="true">
      <gmt-select
        [data]="data"
        field="user"
        resource="users/list"
        selectTitle="Uživatel"
        [itemPipe]="userNamePipe"
      ></gmt-select>
    </gmt-input>
  </div>
</fieldset>

<div class="mt-10">
  <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Dále</gmt-button>
</div>
