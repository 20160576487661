<div class="detail">
  <gmt-button [clickAction]="goToCase"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  <div [loading]="!occupationObject">
    <ng-container *ngIf="occupationObject">
      <fieldset class="bordered">
        <div class="column-50"><label>Parcela</label>
          <div class="info"><a uiSref="symap.project.parcels.detail" [uiParams]="{id: occupationObject.parcel.id}">{{occupationObject.parcel|parcelnumber}}</a></div>
        </div>
        <div class="column-50"><label>Katastrální území</label>
          <div class="info">{{occupationObject.parcel.katuzeNazev}}</div>
        </div>
        <div class="column-50"><label>Výměra parcely</label>
          <div class="info">{{occupationObject.parcel.vymera}} m<sup>2</sup></div>
        </div>
        <div class="column-50"><label>Druh záboru</label>
          <div class="info">{{occupationObject.zabtyptx}}</div>
        </div>
      </fieldset>

      <div class="bordered fieldset no-padding">
        <div class="legend">Aktuálně platné zábory stejného typu na parcele</div>
        <div [loading]="occupationList.loading">
          <div class="table" *ngIf="occupationList.list?.length">
            <div class="head-group">
              <div class="row">
                <div class="cell min"></div>
                <div class="cell">Číslo</div>
                <div class="cell">{{'ETAPA_USEK'|wordtranslate}}</div>
                <div class="cell">Výměra <span class="unit">m<sup>2</sup></span></div>
                <div class="cell">
                  {{ 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT' | wordtranslate:true }}
                </div>
                <div class="cell">Případy</div>
              </div>
            </div>
            <div class="row-group">
              <div class="row cancelled" *ngIf="occupationObject.endDate" (click)="onToggleCheckItem(occupationObject)">
                <div class="cell"><gmt-radio [gmtChecked]="selected.id === occupationObject.id"></gmt-radio></div>
                <div class="cell">{{occupationObject.zabcis}}</div>
                <div class="cell">{{occupationObject.zabst}}</div>
                <div class="cell number">{{occupationObject.zabvym}}</div>
                <div class="cell">
                  <gmt-construction-object-list [constructionObjects]="occupationObject.constructionObjects"></gmt-construction-object-list>
                </div>
                <div class="cell no-padding">
                  <cell-cases
                    [casesCount]="occupationObject.countCases"
                    tooltipType="cases"
                    [tooltipData]="{filter: {filters: {occupationId: occupationObject.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
                  ></cell-cases>
                </div>
              </div>
              <div class="row" *ngFor="let occupation of occupationList.list" (click)="onToggleCheckItem(occupation)">
                <div class="cell"><gmt-radio [gmtChecked]="selected.id === occupation.id"></gmt-radio></div>
                <div class="cell">{{occupation.zabcis}}</div>
                <div class="cell">{{occupation.zabst}}</div>
                <div class="cell number">{{occupation.zabvym}}</div>
                <div class="cell">
                  <gmt-construction-object-list [constructionObjects]="occupation.constructionObjects"></gmt-construction-object-list>
                </div>
                <div class="cell no-padding">
                  <cell-cases
                    [casesCount]="occupation.countCases"
                    tooltipType="cases"
                    [tooltipData]="{filter: {filters: {occupationId: occupation.id, cancelStatus: 'notCancelled', loadCollections: ['caseOwnerships']}}}"
                  ></cell-cases>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-info" *ngIf="occupationList.list?.length === 0">Nenalezeny žádné zábory</div>
        </div>
      </div>

      <div>
        <gmt-button [clickAction]="update">Uložit</gmt-button>
      </div>
    </ng-container>
  </div>
</div>
