import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gmt-labeled-block',
  templateUrl: './labeled-block.component.html',
  styleUrls: ['./labeled-block.component.scss']
})
export class LabeledBlockComponent {
  @Input() label: string;
  @Input() collapsed = false;
  @Output() changed = new EventEmitter<boolean>();

  onToggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.changed.emit(this.collapsed);
  }
}
