<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1 class="mb-30">{{ cadastreEntryName }}</h1>
    <fieldset *ngIf="step === 'CadastreEntry'">
      <div class="column-100">
        <label class="required">Datum doručení rozhodnutí</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.cadastreEntryDeliveryDate" (ngModelChange)="onDateChanged($event, 'CadastreEntryDeliveryDate')" [maxToday]="true"/>
        </div>

        <label class="required">Datum právních účinků</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.legalEffectsDate" [maxToday]="true"/>
        </div>

        <label class="required">Datum rozhodnutí KN</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.cadastreEntryDate" (ngModelChange)="onDateChanged($event, 'CadastreEntryDate')" [maxToday]="true"/>
        </div>

        <label class="required">Číslo jednací KN</label>
        <div class="input"><input type="text" [(ngModel)]="data.cadastreEntryNumber"/>
        </div>

        <div *ngIf="!data.paymentOrderDate">
          <label>Datum splatnosti</label>
          <div class="info">{{data.dueDate | date}}</div>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="step === 'EntryDeclined'">
      <div class="column-100">
        <label class="required">Datum rozhodnutí KN</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.cadastreEntryDeclinedDate" [maxToday]="true"/>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="step === 'EntryStopped'">
      <div class="column-100">
        <label class="required">Datum rozhodnutí o zastavení</label>
        <div class="input"><input type="text" pick-a-date [(ngModel)]="data.cadastreEntryStoppedDate" [maxToday]="true"/>
        </div>
      </div>
    </fieldset>

    <div>
      <gmt-button class="red" *ngIf="entryDeclinedName && step === 1" (click)="setStep('EntryDeclined')">{{ entryDeclinedName }}</gmt-button>
      <gmt-button class="red" *ngIf="entryStoppedName && step === 1" (click)="setStep('EntryStopped')">{{ entryStoppedName }}</gmt-button>
      <gmt-button *ngIf="cadastreEntryName && step === 1" (click)="setStep('CadastreEntry')">{{ cadastreEntryName }}</gmt-button>
      <gmt-button class="black" *ngIf="step !== 1 && nextStates.length !== 1" (click)="setStep(1)">Zpět</gmt-button>
      <gmt-button [gmtDisabled]="!isValidAccepted()" *ngIf="step === 'CadastreEntry'" [clickAction]="onConfirm">Uložit</gmt-button>
      <gmt-button [gmtDisabled]="!isValidDecline()" *ngIf="step === 'EntryDeclined'" [clickAction]="onDecline">Uložit</gmt-button>
      <gmt-button [gmtDisabled]="!isValidStop()" *ngIf="step === 'EntryStopped'" [clickAction]="onStop">Uložit</gmt-button>
    </div>
  </div>
</div>
