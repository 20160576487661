<div class="bordered" *ngIf="list.itemCount">
  <div class="table">
    <div class="head-group sticky">
      <div class="row">

        <!-- Parcela -->
        <span *ngIf="!hideColumn('parcel')" class="cell">
          <sort-by
            [selected]="isArray(list.filter.sortOrder) && list.filter.sortOrder[0].sortBy === 'parcisKmen'"
            (sort)="onSort(['parcisKmen','parcisPod'], $event)"
            [sortDir]="list.filter.sortOrder[0]?.direction"
          >{{ getColumnName('parcel') }}</sort-by>
        </span>

        <!-- K.U. -->
        <span *ngIf="!hideColumn('areaName')" class="cell">
          <sort-by
            [selected]="'area.name' === list.filter.sortOrder.sortBy"
            (sort)="onSort('area.name', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{ getColumnName('areaName') }}</sort-by>
        </span>

        <!-- LV -->
        <span *ngIf="!hideColumn('lv')" class="cell">
          <sort-by
            [selected]="'lv' === list.filter.sortOrder.sortBy"
            (sort)="onSort('lv', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{ getColumnName('lv') }}</sort-by>
        </span>

        <!-- Vlastnik -->
        <span *ngIf="!hideColumn('owner')" class="cell">
          {{ getColumnName('owner') }}
        </span>

        <!-- Pripady -->
        <span *ngIf="!hideColumn('case')" class="cell">
          <sort-by
            [selected]="list.filter.sortOrder.sortBy === 'countCases'"
            (sort)="onSort('countCases', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{ getColumnName('case') }}</sort-by>
        </span>

        <!-- GP -->
        <span *ngIf="!hideColumn('geometricPlan')" class="cell">
          <sort-by
            [selected]="list.filter.sortOrder.sortBy === 'geometricPlanSort.geometricPlanNameString'"
            (sort)="onSort('geometricPlanSort.geometricPlanNameString', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{ getColumnName('geometricPlan') }}</sort-by>
        </span>

        <!-- ZP -->
        <span *ngIf="!hideColumn('opinion')" class="cell">
          <sort-by
            [selected]="list.filter.sortOrder.sortBy === 'opinionSort.opinionNameString'"
            (sort)="onSort('opinionSort.opinionNameString', $event)"
            [sortDir]="list.filter.sortOrder.direction"
          >{{ getColumnName('opinion') }}</sort-by>
        </span>

        <!-- Protection -->
        <span *ngIf="!hideColumn('protection')" class="cell">
          {{ getColumnName('protection') }}
        </span>

        <!-- SO -->
        <span *ngIf="!hideColumn('constructionObjects')" class="cell">
          {{ getColumnName('constructionObjects') }}
        </span>
      </div>
    </div>
    <div class="row-group">
      <a class="row" *ngFor="let parcel of list.list" uiSref="symap.project.parcels.detail" [uiParams]="{ id: parcel.id }">

        <!-- Parcela -->
        <span *ngIf="!hideColumn('parcel')" class="cell top no-padding cell-max-height">
          <div class="cell--flex__container">
            <div class="cell--flex__container__main">
              <cell-parcel
                [parcel]="parcel"
                [tooltipType]="'parcel'"
                [tooltipData]="{ parcel: parcel, withoutTitle: true }"
              ></cell-parcel>
            </div>
            <div class="cell--flex__container_side pr-10">
              <ng-container *ngIf="showNavIcons">
                <a class="pl-5 pt-5" uiSref="symap.project.parcels.detail" [uiParams]="{ projectKey: customProjectKey, id: parcel.id }" target="_blank" title="Zobrazit detail akce" (click)="$event.stopPropagation()"><i class="fa fa-eye"></i></a>
                <a class="pl-5 pt-5" uiSref="symap.project.map" [uiParams]="{ projectKey: customProjectKey, parcel: parcel.id, layer: ['geom_ku', 'kn_latest'] }" target="_blank" title="Zobrazit v mapovém okně" (click)="$event.stopPropagation()"><i class="fa fa-map"></i></a>
              </ng-container>
            </div>
          </div>
        </span>

        <!-- K.U. -->
        <span *ngIf="!hideColumn('areaName')" class="cell">{{ parcel.area.name }}</span>

        <!-- LV -->
        <span class="cell cell--flex">
          <span *ngIf="!parcel.title?.jpvExists">
            <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" (click)="$event.stopPropagation()">
              {{ parcel.title.lv }}
            </a>
          </span>
          <span *ngIf="parcel.title?.jpvExists" class="cell--flex__container">
            <span class="cell--flex__container__main">
              <a *ngIf="parcel.title" uiSref="symap.project.titles.detail" [uiParams]="{ id: parcel.title.id, tab: null }" (click)="$event.stopPropagation()">
                {{ parcel.title.lv }}
              </a>
            </span>
            <span class="cell--flex__container_side">
              <span title="Jiné právní vztahy" class="tag tag--inactive tag--small is-uppercase ml-5">JPV</span>
            </span>
          </span>
        </span>

        <!-- Vlastnik -->
        <span *ngIf="!hideColumn('owner')" class="cell no-padding cell-max-height">
              <cell-ownership
                *ngIf="parcel.title && parcel.title.id"
                [count]="parcel.title.countOwnerships"
                [ownerships]="parcel.title.ownerships"
                [tooltipData]="{ filter: {sortOrder: {sortBy: 'subjectName'}, filters: { titleId: parcel.title.id, validity: 'valid' }}, attributes: {subject: {load: false}}}"
                tooltipType="ownership-list"
              ></cell-ownership>
            </span>

        <!-- Pripady -->
        <span *ngIf="!hideColumn('case')" class="cell no-padding cell-max-height">
              <cell-cases
                [casesCount]="parcel.countCases"
                tooltipType="cases"
                [tooltipData]="{ filter: { filters: { parcelId: parcel.id, cancelStatus: 'notCancelled' }}}"
              ></cell-cases>
            </span>

        <!-- GP -->
        <span *ngIf="!hideColumn('geometricPlan')" class="cell">
              <cell-geometric-plans
                [geometricPlanRequests]="geometricPlanModuleEnabled ? parcel.geometricPlanRequests : []"
                [knGps]="getKnGpsFromParcel(parcel)"
                [withLink]="geometricPlanModuleEnabled"
              ></cell-geometric-plans>
            </span>

        <!-- ZP -->
        <span *ngIf="!hideColumn('opinion')" class="cell">
              <cell-opinions
                [opinionRequests]="opinionModuleEnabled ? parcel.opinionRequests : []"
                [opinions]="getOpinionsFromParcel(parcel)"
              ></cell-opinions>
            </span>

        <!-- Protection -->
        <span *ngIf="!hideColumn('protection')" class="cell">{{ parcel | parcelprotection }}</span>

        <!-- SO -->
        <span *ngIf="!hideColumn('constructionObjects')" class="cell">
              <gmt-construction-object-list [constructionObjects]="parcel.constructionObjects"></gmt-construction-object-list>
            </span>
      </a>
    </div>
  </div>
</div>
<div class="empty-info bordered" *ngIf="!list.itemCount && list.list !== null">
  Nenalezena žádná data
</div>
