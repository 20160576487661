<div class="detail">
  <div class="mb-10">
    <gmt-button uiSref="^"><i class="fa fa-chevron-left mr-5"></i> Zpět na případ</gmt-button>
  </div>
  <div [loading]="loading" *ngIf="loading"></div>
  <div *ngIf="!loading">
    <h1>{{ name }}</h1>
    <fieldset>
      <div class="column-100">
        <label>Adresát</label>
        <div class="info">
          {{ recipient.name }}
        </div>
      </div>
      <div class="column-100">
        <gmt-input label="Datum doručení" [required]="true">
          <input type="text" pick-a-date [(ngModel)]="sendData.receivedOwnerDate" (ngModelChange)="onReceivedOwnerDateChanged($event)" [maxToday]="true"/>
        </gmt-input>
      </div>
      <div class="column-100" *ngIf="data.obligation.bonusPeriod">
        <label>Datum pro přiznání akceleračního příplatku</label>
        <div class="info" *ngIf="data.bonusPossible">{{ sendData.bonusDate | date }}</div>
        <div class="info" *ngIf="!data.bonusPossible">bez nároku</div>
      </div>
      <div class="column-100" *ngIf="data.obligation.validityPeriod && sendData.validityDate">
        <label>Datum lhůty pro doručení podepsaného návrhu</label>
        <div class="info">{{ sendData.validityDate | date }}</div>
      </div>
    </fieldset>

    <div>
      <gmt-button [clickAction]="onSubmit" [gmtDisabled]="!isValid()">Uložit</gmt-button>
    </div>
  </div>
</div>
