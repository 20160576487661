import { ChangeDetectorRef, OnInit, Input, Inject } from '@angular/core';

import { ListService } from '../../services/list.service';
import { ListModel } from '../../models/list.model';

export interface TooltipAbstractListComponentData<T> {
  filter?: any;
  attributes?: any;
  list?: ListModel<T>;
  customData: any;
  resource?: string;
}

export abstract class TooltipAbstractListComponent<T> implements OnInit {

  // bindings
  @Input() data: TooltipAbstractListComponentData<T>;

  // model
  loading: boolean;

  protected abstract resource: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected listLoadService: ListService,
    @Inject('tooltipConfig') protected tooltipConfig: any
  ) { }

  ngOnInit() {
    this.cdr.detach();
    if (!this.data.list) {
      this.loading = true;
      this.data.list = this.listLoadService.createList(
          this.data.resource || this.resource,
          this.data.filter ? this.data.filter : {limit: 0},
          undefined,
          this.data.attributes
      );

      this.cdr.detectChanges();
      this.listLoadService.fetchResult(this.data.list)
          .then(() => {
            this.loading = false;
            this.cdr.detectChanges();
            this.tooltipConfig.checkPosition();
          });
    } else {
      this.cdr.detectChanges();
    }
  }
}
