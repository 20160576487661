<div class="list">
  <div style="display:flex">
    <div class="line-height-l" style="min-width: 120px;">
      <a uiSref="^"  class="button mb-10"><i class="fa fa-chevron-left mr-5"></i> Nastavení</a>
    </div>
    <div class="actions">
      <a *ngIf="APP_BRAND.NAME !== 'RSD' && APP_BRAND.NAME !== 'SZ'" (click)="onCreate()" class="button"><i class="fa fa-plus-square"></i>Vytvořit nového uživatele</a>
    </div>
  </div>

  <h1>Uživatelé</h1>

  <ng-container *ngIf="list">
    <filter [list]="list" (onChanged)="pageableList.onFilterChanged($event)">
      <ng-container *ngIf="projectKey">
        <div
          visibleByBrand="maja"
          (click)="list.filter.filters.projects.values = (list.filter.filters.projects.values.length !== 0 ? [] : [projectKey])"
          [ngClass]="{ active: list.filter.filters.projects.values.length !== 0 }"
          title="Zobrazení uživatelů aktivních v aktuálním projektu"
          class="tag"
        >
          <div>Aktuální projekt</div>
        </div>

        <ng-container *ngIf="module === 'SY' && list.filter.filters.projects.values.length !== 0">
          <gmt-select
            [optionItems]="roles"
            [data]="list.filter.filters.roles"
            field="values"
            [multiselect]="true"
            selectTitle="Role"
            [itemPipe]="rolePipe"
          ></gmt-select>

          <div
            visibleByBrand="maja"
            (click)="list.filter.filters.withoutRole.values = (list.filter.filters.withoutRole.values.length !== 0 ? [] : [true])"
            [ngClass]="{ active: list.filter.filters.withoutRole.values.length !== 0 }"
            title="Zobrazení uživatelů bez role"
            class="tag"
          >
            <div>Bez role</div>
          </div>
        </ng-container>
      </ng-container>
      <div class="column-100">
        <input class="fulltext" type="text" placeholder="Fultextové hledání..." [(ngModel)]="list.filter.filters.searchText.values[0].id"/>
      </div>
    </filter>

    <div [loading]="list.loading">
      <div class="table-top-container">
        <div class="invisible cm-select-placeholder"></div>
        <pagescounter *ngIf="list.itemCount && list.list !== null" [list]="list" (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"></pagescounter>
        <div class="page-count-select" *ngIf="list.itemCount && list.list !== null">
          <span>Záznamů na stránce</span>
          <gmt-select required="true" [data]="pageableList.dataPaging" field="itemsPerPage" [optionItems]="pageableList.itemsPerPageItems" (changed)="pageableList.changePageItems()" class="min-width-unset"></gmt-select>
        </div>
      </div>

      <div class="bordered" *ngIf="list.itemCount">
        <div class="table">
          <div class="head-group sticky">
            <div class="row">
              <span class="cell" visibleByBrand="maja">Aktivní?</span>
              <span class="cell">
                <sort-by
                  [selected]="'fullName' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('fullName', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Celé jméno</sort-by>
              </span>
              <span class="cell">
                <sort-by
                  [selected]="'username' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('username', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >Přihlašovací jméno</sort-by>
              </span>
              <span class="cell">
                <sort-by
                  [selected]="'email' === list.filter.sortOrder.sortBy"
                  (sort)="pageableList.onSort('email', $event)"
                  [sortDir]="list.filter.sortOrder.direction"
                >E-mail</sort-by>
              </span>
              <span class="cell">Firma</span>
            </div>
          </div>
          <div class="row-group">
            <ng-container *ngIf="APP_BRAND.NAME !== 'RSD' || editor.superuser">
              <a class="row" *ngFor="let user of list.list" uiSref=".detail" [uiParams]="{ id: user.id }">
                <span class="cell" visibleByBrand="maja" *ngIf="user.applicationProjects[module].length > 0">
                  <i class="fa fa-check"></i>
                </span>
                <span class="cell" *ngIf="user.applicationProjects[module].length === 0">
                  <i class="fa fa-times"></i>
                </span>
                <span class="cell">{{ user.fullName }}</span>
                <span class="cell">{{ user.username }}</span>
                <span class="cell">{{ user.email }}</span>
                <span class="cell">{{ user.company | customerName }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="APP_BRAND.NAME === 'RSD' && !editor.superuser">
              <div class="row" *ngFor="let user of list.list">
                <span class="cell" visibleByBrand="maja" *ngIf="user.applicationProjects[module].length > 0">
                  <i class="fa fa-check"></i>
                </span>
                <span class="cell" *ngIf="user.applicationProjects[module].length === 0">
                  <i class="fa fa-times"></i>
                </span>
                <span class="cell">{{ user.fullName }}</span>
                <span class="cell">{{ user.username }}</span>
                <span class="cell">{{ user.email }}</span>
                <span class="cell">{{ user.company | customerName }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="empty-info bordered pb-20 pt-20 centered-text" *ngIf="!list.itemCount && list.list !== null && !list.loading">
        <span>Nebyla nalezena žádná data.</span>
      </div>
    </div>

    <itemscounter
      *ngIf="list.itemCount && list.list !== null" [list]="list"
      (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
      style="max-width: 100px;"
    ></itemscounter>

    <pagination
      *ngIf="list.itemCount && list.list !== null"
      [list]="list"
      (callbackRegister)="pageableList.paginationCallbackRegister($event)"
    ></pagination>

    <help [helpId]="helpIds.SETTINGS_USERS" class="cm-help-page"></help>
  </ng-container>
</div>
