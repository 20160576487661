import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DialogConfig, DialogConfigData } from '@app/common/models/dialog-config';
import { DialogRef } from '@app/common/services/dialog-ref';
import { ProjectsColumnDefinitionModel } from '@app/landing/models/projects-column-definition.model';

@Component({
  selector: 'project-columns-edit',
  styleUrls: ['./project-columns-edit.component.scss'],
  templateUrl: './project-columns-edit.component.html',
})
export class ProjectColumnsEditComponent implements OnInit {

  columns: ProjectsColumnDefinitionModel[];
  selectedColumns: string[];

  constructor(
    private dialogConfig: DialogConfig,
    public dialogComponent: DialogRef,
  ) {
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  ngOnInit() {
    this.columns = (<DialogConfigData>this.dialogConfig.data).columns;
    this.selectedColumns = (<DialogConfigData>this.dialogConfig.data).selectedColumns;
  }

  onToggleColumn(column: ProjectsColumnDefinitionModel) {
    this.selectedColumns.find(c => c === column.id)
      ? _.remove(this.selectedColumns, c => c === column.id)
      : this.selectedColumns.push(column.id);
  }

  onMoveColumn(column: ProjectsColumnDefinitionModel, step: number) {
    const index = _.indexOf(this.columns, column);
    if (index + step < this.columns.length && index + step >= 2) {
      _.pull(this.columns, column);
      this.columns.splice(index + step, 0, column);
    }
  }

  isColumnEnabled(column: ProjectsColumnDefinitionModel): boolean {
    return !!this.selectedColumns.find(c => c === column.id);
  }

  isValid() {
    return !!this.selectedColumns.length;
  }

  onUpdate() {
    this.dialogComponent.close({ selectedColumns: this.selectedColumns, sortedColumns: this.columns.map(c => c.id)});
  }

  onCancel() {
    this.dialogComponent.close();
  }
}
