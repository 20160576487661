<fieldset class="no-legend">
  <div class="column-100">
    <label class="required">Datum přijetí podepsané smlouvy</label>
    <div class="input"><input type="text" pick-a-date [(ngModel)]="data.responseReceivedDate" (ngModelChange)="onUpdate()" [maxToday]="true"/></div>
  </div>
  <div class="column-100">
    <label class="required">Datum podpisu smlouvy</label>
    <div class="input"><input type="text" pick-a-date [(ngModel)]="signedDate" (ngModelChange)="onUpdate()" [maxToday]="true"/></div>
  </div>
  <div class="column-100" *ngIf="displaySignatureVerificationPrice">
    <label>Částka za ověření podpisu (Kč)</label>
    <div class="input">
      <input type="text" [(ngModel)]="signatureVerificationPrice" [gmtInputNumber]="true" (ngModelChange)="onUpdate()"/>
    </div>
  </div>
</fieldset>

<fieldset>
  <legend>Případy s více možnými osobami k podpisu</legend>
  <div class="column-100" [loading]="true" *ngIf="firstCaseId === undefined && caseIndex!==cases.length"></div>
  <div *ngIf="firstCaseId !== undefined && caseIndex < cases.length && caseIndex >= 0">
    <div class="column-100">
      <label>Číslo případu</label>
      <div class="info">
        <a uiSref="symap.project.cases.detail" [uiParams]="{id: cases[caseIndex].id}">{{cases[caseIndex].mpp.number || 'bez čísla'}}</a>
      </div>
    </div>
    <div class="column-100" *ngFor="let caseSubject of cases[caseIndex].caseSubjects">
      <div class="flexbox-container info" (click)="checkSubject(caseSubject)">
        <gmt-checkbox [gmtChecked]="checkedSubject(caseSubject)" class="mr-10"></gmt-checkbox> {{caseSubject.subject | subjectName}}
      </div>
    </div>
  </div>
  <div *ngIf="caseIndex===cases.length" class="empty-info">
    Všechny zvolené případy mají zvolené podepisující osoby. Pokračujte tlačítkem "Další" níže.
  </div>
  <div class="column-100 mt-20" *ngIf="firstCaseId !== undefined">
    <gmt-button [gmtDisabled]="firstCaseId === undefined || caseIndex===firstCaseId" [clickAction]="previous">Předchozí</gmt-button>
    <gmt-button [gmtDisabled]="!isValidCurrentCase()" *ngIf="caseIndex<cases.length" [clickAction]="next">Následující</gmt-button>
  </div>
</fieldset>
