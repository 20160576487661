import { Component, OnInit } from '@angular/core';
import { PermissionsBulkStepComponent } from '@app/settings/users/component/permission-bulk-step/permissions-bulk-step.component';
import {User} from '@app/models/user';
import {UserNamePipe} from '@app/common/pipes/username.pipe';
import {ProjectNamePipe} from '@app/common/pipes/project-name.pipe';
import {BrandService} from "@app/common/services/brand.service";
import {BrandTranslatePipe} from "@app/common/pipes/brand-translate.pipe";

@Component({
  selector: 'permissions-bulk-type',
  templateUrl: './permissions-bulk-type.component.html',
})
export class PermissionsBulkTypeComponent extends PermissionsBulkStepComponent implements OnInit {

  user: User;
  project: any;

  operationTypes = [
    { id: 'u', value: 'Oprávnění pro uživatele' },
    { id: 'p', value: 'Oprávnění pro ' + this.brandTranslatePipe.transform('ps.projectAccusative') },
  ];

  constructor(
    public userNamePipe: UserNamePipe,
    public projectNamePipe: ProjectNamePipe,
    private brandTranslatePipe: BrandTranslatePipe
  ) {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  ngOnInit() {

  }

  isValid(): boolean {
    return !!this.data.user || !!this.data.project;
  }

  onSubmit() {
    this.submitCallback.emit();
  }
}
