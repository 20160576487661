import { Component, Inject, OnInit } from '@angular/core';
import { RESTANGULAR_DIMAP } from '@app/common/services/restangular-dimap.service';
import { AuthService } from '@app/common/services/auth.service';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'project-create',
  templateUrl: './project-create.component.html',
})
export class ProjectCreateComponent implements OnInit {

  private static readonly PREFIX = 'di_';

  data: any = {project: {}};

  constructor(
    @Inject(RESTANGULAR_DIMAP) private restangularDimap: any,
    private authService: AuthService,
    private stateService: StateService
  ) {
    this.update = this.update.bind(this);
  }

  ngOnInit(): void {
  }

  disabled() {
    return !(this.data.project.name && this.data.project.key);
  }

  removePrefix() {
    if (this.data.key) {
      const idx = this.data.key.indexOf(ProjectCreateComponent.PREFIX);
      if (idx === -1 || idx !== 0) {
        return;
      }
      this.data.key = this.data.key.substring(idx + ProjectCreateComponent.PREFIX.length);
    }
  }

  update() {
    if (this.disabled()) {
      return;
    }

    // save and return data
    return this.restangularDimap.all('projects').post(this.data).toPromise().then((data) => {
      if (data) {
        return this.authService.loadDimapProjects(this.authService.getUser().id).then(() => {
          this.stateService.go('dimap.project.settings');
        });
      }
    });
  }
}
