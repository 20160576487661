<div class="detail">
  <div>
    <a uiSref="^" class="button mb-10"><i class="fa fa-chevron-left mr-5"></i>Seznam uživatelů</a>
  </div>

  <h1>Nastavení oprávnění</h1>

  <div class="mt-20" [loading]="loading">
    <div class="flexbox-container" *ngIf="!loading">
      <div class="content-menu">
        <div
          *ngFor="let step of steps"
          (click)="onStep(step.id)"
          [ngClass]="{ active : step.id === activeStep.id, cancelled: !step.enabled }
        ">{{ step.name }}</div>
      </div>
      <div class="flex-rest">
        <permissions-bulk-type
          *ngIf="activeStep.id === 'type'"
          [data]="data"
          (submitCallback)="onStep('entities', true)"
        ></permissions-bulk-type>

        <permissions-bulk-entities
          *ngIf="activeStep.id === 'entities'"
          [data]="data"
          (submitCallback)="onStep('permissions', true)"
          (backCallback)="onStep('type', true)"
        ></permissions-bulk-entities>

        <permissions-bulk-permissions
          *ngIf="activeStep.id === 'permissions'"
          [data]="data"
          (submitCallback)="onStep('confirm', true)"
          (backCallback)="onStep('entities', true)"
        ></permissions-bulk-permissions>

        <permissions-bulk-confirm
          *ngIf="activeStep.id === 'confirm'"
          [data]="data"
          (submitCallback)="onFinish()"
          (backCallback)="onStep('entities', true)"
        ></permissions-bulk-confirm>
      </div>
    </div>
  </div>
</div>
