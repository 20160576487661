import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ListService } from "@app/common/services/list.service";
import { EasementModel } from '@app/common/models/easement.model';
import { ListModel } from '@app/common/models/list.model';
import { Restangular } from 'ngx-restangular';

@Component({
  templateUrl: './update-easement.component.html',
})
export class UpdateEasementComponent implements OnInit {
  @Input() caseId: number;
  @Input() easementId: number;
  easementObject: EasementModel;
  easementList: ListModel<EasementModel>;
  selected: EasementModel;
  showVbColumn: boolean;

  constructor(
    private stateService: StateService,
    private listService: ListService,
    private restangular: Restangular,
  ) {
    this.goToCase = this.goToCase.bind(this);
    this.update = this.update.bind(this);
  }

  async ngOnInit() {
    await this.loadEasement();
    this.easementList = this.listService.createList(
        'easements',
        {
          limit: undefined,
          filters: {
            parcelId: this.easementObject.parcel.id,
            validity: 'valid',
            loadCollections: ['constructionObjects'],
          },
          sortOrder: [{sortBy: 'parcel.parcisKmen'}, {sortBy: 'parcel.parcisPod'}]
        }
    );
    await this.listService.fetchResult(this.easementList);
    this.showVbColumn = (this.easementObject.hasOwnProperty('oznacVb') && this.easementObject.oznacVb) || this.easementList.list.some(easement => easement.hasOwnProperty('oznacVb') && easement.oznacVb);
  }

  async loadEasement() {
    this.selected = this.easementObject = await this.restangular.one('easements', this.easementId).customPOST({
      constructionObjects: {},
    }).toPromise();
  }

  goToCase() {
    this.stateService.go('^');
  }

  onToggleCheckItem(easement: EasementModel) {
    this.selected = easement;
  }

  async update() {
    await this.restangular.one('cases', this.caseId).one('update-easement', this.easementId).all(this.selected.id).customPUT().toPromise();
    this.stateService.go('^');
  }
}

