import { InjectionToken } from '@angular/core';

export const RESTANGULAR_SETTINGS = new InjectionToken<any>('RestangularSettings');

export function RestangularSettingsFactory(restangular: any, config: any) {
  const r = restangular.withConfig((restangularConfigurer) => {
    restangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlSETTINGS);
  });
  return r;
}
