import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TabModel } from '@app/common/models/tab.model';

@Component({
  selector: 'landing-vfze',
  templateUrl: './landing-vfze.component.html',
  styleUrls: ['./landing-vfze.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingVfzeComponent implements OnInit {
  @Input() activeTab: string;

  tabs: TabModel[] = [
    {
      name: 'Podmínky použití',
      id: 'terms-of-use',
      href: 'settings.modules.vfze',
      urlParams: { tab: 'terms-of-use' },
    },
  ];

  constructor() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  ngOnInit() {
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  isActiveTab(tabId: string): boolean {
    return tabId === this.activeTab;
  }
}
