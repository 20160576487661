import { Inject, Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { ModulesService } from '@app/common/services/modules.service';
import { RESTANGULAR_CONFIGURATION } from '@app/common/services/restangular-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    @Inject(RESTANGULAR_CONFIGURATION) private configRestangularService: any,
    private modulesService: ModulesService,
  ) { }

  /**
   * @param {string} customerId
   * @param {FormData} data
   *
   * @returns {Promise}
   */
  async create(customerId: string, data: FormData): Promise<any> {
    return this.configRestangularService
      .all(`v2/contracts/customer/${customerId}/new`)
      .customPOST(
        data,
        '',
        null,
        {'Content-Type': undefined},
      ).toPromise();
  }

  /**
   * @param {string} contractId
   * @param {FormData} data
   *
   * @returns {Promise}
   */
  async update(contractId: string, data: FormData): Promise<any> {
    return await this.configRestangularService
      .all(`v2/contracts/${contractId}`)
      .customPUT(
        data,
        '',
        null,
        {'Content-Type': undefined},
      )
      .toPromise();
  }

  async getOne(contractId: string): Promise<any> {
    return await this.configRestangularService
    .one(`v2/contracts/${contractId}`)
    .get((res) => {
      return res.plain();
    })
    .toPromise();
  }

  getOneFile(contractId: string, filename: string): Promise<any> {
    return this.configRestangularService
      .one(`v2/contracts/${contractId}/document`).withHttpConfig({ responseType: 'blob' }).get().toPromise().then((response) => {
        saveAs(response, filename, true);
      });
  }

  /**
   * @param {string} customerId
   *
   * @returns {Promise}
   */
  async getForCustomer(customerId: string): Promise<any[]> {
    return await this.configRestangularService
      .all(`v2/customers/${customerId}/contracts`)
      .getList()
      .toPromise();
  }

  async deleteOne(contractId: string): Promise<any> {
    return await this.configRestangularService
      .one(`v2/contracts/${contractId}`)
      .remove()
      .toPromise();
  }

  async addProject(contractId: string, application: string, projectKey: string) {
    const data = new FormData();
    data.append('application', application);
    data.append('projectKey', projectKey);

    return await this.configRestangularService
      .all(`v2/contracts/${contractId}/project`)
      .customPOST(
        data,
        '',
        null,
        {'Content-Type': undefined},
      ).toPromise();
  }

  async deleteProject(contractId: string, projectId: number): Promise<any> {
    return await this.configRestangularService
      .one(`v2/contracts/${contractId}/project/${projectId}`)
      .remove()
      .toPromise();
  }
}
