﻿import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

enum LabelPositions {
  AFTER = 'after',
  BEFORE = 'before',
}

@Component({
  selector: 'gmt-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() gmtChecked: boolean;
  @Input() gmtDisabled: boolean;
  @Input() gmtType: string;
  @Input() gmtLabelPosition?: string;
  @Output() changed = new EventEmitter();

  ngOnInit() {
    this.gmtType = this.gmtType || 'checkbox';
    this.throwOnBadLabelPosition();
  }

  onChanged() {
    this.changed.emit(this.gmtChecked);
  }

  /**
   * Is there a better way to check the @Input value?
   */
  private throwOnBadLabelPosition() {
    if (this.gmtLabelPosition && !Object.values(LabelPositions).includes(this.gmtLabelPosition)) {
      throw new Error(`Provided invalid label position. Choose one of ${Object.values(LabelPositions)}.`);
    }
  }
}
