<div [loading]="loading">
  <ul>
    <li *ngFor="let ownership of data.list?.list" class="ownership cell--flex__container">
      <div class="cell--flex__container__main">
        {{ownership.podilCitatel}} / {{ownership.podilJmenovatel}}{{ownership.typravNazev ? ' (' + ownership.typravNazev + ")" : ''}}
        <div class="comma-list">
          <a *ngFor="let subject of (ownership.subjects || ownership.subject)"
             uiSref="symap.project.owners.detail"
             [uiParams]="{ownerId: subject.id, tab: 'about'}">
            {{subject | subjectName}}
          </a>
        </div>
      </div>
      <div class="cell--flex__container_side">
        <span title="Problematický vlastník" class="tag tag--danger tag--small is-uppercase" *ngIf="ownership.subjects && (ownership.subjects[0].subjectDetail?.problematic || ownership.subjects[1]?.subjectDetail?.problematic)">PV</span>
      </div>
    </li>
  </ul>
  <div *ngIf="data.list.list?.length === 0">Žádní vlastníci</div>
</div>
