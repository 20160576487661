import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({ name: 'personalNumber' })
@Injectable({
  providedIn: 'root'
})
export class PersonalNumberPipe implements PipeTransform {
  transform(number: string) {
    if (!number) {
      return '';
    }
    if (number.length <= 6) {
      return number;
    }
    return number.substr(0, 6) + "/" + number.substr(6);
  }
}
